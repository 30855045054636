import { Component, Inject, ViewChild, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../services/core/alert.service'
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent } from "devextreme-angular";

import { BsModalService } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ready-for-billing-orders',
  templateUrl: './ready-for-billing-orders.component.html',
  styleUrls: ['./ready-for-billing-orders.component.css']
})
export class ReadyForBillingOrdersComponent implements OnInit {

  public readyForBillingOrdersStore: any = {};
  @ViewChild('readyForBillingOrders', { static: true }) readyForBillingOrdersGrid: DxDataGridComponent;

  private refreshId: any;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities, private sub: SubService, private gs: GlobalService) {

  }

  ngOnInit() {

    this.getReadyForBillingOrders();

    this.sub.orderEditedMethodCalled.subscribe(() => { this.readyForBillingOrdersGrid.instance.refresh(); });

    this.refreshId = setInterval(() => {
      this.readyForBillingOrdersGrid.instance.refresh();
    }, environment.ordersReadyForBillingGridRefreshTimeInMins * 60000);
  };


  public onRefresh() {
    this.readyForBillingOrdersGrid.instance.refresh();
  }

  public getReadyForBillingOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.readyForBillingOrdersStore = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };

        return orderService.getReadyForBillingOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var readyForBillingOrdersResponse = response.result;
              var obj: any = {
                data: readyForBillingOrdersResponse.orderResults,
                totalCount: readyForBillingOrdersResponse.totalCount
              };
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}

