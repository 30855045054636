import { UserProfile } from "./user.profile.model";

export class User {

  public id: string;
  public userProfile: UserProfile
  public userName: string;
  public email: string;
  public roles: string[];
  public isEnabled: boolean;
  public isLockedOut: boolean;

  constructor(userProfile: UserProfile, id: string, userName: string, email: string, roles: string[]) {
    this.userProfile = userProfile;
    this.id = id;
    this.userName = userName;
    this.email = email;
    this.roles = roles;
  }

}
