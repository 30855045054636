<script src="angular-input-masks-standalone.min.js"></script>
<header class="tylie-header">
    <h3 class="tylie-header__title">Account Settings</h3>
    <nav class="tylie-header__nav">
      <ul class="tylie-header__nav-lst">
        <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('personalinformation-section')">Personal Information</a></li>
        <li class="tylie-header__nav-lst-itm" *ngIf="this.userProfileObj.userProfileType == 'ClientUser'"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('usernotifications-section')">Notifications</a></li>
        <li class="tylie-header__nav-lst-itm" *ngIf="this.userProfileObj.userProfileType == 'ClientUser'"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('userdistributiongroups-section')">User Distribution Groups</a></li>
      </ul>
    </nav>
</header>

<div class="tylie-body inner-content">
  <div class="tylie-panel" id="personalinformation-section">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
          Personal Information
      </h4>
    </div>
    <div class="tylie-panel__body" *ngIf="userProfileObj != null">
      <form novalidate [formGroup]="PersonalInfo" #p="ngForm" class="tylie-form">
      <div class="row">
        <div class="col-3">
          <h6><b>Profile Avatar</b></h6>
          <div id="myAvatar" class="tylie-carousel carousel slide" *ngIf="showSeeAllAvatarButton()">
            <div class="carousel-inner">
              <div align="center" class="carousel-item active">
                <img src="{{imageBinary}}" alt="">
              </div>
            </div>
          </div>

          <div id="myAvatar" class="tylie-carousel carousel slide" data-ride="carousel" data-interval="false" *ngIf="showsaveSelectedAvatarButton()">
            <!-- Sliding images statring here -->
            <div class="carousel-inner">
              <div class="carousel-item active" id="./assets/avatars/bear colour FINAL3.jpg">
                <img src="./assets/avatars/bear colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/baboon colour FINAL3.jpg">
                <img src="./assets/avatars/baboon colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/cat colour FINAL3.jpg">
                <img src="./assets/avatars/cat colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/chimp colour FINAL3.jpg">
                <img src="./assets/avatars/chimp colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/dog colour FINAL3.jpg">
                <img src="./assets/avatars/dog colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/elephant colour FINAL3.jpg">
                <img src="./assets/avatars/elephant colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/gazelle colour FINAL3.jpg">
                <img src="./assets/avatars/gazelle colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/giraffe colour FINAL3.jpg">
                <img src="./assets/avatars/giraffe colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/hummingbird colour FINAL3.jpg">
                <img src="./assets/avatars/hummingbird colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/kangaroo colour FINAL3.jpg">
                <img src="./assets/avatars/kangaroo colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/koala colour FINAL3.jpg">
                <img src="./assets/avatars/koala colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/lion colour FINAL3.jpg">
                <img src="./assets/avatars/lion colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/llama-colour-FINAL3.jpg">
                <img src="./assets/avatars/llama-colour-FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/ostrich colour FINAL3.jpg">
                <img src="./assets/avatars/ostrich colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/otter colour FINAL3.jpg">
                <img src="./assets/avatars/otter colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/owl colour FINAL3.jpg">
                <img src="./assets/avatars/owl colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/panda colour FINAL3.jpg">
                <img src="./assets/avatars/panda colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/pig colour FINAL3.jpg">
                <img src="./assets/avatars/pig colour FINAL3.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/tiger-COLOUR-FINAL2.jpg">
                <img src="./assets/avatars/tiger-COLOUR-FINAL2.jpg" alt="">
              </div>
              <div class="carousel-item" id="./assets/avatars/zebra-colour-FINAL3.jpg">
                <img src="./assets/avatars/zebra-colour-FINAL3.jpg" alt="">
              </div>
            </div>
           
            <button class="carousel-control-prev" type="button" data-bs-target="#myAvatar" data-bs-slide="prev">
              <icon-arrow-circle-left color="#000"></icon-arrow-circle-left>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#myAvatar" data-bs-slide="next">
              <icon-arrow-circle-right color="#000"></icon-arrow-circle-right>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

          <ng-container *ngIf="showSeeAllAvatarButton()">
            <button class="tylie-button tylie-button--xs" type="button" (click)="enablecarousel()">Change Avatar</button>
          </ng-container>

          <ng-container  *ngIf="showsaveSelectedAvatarButton()">
            <button class="tylie-button tylie-button--xs tylie-button--link me-3" type="button" (click)="cancelAvatarEdit()">Cancel</button>
            <button class="tylie-button tylie-button--xs" type="button" (click)="saveSelectedAvatarInBytes()">Use this Avatar</button>
          </ng-container>

        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="username">User Name<span class="required">*</span></label>
                <div name="username" id="username">{{userName}}</div>
                <p class="p-extra-small">Please Enter user name</p>
              </div>
            </div>
            <div class="col-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: (PersonalInfo.controls['firstname'].errors && !PersonalInfo.controls['firstname'].pristine) || (PersonalInfo.controls['firstname'].errors && p.submitted)}">
                <label class="tylie-form-group__lbl" for="fname">First Name<span class="required">*</span></label>
                <input type="text" name="fname" id="fname" class="tylie-text form-control" formControlName="firstname">
                <p class="p-extra-small">Please Enter First Name</p>
              </div>
            </div>
            <div class="col-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: (PersonalInfo.controls['lastname'].errors && !PersonalInfo.controls['lastname'].pristine) || (PersonalInfo.controls['lastname'].errors && p.submitted)}">
                <label class="tylie-form-group__lbl" for="lname">Last Name<span class="required">*</span></label>
                <input type="text" name="lname" id="lname" class="tylie-text form-control" formControlName="lastname">
                <p class="p-extra-small">Please Enter Last Name</p>
              </div>
            </div>

            <div class="col-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: (workPhoneValid() && p.submitted)}">
                <label class="tylie-form-group__lbl" for="pnumber">Work Phone<span class="required">*</span></label>
                <input type="text" [textMask]="{mask: phoneMask, guide: false, modelClean: true}" name="pnumber" id="pnumber" class="tylie-text form-control" formControlName="workphone">
                <p class="p-extra-small">Please Enter a Valid Work Phone</p>
              </div>
            </div>
            <div class="col-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: (cellPhoneValid() && p.submitted)}">
                <label class="tylie-form-group__lbl" for="cnumber">Cell Phone <span class="required">*</span></label>
                <input type="text" [textMask]="{mask: phoneMask, guide: false, modelClean: true}" name="cnumber" id="cnumber" class="tylie-text form-control" formControlName="cellphone">
                <p class="p-extra-small">Please Enter a Valid Cell Phone</p>
              </div>
            </div>
            <div class="col-4">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="birthmonth">Birthday<span class="required">*</span></label>
                <div class="row">
                  <div class="col-6">
                    <dx-select-box formControlName="birthmonth"
                        [dataSource]="monthList"
                        name="birthmonth" id="birthmonth" class="tylie-select form-control"
                        (onValueChanged)="onbirthMonthChange($event)"
                        [showClearButton]="true"
                        placeholder="Select month">
                    </dx-select-box>
                  </div>
                  <div class="col-6">
                    <dx-select-box formControlName="birthday"
                        [dataSource]="dayList"
                        [disabled]="dayList.length == 0"
                        [showClearButton]="true"
                        name="birthday" id="birthday" class="tylie-select form-control"
                        placeholder="Select day">
                    </dx-select-box>
                  </div>
                </div>
                
               
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="timezone">Time Zone<span class="required">*</span></label>
                  <div class="row">
                    <div class="col-8">
                      <dx-select-box formControlName="timezone"
                        [dataSource]="timezoneDataSource"
                        name="timezone" id="timezone" class="tylie-select form-control"
                        placeholder="Select a value...">
                      </dx-select-box>
                      <p class="p-extra-small">Please Select a Time Zone</p>
                    </div>
                    <!--<div class="col-4"  *ngIf="showChangePwd == true">
                      <button class="tylie-button tylie-button--xs tylie-button--link mt-2" type="button" (click)="changePassword(changePasswordTemp)">Change Password</button>
                    </div>-->
                  </div>
                  
                </div>
              </div>
            </div>
            
            
            <div class="col-12">
              <div class="d-flex justify-content-end">
                <button type="button" (click)="cancelEdits()" class="tylie-button tylie-button--xs tylie-button--link me-3">Cancel Edits</button> 
                <button type="button" class="tylie-button tylie-button--xs" type="submit" (click)="saveAccountSettings(PersonalInfo)">Save Changes</button>
              </div>
            </div>
           
          </div>
        </div>
        </div>
      </form>
    </div>
    
    <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="userProfileObj == null"></dx-load-indicator>
  </div>

  <ng-container  *ngIf="this.userProfileObj.userProfileType == 'ClientUser'">
    <div class="tylie-panel" id="usernotifications-section">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
            Notifications
        </h4>
      </div>
      
      <div class="tylie-panel__body" *ngIf="notificationPreferenceList != null">
        <p class="p-normal">
          Configure all types of notifications you'd like to receive. You can also add more emails and SMS phone numbers.
        </p>
        <form [formGroup]="emailAdd" novalidate #n="ngForm">
          
          <div formArrayName="emails">
            <div class="mb-4" *ngFor="let em of getEmailsData.controls; let i=index">
              <div class="row" [formGroupName]="i">
                <div class="col-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: (emailAdd.controls.emails.controls[i].controls.email.errors && !emailAdd.controls.emails.controls[i].controls.email.pristine) || (emailAdd.controls.emails.controls[i].controls.email.errors && n.submitted) || (emailAlreadyExistsInForm(emailAdd.controls.emails.controls[i].controls.email._value))}">
                    <label class="tylie-form-group__lbl" for="pemail">Email<span class="required">*</span></label>
                    <div class="d-flex">
                      <input type="text" name="pemail" id="pemail" class="tylie-text form-control" formControlName="email">
                      <!-- <span class="glyphicon glyphicon-remove-sign" (click)="removeEmails(i)"></span> -->
                      <button class="tylie-button tylie-button--link ms-2" type="button" (click)="removeEmails(i)"><icon-trash-can color="#1e90ff"></icon-trash-can></button>
                    </div>
                    <p class="p-extra-small" *ngIf="(emailAlreadyExistsInForm(emailAdd.controls.emails.controls[i].controls.email._value)) != true">Please Enter a valid Email</p>
                    <p class="p-extra-small" *ngIf="(emailAlreadyExistsInForm(emailAdd.controls.emails.controls[i].controls.email._value)) == true">Enter distinct Emails</p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group " [ngClass]="{error:((displayErrorForEmailNotificationPreferencesNotSelected(i,emailNotificationType) == true) && n.submitted)}">
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaArrivalConfirmation">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaArrivalConfirmation" formControlName="MediaArrivalConfirmation"><span>Asset Arrival Confirmation</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaApproveRejectNotification">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaApproveRejectNotification" formControlName="MediaApproveRejectNotification"><span>Asset Approve/Reject Notification</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCPending">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaQCPending" formControlName="MediaQCPending"><span>Asset QC Pending</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCPass">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaQCPass" formControlName="MediaQCPass"><span>Asset QC Pass</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCFail">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaQCFail" formControlName="MediaQCFail"><span>Asset QC Fail</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="Order Completion">
                      <span class="required"></span>
                      <input type="checkbox" name="OrderCompletion" formControlName="OrderCompletion"><span>Order Completion</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="OrderEditNotification">
                      <span class="required"></span>
                      <input type="checkbox" name="OrderEditNotification" formControlName="OrderEditNotification"><span>Order Edit Notification</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="OrderConfirmation">
                      <span class="required"></span>
                      <input type="checkbox" name="OrderConfirmation" formControlName="OrderConfirmation"><span>Order Confirmation</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaEditNotification">
                      <span class="required"></span>
                      <input type="checkbox" name="MediaEditNotification" formControlName="MediaEditNotification"><span>Asset Edit Notification</span>
                    </label>
                    <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaPendingApproval">
                      <span class="required"></span>
                      <input type="checkbox" name="mediapendingapproval" formControlName="MediaPendingApproval"><span>Asset Pending Approval</span>
                    </label>
                    <br />
                    <p class="p-extra-small" *ngIf="((displayErrorForEmailNotificationPreferencesNotSelected(i,emailNotificationType) == true) && n.submitted)">Atleast one choice should be selected</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="smsAdd" novalidate #np="ngForm">
            <div formArrayName="phones">
              <div class="mb-4" *ngFor="let em of getSmsData.controls; let i=index">
                <div class="row" [formGroupName]="i">
                  <div class="col-4">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (smsAdd.controls.phones.controls[i].controls.phone.errors && !smsAdd.controls.phones.controls[i].controls.phone.pristine) || (smsAdd.controls.phones.controls[i].controls.phone.errors && n.submitted) || (phoneAlreadyExistsInForm(smsAdd.controls.phones.controls[i].controls.phone._value))}">
                      <label for="stpnum" class="tylie-form-group__lbl">Phone<span class="required">*</span></label>
                      <div class="d-flex">
                        <input type="text" name="phone" id="phone" class="tylie-text form-control" formControlName="phone" [textMask]="{mask: phoneMask, guide: false, modelClean: true}">
                        <!-- <span class="glyphicon glyphicon-remove-sign" (click)="removeSms(i)"></span> -->
                        <button class="tylie-button tylie-button--link ms-2" type="button" (click)="removeSms(i)"><icon-trash-can color="#1e90ff"></icon-trash-can></button>
                      </div>
                      <p class="p-extra-small" *ngIf="(phoneAlreadyExistsInForm(smsAdd.controls.phones.controls[i].controls.phone._value)) != true">Please Enter a valid Phone</p>
                      <p class="p-extra-small" *ngIf="(phoneAlreadyExistsInForm(smsAdd.controls.phones.controls[i].controls.phone._value)) == true">Enter distinct Phones</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group " [ngClass]="{error:((displayErrorForsmsNotificationPreferencesNotSelected(i,smsNotificationType) == true) && n.submitted)}">
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaArrivalConfirmation">
                        <span class="required"></span>
                        <input type="checkbox" name="newmedia" formControlName="MediaArrivalConfirmation"><span>Asset Arrival Confirmation</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaApproveRejectNotification">
                        <span class="required"></span>
                        <input type="checkbox" name="mediaapproval" formControlName="MediaApproveRejectNotification"><span>Asset Approve/Reject Notification</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCPending">
                        <span class="required"></span>
                        <input type="checkbox" name="MediaQCPending" formControlName="MediaQCPending"><span>Asset QC Pending</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCPass">
                        <span class="required"></span>
                        <input type="checkbox" name="MediaQCPass" formControlName="MediaQCPass"><span>Asset QC Pass</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaQCFail">
                        <span class="required"></span>
                        <input type="checkbox" name="MediaQCFail" formControlName="MediaQCFail"><span>Asset QC Fail</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="Order Completion">
                        <span class="required"></span>
                        <input type="checkbox" name="neworder" formControlName="OrderCompletion"><span>Order Completion</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="OrderEditNotification">
                        <span class="required"></span>
                        <input type="checkbox" name="orderedit" formControlName="OrderEditNotification"><span>Order Edit Notification</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="OrderConfirmation">
                        <span class="required"></span>
                        <input type="checkbox" name="ordercomplete" formControlName="OrderConfirmation"><span>Order Confirmation</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaEditNotification">
                        <span class="required"></span>
                        <input type="checkbox" name="mediaedit" formControlName="MediaEditNotification"><span>Asset Edit Notification</span>
                      </label>
                      <label class="checkbox-inline usr-settings col-md-3" notificationPref displayPreference="MediaPendingApproval">
                        <span class="required"></span>
                        <input type="checkbox" name="mediapendingapproval" formControlName="MediaPendingApproval"><span>Asset Pending Approval</span>
                      </label>
                      <br />
                      <p class="p-extra-small" *ngIf="((displayErrorForsmsNotificationPreferencesNotSelected(i,smsNotificationType) == true) && n.submitted)">Atleast one choice should be selected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          
          <div class="row">
            <div class="col">
              <button type="button" class="tylie-button tylie-button--xs me-3" (click)="addEmails()">Add New Email</button>
              <button type="button" class="tylie-button tylie-button--xs" (click)="addPhones()">Add New phone</button>
            </div>
          </div>
          <ng-container class="row" *ngIf="((getSmsData.controls.length > 0) || (getEmailsData.controls.length > 0))">
            <div class="d-flex justify-content-end">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="cancelNotificationEdits(n)">Cancel Edits </button><button class="tylie-button tylie-button--xs" type="submit" (click)="getUserNotificationPreferences(n)">Save Changes</button>
            </div>
          </ng-container>
        </form>

        


        <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="notificationPreferenceList == null"></dx-load-indicator>
      </div>
    </div>
  </ng-container>

  <user-distribution-group id="userdistributiongroups-section" *ngIf="this.userProfileObj.userProfileType == 'ClientUser'"></user-distribution-group>
</div>


