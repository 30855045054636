<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>
  <label>Please select specs or enter comments</label>

  <div *ngIf="customSpecMode == true">
    <label>Step 1</label>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['fileType'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">File Type<span class="required">*</span></label>
      <dx-select-box [items]="fileTypes" class="tylie-select" name="fileType" formControlName="fileType" (onValueChanged)="onFileTypeChanged()" placeholder="Choose fileType">
      </dx-select-box>
      <p class="p-extra-small">Please choose File Type or Add Comments</p>
    </div>
  </div>


  <div *ngIf="codecs.length > 0 && customSpecMode == true">
    <hr />
    <label>Step 2</label>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['codec'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Codec<span class="required">*</span></label>
      <div class="service-radio-sec">
        <ng-container *ngFor="let opt of codecs">
          <input type="radio" name="codec" formControlName="codec" [value]="opt" (change)="onCodecChanged()"><span>{{opt}}</span>
        </ng-container>
      </div>
      <p class="p-extra-small">Please choose Codec</p>
    </div>
    <hr />
  </div>

  <div *ngIf="resolutions.length > 0 && slateoptions.length > 0 && framerates.length > 0 && customSpecMode == true">
    <label>Step 3</label>

    <div class="tylie-form-group form-group" *ngIf="bitratesTypeA.length > 0" [ngClass]="{error: (formGroup.controls['bitrate'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="bitrate">Video Bitrate<span class="required">*</span></label>
      <div class="row col-md-12">
        <div class="col-sm-offset-4">
          <div class="service-radio-sec">
            <ng-container *ngFor="let opt of bitrateunits">
              <input type="radio" name="bitrateunit" [value]="opt" formControlName="bitrateunit" (change)="onBitRateUintChanged(opt)"><span>{{opt}}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <dx-number-box formControlName="bitrate"
                         [placeholder]="bitratesTypeA[0] + '-' + bitratesTypeA[1]"
                         [min]="bitratesTypeA[0]"
                         [max]="bitratesTypeA[1]"
                         class="tylie-select"
                         [value]="formGroup.controls['bitrate'].value"
                         [showSpinButtons]="false"></dx-number-box>
        </div>
        <div class="col-sm-9">
          <dx-slider [min]="bitratesTypeA[0]"
                     [max]="bitratesTypeA[1]"
                     name="bitrate"
                     [value]="formGroup.controls['bitrate'].value"
                     formControlName="bitrate">
            <dxo-tooltip [enabled]="true" showMode="always" position="top"></dxo-tooltip>
            <dxo-label position="bottom" [visible]="true"></dxo-label>
          </dx-slider>
        </div>
      </div>
      <p class="p-extra-small">Please choose Bitrate</p>
    </div>

    <div class="tylie-form-group form-group" *ngIf="audiobitratesTypeA.length > 0" [ngClass]="{error: (formGroup.controls['audiobitrate'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="audiobitrate">Audio Bitrate (kbps)<span class="required">*</span></label>
      <div class="row">
        <div class="col-sm-3">
          <dx-number-box formControlName="audiobitrate"
                         [placeholder]="audiobitratesTypeA[0] + '-' + audiobitratesTypeA[1]"
                         [min]="audiobitratesTypeA[0]"
                         [max]="audiobitratesTypeA[1]"
                         [value]="formGroup.controls['audiobitrate'].value"
                         [showSpinButtons]="false"
                         class="tylie-select"></dx-number-box>
        </div>
        <div class="col-sm-9">
          <dx-slider [min]="audiobitratesTypeA[0]"
                     [max]="audiobitratesTypeA[1]"
                     name="audiobitrate"
                     [value]="formGroup.controls['audiobitrate'].value"
                     formControlName="audiobitrate">
            <dxo-tooltip [enabled]="true" showMode="always" position="top"></dxo-tooltip>
            <dxo-label position="bottom" [visible]="true"></dxo-label>
          </dx-slider>
        </div>
      </div>
      <p class="p-extra-small">Please choose Audio Bitrate</p>
    </div>

    <div class="tylie-form-group form-group" *ngIf="formGroup.controls['resolution'].value!= ''" [ngClass]="{error: (formGroup.controls['resolution'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Resolution<span class="required">*</span></label>
      <div class="service-radio-sec">
        <ng-container *ngFor="let opt of resolutions">
          <input type="radio" name="resolution" formControlName="resolution" [value]="opt"><span>{{opt}}</span>
        </ng-container>
      </div>
      <p class="p-extra-small">Please choose Resolution</p>
    </div>

    <div class="tylie-form-group form-group" *ngIf="formGroup.controls['slate'].value!= ''" [ngClass]="{error: (formGroup.controls['slate'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Slate<span class="required">*</span></label>
      <div class="service-radio-sec">
        <ng-container *ngFor="let opt of slateoptions">
          <input type="radio" name="slate" formControlName="slate" [value]="opt"><span>{{opt}}</span>
        </ng-container>
      </div>
      <p class="p-extra-small">Please choose an option</p>
    </div>

    <div class="tylie-form-group form-group" *ngIf="formGroup.controls['framerate'].value!= ''" [ngClass]="{error: (formGroup.controls['framerate'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Frame Rate<span class="required">*</span></label>
      <div class="service-radio-sec">
        <ng-container *ngFor="let opt of framerates">
          <input type="radio" name="framerate" formControlName="framerate" [value]="opt"><span>{{opt}}</span>
        </ng-container>
      </div>
      <p class="p-extra-small">Please choose Frame Rate</p>
    </div>

    <div class="tylie-form-group form-group" *ngIf="audiosamplerates.length > 0" [ngClass]="{error: (formGroup.controls['audiosamplerate'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Audio Sample Rate<span class="required">*</span></label>
      <div class="service-radio-sec">
        <ng-container *ngFor="let opt of audiosamplerates">
          <input type="radio" name="audiosamplerate" formControlName="audiosamplerate" [value]="opt"><span>{{opt}}</span>
        </ng-container>
      </div>
      <p class="p-extra-small">Please choose Audio Sample Rate</p>
    </div>

  </div>

  <div class="tylie-form-group form-group">
    <label class="tylie-form-group__lbl">Custom File Name:  Paste your complete File Name in the first field or choose from drop down options (options will be separated by "-").  When not specified, system uses default file name "Ad ID-Title-Duration".</label>
    <div class="row">
      <div class="col-md-4">
        <dx-select-box class="tylie-select" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos1" formControlName="customfilenamepos1" placeholder="Select">
        </dx-select-box>
      </div>

      <div class="col-md-4">
        <dx-select-box class="tylie-select" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos2" formControlName="customfilenamepos2" placeholder="Select">
        </dx-select-box>
      </div>

      <div class="col-md-4">
        <dx-select-box class="tylie-select" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos3" formControlName="customfilenamepos3" placeholder="Select">
        </dx-select-box>
      </div>
    </div>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['comments'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="comments">Comments<span class="required">*</span></label>
    <textarea name="comments" id="comments" class="tylie-textarea tylie-textarea--md form-control" formControlName="comments" rows="4"></textarea>
    <p class="p-extra-small">Please enter Comments or Choose File Type</p>
  </div>
</div>
