<header class="tylie-header">
  <h3 class="tylie-header__title">TAGGER</h3>
  <nav class="tylie-header__nav">
    <button type="button"
      class="tylie-button tylie-button--link tylie-button--xs"
      (click)="gotoTaggerHome()"
    >
      <span class="tylie-button__icon"
        ><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span
      >Back To Tagger Home
    </button>
  </nav>
</header>

<div class="tylie-body">
  <div class="tylie-panel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
       CREATE A NEW AD
      </h4>
    </div>
    <div class="tylie-panel__body">
      <form novalidate [formGroup]="topFormGroup">
        <!-- Review step -->
        <div id="review_step" *ngIf="
            currentStep !== adCreatorStepEnum.selectCreative &&
            (selectedBaseCreatives.length ||
              selectedOfferCards.length ||
              selectedEndCards.length ||
              selectedEndCardVoiceOvers.length ||
              (currentStep == adCreatorStepEnum.reviewAdCreator &&
                !isAdCreatorSubmitted))
          "
        >
          <app-tagger-review-step
            [currentStep]="currentStep"
            [requestOrigination]="requestOrigination"
            [topFormGroup]="topFormGroup"
            [selectedBaseCreatives]="selectedBaseCreatives"
            [selectedOfferCards]="selectedOfferCards"
            [selectedEndCards]="selectedEndCards"
            [selectedEndCardVoiceOvers]="selectedEndCardVoiceOvers"
          >
          </app-tagger-review-step>
        </div>

        <!-- Header -->
        <div *ngIf="currentStep === adCreatorStepEnum.selectCreative || (requestOrigination === 'CreativeTags') && currentStep === adCreatorStepEnum.selectOfferCard" class="tylie-panel tylie-panel--secondary">
          <div class="tylie-panel__header tylie-panel__header--br-thick">
            <h4 class="tylie-panel__title">
              <div><span class="me-2"><icon-checkmark-white></icon-checkmark-white></span>NAME YOUR AD</div>
            </h4>
          </div>

          <div class="tylie-panel__body">
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="adCreatorName">
                    Give your Ad a Name {{requestOrigination}}
                  </label>
                  <input
                    name="name"
                    type="text"
                    autofocus
                    class="tylie-text form-control"
                    id="adCreatorName"
                    placeholder="Here is my new Ad name"
                    formControlName="name"
                  />
                  <div [ngClass]="{error: topFormGroup.get('name').errors.required}"
                    *ngIf="
                      topFormGroup.get('name').invalid &&
                      (topFormGroup.get('name').dirty ||
                        topFormGroup.get('name').touched)
                    "
                  >
                    <div  *ngIf="topFormGroup.get('name').errors.required">
                      Name is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Step 1 select slots -->
        <div id="creative_step" *ngIf="currentStep === adCreatorStepEnum.selectCreative">
          <app-tagger-creative-step
            [topFormGroup]="topFormGroup"
            (next)="onNext()"
          ></app-tagger-creative-step>
        </div>
        <!-- Step 2 select offer cards -->
        <div id="selectOfferCard_step" *ngIf="currentStep == adCreatorStepEnum.selectOfferCard">
          <app-tagger-offer-card-step
            [topFormGroup]="topFormGroup"
            (next)="onNext()"
          ></app-tagger-offer-card-step>
        </div>
        <!-- Step 3 select end cards -->
        <div id="selectEndCard_step" *ngIf="currentStep == adCreatorStepEnum.selectEndCard">
          <app-tagger-end-card-step
            [topFormGroup]="topFormGroup"
            (next)="onNext()"
          ></app-tagger-end-card-step>
        </div>
        <!-- Step 4 select end card voice over -->
        <div id="selectEndCard_step" *ngIf="currentStep == adCreatorStepEnum.selectEndCardVoiceOver">
          <app-tagger-end-card-voice-over-step
            [topFormGroup]="topFormGroup"
            (next)="onNext()"
          ></app-tagger-end-card-voice-over-step>
        </div>
      </form>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!isAdCreatorSubmitted">
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="gotoAdCreators()">
          CANCEL
      </button>
      <button type="button" *ngIf="!isFirstStep" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onPrevious()">
        PREVIOUS STEP
      </button>
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" *ngIf="!isLastStep" (click)="onNext()">
        NEXT
      </button>
      <button type="button" *ngIf="!isLastStep" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onSaveAsDraft()">
        <span class="tylie-button__icon">
          <icon-file></icon-file>
        </span>
        <span class="tylie-button__text">SAVE AS DRAFT</span>
      </button>
      <button type="button" *ngIf="isLastStep && !adGuid && !orderGuid" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onAdCreate()">
        <span class="tylie-button__icon">
          <icon-checkmark></icon-checkmark>
        </span>
        <span class="tylie-button__text">CREATE YOUR AD</span>
      </button>
      <button type="button" *ngIf="isLastStep && adGuid && orderGuid" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onUpdateAd()">
        <span class="tylie-button__icon">
          <icon-checkmark></icon-checkmark>
        </span>
        <span class="tylie-button__text">Update YOUR AD</span>
      </button>
  </div>
</div>
