import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  LocationModel,
  TaggerGroupRequestModel,
  UpdateTaggerGroupLocationModel,
} from "../../../models/tagger/tagger.model";
import {
  AlertService,
  MessageSeverity,
} from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { Subject } from "rxjs";
import { DxDataGridComponent } from "devextreme-angular";
import { GridParams } from "../../../models/config/gridparams.model";
import CustomStore from "devextreme/data/custom_store";
import { UUID } from "angular2-uuid";

@Component({
  selector: "app-add-groups-locations-pop-up",
  templateUrl: "./add-groups-locations-pop-up.component.html",
  styleUrls: ["./add-groups-locations-pop-up.component.css"],
})
export class AddGroupsLocationsPopUpComponent implements OnInit {
  @ViewChild("groupLocationsGrid", { static: false })
  groupLocationsGrid: DxDataGridComponent;

  public addOrUpdateGroupFormGroup: FormGroup;

  public modalTitle: string;
  @Input() locationsByGroup: LocationModel[];

  @Input() isEditGroup: boolean = false;
  @Input() isAddOrEditLocation: boolean = false;
  @Input() isAddGroup: boolean = false;
  @Input() groupName: string;
  @Input() groupDescription: string;
  @Input() groupGuid: string;
  public onClose: Subject<boolean>;
  public isDuplicateGroupName: boolean = false;

  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  //public resultLocationStore: any = {};
  public locations: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private taggerService: TaggerService,
    public util: Utilities
  ) {
    // Tagger Group Form
    this.addOrUpdateGroupFormGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      groupDescription: new FormControl(""),
    });
  }

  ngOnInit(): void {
    if (this.isAddGroup) {
      this.searchLocations();
      this.modalTitle = "ADD NEW GROUP";
    }
    if (this.isAddOrEditLocation) {
      this.modalTitle = "EDIT GROUP LOCATIONS";
      this.searchLocations();
    }
    if (this.isEditGroup) {
      this.modalTitle = "EDIT GROUP";
      this.addOrUpdateGroupFormGroup.controls["name"].setValue(this.groupName);
      this.addOrUpdateGroupFormGroup.controls["groupDescription"].setValue(
        this.groupDescription
      );
    }
    this.onClose = new Subject();
  }

  ngAfterViewInit() {
    if (
      this.groupLocationsGrid &&
      this.groupLocationsGrid.instance &&
      this.locationsByGroup
    ) {
      const locationsGuids = this.locationsByGroup.map((l) => l.locationGuid);
      this.groupLocationsGrid.instance.selectRows(locationsGuids, true);
    }
  }

  private getTaggerGroupRequestForm(selectedLocations: Array<string>) {
    const taggerGroupInfo: TaggerGroupRequestModel = {
      groupName: this.addOrUpdateGroupFormGroup.get("name").value,
      groupDescription:
        this.addOrUpdateGroupFormGroup.get("groupDescription").value || null,
      groupGuid: UUID.UUID(),
      locationGuids: selectedLocations,
    };

    return taggerGroupInfo;
  }

  public createTaggerGroup(payload: TaggerGroupRequestModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.taggerService.createTaggerGroup(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.createTaggerGroupSuccessful,
            MessageSeverity.success
          );
          this.closePopup();
          this.onClose.next(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public updateTaggerGroup() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: TaggerGroupRequestModel =
      this.getUpdateTaggerGroupRequestModel();
    this.taggerService.updateTaggerGroup(payload).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.updateTaggerGroupSuccessful,
            MessageSeverity.success
          );
          this.closePopup();
          this.onClose.next(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  validateDuplicateGroupName() {
    const groupName = this.addOrUpdateGroupFormGroup.controls["name"];
    if (!groupName) {
      this.isDuplicateGroupName = false;
      return;
    }
    const payload: TaggerGroupRequestModel = {
      groupName: groupName.value,
      groupGuid: this.groupGuid
    };

    this.taggerService.validateDuplicateGroupName(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.isDuplicateGroupName = res.result;
          if (!this.isDuplicateGroupName) {
            if (this.isEditGroup) {
              this.updateTaggerGroup();
            }
            else {
            var selectedLocations: Array<string> = [];
            for (
              var i = 0;
              i < this.groupLocationsGrid.instance.getSelectedRowKeys().length;
              i++
            ) {
              selectedLocations.push(
                this.groupLocationsGrid.instance.getSelectedRowKeys()[i]
              );
            }
            const groupValue =
              this.getTaggerGroupRequestForm(selectedLocations);   
              // Creates new Group
              this.createTaggerGroup(groupValue);
            }
          }
        } else {
          this.isDuplicateGroupName = false;
        }
      },
      () => {
        this.isDuplicateGroupName = false;
      }
    );
  }

  private getUpdateTaggerGroupRequestModel() {
    const updateGroup: TaggerGroupRequestModel = {
      groupGuid: this.groupGuid,
      groupName: this.addOrUpdateGroupFormGroup.get("name").value || null,
      groupDescription:
        this.addOrUpdateGroupFormGroup.get("groupDescription").value || null,
    };

    return updateGroup;
  }

  private validateGroupName(): boolean {
    const name = this.addOrUpdateGroupFormGroup.get("name").value;
    return name !== null && name !== "";
  }

  onGroupFormSubmit({
    value,
    valid,
  }: {
    value: TaggerGroupRequestModel;
    valid: boolean;
  }) {
    if (!this.validateGroupName()) {
      this.addOrUpdateGroupFormGroup.get("name").invalid;
      this.addOrUpdateGroupFormGroup.get("name").errors.required;
      this.addOrUpdateGroupFormGroup.get("name").markAsDirty();
      return;
    }
    if (!valid) {
      return;
    } else {
      this.validateDuplicateGroupName();
      if (this.isDuplicateGroupName) {
        return;
      }
    }
  }

  // search on enter click
  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  quickSearch() {
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.refreshSearchResults(true);
    }
  }

  clearQuickSearch() {
    if (this.quickSearchValue == "") {
      this.refreshSearchResults(true);
    }
  }

  public refreshSearchResults(isRefresh: boolean): void {
    if (isRefresh) {
      this.groupLocationsGrid.instance.refresh().then(() => {
        if (
          this.groupLocationsGrid &&
          this.groupLocationsGrid.instance &&
          this.locationsByGroup
        ) {
          const locationsGuids = this.locationsByGroup.map((l) => l.locationGuid);
          this.groupLocationsGrid.instance.selectRows(locationsGuids, true);
        }
      });
    }
  }


  searchLocations() {
    this.locations.store = new CustomStore({
      key: "locationGuid",
      load: (loadOptions: any) => {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort
          ? JSON.stringify(loadOptions.sort)
          : "[{'selector':'name','desc':false}]";
        let gridParams: GridParams = {
          group: null,
          skip: skip,
          take: take,
          sort: sortOptions,
          isExport: loadOptions.isLoadingAll && loadOptions.isLoadingAll === true,
        };
        let request: any = {
          quickSearch: this.quickSearchValue,
          gridParams: gridParams,
        };

        return this.taggerService
          .searchGroupLocations(request)
          .toPromise()
          .then((response) => {
            if (response.isSuccess) {
              this.locations.data = response.result.searchResults;
              this.locations.totalCount = response.result.totalCount;

              return this.locations;
            } else {
              // Handle the error
              throw new Error("Data Loading Error");
            }
          })
          .catch((error) => {
            // Handle the error
            throw new Error("Data Loading Error");
          });
      },
    });
  }

  //searchLocations() {
  //  alert(this.quickSearchValue)
  //  //var quickSearchValue = this.quickSearchValue;
  //  //var taggerService = this.taggerService;
  //  //var locations = this.locations;

  //  this.locations.store = new CustomStore({
  //    key: "locationGuid",
  //    load: (loadOptions: any) => { 
  //      var skip = loadOptions.skip;
  //      var take = loadOptions.take;
  //      var sortOptions = loadOptions.sort
  //        ? JSON.stringify(loadOptions.sort)
  //        : "[{'selector':'name','desc':false}]";
  //      let gridParams: GridParams = {
  //        group: null,
  //        skip: skip,
  //        take: take,
  //        sort: sortOptions,
  //        isExport:
  //          loadOptions.isLoadingAll && loadOptions.isLoadingAll === true,
  //      };
  //      let request: any = {
  //        quickSearch: this.quickSearchValue,
  //        gridParams: gridParams,
  //      };

  //      return this.taggerService
  //        .searchGroupLocations(request)
  //        .toPromise()
  //        .then((response) => {
  //          if (response.isSuccess) {
  //            this.locations = response.result.searchResults;
  //            var obj: any = {
  //              data: response.result.searchResults,
  //              totalCount: response.result.totalCount,
  //            };
  //            return obj;
  //          } else {
  //            // thisRef.util.handleIsNotSuccess([ "Please try again" ]);
  //            throw "Data Loading Error";
  //          }
  //        })
  //        .catch((error) => {
  //          // thisRef.util.handleError(error);
  //          throw "Data Loading Error";
  //        });
  //    },
  //  });
  //}

  onSaveGroupLocations() {
    var grid = this.groupLocationsGrid.instance;

    if (this.groupLocationsGrid.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage(
        "ERROR",
        Constants.noLocationsSelected,
        MessageSeverity.error
      );
      return;
    }

    var selectedLocationGuids: Array<string> = [];

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedLocationGuids.push(grid.getSelectedRowKeys()[i]);
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: UpdateTaggerGroupLocationModel =
      this.getUpdateTaggerGroupLocationModel(selectedLocationGuids);
    this.taggerService.updateTaggerGroupLocations(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.updateTaggerGroupLocationsSuccessful,
            MessageSeverity.success
          );
          this.closePopup();
          this.onClose.next(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getUpdateTaggerGroupLocationModel(
    selectedLocationGuids: Array<string>
  ) {
    const updateGroup: UpdateTaggerGroupLocationModel = {
      groupGuid: this.groupGuid,
      locationGuids: selectedLocationGuids,
    };

    return updateGroup;
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
