<div class="tylie-accordion accordion" id="order-in-process-section">
  <div class="tylie-accordion__panel accordion-item" id="recentlyviewedPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#processingordersCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        Post Orders in Progress
      </button>
    </h2>
    <div id="processingordersCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar">
          <div class="d-flex">
            <button type="button" class="tylie-button tylie-button--xs me-2" (click)="onClickCompleteMultipleOrderProcessing()"><span>COMPLETE</span></button>
            <button type="button" class="tylie-button tylie-button--xs me-2" (click)="onClickReleaseMultipleOrdersClaimedForProcessing()"><span>CANCEL CLAIM</span></button>
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
          </div>

          <div class="pt-3">
            <form novalidate [formGroup]="orderSearchRequest" role="search">
              <div class="d-flex">
                <label class="tylie-form-group form-check me-5">
                  <input type="checkbox" class="form-check-input" formControlName="awaitingMediaOrdersOnly" name="awaitingMediaOrdersOnly" (change)="onClickCheckBox(orderSearchRequest)" />
                  <span class="form-check-label"> Awaiting Asset</span>
                </label>
                <label class="tylie-form-group form-check me-5">
                  <input type="checkbox" class="form-check-input" formControlName="approvalRejectedOrders" name="approvalRejectedOrders" (change)="onClickCheckBox(orderSearchRequest)" />
                  <span class="form-check-label">Asset Rejected</span>
                </label>
                <label class="tylie-form-group form-check me-5">
                  <input type="checkbox" class="form-check-input" formControlName="awaitingApprovalOrders" name="awaitingApprovalOrders" (change)="onClickCheckBox(orderSearchRequest)" />
                  <span class="form-check-label">Pending Approval</span>
                </label>
                <label class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="processingOnly" name="processingOnly" (change)="onClickCheckBox(orderSearchRequest)" />
                  <span class="form-check-label">Processing Only</span>
                </label>
              </div>
            </form>
          </div>
        </div>
        <div class="tylie-accordion__body-content">
          <dx-data-grid id="ordersInProcessing" class="tylie-grid"
                        #ordersInProcessing
                        [dataSource]="processingOrdersStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        [rowAlternationEnabled]="true"
                        (onRowPrepared)="onRowPrepared($event)"
                        [showBorders]="true">
            <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
            </dxo-selection>

            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="workOrder" caption="Order #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" cellTemplate="workOrderTemplate" width="7%"></dxi-column>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="9%"></dxi-column>
            <dxi-column dataField="spotLevelServices" caption="Services" cssClass="gridStyle tyl-nopadding tyl-grid-padding dx-datagrid-nowrap-remove" width="20%" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="claimedByUser" caption="Claimed By" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="orderBuiltBy" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="reviewCompletedDate" cellTemplate="dateTemplate" caption="Submitted" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
            <dxi-column dataField="status" caption="Status" cssClass="gridStyle tyl-nopadding" cellTemplate="thumbnailTemplate" width="10%"></dxi-column>
            <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>

            <div *dxTemplate="let t of 'workOrderTemplate'">
              <span class="quickLink" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
              <span><img *ngIf="t.data.isRush == true" src="./assets/svg/Icon-Rush.svg" class="ordersIcons" alt="rush"></span>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>

            <div *dxTemplate="let t of 'thumbnailTemplate'">
              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isApprovalRejected === true" class="OrderStatusRejectedThumbnail"
                  [src]="this.util.approvalRejectedThumbnailUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.approvalRejectedSpotsDisplay" triggers="mouseenter:mouseleave" />

              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isAwaitingApproval === true" class="OrderStatusApprovalThumbnail"
                  [src]="this.util.pendingApprovalThumbnailUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.awaitingApprovalSpotsDisplay" triggers="mouseenter:mouseleave" />

              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isAwaitingMedia === true" class="OrderStatusThumbnail"
                  [src]="this.util.awaitingMediaUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.awaitingMediaSpotsDisplay" triggers="mouseenter:mouseleave" />

              <span *ngIf="t.data.awaitingMediaOrder == null || t.data.awaitingMediaOrder == undefined || (t.data.awaitingMediaOrder.isAwaitingMedia === false && t.data.awaitingMediaOrder.isAwaitingApproval === false)"
                    class="tyl-padding">{{t.data.status}}</span>
            </div>

            <!--<div *dxTemplate="let data of 'finalReviewTemplate'">
      <div>{{data.value == true ? 'Yes' : 'No'}}</div>
    </div>-->

            <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      infoText="Page {0} of {1} ({2} items)"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>


