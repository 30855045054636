import { Component, Inject } from '@angular/core';
import { DBkeys } from '../../configurations/db-Keys';
import { MultiUserProfileModel, UserProfile, UserProfileSelectionModel } from '../../models/user/user.profile.model';
import { LocalStoreManager } from '../../services/core/local-store-manager.service';
import { Utilities } from "../../services/core/utilities";
import { UserProfileService } from '../../services/user/user-profile.service';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  SilentRequest,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../services/auth/auth.service';
import { RouteConstants } from '../../configurations/route-constants';
import { Router } from '@angular/router';
import { filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { Constants } from '../../configurations/constants';
import { User } from '../../models/user/user.model';
import { AppConfig, APP_CONFIG } from '../../app.module.config';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'profileselection',
  templateUrl: './profileselection.component.html',
  styleUrls: ['./profileselection.component.css']
})
export class ProfileSelectionComponent {
  public userName: string = '';
  public hasMultipleprofiles: boolean = false;
  public userProfileSelectionsList: UserProfileSelectionModel[] = [];
  public avatarEnconding = Constants.avatarEncoding;
  public loginStatusSubscription: any;

  private readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authMS: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public util: Utilities,
    private userProfileService: UserProfileService,
    private localStorage: LocalStoreManager,
    private authService: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document,
    private alertService: AlertService,
    private http: HttpClient) {

  }

  ngOnInit() {
    // Subscribe to MSAL broadcast to listen for login success event
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;

        if (payload && payload.account) {
          // Set the active account
          this.authMS.instance.setActiveAccount(payload.account);
          console.log(this.authService.activeUser);

          // Call acquireTokenSilentRequest to get the access token
          this.authService.acquireTokenSilentRequest()
            .pipe(finalize(() => { this.alertService.ShowLoader(false); }))
            .subscribe({
              next: (res: AuthenticationResult) => {
                console.log(res);
                const accessToken = res.accessToken;

                // Process the login response
                this.authService.processLoginResponse(res).subscribe(profileRes => {
                  console.log("multiUserProfileModel", profileRes);

                  if (profileRes != null && profileRes != undefined) {
                    profileRes as MultiUserProfileModel;

                    if (profileRes.hasMultipleProfiles === false) {
                      this.completeLoggingInSingleUserProfile(
                        profileRes.user.userProfile.userProfileGuid,
                        "",
                        []
                      );
                    } else if (profileRes.hasMultipleProfiles) {
                      this.hasMultipleprofiles = profileRes.hasMultipleProfiles;
                      this.userProfileSelectionsList = profileRes.userProfileSelectionModelList as UserProfileSelectionModel[];
                    }
                  } else {
                    this.alertService.showMessage("ERROR", Constants.noUserProfileCreated, MessageSeverity.error);
                  }
                });
              },
              error: (error) => {
                console.error("Error acquiring token silently:", error);
              }
            });
        }
      });

    // Check if there's an active account and acquire token silently if one exists
    const activeAccount = this.authService.activeUser;
    if (activeAccount) {
      this.authService.acquireTokenSilentRequest()
        .pipe(finalize(() => { this.alertService.ShowLoader(false); }))
        .subscribe({
          next: (res: AuthenticationResult) => {
            console.log(res);
            const accessToken = res.accessToken;

            // Process the login response
            this.authService.processLoginResponse(res).subscribe(profileRes => {
              console.log("multiUserProfileModel", profileRes);

              if (profileRes != null && profileRes != undefined) {
                profileRes as MultiUserProfileModel;

                if (profileRes.hasMultipleProfiles === false) {
                  this.completeLoggingInSingleUserProfile(
                    profileRes.user.userProfile.userProfileGuid,
                    "",
                    []
                  );
                } else if (profileRes.hasMultipleProfiles) {
                  this.hasMultipleprofiles = profileRes.hasMultipleProfiles;
                  this.userProfileSelectionsList = profileRes.userProfileSelectionModelList as UserProfileSelectionModel[];
                }
              } else {
                this.alertService.showMessage("ERROR", Constants.noUserProfileCreated, MessageSeverity.error);
              }
            });
          },
          error: (error) => {
            console.error("Error acquiring token silently:", error);
          }
        });
    }

    this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      if (this.getShouldRedirect()) {
        this.authService.redirectLoginUser();
      }
      else {

      }
    });
  }

  public getShouldRedirect() {
    return this.authService.activeUser != null;
  }

  private completeLoggingInSingleUserProfile(userProfileGuid, authIdentifier, roles:string []) {
    this.util.loginEvents(userProfileGuid, authIdentifier, roles);
    this.hasMultipleprofiles = false;
  }

  public onSelectProfile(userProfileGuid: string, authIdentifier: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.util.profileSelected = true;

    // save this to session storage
    this.completeLoggingInSingleUserProfile(userProfileGuid, "", []);
    this.authService.processProfileSelectLoginResponse(userProfileGuid).subscribe(
      (response) => {
        console.log("Profile selection response", response);
        this.alertService.ShowLoader(false);
        this.authService.redirectLoginUser();
      },
      (error) => {
        console.error("Error processing profile selection", error);
        this.alertService.ShowLoader(false); 
      }
    );
  }

  public logout() {
    this.authService.forceLogOut();
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
