import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';


@Component({
  selector: 'brandsearch',
  templateUrl: './brandsearch.component.html',
  styleUrls: ['./brandsearch.component.css'],
})
export class BrandSearchComponent implements OnInit {

  public dataSource: any = {};
  public quickSearchValue: string = null;
  public onClose: Subject<number[]>;

  @ViewChild('brandsGrid', { static: true }) brandSearchGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.searchBrands();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClearQuickSearch() {
    if (this.util.notEmpty(this.quickSearchValue) == false) {
      this.searchBrands();
    }
  }

  public onQuickSearchBrands() {
    if (this.util.notEmpty(this.quickSearchValue)) {
      this.searchBrands();
    }
  }

  public onEnterQuickSearch(e: any) {
    this.onQuickSearchBrands();
  }

  public onaddSelectedBrands() {
    this.publishSelectedRowKeys();
  }

  public searchBrands() {
    if (this.util.notEmpty(this.quickSearchValue)) {
      this.configService.searchCurrentBrands(this.quickSearchValue).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.dataSource = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
    else {
      this.configService.getCurrentBrands().subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.dataSource = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
  }

  private publishSelectedRowKeys() {
    var selectedRowKeys = this.brandSearchGrid.instance.getSelectedRowKeys();
    this.onClose.next(selectedRowKeys);
    this.closePopup();
  }
}
