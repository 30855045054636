
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { AlertService } from '../../../../services/core/alert.service';
import { Utilities } from '../../../../services/core/utilities';
import { TagValueChangedEvent } from '../../../../models/admin/client/customtagconfig.model';


@Component({
  selector: 'customtagconfiggroup',
  templateUrl: './customtagconfiggroup.component.html',
  styleUrls: ['./customtagconfiggroup.component.css'],
})
export class CustomTagConfigGroup implements OnInit {

  @Input() ctcGroup: FormGroup;
  @Input() parentForm: NgForm;
  @Input() groupName: number;
  @Input() formGroupcount: number;
  @Input() customTagDataTypes: any[]; // define datatype

  @Output() onTagValueChangedEventTriggered: EventEmitter<TagValueChangedEvent> = new EventEmitter<TagValueChangedEvent>();
  @Output() onClickRemoveCustomTagEventTriggered: EventEmitter<number> = new EventEmitter<number>();

  public isDataTypeConfigRequired: boolean = false;
  public displayDataType: string = "";
  public toolTipDetail: toolTip = { "type": "", "options": ["Option1", "Option2"]};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities) {


  }

  ngOnInit() {
    this.ctcGroup.get('tag').valueChanges.pipe(debounceTime(300),
      distinctUntilChanged(),)
      .subscribe(val => {
        var tagValChange = new TagValueChangedEvent();
        tagValChange.formGroupNumber = this.groupName;
        tagValChange.tag = val;

        if (val != null && val != undefined && val.trim().length > 0) {
          this.onTagValueChangedEventTriggered.emit(tagValChange);
        }
      });

    var dataTypeVal = this.ctcGroup.get('dataType').value;

    if (dataTypeVal != null && dataTypeVal != undefined && dataTypeVal > 0) {
      this.isConfigRequired(dataTypeVal);
    }
  }

  public onCustomTagDataTypeChange(e: any) {
    if (e == null || e == undefined) {
      return;
    }

    this.isConfigRequired(e.value);
  }

  public getDisplayDataTypeFromDataTypeValue(dataTypeId: number) {
    var dataType = this.customTagDataTypes.find(cd => cd.id == dataTypeId);
    this.displayDataType = dataType.name;
  }

  public onClickRemoveCustomTag() {
    this.onClickRemoveCustomTagEventTriggered.emit(this.groupName);
  }

  private isConfigRequired(dataType: number) {
    this.isDataTypeConfigRequired = false;

    var selectedDataType = this.customTagDataTypes.find(cd => cd.id == dataType);

    if (selectedDataType != undefined) {
      this.isDataTypeConfigRequired = selectedDataType.isConfigRequired;
    }

    this.toolTipDetail.type = selectedDataType.name;

    if (this.isDataTypeConfigRequired) {
      this.ctcGroup.get('config').setValidators([Validators.required]);
      this.ctcGroup.get('config').updateValueAndValidity();
    }
    else {
      this.ctcGroup.get('config').setValidators(null);
      this.ctcGroup.get('config').updateValueAndValidity();
    }
  }

  public onOpened(e: any) {
    e.component.option("items", []);
    e.component.option("opened", true);
    e.component.option("items", this.toolTipDetail.options);
  }
}

export class toolTip {
  public type: string;
  public options: any[];
}
