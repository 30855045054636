import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { RouteConstants } from "../../../configurations/route-constants";
import { downloadStatusEnum } from '../../../models/download/downloadmedia-status';
import { DxTreeViewComponent, DxDataGridComponent } from 'devextreme-angular';
import { DistributionService } from '../../../services/distribution/distribution.service';
import { Constants } from '../../../configurations/constants';
import { MessageSeverity, AlertService } from '../../../services/core/alert.service';

@Component({
  selector: 'tmc-download',
  templateUrl: './tmcdownload.component.html',
  styleUrls: ['./tmcdownload.component.css'],
})

export class TMCDownloadComponent implements OnInit {

  @ViewChild('tmcReleaseGrid', { static: true }) tmcReleaseGrid: DxDataGridComponent;

  //public windowsReleaseStore: any = [{ "tmcReleaseInfoId": 1, "displayName": "TMC msi v1.0", "releaseDate": "Jan. 29, 2021", "downloadPath": "/s3", "releaseInfoPath": "/s3" },
  //  { "tmcReleaseInfoId": 2, "displayName": "TMC zip v1.0", "releaseDate": "Jan. 29, 2021", "downloadPath": "/s3", "releaseInfoPath": "/s3" }];
  public windowsReleaseStore: any = null;
  public macReleaseStore: any = null;
  public linuxReleaseStore: any = null;
  public downloadingReleases: string[] = [];

  constructor(
    public util: Utilities,
    private router: Router,
    private distributionService: DistributionService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.getTmcReleaseInfo();
  }

  public gotoMedia() {
    this.router.navigate([RouteConstants.distributionRoute]);
  }

  public getTmcReleaseInfo() {
    this.distributionService.getTMCReleasesUrlEndPoint().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result;
        console.log(result);
        if (result != null) {
          this.windowsReleaseStore = result.windowsReleaseInfo;
          this.macReleaseStore = result.macReleaseInfo;
          this.linuxReleaseStore = result.linuxReleaseInfo;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onDownloadReleaseFile(data, isInstallFile: boolean) {
    var downloadPath = null;
    if (isInstallFile) {
      downloadPath = data.downloadPath
    }
    else {
      downloadPath = data.installationInfoPath
    }

    if (downloadPath != null) {
      this.downloadingReleases.push(data.tmcReleaseInfoId + downloadPath);
      this.distributionService.getTMCReleaseDownloadUrl({ Path: downloadPath }).subscribe((res: any) => {
        this.alertService.showMessage("SUCCESS", Constants.downloadInitiatedSimpleMessage, MessageSeverity.success);
        if (res.isSuccess == true) {
          var result = res.result;
          this.downloadFile(result.url, result.fileName, data.tmcReleaseInfoId + downloadPath);
        }
        else {
          this.resetuploadingPodDestinationIds(data.tmcReleaseInfoId + downloadPath);
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.resetuploadingPodDestinationIds(data.tmcReleaseInfoId + downloadPath);
          this.util.handleError(error);
        });
    }
  }

  private downloadFile(path, fileName, identifier) {
    this.util.downloadFile(path).subscribe((event: any) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(event);
      a.download = fileName;
      a.click();
      this.resetuploadingPodDestinationIds(identifier);
      this.alertService.showMessage("SUCCESS", Constants.downloadCompleted, MessageSeverity.success);
    },
      error => {
        console.log(error);
        this.resetuploadingPodDestinationIds(identifier);
        this.util.handleIsNotSuccess(['Error downloading the file']);
      });
  }

  private resetuploadingPodDestinationIds(identifier: string) {
    if (this.downloadingReleases.includes(identifier)) {
      this.downloadingReleases = this.downloadingReleases.filter(f => f != identifier);
    }
  }
}
