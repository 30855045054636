<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="row">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['spec'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Predefined Specs<span class="required">*</span></label>
      <dx-select-box [items]="predefinedSpecs" name="spec" formControlName="spec" placeholder="Choose Spec"></dx-select-box>
      <p class="p-extra-small">Please choose a Spec</p>
    </div>
  </div>

  <div class="tylie-form-group form-group">
    <label class="tylie-form-group__lbl">Custom File Name:  Paste your complete File Name in the first field or choose from drop down options (options will be separated by "-").  When not specified, system uses default file name "Ad ID-Title-Duration".</label>
    <div class="row">
      <div class="col-md-4">
        <dx-select-box class="tylie-select form-control" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos1" formControlName="customfilenamepos1" placeholder="Select">
        </dx-select-box>
      </div>

      <div class="col-md-4">
        <dx-select-box class="tylie-select form-control" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos2" formControlName="customfilenamepos2" placeholder="Select">
        </dx-select-box>
      </div>

      <div class="col-md-4">
        <dx-select-box class="tylie-select form-control" [items]="customFileNameOptions" [acceptCustomValue]="true" name="customfilenamepos3" formControlName="customfilenamepos3" placeholder="Select">
        </dx-select-box>
      </div>
    </div>
  </div>

  <div class="tylie-form-group form-group">
    <label class="tylie-form-group__lbl" for="comments">Comments<span class="required">*</span></label>
    <textarea name="comments" id="comments" class="tylie-textarea tylie-textarea--md form-control" formControlName="comments" rows="4"></textarea>
    <p class="p-extra-small">Please enter Comments</p>
  </div>
</div>
