import { Component, ViewChild, TemplateRef, Input, OnInit, ViewChildren, QueryList } from '@angular/core';

import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { SpecificationSearch } from '../../../models/admin/destination/destinationsearch.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { OrderUserDestination } from '../../../models/order/order-userdestination';
import { SearchUserDestinationRequest } from '../../../models/order/order-searchuserdestination-request.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranscodeListPopupComponent } from '../../popups/transcodelistpopup/transcodelistpopup.component';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RouteConstants } from '../../../configurations/route-constants';
import { UserRoles } from '../../../configurations/user-roles';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../configurations/constants';

@Component({
  selector: 'transcode-user-lists',
  templateUrl: './transcodeuserlists.component.html',
  styleUrls: ['./transcodeuserlists.component.css']
})

export class TranscodeUserListsComponent implements OnInit {

  public transcodesSavedListSource: Array<OrderUserDestination> = [];
  public olvSource: Array<any> = [];
  public modalRef: BsModalRef;
  public listType: string;
  public quickSearchValue: string = "";

  @ViewChild('olvGrid', { static: false }) olvGrid: DxDataGridComponent;
  @ViewChildren('listdeletepop') listdeletepop: QueryList<PopoverDirective>;

  constructor(
    private configService: ConfigService,
    public util: Utilities,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    public alertService: AlertService,
    private route: ActivatedRoute,
    public userRoles: UserRoles) {
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.listType = params['listType'];

      if (this.listType == 'saved')
        this.getUserLists();
      else if (this.listType == 'olv')
        this.getSpecLists();
    });
  }

  private getSpecLists() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getTranscodeSpecs().subscribe((res: any) => {
      if (res.isSuccess == true) {

        var specs = res.result as Array<any>;

        this.olvSource = specs;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getUserLists() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    let request: SearchUserDestinationRequest = { category: 'speclist', quickSearch: '' };

    this.configService.searchUserDestinations(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var destinations = res.result as Array<OrderUserDestination>;
        this.transcodesSavedListSource = destinations;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onCreateNewList() {
    var initialState = {
      transcodesSavedListToEdit: null,
      isEdit: false
    };

    this.modalRef = this.modalService.show(TranscodeListPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w70"));

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true)
        this.getUserLists();
    });
  }

  public onItemDelete(id: number) {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.deleteUserSavedDestinationList(id, "speclist").subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcodeorder_list_deleted, MessageSeverity.success);
        this.getUserLists();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.onHideDeleteListPop();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);

        this.onHideDeleteListPop();

        this.alertService.ShowLoader(false);
      });
  }

  public onItemEdit(id: number) {
    var itemToEdit = this.transcodesSavedListSource.find(t => t.id == id);

    var initialState = {
      transcodesSavedListToEdit: itemToEdit,
      isEdit: true
    };

    this.modalRef = this.modalService.show(TranscodeListPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w70"));

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true)
        this.getUserLists();
    });
  }

  public onGotoTranscodeFilesMain() {
    this.router.navigate([RouteConstants.transcodeFilesRoute]);
  }

  public onGotoDownload() {
    if (this.listType == 'olv')
      this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "olv" } });
    else
      this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "saved" } });
  }

  public onGotoUpload() {
    this.router.navigate([RouteConstants.mediaUploadRoute]);
  }

  public onQuickSearch() {
    this.olvGrid.instance.searchByText(this.quickSearchValue);
  }

  public onClearQuickSearch() {
    this.olvGrid.instance.searchByText("");
  }

  public onGotoMedia(section) {
    this.router.navigate([RouteConstants.mediaRoute], { queryParams: { t: section } });
  }

  public onHideDeleteListPop() {
    this.listdeletepop.forEach(p => {
      p.hide();
    });
  }

  ngOnDestroy() {
  }
}
