import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../../../services/auth/auth.service";
import { UserRoles } from "../../../configurations/user-roles";
import { AlertService } from '../../../services/core/alert.service';
import { OrderService } from "../../../services/order/order.service";
import { Utilities } from "../../../services/core/utilities";
import { UserProfileService } from "../../../services/user/user-profile.service";
import { RouteConstants } from "../../../configurations/route-constants";
import { CreateOrderConfig } from '../../../models/order/createorder-config.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { OrderFile } from '../../../models/order/order-create.model';
import { GlobalService } from '../../../services/core/global.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  public orderConfig: any;
  public draftConfig: any;
  public uploadedOrderConfig: any;
  public carrierSubmissionFailureConfig: any;
  public inProgressOrderConfig: any;
  public createOrderConfig: CreateOrderConfig;
  public modalRef: BsModalRef;

  constructor(
    private orderService: OrderService,
    public util: Utilities,    
    public userRoles: UserRoles,
    private alertService: AlertService,
    private router: Router) { }


  ngOnInit() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.orderService.getOrderHomeInitLoad().subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.orderConfig = res.result.orderConfig;
        this.inProgressOrderConfig = res.result.inProgressOrderConfig;
        this.draftConfig = res.result.draftOrderConfig;
        this.uploadedOrderConfig = res.result.uploadedOrderConfig;
        this.carrierSubmissionFailureConfig = res.result.carrierSubmissionFailureConfig;
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.orderConfig = {};
        this.draftConfig = {};
        this.inProgressOrderConfig = {};
        this.carrierSubmissionFailureConfig = {};
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public gotoCreateOrder() {
    this.router.navigate([RouteConstants.createorderRoute]);    
  }

  private addFileToHeader(newFile: OrderFile) {
  }

  private onSelectAttachmentCategorySubscriptionRaised(orderFile: OrderFile) {
    this.addFileToHeader(orderFile);
  }

  public gotoUploadOrder() {

    this.router.navigate([RouteConstants.ordersUpload]);
  }

}

