<div class="tylie-section px-0"  [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['standard'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl">Standard<span class="required">*</span></label>
    <div class="d-flex">
      <div class="form-check me-3">
        <input class="form-check-input" type="radio" name="standard" formControlName="standard" value="NTSC"><span class="form-check-label">NTSC</span>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="standard" formControlName="standard" value="PAL"><span class="form-check-label">PAL</span>
      </div>
    </div>
    <p class="p-extra-small">Please choose an option</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['title'].errors && f.submitted)}">
    <div *ngIf="applyAllMode === true">
      <label class="tylie-form-group__lbl">This below entry is disabled in Apply All Mode. Please edit later.</label>
    </div>
    <label class="tylie-form-group__lbl" for="title">Title for the new Asset<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="title" formControlName="title" [attr.disabled]="(applyAllMode === true || isDisabled === true) ? '' : null" />
    <p class="p-extra-small">Please enter Title</p>
  </div>
</div>
