<div class="tylie-panel tylie-panel--tab" [formGroup]="carton">
  <div class="tylie-panel__header tylie-panel__header--br-thick">
    <h4 class="tylie-panel__title">
      <span class="tylie-panel__tab">CARTON {{cartonSequence + 1}}</span>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onDeleteCarton()" *ngIf="isStagingRequest == false">
        <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>DELETE CARTON
      </button>
    </h4>
  </div>

  <div class="tylie-panel__body ">
    <h3 class="tylie-type-h3">CARTON WAREHOUSE LOCATION</h3>

    <div class="row">
      <div class="col-md-2">
        <div class="tylie-form-group form-group" [ngClass]="{error: carton.controls['cartonColumn'].errors && triedToSubmitRequest }">
          <label class="tylie-form-group__lbl" for="cartonColumn">Column<span class="required">*</span></label>
          <input type="text" id="cartonColumn" name="cartonColumn" class="tylie-text form-control" formControlName="cartonColumn" *ngIf="isStagingRequest == false">
          <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{carton.controls['cartonColumn'].value}}</div>
          <p class="p-extra-small" *ngIf="carton.controls['cartonColumn'].errors?.required">Please enter Column</p>
        </div>
      </div>
      <div class="col-md-2">
        <div class="tylie-form-group form-group" [ngClass]="{error: carton.controls['cartonPosition'].errors && triedToSubmitRequest }">
          <label class="tylie-form-group__lbl" for="cartonPosition">Position<span class="required">*</span></label>
          <input type="text" id="cartonPosition" name="cartonPosition" class="tylie-text form-control" formControlName="cartonPosition" *ngIf="isStagingRequest == false">
          <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{carton.controls['cartonPosition'].value}}</div>
          <p class="p-extra-small" *ngIf="carton.controls['cartonPosition'].errors?.required">Please enter Position</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-form-group form-group" [ngClass]="{error: carton.controls['cartonBarcode'].errors && triedToSubmitRequest }">
          <label class="tylie-form-group__lbl" for="cartonBarcode">Carton Barcode<span class="required">*</span></label>
          <input type="text" id="cartonBarcode" name="cartonBarcode" class="tylie-text form-control" formControlName="cartonBarcode" *ngIf="isStagingRequest == false">
          <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{carton.controls['cartonBarcode'].value}}</div>
          <p class="p-extra-small" *ngIf="carton.controls['cartonBarcode'].errors?.required">Please enter Carton Barcode</p>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <h3 class="tylie-type-h3">ASSETS IN THIS CARTON</h3>
    </div>

    <ng-container *ngFor="let asset of carton.get('assets')['controls']; let i = index">
      <div class="row" [formGroup]="asset">
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: asset.controls['assetFormat'].errors && triedToSubmitRequest}">
            <label class="tylie-form-group__lbl" for="format">Asset Format<span class="required">*</span></label>
            <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{asset.controls['assetFormatName'].value}}&nbsp;</div>
            <dx-select-box *ngIf="isStagingRequest == false"
                          [dataSource]="formatDataSource"
                          id="id"
                          name="assetFormat"
                          class="tylie-select form-control"
                          formControlName="assetFormat"
                          [searchEnabled]="true"
                          [showClearButton]="true"
                          valueExpr="id"
                          displayExpr="name"
                          placeholder="Select a format...">
            </dx-select-box>
            <p class="p-extra-small">Please select Asset Format</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: asset.controls['assetBarcode'].errors && triedToSubmitRequest }">
            <label class="tylie-form-group__lbl" for="assetBarcode">Asset Barcode<span class="required">*</span></label>
            <input type="text" id="assetBarcode" name="assetBarcode" class="tylie-text form-control" formControlName="assetBarcode" *ngIf="isStagingRequest == false">
            <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{asset.controls['assetBarcode'].value}}</div>
            <p class="p-extra-small" *ngIf="asset.controls['assetBarcode'].errors?.required">Please enter Asset Barcode</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="assetDescription">Asset Description<span class="required">*</span></label>
            <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{asset.controls['assetDescription'].value}}</div>
            <input type="text" id="assetDescription" name="assetDescription" class="tylie-text form-control" formControlName="assetDescription" *ngIf="isStagingRequest == false">
          </div>
        </div>
        <div class="col-md-3" *ngIf="isStagingRequest == true">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="assetDescription">Asset Status<span class="required">*</span></label>
            <div class="tylie-form-group__txt" *ngIf="isStagingRequest == true">{{asset.controls['vaultAssetStatus'].value}}</div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="isStagingRequest == false">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="actions">Actions<span class="required">*</span></label>
            <div>
              <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onDeleteAsset(i)"><icon-delete color="#1e90ff"></icon-delete></button>
            </div>
          </div>
        </div>
      </div>
      
      <table class="tylie-table table">
        <thead class="tylie-table__head">
          <tr>
            <th style="width:5%;">Seq.</th>
            <th style="width:20%;">Asset-ID</th>
            <th style="width:20%;">Length</th>
            <th style="width:50%;">Title</th>
            <th style="width:5%;"></th>
          </tr>
        </thead>
        <tbody class="tylie-table__body">
          <ng-container *ngFor="let att of asset.controls['assetLineItemData'].controls; let j = index">
            <ng-container [formGroup]="att">
              <tr>
                <td style="width:5%;" class="tyl-field-underline1">{{j+1}}</td>
                <td style="width:20%;" class="tyl-field-underline1">
                  <div class="form-group" [ngClass]="{error: att.controls['adId'].errors && triedToSubmitRequest }">
                    <input type="text" name="adId" class="form-control" formControlName="adId" *ngIf="isStagingRequest == false">
                    <p class="p-extra-small" *ngIf="att.controls['adId'].errors">Please enter a valid Asset-ID</p>
                    <p class="black" *ngIf="isStagingRequest == true">{{att.controls['adId'].value}}&nbsp;</p>
                  </div>
                </td>
                <td style="width:20%;" class="tyl-field-underline1">
                  <div class="form-group" [ngClass]="{error: att.controls['length'].errors && triedToSubmitRequest }">
                    <input type="text" name="length" class="form-control" formControlName="length" *ngIf="isStagingRequest == false">
                    <p class="p-extra-small" *ngIf="att.controls['length'].errors">Please enter a valid length (Ex: 60, 3:45, 1:20:30)</p>
                    <p class="black" *ngIf="isStagingRequest == true">{{att.controls['length'].value}}&nbsp;</p>
                  </div>
                </td>
                <td style="width:50%;" class="tyl-field-underline1">
                  <div class="form-group" [ngClass]="{error: att.controls['title'].errors && triedToSubmitRequest }">
                    <input type="text" name="title" class="form-control" formControlName="title" *ngIf="isStagingRequest == false">
                    <p class="p-extra-small" *ngIf="att.controls['title'].errors">Please enter a valid Title</p>
                    <p class="black" *ngIf="isStagingRequest == true">{{att.controls['title'].value}}&nbsp;</p>
                  </div>
                </td>
                <td style="width:5%;" align="left" *ngIf="isStagingRequest == false">
                  <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onDeleteAssetLineItem(i,j)"><icon-delete color="#1e90ff"></icon-delete></button>
                  <!--<i class="glyphicon glyphicon-remove-sign tyl-input-delete-vertical-align" (click)="onDeleteAssetLineItem(i,j)"></i>-->
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <div *ngIf="isStagingRequest == false">
        <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="onAddNewAssetLine(i)">
          <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
          ADD MORE LINES
        </button>
      </div>
    </ng-container>

    <div *ngIf="isStagingRequest == false">
      <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="onAddNewAsset()">
        <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
        ADD ANOTHER ASSET
      </button>
    </div>
  </div>
</div>


