<div id="select-services">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
      <ng-container *ngIf="additionalDataMode === false && editMode === false">
        <form [formGroup]="spotSelectedServicesRequest" #f="ngForm" (ngSubmit)="onSaveSelectServices(spotSelectedServicesRequest)">
          <div class="row">
            <div class="col-md-6" *ngIf="deliveryServicesDataSource.length > 0">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="delivery">Delivery<span class="required">*</span></label>
                <dx-drop-down-box [(value)]="deliveryServicesValue"
                                  formControlName="deliveryServices"
                                  id="deliveryServices"
                                  class="tylie-select form-control"
                                  valueExpr="id"
                                  displayExpr="name"
                                  (onValueChanged)="syncTreeViewSelection('delivery')"
                                  [dataSource]="deliveryServicesDataSource">
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="deliveryServicesDataSource"
                                  dataStructure="plain"
                                  keyExpr="id"
                                  #treeDelivery
                                  parentIdExpr="parentId"
                                  selectionMode="single"
                                  displayExpr="name"
                                  showCheckBoxesMode="normal"
                                  [selectByClick]="true"
                                  (onItemRendered)="onItemRendered('delivery', $event)"
                                  (onContentReady)="syncTreeViewSelection('delivery', $event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event, 'delivery')">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please Select Delivery</p>
              </div>
            </div>
            <div class="col-md-6" *ngIf="addlServicesDataSource.length > 0">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="additionalservices">Additional Services<span class="required">*</span></label>
                <dx-drop-down-box [(value)]="addlServicesValue"
                                  formControlName="addlServices"
                                  id="addlServices"
                                  class="tylie-select form-control"
                                  valueExpr="id"
                                  displayExpr="name"
                                  (onValueChanged)="syncTreeViewSelection('addl')"
                                  [dataSource]="addlServicesDataSource">
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="addlServicesDataSource"
                                  dataStructure="plain"
                                  keyExpr="id"
                                  #treeAddl
                                  parentIdExpr="parentId"
                                  selectionMode="multiple"
                                  showCheckBoxesMode="normal"
                                  displayExpr="name"
                                  [selectByClick]="true"
                                  (onItemRendered)="onItemRendered('addl', $event)"
                                  (onContentReady)="syncTreeViewSelection('addl', $event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event, 'addl')">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please Select Additional Services</p>
              </div>
            </div>
            <div class="col-md-6" *ngIf="ppServicesDataSource.length > 0">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="postproduction">Post Production<span class="required">*</span></label>
                <dx-drop-down-box [(value)]="ppServicesValue"
                                  formControlName="ppServices"
                                  id="ppServices"
                                  class="tylie-select form-control"
                                  valueExpr="id"
                                  displayExpr="name"
                                  (onValueChanged)="syncTreeViewSelection('pp')"
                                  [dataSource]="ppServicesDataSource">
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="ppServicesDataSource"
                                  dataStructure="plain"
                                  keyExpr="id"
                                  #treePp
                                  parentIdExpr="parentId"
                                  selectionMode="multiple"
                                  showCheckBoxesMode="normal"
                                  displayExpr="name"
                                  [selectByClick]="true"
                                  (onItemRendered)="onItemRendered('pp', $event)"
                                  (onContentReady)="syncTreeViewSelection('pp', $event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event, 'pp')">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please Select Post Productions</p>
              </div>
            </div>
            <div class="col-md-6" *ngIf="spotLevel == true">
              <div class="tylie-form-group form-check mt-5" *ngIf="orderEditMode == false">
                <input class="form-check-input" type="checkbox" formControlName="applyToAllMedia" name="service-to-all-media" id="service-to-all-media" [attr.disabled]="(applyAllMode == true || editModeWithUpdateService == true) ? '': null">
                <label for="service-to-all-media" class="form-check-label">Apply Selected Services to All Assets</label>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs" type="submit">{{editModeWithUpdateService == true ? 'UPDATE SERVICES' : 'SELECT SERVICES'}}</button>
          </div>
        </form>
      </ng-container>

      <div *ngIf="editMode === true && showInEditMode === true">
        <div class="col-md-3">
          <button class="tylie-button tylie-button--xs mb-3" type="button" (click)="onAddNewService()">ADD NEW SERVICE</button><br />
        </div>
        <h3 class="tylie-type-h3 br-b pb-2">
          Edit or delete the existing services for this Assets below:
        </h3>
      </div>

      <h3 class="tylie-type-h3 br-b pb-2" *ngIf="editMode === false && additionalDataMode === true">
        Some of your selected services require additional information listed below.
      </h3>

      <service-metadata-wrapper *ngIf="additionalDataMode === true"
                                (optionsSave)='onServiceSubmitOptions($event)'
                                [editMode]="editMode"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [orderGuid]="orderGuid"
                                [showInEditMode]="showInEditMode"
                                [specialServiceMode] = "specialServiceMode"
                                [applyAllMode]="applyAllMode"
                                [additionalMetadata] = "additionalMetadata"
                                [servicesOptional] = "servicesOptional"
                                [services]="servicesForAdditionalData">
      </service-metadata-wrapper>
   
  </div>
</div>
