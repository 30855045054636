<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="canDelete === true" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['clientId'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="clientId">Client ID<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="clientId" formControlName="clientId" />
    <p class="p-extra-small">Please enter Client ID</p>
  </div>
</div>
