export class ShareMediaFileModel {
  spotFiles: string[];
  name: string;
  subject: string;
  comments: string;
  password: string;
  isPasswordRequired: boolean;
  isUserCopied: boolean;
  isDownloadable: boolean;
  expiryInDays: number;
  limitViews: number;
  emailTo: Array<string>;
  playlistGuid?: string;
  isApproval: boolean;
  approvalLevel: number;
  isMasterFileLink: boolean;
  downloadsPermitted: number;
  isProxyIncluded: boolean;
  isMasterShare: boolean;
  nonbillable: boolean;
  isMasterShareInternalUser: boolean;

  constructor(
    isApproval: boolean, approvalLevel: number,
    spotFiles: string[], name: string,
    subject: string, comments: string,
    password: string, isPasswordRequired: boolean, isUserCopied: boolean,
    isDownloadable: boolean, expiryInDays: number, limitViews: number, emailTo: Array<string>, isMasterFileLink: boolean,
    downloadsPermitted: number, isProxyIncluded: boolean, isMasterShare: boolean, nonbillable: boolean, isMasterShareInternalUser: boolean,
    playlistGuid?: string) {
    this.isApproval = isApproval;
    this.approvalLevel = approvalLevel;
    this.spotFiles = spotFiles;
    this.name = name;
    this.subject = subject;
    this.comments = comments;
    this.password = password;
    this.isPasswordRequired = isPasswordRequired;
    this.isUserCopied = isUserCopied;
    this.isDownloadable = isDownloadable;
    this.expiryInDays = expiryInDays;
    this.limitViews = limitViews;
    this.emailTo = emailTo;
    this.isMasterFileLink = isMasterFileLink;
    this.downloadsPermitted = downloadsPermitted;
    this.isProxyIncluded = isProxyIncluded;
    this.isMasterShare = isMasterShare;
    this.playlistGuid = playlistGuid;
    this.nonbillable = nonbillable;
    this.isMasterShareInternalUser = isMasterShareInternalUser;
  }
}
