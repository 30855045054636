<header class="tylie-header">
  <h3 class="tylie-header__title">Destinations</h3>
</header>

<div class="tylie-body">
  <ng-container secured [accessControlListComponent]="[this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminSystemsRoleName()]">
    <div class="tylie-body__bar">
      <button type="button" class="tylie-button tylie-button--icon" (click)="gotoCreateNewDestination()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">CREATE NEW DESTINATION</span>
      </button>
    </div>
  </ng-container>

  <search-destinations secured [accessControlListComponent]="[this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminSystemsRoleName()]">

  </search-destinations>
</div>

