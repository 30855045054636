<div id="clientbrandproductgroup">
  <div [formGroup]="cbpGroup">
    <div class="row">
      <div class="col-md-6">
        <div class="tylie-form-group form-group" [ngClass]="{error: (cbpGroup.controls['id'].errors && (!cbpGroup.controls['id'].pristine || parentForm.submitted))}">
          <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
          <dx-select-box [dataSource]="brandProductDataSource"
                         name="id" id="id" class="tylie-select form-control"
                         valueExpr="id"
                         [showClearButton]="true"
                         displayExpr="name"
                         formControlName="id"
                         placeholder="Select a product..."
                         [visible]="cbpGroup.controls['id'].value == null"
                         (onValueChanged)="onProductSeleceted($event)">
          </dx-select-box>
          <p class="p-extra-small">Please Select a Product</p>
        </div>
        <div class="form-group " *ngIf="cbpGroup.controls['name'].value != null">
          <!--<label for="product">product<span class="required">*</span></label>-->
          <div><span>{{cbpGroup.controls['name'].value}}</span></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="formGroupcount > 1" (click)="oncbpGroupRemove()">
            <icon-trash-can color="#1e90ff"></icon-trash-can>
          </a>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
      <div class="col-md-6">
        <label class="tylie-form-group form-check">
          <input class="form-check-input" type="checkbox" formControlName="isTrafficEmailInPODDisplayed" name="isTrafficEmailInPODDisplayed" id="isTrafficEmailInPODDisplayed">
          <span class="form-check-label">Display Traffic Email In POD</span>
        </label>
      </div>
    </div>

    <div class="row" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
      <div class="col-md-12">
        <div class="tylie-form-group form-group" [ngClass]="{error: cbpGroup.controls['podEmails'].hasError('inValidEmailList') && (!cbpGroup.controls['podEmails'].pristine || parentForm.submitted)}">
          <label class="tylie-form-group__lbl" for="podEmails">
            POD Recipients (via Email)<span class="required">*</span>
          </label>
          <textarea id="podEmails" name="podEmails" class="tylie-textarea tylie-textarea--md form-control" formControlName="podEmails" placeholder="semi-colon seperated"></textarea>
          <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
      <div class="col-md-3">
        <div class="tylie-form-group form-group" [ngClass]="{error: cbpGroup.controls['stdStorageDays'].hasError('inValidAliasId') && (!cbpGroup.controls['stdStorageDays'].pristine || parentForm.submitted)}">
          <label class="tylie-form-group__lbl" for="StdStorageDays">Standard<span class="required">*</span></label>
          <label class="tylie-form-group__slbl" for="StdStorageDays1">(Minimum # of days)<span class="required">*</span></label>
          <input formControlName="stdStorageDays" type="text" id="stdStorageDays" name="stdStorageDays" class="tylie-text form-control " />
          <p class="p-extra-small">should be numeric value</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-form-group form-group" [ngClass]="{error: cbpGroup.controls['infrqStorageDays'].hasError('inValidAliasId') && (!cbpGroup.controls['infrqStorageDays'].pristine || parentForm.submitted)}">
          <label class="tylie-form-group__lbl" for="InfrqStorageDays">Infrequent Access<span class="required">*</span></label>
          <label class="tylie-form-group__slbl" for="InfrqStorageDays1">(Minimum # of days)<span class="required">*</span></label>
          <input formControlName="infrqStorageDays" type="text" id="infrqStorageDays" name="infrqStorageDays" class="tylie-text form-control " />
          <p class="p-extra-small">should be numeric value</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-form-group form-group" [ngClass]="{error: cbpGroup.controls['archiveDurationInDays'].hasError('inValidAliasId') && (!cbpGroup.controls['archiveDurationInDays'].pristine || parentForm.submitted)}">
          <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Archive<span class="required">*</span></label>
          <label class="tylie-form-group__slbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
          <input formControlName="archiveDurationInDays" type="text" id="archiveDurationInDays" name="archiveDurationInDays" class="tylie-text form-control " />
          <p class="p-extra-small">should be numeric value</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-form-group form-group" [ngClass]="{error: cbpGroup.controls['activeTandemStorageInDays'].hasError('inValidAliasId') && (!cbpGroup.controls['activeTandemStorageInDays'].pristine || parentForm.submitted)}">
          <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Active Tandem Storage<span class="required">*</span></label>
          <label class="tylie-form-group__slbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
          <input formControlName="activeTandemStorageInDays" type="text" id="activeTandemStorageInDays" name="activeTandemStorageInDays" class="tylie-text form-control " />
          <p class="p-extra-small">should be numeric value</p>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCCandBT == true))">
      <div class="col-md-6" id="costCenters">
        <div class="tylie-form-group form-group">
          <label for="costCenter" class="tylie-form-group__lbl">Cost Center</label>
          <div formArrayName="costCenter" *ngFor="let costCenterGroup of formCostCentersArray.controls; let j = index">
            <div class="mb-2" [formGroupName]="j"
                [ngClass]="{error: (formCostCentersArray.controls[j].controls['costCenter'].errors && (!formCostCentersArray.controls[j].controls['costCenter'].pristine || parentForm.submitted))}">
              <div class="d-flex align-items-center">
                <input type="text" id="costCenter" name="costCenter" class="tylie-text form-control" formControlName="costCenter">
                <a class="tylie-lnk ms-2" *ngIf="formCostCentersArray.controls?.length > 1" (click)="onCostCenterRemove(i)"><icon-expire color="#1e90ff"></icon-expire></a>
              </div>
              <p class="p-extra-small" *ngIf="formCostCentersArray.controls[j].controls['costCenter'].errors?.pattern">Cost Center must not include semi colon (;)</p>
              <p class="p-extra-small" *ngIf="formCostCentersArray.controls[j].controls['costCenter'].errors?.maxlength">
                Cost Center can have a maximum of 35 characters.
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onCostCenterAddMore()">
            <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
            ADD MORE
          </button>
        </div>
      </div>

      <div class="col-md-6" id="billTos">
        <div class="tylie-form-group form-group">
          <label for="billTo" class="tylie-form-group__lbl">Bill To</label>
          <div formArrayName="inVoiceTo" *ngFor="let billToGroup of formbillTosArray.controls; let j = index">
            <div class="mb-2" [formGroupName]="j"
                [ngClass]="{error: (formbillTosArray.controls[j].controls['billTo'].errors && (!formbillTosArray.controls[j].controls['billTo'].pristine || parentForm.submitted))}">
              <div class="d-flex align-items-center">
                <input type="text" id="billTo" name="billTo" class="tylie-text form-control" formControlName="billTo">
                <a class="tylie-lnk ms-2" *ngIf="formbillTosArray.controls?.length > 1" (click)="onBillToRemove(i)"><icon-expire color="#1e90ff"></icon-expire></a>
              </div>
              <p class="p-extra-small">Bill To must not include semi colon (;)</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onBillToAddMore()">
            <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
              ADD MORE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

