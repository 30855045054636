<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Are you sure ?</h4>
</div>
<div class="modal-body">
  <form [formGroup]="confirmRequest" #cp="ngForm" (ngSubmit)="onConfirm(confirmRequest)" novalidate>
    <div id="confirmNotesDiv" class="tylie-form-group form-group">
      <label class="tylie-form-group__lbl" for="confirmNotes">Notes<span class="required">*</span></label>
      <textarea name="confirmNotes" formControlName="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
      <p class="p-extra-small">Please enter notes</p>
    </div>
    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="submit">Yes</button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">No</button>
    </div>
  </form>
</div>
