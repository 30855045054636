<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['phone'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="phone">Tag Field 1<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="phone" formControlName="phone" />
    <p class="p-extra-small">Please enter phone</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['url'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="url">Tag Field 2<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="url" formControlName="url" />
    <p class="p-extra-small">Please enter url</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['voiceOver'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="voiceOver">Voice Over<span class="required">*</span></label>
    <textarea name="voiceOver" id="voiceOver" class="tylie-textarea tylie-textarea--md form-control" formControlName="voiceOver"></textarea>
    <p class="p-extra-small">Please enter voice over</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['otherInstructions'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="otherins">Other Instructions<span class="required">*</span></label>
    <textarea name="otherins" id="otherins" class="tylie-textarea tylie-textarea--md form-control" formControlName="otherInstructions" rows="4"></textarea>
    <p class="p-extra-small">Please enter instructions</p>
  </div>
</div>
