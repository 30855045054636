import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from '../../../services/core/utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'nav-header',
  templateUrl: './navheader.component.html',
  styleUrls: ['./navheader.component.css']
})
export class NavHeaderComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() link: string;
  @Input() linkRoute: any;

  constructor(public util: Utilities, private router: Router) {
  }

  ngOnInit() {    
  }

  onLinkClick() {
    this.router.navigate([this.linkRoute]);
  }
}
