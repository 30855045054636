<svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66.59 65.1"
    [attr.height]="height" [attr.width]="width"
>
    <g id="Layer_1-2" [attr.fill]="color">
        <g>
            <path
                d="m28.34,13.52c-1.27,1.27-2.05,3.02-2.05,4.95s.78,3.69,2.05,4.95c1.27,1.27,3.02,2.05,4.96,2.05s3.69-.78,4.96-2.05c1.26-1.26,2.05-3.01,2.05-4.95s-.78-3.69-2.05-4.95c-1.27-1.27-3.02-2.05-4.95-2.05s-3.69.78-4.95,2.05h0Zm18.3-7.99c3.41,3.41,5.53,8.13,5.53,13.35,0,3.73-2.53,9.36-5.77,15.03-3.33,5.82-7.38,11.63-10.31,15.63-.33.44-.75.8-1.23,1.04-.47.24-1.01.37-1.56.37s-1.08-.13-1.56-.37c-.48-.24-.91-.6-1.23-1.04-2.93-4-6.98-9.81-10.31-15.63-3.24-5.67-5.77-11.3-5.77-15.03,0-5.21,2.11-9.93,5.53-13.34,3.41-3.41,8.13-5.53,13.34-5.53s9.93,2.11,13.34,5.53Z"
            />
            <path
                d="m49.82,36.65c2.4.61,4.6,1.35,6.57,2.2,6.3,2.72,10.2,6.62,10.2,11.05s-3.9,8.33-10.2,11.05c-5.93,2.56-14.1,4.15-23.09,4.15s-17.16-1.58-23.09-4.15c-6.3-2.72-10.2-6.62-10.2-11.05s3.9-8.33,10.2-11.05c1.96-.85,4.17-1.59,6.57-2.2.5.87,1.02,1.74,1.55,2.6-2.57.61-4.92,1.37-6.97,2.26-5.22,2.25-8.45,5.23-8.45,8.4s3.23,6.14,8.45,8.4c5.59,2.42,13.35,3.91,21.95,3.91s16.35-1.49,21.95-3.91c5.22-2.25,8.45-5.23,8.45-8.4s-3.23-6.14-8.45-8.4c-2.05-.89-4.4-1.65-6.97-2.26.53-.86,1.04-1.73,1.55-2.6Z"
            />
        </g>
    </g>
</svg>
