import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { VaultFile } from '../../../models/vault/vault-create.model';
import { UploadVaultAssetFileRequest, UploadVaultFileRequest } from '../../../models/vault/vault-upload.model';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { UploadOrderFileService } from '../../../services/order/uploadorderfile.service';
import { UploadVaultFileService } from '../../../services/vault/uploadvaultfile.service';
import { VaultService } from '../../../services/vault/vault.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-add-vault-asset-attachment-popup',
  templateUrl: './add-vault-asset-attachment-popup.component.html',
  styleUrls: ['./add-vault-asset-attachment-popup.component.css']
})
export class AddVaultAssetAttachmentPopupComponent extends BasePopupComponent {

  @Input('vaultAssetId') vaultAssetId: number;
  @Input('vaultAssetGuid') vaultAssetGuid: string;


  @Input('vaultRequestId') vaultRequestId: number;
  @Input('vaultRequestGuid') vaultRequestGuid: string;

  @Input('vaultDigitalAssetLineItemId') vaultDigitalAssetLineItemId: number;
  @Input('vaultDigitalAssetLineItemGuid') vaultDigitalAssetLineItemGuid: string;
  @Input('isVaultDigitalAsset') isVaultDigitalAsset: boolean;

  @Input('fileType') fileType: string;

  @ViewChild('selectedFile', { static: false }) selectedFile: ElementRef;

  public onSelectAttachmentVault: Subject<VaultFile>;
  public attchmentCategoryRequest: FormGroup;
  public isUploading: boolean = false;
  public attachedFiles: Array<VaultFile> = [];
  public errorMsg: string = '';
  public acceptFileTypes: string = "*";

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private uploadVaultFileService: UploadVaultFileService
  ) {
    super();
    this.onSelectAttachmentVault = new Subject();
  }

  ngOnInit() {
    super.ngOnInit();
    this.attchmentCategoryRequest = new FormGroup({
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onAttachFile(event: any) {

    if (event.target.files && event.target.files.length > 0) {

      for (const uploadFile of event.target.files) {
        if (this.acceptFileTypes != '*' && this.acceptFileTypes.indexOf(uploadFile.type) < 0) {
          this.isUploading = false;
          this.selectedFile.nativeElement.value = "";
          this.errorMsg = uploadFile.name + ' is not acceptable file type.'
          return;
        }
      }

      for (const uploadFile of event.target.files) {
        var request: UploadVaultAssetFileRequest = {
          fileType: this.fileType,
          fileName: uploadFile.name,
          description: "",
          vaultAssetGuid: this.vaultAssetGuid,
          vaultAssetId: this.vaultAssetId,
          vaultRequestGuid: this.vaultRequestGuid,
          vaultRequestId: this.vaultRequestId,
          vaultDigitalAssetLineItemGuid: this.vaultDigitalAssetLineItemGuid,
          vaultDigitalAssetLineItemId: this.vaultDigitalAssetLineItemId,
          isVaultDigitalAsset: this.isVaultDigitalAsset,
          vaultDestinationId: 0
        };

        this.isUploading = true;

        this.uploadVaultFileService.uploadVaultAssetFile(request, uploadFile).pipe(
          finalize(() => {
            this.selectedFile.nativeElement.value = "";
            this.isUploading = false;
          }))
          .subscribe((newFile: any) => {
            if (newFile != null && newFile != undefined) {
              this.attachedFiles.push(newFile);
              this.onSelectAttachmentVault.next(newFile);
            }
          }, error => {
            this.closePopup();
            this.util.handleError(error);
          });
      }
    }
    else
      this.isUploading = false;
  }

}
