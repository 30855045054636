<div class="panel-body row">
  <div class="col-md-12">
    <div class="tylie-panel tylie-panel--secondary">
      <div class="tylie-panel__header tylie-panel__header--br-thick">
        <h4 class="tylie-panel__title">
          YOUR AD
        </h4>
      </div>
      <div class="tylie-panel__body">
        <div class="tylie-type-body mb-3" *ngIf="taggerName != '' && ((requestOrigination != 'CreativeTags')
             || (requestOrigination === 'CreativeTags' && currentStep != adCreatorStepEnum.selectOfferCard))">
          {{ taggerName }}<i
            class="tylicon tylicon-editpencil"
            outsideClick="true"
            role="button"
            placement="top"
            #taggernamepop="bs-popover"
            container="body"
            [popover]="taggerNameTemplate"
            (click)="stopTogglePropogation($event)"
          >
          </i>
          <div
            *ngIf="
              topFormGroup.get('name').invalid &&
              (topFormGroup.get('name').dirty ||
                topFormGroup.get('name').touched)
            "
          >
            <div class="error" *ngIf="topFormGroup.get('name').errors.required">
              Name is required.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" *ngFor="let selectedBaseCreative of selectedBaseCreatives">
            <div class="tylie-card">
              <div class="tylie-card__img-wrap">
                <img
                  class="tylie-card__img"
                  [src]="selectedBaseCreative.thumbnailDisplayUrl"
                  alt="Image"
                />
              </div>
              <div class="tylie-card__body">
                <label class="tylie-card__title">
                  <icon-checkmark-green></icon-checkmark-green> Base Creative:
                </label>
                <div class="tylie-data tylie-data--block">
                  <span class="tylie-data__txt">{{
                    selectedBaseCreative.creativeName
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3" *ngFor="let selectedOfferCard of selectedOfferCards">
            <div class="tylie-card">
              <div class="tylie-card__img-wrap">
                <img
                  class="tylie-card__img"
                  [src]="selectedOfferCard.cardDisplayUrl"
                  alt="Image"
                />
              </div>
              <div class="tylie-card__body">
                <Label class="tylie-card__tile"
                  ><icon-checkmark-green></icon-checkmark-green> Offer Tag:</Label
                >
                <div class="tylie-data tylie-data--block">
                  <span class="tylie-data__txt">{{ selectedOfferCard.cardName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3" *ngFor="let selectedEndCard of selectedEndCards">
            <div class="tylie-card">
              <div class="tylie-card__img-wrap">
                <img
                  class="tylie-card__img"
                  [src]="selectedEndCard.cardDisplayUrl"
                  alt="Image"
                />
              </div>
              <div class="tylie-card__body">
                <Label class="tylie-card__title"
                  ><icon-checkmark-green></icon-checkmark-green> End Tag:</Label
                >
                <div class="tylie-data tylie-data--block">
                  <span class="tylie-data__txt">{{ selectedEndCard.cardName }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3" *ngFor="let selectedEndCardVoiceOver of selectedEndCardVoiceOvers">
            <div class="tylie-card">
              <div class="tylie-card__img-wrap">
                <img
                  class="tylie-card__img"
                  src="./assets/images/voice-over.png"
                  alt="Image"
                />
              </div>
              <div class="tylie-card__body">
                <Label class="tylie-card__title"
                  ><icon-checkmark-green></icon-checkmark-green> End Tag Voice
                  Over:</Label
                >
                <div class="tylie-data tylie-data--block">
                  <span class="tylie-data__txt">{{
                    selectedEndCardVoiceOver.voiceOverName
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</div>

<ng-template #taggerNameTemplate>
  <button class="popover__close" (click)="onTaggerNamePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <form novalidate [formGroup]="topFormGroup">
      <div class="tylie-form-group form-group">
        <label class="tylie-form-group__lbl">Give your Ad a Title</label>
        <input
          name="name"
          type="text"
          class="tylie-text form-control"
          placeholder="Here is my new Ad name"
          formControlName="name"
        />
        <div
          *ngIf="
            topFormGroup.get('name').invalid &&
            (topFormGroup.get('name').dirty || topFormGroup.get('name').touched)
          "
        >
          <div class="error" *ngIf="topFormGroup.get('name').errors.required">
            Title is required.
          </div>
        </div>
      </div>
  </form>
</ng-template>
