import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from "../../../services/core/utilities";
import { OrderService } from '../../../services/order/order.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'billingitemspopup',
  templateUrl: './billingitemspopup.component.html',
  styleUrls: ['./billingitemspopup.component.css'],
})
export class BillingItemsPopupComponent extends BasePopupComponent {

  public orderGuid: string;
  public salesOrderLineItems: Array<any>;
  public purchaseOrderLineItems: Array<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private orderService: OrderService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.getBillingItems();
  }

  private getBillingItems() {
    this.orderService.getBillingItems(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var billingItems = res.result;

        this.salesOrderLineItems = billingItems.salesOrderLineItems;
        this.purchaseOrderLineItems = billingItems.purchaseOrderLineItems;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
