
<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">FINALIZE ORDER</h4>
</div>
<ng-container *ngIf="showWarningMessage">
  <div class="col-md-12 dest-notes">
    <p class="p-small p-warning error-message">Some spots on the order has failed QC and waiting for the spots to be re-uploaded</p>
  </div>
</ng-container>
<div class="modal-body">
  <h2 class="tylie-type-h2">Take a Final Look</h2>
  <p class="tylie-type-body">You're about to submit this order. If you're sure your selections are correct please finalize your order below. Or go back to order creation to view or edit.</p>
  <div class="d-flex justify-content-between">
    <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="onBackToOrder()">
      <span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>
      Back To Order creation
    </button>
    <button class="tylie-button tylie-button--xs" type="button" (click)="OnOrderFinalize()">
      Finalize Order
    </button>
  </div>
</div>