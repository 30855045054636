<!--<dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="orderDigitalDeliveryDestinationModel == null"></dx-load-indicator>
<div class="media-qc" id="order-details-destination-section" *ngIf="orderDigitalDeliveryDestinationModel != null">-->
<!--<spinner [show]="showLoader"></spinner>-->
<!--<dx-load-indicator id="large-indicator" height="60" width="60" [visible]="showLoader==true"></dx-load-indicator>-->
<ng-container id="order-details-destination-section">

  <div class="d-flex justify-content-end mb-3">
    <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onExport()">
      <span class="tylie-button__icon"><icon-export></icon-export></span>
      <span class="tylie-button__text">Export</span>
    </button>
  </div>
  <dx-data-grid id="orderDetailDestinations"
      class="tylie-grid"
                #orderDetailDestinations
                [dataSource]="orderDetailDestinationStore"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                [showBorders]="true"
                (onRowPrepared)="onRowPrepared($event)"
                keyExpr="destinationGuid">

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-export [enabled]="false" fileName="Destinations"></dxo-export>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="destinationName" cellTemplate="destinationNameTemplate" width="14%" cssClass="gridStyle" caption="Name" sortOrder="asc"></dxi-column>
    <dxi-column dataField="destinationCode" width="6%" cssClass="gridStyle" caption="Destination ID"></dxi-column>
    <dxi-column dataField="market" width="11%" cssClass="gridStyle" caption="Market"></dxi-column>
    <dxi-column dataField="serviceName" width="11%" cssClass="gridStyle" caption="Service Name"></dxi-column>
    <dxi-column dataField="adId" width="14%" cssClass="gridStyle" caption="Ad-ID / Instructions"></dxi-column>
    <dxi-column dataField="groupId" width="5%" cssClass="gridStyle tyl-text-align-left" caption="Group"></dxi-column>
    <dxi-column dataField="vendorName" width="11%" cssClass="gridStyle" caption="Network"></dxi-column>
    <dxi-column dataField="tiedId" width="9%" cssClass="gridStyle" caption="Hub Delivery" [visible]="showHubbedDeliveryInDetailTabs"></dxi-column>
    <dxi-column dataField="status" width="12%" cellTemplate="statusTemplate" cssClass="gridStyle" caption="Status"></dxi-column>
    <dxi-column dataField="podDate" width="14%" cellTemplate="dateTemplate" cssClass="gridStyle" caption="Status Date (EST)"></dxi-column>
    <dxi-column dataField="destinationGuid" cellTemplate="actionTemplate" cssClass="gridStyle" caption="Action" [visible]="showActionsColumnInDestinationDestinationsTab" [allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"></dxi-column>

    <div *dxTemplate="let t of 'destinationNameTemplate'">
      <span class="p-extra-small" [class.blue-text]="t.data.showDestinationDetail" (click)='showDetail(t.data.destinationGuid, t.data.showDestinationDetail)'>{{t.data.destinationName}}</span>
    </div>

    <div *dxTemplate="let t of 'actionTemplate'" class="tyl-center ">
      <div>
        <span *ngIf="t.data.allowPODUpdate == true">
          <a class="tylie-lnk"
              (click)="onUpdateVendorPOD(t.data)">
            Update POD
          </a>
        </span>
        <span *ngIf="t.data.allowReSendWithVendorAssignment == true">
          <a class="tylie-lnk"
              (click)="onReSendAssignVendor(t.data)">
            ReSend
          </a>
        </span>
        <span *ngIf="t.data.allowVendorAssignment == true">
          <a class="tylie-lnk"
              (click)="onAssignVendor(t.data)">
            Assign Carrier
          </a>
        </span>
        <span *ngIf="t.data.retryVendorLineItemCreation == true">
          <a class="tylie-lnk" (click)="onRetryVendorLineItemCreation(t.data)">Retry</a>
        </span>
        <span *ngIf="t.data.allowReSubmitWithDifferentVendor == true || t.data.allowStatusUpdate == true">
          <a class="tylie-lnk"
              (click)="onTakeAction(t.data)">
            Select Action
          </a>
          &nbsp;
        </span>
      </div>
    </div>

    <div *dxTemplate="let t of 'statusTemplate'">
      <div *ngIf="(showCarrierErrorInDetailTabs && t.data?.error != null && t.data.error != '')"
            outsideClick="true" placement="left" #displErrorpop="bs-popover" [popoverContext]="t.data" container="body" [popover]="errorDisplayTemplate"
            class="blue-text">
        {{t.data.status}}
      </div>
      <div *ngIf="!(showCarrierErrorInDetailTabs && t.data?.error != null && t.data.error != '')">
        {{t.data.status}}
      </div>
    </div>

    <div *dxTemplate="let t of 'dateTemplate'">
      <div style="text-align:left;">{{ t.data.podDate | convertFromUTC:'MM/DD/YYYY hh:mm A': t.data.vendorDeliveryPODTimeFormat }}</div>
    </div>
    <dxo-sorting mode="multiple"></dxo-sorting>
  </dx-data-grid>
</ng-container>

<ng-template #errorDisplayTemplate let-error="error">
  <!-- <div class='p-normal'>
    <div>
      <p class='black'>{{error}}</p>
    </div>
    <i (click)="onHideDisplayErrorPop()"></i>  </div> -->

  <button class="popover__close" (click)="onHideDisplayErrorPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <p class='tylie-type-body'>{{error}}</p>
</ng-template>
