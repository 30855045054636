<div class="tylie-panel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        NETWORK
      </h4>
    </div>
    <div class="tylie-panel__body">
      <dx-data-grid id="networkGrid" class="tylie-grid"
                    #networkGrid
                    [dataSource]="networkDataSource"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [showBorders]="true"
                    [showRowLines]="true"
                    [hoverStateEnabled]="true"
                    keyExpr="vendorId"
                    [rowAlternationEnabled]="true"
                    [wordWrapEnabled]="true">

        <dxi-column dataField="vendorCode" caption="Name" width="32%" cssClass="gridStyle tyl-vertical-align-intial"></dxi-column>
        <dxi-column dataField="vendorName" caption="Description" width="32%" cssClass="gridStyle tyl-vertical-align-intial"></dxi-column>
        <dxi-column dataField="vendorDestinationCode" caption="Mapped ID" width="31%" cssClass="gridStyle tyl-vertical-align-intial tyl-center" [allowSorting]="false"></dxi-column>
        <dxi-column width="5%" cssClass="gridStyle" cellTemplate="actionsTemplate" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let t of 'actionsTemplate'">

          <div style="text-align:left;">
            <a *ngIf="t.data.vendorDestinationCode == null" class="tyl-blue-text p-extra-small" (click)="onAddMapping(t.data)">ADD</a>
          </div>

          <div style="text-align:left;" *ngIf="t.data.vendorDestinationCode != null">
            <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onMappedIdDelete(t.data.vendorId)">
              <icon-trash-can color="#1e90ff"></icon-trash-can>
            </a>&nbsp;&nbsp;
            <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="t.data.isDestinationMappingAvailable == true" (click)="onEditMapping(t.data)">
              <i class="glyphicon glyphicon-edit"></i>
            </a>
          </div>
        </div>

      </dx-data-grid>
    </div>
</div>
 
