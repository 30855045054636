import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuickplayerComponent } from '../../../videoplayer/quickplayer/quickplayer.component';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Utilities } from '../../../../services/core/utilities';
import { TaggerService } from '../../../../services/tagger/tagger.service';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { TaggerBaseCreative } from '../../../../models/tagger/tagger.model';
import { Constants } from '../../../../configurations/constants';
import { TaggableDurationEnum } from '../../../../configurations/enums/enums';

@Component({
  selector: 'app-tagger-creative-step',
  templateUrl: './tagger-creative-step.component.html',
  styleUrls: ['./tagger-creative-step.component.css']
})
export class TaggerCreativeStepComponent implements OnInit {
  @Input() topFormGroup: FormGroup;
  @Output() next: EventEmitter<any> = new EventEmitter();
  public baseCreatives: TaggerBaseCreative[] = [];
  public selectedBaseCreatives: TaggerBaseCreative[] = [];
  public modalRef: BsModalRef;

  constructor(
    public util: Utilities,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private taggerService: TaggerService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.getBaseCreatives();
    this.topFormGroup.get('baseCreatives').valueChanges.subscribe((data) => {
      this.selectedBaseCreatives = data;
    });
  }

  public getBaseCreatives() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getBaseCreatives().subscribe((res: any) => {
      if (res.isSuccess == true) {
        if (res.result.searchResults) {
          this.baseCreatives = res.result.searchResults.filter(bc =>
            bc.taggableDurationInSecs === TaggableDurationEnum.THREE ||
            bc.taggableDurationInSecs === TaggableDurationEnum.FIVE ||
            bc.taggableDurationInSecs === TaggableDurationEnum.SEVEN
          );
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onSelect(creativeMedia: any): void {
    if (creativeMedia.durationinsecs > 0) {
      this.selectedBaseCreatives = [creativeMedia];
      this.topFormGroup.get('baseCreatives').setValue(this.selectedBaseCreatives);
      this.next.emit();
    } else {
      this.alertService.showMessage(
        "ERROR",
        Constants.creative_duration_error,
        MessageSeverity.error
      );
    }
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public getThumbnail() {
    return this.util.getThumbnailUrl("", false, false, false);
  }

}
