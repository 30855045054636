import { Clients } from "./create-project.model";

export interface ProjectSearchRequest {
  projectCode: string;
  projectName: string;
  clientId: number;
  quickSearch: string;
  projectStatus: number;
}

export class ProjectSearchResult {
  isSuccess: boolean;
  records: Project[];
  totalCount: number;
}

export class Project {
  fileCollectionId: number;
  fileCollectionGuid: string;
  collectionName: string;
  collectionnId: string;
  collectionType: string;
  clientId: number;
  clientName: string;
  status: string;
  parentFileCollectionId: number;
  createdDate: Date;
  modifiedDate: Date;
  isProjectManager: boolean;
  isProjectViewer: boolean;
  isProjectContributor: boolean;
  projectFileCollectionId: number;
  inverseParentfilecollection: Project[];
}

// export class ProjectFolder {
//     fileCollectionId: number;
//     storagekey: string;
//     collectionName: string;
//     collectionnId: string;
//     collectionType: string;
//     parentFileCollectionId: number;
// }

export class ClientUserModel {
  fileCollectionUserAccessid: number;
  userProfileId: number;
  userProfileGuid: string;
  userName: string;
  fileCollectionId: number;
  isActive: boolean;
  isDeleted: boolean;
  userPermissionLevelId: number
  selectedFolders: number[] = []
}

export class ApiResponse {
  isSuccess: boolean;
}

export class UserPermissionLevel {
  userPermissionLevelId: number;
  description: string
}

export class FileCollectionUserAccess {
  fileCollectionId: number;
  userProfileGuid: string;
  userprofileId: number;
  username: string;
  userPermissionLevelId: number;
  client: number;
}

export class UserPermissionResponse {
  isSuccess: boolean;
  errors: string[];
}
