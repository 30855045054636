import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientOrderedBy } from '../../../models/admin/client/client';

@Component({
  selector: 'clientaddorderedby',
  templateUrl: './addorderedby.component.html',
  styleUrls: ['./addorderedby.component.css'],
})
export class AddOrderedByPopUp implements OnInit {

  @Input('orderedByList') orderedByList: string;

  public modalRef: BsModalRef;
  public orderedByForm: FormGroup;
  public onSaveOrderedBy: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder) {

    this.onSaveOrderedBy = new Subject<string>();
  }

  ngOnInit() {
    this.orderedByForm = this.fb.group({
      orderedByList: [this.orderedByList]
    });
  }

  public onOrderedByFormFormSubmit({ value, valid }: { value: ClientOrderedBy, valid: boolean }) {

    if (!valid) {
      return;
    }

    if (value.orderedByList != null && value.orderedByList != undefined) {
      var orderedBy = value.orderedByList.split(";");
      value.orderedByList = orderedBy.join('; ');
    }
    else {
      value.orderedByList = '';
    }

    this.onSaveOrderedBy.next(value.orderedByList);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
