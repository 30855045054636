export class CustomTagConfig {
  public id: number;
  public tag: string;
  public dataType: number;
  public displayDataType: string;
  public description: string;
  public config: string;
  public isActive: boolean;
}

export class CreateCustomTagConfig {
  public customTagConfigurations: CustomTagConfig[];
  public selectedBrandsForCustomTags: any[];
  public inActiveCustomTagConfig: CustomTagConfig[];
}

export class TagValueChangedEvent {
  public formGroupNumber: number;
  public tag: string;
}
