<header class="tylie-header">
  <h3 class="tylie-header__title">Projects</h3>
</header>

<div class="tylie-body">

  <ng-container *isAuthorized="[this.userRoles.getProjectAdminRoleName()]">
    <div class="tylie-body__bar" id="upload-section">
      <button type="button" class="tylie-button tylie-button--icon" secured
        [accessControlListComponent]=[this.userRoles.getProjectAdminRoleName()] (click)="gotoCreateProject()">
       <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">CREATE NEW PROJECT</span>
      </button>
    </div>
  </ng-container>

  <project-search></project-search>
</div>