import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '../../../services/project/create-project.service';
import { ProjectFolder } from '../../../models/project/create-project.model';
import { FileUploadQueue } from '../../../services/project/multipart-file-upload-handler.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiEndPoint } from '../../../configurations/api-endpoint.service';
import { FileUploadModel, ProjectFile } from '../../../models/project/project-file-upload.model';
import { SinglePartFileUploadService } from '../../../services/project/singlepart-file-upload-handler.service';
import { Constants } from '../../../configurations/constants';
import { Utilities } from '../../../services/core/utilities';
import { MoveFileBetweenFolders } from '../../../models/project/project-actions.model';

@Component({
  selector: 'movefiles-betweenfolders-project',
  templateUrl: './movefilesbetweenfoldersprojectpopup.component.html',
  styleUrls: ['./movefilesbetweenfoldersprojectpopup.component.css'],
})
export class MoveFilesBetweenFoldersProjectPopUp implements OnInit, OnDestroy {

  public onClose: Subject<boolean>;
  public modalRef: BsModalRef;

  moveFilesBetweenFoldersProjectForm: FormGroup;
  folders: ProjectFolder[]
  subscriptions: Subscription[] = [];

  @Input() projectId: number;
  @Input() fileCollectionId: number;
  @Input() parentFileCollectionId: number;

  postModel = {} as ProjectFile;

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private singlePartUploadService: SinglePartFileUploadService,
    private util: Utilities) {

    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.moveFilesBetweenFoldersProjectForm = this.fb.group({
      destinationFileCollectionId: new FormControl(null),
      fileCollectionId: new FormControl(this.fileCollectionId, Validators.required)
    });

    this.loadFolders();
  }

  loadFolders(): void {
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectService.loadProjFolders(this.projectId).subscribe((folders) => {
      this.alertService.ShowLoader(false);
      var filteredFolders = folders.result.filter(f => f.fileCollectionId != this.parentFileCollectionId)
      this.folders = filteredFolders;
    }, error => {
      this.alertService.ShowLoader(false);
      if (this.util.handleError(error))
        this.closePopup();
    }));
  }

  onFolderChange(e): void {
    console.log(e);
  }

  moveFileBetweenFolders() {
    var moveReq: MoveFileBetweenFolders = this.moveFilesBetweenFoldersProjectForm.value;
    moveReq.fileCollectionId = this.fileCollectionId;
    moveReq.projectId = this.projectId;

    if (moveReq.destinationFileCollectionId == undefined || moveReq.destinationFileCollectionId == null) {
      moveReq.destinationFileCollectionId = this.projectId;
    }
    // make api call
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectService.moveFileCollection(moveReq)
      .subscribe(res => {
        if (res.isSuccess) {
          this.alertService.ShowLoader(false);
          // close pop up and subsc
          this.onClose.next(true);
          this.closePopup();
        }
      },
        error => {
          this.closePopup();
          this.util.handleError(error);
        }));
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
