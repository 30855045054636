import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { Constants } from "../../configurations/constants";
import { LogSeverity } from "../../models/core/logmessage.model";
import { AddFilesResponse, FileUploadModel, ProjectFile, SinglepartSignedURLResponse } from "../../models/project/project-file-upload.model";
import { AlertService, MessageSeverity } from "../core/alert.service";
import { Utilities } from "../core/utilities";
import { AdFileS3UploadRequest, SpotS3UploadRequest } from '../../models/upload/upload-request.model';

@Injectable()
  
export class SinglePartFileUploadService {
  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private alertService: AlertService,
    public util: Utilities) {

  }

  public uploadProjectFile(request: FileUploadModel, uploadedFile: File) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadProjectFile(request).pipe(
      mergeMap(res => {
        if (res.isSuccess) {
          if (res.presignedUrl) {
            fileGuid = res.presignedUrl.fileGuid;
            return this.uploadToS3NoProgress(res.presignedUrl.url, uploadedFile);
          }
        }
        return of(res);
      }),
      mergeMap(res3 => {
        if (res3 == true) {
          return of({ fileGuid: fileGuid, fileName: request.fileName });
        }
        else {
          return of(res3);
        }
      }),)
  }

  private initiateUploadProjectFile(request: FileUploadModel): Observable<any> {
    return this.initiateProjectFileUpload(request).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return of(error);
      }),)
  }


  public uploadAsset(request: SpotS3UploadRequest, uploadedFile: File) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadAsset(request, uploadedFile).pipe(
      mergeMap(res => {
        if (res.isSuccess) {
          if (res.presignedUrl) {
            fileGuid = res.presignedUrl.fileGuid;
            return this.uploadToS3(res.presignedUrl.url, uploadedFile);
          }
        }
        return of(res);
      }),
      mergeMap(res3 => {
        if (res3 == true) {
          return of({ fileGuid: fileGuid, fileName: uploadedFile.name });
        }
        else {
          return of(res3);
        }
      }))
  }

  private initiateUploadAsset(request: SpotS3UploadRequest, uploadedFile: File): Observable<any> {
    //request.fileName = uploadedFile.name;
    return this.initiateAssetUpload(request).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return of(error);
      }))
  }

  private initiateAssetUpload(request: SpotS3UploadRequest): Observable<SinglepartSignedURLResponse> {
    return this.http.post<SinglepartSignedURLResponse>(this.apiendPoint.getAssetSinglePartSignedURL(), request);
  }


  public uploadAdFile(request: AdFileS3UploadRequest, uploadedFile: File) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadAdFile(request, uploadedFile).pipe(
      mergeMap(res => {
        if (res.isSuccess) {
          if (res.presignedUrl) {
            fileGuid = res.presignedUrl.fileGuid;
            return this.uploadToS3(res.presignedUrl.url, uploadedFile);
          }
        }
        return of(res);
      }),
      mergeMap(res3 => {
        if (res3 == true) {
          return of({ fileGuid: fileGuid, fileName: uploadedFile.name });
        }
        else {
          return of(res3);
        }
      }))
  }

  private initiateUploadAdFile(request: AdFileS3UploadRequest, uploadedFile: File): Observable<any> {
    //request.fileName = uploadedFile.name;
    return this.initiateAdFileUpload(request).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return of(error);
      }))
  }

  private initiateAdFileUpload(request: AdFileS3UploadRequest): Observable<SinglepartSignedURLResponse> {
    return this.http.post<SinglepartSignedURLResponse>(this.apiendPoint.getAdFileSinglePartSignedURL(), request);
  }

  //private uploadToS3(presignedUrl: string, uploadedFile: File) {
  //  return this.uploadfileAWSS3(presignedUrl, "application/octet-stream", uploadedFile).pipe(
  //    map((res: any) => {
  //      console.log(res);
  //      if (res.type === HttpEventType.DownloadProgress) {
  //        console.log(res);
  //      }
  //      else if (res.status == 200) {
  //        return true;
  //      }
  //      else if (res.status == 200) {
  //        return true;
  //      }
  //    }),
  //    catchError((error: any) => {
  //      this.alertService.logDB(error.toString(), LogSeverity.error);
  //      this.alertService.showMessage("ERROR", Constants.uploadOrderFileFailed, MessageSeverity.error, true);
  //      return error;
  //    }))
  //}

  private uploadToS3(presignedUrl: string, uploadedFile: File) {
    return this.uploadfileAWSS3(presignedUrl, "application/octet-stream", uploadedFile).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        this.alertService.logDB(error.toString(), LogSeverity.error);
        this.alertService.showMessage("ERROR", Constants.uploadOrderFileFailed, MessageSeverity.error, true);
        return error;
      }),)
  }

  private uploadfileAWSS3(fileuploadurl: string, contentType: string, file: Blob) {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");
    header = header.append("Content-Type", contentType);
   
    return this.http.put(fileuploadurl, file, { headers: header, observe: 'events', reportProgress: true, responseType: 'blob' }).pipe(retry(3));
  }

  private uploadToS3NoProgress(presignedUrl: string, uploadedFile: File) {
    return this.uploadfileAWSS3NoProgress(presignedUrl, "application/octet-stream", uploadedFile).pipe(
      map((res: any) => {
        if (res.status == 200) {
          return true;
        }
      }),
      catchError((error: any) => {
        this.alertService.logDB(error.toString(), LogSeverity.error);
        this.alertService.showMessage("ERROR", Constants.uploadOrderFileFailed, MessageSeverity.error, true);
        return error;
      }))
  }


  private uploadfileAWSS3NoProgress(fileuploadurl: string, contentType: string, file: Blob) {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");
    header = header.append("Content-Type", contentType);
    return this.http.put(fileuploadurl, file, { headers: header, observe: 'response' });
  }

  private initiateProjectFileUpload(request: FileUploadModel): Observable<SinglepartSignedURLResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId': [request.projectId.toString(), request.folderId ? request.folderId.toString() : '']
    });
    let options = { headers: headers };
    return this.http.post<SinglepartSignedURLResponse>(this.apiendPoint.getSinglePartSignedURL(), request, options);
  }

  public addFiles(request: ProjectFile): Observable<AddFilesResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId': [request.projectId.toString(), request.folderId ? request.folderId.toString() : '']
    });
    let options = { headers: headers };
    return this.http.post<AddFilesResponse>(this.apiendPoint.addFiles(), request, options);
  }
}
