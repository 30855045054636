<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 90"
     [attr.height]="height" [attr.width]="width"
>
    
    <g
        id="Layer_2"
        data-name="Layer 2"
    >
        <g
            id="Layer_1-2"
            data-name="Layer 1"
        >
            <path
                [attr.fill]="color"
                d="M78.1,0H11.9L0,23.8V90H90V23.8Zm-60,10H71.9l5,10H13.1ZM80,80H10V30H80Z"
            />
            <polygon
                [attr.fill]="color"
                points="60 55 50 55 50 40 40 40 40 55 30 55 45 70 60 55"
            />
        </g>
    </g>
</svg>
