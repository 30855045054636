<div id="select-services">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Additional Info for {{this.spotDestinationAddlInfo.destination.name}}-{{this.spotDestinationAddlInfo.destination.code}}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 service-help-text">
        <p>Your Group contain Digital Destinations that allow for optional additional information.</p>
        <hr class="seperator" />
      </div>

      <form [formGroup]="addlInfoRequest" #f="ngForm" (ngSubmit)="onSave(addlInfoRequest)">
        <div class="col-md-12 captioning" *ngFor="let set of sets.controls; let i=index">
          <div [ngSwitch]="this.spotDestinationAddlInfo.destination.fileDeliveryType.toLowerCase()"> 
            <destination-innovid-info *ngSwitchCase="'innovid'"
                                      [formGroup]="set"
                                      [f]="f">
            </destination-innovid-info>
          </div>
        </div>

        <div class="submit-option" *ngIf="readOnlyMode == false">
          <div class="col-md-4"></div>
          <div class="col-md-6">
            <div class="form-group apply-to-all-media">
              <input type="checkbox" name="apply-to-all-media" id="apply-to-all-media" [ngModelOptions]="{standalone: true}" [(ngModel)]="applyToAll" (ngModelChange)="onApplyAllChange()">
              <label for="apply-to-all-media">Apply to All Assets</label>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn new-default-btn btn-normal" type="submit">SUBMIT</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
