import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'convertFromUTC'
})

export class ConvertFromUTC implements PipeTransform {

  // Converts to EST from UTC by default
  constructor() {

  }

  transform(value: Date, format: string, timeZone: string): string {
    if (!value)
      return "";

    var date = moment.utc(value);
    if (timeZone == null || timeZone == undefined) {
      timeZone = 'America/New_York';
      return date.tz(timeZone).format(format);
    }
    else {
      return date.tz(timeZone).format(format);
    }
  }
}
