<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Edit History</h4>
</div>
<div class="modal-body">
  <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="!editHistoryData"></dx-load-indicator>
  <dx-data-grid id="editHistoryGrid" *ngIf="editHistoryData" class="tylie-grid tylie-grid--bordered"
                [dataSource]="editHistoryData"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                keyExpr="auditLogID">
    <dxi-column dataField="message" [allowSorting]="false" width="60%" caption="History" [encodeHtml]="false" cssClass="gridStyle"></dxi-column>
    <dxi-column dataField="userName" caption="Updated By" width="20%" cssClass="gridStyle"></dxi-column>
    <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Updated On" width="20%" cssClass="gridStyle" sortOrder="desc"></dxi-column>
    <div *dxTemplate="let data of 'dateTemplate'">
      <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY HH:mm:ss'}}</div>
    </div>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20, 50, 100]"
                infoText="Page {0} of {1} ({2} items)"
                [showNavigationButtons]="true"
                [showInfo]="true">
    </dxo-pager>
  </dx-data-grid>
</div>
