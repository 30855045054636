<div class="d-flex justify-content-between mt-3 mb-2">
    <h4 class="tylie-body__title">Your Tagger Assets</h4>

    <!-- <label class="new-default-btn x-small-btn file-upload-btn">
        UPLOAD ASSETS
        <input
            #asset_file
            type="file"
            multiple
            style="display: none"
            (change)="onAttachFile($event)"
            [accept]="acceptFileTypes"
        />
    </label> -->

    <label class="tylie-upload">
      <input
        #asset_file
        type="file"
        multiple
        style="display: none"
        (change)="onAttachFile($event)"
        [accept]="acceptFileTypes"
      />
      <div class="tylie-upload__action tylie-upload__action--sm">
        UPLOAD ASSETS
      </div>
    </label>
</div>

<div class="tylie-panel tylie-panel--br-top">
    <div class="tylie-panel__body">
        <div class="d-flex justify-content-end mb-3">
            <button type="button"
                class="tylie-button tylie-button--xs tylie-button--icon"
                (click)="onRefresh()"
            >
                <span class="tylie-button__icon">
                    <icon-refresh></icon-refresh>
                </span>
                <span class="tylie-button__text">REFRESH</span>
            </button>
        </div>

        <div class="row">
            <div class="col-6">
                <section class="tylie-input-search">
                    <div class="tylie-input-search__inner">
                        <div class="tylie-input-search__icon">
                            <icon-search></icon-search>
                        </div>
                        <input
                            class="tylie-input-search__text"
                            placeholder="Search by keyword"
                            name="srch-term"
                            id="srch-term"
                            type="search"
                            [(ngModel)]="quickSearchValue"
                            (ngModelChange)="clearQuickSearch()"
                            (keydown.enter)="onEnterQuickSearch($event)"
                        />
                    </div>
                    <div class="tylie-input-search__btn">
                        <button
                            class="tylie-button tylie-button--sm"
                            type="button"
                            (click)="quickSearch()"
                        >
                            Search
                        </button>
                    </div>
                </section>
            </div>
            <div class="col-3">
                <button type="button"
                    class="tylie-button tylie-button--link tylie-button--xs collapsed mt-3"
                    data-bs-toggle="collapse"
                    href="#advancedSearchContainer"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    Advanced Search
                    <span class="tylie-button__icon e">
                        <icon-arrow-simple-down
                            height="12px"
                            width="12px"
                            color="#1e90ff"
                        ></icon-arrow-simple-down>
                    </span>
                </button>
            </div>
            <div class="col-3">
                <div class="row">
                    <div class="col-4">
                        <label
                            for="sortBy"
                            class="mt-3"
                            >Sort By</label
                        >
                    </div>
                    <div class="col-8">
                        <dx-select-box
                            [dataSource]="sortDataSource"
                            class="tylie-select form-control"
                            [searchEnabled]="true"
                            valueExpr="id"
                            displayExpr="name"
                            [value]="selectedSortOrder"
                            (onValueChanged)="onSortValueChanged($event)"
                        >
                        </dx-select-box>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="tylie-section collapse mt-3"
            id="advancedSearchContainer"
        >
            <form
                novalidate
                (ngSubmit)="onFormSubmit(searchRequestFormGroup)"
                [formGroup]="searchRequestFormGroup"
                role="search"
            >
                <div class="row">
                    <div class="col-4">
                        <div class="tylie-form-group form-group">
                            <label
                                class="tylie-form-group__lbl"
                                for="assetType"
                                >Asset Type</label
                            >
                            <dx-select-box
                                [dataSource]="assetTypeDataSource"
                                formControlName="assetType"
                                class="tylie-select form-control"
                                name="assetType"
                                id="assetType"
                                showClearButton="true"
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Select a value..."
                            >
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="tylie-form-group form-group">
                            <label
                                class="tylie-form-group__lbl"
                                for="startDate"
                                >Available Start Date</label
                            >
                            <dx-date-box
                                type="date"
                                formControlName="startDate"
                                id="startDate"
                                displayFormat="MM/dd/yyyy"
                                class="tylie-datepicker form-control"
                                [showClearButton]="true"
                            ></dx-date-box>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="tylie-form-group form-group">
                            <label
                                class="tylie-form-group__lbl"
                                for="endDate"
                                >Available End Date</label
                            >
                            <dx-date-box
                                type="date"
                                formControlName="endDate"
                                id="endDate"
                                displayFormat="MM/dd/yyyy"
                                class="tylie-datepicker form-control"
                                [showClearButton]="true"
                            ></dx-date-box>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="tylie-form-group form-group">
                            <label
                                for="group"
                                class="tylie-form-group__lbl"
                                >Available for Groups</label
                            >
                            <dx-select-box
                                [dataSource]="groupDataSource"
                                name="group"
                                id="group"
                                class="tylie-select form-control"
                                formControlName="group"
                                [showClearButton]="true"
                                valueExpr="groupGuid"
                                displayExpr="name"
                                placeholder="Select a value..."
                            >
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="tylie-form-group form-group">
                            <label
                                for="location"
                                class="tylie-form-group__lbl"
                                >Available for Locations</label
                            >
                            <dx-select-box
                                [dataSource]="locationDataSource"
                                name="location"
                                id="location"
                                class="tylie-select form-control"
                                formControlName="location"
                                valueExpr="locationGuid"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select a value..."
                            >
                            </dx-select-box>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start gap-3">
                        <div class="tylie-form-group form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                formControlName="unassignedGroup"
                                name="unassignedGroup"
                                id="unassignedGroup"
                            />
                            <label
                                class="form-check-label"
                                for="unassignedGroup"
                                >Unassigned to Groups</label
                            >
                        </div>

                        <div class="tylie-form-group form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                formControlName="availableOnly"
                                name="availableOnly"
                                id="availableOnly"
                            />
                            <label
                                class="form-check-label"
                                for="availableOnly"
                                >Currently Available Only</label
                            >
                        </div>

                        <div class="tylie-form-group form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                formControlName="expiredOnly"
                                name="expiredOnly"
                                id="expiredOnly"
                            />
                            <label
                                class="form-check-label"
                                for="expiredOnly"
                                >Expired Only</label
                            >
                        </div>

                        <div class="tylie-form-group form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                formControlName="featuredOnly"
                                name="featuredOnly"
                                id="featuredOnly"
                            />
                            <label
                                class="form-check-label"
                                for="featuredOnly"
                                >Featured Only</label
                            >
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="button"
                            class="tylie-button tylie-button--xs tylie-button--link me-3"
                            (click)="clearSearch()"
                        >
                            Clear Search
                        </button>
                        <button
                            class="tylie-button tylie-button--xs btn-icon-split"
                            type="submit"
                        >
                            <span class="icon">
                                <i class="tylicon tylicon-search-white"></i>
                            </span>
                            <span class="text">SEARCH</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="tylie-panel tylie-panel--br-top">
    <div class="tylie-panel__body">
      <div class="grid-container"
           *ngFor="let taggerAsset of taggerAssets">
        <div class="grid-item">
          <div class="row thumbnail-content">
            <img class="quickLink"
                 [src]="taggerAsset.thumbnailDisplayUrl"
                 *ngIf="taggerAsset.isPlayable"
                 (click)="
                            quickPlay(
                                taggerAsset.proxyUrl,
                                taggerAsset.assetName,
                                taggerAsset.thumbnailUrl,
                                taggerAsset.format
                            )
                        " />
            <div *ngIf="taggerAsset.isPlayable"
                 class="video-play-icon"
                 (click)="
                            quickPlay(
                                taggerAsset.proxyUrl,
                                taggerAsset.assetName,
                                taggerAsset.thumbnailUrl,
                                taggerAsset.format
                            )
                        "></div>
            <img [src]="taggerAsset.thumbnailDisplayUrl"
                 *ngIf="
                            taggerAsset.thumbnailDisplayUrl &&
                            !taggerAsset.isPlayable
                        " />
            <img [src]="getThumbnail()"
                 *ngIf="!taggerAsset.thumbnailDisplayUrl"
                 alt="Image" />
          </div>
        </div>
        <div class="grid-item">
          <div class="row">
            <div *ngIf="taggerAsset.isUploading == true; else UploadFinishedBlock" class="col-md-12 tagger-asset-title-container">
              <span class="tagger-asset-title">
                {{ taggerAsset.assetName }}
              </span>
              <span class="tagger-asset-subtitle">
                Upload in progress
              </span>
            </div>
            <ng-template #UploadFinishedBlock>
              <div *ngIf="taggerAsset.isUploadSuccess == false; else UploadSuccessBlock" class="col-md-12 tagger-asset-title-container">
                <span class="tagger-asset-fialed-title">
                  {{
                            taggerAsset.assetName
                  }}
                </span>
                <span *ngIf="taggerAsset.isUploading == false" class="tagger-asset-failed-subtitle">
                  Upload Failed
                </span>
              </div>
              <ng-template #UploadSuccessBlock>
                <div class="col-md-12 tagger-asset-title-container">
                  <span class="tagger-asset-title">
                    {{
                            taggerAsset.assetName
                    }}
                  </span>
                  <span class="tagger-asset-subtitle">
                    Uploaded:
                    {{ taggerAsset.createdDate | usertimezone : "MM/DD/YYYY HH:mm A" }} by {{ taggerAsset.createdBy }}
                  </span>
                </div>
              </ng-template>
            </ng-template>

            <div class="col-md-6">
              <div *ngIf="taggerAsset.assetType"
                   class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Asset Type:</Label>
                <span>{{ taggerAsset.assetType }}</span>
              </div>
              <div *ngIf="taggerAsset.availableStartDate"
                   class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Available Start:</Label>
                <span>
                  {{
                                    taggerAsset.availableStartDate
                                        | usertimezone : "MM/DD/YYYY"
                  }}
                </span>
              </div>
              <div *ngIf="taggerAsset.availableEndDate"
                   class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Available End:</Label>
                <span>
                  {{
                                    taggerAsset.availableEndDate
                                        | usertimezone : "MM/DD/YYYY"
                  }}
                </span>
              </div>
              <div *ngIf="taggerAsset.duration"
                   class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Length (secs):</Label>
                <span>{{ taggerAsset.duration }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="taggerAsset.taggableDurationInSecs"
                   class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Taggable Duration (secs):</Label>
                <span>
                  {{
                                taggerAsset.taggableDurationInSecs
                  }}
                </span>
              </div>
              <div class="tagger-asset-detail-container">
                <Label class="tylie-form-group__lbl">Available for:</Label>
              </div>
              <div class="tagger-asset-detail-container">
                <span *ngFor="
                                    let availableGroup of taggerAsset.availableGroups
                                ">{{ availableGroup }}</span>
              </div>
              <div class="tagger-asset-detail-container">
                <span *ngFor="
                                    let availableLocation of taggerAsset.availableLocations
                                ">{{ availableLocation }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-item">
          <div class="d-lg-flex d-block align-items-center justify-content-end">
            <div class="d-flex flex-lg-row flex-column align-items-center">
              <div class="mb-2 mb-lg-0 me-lg-2">
                <!-- Edit Asset button -->
                <button *ngIf="taggerAsset.isUploading == true" type="button"
                        class="tagger-asset-subtitle tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                        [disabled]="taggerAsset.isUploading">
                  <span class="tylie-button__icon">
                    <icon-edit-pencil color="#b5b5b5" height="15px" width="15px"></icon-edit-pencil>
                  </span>
                  EDIT ASSET
                </button>
              </div>

              <div class="mb-2 mb-lg-0 me-lg-2">
                <!-- Edit Asset button -->
                <button *ngIf="taggerAsset.isUploadSuccess == true" type="button"
                        class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                        (click)="onEditTaggerAsset(taggerAsset)">
                  <span class="tylie-button__icon">
                    <icon-edit-pencil color="#1e90ff" height="15px" width="15px"></icon-edit-pencil>
                  </span>
                  EDIT ASSET
                </button>
              </div>

              <div class="mb-2 mb-lg-0 me-lg-2">
                <!-- Upload Again button -->
                <label *ngIf="taggerAsset.isUploadSuccess == false && taggerAsset.isUploading == false"
                       class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon">
                  <span class="tylie-button__icon">
                    <icon-circle-arrow-up color="#1e90ff" height="15px" width="15px"></icon-circle-arrow-up>
                  </span>
                  UPLOAD AGAIN
                  <input #asset_file type="file" style="display: none"
                         (click)="reUploadClicked(taggerAsset)"
                         (change)="onReuploadFile($event, taggerAsset)"
                         [accept]="reUploadAcceptFileTypes" />
                </label>
              </div>

              <div class="mb-2 mb-lg-0 me-lg-2">
                <!-- Delete button -->
                <button *ngIf="taggerAsset.isUploadSuccess == false && taggerAsset.isUploading == false" type="button"
                        class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onDeleteTaggerAsset(taggerAsset)">
                  <span class="tylie-button__icon">
                    <icon-trash-can color="#1e90ff" height="15px" width="15px"></icon-trash-can>
                  </span>
                  DELETE
                </button>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center">
              <!-- Tylie badge -->
              <span *ngIf="taggerAsset.isPlaceHolder === true; else elseBlock" class="tylie-badge"
                    [ngClass]="statusClassPlaceholder(taggerAsset.displayStatus, taggerAsset.assetGuid)">
                {{ taggerAsset.displayStatus }}
              </span>
              <ng-template #elseBlock>
                <span class="tylie-badge" [ngClass]="statusClass(taggerAsset.displayStatus)" *ngIf="taggerAsset.displayStatus">
                  {{ taggerAsset.displayStatus }}
                </span>
              </ng-template>
            </div>
          </div>

          <div class="text-center">
            <div class="d-flex align-items-center justify-content-end">
              <icon-star *ngIf="taggerAsset.isFeatured" class="me-2"></icon-star>
              <span class="featured" *ngIf="taggerAsset.isFeatured">FEATURED</span>
            </div>
          </div>
        </div>

        <!--<div class="grid-item">
    <div class="d-flex gap-2 align-items-center">
      <button *ngIf="taggerAsset.isUploading == true" class="tagger-asset-subtitle tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3"
              [disabled]="taggerAsset.isUploading">
        <span class="tylie-button__icon">
          <icon-edit-pencil color="#b5b5b5"
                            height="15px"
                            width="15px"></icon-edit-pencil>
        </span>EDIT ASSET
      </button>

      <button *ngIf="taggerAsset.isUploadSuccess == true" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3"
               (click)="onEditTaggerAsset(taggerAsset)">
        <span class="tylie-button__icon">
          <icon-edit-pencil color="#1e90ff"
                            height="15px"
                            width="15px"></icon-edit-pencil>
        </span>EDIT ASSET
      </button>
      <label *ngIf="taggerAsset.isUploadSuccess == false && taggerAsset.isUploading == false" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3">
        <span class="tylie-button__icon">
          <icon-circle-arrow-up color="#1e90ff"
                            height="15px"
                            width="15px"></icon-circle-arrow-up>
        </span>
        UPLOAD AGAIN
        <input #asset_file
               type="file"
               style="display: none"
               (click)="reUploadClicked(taggerAsset)"
               (change)="onReuploadFile($event, taggerAsset)"
               [accept]="reUploadAcceptFileTypes" />
      </label>
      <button *ngIf="taggerAsset.isUploadSuccess == false && taggerAsset.isUploading == false" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3"
              (click)="onDeleteTaggerAsset(taggerAsset)">
        <span class="tylie-button__icon">
          <icon-trash-can color="#1e90ff"
                            height="15px"
                            width="15px"></icon-trash-can>
        </span>DELETE
      </button>
      <div class="row">
        <span *ngIf="
                      taggerAsset.isPlaceHolder === true;
                      else elseBlock
                  "
              class="tylie-badge"
              [ngClass]="
                      statusClassPlaceholder(
                          taggerAsset.displayStatus,
                          taggerAsset.assetGuid
                      )
                  ">
          {{ taggerAsset.displayStatus }}
        </span>
        <ng-template #elseBlock>
          <span class="tylie-badge"
                [ngClass]="
                          statusClass(taggerAsset.displayStatus)
                      "
                *ngIf="taggerAsset.displayStatus">
            {{ taggerAsset.displayStatus }}
          </span>
        </ng-template>
        <span *ngIf="taggerAsset.isFeatured" class="d-flex gap-2 align-items-center">
          <icon-star></icon-star>
          <span class="featured">FEATURED</span>
        </span>

      </div>
    </div>
  </div>-->
      </div>
        <div
            class="no-records"
            *ngIf="!taggerAssets || taggerAssets?.length === 0"
        >
            <span>No Records found</span>
        </div>
    </div>
</div>
