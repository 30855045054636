import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { DxDataGridComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { OrderService } from "../../../services/order/order.service";
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { ProductModel } from "../../../models/admin/brand/createproduct.model";
import { ConfigService } from "../../../services/core/config.service";
import { ClientBrandProductSelectedEvent, CreateClientBrandProduct } from "../../../models/admin/client/createclient.model";
import { AdminService } from "../../../services/admin/admin.service";
import { ConfirmPopupComponent } from "../confirmationpopups/confirmpopup.component";
import { confirmEventType } from "../../../configurations/enums/enums";
import { digitsOnlyValidator, EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { EditClientConfig } from '../../../models/admin/client/editclient.model';

@Component({
  selector: 'addclientbrandproductpopup',
  templateUrl: './addclientbrandproductpopup.component.html',
  styleUrls: ['./addclientbrandproductpopup.component.css'],
})
export class AddClientBrandProductPopUp implements OnInit {

  @Input('clientBrandProducts') clientBrandProducts: ProductModel[] = [];
  @Input('brandId') brandId: number = 0;
  @Input('editConfig') editConfig: EditClientConfig = null;
  @Input('allowEdits') allowEdits: boolean = false;
  @Input('isClientEdit') isClientEdit: boolean = false;

  public modalRef: BsModalRef;

  public addClientBrandProductForm: FormGroup;
  public clientBrandProductGroups: FormArray = this.fb.array([]);

  public selectedClientBrandProducts: number[] = [];
  public brandProducts: ProductModel[] = [];
  public brandProductDataSource: ProductModel[] = [];
  public onClientBrandProductAddEdit: Subject<CreateClientBrandProduct>;
  public onClientBrandProductDelete: Subject<number>;

  constructor(
    public bsModalRef: BsModalRef
    , private modalService: BsModalService
    , private fb: FormBuilder
    , private util: Utilities
    , private configService: ConfigService) {

  }

  ngOnInit() {
    this.onClientBrandProductAddEdit = new Subject<CreateClientBrandProduct>();
    this.onClientBrandProductDelete = new Subject<number>();

    this.addClientBrandProductForm = this.fb.group({
      clientBrandProductGroups: this.createClientBrandProductGroupsFormArray()
    });

    this.getBrandProducts(this.brandId);
  }

  public get formclientBrandProductGroupsArray() {
    return <FormArray>this.addClientBrandProductForm.get('clientBrandProductGroups');
  }

  public getBrandProducts(brandId: number) {
    this.configService.getAdminCurrentBrandProducts(brandId, 0).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.brandProducts = (products != null || products != undefined) ? (products.length > 0 ? products.filter(pds => pds.id != 0) : products) : [];
        this.filterSelectedBrandProducts();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        };
        this.brandProducts = [];
      });
  }

  public createClientBrandProductGroupsFormArray() {
    if (this.clientBrandProducts.length > 0) {
      this.clientBrandProducts.forEach(cbp => {
        if (this.util.notEmpty(cbp)) {
          const group = this.fb.group({
            id: [cbp.id, Validators.compose([Validators.required])],
            clientBrandProductId: [cbp.clientBrandProductId],
            name: [cbp.name],
            costCenter: this.addCostCenterFormArray(cbp.costCenter) as FormArray,
            inVoiceTo: this.addbillToFormArray(cbp.inVoiceTo) as FormArray,
            podEmails: [cbp.podDistributionEmails, Validators.compose([EmailListValidatorIfValueExists])],
            isTrafficEmailInPODDisplayed: cbp.isTrafficEmailInPODDisplayed,
            stdStorageDays: [cbp.stdStorageDays, Validators.compose([digitsOnlyValidator])],
            infrqStorageDays: [cbp.infrqStorageDays, Validators.compose([digitsOnlyValidator])],
            archiveDurationInDays: [cbp.archiveDurationInDays, Validators.compose([digitsOnlyValidator])],
            activeTandemStorageInDays: [cbp.activeTandemStorageInDays, Validators.compose([digitsOnlyValidator])]
          });
          this.clientBrandProductGroups.push(group);
          this.selectedClientBrandProducts.push(cbp.id);
        }
      });
    }
    else {
      this.onAddAnotherProduct();
    }
    return this.clientBrandProductGroups;
  }

  public onAddAnotherProduct() {
    const group = this.fb.group({
      id: [null, Validators.compose([Validators.required])],
      clientBrandProductId: [0],
      name: [null],
      isTrafficEmailInPODDisplayed: [true],
      costCenter: this.addCostCenterFormArray(null) as FormArray,
      inVoiceTo: this.addbillToFormArray(null) as FormArray,
      podEmails: ['', Validators.compose([EmailListValidatorIfValueExists])],
      stdStorageDays: ['', Validators.compose([digitsOnlyValidator])],
      infrqStorageDays: ['', Validators.compose([digitsOnlyValidator])],
      archiveDurationInDays: ['', Validators.compose([digitsOnlyValidator])],
      activeTandemStorageInDays: ['', Validators.compose([digitsOnlyValidator])]
    });

    this.clientBrandProductGroups.push(group);
  }

  public onClientBrandProductSelected(e: ClientBrandProductSelectedEvent) {
    this.selectedClientBrandProducts.push(e.productSelected);
    this.filterSelectedBrandProducts();
  }

  public onRemoveClientBrandProductFormGroup(e: number) {
    var cbpArray = this.addClientBrandProductForm.get('clientBrandProductGroups') as FormArray;
    var cbpGroup = cbpArray.at(e);
    if (this.util.notEmpty(cbpGroup)) {
      var productId: number = cbpGroup.get('id').value;
      var clientBrandProductId: number = cbpGroup.get('clientBrandProductId').value;
      if (this.util.notEmpty(clientBrandProductId) && clientBrandProductId > 0) {

        var initialState = {
          eventType: confirmEventType[confirmEventType.DeleteBrandProduct]
        };

        this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        this.modalRef.content.onClose.subscribe(result => {
          if (result == true) {
            this.selectedClientBrandProducts = this.selectedClientBrandProducts.filter(p => p != productId);
            this.filterSelectedBrandProducts();
            cbpArray.removeAt(e);
            this.onClientBrandProductDelete.next(clientBrandProductId);
          }
        });
      }
      else {
        this.selectedClientBrandProducts = this.selectedClientBrandProducts.filter(p => p != productId);
        this.filterSelectedBrandProducts();
        cbpArray.removeAt(e);
      }
    }
  }

  public onaddClientBrandProductFormSubmit({ value, valid }: { value: CreateClientBrandProduct, valid: boolean }) {
    if (!valid) {
      return;
    }
    this.onClientBrandProductAddEdit.next(value);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  private filterSelectedBrandProducts() {
    // check why filter is not working
    this.brandProductDataSource = [];
    this.brandProducts.forEach(bp => {
      if (this.filterCriteria(bp) === -1) {
        this.brandProductDataSource.push(bp);
      }
    });
  }

  private filterCriteria(brandProduct: ProductModel) {
    return this.selectedClientBrandProducts.indexOf(brandProduct.id);
  }

  private addbillToFormArray(billToList: string[]) {
    var inVoiceTo: FormArray = this.fb.array([]);
    if (this.util.notEmpty(billToList) && billToList.length > 0) {
      billToList.forEach(billto => {
        if (this.util.notEmpty(billto)) {
          const group = this.fb.group({
            billTo: [billto, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern)])]
          });
          inVoiceTo.push(group);
        }
      });
    }
    else {
      const group = this.fb.group({
        billTo: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern)])]
      });
      inVoiceTo.push(group);
    }
    return inVoiceTo;
  }

  private addCostCenterFormArray(costCenterList: string[]) {
    var costCenter: FormArray = this.fb.array([]);
    if (this.util.notEmpty(costCenterList) && costCenterList.length > 0) {
      costCenterList.forEach(ccenter => {
        if (this.util.notEmpty(ccenter)) {
          const group = this.fb.group({
            costCenter: [ccenter, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern), Validators.maxLength(35)])]
          });
          costCenter.push(group);
        }
      });
    }
    else {
      const group = this.fb.group({
        costCenter: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern), Validators.maxLength(35)])]
      });
      costCenter.push(group);
    }
    return costCenter;
  }
}
