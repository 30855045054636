<nav-header headerTitle="Order Trace" link="Back To All Orders" [linkRoute]="ordersRoute"></nav-header>
<div class="tylie-body" *ngIf="orderGuid" id="scrollContainer">
    <dx-load-indicator height="60" width="60" *ngIf="!orderTrace"></dx-load-indicator>
    <div class="order-intractions" *ngIf="orderTrace">
      <h2 class="tylie-type-h2">ORDER# {{orderDetail.workOrder}}</h2>
      <div class="row">
        <div class="col-md-3">
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Submitted by</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.createdBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Submitted on</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.createdOn | usertimezone: 'LL HH:mm' }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Scope</label>
            <span class="tylie-data__txt">
              {{orderDetail.config.orderActions.editScope.toString() }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block" [ngClass]="{'label-warning': orderDetail.isDigitalDeliveryOnly == true }">
            <label class="tylie-data__lbl">Distribution Only</label>
            <span class="tylie-data__txt">
              {{orderDetail.config.orderActions.isDistributionOrderOnly }}
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Reviewed by</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.reviewedBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Reviewed on</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.reviewCompletedOn | usertimezone: 'LL HH:mm' }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Edit Owner</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.editStartedBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Edit Expires on</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.editExpiryOn | usertimezone: 'LL HH:mm' }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block"
            [ngClass]="{'label-warning': orderDetail.header.isSetToReviewByOps == true }">
            <label class="tylie-data__lbl">Sent to Review By Ops</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.isSetToReviewByOps }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block"
            [ngClass]="{'label-warning': orderDetail.hasOnlyAutomationServices == true }">
            <label class="tylie-data__lbl">Has Only Automation Services</label>
            <span class="tylie-data__txt">
              {{orderDetail.config.orderActions.hasOnlyAutomationServices }}
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Claimed by</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.claimedBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Claimed on</label>
            <span class="tylie-data__txt">{{orderDetail.header.claimedOn | usertimezone: 'LL HH:mm' }}</span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Process Completed by</label>
            <span class="tylie-data__txt">
              {{orderDetail.header.processCompletedBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Process Completed on</label>
            <span class="tylie-data__txt">
               {{orderDetail.header.processCompletedOn | usertimezone: 'LL HH:mm' }}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <label class="tylie-data__lbl">Submitted to Automation on</label>
            <span class="tylie-data__txt">{{orderDetail.header.submittedToAutomationAt | usertimezone: 'LL HH:mm' }}</span>
          </div>
          <div class="tylie-data tylie-data--c-block"
            [ngClass]="{'label-warning': orderDetail.hasAnyAutomationServices == true }">
            <label class="tylie-data__lbl">Has Any Automation Services</label>
            <span class="tylie-data__txt">
              {{orderDetail.config.orderActions.hasAnyAutomationServices }}
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="d-flex justify-content-end flex-column">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon mb-2" (click)="onRefresh()">
              <span class="tylie-button__icon"><icon-refresh></icon-refresh></span>
              <span class="tylie-button__text">Refresh</span>
            </button>
  
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon mb-2" (click)="onShowOrderDetail()">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-list-alt"></i></span>
              <span class="tylie-button__text">Details</span>
            </button>
  
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onShowNotifications()">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-send"></i></span>
              <span class="tylie-button__text">Notifications</span>
            </button>
          </div>
         

        </div>
      </div>

      <div class="tylie-step-progress mt-3 mb-4">
        <header class="tylie-step-progress__title">Order Status</header>
        <div class="tylie-step-progress__steps" [ngClass]="(orderDetail.header.progress.length == 3)?'col-md-3':'col-md-9'">
          <ng-container *ngFor="let pp of orderDetail.header.progress; let i = index;">
            <div class="tylie-step-progress__step" [ngClass]="{'active': pp.isActive == true }" [style.left.%]="progressBarDivider * i"></div>
            <div class="tylie-step-progress__step-txt" [ngClass]="{'active': pp.isActive == true }" [style.left.%]="progressBarDivider * i">{{pp.progressText}}</div>
          </ng-container>
          <div class="tylie-step-progress__bar"></div>
        </div>
      </div>

      <div class="tylie-accordion accordion">
        <div class="tylie-accordion__panel accordion-item">
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#messagesCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Queue Messages
            </button>
          </h2>
          <div id="messagesCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body">
              <div class="tylie-accordion__body-bar">
                <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="onQueueMessagesRefresh()">
                  <span class="tylie-button__icon"><icon-refresh></icon-refresh></span>
                  <span class="tylie-button__text">Refresh</span>
                </button>
                <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onSendNewMessage(queueMsgTemplate)">
                  <span class="tylie-button__icon"><icon-send></icon-send></span>
                  <span class="tylie-button__text">New Message</span>
                </button>
              </div>
              <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
                <dx-data-grid class="tylie-grid" id="queueMessagesGrid" [dataSource]="queueMessages" [allowColumnReordering]="true"
                  [allowColumnResizing]="true" [columnAutoWidth]="false" [rowAlternationEnabled]="true"
                  [showColumnLines]="true" [hoverStateEnabled]="true" [showRowLines]="true" [cacheEnabled]="false"
                  (onRowPrepared)="onQueueMessagesRowPrepared($event)" keyExpr="messageStatusId">
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                  <dxi-column width="20%" dataField="simpleQueueName" caption="Queue" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="15%" dataField="status" caption="Status" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="14%" dataField="correlationId" caption="CorrelationId"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column width="5%" dataField="retryCount" caption="Retry#" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="10%" dataField="createdDate" caption="Created" cellTemplate="dateTemplate"
                    cssClass="gridStyle" sortOrder="desc"></dxi-column>
                  <dxi-column width="12%" dataField="processStartedDate" caption="Started" cellTemplate="dateTemplate"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column width="12%" dataField="processCompletedDate" caption="Completed" cellTemplate="dateTemplate"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column width="12%" dataField="status" caption="Actions" cellTemplate="actionsTemplate"
                    cssClass="gridStyle" [allowSorting]="false" [allowGrouping]="false"></dxi-column>
                  <dxo-search-panel [visible]="false"></dxo-search-panel>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                    [showNavigationButtons]="true" [showInfo]="true">
                  </dxo-pager>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-sorting mode="multiple"></dxo-sorting>
                  <dxo-header-filter [visible]="true"></dxo-header-filter>
                  <dxo-filter-row [visible]="true"></dxo-filter-row>
                  <div *dxTemplate="let t of 'actionsTemplate'">
                    <div class="d-flex justify-content-end">
                      <a class="tylie-button tylie-button--xs tylie-button--link me-1" (click)="onViewQueueMessage(t.data.messageStatusId, queueMsgTemplate)">
                        Message
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link me-1" (click)="onViewQueuePayload(t.data.messageStatusId, queueMsgTemplate)">
                        Payload
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onQueueMessageResend(t.data.messageStatusId, queueMsgTemplate)">
                        Resend
                      </a>
                    </div>
                  </div>
                  <div *dxTemplate="let data of 'dateTemplate'">
                    <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</div>
                  </div>
              </dx-data-grid>
              </div>
            </div>
          </div>
        </div>

        <div class="tylie-accordion__panel accordion-item">
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#venCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Vendor LineItems
            </button>
          </h2>
          <div id="venCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body">
              <div class="tylie-accordion__body-content">
                <dx-data-grid class="tylie-grid" id="venGrid" [dataSource]="vendorLineItems" [allowColumnReordering]="true"
                  [allowColumnResizing]="true" [columnAutoWidth]="false" [rowAlternationEnabled]="true"
                  [showColumnLines]="true" [hoverStateEnabled]="true" [showRowLines]="true" [cacheEnabled]="false"
                  keyExpr="id">
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                  <dxi-column dataField="vendorName" caption="Name" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="vendorOrderNumber" caption="Order Number" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="status" caption="Status" cssClass="gridStyle"></dxi-column>
                  <dxo-search-panel [visible]="false"></dxo-search-panel>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                    [showNavigationButtons]="true" [showInfo]="true">
                  </dxo-pager>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-sorting mode="multiple"></dxo-sorting>
                  <dxo-header-filter [visible]="false"></dxo-header-filter>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>

        <div class="tylie-accordion__panel accordion-item">
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#oliCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Order LineItems In Progress (Network)
            </button>
          </h2>
          <div id="oliCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body">
              <div class="tylie-accordion__body-content">
                <dx-data-grid class="tylie-grid" id="oliGrid" [dataSource]="orderLineItems" [allowColumnReordering]="true"
                  [allowColumnResizing]="true" [columnAutoWidth]="false" [rowAlternationEnabled]="true"
                  [showColumnLines]="true" [hoverStateEnabled]="true" [showRowLines]="true" [cacheEnabled]="false"
                  keyExpr="orderLineItemId">
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                  <dxi-column dataField="orderLineItemId" caption="OrderLineItemId"
                    cssClass="gridStyle tyl-text-align-left"></dxi-column>
                  <dxi-column dataField="vendorName" caption="Vendor" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="status" caption="Status" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="vendorDestinationCode" caption="Vendor Destination Code"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="tiedId" caption="TiedId" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="status" caption="Status" cssClass="gridStyle"></dxi-column>
                  <dxi-column dataField="vendorLineItemId" caption="VendorLineItemId"
                    cssClass="gridStyle tyl-text-align-left"></dxi-column>
                  <dxi-column dataField="destinationCode" caption="Destination Code" cssClass="gridStyle"></dxi-column>

                  <dxo-search-panel [visible]="false"></dxo-search-panel>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                    [showNavigationButtons]="true" [showInfo]="true">
                  </dxo-pager>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-sorting mode="multiple"></dxo-sorting>
                  <dxo-header-filter [visible]="false"></dxo-header-filter>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>

        <div class="tylie-accordion__panel accordion-item">
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#spotExpectedCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Spots Expected
            </button>
          </h2>
          <div id="spotExpectedCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body">
              <div class="tylie-accordion__body-content">
                <dx-data-grid class="tylie-grid" id="postProdSpotsGrid" [dataSource]="postProdSpots" [allowColumnReordering]="true"
                  [allowColumnResizing]="true" [columnAutoWidth]="false" [rowAlternationEnabled]="true"
                  [showColumnLines]="true" [hoverStateEnabled]="true" [showRowLines]="true" [cacheEnabled]="false"
                  keyExpr="id" (onRowPrepared)="onPostProdSpotsRowPrepared($event)">
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                  <dxi-column width="5%" dataField="groupNumber" caption="Group#" cssClass="gridStyle"></dxi-column>

                  <dxi-column width="10%" dataField="postProdSpotAdId" caption="Ad-ID" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="20%" dataField="postProdSpotTitle" caption="Title" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="30%" dataField="outputFileName" caption="Filename" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="5%" dataField="postProdSpotStatus" caption="Status"
                    cssClass="gridStyle"></dxi-column>

                  <dxi-column width="10%" dataField="selectedSpotAdId" caption="Selected Ad-ID"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column width="20%" dataField="selectedSpotTitle" caption="Title" cssClass="gridStyle"></dxi-column>
                  <dxo-search-panel [visible]="false"></dxo-search-panel>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                    [showNavigationButtons]="true" [showInfo]="true">
                  </dxo-pager>
                  <dxo-group-panel [visible]="false"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-sorting mode="multiple"></dxo-sorting>
                  <dxo-header-filter [visible]="false"></dxo-header-filter>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>

        <div class="tylie-accordion__panel accordion-item">
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse"
              data-bs-target="#spotIngestCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Spots Ingested
            </button>
          </h2>
          <div id="spotIngestCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body">
              <div class="tylie-accordion__body-bar">
                <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onWorkflowsRefresh()">
                  <span class="tylie-button__icon"><icon-refresh></icon-refresh></span>
                  <span class="tylie-button__text">Refresh</span>
                </button>
              </div>
              <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
                <dx-data-grid class="tylie-grid" id="workflowsGrid" #workflowsGrid [dataSource]="workflows" [allowColumnReordering]="true"
                  [allowColumnResizing]="true" [columnAutoWidth]="false" [rowAlternationEnabled]="true"
                  [showColumnLines]="true" [hoverStateEnabled]="true" [showRowLines]="true" [cacheEnabled]="false"
                  (onRowPrepared)="onWorkflowsRowPrepared($event)" keyExpr="requestId">
                  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
                  <dxi-column width="6%" dataField="requestId" cssClass="gridStyle" sortOrder="desc"></dxi-column>
                  <dxi-column width="17%" dataField="reference" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="8%" dataField="action" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="8%" dataField="status" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="14%" dataField="payLoad" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="24%" dataField="resultPayload" caption="Result Payload"
                    cssClass="gridStyle"></dxi-column>
                  <dxi-column width="5%" dataField="retryCount" caption="Retry#" cssClass="gridStyle"></dxi-column>
                  <dxi-column width="10%" dataField="retryPickupDateTime" caption="Retry Pickup"
                    cellTemplate="dateTemplate" cssClass="gridStyle"></dxi-column>
                  <!--<dxi-column dataField="startedDateTime" caption="Stated" cellTemplate="dateTemplate" cssClass="gridStyle"></dxi-column>-->
                  <!--<dxi-column dataField="completedDateTime" caption="Completed" cellTemplate="dateTemplate" cssClass="gridStyle"></dxi-column>-->
                  <!--<dxi-column dataField="lastPollDateTime" caption="Last Poll" cellTemplate="dateTemplate" cssClass="gridStyle"></dxi-column>-->
                  <!--<dxi-column dataField="nextPollDateTime" caption="Next Poll" cellTemplate="dateTemplate" cssClass="gridStyle"></dxi-column>-->

                  <dxo-search-panel [visible]="false"></dxo-search-panel>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-selection mode="single"></dxo-selection>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                    [showNavigationButtons]="true" [showInfo]="true">
                  </dxo-pager>
                  <dxo-group-panel [visible]="false"></dxo-group-panel>
                  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                  <dxo-sorting mode="multiple"></dxo-sorting>
                  <dxo-header-filter [visible]="false"></dxo-header-filter>
                  <dxo-filter-row [visible]="false"></dxo-filter-row>
                  <div *dxTemplate="let data of 'dateTemplate'">
                    <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</div>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scrolltop" style="display: none;" (click)="onScrollToTop()"><span></span></div>
</div>

<ng-template #queueMsgTemplate>
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{messageTitle}}</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="isMessageNewMode == true">
      <div class="tylie-form-group form-group" [ngClass]="{error: queueEndpoint == ''}">
        <!--<input type="text" [(ngModel)]="queueEndpoint" class="form-control" placeholder="SQSQueue Arn" />-->
        <dx-select-box class="tylie-select form-control" [items]="queues" [(ngModel)]="queueEndpoint" placeholder="Choose Queue"></dx-select-box>
      </div>
    </ng-container>
    <div class="tylie-form-group form-group"
      [ngClass]="{error: (messageContent == '' && (isMessageResendMode == true || isMessageNewMode == true))}">
      <textarea [(ngModel)]="messageContent" class="tylie-textarea tylie-textarea--h350 form-control" cols="8" rows="20"></textarea>
    </div>
    <div class="d-flex">
      <button class="tylie-button tylie-button--xs me-3" (click)="onQueueMessageResendSubmit()"
        *ngIf="isMessageResendMode == true">Resend</button>
      <button class="tylie-button tylie-button--xs me-3" (click)="onSendNewMessageSubmit()"
        *ngIf="isMessageNewMode == true">Send</button>
      <button class="tylie-button tylie-button--xs" (click)="modalClose()">Close</button>
    </div>
  </div>
</ng-template>