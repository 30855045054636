import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { OrderGroupService, DeleteOrderMediaEvent, OrderServiceMediaViewModel, CreateVendorPORequest, Vendor, VendorPurchaseOrderViewModel, UpdateVendorDateEvent } from "../../../models/order/vendor-purchaseorder-request.model";
import { OrderService } from "../../../services/order/order.service";
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { CostCenterAndBillToObjects } from "../../../models/admin/client/createclient.model";
import { digitsOnlyValidator, EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { EditClientConfig } from '../../../models/admin/client/editclient.model';

@Component({
  selector: 'addcostcenterandbilltopopup',
  templateUrl: './addcostcenterandbilltopopup.component.html',
  styleUrls: ['./addcostcenterandbilltopopup.component.css'],
})
export class AddCostCenterAndBillToPopUp implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('costCenterList') costCenterList: string[] = [];
  @Input('billToList') billToList: string[] = [];
  @Input('cutDownServicesDataSource') cutDownServicesDataSource: string[] = [];
  @Input('reslateBundleServicesDataSource') reslateBundleServicesDataSource: string[] = [];
  @Input('cutDownServicesOfferedIds') cutDownServicesOfferedIds: number[] = [];
  @Input('reslateBundleServicesIds') reslateBundleServicesIds: number[] = [];
  @Input('podDistributionEmails') podDistributionEmails: string = '';
  @Input('stdStorageDays') stdStorageDays: string = '';
  @Input('infrqStorageDays') infrqStorageDays: string = '';
  @Input('archiveDurationInDays') archiveDurationInDays: string = '';
  @Input('activeTandemStorageInDays') activeTandemStorageInDays: string = '';
  @Input('editConfig') editConfig: EditClientConfig = null;
  @Input('allowEdits') allowEdits: boolean = false;
  @Input('isClientEdit') isClientEdit: boolean = false;
  @Input('title') title: string = "EDIT DETAIL";
  @Input('isWCPFileDeliveryBilled') isWCPFileDeliveryBilled: boolean = false;
  @Input('opsNotes') opsNotes: string = '';


  @ViewChild('treeServices', { static: false }) treeServices: DxTreeViewComponent;
  @ViewChild('rbtreeServices', { static: false }) rbtreeServices: DxTreeViewComponent;
  public costCenterAndBillToForm: FormGroup;
  public costCenters: FormArray = this.fb.array([]);
  public billTos: FormArray = this.fb.array([]);
  public modalRef: BsModalRef;
  public oncostCenterAndBillToAddEdit: Subject<CostCenterAndBillToObjects>;
  public servicesValue: Array<number>;
  public rbservicesValue: Array<number>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private util: Utilities) {

  }

  ngOnInit() {
    this.oncostCenterAndBillToAddEdit = new Subject<CostCenterAndBillToObjects>();

    this.costCenterAndBillToForm = this.fb.group({
      costCenters: this.addCostCenterFormArray() as FormArray,
      billTos: this.addbillToFormArray() as FormArray,
      cutDownServices: [this.cutDownServicesOfferedIds],
      reslatebundleServices: [this.reslateBundleServicesIds],
      podEmails: new FormControl(this.podDistributionEmails, [EmailListValidatorIfValueExists]),
      stdStorageDays: new FormControl(this.stdStorageDays, [digitsOnlyValidator]),
      infrqStorageDays: new FormControl(this.infrqStorageDays, [digitsOnlyValidator]),
      archiveDurationInDays: new FormControl(this.archiveDurationInDays, [digitsOnlyValidator]),
      activeTandemStorageInDays: new FormControl(this.activeTandemStorageInDays, [digitsOnlyValidator]),
      isWCPFileDeliveryBilled: new FormControl(this.isWCPFileDeliveryBilled),
      opsNotes: new FormControl(this.opsNotes)
    });

    this.servicesValue = this.cutDownServicesOfferedIds;
    this.rbservicesValue = this.reslateBundleServicesIds;
  }

  public get formCostCentersArray() {
    return <FormArray>this.costCenterAndBillToForm.get('costCenters');
  }

  public get formbillTosArray() {
    return <FormArray>this.costCenterAndBillToForm.get('billTos');
  }

  public onCostCenterAddMore() {
    const group = this.fb.group({
      costCenter: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern), Validators.maxLength(35)])],
    });

    this.costCenters.push(group);
  }

  public onBillToAddMore() {
    const group = this.fb.group({
      billTo: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern)])],
    });

    this.billTos.push(group);
  }

  public onCostCenterRemove(groupIndex: number) {
    var costCenterArray = this.costCenterAndBillToForm.get('costCenters') as FormArray
    costCenterArray.removeAt(groupIndex);
  }

  public onBillToRemove(groupIndex: number) {
    var billToArray = this.costCenterAndBillToForm.get('billTos') as FormArray
    billToArray.removeAt(groupIndex);
  }

  public oncostCenterAndBillToFormSubmit({ value, valid }: { value: CostCenterAndBillToObjects, valid: boolean }) {
    if (!valid) {
      return;
    }
    else {
      this.oncostCenterAndBillToAddEdit.next(value);
      this.closePopup();
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeServices && this.treeServices.instance);

    if (!component) return;

    if (this.servicesValue == null || !this.servicesValue.length) {
      component.unselectAll();
    }

    if (this.servicesValue != null) {
      this.servicesValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.servicesValue = newValues;
  }

  public syncrbTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.rbtreeServices && this.rbtreeServices.instance);

    if (!component) return;

    if (this.rbservicesValue == null || !this.rbservicesValue.length) {
      component.unselectAll();
    }

    if (this.rbservicesValue != null) {
      this.rbservicesValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public rbtreeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.rbservicesValue = newValues;
  }

  private addCostCenterFormArray() {
    if (this.costCenterList.length > 0) {
      this.costCenterList.forEach(ccenter => {
        if (this.util.notEmpty(ccenter)) {
          const group = this.fb.group({
            costCenter: [ccenter, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern), Validators.maxLength(35)])]
          });
          this.costCenters.push(group);
        }
      });
    }
    else {
      this.onCostCenterAddMore();
    }
    return this.costCenters;
  }

  private addbillToFormArray() {
    if (this.billToList.length > 0) {
      this.billToList.forEach(billto => {
        if (this.util.notEmpty(billto)) {
          const group = this.fb.group({
            billTo: [billto, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern)])]
          });
          this.billTos.push(group);
        }
      });
    }
    else {
      this.onBillToAddMore();
    }
    return this.billTos;
  }
}
