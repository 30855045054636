<div class="tylie-accordion accordion" id="search-all-media-section">
  <div class="tylie-accordion__panel accordion-item" id="orderSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#clientSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH ALL CLIENTS
      </button>
    </h2>
    <div id="clientSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">

      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Client Name" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="clearQuickSearch()"
                       (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col-6">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>

        <div class="tylie-section collapse" id="advancedSearchContainer" >
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
          <form novalidate role="search" (ngSubmit)="onFormSubmit(searchClientFormGroup)" [formGroup]="searchClientFormGroup">
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="client">Client</label>
                  <input type="text" name="clientName" id="clientName" class="tylie-text form-control" formControlName="clientName">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                name="status" id="status" class="tylie-select form-control"
                                formControlName="status"
                                [searchEnabled]="true"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="brand">Brand</label>
                  <dx-select-box [dataSource]="brandDataSource"
                                name="brand" id="brand" class="tylie-select"
                                formControlName="brand"
                                [searchEnabled]="true"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cf">Created From</label>
                  <dx-date-box type="date" id="cf" class="tylie-datepicker" formControlName="createdFrom" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="ct">Created To </label>
                  <dx-date-box type="date" id="ct" class="tylie-datepicker" formControlName="createdTo" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>  
            </div>


            <div class="d-flex justify-content-end">
                <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>

        <div class="px-4 py-3">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="exportSpots()"><span class="tylie-button__icon"><icon-export></icon-export></span><span class="tylie-button__text">Export</span></button>
        </div>

      
        <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
          <div id="clientview">
            <dx-data-grid id="clientSearch" class="tylie-grid"
                          #clientSearch
                          [dataSource]="resultClientStore"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [hoverStateEnabled]="true"
                          keyExpr="id"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          (onExporting)="onExporting()"
                          (onExported)="onExported()"
                          [wordWrapEnabled]="true">

              <dxi-column dataField="name" caption="Client Name" cellTemplate="clientNameTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="asc" width="13%"></dxi-column>
              <dxi-column dataField="aliasId" caption="Client ID" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
              <dxi-column dataField="brands" caption="Brands" cellTemplate="brandsTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [allowSorting]="false" width="21%"></dxi-column>
              <dxi-column dataField="accountExecutiveUserProfileName" caption="CX Manager" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [allowSorting]="false" width="13%"></dxi-column>
              <dxi-column dataField="city" caption="City/ State" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [allowSorting]="false" width="15%"></dxi-column>
              <dxi-column dataField="status" caption="Status" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
              <dxi-column dataField="lastOrderDate" cellTemplate="dateTemplate" caption="Last Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [allowSorting]="false" width="10%"></dxi-column>
              <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created Date" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>

              <dxi-column dataField="phoneNumber" caption="Phone#" width="0%" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [visible]="false"></dxi-column>
              <dxi-column dataField="contactName" caption="Contact Name" width="0%" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [visible]="false"></dxi-column>
              <dxi-column dataField="address" caption="Address" width="0%" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [visible]="false"></dxi-column>

              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
              </div>

              <div *dxTemplate="let t of 'clientNameTemplate'">
                <span class="quickLink" (click)='showClientViewDetail(t.data.clientGuid)'>{{t.data.name}}</span>
              </div>

              <div *dxTemplate="let data of 'brandsTemplate'">
                <span class="tyl-wordwrap">{{data.value | joinBySeperator: '; '}}</span>
              </div>
              <dxo-export [enabled]="false" fileName="Clients" [allowExportSelectedData]="true"></dxo-export>
              <dxo-remote-operations [sorting]="true"
                                      [paging]="true">
              </dxo-remote-operations>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                          [allowedPageSizes]="[10, 20, 50, 100]"
                          infoText="Page {0} of {1} ({2} items)"
                          [showNavigationButtons]="true"
                          [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
