import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigService } from '../../../services/core/config.service';
import { SelectedDestinationListRequest } from '../../../models/order/order-newdestinationlist.model';
import { OrderUserDestination } from '../../../models/order/order-userdestination';

@Component({
  selector: 'ordersavetodestinationlistpopup',
  templateUrl: './ordersavetodestinationlistpopup.component.html',
  styleUrls: ['./ordersavetodestinationlistpopup.component.css'],
})
export class OrderSaveDestinationToListPopupComponent implements OnInit {

  @Input() destinationsToAdd: Array<string>;
  @Output() saveToListComplete = new EventEmitter<void>();

  public destinationListRequest: FormGroup;
  public userDestinationLists: Array<OrderUserDestination>;

  constructor(
    private configService: ConfigService,
    private alertService: AlertService,
    private util: Utilities) {
  }

  ngOnInit() {

    this.destinationListRequest = new FormGroup({
      newDestinationListName: new FormControl(null, Validators.required)
    });

    this.loadDestinationLists();
  }

  private loadDestinationLists() {
    this.configService.getUserSavedDestinationLists("list").subscribe((res: any) => {
      if (res.isSuccess == true) {
        var destLists = res.result as Array<OrderUserDestination>;
        this.userDestinationLists = destLists;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error)) {
          this.close();
        }
      });
  }

  public onSelectDestinationList(e) {

    this.configService.addToUserSavedDestinationList(e.value, "list", this.destinationsToAdd).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.destinationsAddedToList, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.close();
    },
      error => {
        this.util.handleError(error);
        this.close();
      });
  }

  public createNewDestinationList({ value, valid }: { value: SelectedDestinationListRequest, valid: boolean }) {
    if (this.destinationListRequest.controls['newDestinationListName'].errors)
      return;

    this.configService.createUserSavedDestinationList(value.newDestinationListName, "list", this.destinationsToAdd).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.destinationsListCreated, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.close();
    },
      error => {
        this.util.handleError(error);
        this.close();
      });
  }

  public close() {
    this.saveToListComplete.emit();
  }
}
