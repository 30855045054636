import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ProjectService } from '../../../services/project/create-project.service';
import { ProjectFolder } from '../../../models/project/create-project.model';
import { FileUploadQueue } from '../../../services/project/multipart-file-upload-handler.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiEndPoint } from '../../../configurations/api-endpoint.service';
import { FileUploadModel, ProjectFile } from '../../../models/project/project-file-upload.model';
import { SinglePartFileUploadService } from '../../../services/project/singlepart-file-upload-handler.service';
import { Constants } from '../../../configurations/constants';
import { Utilities } from '../../../services/core/utilities';

@Component({
  selector: 'addfiles-to-project',
  templateUrl: './addfilestoprojectpopup.component.html',
  styleUrls: ['./addfilestoprojectpopup.component.css'],
})
export class AddFilesToProjectPopUp implements OnInit, OnDestroy {
  @ViewChild('project_file', { static: true }) projectfile;
  public onClose: Subject<boolean>;
  public modalRef: BsModalRef;
  addFilesToProjectForm: FormGroup;
  folders: ProjectFolder[];
  subscriptions: Subscription[] = [];
  @Input() projectId: number;
  postModel = {} as ProjectFile;
  fileUploadModel = {} as FileUploadModel;
  isFileSelected: boolean;
  selectedFiles: { fileGuid: string, fileName: string }[] = [];
  maxFilesUploadError = "You can only upload a max of 5 files";
  isUploading = false;
  isMaxFilesExceeded = false;
  isFileBrowsed = false;
  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private singlePartUploadService: SinglePartFileUploadService,
    private util: Utilities) {

    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.addFilesToProjectForm = this.fb.group({
      folderId: new FormControl()
    });
    this.loadFolders();
  }

  loadFolders(): void {
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectService.loadProjFolders(this.projectId).subscribe((folders) => {
      this.alertService.ShowLoader(false);
      this.folders = folders.result;
    }, error => {
      this.alertService.ShowLoader(false);
      if (this.util.handleError(error))
        this.closePopup();
    }));
  }

  onFolderChange(e): void {
    console.log(e);
  }

  incomingfile(event) {
    if (!event.target.files.length) {
      return;
    }
    if (this.selectedFiles.length >= 5) {
      this.isMaxFilesExceeded = true;
    }
    else {
      for (const uploadFile of event.target.files) {
        this.isFileSelected = false;
        this.isFileBrowsed = false;
        this.uploadProjectFile(uploadFile);
      }
    }
  }

  addFilesToProject() {
    if (this.addFilesToProjectForm.valid) {
      if (!this.selectedFiles.length) {
        this.isFileBrowsed = true;
        return;
      }
      this.alertService.ShowLoader(true);
      this.composePostModel();
      this.subscriptions.push(this.singlePartUploadService.addFiles(this.postModel).subscribe((res) => {
        this.alertService.ShowLoader(false);
        if (res.isSuccess) {
          this.onClose.next(true);
          this.closePopup();
        }
      }, error => {
        this.alertService.ShowLoader(false);
        if (error.status === 403) {
          this.alertService.showMessage("ERROR", `You do not have access to add files to the ${this.postModel.folderId ? 'folder' : 'project'}.Please contact your admin.`, MessageSeverity.error, true);
        }
        else {
          this.util.handleError(error)
        }
      }));
    }
  }

  uploadProjectFile(currentBrowsedFile) {
    this.composeUploadFileModel(currentBrowsedFile);
    if (this.selectedFiles.find(x => x.fileName.toLowerCase() === this.fileUploadModel.fileName.toLowerCase())) {
      this.alertService.showMessage("ERROR", `Browsed file is already selected!`, MessageSeverity.error, true);
      return;
    }
    this.isUploading = true;
    if (currentBrowsedFile.size <= Constants.singlepart_max_file_size) // Single part upload
    {
      this.subscriptions.push(this.singlePartUploadService.uploadProjectFile(this.fileUploadModel, currentBrowsedFile).subscribe((x: any) => {
        if (x.fileGuid) {
          this.selectedFiles.push({
            fileGuid: x.fileGuid,
            fileName: currentBrowsedFile.name
          });
          //this.alertService.showMessage("SUCCESS", `File ${currentBrowsedFile.name} uploaded successfully!`, MessageSeverity.success, true);

          this.isUploading = false;
        }
        else if (!x) {
          this.alertService.showMessage("ERROR", Constants.error500, MessageSeverity.error, true);
          this.isUploading = false;
        }
        else {
          this.isUploading = false;
          if (x.status === 403) {
            this.alertService.showMessage("ERROR", `You do not have access to add files to the ${this.fileUploadModel.folderId ? 'folder' : 'project'}.Please contact your admin.`, MessageSeverity.error, true);
          }
          else {
            this.util.handleError(x);
          }
        }
        this.projectfile.nativeElement.value = null;
      }, error => {
        this.util.handleError(error);
      }));
    }
    else { // Multi part upload
      const file = new FileUploadQueue(this.fileUploadModel, currentBrowsedFile, this.http, this.apiendPoint);
      this.subscriptions.push(file.status.subscribe(val => {
        // show the message
        if (val) {
          this.selectedFiles.push({
            fileGuid: file.fileGuid,
            fileName: currentBrowsedFile.name
          });
        }
        else {
          if (currentBrowsedFile == null) {
            this.alertService.showMessage("ERROR", `Upload failed, Please try again later.`, MessageSeverity.error, true);
          }
        }
        this.projectfile.nativeElement.value = null;
        this.isUploading = false;
      }));

      this.subscriptions.push(file.forbiddenStatus.subscribe(val => {
        // show the error message
        if (val) {
          this.alertService.showMessage("ERROR", `You do not have access to add files to the ${this.fileUploadModel.folderId ? 'folder' : 'project'}.Please contact your admin.`, MessageSeverity.error, true);
        }
        this.projectfile.nativeElement.value = null;
        this.isUploading = false;
      }));
    }
  }

  composeUploadFileModel(currentBrowsedFile) {
    const folderId = this.addFilesToProjectForm.controls['folderId'].value;
    this.fileUploadModel = {
      projectId: this.projectId,
      fileName: currentBrowsedFile.name,
      folderId: folderId
    }
  }

  composePostModel() {
    const folderId = this.addFilesToProjectForm.controls['folderId'].value;
    this.postModel = {
      projectId: this.projectId,
      files: this.selectedFiles,
      folderId: folderId
    }
  }

  removeFile(file): void {
    this.selectedFiles = this.selectedFiles.filter(x => x.fileGuid !== file.fileGuid);
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
