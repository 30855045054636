import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../../../services/core/utilities";
import { AdminService } from "../../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { DxDataGridComponent, DxTreeViewComponent } from "devextreme-angular";
import { UserProfileDetail, UserEditConfig } from "../../../../../models/user/user-profile-detail";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { customEmailValidator, customPhoneValidator } from "../../../../../configurations/custom-validations";
import { UserClientBrand, UserBrandProduct, UserRole } from "../../../../../models/user/create-user";
import { Constants } from "../../../../../configurations/constants";
import { UserRoles } from "../../../../../configurations/user-roles";
import { PostHouseAddProductPopUp } from "../../../../popups/posthouseaddproduct/posthouseaddproductpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ProductModel } from "../../../../../models/admin/brand/createproduct.model";
import { EditUserProfile } from "../../../../../models/user/edit-user";
import { ErrorPopupComponent } from "../../../../popups/errorpopup/errorpopup.component";
import { userProfileRoles } from "../../../../../configurations/enums/enums";
import { AdmintranscodepreferencessettingsComponent } from '../../../../popups/admintranscodepreferencessettings/admintranscodepreferencessettings.component';

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  @Input('userProfileGuid') userProfileGuid: string = null;
  @ViewChild('brandsGrid', { static: false }) brandsGrid: DxDataGridComponent;
  @ViewChild('treeUserGrps', { static: false }) treeUserGrps: DxTreeViewComponent;
  @Output() onEditCompleteOrCancel: EventEmitter<UserProfileDetail> = new EventEmitter<UserProfileDetail>();

  public editUserFormGroup: FormGroup;
  public editUserConfig: UserEditConfig = null;
  public userClientBrandDataSource: UserClientBrand[] = null;
  public timeZoneStore: any[] = [];
  public selectedBrands: number[] = [];
  public networkSpecifications: any[] = []
  public mediaApproverSelected: boolean = false;
  public detail: UserProfileDetail = null;
  public modalRef: BsModalRef;
  public enableAdvancedFileNaming: boolean = false;
  public userGrpsValue: Array<number>;
  public userGrpDataSource: any[] = [];

  public usersRoute = RouteConstants.usersRoute;
  public phoneMask = Constants.phoneMask;

  constructor(public util: Utilities
    , public adminService: AdminService
    , private alertService: AlertService
    , private fb: FormBuilder
    , private userRoles: UserRoles
    , private modalService: BsModalService) {

  }

  ngOnInit() {
    this.editUserFormGroup = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.compose([customEmailValidator])]],
      phone: [null, Validators.compose([customPhoneValidator])],
      timeZone: [null, Validators.required],
      userRole: this.fb.group({
        userRoles: this.fb.array([])
      }),
      enableAdvancedFileNaming: [false],
      specifications: [null],
      dealerName: [null],
      dealerNumber: [null],
      userGroups: [null]
    });

    if (this.userProfileGuid != null) {
      this.getUserProfileView(this.userProfileGuid);
    }
  }

  public onFormSubmit({ value, valid }: { value: EditUserProfile, valid: boolean }) {
    if (!valid) {
      return;
    }

    if (this.util.notEmpty(this.brandsGrid) && this.util.notEmpty(this.brandsGrid.instance)) {
      value.brands = this.brandsGrid.instance.getSelectedRowsData();
    }

    if (this.validateCreateUserRequest(value.brands, value.userRole)) {
      value.specifications = this.networkSpecifications;
      this.editUserProfile(value, this.detail.userProfileGuid);
    }
  }

  public onSelectionChangedApprovalLvl(e: any, data: UserClientBrand) {
    if (this.util.notEmpty(e)) {
      data.userClientBrandApprovalLevel = e.selectedItem;
    }
  }

  public onCancelEdits() {
    this.onEditCompleteOrCancel.emit(this.detail);
  }

  public getNameFromDisplayName(standardName: string): string {
    for (let tz of this.timeZoneStore) {
      if (tz.standardName == standardName) { return tz.displayName; }
    }
  }

  public getStandardNameFromDisplayName(displayName: string): string {
    for (let tz of this.timeZoneStore) {
      if (tz.displayName == displayName) { return tz.standardName; }
    }
  }

  public onContentReady(e: any) {
    if (this.util.notEmpty(e) && this.util.notEmpty(e.component)) {
      e.component.selectRows(this.selectedBrands, true);
    }
  }

  public onMediaApproverRoleUpdatedEventTriggered(e: any) {
    this.mediaApproverSelected = e;

    if (this.util.notEmpty(this.userClientBrandDataSource)) {
      this.userClientBrandDataSource.forEach(cb => {
        if (cb.hasApprovalLevels == true) {
          cb.userClientBrandApprovalLevel = null;
        }
        else {
          cb.userClientBrandApprovalLevel = 1;
        }
      });
    }
  }

  public addClientBrandProduct(data: any) {
    if (this.util.notEmpty(data.products)) {
      var productKeys: number[] = data.products.map(product => product.productId);
    }
    var initialState = {
      clientId: this.detail.isPostHouseUser == true ? this.detail.editorialHouse.id : this.detail.client.id,
      agencyId: this.detail.isPostHouseUser == true ? this.detail.client.id : null,
      brandId: data.brandId,
      productKeys: productKeys
    };

    this.modalRef = this.modalService.show(PostHouseAddProductPopUp, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe((result: ProductModel[]) => {
      var userBrandProducts: UserBrandProduct[] = [];
      result.forEach(res => {
        var product = new UserBrandProduct();
        product.productGuid = res.productGuid;
        product.productId = res.id;
        product.name = res.name;

        userBrandProducts.push(product);
      });

      data.products = userBrandProducts;
    });
  }

  private updateEditClientForm(detail: UserProfileDetail) {
    this.editUserFormGroup.controls['firstName'].setValue(detail.firstName);
    this.editUserFormGroup.controls['lastName'].setValue(detail.lastName);
    this.editUserFormGroup.controls['email'].setValue(detail.email);
    this.editUserFormGroup.controls['phone'].setValue(detail.workPhone);
    this.editUserFormGroup.controls['timeZone'].setValue(detail.timeZone);
    this.editUserFormGroup.controls['dealerName'].setValue(detail.dealerName);
    this.editUserFormGroup.controls['dealerNumber'].setValue(detail.dealerNumber);

    this.editUserFormGroup.controls['enableAdvancedFileNaming'].setValue(detail.enableAdvancedFileNaming);
    this.enableAdvancedFileNaming = detail.enableAdvancedFileNaming;

    if (this.util.notEmpty(detail.userRole) && this.util.notEmpty(detail.userRole.userRoles)) {
      this.editUserRolesFormArray(detail.userRole.userRoles);
    }

    if (this.userGrpsValue != null) {
      this.editUserFormGroup.controls['userGroups'].setValue(this.userGrpsValue);
    }
  }

  private editUserProfile(value: EditUserProfile, userProfileGuid: string) {
    this.adminService.editUserProfile(value, userProfileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result as UserProfileDetail;
        result.avatar = this.util.notEmpty(result.avatar) ? Constants.avatarEncoding + result.avatar : null;
        this.alertService.showMessage("SUCCESS", Constants.editUserProfileSuccessful, MessageSeverity.success);
        this.onEditCompleteOrCancel.emit(result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private validateCreateUserRequest(brands: UserClientBrand[], userRole: UserRole) {
    var selectionValid = true;
    if ((brands != null || brands != undefined) && brands.length > 0) {
      brands.forEach(bs => {
        if (bs.hasBrandProducts && (bs.products == null || bs.products == undefined || bs.products.length == 0)) {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.productsNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          selectionValid = false;
        }
        else if (this.util.notEmpty(userRole) && (this.util.notEmpty(userRole.userRoles)) && (userRole.userRoles.find(r => r.roleId == userProfileRoles.MediaApprover && r.isChecked == true) != undefined)
          && bs.clientBrandApprovalLevels != null && bs.userClientBrandApprovalLevel == null) {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.approvalLevelsNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          selectionValid = false;
        }
      });
    }
    return selectionValid;
  }

  private getEditUserConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.adminService.getEditUserProfileConfig(this.userProfileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.editUserConfig = res.result as UserEditConfig;
        this.alertService.ShowLoader(false);

        if (this.util.notEmpty(this.editUserConfig.userClientBrands)) {
          this.userClientBrandDataSource = this.editUserConfig.userClientBrands;
          this.setProductsForUserClientBrands();
        }
        if (this.editUserConfig.specifications != null) {
          this.networkSpecifications = this.editUserConfig.specifications;
        }
        this.timeZoneStore = this.editUserConfig.timeZones;
        this.userGrpDataSource = this.editUserConfig.clientUserGroups;

        this.updateEditClientForm(this.detail);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private setProductsForUserClientBrands() {
    if (this.util.notEmpty(this.detail.userClientBrands)) {
      this.detail.userClientBrands.forEach(obj => {
        this.selectedBrands.push(obj.brandId);
        var brand = this.userClientBrandDataSource.find(b => b.brandId == obj.brandId);
        if (brand != undefined && brand != null) {
          brand.products = obj.products;
          brand.productsDisplay = obj.productsDisplay;
        }
      });
    }
  }

  private editUserRolesFormArray(rolesNotifications: any[]) {
    var roleFormGroup = this.editUserFormGroup.get('userRole');
    var rolesArray = <FormArray>roleFormGroup.get('userRoles') as FormArray;
    rolesNotifications.forEach(obj => {
      if (obj.roleName == this.userRoles.getMediaApproverRoleName() && obj.isChecked == true) {
        this.mediaApproverSelected = true;
      }
      const group = this.fb.group({
        isChecked: [obj.isChecked],
        roleId: [obj.roleId],
        roleName: [obj.roleName]
      });
      rolesArray.push(group);
    });
    return rolesArray;
  }

  private getUserProfileView(userProfileGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getUserProfileView(userProfileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.getEditUserConfig();
        this.detail = res.result as UserProfileDetail;
        this.detail.avatar = this.util.notEmpty(this.detail.avatar) ? Constants.avatarEncoding + this.detail.avatar : null;

        if (this.detail.userClientGroups != null) {
          this.userGrpsValue = this.detail.userClientGroups.map(t => t.clientUserGroupId);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onAddDistributionSettings() {
    var initialState = {
      enableAdvancedFileNaming: this.editUserFormGroup.controls['enableAdvancedFileNaming'].value,
    };

    this.modalRef = this.modalService.show(AdmintranscodepreferencessettingsComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        this.editUserFormGroup.controls['enableAdvancedFileNaming'].setValue(result.enableAdvancedFileNaming);
        this.enableAdvancedFileNaming = result.enableAdvancedFileNaming;

        result.specifications.forEach(spec => {
          if (this.networkSpecifications.find(t => t.id == spec.specificationMappingId) == undefined) {
            this.networkSpecifications.push({ id: spec.specificationMappingId, name : spec.name});
          }
        });
      }
    });
  }

  public onRemoveSpecMapping(spec) {
    this.networkSpecifications = this.networkSpecifications.filter(ns => ns.id != spec.id);
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeUserGrps && this.treeUserGrps.instance);

    if (!component) {
      return;
    }

    if (this.userGrpsValue == null || !this.userGrpsValue.length) {
      component.unselectAll();
    }

    if (this.userGrpsValue != null) {
      this.userGrpsValue.forEach((value) => {
        component.selectItem(value);
      });
    }
  }

  public treeView_itemSelectionChanged(e) {
    this.userGrpsValue = e.component.getSelectedNodesKeys();
  }
}
