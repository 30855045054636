import { Injectable, EventEmitter, Output } from '@angular/core';
import { Observable ,  Subject } from "rxjs";
import { LogMessage, LogSeverity } from "../../models/core/logmessage.model";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AlertService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) {

  }

  @Output('isshowLoading') ShowLoading: EventEmitter<boolean> = new EventEmitter();

  public ShowLoader(e: boolean) {
    this.ShowLoading.emit(e);
  }

  public logDebug(msg: any) {
    console.debug(msg);
  }

  public logError(msg: any) {
    console.error(msg);
  }

  public logInfo(msg: any) {
    console.info(msg);
  }

  public logMessage(msg: any) {
    console.log(msg);
  }

  public logTrace(msg: any) {
    console.trace(msg);
  }

  public logWarning(msg: any) {
    console.warn(msg);
  }

  public logDB(message: string, level: LogSeverity) {
    let msg: LogMessage = { message: message, level: LogSeverity[level] };

    this.http.post(this.apiendPoint.getLogEndpoint(), msg).subscribe(res => {
    },
      error => {
      });
  }

  private messages = new Subject<AlertMessage>();

  public getMessageEvent(): Observable<AlertMessage> {
    return this.messages.asObservable();
  }

  public showMessage(summary: string, detail: string, severity: MessageSeverity, isSticky: boolean = false) {

    this.messages.next({ severity: severity, summary: summary, detail: detail, isSticky: isSticky });
  }

  public safeStringify(object: any) {

    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    }
    catch (error) {

    }

    let simpleObject: any = {};

    for (let prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof (object[prop]) == 'object') {
        continue;
      }
      if (typeof (object[prop]) == 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }

    result = "[***Sanitized Object***]: " + JSON.stringify(simpleObject);

    return result;
  }
}

export class AlertMessage {
  constructor(public severity: MessageSeverity, public summary: string, public detail: string, public isSticky: boolean) { }
}

export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn,
  wait
}
