<div id="saved-destinations" class="search-all-media all-orders">
  <div class="modal-header">
    <button type="button" class="modal-close delete" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Search - Destinations</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <p class="p-extra-small black">Click on a row to select.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="input-group add-on col-md-8 col-sm-8  col-xs-6 search-text">
          <input class="form-control" placeholder="Search By Code or Name or Aka" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                 (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
          </div>
        </div>
      </div>
    </div>

    <div class="my-4">
      <dx-data-grid class="tylie-grid tylie-grid--bordered " id="destinationsGrid"
                    #destinationsGrid
                    [dataSource]="dataSource"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [rowAlternationEnabled]="true"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showRowLines]="true"
                    keyExpr="id">
        <dxo-selection mode="single"
                       selectAllMode="page"
                       allowSelectAll="Select"
                       showCheckBoxesMode="always">
        </dxo-selection>
        <dxi-column dataField="code" width="20%" cssClass="gridStyle" caption="Destination ID" sortOrder="asc"></dxi-column>
        <dxi-column dataField="name" width="40%" cssClass="gridStyle" caption="Name"></dxi-column>
        <dxi-column dataField="destinationType" width="20%" cssClass="gridStyle" caption="Type"></dxi-column>

        <dxo-remote-operations [sorting]="true"
                               [paging]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[5, 10, 20, 50]"
                   infoText="Page {0} of {1} ({2} items)"
                   [showNavigationButtons]="true"
                   [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="button" (click)="onAddAsDefaultHub()">ADD</button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>

  </div>
</div>
