<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Confirm</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <div [innerHTML]="mainMessage"></div>
  </div>
  <div class="d-flex justify-content-end">
    <button class="tylie-button tylie-button--xs me-3" type="button" (click)="onYes()">Yes</button>
    <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onNo()">No</button>
  </div>
</div>
