export class CreateProductModel {
  public productName: string;
  public shortName: string;
  public productGuid: string;
  public brandId: number;
}

export class ProductModel {
  public id: number;
  public shortName: string;
  public clientBrandProductId: number;
  public productGuid: string;
  public code: string;
  public name: string;
  public inVoiceTo: string[];
  public costCenter: string[];
  public costCenterDisplay: string;
  public billToDisplay: string;
  public podDistributionEmails: string;
  public isTrafficEmailInPODDisplayed: boolean;
  public archiveDurationInDays: string;
  public infrqStorageDays: string;
  public stdStorageDays: string;
  public activeTandemStorageInDays: string;
}

export class EditProduct {
  public productName: string;
  public shortName: string;
  public brandId: number;
}
