<div id="share-links">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{addMoreMode == true ? 'ADD NEW FTP' : 'APPLY TO ORDER - FTP UPLOAD'}}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8" *ngIf="addMoreMode == false">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Name" (keyup.enter)="onQuickSearchDestinations()"
                 name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
          </div>
        </section>
      </div>

      <div class="col-md-4 d-flex justify-content-end" >
        <button class="tylie-button tylie-button--xs" type="button" (click)="onShowFtpList()" *ngIf="savedFtpListMode == true && addMoreMode == false && allowAllFTP == true">FTP LIST</button>
     
        <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" *ngIf="savedFtpListMode == true && addMoreMode == false" type="button" (click)="onAddMore()">
          <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
          <span class="tylie-button__text">ADD NEW</span>
        </button>
        <button class="tylie-button tylie-button--xs ms-3" *ngIf="savedFtpListMode == false" type="button" (click)="onShowSavedFtpList()">SAVED FTP LIST</button>
      </div>
    </div>

    <div class="row" *ngIf="addMoreMode == true">
      <form [formGroup]="createFtpDestinationRequest" #ftpForm="ngForm" (ngSubmit)="onCreateFtpDestinationFormSubmit(createFtpDestinationRequest)">
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpUserName'].errors && ftpForm.submitted}">
            <label class="tylie-form-group__lbl" for="ftpusername">Username<span class="required">*</span></label>
            <input type="text" id="ftpusername" name="ftpusername" class="tylie-text form-control" formControlName="ftpUserName">
            <p class="p-extra-small">Please enter Username</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpServer'].errors && ftpForm.submitted}">
            <label class="tylie-form-group__lbl" for="server">FTP Server<span class="required">*</span></label>
            <input type="text" id="server" name="server" class="tylie-text form-control" formControlName="ftpServer">
            <p class="p-extra-small">Please enter FTP Server</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpPassword'].errors && ftpForm.submitted}">
            <label class="tylie-form-group__lbl" for="ftppassword">FTP Password<span class="required">*</span></label>
            <input type="password" id="ftppassword" name="ftppassword" class="tylie-text form-control" formControlName="ftpPassword">
            <p class="p-extra-small">Please enter FTP Password</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="ftpport">FTP Port#<span class="required">*</span></label>
            <input type="text" id="ftpport" name="ftpPort" class="tylie-text form-control" formControlName="ftpPort">
          </div>
        </div>
        <div class="col-md-6">
          <div class="tylie-form-group form-group" [ngClass]="{error: createFtpDestinationRequest.controls['name'].errors && createFtpDestinationRequest.controls['id'].errors && ftpForm.submitted}">
            <label class="tylie-form-group__lbl" for="ftplist">Update to Existing FTP</label>
            <dx-select-box [dataSource]="ftpSavedDestinationsDataSource"
                            valueExpr="id"
                            displayExpr="name"
                            formControlName="id"
                            class="tylie-select form-control"
                            name="ftplist"
                            placeholder="Select an exisiting FTP">
            </dx-select-box>
          </div>
        </div>

        <div class="col-md-6">
          <div class="tylie-form-group form-group" [ngClass]="{error: createFtpDestinationRequest.controls['name'].errors && createFtpDestinationRequest.controls['id'].errors && ftpForm.submitted}">
            <label class="tylie-form-group__lbl" for="newftp">Add New FTP</label>
            <input type="text" name="newftp" placeholder="Name your FTP" class="tylie-text form-control" formControlName="name">
            <p class="p-extra-small">Please enter an name for your FTP or choose an exisiting one</p>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs" type="submit">Save FTP</button>
          <button class="tylie-button tylie-button--xs tylie-button--link ms-3" type="button" (click)="onCancelAddMore()">Cancel</button>
        </div>
      </form>
    </div>

    <ng-container *ngIf="savedFtpListMode == true && addMoreMode == false">       
        <dx-data-grid class="my-4 tylie-grid tylie-grid--bordered" id="ftpSavedDestinationsGrid"
                      #ftpSavedDestinationsGrid
                      [dataSource]="ftpSavedDestinationsDataSource"
                      [allowColumnReordering]="false"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [showColumnLines]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="name" width="20%" cssClass="gridStyle" caption="Name"></dxi-column>
          <dxi-column dataField="ftpUserName" width="20%" cssClass="gridStyle" caption="UserName"></dxi-column>
          <dxi-column dataField="ftpServer" width="35%" cssClass="gridStyle" caption="Server"></dxi-column>
          <dxi-column dataField="ftpPort" width="10%" cssClass="gridStyle" caption="Port"></dxi-column>
          <dxi-column dataField="name" width="15%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate"></dxi-column>
          <div *dxTemplate="let t of 'actionsTemplate'">
            <div style="text-align:center;">
              <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onSavedFtpDestinationDelete(t.data.id)">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </a>
              <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onSavedFtpDestinationEdit(t.data.id)">
                <i class="glyphicon glyphicon-edit"></i>
              </a>
            </div>
          </div>
          <dxo-paging [pageSize]="5"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[5, 10, 15]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
    </ng-container>

    <ng-container *ngIf="savedFtpListMode == false && addMoreMode == false">
      <dx-data-grid class="my-4 tylie-grid tylie-grid--bordered" id="ftpDestinationsGrid"
                    #ftpDestinationsGrid
                    [dataSource]="ftpDestinationsDataSource"
                    [allowColumnReordering]="false"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [rowAlternationEnabled]="true"
                    [showColumnLines]="true"
                    [hoverStateEnabled]="true"
                    [showRowLines]="true"
                    keyExpr="id">
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-selection mode="multiple"
                        selectAllMode="page"
                        allowSelectAll="Select"
                        showCheckBoxesMode="always">
        </dxo-selection>
        <dxi-column dataField="name" width="25%" cssClass="gridStyle" caption="Name"></dxi-column>
        <dxi-column dataField="ftpUserName" width="25%" cssClass="gridStyle" caption="UserName"></dxi-column>
        <dxi-column dataField="ftpServer" width="40%" cssClass="gridStyle" caption="Server"></dxi-column>
        <dxi-column dataField="ftpPort" width="10%" cssClass="gridStyle" caption="Port"></dxi-column>
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                    [allowedPageSizes]="[5, 10, 15]"
                    [showNavigationButtons]="true"
                    [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </ng-container>

    <ng-container *ngIf="addMoreMode == false">
      <div class="d-flex justify-content-end">
        <div *ngIf="isEdit == false" class="tylie-form-group form-check">
          <input class="form-check-input" type="checkbox" name="apply-to-all-groups" id="apply-to-all-groups" [ngModelOptions]="{standalone: true}" [(ngModel)]="applyToAllGroups">
          <label class="form-check-label" for="apply-to-all-groups">Apply To All Groups</label>
        </div>
        <button type="button" class="tylie-button tylie-button--xs ms-3" (click)="onApplyToOrder()">APPLY TO ORDER</button>
      </div>

      <p align="right" class="col-md-12 gray1">* Add Additional Destinations from Order Build</p>
    </ng-container>

    
  </div>
</div>
