import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { LocalStoreManager } from '../core/local-store-manager.service';
import { EndpointFactory } from '../../configurations/endpoint-factory.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AlertService } from '../core/alert.service';
import { SpotComment } from "../../models/spot/spotdetail-model";
import { SpotEditRequest } from "../../models/spot/spot.edit-request";
import { SpotUpdateApprovalRequest } from "../../models/spot/spot-update-approval-request.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaCustomTagAddEditRequest } from '../../models/spot/addeditmediacustomtag.model';
import { PurgeMedia } from '../../models/spot/purgemedia.model';

@Injectable()
export class MediaDetailService {

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getSpotDetail(spotFileGuid: string) {
    return this.http.get(this.apiendPoint.getSpotDetailEndpoint() + "/" + spotFileGuid);
  }

  public addSpotComment(id: string, comment:SpotComment) {

    let comments: Array<SpotComment> = [];
    comments.push(comment);

    return this.http.put(this.apiendPoint.addSpotCommentEndpoint() + "/" + id, comments);
  }

  public editSpot(spotFileGuid: string, editRequest: SpotEditRequest) {
    return this.http.put(this.apiendPoint.editSpotEndPoint() + "/" + spotFileGuid, editRequest);
  }

  public purgeMedia(request: PurgeMedia) {
    return this.http.put(this.apiendPoint.purgeMediaEndPoint(), request);
  }

  public submitSpotApproval(spotFileGuid: string, approveRequest: SpotUpdateApprovalRequest) {
    return this.http.put(this.apiendPoint.spotApprovalSubmitEndPoint() + "/" + spotFileGuid, approveRequest);
  }

  public getAuditLogsSpot(spotGuid: string) {
    return this.http.get(this.apiendPoint.getAuditLogsEndPoint() + "/" + spotGuid);
  }

  public getOrderHistoryOfSpot(spotGuid: string) {
    return this.http.get(this.apiendPoint.getOrderHistoryOfSpotEndPoint() + "/" + spotGuid);
  }

  public addEditMediaCustomTags(spotFileGuid: string, request: MediaCustomTagAddEditRequest) {
    return this.http.put(this.apiendPoint.addEditMediaCustomTagsEndPoint() + "/" + spotFileGuid, request);
  }

  public getMediaCustomTags(spotFileGuid: string) {
    return this.http.get(this.apiendPoint.getMediaCustomTagsEndPoint() + "/" + spotFileGuid);
  }
}

