import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'errorpopup',
  templateUrl: './errorpopup.component.html',
  styleUrls: ['./errorpopup.component.css'],
})
export class ErrorPopupComponent extends BasePopupComponent {
     
  public errors: Array<string>;
  public title: string = '';

  constructor(
    public bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {    
  }

  public closePopup() {
    super.ngOnInit();

    this.bsModalRef.hide();
  }
}
