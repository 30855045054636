import { Component, OnInit } from "@angular/core";
import { RouteConstants } from "../../configurations/route-constants";
import { Utilities } from "../../services/core/utilities";
import { UserRoles } from "../../configurations/user-roles";
import { ActivatedRoute, Router } from "@angular/router";
import { TaggerTabs } from "../../configurations/enums/enums";

@Component({
  selector: "app-tagger-home",
  templateUrl: "./tagger-home.component.html",
  styleUrls: ["./tagger-home.component.css"],
})
export class TaggerHomeComponent implements OnInit {
  public searchConfig: any;
  public taggerTabs = TaggerTabs;
  public activeTab = TaggerTabs.taggedAds;
  public tabConfig: any[] = [
    {icon: 'tylicon tylicon-tylmark', name: TaggerTabs.taggedAds},
    {icon: 'tylicon tylicon-draft-white', name: TaggerTabs.savedDraftAds},
    {icon: 'tylicon tylicon-archive', name: TaggerTabs.archivedAds},
  ];

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activeTab = this.route.snapshot.queryParams['activeTab'] || TaggerTabs.taggedAds;
  }

  public gotoAdCreate() {
    this.router.navigate([RouteConstants.adCreatorRoute], {
      queryParams: {
        isEditDraft: false,
        isEdit: false,
        activeTab: this.activeTab
      },
    });
  }

  public onClickCreativeTags() {
    this.router.navigate([RouteConstants.creativeTagsRoute]);
  }

  public onClickGroups() {
    this.router.navigate([RouteConstants.groupsRoute]);
  }

  public onLocationsAndUsers() {
    this.router.navigate([RouteConstants.locationsAndUsersRoute]);
  }
}
