<div class="tylie-accordion accordion" id="search-all-orders-section">
  <div class="tylie-accordion__panel accordion-item" id="orderSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#orderSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        ALL ORDERS
      </button>
    </h2>
    <div id="orderSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">

      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Order # or Client Purchase Order or Job #" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                  (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse"
                      href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>
        
        <div class="tylie-section collapse mb-3" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
          <form novalidate (ngSubmit)="onFormSubmit(orderSearchRequest)" [formGroup]="orderSearchRequest" role="search">
            <div class="row">
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="adId">Ad-ID</label>
                  <input type="text" name="adId" id="adId" class="tylie-text form-control" formControlName="adId">
                </div>
              </div>

              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="spotTitle">Spot Title</label>
                  <input type="text" name="spotTitle" id="spotTitle" class="tylie-text form-control" formControlName="spotTitle">
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                 name="status" id="status" class="tylie-select form-control"
                                 formControlName="status"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == false">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                 name="status" id="status" class="tylie-select form-control"
                                 formControlName="status"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="externalName"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cf">Order Date From</label>
                  <dx-date-box type="date" width="100%" formControlName="orderDateFrom"
                               displayFormat="MM/dd/yyyy"
                               id="cf" class="tylie-datepicker form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="ct">Order Date To </label>
                  <dx-date-box type="date" formControlName="orderDateTo" id="ct"
                               displayFormat="MM/dd/yyyy"
                               class="tylie-datepicker form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>

              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="clientPurchaseOrder">Client Purchase Order</label>
                  <input type="text" name="clientPurchaseOrder" id="clientPurchaseOrder" class="tylie-text form-control" formControlName="clientPurchaseOrder">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="jobNumber">Job #</label>
                  <input name="jobNumber" type="text" class="tylie-text form-control" id="jobNumber" formControlName="jobNumber">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="workOrderNumber">Order #</label>
                  <input type="text" name="workOrderNumber" id="workOrderNumber" class="tylie-text form-control" formControlName="workOrderNumber">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="destination">Destination</label>
                  <dx-autocomplete name="destination" id="destination" class="tylie-select form-control"
                                   formControlName="destination"
                                   placeholder="Type three letters to search..."
                                   [minSearchLength]="3"
                                   [searchTimeout]="300"
                                   valueExpr="code"
                                   [dataSource]="destinationDataSource"
                                   value="id">
                  </dx-autocomplete>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="client">Client</label>
                  <dx-select-box [dataSource]="clientDataSource"
                                 name="client" id="client" class="tylie-select form-control"
                                 formControlName="client"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="showClientClear"
                                 (onValueChanged)="onClientValueChanged($event)"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="brand">Brand</label>
                  <dx-select-box [dataSource]="brandDataSource"
                                 name="brand" id="brand" class="tylie-select form-control"
                                 formControlName="brand"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 (onValueChanged)="onBrandValueChanged($event)"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="product">Product</label>
                  <dx-select-box [dataSource]="productDataSource"
                                 name="product" id="product" class="tylie-select form-control"
                                 formControlName="product"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="createdBy">Created By</label>
                  <dx-autocomplete name="orderBuiltBy" id="orderBuiltBy" class="tylie-select form-control"
                                   formControlName="orderBuiltBy"
                                   placeholder="Type three letters to search..."
                                   [minSearchLength]="3"
                                   [searchTimeout]="100"
                                   [dataSource]="userProfileDataSource"
                                   valueExpr="fullName">
                  </dx-autocomplete>
                </div>
              </div>

              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cf">Order Billed Date From</label>
                  <dx-date-box type="date" width="100%" formControlName="orderBilledDateFrom" id="cf" class="tylie-datepicker form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="ct">Order Billed Date To </label>
                  <dx-date-box type="date" width="100%" formControlName="orderBilledDateTo" id="ct" class="tylie-datepicker form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="vendorPurchaseOrder">Partner PO</label>
                  <input name="vendorPurchaseOrder" type="text" class="tylie-text form-control" id="vendorPurchaseOrder" formControlName="vendorPurchaseOrder">
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == false">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="estimate">Estimate</label>
                  <input name="estimate" type="text" class="tylie-text form-control" id="estimate" formControlName="estimate">
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == false">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="costCenter">Cost Center</label>
                  <input name="costCenter" type="text" class="tylie-text form-control" id="costCenter" formControlName="costCenter">
                </div>
              </div>

              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="estimate">Estimate</label>
                  <input name="estimate" type="text" class="tylie-text form-control" id="estimate" formControlName="estimate">
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="costCenter">Cost Center</label>
                  <input name="costCenter" type="text" class="tylie-text form-control" id="costCenter" formControlName="costCenter">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="referenceKeyword">Reference/Keyword(s)</label>
                  <input name="referenceKeywords" type="text" class="tylie-text form-control" id="referenceKeywords" formControlName="referenceKeywords">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="trafficFileName">Traffic File</label>
                  <input name="trafficFileName" type="text" class="tylie-text form-control" id="trafficFileName" formControlName="trafficFileName">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group  form-check mt-4">
                  <input type="checkbox" class="form-check-input" formControlName="awaitingMediaOrdersOnly" name="awaitingMediaOrdersOnly" id="awaitingMediaOrdersOnly">
                  <label class="form-check-label" for="awaitingMediaOrdersOnly">Awaiting Asset Orders</label>
                </div>
              </div>
              <div class="col-4" *ngIf="this.showTabs == true">
                <div class="tylie-form-group  form-check mt-4">
                  <input type="checkbox" class="form-check-input" formControlName="flaggedOnly" name="flaggedonly" id="flaggedonly">
                  <label class="form-check-label" for="flaggedonly">Flagged Orders Only</label>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>

          </form>
        </div>
      </div>

      <div class="d-flex justify-content-between">
          <div *ngIf="this.showTabs == true">
            <ul class="tylie-tab-nav nav nav-tabs mx-4 mt-3" id="myTab" role="tablist">
              <li class="tylie-tab-nav__itm nav-item" role="presentation">
                <button class="tylie-tab-nav__lnk nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#orderview"
                        type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="onclickOrdersGrid()">ORDERS</button>
              </li>
              <li class="tylie-tab-nav__itm nav-item" role="presentation">
                <button class="tylie-tab-nav__lnk nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#spotView"
                        type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" (click)="onclickDeliveryHistoryGrid()">DELIVERY HISTORY</button>
              </li>
            </ul>
          </div>
       
          <div class="d-flex justify-content-end pt-3 me-4">
            <button *ngIf="(this.isAllowedToFlagOrder == true && this.showFlagOrder == true)" class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="flagOrder()"><span class="tylie-button__icon"><icon-flag></icon-flag></span><span class="tylie-button__text">Flag Order</span></button>
            <button *ngIf="this.isAllowedToCreateOrder == true" class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="copySelectedOrder()"><span class="tylie-button__icon"><icon-copy></icon-copy></span><span class="tylie-button__text">Copy Existing order</span></button>
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="exportOrders()"><span class="tylie-button__icon"><icon-export></icon-export></span><span class="tylie-button__text">Export</span></button>
          </div>
      
      </div>
       
      <div class="tylie-tab-content tab-content">
        <div id="orderview" class="tylie-tab-content__pane tab-pane fade show active">
          <dx-data-grid id="orderSearch" class="tylie-grid"
                        #orderSearch
                        [dataSource]="resultOrderStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="orderId"
                        [rowAlternationEnabled]="true"
                        [showBorders]="true"
                        (onExporting)="onExporting()"
                        (onExported)="onExported()"
                        (onRowPrepared)="onRowPrepared($event)">
            <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
            </dxo-selection>
            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="workOrder" caption="Order #" cellTemplate="workOrderTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
            <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="10%"></dxi-column>
            <dxi-column dataField="clientPurchaseOrder" caption="Purchase Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="jobNumber" caption="Job #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="consolidatedDisplay" caption="Consolidated" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="estimate" caption="Estimate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="billedDate" caption="Billed Date" cellTemplate="dateTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
            <dxi-column dataField="status" caption="Status" cellTemplate="thumbnailTemplate" cssClass="gridStyle tyl-nopadding" width="12%" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="orderBuiltBy" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="airDateStartStr" cellTemplate="dateTemplate" caption="Air Date Start" cssClass="gridStyle" width="0%" [visible]="false"></dxi-column>
            <dxi-column dataField="airDateEndStr" cellTemplate="dateTemplate" caption="Air Date End" cssClass="gridStyle" width="0%" [visible]="false"></dxi-column>

            <div *dxTemplate="let t of 'workOrderTemplate'">
              <span class="quickLink" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
            </div>

            <div *dxTemplate="let t of 'thumbnailTemplate'">
              <img *ngIf="t.data.awaitingMediaOrder.isAwaitingMedia === true" class="OrderStatusThumbnail"
                    [src]="this.util.awaitingMediaUrl"
                    containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.awaitingMediaSpotsDisplay" triggers="mouseenter:mouseleave" />
              <span *ngIf="t.data.awaitingMediaOrder.isAwaitingMedia === false" class="tyl-padding">{{t.data.status}}</span>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>

            <div *dxTemplate="let data of 'datetimeTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>

            <dxo-remote-operations [sorting]="true"
                                    [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10,20, 50, 100]"
                        infoText="Page {0} of {1} ({2} items)"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
        <div id="spotView" class="tylie-tab-content__pane tab-pane fade" >
          <dx-data-grid id="spotView" class="tylie-grid"
                        #spotView 
                        [dataSource]="resultOrderSpotViewStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="orderLineItemGuid"
                        [rowAlternationEnabled]="true"
                        [showBorders]="true"
                        (onExporting)="onExportingDeliveryHistory()"
                        (onExported)="onExportedDeliveryHistory()"
                        (onRowPrepared)="onRowPrepared($event)">
            <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
            </dxo-selection>
            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="adId" caption="Ad-ID" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="destinationCode" caption="Destination" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="vendor" caption="Network" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="pod" caption="POD" cellTemplate="podTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
            <dxi-column dataField="workOrder" caption="Order #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="sequenceId" caption="Grp." cssClass="gridStyle tyl-nopadding tyl-grid-padding tyl-text-align-left" [allowSorting]="false" width="4%"></dxi-column>
            <dxi-column dataField="createdDate" caption="Order Date" cellTemplate="dateTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="8%"></dxi-column>
            <dxi-column dataField="purchaseOrder" caption="Purchase Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <dxi-column dataField="jobNumber" caption="Job #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="9%"></dxi-column>
            <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="9%"></dxi-column>
            <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="9%"></dxi-column>
            <dxi-column dataField="airStartDate" cellTemplate="dateTemplate" caption="Air Date Start" cssClass="gridStyle" [visible]="false"></dxi-column>
            <dxi-column dataField="airEndDate" cellTemplate="dateTemplate" caption="Air Date End" cssClass="gridStyle" [visible]="false"></dxi-column>

            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>

            <div *dxTemplate="let data of 'podTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</div>
            </div>

            <dxo-remote-operations [sorting]="true"
                                    [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50, 100]"
                        infoText="Page {0} of {1} ({2} items)"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
     
    </div>
  </div>
</div>

