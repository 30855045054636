<!--<nav-header headerTitle="User" link="Back To All Users" [linkRoute]="usersRoute"></nav-header>-->

<ng-container *ngIf="editUserConfig && detail != null">
  <form [formGroup]="editUserFormGroup" (ngSubmit)="onFormSubmit(editUserFormGroup)" novalidate #f="ngForm">
    <ng-container class="notifications-section" id="personalinformation-section">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            USER DETAILS
          </h4>
        </div>
        <div class="tylie-panel__body">
          <div class="row">
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['firstName'].errors && !editUserFormGroup.controls['firstName'].pristine)
                || (editUserFormGroup.controls['firstName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="username">First Name<span class="required">*</span></label>
                <input type="text" formControlName="firstName" name="fname" id="fname" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter First name</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['lastName'].errors && !editUserFormGroup.controls['lastName'].pristine)
                || (editUserFormGroup.controls['lastName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="fname">Last Name<span class="required">*</span></label>
                <input type="text" formControlName="lastName" name="lname" id="lname" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Last Name</p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="detail.isUserTypeClient == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Client<span class="required">*</span></label>
                <div class="tylie-form-group__txt">{{detail.client.name}}</div>
              </div>
            </div>

            <div class="col-md-3" *ngIf="detail.isUserTypeDestination == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Destination<span class="required">*</span></label>
                <div class="tylie-form-group__txt">{{detail.destination}}</div>
              </div>
            </div>

            <div class="col-md-3" *ngIf="detail.isUserTypeClient == true && userGrpDataSource != null && userGrpDataSource.length > 0">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="userGroups">User Groups<span class="required">*</span></label>
                <dx-drop-down-box [(value)]="userGrpsValue"
                                  formControlName="userGroups"
                                  id="userGroups"
                                  class="tylie-select form-control"
                                  valueExpr="clientUserGroupId"
                                  displayExpr="displayName"
                                  (onValueChanged)="syncTreeViewSelection()"
                                  [dataSource]="userGrpDataSource">
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="userGrpDataSource"
                                  dataStructure="plain"
                                  keyExpr="clientUserGroupId"
                                  #treeUserGrps
                                  parentIdExpr="parentId"
                                  selectionMode="multiple"
                                  showCheckBoxesMode="normal"
                                  displayExpr="displayName"
                                  [selectByClick]="true"
                                  (onContentReady)="syncTreeViewSelection($event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please Select User Groups</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['email'].errors && !editUserFormGroup.controls['email'].pristine)
                || (editUserFormGroup.controls['email'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="pnumber">Email<span class="required">*</span></label>
                <input type="text" formControlName="email" name="pnumber" id="pnumber" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter an Email</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['phone'].errors && !editUserFormGroup.controls['phone'].pristine)
                || (editUserFormGroup.controls['phone'].errors && f.submitted) }">
                <label class="tylie-form-group__lbl" for="cnumber">Phone<span class="required">*</span></label>
                <input type="text" formControlName="phone" name="cnumber" id="cnumber" [textMask]="{mask: phoneMask, guide: false, modelClean: true}" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter a Valid Phone Number</p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="detail.isPostHouseUser == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Post House<span class="required">*</span></label>
                <div class="tylie-form-group__txt">{{detail.editorialHouse.name}}</div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="detail.isPostHouseUser == true">

            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['timeZone'].errors && !editUserFormGroup.controls['timeZone'].pristine)
                || (editUserFormGroup.controls['timeZone'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="timezone">Time Zone<span class="required">*</span></label>
                <dx-select-box [dataSource]="timeZoneStore"
                                formControlName="timeZone"
                                name="timezone" id="timezone" class="tylie-select form-control"
                                displayExpr="displayName"
                                valueExpr="standardName"
                                placeholder="Select a value...">
                </dx-select-box>
                <p class="p-extra-small">Please Select a Time Zone</p>
              </div>
            </div>

            <div class="col-md-12" *ngIf="detail.isPostHouseUser == false">

            </div>

            <div class="col-md-3" *ngIf="editUserConfig.allowSpecialOptions == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['dealerName'].errors
                    && !editUserFormGroup.controls['dealerName'].pristine)
                || (editUserFormGroup.controls['dealerName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="dealerName">Dealer Name<span class="required">*</span></label>
                <input type="text" formControlName="dealerName" name="dealerName" id="dealerName" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Dealer Name</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="editUserConfig.allowSpecialOptions == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editUserFormGroup.controls['dealerNumber'].errors && !editUserFormGroup.controls['dealerNumber'].pristine)
                || (editUserFormGroup.controls['dealerNumber'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="dealerNumber">Dealer Number<span class="required">*</span></label>
                <input type="text" formControlName="dealerNumber" name="dealerNumber" id="dealerNumber" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Dealer Number</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-container>

    <ng-container class="notifications-section" id="personalinformation-section" *ngIf="editUserConfig.allowSpecialOptions == true">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Brands
          </h4>
        </div>
        <div class="tylie-panel__body" *ngIf="userClientBrandDataSource != null && userClientBrandDataSource?.length > 0">
          <dx-data-grid id="brandsGrid"
                        #brandsGrid class="tylie-grid"
                        [dataSource]="userClientBrandDataSource"
                        [showColumnLines]="true"
                        [rowAlternationEnabled]="true"
                        [hoverStateEnabled]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        keyExpr="brandId"
                        (onContentReady)="onContentReady($event)">
            <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
            </dxo-selection>
            <dxi-column dataField="brandName" width="30%" cssClass="gridStyle" caption="Brand Name"></dxi-column>
            <dxi-column caption="Product" width="40%" cellTemplate="productTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Appr.Lvl" width="30%" cellTemplate="approvalLvlTemplate" cssClass="gridStyle tyl-vertical-align-intial" [visible]="mediaApproverSelected && this.previousPostHouseValue == null" [allowSorting]="false"></dxi-column>

            <div *dxTemplate="let t of 'approvalLvlTemplate'">
              <div *ngIf="t.data.clientBrandApprovalLevels?.length > 0">
                <dx-select-box [dataSource]="t.data.clientBrandApprovalLevels"
                                #approvalLevel
                                name="approvalLevel" id="approvalLevel" class="form-control"
                                [value]="t.data.userClientBrandApprovalLevel"
                                (onSelectionChanged)="onSelectionChangedApprovalLvl($event, t.data)">
                </dx-select-box>
              </div>
            </div>

            <div *dxTemplate="let t of 'productTemplate'">
              <div *ngFor="let product of t.data.products">
                <span class="p-small">
                  {{product.name}}
                </span>
              </div>
              <span *ngIf="t.data.products?.length > 0" for="f02" class="qc-cancel p-small tyl-no-margin" (click)="addClientBrandProduct(t.data)">
                ADD/EDIT
              </span>
              <span style="vertical-align:middle;" *ngIf="(t.data.hasBrandProducts && (t.data.products == null || t.data.products?.length == 0))" for="f02" class="tyl-blue-text tyl-no-margin tyl-clickable" (click)="addClientBrandProduct(t.data)">
                <i class="tyl-glyphicon glyphicon glyphicon-plus-sign"></i><span class="tyl-blue-text">ADD PRODUCTS</span>
              </span>
            </div>

            <dxo-paging [pageSize]="25"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[25,50,100]"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
        <div class="tylie-panel__body" *ngIf="userClientBrandDataSource == null || userClientBrandDataSource?.length == 0">
          <p class="tylie-type-body">No brands are assigned to this user.</p>
        </div>
      </div>
    </ng-container>

    <ng-container class="notifications-section" id="personalinformation-section" *ngIf="detail.isUserTypeClient == true">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Preferences
          </h4>
        </div>
        <div class="tylie-panel__body">
          <div class="mb-3">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddDistributionSettings()">Distribution Settings</button>
          </div>
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl">Advanced File Naming Enabled :</label>
            <div class="tylie-form-group__txt">{{this.enableAdvancedFileNaming? '&nbsp;Yes' : '&nbsp;No' }} </div>
          </div>
            <div class="tylie-form-group form-group" *ngIf="networkSpecifications.length > 0">
              <label class="tylie-form-group__lbl" for="specMappings">Specification Mappings</label>
              <ng-container *ngFor="let spec of networkSpecifications; let i = index">
                <div class="tylie-form-group__txt">
                  {{spec.name}}
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" (click)="onRemoveSpecMapping(spec)">
                    <icon-delete></icon-delete>
                  </button>
                </div>
              </ng-container>
            </div>
        </div>
      </div>
    </ng-container>

    <edit-user-roles [addUserRolesFormGroup]="this.editUserFormGroup.get('userRole')"
                     (onMediaApproverRoleUpdated)="onMediaApproverRoleUpdatedEventTriggered($event)">

    </edit-user-roles>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">Save User</span>
      </button>
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onCancelEdits()">Cancel</button>
    </div>
  </form>
</ng-container>



