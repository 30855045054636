<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="row">
    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.hasError('nonZeroNumberError') && f.submitted)}">
      <label class="tylie-form-group__lbl" for="adjustamount">Adjust Amount<span class="required">*</span></label>
      <div class="tylie-text-group input-group">
        <span class="input-group-text">$</span>
        <dx-number-box formControlName="adjustamount"
                       name="adjustamount"
                       class="tylie-select form-control"
                       [showSpinButtons]="false">
        </dx-number-box>
      </div>
      <p class="p-extra-small">Please enter amount</p>
    </div>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['comments']?.errors && f.submitted)}">
    <label class="tylie-form-group__lbl" for="comments">Comments<span class="required">*</span></label>
    <textarea name="comments" id="comments" class="tylie-textarea tylie-textarea--md form-control" formControlName="comments" rows="4"></textarea>
    <p class="p-extra-small">Please enter Comments</p>
  </div>
</div>
