export interface TaggerBaseCreative {
  baseCreativeGuid?: string;
  creativeName?: string;
  clientId?: number;
  durationinsecs?: number;
  taggableDurationInSecs?: number;
  proxyurl?: string;
  thumbnailurl?: string;
  receivedDate?: Date;
  availableForOrderingFromDate?: Date;
  expirydate?: Date;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
  isPlayable?: number;
  format?: string;
  thumbnailDisplayUrl?: string;
  assetType?: string;
  assetTitle?: string;
  startDate?: string;
  endDate?: string;
  groups?: Array<string>;
  locations?: Array<string>;
  assetGuid?: string;
  originalFileName?: string;
  isPlaceHolder: boolean;
  isFeatured?: boolean;
}

export interface TaggerCard {
  cardGuid?: string;
  clientId?: number;
  cardName?: string;
  type?: string;
  receivedDate?: Date;
  availableForOrderingFromDate?: Date;
  expirydate?: Date;
  createdDate?: Date;
  createdBy?: string;
  s3StoragePath?: string;
  fileName?: string;
  isActive?: boolean;
  modifiedDate?: Date;
  modifiedBy?: string;
  cardDisplayUrl?: string;
  assetType?: string;
  assetTitle?: string;
  startDate?: string;
  endDate?: string;
  groups?: Array<string>;
  locations?: Array<string>;
  assetGuid?: string;
  originalFileName?: string;
  isFeatured?: boolean;
}

export interface TaggerCardVoiceOver {
  voiceOverGuid?: string;
  voiceOverName?: string;
  clientId?: number;
  durationinsecs?: number;
  proxyUrl?: string;
  thumbnailUrl?: string;
  receivedDate?: Date;
  availableForOrderingFromDate?: Date;
  expirydate?: Date;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
  isPlayable?: number;
  format?: string;
  assetType?: string;
  assetTitle?: string;
  startDate?: string;
  endDate?: string;
  groups?: Array<string>;
  locations?: Array<string>;
  assetGuid?: string;
  originalFileName?: string;
  isFeatured?: boolean;
}

export interface CreateOrderRequestModel {
  orderGuid?: string;
  adGuid?: string;
  adName: string;
  baseCreativeGuid: string;
  offerCardGuid?: string;
  endCardGuid: string;
  voiceOverGuid?: string;
  createdDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
  modifiedDate?: Date;
  workOrder?: string;
  clientId?: string;
  assetType?: string;
  assetTitle?: string;
  startDate?: string;
  endDate?: string;
  groups?: Array<string>;
  locations?: Array<string>;
  assetGuid?: string;
  originalFileName?: string;
}

export interface UpdateOrderRequestModel {
  clientId?: number;
  orderGuid: string;
  lineItemGuid: string;
  adGuid: string;
  baseCreativeGuid: string;
  offerCardGuid?: string;
  endCardGuid: string;
  voiceOverGuid?: string;
  adName: string;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
  workOrder?: string;
}

export interface CreateDraftRequestModel {
  draftOrderGuid: string;
  baseCreativeGuid?: string;
  offerCardGuid?: string;
  endCardGuid?: string;
  voiceOverGuid?: string;
  adName?: string;
  createdDate?: Date;
  createdBy?: string;
  isActive: boolean;
  status: string;
}

export interface DraftOrderResponseModel {
  draftOrderGuid: string;
  baseCreativeGuid: string;
  offerCardGuid: string;
  endCardGuid: string;
  voiceOverGuid: string;
  adName: string;
  createdDate?: Date;
  createdBy: string;
  isActive: boolean;
  status: string;
  note: DraftOrderNoteViewModel;
  offerCardDetails: OfferCardResponseDetail;
  baseCreativeDetails: BaseCreativeResponseDetail;
  endCardDetails: EndCardResponseDetail;
  voiceOverDetails: VoiceOverResponseDetail;
}

export interface DraftOrderNoteViewModel {
  payload: string;
  createdDate: Date;
  createdBy: string;
}

export interface OfferCardResponseDetail {
  message: string;
  offerCard: TaggerCard;
}

export interface BaseCreativeResponseDetail {
  message: string;
  baseCreative: TaggerBaseCreative;
}

export interface EndCardResponseDetail {
  message: string;
  endCard: TaggerCard;
}

export interface VoiceOverResponseDetail {
  message: string;
  voiceOver: TaggerCardVoiceOver;
}

export interface SearchDraftRequestModel {
  search: string;
  sortOrder: string;
}

export class TaggedAdResponseModel {
  orderGuid: string;
  lineItemGuid: string;
  adGuid: string;
  baseCreativeGuid: string;
  offerCardGuid: string;
  endCardGuid: string;
  voiceOverGuid: string;
  adName: string;
  createdDate: Date;
  createdBy: string;
  modifiedDate: Date;
  modifiedBy: string;
  workOrder: string;
  clientId: number;
  isActive: boolean;
  status: string;
  displayStatus: string;
  note: DraftOrderNoteViewModel;
  ad: AdResponseModel;
  offerCardDetails: OfferCardResponseDetail;
  baseCreativeDetails: BaseCreativeResponseDetail;
  endCardDetails: EndCardResponseDetail;
  voiceOverDetails: VoiceOverResponseDetail;
}

export class OrderNote {
  public orderGuid: string;
  public note: string;
}

export class OrderNoteResponse {
  payload: string;
  createdBy: string;
  createdDate: Date;
}

export class AdResponseModel {
  adGuid: string;
  adName: string;
  s3StoragePath: string;
  thumbnail: string;
  fileSize: number;
  adSystemName: string;
  mediaTranscodeId: number;
  processingEndtime: Date;
  proxyUrl: string;
  isActive: boolean;
  createdDate: Date;
  createdBy: string;
  modifiedDate: Date;
  modifiedBy: string;
  fileName: string;
  fileExtension: string;
}

export class TaggerAd {
  public adGuid: string;
  public status: string;
  public isFailed: boolean;
  public isProcessing: boolean;
  public isCompleted: boolean;
  public canDelete: boolean;
  public canRenameFileName: boolean;
  public canDownload: boolean;
  public canShare: boolean;
  public fileName: string;
  public fileExtension: string;
  public filePath: string;
  public fileSize: string;
  public duration: string;
  public createdDate: Date;
  public sharedDate: Date;
  public downloadedDate: Date;
  public transcodeSpec: string;
  public payload: string;
}

export class ShareTaggerAdRequest {
  adShareGuid?: string;
  taggerAds: Array<string>;
  subject: string;
  comments: string;
  password: string;
  isPasswordRequired: boolean;
  isUserCopied: boolean;
  expiryInDays: number;
  limitViews: number;
  emailTo: Array<string>;
}

export interface SearchTaggedAdRequestModel {
  search?: string;
  sortOrder?: string;
  clientId?: number;
  isArchive?: boolean;
}

export interface TaggerAssetSearchRequest {
  search: string;
  sortOrder: string;
  assetType?: string;
  startDate?: string;
  endDate?: string;
  group?: string;
  location?: string;
  unassignedGroup: boolean;
  availableOnly: boolean;
  expiredOnly: boolean;
  featuredOnly: boolean;
}

export interface UploadTaggerAssetRequestModel {
  assetType?: string;
  assetTitle?: string;
  startDate?: string;
  endDate?: string;
  groups?: Array<string>;
  locations?: Array<string>;
  assetGuid?: string;
  originalFileName?: string;
}

export interface CreateTaggerAssetRequestModel {
  baseCreativeRequestViewModels?: TaggerBaseCreative[];
  offerCardRequestViewModels?: TaggerCard[];
  endCardRequestViewModels?: TaggerCard[];
  voiceOverRequestViewModels?: TaggerCardVoiceOver[];
}

export interface TaggerAssetModel {
  assetGuid?: string;
  assetName?: string;
  assetType?: string;
  availableStartDate?: Date;
  availableEndDate?: Date;
  duration?: number;
  taggableDurationInSecs?: number;
  availableGroups?: Array<string>;
  availableLocations?: Array<string>;
  createdBy?: string;
  createdDate?: Date;
  proxyUrl?: string;
  thumbnailUrl?: string;
  isPlayable: boolean;
  clientId?: number;
  fileName?: string;
  fileExtension?: string;
  s3StoragePath?: string;
  format?: string;
  thumbnailDisplayUrl?: string;
  displayStatus?: string;
  groups?: GroupModel[];
  locations?: LocationModel[];
  originalFileName?: string;
  isPlaceHolder: boolean;
  isFeatured?: boolean;
  receivedDate?: Date;
  isUploadSuccess?: boolean;
  isUploading?: boolean;
}

export interface GroupModel {
  groupGuid?: string;
  groupDescription?: string;
  clientId?: number;
  name?: string;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
  isActive?: boolean;
  locations?: LocationModel[];
}

export interface LocationModel {
  locationGuid?: string;
  clientId?: number;
  name?: string;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
  isActive?: boolean;
  zipCode?: string;
  address?: string;
  city?: string;
  state?: string;
  groups?: GroupModel[];
  locationUsers?: LocationUserModel[];
}

export interface UpdateTaggerAssetRequestModel {
  assetGuid?: string;
  assetTitle?: string;
  assetType?: string;
  availableStartDate?: string;
  availableEndDate?: string;
  availableGroups?: Array<string>;
  availableLocations?: Array<string>;
  isFeatured?: boolean;
  taggableDurationInSecs?: number;
}

export interface TaggerGroupRequestModel {
  groupGuid?: string;
  groupName: string;
  groupDescription?: string;
  locationGuids?: Array<string>;
}

export interface LocationSearchRequest {
  groups?: string[];
  states?: string[];
  quickSearch?: string;
  unassignedGroup: boolean;
}

export interface StateModel {
  stateId?: string;
  stateName?: string;
  shortCode?: string;
  country?: string;
}

export interface LocationUserModel {
  userProfileGuid?: string;
  clientId?: number;
  userProfileId?: number;
  title?: string;
  userName?: string;
  userDetail?: any;
}

export interface UpdateTaggerGroupLocationModel {
  groupGuid?: string;
  locationGuids?: Array<string>;
}

export interface CreateTaggerUserModel {
  locations?: Array<string>;
  location?: string;
  username?: string;
  firstname?: string;
  lastname?: string;
  title?: string;
  phonenumber?: string;
}

export interface CreateLocationModel {
  locationGuid?: string;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  users?: CreateLocationUserModel[];
  groupGuids?: string[];
}

export interface CreateLocationUserModel {
  userName?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
}

export interface UpdateLocationGroupModel {
  locationGuid?: string;
  groupGuids?: Array<string>;
}

export interface LocationValidationModel {
  name?: string;
  clientId?: number;
}

export interface UpdateTaggerAssetStatusRequestModel {
  assetGuid?: string;
  assetType?: string;
}

export interface UploadStatusRequestViewModel {
  assetGuid?: string;
  fileType?: string;
  //isReUpload?: boolean;
}
