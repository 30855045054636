<form [formGroup]="updateOrderResendRequest" #resendForm="ngForm">
  <p class="tylie-type-body mb-3">RESEND</p>

  <div class="tylie-alert tylie-alert--error my-4">
    Please choose a carrier to resend
  </div>

  
  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderResendRequest.controls['vendorId'].errors && resendForm.submitted}">
    <label class="tylie-form-group__lbl" for="name">Carrier<span class="required">*</span></label>
    <dx-select-box [dataSource]="carrierDataSource"
                    name="vendorId" id="vendorId" class="tylie-select form-control"
                    formControlName="vendorId"
                    valueExpr="id"
                    [showClearButton]="true"
                    displayExpr="name"
                    placeholder="Select a carrier...">
    </dx-select-box>
    <p class="p-extra-small">Please select a carrier</p>
  </div>

  <div class="d-flex justify-content-end">
    <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit" (click)="onFormSubmit(updateOrderResendRequest)"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
  </div>
</form>
