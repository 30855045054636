import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from "../../../services/core/utilities";
import { NotificationService } from "../../../services/notifications/notification.service";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'spotnotificationspopup',
  templateUrl: './spotnotificationspopup.component.html',
  styleUrls: ['./spotnotificationspopup.component.css'],
})
export class SpotNotificationPopupComponent extends BasePopupComponent {

  public spotFileGuid: string;
  public orderGuid: string;
  public dataSource: any = {};
  public notificationMessage: string = "";

  @ViewChild('notificationGrid', { static: true }) notificationGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private notificationService: NotificationService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.spotFileGuid != null && this.spotFileGuid != '')
      this.getSpotNotifications();
    else
      this.getOrderNotifications();
  }

  private getSpotNotifications() {
    var notifService = this.notificationService;
    var spotIdentifier = this.spotFileGuid;
    var util = this.util;
    var mRef = this.bsModalRef;

    this.dataSource.store = new CustomStore({
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let request: any = { archivedOnly: false, criteria: null, skip: skip, take: take, sort: sortOptions, user: null };

        return notifService.searchSpotNotifications(spotIdentifier, request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var notificationResponse = response.result;
              var notificationResults = notificationResponse.searchResults;

              var obj: any = {
                data: notificationResponse.searchResults,
                totalCount: notificationResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  private getOrderNotifications() {
    var notifService = this.notificationService;
    var orderIdentifier = this.orderGuid;
    var util = this.util;
    var mRef = this.bsModalRef;

    this.dataSource.store = new CustomStore({
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let request: any = { archivedOnly: false, criteria: null, skip: skip, take: take, sort: sortOptions, user: null };

        return notifService.searchOrderNotifications(orderIdentifier, request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var notificationResponse = response.result;
              var notificationResults = notificationResponse.searchResults;

              var obj: any = {
                data: notificationResponse.searchResults,
                totalCount: notificationResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  public onRowClick(e: any) {
    $(".dx-datagrid-table tr").removeClass("highlightSelected");
    e.rowElement.removeClass('dx-state-hover');
    e.rowElement.addClass('highlightSelected');

    this.notificationService.getMessage(e.data.requestID, e.data.requestNotifyID).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var obj = res.result;
        this.notificationMessage = obj.message;

        e.rowElement.removeClass('unReadItem');

        e.data.isViewed = true;
      }
      else {
        this.notificationMessage = null;
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.notificationMessage = null;

        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
