import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { QuickLinkConfig, QuickLinkTranscoding } from '../../../models/spot/quicklink.model';
import { DxTreeViewComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { SpotService } from '../../../services/media/spot.service';
import { MessageSeverity, AlertService } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';

@Component({
  selector: 'quicklink',
  templateUrl: './quicklinkpopup.component.html',
  styleUrls: ['./quicklinkpopup.component.css'],
})
export class QuickLinkPopUp implements OnInit {

  @Input('config') config: QuickLinkConfig;
  @Input('spotData') spotData: any[];
  @ViewChild('treeSpecs', { static: false }) treeSpecs: DxTreeViewComponent;

  public quickLinkFormGroup: FormGroup;
  public onSubmitSuccess: Subject<boolean>;
  public onClose: Subject<string[]>;

  public predefinedSpecs: Array<string> = [];
  public selectedSpotFileGuids: Array<string> = [];
  public fileTypes: Array<string> = [];
  public slateoptions: Array<string> = ['Keep', 'Remove'];
  public selectedSpecs: string[] = [];
  public quickLinkNotAvaialableError = Constants.quickLinkNotAvaialableError;
  public nospotsSelected = Constants.nospotsSelected;
  public deselectedSpotFileGuids: string[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private spotService: SpotService,
    private util: Utilities,
    public alertService: AlertService) {

  }

  ngOnInit() {
    this.quickLinkFormGroup = this.fb.group({
      spec: [null, Validators.compose([Validators.required])],
      slate: [null, Validators.compose([Validators.required])],
      isInternal: [false]
    });

    this.onSubmitSuccess = new Subject();
    this.onClose = new Subject();
  }

  public onClickSubmitForm({ value, valid }: { value: QuickLinkTranscoding, valid: boolean }) {
    if (this.spotData == null || this.spotData.length <= 0) {
      return;
    }

    if (!valid || this.spotData.filter(sd => sd.isQuickLinkAllowed == false).length > 0) {
      return;
    }

    this.spotData.forEach((item) => {
      this.selectedSpotFileGuids.push(item.spotFileGuid);
    });

    value.spotFileGuids = this.selectedSpotFileGuids;

    this.spotService.createQuickLinkRequest(value)
      .subscribe((response: any) => {
        if (response.isSuccess == true) {
          this.closePopup();
          this.onSubmitSuccess.next(response.result);
          this.alertService.showMessage("SUCCESS", Constants.quickLinkRequestSuccessfully, MessageSeverity.success);
        }
        else {
          this.closePopup();
          this.onClose.next(this.deselectedSpotFileGuids);
          this.util.handleIsNotSuccess(response.errors);
        }
      },
        error => {
          this.closePopup();
          this.onClose.next(this.deselectedSpotFileGuids);
          this.util.handleError(error);
        });
  }

  public closePopup() {
    this.bsModalRef.hide();
    this.onClose.next(this.deselectedSpotFileGuids);
  }

  public syncTreeViewSelection(e = null) {

    var component = (e && e.component) || (this.treeSpecs && this.treeSpecs.instance);

    if (!component) return;

    if (this.selectedSpecs == null || this.selectedSpecs.length <= 0) {
      component.unselectAll();
    }

    if (this.selectedSpecs) {
      this.selectedSpecs.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<string>;
    this.selectedSpecs = newValues;
  }

  public onClickdelete(spotFileGuid: string) {
    this.spotData.forEach((item, j) => {
      if (item.spotFileGuid === spotFileGuid) {
        this.spotData.splice(j, 1);
        this.deselectedSpotFileGuids.push(spotFileGuid);
      }
    });
  }
}
