import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';

import { OrderGroupService, DeleteOrderMediaEvent, Vendor, UpdateVendorDateEvent } from "../../../../models/order/vendor-purchaseorder-request.model";
import { FormGroup, FormGroupName, NgForm } from "@angular/forms";

@Component({
  selector: 'ordergroupservicevendorporequest',
  templateUrl: './ordergroupservicevendorporequest.component.html',
  styleUrls: ['./ordergroupservicevendorporequest.component.css'],
})
export class OrderGroupServiceVendorPORequestComponent implements OnInit {
  @Input() groupService: FormGroup;
  @Input() groupName: number;
  @Input() formGroupCount: number;
  @Input() parentForm: NgForm;
  @Input() nonDigitalVendors: Vendor[];
  @Input() orderLvlFormGroupCount: number;

  @Output() onDeleteOrderGroupService: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDeleteOrderMedia: EventEmitter<DeleteOrderMediaEvent> = new EventEmitter<DeleteOrderMediaEvent>();
  @Output() onVendorChangeDueDateUpdateTriggered: EventEmitter<UpdateVendorDateEvent> = new EventEmitter<UpdateVendorDateEvent>();
  @Output() onDueDateUpdateTriggered: EventEmitter<UpdateVendorDateEvent> = new EventEmitter<UpdateVendorDateEvent>();

  public now: Date = new Date();

  public attributeId: number = 0;

  constructor() {

  }

  ngOnInit() {
    this.attributeId = (this.groupName);
  }

  public onClickDeleteOrderGroupService() {
    this.onDeleteOrderGroupService.emit(this.groupName);
  }

  public onClickDeleteMedia(orderMediaIndex: number, length: number) {
    if (length == 1 && orderMediaIndex == 0) {
      this.onClickDeleteOrderGroupService();
    }

    else {
      var deleteOrderMediaEvent = new DeleteOrderMediaEvent();
      deleteOrderMediaEvent.groupName = this.groupName;
      deleteOrderMediaEvent.orderMediaIndex = orderMediaIndex;
      this.onDeleteOrderMedia.emit(deleteOrderMediaEvent);
    }
  }

  public onVendorChange(e: any) {
    if (e.value != "" && e.value != null && e.value != undefined && e.previousValue != e.value) {
      var updatedueDateEvent = new UpdateVendorDateEvent();
      updatedueDateEvent.vendor = e.value;
      this.onVendorChangeDueDateUpdateTriggered.emit(updatedueDateEvent);
    }
  }

  public onDueDateChange(e: any, vendor: any) {
    if (vendor != "" && vendor != null && vendor != undefined && e.previousValue != e.value) {
      var updatedueDateEvent = new UpdateVendorDateEvent();
      updatedueDateEvent.dueDate = e.value;
      updatedueDateEvent.vendor = vendor;
      this.onDueDateUpdateTriggered.emit(updatedueDateEvent);
    }
  }
}
