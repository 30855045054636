import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { CreateCustomTagConfig, TagValueChangedEvent, CustomTagConfig } from '../../../models/admin/client/customtagconfig.model';
import { ConfigService } from '../../../services/core/config.service';
import { emptySpacesValidator } from '../../../configurations/custom-validations';
import { TranscodeBillingSettings } from '../../../models/admin/client/billingsettings.model';

@Component({
  selector: 'addtranscodebillingsettings',
  templateUrl: './addtranscodebillingsettings.component.html',
  styleUrls: ['./addtranscodebillingsettings.component.css'],
})
export class AddTranscodeBillingSettingsPopUp implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('transcodeBillingTypes') transcodeBillingTypes: any = {};
  @Input('billingFrequency') billingFrequency: any = {};
  @Input('flatTranscodeBillingFrequency') flatTranscodeBillingFrequency: any = {};
  @Input('selectedBrandsDataSource') selectedBrandsDataSource: any[] = [];
  @Input('isBrandsNotSelected') isBrandsNotSelected: boolean = false;
  @Input('flatTranscodeTiers') flatTranscodeTiers: any = {};
  @Input('brandTranscodeSettings') brandTranscodeSettings: TranscodeBillingSettings = null;
  @Input('clientTranscodeBillingSettings') clientTranscodeBillingSettings: TranscodeBillingSettings = null;

  public modalRef: BsModalRef;

  public transcodeBillingSettingsForm: FormGroup;

  public onSaveTranscodeBillingSettings: Subject<TranscodeBillingSettings>;
  public brandsValue: Array<number>;
  public brandsNotSelected: boolean = false;
  public defaultBillingTypeForBrand = "Per Request";
  public flatBillingType = "Flat";
  public transcodeBillingSettings: TranscodeBillingSettings = null;
  public billingFreqDataSource: any[] = [];
  public now: Date = new Date();

  @ViewChild('treeBrands', { static: false }) treeBrands: DxTreeViewComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService) {

    this.onSaveTranscodeBillingSettings = new Subject<TranscodeBillingSettings>();
  }

  ngOnInit() {

    console.log(this.flatTranscodeBillingFrequency);
    this.billingFreqDataSource = this.billingFrequency;
    this.transcodeBillingSettings = (this.brandTranscodeSettings == null || this.brandTranscodeSettings == undefined) ? this.clientTranscodeBillingSettings : this.brandTranscodeSettings;

    if ((this.transcodeBillingSettings == null || this.transcodeBillingSettings == undefined)) {
      this.transcodeBillingSettingsForm = this.fb.group({
        selectedBrandsForTranscodeBilling: [],
        billingType: [null, Validators.required],
        purchaseOrder: [null],
        jobNumber: [null],
        billingCycle: [null, Validators.required],
        distributionIncluded: [false],
        nextBillingCycle: [null, Validators.required],
        isSelfService: [false],
        tier: this.fb.group({
          level: new FormControl(1),
        })
      });
    }
    else {
      if (this.transcodeBillingSettings.billingType != this.defaultBillingTypeForBrand) {
        this.billingFreqDataSource = this.flatTranscodeBillingFrequency;
      }
      this.transcodeBillingSettingsForm = this.fb.group({
        selectedBrandsForTranscodeBilling: [],
        billingType: [this.transcodeBillingSettings.billingType, Validators.required],
        purchaseOrder: [this.transcodeBillingSettings.purchaseOrder],
        jobNumber: [this.transcodeBillingSettings.jobNumber],
        billingCycle: [this.transcodeBillingSettings.billingCycle, Validators.required],
        distributionIncluded: [this.transcodeBillingSettings.distributionIncluded],
        isSelfService: [this.transcodeBillingSettings.isSelfService],
        nextBillingCycle: [this.transcodeBillingSettings.nextBillingCycle],
        tier: this.fb.group({
          level: this.transcodeBillingSettings.tier == null ? new FormControl(1, [Validators.required]) : new FormControl(this.transcodeBillingSettings.tier.level, [Validators.required]),
        })
      });
    }
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeBrands && this.treeBrands.instance);

    if (!component) return;

    if (this.brandsValue == null || !this.brandsValue.length) {
      component.unselectAll();
    }

    if (this.brandsValue != null) {
      this.brandsValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.brandsValue = newValues;
    this.billingFreqDataSource = this.billingFrequency;
  }

  public onbillingTypeChange(e) {
    console.log(e);
    if (e != null && e.value != null) {
      if (e.value == this.defaultBillingTypeForBrand) {
        this.billingFreqDataSource = this.billingFrequency;
      }
      else {
        this.billingFreqDataSource = this.flatTranscodeBillingFrequency;
      }
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSaveTranscodeBillingSettingsFormSubmit({ value, valid }: { value: TranscodeBillingSettings, valid: boolean }) {
    console.log(value);
    console.log(valid);

    var brands = this.transcodeBillingSettingsForm.controls['selectedBrandsForTranscodeBilling'].value;

    console.log(brands);

    if ((brands != null && brands.length > 0) || this.brandName != null) {
      this.transcodeBillingSettingsForm.controls['billingType'].setValue(this.defaultBillingTypeForBrand);
      this.transcodeBillingSettingsForm.controls['billingType'].updateValueAndValidity();
      value.billingType = this.defaultBillingTypeForBrand;
    }

    if (!this.transcodeBillingSettingsForm.valid) {
      return;
    }

    this.onSaveTranscodeBillingSettings.next(value);
    this.closePopup();
  }


}
