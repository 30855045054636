import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-nav-distribution',
  templateUrl: './icon-nav-distribution.component.html',
  styleUrls: ['./icon-nav-distribution.component.css']
})
export class IconNavDistributionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
