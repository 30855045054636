import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { CreateCustomTagConfig, TagValueChangedEvent, CustomTagConfig } from '../../../models/admin/client/customtagconfig.model';
import { ConfigService } from '../../../services/core/config.service';
import { emptySpacesValidator } from '../../../configurations/custom-validations';
import { ManagementFeeSettings, StorageManagementFeeSettings } from '../../../models/admin/client/billingsettings.model';

@Component({
  selector: 'addstoragemanagementfeesettings',
  templateUrl: './addstoragemanagementfeesettings.component.html',
  styleUrls: ['./addstoragemanagementfeesettings.component.css'],
})
export class AddStorageManagementFeeSettingsettingsPopUp implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('billingFrequency') billingFrequency: any = {};
  @Input('selectedBrandsDataSource') selectedBrandsDataSource: any[] = [];
  @Input('isBrandsNotSelected') isBrandsNotSelected: boolean = false;
  @Input('brandStorageManagementFeeSettings') brandStorageManagementFeeSettings: StorageManagementFeeSettings = null;
  @Input('clientStorageManagementBillingSettings') clientStorageManagementBillingSettings: StorageManagementFeeSettings = null;

  public modalRef: BsModalRef;

  public storageManagementFeeSettingsForm: FormGroup;

  public onSaveStorageManagementFeeSettings: Subject<StorageManagementFeeSettings>;
  public brandsValue: Array<number>;
  public brandsNotSelected: boolean = false;
  public storageManagementFeeSettings: StorageManagementFeeSettings = null;
  public billingFreqDataSource: any[] = [];
  public now: Date = new Date();

  @ViewChild('treeBrands', { static: false }) treeBrands: DxTreeViewComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService) {

    this.onSaveStorageManagementFeeSettings = new Subject<StorageManagementFeeSettings>();
  }

  ngOnInit() {
    this.billingFreqDataSource = this.billingFrequency;
    this.storageManagementFeeSettings = (this.brandStorageManagementFeeSettings == null || this.brandStorageManagementFeeSettings == undefined) ? this.clientStorageManagementBillingSettings : this.brandStorageManagementFeeSettings;

    if ((this.storageManagementFeeSettings == null || this.storageManagementFeeSettings == undefined)) {
      this.storageManagementFeeSettingsForm = this.fb.group({
        selectedBrandsForStorageManagementFee: [],
        purchaseOrder: [null],
        jobNumber: [null],
        billingCycle: [null, Validators.required],
        nextBillingCycle: [null, Validators.required],
        orderedBy: [null]
      });
    }
    else {
      this.storageManagementFeeSettingsForm = this.fb.group({
        selectedBrandsForStorageManagementFee: [],
        purchaseOrder: [this.storageManagementFeeSettings.purchaseOrder],
        jobNumber: [this.storageManagementFeeSettings.jobNumber],
        billingCycle: [this.storageManagementFeeSettings.billingCycle, Validators.required],
        nextBillingCycle: [this.storageManagementFeeSettings.nextBillingCycle, Validators.required],
        orderedBy: [this.storageManagementFeeSettings.orderedBy]
      });
    }
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeBrands && this.treeBrands.instance);

    if (!component) return;

    if (this.brandsValue == null || !this.brandsValue.length) {
      component.unselectAll();
    }

    if (this.brandsValue != null) {
      this.brandsValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.brandsValue = newValues;
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSaveStorageManagementFeeBillingSettingsFormSubmit({ value, valid }: { value: StorageManagementFeeSettings, valid: boolean }) {
    console.log(value);
    console.log(valid);

    if (!this.storageManagementFeeSettingsForm.valid) {
      return;
    }

    this.onSaveStorageManagementFeeSettings.next(value);
    this.closePopup();
  }


}
