import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { PODRecipients } from '../../../models/admin/client/client';

@Component({
  selector: 'addpodrecipients',
  templateUrl: './addpodrecipients.component.html',
  styleUrls: ['./addpodrecipients.component.css'],
})
export class AddPODRecipientsPopUp implements OnInit {

  @Input('podRecipientsList') podRecipientsList: string;

  public modalRef: BsModalRef;
  public podRecipientsForm: FormGroup;
  public onSavePodRecipients: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder) {

    this.onSavePodRecipients = new Subject<string>();
  }

  ngOnInit() {
    this.podRecipientsForm = this.fb.group({
      podDistributionEmails: [this.podRecipientsList, Validators.compose([EmailListValidatorIfValueExists])]
    });
  }

  public onpodRecipientsFormSubmit({ value, valid }: { value: PODRecipients, valid: boolean }) {

    if (!valid) {
      return;
    }

    if (value.podDistributionEmails != null && value.podDistributionEmails != undefined) {
      var podEmails = value.podDistributionEmails.split(";");
      value.podDistributionEmails = podEmails.join('; ');
    }
    else {
      value.podDistributionEmails = '';
    }

    this.onSavePodRecipients.next(value.podDistributionEmails);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
