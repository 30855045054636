import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GroupModel, LocationModel } from '../../../../models/tagger/tagger.model';
import { AssetType } from '../../../../configurations/enums/enums';
import { TaggerService } from '../../../../services/tagger/tagger.service';
import { Utilities } from '../../../../services/core/utilities';
import { TaggerAssetValidationModel } from '../../../../models/upload/upload-asset-form.model';

@Component({
  selector: 'app-admin-asset-upload-form',
  templateUrl: './admin-asset-upload-form.component.html',
  styleUrls: ['./admin-asset-upload-form.component.css']
})
export class AdminAssetUploadFormComponent implements OnInit {
  @Input() assetRequestFormGroup: FormGroup;
  @Input() groupName: string;
  @Input() groups: GroupModel[];
  @Input() locations: LocationModel[];
  @Input() fileName: string;
  @Input() isEditAsset: boolean = false;
  @Output() removeFile: EventEmitter<any> = new EventEmitter();
  @Input('isFormSubmitted') isFormSubmitted: boolean;

  public assetTypeDataSource: any[];
  public groupDataSource: GroupModel[];
  public locationDataSource: GroupModel[];
  public allLocations: GroupModel[];
  // public isDuplicateAssetTitle: boolean = false;
  public selectedGroups: any[] = [];
  public selectedLocations: any[] = [];

  constructor(
    public taggerService: TaggerService,
    public util: Utilities
  ) { }

  ngOnInit() {
    this.assetTypeDataSource = Object.keys(AssetType).map((key) => ({
      id: AssetType[key],
      name: AssetType[key],
    }));

    this.groupDataSource = this.groups;
    this.locationDataSource = this.groups;
    this.allLocations = this.groups;

    this.assetTypeFilter();

    // this.assetRequestFormGroup.get(this.groupName).get("assetType").valueChanges.subscribe((value) => {
    //   this.validateAssetTitle();
    // });

    // this.assetRequestFormGroup.get(this.groupName).get("assetTitle").valueChanges.subscribe((value) => {
    //   this.validateAssetTitle();
    // });
  }

  ngOnChanges(changes: any): void {
    this.groupDataSource = changes.groups && changes.groups.currentValue || this.groupDataSource;
    this.locationDataSource = this.groupDataSource;
    this.allLocations = this.groupDataSource;

    this.selectedGroups = [];
    const groupNames = this.assetRequestFormGroup.get(this.groupName).get("groups").value;
    if (groupNames) {
      groupNames.forEach(groupName => {
        const group = this.groupDataSource && this.groupDataSource.find(x => x.name === groupName);
        if (group) { 
          this.selectedGroups.push(group);
        }
      });
    }
    
    this.selectedLocations = [];
    const locationNames = this.assetRequestFormGroup.get(this.groupName).get("locations").value;
    if (locationNames) {
      locationNames.forEach(locationName => {
        const locations = this.locationDataSource && this.locationDataSource.reduce((acc, cur) => { return acc.concat(cur.locations) },[]) || [];
        const location = locations && locations.find(x => x.name === locationName);
        if (location) { 
          this.selectedLocations.push(location);
        }
      });
    }
    
  }

  get formControl() {
    return this.assetRequestFormGroup.get(this.groupName);
  }

  get assetTypeDisabled(): boolean {
    const type = this.assetRequestFormGroup.get(this.groupName).get("assetType").value;
    return this.isEditAsset && (type === AssetType.BaseCreative || type === AssetType.VoiceOver);
  }

  get selectedGroupLabel() {
    return this.assetRequestFormGroup.get(this.groupName).get("groups").value;
  }

  get selectedLocationLabel() {
    return this.assetRequestFormGroup.get(this.groupName).get("locations").value;
  }

  get isDuplicateAssetTitle(): boolean {
    return this.assetRequestFormGroup.get(this.groupName).get("isDuplicate").value;
  }

  // validateAssetTitle() {
  //   const assetTitle = this.formControl.get('assetTitle');
  //   const assetType = this.formControl.get('assetType');
  //   const assetGuid = this.formControl.get('assetGuid');
  //   if (!assetTitle || !assetType || !assetType.value || !assetTitle.value) {
  //     this.isDuplicateAssetTitle = false;
  //     return;
  //   }
  //   const payload: TaggerAssetValidationModel = {
  //     assetGuid: assetGuid.value,
  //     assetTitle: assetTitle.value,
  //     assetType: assetType.value,
  //   };

  //   this.taggerService.validateAssetTitle(payload).subscribe(
  //     (res: any) => {
  //       if (res.isSuccess == true) {
  //         this.isDuplicateAssetTitle = res.result;
  //       } else {
  //         this.isDuplicateAssetTitle = false;
  //       }
  //     },
  //     () => {
  //       this.isDuplicateAssetTitle = false;
  //     }
  //   );
  // }

  public displayFileName(groupName: string) {
    return this.assetRequestFormGroup.get(groupName).get("fileName").value
  }

  private assetTypeFilter() {
    var fileExtension = this.fileName && this.fileName.split('.').pop();
    if (fileExtension == 'mp3' || fileExtension == 'wav') {
      this.assetTypeDataSource = this.assetTypeDataSource.filter(f=> f.name == AssetType.VoiceOver);
    }
    else if (fileExtension == 'mov') {
      this.assetTypeDataSource = this.assetTypeDataSource.filter(f=> f.name == AssetType.BaseCreative);
    }
    else if (fileExtension == 'png') {
      this.assetTypeDataSource = this.assetTypeDataSource.filter(f=> f.name == AssetType.OfferTag || f.name == AssetType.EndTag);
    }
  }

  public onRemoveFile(groupName: string) {
    this.removeFile.emit(groupName);
  }

  public onSelectedGroups(groups) {
    const groupNames = groups && groups.map(group => group.name);
    if (groups && groups.length) {
      this.locationDataSource = groups;
    } else {
      this.locationDataSource = this.allLocations;
    }
    this.selectedGroups = groups || [];
    this.selectedLocations = [];
    this.assetRequestFormGroup.get(this.groupName).get("groups").setValue(groupNames);
    this.assetRequestFormGroup.get(this.groupName).get("locations").setValue(null);
  }

  public onSelectedLocations(locations) {
    const locationNames = locations && locations.map(location => location.name);
    this.selectedLocations = locations || [];
    this.assetRequestFormGroup.get(this.groupName).get("locations").setValue(locationNames);
  }

}
