import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject ,  Subscription } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { ProjectFolders, NewFolder } from '../../../models/project/create-project.model';
import { ProjectFolderService } from '../../../services/project/create-folder.service'

@Component({
  selector: 'create-new-projectfolder',
  templateUrl: './createnewprojectfolderpopup.component.html',
  styleUrls: ['./createnewprojectfolderpopup.component.css'],
})
export class CreateNewProjectFolderPopUp implements OnInit {

  public onClose: Subject<boolean>;
  public modalRef: BsModalRef;
  public createNewProjectFolderForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public projectFolders: ProjectFolders = new ProjectFolders();

  @Input() selectedProjectId: Number;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private fb: FormBuilder,
    private util: Utilities,
    private projectFolderService: ProjectFolderService) {
    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.createNewProjectFolderForm = this.fb.group({
      folderName: new FormControl('', Validators.required)
    });
  }

  public onCreateProjectFolderFormSubmit() {
    if (!this.createNewProjectFolderForm.valid) {
      return;
    }

    this.projectFolders = new ProjectFolders();

      this.projectFolders.folder.push({
        ProjectId: this.selectedProjectId,
        FolderName: this.createNewProjectFolderForm.value.folderName
      });

    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectFolderService.createProjectFolder(this.projectFolders).subscribe((res) => {
      this.alertService.ShowLoader(false);
      if (res.isFolderExist) {
        this.createNewProjectFolderForm.controls['folderName'].setErrors({
          'duplicate': true
        })
      }
      else {
        this.onClose.next(true);
        this.closePopup();
      }
    }, error => {
      if (this.util.handleError(error))
        this.closePopup();
    }));
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
