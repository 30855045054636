import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { OrderFTPDestinationDetailModel, OrderDetailFTPDestinationModel } from '../../../models/order/order-userdestination';
import { Constants } from "../../../configurations/constants";
import { OrderService } from "../../../services/order/order.service";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'ordereditftpdestinationpopup',
  templateUrl: './ordereditftpdestinationpopup.component.html',
  styleUrls: ['./ordereditftpdestinationpopup.component.css'],
})
export class OrderEditFTPDestinationPopupComponent extends BasePopupComponent {

  public createFtpDestinationRequest: FormGroup;
  public onClose: Subject<OrderDetailFTPDestinationModel>;

  @ViewChild("ftpForm", { static: true }) ftpForm: NgForm;
  @Input() data: OrderFTPDestinationDetailModel;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private orderService: OrderService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();

    this.createFtpDestinationRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required),
      orderDestinationId: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      ftpServer: new FormControl('', Validators.required),
      ftpUserName: new FormControl('', Validators.required),
      ftpPassword: new FormControl('', Validators.required)
    });

    console.log(this.data);
    //set values
    this.createFtpDestinationRequest.controls['name'].setValue(this.data.name);
    this.createFtpDestinationRequest.controls['ftpUserName'].setValue(this.data.ftpUserName);
    this.createFtpDestinationRequest.controls['ftpServer'].setValue(this.data.ftpServer);
    this.createFtpDestinationRequest.controls['ftpPassword'].setValue(this.data.ftpPassword);
    this.createFtpDestinationRequest.controls['orderGuid'].setValue(this.data.orderGuid);
    this.createFtpDestinationRequest.controls['orderDestinationId'].setValue(this.data.orderDestinationId);
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onCreateFtpDestinationFormSubmit({ value, valid }: { value: OrderFTPDestinationDetailModel, valid: boolean }) {
    if (!valid)
      return;
    else {
      console.log(value);
      this.orderService.updateOrderFTPUploadDestination(value)
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.onClose.next(res.result);
            this.alertService.showMessage("SUCCESS", Constants.userFtpDestinationUpdated, MessageSeverity.success);
          }
          else {
            this.onClose.next(res.result);
            this.util.handleIsNotSuccess(res.errors);
          }
        },
        error => {
          this.util.handleError(error);
        });
      this.closePopup();
    }
  }
}
