import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { CreateVaultRequest, VaultAssetEditRequest, VaultAssetNote, VaultAssetRemoveDestroyRequest, VaultAssetServicesRequest, VaultDigitalAssetEditRequest, VaultStatusChangeRequest } from "../../models/vault/vault-create.model";
import { VaultAssetStatusUpdateRequest } from "../../models/vault/vault-asset.model";

@Injectable()
export class VaultService {

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private authService: AuthService) { }

  public createVault(createVaultRequest: CreateVaultRequest) {
    return this.http.post(this.apiendPoint.createVaultEndpoint(), createVaultRequest);
  }

  public deleteVaultFile(fileGuid: string) {
    return this.http.delete(this.apiendPoint.deleteVaultFileEndpoint() + "/" + fileGuid);
  }

  public searchVaultRequests(request: any) {
    return this.http.post(this.apiendPoint.getVaultRequestSearchEndpoint(), request);
  }

  public searchVaultAssets(request: any) {
    return this.http.post(this.apiendPoint.getVaultAssetSearchEndpoint(), request);
  }

  public getSearchVaultConfig() {
    return this.http.get(this.apiendPoint.getVaultSearchConfigEndpoint());
  }

  public getVaultAssetDetails(vaultAssetGuid: string) {
    return this.http.get(this.apiendPoint.getVaultAssetDetailsEndpoint() + "/" + vaultAssetGuid);
  }

  public getVaultDigitalAssetDetails(vaultDigitalAssetLineItemId: number) {
    return this.http.get(this.apiendPoint.getVaultDigitalAssetDetailsEndpoint() + "/" + vaultDigitalAssetLineItemId);
  }

  public updateVaultAssetsStatusLogin(vaultAssetsRequest: VaultAssetStatusUpdateRequest) {
    return this.http.post(this.apiendPoint.getUpdateVaultAssetsStatusLoginEndpoint(), vaultAssetsRequest);
  }

  public updateVaultAssetsStatusStaging(vaultAssetsRequest: VaultAssetRemoveDestroyRequest) {
    console.log(vaultAssetsRequest)
    return this.http.post(this.apiendPoint.getUpdateVaultAssetsStatusStagingEndpoint(), vaultAssetsRequest);
  }

  public updateVaultAssetStatusVault(request: VaultStatusChangeRequest) {
    return this.http.post(this.apiendPoint.updateVaultAssetStatusVault(), request);
  }

  public getVaultRequestDetail(vaultRequestGuid: string) {
    return this.http.get(this.apiendPoint.getVaultRequestDetailEndpoint() + "/" + vaultRequestGuid);
  }

  public getVaultAssetEditConfig() {
    return this.http.get(this.apiendPoint.getVaultAssetEditConfigEndpoint());
  }

  public updateVaultAsset(vaultAssetEditRequest: VaultAssetEditRequest) {
    return this.http.post(this.apiendPoint.updateVaultAssetEndpoint(), vaultAssetEditRequest);
  }

  public updateVaultDigitalAsset(vaultDigitalAssetEditRequest: VaultDigitalAssetEditRequest) {
    return this.http.post(this.apiendPoint.updateVaultDigitalAssetEndpoint(), vaultDigitalAssetEditRequest);
  }

  public updateVaultRequestDetailHeader(vaultRequestGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateVaultRequestDetailHeaderEndpoint() + "/" + vaultRequestGuid, request);
  }

  public updateVaultRequestDetail(vaultRequestGuid: string, request: any) {
    console.log(request);
    return this.http.put(this.apiendPoint.updateVaultRequestDetailEndpoint() + "/" + vaultRequestGuid, request);
  }

  public addVaultAssetNote(vaultAssetNote: VaultAssetNote) {
    return this.http.post(this.apiendPoint.addVaultAssetNoteEndpoint(), vaultAssetNote);
  }

  public getVaultAssetAdditionalServices(vaultAssetId: number, vaultRequestId: number) {
    return this.http.get(this.apiendPoint.getVaultAssetAdditionalServicesEndpoint() + "/" + vaultAssetId + "/" + vaultRequestId);
  }

  public updateVaultAssetAdditionalServices(request: VaultAssetServicesRequest) {
    console.log(request);
    return this.http.put(this.apiendPoint.updateVaultAssetAdditionalServicesEndpoint(), request);
  }

  public downloadVaultFile(vaultFileGuid: string) {
    return this.http.get(this.apiendPoint.downloadVaultFileEndpoint() + "/" + vaultFileGuid);
  }

  public getVaultShippingServices() {
    return this.http.get(this.apiendPoint.getVaultShippingServicesEndpoint());
  }

  public updateVaultAssetsStatusRemove(request: VaultAssetRemoveDestroyRequest) {
    return this.http.post(this.apiendPoint.updateVaultAssetsStatusRemoveEndpoint(), request);
  }

  public updateVaultAssetsStatusLogout(request: VaultAssetRemoveDestroyRequest) {
    return this.http.post(this.apiendPoint.updateVaultAssetsStatusLogoutEndpoint(), request);
  }

  public updateVaultAssetsStatusDestroy(vaultAssetsRequest: VaultAssetStatusUpdateRequest) {
    return this.http.post(this.apiendPoint.getUpdateVaultAssetsStatusDestroyEndpoint(), vaultAssetsRequest);
  }

  public updateVaultAssetMissing(vaultAssetId: number) {
    return this.http.get(this.apiendPoint.updateVaultAssetMissingEndpoint() + "/" + vaultAssetId);
  }

  public updateVaultDigitalAssetMissing(vaultDigitalAssetId: number) {
    return this.http.get(this.apiendPoint.updateVaultDigitalAssetMissingEndpoint() + "/" + vaultDigitalAssetId);
  }

  public searchVaultDigitalStorage(request: any) {
    return this.http.post(this.apiendPoint.searchVaultDigitalStorageEndpoint(), request);
  }
}
