import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SpotqcComponent } from './spotqc/spotqc.component';
import { SpotApprovalComponent } from './spotapproval/spotapproval.component';
import { SpotSearchComponent } from './spotsearch/spotsearch.component';
import { MediaService } from "../../services/media/media.service";
import { AuthService } from "../../services/auth/auth.service";
import { Spot } from "../../models/spot/spot.model";
import { Utilities } from "../../services/core/utilities";
import { UserRoles } from "../../configurations/user-roles";
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { Constants } from "../../configurations/constants";
import { PlayList } from "../../models/playlist/playlist.model";
import { mediaDetailSource } from "../../configurations/enums/enums";
import { RecentlyViewed } from "../../models/spot/recentlyviewed.model";
import { SubService } from "../../services/media/sub.service";
import { RouteConstants } from "../../configurations/route-constants";

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  public spotapproverParent: Spot[];
  public recentlyViewed: RecentlyViewed[];
  public searchConfig: any;
  public playList: PlayList[];
  public isPlayListExists: boolean = false;

  constructor(private mediaService: MediaService,
    private authService: AuthService,
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private subService: SubService) {
  }

  ngOnInit() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.mediaService.getMedia().subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.searchConfig = res.searchConfig;

        this.spotapproverParent = res.approvalReadySpots as Spot[];

        this.util.spotApprovalCount = this.spotapproverParent != null ? this.spotapproverParent.length : 0;

        this.recentlyViewed = res.recentlyViewedSpots;

        this.playList = res.playList;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.spotapproverParent = [];
        this.recentlyViewed = [];
        this.playList = [];
        this.util.handleError(error);

        this.alertService.ShowLoader(false);
      });

  }

  public gotoUpload() {
    this.router.navigate([RouteConstants.mediaUploadRoute]);
  }

  public gotoDownload() {
    this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "media" } });
  }

  public gotoTranscodeFiles() {
    this.router.navigate([RouteConstants.transcodeFilesRoute]);
  }

  public gotoTalentRights() {
    this.router.navigate([RouteConstants.talentRightsRoute]);
  }
 
  public showPlayList(playList: PlayList[]) {
    if (playList != null && playList != undefined && playList.length > 0) {
      this.isPlayListExists = true;
    }
    else {
      this.isPlayListExists = false;
    }
  }

  ngOnDestroy() {
    //this.alertService.ShowLoader(false);
  }
}
