<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header media-text">
      <h3 class="media-text">Download Tylie Media Client (TMC)</h3>
    </div>
    
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li (click)="gotoMedia()">
          <a class="back-to-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.37 108.37">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M54.19,0a54.19,54.19,0,1,0,54.18,54.18A54.19,54.19,0,0,0,54.19,0Zm6,82.36L32.51,54.76,58.42,28.84l6.8,6.86-19,19L67,75.62Z" />
                </g>
              </g>
            </svg>
            <span class="back_to_nav">Back to All Distribution Assets</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="inner-content">
  <div class="panel-body">
    <h4 class="media-text p-normal-bold">Tylie Media Client (TMC) (Windows)</h4>

    <dx-data-grid id="tmcReleaseGrid"
                  #tmcReleaseGrid
                  [dataSource]="windowsReleaseStore"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  keyExpr="tmcReleaseInfoId"
                  [showBorders]="false"
                  [rowAlternationEnabled]="true">
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="displayName" caption="Release Version" width="25%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="releaseDate" caption="Release Date" cellTemplate="dateTemplate" width="15%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="osVersion" caption="OS Version" width="30%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="downloadPath" caption="" width="15%" cellTemplate="releasePathTemplate" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="installationInfoPath" caption="" width="20%" cellTemplate="installationInfoPathTemplate" cssClass="gridStyle tyl-text-align-left"></dxi-column>

      <div *dxTemplate="let t of 'releasePathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, true)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class="glyphicon glyphicon-download"></i><span>&nbsp;Download</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let t of 'installationInfoPathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, false)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <span>Installation Guide</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let data of 'dateTemplate'">
        <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
      </div>

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true"
                 [allowedPageSizes]="[10, 20, 50, 100]"
                 infoText="Page {0} of {1} ({2} items)"
                 [showNavigationButtons]="true"
                 [showInfo]="true">
      </dxo-pager>
    </dx-data-grid>
  </div>

  <div class="panel-body">
    <h4 class="media-text p-normal-bold">Tylie Media Client (TMC) (Linux / UNIX)</h4>

    <dx-data-grid id="tmcReleaseGrid"
                  #tmcReleaseGrid
                  [dataSource]="linuxReleaseStore"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  keyExpr="tmcReleaseInfoId"
                  [showBorders]="false"
                  [rowAlternationEnabled]="true">
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="displayName" caption="Release Version" width="25%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="releaseDate" caption="Release Date" cellTemplate="dateTemplate" width="15%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="osVersion" caption="OS Version" width="30%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="downloadPath" caption="" width="15%" cellTemplate="releasePathTemplate" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="installationInfoPath" caption="" width="20%" cellTemplate="installationInfoPathTemplate" cssClass="gridStyle tyl-text-align-left"></dxi-column>

      <div *dxTemplate="let t of 'releasePathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, true)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class="glyphicon glyphicon-download"></i><span>&nbsp;Download</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let t of 'installationInfoPathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, false)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <span>Installation Guide</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let data of 'dateTemplate'">
        <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
      </div>

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true"
                 [allowedPageSizes]="[10, 20, 50, 100]"
                 infoText="Page {0} of {1} ({2} items)"
                 [showNavigationButtons]="true"
                 [showInfo]="true">
      </dxo-pager>
    </dx-data-grid>
  </div>

  <div class="panel-body">
    <h4 class="media-text p-normal-bold">Tylie Media Client (TMC) (Mac OS X)</h4>

    <dx-data-grid id="tmcReleaseGrid"
                  #tmcReleaseGrid
                  [dataSource]="macReleaseStore"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  keyExpr="tmcReleaseInfoId"
                  [showBorders]="false"
                  [rowAlternationEnabled]="true">
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="displayName" caption="Release Version" width="25%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="releaseDate" caption="Release Date" cellTemplate="dateTemplate" width="15%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="osVersion" caption="OS Version" width="30%" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="downloadPath" caption="" width="15%" cellTemplate="releasePathTemplate" cssClass="gridStyle"></dxi-column>
      <dxi-column [allowSorting]="false" dataField="installationInfoPath" caption="" width="20%" cellTemplate="installationInfoPathTemplate" cssClass="gridStyle tyl-text-align-left"></dxi-column>

      <div *dxTemplate="let t of 'releasePathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, true)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class="glyphicon glyphicon-download"></i><span>&nbsp;Download</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.downloadPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let t of 'installationInfoPathTemplate'">
        <a class="icon-link icon-primary icon-small text-primary" (click)="onDownloadReleaseFile(t.data, false)" *ngIf="!downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <span>Installation Guide</span>
        </a>
        <a class="icon-link icon-primary icon-small text-primary" *ngIf="downloadingReleases.includes(t.data.tmcReleaseInfoId+t.data.installationInfoPath)">
          <i class='fa fa-circle-o-notch fa-spin'></i><span>&nbsp;Downloading...</span>
        </a>
      </div>

      <div *dxTemplate="let data of 'dateTemplate'">
        <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
      </div>

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true"
                 [allowedPageSizes]="[10, 20, 50, 100]"
                 infoText="Page {0} of {1} ({2} items)"
                 [showNavigationButtons]="true"
                 [showInfo]="true">
      </dxo-pager>
    </dx-data-grid>
  </div>
</div>
