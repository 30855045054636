import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { ConfigService } from '../../../services/core/config.service';
import { BundleService } from '../../../models/admin/client/createclient.model';
import { DxTreeViewComponent } from 'devextreme-angular';

@Component({
  selector: 'addeditbundleservices',
  templateUrl: './addeditbundleservices.component.html',
  styleUrls: ['./addeditbundleservices.component.css']
})
export class AddEditBundleServicesPopUp implements OnInit {

  @Input('bundleServiceSelected') bundleServiceSelected: BundleService = null;
  @Input('bundleServices') bundleServices: BundleService[] = [];
  @Input('isEdit') isEdit: boolean = false;

  @ViewChild('treeView', { static: false }) treeView: DxTreeViewComponent;

  public modalRef: BsModalRef;

  public bundleServicesForm: FormGroup;

  public onSaveBundleServices: Subject<BundleService>;
  public servicesDataSource: any[] = [];
  public bundleServicesDataSource: any[] = [];

  public servicesValue: Array<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService,
    protected changeDetectorRef: ChangeDetectorRef) {

    this.onSaveBundleServices = new Subject<BundleService>();
  }

  ngOnInit() {
    this.getClientBundleServicesConfig();

    this.bundleServicesForm = this.fb.group({
      bundleServiceCode: [null, Validators.compose([Validators.required])],
      bundleServiceName: [null],
      serviceCodes: [null, Validators.compose([Validators.required])]
    });

    if (this.isEdit == true && this.bundleServiceSelected != null && this.bundleServiceSelected != undefined) {
      this.updateClientEditForm(this.bundleServiceSelected);
    }
  }

  public onaddEditBundleServicesFormSubmit({ value, valid }: { value: BundleService, valid: boolean }) {

    if (!valid) {
      return;
    }

    var servName = this.bundleServicesDataSource.find(serv => serv.code == value.bundleServiceCode);

    if (servName != undefined) {
      value.bundleServiceName = servName.name;
    }

    if (value.serviceCodes != null && value.serviceCodes != undefined) {
      var srvNames: string[] = [];
      value.serviceCodes.forEach(srv => {
        var name = this.servicesDataSource.find(sr => sr.code == srv).name;
        srvNames.push(name);
      });

      value.serviceNames = srvNames.join(', ');
    }

    this.onSaveBundleServices.next(value);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (this.servicesValue == null || this.servicesValue.length <= 0) {
      component.unselectAll();
    }

    if (this.servicesValue) {
      this.servicesValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var nodes = e.component.getSelectedNodesKeys() as Array<string>;
    console.log(nodes);
    this.servicesValue = nodes;

    this.changeDetectorRef.detectChanges();
  }

  private filterBundleServices(bundleServices: BundleService[]) {
    var bundleServicesFiltered: any[] = [];
    if (this.bundleServices != null && this.bundleServices.length > 0) {
      this.bundleServicesDataSource.forEach
        (ser => {
          if (this.bundleServices.findIndex(bs => bs.bundleServiceCode.toLowerCase() == ser.code.toLowerCase()) == -1) {
            bundleServicesFiltered.push(ser);
          }
        });

      this.bundleServicesDataSource = bundleServicesFiltered;
    }
  }

  private getClientBundleServicesConfig() {
    this.configService.getClientBundleServicesConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var res = res.result;
        this.bundleServicesDataSource = res.bundleServices;
        this.servicesDataSource = res.servicesAvailableForBundling;

        this.filterBundleServices(this.bundleServices);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
      });
  }

  private updateClientEditForm(bundleServiceSelected: BundleService) {
    this.bundleServicesForm.controls['bundleServiceCode'].setValue(bundleServiceSelected.bundleServiceCode);
    this.bundleServicesForm.controls['bundleServiceName'].setValue(bundleServiceSelected.bundleServiceName);
    this.bundleServicesForm.controls['serviceCodes'].setValue(bundleServiceSelected.serviceCodes);

    this.servicesValue = bundleServiceSelected.serviceCodes;
  }
}
