<div class="tylie-accordion accordion" id="search-all-orders-section">
  <div class="tylie-accordion__panel accordion-item" id="orderSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#orderSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH ALL USERS
      </button>
    </h2>
    <div id="orderSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">

      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                   <input class="tylie-input-search__text" placeholder="Search By UserName or First Name or Last Name or Destination" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                   (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col-6">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>

        <div class="tylie-section collapse collapse" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
          <form novalidate role="search" (ngSubmit)="onFormSubmit(searchUserFormGroup)" [formGroup]="searchUserFormGroup">
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="brand">Client</label>
                  <dx-select-box [dataSource]="clientDataSource"
                                 name="client" id="client" class="tylie-select form-control"
                                 formControlName="client"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 (onValueChanged)="onClientValueChanged($event)"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group" *ngIf="this.searchConfig.allowSpecialOptions == true">
                  <label class="tylie-form-group__lbl" for="brand">Post House</label>
                  <dx-select-box [dataSource]="postHouseDataSource"
                                 name="posthouse" id="posthouse" class="tylie-select form-control"
                                 formControlName="posthouse"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-check mt-4">
                  <input type="checkbox" class="form-check-input" formControlName="includeInactiveUsers" name="includeInactiveUsers" id="includeInactiveUsers" />
                  <label for="includeInactiveUsers" class="form-check-label">Include InActive Users</label>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>
      </div>

     
      <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
        
            <div id="brandview">
              <dx-data-grid id="userSearch" class="tylie-grid"
                            #userSearch
                            [dataSource]="resultUserProfileStore"
                            [allowColumnReordering]="true"
                            [allowColumnResizing]="true"
                            [columnAutoWidth]="true"
                            [showColumnLines]="true"
                            [showRowLines]="true"
                            [hoverStateEnabled]="true"
                            keyExpr="userProfileId"
                            [rowAlternationEnabled]="true"
                            [showBorders]="true">

                <dxi-column dataField="userName" caption="Username" cellTemplate="userNameTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="15%" sortOrder="asc"></dxi-column>
                <dxi-column dataField="email" caption="Email" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="15%"></dxi-column>
                <dxi-column dataField="lastName" caption="Last Name" cssClass="gridStyle tyl-nopadding tyl-grid-padding dx-datagrid-nowrap-remove" width="15%"></dxi-column>
                <dxi-column dataField="firstName" caption="First Name" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="15%"></dxi-column>
                <dxi-column dataField="client.name" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="15%"></dxi-column>
                <dxi-column dataField="userBrands" caption="Brands" cellTemplate="userBrandsTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="20%" [allowSorting]="false"></dxi-column>
                <dxi-column dataField="editorialHouse.name" caption="Post House" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="15%" [visible]="this.searchConfig.allowSpecialOptions == true"></dxi-column>
                <dxi-column dataField="destinationName" caption="Destination" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%" [visible]="this.searchConfig.allowSpecialOptions == true"></dxi-column>

                <div *dxTemplate="let t of 'userNameTemplate'">
                  <span *ngIf="t.data.isViewAllowed  == true" class="quickLink" (click)='showUserProfileDetail(t.data.userProfileGuid)'>{{t.data.userName}}</span>
                  <span *ngIf="t.data.isViewAllowed  == false">{{t.data.userName}}</span>
                </div>

                <div *dxTemplate="let data of 'userBrandsTemplate'">
                  <span class="dx-datagrid-nowrap-remove">
                    {{data.value | joinBySeperator: '; '}}
                  </span>
                </div>

                <dxo-remote-operations [sorting]="true"
                                       [paging]="true">
                </dxo-remote-operations>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true"
                           [allowedPageSizes]="[10, 20, 50, 100]"
                           infoText="Page {0} of {1} ({2} items)"
                           [showNavigationButtons]="true"
                           [showInfo]="true">
                </dxo-pager>
              </dx-data-grid>
            </div>
          
      </div>
     
    </div>
  </div>
</div>
