<div id="spot-history">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{this.title}}</h4>
  </div>
  <div class="modal-body">
    <div id="orderHistoryCollapseOne" class="tylie-panel tylie-panel--secondary mb-0">
      <div class="tylie-panel__header tylie-panel__header--br-thick">
        <h4 class="tylie-panel__title">
          {{this.headerText}}
        </h4>
      </div>
      <div class="tylie-panel__body mb-0">     
        <div  class="tylie-table-wrapper" *ngIf="this.selectedAssets.length > 0">
          <table class="tylie-table table table-bordered">
            <thead class="tylie-table__head">
              <tr>
                <th width="20%" *ngIf="isVaultDigitalAsset == false">Asset Barcode</th>
                <th width="20%" *ngIf="isVaultDigitalAsset == false">Asset Format</th>
                <th width="20%" *ngIf="isVaultDigitalAsset == true">Asset Identifier</th>
                <th width="20%">Client</th>
                <th width="20%">Brand</th>
                <th width="20%">Product</th>
              </tr>
            </thead>
            <tbody class="tylie-table__body">
              <tr *ngFor="let att of this.selectedAssets; let i = index">
                <td *ngIf="isVaultDigitalAsset == false">{{att.assetBarcode}}</td>
                <td *ngIf="isVaultDigitalAsset == false">{{att.format}}</td>
                <td *ngIf="isVaultDigitalAsset == true">{{att.assetIdentifier}}</td>
                <td>{{att.client}}</td>
                <td>{{att.brand}}</td>
                <td>{{att.product}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tylie-form-group form-group" [ngClass]="{error: this.isNotesEmpty == true}">
      <label class="tylie-form-group__lbl" for="notes">Notes</label>
      <textarea id="notes" name="notes" [(ngModel)]="txtNotes" class="tylie-textarea tylie-textarea--lg form-control"></textarea>
      <p style="color:red" *ngIf="this.isNotesEmpty == true">Please enter notes</p>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--link me-3" type="button" (click)="closePopup()">Cancel</button>
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onStageClicked()">
        <span class="tylie-button__icon"><icon-settings color="#fff"></icon-settings></span>
        <span class="tylie-button__text">Staging</span>
      </button>
      <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" (click)="onLoginClicked()" *ngIf="this.functionButtonVisible && this.source == 5">
        <span class="tylie-button__icon"><icon-vault-login></icon-vault-login></span>
        <span class="tylie-button__text">{{this.functionButtonText}}</span>
      </button>
      <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" (click)="onLoginClicked()" *ngIf="this.functionButtonVisible && this.source == 2">
        <span class="tylie-button__icon"><icon-trash-can></icon-trash-can></span>
        <span class="tylie-button__text">{{this.functionButtonText}}</span>
      </button>
    </div>
  </div>
</div>
