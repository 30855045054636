import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { customPhoneValidator, EmailListValidatorIfValueExists, EmailListValidator } from "../../../../configurations/custom-validations";
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { CreateDestinationConfig, CreateDestination } from "../../../../models/admin/destination/createdestination.model";
import { DxTreeViewComponent } from "devextreme-angular";
import { AdminDefaultHubDestinationSearchComponent } from "../../../popups/admindefaulthubdestinationsearch/admindefaulthubdestinationsearchpopup.component";
import { SelectNetworkComponent } from "./select-network/select-network.component";
import { AdminDestinationSettingsPopupComponent } from '../../../popups/admindestinationsettingspopup/admindestinationsettingspopup.component';
import { AddEditSpecDetailsPopUp } from '../../../popups/addeditspecdetailspopup/addeditspecdetailspopup.component';
import { saveAs } from "file-saver";

@Component({
  selector: 'create-destination',
  templateUrl: './create-destination.component.html',
  styleUrls: ['./create-destination.component.css']
})
export class CreateDestinationComponent implements OnInit {
  public createDestinationFormGroup: FormGroup;

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeBoxValue: number[];

  @ViewChild(SelectNetworkComponent, { static: false }) selectNetworkComponent: SelectNetworkComponent;

  public modalRef: BsModalRef;

  public destinationRoute = RouteConstants.destinationsRoute;
  public createDestinationConfig: CreateDestinationConfig = null;
  public showFTPConfig: boolean = false;
  public showLinksConfig: boolean = false;
  public networkSpecifications: any[] = []
  public showDistributionSettings: boolean = false;
  public bxfByteArray: any = null;
  public bxfXML: any = null;
  public isDestinationTypeInternational: boolean = false;
  public isDestinationTypeBackup: boolean = false;
  public s3: any = null;
  public aspera: any = null;
  public googleDrive: any = null;
  public dropBox: any = null;
  public backupS3: any = null;
  public backupAspera: any = null;
  public backupGoogleDrive: any = null;
  public backupDropBox: any = null;

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , private modalService: BsModalService
    , private fb: FormBuilder) {

  }

  ngOnInit() {
    this.getConfig();

    this.createDestinationFormGroup = this.fb.group({
      code: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      destinationType: [null, Validators.compose([Validators.required])],
      akas: [null],
      defaultHub: [null],
      market: [null],
      acceptableFormats: [null],
      preferredFormat: [null],
      secondaryFormat: [null],
      preferredVendorId: [null],
      fileDeliveryType: [null],
      ftpServer: [null],
      ftpUserName: [null],
      ftpPassword: [null],
      ftpPort: [null],
      linkEmails: [null],
      s3: [null],
      aspera: [null],
      googleDrive: [null],
      dropBox: [null],
      isCompanionFormatRequired: [null],
      acceptsDescriptiveVideo:[null],
      isTrafficOnly: [false],
      comments: [null],
      destinationContact: this.fb.group({
        destinationContacts: this.addMainDestinationContactFormToArray()
      }),
      isHouseIdRequired: [false],
      specifications: [null],
      bxfXML: [null],
      transcodeCustomFileName: [null],
      transcodeCustomFileNameSeperator: ["_"],
      notes: [null],
      primaryDestination:[null],


      // not in use
      backupFileDeliveryType: [null],
      backupFtpServer: [null],
      backupFtpUserName: [null],
      backupFtpPassword: [null],
      backupFtpPort: [null],
      backupLinkEmails: [null],
      backupS3: [null],
      backupAspera: [null],
      backupGoogleDrive: [null],
      backupDropBox: [null],
      backupNotes: [null],
    });
  }

  public onFormSubmit({ value, valid }: { value: CreateDestination, valid: boolean }) {
    console.log(value);
    if (valid == false) {
      return;
    }

    this.vaidateCreateDestinationRequest(value);
  }

  public onDefaultHubSearch() {
    var initialState = {

    };

    this.modalRef = this.modalService.show(AdminDefaultHubDestinationSearchComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      this.createDestinationFormGroup.controls['defaultHub'].setValue(result);
    });
  }

  public onPrimaryDestinationSearch() {
    var initialState = {

    };

    this.modalRef = this.modalService.show(AdminDefaultHubDestinationSearchComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      this.createDestinationFormGroup.controls['primaryDestination'].setValue(result);
    });
  }

  public gotoDestinationHome() {
    this.router.navigate([RouteConstants.destinationsRoute]);
  }

  public syncTreeViewSelection(e) {
    var component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
  }

  public onAddDistributionSettings() {
    var customSelections = [];
    if (this.createDestinationFormGroup.controls['transcodeCustomFileName'].value != null && this.createDestinationFormGroup.controls['transcodeCustomFileName'].value != undefined) {
      var sep = this.createDestinationFormGroup.controls['transcodeCustomFileNameSeperator'].value;
      customSelections = this.createDestinationFormGroup.controls['transcodeCustomFileName'].value.split(sep);
    }

    var initialState = {
      isHouseIdRequired: this.createDestinationFormGroup.controls['isHouseIdRequired'].value,
      fileDeliveryType: this.createDestinationFormGroup.controls['fileDeliveryType'].value,
      linkEmails: this.createDestinationFormGroup.controls['linkEmails'].value,
      ftpServer: this.createDestinationFormGroup.controls['ftpServer'].value,
      ftpPassword: this.createDestinationFormGroup.controls['ftpPassword'].value,
      ftpUserName: this.createDestinationFormGroup.controls['ftpUserName'].value,
      ftpPort: this.createDestinationFormGroup.controls['ftpPort'].value,
      notes: this.createDestinationFormGroup.controls['notes'].value,
      s3: this.s3,
      aspera: this.aspera,
      googleDrive: this.googleDrive,
      dropBox: this.dropBox,
      config: this.createDestinationConfig,
      customfilenamepos1: customSelections.length >= 1 ? customSelections[0] : null,
      customfilenamepos2: customSelections.length >= 2 ? customSelections[1] : null,
      customfilenamepos3: customSelections.length >= 3 ? customSelections[2] : null
    };

    this.modalRef = this.modalService.show(AdminDestinationSettingsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        this.createDestinationFormGroup.controls['isHouseIdRequired'].setValue(result.isHouseIdRequired);
        this.createDestinationFormGroup.controls['fileDeliveryType'].setValue(result.fileDeliveryType);
        if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeLinks) {
          this.createDestinationFormGroup.get('linkEmails').setValue(result.linkEmails);
        }
        else if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeFTP) {
          this.createDestinationFormGroup.get('ftpServer').setValue(result.ftpServer);
          this.createDestinationFormGroup.get('ftpUserName').setValue(result.ftpUserName);
          this.createDestinationFormGroup.get('ftpPassword').setValue(result.ftpPassword);
          this.createDestinationFormGroup.get('ftpPort').setValue(result.ftpPort);
        }
        else if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeS3) {
          this.s3 = result.s3;
        }
        else if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeAspera) {
          this.aspera = result.aspera;
        }
        else if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeGdrive) {
          this.googleDrive = result.googleDrive;
        }
        else if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeDropBox) {
          this.dropBox = result.dropBox;
        }
        if (result.fileDeliveryType == this.createDestinationConfig.fileDeliveryTypeManual) {
          this.createDestinationFormGroup.get('notes').setValue(result.notes);
        }

        if (result.bxfByteArray != null) {
          this.createDestinationFormGroup.controls['bxfXML'].setValue(result.bxfByteArray);
          this.bxfByteArray = result.bxfByteArray;
          this.bxfXML = result.bxfXML;
        }
        result.specifications.forEach(spec => {
          console.log(spec.id)
          if (this.networkSpecifications.find(t => t.id == spec.id) == undefined) {
            this.networkSpecifications.push(spec);
          }
        });

        this.createDestinationFormGroup.controls['transcodeCustomFileName'].setValue(result.transcodeCustomFileName);
      }
    });
  }

  public onDestinationTypeChange() {
    if (this.createDestinationFormGroup.controls['destinationType'].value == this.createDestinationConfig.fileDestinationTypeInternational) {
      this.isDestinationTypeInternational = true;
      this.createDestinationFormGroup.get('market').setValue(null);
      this.createDestinationFormGroup.get('market').setValidators([Validators.required]);
      this.createDestinationFormGroup.controls["market"].updateValueAndValidity();
    }
    else if (this.createDestinationFormGroup.controls['destinationType'].value == this.createDestinationConfig.fileDestinationTypeBackup) {
      this.isDestinationTypeBackup = true;
      this.createDestinationFormGroup.get('primaryDestination').setValue(null);
      this.createDestinationFormGroup.get('primaryDestination').setValidators([Validators.required]);
      this.createDestinationFormGroup.controls["primaryDestination"].updateValueAndValidity();
    }
    else {
      this.isDestinationTypeInternational = false;
      this.createDestinationFormGroup.get('market').setValue(null);
      this.createDestinationFormGroup.get('market').clearValidators();
      this.createDestinationFormGroup.controls["market"].updateValueAndValidity();

      this.isDestinationTypeBackup = false;
      this.createDestinationFormGroup.get('primaryDestination').setValue(null);
      this.createDestinationFormGroup.get('primaryDestination').clearValidators();
      this.createDestinationFormGroup.controls["primaryDestination"].updateValueAndValidity();
    }
  }

  public onPreferredNetworkChange() {
    if (this.createDestinationConfig != null && this.createDestinationConfig.networksRequireDestinationSettings != null
      && this.createDestinationConfig.networksRequireDestinationSettings.includes(this.createDestinationFormGroup.controls['preferredVendorId'].value)) {
      this.showDistributionSettings = true;
    }
    else {
      this.showDistributionSettings = false;
    }
  }

  public onAddEditRecipeName(spec) {
    console.log(spec);
    var initialState = {
      recipeName: spec.recipeName
    };

    this.modalRef = this.modalService.show(AddEditSpecDetailsPopUp, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        var editedSpec = this.networkSpecifications.find(t => t.id == spec.id)
        editedSpec.recipeName = result.recipeName
      }
    });
  }

  public onRemoveSpecMapping(spec) {
    this.networkSpecifications = this.networkSpecifications.filter(ns => ns.id != spec.id);
  }

  public onDownloadBxfFile() {
    const file = new Blob([this.bxfXML], { type: 'text/plain' });
    saveAs(file, "bxf-file.xml");
  }

  public onRemoveBXfFile() {
    this.createDestinationFormGroup.controls['bxfXML'].setValue(null);
  }

  private getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  private createDestination(value: CreateDestination) {
    this.adminService.createDestination(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        if (res.errors != null && res.errors.length > 0) {
          var err = res.errors as string[];
          this.alertService.showMessage("WARNING", err.join(";"), MessageSeverity.warn);
        }
        else {
          this.alertService.showMessage("SUCCESS", Constants.createDestinationSuccessful, MessageSeverity.success);
        }
        this.router.navigate([RouteConstants.destinationsRoute]);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateDestinationConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.createDestinationConfig = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private addMainDestinationContactFormToArray() {
    var destinationContactsArray: FormArray = this.fb.array([]);

    const group = this.fb.group({
      name: [null],
      title: [null],
      address1: [null],
      address2: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      phone: [null, Validators.compose([customPhoneValidator])],
      fax: [null],
      email: ['', Validators.compose([EmailListValidatorIfValueExists])],
      isMainContact: [true]
    });

    destinationContactsArray.push(group);
    return destinationContactsArray;
  }

  private vaidateCreateDestinationRequest(value: CreateDestination) {
    console.log(value);
    if (value.defaultHub != null && value.defaultHub != undefined && value.defaultHub != '') {
      this.adminService.isDestinationCodeValid(value.defaultHub).subscribe((res: any) => {
        if (res.isSuccess == true) {
          if (res.result) {
            if (this.validateVendorDestinationMappings(value) == true) {
              value.specifications = this.networkSpecifications;
              this.createDestination(value);
            }
          }
          else {
            this.createDestinationFormGroup.controls['defaultHub'].setErrors({ invalidDestination: true });
          }
        }
      },
        error => {
          this.util.handleError(error);
        });
    }
    else {
      if (this.validateVendorDestinationMappings(value) == true) {
        value.specifications = this.networkSpecifications;
        value.aspera = this.aspera;
        value.s3 = this.s3;
        value.googleDrive = this.googleDrive;
        value.dropBox = this.dropBox;
        this.createDestination(value);
      }
    }
  }

  private validateVendorDestinationMappings(value: CreateDestination): boolean {
    if (this.selectNetworkComponent != null) {
      var vendorDestinationList = this.selectNetworkComponent.getSelectedVendors();
      if (this.util.notEmpty(vendorDestinationList) && vendorDestinationList.length > 0) {
        if (vendorDestinationList.find(t => t.vendorDestinationCode == null && t.isDestinationMappingAvailable == true) == undefined) {
          value.vendorDestinationMappings = vendorDestinationList;
          return true;
        }
        else {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.destinationMappingIdNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
          return false;
        }
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  ngOnDestroy() {

  }
}
