<header class="tylie-header">
    <h3 class="tylie-header__title">Tagger</h3>
    <nav class="tylie-header__nav">
        <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoTaggerHome()"><span
                class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back
            To Tagger Home
        </button>
    </nav>
</header>

<div class="tylie-body">
    <h4 class="tylie-body__title">Creative & Tags</h4>
    <p class="tylie-body__subtitle">Currently available Base Creative and Tags.</p>
    <div class="tylie-panel">
        <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              Current Base Creative
            </h4>
        </div>
        <div class="tylie-panel__body">
            <p class="tylie-type-body mb-3">
                <span>Base Creative are the main advertisements from which you can build your customized
                    versions featuring Tags such as</span>
                <span>Offer Tags, End tags, and Voice Over to localize your ad to your franchise. Expired
                    Base
                    Creative are removed from this list.</span>
            </p>
            <div class="row">
                <div class="col-sm-3" *ngFor="let baseCreative of baseCreatives">
                    <div class="tylie-card">
                        <div class="tylie-card__img-wrap">
                            <img class="tylie-card__img" [src]="baseCreative.thumbnailDisplayUrl"
                                *ngIf="baseCreative.isPlayable"
                                (click)="quickPlay(baseCreative.proxyurl, baseCreative.creativeName, baseCreative.thumbnailurl, baseCreative.format)"
                                alt="Image">
                            <img class="tylie-card__img" [src]="baseCreative.thumbnailDisplayUrl" *ngIf="!baseCreative.isPlayable" alt="Image">
                        </div>
                        <div class="tylie-card__body">
                            <Label class="tylie-card__title">{{baseCreative.creativeName}}</Label>
                            <div class="tylie-data tylie-data--block">
                                <ng-container *ngIf="baseCreative.expirydate; else elseAvailableTemplate">
                                    <span class="tylie-data__txt">Available: {{baseCreative.availableForOrderingFromDate |
                                        date:'M/d/yy'}} -
                                        {{baseCreative.expirydate | date:'M/d/yy'}}</span>
                                </ng-container>
                                <ng-template #elseAvailableTemplate>
                                    <span class="tylie-data__txt">Available: Unlimited</span>
                                </ng-template>
                            </div>
                            <div class="tylie-data tylie-data--block">
                                <span class="tylie-data__txt">Length: {{baseCreative.durationinsecs}}</span>
                            </div>
                            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon mt-2" (click)="onSelect(baseCreative)">
                                <span class="tylie-button__icon">
                                   <icon-arrow-circle-right></icon-arrow-circle-right>
                                </span>
                                <span class="tylie-button__text">CREATE AD WITH THIS BASE</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tylie-panel">
        <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
                Current Offer Cards
            </h4>
        </div>
        <div class="tylie-panel__body">
            <p class="tylie-type-body mb-3">
                <span>Offer Tags promote specials and are inserted into predetermined sections of the Base
                    Creative. Expired OfferTags are removed from the list.</span>
            </p>
            <div class="row">
                <div class="col-sm-3" *ngFor="let offerCard of offerCards">
                    <div class="tylie-card">
                        <div class="tylie-card__img-wrap">
                            <img [src]="offerCard.cardDisplayUrl" alt="Image" class="tylie-card__img">
                        </div>
                        <div class="tylie-card__body">
                            <Label class="tylie-card__title">{{offerCard.cardName}}</Label>
                            <div class="tylie-data tylie-data--block">
                                <ng-container *ngIf="offerCard.expirydate; else elseAvailableTemplate">
                                    <span class="tylie-data__txt">Available: {{offerCard.availableForOrderingFromDate | date:'M/d/yy'}}
                                        -
                                        {{offerCard.expirydate | date:'M/d/yy'}}</span>
                                </ng-container>
                                <ng-template #elseAvailableTemplate>
                                    <span class="tylie-data__txt">Available: Unlimited</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tylie-panel">
        <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
                Current End Cards
            </h4>
        </div>
        <div class="tylie-panel__body">
            <p class="tylie-type-body mb-3">
                <span>End Tags promote your business location and are inserted at the end of Base Creative.
                    Expired End Tags are removed from this list.</span>
            </p>
            <div class="row">
                <div class="col-sm-3" *ngFor="let endCard of endCards">
                    <div class="tylie-card">
                        <div class="tylie-card__img-wrap">
                            <img [src]="endCard.cardDisplayUrl" alt="Image" class="tylie-card__img">
                        </div>
                        <div class="tylie-card__body">
                            <Label class="tylie-card__title">{{endCard.cardName}}</Label>
                            <div class="tylie-data tylie-data--block">
                                <ng-container *ngIf="endCard.expirydate; else elseAvailableTemplate">
                                    <span class="tylie-data__txt">Available: {{endCard.availableForOrderingFromDate | date:'M/d/yy'}} -
                                        {{endCard.expirydate | date:'M/d/yy'}}</span>
                                </ng-container>
                                <ng-template #elseAvailableTemplate>
                                    <span class="tylie-data__txt">Available: Unlimited</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
