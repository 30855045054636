import { Component, OnInit } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'app-service-batalentmanagement-metadata',
  templateUrl: './service-batalentmanagement-metadata.component.html',
  styleUrls: ['./service-batalentmanagement-metadata.component.css']
})
export class ServiceBatalentmanagementMetadataComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();
  }

}
