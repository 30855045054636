<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div *ngIf="isAddGroup || isEditGroup" class="modal-body">
  <div>
    <span *ngIf="isEditGroup" class="p-small pb-10">GROUP INFO</span>
  </div>
  <div>
    <form
      novalidate
      (ngSubmit)="onGroupFormSubmit(addOrUpdateGroupFormGroup)"
      [formGroup]="addOrUpdateGroupFormGroup"
      #f="ngForm"
    >
      <div class="row col-md-12">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="groupName">Group Name <span class="required">*</span></label>
          <input
            type="text"
            id="name"
            name="name"
            formControlName="name"
            class="tylie-text form-control"
            placeholder="Type a group name..."
            [ngClass]="{
              error:
                isDuplicateGroupName ||
                (addOrUpdateGroupFormGroup.get('name').invalid &&
                  (addOrUpdateGroupFormGroup.get('name').dirty ||
                    addOrUpdateGroupFormGroup.get('name').touched))
            }"
          />
          <div
            *ngIf="
              addOrUpdateGroupFormGroup.get('name').invalid &&
              (addOrUpdateGroupFormGroup.get('name').dirty ||
                addOrUpdateGroupFormGroup.get('name').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="addOrUpdateGroupFormGroup.get('name').errors.required"
            >
              Field is required.
            </div>
          </div>
          <div class="error-text" *ngIf="isDuplicateGroupName">
            Same group name already exists.
          </div>
        </div>
        <div class="tylie-form-group form-group">
          <label for="groupDescription">Group Description</label>
          <textarea
            type="text"
            id="groupDescription"
            name="groupDescription"
            formControlName="groupDescription"
            class="form-control"
            placeholder="Type a description..."
          ></textarea>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="isAddOrEditLocation || isAddGroup" class="modal-body">
  <div>
    <span class="p-small pb-10"
      >LOCATIONS
      <span *ngIf="groupName != null"
        >INCLUDED IN GROUP "{{ groupName }}"</span
      ></span
    >
  </div>
  <div class="mb-12" *ngIf="isAddOrEditLocation">
    <div class="row col-md-12 d-flex align-items-center gap-3">
      <div class="col-md-10 tylie-input-search tylie-tagger-input-search">
        <div class="tylie-input-search__inner">
          <div class="tylie-input-search__icon tylie-tagger-input-search__icon">
            <icon-search></icon-search>
          </div>
          <input
            class="tylie-input-search__text"
            placeholder="Search By Location keyword"
            name="srch-term"
            id="srch-term"
            type="search"
            [(ngModel)]="quickSearchValue"
            (ngModelChange)="clearQuickSearch()"
            (keydown.enter)="onEnterQuickSearch($event)"
          />
        </div>
        <div class="tylie-input-search__btn">
          <button
            class="tylie-button tylie-button--sm quick-search"
            type="button"
            (click)="quickSearch()"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item">
      <div class="tylie-accordion__body accordion-body">
        <div class="panel-footer"></div>
        <div class="tylie-accordion__body-content pb-2">
          <div class="row col-md-12 d-flex align-items-center">
            <dx-data-grid
              class="tylie-grid"
              id="groupLocationsGrid"
              #groupLocationsGrid
              [dataSource]="locations"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [showBorders]="true"
              [showRowLines]="true"
              [hoverStateEnabled]="true"
              keyExpr="locationGuid"
              [rowAlternationEnabled]="true"
            >
              <dxo-remote-operations [sorting]="true" [paging]="true">
              </dxo-remote-operations>
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
              <dxo-selection
                mode="multiple"
                selectAllMode="page"
                allowSelectAll="false"
                showCheckBoxesMode="always"
              >
              </dxo-selection>
              <dxi-column
                dataField="name"
                caption="Location"
                cssClass="gridStyle"
                width="50%"
                sortOrder="asc"
              ></dxi-column>
              <dxi-column
                dataField="city"
                caption="City"
                cssClass="gridStyle"
                width="30%"
              ></dxi-column>
              <dxi-column
                dataField="state"
                caption="State"
                cssClass="gridStyle"
                width="30%"
              ></dxi-column>
              <dxi-column
                dataField="zipcode"
                caption="Zip"
                cssClass="gridStyle"
                width="30%"
              ></dxi-column>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20, 50, 100]"
                infoText="Page {0} of {1} ({2} items)"
                [showNavigationButtons]="true"
                [showInfo]="true"
              >
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <div class="panel-footer"></div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="footer-actions-container">
    <div class="footer-actions">
      <span class="qc-cancel p-normal pr-10" (click)="closePopup()"
        >Cancel</span
      >
      <button
        *ngIf="isAddOrEditLocation"
        class="btn tyl-btn btn-icon-split"
        (click)="onSaveGroupLocations(this.groupGuid)"
      >
        <span class="icon">
          <i class="tylicon tylicon-tylmark"></i>
        </span>
        <span class="text">SAVE GROUP LOCATIONS</span>
      </button>

      <button
        *ngIf="isAddGroup || isEditGroup"
        class="btn tyl-btn btn-icon-split"
        (click)="onGroupFormSubmit(addOrUpdateGroupFormGroup)"
      >
        <span class="icon">
          <i class="tylicon tylicon-tylmark"></i>
        </span>
        <span class="text" *ngIf="isAddGroup">SAVE GROUP</span>
        <span class="text" *ngIf="isEditGroup">UPDATE GROUP</span>
      </button>
    </div>
  </div>
</div>
