<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Create New Folder</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="createNewProjectFolderForm" (submit)="onCreateProjectFolderFormSubmit()" novalidate #f="ngForm">
      <div class="row">
        <div class="col-4">
          <div class="tylie-form-group form-group" [ngClass]="{error: (createNewProjectFolderForm.controls['folderName'].errors
                  && !createNewProjectFolderForm.controls['folderName'].pristine)
                  || (createNewProjectFolderForm.controls['folderName'].errors && f.submitted)}">
            <label class="tylie-form-group__lbl" for="folderName">Folder Name<span class="required">*</span></label>
            <input formControlName="folderName" type="text" id="folderName" name="folderName" class="tylie-text form-control" autocomplete="off" />
            <p class="p-extra-small" *ngIf = "createNewProjectFolderForm.controls['folderName'].errors?.required && f.submitted">Please enter Folder Name</p>
            <p class="p-extra-small" *ngIf = "createNewProjectFolderForm.controls['folderName'].errors?.duplicate && f.submitted">Folder Name already exist</p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
