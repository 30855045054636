import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { BaseDestinationInfoComponent } from '../dest-baseinfo/destinationbaseinfo.component';

@Component({
  selector: 'destination-innovid-info',
  templateUrl: './destinationinnovidinfo.component.html'
})
export class DestinationInnovidInfoComponent extends BaseDestinationInfoComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();
  }

  public get spotFiles() {
    return this.formGroup.controls['spotFiles'].value as Array<SpotFile>;
  }

  public get innovidData() {
    return (<FormGroup>this.formGroup.controls['innovidData']);
  }
}
