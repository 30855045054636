import { SpotFile } from "../spot/spot-file.model";

export class TranscodeOrderSearchResult {
  public transcodeOrders: Array<TranscodeOrder>;
  public totalCount: number;
}


export class TranscodeOrder {
  public orderId: number;
  public workOrder: string;
  public orderGuid: string;
  public referenceCode: string;
  public created: string;
  public createdBy: string;
  public transcodeAssets: Array<TranscodeAsset>;
}

export class TranscodeAsset {
  public spotFile: SpotFile;
  public assetGuid: string;
  public transcodeAssetFiles: Array<TranscodeAssetFile>;
}

export class TranscodeAssetFile {
  public assetFileGuid: string;
  public status: string;
  public isFailed: boolean;
  public isProcessing: boolean;
  public isCompleted: boolean;
  public canDelete: boolean;
  public canRenameFileName: boolean;
  public canDownload: boolean;
  public canShare: boolean;
  public fileName: string;
  public fileExtension: string;
  public filePath: string;
  public fileSize: string;
  public duration: string;
  public createdDate: Date;
  public sharedDate: Date;
  public downloadedDate: Date;
  public transcodeSpec: string;
  public payload: string;
}

export class EditTranscodeOrderRequest {
  public editCategory: string;
  public newValue: string;
  public orderGuid: string;
  public assetFileGuid: string;
  public workOrder: string
}


export class PagerSetting {
  public totalCount: number;
  public pageSizes: Array<number>;
  public currentPageSize: number;
  public totalPages: number
  public currentPageNumber: number;
  public pageNumbers: Array<number>;
  public startPage: number;
  public endPage: number;
}
