
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Constants } from "../../../configurations/constants";
import { RouteConstants } from "../../../configurations/route-constants";
import { AuthService } from "../../../services/auth/auth.service";
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { ForgotPasswordSettingsService } from "../../../services/user/forgotpassword-settings.service";

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public errorMsg: string = '';
  public successMessage: String = '';
  public hideEmailContainer: boolean = false;
  public forgotPasswordEmail: string = "";

  constructor(private alertService: AlertService,
    public util: Utilities,
    private forgotPasswordSettingsService: ForgotPasswordSettingsService,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.hideEmailContainer = false;
  }

  ngOnDestroy() {
  }

  public backToLogin() {
    this.router.navigate([RouteConstants.loginRoute]);
  }

  public verifyUserName(userName: string) {
    if (userName == "" || userName == null || userName == undefined) {
      return;
    }

    this.isLoading = true;
    this.forgotPasswordSettingsService.generateResetPasswordToken(userName).pipe(
      finalize(() => {
        this.isLoading = false;
      })).subscribe((res: any) => {
        if (res.isSuccess == true) {
          if (res.result) {
            this.hideEmailContainer = true;
            this.successMessage = Constants.resetPasswordEmailSent + userName;
          }
          else {
            this.hideEmailContainer = false;
            this.errorMsg = Constants.userNameInValid;
          }
        }
        else {
          this.hideEmailContainer = false;
          let errorMessage = this.util.handleIsNotSuccessLogin(res.errors);
          this.errorMsg = errorMessage;
        }
      },
        error => {
          this.hideEmailContainer = false;
          var err = this.authService.processLoginError(error);
          this.errorMsg = err.errorDescrption;
        });
  }
}
