<!--<nav-header headerTitle="Destination" link="Back To All Destinations" [linkRoute]="destinationRoute"></nav-header>-->

<div *ngIf="config != null && detail != null">
  <form [formGroup]="editDestinationFormGroup" (ngSubmit)="onFormSubmit(editDestinationFormGroup)" novalidate role="search" #f="ngForm">
    
    <div class="tylie-panel tylie-panel--clean" id="createclient">
        <div class="tylie-panel__body" id="createClientBody">
          <div class="row">
            <div class="col-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="code">Code</label>
                <div class="tylie-form-group__txt">{{detail.code}}</div>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editDestinationFormGroup.controls['name'].errors && !editDestinationFormGroup.controls['name'].pristine)
              || (editDestinationFormGroup.controls['name'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="code">Name<span class="required">*</span></label>
                <input type="text" name="name" id="name" formControlName="name" class="tylie-text form-control">
                <p class="p-extra-small">Please enter a name</p>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group" [ngClass]="{error: (editDestinationFormGroup.controls['destinationType'].errors && !editDestinationFormGroup.controls['destinationType'].pristine)
              || (editDestinationFormGroup.controls['destinationType'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="destinationType">Type<span class="required">*</span></label>
              <dx-select-box [dataSource]="config.destinationTypes"
                              name="destinationType" id="destinationType" class="tylie-select form-control"
                              formControlName="destinationType"
                              valueExpr="id"
                              displayExpr="name"
                              [showClearButton]="true"
                              placeholder="Select a value..."
                              (onValueChanged)="onDestinationTypeChange()">
              </dx-select-box>
              <p class="p-extra-small">Please select a type</p>
            </div>
            </div>
            
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="code">AKAs</label>
                <input type="text" name="akas" id="akas" formControlName="akas" class="tylie-text form-control">
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <destination-contact [destinationContact]="editDestinationFormGroup.get('destinationContact')"
                            [states]="config.states"
                            [contactTypes]="config.destinationContactTypes"
                            [parentForm]="f">

        </destination-contact>
      </div>
      <div class="col-6">
        <div class="tylie-panel" id="createclient">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              OTHER
            </h4>
          </div>
          <div class="tylie-panel__body" id="createClientBody">
            <div class="row">
              <div class="col-md-12">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editDestinationFormGroup.controls['defaultHub'].errors && !editDestinationFormGroup.controls['defaultHub'].pristine)
              || (editDestinationFormGroup.controls['defaultHub'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="defaultHub">Default Hub<span class="required">*</span></label>
                  <div class="row">
                    <div class="col-6">
                      <input type="text" id="defaultHub" name="defaultHub" formControlName="defaultHub" class="tylie-text form-control " />
                      <p class="p-extra-small">Please enter a valid default hub</p>
                    </div>
                    <div class="col-6">
                      <button class="tylie-button tylie-button--sm" type="button" (click)="onDefaultHubSearch()">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="this.editDestinationFormGroup.controls['destinationType'].value == this.config.fileDestinationTypeBackup">
              <div class="col-12">
                <label class="tylie-form-group__lbl" [ngClass]="{error: (editDestinationFormGroup.controls['primaryDestination'].errors && !editDestinationFormGroup.controls['primaryDestination'].pristine)
                || (editDestinationFormGroup.controls['primaryDestination'].errors && f.submitted)}" for="primaryDestination">Primary Destination<span class="required">*</span></label>

              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editDestinationFormGroup.controls['primaryDestination'].errors && !editDestinationFormGroup.controls['primaryDestination'].pristine)
            || (editDestinationFormGroup.controls['primaryDestination'].errors && f.submitted)}">
                  <input type="text" id="primaryDestination" name="primaryDestination" formControlName="primaryDestination" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter a valid primary destination</p>
                </div>
              </div>
              <div class="col-6">
                <button class="tylie-button tylie-button--sm" type="button" (click)="onPrimaryDestinationSearch()">
                  Search
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-6" *ngIf="this.editDestinationFormGroup.controls['destinationType'].value != this.config.fileDestinationTypeInternational">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="market">Market<span class="required">*</span></label>
                  <input type="text" id="market" name="market" formControlName="market" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter a valid Market</p>
                </div>
              </div>

              <div class="col-6" *ngIf="this.editDestinationFormGroup.controls['destinationType'].value == this.config.fileDestinationTypeInternational">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editDestinationFormGroup.controls['market'].errors
                    && !editDestinationFormGroup.controls['market'].pristine)
                || (editDestinationFormGroup.controls['market'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="market">Market<span class="required">*</span></label>
                  <dx-select-box [dataSource]="config.markets"
                                 name="market" id="market" class="tylie-select form-control"
                                 formControlName="market"
                                 valueExpr="code"
                                 displayExpr="displayName"
                                 [showClearButton]="true"
                                 placeholder="Select Market">
                  </dx-select-box>
                  <p class="p-extra-small">Please select a Market</p>
                </div>
              </div>

              <div class="col-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="acceptableFormats">Acceptable Format<span class="required">*</span></label>
                  <dx-drop-down-box [dataSource]="config.formatTypes"
                                    [(value)]="treeBoxValue"
                                    name="acceptableFormats" id="acceptableFormats" class="tylie-select form-control"
                                    formControlName="acceptableFormats"
                                    valueExpr="id"
                                    [showClearButton]="true"
                                    displayExpr="name"
                                    placeholder="Select Formats"
                                    (onValueChanged)="syncTreeViewSelection()">

                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view [dataSource]="config.formatTypes"
                                    dataStructure="plain"
                                    keyExpr="id"
                                    selectionMode="multiple"
                                    showCheckBoxesMode="normal"
                                    [selectNodesRecursive]="false"
                                    displayExpr="name"
                                    [selectByClick]="true"
                                    (onContentReady)="syncTreeViewSelection($event)"
                                    (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                  <p class="p-extra-small">Please select Formats</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-check mt-4">
                  <input type="checkbox" id="isCompanionFormatRequired" name="isCompanionFormatRequired" formControlName="isCompanionFormatRequired" class="form-check-input" />
                  <label class="form-check-label" for="comments">Companion Format Required<span class="required">*</span></label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="primaryFormat">Preferred Network</label>
                  <dx-select-box [dataSource]="config.vendors"
                                 name="secondaryFormat" id="secondaryFormat" class="tylie-select form-control"
                                 formControlName="preferredVendorId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a Vendor"
                                 (onValueChanged)="onPreferredNetworkChange()">
                  </dx-select-box>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddDistributionSettings()" *ngIf="this.showDistributionSettings == true">
                    Distribution Settings
                  </button>
                </div>
              </div>
            </div>

            <div class="tylie-form-group form-group" *ngIf="this.showDistributionSettings == true && networkSpecifications != null && networkSpecifications.length > 0">
              <label class="tylie-form-group__lbl" for="specMappings">Specification Mappings</label>
              <ng-container *ngFor="let spec of networkSpecifications; let i = index">
                <div class="p-extra-small">
                  {{spec.name}}
                  <span class="p-extra-small" *ngIf="spec.recipeName">({{spec.recipeName}})</span>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onAddEditRecipeName(spec, i)">ADD / EDIT RECIPE NAME</button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onRemoveSpecMapping(spec)">
                    <icon-trash-can color="#1e90ff"></icon-trash-can>
                  </button>
                </div>
              </ng-container>
            </div>

            <div class="tylie-form-group form-group" *ngIf="this.editDestinationFormGroup.controls['bxfXML'].value != null">
              <label class="tylie-form-group__lbl">BXF File</label>
              <div>
                <span class="tyl-blue-text p-extra-small tyl-clickable" (click)="onDownloadBxfFile()">Download BXF File</span>
                <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onRemoveBXfFile()">
                  <icon-trash-can color="#1e90ff"></icon-trash-can>
                </a>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" id="isTrafficOnly" name="isTrafficOnly" formControlName="isTrafficOnly" class="form-check-input" />
                  <label class="form-check-label" for="comments">Is Traffic Only<span class="required">*</span></label>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" id="acceptsDescriptiveVideo" name="acceptsDescriptiveVideo" formControlName="acceptsDescriptiveVideo" class="form-check-input" />
                  <label class="form-check-label" for="comments">Accepts DVS<span class="required">*</span></label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="comments">Comments<span class="required">*</span></label>
                  <textarea type="text" id="comments" name="comments" formControlName="comments" class="tylie-textarea tylie-textarea--lg form-control"></textarea>
                  <p class="p-extra-small">Please enter Comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <select-network [vendorDestinationMappings]="detail.vendorDestinationMappings"
                    [editMode]="true"
                    [destinationCode]="detail.code">

    </select-network>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="submit">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">SAVE Destination</span>
      </button>
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onCancelEdits()">Cancel</button>
    </div>
  </form>
</div>
