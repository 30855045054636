import { SmsNotificationPreference } from "./sms-notification-preference.model";
import { EmailNotificationPreference } from "./email-notification-preference.model";

export class NotificationPreferences{

  public smsNotificationPreferences: SmsNotificationPreference[];
  public emailNotificationPreferences: EmailNotificationPreference[];
 

  constructor(smsNotificationPreferences: SmsNotificationPreference[], emailNotificationPreferences: EmailNotificationPreference[]) {
    this.smsNotificationPreferences = smsNotificationPreferences;
    this.emailNotificationPreferences = emailNotificationPreferences;
  }

}
