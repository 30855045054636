import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { Spot } from '../../../models/spot/spot.model';
import { SpotService } from '../../../services/media/spot.service';
import { QuickplayerComponent } from '../../videoplayer/quickplayer/quickplayer.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { SpotUploadRequest } from "../../../models/upload/upload-request.model";
import { SpotUploadResult } from "../../../models/upload/upload-result.model";
import { ErrorPopupComponent } from "../errorpopup/errorpopup.component";
import { Constants } from "../../../configurations/constants";
import { UploadService } from "../../../services/upload/upload.service";
import { SpotFile } from '../../../models/spot/spot-file.model';
import { SortOptions } from '../../../models/config/sortoptions.model';
import { TruncateByLetters } from '../../../pipes/truncateByLetters.pipe';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { SelectedGroupMedia } from '../../../models/order/order-create.model';
import { OrderDestination } from '../../../models/order/order-destinations.model';
import { DestinationSuggest } from '../../../models/order/order-destinationsuggest.model';

@Component({
  selector: 'orderaddspotpopup',
  templateUrl: './orderaddspotpopup.component.html',
  styleUrls: ['./orderaddspotpopup.component.css'],
})
export class OrderAddSpotPopupComponent extends BasePopupComponent {

  @Input() clientId: number;
  @Input() brandId: number;
  @Input() productId: number;
  @Input() specialVisibilityClientId: number;

  public formatDataSource: any = [];
  public addPlaceHolderRequest: FormGroup;
  public spotsDataSource: Array<SpotFile> = null;
  public quickSearchValue: string = '';
  public errorMessage: string = '';
  public onClose: Subject<SelectedGroupMedia>;
  public selectedSpotFiles: Array<SpotFile> = [];
  public sortOptions: Array<SortOptions> = [{ desc: true, selector: 'created' }];
  public applyToAllGroups: boolean = false;
  public pastedDestinations: string = "";
  public selectionMethod: string = "assetsearch"
  public copyUploadSuccessSpotFiles: Array<SpotFile> = null;
  public copyUploadErrorSpotFiles: Array<string> = null;
  public copyUploadResultMessage: string = '';
  public copyUploadErrorsExist: boolean = false;
  public metadataExcelFile: File;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private spotService: SpotService,
    private uploadService: UploadService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onClose = new Subject();

    if (this.clientId != null && this.clientId > 0 && this.brandId != null && this.brandId > 0) {
      this.searchSpots();
      this.getCreatePlaceHolderConfig();
    }

    this.addPlaceHolderRequest = new FormGroup({
      title: new FormControl('', Validators.required),
      format: new FormControl(),
      length: new FormControl('', Validators.required),
      adID: new FormControl('', Validators.required)
    });
  }

  public onCopyPasteOptionSelected() {
    this.selectionMethod = "copypaste";
  }

  public onAssetSearchOptionSelected() {
    this.selectionMethod = "assetsearch";
  }

  public onCopyUploadSuccessSpotFileDelete(spotFileGuid: string) {
    this.copyUploadSuccessSpotFiles = this.copyUploadSuccessSpotFiles.filter(t => t.spotFileGuid.toLowerCase() != spotFileGuid.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  public onCopyUploadErrorSpotFileDelete(adId: string) {
    this.copyUploadErrorSpotFiles = this.copyUploadErrorSpotFiles.filter(t => t.toLowerCase() != adId.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  public onExcelUploadFileChange(event) {
    this.metadataExcelFile = event.target.files[0];

    if (this.metadataExcelFile == null)
      return;

    var reader = new FileReader();

    reader.readAsDataURL(this.metadataExcelFile);

    reader.onload = () => {
      var request: any = {
        metadataFileType: this.metadataExcelFile.type,
        metadataFileName: this.metadataExcelFile.name,
        metadataFile: (<string>reader.result).split(',')[1],
        client: this.clientId,
        brand: this.brandId
      };

      this.alertService.ShowLoader(true)

      this.spotService.validateSpotsFromFile(request).subscribe((res: any) => {

        this.selectionMethod = 'copypasteresult';

        if (res.isSuccess == true) {

          console.log(res.result);

          var spotfiles = res.result.spotFiles as Array<SpotFile>;

          for (let s of spotfiles) {
            s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));
            s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
            s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);
            s.formatClasses = this.util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);
          }

          this.copyUploadSuccessSpotFiles = spotfiles;
          this.copyUploadErrorSpotFiles = res.result.unMappedSpotFiles as Array<string>;

          if (this.copyUploadErrorSpotFiles != null && this.copyUploadErrorSpotFiles.length > 0) {
            this.copyUploadResultMessage = this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets not found";
            this.copyUploadErrorsExist = true;
          }
          else {
            this.copyUploadResultMessage = this.copyUploadSuccessSpotFiles.length.toString() + " assets found successfully";
            this.copyUploadErrorsExist = false;
          }
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.alertService.ShowLoader(false);
          this.closePopup();
          this.util.handleError(error);
        });
    }
  }

  private applyCopyUploadErrorCount() {

    if (this.copyUploadSuccessSpotFiles.length == 0 && this.copyUploadErrorSpotFiles.length == 0) {
      this.copyUploadResultMessage = "No Assets";
      this.copyUploadErrorsExist = true;
    }
    else if (this.copyUploadErrorSpotFiles.length > 0) {
      this.copyUploadResultMessage = "Error parsing " + this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets";
      this.copyUploadErrorsExist = true;
    }
    else {
      this.copyUploadResultMessage = "Successfully parsed " + (this.copyUploadSuccessSpotFiles.length).toString() + " assets";
      this.copyUploadErrorsExist = false;
    }
  }

  public onSearchCopySubmitted() {
    if (this.pastedDestinations === null || this.pastedDestinations.trim() === '')
      return;

    var request: any = { adIdText: this.pastedDestinations, client: this.clientId, brand: this.brandId };

    this.alertService.ShowLoader(true)

    this.spotService.validateSpotsFromText(request).subscribe((res: any) => {

      this.selectionMethod = 'copypasteresult';

      if (res.isSuccess == true) {

        console.log(res.result);

        var spotfiles = res.result.spotFiles as Array<SpotFile>;

        for (let s of spotfiles) {
          s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));
          s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
          s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
            s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
            s.sap, s.vchip, s.letterBox, s.surround, s.mono);
          s.formatClasses = this.util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
            s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
            s.sap, s.vchip, s.letterBox, s.surround, s.mono);
        }

        this.copyUploadSuccessSpotFiles = spotfiles;
        this.copyUploadErrorSpotFiles = res.result.unMappedSpotFiles as Array<string>;

        if (this.copyUploadErrorSpotFiles != null && this.copyUploadErrorSpotFiles.length > 0) {
          this.copyUploadResultMessage = this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets not found";
          this.copyUploadErrorsExist = true;
        }
        else {
          this.copyUploadResultMessage = this.copyUploadSuccessSpotFiles.length.toString() + " assets found successfully";
          this.copyUploadErrorsExist = false;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        this.closePopup();
        this.util.handleError(error);
      });
  }

  private searchSpots() {

    this.getLatestSpots();
  }

  private getLatestSpots(): void {

    this.spotsDataSource = null;

    let gridParams: GridParams = { group: "", skip: 0, take: 0, sort: JSON.stringify(this.sortOptions), isExport: false }
    let request: any = { quickSearch: this.quickSearchValue, gridParams: gridParams, client: this.clientId, brand: this.brandId, specialVisibilityClientId: this.specialVisibilityClientId };

    this.spotService.searchSpotsForOrder(request)
      .subscribe((response: any) => {
        if (response.isSuccess == true) {

          var spots = response.result.spotFiles as Array<SpotFile>;

          for (let s of spots) {

            s.isSelected = this.isSpotSelected(s.spotFileGuid);
            s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));
            s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
            s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);
            s.formatClasses = this.util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);

            for (let spotfile of s.postProdFiles) {

              spotfile.isSelected = this.isSpotSelected(spotfile.spotFileGuid);
              spotfile.thumbnailUrl = this.util.getThumbnailUrl(spotfile.thumbnailUrl, (spotfile.format.toLowerCase() == 'audio'), (spotfile.status.toLowerCase() == 'awaiting media'), (spotfile.status.toLowerCase() == 'creating media'));
              spotfile.proxyUrl = this.util.getProxyUrl(spotfile.proxyUrl);
              spotfile.formatSources = this.util.getAllIconSources(s.format, spotfile.audioFormat, spotfile.spotTrac,
                spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo, spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic,
                spotfile.runningFootage, spotfile.cc, spotfile.sap, spotfile.vchip, spotfile.letterBox, spotfile.surround, spotfile.mono);
              spotfile.formatClasses = this.util.getAllIconClasses(s.format, spotfile.audioFormat, spotfile.spotTrac,
                spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo, spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic,
                spotfile.runningFootage, spotfile.cc, spotfile.sap, spotfile.vchip, spotfile.letterBox, spotfile.surround, spotfile.mono);
            }
          }

          this.spotsDataSource = spots;
        }
        else {
          this.util.handleIsNotSuccess(response.errors);
        }
      },
        error => {
          if (this.util.handleError(error))
            this.closePopup();
        });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.searchSpots();
    }
  }

  public onQuickSearchSpots() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      this.searchSpots();
    }
  }

  public onAddMedia() {

    if (this.selectionMethod == "copypasteresult" && this.copyUploadErrorSpotFiles.length > 0) {
      this.alertService.showMessage("ERROR", "Please fix the errors", MessageSeverity.error);
      return;
    }

    let selectedGroupMedia: SelectedGroupMedia = { applyToAllGroups: this.applyToAllGroups, selectedSpotFiles: this.selectedSpotFiles };

    if (this.selectionMethod == "assetsearch")
      selectedGroupMedia.selectedSpotFiles = this.selectedSpotFiles;
    else
      selectedGroupMedia.selectedSpotFiles = this.copyUploadSuccessSpotFiles;

    this.onClose.next(selectedGroupMedia);

    this.closePopup();
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    var initialState = {
      videoSource: proxy,
      title: title,
      thumbnail: thumbnail,
      format: format
    };

    this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public addPlaceHolderFormSubmit({ value, valid }: { value: SpotUploadRequest, valid: boolean }, f: NgForm) {
    if (this.clientId != null && this.clientId > 0 && this.brandId != null && this.brandId > 0) {

      if (this.addPlaceHolderRequest.controls['title'].errors || this.addPlaceHolderRequest.controls['adID'].errors || this.addPlaceHolderRequest.controls['length'].errors)
        return;

      value.client = this.clientId;
      value.brand = this.brandId;
      value.productId = this.productId;

      this.createPlaceHolder(value, f);
    }
    else { return; }
  }

  public createPlaceHolder(uploadRequest: SpotUploadRequest, f: NgForm): void {
    this.uploadService.createPlaceHolder(uploadRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.createPlaceHolderSuccessful, MessageSeverity.success);
        this.getLatestSpots();
        f.resetForm();
        this.errorMessage = '';
      }
      else {
        //this.util.handleIsNotSuccess(jsonRes.errors);
        this.errorMessage = res.errors.join(", ");
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public getCreatePlaceHolderConfig() {
    this.spotService.getCreatePlaceHolderConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.formatDataSource = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public onSelectSpot(spotFileGuid: string, ppSpotFileGuid: string) {
    var spotFile = this.spotsDataSource.find(t => t.spotFileGuid == spotFileGuid);
    if (spotFile == null) return;

    if (ppSpotFileGuid == '') {
      if (this.selectedSpotFiles.find(t => t.spotFileGuid == spotFileGuid) == null) {
        spotFile.isSelected = true;
        this.selectedSpotFiles.push(spotFile);
      }
      else {
        spotFile.isSelected = false;
        this.selectedSpotFiles = this.selectedSpotFiles.filter(t => t.spotFileGuid != spotFileGuid);
      }
    }
    else {
      var ppSpotFile = spotFile.postProdFiles.find(t => t.spotFileGuid == ppSpotFileGuid);
      if (ppSpotFile != null) {
        if (this.selectedSpotFiles.find(t => t.spotFileGuid == ppSpotFileGuid) == null) {
          ppSpotFile.isSelected = true;
          this.selectedSpotFiles.push(ppSpotFile);
        }
        else {
          ppSpotFile.isSelected = false;
          this.selectedSpotFiles = this.selectedSpotFiles.filter(t => t.spotFileGuid != ppSpotFileGuid);
        }
      }
    }
  }

  public toggleVersionFiles(event: any, id: string, parentId: string) {
    var div = document.getElementById(id);

    if (div != null)
      div.classList.toggle('hide');

    var divParent = document.getElementById(parentId);

    if (divParent != null)
      divParent.classList.toggle('parent');

    event.target.classList.toggle('glyphicon-chevron-down');
    event.target.classList.toggle('glyphicon-chevron-up');
  }

  public onSortBy(event: any, field: string) {
    var option = this.sortOptions.find(t => t.selector == field);

    if (option != null) {
      option.desc = !option.desc;

      event.target.classList.toggle('glyphicon-chevron-down');
      event.target.classList.toggle('glyphicon-chevron-up');
    }
    else {
      this.sortOptions.push({ desc: true, selector: field });
    }

    this.getLatestSpots();
  }

  public isSortDown(field: string): boolean {
    var option = this.sortOptions.find(t => t.selector == field && t.desc == true);
    return option != null ? true : false;
  }

  public isSortUp(field: string): boolean {
    var option = this.sortOptions.find(t => t.selector == field && t.desc == false);
    return option != null ? true : false;
  }

  private isSpotSelected(spotFileGuid: string): boolean {
    if (this.selectedSpotFiles.find(t => t.spotFileGuid == spotFileGuid) == null)
      return false;
    else
      return true;
  }
}
