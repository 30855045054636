<div class="tylie-accordion accordion">
  <div class="tylie-accordion__panel accordion-item">
    <div class="panel-heading" [ngClass]="{'error-panel-groups': group.containsGroupErrors == true && triedToSave == true }">
    
      <div class="d-flex">
        <div class="group-header col-md-2">
          <span class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': group.containsGroupErrors == true && triedToSave == true }">
              GROUP {{group.sequenceId}}&nbsp;&nbsp;{{group.name}}
              <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button" placement="top" #gpnamepop="bs-popover" container="body" [popover]="groupNameTemplate" *ngIf="config.orderActions.canEditGroupName === true" (click)="stopTogglePropogation($event)">edit</a>
          </span>
        </div>
        <div class="col-md-10">
          <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': group.containsGroupErrors == true && triedToSave == true }">
            <button class="tylie-accordion__heading-btn accordion-button"  type="button" data-bs-toggle="collapse" data-bs-target="'#group' + group.sequenceId" aria-expanded="true" aria-controls="collapseOne">
              &nbsp;
            </button>
          </h2>
        </div>
      </div>

    </div>
    <div [attr.id]="'group' + group.sequenceId" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content">
          <div class="row">
            <iv class="col-md-12" *ngIf="group.orderGroupId > 0">
              <div class="tylie-data tylie-data--c-block">
                <label class="tylie-data__lbl">Service Level:</label>
                <span class="tylie-data__txt" *ngIf="!group.isRush">Standard</span>
                <ng-container *ngIf="group.isRush">
                  <span class="rush-alert hide">!</span>
                  <span class="rush-alert-svg"></span>
                  <span class="rush-text group-error-message">Rush</span>
                </ng-container>
                <span class="blue-text" *ngIf="config.orderActions.canEditRush === true" (click)="onServiceLevelEdit()">Edit</span>
                
              </div>
            </iv>

            <div class="col-md-12" *ngIf="group.orderGroupId == 0">
              <div class="form-group servicelevel" [ngClass]="{error: group.isRush == null && triedToSave == true }">
                <label class="radio-inline servicelevel-label p-normal">Service Level:</label>
                <label class="radio-inline">
                  <input type="radio" value="false" [name]="'rushOption' + group.sequenceId" (change)="onRushChanged('false')" [checked]="group.isRush == false">
                  <span>Standard</span>
                </label>
                <label class="radio-inline">
                  <input type="radio" value="true" [name]="'rushOption' + group.sequenceId" class="rush-radio" (change)="onRushChanged('true')" [checked]="group.isRush == true">
                  <span *ngIf="group.isRush === true">
                    <span class="rush-alert-svg"></span>
                    <span class="rush-text group-error-message">Rush</span>
                  </span>
                  <span *ngIf="group.isRush !== true">Rush</span>
                </label>
                <p class="p-extra-small">Please select an option</p>
              </div>
            </div>
          </div>
          
          <section class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick">
              <h4 class="tylie-panel__title">
                Asset
                <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" *ngIf="config.orderActions.canAddMedia === true && group.spotData.length > 0" (click)="onAddMedia()">
                  <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                  <span class="tylie-button__text">Add Asset</span>
                </button>
              </h4>
            </div>
            
            <div class="tylie-panel__body">
              <div class="d-flex mb-3" *ngIf="config.orderActions.canAddGroup === true && group.spotData.length == 0">
                <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddMedia()">
                  <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                  <span class="tylie-button__text">Add Asset</span>
                </button>
              </div>
              <table class="tylie-table table table-bordered" *ngIf="group.spotData.length > 0">
                <thead class="tylie-table__head">
                  <tr>
                    <th width="132px">Thumbnail</th>
                    <th width="15.57%" [ngClass]="{'group-error-message': (group.containsGroupAdIdErrors == true && triedToSave == true) }">Ad-ID</th>
                    <th width="19.57%" [ngClass]="{'group-error-message': (group.containsGroupTitleErrors == true && triedToSave == true) }">Title</th>
                    <th width="8%">Status</th>
                    <th width="9%">Format</th>
                    <th width="4.47%">Dur.</th>
                    <th width="45%" [ngClass]="{'group-error-message': (group.containsGroupServiceErrors == true && triedToSave == true) }">Services Selected</th>
                    <th width="4%"></th>
                  </tr>
                </thead>
                <tbody class="tylie-table__body" *ngFor="let sp of group.spotData; let i = index">
                  <tr>
                    <td class="tylie-table__cell-img" [attr.rowspan]="isNonTextItemsExists(sp.serviceData) ? 2 : null">
                      <div class="tylie-img-container tylie-img-container--thumbnail thumb-play-parent">
                        <span *ngIf="sp.spotFile.isPlayable === true && sp.ppSpotFile == null" class="play"
                              (click)="quickPlay(sp.spotFile.proxyUrl, sp.spotFile.title, sp.spotFile.thumbnailUrl, sp.spotFile.format)"></span>
                        <span *ngIf="sp.ppSpotFile != null && sp.ppSpotFile.isPlayable === true" class="play"
                              (click)="quickPlay(sp.ppSpotFile.proxyUrl, sp.ppSpotFile.title, sp.ppSpotFile.thumbnailUrl, sp.ppSpotFile.format)"></span>
                        <span *ngIf="sp.ppSpotFile != null && sp.ppSpotFile.showCreatingMediaBanner == true" class="creating-new-media-banner"></span>
                        <img class="tylie-img" src="{{sp.spotFile.thumbnailUrl
      }}" *ngIf="sp.ppSpotFile == null || (sp.ppSpotFile != null && sp.ppSpotFile.thumbnailUrl == '')" />

                        <img class="tylie-img" src="{{sp.ppSpotFile.thumbnailUrl}}" *ngIf="sp.ppSpotFile != null && sp.ppSpotFile.thumbnailUrl != ''" />
                      </div>
                    </td>
                    <td [ngClass]="{'group-error-border': (sp.containsAdIdErrors == true && triedToSave == true)}" style="word-break:break-all;">
                      <div class="blue-text" *ngIf="sp.ppSpotFile == null" [ngClass]="{'group-error-message': (sp.containsSpotErrors == true && triedToSave == true)}"
                          containerClass="customPopover" placement="top" [popover]="sp.errorMsgSpot" triggers="mouseenter:mouseleave" (click)="showDetail(sp.spotFile.spotFileGuid)">
                        {{sp.spotFile.adId}}
                      </div>
                      <div *ngIf="sp.ppSpotFile != null">
                        <div class="blue-text" [ngClass]="{'group-error-message': (sp.containsPpSpotAdIdErrors == true && triedToSave == true) }" (click)="showDetail(sp.ppSpotFile.spotFileGuid)"
                            containerClass="customPopover" container="body" placement="top" [popover]="sp.errorMsgPpSpotAdId" triggers="mouseenter:mouseleave">{{sp.ppSpotFile.adId}}</div>
                        <div class="prev_version gray1">
                          <div class="p-small">Selected Asset:</div>
                          <div class="p-small blue-text" [ngClass]="{'group-error-message': (sp.containsSpotErrors == true && triedToSave == true) }" (click)="showDetail(sp.spotFile.spotFileGuid)"
                              containerClass="customPopover" placement="top" [popover]="sp.errorMsgSpot" triggers="mouseenter:mouseleave">{{sp.spotFile.adId}}</div>
                        </div>
                      </div>
                    </td>
                    <td [ngClass]="{'group-error-border': (sp.containsTitleErrors == true && triedToSave == true) }" class="service-selected" style="word-break:break-all;">
                      <div *ngIf="sp.ppSpotFile == null">
                        <div>
                          {{sp.spotFile.title}}
                        </div>

                        <p *ngIf="sp.spotFile.outputFileName != null && sp.spotFile.outputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                           (click)="onCopyFileName(sp.spotFile.outputFileName)" [popover]="sp.spotFile.outputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy Filename
                        </p>

                        <p *ngIf="sp.spotFile.wcpOutputFileName != null && sp.spotFile.wcpOutputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                           (click)="onCopyFileName(sp.spotFile.wcpOutputFileName)" [popover]="sp.spotFile.wcpOutputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy WCP Filename
                        </p>

                        <p *ngIf="sp.spotFile.tbiOutputFileName != null && sp.spotFile.tbiOutputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                           (click)="onCopyFileName(sp.spotFile.tbiOutputFileName)" [popover]="sp.spotFile.tbiOutputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy TBI Filename
                        </p>

                      </div>
                      <div *ngIf="sp.ppSpotFile != null">
                        <div class="blue-text-np" [ngClass]="{'group-error-message': (sp.containsPpSpotTitleErrors == true && triedToSave == true) }"
                            containerClass="customPopover" placement="top" [popover]="sp.errorMsgPpSpotTitle" triggers="mouseenter:mouseleave">{{sp.ppSpotFile.title}}</div>
                        <div class="prev_version gray1">
                          <div class="p-small">Selected Asset:</div>
                          <div class="p-small">{{sp.spotFile.title}}</div>
                        </div>
                        <p *ngIf="sp.ppSpotFile.wcpOutputFileName != null && sp.ppSpotFile.wcpOutputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                          (click)="onCopyFileName(sp.ppSpotFile.wcpOutputFileName)" [popover]="sp.ppSpotFile.wcpOutputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy WCP Filename
                        </p>

                        <p *ngIf="sp.ppSpotFile.tbiOutputFileName != null && sp.ppSpotFile.tbiOutputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                          (click)="onCopyFileName(sp.ppSpotFile.tbiOutputFileName)" [popover]="sp.ppSpotFile.tbiOutputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy TBI Filename
                        </p>

                        <p *ngIf="sp.ppSpotFile.outputFileName != null && sp.ppSpotFile.outputFileName != '' && config.orderActions.showCopyFilenameLink === true" class="add-edit-services p-extra-small"
                          (click)="onCopyFileName(sp.ppSpotFile.outputFileName)" [popover]="sp.ppSpotFile.outputFileName" placement="top" triggers="mouseenter:mouseleave">
                          Copy Filename
                        </p>
                      </div>
                    </td>
                    <td>{{sp.spotFile.status}}</td>
                    <td class="format-cell">
                      <ng-container *ngFor="let iconSrc of sp.spotFile.formatClasses">
                        <span [class]="iconSrc"></span>
                      </ng-container>
                    </td>
                    <td>{{sp.spotFile.length}}</td>
                    <td class="service-selected" [ngClass]="{'group-error-border': ((sp.serviceData.length === 0 || sp.containsSpotServiceDataErrors == true) && triedToSave == true) }">
                      <div *ngIf="sp.serviceData.length > 0">
                        <div *ngIf="sp.isDeliveryExists === true">
                          <p class="p-small">Digital Delivery</p>
                          <div *ngFor="let serv of sp.serviceData;">
                            <div *ngIf="serv.service.category === 'Digital Delivery'">
                              <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                *ngIf="serv.containsServiceMetadataDataErrors == true">
                                {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                              </p>
                              <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="sp.isPpExists === true">
                          <p class="p-small">Post Production</p>
                          <div *ngFor="let serv of sp.serviceData;">
                            <div *ngIf="serv.service.category === 'Post Production'">
                              <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                *ngIf="serv.containsServiceMetadataDataErrors == true">
                                {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                              </p>
                              <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="sp.isAddlExists === true">
                          <p class="p-small">Additional</p>
                          <div *ngFor="let serv of sp.serviceData;">
                            <div *ngIf="serv.service.category === 'Additional'">
                              <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                *ngIf="serv.containsServiceMetadataDataErrors == true">
                                {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                              </p>
                              <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                            </div>
                          </div>
                        </div>
                        <a class="tylie-lnk" (click)="onEditServicesOfMedia(sp.spotFile.spotFileGuid, sp.groupSpotId)">
                          {{(config.orderActions.canUpdateSpecialServices === true || config.orderActions.canAddMedia === true) ? "Add/Edit Services" : "View Services"}}
                        </a>
                      </div>
                      <div *ngIf="sp.serviceData.length === 0 && (config.orderActions.canUpdateSpecialServices === true || config.orderActions.canAddMedia === true)">
                        <button class="new-default-btn add-services x-small-btn add-group-btn" type="button" (click)="onAddServicesToMedia(sp.spotFile.spotFileGuid, sp.groupSpotId)">
                          <label>Add Services</label>
                        </button>
                      </div>
                    </td>
                    <td align="center" class="blue-text p-normal">
                      <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="config.orderActions.canDeleteMedia === true || (config.orderActions.canDeleteAwaitingMedia === true && sp.spotFile.isAwaitingMedia == true)" (click)="onDeleteMedia(sp.spotFile.spotFileGuid, sp.groupSpotId)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </td>
                  </tr>
                  <tr *ngIf="isNonTextItemsExists(sp.serviceData)">
                    <td [attr.colspan]="config.orderActions.canEditServiceOptions === true ? 7 : 6" class="tylie-table__cell-brless">
                      <table class="tylie-table table">
                        <thead class="tylie-table__head tylie-table__head--dark">
                          <tr>
                            <th width="25%">Service</th>
                            <th width="60%">File</th>
                            <th width="15%">Type</th>
                          </tr>
                        </thead>
                        <tbody class="tylie-table__body">
                          <ng-container *ngFor="let serv of sp.serviceData">
                            <ng-container *ngFor="let mt of serv.serviceMetadata">
                              <tr *ngIf="isNonTextItem(mt)">
                                <td>{{serv.service.name}}</td>
                                <td *ngIf="mt.fileValue == null">{{mt.display}}</td>
                                <td *ngIf="mt.fileValue != null"><a class="quickLink" (click)="onDownloadFile(mt.fileValue.orderFileGuid, mt.fileValue.fileName);">{{mt.display}}</a></td>
                                <td>{{mt.fileValue != null ? mt.fileValue.fileType : 'Text'}}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-container *ngIf="triedToSave == true">
                <p class="p-small" *ngFor="let er of serviceErrors" style="color: #ec1c23">{{er}}</p>
              </ng-container>
            </div>
          </section>

          <section class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.destinations.length > 0 || group.ftpDestinations.length > 0 || group.shareLinks.length > 0">
                <h4 class="tylie-panel__title">
                  Destinations&nbsp;({{group.destinations.length + group.ftpDestinations.length + group.shareLinks.length}})
                  <div class="d-flex justify-content-end">
                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" *ngIf="config.orderActions.canAddDestination === true" (click)="onAddFtpDestination()">
                      <span class="tylie-button__icon"><icon-upload color="#1e90ff"></icon-upload></span>
                      Ftp Upload
                    </button>

                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" *ngIf="config.orderActions.canAddDestination === true" (click)="onAddShareLinks()">
                      <span class="tylie-button__icon">
                        <svg height="20px" width="20px" enable-background="new 0 0 300 300" version="1.1" viewBox="0 0 300 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                          <path fill="#1e90ff"d="m150 0c-82.839 0-150 67.161-150 150s67.156 150 150 150 150-67.163 150-150-67.164-150-150-150zm-16.682 150c0 3.133-0.571 6.111-1.569 8.901l41.868 20.142c4.819-5.275 11.731-8.595 19.436-8.595 14.553 0 26.353 11.796 26.353 26.348 0 14.555-11.803 26.356-26.359 26.356-14.553 0-26.359-11.801-26.359-26.356 0-1.395 0.145-2.757 0.353-4.09l-44.568-21.436c-4.357 3.188-9.71 5.089-15.52 5.089-14.555 0-26.356-11.796-26.356-26.361 0-14.55 11.801-26.348 26.356-26.348 5.81 0 11.165 1.901 15.523 5.086l44.571-21.431c-0.21-1.336-0.353-2.692-0.353-4.09 0-14.558 11.803-26.356 26.359-26.356 14.553 0 26.356 11.798 26.356 26.356 0 14.555-11.803 26.356-26.356 26.356-7.7 0-14.617-3.328-19.436-8.598l-41.868 20.134c0.999 2.786 1.569 5.771 1.569 8.893z" />
                        </svg>
                        <icon-share ></icon-share>
                      </span>
                        Share Links
                    </button>
                    
                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" *ngIf="config.orderActions.canAddDestination === true" (click)="onAddDestination()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                      Destinations
                    </button>
                  </div>
                </h4>
              </div>
              <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.destinations.length == 0 && group.ftpDestinations.length == 0 && group.shareLinks.length == 0">
                <h4 class="tylie-panel__title">Destinations</h4>
              </div>
              <div class="tylie-panel__body" *ngIf="group.destinations.length == 0 && group.ftpDestinations.length == 0 && group.shareLinks.length == 0 && config.orderActions.canAddDestination == true">
                <div class="d-flex">
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddDestination()">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                    Destinations
                  </button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddFtpDestination()">
                    <span class="tylie-button__icon"><icon-upload color="#1e90ff"></icon-upload></span>
                    Ftp Upload
                  </button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddShareLinks()">
                    <span class="tylie-button__icon">
                      <svg height="20px" width="20px" enable-background="new 0 0 300 300" version="1.1" viewBox="0 0 300 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#1e90ff"d="m150 0c-82.839 0-150 67.161-150 150s67.156 150 150 150 150-67.163 150-150-67.164-150-150-150zm-16.682 150c0 3.133-0.571 6.111-1.569 8.901l41.868 20.142c4.819-5.275 11.731-8.595 19.436-8.595 14.553 0 26.353 11.796 26.353 26.348 0 14.555-11.803 26.356-26.359 26.356-14.553 0-26.359-11.801-26.359-26.356 0-1.395 0.145-2.757 0.353-4.09l-44.568-21.436c-4.357 3.188-9.71 5.089-15.52 5.089-14.555 0-26.356-11.796-26.356-26.361 0-14.55 11.801-26.348 26.356-26.348 5.81 0 11.165 1.901 15.523 5.086l44.571-21.431c-0.21-1.336-0.353-2.692-0.353-4.09 0-14.558 11.803-26.356 26.359-26.356 14.553 0 26.356 11.798 26.356 26.356 0 14.555-11.803 26.356-26.356 26.356-7.7 0-14.617-3.328-19.436-8.598l-41.868 20.134c0.999 2.786 1.569 5.771 1.569 8.893z" />
                      </svg>
                      <icon-share ></icon-share>
                    </span>
                    Share Links
                  </button>
                </div>
                <ng-container *ngIf="triedToSave == true">
                  <p class="p-small" *ngFor="let er of destinationErrors" style="color: #ec1c23">{{er}}</p>
                </ng-container>
              </div>

              <div class="tylie-panel__body" *ngIf="group.destinations.length > 0 || group.ftpDestinations.length > 0 || group.shareLinks.length > 0">
                <table class="tylie-table table table-bordered">
                  <thead class="tylie-table__head">
                    <tr>
                      <th style="width:50%;" [ngClass]="{'group-error-message': (group.containsGroupDestinationErrors == true && triedToSave === true)}">Name</th>
                      <th style="width:15%;">Accepts DVS</th>
                      <th style="width:15%;">Type</th>
                      <th style="width:15%;">Category</th>
                      <th style="width:5%;" *ngIf="config.orderActions.canDeleteDestination === true"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <tr *ngFor="let des of group.destinations">
                      <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSave == true) }">
                        <div [ngClass]="{'group-error-message': (des.containsDestinationError == true && triedToSave === true) }"
                            placement="top" container="body" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">
                          {{des.code}}-{{des.name}} <span *ngIf="des.isBackupDestination"> (Backup for {{des.primaryDestinationCode}})</span>
                        </div>
                        <div class="service-selected" *ngIf="des.isAdditionalInfoRequired == true && anySpotDeliveryExists == true">
                          <a class="tylie-lnk" (click)="onAddEditDestinationInfo(des.code)">
                            {{(config.orderActions.canAddDestination === true) ? "Add / Edit Addl Info" : "View Addl Info"}}
                          </a>
                        </div>
                      </td>
                      <td style="width:15%;">{{des.acceptsDescriptiveVideo}}</td>
                      <td style="width:15%;">{{des.destinationType}}</td>
                      <th style="width:15%;">Destination</th>
                      <td align="center" *ngIf="config.orderActions.canDeleteDestination === true">
                        <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="des.isBackupDestination == false" (click)="onDestinationDelete(des.code, des.orderDestinationId)">
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                      <td align="center" class="blue-text p-normal" *ngIf="config.orderActions.showFlagAndNotify === true && group.orderGroupId > 0">
                        <p class="blue-text o-pad-mar" (click)="onDestinationMark(des.orderDestinationId)">{{ des.isMarked == true ? 'UnMark' : 'Mark' }}</p>
                      </td>
                    </tr>
                    <tr *ngFor="let des of group.ftpDestinations">
                      <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSave == true) }">
                        <div [ngClass]="{'group-error-message': (des.containsDestinationError == true && triedToSave === true) }"
                            placement="top" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">{{des.name}} <span *ngIf="des.isBackupDestination"> (Backup for {{des.primaryDestinationCode}})</span></div>
                      </td>
                      <td style="width:15%;"></td>
                      <td style="width:15%;"></td>
                      <th style="width:15%;">FTP</th>
                      <td align="center" *ngIf="config.orderActions.canDeleteDestination === true">
                        <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="des.isBackupDestination == false" (click)="onFtpDestinationDelete(des.id, des.orderDestinationId, des.name)">
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                      <td align="center" class="blue-text p-normal" *ngIf="config.orderActions.showFlagAndNotify === true && group.orderGroupId > 0">
                        <p class="blue-text o-pad-mar" (click)="onDestinationMark(des.orderDestinationId)">{{ des.isMarked == true ? 'UnMark' : 'Mark' }}</p>
                      </td>
                    </tr>
                    <tr *ngFor="let des of group.shareLinks">
                      <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSave == true) }">
                        <div [ngClass]="{'group-error-message': (des.containsDestinationError == true && triedToSave === true) }"
                            placement="top" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">{{des.groupName}}{{des.isPasswordProtected === true ? ' (Password Protected)' : ''}} <span *ngIf="des.isBackupDestination"> (Backup for {{des.primaryDestinationCode}})</span></div>
                      </td>
                      <td style="width:150%;"></td>
                      <td style="width:15%;"></td>
                      <th style="width:15%;">Share Links</th>
                      <td align="center" *ngIf="config.orderActions.canDeleteDestination === true" (click)="onShareLinksDelete(des.groupGuid, des.orderDestinationId, des.groupName)">
                        <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="des.isBackupDestination == false" >
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                      <td align="center" class="blue-text p-normal" *ngIf="config.orderActions.showFlagAndNotify === true && group.orderGroupId > 0">
                        <p class="blue-text o-pad-mar" (click)="onDestinationMark(des.orderDestinationId)">{{ des.isMarked == true ? 'UnMark' : 'Mark' }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-container *ngIf="triedToSave == true">
                  <p class="p-small" style="color: #ec1c23" *ngFor="let er of destinationErrors">{{er}}</p>
                </ng-container>
              </div>
          </section>

          <section class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.serviceData.length == 0">
              <h4 class="tylie-panel__title">
                Traffic Express

                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddEditGroupServices(false)" *ngIf="config.orderActions.canAddOrderLevelServices == true || config.orderActions.canUpdateSpecialServices == true">
                  <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                  Add Services
                </button>
              </h4>
            </div>

            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.serviceData.length > 0">
              <h4 class="tylie-panel__title">
                Traffic Express
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddEditGroupServices(true)" *ngIf="config.orderActions.canAddOrderLevelServices === true || config.orderActions.canUpdateSpecialServices == true">
                  <span class="tylie-button__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
                      <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0ZM58,43.49H43.42V58H35.61V43.49H21.08V35.68H35.61V21.15h7.81V35.68H58Z" /></g></g>
                    </svg>
                  </span>
                  Add/Edit Services
                </button>

                <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddEditGroupServices(true)" *ngIf="config.orderActions.canAddOrderLevelServices === false && config.orderActions.canUpdateSpecialServices == false">
                  View Services
                </button>
              </h4>
            </div>
            
            <div class="tylie-panel__body" *ngIf="group.serviceData.length == 0">There are no Services.</div>
            <div class="tylie-panel__body" *ngIf="group.serviceData != null && group.serviceData.length > 0">
              <table class="tylie-table table table-bordered">
                <thead class="tylie-table__head">
                  <tr>
                    <th width="90%" [ngClass]="{'group-error-message': (group.containsGroupServiceLevelErrors == true && triedToSave === true) }">
                      Services Selected
                    </th>
                    <th width="10%" *ngIf="config.orderActions.canAddOrderLevelServices === true || config.orderActions.canUpdateSpecialServices == true"></th>
                  </tr>
                </thead>
                <tbody class="tylie-table__body">
                  <tr *ngFor="let serv of group.serviceData; let i = index">
                    <td class="service-selected" [ngClass]="{'group-error-border': (serv.containsServiceMetadataDataErrors == true && triedToSave) }">
                      <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                        *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSave)">
                        {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                      </p>
                      <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && triedToSave)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                    </td>
                    <td align="center" valign="middle" *ngIf="config.orderActions.canAddOrderLevelServices === true">
                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onGroupServiceDelete(serv.service.id)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </td>
                  </tr>
                  <tr *ngIf="isNonTextItemsExists(group.serviceData)">
                    <td colspan="2" class="thumb-cell">
                      <table class="table services-row">
                        <thead>
                          <tr>
                            <th width="20%">Service</th>
                            <th width="55%">File</th>
                            <th width="15%">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let serv of group.serviceData">
                            <ng-container *ngFor="let mt of serv.serviceMetadata">
                              <tr *ngIf="isNonTextItem(mt)">
                                <td>{{serv.service.name}}</td>
                                <td *ngIf="mt.fileValue == null">{{mt.display}}</td>
                                <td *ngIf="mt.fileValue != null"><a class="quickLink" (click)="onDownloadFile(mt.fileValue.orderFileGuid, mt.fileValue.fileName);">{{mt.display}}</a></td>
                                <td>{{mt.fileValue != null ? mt.fileValue.fileType: 'Text'}}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        
          <!--transcode requests-->
          <section class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.transcodeRequest == null">
              <h4 class="tylie-panel__title">
                Transcodes
              </h4>
            </div>

            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.transcodeRequest != null">
              <h4 class="tylie-panel__title">
                Transcodes
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="gotoTranscodeFiles()" *ngIf="config.orderActions.canAddMedia == true && group.spotData != null && (getGroupVideoSpotsCount() > group.transcodeRequest.spotFilesData.length)">
                  <span class="tylie-button__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
                      <!--<title>Asset 8</title>-->
                      <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0ZM58,43.49H43.42V58H35.61V43.49H21.08V35.68H35.61V21.15h7.81V35.68H58Z" /></g></g>
                    </svg>
                  </span>
                  Transcodes
                </button>
              </h4>
            </div>
          
            <div class="tylie-panel__body">
              <div class="d-flex justify-content-start mb-3" *ngIf="group.transcodeRequest == null && config.orderActions.canAddMedia == true">
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="gotoTranscodeFiles()">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                    Add Transcodes
                  </button>
              </div>  

              <ng-container *ngIf="group.transcodeRequest != null && group.transcodeRequest.spotFilesData.length > 0">
                  <table class="tylie-table table table-bordered">
                    <thead class="tylie-table__head">
                      <tr>
                        <!--<th width="90%" [ngClass]="{'group-error-message': (group.controls['containsGroupServiceLevelErrors'].value == true && triedToSubmitOrder === true) }">
                          TRANSCODES
                        </th>-->
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody class="tylie-table__body">
                      <tr>
                        <td colspan="2" class="thumb-cell">
                          <table class="table services-row table-bordered">
                            <thead>
                              <tr>
                                <th width="20%">Ad ID</th>
                                <th width="40%">Title</th>
                                <th width="35%">OLV Profiles</th>
                                <!--<th width="15%">File Naming</th>-->
                                <th width="5%" *ngIf="config.orderActions.canAddMedia == true"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let mt of group.transcodeRequest.spotFilesData">
                                <tr>
                                  <td>{{mt.adId}}</td>
                                  <td>{{mt.title}}</td>
                                  <!--<td *ngIf="group.transcodeRequest.spotFileTranscodesData != null">{{getOLVProfilesForSpot(mt.spotFileGuid)}}</td>
                                  <ng-container *ngIf="getFileNamingForSpot(mt.spotFileGuid) as fileNaming">
                                    <td *ngIf="fileNaming.isBasicFileNaming == true"><p class="p-extra-small">{{fileNaming.displayString}}</p></td>
                                    <td *ngIf="fileNaming.isBasicFileNaming == false">
                                      <p class="p-extra-small blue-text" placement="top" [popover]="fileNaming.fileNamingPopOverDisplay" triggers="mouseenter:mouseleave" containerClass="customPopover">
                                        {{fileNaming.displayString}}
                                      </p>
                                    </td>
                                  </ng-container>-->

                                  <td>
                                    <ng-container *ngFor="let spec of getOLVProfilesForSpot(mt.spotFileGuid)">
                                      <p class="p-extra-small black">
                                        {{spec}}
                                        <ng-container *ngIf="getFileNamingForSpot(mt.spotFileGuid, spec) as fileName">
                                            ({{fileName}})
                                        </ng-container>
                                      </p>
                                    </ng-container>
                                  </td>

                                  <td *ngIf="config.orderActions.canAddMedia == true" align="center">
                                    <span>
                                      <i class="icon-ProjectEdit" (click)="editGroupSpotMediaTranscode(mt.spotFileGuid)"></i> &nbsp;
                                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onTranscodeAssetDelete(mt.spotFileGuid)">
                                        <icon-trash-can color="#1e90ff"></icon-trash-can></a>
                                    </span>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </ng-container>

              <!--transcode requests-->

              <div *ngIf="groupErrors != null && groupErrors.length > 0">
                <br />
                <ng-container *ngFor="let err of groupErrors">
                  <p class="p-small" style="color: #ec1c23">{{err}}</p>
                </ng-container>
              </div>
            </div>
          </section>

          <div class="mb-3" *ngIf="config.orderActions.canDeleteGroup === true || (config.orderActions.canDeleteAwaitingMediaGroup === true && group.isAllAwaitingMediaSpotsInGroup == true)">
            <div class="d-flex justify-content-end">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                    outsideClick="true" placement="left" #delpop="bs-popover" container="body" [popover]="deleteGroupTemplate">
                <span class="tylie-button__icon">
                  <icon-trash-can color="#1e90ff"></icon-trash-can>
                </span>
                Delete Group
              </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteGroupTemplate>
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this Group?
    </p>
    <i (click)="onHidePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onGroupDeleteConfirm()">Delete</button> -->


  <button class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header"> Are you sure you'd like to delete this Group?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onGroupDeleteConfirm()">Delete</button>
</ng-template>

<ng-template #groupNameTemplate>
  <button class="popover__close" (click)="onHideGroupNamePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <div class="tylie-form-group form-group">
    <label class='tylie-form-group__lbl'>Group Name</label>
    <input class="form-control tylie-text" type="text" [(ngModel)]="groupName" />
  </div>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onGroupNameEditConfirm()">Rename</button>
</ng-template>
