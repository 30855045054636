<div id="saved-destinations">
  <div class="modal-header">
    <button type="button" class="modal-close delete" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    
      <div class="row" *ngIf="selectionMethod == 'editsavedlist'">
        <div class="col-md-8 edit-dest-heading">
          <h6 *ngIf="nameEditInEditSavedDestinationList == false">
            <span>{{savedDestinationListToEdit.name}}</span>
            <!-- <i class="glyphicon glyphicon-pencil"></i>
            <span class="edit-text-dest"
                  (click)="nameEditInEditSavedDestinationList=true;">Edit</span> -->
            <a class="tylie-button tylie-button--xs tylie-button--link" (click)="nameEditInEditSavedDestinationList=true;">
              <span class="tylie-button__icon"><icon-edit-pencil color="#1e90ff"></icon-edit-pencil></span>
              Edit
            </a>
          </h6>
          <input type="text" [(ngModel)]="savedDestinationListToEdit.name"
                 class="form-control edit-destinationlist-name"
                 *ngIf="nameEditInEditSavedDestinationList == true" />
        </div>
        <div class="col-md-4">
          <button class="new-default-btn x-small-btn add-group-btn" (click)="onAddMoreToEditDestinationList()"
                  *ngIf="addMoreInEditSavedDestinationList == false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
              <!--<title>Asset 8</title>-->
              <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0ZM58,43.49H43.42V58H35.61V43.49H21.08V35.68H35.61V21.15h7.81V35.68H58Z"></path></g></g>
            </svg>
            <label>ADD MORE DESTINATIONS</label>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <section class="tylie-input-search" *ngIf="selectionMethod == 'list' || selectionMethod == 'savedlist' || (selectionMethod == 'editsavedlist' && addMoreInEditSavedDestinationList == true)">
            <div class="tylie-input-search__inner">
              <div class="tylie-input-search__icon"><icon-search></icon-search></div>
              <input class="tylie-input-search__text" [placeholder]="selectionMethod == 'savedlist' ? 'Search By Name' : 'Search By Name or Destination ID or Aka'"
                  name="srch-term" id="srch-term" type="search" (keyup.enter)="onQuickSearchDestinations()"
                  [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
            </div>
            <div class="tylie-input-search__btn">
              <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
            </div>
          </section>

          <div class="tylie-form-group form-group" *ngIf="selectionMethod == 'copyupload'">
            <div>
              <label class="tylie-form-group__lbl">Upload Destination from File</label>
            </div>
            <!-- <span class="btn-bs-file new-default-btn small-btn">
              {{isUploading ? 'Importing...' : 'Browse'}}
              <input type="file" (change)="onExcelUploadFileChange($event)" accept=".xlsx,.xls">
            </span> -->

            <label class="tylie-upload">
              <input type="file" (change)="onExcelUploadFileChange($event)" style="display:none" accept=".xlsx,.xls">
              <div class="tylie-upload__action tylie-upload__action--xs">
                {{isUploading ? 'Importing...' : 'Browse'}}
              </div>
            </label>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs" *ngIf="selectionMethod != 'editsavedlist' && selectionMethod != 'copyuploadresult' && selectionMethod != 'list'" type="button" (click)="onDestinationListsSelected();">Destination List</button>
        
          <button class="tylie-button tylie-button--xs ms-3" *ngIf="selectionMethod != 'editsavedlist' && selectionMethod != 'copyuploadresult' && selectionMethod != 'savedlist'" type="button" (click)="onDestinationSavedListsSelected();">SAVED DESTINATION LISTS</button>
        
          <button class="tylie-button tylie-button--xs ms-3" *ngIf="selectionMethod != 'editsavedlist' && selectionMethod != 'copyuploadresult' && selectionMethod != 'copyupload'" type="button" (click)="onCopyPasteOptionSelected();">Copy Or Upload</button>
        </div>
      </div>
      <div class="my-4" *ngIf="selectionMethod == 'list' || (selectionMethod == 'editsavedlist' && addMoreInEditSavedDestinationList == true)">
        <dx-data-grid class="tylie-grid tylie-grid--bordered " id="destinationsGrid"
                      #destinationsGrid
                      [dataSource]="dataSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="code" width="20%" cssClass="gridStyle" caption="Destination ID" sortOrder="asc"></dxi-column>
          <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Name"></dxi-column>
          <dxi-column dataField="acceptsDescriptiveVideo" width="12%" cssClass="gridStyle" caption="Accepts DVS"></dxi-column>
          <dxi-column dataField="destinationType" width="15%" cssClass="gridStyle" caption="Type"></dxi-column>
          <dxi-column dataField="aka" width="15%" cssClass="gridStyle" caption="Aka"></dxi-column>
          <dxi-column dataField="market" width="40%" cssClass="gridStyle" caption="Market"></dxi-column>
          <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
          </dxo-remote-operations>
          <dxo-paging [pageSize]="selectionMethod == 'list' ? 10 : 5"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[5, 10, 20, 50]"
                      infoText="Page {0} of {1} ({2} items)"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
       
        <div class="d-flex justify-content-end" *ngIf="selectionMethod == 'editsavedlist'">
            <button class="tylie-button tylie-button--xs" type="button"
                    (click)="onAddDestinationInEditDestinationList()">
              ADD TO LIST
            </button>
        </div>
      </div>

      <div class="my-4" *ngIf="selectionMethod == 'savedlist'">
        <dx-data-grid class="tylie-grid tylie-grid--bordered " id="destinationsSavedListGrid"
                          #destinationsSavedListGrid
                          [dataSource]="dataSourceSavedList"
                          [columnAutoWidth]="true"
                          [showColumnLines]="true"
                          [rowAlternationEnabled]="true"
                          [hoverStateEnabled]="true"
                          [showBorders]="true"
                          [showRowLines]="true"
                          keyExpr="id">
              <dxo-selection mode="multiple"
                             selectAllMode="page"
                             allowSelectAll="Select"
                             showCheckBoxesMode="always">
              </dxo-selection>
              <dxi-column dataField="name" width="25%" cssClass="gridStyle" caption="List Name" sortOrder="asc"></dxi-column>
              <dxi-column dataField="favDestination" width="60%" cssClass="gridStyle" caption="Destinations in List"></dxi-column>
              <dxi-column dataField="name" width="15%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate"></dxi-column>
              <div *dxTemplate="let t of 'actionsTemplate'">
                <div style="text-align:center;">
                  <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDestinationListDelete(t.data.id)">
                    <icon-trash-can color="#1e90ff"></icon-trash-can>
                  </a>
                  <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDestinationListEdit(t.data.id)">
                    <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
                  </a>
                </div>
              </div>
        </dx-data-grid>
      </div>

      <div class="my-4" *ngIf="selectionMethod == 'editsavedlist'">
        <dx-data-grid class="tylie-grid tylie-grid--bordered" id="editDestinationListGrid" *ngIf="savedDestinationListToEdit"
                      #editDestinationListGrid
                      [dataSource]="savedDestinationListToEdit.destinations"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxi-column dataField="code" width="20%" cssClass="gridStyle" caption="Destination ID" sortOrder="asc"></dxi-column>
          <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Name"></dxi-column>
          <dxi-column dataField="aka" width="15%" cssClass="gridStyle" caption="aka"></dxi-column>
          <dxi-column dataField="destinationType" width="15%" cssClass="gridStyle" caption="Type"></dxi-column>
          <dxi-column dataField="name" width="10%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate"></dxi-column>
          <div *dxTemplate="let t of 'actionsTemplate'">
            <div style="text-align:center;">
              <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDestinationListDestinationDelete(t.data.code)">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </a>
            </div>
          </div>
          <dxo-paging [pageSize]="5"></dxo-paging>
          <dxo-pager [showNavigationButtons]="true"
                      infoText="Page {0} of {1} ({2} items)"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>

      <ng-container *ngIf="selectionMethod == 'copyupload'">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl">Paste Destinations below (comma separated or stacked as one item per line)</label>
          <textarea name="upload-text" class="tylie-textarea tylie-textarea--lg form-control" [(ngModel)]="pastedDestinations"></textarea>
        </div>
      </ng-container>

      <div class="" *ngIf="selectionMethod == 'copyuploadresult'">
        <p class="p-small" [ngClass]="copyUploadErrorsExist == true ? 'error-message' : 'sucess-message'">{{copyUploadResultMessage}}</p>
        <p class="p-normal">
          {{copyUploadErrorsExist == 'copyuploadresult' ? "Please select from the suggested destinations or delete to continue." : "Add them to the Order using the 'Add Destinations' button below or save these to a list."}}
        </p>
      </div>

      <div class="my-4" *ngIf="selectionMethod == 'copyuploadresult'">
        <table class="tylie-table table add-dest-table" border="0">
          <thead class="tylie-table__head">
            <tr>
              <th>Destination ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Market</th>
              <th class="close-border-dest"></th>
            </tr>
          </thead>
          <tbody class="tylie-table__body">
            <ng-container *ngFor="let dest of copyUploadErrorDestinations; let i = index">
              <tr *ngIf="dest.selectedDestination != null">
                <td>{{dest.selectedDestination.code}}</td>
                <td>{{dest.selectedDestination.name}}</td>
                <td>{{dest.selectedDestination.destinationType}}</td>
                <td>{{dest.selectedDestination.market}}</td>
                <td><a class="close-btntylie-button tylie-button--xs tylie-button--link" (click)="onCopyUploadErrorSuggestedDestinationDelete(dest.selectedDestination.code)"><icon-trash-can color="#1e90ff"></icon-trash-can></a></td>
              </tr>
              <tr class="danger" *ngIf="dest.selectedDestination == null">
                <td class="error-message">{{dest.code}}</td>
                <td colspan="2" class="zero-dest-margin" *ngIf="dest.destinations.length > 0">
                  <table class="table table-bordered" *ngIf="dest.destinations.length > 0">
                    <thead>
                      <tr class="danger">
                        <th>Select</th>
                        <th>Destination ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Market</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="danger" *ngFor="let destSuggest of dest.destinations; let i = index">
                        <td align="center">
                          <input type="radio" name="dest.code" (change)="onDestinationSuggest(dest.code, destSuggest.code)">
                        </td>
                        <td>{{destSuggest.code}}</td>
                        <td>{{destSuggest.name}}</td>
                        <td>{{destSuggest.destinationType}}</td>
                        <td>{{destSuggest.market}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td *ngIf="dest.destinations.length == 0" colspan="2">No suggested match found</td>
                <td>
                  <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onCopyUploadErrorNoSuggestedDestinationDelete(dest.code)"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
                </td>
              </tr>
            </ng-container>

            <tr *ngFor="let dest of copyUploadSuccessDestinations; let i = index">
              <td>{{dest.code}}</td>
              <td>{{dest.name}}</td>
              <td>{{dest.destinationType}}</td>
              <td>{{dest.market}}</td>
              <td>
                <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onCopyUploadSuccessDestinationDelete(dest.code)"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex" *ngIf="selectionMethod == 'copyupload'">
        <button type="button" class="tylie-button tylie-button--xs me-3" type="button"
                  [ngClass]="{disabled : (pastedDestinations == null || pastedDestinations.trim() == '')}"
                  (click)="onAddDestinationsAfterUploadComplete()">
            Add Destinations
        </button>
        <div class="d-flex justify-content-end">
          <p class="tylie-type-body">* Add Additional Destinations from Order Build</p>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link"
              [ngClass]="copyUploadErrorsExist == true ? 'disabled' : 'qc-cancel'"
              *ngIf="selectionMethod == 'list' || selectionMethod == 'copyuploadresult'"
              (click)="onSaveToDestinationList()">SAVE TO DESTINATIONS LIST</button>


        <button class="tylie-button tylie-button--xs ms-3" type="button"
                [ngClass]="{disabled: copyUploadErrorsExist == true}"
                *ngIf="selectionMethod == 'list' || selectionMethod == 'copyuploadresult' || selectionMethod == 'savedlist'"
                (click)="onApplyToOrder()">
          APPLY TO ORDER
        </button>

        <button class="tylie-button tylie-button--xs ms-3" type="button" *ngIf="selectionMethod == 'editsavedlist'" (click)="onSaveInEditDestinationList()">
          SAVE DESTINATION LIST
        </button>
      </div>

      <div *ngIf="selectionMethodNext == 'savetolist'">
        <ordersavetodestinationlistpopup [destinationsToAdd]="destinationsToAddToSavedList" (saveToListComplete)="onSaveToListCompleted()"></ordersavetodestinationlistpopup>
      </div>
    
  </div>
</div>
