export class SpotFile {
  public spotFileGuid: string;
  public spotGuid: string;
  public fileName: string;
  public masterFileName: string;
  public proxyFileName: string;
  public filePath: string;
  public title: string;
  public mediaType: string;
  public status: string;
  public created: string;
  public proxyUrl: string;
  public thumbnailUrl: string;
  public length: string;
  public isPlayable: boolean;
  public isViewable: boolean;
  public isDownloadable: boolean;
  public formatSources: Array<string>;
  public formatClasses: Array<string>;

  public spotTrac: boolean;
  public veilEncode: boolean;
  public descriptiveVideo: boolean;
  public centerCutProtected: boolean;
  public subtitles: boolean;
  public sc: boolean;
  public audioFormat: string;
  public aspectRatio: string;

  public broadcast: boolean;
  public web: boolean;
  public taggable: boolean;
  public generic: boolean;
  public runningFootage: boolean;
  public cc: boolean;

  public sap: boolean;
  public vchip: boolean;
  public surround: boolean;
  public mono: boolean;
  public letterBox: boolean;

  public adId: string;

  public postProdFiles: SpotFile[];
  public format: string;
  public postProdFilesExists: boolean;

  public isExpired: boolean;
  public isArchived: boolean;
  public isActive: boolean;

  public titleInValid: boolean;
  public adIdInValid: boolean;

  public isSelected: boolean

  public product: string;

  public outputFileName: string;
  public wcpOutputFileName: string;
  public tbiOutputFileName: string;

  public showCreatingMediaBanner: boolean;

  public isAwaitingMedia: boolean;

  public isMasterDownloadable: boolean;

  public storageKey: string;

  public storageType: string;

  public client: string;

  public brand: string;

  public clientId: number;
  public durationInSecs: number;
}
