import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { SpotResendConfirm, SpotResendWarning } from '../../../models/order/spot-onhand.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { MediaDetailComponent } from '../../mediadetail/mediadetail.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MediaDetailPopupComponent } from '../mediadetailpopup/mediadetailpopup.component';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'spotresendpopup',
  templateUrl: './spotresendpopup.component.html',
  styleUrls: ['./spotresendpopup.component.css'],
})
export class SpotResendPopupComponent extends BasePopupComponent {

  @Input() spotResendData: Array<SpotResendConfirm>;
  @Input() spotDestinationOnHandDurationInDays: number;
  @Input() spotResendWarnings: Array<SpotResendWarning>;


  public onResendConfirmComplete: Subject<Array<SpotResendConfirm>>;
  @ViewChild('resendGrid', { static: false }) resendGrid: DxDataGridComponent;
  public modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onResendConfirmComplete = new Subject();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onConfirm() {

    //let selected: Array<number> = [];

    //this.resendGrid.instance.getSelectedRowKeys().forEach((id, i) => {
    //  var item = this.spotResendData.find(t => t.id = id);
    //  item.resend = true;
    //});

    var grid = this.resendGrid.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {

      var item = this.spotResendData.find(t => t.id == grid.getSelectedRowsData()[i].id);

      if (item != null)
        item.resend = true;
    }

    this.onResendConfirmComplete.next(this.spotResendData);

    this.closePopup();
  }

  public showDetail(spotFileGuid: string) {
    var initialState = {
      spotFileIdentifier: spotFileGuid
    };

    this.modalRef = this.modalService.show(MediaDetailPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
     // e.rowElement.css({ height: 54 });
      if (e.data.daysFromLastDelivery > 79 && e.data.daysFromLastDelivery <= this.spotDestinationOnHandDurationInDays) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.daysFromLastDelivery > 60 && e.data.daysFromLastDelivery <= 79) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public showOrderDetail(orderGuid: string): void {

    this.util.showOrderDetailInNewWindow(orderGuid);
  }
}
