import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VendorPurchaseOrderViewModel } from "../../../../models/order/vendor-purchaseorder-request.model";
import { ReportService } from "../../../../services/report/report.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Utilities } from "../../../../services/core/utilities";
import { Constants } from "../../../../configurations/constants";

@Component({
  selector: 'vendor-po-confirmation',
  templateUrl: './vendorpoconfirmation.component.html',
  styleUrls: ['./vendorpoconfirmation.component.css'],
})
export class VendorPOConfirmationComponent implements OnInit {

  @Input() vendorPOList: VendorPurchaseOrderViewModel[];
  public downloadingVendorPOs = [];

  constructor(private reportService: ReportService,
    private alertService: AlertService,
    public util: Utilities) {
  }

  ngOnInit() {

  }

  public onDownloadVendorPOPDF(orderId: number, vendorId: number, vendorPurchaseOrder: string) {
    this.downloadingVendorPOs.push(vendorPurchaseOrder);
    this.reportService.getVendorPurchaseOrderPDF({ orderId: orderId, vendorId: vendorId, purchaseOrderNumber: vendorPurchaseOrder }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var path = res.result.reportFullPath;
        this.downloadFile(path, res.result.reportName, vendorPurchaseOrder);
      }
      else {
        this.resetDownloadPOs(vendorPurchaseOrder);
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.resetDownloadPOs(vendorPurchaseOrder);
        this.util.handleError(error)
      });
  }

  private downloadFile(path: string, reportName: string, vendorPurchaseOrder: string) {
    if (path == null) {
      this.resetDownloadPOs(vendorPurchaseOrder);
      return;
    }
    else {
      this.util.downloadFile(this.util.getFileUrl(path)).subscribe(
        fileData => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(fileData);
            this.resetDownloadPOs(vendorPurchaseOrder);
          }
          else {
            var objectUrl = URL.createObjectURL(fileData);
            window.open(objectUrl);
            this.resetDownloadPOs(vendorPurchaseOrder);
          }
        });
    }
  }

  private resetDownloadPOs(vendorPurchaseOrder: string) {
    if (this.downloadingVendorPOs.includes(vendorPurchaseOrder)) {
      this.downloadingVendorPOs = this.downloadingVendorPOs.filter(f => f != vendorPurchaseOrder);
    }
  }
}
