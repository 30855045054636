<!--resend??-->
<div class="container">
  <div class="imgcontainer">
    <img src="./assets/svg/TylieTandem-Logo.svg" alt="Avatar" class="avatar">
  </div>
  <div class="media-awaiting-approval-section animated zoomIn" *ngIf="userResetTokenValid==true">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a class="p-small">
            Create New Password
          </a>
        </h4>
      </div>
      <div id="mediaApprovalCollapseOne" class="panel-collapse collapse in">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-danger" *ngIf="errorMsg">
                {{errorMsg}}
              </div>
              <form novalidate [formGroup]="ForgotPassword" #r="ngForm">
                <p class="p-small black">Please enter your new password</p>
                <div class="form-group" [ngClass]="{error: (ForgotPassword.controls['newpsw'].errors && !ForgotPassword.controls['newpsw'].pristine) || (ForgotPassword.controls['newpsw'].errors && r.submitted)}">
                  <label for="psw"><b>New Password</b><span class="required">*</span></label>
                  <input id="newpsw" type="password" formControlName="newpsw" name="newpsw" class="form-control">
                  <p class="p-extra-small" *ngIf="ForgotPassword.controls['newpsw'].errors?.required">Please Enter New Password</p>
                  <p class="p-extra-small" *ngIf="ForgotPassword.controls['newpsw'].errors?.pattern">Password must be atleast 8 letters and must include at least a Capital Letter [A-Z], lowercase letter [a-z], a digit [0-9], and a special character</p>
                </div>
                <div class="form-group" [ngClass]="{error: ((ForgotPassword.controls['cnfpsw'].errors || ForgotPassword.controls['cnfpsw'].value != ForgotPassword.controls['newpsw'].value ) && !ForgotPassword.controls['cnfpsw'].pristine) || (ForgotPassword.controls['cnfpsw'].errors && r.submitted)}">
                  <label for="psw"><b>Confirm Password</b><span class="required">*</span></label>
                  <input id="cnfpsw" type="password" formControlName="cnfpsw" name="cnfpsw" class="form-control">
                  <p class="p-extra-small" *ngIf="ForgotPassword.controls['cnfpsw'].errors?.required">Please Confirm New Password</p>
                  <p class="p-extra-small" *ngIf="ForgotPassword.controls['cnfpsw'].value != ForgotPassword.controls['newpsw'].value ">New password and confirm password do not match</p>
                </div>
                <div class="form-group media-qc  col-md-5 login-btn">
                  <button type="submit" class="btn btn-normal qc-update" [disabled]="isLoading" (click)="updatePassword()"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? 'Updating...' : 'Continue'}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="tyl-copy-right p-tiny gray3">
          Any concerns please contact us at (818) 955 7600 or support@tylie.com
        </div>
      </div>
    </div>
  </div>
</div>
  <div  class="container" *ngIf="userResetTokenValid==false">
    <!--{{invalidErrorMsg}}-->
    <div >
      <br />
      <div>
        <div class="change-password-container">
          <p class="p-small black">{{invalidErrorMsg}}</p>

          <p class="p-small">Looks like the link has expired, please request for a new one.</p>

          <!--<p class="p-extra-small">You may need to check your Junk or Spam folder for a message from tylie.com</p>-->
          <div class="form-group media-qc  col-md-5 login-btn">
            <button type="button" (click)="backToLogin()" class="btn btn-normal qc-update" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? 'Taking you back...' : 'Back to login'}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>

