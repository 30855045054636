import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { OrderAdditionalMetadata } from '../../../../models/order/order-additionalmetadata';

@Component({
  selector: 'service-datacollection',
  templateUrl: './servicebasedatacollection.component.html'
})
export class BaseServiceDataCollectionComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() f: any;
  @Input() orderGuid: string;
  @Input() additionalMetadata: OrderAdditionalMetadata;
  @Input() applyAllMode: boolean;
  @Input() specialServiceMode: boolean;
  @Input() showInEditMode: boolean;
  @Input() spotLevel: boolean;
  @Input() groupLevel: boolean;
  @Input() orderLevel: boolean;

  @Output() serviceDeleted = new EventEmitter<void>();

  //public readOnlyForm: boolean = false;
  //public specialOptionTags: Array<boolean> = [];
  public isDeleted: boolean = false;
  public canDelete: boolean = false;
  public allowSpecialOptions: boolean = false;

  constructor() {
  }

  ngOnInit() {

    //if ((this.showInEditMode === false) || (this.specialServiceMode == true && this.formGroup.controls['isSpecialOptionEdit'].value === false && this.formGroup.controls['isSpecialEdit'].value === false))
    //this.readOnlyForm = true;
    //this.readOnlyForm = (this.formGroup.controls['readOnlyForm'].value == true);

    //this.specialOptionTags = this.formGroup.controls['specialOptionTags'].value;

    //console.log(this.formGroup.controls['serviceName'].value, this.readOnlyForm, this.specialOptionTags);

    this.isDeleted = (this.formGroup.controls['isDeleted'].value === true);

    if (this.orderLevel == true)
      this.canDelete = (this.showInEditMode === true && this.formGroup.controls['recentlyAdded'].value === false && (this.specialServiceMode === false || (this.specialServiceMode === true && this.formGroup.controls['isOrderSpecialEdit'].value === true)));
    else if (this.groupLevel == true)
      this.canDelete = (this.showInEditMode === true && this.formGroup.controls['recentlyAdded'].value === false && (this.specialServiceMode === false || (this.specialServiceMode === true && this.formGroup.controls['isGroupSpecialEdit'].value === true)));
    else if (this.spotLevel == true)
      this.canDelete = (this.showInEditMode === true && this.formGroup.controls['recentlyAdded'].value === false && (this.specialServiceMode === false || (this.specialServiceMode === true && this.formGroup.controls['isSpotSpecialEdit'].value === true)));
    else
      this.canDelete = false;

    this.allowSpecialOptions = this.additionalMetadata.allowSpecialOptions;
  }

  onServiceDelete(): void {
    this.formGroup.controls['isDeleted'].setValue(true);
    this.formGroup.clearValidators();
    this.formGroup.setErrors(null);
    this.formGroup.updateValueAndValidity();

    for (const key in this.formGroup.controls) {
      this.formGroup.get(key).setErrors(null);
      this.formGroup.get(key).clearValidators();
      this.formGroup.get(key).updateValueAndValidity();
    }

    this.isDeleted = true;

    this.serviceDeleted.emit();
  }

  uploadOrPasteScriptRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((!Validators.required(controls[0]) && controls[0].value.trim() != '') || !Validators.required(controls[1])) {
        return null;
      }
      return { uploadOrPasteScriptRequired: true };
    };
  }

  nonZeroNumberRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!Validators.required(controls[0]) && controls[0].value != '0') {
        return null;
      }
      return { nonZeroNumberError: true };
    };
  }

  minimumMinsRequired(minimumMins: number, ...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      //if (controls[0].value == '0' && controls[1].value == '0') {
      //  return { minOneHrRequired: true }
      //}
      if (controls[0].value != null && controls[0].value != '' && parseInt(controls[0].value) < minimumMins) {
        return { minimumMinsRequired: true }
      }
      return null;
    };
  }

  oneOfControlRequiredBasedOnAnother(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((controls[2].value != true) || (!Validators.required(controls[0]) && controls[0].value.trim() != '') || !Validators.required(controls[1]) && controls[1].value.trim() != '') {
        return null;
      }
      return { oneOfControlRequiredBasedOnAnother: true };
    };
  }

  oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (!Validators.required(aControl) && aControl.value.trim() != '') {
          return null;
        }
      }
      return { oneOfRequired: true };
    };
  }

  oneOfControlRequiredBool(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (aControl.value === true) {
          return null;
        }
      }
      return { oneOfRequiredBool: true };
    };
  }

  baGuaranteePaymentOptionsValidator(baGuaranteePaymentOptions: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasError = baGuaranteePaymentOptions.some(option =>
        option.invoiceamount == null || option.invoiceamount === '' ||
        option.percentage == null || option.percentage === '' ||
        option.itemdescription == null || option.itemdescription === '' ||
        option.refno == null || option.refno === ''
      );

      return hasError ? { 'baGuaranteePaymentOptionsError': true } : null;
    };
  }
}

