// Default and angular components/services
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Main
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.module.routing';
import { AppConfigModule } from './app.module.config';

// Custom imports
import { DevExtremeModule, DxAutocompleteModule } from 'devextreme-angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxLoadingModule } from 'ngx-loading';
import { NgIdleModule } from '@ng-idle/core';


// Components

// media
import { MediaComponent } from './components/media/media.component';
import { MediaDetailComponent } from './components/mediadetail/mediadetail.component';
import { SpotqcComponent } from './components/media/spotqc/spotqc.component';
import { SpotSearchComponent } from './components/media/spotsearch/spotsearch.component';
import { SpotApprovalComponent } from './components/media/spotapproval/spotapproval.component';
import { PlaylistComponent } from './components/media/playlist/playlist.component';
import { RecentlyViewedComponent } from './components/media/recentlyviewed/recentlyviewed.component';
import { ReportComponent } from './components/report/report.component';
import { VideoplayerComponent } from './components/videoplayer/videoplayer.component';
import { SpotUploadComponent } from './components/media/spotupload/spotupload.component';
import { QuickplayerComponent } from './components/videoplayer/quickplayer/quickplayer.component';
import { QuickLinkComponent } from './components/media/quicklink/quicklink.component';
import { QuickLinkDetailComponent } from './components/quicklinkdetail/quicklinkdetail.component';
import { MediaDetailContentComponent } from './components/mediadetail/mediadetailcontent/mediadetailcontent.component';
import { SpotDownloadComponent } from './components/media/spotdownload/spotdownload.component';
import { RightsManagementComponent } from './components/rightsmangement/rightsmanagement.component';

// Orders
import { OrdersComponent } from './components/orders/order-home/orders.component';
import { OrderSearchComponent } from './components/orders/order-home/order-search/order-search.component';
import { DraftOrdersComponent } from './components/orders/order-home/draft-orders/draft-orders.component';
import { CreateOrderComponent } from './components/orders/order-create/createorder.component';
import { CreateOrderGroupComponent } from './components/orders/order-create/create-order-group/createordergroup.component';
import { AwaitingOrderReviewComponent } from './components/orders/order-home/awaiting-order-review/awaiting-order-review.component';
import { OrderGroupDetailComponent } from './components/orders/order-detail/order-detail-group/ordergroupdetail.component';
import { OrderHeaderDetailComponent } from './components/orders/order-detail/order-detail-header/orderheaderdetail.component';
import { OrdersInProgressComponent } from "./components/orders/order-home/orders-in-progress/orders-in-progress.component";
import { ReadyToProcessOrdersComponent } from "./components/orders/order-home/ready-to-process-orders/ready-to-process-orders.component";
import { ProcessingOrdersComponent } from "./components/orders/order-home/processing-orders/processing-orders.component";
import { OrderDetailSpotsComponent } from "./components/orders/order-detail/order-detail-spots/order-detail-spots.component";
import { OrderDetailComponent } from "./components/orders/order-detail/orderdetail.component";
import { OrderDetailFTPComponent } from "./components/orders/order-detail/order-detail-ftp/order-detail-ftp.component";
import { OrderDetailDownloadsComponent } from "./components/orders/order-detail/order-detail-download/order-detail-download.component";
import { OrderDetailDestinationsComponent } from "./components/orders/order-detail/order-detail-destinations/order-detail-destinations.component";
import { OrderDetailShareLinkComponent } from "./components/orders/order-detail/order-detail-sharelink/order-detail-sharelink.component";
import { VendorSubmissoinFailedOrderComponent } from "./components/orders/order-home/vendor-submission-failed-orders/vendor-submission-failed-orders.component";
import { OrderUploadComponent } from "./components/orders/order-upload/orderupload.component";
import { UploadedOrdersComponent } from "./components/orders/order-home/uploaded-orders/uploaded-orders.component";
import { CreateOrderContentComponent } from './components/orders/order-create/create-order-content/createordercontent.component';
import { OrderDetailContentComponent } from './components/orders/order-detail/order-detail-content/orderdetailcontent.component';
import { ReadyForBillingOrdersComponent } from './components/orders/order-home/ready-for-billing-orders/ready-for-billing-orders.component';

// Order Service Metadata questions
import { ServiceDescriptiveVideoQComponent } from './components/orders/order-servicemetadata-questions/service-descriptivevideoservice-metadata/servicedescriptivevideo.component';
import { ServiceCaptioningQComponent } from './components/orders/order-servicemetadata-questions/service-captioning-metadata/servicecaptioning.component';
import { ServiceMetadataWrapperComponent } from './components/orders/order-servicemetadata-questions/service-metadata-wrapper/servicemetadatawrapper.component';
import { ServiceWebCaptioningQComponent } from './components/orders/order-servicemetadata-questions/service-webcaptioning-metadata/servicewebcaptioning.component';
import { ServiceWaterMarkingBVSQComponent } from './components/orders/order-servicemetadata-questions/service-watermarkingbvs-metadata/servicewatermarkingbvs.component';
import { ServiceWebmarkingSTQComponent } from './components/orders/order-servicemetadata-questions/service-webmarkingst-metadata/servicewebmarkingst.component';
import { ServiceSubtitlingEmbeddedQComponent } from './components/orders/order-servicemetadata-questions/service-subtitlingembeddedd-metadata/servicesubtitlingembedded.component';
import { ServiceDigitalEditingQComponent } from './components/orders/order-servicemetadata-questions/service-digitalediting-metadata/servicedigitalediting.component';
import { ServiceStdConversionQComponent } from './components/orders/order-servicemetadata-questions/service-stdconversion-metadata/servicestdconversion.component';
import { ServiceSlateReslateQComponent } from './components/orders/order-servicemetadata-questions/service-slatereslate-metadata/serviceslatereslate.component';
import { ServiceTranscribeScriptQComponent } from './components/orders/order-servicemetadata-questions/service-transcribescript-metadata/servicetranscribescript.component';
import { ServiceVoiceOverQComponent } from './components/orders/order-servicemetadata-questions/service-voiceover-metadata/servicevoiceover.component';
import { ServiceTaggingQComponent } from './components/orders/order-servicemetadata-questions/service-tagging-metadata/servicetagging.component';
import { ServiceThumbnailImagesQComponent } from './components/orders/order-servicemetadata-questions/service-thumbnailimages-metadata/servicethumbnailimages.component';
import { ServiceNoAdditionalDataComponent } from './components/orders/order-servicemetadata-questions/service-noadditionaldata-metadata/servicenoadditionaldata.component';
import { BaseServiceDataCollectionComponent } from './components/orders/order-servicemetadata-questions/service-basedatacollection-metadata/servicebasedatacollection.component';
import { OrderSaveDestinationToListPopupComponent } from './components/popups/ordersavetodestinationlistpopup/ordersavetodestinationlistpopupcomponent';
import { ServiceDownConversionQComponent } from './components/orders/order-servicemetadata-questions/service-downconversion-metadata/servicedownconversion.component';
import { ServiceHardDriveQComponent } from './components/orders/order-servicemetadata-questions/service-harddrive-metadata/serviceharddrive.component';
import { ServiceThumbFlashDriveQComponent } from './components/orders/order-servicemetadata-questions/service-thumbflashdrive-metadata/servicethumbflashdrive.component';
import { ServiceOvertimeQComponent } from './components/orders/order-servicemetadata-questions/service-overtime-metadata/serviceovertime.component';
import { ServiceInternationalOrderMgmtsupportQComponent } from './components/orders/order-servicemetadata-questions/service-internationalordermgmtsupport-metadata/serviceinternationalordermgmtsupport.component';
import { ServiceInternationalClearanceFeeQComponent } from './components/orders/order-servicemetadata-questions/service-internationalclearancefee-metadata/serviceinternationalclearancefee.component';
import { ServiceBroadcastClearanceCanQComponent } from './components/orders/order-servicemetadata-questions/service-broadcastclearancecan-metadata/servicebroadcastclearancecan.component';
import { ServiceVideoTranscodingQComponent } from './components/orders/order-servicemetadata-questions/service-videotranscoding-metadata/servicevideotranscoding.component';
import { ServiceInstructionCreationQComponent } from './components/orders/order-servicemetadata-questions/service-instructioncreation-metadata/serviceinstructioncreation.component';
import { ServiceBroadcastClearanceAdministrationQComponent } from './components/orders/order-servicemetadata-questions/service-broadcastclearanceadministration-metadata/servicebroadcastclearanceadministration.component';
import { ServiceAdministrativeReportingQComponent } from './components/orders/order-servicemetadata-questions/service-administrativereporting-metadata/serviceadministrativereporting.component';
import { ServiceVaultAccessQComponent } from './components/orders/order-servicemetadata-questions/service-vaultaccess-metadata/servicevaultaccess.component';
import { ServiceAccountManagerAdjustmentQComponent } from './components/orders/order-servicemetadata-questions/service-accountmanageradjustment-metadata/serviceaccountmanageradjustment.component';
import { ServiceDigitalTransferQComponent } from './components/orders/order-servicemetadata-questions/service-digitaltransfer-metadata/serviceadigitaltransfer.component';
import { ServiceTrafficExpressQComponent } from './components/orders/order-servicemetadata-questions/service-trafficexpress-metadata/servicetrafficexpress.component';
import { ServiceSpotExpirationNotifQComponent } from './components/orders/order-servicemetadata-questions/service-spotexpirationnotif-metadata/servicespotexpirationnotif.component';
import { ServiceHardDriveFormattingQComponent } from './components/orders/order-servicemetadata-questions/service-harddriveformatting-metadata/serviceharddriveformatting.component';
import { ServiceCaptioningReApplicationQComponent } from './components/orders/order-servicemetadata-questions/service-captioningreapplication-metadata/servicecaptioningreapplication.component';
import { ServiceDescriptiveVideoReApplicationQComponent } from './components/orders/order-servicemetadata-questions/service-descriptivevideoservicereapplication-metadata/servicedescriptivevideoreapplication.component';
import { ServiceTargaImagesQComponent } from './components/orders/order-servicemetadata-questions/service-targaimages-metadata/servicetargeimages.component';
import { ServiceBillBoardQComponent } from './components/orders/order-servicemetadata-questions/service-billboard-metadata/servicebillboard.component';
import { ServiceAudioTranscodingQComponent } from './components/orders/order-servicemetadata-questions/service-audiotranscoding-metadata/serviceaudiotranscoding.component';
import { ServiceBroadcastClearanceUsQComponent } from './components/orders/order-servicemetadata-questions/service-broadcastclearanceus-metadata/servicebroadcastclearanceus.component';
import { ServiceDVDQComponent } from './components/orders/order-servicemetadata-questions/service-dvd-metadata/servicedvd.component';
import { ServiceExpHandlingQComponent } from './components/orders/order-servicemetadata-questions/service-exphandling-metadata/serviceexphandling.component';
import { ServiceInternationalCaptioningQComponent } from './components/orders/order-servicemetadata-questions/service-internationalcaptioning-metadata/serviceinternationalcaptioning.component';
import { ServiceInternationalTranscribeScriptQComponent } from './components/orders/order-servicemetadata-questions/service-internationaltranscribescript-metadata/serviceinternationaltranscribescript.component';
import { ServiceDDIComponent } from './components/orders/order-servicemetadata-questions/service-ddi-metadata/serviceddi.component';
import { ServiceTrafficManagementFeeQComponent } from './components/orders/order-servicemetadata-questions/service-trafficmanagementfee-metadata/servicetrafficmanagementfee.component';
import { ServiceClearanceAdviceSupportQComponent } from './components/orders/order-servicemetadata-questions/service-clearanceadvicesupport-metadata/serviceclearanceadvicesupport.component';
import { ServiceCinemaClearanceQComponent } from './components/orders/order-servicemetadata-questions/service-cinemaclearance-metadata/servicecinemaclearance.component';
import { ServiceStandard3rdPartyFeeQComponent } from './components/orders/order-servicemetadata-questions/service-standard3rdpartyfee-metadata/servicestandard3rdpartyfee.component';

// Admin
import { ClientComponent } from "./components/admin/client/client.component";
import { CreateClientComponent } from "./components/admin/client/create-client/create-client.component";
import { BrandSearchComponent } from "./components/popups/brandsearch/brandsearch.component";
import { SearchClientsComponent } from "./components/admin/client/search-clients/search-clients.component";
import { SearchBrandsComponent } from "./components/admin/brand/search-brands/search-brands.component";
import { BrandComponent } from "./components/admin/brand/brand.component";
import { ViewEditBrandComponent } from "./components/admin/brand/viewedit-brand/viewedit-brand.component";
import { CreateBrandComponent } from "./components/admin/brand/create-brand/create-brand.component";
import { SelectedProductsComponent } from "./components/admin/brand/selected-products/selected-products.component";
import { SelectedBrandsComponent } from "./components/admin/client/selectedbrands/selected-brands.component";
import { ClientDetailComponent } from "./components/admin/client/clientdetail/client-detail.component";
import { ClientViewComponent } from "./components/admin/client/clientdetail/clientview/client-view.component";
import { ClientEditComponent } from "./components/admin/client/clientdetail/clientedit/client-edit.component";
import { AddPostHouseComponent } from "./components/admin/client/clientdetail/clientview/addposthouse/add-posthouse.component";
import { AddNewPostHouseComponent } from "./components/admin/client/clientdetail/clientview/addposthouse/addnewposthouse/add-newposthouse.component";
import { UserComponent } from "./components/admin/user/user.component";
import { SearchUsersComponent } from "./components/admin/user/search-users/search-users.component";
import { CreateUserComponent } from "./components/admin/user/create-user/create-user.component";
import { AddRolesNotificationsComponent } from "./components/admin/user/create-user/addroles-notifications/addroles-notifications.component";
import { UserViewComponent } from "./components/admin/user/user-detail/user-view/user-view.component";
import { UserDetailComponent } from "./components/admin/user/user-detail/user-detail.component";
import { UserEditComponent } from "./components/admin/user/user-detail/user-edit/user-edit.component";
import { EditUserRolesComponent } from "./components/admin/user/user-detail/user-edit/edit-user-roles/edit-user-roles.component";
import { DestinationComponent } from "./components/admin/destination/destination.component";
import { SearchDestinationsComponent } from "./components/admin/destination/search-destinations/search-destinations.component";
import { CreateDestinationComponent } from "./components/admin/destination/create-destination/create-destination.component";
import { SelectNetworkComponent } from "./components/admin/destination/create-destination/select-network/select-network.component";
import { DestinationContactComponent } from "./components/admin/destination/create-destination/destination-contact/destination-contact.component";
import { DestinationViewComponent } from "./components/admin/destination/destination-detail/destination-view/destination-view.component";
import { DestinationDetailComponent } from "./components/admin/destination/destination-detail/destination-detail.component";
import { RegistationRequestsComponent } from './components/admin/user/tmc-registration-requests/tmc-registration-requests.component';

// settings,login,MISC
import { NavHeaderComponent } from './components/navmenu/navheader/navheader.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AccountSettingsComponent } from './components/account/settings/accountsettings.component';
import { ForgotPasswordComponent } from "./components/account/forgotpassword/forgotpassword.component";
import { NotificationComponent } from "./components/notifications/notification.component";
import { UserDistributionGroupComponent } from "./components/account/settings/userdistributiongroups/userdistributiongroup.component";
import { NotFoundComponent } from './components/notfound/notfound.component';
import { CreateNewPasswordComponent } from './components/account/createpassword/createnewpassword.component';
import { ChangePasswordComponent } from './components/account/createpassword/changepassword/changepassword.component';
import { OrderDebugComponent } from './components/orders/order-debug/orderdebug.component';
import { ITOpsComponent } from './components/itOps/itOps.component';
import { ProfileSelectionComponent } from './components/profileselection/profileselection.component';

// distribution
import { DistributionMediaDownloadComponent } from './components/distribution/distributionmediadownload/distributionmediadownload.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { DistributionSearchComponent } from './components/distribution/distributionsearch/distributionsearch.component';


// popups
import { CopyOrderOptionsPopupComponent } from './components/popups/copyorderoptionspopup/copyorderoptionspopup.component';
import { AddToPlayListComponent } from './components/popups/addtoplaylist/addtoplaylist.component';
import { ShareMediaPopupComponent } from "./components/popups/sharemediapopup/sharemediapopup.component";
import { ErrorPopupComponent } from "./components/popups/errorpopup/errorpopup.component";
import { InfoPopupComponent } from "./components/popups/infopopup/infopopup.component";
import { ConfirmPopupComponent } from "./components/popups/confirmationpopups/confirmpopup.component";
import { ExpirePopupComponent } from "./components/popups/expire/expirepopup.component";
import { SpotNotificationPopupComponent } from "./components/popups/spotnotificationspopup/spotnotificationspopup.component";
import { OrderAddSpotPopupComponent } from './components/popups/orderaddspotpopup/orderaddspotpopup.component';
import { OrderAddShareLinksPopupComponent } from './components/popups/orderaddsharelinkspopup/orderaddsharelinkspopup.component';
import { OrderAddFTPDestinationPopupComponent } from './components/popups/orderaddftpdestinationpopup/orderaddftpdestinationpopup.component';
import { OrderAddDestinationListPopupComponent } from './components/popups/orderadddestinationlistpopup/orderadddestinationlistpopup.component';
import { DestinationSearchPopupComponent } from './components/popups/destinationsearchpopup/destinationsearchpopup.component';
import { OrderFinalizePopupComponent } from './components/popups/orderfinalizepopup/orderfinalizepopup.component';
import { OrderConfirmationPopupComponent } from './components/popups/orderconfirmationpoup/orderconfirmationpopup.component';
import { SelectAttachmentCategoryPopupComponent } from './components/popups/selectattachmentcategorypopup/selectattachmentcategorypopup.component';
import { DeletePlaceholderPopupComponent } from './components/popups/deleteplaceholderpopup/deleteplaceholderpopup.component';
import { OrderAddServicePopupComponent } from './components/popups/orderaddservicepopup/orderaddservicepopup.component';
import { OrderDetailHeaderEditPopupComponent } from './components/popups/orderdetailheadereditpopup/orderdetailheadereditpopup.component';
import { OrderEditFTPDestinationPopupComponent } from "./components/popups/ordereditftpdestinationpopup/ordereditftpdestinationpopup.component";
import { OrderEditNetworkDestinationPopupComponent } from "./components/popups/ordereditnetworkdestinationpopup/ordereditnetworkdestinationpopup.component";
import { OrderStatusConfirmpopup } from './components/popups/orderstatusconfirmpopup/orderstatusconfirmpopup.component';
import { EditHistoryPopupComponent } from './components/popups/edithistorypopup/edithistorypopup.component';
import { SpotResendPopupComponent } from './components/popups/spotresendpopup/spotresendpopup.component';
import { AssignVendorComponent } from "./components/popups/ordereditnetworkdestinationpopup/assignvendor/assignvendor.component";
import { OrderGroupServiceVendorPORequestComponent } from "./components/popups/vendorpurchaseorder/ordergroupservicevendorporequest/ordergroupservicevendorporequest.component";
import { VendorPurchaseOrderPopupComponent } from "./components/popups/vendorpurchaseorder/vendorpurchaseorderpopup.component";
import { OrderLvlServiceVendorPORequestComponent } from "./components/popups/vendorpurchaseorder/orderlvlservicevendorporequest/orderlvlservicevendorporequest.component";
import { VendorPurchaseOrderConfirmationPopupComponent } from "./components/popups/vendorpurchaseorderconfirmationpopup/vendorpurchaseorderconfirmationpopup.component";
import { VendorPOConfirmationComponent } from "./components/popups/vendorpurchaseorderconfirmationpopup/vendorpoconfirmation/vendorpoconfirmation.component";
import { UpdatePODComponent } from "./components/popups/ordereditnetworkdestinationpopup/updatepod/updatepod.component";
import { UpdateVendorOrderNumberComponent } from "./components/popups/ordereditnetworkdestinationpopup/updatevendorordernumber/updatevendorordernumber.component";
import { AddCostCenterAndBillToPopUp } from "./components/popups/addcostcenterandbilltopopup/addcostcenterandbilltopopup.component";
import { AddClientBrandVendorInfoPopUp } from "./components/popups/addclientbrandvendorinfopopup/addclientbrandvendorinfopopup.component";
import { AddClientBrandProductPopUp } from "./components/popups/addclientbrandproductpopup/addclientbrandproductpopup.component";
import { ClientBrandProductGroup } from "./components/popups/addclientbrandproductpopup/clientbrandproductgroup/clientbrandproductgroup.component";
import { OrderInvalidDataPopupComponent } from './components/popups/orderinvaliddatapopup/orderinvaliddatapopup.component';
import { PostHouseAddProductPopUp } from "./components/popups/posthouseaddproduct/posthouseaddproductpopup.component";
import { AdminDefaultHubDestinationSearchComponent } from "./components/popups/admindefaulthubdestinationsearch/admindefaulthubdestinationsearchpopup.component";
import { AdminVendorDestinationMappingComponent } from "./components/popups/adminvendordestinationmapping/adminvendordestinationmappingpopup.component";
import { SpotOrderHistoryPopupComponent } from './components/popups/spotorderhistorypopup/spotorderhistorypopup.component';
import { UnSavedDataWarningComponent } from './components/popups/unsaveddatapopup/unsaveddatapopup.component';
import { QuickLinkPopUp } from './components/popups/quicklinkpopup/quicklinkpopup.component';
import { SessionExpiryWarningPopupComponent } from './components/popups/sessionexpirywarningpopup/sessionexpirywarningpopup.component';
import { BillingItemsPopupComponent } from './components/popups/billingitemspopup/billingitemspopup.component';
import { AddCustomTagConfigurationPopUp } from './components/popups/addcustomtagconfiguration/addcustomtagconfiguration.component';
import { CustomTagConfigGroup } from './components/popups/addcustomtagconfiguration/customtagconfiggroup/customtagconfiggroup.component';
import { AddEditMediaCustomTagPopUp } from './components/popups/addeditmediacustomtagpopup/addeditmediacustomtagpopup.component';
import { AddEditBundleServicesPopUp } from './components/popups/addeditbundleservices/addeditbundleservices.component';
import { BundleServiceGroup } from './components/popups/addeditbundleservices/bundleservicegroup/bundleservicegroup.component';
import { BasePopupComponent } from './components/popups/basepopup/basepopup.component';
import { ResendwithAssignVendorComponent } from './components/popups/ordereditnetworkdestinationpopup/resendwithassignvendor/resendwithassignvendor.component';
import { CustomTagControlsComponent } from './components/popups/addeditmediacustomtagpopup/customtagcontrols/customtagcontrols.component';
import { MediaDetailPopupComponent } from './components/popups/mediadetailpopup/mediadetailpopup.component';
import { LayoverPopupComponent } from './components/popups/layoverpopup/layoverpopup.component';
import { PurgeMediaPopupComponent } from './components/popups/purgemediapopup/purgemediapopup.component';
import { AddPODRecipientsPopUp } from './components/popups/addpodrecipients/addpodrecipients.component';
import { AddOrderedByPopUp } from './components/popups/addorderedby/addorderedby.component';
import { UserSelectionPopupComponent } from './components/popups/userselectionpopup/userselectionpopup.component';
import { UpdateDistributionMediaPopUp } from './components/popups/updatedistributionmediapopup/updatedistributionmediapopup.component';
import { OrderCommentsHistoryPopupComponent } from './components/popups/ordercommentshistorypopup/ordercommentshistorypopup.component';
import { AddFileDeliveryConfigurationPopUp } from './components/popups/addfiledeliveryconfiguration/addfiledeliveryconfiguration.component';
import { FileDeliveryConfigGroup } from './components/popups/addfiledeliveryconfiguration/filedeliveryconfiggroup/filedeliveryconfiggroup.component';
import { OrderDestinationInfoPopupComponent } from './components/popups/orderadestinationinfopopup/orderadestinationinfopopup.component';
import { MoveFilesBetweenFoldersProjectPopUp } from './components/popups/movefilesbetweenfoldersprojectpopup/movefilesbetweenfoldersprojectpopup.component';
import { AddTranscodeBillingSettingsPopUp } from './components/popups/addtranscodebillingsettings/addtranscodebillingsettings.component';
import { AddManagementFeeSettingsettingsPopUp } from './components/popups/addmanagementfeesettings/addmanagementfeesettings.component';
import { AddStorageManagementFeeSettingsettingsPopUp } from './components/popups/addstoragemanagementfeesettings/addstoragemanagementfeesettings.component';
import { TranscodeOrderCopyUploadAssetsPopupComponent } from './components/popups/transcodeordercopyuploadassetspopup/transcodeordercopyuploadassetspopup.component';

// Directives
import { SecuredDirective } from './directives/secured';
import { SecuredExcludeDirective } from "./directives/secured-exclude";
import { notificationPrefDirective } from "./directives/notificationPref";
import { IsAuthorizedDirective } from './directives/isAuthorized';

// Custom pipes
import { UserTimeZone } from './pipes/usertimezone.pipe';
import { SafeHtml } from './pipes/safeHtml.pipe';
import { TimecodeFormat } from './pipes/timecodeformat.pipe';
import { TruncateByWords } from './pipes/truncateByWords.pipe';
import { TruncateByLetters } from './pipes/truncateByLetters.pipe';
import { UserTimeZoneToUtc } from './pipes/usertimezoneToUtc.pipe';
import { JoinBySeperatorPipe } from "./pipes/joinBySeperatorPipe";
import { ConvertFromUTC } from './pipes/convertFromUTC.pipe';

// Services

// Core Services
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './services/auth/auth-guard.service';
import { AppErrorHandler } from './app-error.handler';
import { CustomReuseStrategy } from './app-reuse.strategy';
import { AlertService } from './services/core/alert.service';
import { Utilities } from "./services/core/utilities";
import { LocalStoreManager } from './services/core/local-store-manager.service';
import { LoginGuard } from "./services/auth/login-guard.service";

// Utility and config Services
import { ApiEndPoint } from './configurations/api-endpoint.service';
import { UserRoles } from './configurations/user-roles';
import { EndpointFactory } from './configurations/endpoint-factory.service';

//Other Services
import { UserRolesNotification } from "./configurations/user-roles-notification";
import { UserDataService } from './services/user/user-data.service';
import { UserProfileService } from './services/user/user-profile.service';
import { DatGridService } from "./services/media/datagrid.service";
import { ShareMediaService } from "./services/playlist/sharemedia.service";
import { UploadService } from "./services/upload/upload.service";
import { AccountSettingsService } from "./services/user/account-settings.service";
import { ForgotPasswordSettingsService } from "./services/user/forgotpassword-settings.service";
import { SpotService } from './services/media/spot.service';
import { PlayListService } from './services/playlist/playlist.service';
import { UserMgmtService } from './services/user/user-mgmt.service';
import { MediaService } from './services/media/media.service';
import { MediaDetailService } from './services/mediadetail/mediadetail.service';
import { SubService } from "./services/media/sub.service";
import { NotificationService } from "./services/notifications/notification.service";
import { OrderService } from "./services/order/order.service";
import { VaultService } from "./services/vault/vault.service";
import { TandemGridService } from "./services/grid/tandem-grid.service";
import { ConfigService } from './services/core/config.service';
import { ReportService } from "./services/report/report.service";
import { EditDestinationComponent } from "./components/admin/destination/destination-detail/destination-edit/edit-destination.component";
import { AdminService } from "./services/admin/admin.service";
import { UploadOrderFileService } from './services/order/uploadorderfile.service';
import { CanDeactivateCreateOrder, CanDeactivateCreateVault, CanDeactivateOrderTranscodes } from './services/core/caneactivateuard.service';
import { CanDeactivateOrderDetail } from './services/core/caneactivateuard.service';
import { CustomInterceptor } from './services/core/requestinterceptor.service';
import { GlobalService } from './services/core/global.service';
import { DownloadMediaService } from './services/media/media-download.service';
import { DistributionService } from './services/distribution/distribution.service';
import { AdminDestinationSettingsPopupComponent } from './components/popups/admindestinationsettingspopup/admindestinationsettingspopup.component';
import { AddEditSpecDetailsPopUp } from './components/popups/addeditspecdetailspopup/addeditspecdetailspopup.component';
import { TMCDownloadComponent } from './components/distribution/tmcdownload/tmcdownload.component';
import { ServiceBroadcastClearanceIntlComponent } from './components/orders/order-servicemetadata-questions/service-broadcastclearanceintl-metadata/servicebroadcastclearanceintl-metadata.component';
import { ServiceInternationalTrafficInstructionQComponent } from './components/orders/order-servicemetadata-questions/service-internationaltrafficinstructions-metadata/serviceinternationaltrafficinstructions.component';
import { ServiceDDAIComponent } from './components/orders/order-servicemetadata-questions/service-ddai-metadata/serviceddai.component';

//Project
import { ProjectService } from './services/project/create-project.service';
import { SinglePartFileUploadService } from './services/project/singlepart-file-upload-handler.service';
import { ProjectFolderService } from './services/project/create-folder.service';
import { ArchiveProjectService } from './services/project/archive-project.service';
import { AddUserPermissionToProjectService } from './services/project/add-user-permission.service';

// temp
import { ProjectSearchComponent } from './components/project/projectsearch/projectsearch.component';
import { ProjectComponent } from './components/project/project.component';
import { CreateNewProjectPopUp } from './components/popups/createnewprojectpopup/createnewprojectpopup.component';
import { CreateNewProjectFolderPopUp } from './components/popups/createnewprojectfolderpopup/createnewprojectfolderpopup.component';
import { AddFilesToProjectPopUp } from './components/popups/addfilestoprojectpopup/addfilestoprojectpopup.component';

// transacode assets
import { TranscodeAssetsComponent } from './components/transcodeassets/transcodeassets.component';
import { MediaTranscodedAssetsComponent } from './components/transcodeassets/mediawithtranscodedassets/mediawithtranscodedassets.component';
import { TranscodeUserListsComponent } from './components/transcodeassets/transcodeuserlists/transcodeuserlists.component';
import { OrderTranscodesComponent } from './components/transcodeassets/ordertranscodes/ordertranscodes.component';
import { TranscodeListPopupComponent } from './components/popups/transcodelistpopup/transcodelistpopup.component';
import { TranscodeSearchMediaComponent } from './components/transcodeassets/ordertranscodes/transcode-searchmedia-step/transcode-searchmedia-step.component';
import { TranscodeFilenameComponent } from './components/transcodeassets/ordertranscodes/transcode-filename-step/transcode-filename-step.component';
import { AddUserPermissionToProjectPopUp } from './components/popups/adduserpermissionpopup/adduserpermissionpopup.component';
import { TranscodeSpecSelectionComponent } from './components/transcodeassets/ordertranscodes/transcode-specselection-step/transcode-specselection-step.component';
import { ShareTranscodeFilePopupComponent } from './components/popups/sharetranscodefilepopup/sharetranscodefilepopup.component';
import { ProjectPromoteComponent } from './components/popups/project-promote/project-promote.component';
import { PromoteProjectFileService } from './services/project/promote-project-file.service';
import { ManageProjectUsersComponent } from './components/popups/manage-project-users/manage-project-users.component';

// vault
import { VaultCreateComponent } from './components/vault/vault-create/vault-create.component';
import { SelectattachmentvaultpopupComponent } from './components/popups/selectattachmentvaultpopup/selectattachmentvaultpopup.component';
import { VaultComponent } from './components/vault/vault.component';
import { VaultSearchComponent } from './components/vault/vault-search/vault-search.component';
import { VaultAssetDetailComponent } from './components/vault/vault-asset-detail/vault-asset-detail.component';
import { VaultRequestDetailComponent } from './components/vault/vault-request-detail/vault-request-detail.component';
import { VaultRequestCartonComponent } from './components/vault/vault-request-detail/vault-request-cartons/vault-request-cartons.component';
import { VaultDetailHeaderEditPopupComponent } from './components/popups/vaultdetailheadereditpopup/vaultdetailheadereditpopup.component';
import { VaultAssetPopupComponent } from './components/popups/vault-asset-popup/vault-asset-popup.component';
import { AddVaultAssetNoteComponent } from './components/popups/add-vault-asset-note/add-vault-asset-note.component';
import { AddVaultAssetAdditionServicePopupComponent } from './components/popups/add-vault-asset-addition-service-popup/add-vault-asset-addition-service-popup.component';
import { AddVaultAssetAttachmentPopupComponent } from './components/popups/add-vault-asset-attachment-popup/add-vault-asset-attachment-popup.component';
import { VaultassetsremovedestroypopupComponent } from './components/popups/vaultassetsremovedestroypopup/vaultassetsremovedestroypopup.component';
import { VaultConfirmationPopupComponent } from './components/popups/vaultconfirmationpopup/vaultconfirmationpopup.component';

//destination info questions
import { BaseDestinationInfoComponent } from './components/orders/dest-info-questions/dest-baseinfo/destinationbaseinfo.component';
import { DestinationInnovidInfoComponent } from './components/orders/dest-info-questions/dest-innovid-info/destinationinnovidinfo.component';
import { RightsManagementService } from './services/media/rightsmanagement.service';
import { environment } from '../environments/environment';
import { TextMaskModule } from 'angular2-text-mask';
import { AdmintranscodepreferencessettingsComponent } from './components/popups/admintranscodepreferencessettings/admintranscodepreferencessettings.component';
import { IconSendComponent } from './components/shared/icons/icon-send/icon-send.component';
import { IconSearchComponent } from './components/shared/icons/icon-search/icon-search.component';
import { IconArrowCircleLeftComponent } from './components/shared/icons/icon-arrow-circle-left/icon-arrow-circle-left.component';
import { IconDownloadComponent } from './components/shared/icons/icon-download/icon-download.component';
import { IconEnvelopeShareComponent } from './components/shared/icons/icon-envelope-share/icon-envelope-share.component';
import { IconNavProjectsComponent } from './components/shared/icons/icon-nav-projects/icon-nav-projects.component';
import { IconNavAssetsComponent } from './components/shared/icons/icon-nav-assets/icon-nav-assets.component';
import { IconNavOrdersComponent } from './components/shared/icons/icon-nav-orders/icon-nav-orders.component';
import { IconNavNotificationsComponent } from './components/shared/icons/icon-nav-notifications/icon-nav-notifications.component';
import { IconNavReportsComponent } from './components/shared/icons/icon-nav-reports/icon-nav-reports.component';
import { IconNavVaultComponent } from './components/shared/icons/icon-nav-vault/icon-nav-vault.component';
import { IconNavAdminComponent } from './components/shared/icons/icon-nav-admin/icon-nav-admin.component';
import { IconNavOpsComponent } from './components/shared/icons/icon-nav-ops/icon-nav-ops.component';
import { IconNavDistributionComponent } from './components/shared/icons/icon-nav-distribution/icon-nav-distribution.component';
import { IconQuestionMarkComponent } from './components/shared/icons/icon-question-mark/icon-question-mark.component';
import { IconArrowSimpleRightComponent } from './components/shared/icons/icon-arrow-simple-right/icon-arrow-simple-right.component';
import { IconArrowSimpleLeftComponent } from './components/shared/icons/icon-arrow-simple-left/icon-arrow-simple-left.component';
import { IconSettingsComponent } from './components/shared/icons/icon-settings/icon-settings.component';
import { IconLogoutComponent } from './components/shared/icons/icon-logout/icon-logout.component';
import { IconPlusCircleComponent } from './components/shared/icons/icon-plus-circle/icon-plus-circle.component';
import { IconPlaylistAddComponent } from './components/shared/icons/icon-playlist-add/icon-playlist-add.component';
import { IconDeleteComponent } from './components/shared/icons/icon-delete/icon-delete.component';
import { IconRefreshComponent } from './components/shared/icons/icon-refresh/icon-refresh.component';
import { IconExportComponent } from './components/shared/icons/icon-export/icon-export.component';
import { IconExpireComponent } from './components/shared/icons/icon-expire/icon-expire.component';
import { IconArrowCircleRightComponent } from './components/shared/icons/icon-arrow-circle-right/icon-arrow-circle-right.component';
import { IconArrowSimpleDownComponent } from './components/shared/icons/icon-arrow-simple-down/icon-arrow-simple-down.component';
import { IconArrowSimpleUpComponent } from './components/shared/icons/icon-arrow-simple-up/icon-arrow-simple-up.component';
import { IconPlayComponent } from './components/shared/icons/icon-play/icon-play.component';
import { IconPlayV2Component } from './components/shared/icons/icon-play-v2/icon-play-v2.component';
import { IconCircleTrashComponent } from './components/shared/icons/icon-circle-trash/icon-circle-trash.component';
import { IconArchiveComponent } from './components/shared/icons/icon-archive/icon-archive.component';
import { IconAudioComponent } from './components/shared/icons/icon-audio/icon-audio.component';
import { IconBrowseComponent } from './components/shared/icons/icon-browse/icon-browse.component';
import { IconCalendarComponent } from './components/shared/icons/icon-calendar/icon-calendar.component';
import { IconCheckmarkComponent } from './components/shared/icons/icon-checkmark/icon-checkmark.component';
import { IconCompanyComponent } from './components/shared/icons/icon-company/icon-company.component';
import { IconCopyComponent } from './components/shared/icons/icon-copy/icon-copy.component';
import { IconDashboardComponent } from './components/shared/icons/icon-dashboard/icon-dashboard.component';
import { IconEditHistoryComponent } from './components/shared/icons/icon-edit-history/icon-edit-history.component';
import { IconEditPencilComponent } from './components/shared/icons/icon-edit-pencil/icon-edit-pencil.component';
import { IconEditErrorComponent } from './components/shared/icons/icon-edit-error/icon-edit-error.component';
import { IconEstimateCalculateComponent } from './components/shared/icons/icon-estimate-calculate/icon-estimate-calculate.component';
import { IconEyeComponent } from './components/shared/icons/icon-eye/icon-eye.component';
import { IconFileComponent } from './components/shared/icons/icon-file/icon-file.component';
import { IconFolderComponent } from './components/shared/icons/icon-folder/icon-folder.component';
import { IconFolderAddComponent } from './components/shared/icons/icon-folder-add/icon-folder-add.component';
import { IconFolderMoveComponent } from './components/shared/icons/icon-folder-move/icon-folder-move.component';
import { IconMediaComponent } from './components/shared/icons/icon-media/icon-media.component';
import { IconMediatypeFourKComponent } from './components/shared/icons/icon-mediatype-four-k/icon-mediatype-four-k.component';
import { IconMediatypeFiveOneComponent } from './components/shared/icons/icon-mediatype-five-one/icon-mediatype-five-one.component';
import { IconMediatypeAudioComponent } from './components/shared/icons/icon-mediatype-audio/icon-mediatype-audio.component';
import { IconMediatypeBroadcastComponent } from './components/shared/icons/icon-mediatype-broadcast/icon-mediatype-broadcast.component';
import { IconMediatypeBvsComponent } from './components/shared/icons/icon-mediatype-bvs/icon-mediatype-bvs.component';
import { IconMediatypeCcpComponent } from './components/shared/icons/icon-mediatype-ccp/icon-mediatype-ccp.component';
import { IconMediatypeClosedCaptionComponent } from './components/shared/icons/icon-mediatype-closed-caption/icon-mediatype-closed-caption.component';
import { IconMediatypeDvsComponent } from './components/shared/icons/icon-mediatype-dvs/icon-mediatype-dvs.component';
import { IconMediatypeGenericComponent } from './components/shared/icons/icon-mediatype-generic/icon-mediatype-generic.component';
import { IconMediatypeHdComponent } from './components/shared/icons/icon-mediatype-hd/icon-mediatype-hd.component';
import { IconMediatypeRunningFootageComponent } from './components/shared/icons/icon-mediatype-running-footage/icon-mediatype-running-footage.component';
import { IconMediatypeRunningFootageV2Component } from './components/shared/icons/icon-mediatype-running-footage-v2/icon-mediatype-running-footage-v2.component';
import { IconMediatypeWebComponent } from './components/shared/icons/icon-mediatype-web/icon-mediatype-web.component';
import { IconMediatypeVeilComponent } from './components/shared/icons/icon-mediatype-veil/icon-mediatype-veil.component';
import { IconMediatypeSdComponent } from './components/shared/icons/icon-mediatype-sd/icon-mediatype-sd.component';
import { IconMediatypeStComponent } from './components/shared/icons/icon-mediatype-st/icon-mediatype-st.component';
import { IconMediatypeStereoComponent } from './components/shared/icons/icon-mediatype-stereo/icon-mediatype-stereo.component';
import { IconMediatypeSubtitlesComponent } from './components/shared/icons/icon-mediatype-subtitles/icon-mediatype-subtitles.component';
import { IconMediatypeTaggableComponent } from './components/shared/icons/icon-mediatype-taggable/icon-mediatype-taggable.component';
import { IconMediatypeUnknownComponent } from './components/shared/icons/icon-mediatype-unknown/icon-mediatype-unknown.component';
import { IconMoveComponent } from './components/shared/icons/icon-move/icon-move.component';
import { IconNextArrowComponent } from './components/shared/icons/icon-next-arrow/icon-next-arrow.component';
import { IconNotificationBellComponent } from './components/shared/icons/icon-notification-bell/icon-notification-bell.component';
import { IconOpenEnvelopeComponent } from './components/shared/icons/icon-open-envelope/icon-open-envelope.component';
import { IconOrderReviewFailComponent } from './components/shared/icons/icon-order-review-fail/icon-order-review-fail.component';
import { IconOrdersShoppingCartComponent } from './components/shared/icons/icon-orders-shopping-cart/icon-orders-shopping-cart.component';
import { IconOrdersTylieLogoMarkComponent } from './components/shared/icons/icon-orders-tylie-logo-mark/icon-orders-tylie-logo-mark.component';
import { IconOrdersTylieMarkComponent } from './components/shared/icons/icon-orders-tylie-mark/icon-orders-tylie-mark.component';
import { IconProjectComponent } from './components/shared/icons/icon-project/icon-project.component';
import { IconQuickOrderComponent } from './components/shared/icons/icon-quick-order/icon-quick-order.component';
import { IconQuickOrderV2Component } from './components/shared/icons/icon-quick-order-v2/icon-quick-order-v2.component';
import { IconReportsComponent } from './components/shared/icons/icon-reports/icon-reports.component';
import { IconReviewComponent } from './components/shared/icons/icon-review/icon-review.component';
import { IconRushComponent } from './components/shared/icons/icon-rush/icon-rush.component';
import { IconSaveComponent } from './components/shared/icons/icon-save/icon-save.component';
import { IconTranscodeOrderComponent } from './components/shared/icons/icon-transcode-order/icon-transcode-order.component';
import { IconUploadComponent } from './components/shared/icons/icon-upload/icon-upload.component';
import { IconUserComponent } from './components/shared/icons/icon-user/icon-user.component';
import { IconVaultComponent } from './components/shared/icons/icon-vault/icon-vault.component';
import { IconVaultLoginComponent } from './components/shared/icons/icon-vault-login/icon-vault-login.component';
import { IconVaultLogoutComponent } from './components/shared/icons/icon-vault-logout/icon-vault-logout.component';
import { IconVaultStaggingComponent } from './components/shared/icons/icon-vault-stagging/icon-vault-stagging.component';
import { IconVideoComponent } from './components/shared/icons/icon-video/icon-video.component';
import { IconCircleArrowUpComponent } from './components/shared/icons/icon-circle-arrow-up/icon-circle-arrow-up.component';
import { IconStarComponent } from './components/shared/icons/icon-star/icon-star.component';
import { IconTrashCanComponent } from './components/shared/icons/icon-trash-can/icon-trash-can.component';
import { IconPurgeComponent } from './components/shared/icons/icon-purge/icon-purge.component';
import { IconRestoreComponent } from './components/shared/icons/icon-restore/icon-restore.component';
import { IconDetailsViewComponent } from './components/shared/icons/icon-details-view/icon-details-view.component';
import { IconFlagComponent } from './components/shared/icons/icon-flag/icon-flag.component';
import { IconTraceComponent } from './components/shared/icons/icon-trace/icon-trace.component';
import { IconClaimComponent } from './components/shared/icons/icon-claim/icon-claim.component';

// Tagger
import { TaggerHomeComponent } from './components/tagger-home/tagger-home.component';
import { TaggerSearchComponent } from './components/tagger-home/tagger-search/tagger-search.component';
import { TaggerFormComponent } from './components/tagger-home/tagger-form/tagger-form.component';
import { TaggerCreativeStepComponent } from './components/tagger-home/tagger-form/tagger-creative-step/tagger-creative-step.component';
import { TaggerOfferCardStepComponent } from './components/tagger-home/tagger-form/tagger-offer-card-step/tagger-offer-card-step.component';
import { TaggerEndCardStepComponent } from './components/tagger-home/tagger-form/tagger-end-card-step/tagger-end-card-step.component';
import { TaggerReviewStepComponent } from './components/tagger-home/tagger-form/tagger-review-step/tagger-review-step.component';
import { TaggerEndCardVoiceOverStepComponent } from './components/tagger-home/tagger-form/tagger-end-card-voice-over-step/tagger-end-card-voice-over-step.component';
import { TaggerDraftSearchComponent } from './components/tagger-home/tagger-draft-search/tagger-draft-search.component';
import { TaggerDraftorderAddnotepopupComponent } from './components/popups/tagger-draftorder-addnotepopup/tagger-draftorder-addnotepopup.component';
import { TaggerOrderNotepopupComponent } from './components/popups/tagger-order-notepopup/tagger-order-notepopup.component';
import { ShareTaggerAdPopupComponent } from './components/popups/share-tagger-ad-popup/share-tagger-ad-popup.component';
import { ViewTaggerOrderAdpopupComponent } from './components/popups/view-tagger-order-adpopup/view-tagger-order-adpopup.component';
import { TaggerArchivedSearchComponent } from './components/tagger-home/tagger-archived-search/tagger-archived-search.component';
import { CreativeTagsComponent } from './components/tagger-home/creative-tags/creative-tags.component';
import { TaggerAssetComponent } from './components/tagger-home/tagger-asset/tagger-asset.component';
import { AdminAssetUploadPopUpComponent } from './components/popups/admin-asset-upload-pop-up/admin-asset-upload-pop-up.component';
import { AdminAssetUploadFormComponent } from './components/popups/admin-asset-upload-pop-up/admin-asset-upload-form/admin-asset-upload-form.component';
import { GroupsComponent } from './components/tagger-home/groups/groups.component';
import { AddGroupsLocationsPopUpComponent } from './components/popups/add-groups-locations-popup/add-groups-locations-pop-up.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { IconNavTaggerComponent } from './components/shared/icons/icon-nav-tagger/icon-nav-tagger.component';
import { IconTabTaggedAdComponent } from './components/shared/icons/icon-tab-tagged-ad/icon-tab-tagged-ad.component';
import { IconTabDraftAdComponent } from './components/shared/icons/icon-tab-draft-ad/icon-tab-draft-ad.component';
import { IconTabArchiveAdComponent } from './components/shared/icons/icon-tab-archive-ad/icon-tab-archive-ad.component';
import { IconCheckmarkGreenComponent } from './components/shared/icons/icon-checkmark-green/icon-checkmark-green.component';
import { IconCheckmarkWhiteComponent } from './components/shared/icons/icon-checkmark-white/icon-checkmark-white.component';
import { LocationsAndUsersComponent } from './components/tagger-home/locations-and-users/locations-and-users.component';
import { TaggerHeaderComponent } from './components/tagger-home/tagger-header/tagger-header.component';
import { IconLocationComponent } from './components/shared/icons/icon-location/icon-location.component';
import { AddLocationsPopupComponent } from './components/popups/add-locations-popup/add-locations-popup.component';
import { AddTaggerUserPopupComponent } from './components/popups/add-tagger-user-popup/add-tagger-user-popup.component';
import { TaggeruserpopupComponent } from './components/popups/taggeruserpopup/taggeruserpopup.component';
import { TaggerFeaturedComponent } from './components/tagger-home/tagger-featured/tagger-featured.component';
import { ManageGridButtonsComponent } from './components/popups/manage-grid-buttons/manage-grid-buttons.component';
import { AddAssetShareBillingSettingsPopupComponent } from './components/popups/add-asset-share-billing-settings-popup/add-asset-share-billing-settings-popup.component';
import { ServiceBaguaranteepaymentsMetadataComponent } from './components/orders/order-servicemetadata-questions/service-baguaranteepayments-metadata/service-baguaranteepayments-metadata.component';
import { ServiceBahourlyfeeMetadataComponent } from './components/orders/order-servicemetadata-questions/service-bahourlyfee-metadata/service-bahourlyfee-metadata.component';
import { ServiceBaklhsignatoryfeesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-baklhsignatoryfees-metadata/service-baklhsignatoryfees-metadata.component';
import { ServiceBalegalotherfeesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-balegalotherfees-metadata/service-balegalotherfees-metadata.component';
import { ServiceBaotherMetadataComponent } from './components/orders/order-servicemetadata-questions/service-baother-metadata/service-baother-metadata.component';
import { ServiceBapasignatoryfeesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-bapasignatoryfees-metadata/service-bapasignatoryfees-metadata.component';
import { ServiceBatalentmanagementMetadataComponent } from './components/orders/order-servicemetadata-questions/service-batalentmanagement-metadata/service-batalentmanagement-metadata.component';
import { ServiceConsultingtalentbaMetadataComponent } from './components/orders/order-servicemetadata-questions/service-consultingtalentba-metadata/service-consultingtalentba-metadata.component';
import { ServiceConsultingtrafficdistributionMetadataComponent } from './components/orders/order-servicemetadata-questions/service-consultingtrafficdistribution-metadata/service-consultingtrafficdistribution-metadata.component';
import { ServiceCrewpayrollprocessingMetadataComponent } from './components/orders/order-servicemetadata-questions/service-crewpayrollprocessing-metadata/service-crewpayrollprocessing-metadata.component';
import { ServiceSignatoryfeeotherMetadataComponent } from './components/orders/order-servicemetadata-questions/service-signatoryfeeother-metadata/service-signatoryfeeother-metadata.component';
import { ServiceTalentpayrollprocessingMetadataComponent } from './components/orders/order-servicemetadata-questions/service-talentpayrollprocessing-metadata/service-talentpayrollprocessing-metadata.component';
import { ServicePayrollmanagementfeesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-payrollmanagementfees-metadata/service-payrollmanagementfees-metadata.component';
import { ServiceDistributionservicesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-distributionservices-metadata/service-distributionservices-metadata.component';
import { ServiceDistributionservicefeesMetadataComponent } from './components/orders/order-servicemetadata-questions/service-distributionservicefees-metadata/service-distributionservicefees-metadata.component';
import { ServiceDistributionserviceotherMetadataComponent } from './components/orders/order-servicemetadata-questions/service-distributionserviceother-metadata/service-distributionserviceother-metadata.component';
import { ServiceMusicpublishingMetadataComponent } from './components/orders/order-servicemetadata-questions/service-musicpublishing-metadata/service-musicpublishing-metadata.component';
import { ServiceOtherincomeMetadataComponent } from './components/orders/order-servicemetadata-questions/service-otherincome-metadata/service-otherincome-metadata.component';
import { ServiceCustomsystemdevelopmentMetadataComponent } from './components/orders/order-servicemetadata-questions/service-customsystemdevelopment-metadata/service-customsystemdevelopment-metadata.component';
import { ServiceGlobalacademy7mMetadataComponent } from './components/orders/order-servicemetadata-questions/service-globalacademy7m-metadata/service-globalacademy7m-metadata.component';
import { ServiceBaquestionsMetadataComponent } from './components/orders/order-servicemetadata-questions/service-baquestions-metadata/service-baquestions-metadata.component';
import { ServiceBatrafficMetadataComponent } from './components/orders/order-servicemetadata-questions/service-batraffic-metadata/service-batraffic-metadata.component';
import { SendorderemailpopupComponent } from './components/popups/sendorderemailpopup/sendorderemailpopup.component';
import { HighresshareconfirmpopupComponent } from './components/popups/highresshareconfirmpopup/highresshareconfirmpopup.component';
import { VaultDigitalAssetDetailComponent } from './components/vault/vault-digital-asset-detail/vault-digital-asset-detail.component';


/**Entra config*/

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';

import {
  MSAL_INSTANCE,
  MsalService,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalModule,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';


import { msalConfig, loginRequest } from './auth-config';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}


// this might not be required
/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}


// MsalInterceptorConfigFactory for API endpoints
export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  // Define which API endpoints should use the access token
  protectedResourceMap.set(environment.apiBaseUrl, environment.entraApiscopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ITOpsComponent,
    LayoverPopupComponent,
    OrderDetailContentComponent,
    CreateOrderContentComponent,
    NavMenuComponent,
    NavHeaderComponent,
    NotificationComponent,
    SpotqcComponent,
    LoginComponent,
    HomeComponent,
    AccountSettingsComponent,
    AddToPlayListComponent,
    CopyOrderOptionsPopupComponent,
    ChangePasswordComponent,
    MediaComponent,
    MediaDetailComponent,
    MediaDetailContentComponent,
    CreateNewPasswordComponent,
    ShareMediaPopupComponent,
    ErrorPopupComponent,
    SpotSearchComponent,
    SpotApprovalComponent,
    NotFoundComponent,
    ReportComponent,
    PlaylistComponent,
    RecentlyViewedComponent,
    SecuredDirective,
    notificationPrefDirective,
    UserTimeZone,
    SafeHtml,
    TimecodeFormat,
    TruncateByWords,
    TruncateByLetters,
    SpotUploadComponent,
    SpotDownloadComponent,
    ConfirmPopupComponent,
    DeletePlaceholderPopupComponent,
    EditHistoryPopupComponent,
    SpotOrderHistoryPopupComponent,
    UnSavedDataWarningComponent,
    SpotResendPopupComponent,
    SpotNotificationPopupComponent,
    OrderFinalizePopupComponent,
    OrderConfirmationPopupComponent,
    ExpirePopupComponent,
    QuickplayerComponent,
    InfoPopupComponent,
    VideoplayerComponent,
    UserDistributionGroupComponent,
    ForgotPasswordComponent,
    OrdersComponent,
    OrderSearchComponent,
    DraftOrdersComponent,
    OrdersInProgressComponent,
    CreateOrderGroupComponent,
    OrderGroupDetailComponent,
    OrderHeaderDetailComponent,
    CreateOrderComponent,
    OrderAddServicePopupComponent,
    BasePopupComponent,
    OrderAddSpotPopupComponent,
    AwaitingOrderReviewComponent,
    UserTimeZoneToUtc,
    ServiceDescriptiveVideoQComponent,
    ServiceCaptioningQComponent,
    ServiceInternationalTranscribeScriptQComponent,
    ServiceInternationalCaptioningQComponent,
    ServiceInternationalClearanceFeeQComponent,
    ServiceInternationalOrderMgmtsupportQComponent,
    ServiceMetadataWrapperComponent,
    ServiceWebCaptioningQComponent,
    ServiceWebmarkingSTQComponent,
    ServiceSubtitlingEmbeddedQComponent,
    ServiceDigitalEditingQComponent,
    ServiceStdConversionQComponent,
    ServiceSlateReslateQComponent,
    ServiceDownConversionQComponent,
    ServiceVoiceOverQComponent,
    ServiceTrafficExpressQComponent,
    ServiceSpotExpirationNotifQComponent,
    ServiceCaptioningReApplicationQComponent,
    ServiceDescriptiveVideoReApplicationQComponent,
    ServiceTranscribeScriptQComponent,
    ServiceTaggingQComponent,
    ServiceNoAdditionalDataComponent,
    BaseServiceDataCollectionComponent,
    ServiceThumbnailImagesQComponent,
    ServiceWaterMarkingBVSQComponent,
    ServiceOvertimeQComponent,
    ServiceDigitalTransferQComponent,
    ServiceBroadcastClearanceCanQComponent,
    ServiceAdministrativeReportingQComponent,
    ServiceVaultAccessQComponent,
    ServiceAccountManagerAdjustmentQComponent,
    ServiceVideoTranscodingQComponent,
    ServiceInstructionCreationQComponent,
    ServiceTargaImagesQComponent,
    ServiceBillBoardQComponent,
    ServiceAudioTranscodingQComponent,
    ServiceBroadcastClearanceUsQComponent,
    ServiceExpHandlingQComponent,
    ServiceDVDQComponent,
    ServiceBroadcastClearanceAdministrationQComponent,
    ServiceHardDriveQComponent,
    ServiceHardDriveFormattingQComponent,
    ServiceThumbFlashDriveQComponent,
    OrderAddShareLinksPopupComponent,
    OrderAddFTPDestinationPopupComponent,
    OrderAddDestinationListPopupComponent,
    DestinationSearchPopupComponent,
    SelectAttachmentCategoryPopupComponent,
    OrderSaveDestinationToListPopupComponent,
    OrderDetailComponent,
    ClientComponent,
    CreateClientComponent,
    SelectedBrandsComponent,
    BrandSearchComponent,
    SearchClientsComponent,
    BrandComponent,
    SearchBrandsComponent,
    CreateBrandComponent,
    SelectedProductsComponent,
    SecuredExcludeDirective,
    ReadyToProcessOrdersComponent,
    ProcessingOrdersComponent,
    OrderDetailSpotsComponent,
    OrderDetailDestinationsComponent,
    OrderDetailFTPComponent,
    OrderDetailDownloadsComponent,
    OrderDetailShareLinkComponent,
    OrderEditFTPDestinationPopupComponent,
    OrderDetailHeaderEditPopupComponent,
    OrderEditNetworkDestinationPopupComponent,
    OrderStatusConfirmpopup,
    OrderGroupServiceVendorPORequestComponent,
    OrderStatusConfirmpopup,
    VendorPurchaseOrderPopupComponent,
    VendorPurchaseOrderConfirmationPopupComponent,
    VendorPOConfirmationComponent,
    AssignVendorComponent,
    UpdatePODComponent,
    UpdateVendorOrderNumberComponent,
    VendorSubmissoinFailedOrderComponent,
    AddCostCenterAndBillToPopUp,
    AddClientBrandVendorInfoPopUp,
    OrderUploadComponent,
    AddClientBrandProductPopUp,
    ClientBrandProductGroup,
    ClientViewComponent,
    ClientDetailComponent,
    ClientEditComponent,
    ViewEditBrandComponent,
    JoinBySeperatorPipe,
    AddPostHouseComponent,
    PostHouseAddProductPopUp,
    AddNewPostHouseComponent,
    UserComponent,
    SearchUsersComponent,
    RegistationRequestsComponent,
    CreateUserComponent,
    AddRolesNotificationsComponent,
    UploadedOrdersComponent,
    OrderInvalidDataPopupComponent,
    UserViewComponent,
    UserDetailComponent,
    UserEditComponent,
    EditUserRolesComponent,
    DestinationComponent,
    SearchDestinationsComponent,
    CreateDestinationComponent,
    SelectNetworkComponent,
    DestinationContactComponent,
    AdminDefaultHubDestinationSearchComponent,
    AdminVendorDestinationMappingComponent,
    DestinationViewComponent,
    DestinationDetailComponent,
    EditDestinationComponent,
    QuickLinkPopUp,
    MediaDetailPopupComponent,
    QuickLinkComponent,
    QuickLinkDetailComponent,
    SessionExpiryWarningPopupComponent,
    OrderDebugComponent,
    BillingItemsPopupComponent,
    AddCustomTagConfigurationPopUp,
    CustomTagConfigGroup,
    AddEditMediaCustomTagPopUp,
    CustomTagControlsComponent,
    AddEditBundleServicesPopUp,
    BundleServiceGroup,
    ConvertFromUTC,
    IsAuthorizedDirective,
    ResendwithAssignVendorComponent,
    ReadyForBillingOrdersComponent,
    PurgeMediaPopupComponent,
    AddPODRecipientsPopUp,
    AddOrderedByPopUp,
    DistributionSearchComponent,
    DistributionComponent,
    UserSelectionPopupComponent,
    UpdateDistributionMediaPopUp,
    DistributionMediaDownloadComponent,
    AdminDestinationSettingsPopupComponent,
    AddEditSpecDetailsPopUp,
    TMCDownloadComponent,
    OrderLvlServiceVendorPORequestComponent,
    ServiceBroadcastClearanceIntlComponent,
    ServiceInternationalTrafficInstructionQComponent,
    OrderCommentsHistoryPopupComponent,
    ProjectComponent,
    ProjectSearchComponent,
    CreateNewProjectPopUp,
    CreateNewProjectFolderPopUp,
    AddFilesToProjectPopUp,
    AddFileDeliveryConfigurationPopUp,
    FileDeliveryConfigGroup,
    TranscodeAssetsComponent,
    MediaTranscodedAssetsComponent,
    TranscodeUserListsComponent,
    OrderTranscodesComponent,
    TranscodeListPopupComponent,
    TranscodeSearchMediaComponent,
    TranscodeFilenameComponent,
    AddUserPermissionToProjectPopUp,
    TranscodeSpecSelectionComponent,
    ShareTranscodeFilePopupComponent,
    ProjectPromoteComponent,
    OrderDestinationInfoPopupComponent,
    ManageProjectUsersComponent,
    BaseDestinationInfoComponent,
    DestinationInnovidInfoComponent,
    RightsManagementComponent,
    ServiceDDIComponent,
    ProfileSelectionComponent,
    MoveFilesBetweenFoldersProjectPopUp,
    AddTranscodeBillingSettingsPopUp,
    AddManagementFeeSettingsettingsPopUp,
    AddStorageManagementFeeSettingsettingsPopUp,
    TranscodeOrderCopyUploadAssetsPopupComponent,
    ServiceTrafficManagementFeeQComponent,
    ServiceClearanceAdviceSupportQComponent,
    ServiceCinemaClearanceQComponent,
    ServiceStandard3rdPartyFeeQComponent,
    ServiceDDAIComponent,
    AdmintranscodepreferencessettingsComponent,
    VaultComponent,
    VaultCreateComponent,
    SelectattachmentvaultpopupComponent,
    VaultSearchComponent,
    VaultAssetDetailComponent,
    VaultRequestDetailComponent,
    VaultRequestCartonComponent,
    VaultDetailHeaderEditPopupComponent,
    AddVaultAssetNoteComponent,
    AddVaultAssetAdditionServicePopupComponent,
    AddVaultAssetAttachmentPopupComponent,
    VaultassetsremovedestroypopupComponent,
    VaultAssetPopupComponent,
    VaultConfirmationPopupComponent,
    IconSendComponent,
    IconSearchComponent,
    IconArrowCircleLeftComponent,
    IconDownloadComponent,
    IconEnvelopeShareComponent,
    IconNavProjectsComponent,
    IconNavAssetsComponent,
    IconNavOrdersComponent,
    IconNavNotificationsComponent,
    IconNavReportsComponent,
    IconNavVaultComponent,
    IconNavAdminComponent,
    IconNavOpsComponent,
    IconNavDistributionComponent,
    IconQuestionMarkComponent,
    IconArrowSimpleRightComponent,
    IconArrowSimpleLeftComponent,
    IconSettingsComponent,
    IconLogoutComponent,
    IconPlusCircleComponent,
    IconPlaylistAddComponent,
    IconDeleteComponent,
    IconRefreshComponent,
    IconExportComponent,
    IconExpireComponent,
    IconArrowCircleRightComponent,
    IconArrowSimpleDownComponent,
    IconArrowSimpleUpComponent,
    IconPlayComponent,
    IconPlayV2Component,
    IconCircleTrashComponent,
    IconArchiveComponent,
    IconAudioComponent,
    IconBrowseComponent,
    IconCalendarComponent,
    IconCheckmarkComponent,
    IconCompanyComponent,
    IconCopyComponent,
    IconDashboardComponent,
    IconEditHistoryComponent,
    IconEditPencilComponent,
    IconEditErrorComponent,
    IconEstimateCalculateComponent,
    IconEyeComponent,
    IconFileComponent,
    IconFolderComponent,
    IconFolderAddComponent,
    IconFolderMoveComponent,
    IconMediaComponent,
    IconMediatypeFourKComponent,
    IconMediatypeFiveOneComponent,
    IconMediatypeAudioComponent,
    IconMediatypeBroadcastComponent,
    IconMediatypeBvsComponent,
    IconMediatypeCcpComponent,
    IconMediatypeClosedCaptionComponent,
    IconMediatypeDvsComponent,
    IconMediatypeGenericComponent,
    IconMediatypeHdComponent,
    IconMediatypeRunningFootageComponent,
    IconMediatypeRunningFootageV2Component,
    IconMediatypeWebComponent,
    IconMediatypeVeilComponent,
    IconMediatypeSdComponent,
    IconMediatypeStComponent,
    IconMediatypeStereoComponent,
    IconMediatypeSubtitlesComponent,
    IconMediatypeTaggableComponent,
    IconMediatypeUnknownComponent,
    IconMoveComponent,
    IconNextArrowComponent,
    IconNotificationBellComponent,
    IconOpenEnvelopeComponent,
    IconOrderReviewFailComponent,
    IconOrdersShoppingCartComponent,
    IconOrdersTylieLogoMarkComponent,
    IconOrdersTylieMarkComponent,
    IconProjectComponent,
    IconQuickOrderComponent,
    IconQuickOrderV2Component,
    IconReportsComponent,
    IconReviewComponent,
    IconRushComponent,
    IconSaveComponent,
    IconTranscodeOrderComponent,
    IconUploadComponent,
    IconUserComponent,
    IconVaultComponent,
    IconVaultLoginComponent,
    IconVaultLogoutComponent,
    IconVaultStaggingComponent,
    IconVideoComponent,
    IconClaimComponent,
    VaultConfirmationPopupComponent,
    TaggerHomeComponent,
    TaggerSearchComponent,
    TaggerFormComponent,
    TaggerCreativeStepComponent,
    TaggerOfferCardStepComponent,
    TaggerEndCardStepComponent,
    TaggerReviewStepComponent,
    TaggerEndCardVoiceOverStepComponent,
    TaggerDraftSearchComponent,
    TaggerDraftorderAddnotepopupComponent,
    TaggerOrderNotepopupComponent,
    ShareTaggerAdPopupComponent,
    ViewTaggerOrderAdpopupComponent,
    TaggerArchivedSearchComponent,
    CreativeTagsComponent,
    TaggerAssetComponent,
    AdminAssetUploadPopUpComponent,
    AdminAssetUploadFormComponent,
    GroupsComponent,
    AddGroupsLocationsPopUpComponent,
    CustomDropdownComponent,
    IconNavTaggerComponent,
    IconTabTaggedAdComponent,
    IconTabDraftAdComponent,
    IconTabArchiveAdComponent,
    IconCheckmarkGreenComponent,
    IconCheckmarkWhiteComponent,
    LocationsAndUsersComponent,
    TaggerHeaderComponent,
    IconLocationComponent,
    AddLocationsPopupComponent,
    AddTaggerUserPopupComponent,
    TaggeruserpopupComponent,
    TaggerFeaturedComponent,
    IconStarComponent,
    IconCircleArrowUpComponent,
    IconTrashCanComponent,
    IconPurgeComponent,
    IconRestoreComponent,
    IconDetailsViewComponent,
    IconFlagComponent,
    IconTraceComponent,
    ManageGridButtonsComponent,
    AddAssetShareBillingSettingsPopupComponent,
    ServiceBaguaranteepaymentsMetadataComponent,
    ServiceBahourlyfeeMetadataComponent,
    ServiceBaklhsignatoryfeesMetadataComponent,
    ServiceBalegalotherfeesMetadataComponent,
    ServiceBaotherMetadataComponent,
    ServiceBapasignatoryfeesMetadataComponent,
    ServiceBatalentmanagementMetadataComponent,
    ServiceConsultingtalentbaMetadataComponent,
    ServiceConsultingtrafficdistributionMetadataComponent,
    ServiceCrewpayrollprocessingMetadataComponent,
    ServiceSignatoryfeeotherMetadataComponent,
    ServiceTalentpayrollprocessingMetadataComponent,
    ServicePayrollmanagementfeesMetadataComponent,
    ServiceDistributionservicesMetadataComponent,
    ServiceDistributionservicefeesMetadataComponent,
    ServiceDistributionserviceotherMetadataComponent,
    ServiceMusicpublishingMetadataComponent,
    ServiceOtherincomeMetadataComponent,
    ServiceCustomsystemdevelopmentMetadataComponent,
    ServiceGlobalacademy7mMetadataComponent,
    ServiceBaquestionsMetadataComponent,
    ServiceBatrafficMetadataComponent,
    SendorderemailpopupComponent,
    HighresshareconfirmpopupComponent,
    VaultDigitalAssetDetailComponent
  ],
  providers: [
    GlobalService,
    AuthService,
    AlertService,
    AuthGuard,
    CanDeactivateCreateOrder,
    CanDeactivateCreateVault,
    CanDeactivateOrderDetail,
    CanDeactivateOrderTranscodes,
    LoginGuard,
    SubService,
    SpotService,
    PlayListService,
    UserMgmtService,
    UploadService,
    LocalStoreManager,
    EndpointFactory,
    ApiEndPoint,
    MediaService,
    NotificationService,
    MediaDetailService,
    UserDataService,
    Utilities,
    UserRoles,
    UserProfileService,
    DatGridService,
    ShareMediaService,
    AccountSettingsService,
    UserRolesNotification,
    ConfigService,
    ForgotPasswordSettingsService,
    AdminService,
    OrderService,
    VaultService,
    TandemGridService,
    ReportService,
    DownloadMediaService,
    UploadOrderFileService,
    DistributionService,
    ArchiveProjectService,
    SinglePartFileUploadService,
    BsModalRef,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ProjectService,
    ProjectFolderService,
    AddUserPermissionToProjectService,
    PromoteProjectFileService,
    RightsManagementService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigurationFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppConfigModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      iconClasses: {
        error: 'toast-error-custom',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      }
    }),
    ScrollToModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    DevExtremeModule,
    TextMaskModule,
    DxAutocompleteModule,
    NgxLoadingModule.forRoot({}),
    NgIdleModule.forRoot(),
    MsalModule
  ]
})

export class AppModule {
}
