import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { OrderDestination } from '../../../models/order/order-destinations.model';
import { Subject } from 'rxjs';
import { OrderClientDestination } from '../../../models/order/order-clientdestination.model';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { SearchUserDestinationRequest } from '../../../models/order/order-searchuserdestination-request.model';
import { OrderUserDestination } from '../../../models/order/order-userdestination';
import { Constants } from '../../../configurations/constants';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderaddftpdestinationpopup',
  templateUrl: './orderaddftpdestinationpopup.component.html',
  styleUrls: ['./orderaddftpdestinationpopup.component.css'],
})
export class OrderAddFTPDestinationPopupComponent extends BasePopupComponent {

  @Input() isEdit: boolean;
  @Input() allowAllFTP: boolean = false;

  public quickSearchValue: string = '';
  public ftpDestinationsDataSource: Array<OrderUserDestination> = null;
  public ftpSavedDestinationsDataSource: Array<OrderUserDestination> = null;
  public createFtpDestinationRequest: FormGroup;
  public onClose: Subject<SelectedOrderDestinations>;
  public addMoreMode: boolean = false;
  public savedFtpListMode: boolean = false;
  public applyToAllGroups: boolean = false;

  @ViewChild('ftpDestinationsGrid', { static: false }) ftpDestinationsGrid: DxDataGridComponent;
  @ViewChild('ftpSavedDestinationsGrid', { static: false }) ftpSavedDestinationsGrid: DxDataGridComponent;
  @ViewChild("ftpForm", { static: false }) ftpForm: NgForm;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onClose = new Subject();

    this.createFtpDestinationRequest = new FormGroup({
      name: new FormControl('', Validators.required),
      ftpServer: new FormControl('', Validators.required),
      ftpUserName: new FormControl('', Validators.required),
      ftpPassword: new FormControl('', Validators.required),
      ftpPort: new FormControl(''),
      id: new FormControl(null, Validators.required)
    });

    if (this.allowAllFTP == true)
      this.savedFtpListMode = false;
    else
      this.savedFtpListMode = true;

    this.searchFtpDestinations();
  }

  public searchFtpDestinations() {

    let request: SearchUserDestinationRequest = { category: 'ftp', quickSearch: this.quickSearchValue };

    if (this.savedFtpListMode == true) {
      this.configService.searchUserDestinations(request).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var destinations = res.result as Array<OrderUserDestination>;
          this.ftpSavedDestinationsDataSource = destinations;
          this.ftpDestinationsDataSource = [];
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          if (this.util.handleError(error))
            this.closePopup();
        });
    }
    else {
      this.configService.searchFTPDestinations(request).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var destinations = res.result as Array<OrderUserDestination>;
          this.ftpDestinationsDataSource = destinations;
          this.ftpSavedDestinationsDataSource = [];
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          if (this.util.handleError(error))
            this.closePopup();
        });
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      //this.clearForm();
      this.searchFtpDestinations();
    }
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue === '') {
      //this.clearForm();
      this.searchFtpDestinations();
    }
  }

  public onApplyToOrder() {
    this.saveFTPDestinations();
  }

  public onCreateFtpDestinationFormSubmit({ value, valid }: { value: OrderUserDestination, valid: boolean }) {

    if (this.createFtpDestinationRequest.controls['id'].errors && this.createFtpDestinationRequest.controls['name'].errors)
      return;

    if (this.createFtpDestinationRequest.controls['ftpServer'].errors
      || this.createFtpDestinationRequest.controls['ftpUserName'].errors
      || this.createFtpDestinationRequest.controls['ftpPassword'].errors)
      return;

    if (this.createFtpDestinationRequest.controls['name'].errors) {

      var toEdit = this.ftpSavedDestinationsDataSource.find(t => t.id == value.id);

      if (toEdit != null) {
        value.name = toEdit.name;
        this.updateFTPDestination(value);
      }
    }
    else {
      this.createFTPDestination(value);
    }
  }

  private createFTPDestination(orderUserDestination: OrderUserDestination) {
    this.configService.createUserFtpDestination(orderUserDestination).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var newDestination: OrderUserDestination = res.result as OrderUserDestination;
        this.alertService.showMessage("SUCCESS", Constants.userFtpDestinationCreated, MessageSeverity.success);
        this.clearForm();
        this.addMoreMode = false;
        this.searchFtpDestinations();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  private updateFTPDestination(orderUserDestination: OrderUserDestination) {
    this.configService.updateUserFtpDestination(orderUserDestination).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var newDestination: OrderUserDestination = res.result as OrderUserDestination;
        this.alertService.showMessage("SUCCESS", Constants.userFtpDestinationUpdated, MessageSeverity.success);
        this.clearForm();
        this.addMoreMode = false;
        this.searchFtpDestinations();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  private saveFTPDestinations() {

    var selectedftpDestinations = new Array<OrderUserDestination>();
    var allSelectedDestiantions = new SelectedOrderDestinations();

    var grid = this.savedFtpListMode ? this.ftpSavedDestinationsGrid.instance : this.ftpDestinationsGrid.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedftpDestinations.push(grid.getSelectedRowsData()[i]);
    }

    allSelectedDestiantions.applyToAllGroups = this.applyToAllGroups;

    allSelectedDestiantions.ftpDestinations = selectedftpDestinations;

    this.onClose.next(allSelectedDestiantions);

    this.closePopup();
  }

  public onSavedFtpDestinationDelete(id: number) {
    this.configService.deleteUserFtpDestination(id).subscribe((res:any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.ftpDestinationDeleted, MessageSeverity.success);
        this.searchFtpDestinations();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public onSavedFtpDestinationEdit(id: number) {
    var ftpDestination = this.ftpSavedDestinationsDataSource.find(t => t.id == id);

    if (ftpDestination != null) {
      this.addMoreMode = true;
      this.createFtpDestinationRequest.controls['ftpUserName'].setValue(ftpDestination.ftpUserName);
      this.createFtpDestinationRequest.controls['ftpServer'].setValue(ftpDestination.ftpServer);
      this.createFtpDestinationRequest.controls['ftpPort'].setValue(ftpDestination.ftpPort);
      this.createFtpDestinationRequest.controls['ftpPassword'].setValue(ftpDestination.ftpPassword);
      //this.createFtpDestinationRequest.controls['name'].setValue(ftpDestination.name);
      this.createFtpDestinationRequest.controls['id'].setValue(ftpDestination.id);
    }
  }

  private clearForm() {
    //this.ftpForm.resetForm();
    this.createFtpDestinationRequest = new FormGroup({
      name: new FormControl('', Validators.required),
      ftpServer: new FormControl('', Validators.required),
      ftpUserName: new FormControl('', Validators.required),
      ftpPassword: new FormControl('', Validators.required),
      ftpPort: new FormControl(''),
      id: new FormControl(null, Validators.required)
    });
  }

  public onAddMore() {
    this.addMoreMode = true;
  }

  public onCancelAddMore() {
    this.addMoreMode = false;
    this.clearForm();
  }

  public onShowFtpList() {
    this.savedFtpListMode = false;
    this.searchFtpDestinations();
  }

  public onShowSavedFtpList() {
    this.savedFtpListMode = true;
    this.searchFtpDestinations();
  }
}
