<div [formGroup]="taggerUserRequest">
    <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-group"
               [ngClass]="{
                    error:
                        taggerUserRequest.controls['userName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['userName'].dirty ||
                            taggerUserRequest.controls['userName'].touched)
                }">
            <label class="tylie-form-group__lbl"
                   for="userName">
              User Email (is also Username)<span class="required">*</span>
            </label>
            <input *ngIf="isNewUser"
                   type="email"
                   id="userName"
                   placeholder="Please enter Email"
                   name="userName"
                   class="tylie-text form-control"
                   formControlName="userName"/>

            <input *ngIf="isEditUser"
                   type="email"
                   id="userName"
                   placeholder="Please enter Email"
                   name="userName"
                   class="tylie-text form-control"
                   formControlName="userName"
                   disabled />

            <div class="error-text"
                 *ngIf="
                        taggerUserRequest.controls['userName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['userName'].dirty ||
                            taggerUserRequest.controls['userName'].touched)
                    ">
              User Email is Required
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl">User Password</label>
            <span class="d-block mt-2" *ngIf="this.isExistingUser == true">Existing Tandem Password</span>
            <span class="d-block mt-2"
                  *ngIf="isNewUser && this.isExistingUser == false">will be emailed to User</span>
            <span *ngIf="isEditUser"
                  class="d-block mt-2 tylie-form-group__lbl_2 pr-10"
                  (click)="generateResetPasswordToken()">Send Password Reset Email</span>
          </div>
        </div>
        <div class="col-md-3" *ngIf="isNewUser == true">
          <div class="tylie-form-group form-check">
            <input type="checkbox"
                   class="form-check-input"
                   formControlName="isExistingTandemUser"
                   name="isFeatured"
                   id="isFeatured" />
            <label class="form-check-label" for="isExistingTandemUser">Existing Tandem User</label>
          </div>
        </div>
    </div>
    <div class="row" *ngIf="this.isExistingUser == false">
        <div class="col-md-6">
            <div
                class="tylie-form-group form-group"
                [ngClass]="{
                    error:
                        taggerUserRequest.controls['firstName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['firstName'].dirty ||
                            taggerUserRequest.controls['firstName'].touched)
                }"
            >
                <label
                    class="tylie-form-group__lbl"
                    for="firstName"
                    >First Name<span class="required">*</span></label
                >
                <input
                    type="text"
                    id="firstName"
                    placeholder="Please enter First Name"
                    name="firstName"
                    class="tylie-text form-control"
                    formControlName="firstName"
                />
                <div
                    class="error-text"
                    *ngIf="
                        taggerUserRequest.controls['firstName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['firstName'].dirty ||
                            taggerUserRequest.controls['firstName'].touched)
                    "
                >
                    First Name is Required
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div
                class="tylie-form-group form-group"
                [ngClass]="{
                    error:
                        taggerUserRequest.controls['lastName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['lastName'].dirty ||
                            taggerUserRequest.controls['lastName'].touched)
                }"
            >
                <label
                    class="tylie-form-group__lbl"
                    for="lastName"
                    >Last Name<span class="required">*</span></label
                >
                <input
                    type="text"
                    id="lastName"
                    placeholder="Please enter Last Name"
                    name="lastName"
                    class="tylie-text form-control"
                    formControlName="lastName"
                />

                <div
                    class="error-text"
                    *ngIf="
                        taggerUserRequest.controls['lastName'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['lastName'].dirty ||
                            taggerUserRequest.controls['lastName'].touched)
                    "
                >
                    Last Name is Required
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div
                class="tylie-form-group form-group"
                [ngClass]="{
                    error:
                        taggerUserRequest.controls['title'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['title'].dirty ||
                            taggerUserRequest.controls['title'].touched)
                }"
            >
                <label
                    class="tylie-form-group__lbl"
                    for="title"
                    >Title<span class="required">*</span></label
                >
                <input
                    type="text"
                    id="title"
                    placeholder="Please enter Title"
                    name="title"
                    class="tylie-text form-control"
                    formControlName="title"
                />
                <div
                    class="error-text"
                    *ngIf="
                        taggerUserRequest.controls['title'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['title'].dirty ||
                            taggerUserRequest.controls['title'].touched)
                    "
                >
                    Title is Required
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div
                class="tylie-form-group form-group"
                [ngClass]="{
                    error:
                        taggerUserRequest.controls['phoneNumber'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['phoneNumber'].dirty ||
                            taggerUserRequest.controls['phoneNumber'].touched)
                }"
            >
                <label
                    class="tylie-form-group__lbl"
                    for="phoneNumber"
                    >Phone Number<span class="required">*</span></label
                >
                <input
                    type="text"
                    id="phoneNumber"
                    placeholder="Please enter Phone Number"
                    name="phoneNumber"
                    class="tylie-text form-control"
                    formControlName="phoneNumber"
                />
                <div
                    class="error-text"
                    *ngIf="
                        taggerUserRequest.controls['phoneNumber'].invalid &&
                        (isFormSubmitted ||
                            taggerUserRequest.controls['phoneNumber'].dirty ||
                            taggerUserRequest.controls['phoneNumber'].touched)
                    "
                >
                    Phone Number is Required
                </div>
            </div>
        </div>
    </div>
</div>
