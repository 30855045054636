import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { confirmEventType } from "../../../configurations/enums/enums";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'confirm-popup',
  templateUrl: './confirmpopup.component.html',
  styleUrls: ['./confirmpopup.component.css'],
})
export class ConfirmPopupComponent extends BasePopupComponent
 {
  @Input() eventType: string;
  @Input() groupName: string;
  @Input() sequenceId: string;


  public mainMessage: string;

  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    if (this.eventType == confirmEventType[confirmEventType.DeleteOrderGroup]) {
      this.mainMessage = "Are you sure to delete this Group ?";

      if (this.groupName != null && this.sequenceId != null) {
        this.mainMessage = "Are you sure to delete this Group " + this.sequenceId + " (" + this.groupName + ") ? ";
      }
      else if (this.sequenceId != null) {
        this.mainMessage = "Are you sure to delete this Group " + this.sequenceId +" ?";
      }

    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeOrderClient]) {
      this.mainMessage = "Changing client would delete all the asset from all the groups. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeOrderBrand]) {
      this.mainMessage = "Changing brand would delete all the asset from all the groups. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeOrderAgency]) {
      this.mainMessage = "Changing agency would delete all the asset from all the groups. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeleteDraftOrder]) {
      this.mainMessage = 'Are you sure to delete this Draft Order ?';
    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeOrderProduct]) {
      this.mainMessage = "Changing product would delete all the asset from all the groups. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeleteBrandProduct]) {
      this.mainMessage = "Are you sure to delete this Product ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeleteClientBrand]) {
      this.mainMessage = "Are you sure to delete this Brand ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeletePostHouse]) {
      this.mainMessage = "Are you sure to delete this Post House ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeUserCreationClient]){
      this.mainMessage = "Changing Client would delete all the brand selections. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.ChangeUserCreationPostHouse]) {
      this.mainMessage = "Updating Post House would delete all the brand selections. Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeleteUploadedOrder]) {
      this.mainMessage = "Are you sure to delete this Uploaded Order ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.DeleteUser]) {
      this.mainMessage = "Inactivating this user profile, will delete the user ? Do you want to proceed ?";
    }
    else if (this.eventType == confirmEventType[confirmEventType.CopyOrderDraft]) {
      this.mainMessage = "Are you sure to Copy the Draft Order?";
    }
    this.onClose = new Subject();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onYes() {
    this.onClose.next(true);

    this.closePopup();
  }

  public onNo() {
    this.onClose.next(false);

    this.closePopup();
  }
}
