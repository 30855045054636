<header class="tylie-header">
  <h3 class="tylie-header__title">Assets</h3>
  <nav class="tylie-header__nav" id="bs-example-navbar-collapse-1">
    <ul class="tylie-header__nav-lst">
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaProviderRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="gotoUpload()">Upload Assets</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaDownloadRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="gotoDownload()">Download Status</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaApproverRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('media-awaiting-approval-section')">Approvals</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaQCRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('media-qc-section')">Asset QC</a></li>
      <li class="tylie-header__nav-lst-itm" *ngIf="((playList != null && playList != undefined && playList.length > 0) || (isPlayListExists == true))"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('playlist-section')">Playlists</a></li>
      <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('search-all-media-section')">View/Search All Assets</a></li>
      <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('recently-viewed-section')">Recently Viewed</a></li>
      <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getMediaTranscoderRoleName()]" (click)="gotoTranscodeFiles()">Transcode Files</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getAssetRightsManagementRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="gotoTalentRights()">Talent Rights</a></li>
    </ul>
  </nav>  
</header>

<div class="tylie-body">

  <ng-container *isAuthorized="[this.userRoles.getMediaProviderRoleName()]">
    <div class="tylie-body__bar">
      <button type="button" class="tylie-button tylie-button--icon" (click)="gotoUpload()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">UPLOAD ASSETS</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="spotapproverParent">
    <spot-approval *isAuthorized="[this.userRoles.getMediaApproverRoleName()]" [spotapprovalList]="spotapproverParent"></spot-approval>
  </ng-container>

  <spot-qc *isAuthorized="[this.userRoles.getMediaQCRoleName()]"></spot-qc>

  <ng-container *ngIf="playList">
    <playlist [playList]="playList" (playListChange)='showPlayList($event)'></playlist>
  </ng-container>

  <!--<div>
    <quick-link secured [accessControlListComponent]="[this.userRoles.getOrderBuilderRoleName(), this.userRoles.getOrderStatusEditorRoleName()]">
    </quick-link>
  </div>-->

  <ng-container *ngIf="searchConfig">
    <spot-search [searchConfig]="searchConfig"></spot-search>
  </ng-container>

  <ng-container *ngIf="recentlyViewed">
    <recently-viewed [recentlyViewedList]="recentlyViewed"></recently-viewed>
  </ng-container>

</div>
