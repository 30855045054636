<div id="share-links">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">User Type Selection</h4>
  </div>
  <div class="modal-body">
    <form novalidate (ngSubmit)="onFormSubmit(userSelectionRequest)" [formGroup]="userSelectionRequest" role="search" #f="ngForm">
      <p class="mb-3">Please select a user type from the dropdown to proceed.</p>
      <div class="tylie-form-group form-group" [ngClass]="{error: (userSelectionRequest.controls['userType'].errors && !userSelectionRequest.controls['userType'].pristine)
                 || (userSelectionRequest.controls['userType'].errors && f.submitted) }">
        <label class="tylie-form-group__lbl" for="userType">User Type<span class="required">*</span></label>
        <dx-select-box [dataSource]="userTypeDataSource"
                       name="userType" id="userType" class="tylie-select form-control"
                       formControlName="userType"
                       [searchEnabled]="true"
                       valueExpr="name"
                       [showClearButton]="true"
                       displayExpr="displayName"
                       placeholder="Select a value...">
        </dx-select-box>
        <p class="p-extra-small">Please select User Type</p>
      </div>

      <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs me-3" type="submit">Continue</button>
          <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>

  </div>
</div>



