import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { DestinationSearch } from "../../../models/admin/destination/destinationsearch.model";
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { AdminService } from '../../../services/admin/admin.service';

@Component({
  selector: 'destinationsearchpopup.component.ts',
  templateUrl: './destinationsearchpopup.component.html',
  styleUrls: ['./destinationsearchpopup.component.css'],
})
export class DestinationSearchPopupComponent extends BasePopupComponent {

  @Input() title: string;
  @Input() multipleSelection: boolean;

  private customSelection: boolean = false;
  public dataSource: any = {};
  public quickSearchValue: string = '';
  public onClose: Subject<any>;
  public searchDestinationCriteriaObj = <DestinationSearch>{};

  @ViewChild('destinationsGrid', { static: true }) destinationsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private adminService: AdminService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onClose = new Subject();

    this.searchDestinationCriteriaObj.ignoreSort = false;

    this.searchDestinations();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.searchDestinationCriteriaObj.quickSearch = '';
      this.searchDestinations();
    }
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
      this.searchDestinations();
    }
  }

  public onMapDestination() {

    var grid = this.destinationsGrid.instance;

    var selectedDestinations = new Array<any>();

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedDestinations.push(grid.getSelectedRowsData()[i]);
    }

    this.onClose.next(selectedDestinations);

    this.closePopup();
  }

  private get destinationsDataStore() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchDestinationCriteriaObj;
    var mRef = this.bsModalRef;

    return new CustomStore({
      load: function (loadOptions: any) {

        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'code','desc':false}]";
        var groupConfig = loadOptions.group ? JSON.stringify(loadOptions.group) : "";

        let gridParams: GridParams = { group: groupConfig, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, gridParams: gridParams };

        return adminService.searchAllDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var destinationSearchResponse = response.result;

              var obj: any = {
                data: destinationSearchResponse.destinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  private searchDestinations() {
    this.dataSource = new DataSource({
      store: this.destinationsDataStore
    });
  }

  public onSelectionChanged(e: any) {

    if (this.multipleSelection == true) return;

    if (this.customSelection) return;

    this.customSelection = true;

    e.component.selectRows(e.currentSelectedRowKeys[0], false);

    this.customSelection = false;
  }
}
