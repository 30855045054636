<app-tagger-header [activeLinks]="['groups']"></app-tagger-header>

<div class="tylie-body">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="locations-users-header-title">
      <span>Groups</span>
    </div>
    <div class="d-flex gap-3">
      <button type="button"
        class="tylie-button tylie-button--xs tylie-button--icon"
        (click)="onClickAddGroup()"
      >
        <span class="tylie-button__icon">
          <icon-plus-circle></icon-plus-circle>
        </span>
        <span class="tylie-button__text">ADD A GROUP</span>
      </button>
    </div>
  </div>

  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item">
      <div class="panel-footer panel-footer-master-child-row"></div>
      <div
        id="mediaSearchCollapseOne"
        class="tylie-accordion__content accordion-collapse collapse show"
      >
        <div class="tylie-accordion__body-content pb-2">
          <dx-data-grid
            id="groupSearch"
            #groupSearch
            [dataSource]="resultGroupsStore"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            [columnAutoWidth]="true"
            [showColumnLines]="true"
            [showRowLines]="true"
            [hoverStateEnabled]="true"
            keyExpr="groupGuid"
            [rowAlternationEnabled]="true"
            class="tylie-grid"
            [masterDetail]="{
              enabled: true,
              template: 'filesTemplate',
              autoExpandAll: false
            }"
          >
            <dxo-remote-operations [sorting]="true" [paging]="true">
            </dxo-remote-operations>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxi-column
              dataField="name"
              caption="Group Name"
              cellTemplate="groupNameTemplate"
              width="90%"
              cssClass="gridStyle"
              sortOrder="asc"
            ></dxi-column>
            <dxi-column
              caption="Locations"
              cellTemplate="locationsTemplate"
              width="10%"
              cssClass="gridStyle"
            ></dxi-column>

            <div
              *dxTemplate="let t of 'groupNameTemplate'"
              class="d-flex align-items-center gap-2"
            >
              <span class="tylie-button__icon">
                <icon-location></icon-location>
              </span>
              <span>{{ t.data.name }}</span>
            </div>

            <div *dxTemplate="let t of 'locationsTemplate'">
              <span>{{ t.data.locations.length }}</span>
            </div>

            <div
              *dxTemplate="let t of 'filesTemplate'"
              class="detail-grid-margin-left-small"
            >
              <div class="row tagger-row">
                <div class="col-md-2">
                  <div class="row">
                    <span class="multiline bold-span">{{ t.data.name }}</span>
                  </div>
                  <div class="row">
                    <div class="multiline">{{ t.data.groupDescription }}</div>
                  </div>
                  <button type="button"
                    class="tylie-button tylie-button--xs tylie-button--link add-edit"
                    (click)="onClickEditGroup(t.data)"
                  >
                    Edit
                  </button>
                </div>
                <div class="col-md-10">
                  <div class="row">
                    <span class="bold-span">LOCATIONS</span>
                  </div>
                  <dx-data-grid
                    [showBorders]="false"
                    keyExpr="locationGuid"
                    [columnAutoWidth]="true"
                    [showColumnLines]="false"
                    [hoverStateEnabled]="true"
                    [showRowLines]="true"
                    class="tylie-grid"
                    [dataSource]="t.data.locations"
                  >
                    <dxi-column
                      dataField="name"
                      caption="Location"
                      width="80%"
                      cssClass="gridStyle1"
                      sortOrder="asc"
                    ></dxi-column>
                    <dxi-column
                      dataField="city"
                      caption="City"
                      width="10%"
                      cssClass="gridStyle1"
                    ></dxi-column>
                    <dxi-column
                      dataField="state"
                      caption="State"
                      width="5%"
                      cssClass="gridStyle1"
                    ></dxi-column>
                    <dxi-column
                      dataField="zip"
                      caption="Zip"
                      width="5%"
                      cssClass="gridStyle1"
                    ></dxi-column>
                  </dx-data-grid>
                  <button type="button"
                    class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link tagger-row"
                    (click)="onClickAddOrEditGroupLocations(t.data)"
                  >
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    <span>ADD / Edit LOCATIONS</span>
                  </button>
                </div>
              </div>

              <div class="panel-footer panel-footer-master-child-row"></div>
            </div>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 20, 50, 100]"
              infoText="Page {0} of {1} ({2} items)"
              [showNavigationButtons]="true"
              [showInfo]="true"
            >
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
