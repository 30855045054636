import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { UserRoles } from "../../../configurations/user-roles";
import { RouteConstants } from "../../../configurations/route-constants";

@Component({
    selector: 'brand',
    templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit{    
  constructor(public util: Utilities
    ,private router: Router
    ,public userRoles: UserRoles) {
  }

  ngOnInit() { }

  public gotoCreateNewBrand() {
    this.router.navigate([RouteConstants.createBrandsRoute]);
  }
}
