import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import 'jqueryui';

@Component({
  selector: 'basepopup',
  templateUrl: './basepopup.component.html'
})
export class BasePopupComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    $(document).ready(function () {

      let modalContent: any = $('.modal-content');
      let modalHeader = $('.modal-header');

      modalHeader.addClass('cursor-all-scroll');

      modalContent.draggable({
        handle: '.modal-header'
      });

      //let modalDialog: any = $('.modal-dialog');
      //modalContent.resizable({
      //});

      //modalDialog.draggable({
      //});

    });
  }
}
