import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-playlist-add',
  templateUrl: './icon-playlist-add.component.html',
  styleUrls: ['./icon-playlist-add.component.css']
})
export class IconPlaylistAddComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
