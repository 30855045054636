import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { ServiceMetadataObjectOption } from '../../../../models/order/order-servicequestions.model';

@Component({
  selector: 'service-digitaltransfer',
  templateUrl: './servicedigitaltransfer.component.html'
})
export class ServiceDigitalTransferQComponent extends BaseServiceDataCollectionComponent {

  public minuteOptions: Array<any> = [{ "val": "1", "display": "1" },
  { "val": "10", "display": "10" },
  { "val": "15", "display": "15" },
  { "val": "20", "display": "20" },
  { "val": "30", "display": "30" },
  { "val": "45", "display": "45" },
  { "val": "60", "display": "60" },
  { "val": "90", "display": "90" }];

  public minstransfersList: Array<MinsTransfersPair> = [];
  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();    

    if (this.orderLevel == true) {

      this.isDisabled = this.formGroup.controls["minstransfers"].disabled;

      if (this.formGroup.controls["minstransfers"].value == null) {
        this.minstransfersList.push({ id: 1, mins: null, nooftransfers: null });
      }
      else {
        var exisitingValues = this.formGroup.controls["minstransfers"].value as Array<ServiceMetadataObjectOption>;

        exisitingValues.forEach((item, index) => {
          this.minstransfersList.push({ id: index + 1, mins: item.val1, nooftransfers: item.val2 });
        });
      }
    }
  }

  public onAddNew(i) {
    if (i == 1)
      this.minstransfersList.push({ id: this.minstransfersList[this.minstransfersList.length - 1].id + 1, mins: null, nooftransfers: null });
    else if (i > 1)
      this.minstransfersList = this.minstransfersList.filter(t => t.id != i);

    this.onChange();
  }

  public onChange() {

    let val: Array<ServiceMetadataObjectOption> = [];

    this.minstransfersList.forEach((item, index) => {
      console.log(index, item.mins, item.nooftransfers);
      if (item.mins != null && item.nooftransfers != null)
        val.push({ val1: item.mins, val2: item.nooftransfers });
    });

    this.formGroup.controls["minstransfers"].setValue(val);
  }
}

interface MinsTransfersPair {
  id: number,
  mins: string,
  nooftransfers: string,
}
