import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "../../../services/core/utilities";
import { UserRoles } from "../../../configurations/user-roles";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { QuickplayerComponent } from "../../videoplayer/quickplayer/quickplayer.component";
import { OrderNoteResponse, SearchTaggedAdRequestModel, TaggedAdResponseModel, TaggerAd } from "../../../models/tagger/tagger.model";
import { RouteConstants } from "../../../configurations/route-constants";
import { DraftOrderSort, Statuses, TaggerTabs, TaggingStatus } from "../../../configurations/enums/enums";
import { TaggerOrderNotepopupComponent } from "../../popups/tagger-order-notepopup/tagger-order-notepopup.component";
import { Constants } from "../../../configurations/constants";
import { ShareTaggerAdPopupComponent } from "../../popups/share-tagger-ad-popup/share-tagger-ad-popup.component";
import { DownloadMediaService } from "../../../services/media/media-download.service";
import { ViewTaggerOrderAdpopupComponent } from "../../popups/view-tagger-order-adpopup/view-tagger-order-adpopup.component";

@Component({
  selector: 'app-tagger-archived-search',
  templateUrl: './tagger-archived-search.component.html',
  styleUrls: ['./tagger-archived-search.component.css']
})
export class TaggerArchivedSearchComponent implements OnInit {
  public taggerAds: TaggedAdResponseModel[] = [];
  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  public selectedSortOrder: string = "";
  public modalRef: BsModalRef;
  public sortDataSource: any[];
  public taggingStatus = TaggingStatus;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private taggerService: TaggerService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private ds: DownloadMediaService,
  ) { }

  ngOnInit() {
    this.sortDataSource = Object.keys(DraftOrderSort).map(key => ({
      id: key,
      name: DraftOrderSort[key]
    }));
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadArchiveTaggerAds();
  }

  public onRefresh() {
    this.quickSearchValue = "";
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadArchiveTaggerAds();
  }

  public loadArchiveTaggerAds() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: SearchTaggedAdRequestModel = {
      search: this.quickSearchValue,
      sortOrder: this.selectedSortOrder,
      isArchive: true,
    };
    this.taggerService.getTaggedAds(payload).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.taggerAds = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        // this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onSortValueChanged(e: any) {
    this.selectedSortOrder = e.value;
    this.loadArchiveTaggerAds();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.loadArchiveTaggerAds();
    }
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public statusClass(status: string) {
    let cls = '';
    if (status == Statuses.Current) {
      cls += " tylie-badge--success"
    } else if (status == Statuses.ExpireSoon) {
      cls += " tylie-badge--warning"
    } else if (status == Statuses.Expired) {
      cls += " tylie-badge--error"
    }
    return cls;
  }

  public editNote(taggerAd: TaggedAdResponseModel) {
    const initialState = {
      orderGuid: taggerAd.orderGuid,
      payload: taggerAd.note? taggerAd.note.payload : ""
    };
    this.bsModalRef = this.modalService.show(TaggerOrderNotepopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSaveVaultAssetNote.subscribe((result: OrderNoteResponse) => {
      if (result != null && result != undefined) {
        var dOrder = this.taggerAds.find(d => d.orderGuid = taggerAd.orderGuid);
        dOrder['note'] = {
          payload: result.payload,
          createdBy: result.createdBy,
          createdDate: result.createdDate
        }
      }
    });
  }

  public onUnArchive(taggerAd: TaggedAdResponseModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: any = {
      orderGuid: taggerAd.orderGuid,
      lineItemGuid: taggerAd.lineItemGuid,
      adGuid: taggerAd.adGuid,
    }
    this.taggerService.unArchiveOrder(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Success", Constants.success_unarchive_order, MessageSeverity.success, true);
          this.loadArchiveTaggerAds();
        } else {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Failed", Constants.failed_unarchive_order, MessageSeverity.error, true);
        }
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.showMessage("Failed", Constants.failed_unarchive_order, MessageSeverity.error, true);
        this.alertService.ShowLoader(false);
      }
    );
  }

}
