<ng-container>
  <div class="row" *ngFor="let t of baQuestionOpptions; index as i;">
    <div class="tylie-form-group form-group col-md-4" [ngClass]="{error: (formGroup.controls['passthroughdata'].errors && f.submitted && (t.itemdescription == null || t.itemdescription == ''))}">
      <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Item Description<span class="required">*</span></label>
      <div class="d-flex">
        <input type="text"
               class="tylie-text form-control"
               [ngModelOptions]="{standalone: true}"
               [(ngModel)]="t.itemdescription"
               [disabled]="isDisabled"
               (ngModelChange)="onChange()"
               maxlength="40" />
      </div>
    </div>
    <div class="tylie-form-group form-group col-md-5" [ngClass]="{error: (formGroup.controls['passthroughdata'].errors && f.submitted && (t.refno == null || t.refno == ''))}">
      <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Ref No<span class="required">*</span></label>
      <div class="d-flex">
        <input type="text"
               class="tylie-text form-control"
               [ngModelOptions]="{standalone: true}"
               [(ngModel)]="t.refno"
               [disabled]="isDisabled"
               (ngModelChange)="onChange()" />
      </div>
    </div>
    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['passthroughdata'].errors && f.submitted && (t.invoiceamount == null || t.invoiceamount == ''))}">
      <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Amount<span class="required">*</span></label>
      <div class="tylie-text-group input-group">
        <span class="input-group-text">$</span>
        <dx-number-box [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="t.invoiceamount"
                       [disabled]="isDisabled"
                       (ngModelChange)="onChange()"
                       class="tylie-select form-control"
                       [showSpinButtons]="false">
        </dx-number-box>
        <a class="tylie-button--xs tylie-button--link mb-2" *ngIf="t.id > 1 && isDisabled === false" (click)="onAddNew(t.id)"><icon-expire color="#1e90ff"></icon-expire></a>
        <a class="tylie-button--xs tylie-button--link mb-2" *ngIf="t.id == 1 && isDisabled === false" (click)="onAddNew(t.id)"><icon-plus-circle color="#1e90ff"></icon-plus-circle></a>
      </div>
    </div>
  </div>
</ng-container>
