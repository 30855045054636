import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { Utilities } from "../../../services/core/utilities";
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserPermissionLevel, Project } from "../../../models/project/project.model";
import { Subject ,  Subscription } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ProjectFolder } from '../../../models/project/create-project.model';
import { ClientUserModel } from "../../../models/project/project.model";
import { AddUserPermissionToProjectService } from '../../../services/project/add-user-permission.service';
import { clientEditScopeEnum } from "../../../configurations/enums/enums";
import { ManageProjectUsersComponent } from "../manage-project-users/manage-project-users.component";
import { resolveMx } from "dns";


@Component({
  selector: 'user-permission',
  templateUrl: './adduserpermissionpopup.component.html',
  styleUrls: ['./adduserpermissionpopup.component.css'],
})

export class AddUserPermissionToProjectPopUp implements OnInit {

  public onClose: Subject<Boolean>;
  public modalRef: BsModalRef;
  manageUserForm: FormGroup;
  subscriptions: Subscription[] = [];
  public folders: ProjectFolder[];
  public permissionLevel: UserPermissionLevel[] = [];
  public clientUsers: ClientUserModel[];
  public addUsers: ClientUserModel[] = [];
  public prevAssignedUsers: ClientUserModel[] = [];
  public assignedUsers: ClientUserModel[] = [];
  @Input() project: Project;
  @ViewChild('treeFolder', { static: false }) treeFolder: DxTreeViewComponent;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private addUserPermissionService: AddUserPermissionToProjectService) {
    this.onClose = new Subject<Boolean>();
  }

  ngOnInit() {
    this.getUsersByClient();
  }

  public addMangeUserGroupsToFormArray() {
    var manageUsers = this.fb.array([]);
    this.assignedUsers.forEach(x => {
      var group = this.fb.group({
        id: [x.fileCollectionId],
        userName: x.userName,
        userProfileId: x.userProfileId,
        userPermissionLevelId: x.userPermissionLevelId,
        folderId: x.fileCollectionId,
        folderValues: [x.selectedFolders]
      });
      manageUsers.push(group);
    });
    return manageUsers;
  }

  public get getFormManageUserGroupsFormArray() {
    return <FormArray>this.manageUserForm.get('manageUsers');
  }

  public getUsersByClient(): void {
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.addUserPermissionService.getUsersByClient(this.project).subscribe((res) => {
      this.alertService.ShowLoader(false);
      this.permissionLevel = res.userPermissionLevels;
      this.folders = res.folders;
      this.folders.push({ fileCollectionId: this.project.fileCollectionId, collectionName: "Entire Project" });
      this.clientUsers = res.manageClientUsers;
      this.addUsers = res.addClientUsers;
      this.prevAssignedUsers = res.userAccessProjects;
      this.setAssignedUsers(res.userAccessProjects);
      this.manageUserForm = this.fb.group({
        manageUsers: this.addMangeUserGroupsToFormArray()
      });
    }, error => {
      if (this.util.handleError(error))
        this.closePopup();
    }))
  }

  public setAssignedUsers(projectUsers) {
    let id = 0;
    this.clientUsers.forEach(user => {
      var client = new ClientUserModel();
      var users = [];
      users = projectUsers.filter((x: { userProfileId: number; }) => x.userProfileId == user.userProfileId);
      if (users.length > 1) {
        client.fileCollectionId = id++;
        client.userName = users[0].userName;
        client.userProfileId = users[0].userProfileId;
        client.userPermissionLevelId = users[0].userPermissionLevelId;
        users.forEach(f => {
          client.selectedFolders.push(f.fileCollectionId)
        })
        this.assignedUsers.push(client)
      }
      else if (users.length == 1) {
        client.fileCollectionId = id++;
        client.userName = users[0].userName;
        client.userProfileId = users[0].userProfileId;
        client.userPermissionLevelId = users[0].userPermissionLevelId;
        client.selectedFolders.push(users[0].fileCollectionId);
        this.assignedUsers.push(client)
      }
    });
  }

  public syncTreeViewSelection(e = null, user) {
    var component = (e && e.component);

    if (!component) return;
    if (user.controls.folderValues.value) {
      component.unselectAll();
      user.controls.folderValues.value.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e, user) {
    var folderId = e.itemData.fileCollectionId as number;
    var folderSelected = user.controls['folderValues'].value as number[];
    if (e.itemData.selected)
      folderSelected.push(folderId);
    else
      folderSelected = folderSelected.filter(y => y != folderId);
    folderSelected = folderSelected.filter((value, index) => folderSelected.indexOf(value) == index);

    this.prevAssignedUsers.forEach(x => {
      if (x.userProfileId == user.controls.userProfileId.value && x.fileCollectionId == folderId)
        if (!e.itemData.selected)
          x.isActive = false;
        else
          x.isActive = true;
    });

    let fc = user.controls['folderValues'] as FormControl;
    fc.patchValue(folderSelected);
  }

  public isUserPresent(user: ClientUserModel) {
    let presentUser = this.prevAssignedUsers.find(
      x => x.userProfileId == user.userProfileId &&
        x.fileCollectionId == user.fileCollectionId
        && x.userPermissionLevelId == user.userPermissionLevelId);
    return presentUser != null;
  }

  public inActivateUser(user) {
    user.folderValues.map(fId => {
      this.prevAssignedUsers.forEach(x => {
        if (x.fileCollectionId == fId && x.userProfileId == user.userProfileId)
          x.isActive = false;
      })
    })
  }

  public permissionModifiedUser(rmUser) {
    this.prevAssignedUsers.forEach(x => {
      if (x.userProfileId == rmUser.userProfileId
        && x.fileCollectionId == rmUser.fileCollectionId
        && x.userPermissionLevelId != rmUser.userPermissionLevelId)
        x.isActive = false;
    })
  }

  public onClickRemoveUser(i, user) {
    var manageUsers = this.getFormManageUserGroupsFormArray;
    this.inActivateUser(user);
    manageUsers.removeAt(i);
  }

  public addUserPermissionFormSubmit() {
    let postAddUsers = [];
    let postRemoveUsers = [];
    this.manageUserForm.value.manageUsers.forEach(user => {
      if (user.folderValues.find(x => x == this.project.fileCollectionId) != null) {
        user.fileCollectionId = this.project.fileCollectionId;
        user.isActive = true;
        user.folderValues = user.folderValues.filter(x => x != this.project.fileCollectionId);
        this.inActivateUser(user);
        this.permissionModifiedUser(user)
        if (!this.isUserPresent(user))
          postAddUsers.push(JSON.parse(JSON.stringify(user)));
      } else {
        user.folderValues.forEach(folderId => {
          user.fileCollectionId = folderId;
          user.isActive = true;
          this.permissionModifiedUser(user);
          if (!this.isUserPresent(user))
            postAddUsers.push(JSON.parse(JSON.stringify(user)));
        });
      }
    })

    postRemoveUsers = this.prevAssignedUsers.filter(x => x.isActive == false);

    if (this.manageUserForm.valid) {
      postAddUsers = postAddUsers.filter((value, index) => postAddUsers.indexOf(value) == index);
      let postData = postRemoveUsers.concat(postAddUsers);
      if (postData.length == 0)
        return

      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.addUserPermissionService.assignUserPermission(postData, this.project.fileCollectionId).subscribe((res) => {
        if (res.isSuccess) {
          this.alertService.ShowLoader(false);
          this.onClose.next(true);
          this.closePopup();
        }
      }, error => {
        if (this.util.handleError(error))
          this.closePopup();
      }));
    }
  }

  public addNewUser() {
    this.closePopup();
    var initialState = {
      folders: this.folders,
      userPermissions: this.permissionLevel,
      users: this.addUsers,
      project: this.project
    }
    this.modalRef = this.modalService.show(ManageProjectUsersComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onClose.subscribe((result: Boolean) => {
      if (result) {
        this.alertService.showMessage("SUCCESS", "Permission set Successfully.", MessageSeverity.success);
      }
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
