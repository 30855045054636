import { Injectable } from '@angular/core';

import { ApiEndPoint } from '../../configurations/api-endpoint.service';
import { OrderClientDestination } from '../../models/order/order-clientdestination.model';
import { SearchUserDestinationRequest } from '../../models/order/order-searchuserdestination-request.model';
import { OrderUserDestination } from '../../models/order/order-userdestination';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getClientBrands(clientId: number) {
    return this.http.get(this.apiendPoint.getClientBrandsEndpoint() + "/" + clientId);
  }

  public getBrands() {
    return this.http.get(this.apiendPoint.getBrandsEndpoint());
  }

  public getCurrentBrands() {
    return this.http.get(this.apiendPoint.getCurrentBrandsEndpoint());
  }

  public searchCurrentBrands(searchString: string) {
    return this.http.get(this.apiendPoint.searchCurrentBrandsEndPoint() + "/" + searchString);
  }

  public getProducts() {
    return this.http.get(this.apiendPoint.getProductsEndpoint());
  }

  public getCurrentProducts() {
    return this.http.get(this.apiendPoint.getCurrentProductsEndPoint());
  }

  public getBrandProducts(brandId: number, clientId) {
    return this.http.get(this.apiendPoint.getBrandProductsEndpoint() + "/" + brandId + "/" + clientId);
  }

  public getCurrentBrandProducts(brandId: number, clientId) {
    return this.http.get(this.apiendPoint.getCurrentBrandProductsEndpoint() + "/" + brandId + "/" + clientId);
  }

  public getCurrentProductAgencies(brandId: number, productId) {
    return this.http.get(this.apiendPoint.getCurrentProductAgenciesEndpoint() + "/" + brandId + "/" + productId);
  }

  public getAdminCurrentBrandProducts(brandId: number, clientId, agencyId: any = null) {
    return this.http.get(this.apiendPoint.getAdminCurrentBrandProductsEndpoint() + "/" + brandId + "/" + clientId + "/" + agencyId);
  }

  public getClientBrandProducts(clientId: number, brandId: number) {
    return this.http.get(this.apiendPoint.getClientBrandProductsEndpoint() + "/" + clientId + "/" + brandId);
  }

  public getCurrentClientBrandProducts(clientId: number, brandId: number) {
    return this.http.get(this.apiendPoint.getCurrentClientBrandProductsEndpoint() + "/" + clientId + "/" + brandId);
  }

  public getBrandProductsForList(brandIds: number[]) {
    return this.http.post(this.apiendPoint.getBrandProductsForListEndpoint(), brandIds);
  }

  public getUploadConfig() {
    return this.http.get(this.apiendPoint.getUploadConfigEndpoint());
  }

  public getUploadOrderConfig() {
    return this.http.get(this.apiendPoint.getUploadOrderConfigEndpoint());
  }
  
  public getServiceCategories() {
    return this.http.get(this.apiendPoint.getServiceCategoriesEndpoint());
  }

  public getCreateOrderConfig(config: any) {
    return this.http.post(this.apiendPoint.getCreateOrderConfigEndpoint(), config);
  }

  public getCreateVaultConfig(config: any) {
    return this.http.post(this.apiendPoint.getCreateVaultConfigEndpoint(), config);
  }

  public getTranscodeOrderConfig() {
    return this.http.get(this.apiendPoint.getTrasncodeOrderConfigEndpoint());
  }

  public getTalentRightsConfig() {
    return this.http.get(this.apiendPoint.getTalentRightsConfigEndpoint());
  }

  public searchDestinations(request: any) {
    return this.http.post(this.apiendPoint.searchDestinationsEndpoint(), request);
  }

  public validateDestinationsFromText(destinationsText: any) {
    return this.http.post(this.apiendPoint.validateDestinationsTextEndpoint(), destinationsText);
  }

  public validateDestinationsFromFile(request: any) {
    return this.http.post(this.apiendPoint.validateDestinationsFromFileEndpoint(), request);
  }

  public getClientFtpDestinations(clientId: number) {
    return this.http.get(this.apiendPoint.getClientFtpDestinationsEndpoint() + "/" + clientId);
  }

  public createClientFtpDestination(request: OrderClientDestination) {
    return this.http.post(this.apiendPoint.createClientFtpDestinationEndpoint(), request);
  }

  public searchUserDestinations(request: SearchUserDestinationRequest) {
    return this.http.post(this.apiendPoint.searchUserDestinationEndpoint(), request);
  }

  public searchFTPDestinations(request: SearchUserDestinationRequest) {
    return this.http.post(this.apiendPoint.searchFTPDestinationEndpoint(), request);
  }

  public searchShareLinkDestinations(request: any) {
    return this.http.post(this.apiendPoint.searchShareLinkDestinations(), request);
  }

  public getUserSavedDestinationLists(category: string) {
    return this.http.get(this.apiendPoint.getUserSavedDestinationListsEndpoint() + "/" + category);
  }

  public addToUserSavedDestinationList(id: number, category: string, destinations: Array<string>) {
    return this.http.put(this.apiendPoint.addToUserSavedDestinationListEndpoint() + "/" + id + "/" + category, destinations);
  }

  public updateUserSavedDestinationList(id: number, category: string, destList: any) {
    return this.http.put(this.apiendPoint.updateUserSavedDestinationListEndpoint() + "/" + id + "/" + category, destList);
  }

  public createUserSavedDestinationList(name: string, category: string, destinations: Array<string>) {
    return this.http.put(this.apiendPoint.createUserSavedDestinationListEndpoint() + "/" + name + "/" + category, destinations);
  }

  public deleteUserSavedDestinationList(id: number, category: string) {
    return this.http.delete(this.apiendPoint.deleteUserSavedDestinationListEndpoint() + "/" + id + "/" + category);
  }

  public deleteUserFtpDestination(id: number) {
    return this.http.delete(this.apiendPoint.deleteUserFtpDestinationEndpoint() + "/" + id);
  }

  public createUserFtpDestination(request: OrderUserDestination) {
    return this.http.post(this.apiendPoint.createUserFtpDestinationEndpoint(), request);
  }

  public updateUserFtpDestination(request: OrderUserDestination) {
    return this.http.post(this.apiendPoint.updateUserFtpDestinationEndpoint(), request);
  }

  public getBrandSelectionConfig() {
    return this.http.get(this.apiendPoint.getBrandSelectionConfigEndpoint());
  }

  public getCreateClientConfig() {
    return this.http.get(this.apiendPoint.getCreateClientConfigEndpoint());
  }

  public getClientSearchConfig() {
    return this.http.get(this.apiendPoint.getClientSearchConfigEndPoint());
  }

  public getBrandSearchConfig() {
    return this.http.get(this.apiendPoint.getBrandSearchConfigEndPoint());
  }

  public getCreateBrandConfig() {
    return this.http.get(this.apiendPoint.getCreateBrandConfigEndPoint());
  }

  public getCostCenterAndInvoice(request: any) {
    return this.http.post(this.apiendPoint.getCostCenterAndInvoiceEndPoint(), request);
  }

  public getOrderedByData(clientId: number) {
    return this.http.get(this.apiendPoint.getOrderedByDataEndPoint() + "/" + clientId);
  }

  public getAssignVendorConfig(request: any) {
    return this.http.post(this.apiendPoint.getAssignVendorConfigEndPoint(), request);
  }

  public getUserAdvancedSearchConfig() {
    return this.http.get(this.apiendPoint.getUserAdvancedSearchConfigEndPoint());
  }

  public getUserHomeConfig() {
    return this.http.get(this.apiendPoint.getUserHomeConfigEndPoint());
  }

  public getClientPostHouses(clientId: number) {
    return this.http.get(this.apiendPoint.getClientPostHousesEndPoint() + "/" + clientId);
  }

  public getClientUserGroups(clientId: number) {
    return this.http.get(this.apiendPoint.getClientUserGrpEndPoint() + "/" + clientId);
  }

  public getCreateUserConfig(userType: string) {
    return this.http.get(this.apiendPoint.getCreateUserConfigEndPoint() + "/" + userType);
  }

  public getCurrentClientBrands(clientId: number) {
    return this.http.get(this.apiendPoint.getCurrentClientBrandsEndPoint() + "/" + clientId);
  }

  public getClientSpecialVisibilityClients(clientId: number) {
    return this.http.get(this.apiendPoint.getClientSpecialVisibilityClientEndPoint() + "/" + clientId);
  }

  public getAdminCurrentClientBrands(clientId: number, agencyId: number) {
    return this.http.get(this.apiendPoint.getCurrentClientBrandsEndPoint() + "/" + clientId + "/" + agencyId);
  }

  public getCreateUserProfileConfig(userType: string, userName: string) {
    return this.http.get(this.apiendPoint.getCreateUserProfileConfigEndPoint() + "/" + userType + "/" + userName);
  }

  public getSearchDestinationConfig() {
    return this.http.get(this.apiendPoint.getSearchDestinationConfigEndPoint());
  }

  public getDigitalVendorsForDestinationMapping() {
    return this.http.get(this.apiendPoint.getDigitalVendorsForDestinationMappingEndPoint());
  }

  public getCreateDestinationConfig() {
    return this.http.get(this.apiendPoint.getCreateDestinationConfigEndPoint());
  }

  public getClientProducts(clientId: number) {
    return this.http.get(this.apiendPoint.getclientProductsEndpoint() + "/" + clientId);
  }

  public getQuickLinkConfig() {
    return this.http.get(this.apiendPoint.getQuickLinkConfigEndPoint());
  }

  public getStatesByCountry(code: string) {
    return this.http.get(this.apiendPoint.getStatesByCountryEndPoint() + "/" + code);
  }

  public getClientCustomTagConfig() {
    return this.http.get(this.apiendPoint.getClientCustomTagConfigEndPoint());
  }

  public getClientBundleServicesConfig() {
    return this.http.get(this.apiendPoint.getClientBundleServicesConfigEndPoint());
  }

  public clearCache() {
    return this.http.get(this.apiendPoint.clearCacheEndPoint());
  }

  public getDistributionMediaAdvancedSearchConfig() {
    return this.http.get(this.apiendPoint.getDistributionMediaAdvancedSearchConfigEndPoint());
  }

  public searchSpecifications(searchOptions: any) {
    return this.http.post(this.apiendPoint.getSearchSpecificationsEndPoint(), searchOptions);
  }

  public encryptText(request: any) {
    return this.http.post(this.apiendPoint.getEncryptTextEndPoint(), request);
  }

  public getTranscodeSpecs() {
    return this.http.get(this.apiendPoint.getTranscodeSpecsEndPoint());
  }

  public getOrderStatusesForITOPs() {
    return this.http.get(this.apiendPoint.getOrderStatusesForITOPsEndPoint());
  }

  public getServiceLevelsForITOPs() {
    return this.http.get(this.apiendPoint.getServiceLevelsForITOPsEndPoint());
  }

  public checkSpotPattern(files: any) {
    return this.http.post(this.apiendPoint.checkSpotPatternEndPoint(), files);
  }

  public getVendorsForSendOrerEmail() {
    return this.http.get(this.apiendPoint.getVendorsForSendOrerEmailEndpoint());
  }
}
