import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { RouteConstants } from "../../../../configurations/route-constants";
import { SelectedProductsComponent } from "../selected-products/selected-products.component";
import { Subscription } from "rxjs";
import { BrandDetail, EditBrandRequest } from "../../../../models/admin/brand/branddetail.model";
import { Constants } from "../../../../configurations/constants";
import { brandDetailSource, brandEditScopeEnum } from "../../../../configurations/enums/enums";

@Component({
  selector: 'viewedit-brand',
  templateUrl: './viewedit-brand.component.html',
  styleUrls: ['./viewedit-brand.component.css']
})
export class ViewEditBrandComponent implements OnInit {

  @ViewChild(SelectedProductsComponent, { static: false }) productSelectedComp: SelectedProductsComponent;
  public editBrandFormGroup: FormGroup;

  @Input('brandGuid') brandGuid: string;

  public brandDetail: BrandDetail = null;
  public brandsRoute = RouteConstants.brandsRoute;
  //public brandGuid: string = null;
  public editMode: boolean = false;
  public resetProducts: boolean = false;
  private subscription: Subscription;
  public source: string;
  public shortNameValue: string;

  constructor(public util: Utilities
    , private adminService: AdminService
    , private alertService: AlertService
    , private route: ActivatedRoute
    , private router: Router) {

  }

  ngOnInit() {
    this.editBrandFormGroup = new FormGroup({
      brandName: new FormControl(null, Validators.required),
      shortName: new FormControl(null, [Validators.required, Validators.maxLength(16)]),
      location: new FormControl(1, Validators.required)
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.source = params['source'] || '';
    });

    if (this.brandGuid == null || this.brandGuid == undefined) {
      this.route.params.subscribe((p: Params) => {
        this.brandGuid = p.brandGuid;
      });
    }

    this.getBrandDetail(this.brandGuid, false);

    //this.subscription = this.route.params.subscribe((params: Params) => {
    //  this.brandGuid = params['brandGuid'];
    //});
  }

  public onEditBrand() {
    this.getBrandDetail(this.brandGuid, true)
  }

  public gotoBrandHome() {
    this.router.navigate([RouteConstants.brandsRoute]);
  }

  public openBrandInEditMode() {
    this.editMode = true;
    this.resetProducts = false;
    this.updateEditForm();
    this.updateBrandEditConfig(this.brandDetail);
  }

  public onFormSubmit({ value, valid }: { value: EditBrandRequest, valid: boolean }) {
    if (!valid) {
      return;
    }
    else {
      var deletedProducts = this.productSelectedComp.getRemovedProducts();
      value.deletedProducts = [];

      if (deletedProducts != null && deletedProducts != undefined) {
        value.deletedProducts = deletedProducts;
      }
      this.editBrand(value);
    }
  }

  public onCancelBrandEdit() {
    this.source = '';
    this.editMode = false;
    this.resetProducts = true;
    this.getBrandDetail(this.brandGuid, false);
  }

  private getBrandDetail(brandGuid: string, openInEditMode: boolean) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getBrandDetail(brandGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.brandDetail = res.result as BrandDetail;
        console.log(this.brandDetail);
        if ((this.source == brandDetailSource[brandDetailSource.create]) || openInEditMode == true) {
          this.openBrandInEditMode();
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onBrandStatusUpdate(isCurrent: boolean) {
    this.adminService.updateBrandStatus(this.brandGuid, { isCurrent: isCurrent }).subscribe((res: any) => {
      var result = res.result;
      this.brandDetail = result as BrandDetail;
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.brandStatusUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private updateEditForm() {
    this.editBrandFormGroup.controls['brandName'].setValue(this.brandDetail.brandName);
    this.editBrandFormGroup.controls['shortName'].setValue(this.brandDetail.shortName);
    this.editBrandFormGroup.controls['location'].setValue(this.brandDetail.location);

    this.shortNameValue = this.editBrandFormGroup.controls['shortName'].value;
  }

  private editBrand(value: EditBrandRequest) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.adminService.editBrand(value, this.brandGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.editMode = false;
        this.resetProducts = true;
        this.brandDetail = res.result as BrandDetail;
        this.alertService.showMessage("SUCCESS", Constants.editBrandSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private updateBrandEditConfig(brandDetail: BrandDetail) {

    brandDetail.config.canEditHeader = brandDetail.config.editScope.some(t => t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.Header].toLowerCase()) === true;

    brandDetail.config.canAddProduct = brandDetail.config.editScope.some(t => t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.AddProduct].toLowerCase()) === true;

    brandDetail.config.canEditProduct = brandDetail.config.editScope.some(t => t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.EditProduct].toLowerCase()) === true;

    brandDetail.config.canDeleteProduct = brandDetail.config.editScope.some(t => t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === brandEditScopeEnum[brandEditScopeEnum.DeleteProduct].toLowerCase()) === true;
  }
}
