<div id="share-links">
  <div class="modal-header">
    <button type="button" class="close delete" (click)="closePopup()"></button>
    <h4 class="modal-title">EDIT - FTP UPLOAD</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">

      </div>
      <div>
        <form [formGroup]="createFtpDestinationRequest" #ftpForm="ngForm" (ngSubmit)="onCreateFtpDestinationFormSubmit(createFtpDestinationRequest)">
          <p class="gray1 normal col-md-12">Edit FTP</p>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{error: createFtpDestinationRequest.controls['name'].errors && ftpForm.submitted}">
              <label for="name">Name<span class="required">*</span></label>
              <input type="text" id="name" name="name" class="form-control" formControlName="name">
              <p class="p-extra-small">Please enter a name for FTP</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpUserName'].errors && ftpForm.submitted}">
              <label for="ftpusername">FTP Username<span class="required">*</span></label>
              <input type="text" id="ftpusername" name="ftpusername" class="form-control" formControlName="ftpUserName">
              <p class="p-extra-small">Please enter Username</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpServer'].errors && ftpForm.submitted}">
              <label for="server">FTP Server<span class="required">*</span></label>
              <input type="text" id="server" name="server" class="form-control" formControlName="ftpServer">
              <p class="p-extra-small">Please enter FTP Server</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" [ngClass]="{error: createFtpDestinationRequest.controls['ftpPassword'].errors && ftpForm.submitted}">
              <label for="ftppassword">FTP Password<span class="required">*</span></label>
              <input type="password" id="ftppassword" name="ftppassword" class="form-control" formControlName="ftpPassword">
              <p class="p-extra-small">Please enter FTP Password</p>
            </div>
          </div>
          <div class="col-md-12">
            <button class="new-default-btn x-small-btn add-group-btn" type="submit">
              SAVE
            </button>
            <span class="qc-cancel p-normal" (click)="closePopup()">Cancel</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
