<!--<nav-header headerTitle="Client" link="Back To All Clients" [linkRoute]="clientsRoute"></nav-header>-->

<ng-container *ngIf="client">
  <form novalidate (ngSubmit)="onFormSubmit(editClientFormGroup)" [formGroup]="editClientFormGroup" role="search" #f="ngForm">
    <div class="tylie-panel tylie-panel--clean" id="createclient">
        <div class="tylie-panel__body" id="createClientBody">
          <div class="row">
            <div class="col-md-3 " *ngIf="client.config.canEditHeader == false">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="aliasId">Client Name</label>
                <div class="tylie-form-group__txt">{{client.clientName}}</div>
              </div>
            </div>
            <div class="col-md-3" *ngIf="client.config.canEditHeader == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['clientName'].errors && !editClientFormGroup.controls['clientName'].pristine)
                || (editClientFormGroup.controls['clientName'].errors && f.submitted)}">
                <label for="ClientName" class="tylie-form-group__lbl">Client Name<span class="required">*</span></label>
                <input formControlName="clientName" type="text" id="clientName" name="clientName" class="tylie-text form-control " />
                <p class="p-extra-small">Please enter Client Name</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Type</label>
                <div class="tylie-form-group__txt">{{client.clientType}}</div>
              </div>
            </div>

            <div class="col-md-3 " *ngIf="client.config.canEditHeader == false">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" for="aliasId">CX Manager</label>
                <div class="tylie-form-group__txt">{{client.accountExecutiveName}}</div>
              </div>
            </div>
            <div class="col-md-3" *ngIf="client.config.canEditHeader == true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" for="status">CX Manager</label>
                <dx-select-box [dataSource]="client.config.accountExecutives"
                                name="accountExecutive" id="accountExecutive" class="tylie-select form-control"
                                formControlName="accountExecutive"
                                [searchEnabled]="true"
                                valueExpr="userProfileId"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Account Executive">
                </dx-select-box>
              </div>
            </div>

            <div class="col-md-3" *ngIf="client.aliasId && client.config.canEditClientId == false">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" for="aliasId">Client ID</label>
                <div class="tylie-form-group__txt">{{client.aliasId}}</div>
              </div>
            </div>

            <div class="col-md-3" *ngIf="client.config.canEditClientId == true">
              <div class="tylie-form-group form-group " [ngClass]="{error: (editClientFormGroup.controls['aliasId'].errors && !editClientFormGroup.controls['aliasId'].pristine)
                || (editClientFormGroup.controls['aliasId'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="ClientName">Client ID<span class="required">*</span></label>
                <input formControlName="aliasId" type="text" id="aliasId" name="aliasId" class="tylie-text form-control " />
                <p class="p-extra-small" *ngIf="(editClientFormGroup.controls['aliasId'].hasError('required')
                    && !editClientFormGroup.controls['aliasId'].pristine)
                || (editClientFormGroup.controls['aliasId'].hasError('required') && f.submitted)">Please enter Client ID</p>

                <p class="p-extra-small" *ngIf="(editClientFormGroup.controls['aliasId'].hasError('inValidAliasId')
                    && !editClientFormGroup.controls['aliasId'].pristine)
                || (editClientFormGroup.controls['aliasId'].hasError('inValidAliasId') && f.submitted)">Client ID can have only digits and value cannot be zero</p>
              </div>
            </div>
            <div class="col-md-3 line-height" *ngIf="this.client.isClientTypePartner">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="partnerClient">Billable Client<span class="required">*</span></label>
                <dx-drop-down-box [dataSource]="partnerClientDataSource"
                                  [(value)]="treeBoxValue"
                                  name="partnerClient" id="partnerClient" class="tylie-select form-control"
                                  formControlName="partnerClient"
                                  valueExpr="id"
                                  [showClearButton]="true"
                                  displayExpr="name"
                                  placeholder="Select Clients"
                                  (onValueChanged)="syncTreeViewSelection()">

                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="partnerClientDataSource"
                                  dataStructure="plain"
                                  keyExpr="id"
                                  selectionMode="multiple"
                                  showCheckBoxesMode="normal"
                                  [selectNodesRecursive]="false"
                                  [searchEnabled]="true"
                                  displayExpr="name"
                                  [selectByClick]="true"
                                  (onContentReady)="syncTreeViewSelection($event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please select Formats</p>
              </div>
            </div>
          </div>
        </div>
    </div>
    
    <div class="row">    
      <div class="col-md-6" id="create-client-section" *ngIf="client.config.canAddEditContact == false">
        <div class="tylie-panel" id="createclient">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              CONTACT DETAILS
            </h4>
          </div>
          <div class="tylie-panel__body" id="createClientBody">
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Name</label>
                  <div class="tylie-form-group__txt">{{client.name}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Address 1</label>
                  <div class="tylie-form-group__txt">{{client.address1}}</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Address 2</label>
                  <div class="tylie-form-group__txt">{{client.address2}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">City</label>
                  <div class="tylie-form-group__txt">{{client.city}}</div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">State</label>
                  <div class="tylie-form-group__txt">{{client.stateDisplay}}</div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Zip Code</label>
                  <div class="tylie-form-group__txt">{{client.zipCode}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Country</label>
                  <div class="tylie-form-group__txt">{{client.country}}</div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Phone</label>
                  <div class="tylie-form-group__txt">{{client.phone}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Email</label>
                  <div class="tylie-form-group__txt">{{client.email}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Team Email</label>
                  <div class="tylie-form-group__txt">{{client.teamEmail}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6" id="create-client-section" *ngIf="client.config.canAddEditContact == true">
        <div class="tylie-panel" id="createclient">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              CONTACT DETAILS
            </h4>
          </div>
          <div class="tylie-panel__body" id="createClientBody">
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['name'].errors && !editClientFormGroup.controls['name'].pristine)
                    || (editClientFormGroup.controls['name'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="name">Name<span class="required">*</span></label>
                  <input type="text" name="name" id="name" class="tylie-text form-control" formControlName="name">
                  <p class="p-extra-small">Please enter a contact name</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['address1'].errors && !editClientFormGroup.controls['address1'].pristine)
                  || (editClientFormGroup.controls['address1'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="Address1">Address 1<span class="required">*</span></label>
                  <input formControlName="address1" type="text" id="address1" name="address1" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter Address 1</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="Address2">Address 2</label>
                  <input formControlName="address2" type="text" id="address2" name="address2" class="tylie-text form-control " />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['city'].errors && !editClientFormGroup.controls['city'].pristine)
                  || (editClientFormGroup.controls['city'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="City">City<span class="required">*</span></label>
                  <input formControlName="city" type="text" id="city" name="city" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter City</p>
                </div>
              </div>
              <div class="col-md-3" [ngClass]="{error: (editClientFormGroup.controls['state'].errors && !editClientFormGroup.controls['state'].pristine)
                  || (editClientFormGroup.controls['state'].errors && f.submitted)}">
                  <div class="tylie-form-group">
                    <label class="tylie-form-group__lbl" for="status">State</label>
                    <dx-select-box [dataSource]="client.config.states"
                                    name="state" id="state" class="tylie-select form-control"
                                    formControlName="state"
                                    [searchEnabled]="true"
                                    valueExpr="id"
                                    [showClearButton]="true"
                                    displayExpr="name"
                                    placeholder="State">
                    </dx-select-box>
                    <p class="p-extra-small">Please choose a state</p>
                  </div>
              </div>
              <div class="col-md-3">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['zipCode'].errors && !editClientFormGroup.controls['zipCode'].pristine)
                  || (editClientFormGroup.controls['zipCode'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="ZipCode">Zip Code<span class="required">*</span></label>
                  <input formControlName="zipCode" type="text" id="zipCode" name="zipCode" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter a valid Zip Code</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6" [ngClass]="{error: (editClientFormGroup.controls['country'].errors && !editClientFormGroup.controls['country'].pristine)
                  || (editClientFormGroup.controls['country'].errors && f.submitted)}">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="country">Country</label>
                    <dx-select-box [dataSource]="client.config.countries"
                                    name="country" id="country" class="tylie-select form-control"
                                    formControlName="country"
                                    (onValueChanged)="onCountryValueChanged($event)"
                                    placeholder="Country">
                    </dx-select-box>
                    <p class="p-extra-small">Please choose a Country</p>
                  </div>
                
              </div>
              <div class="col-md-6">
                <div class="tylie-form-group form-group " [ngClass]="{error: (editClientFormGroup.controls['phone'].errors && !editClientFormGroup.controls['phone'].pristine)
                  || (editClientFormGroup.controls['phone'].errors && f.submitted) }">
                  <label class="tylie-form-group__lbl" for="Phone">Phone<span class="required">*</span></label>
                  <input formControlName="phone" type="text" id="phone" name="phone" class="tylie-text form-control " [textMask]="{mask: phoneMask, guide: false, modelClean: true}" />
                  <p class="p-extra-small">Please enter a valid Phone</p>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group " [ngClass]="{error: (editClientFormGroup.controls['email'].errors && !editClientFormGroup.controls['email'].pristine)
                  || (editClientFormGroup.controls['email'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="Email">Email<span class="required">*</span></label>
                  <input formControlName="email" type="text" id="email" name="email" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter Email</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['teamEmail'].errors && !editClientFormGroup.controls['teamEmail'].pristine)
                  || (editClientFormGroup.controls['teamEmail'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="TeamEmail">Team Email<span class="required">*</span></label>
                  <input formControlName="teamEmail" type="text" id="teamEmail" name="teamEmail" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter Team Email</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="col-md-6" id="create-client-section">
        <div class="tylie-panel" id="createclient">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              OTHER
            </h4>
          </div>
          <div class="tylie-panel__body" id="createClientBody">
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">PO Required</label>
                  <div class="tylie-form-group__txt">{{client.poRequired == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="poRequired" name="poRequired" id="poRequired">
                  <label class="form-check-label" for="poRequired">PO Required</label>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">Order Review Required</label>
                  <div class="tylie-form-group__txt">{{client.orderReviewRequired == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="orderReviewRequired" name="orderReviewRequired" id="orderReviewRequired">
                  <label for="orderReviewRequired" class="form-check-label">Order Review Required</label>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">Vault Billing</label>
                  <div class="tylie-form-group__txt">{{client.isVaultBilling == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="isVaultBilling" name="isVaultBilling" id="isVaultBilling">
                  <label for="isVaultBilling" class="form-check-label">Vault Billing</label>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">Order Final Review Required</label>
                  <div class="tylie-form-group__txt">{{client.orderFinalReviewRequired == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="orderFinalReviewRequired" name="orderFinalReviewRequired" id="orderFinalReviewRequired">
                  <label for="orderFinalReviewRequired" class="form-check-label">Order Final Review Required</label>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">Restricted View</label>
                  <div class="tylie-form-group__txt">{{client.isViewRestricted == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="isViewRestricted" name="isViewRestricted" id="isViewRestricted">
                  <label for="isViewRestricted" class="form-check-label">Restricted View</label>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canEditOthers == false">
                  <label class="tylie-form-group__lbl">Is On Trial</label>
                  <div class="tylie-form-group__txt">{{client.isOnTrail == true ? 'Yes' : 'No'}}</div>
                </div>

                <div class="tylie-form-group form-check" *ngIf="client.config.canEditOthers == true">
                  <input type="checkbox" class="form-check-input" formControlName="isOnTrail" name="isOnTrail" id="isOnTrail">
                  <label for="isOnTrail" class="form-check-label">Is On Trial</label>
                </div>


                <div class="row" *ngIf="client.config.canEditOthers == false">
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group">
                      <label class="tylie-form-group__lbl" for="StdStorageDays">Standard<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="StdStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <div class="tylie-form-group__txt">{{client.stdStorageDays}}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group">
                      <label class="tylie-form-group__lbl" for="InfrqStorageDays">Infrequent Access<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="InfrqStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <div class="tylie-form-group__txt">{{client.infrqStorageDays}}</div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="client.config.canEditOthers == true">
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['stdStorageDays'].errors && !editClientFormGroup.controls['stdStorageDays'].pristine)
                  || (editClientFormGroup.controls['stdStorageDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="StdStorageDays">Standard<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="StdStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="stdStorageDays" type="text" id="stdStorageDays" name="stdStorageDays" class="tylie-text form-control" />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['infrqStorageDays'].errors && !editClientFormGroup.controls['infrqStorageDays'].pristine)
                  || (editClientFormGroup.controls['infrqStorageDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="InfrqStorageDays">Infrequent Access<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="InfrqStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="infrqStorageDays" type="text" id="infrqStorageDays" name="infrqStorageDays" class="tylie-text form-control " />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="client.config.canEditOthers == false">
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group">
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Archive<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <div class="tylie-form-group__txt">{{client.archiveDurationInDays}}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group">
                      <label class="tylie-form-group__lbl" for="ActiveTandemStorageInDays">Active Tandem Storage<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ActiveTandemStorageInDays1">(Minimum # of days)<span class="required">*</span></label>
                      <div class="tylie-form-group__txt">{{client.activeTandemStorageInDays}}</div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="client.config.canEditOthers == true">
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['archiveDurationInDays'].errors && !editClientFormGroup.controls['archiveDurationInDays'].pristine)
                    || (editClientFormGroup.controls['archiveDurationInDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Archive<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="archiveDurationInDays" type="text" id="archiveDurationInDays" name="archiveDurationInDays" class="tylie-text form-control " />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="client.config.canEditOthers == true">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (editClientFormGroup.controls['activeTandemStorageInDays'].errors && !editClientFormGroup.controls['activeTandemStorageInDays'].pristine)
                    || (editClientFormGroup.controls['activeTandemStorageInDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="ActiveTandemStorageInDays">Active Tandem Storage<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ActiveTandemStorageInDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="activeTandemStorageInDays" type="text" id="activeTandemStorageInDays" name="activeTandemStorageInDays" class="tylie-text form-control " />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="tylie-form-group form-group">
                      <label class="tylie-form-group__lbl" for="bundleServicesOffered">Logo</label>
                      <div class="tylie-img-container mb-2">
                        <img *ngIf="this.client.logo != null" class="tylie-img" [src]="this.client.logo" alt="Logo">
                        <span class="tylie-img__fallback" *ngIf="this.client.logo == null"> 110x110 </span>
                      </div>

                      <div class="tylie-upload" *ngIf="client.config.canEditOthers == true && this.client.logo == null">
                        <input style="display: none;" id="f02" type="file" (change)="onUploadLogo($event)" accept="image/*" />
                        <label class="tylie-upload__link" for="f02">
                          UPLOAD LOGO
                        </label>
                      </div>

                      <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveLogo()" *ngIf="client.config.canEditOthers == true && this.client.logo != null">
                        REMOVE LOGO
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="opsNotes">OPs Notes</label>
                  <textarea id="opsNotes" name="opsNotes" class="tylie-textarea form-control" formControlName="opsNotes" *ngIf="client.config.canEditOthers == true"></textarea>
                  <div class="p-extra-small line-height" *ngIf="client.config.canEditOthers == false">{{client.opsNotes}}</div>
                </div>


                <div class="tylie-form-group form-group" *ngIf="client.config.canAddEditCustomTags == true">
                  <label class="tylie-form-group__lbl" for="opsNotes">Custom Tags</label>
                  <div class="tylie-form-group__slbl">
                    View Custom Tags in Brands section below
                  </div>
                  <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyAllCustomTags()">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT CUSTOM TAGS
                  </button>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canAddEditBillingSettings == true">
                  <label class="tylie-form-group__lbl" for="opsNotes">Transcode Billing Settings</label>

                  <ng-container *ngIf="client.transcodeBillingSettings != null">
                    <div class="tylie-form-group__txt">{{client.transcodeBillingSettings.billingType}} ({{client.transcodeBillingSettings.billingCycle}})</div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveBillingSettings()">
                      <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>REMOVE
                    </button>
                  </ng-container>

                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyTranscodeBillingSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT TRANSCODE BILLING SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canAddEditBillingSettings == true">
                  <label class="tylie-form-group__lbl" for="opsNotes">Asset Share Billing Settings</label>

                  <ng-container *ngIf="client.assetShareBillingSettings != null">
                    <div class="tylie-form-group__txt">{{client.assetShareBillingSettings.billingType}} ({{client.assetShareBillingSettings.billingCycle}})</div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveAssetShareBillingSettings()">
                      <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>REMOVE
                    </button>
                  </ng-container>

                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyAssetShareBillingSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT ASSET SHARE BILLING SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canAddEditBillingSettings == true">
                  <label class="tylie-form-group__lbl" for="opsNotes">Management Fee Settings</label>

                  <ng-container *ngIf="client.managementFeeSettings != null">
                    <div class="tylie-form-group__txt">{{client.managementFeeSettings.billingCycle}}</div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>REMOVE
                    </button>
                  </ng-container>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT MANAGEMENT FEE SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group" *ngIf="client.config.canAddEditBillingSettings == true">
                  <label class="tylie-form-group__lbl">Cloud Storage Billing Settings</label>
                  <ng-container *ngIf="client.storageManagementFeeSettings != null">
                    <div class="tylie-form-group__txt">{{client.storageManagementFeeSettings.billingCycle}}</div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveStorageManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>REMOVE
                    </button>
                  </ng-container>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyStorageManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT Storage MANAGEMENT FEE SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group" *ngIf=" client.config.canEditFileDeliveryConfig == true">
                  <label class="tylie-form-group__lbl">File Delivery Config</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditFileDeliveryConfig()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT FILE DELIVERY CONFIG
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">POD Recipients (via Email)</label>
                  <div *ngIf="client.config.canEditPODDistributionEmails == true && (client.podDistributionEmails == null || client.podDistributionEmails?.length == 0)">
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditPODRecipients()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD POD RECIPIENTS
                    </button>
                  </div>

                  <div class="tylie-form-group form-group" *ngIf="client.podDistributionEmails != null && client.podDistributionEmails.length > 0">
                    <label class="tylie-form-group__lbl" *ngIf="client.config.canEditPODDistributionEmails == true">POD Recipients: </label>
                    <span class="tylie-form-group__txt">{{client.podDistributionEmails}}</span>
                  </div>
                  <ng-container *ngIf="client.config.canEditPODDistributionEmails == true && (client.podDistributionEmails != null && client.podDistributionEmails.length > 0)">
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditPODRecipients()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT POD RECIPIENTS
                    </button>
                  </ng-container>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Cost Center and Bill To</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="addBillToAndCostCenter()" *ngIf="client.config.canAddEditCCandBT == true && client.costCenters.length == 0 && client.billTos.length == 0">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD CC & BT
                    </button>
                  </div>

                  <div class="col-md-12 tyl-wordwrap" *ngIf="client.costCenters.length > 0">
                    <label class="tylie-form-group__lbl">Cost Center: &nbsp;</label>
                    <span class="tylie-form-group__txt">{{client.costCenterDisplayString}}</span>
                  </div>
                  <div class="col-md-12 tyl-wordwrap" *ngIf="client.billTos.length > 0">
                    <label class="tylie-form-group__lbl">Bill To:&nbsp;</label>
                    <span class="tylie-form-group__txt">{{client.billToDisplayString}}</span>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="addBillToAndCostCenter()" *ngIf="client.config.canAddEditCCandBT == true && (client.costCenters.length > 0 || client.billTos.length > 0)">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT CC & BT
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Ordered By</label>

                  <div *ngIf="client.config.canEditOrderedBy == true && (client.orderedBy == null || client.orderedBy?.length == 0)">
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditOrderedBy()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD ORDERED BY
                    </button>
                  </div>
                  <div class="col-md-12 tyl-wordwrap" *ngIf="client.orderedBy != null && client.orderedBy.length > 0">
                    <label class="tylie-form-group__lbl" *ngIf="client.config.canEditOrderedBy == true">Ordered By: &nbsp; </label>
                    <span class="tylie-form-group__txt">{{client.orderedBy}}</span>
                  </div>

                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditOrderedBy()"
                            *ngIf="client.config.canEditOrderedBy == true && (client.orderedBy != null && client.orderedBy.length > 0)">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT ORDERED BY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <selected-brands [allowEdits]="false"
                      [isClientEdit]="true"
                      [clientBrands]="client.brandSelectionList"
                      [editConfig]="client.config">
    </selected-brands>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit">
        <span class="tylie-button__icon"><icon-save></icon-save></span>
        <span class="tylie-button__text">Save Client</span>
      </button>
      
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onCancelEdits()">Cancel</button>
    </div>
  </form>
</ng-container>
