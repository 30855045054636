<header class="tylie-header">
  <h3 class="tylie-header__title">Create New Client</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoClientHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Clients</button>
  </nav>
</header>

<div class="tylie-body">
  <form novalidate (ngSubmit)="onFormSubmit(createClientFormGroup)" [formGroup]="createClientFormGroup" role="search" #f="ngForm">
    <div class="tylie-panel tylie-panel--clean" id="createclient">
      <div class="tylie-panel__body" id="createClientBody">
        <div class="row">
          <div class="col-md-3">
            <div class="tylie-form-group form-group " [ngClass]="{error: (createClientFormGroup.controls['clientName'].errors && !createClientFormGroup.controls['clientName'].pristine)
              || (createClientFormGroup.controls['clientName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="ClientName">Client Name<span class="required">*</span></label>
              <input formControlName="clientName" type="text" id="clientName" name="clientName" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter Client Name</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['clientType'].errors && !createClientFormGroup.controls['clientType'].pristine)
                || (createClientFormGroup.controls['clientType'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="clientType">Type<span class="required">*</span></label>
              <dx-select-box [dataSource]="clientTypeDataSource"
                  name="clientType" id="clientType" class="tylie-select form-control"
                  formControlName="clientType"
                  [searchEnabled]="true"
                  valueExpr="id"
                  [showClearButton]="true"
                  displayExpr="name"
                  (onValueChanged)="onClientTypeValueChanged($event)"
                  placeholder="Select Type">
              </dx-select-box>
            </div>
          </div>

          <div class="col-md-3">
            <div class="tylie-form-group">
              <label class="tylie-form-group__lbl" for="status">CX Manager</label>
              <dx-select-box [dataSource]="accountExecutiveDataSource"
                              name="accountExecutive" id="accountExecutive" class="tylie-select form-control"
                              formControlName="accountExecutive"
                              [searchEnabled]="true"
                              valueExpr="userProfileId"
                              [showClearButton]="true"
                              displayExpr="name"
                              placeholder="Select Account Executive">
              </dx-select-box>
            </div>
          </div>

          <div class="col-md-3" *ngIf="this.isClientTypePartner">
            <div class="tylie-form-group">
              <label class="tylie-form-group__lbl" for="partnerClient">Billable Client<span class="required">*</span></label>
              <dx-drop-down-box [dataSource]="partnerClientDataSource"
                                [(value)]="treeBoxValue"
                                name="partnerClient" id="partnerClient" class="tylie-select form-control"
                                formControlName="partnerClient"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select Clients"
                                (onValueChanged)="syncTreeViewSelection()">

                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view [dataSource]="partnerClientDataSource"
                                dataStructure="plain"
                                keyExpr="id"
                                selectionMode="multiple"
                                showCheckBoxesMode="normal"
                                [selectNodesRecursive]="false"
                                [searchEnabled]="true"
                                displayExpr="name"
                                [selectByClick]="true"
                                (onContentReady)="syncTreeViewSelection($event)"
                                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
              <p class="p-extra-small">Please select Formats</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="tylie-panel">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              CONTACT DETAILS
            </h4>
          </div>
          <div class="tylie-panel__body">
            <div class="row">
              <div class="col-6" [ngClass]="{error: (createClientFormGroup.controls['name'].errors && !createClientFormGroup.controls['name'].pristine)
                || (createClientFormGroup.controls['name'].errors && f.submitted)}">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="name">Name<span class="required">*</span></label>
                  <input type="text" name="name" id="name" class="tylie-text form-control" formControlName="name">
                  <p class="p-extra-small">Please enter a contact name</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['address1'].errors && !createClientFormGroup.controls['address1'].pristine)
                || (createClientFormGroup.controls['address1'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="Address1">Address 1<span class="required">*</span></label>
                  <input formControlName="address1" type="text" id="address1" name="address1" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter Address 1</p>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="Address2">Address 2</label>
                  <input formControlName="address2" type="text" id="address2" name="address2" class="tylie-text form-control" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['city'].errors && !createClientFormGroup.controls['city'].pristine)
                || (createClientFormGroup.controls['city'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="City">City<span class="required">*</span></label>
                  <input formControlName="city" type="text" id="city" name="city" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter City</p>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['state'].errors && !createClientFormGroup.controls['state'].pristine)
                  || (createClientFormGroup.controls['state'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="state">State<span class="required">*</span></label>
                  <dx-select-box [dataSource]="stateDataSource"
                                  name="state" id="state" class="tylie-select form-control"
                                  formControlName="state"
                                  [searchEnabled]="true"
                                  valueExpr="id"
                                  [showClearButton]="true"
                                  displayExpr="name"
                                  placeholder="Select State">
                  </dx-select-box>
                  <p class="p-extra-small">Please choose a state</p>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['zipCode'].errors && !createClientFormGroup.controls['zipCode'].pristine)
                || (createClientFormGroup.controls['zipCode'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="ZipCode">Zip Code<span class="required">*</span></label>
                  <input formControlName="zipCode" type="text" id="zipCode" name="zipCode" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter a valid Zip Code</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['country'].errors && !createClientFormGroup.controls['country'].pristine)
                  || (createClientFormGroup.controls['country'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="country">Country</label>
                  <dx-select-box [dataSource]="countriesDataSource"
                                  name="country" id="country" class="tylie-select form-control"
                                  formControlName="country"
                                  (onValueChanged)="onCountryValueChanged($event)"
                                  placeholder="Country">
                  </dx-select-box>
                  <p class="p-extra-small">Please choose a Country</p>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group " [ngClass]="{error: (createClientFormGroup.controls['phone'].errors && !createClientFormGroup.controls['phone'].pristine)
                || (createClientFormGroup.controls['phone'].errors && f.submitted) }">
                  <label class="tylie-form-group__lbl" for="Phone">Phone<span class="required">*</span></label>
                  <input formControlName="phone" type="text" id="phone" name="phone" class="tylie-text form-control" [textMask]="{mask: phoneMask, guide: false, modelClean: true}" />
                  <p class="p-extra-small">Please enter a valid Phone</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['email'].errors && !createClientFormGroup.controls['email'].pristine)
                || (createClientFormGroup.controls['email'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="Email">Email<span class="required">*</span></label>
                  <input formControlName="email" type="text" id="email" name="email" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter a valid Email</p>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['teamEmail'].errors && !createClientFormGroup.controls['teamEmail'].pristine)
                || (createClientFormGroup.controls['teamEmail'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="TeamEmail">Team Email<span class="required">*</span></label>
                  <input formControlName="teamEmail" type="text" id="teamEmail" name="teamEmail" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter a valid Team Email</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="tylie-panel">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              Other
            </h4>
          </div>
          <div class="tylie-panel__body">
            <div class="row">
              <div class="col-6">

                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="poRequired" name="poRequired" id="poRequired">
                  <label for="poRequired" class="form-check-label">PO Required</label>
                </div>
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="orderReviewRequired" name="orderReviewRequired" id="orderReviewRequired">
                  <label for="orderReviewRequired" class="form-check-label">Order Review Required</label>
                </div>
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="isVaultBilling" name="isVaultBilling" id="isVaultBilling">
                  <label for="isVaultBilling" class="form-check-label">Vault Billing</label>
                </div>
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="orderFinalReviewRequired" name="orderFinalReviewRequired" id="orderFinalReviewRequired">
                  <label for="orderFinalReviewRequired" class="form-check-label">Order Final Review Required</label>
                </div>
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="isViewRestricted" name="isViewRestricted" id="isViewRestricted">
                  <label for="isViewRestricted" class="form-check-label">Restricted View</label>
                </div>
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="isOnTrail" name="isOnTrail" id="isOnTrail">
                  <label for="isOnTrail" class="form-check-label">Is On Trial</label>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['stdStorageDays'].errors && !createClientFormGroup.controls['stdStorageDays'].pristine)
                  || (createClientFormGroup.controls['stdStorageDays'].errors && f.submitted)}">
                      <label for="StdStorageDays" class="tylie-form-group__lbl">Standard<span class="required">*</span></label>
                      <label for="StdStorageDays1" class="tylie-form-group__lbl">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="stdStorageDays" type="text" id="stdStorageDays" name="stdStorageDays" class="tylie-text form-control " />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['infrqStorageDays'].errors && !createClientFormGroup.controls['infrqStorageDays'].pristine)
                  || (createClientFormGroup.controls['infrqStorageDays'].errors && f.submitted)}">
                      <label for="InfrqStorageDays" class="tylie-form-group__lbl">Infrequent Access<span class="required">*</span></label>
                      <label for="InfrqStorageDays1" class="tylie-form-group__lbl">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="infrqStorageDays" type="text" id="infrqStorageDays" name="infrqStorageDays" class="tylie-text form-control " />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['archiveDurationInDays'].errors && !createClientFormGroup.controls['archiveDurationInDays'].pristine)
                  || (createClientFormGroup.controls['archiveDurationInDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Archive<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="archiveDurationInDays" type="text" id="archiveDurationInDays" name="archiveDurationInDays" class="tylie-text form-control" />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (createClientFormGroup.controls['activeTandemStorageInDays'].errors && !createClientFormGroup.controls['activeTandemStorageInDays'].pristine)
                  || (createClientFormGroup.controls['activeTandemStorageInDays'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Active Tandem Storage<span class="required">*</span></label>
                      <label class="tylie-form-group__lbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
                      <input formControlName="activeTandemStorageInDays" type="text" id="activeTandemStorageInDays" name="activeTandemStorageInDays" class="tylie-text form-control" />
                      <p class="p-extra-small">should be numeric value</p>
                    </div>
                  </div>

                </div>

                <div class="tylie-form-group form-group">

                  <label class="tylie-form-group__lbl" for="bundleServicesOffered">Logo</label>
                  <div class="tylie-img-container mb-2">
                    <img *ngIf="base64logo != null" class="tylie-img" [src]="base64logo" alt="Logo">
                    <span class="tylie-img__fallback" *ngIf="base64logo == null"> 110x110 </span>
                  </div>

                  <div class="tylie-upload" *ngIf="base64logo == null">
                    <input style="display: none;" id="f02" type="file" (change)="onUploadLogo($event)" accept="image/*" />
                    <label class="tylie-upload__link" for="f02">
                      UPLOAD LOGO
                    </label>
                  </div>


                  <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveLogo()" *ngIf="base64logo != null">
                    REMOVE LOGO
                  </button>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="opsNotes">OPs Notes</label>
                  <textarea id="opsNotes" name="opsNotes" class="tylie-text form-control" formControlName="opsNotes"></textarea>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="transcodeBillingSettings">Transcode Billing Settings</label>

                  <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="transcodeBillingSettings != null">
                    <span>{{transcodeBillingSettings.billingType}} ({{transcodeBillingSettings.billingCycle}})</span>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveBillingSettings()">
                      <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>Remove
                    </button>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyTranscodeBillingSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT TRANSCODE BILLING SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="assetShareBillingSettings">Asset Share Billing Settings</label>

                  <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="assetShareBillingSettings != null">
                    <span>{{assetShareBillingSettings.billingType}} ({{assetShareBillingSettings.billingCycle}})</span>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveAssetShareBillingSettings()">
                      <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>Remove
                    </button>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyAssetShareBillingSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT ASSET SHARE BILLING SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group">
                  <label class="tylie-form-group__lbl" for="managementFeeSettings">Management Fee Settings</label>

                  <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="managementFeeSettings != null">
                    <span>{{managementFeeSettings.billingCycle}}</span>

                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>Remove
                    </button>
                  </div>

                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT MANAGEMENT FEE SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="managementFeeSettings">Cloud Storage Billing Settings</label>

                  <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="storageManagementFeeSettings != null">
                    <span>{{storageManagementFeeSettings.billingCycle}}</span>

                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveStorageManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>REMOVE
                    </button>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyStorageManagementFeeSettings()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT STORAGE MANAGEMENT FEE SETTINGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="customTags">Custom Tags</label>
                  <div>
                    <span class="p-extra-small">View Custom Tags in Brands section below</span>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditApplyAllCustomTags()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT CUSTOM TAGS
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group" *ngIf="this.canAddFileDeliveryConfig == true">
                  <label class="tylie-form-group__lbl" for="customTags">File Delivery Config</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditFileDeliveryConfig()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT FILE DELIVERY CONFIG
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="customTags">POD Recipients (via Email)</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditPODRecipients()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT POD RECIPIENTS
                    </button>
                  </div>
                  <div class="col-md-12 tyl-wordwrap" *ngIf="this.podDistributionEmails.length > 0">
                    <span>POD Recipients: &nbsp; </span>
                    <span>{{podDistributionEmails}}</span>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="opsNotes">Cost Center and Bill To</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="addBillToAndCostCenter()" *ngIf="this.costCenters.length == 0 && this.billTos.length == 0">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD CC & BT
                    </button>
                  </div>
                  <div class="col-md-12 tyl-wordwrap" *ngIf="this.costCenters.length > 0">
                    <span>Cost Center: &nbsp; </span>
                    <span>{{costCenterDisplayString}}</span>
                  </div>

                  <div class="col-md-12 tyl-wordwrap" *ngIf="this.billTos.length > 0">
                    <span>Bill To :&nbsp;  </span>
                    <span>{{billToDisplayString}}</span>
                  </div>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="addBillToAndCostCenter()" *ngIf="this.costCenters.length > 0 || this.billTos.length > 0">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT CC & BT
                    </button>
                  </div>
                </div>

                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="customTags">Ordered By</label>
                  <div>
                    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddEditOrderedBy()">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD / EDIT ORDERED BY
                    </button>
                  </div>
                  <div class="col-md-12 tyl-wordwrap" *ngIf="this.orderedByList.length > 0">
                    <span>Ordered By: &nbsp; </span>
                    <span>{{orderedByList}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <selected-brands [allowEdits]="true" [createConfig]="createConfig"></selected-brands>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">Save Client</span>
      </button>
    </div>

  </form>
</div>


