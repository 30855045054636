<ng-container *ngIf="brandDetail">

  <header class="tylie-header" *ngIf="source == 'create'">
    <h3 class="tylie-header__title">Create New Brand</h3>
    <nav class="tylie-header__nav">
      <button type="button" class="tylie-button tylie-button--link tylie-button--xs" type="button" (click)="gotoBrandHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Brands</button>
    </nav>
  </header>

  <div class="d-flex justify-content-end mb-3" *ngIf="editMode == false">
    <button type="button" *ngIf="brandDetail.config.showInActivate == true" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onBrandStatusUpdate(false)">
      <span class="tylie-button__icon"><icon-expire></icon-expire></span>
      <span class="tylie-button__text">InActivate Brand</span>
    </button>
    <button type="button" *ngIf="brandDetail.config.showActivate == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onBrandStatusUpdate(true)">
      <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
      <span class="tylie-button__text">Activate Brand</span>
    </button>
    <button type="button" *ngIf="brandDetail.config.showEdit == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onEditBrand()" type="button">
      <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
      <span class="tylie-button__text">Edit Brand</span>
    </button>
  </div>

  <div [class.tylie-body]="source === 'create'">
    <form novalidate (ngSubmit)="onFormSubmit(editBrandFormGroup)" [formGroup]="editBrandFormGroup" role="search" #f="ngForm">
      <div class="tylie-panel tylie-panel--clean" id="editBrand">
        <div class="tylie-panel__body">
          <div class="row">
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (brandDetail.config.canEditHeader == true && ((editBrandFormGroup.controls['brandName'].errors && !editBrandFormGroup.controls['brandName'].pristine)
                || (editBrandFormGroup.controls['brandName'].errors && f.submitted)))}">
                <label class="tylie-form-group__lbl" for="brandName">Brand Name<span class="required">*</span></label>
                <div class="tylie-form-group__txt" *ngIf="editMode == false || (editMode == true && brandDetail.config.canEditHeader != true)">{{brandDetail.brandName}}</div>
                <input formControlName="brandName" type="text" id="brandName" name="brandName" class="tylie-text form-control " *ngIf="editMode == true && brandDetail.config.canEditHeader == true" />
                <p class="p-extra-small" *ngIf="editMode == true && brandDetail.config.canEditHeader == true">Please enter Brand Name</p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="brandDetail.config.showBrandCode == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="brandCode">Brand Code</label>
                <div class="tylie-form-group__txt">{{brandDetail.brandCode}}</div>
              </div>
            </div>
            <div class="col-md-3" [ngClass]="{'col-md-2': brandDetail.config.showBrandCode == true, 'col-md-3': brandDetail.config.showBrandCode == false}">
              <div class="tylie-form-group form-group" [ngClass]="{error: (brandDetail.config.canEditHeader == true && ((editBrandFormGroup.controls['shortName'].errors
                    && !editBrandFormGroup.controls['shortName'].pristine)
                || (editBrandFormGroup.controls['shortName'].errors && f.submitted)))}">
                <label class="tylie-form-group__lbl" for="shortName">Short Name<span class="required">*</span></label>
                <div class="tylie-form-group__txt" *ngIf="editMode == false || (editMode == true && brandDetail.config.canEditHeader != true)">{{brandDetail.shortName}}</div>
                <input formControlName="shortName" type="text" id="shortName" name="shortName" class="tylie-text form-control" *ngIf="editMode == true && brandDetail.config.canEditHeader == true" />
                <p class="p-extra-small" *ngIf="editMode == true && brandDetail.config.canEditHeader == true">Please Enter a Short Name with a maximum of 15 Characters</p>
              </div>
            </div>
            <div class="col-md-3" [ngClass]="{'col-md-2': brandDetail.config.showBrandCode == true, 'col-md-3': brandDetail.config.showBrandCode == false}">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="status">Location</label>
                <div class="tylie-form-group__txt" *ngIf="editMode == false || (editMode == true && brandDetail.config.canEditHeader != true)">{{brandDetail.locationDisplay}}</div>
                <ng-container *ngIf="brandDetail.config.canEditHeader == true">
                  <dx-select-box [dataSource]="brandDetail.config.locations"
                                 name="location" id="location" class="tylie-select form-control"
                                 formControlName="location"
                                 valueExpr="id"
                                 displayExpr="name">
                  </dx-select-box>
                </ng-container>
              </div>
            </div>
            <div class="col-md-3" [ngClass]="{'col-md-2': brandDetail.config.showBrandCode == true, 'col-md-3': brandDetail.config.showBrandCode == false}">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="brandName">Status</label>
                <div class="tylie-form-group__txt">{{brandDetail.statusDisplay}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="(editMode == true || (brandDetail.brandProducts != null && brandDetail.brandProducts?.length > 0))">
        <selected-products [editMode]="editMode"
                           [brandProducts]="brandDetail.brandProducts"
                           [brandId]="brandDetail.brandId"
                           [resetProducts]="resetProducts"
                           [config]="brandDetail.config"></selected-products>
      </ng-container>

      <div class="d-flex justify-content-end" *ngIf="editMode == true">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit">
          <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
          <span class="tylie-button__text">Save Brand</span>
        </button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onCancelBrandEdit()">Cancel</button>
      </div>
    </form>
    </div>
</ng-container>

