import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { OrderService } from "../../../services/order/order.service";
import { OrderDigitalDeliveryDestinationDetailModel, OrderDetailDigitalDeliveryDestinationModel, VendorAssignmentModel, UpdateDeliveryPODModel, UpdateVendorOrderNumberModel, RetryVendorProcessRequestModel, ResendMediaToDestinationModel } from "../../../models/order/order-destinations.model";
import { Constants } from "../../../configurations/constants";
import { ConfigService } from "../../../services/core/config.service";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'ordereditnetworkdestinationpopup',
  templateUrl: './ordereditnetworkdestinationpopup.component.html',
  styleUrls: ['./ordereditnetworkdestinationpopup.component.css'],
})
export class OrderEditNetworkDestinationPopupComponent extends BasePopupComponent {

  public updateOrderStatusRequest: FormGroup;
  public onClose: Subject<OrderDetailDigitalDeliveryDestinationModel>;

  @Input() data: OrderDigitalDeliveryDestinationDetailModel;
  @Input() isAssignVendor: boolean = false;
  @Input() title: string = "Select Action";

  public carrierDataSource: any = {};
  public statusDataSource: any = {};

  carrierStatusUpdateForm: FormGroup;
  carrierSubmissionFailureForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private orderService: OrderService,
    private configService: ConfigService) {

    super();

    this.carrierStatusUpdateForm = new FormGroup({
      carrierStatusCheckOpt: new FormControl()
    });

    this.carrierSubmissionFailureForm = new FormGroup({
      carrierSubmissionFailureOpt: new FormControl()
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();
  }

  public onAssignVendorFormSubmit({ value, valid }: { value: VendorAssignmentModel, valid: boolean }) {
    if (!valid)
      return;
    else {
      value.adId = this.data.adId;
      value.orderGuid = this.data.orderGuid;
      value.destinationGuid = this.data.destinationGuid;
      value.destinationCode = this.data.destinationCode;
      value.serviceId = this.data.serviceId;

      if (this.isAssignVendor == true) {
        this.orderService.assignVendorForOrderLineItems(value)
          .subscribe((res: any) => {
            this.onClose.next(res.result);
            if (res.isSuccess == true) {
              this.alertService.showMessage("SUCCESS", Constants.orderNetworkCarrierUpdated, MessageSeverity.success);
            }
            else {
              this.util.handleIsNotSuccess(res.errors);
            }
          },
            error => {
              this.util.handleError(error);
            });
      }
      else {
        this.orderService.reAssignVendorForOrderLineItems(value)
          .subscribe((res: any) => {
            this.onClose.next(res.result);
            if (res.isSuccess == true) {
              this.alertService.showMessage("SUCCESS", Constants.orderNetworkCarrierUpdated, MessageSeverity.success);
            }
            else {
              this.util.handleIsNotSuccess(res.errors);
            }
          },
            error => {
              this.util.handleError(error);
            });
      }
      this.closePopup();
    }
  }

  public onReSendAssignVendorFormSubmit({ value, valid }: { value: ResendMediaToDestinationModel, valid: boolean }) {
    if (!valid)
      return;
    else {
      value.adId = this.data.adId;
      value.orderGuid = this.data.orderGuid;
      value.destinationGuid = this.data.destinationGuid;
      value.destinationCode = this.data.destinationCode;
      value.serviceId = this.data.serviceId;

      this.orderService.reSendMediaToDestinations(value)
        .subscribe((res: any) => {
          this.onClose.next(res.result);
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.orderResendUpdated, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });


      this.closePopup();
    }
  }

  public onUpdateDeliveryPODFormSubmit({ value, valid }: { value: UpdateDeliveryPODModel, valid: boolean }) {
    console.log(value);
    if (!valid)
      return;
    else {
      value.adId = this.data.adId;
      value.orderGuid = this.data.orderGuid;
      value.destinationGuid = this.data.destinationGuid;
      value.destinationCode = this.data.destinationCode;
      value.vendorId = this.data.vendorId;

      this.orderService.updateDeliveryPOD(value)
        .subscribe((res: any) => {
          this.onClose.next(res.result);
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.orderDeliveryPodUpdated, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      this.closePopup();
    }
  }

  public onUpdateVendorOrderNumberFormSubmit({ value, valid }: { value: UpdateVendorOrderNumberModel, valid: boolean }) {
    if (!valid)
      return;
    else {
      value.orderGuid = this.data.orderGuid;
      value.vendorLineItemId = this.data.vendorLineItemId;
      value.vendorId = this.data.vendorId;

      this.orderService.updateVendorOrderNumber(value)
        .subscribe((res: any) => {
          this.onClose.next(res.result);
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.vendorOrderNumberUpdated, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      this.closePopup();
    }
  }

  public onRetryCarrierStatusCheckYes() {
    var value = new RetryVendorProcessRequestModel();
    value.adId = this.data.adId;
    value.orderGuid = this.data.orderGuid;
    value.destinationGuid = this.data.destinationGuid;
    value.vendorId = this.data.vendorId;
    value.vendorLineItemId = this.data.vendorLineItemId;

    this.orderService.retryVendorOrderStatusCheck(value)
      .subscribe((res: any) => {
        this.onClose.next(res.result);
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderRetrySuccessful, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
    this.closePopup();
  }

  public onRetryCarrierStatusCheckNo() {
    this.closePopup();
  }

  public onRetryCarrierSubmissionYes() {
    var value = new RetryVendorProcessRequestModel();
    value.adId = this.data.adId;
    value.orderGuid = this.data.orderGuid;
    value.destinationGuid = this.data.destinationGuid;
    value.vendorId = this.data.vendorId;
    value.vendorLineItemId = this.data.vendorLineItemId;

    this.orderService.retryVendorOrderSubmission(value)
      .subscribe((res: any) => {
        this.onClose.next(res.result);
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderRetrySuccessful, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
    this.closePopup();
  }

  public onRetryCarrierSubmissionNo() {
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
