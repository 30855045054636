
import {finalize} from 'rxjs/operators';
import { Component } from '@angular/core';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import { OrderFile } from '../../../../models/order/order-create.model';
import { OrderService } from '../../../../services/order/order.service';
import { Utilities } from '../../../../services/core/utilities';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';

@Component({
  selector: 'service-subtitlingembedded',
  templateUrl: './servicesubtitlingembedded.component.html'
})
export class ServiceSubtitlingEmbeddedQComponent extends BaseServiceDataCollectionComponent {

  public isUploading: boolean = false;

  public isUploadFileDisabled: boolean = false;
  public isTitleDisabled: boolean = false;

  constructor(private orderService: OrderService,
    public util: Utilities,
    private uploadOrderFileService: UploadOrderFileService) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.isUploadFileDisabled = this.formGroup.controls["scriptFile"].disabled;
    this.isTitleDisabled = this.formGroup.controls["title"].disabled;

    this.formGroup.setValidators([
      this.uploadOrPasteScriptRequired(this.formGroup.get('scriptText'), this.formGroup.get('scriptFile'),
      )]);

    this.formGroup.updateValueAndValidity();

    if (this.formGroup.controls["scriptFile"].value == null)
      this.formGroup.controls["scriptFile"].setValue([]);

    if (this.formGroup.controls["scriptText"].value == null)
      this.formGroup.controls["scriptText"].setValue('');

    if (this.applyAllMode === true) {
      this.formGroup.controls["title"].clearValidators();
      this.formGroup.controls["title"].updateValueAndValidity();
    }
    else {
      if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0)
        this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileSpecs[0].ppFileTitle);
      else
        this.formGroup.controls["title"].setValue('');
      //this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileTitle);
    }
  }

  public onAttachFile(event: any) {

    if (event.target.files && event.target.files.length > 0) {

      for (const uploadFile of event.target.files) {
        this.isUploading = true;
        var request: UploadOrderFileRequest = {
          fileType: uploadFile.type,
          fileName: uploadFile.name,
          description: "",
          fileCategory: "serviceMetadata",
          orderGuid: this.orderGuid,
          orderDestinationId: 0
        };

        this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
          finalize(() => {
            this.isUploading = false;
          }))
          .subscribe((newFile: OrderFile) => {
            if (newFile != null && newFile != undefined) {
              this.addFilesToList(newFile);
            }
          });
      }
    }
    else
      this.isUploading = false;
  }

  private addFilesToList(newFile: OrderFile) {
    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles.push(newFile);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
  }

  public onRemoveFile(orderFileGuid: string) {

    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);

    //this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    //    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    //    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }

    //  this.isUploading = false;
    //},
    //  error => {
    //    this.isUploading = false;
    //    this.util.handleError(error);
    //  });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {
    console.log(orderFileGuid);
    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }
}
