<div class="tylie-accordion accordion" id="order-in-progress-section">
  <div class="tylie-accordion__panel accordion-item" id="recentlyviewedPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#inprogressordersCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        Orders in Progress
      </button>
    </h2>
    <div id="inprogressordersCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">

        <div class="tylie-accordion__body-bar">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
        </div>
        <div class="tylie-accordion__body-content">
          <dx-data-grid id="ordersInProgress"
                        #ordersInProgress class="tylie-grid"
                        [dataSource]="ordersInProgresStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        [rowAlternationEnabled]="true"
                        (onRowPrepared)="onRowPrepared($event)"
                        [showBorders]="true">

            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="workOrder" caption="Order #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" cellTemplate="workOrderTemplate" width="{{showAllColumns == true ? '8%' :'9%'}}"></dxi-column>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" [allowSorting]="this.showAllColumns" width="{{showAllColumns == true ? '9%' :'11%'}}"></dxi-column>
            <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '10%' :'11%'}}"></dxi-column>
            <dxi-column dataField="clientPurchaseOrder" caption="Client Purchase Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '10%' :'13%'}}"></dxi-column>
            <dxi-column dataField="jobNumber" caption="Job #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '8%' :'12%'}}"></dxi-column>
            <dxi-column dataField="isConsolidated" caption="Consolidated" cellTemplate="consolidatedTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding tyl-text-align-left" width="8%"></dxi-column>
            <dxi-column dataField="isMarkedForFinalReview" caption="Final Review" cssClass="gridStyle tyl-nopadding tyl-grid-padding tyl-text-align-left" cellTemplate="finalReviewTemplate" width="{{showAllColumns == true ? '8%' :'0%'}}"></dxi-column>
            <dxi-column dataField="claimedByUser" caption="Claimed By" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '8%' :'0%'}}"></dxi-column>
            <dxi-column dataField="orderBuiltBy" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '8%' :'12%'}}"></dxi-column>
            <dxi-column dataField="status" caption="Status" cssClass="gridStyle tyl-nopadding" cellTemplate="thumbnailTemplate" width="{{showAllColumns == true ? '12%' :'12%'}}"></dxi-column>
            <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '11%' :'13%'}}"></dxi-column>

            <div *dxTemplate="let t of 'workOrderTemplate'">
              <span class="quickLink" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
              <span><img *ngIf="t.data.isRush == true" src="./assets/svg/Icon-Rush.svg" class="ordersIcons" alt="rush"></span>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>

            <div *dxTemplate="let data of 'finalReviewTemplate'">
              <div>{{data.value == true ? 'Yes' : 'No'}}</div>
            </div>

            <div *dxTemplate="let data of 'consolidatedTemplate'">
              <div>{{data.value == true ? 'Yes' : 'No'}}</div>
            </div>

            <div *dxTemplate="let t of 'thumbnailTemplate'">
              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isApprovalRejected === true" class="OrderStatusRejectedThumbnail"
                  [src]="this.util.approvalRejectedThumbnailUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.approvalRejectedSpotsDisplay" triggers="mouseenter:mouseleave" />

              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isAwaitingApproval === true" class="OrderStatusApprovalThumbnail"
                  [src]="this.util.pendingApprovalThumbnailUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.awaitingApprovalSpotsDisplay" triggers="mouseenter:mouseleave" />

              <img *ngIf="t.data.awaitingMediaOrder != null && t.data.awaitingMediaOrder.isAwaitingMedia === true" class="OrderStatusThumbnail"
                  [src]="this.util.awaitingMediaUrl"
                  containerClass="customGridPopover" placement="left" [popover]="t.data.awaitingMediaOrder.awaitingMediaSpotsDisplay" triggers="mouseenter:mouseleave" />

              <span *ngIf="t.data.awaitingMediaOrder == null || t.data.awaitingMediaOrder == undefined || (t.data.awaitingMediaOrder.isAwaitingMedia === false && t.data.awaitingMediaOrder.isAwaitingApproval === false)"
                    class="tyl-padding">{{t.data.status}}</span>
            </div>

            <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      infoText="Page {0} of {1} ({2} items)"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
     
    </div>
  </div>
</div>

