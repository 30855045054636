<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Edit Vault Request</h4>
</div>
<div class="modal-body">
  <form [formGroup]="editRequest" #f="ngForm" (ngSubmit)="onSave(editRequest)">

    <div class="tylie-form-group form-group" [ngClass]="{error: (editRequest.controls['newValue'].errors || editRequest.controls['newValue'].hasError('inValidEmailList')) && f.submitted}">

      <label class="tylie-form-group__lbl" for="notes" *ngIf="controlType !== 'airdate' && controlType !== 'checkbox' && controlType !== 'servicelevel'">{{label}}<span class="required">*</span></label>

      <dx-date-box formControlName="newValue" type="date" class="tylie-datepicker form-control" name="datectrl" *ngIf="controlType == 'date'" [showClearButton]="true"></dx-date-box>

      <input formControlName="newValue" type="text" class="tylie-text form-control" name="textctrl" *ngIf="controlType == 'text'" [placeholder]="placeholder">

      <textarea formControlName="newValue" class="tylie-textarea tylie-textarea--lg form-control" name="textareactrl" *ngIf="controlType == 'textarea'"></textarea>

      <textarea formControlName="newValue" class="tylie-textarea tylie-textarea--lg form-control" name="textareactrl" placeholder="semi-colon seperated" *ngIf="controlType == 'podemails'"></textarea>

      <dx-select-box [items]="itemsDataSource" *ngIf="controlType == 'dropdown'"
                     class="tylie-select form-control"
                     name="selectctrl"
                     [showClearButton]="true"
                     [acceptCustomValue]="canAddCustom"
                     [placeholder]="canAddCustom ? 'Select or Add new' : 'Select...'"
                     formControlName="newValue"
                     valueExpr="id"
                     displayExpr="name"
                     noDataText="No data available">
      </dx-select-box>
    </div>
    <div class="tylie-form-group form-check" *ngIf="controlType == 'checkbox'">
      <label class="form-check-label">
        <input formControlName="newValue" type="checkbox" name="checkboxctrl"  class="tylie-form-input"/><span>{{label}}</span>
      </label>
    </div>

    <p class="tylie-type-body tylie-type-body--sm" *ngIf="editRequest.controls['newValue'].errors?.required || editRequest.controls['newValue'].hasError('inValidEmailList')">
    {{error}}
    </p>
    <p class="tylie-type-body tylie-type-body--sm" *ngIf="editRequest.controls['newValue'].errors?.maxlength">{{maxLengthError}}</p>
   

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>
  </form>
</div>
