import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-instructioncreation',
  templateUrl: './serviceinstructioncreation.component.html'
})
export class ServiceInstructionCreationQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
