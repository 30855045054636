<div [formGroup]="assetRequestFormGroup">
  <div [formGroupName]="groupName">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between">
          <h3 class="tylie-type-h3">
            {{ displayFileName(groupName) }}
          </h3>
          <button type="button"
            class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
            (click)="onRemoveFile(groupName)"
          >
            <span class="tylie-button__icon"
              ><icon-delete color="#1e90ff"></icon-delete
            ></span>
            Remove
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="assetType"
            >Asset Type</label
          >
          <dx-select-box
            [dataSource]="assetTypeDataSource"
            formControlName="assetType"
            class="tylie-select form-control"
            [ngClass]="{
              error:
                formControl?.get('assetType').invalid &&
                (isFormSubmitted ||
                  formControl?.get('assetType').dirty ||
                  formControl?.get('assetType').touched)
            }"
            name="assetType"
            id="assetType"
            showClearButton="true"
            valueExpr="id"
            displayExpr="name"
            placeholder="Select a value..."
            [disabled]="assetTypeDisabled"
          >
          </dx-select-box>
          <div
            *ngIf="
              formControl?.get('assetType').invalid &&
              (isFormSubmitted ||
                formControl?.get('assetType').dirty ||
                formControl?.get('assetType').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="formControl?.get('assetType').errors.required"
            >
              Field is required.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="assetType"
            >Asset Title</label
          >
          <input
            type="text"
            id="assetTitle"
            name="assetTitle"
            formControlName="assetTitle"
            [ngClass]="{
              error:
                isDuplicateAssetTitle ||
                (formControl?.get('assetTitle').invalid &&
                  (isFormSubmitted ||
                    formControl?.get('assetTitle').dirty ||
                    formControl?.get('assetTitle').touched))
            }"
            class="tylie-text form-control"
            placeholder="Type a title..."
          />
          <div
            *ngIf="
              formControl?.get('assetTitle').invalid &&
              (isFormSubmitted ||
                formControl?.get('assetTitle').dirty ||
                formControl?.get('assetTitle').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="formControl?.get('assetTitle').errors.required"
            >
              Field is required.
            </div>
          </div>
          <div class="error-text" *ngIf="isDuplicateAssetTitle">
            Same title already exists.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="startDate"
            >Available Start Date</label
          >
          <dx-date-box
            type="date"
            formControlName="startDate"
            id="startDate"
            displayFormat="MM/dd/yyyy"
            class="tylie-datepicker form-control"
            [ngClass]="{
              error:
                formControl?.get('startDate').invalid &&
                (isFormSubmitted ||
                  formControl?.get('startDate').dirty ||
                  formControl?.get('startDate').touched)
            }"
            [showClearButton]="true"
          ></dx-date-box>
          <div
            *ngIf="
              formControl?.get('startDate').invalid &&
              (isFormSubmitted ||
                formControl?.get('startDate').dirty ||
                formControl?.get('startDate').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="formControl?.get('startDate').errors.required"
            >
              Field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="endDate"
            >Available End Date</label
          >
          <dx-date-box
            type="date"
            formControlName="endDate"
            id="endDate"
            displayFormat="MM/dd/yyyy"
            class="tylie-datepicker form-control"
            [ngClass]="{
              error:
                formControl?.get('endDate').invalid &&
                (isFormSubmitted ||
                  formControl?.get('endDate').dirty ||
                  formControl?.get('endDate').touched)
            }"
            [showClearButton]="true"
          ></dx-date-box>
          <div
            *ngIf="
              formControl?.get('endDate').invalid &&
              (isFormSubmitted ||
                formControl?.get('endDate').dirty ||
                formControl?.get('endDate').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="formControl?.get('endDate').errors.required"
            >
              Field is required.
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          isEditAsset &&
          this.assetRequestFormGroup.get(this.groupName).get('assetType')
            .value == 'Base Creative'
        "
        class="col-4"
      >
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="endDate"
            >Taggable Duration (secs)</label
          >
          <input
            type="text"
            id="taggableDurationInSecs"
            name="taggableDurationInSecs"
            formControlName="taggableDurationInSecs"
            [ngClass]="{
              error:
                formControl?.get('taggableDurationInSecs').invalid &&
                (isFormSubmitted ||
                  formControl?.get('taggableDurationInSecs').dirty ||
                  formControl?.get('taggableDurationInSecs').touched)
            }"
            class="tylie-text form-control"
            placeholder="Type taggable duration (secs)..."
          />
          <div
            *ngIf="
              formControl?.get('taggableDurationInSecs').invalid &&
              (isFormSubmitted ||
                formControl?.get('taggableDurationInSecs').dirty ||
                formControl?.get('taggableDurationInSecs').touched)
            "
          >
            <div
              class="error-text"
              *ngIf="formControl?.get('taggableDurationInSecs').errors.required"
            >
              Field is required.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="group">Available for Groups</label>
          <app-custom-dropdown displayKey="name"
                               [options]="groupDataSource"
                               [selectedLabel]="selectedGroupLabel"
                               [selectedItems]="selectedGroups"
                               selectTitle="SELECT GROUPS"
                               (submit)="onSelectedGroups($event)"></app-custom-dropdown>
          <div *ngIf="
              formControl?.get('groups').invalid &&
              (isFormSubmitted ||
                formControl?.get('groups').dirty ||
                formControl?.get('groups').touched)
            ">
            <div class="error-text"
                 *ngIf="formControl?.get('groups').errors.required">
              Please select a group.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl" for="location">Available for Locations</label>
          <app-custom-dropdown displayKey="name"
                               [options]="locationDataSource"
                               [selectedLabel]="selectedLocationLabel"
                               selectTitle="SELECT LOCATIONS"
                               isLocation="true"
                               groupNameDisplayKey="name"
                               [selectedItems]="selectedLocations"
                               (submit)="onSelectedLocations($event)"></app-custom-dropdown>
          <div *ngIf="
              formControl?.get('locations').invalid &&
              (isFormSubmitted ||
                formControl?.get('locations').dirty ||
                formControl?.get('locations').touched)
            ">
            <div class="error-text"
                 *ngIf="formControl?.get('locations').errors.required">
              Please select a location.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="tylie-form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          formControlName="isFeatured"
          name="isFeatured"
          id="isFeatured"
        />
        <label class="form-check-label" for="isFeatured">Set as Featured</label>
      </div>
    </div>
  </div>
</div>
