
import {finalize} from 'rxjs/operators';
import { Component, Inject, ViewChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import { DxDataGridComponent } from "devextreme-angular";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { GlobalService } from '../../../../services/core/global.service';
import { SubService } from '../../../../services/media/sub.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'awaiting-review-orders',
  templateUrl: './awaiting-order-review.component.html',
  styleUrls: ['./awaiting-order-review.component.css']
})
export class AwaitingOrderReviewComponent implements OnInit {

  public readyForReviewOrderStore: any = {};
  @ViewChild('orderReadyForReview', { static: true }) orderReadyForReview: DxDataGridComponent;

  public modalRef: BsModalRef;

  private refreshId: any;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private gs: GlobalService,
    private sub: SubService) {

  }

  ngOnInit() {
    if (this.userProfileService.getUserRoles().includes(this.userRoles.getOrderDetailEditorRoleName())) {
      this.getAwaitingReviewOrders();

      this.sub.orderEditedMethodCalled.subscribe(() => { this.orderReadyForReview.instance.refresh(); });

      this.refreshId = setInterval(() => {
        this.orderReadyForReview.instance.refresh();
      }, environment.ordersReviewGridRefreshTimeInMins * 60000);
    }
  };

  public getAwaitingReviewOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.readyForReviewOrderStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };
        return orderService.getAwaitingReviewOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var orderReviewResponse = response.result;
              var obj: any = {
                data: orderReviewResponse.inLineClaimableOrderResults,
                totalCount: orderReviewResponse.totalCount
              };

              util.awaitingOrderReviewCount = orderReviewResponse.totalPendingActionOrdersCount;
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public onRefresh() {
    this.orderReadyForReview.instance.refresh();
  }

  public claimOrderForReview(orderGuid: string) {
    this.orderService.claimOrder(orderGuid).pipe(
      finalize(() => {
        //this.orderReadyForReview.instance.refresh();
      }))
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderReviewClaimSuccess, MessageSeverity.success);
          this.showOrderDetail(orderGuid);
          this.gs.orderEdited();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);

    //this.router.navigate([RouteConstants.orderDetailRoute, orderGuid]);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.hasReviewComments) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.isSetToReviewByOps) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}
