import { Component, Inject, ViewChild, Input, OnInit, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { OrderService } from "../../../../services/order/order.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { confirmEventType } from "../../../../configurations/enums/enums";
import { Router } from "@angular/router";
import { ConfirmPopupComponent } from "../../../popups/confirmationpopups/confirmpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { GridParams } from "../../../../models/config/gridparams.model";
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import { DxDataGridComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../configurations/route-constants";
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ErrorPopupComponent } from '../../../popups/errorpopup/errorpopup.component';
import { GlobalService } from '../../../../services/core/global.service';
import { SubService } from '../../../../services/media/sub.service';

@Component({
  selector: 'app-uploaded-orders',
  templateUrl: './uploaded-orders.component.html',
  styleUrls: ['./uploaded-orders.component.css'],
})

export class UploadedOrdersComponent implements OnInit {

  @ViewChild('uploadedOrderStoreGrid', { static: true }) uploadedOrderStoreGrid: DxDataGridComponent;

  @Input('uploadedOrderConfig') uploadedorderConfig: any;

  public uploadOrderStore: any = {};
  public modalRef: BsModalRef;
  public showAllColumns: boolean = false;
  public isClientAdmin: boolean = false;

  constructor(public util: Utilities
    , private orderService: OrderService
    , private alertService: AlertService
    , private router: Router
    , private gs: GlobalService
    , private sub: SubService
    , private modalService: BsModalService
    , private userProfileService: UserProfileService
    , private userRoles: UserRoles) { }

  ngOnInit() {

    if (this.uploadedorderConfig != null) {
      if (this.uploadedorderConfig.showAllColumns != null || this.uploadedorderConfig.showAllColumns != undefined) {
        this.showAllColumns = this.uploadedorderConfig.showAllColumns;
      }
      if (this.uploadedorderConfig.isClientAdmin != null || this.uploadedorderConfig.isClientAdmin != undefined) {
        this.isClientAdmin = this.uploadedorderConfig.isClientAdmin;
      }

      this.getUploadedOrders();

      this.sub.uploadedOrderEditedMethodCalled.subscribe(() => { this.uploadedOrderStoreGrid.instance.refresh(); });
    }
  }

  ngAfterViewInit(): void {
    if (!this.showAllColumns && !this.isClientAdmin) {
      this.uploadedOrderStoreGrid.instance.columnOption("Created By", "visible", false);
    }
  }

  public onRefresh() {
    this.uploadedOrderStoreGrid.instance.refresh();
  }

  public continueToOrder(uploadedOrder: string) {
    if (uploadedOrder == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openUploadedOrder(uploadedOrder);

    //this.router.navigate([RouteConstants.ordersUploaded, uploadedOrder]);
  }

  public deleteOrder(deleteUploadedOrderGuid: string) {
    var initialState = {
      eventType: confirmEventType[confirmEventType.DeleteUploadedOrder]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.orderService.deleteUploadedOrder(deleteUploadedOrderGuid).subscribe((res: any) => {

          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESSS", Constants.uploadedOrderDeleted, MessageSeverity.success, true);
            this.uploadedOrderStoreGrid.instance.refresh();
            //this.util.draftOrdersCount = this.draftOrderStoreGrid.instance.totalCount();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    });
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public getUploadedOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.uploadOrderStore.store = new CustomStore({
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";
        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };
        return orderService.getUploadedOrders(request).toPromise().then((response: any) => {
          if (response.isSuccess == true) {
            var orderResponse = response.result;
            var obj: any = {
              data: orderResponse.orderResults,
              totalCount: orderResponse.totalCount
            };
            return obj;
          }
          else {
            util.handleIsNotSuccess(response.errors);
            throw 'Data Loading Error';
          }
        })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public showError(err: any) {
    var initialState = {
      errors: err
    };

    this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }
}
