<!--<dx-load-indicator id="large-indicator" height="60" width="60" [visible]="showLoader==true"></dx-load-indicator>-->

<dx-data-grid class="tylie-grid" id="orderDetailSpots"
                    #orderDetailSpots
                    [dataSource]="orderDetailSpotStore"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [hoverStateEnabled]="true"
                    [rowAlternationEnabled]="true"
                    [showBorders]="true"
                    (onRowPrepared)="onRowPrepared($event)"
                    keyExpr="orderLineItemGuid">
        <dxi-column dataField="adId" cellTemplate="adIDTemplate" width="15%" cssClass="gridStyle" caption="Ad-ID" sortOrder="asc"></dxi-column>
        <dxi-column dataField="spotTitle" width="25%" cssClass="gridStyle" caption="Spot Title"></dxi-column>
        <dxi-column dataField="serviceName" width="19%" cssClass="gridStyle" caption="Service Name"></dxi-column>
        <dxi-column dataField="format" cellTemplate="formatTemplate" width="10%" cssClass="gridStyle" caption="Format" [allowSorting]="false"></dxi-column>
        <dxi-column dataField="group" width="8%" cssClass="gridStyle tyl-text-align-left" caption="Group"></dxi-column>
        <dxi-column dataField="vendorName" width="15%" cssClass="gridStyle" caption="Network"></dxi-column>
        <dxi-column dataField="adId" width="8%" cellTemplate="actionTemplate" cssClass="gridStyle" caption="Action" [visible]="showActionsColumnInSpotsTab"
                    [allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let t of 'adIDTemplate'">
          <span class="blue-text p-extra-small" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
        </div>

        <div *dxTemplate="let t of 'formatTemplate'">
          <div class="dx-datagrid-nowrap-remove">
            <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
          </div>
        </div>

        <div *dxTemplate="let t of 'actionTemplate'" class="tyl-center ">
          <div>
            <span *ngIf="t.data.allowVendorAssignment == true">
              <a class="tylie-lnk"
                 (click)="onAssignVendor(t.data, vendorAssignTemp)">
                Assign Carrier
              </a>
            </span>
          </div>
        </div>

        <dxo-sorting mode="multiple"></dxo-sorting>
 
<ng-template #vendorAssignTemp>
  <div class="modal-header">
    <button type="button" class="close delete" (click)="closePopup()"></button>
    <h4 class="modal-title">Assign Vendor - {{vendorRequest.controls['serviceName'].value}}</h4>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="vendorRequest" #f="ngForm" (ngSubmit)="onAssignVendorSubmit(vendorRequest)">
      <div class="form-group" [ngClass]="{error: vendorRequest.controls['vendorId'].errors && f.submitted}">
        <label>Vendor<span class="required">*</span></label>
        <dx-select-box [items]="vendors" name="vendor"
                       valueExpr="value"
                       displayExpr="name"
                       formControlName="vendorId"
                       placeholder="Choose vendor"></dx-select-box>
        <p class="p-extra-small">Please choose a Vendor</p>
      </div>
      <div class="form-group">
        <span class="p-extra-small" style="color: #ec1c23">
          *The selected Carrier will be applied to all the relevant Spots in all Groups
        </span>
      </div>
      <div class="form-group media-qc">
        <button class="btn btn-small qc-update" type="submit">Assign</button>
        <span class="qc-cancel p-normal" (click)="closePopup()">Cancel</span>
      </div>
    </form>
  </div>
</ng-template>

