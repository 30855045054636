import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-logout',
  templateUrl: './icon-logout.component.html',
  styleUrls: ['./icon-logout.component.css']
})
export class IconLogoutComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#a1a1a1';
  constructor() { }

  ngOnInit() {
  }

}
