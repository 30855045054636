import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { Project } from '../../../models/project/project.model';
import { SpotUploadRequest } from '../../../models/upload/upload-request.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { PromoteProjectFileService } from '../../../services/project/promote-project-file.service';

@Component({
  selector: 'app-project-promote',
  templateUrl: './project-promote.component.html',
  styleUrls: ['./project-promote.component.css']
})
export class ProjectPromoteComponent implements OnInit {
  public onClose: Subject<boolean>;
  @Input() projectFile: Project;
  @Input() fileCollectionIds: string[];
  public modalRef: BsModalRef;
  brandDataSource: any = [];
  promoteForm: FormGroup;
  promoteUpload = {} as SpotUploadRequest;
  subscriptions: Subscription[] = [];
  @ViewChild('titleToolTip', { static: true }) titleToolTip: PopoverDirective;
  constructor(
    public bsModalRef: BsModalRef,
    private configService: ConfigService,
    public util: Utilities,
    private fb: FormBuilder,
    private alertService: AlertService,
    private promoteProjectFileService: PromoteProjectFileService) {
    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.initializePromoteForm();
    this.getClientBrands(this.projectFile.clientId);
  }

  initializePromoteForm() {
    this.promoteForm = this.fb.group({
      brand: new FormControl(null, Validators.required),
      title: new FormControl('', Validators.required),
      adID: new FormControl('', Validators.required),
      length: new FormControl('', Validators.required),
    });
  }

  getClientBrands(id: number) {
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;

        if (this.brandDataSource.length == 1)
          this.promoteForm.controls['brand'].setValue(this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.alertService.ShowLoader(false);
        this.util.handleError(error);
        this.brandDataSource = [];
      }));
  }

  onPromoteProjectSubmit() {
    if (this.promoteForm.valid) {
      const formvalues = this.promoteForm.value;
      this.promoteUpload.client = this.projectFile.clientId;
      this.promoteUpload.fileCollectionId = this.projectFile.fileCollectionId;
      this.promoteUpload = { ...this.promoteUpload, ...formvalues };

      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.promoteProjectFileService.promoteProjectFile(this.promoteUpload, this.fileCollectionIds).subscribe((res: any) => {
        this.alertService.ShowLoader(false);
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.project_promote_success, MessageSeverity.success);
          this.onClose.next(true);
          this.closePopup()
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        }));
    }
  }

  onHideTooltip() {
    this.titleToolTip.hide();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
