
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Utilities } from '../../../../services/core/utilities';
import { ServiceData, OrderFile } from '../../../../models/order/order-create.model';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { ServiceType } from '../../../../models/order/servicetype.model';
import { ServiceOptionsRequest, ServiceMetadataObjectOption, ServiceBAMetadataObjectOption, ServiceBAGuaranteeMetadataObjectOption } from '../../../../models/order/order-servicequestions.model';
import { metadataCategoryEnum } from '../../../../configurations/enums/enums';
import { element } from 'protractor';
import { Array } from 'core-js';
import { Constants } from '../../../../configurations/constants';
import { OrderAdditionalMetadata } from '../../../../models/order/order-additionalmetadata';

@Component({
  selector: 'service-metadata-wrapper',
  templateUrl: './servicemetadatawrapper.component.html'
})
export class ServiceMetadataWrapperComponent implements OnInit {

  @Input() services: Array<ServiceData>;
  @Input() servicesOptional: Array<ServiceData>;
  @Input() editMode: boolean;
  @Input() orderGuid: string;
  @Input() additionalMetadata: OrderAdditionalMetadata;
  @Input() applyAllMode: boolean;
  @Input() showInEditMode: boolean;
  @Input() specialServiceMode: boolean;
  @Input() spotLevel: boolean;
  @Input() groupLevel: boolean;
  @Input() orderLevel: boolean;

  @Output() optionsSave: EventEmitter<ServiceOptionsRequest> = new EventEmitter<ServiceOptionsRequest>();

  public serviceMetadataRequest: FormGroup;

  constructor(
    private alertService: AlertService,
    private _fb: FormBuilder,
    private util: Utilities) {
  }

  ngOnInit() {

    let mainGroup: any = {};

    console.log("creating form", this.services);

    this.services.forEach(sdata => {

      //var readOnlyForm = false;

      //if ((this.showInEditMode === false) || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === false && sdata.service.isSpecialEdit === false))
      //readOnlyForm = true;

      if (sdata.service.isAdditionalDataNeeded === true) {
        let subGroup: any = {};
        //let specialOptionTags: Array<string> = [];

        subGroup['id'] = new FormControl(sdata.service.id);
        subGroup['serviceName'] = new FormControl(sdata.service.name);
        subGroup['serviceCode'] = new FormControl(sdata.service.code);
        subGroup['recentlyAdded'] = new FormControl(sdata.recentlyAdded);
        subGroup['isDeleted'] = new FormControl(false);
        subGroup['parentServices'] = new FormControl(sdata.parentServices == null ? [] : sdata.parentServices);
        //subGroup['isSpecialEdit'] = new FormControl(sdata.service.isSpecialEdit);
        subGroup['isOrderSpecialEdit'] = new FormControl(sdata.service.isOrderSpecialEdit);
        subGroup['isGroupSpecialEdit'] = new FormControl(sdata.service.isGroupSpecialEdit);
        subGroup['isSpotSpecialEdit'] = new FormControl(sdata.service.isSpotSpecialEdit);
        subGroup['isSpecialOptionEdit'] = new FormControl(sdata.service.isSpecialOptionEdit);

        //subGroup['readOnlyForm'] = new FormControl(readOnlyForm);

        sdata.service.serviceConfig.options.forEach(q => {

          //if (q.isSpecialOption == true) {
          //  specialOptionTags.push(q.tag);
          //}

          var readOnlyTag = false;

          if (this.spotLevel == true) {
            if ((this.showInEditMode === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === false && sdata.service.isSpotSpecialEdit === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === true && q.isSpecialOption == false))
              readOnlyTag = true;
          }
          else if (this.groupLevel == true) {
            if ((this.showInEditMode === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === false && sdata.service.isGroupSpecialEdit === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === true && q.isSpecialOption == false))
              readOnlyTag = true;
          }
          else if (this.orderLevel == true) {
            if ((this.showInEditMode === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === false && sdata.service.isOrderSpecialEdit === false)
              || (this.specialServiceMode == true && sdata.service.isSpecialOptionEdit === true && q.isSpecialOption == false))
              readOnlyTag = true;
          }
          else {
            readOnlyTag = true;
          }

          if ((this.spotLevel == true && q.isSpotLevel == true) || (this.orderLevel == true && q.isOrderLevel == true) || (this.groupLevel == true && q.isGroupLevel == true)) {

            var existingSet = sdata.serviceMetadata.filter(t => t.metadataKey === q.tag);

            if (existingSet != null && existingSet.length > 0) {
              if (existingSet[0].fileValue != null) {
                let arr: Array<OrderFile> = [];

                existingSet.forEach(fl => {
                  arr.push(fl.fileValue as OrderFile)
                });

                subGroup[q.tag] = q.isRequired ? new FormControl({ value: arr, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: arr, disabled: readOnlyTag });
              }
              else if (existingSet[0].objectValue != null && existingSet[0].objectValue != '') {
                if (existingSet[0].category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired]) {
                  let res: Array<ServiceBAMetadataObjectOption> = this.util.convertStringToThreeRequiredObject(existingSet[0].objectValue);
                  subGroup[q.tag] = q.isRequired ? new FormControl({ value: res, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: res, disabled: readOnlyTag });
                }
                else if (existingSet[0].category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired]) {
                  let resu: Array<ServiceBAGuaranteeMetadataObjectOption> = this.util.convertStringToFourRequiredObject(existingSet[0].objectValue);
                  subGroup[q.tag] = q.isRequired ? new FormControl({ value: resu, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: resu, disabled: readOnlyTag });
                }
                else {
                  let arr: Array<ServiceMetadataObjectOption> = this.util.convertStringToObjectMultiple(existingSet[0].objectValue);
                  subGroup[q.tag] = q.isRequired ? new FormControl({ value: arr, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: arr, disabled: readOnlyTag });
                }
              }
              else if (existingSet[0].arrayValue != null && existingSet[0].arrayValue != '') {
                let arr: Array<string> = existingSet[0].arrayValue.split(',');

                subGroup[q.tag] = q.isRequired ? new FormControl({ value: arr, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: arr, disabled: readOnlyTag });
              }
              else {
                if (existingSet[0].textValue != null)
                  subGroup[q.tag] = q.isRequired ? new FormControl({ value: existingSet[0].textValue, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: existingSet[0].textValue, disabled: readOnlyTag });
                else
                  subGroup[q.tag] = q.isRequired ? new FormControl({ value: null, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: null, disabled: readOnlyTag });
              }
            }
            else
              subGroup[q.tag] = q.isRequired ? new FormControl({ value: null, disabled: readOnlyTag }, Validators.required) : new FormControl({ value: null, disabled: readOnlyTag });
          }
        });

        //subGroup['specialOptionTags'] = new FormControl(specialOptionTags);

        mainGroup[sdata.service.code.toLowerCase()] = new FormGroup(subGroup);
      }
      else {
        let subGroup: any = {};

        subGroup['id'] = new FormControl(sdata.service.id);
        subGroup['serviceName'] = new FormControl(sdata.service.name);
        subGroup['serviceCode'] = new FormControl(sdata.service.code);
        subGroup['recentlyAdded'] = new FormControl(sdata.recentlyAdded);
        subGroup['isDeleted'] = new FormControl(false);
        subGroup['parentServices'] = new FormControl(sdata.parentServices == null ? [] : sdata.parentServices);
        //subGroup['isSpecialEdit'] = new FormControl(sdata.service.isSpecialEdit);
        subGroup['isOrderSpecialEdit'] = new FormControl(sdata.service.isOrderSpecialEdit);
        subGroup['isGroupSpecialEdit'] = new FormControl(sdata.service.isGroupSpecialEdit);
        subGroup['isSpotSpecialEdit'] = new FormControl(sdata.service.isSpotSpecialEdit);

        subGroup['isSpecialOptionEdit'] = new FormControl(sdata.service.isSpecialOptionEdit);

        //subGroup['specialOptionTags'] = new FormControl([]);
        //subGroup['readOnlyForm'] = new FormControl(true);

        mainGroup[sdata.service.code.toLowerCase()] = new FormGroup(subGroup);
      }
    });

    this.serviceMetadataRequest = new FormGroup(mainGroup);

    console.log("ngOnInit options form request", this.serviceMetadataRequest);
  }

  onServiceDelete(id: number): void {

    var serviceDeleted = this.services.find(t => t.serviceId == id);

    let depServicesToDelete: Array<ServiceData> = [];
    let allDependentServices: Array<ServiceData> = [];

    if (serviceDeleted == null)
      return;

    this.services.forEach(serv => {

      if (serv.parentServices != null && serv.parentServices.length > 0) {
        var servGroup = <FormGroup>this.serviceMetadataRequest.get(serv.service.code.toLowerCase());
        if (servGroup != null) {
          let parentExists: boolean = false;

          serv.parentServices.forEach(pSer => {
            var parentService = this.services.find(t => t.serviceId == pSer);
            var parentGroup = <FormGroup>this.serviceMetadataRequest.get(parentService.service.code.toLowerCase());
            if (parentGroup.controls['isDeleted'].value != true)
              parentExists = true;
          });

          if (parentExists == false)
            depServicesToDelete.push(serv);
        }
      }
    });

    depServicesToDelete.forEach(t => {

      var depGroup = <FormGroup>this.serviceMetadataRequest.get(t.service.code.toLowerCase());

      if (depGroup != null) {
        depGroup.controls['isDeleted'].setValue(true);
        depGroup.clearValidators();
        depGroup.setErrors(null);
        depGroup.updateValueAndValidity();

        for (const key in depGroup.controls) {
          depGroup.get(key).clearValidators();
          depGroup.get(key).setErrors(null);
          depGroup.get(key).updateValueAndValidity();
        }
      }
    });
  }

  onAddOptionalService(id: number, addService: boolean): void {
    if (addService) {
      var serviceToAdd = this.servicesOptional.find(t => t.serviceId == id);

      if (serviceToAdd != null) {
        let subGroup: any = {};

        subGroup['id'] = new FormControl(serviceToAdd.service.id);
        subGroup['serviceName'] = new FormControl(serviceToAdd.service.name);
        subGroup['recentlyAdded'] = new FormControl(serviceToAdd.recentlyAdded);
        subGroup['isDeleted'] = new FormControl(false);
        subGroup['isAdded'] = new FormControl(true);
        subGroup['parentServices'] = new FormControl(serviceToAdd.parentServices == null ? [] : serviceToAdd.parentServices);

        serviceToAdd.service.serviceConfig.options.forEach(q => {
          subGroup[q.tag] = q.isRequired ? new FormControl(null, Validators.required) : new FormControl(null);
        });

        this.serviceMetadataRequest.addControl(serviceToAdd.service.code.toLowerCase(), new FormGroup(subGroup));

        this.services.push(serviceToAdd);
      }
    }

    this.servicesOptional = this.servicesOptional.filter(t => t.service.id != id);
  }

  onSave({ value, valid }: { value: ServiceOptionsRequest, valid: boolean }) {
    console.log("onSave options form value", value);
    console.log("onSave options form request", this.serviceMetadataRequest);

    if (!valid) {
      this.alertService.showMessage("ERROR", Constants.addOptionsMandatoryFields, MessageSeverity.error);

      return;
    }

    this.optionsSave.emit(value);
  }
}
