<div id="share-links">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="carrierStatusUpdateForm" *ngIf="this.data.allowStatusUpdate == true">
      <div class="d-flex align-items-center">
          <div class="tylie-form-group form-check me-5">
            <input class="form-check-input" type="radio" name="carrierStatusCheckOpt" id="updatecarrierstatus" formControlName="carrierStatusCheckOpt" value="updatecarrierstatus" />
            <label class="form-check-label" for="updatecarrierstatus">Select Carrier POD Date</label>
          </div>
          <div class="tylie-form-group form-check">
            <input class="form-check-input" type="radio" name="carrierStatusCheckOpt" id="retrycarrierstatuscheck" formControlName="carrierStatusCheckOpt" value="retrycarrierstatuscheck" />
            <label class="form-check-label" for="retrycarrierstatuscheck">Retry</label>
          </div>
      </div>
    </form>

    <form [formGroup]="carrierSubmissionFailureForm" *ngIf="this.data.allowReSubmitWithDifferentVendor == true">
      <div class="d-flex align-items-center">
        <div class="tylie-form-group form-check me-5">
          <input class="form-check-input" type="radio" name="carrierSubmissionFailureOpt" id="reassigncarrier" formControlName="carrierSubmissionFailureOpt" value="reassigncarrier" />
          <label class="form-check-label" for="reassigncarrier"><span class="radio">Assign Carrier</span></label>
        </div>
        <div class="tylie-form-group form-check me-5">
          <input class="form-check-input" type="radio" name="carrierSubmissionFailureOpt" id="updatecarrierorder" formControlName="carrierSubmissionFailureOpt" value="updatecarrierorder" />
          <label class="form-check-label" for="updatecarrierorder"><span class="radio">Enter Carrier Order#</span></label>
        </div>
        <div class="tylie-form-group form-check">
          <input class="form-check-input" type="radio" name="carrierSubmissionFailureOpt" id="retrywithsamecarrier" formControlName="carrierSubmissionFailureOpt" value="retrywithsamecarrier" />
          <label class="form-check-label" for="retrywithsamecarrier"><span class="radio">Retry</span></label>
        </div>
      </div>
    </form>

    <ng-container *ngIf="this.data.allowReSendWithVendorAssignment == true">
      <resendassignvendor [destinationId]="data.destinationId"
                          [destinationCode]="data.destinationCode"
                          [vendorId]="data.vendorId"
                          [serviceId]="data.serviceId"
                          (saveVendorAssignment)="onReSendAssignVendorFormSubmit($event)"
                          (closePopUp)="closePopup()">
      </resendassignvendor>
    </ng-container>

    <ng-container *ngIf="this.data.allowVendorAssignment == true && isAssignVendor == true">
      <assignvendor [destinationId]="data.destinationId"
                    [destinationCode]="data.destinationCode"
                    [orderGuid] ="data.orderGuid"
                    [vendorId]="data.vendorId"
                    [adids]="adids"
                    [serviceId]="data.serviceId"
                    (saveVendorAssignment)="onAssignVendorFormSubmit($event)"
                    (closePopUp)="closePopup()">
      </assignvendor>
    </ng-container>

    <ng-container *ngIf="this.data.allowReSubmitWithDifferentVendor == true">
      <ng-container *ngIf="carrierSubmissionFailureForm.controls['carrierSubmissionFailureOpt'].value == 'reassigncarrier'">
        <assignvendor [destinationId]="data.destinationId"
                      [destinationCode]="data.destinationCode"
                      [vendorId]="data.vendorId"
                      [orderGuid] ="data.orderGuid"
                      [adids]="adids"
                      [serviceId]="data.serviceId"
                      (saveVendorAssignment)="onAssignVendorFormSubmit($event)"
                      (closePopUp)="closePopup()">
        </assignvendor>
      </ng-container>

      <ng-container *ngIf="carrierSubmissionFailureForm.controls['carrierSubmissionFailureOpt'].value == 'updatecarrierorder'">
        <updatevendorordernumber (saveVendorOrderNumber)="onUpdateVendorOrderNumberFormSubmit($event)"
                                  (closePopUp)="closePopup()">

        </updatevendorordernumber>
      </ng-container>

      <ng-container *ngIf="carrierSubmissionFailureForm.controls['carrierSubmissionFailureOpt'].value == 'retrywithsamecarrier'">
        <div class="tylie-alert tylie-alert--error mb-4">
          **Retry may result in duplicate orders being submitted to the carrier,
          Please verify that the order is not already submitted to the carrier, if it is submitted you can update the carrier order number(confimation code) by selecting the Enter Carrier Order# option.
        </div>
        <div class="tylie-type-body mb-3">Do you still want us to retry?</div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs  me-3" type="button" (click)="onRetryCarrierSubmissionYes()">Yes</button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onRetryCarrierSubmissionNo()">Cancel</button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="this.data.allowStatusUpdate == true">
      <ng-container *ngIf="carrierStatusUpdateForm.controls['carrierStatusCheckOpt'].value == 'updatecarrierstatus'">
        <updatepod (saveDeliveryPOD)="onUpdateDeliveryPODFormSubmit($event)"
                    (closePopUp)="closePopup()">

        </updatepod>
      </ng-container>

      <ng-container *ngIf="carrierStatusUpdateForm.controls['carrierStatusCheckOpt'].value == 'retrycarrierstatuscheck'">
        <div class="tylie-alert tylie-alert--error mb-4">
          We will try again to fetch the failed POD, but this will take few minutes
          and you will not be able to update the status untill we complete the retry.
        </div>
        
        <div class="tylie-type-body mb-3">Do you want to proceed ?</div>
        
        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs  me-3" type="button" (click)="onRetryCarrierStatusCheckYes()">Yes</button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onRetryCarrierStatusCheckNo()">Cancel</button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="this.data.allowPODUpdate == true">
      <updatepod (saveDeliveryPOD)="onUpdateDeliveryPODFormSubmit($event)"
                  (closePopUp)="closePopup()">

      </updatepod>
    </ng-container>
  
  </div>
</div>



