<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">ADD/EDIT PRODUCTS</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="addClientBrandProductForm" (ngSubmit)="onaddClientBrandProductFormSubmit(addClientBrandProductForm)" novalidate #ngForm="ngForm">
      <div class="br-b pb-3 mb-3" formArrayName="clientBrandProductGroups" *ngFor="let cbp of formclientBrandProductGroupsArray.controls; let i = index">
        <div *ngIf="true">
          <clientbrandproductgroup [cbpGroup]="cbp"
                                   [groupName]="i"
                                   [parentForm]="ngForm"
                                   [formGroupcount]="clientBrandProductGroups.length"
                                   [brandProductDataSource]="brandProductDataSource"
                                   [editConfig]="editConfig"
                                   [allowEdits]="allowEdits"
                                   [isClientEdit]="isClientEdit"
                                   (onProductSelectedEventTriggered)="onClientBrandProductSelected($event)"
                                   (onDeletecbpGroupEventTriggered)="onRemoveClientBrandProductFormGroup($event)">

          </clientbrandproductgroup>
        </div>
      </div>

<div class="d-flex justify-content-end mb-3">
  <a class="tylie-button tylie-button--link tylie-button--xs tylie-button--icon" (click)="onAddAnotherProduct()">
    <span class="tylie-button__icon">
      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
    </span>
    ADD ANOTHER PRODUCT
  </a>
</div>

<div class="d-flex justify-content-end">
  <button class="tylie-button tylie-button--xs" type="submit">
    Save
  </button>
  <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="closePopup()">
    Cancel
  </button>
</div>


    </form>
  </div>
</div>
