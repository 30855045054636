import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { IMedia } from "../../models/videofile/videofile.model";
import { Utilities } from '../../services/core/utilities';
import { SpotService } from '../../services/media/spot.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.css']
})

export class VideoplayerComponent implements OnInit {

  @Input('videoFilesSource') videoFilesSource: Array<IMedia> = [];
  @Input('playListName') playListName: string;
  @Input('isPopup') isPopup: boolean = false;

  @Input('currentIndex') currentIndex: number;
  @Input('currentItem') currentItem: IMedia;

  public overyLayHeightP: number = 0;
  public is169OverlayRequired: boolean = false;
  public is430verlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;

  @ViewChild('videoPlayerP', { static: false }) videoPlayerP: ElementRef;

  public playListHeight: number = (window.innerHeight - 60);

  constructor(ngZone: NgZone, private util: Utilities, private spotService: SpotService, private modalService: BsModalService) {
    window.onresize = (e) => {
      ngZone.run(() => {
        this.playListHeight = (window.innerHeight - 60);

        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {
    console.log("videoFilesSource", this.videoFilesSource);

    this.is169OverlayRequired = this.util.is169OverlayRequired(this.currentItem.format);
    this.is430verlayRequired = this.util.is430verlayRequired(this.currentItem.format);

    this.getProxyUrl();
  }

  public onClickPlaylistItem(item: IMedia, index) {
    this.currentIndex = index;
    this.currentItem = item;

    this.is169OverlayRequired = this.util.is169OverlayRequired(this.currentItem.format);

    item.playthisone = true;

    this.isOver169Overlay = false;
    this.isOver43Overlay = false;

    this.getProxyUrl();
  }

  public toggleOverlayP(overlayType: string) {
    if (overlayType == '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay
    }
    else {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay
    }

    this.overyLayHeightP = this.videoPlayerP.nativeElement.offsetHeight;
  }

  public onPlayerMouseOver(e) {
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;
  }

  private getProxyUrl() {

    this.spotService.getProxyUrl({ objectKey: this.currentItem.src }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.currentItem.proxyUrl = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public closePopup() {
    this.modalService.hide();
  }

}
