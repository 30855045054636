
import {finalize} from 'rxjs/operators';
import { Component, Inject, ViewChild, Input, OnInit, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { OrderUpdateResult } from "../../../../models/order/order-statusupdate-result.model";
import { OrderEditFTPDestinationPopupComponent } from "../../../popups/ordereditftpdestinationpopup/ordereditftpdestinationpopup.component";
import { Order } from "../../../../models/order/order.model";
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { DownloadMediaService } from '../../../../services/media/media-download.service';
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent, DxPopoverComponent } from "devextreme-angular";

import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { MediaDetailComponent } from "../../../mediadetail/mediadetail.component";
import { UploadOrderFileRequest } from "../../../../models/order/order-uploadfile.model";
import { uploadFileCategoryEnum } from "../../../../configurations/enums/enums";
import { OrderContentDownloadViewModel, OrderFTPDestinationDetailModel } from "../../../../models/order/order-userdestination";
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';
import {  TranscodeAssetFile } from '../../../../models/order/transcodeorder.model';
@Component({
  selector: 'order-detail-download',
  templateUrl: './order-detail-download.component.html',
  styleUrls: ['./order-detail-download.component.css']
})
export class OrderDetailDownloadsComponent implements OnInit, AfterViewInit {
  public modalRef: BsModalRef;
  public orderDetailContentDownloadStore: OrderContentDownloadViewModel[] = [];
  public showLoader = false;

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @Input('orderGuid') orderGuid: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private orderService: OrderService,
    private mediaService: DownloadMediaService,
    private userProfileService: UserProfileService,
    private uploadOrderFileService: UploadOrderFileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.orderGuid != null && this.orderGuid != undefined) {
      this.getContentDownloadFromOrder();
    }
  }

  public getContentDownloadFromOrder() {
    this.showLoader = true;
    this.dataGrid.instance.beginCustomLoading("Loading...");

    this.orderService.getContentDownloadDetailsForOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.orderDetailContentDownloadStore = res.result as OrderContentDownloadViewModel[];
        this.alertService.logMessage(this.orderDetailContentDownloadStore);
      }
      else {
        this.orderDetailContentDownloadStore = new OrderContentDownloadViewModel[0];
        this.util.handleIsNotSuccess(res.errors);
      }
      this.showLoader = false;
      this.dataGrid.instance.endCustomLoading();
    },
      error => {
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.orderDetailContentDownloadStore = new OrderContentDownloadViewModel[0];
        this.util.handleError(error);
      });
  }

  public onDownload(assetFileGuid, fileName, fileNameWithExt, filePath) {
    this.showLoader = true;
    this.mediaService.initiateS3TranscodeFileDownload(this.getSelectedDownloadableTranscodeAssetFiles(assetFileGuid, fileName, fileNameWithExt, filePath));
    this.showLoader = false;
  }

  private getSelectedDownloadableTranscodeAssetFiles(assetFileGuid: string, fileName: string, fileExtension: string, filePath: string): Array<TranscodeAssetFile> {
    let assetFiles: TranscodeAssetFile[] = [];

    let af = new TranscodeAssetFile();
    af.assetFileGuid = assetFileGuid;
    af.filePath = filePath;
    af.fileName = fileName;
    af.fileExtension = fileExtension;

    assetFiles.push(af);

    return assetFiles;
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

}

