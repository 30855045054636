import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { UserProfileService } from '../services/user/user-profile.service';

@Pipe({
  name: 'usertimezoneToUTC'
})

export class UserTimeZoneToUtc implements PipeTransform {

  constructor(private userProfileService: UserProfileService) { }

  transform(value: Date, format: string): string
  {
    var userTimeZoneCode = this.userProfileService.getUserProfile().ianaCode;
    if (!value)
      return "";

    var userDate = moment.tz(value, userTimeZoneCode);

    return moment.utc(userDate).format(format);

  }
}
