
<div class="d-flex justify-content-end mb-3">
  <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()">
    <span class="tylie-button__icon">
      <icon-refresh></icon-refresh>
    </span>
    <span class="tylie-button__text">REFRESH</span>
  </button>
</div>

<div class="row justify-content-between">
    <div class="col-md-6">
        <div class="tylie-input-search">
            <div class="tylie-input-search__inner">
                <div class="tylie-input-search__icon">
                    <icon-search></icon-search>
                </div>
                <input
                    class="tylie-input-search__text"
                    placeholder="Search by keyword"
                    name="srch-term"
                    id="srch-term"
                    type="search"
                    [(ngModel)]="quickSearchValue"
                    (keydown.enter)="onEnterQuickSearch($event)"
                />
            </div>
            <div class="tylie-input-search__btn">
                <button
                    class="tylie-button tylie-button--sm"
                    type="button"
                    (click)="quickSearch()"
                >
                    Search
                </button>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="row">
            <div class="col-4">
                <label
                    for="sortBy"
                    class="mt-3"
                    >Sort By</label
                >
            </div>
            <div class="col-8">
                <dx-select-box
                    [dataSource]="sortDataSource"
                    class="tylie-select form-control"
                    [searchEnabled]="true"
                    valueExpr="id"
                    displayExpr="name"
                    [value]="selectedSortOrder"
                    (onValueChanged)="onSortValueChanged($event)"
                >
                </dx-select-box>
            </div>
        </div>
    </div>
</div>

<div
class="grid-container"
*ngFor="let taggerAd of taggerAds"
>
<div class="grid-item">
    <div
        *ngIf="
            taggerAd.baseCreativeGuid &&
            taggerAd.baseCreativeDetails?.baseCreative
        "
        class="creative-thumbnail-content"
    >
        <img
            class="quickLink"
            [src]="
                taggerAd.baseCreativeDetails?.baseCreative
                    ?.thumbnailDisplayUrl
            "
            *ngIf="
                taggerAd.baseCreativeDetails?.baseCreative
                    ?.isPlayable
            "
            (click)="
                quickPlay(
                    taggerAd.baseCreativeDetails?.baseCreative
                        ?.proxyurl,
                    taggerAd.baseCreativeDetails?.baseCreative
                        ?.creativeName,
                    taggerAd.baseCreativeDetails?.baseCreative
                        ?.thumbnailurl,
                    taggerAd.baseCreativeDetails?.baseCreative
                        ?.format
                )
            "
        />
        <img
            [src]="
                taggerAd.baseCreativeDetails?.baseCreative
                    ?.thumbnailDisplayUrl
            "
            *ngIf="
                !taggerAd.baseCreativeDetails?.baseCreative
                    ?.isPlayable
            "
        />
    </div>
    <div class="thumbnail-content">
        <div
            *ngIf="
                taggerAd.offerCardGuid &&
                taggerAd.offerCardDetails?.offerCard
            "
        >
            <img
                [src]="
                    taggerAd.offerCardDetails?.offerCard
                        ?.cardDisplayUrl
                "
                class="gridThumbnail"
            />
        </div>
        <div
            *ngIf="
                taggerAd.endCardGuid &&
                taggerAd.endCardDetails?.endCard
            "
        >
            <img
                [src]="
                    taggerAd.endCardDetails?.endCard?.cardDisplayUrl
                "
            />
        </div>
    </div>
</div>
<div class="grid-item">
    <div class="row">
        <div class="col-md-12">
            <span class="tagger-title">{{ taggerAd.adName }}</span>
        </div>
        <div class="col-md-6">
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Work Order:</Label>
                <span>{{ taggerAd.workOrder }}</span>
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Created:</Label>
                <span>{{
                    taggerAd.createdDate
                        | usertimezone : "MM/DD/YYYY HH:mm A"
                }}</span>
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Expiring:</Label>
                <span
                    *ngIf="
                        taggerAd.baseCreativeGuid &&
                        taggerAd.baseCreativeDetails?.baseCreative
                    "
                    >{{
                        taggerAd.baseCreativeDetails?.baseCreative
                            ?.expirydate
                            | usertimezone : "MM/DD/YYYY HH:mm A"
                    }}</span
                >
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Duration:</Label>
                <span
                    *ngIf="
                        taggerAd.baseCreativeGuid &&
                        taggerAd.baseCreativeDetails?.baseCreative
                    "
                    >{{
                        taggerAd.baseCreativeDetails?.baseCreative
                            ?.durationinsecs
                    }}</span
                >
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Base:</Label>
                <span
                    *ngIf="
                        taggerAd.baseCreativeGuid &&
                        taggerAd.baseCreativeDetails?.baseCreative
                    "
                    >{{
                        taggerAd.baseCreativeDetails?.baseCreative
                            ?.creativeName
                    }}</span
                >
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Offer:</Label>
                <span
                    *ngIf="
                        taggerAd.offerCardGuid &&
                        taggerAd.offerCardDetails?.offerCard
                    "
                    >{{
                        taggerAd.offerCardDetails?.offerCard
                            ?.cardName
                    }}</span
                >
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">End:</Label>
                <span
                    *ngIf="
                        taggerAd.endCardGuid &&
                        taggerAd.endCardDetails?.endCard
                    "
                    >{{
                        taggerAd.endCardDetails?.endCard?.cardName
                    }}</span
                >
            </div>
            <div class="tagger-detail-container">
                <Label class="tylie-form-group__lbl">Voice:</Label>
                <span
                    *ngIf="
                        taggerAd.voiceOverGuid &&
                        taggerAd.voiceOverDetails?.voiceOver
                    "
                    >{{
                        taggerAd.voiceOverDetails?.voiceOver
                            ?.voiceOverName
                    }}</span
                >
            </div>
        </div>
        <div class="col-md-6">
            <Label class="tylie-form-group__lbl">Notes:</Label>
            <div class="p-normal">{{ taggerAd.note?.payload }}</div>
            <span class="p-tiny">{{
                taggerAd.note?.createdBy
            }}</span
            >&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{
                taggerAd.note?.createdDate
                    | usertimezone : "MM/DD/YYYY HH:mm A"
            }}</span>
            <div>
                <a
                    class="btn-link"
                    (click)="editNote(taggerAd)"
                    *ngIf="!taggerAd.note?.payload"
                    >Add Note</a
                >
                <a
                    class="btn-link"
                    (click)="editNote(taggerAd)"
                    *ngIf="taggerAd.note?.payload"
                    >View/Edit Note</a
                >
            </div>
        </div>
    </div>
</div>
<div class="grid-item">
    <div class="row tagger-actions">
        <button
            [ngClass]="statusClass(taggerAd.displayStatus)"
            *ngIf="taggerAd.displayStatus"
        >
            {{ taggerAd.displayStatus }}
        </button>
        <a
            class="btn-link"
            (click)="onUnArchive(taggerAd)"
        >
            <i class="tylicon tylicon-delete-close-blue"></i>
            &nbsp;UNARCHIVE
        </a>
    </div>
</div>
</div>
<div class="no-records" *ngIf="taggerAds?.length === 0">
    <span>No Records found</span>
</div>

