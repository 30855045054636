import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-highresshareconfirmpopup',
  templateUrl: './highresshareconfirmpopup.component.html',
  styleUrls: ['./highresshareconfirmpopup.component.css']
})
export class HighresshareconfirmpopupComponent extends BasePopupComponent {

  @Input('askingConfirmation') askingConfirmation: boolean = false;
  @Input('isGenerateShareableLink') isGenerateShareableLink: boolean = false;
  @Input('generatedLink') generatedLink: string;

  public headerText: string = "";
  public description: string = "";
  public successButton: string = "";
  public isShowCopy: boolean = false;

  public onConfirmed: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    console.log(this.askingConfirmation);
    console.log(this.isGenerateShareableLink);
    this.onConfirmed = new Subject();
    if (this.isGenerateShareableLink) {
      this.headerText = "GENERATE SHAREABLE LINK CONFIRMATION";
      if (this.askingConfirmation) {
        this.description = "You are ordering a High-Res master which will incur a charge";
        this.successButton = "Confirm";
        this.isShowCopy = false;
      } else {
        this.description = "Generated Shareable Link successfully.";
        this.successButton = "Ok";
        this.isShowCopy = true;
      }
    } else {
      this.headerText = "ORDER HIGH RES MASTER CONFIRMATION";
      if (this.askingConfirmation) {
        this.description = "You are ordering a High-Res master which will incur a charge";
        this.successButton = "Confirm";
        this.isShowCopy = false;
      } else {
        this.description = "High Res master file shared successfully.";
        this.successButton = "Ok";
        this.isShowCopy = false;
      }
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public confirmClicked() {
    this.onConfirmed.next(true);
    this.closePopup();
  }

  public copyLink() {
    var urlElement = document.getElementById("sharelink");
    if (urlElement != null || urlElement != undefined) {
      (<HTMLInputElement>urlElement).select();
      document.execCommand("copy");
    }
  }

}
