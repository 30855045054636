
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from '../../../../configurations/constants';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Utilities } from '../../../../services/core/utilities';
import { AuthService } from '../../../../services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserChangePassword } from '../../../../models/user/user-changepassword.model';
import { AccountSettingsService } from '../../../../services/user/account-settings.service';
import { RouteConstants } from '../../../../configurations/route-constants';


@Component({
  selector: 'changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {

  @Input() isModal: boolean = false;
  @Input() userName: string = "";

  @Output() showLoginOnPasswordUpdate: EventEmitter<void> = new EventEmitter<void>();

  ChangePassword: FormGroup;

  public passwordPattern = Constants.passwordPattern;
  public isLoading = false;
  public errMessage: string = "";

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private accountSettingsService: AccountSettingsService,
    public util: Utilities,
    public modalRef: BsModalRef) {

    this.ChangePassword = new FormGroup({
      oldpassword: new FormControl('', Validators.required),
      newpassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
      verifypassword: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  public saveNewPassword(ChangePasswordObj: any) {

    if (this.ChangePassword.invalid || this.ChangePassword.value.verifypassword != this.ChangePassword.value.newpassword) {
      return;
    }

    let userChangePassword = new UserChangePassword(
      this.ChangePassword.value.oldpassword,
      this.ChangePassword.value.newpassword,
      this.userName
    );

    this.isLoading = true;

    if (this.isModal) {
      this.accountSettingsService.updateUserPasswordEndpoint(userChangePassword).pipe(
        finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.userPasswordUpdateSuccessful, MessageSeverity.success);
            this.modalClose();
          }
          else {
            this.errMessage = res.errors.join(',');            
          }
        },
          error => {
            if (this.util.handleError(error)) {
              this.modalClose();
            }
          });
    }
    else {
      this.accountSettingsService.updateUserPasswordAfterExpiry(userChangePassword).pipe(
        finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.userPasswordUpdateSuccessful, MessageSeverity.success);
            this.showLoginOnPasswordUpdate.emit();
          }
          else {
            this.errMessage = res.errors;
          }
        },
          error => {
            this.util.handleError(error)
          });
    }
  }


  public modalClose() {
    this.modalRef.hide();
    this.ChangePassword.reset();
    this.errMessage = "";
  }

  public forgotPassword() {
    this.router.navigate([RouteConstants.forgotPasswordRoute]);
  }
}
