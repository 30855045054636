import { NotificationChoice } from "./notification-choice.model";

export class SmsNotificationPreference{

  public phoneNumber: string;
  public notificationChoices: NotificationChoice[];

  constructor(phoneNumber: string, notificationChoices: NotificationChoice[]) {
    this.phoneNumber = phoneNumber;
    this.notificationChoices = notificationChoices;
  }

}
