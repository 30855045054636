import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forEach } from 'core-js/es7/array';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../configurations/constants';
import { customTimeCodeValidator, customTimeValidator, EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { UpdateVaultAssetServicesResponse, VaultAssetDetail, Vaultassetlineitem, Vaultassetnotes, VaultFile, Vaultservice } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { GlobalService } from '../../../services/core/global.service';
import { Utilities } from '../../../services/core/utilities';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { VaultService } from '../../../services/vault/vault.service';
import { AddVaultAssetAdditionServicePopupComponent } from '../../popups/add-vault-asset-addition-service-popup/add-vault-asset-addition-service-popup.component';
import { AddVaultAssetAttachmentPopupComponent } from '../../popups/add-vault-asset-attachment-popup/add-vault-asset-attachment-popup.component';
import { AddVaultAssetNoteComponent } from '../../popups/add-vault-asset-note/add-vault-asset-note.component';
import { VaultDetailHeaderEditPopupComponent } from '../../popups/vaultdetailheadereditpopup/vaultdetailheadereditpopup.component';

@Component({
  selector: 'vault-request-detail',
  templateUrl: './vault-request-detail.component.html',
  styleUrls: ['./vault-request-detail.component.css']
})
export class VaultRequestDetailComponent implements OnInit {

  @Input('vaultRequestGuid') vaultRequestGuid: string;
  @Input('statusLabel') statusLabel: string;

  @ViewChildren('pop') pop: QueryList<PopoverDirective>;

  public modalRef: BsModalRef;

  public attachments: Array<VaultFile>;
  public assetlineitems: Array<Vaultassetlineitem>;
  public additionServices: Array<Vaultservice>;
  public notes: Array<Vaultassetnotes>;
  public vaultRequestDetail: any;
  public triedToSubmitRequest: boolean = false;
  public isEditMode: boolean = false;

  public createVaultRequest: FormGroup;

  constructor(
    private vaultService: VaultService,
    public util: Utilities,
    public modalService: BsModalService,
    private alertService: AlertService,
    private configService: ConfigService,
    private ds: DownloadMediaService,
    private router: Router,
    private gs: GlobalService,
    private _fb: FormBuilder) { }

  ngOnInit() {
    this.getVaultRequestDetail();
  }

  private getVaultRequestDetail() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.vaultService.getVaultRequestDetail(this.vaultRequestGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.vaultRequestDetail = res.result;
        console.log(this.vaultRequestDetail);
        this.createEmptyForm();     
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public editHeader() {
    this.isEditMode = true;
  }

  public onEdit(category: string) {

    var initialState = {
      vaultRequestGuid: this.vaultRequestGuid,
      header: this.vaultRequestDetail,
      config: this.vaultRequestDetail.config,
      editCategory: category
    };

    this.modalRef = this.modalService.show(VaultDetailHeaderEditPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onEditComplete.subscribe(result => {
      this.onEditCompleteSubscriptionRaised(result);
    });
  }

  public onEditCompleteSubscriptionRaised(detail) {
    this.vaultRequestDetail = detail;
    this.isEditMode = false;
  }

  public get header() {
    return (<FormGroup>this.createVaultRequest.controls['header']);
  }

  public get cartons() {
    return <FormArray>this.createVaultRequest.get('cartons');
  }

  private createEmptyForm() {
    if (this.vaultRequestDetail.isRequestTypeIngest) {
      this.createVaultRequest = this._fb.group({
        cartons: this.newCartonArrayForm,
        ingestStartDate: [this.vaultRequestDetail.ingestStartDate]
      });
    }
    else {
      this.createVaultRequest = this._fb.group({
       
      });
    }
  }

  private get newCartonForm() {
    var cartonFb = this._fb.group({
      cartonColumn: [null, Validators.required],
      cartonPosition: [null, Validators.required],
      cartonBarcode: [null, Validators.required],
      containsCartonErrors: [null],
      assets: this.newAssetArrayForm({assets:null})
    });

    cartonFb.controls['containsCartonErrors'].setValue(true);

    return cartonFb;
  }

  private get newCartonArrayForm() {
    var cartons: FormArray = this._fb.array([]);

    if (this.vaultRequestDetail.cartons == null || this.vaultRequestDetail.cartons.length == 0) {
      cartons.push(this.newCartonForm);
    }
    else {
      this.vaultRequestDetail.cartons.forEach(val => {
        val
        var cartonFb = this._fb.group({
          cartonColumn: [val.cartonColumn, Validators.required],
          cartonPosition: [val.cartonPosition, Validators.required],
          cartonBarcode: [val.cartonBarcode, Validators.required],
          containsCartonErrors: [null],
          assets: this.newAssetArrayForm(val)
        });

        cartonFb.controls['containsCartonErrors'].setValue(true);

        cartons.push(cartonFb);
      });
    }

    return cartons as FormArray;
  }

  private newAssetArrayForm(carton: any) {
    var assetsInCarton: FormArray = this._fb.array([]);

    if (carton.assets == null || carton.assets.length == 0) {
      var assetFb = this._fb.group({
        vaultAssetStatus: [null],
        assetFormatName: [null],
        assetFormat: [null, Validators.required],
        assetBarcode: [null, Validators.required],
        assetDescription: [null],
        vaultAssetId: [0],
        assetLineItemData: this.newAssetLineItemArrayForm({ assetLineItemData : null}),
      });

      assetsInCarton.push(assetFb);
    }
    else {
      carton.assets.forEach(val => {
        val
        var assetFb = this._fb.group({
          assetFormatName: [val.assetFormatName],
          vaultAssetStatus: [val.vaultAssetStatus],
          assetFormat: [val.assetFormat, Validators.required],
          assetBarcode: [val.assetBarcode, Validators.required],
          assetDescription: [val.assetDescription],
          vaultAssetId: [val.vaultAssetId],
          assetLineItemData: this.newAssetLineItemArrayForm(val),
        });

        assetsInCarton.push(assetFb);
      });
    }

    return assetsInCarton as FormArray;
  }

  private newAssetLineItemArrayForm(asset) {
    var assetsLineAssets: FormArray = this._fb.array([]);

    if (asset.assetLineItemData == null || asset.assetLineItemData.length == 0) {
      var seq = 1;
      var assetLineFb = this._fb.group({
        assetLineSequenceId: [null],
        adId: [null, Validators.required],
        title: [null, Validators.required],
        length: [null, Validators.compose([customTimeValidator])],
        vaultAssetLineItemId: [0]
      });

      assetLineFb.controls['assetLineSequenceId'].setValue(seq);

      assetsLineAssets.push(assetLineFb);
    }
    else {
      asset.assetLineItemData.forEach(val => {
        val
        var assetLineFb = this._fb.group({
          assetLineSequenceId: [val.assetLineSequenceId],
          adId: [val.adId, Validators.required],
          title: [val.title, Validators.required],
          length: [val.length, Validators.compose([customTimeValidator])],
          vaultAssetLineItemId: [val.vaultAssetLineItemId]
        });

        assetsLineAssets.push(assetLineFb);
      });

    }
    return assetsLineAssets as FormArray;
  }

  public addAnotherCarton() {
    this.cartons.push(this.newCartonForm);
  }

  public onDeleteCartonEmitted(cartonIndex) {
    this.cartons.removeAt(cartonIndex);
  }

  public onAddNewNote() {
    const initialState = {
      vaultRequestId: this.vaultRequestDetail.vaultRequestId
    };
    this.modalRef = this.modalService.show(AddVaultAssetNoteComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveVaultAssetNote.subscribe((result: Vaultassetnotes) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultRequestDetail.vaultAssetNotes.push(result);
      }
    });
  }

  public onAddNewService() {
    const initialState = {
      vaultRequestId: this.vaultRequestDetail.vaultRequestId
    };

    this.modalRef = this.modalService.show(AddVaultAssetAdditionServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSelectAdditionalServiceVault.subscribe((result: UpdateVaultAssetServicesResponse) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultRequestDetail.vaultServices = result.vaultServices;
      }
    });
  }

  public onAddNewAttachment() {
    var initialState = {
      vaultRequestId: this.vaultRequestDetail.vaultRequestId,
      vaultRequestGuid: this.vaultRequestDetail.vaultRequestGuid,
      fileType: "Attachment"
    };

    this.modalRef = this.modalService.show(AddVaultAssetAttachmentPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSelectAttachmentVault.subscribe((result: VaultFile) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultRequestDetail.vaultFiles.push(result);
      }
    });
  }

  public onAddEstimates() {
    var initialState = {
      vaultRequestId: this.vaultRequestDetail.vaultRequestId,
      vaultRequestGuid: this.vaultRequestDetail.vaultRequestGuid,
      fileType: "Estimate"
    };

    this.modalRef = this.modalService.show(AddVaultAssetAttachmentPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSelectAttachmentVault.subscribe((result: VaultFile) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultRequestDetail.vaultFiles.push(result);
      }
    });
  }

  public onDownloadFile(vaultFileGuid, fileName) {
    if (vaultFileGuid == null || vaultFileGuid == '00000000-0000-0000-0000-000000000000' || vaultFileGuid == '')
      return;

    this.vaultService.downloadVaultFile(vaultFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onSaveChanges() {
    this.triedToSubmitRequest = true;

    console.log(this.createVaultRequest);

    if (!this.createVaultRequest.valid)
      return;
    else {
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      this.vaultService.updateVaultRequestDetail(this.vaultRequestGuid, {
        cartons: this.createVaultRequest.value.cartons,
        ingestStartDate: this.createVaultRequest.value.ingestStartDate,
        warehouse: this.vaultRequestDetail.warehouseId, isRequestComplete: false
      }).subscribe((res: any) => {

        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderVaultUpdatedSuccessfully, MessageSeverity.success);
          console.log(res);
          this.vaultRequestDetail = res.result;
          this.alertService.ShowLoader(false);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
  }

  public onCompleteVaultRequest() {
    this.triedToSubmitRequest = true;

    if (!this.createVaultRequest.valid) {
      return;
    }
    else {
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      this.vaultService.updateVaultRequestDetail(this.vaultRequestGuid, {
        cartons: this.createVaultRequest.value.cartons,
        ingestStartDate: this.createVaultRequest.value.ingestStartDate,
        warehouse: this.vaultRequestDetail.warehouseId, isRequestComplete: true
      }).subscribe((res: any) => {

        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderVaultUpdatedSuccessfully, MessageSeverity.success);
          this.vaultRequestDetail = res.result;
          this.gs.vaultRequestCompleted();
          this.gs.closePopover();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
  }

  public onClose() {
    this.gs.closePopover()
  }

  public onAttDelete(fileName: string, vaultFileGuid: string) {

    this.vaultService.deleteVaultFile(vaultFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var attToDeleteIndex = this.vaultRequestDetail.vaultFiles.findIndex(t => t.vaultFileGuid == vaultFileGuid);

        if (attToDeleteIndex >= 0)
          this.vaultRequestDetail.vaultFiles.splice(attToDeleteIndex, 1);

        this.alertService.showMessage("SUCCESSS", fileName + ' deleted successfully', MessageSeverity.success, true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    this.onHidePop();
  }

  public onHidePop() {
    this.pop.forEach(p => {
      p.hide();
    });
  }

}
