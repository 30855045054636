<!--<nav-header headerTitle="Client" link="Back To All Clients" [linkRoute]="clientsRoute"></nav-header>-->

<ng-container *ngIf="client">
  <div class="tyl-right">
    <div class="p-normal" *ngIf="client.config.showEditStartedByUserProfileName == true">
      <span>
        <b>Open for Edit by</b>
      </span>
      <span class="text-danger">
        <b>{{client.editStartedByUserProfileName}}</b>
      </span>
    </div>
  </div>
  <div class="d-flex justify-content-between mb-4">
    <div class="d-flex">
      <div class="tylie-img-container me-3">
        <img *ngIf="client.base64Logo" class="tylie-img" src="{{client.base64Logo}}" alt="Logo">
        <span class="tylie-img__fallback" *ngIf="client.base64Logo == null"> 110x110 </span>
      </div>
      <div>
        <h3 class="tylie-type-h3 mb-2">{{client.name}}</h3>
        <div class="tylie-data tylie-data--block" *ngIf="client.aliasId">
          <div class="tylie-data__lbl" >Client ID</div>
          <div class="tylie-data__txt">{{client.aliasId}}</div>
        </div>
        
        <div class="tylie-data tylie-data--block" *ngIf="client.accountExecutiveUserProfileName">
        <div class="tylie-data__lbl">CX Manager</div>
        <div class="tylie-data__txt">{{client.accountExecutiveUserProfileName}}</div>
        <div class="tylie-data__txt">{{client.clientType}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <button type="button" *ngIf="client.config.showInActivateClient == true" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onClientStatusUpdate(false)">
        <span class="tylie-button__icon"><icon-expire></icon-expire></span>
        <span class="tylie-button__text">InActivate Client</span>
      </button>
      <button type="button" *ngIf="client.config.showDeleteDraftClient == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="ondeleteDraftClient()">
        <span class="tylie-button__icon"><icon-expire></icon-expire></span>
        <span class="tylie-button__text">Delete Draft</span>
      </button>
      <button type="button" *ngIf="client.config.showActivateClient == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onClientStatusUpdate(true)">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">Activate Client</span>
      </button>
      <button type="button" *ngIf="client.config.showMakeClientBillable == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onMakeClientBillable()">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">Make Client Billable</span>
      </button>
      <button type="button" *ngIf="client.config.showClientEdit == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onEditClient()">
        <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
        <span class="tylie-button__text">Edit Client</span>
      </button>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-6" id="create-client-section" *ngIf="client.clientContact">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            CONTACT DETAILS
          </h4>
        </div>
        <div class="tylie-panel__body" id="createClientBody">
          <div class="tylie-data tylie-data--c-block">
            <span class="tylie-data__txt">{{client.clientContact.name}}</span>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="tylie-data tylie-data--c-block">
                <span for="Address1"  class="tylie-data__txt">{{client.clientContact.address1}}</span>
              </div>
              <div class="tylie-data tylie-data--c-block">
                <span *ngIf="client.clientContact.city" class="tylie-data__txt">{{client.clientContact.city}}</span>
                <span *ngIf="client.clientContact.city && (client.clientContact.state || client.clientContact.zipCode)" class="tylie-data__txt">,</span>
                <span class="tylie-data__txt" *ngIf="client.clientContact.country || client.clientContact.state || client.clientContact.zipCode">{{client.clientContact.state}} {{client.clientContact.zipCode}},  {{client.clientContact.country}}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="tylie-data tylie-data--c-block">
                <span class="tylie-data__txt">{{client.clientContact.address2}}</span>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="client.clientContact.phone">
            <div class="col-md-8">
                <div for="Phone" class="tylie-data tylie-data--c-block">
                  <label class="tylie-data__lbl">phone: </label><span class="tylie-data__txt">({{client.clientContact.phone | slice:0:3}})  {{client.clientContact.phone | slice:3:6}}-{{client.clientContact.phone | slice:6:10}}</span>
                </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
                <div for="Email" class="tylie-data tylie-data--c-block" *ngIf="client.clientContact.email"><label class="tylie-data__lbl">Email: </label><span class="tylie-data__txt">{{client.clientContact.email}}</span></div>
                <div for="TeamEmail" class="tylie-data tylie-data--c-block" *ngIf="client.clientContact.teamEmail"><label class="tylie-data__lbl">team email:</label><span class="tylie-data__txt">{{client.clientContact.teamEmail}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6" id="create-client-section">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            OTHER
          </h4>
        </div>
        
        <div class="tylie-panel__body" id="createClientBody">
          <div class="row">
            <div class="col-md-6" *ngIf="client.isPoRequired == true || client.isReviewRequired == true
                  || client.isBundleServicesOffered == true || client.isVaultBilling == true || client.isOrderFinalReviewRequired || client.isViewRestricted || client.isOnTrail == true">
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isPoRequired == true">
                <span for="poRequired" class="tylie-data__lbl">PO Required</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isReviewRequired == true">
                <span for="orderReviewRequired" class="tylie-data__lbl">Order Review Required</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isVaultBilling == true">
                <span for="vaultBilling" class="tylie-data__lbl">Vault Billing</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isOrderFinalReviewRequired == true">
                <span for="orderFinalReviewRequired" class="tylie-data__lbl">Order Final Review Required</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isViewRestricted == true">
                <span for="isViewRestricted" class="tylie-data__lbl">Restricted View</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isFileDeliveryConfigExists == true">
                <span for="isFileDeliveryConfigExists" class="tylie-data__lbl">File Delivery Configured</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isOnTrail == true">
                <span for="poRequired" class="tylie-data__lbl">On Trial</span>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.stdStorageDays">
                <div for="status" class="tylie-data__lbl">Standard (Minimum # of days)</div>
                <div class="tylie-data__txt">{{client.stdStorageDays}}</div>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.infrqStorageDays">
                <div for="status" class="tylie-data__lbl">Infrequent Access (Minimum # of days)</div>
                <div class="tylie-data__txt">{{client.infrqStorageDays}}</div>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.archiveDurationInDays">
                <div for="status" class="tylie-data__lbl">Archive (Minimum # of days)</div>
                <div class="tylie-data__txt">{{client.archiveDurationInDays}}</div>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.activeTandemStorageInDays">
                <div for="status" class="tylie-data__lbl">Active Tandem Storage (Minimum # of days)</div>
                <div class="tylie-data__txt">{{client.activeTandemStorageInDays}}</div>
              </div>
              <div class="tylie-data tylie-data--c-block" *ngIf="client.isClientTypePartner">
                <div for="status" class="tylie-data__lbl">Billable Clients</div>
                <div class="tylie-data__txt" *ngFor="let em of client.partnerClientNames;">{{em}}</div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="tylie-data tylie-data--c-block">
                <div for="status" class="tylie-data__lbl">Status</div>
                <div class="tylie-data__txt">{{client.status}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.transcodeBillingSettings">
                <div for="status" class="tylie-data__lbl">Transcode Billing Settings</div>
                <div class="tylie-data__txt">{{client.transcodeBillingSettings.billingType}} ({{client.transcodeBillingSettings.billingCycle}})</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.assetShareBillingSettings">
                <div for="status" class="tylie-data__lbl">Asset Share Billing Settings</div>
                <div class="tylie-data__txt">{{client.assetShareBillingSettings.billingType}} ({{client.assetShareBillingSettings.billingCycle}})</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.managementFeeSettings">
                <div for="status" class="tylie-data__lbl">Management Fee Settings</div>
                <div class="tylie-data__txt">{{client.managementFeeSettings.billingCycle}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.storageManagementFeeSettings">
                <div for="status" class="tylie-data__lbl">Cloud Storage Billing Settings</div>
                <div class="tylie-data__txt">{{client.storageManagementFeeSettings.billingCycle}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.opsNotes">
                <div for="status" class="tylie-data__lbl">OPs Notes</div>
                <div class="tylie-data__txt">{{client.opsNotes}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.podDistributionEmails">
                <div class="tylie-data__lbl">POD Recipients</div>
                <div class="tylie-data__txt">{{client.podDistributionEmails}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.orderedBy">
                <div class="tylie-data__lbl">Ordered By</div>
                <div class="tylie-data__txt">{{client.orderedBy}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.costCenter.length > 0">
                <div class="tylie-data__lbl">Cost Center</div>
                <div class="tylie-data__txt">{{client.costCenterDisplayString}}</div>
              </div>

              <div class="tylie-data tylie-data--c-block" *ngIf="client.inVoiceTo.length > 0">
                <div class="tylie-data__lbl">Bill To</div>
                <div class="tylie-data__txt">{{client.billToDisplayString}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="client.brandSelectionList">
      <selected-brands [allowEdits]="false"
                       [isClientView]="true"
                       [clientBrands]="client.brandSelectionList">
      </selected-brands>
  </ng-container>

  <div class="tylie-panel" *ngIf="((client.postHouses != null && client.postHouses?.length > 0) || client.config.showAddPostHouse == true)">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          POST HOUSES
        </h4>
      </div>
      <div class="tylie-panel__body">
        <ng-container *ngIf="client.config.showAddPostHouse == true">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddPostHouse()" *ngIf="postHouseAddMode == false">
            <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
            <span class="tylie-button__text">Add Post House</span>
          </button>
        </ng-container>

        <ng-container *ngIf="postHouseAddMode == true">
          <div class="panel-body" *ngIf="client.config.showAddPostHouse == true">
            <form [formGroup]="addClientPostHouseForm" (ngSubmit)="onFormSubmit(addClientPostHouseForm)" novalidate #f="ngForm">
              <div class="row" id="addPostHouse" *ngIf="isPostHouseEdit == false">
                  <div class="col-md-4 ">
                    <div class="tylie-form-group form-group" [ngClass]="{error: (addClientPostHouseForm.controls['postHouseId'].errors && f.submitted)}">
                      <label class="tylie-form-group__lbl" for="product">Post House<span class="required">*</span></label>
                      <dx-select-box [dataSource]="postHouseDataSource"
                                      name="id" id="id" class="tylie-select form-control"
                                      valueExpr="id"
                                      [showClearButton]="true"
                                      displayExpr="name"
                                      formControlName="postHouseId"
                                      placeholder="Select a Post House..."
                                      (onValueChanged)="onPostHouseSelected($event)">
                      </dx-select-box>
                      <p class="p-extra-small">Please Select a Post House</p>
                    </div>
                    <add-newposthouse (onAddNewPostHouseSuccess)="onAddNewPostHouseSuccess($event)"></add-newposthouse>
                  </div>
              </div>

              <div class="row" id="addPostHouse" *ngIf="isPostHouseEdit == true">
                <div class="col-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: (addClientPostHouseForm.controls['postHouseName'].errors && f.submitted)}">
                    <label class="tylie-form-group__lbl" for="product">Post House<span class="required">*</span></label>
                    <input type="text" id="postHouseName" name="postHouseName" class="tylie-text form-control" formControlName="postHouseName" />
                    <p class="p-extra-small">Please Enter a Post House Name</p>
                  </div>
                </div>
              </div>

              <div class="tylie-grid-wrapper" *ngIf="addPostHouseBrandProductsdataSource != null && addPostHouseBrandProductsdataSource?.length > 0">
                <dx-data-grid id="brandsGrid"
                              #brandsGrid class="tylie-grid"
                              [dataSource]="addPostHouseBrandProductsdataSource"
                              [columnAutoWidth]="true"
                              [showColumnLines]="true"
                              [rowAlternationEnabled]="true"
                              [hoverStateEnabled]="true"
                              [showBorders]="true"
                              [showRowLines]="true"
                              (onContentReady)="onContentReady($event)"
                              keyExpr="brandId">
                  <dxo-selection mode="multiple"
                                  selectAllMode="page"
                                  allowSelectAll="Select"
                                  showCheckBoxesMode="always">
                  </dxo-selection>
                  <dxi-column dataField="brandName" width="40%" cssClass="gridStyle" caption="Brand Name" sortOrder="asc"></dxi-column>
                  <dxi-column dataField="shortName" width="30%" cssClass="gridStyle" caption="Short Name"></dxi-column>
                  <dxi-column caption="Product" width="30%" cellTemplate="productTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>

                  <div *dxTemplate="let t of 'productTemplate'">
                    <div *ngFor="let product of t.data.products">
                      <span class="p-small">
                        {{product.name}}
                      </span>
                    </div>
                    <a *ngIf="t.data.products?.length > 0" for="f02" class="tylie-button tylie-button--xs me-3" (click)="addClientBrandProduct(t.data)">
                      ADD/EDIT
                    </a>
                    <a *ngIf="(t.data.hasBrandProducts && (t.data.products == null || t.data.products?.length == 0))" for="f02" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="addClientBrandProduct(t.data)">
                      <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD PRODUCTS
                    </a>
                  </div>

                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true"
                              [allowedPageSizes]="[10, 20, 50, 100]"
                              [showNavigationButtons]="true"
                              [showInfo]="true">
                  </dxo-pager>
                </dx-data-grid>
              </div>

              <div class="d-flex justify-content-end">
                <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onCancelEditPostHouse()">Cancel</button>
              </div>
            </form>
          </div>
        </ng-container>

        <ng-container *ngIf="client.postHouses != null && client.postHouses?.length > 0">
          <dx-data-grid id="postHousesGrid"
                        #postHousesGrid class="tylie-grid"
                        [dataSource]="client.postHouses"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [rowAlternationEnabled]="true"
                        [hoverStateEnabled]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        keyExpr="id">
            <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Post House Name" sortOrder="asc"></dxi-column>
            <dxi-column caption="Brands" width="30%" cellTemplate="brandsTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Products" width="30%" cellTemplate="productsTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>
            <dxi-column width="10%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate" [visible]="client.config.showAddPostHouse == true"></dxi-column>

            <div *dxTemplate="let t of 'actionsTemplate'">
              <div style="text-align:center;">
                <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDeletePostHouse(t.data)">
                  <icon-trash-can color="#1e90ff"></icon-trash-can>
                </a>
                <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onEditPostHouse(t.data)">
                  <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
                </a>
              </div>
            </div>

            <div *dxTemplate="let t of 'brandsTemplate'">
              <div *ngFor="let brand of t.data.postHouseBrandSelectionList">
                <span class="p-small">
                  {{brand.brandName}}
                </span>
              </div>
            </div>

            <div *dxTemplate="let t of 'productsTemplate'">
              <div *ngIf="t.data.postHouseBrandSelectionList != null && t.data?.postHouseBrandSelectionList != undefined">
                <div *ngFor="let brand of t.data.postHouseBrandSelectionList; let i = index">
                  <div *ngIf="brand.products != null && brand.products?.length > 0" class="p-small tyl-12-margin-bottom" [ngClass]="{'tyl-margin-top-large': i!=0}">{{brand.brandName}}</div>
                  <div *ngFor="let product of brand.products">
                    <div class="p-extra-small">
                      {{product.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50, 100]"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </ng-container>
      </div>
  </div>
</ng-container>
