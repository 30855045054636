<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Billing Items</h4>
</div>
<div class="modal-body">
  <h3 class="tylie-type-h3">SalesOrder-LineItems</h3>
  <div id="salesorderCollapseOne" class="tylie-panel mb-4">
    <div class="tylie-panel__body">
      <dx-load-indicator height="60" width="60" *ngIf="!salesOrderLineItems"></dx-load-indicator>

      <dx-data-grid id="salesOrderGrid" *ngIf="salesOrderLineItems"
                    [dataSource]="salesOrderLineItems"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="false"
                    [rowAlternationEnabled]="true"
                    [showColumnLines]="true"
                    [hoverStateEnabled]="true"
                    [showRowLines]="true"
                    [cacheEnabled]="false"
                    class="tylie-grid"
                    keyExpr="id">
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxi-column width="9%" dataField="workOrderNo" caption="WorkOrder" cssClass="gridStyle"></dxi-column>
        <dxi-column width="15%" dataField="itemId" caption="Item" cssClass="gridStyle"></dxi-column>
        <dxi-column width="17%" dataField="itemDescription" caption="Item Description" cssClass="gridStyle"></dxi-column>
        <dxi-column width="8%" dataField="spots" caption="Spots" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="destination" caption="Destinations" cssClass="gridStyle"></dxi-column>
        <dxi-column width="8%" dataField="length" caption="Length" cssClass="gridStyle"></dxi-column>
        <dxi-column width="8%" dataField="qtyOrd" caption="Qty Ordered" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="addlOrDiscount" caption="AE Discount" cssClass="gridStyle"></dxi-column>
        <dxi-column width="15%" dataField="comment" caption="Comment" cssClass="gridStyle"></dxi-column>
        <dxo-search-panel [visible]="false"></dxo-search-panel>

        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[5, 10, 20, 50, 100]"
                   [showNavigationButtons]="true"
                   [showInfo]="true">
        </dxo-pager>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-header-filter [visible]="false"></dxo-header-filter>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
      </dx-data-grid>
    </div>
  </div>


  <h3 class="tylie-type-h3">PurchaseOrder-LineItems</h3>
  <div id="purchaseorderCollapseOne" class="tylie-panel">
    <div class="tylie-panel__body">
      <dx-load-indicator height="60" width="60" *ngIf="!purchaseOrderLineItems"></dx-load-indicator>

      <dx-data-grid id="purchaseOrderGrid" *ngIf="purchaseOrderLineItems"
                    [dataSource]="purchaseOrderLineItems"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="false"
                    [rowAlternationEnabled]="true"
                    [showColumnLines]="true"
                    [hoverStateEnabled]="true"
                    [showRowLines]="true"
                    [cacheEnabled]="false"
                    class="tylie-grid"
                    keyExpr="id">
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxi-column width="10%" dataField="workOrderNo" caption="WorkOrder" cssClass="gridStyle"></dxi-column>
        <dxi-column width="15%" dataField="itemId" caption="Item" cssClass="gridStyle"></dxi-column>
        <dxi-column width="20%" dataField="itemDescription" caption="Item Description" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="vendorId" caption="VendorId" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="spots" caption="Spots" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="destination" caption="Destinations" cssClass="gridStyle"></dxi-column>
        <dxi-column width="10%" dataField="qtyOrd" caption="Qty Ordered" cssClass="gridStyle"></dxi-column>
        <dxi-column width="15%" dataField="comment" caption="Comment" cssClass="gridStyle"></dxi-column>
        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[5, 10, 20, 50, 100]"
                   [showNavigationButtons]="true"
                   [showInfo]="true">
        </dxo-pager>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-header-filter [visible]="false"></dxo-header-filter>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
      </dx-data-grid>
    </div>
  </div>
</div>
