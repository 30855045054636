<div id="player-pop-up">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title" title="{{title}}">{{title | truncateByLetters}}</h4>
  </div>
  <div class="modal-body">
    <!-- <div class="featured-article" [ngClass]="playerWidthClass"> -->
      <div class="tylie-v-player video-overlay" *ngIf="proxyUrl">
        <div class="tylie-v-player__body" (mouseover)="onPlayerMouseOver($event)" style="z-index: 100">
          <div class="safe-overlay-bg-4-3" [style.height]="overyLayHeightQ + 'px'" *ngIf="isOver43Overlay == true"></div>
          <div class="safe-overlay-bg-16-9" [style.height]="overyLayHeightQ + 'px'" *ngIf="isOver169Overlay == true"></div>
          <video #videoPlayerQ (loadedmetadata)="onVideoPlayerLoaded()" controls class="responsive" autoplay [poster]="thumbnail" controlsList="nodownload" oncontextmenu="return false;">
            <source [src]="proxyUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="tylie-v-player__footer">
          <span class="tylie-v-player__overlay-action-4-3 safe-overlay-4-3" (click)="toggleOverlayQ('43')" *ngIf="this.is430verlayRequired == true"></span>
          <span class="tylie-v-player__overlay-action-16-9 safe-overlay-16-9 safe-overlay-2" (click)="toggleOverlayQ('169')" *ngIf="is169OverlayRequired == true"></span>
        </div>
      </div>
      <div class="tylie-v-player video-overlay" *ngIf="!proxyUrl" align="center">
        <img class="tylie-v-player__fallback-img" [src]="thumbnail" />
      </div>
    <!-- </div> -->
  </div>
</div>
