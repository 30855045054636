<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Add Note</h4>
</div>
<div class="modal-body">
  <form [formGroup]="vaultAssetNoteRequest" #f="ngForm" (ngSubmit)="onSubmitVaultAssetNote(vaultAssetNoteRequest)">
      <div class="tylie-form-group form-group" [ngClass]="{error: vaultAssetNoteRequest.controls['note'].errors && f.submitted}">
        <label class="tylie-form-group__lbl" for="note">Note</label>
        <textarea type="text" class="tylie-textarea tylie-textarea--lg form-control" formControlName="note"
               name="note" id="note"></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
  </form>
</div>
