import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'icon-nav-assets',
  templateUrl: './icon-nav-assets.component.html',
  styleUrls: ['./icon-nav-assets.component.css']
})
export class IconNavAssetsComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#a1a1a1';
  constructor() { }

  ngOnInit() {
  }

}
