<div id="add-media">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Add Assets</h4>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="selectionMethod == 'assetsearch'">
      <div class="d-flex justify-content-between">
        <p class="tylie-type-body">Search and select asset(s) from the list.</p>
        <button type="button" class="tylie-button tylie-button--link tylie-button--xs collapsed" data-bs-toggle="collapse" href="#addPlaceholder" role="button" aria-expanded="false" aria-controls="collapseExample">Add Placeholder<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
      </div>
      <div class="tylie-section collapse" id="addPlaceholder" aria-expanded="false">
        <form [formGroup]="addPlaceHolderRequest" #f="ngForm" name="uploadForm" id="uploadForm">
          <div class="row">
            <div class="col-md-4">
              <div class="tylie-form-group form-group" [ngClass]="{error:(addPlaceHolderRequest.controls['adID'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="adid">Ad-ID<span class="required">*</span></label>
                <input type="text" name="adid" id="adid" class="tylie-text form-control" formControlName="adID" />
                <p class="p-extra-small">Please Enter Ad-ID</p>
                <p *ngIf="errorMessage" [ngStyle]="{'color':'red'}">{{errorMessage}}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: addPlaceHolderRequest.controls['title'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="title">Title<span class="required">*</span></label>
                <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title" />
                <p class="p-extra-small">Please select Title</p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="tylie-form-group form-group" [ngClass]="{error: addPlaceHolderRequest.controls['length'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="length">Length<span class="required">*</span></label>
                <input type="text" name="length" id="length" class="tylie-text form-control" formControlName="length" />
                <p class="p-extra-small">Please Enter Length</p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="format">Format<span class="required">*</span></label>
                <dx-select-box name="format"
                               #format
                               id="format"
                               formControlName="format"
                               [dataSource]="formatDataSource"
                               class="tylie-select form-control"
                               valueExpr="id"
                               displayExpr="name"
                               placeholder="format">
                </dx-select-box>
                <p class="p-extra-small">Please select Format</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" (click)="addPlaceHolderFormSubmit(addPlaceHolderRequest,f)" class="tylie-button tylie-button--xs">Add Placeholder</button>
          </div>
        </form>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-md-6">
        <section class="tylie-input-search mt-4" *ngIf="selectionMethod == 'assetsearch'">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" (keyup.enter)="onQuickSearchSpots()"
                 type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchSpots()">Search</button>
          </div>
        </section>

        <!-- <div class="input-group add-on col-md-9 col-sm-9  col-xs-9 search-text" *ngIf="selectionMethod == 'assetsearch'">
          <input class="form-control" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" (keyup.enter)="onQuickSearchSpots()"
                 type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          <div class="input-group-btn">
            <button class="btn btn-default qc-submit" type="button" (click)="onQuickSearchSpots()">Search</button>
          </div>
        </div> -->

        <div class="tylie-form-group form-group" *ngIf="selectionMethod == 'copypaste'">
          <div>
            <label class="tylie-form-group__lbl">Upload from File</label>
          </div>
          <label class="tylie-upload">
            <input type="file" (change)="onExcelUploadFileChange($event)" style="display:none" accept=".xlsx,.xls">
            <div class="tylie-upload__action tylie-upload__action--xs">
              {{isUploading ? 'Importing...' : 'Browse'}}
            </div>
          </label>
        </div>
      </div>

      <div class="col-md-6 d-flex justify-content-end" >
        <button class="tylie-button tylie-button--xs mt-4" *ngIf="selectionMethod == 'assetsearch'" type="button" (click)="onCopyPasteOptionSelected();">Copy Or Upload</button>
        <button class="tylie-button tylie-button--xs" *ngIf="selectionMethod == 'copypaste'" type="button" (click)="onAssetSearchOptionSelected();">Assets List</button>
      </div>
    </div>

    <div class="tylie-form-group form-group" *ngIf="selectionMethod == 'copypaste'">
      <label class="tylie-form-group__lbl">Paste Ad-ID's below (comma separated or stacked as one item per line)</label>
      <textarea name="upload-text" class="tylie-textarea tylie-textarea--h350 form-control" [(ngModel)]="pastedDestinations"></textarea>
    </div>

    <div class="media-grid my-4" *ngIf="selectionMethod == 'assetsearch'">
      <div class="grid-border"></div>
      <div class="grid-content">
        <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="!spotsDataSource"></dx-load-indicator>
        <div class="divTable" *ngIf="spotsDataSource">
          <div class="div-body">
            <div class="divTableHeading">
              <div class="divTableRow">
                <div class="divTableCell" style="cursor: default;">Select</div>
                <div class="divTableCell" style="cursor: default;">Thumbnail</div>
                <div class="divTableCell" (click)="onSortBy($event, 'adId')"
                      [ngClass]="{'down-arrow':(isSortDown('adId') == true), 'up-arrow':(isSortUp('adId') == true)}">
                  Ad-ID<i class="glyphicon glyphicon-chevron-up"></i> <i class="glyphicon glyphicon-chevron-down"></i>
                </div>
                <div class="divTableCell" (click)="onSortBy($event, 'title')"
                      [ngClass]="{'down-arrow':(isSortDown('title') == true), 'up-arrow':(isSortUp('title') == true)}">
                  Title<i class="glyphicon glyphicon-chevron-up"></i> <i class="glyphicon glyphicon-chevron-down"></i>
                </div>
                <div class="divTableCell" style="cursor: default;">Product</div>
                <div class="divTableCell" style="cursor: default;">Format</div>
                <div class="divTableCell" (click)="onSortBy($event, 'status')"
                      [ngClass]="{'down-arrow':(isSortDown('status') == true), 'up-arrow':(isSortUp('status') == true)}">
                  Status<i class="glyphicon glyphicon-chevron-up"></i> <i class="glyphicon glyphicon-chevron-down"></i>
                </div>
                <div class="divTableCell" (click)="onSortBy($event, 'length')"
                      [ngClass]="{'down-arrow':(isSortDown('length') == true), 'up-arrow':(isSortUp('length') == true)}">
                  Dur.<i class="glyphicon glyphicon-chevron-up"></i> <i class="glyphicon glyphicon-chevron-down"></i>
                </div>
                <div class="divTableCell" (click)="onSortBy($event, 'created')"
                      [ngClass]="{'down-arrow':(isSortDown('created') == true), 'up-arrow':(isSortUp('created') == true)}">
                  Created<i class="glyphicon glyphicon-chevron-up"></i> <i class="glyphicon glyphicon-chevron-down"></i>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let sp of this.spotsDataSource; let ind = index">
              <ng-container *ngIf="sp.postProdFiles.length > 0">
                <div class="divTableRow parent" [attr.id]="'parent' + ind.toString()">
                  <div class="divTableCell">
                    <div class="arrow-block">
                      <i class="glyphicon glyphicon-chevron-down slide" [attr.target]="'target' + ind.toString()" (click)="toggleVersionFiles($event, 'target' + ind.toString(), 'parent' + ind.toString())"></i>
                    </div>
                  </div>
                  <div class="divTableCell">
                    <div class="add-media-images">
                      <span class="badge badge-margin-mediacount">{{sp.postProdFiles.length + 1}}</span>
                      <img [src]="sp.thumbnailUrl" />
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="divTableCell" [title]="sp.adId">{{sp.adId | truncateByLetters: 12}}</div>
                  <div class="divTableCell" [title]="sp.title">{{sp.title | truncateByLetters}}</div>
                  <div class="divTableCell"></div>
                  <div class="divTableCell format-cell">
                  </div>
                  <div class="divTableCell"></div>
                  <div class="divTableCell"></div>
                  <div class="divTableCell"></div>
                </div>
                <div class="divTableBody" [attr.id]="'target' + ind.toString()">
                  <div class="divTableRow" [ngClass]="{'info': sp.isSelected}">
                    <div class="divTableCell">
                      <label class="custom-checkbox">
                        <input type="checkbox" (click)="onSelectSpot(sp.spotFileGuid, '')" [checked]="sp.isSelected">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="divTableCell">
                      <div class="add-media-image">
                        <img *ngIf="sp.isPlayable === true" class="quickLink" [src]="sp.thumbnailUrl" (click)="quickPlay(sp.proxyUrl, sp.title, sp.thumbnailUrl, sp.format)">
                        <img *ngIf="sp.isPlayable === false" [src]="sp.thumbnailUrl" />
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div class="divTableCell" [title]="sp.adId">{{sp.adId | truncateByLetters: 12}}</div>
                    <div class="divTableCell" [title]="sp.title">{{sp.title | truncateByLetters}}</div>
                    <div class="divTableCell">{{sp.product}}</div>
                    <div class="divTableCell format-cell">
                      <ng-container *ngFor="let iconSrc of sp.formatClasses">
                        <span [class]="iconSrc"></span>
                      </ng-container>
                    </div>
                    <div class="divTableCell">{{sp.status}}</div>
                    <div class="divTableCell">{{sp.length}}</div>
                    <div class="divTableCell">{{sp.created | usertimezone: 'MM/DD/YYYY'}}</div>
                  </div>
                  <div class="divTableRow" *ngFor="let pp of sp.postProdFiles; let i = indexpp" [ngClass]="{'info': pp.isSelected}">
                    <div class="divTableCell">
                      <label class="custom-checkbox">
                        <input type="checkbox" (click)="onSelectSpot(sp.spotFileGuid, pp.spotFileGuid)" [checked]="pp.isSelected">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="divTableCell">
                      <div class="add-media-image">
                        <img *ngIf="pp.isPlayable === true" class="quickLink" [src]="pp.thumbnailUrl" (click)="quickPlay(pp.proxyUrl, pp.title, pp.thumbnailUrl, pp.format)">
                        <img *ngIf="pp.isPlayable === false" [src]="pp.thumbnailUrl" />
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div class="divTableCell" [title]="pp.adId">{{pp.adId | truncateByLetters: 12}}</div>
                    <div class="divTableCell" [title]="pp.title">{{pp.title | truncateByLetters}}</div>
                    <div class="divTableCell">{{pp.product}}</div>
                    <div class="divTableCell format-cell">
                      <ng-container *ngFor="let iconSrc of pp.formatClasses">
                        <span [class]="iconSrc"></span>
                      </ng-container>
                    </div>
                    <div class="divTableCell">{{pp.status}}</div>
                    <div class="divTableCell">{{pp.length}}</div>
                    <div class="divTableCell">{{pp.created | usertimezone: 'MM/DD/YYYY'}}</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="sp.postProdFiles.length === 0">
                <div class="divTableRow" [ngClass]="{'info': sp.isSelected}">
                  <div class="divTableCell">
                    <label class="custom-checkbox">
                      <input type="checkbox" (click)="onSelectSpot(sp.spotFileGuid, '')" [checked]="sp.isSelected">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="divTableCell">
                    <div class="add-media-image">
                      <img *ngIf="sp.isPlayable === true" class="quickLink" [src]="sp.thumbnailUrl" (click)="quickPlay(sp.proxyUrl, sp.title, sp.thumbnailUrl, sp.format)">
                      <img *ngIf="sp.isPlayable === false" [src]="sp.thumbnailUrl" />
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="divTableCell" [title]="sp.adId">{{sp.adId | truncateByLetters: 12}}</div>
                  <div class="divTableCell" [title]="sp.title">{{sp.title | truncateByLetters}}</div>
                  <div class="divTableCell">{{sp.product}}</div>
                  <div class="divTableCell format-cell">
                    <ng-container *ngFor="let iconSrc of sp.formatClasses">
                      <span [class]="iconSrc"></span>
                    </ng-container>
                  </div>
                  <div class="divTableCell">{{sp.status}}</div>
                  <div class="divTableCell">{{sp.length}}</div>
                  <div class="divTableCell">{{sp.created | usertimezone: 'MM/DD/YYYY'}}</div>
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="spotsDataSource.length === 0" class="divTableBody no-data" id="target">
              <div class="divTableRow">
                <div class="divTableCell"></div>
                <div class="divTableCell"></div>
                <div class="divTableCell"></div>
                <div class="divTableCell"></div>
                <div class="divTableCell" align="center">No Spots found</div>
                <div class="divTableCell format-cell"></div>
                <div class="divTableCell"></div>
                <div class="divTableCell"></div>
                <div class="divTableCell"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3" *ngIf="selectionMethod == 'copypasteresult'">
      <p class="p-small" [ngClass]="copyUploadErrorsExist == true ? 'error-message' : 'sucess-message'">{{copyUploadResultMessage}}</p>
    </div>

    <div class="media-grid my-4" *ngIf="selectionMethod == 'copypasteresult'">
      <div class="grid-border"></div>
      <div class="grid-content" align="center">
        <table class="tylie-table table" border="0">
          <thead class="tylie-table__head">
            <tr>
              <th>Thumbnail</th>
              <th>Ad-ID</th>
              <th>Title</th>
              <th>Product</th>
              <th>Format</th>
              <th>Status</th>
              <th>Dur.</th>
              <th>Created</th>
              <th class="close-border-dest"></th>
            </tr>
          </thead>
          <tbody class="tylie-table__body">
            <tr *ngFor="let sf of copyUploadSuccessSpotFiles; let i = index">
              <td>
                <div class="add-media-image">
                  <img *ngIf="sf.isPlayable === true" class="quickLink" [src]="sf.thumbnailUrl" (click)="quickPlay(sf.proxyUrl, sf.title, sf.thumbnailUrl, sf.format)">
                  <img *ngIf="sf.isPlayable === false" [src]="sf.thumbnailUrl" />
                  <div></div>
                  <div></div>
                </div>
              </td>
              <td style="vertical-align: middle" [title]="sf.adId">{{sf.adId  | truncateByLetters: 12}}</td>
              <td style="vertical-align: middle" [title]="sf.title">{{sf.title | truncateByLetters}}</td>
              <td style="vertical-align: middle">{{sf.product}}</td>
              <td class="format-cell" style="vertical-align: middle">
                <ng-container *ngFor="let iconSrc of sf.formatClasses">
                  <span [class]="iconSrc"></span>
                </ng-container>
              </td>
              <td style="vertical-align: middle">{{sf.status}}</td>
              <td style="vertical-align: middle">{{sf.length}}</td>
              <td style="vertical-align: middle">{{sf.created | usertimezone: 'MM/DD/YYYY'}}</td>
              <td align="right" style="vertical-align: middle">
                <i class="close-btn" (click)="onCopyUploadSuccessSpotFileDelete(sf.spotFileGuid)"></i>
              </td>
            </tr>
            <tr class="danger" *ngFor="let adId of copyUploadErrorSpotFiles; let i = index">
              <td></td>
              <td style="vertical-align: middle">{{adId}}</td>
              <td></td>
              <td></td>
              <td class="format-cell">
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td align="right" style="vertical-align: middle">
                <i class="close-btn" (click)="onCopyUploadErrorSpotFileDelete(adId)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="d-flex justify-content-end" *ngIf="selectionMethod == 'copypasteresult' || selectionMethod == 'assetsearch'">
      <div class="tylie-form-group form-check me-3 mt-1">
        <input class="form-check-input" type="checkbox" name="media-to-all-groups" id="media-to-all-groups" [(ngModel)]="applyToAllGroups">
        <label class="form-check-label" for="media-to-all-groups">Apply Assets to All Groups</label>
      </div>
      <button class="tylie-button tylie-button--xs" type="button" [ngClass]="{disabled: copyUploadErrorsExist == true}" (click)="onAddMedia()">Add Assets</button>
    </div>

    <div class="d-flex justify-content-end" *ngIf="selectionMethod == 'copypaste'">
      <button class="tylie-button tylie-button--xs" type="button"
              [ngClass]="{disabled : (pastedDestinations == null || pastedDestinations.trim() == '')}"
              (click)="onSearchCopySubmitted()">
        Add Assets
      </button>
    </div>
    
  </div>
</div>
