import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { ConfigService } from "../../../../services/core/config.service";
import { AlertService } from "../../../../services/core/alert.service";
import { Subscription } from 'rxjs';
import { UserProfileDetail } from "../../../../models/user/user-profile-detail";
import { Constants } from "../../../../configurations/constants";
import { DestinationDetail } from "../../../../models/admin/destination/destinationdetail.model";

@Component({
  selector: 'destination-detail',
  templateUrl: './destination-detail.component.html',
  styleUrls: ['./destination-detail.component.css']
})
export class DestinationDetailComponent implements OnInit {

  //public destinationGuid: string = null;
  @Input('destinationGuid') destinationGuid: string;
  public editMode: boolean = false;
  private subscription: Subscription;
  public detail: DestinationDetail = null;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private route: ActivatedRoute) {

  }

  ngOnInit() {
    //this.subscription = this.route.params.subscribe((params: Params) => {
    //  this.destinationGuid = params['destinationGuid'];
    //});
    this.getDestinationView(this.destinationGuid);
  }

  public onEditDestinationEventTriggered(e: any) {
    this.editMode = true;
  }

  public onEditCompleteOrCancelEventTriggered(destinationDetail) {
    this.editMode = false;
    this.detail = destinationDetail;
  }

  private getDestinationView(destinationGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getDestinationView(destinationGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.detail = res.result as DestinationDetail;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }
}
