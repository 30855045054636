<div id="add-media">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">COPY OR UPLOAD ASSETS</h4>
  </div>

  <div class="modal-body">
    <div class="tylie-form-group form-group" *ngIf="selectionMethod == null">
      <label class="tylie-form-group__lbl">Upload from File</label>
      <div>
        <label class="tylie-upload">
          <input type="file" style="display:none" (change)="onExcelUploadFileChange($event)" accept=".xlsx,.xls">
          <div class="tylie-upload__action tylie-upload__action--xs">
            {{isUploading ? 'Importing...' : 'Browse'}}
            <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
          </div>
        </label>
      </div>
      
    </div>

    <div class="tylie-form-group form-group" *ngIf="selectionMethod == null">
      <label class="tylie-form-group__lbl">Paste Ad-ID's below (comma separated or stacked as one item per line)</label>
      <textarea name="upload-text" class="tylie-textarea tylie-textarea--h350" [(ngModel)]="pastedAdIds"></textarea>
    </div>

    <div class="mb-3" *ngIf="selectionMethod == 'copypasteresult'">
      <p class="tylie-type-body tylie-type-body--sm" [ngClass]="copyUploadErrorsExist == true ? 'error-message' : 'sucess-message'">{{copyUploadResultMessage}}</p>
    </div>

    <div class="col-md-12 copypaste-all-media" *ngIf="selectionMethod == 'copypasteresult'">
      <div class="media-grid">
        <div class="grid-border"></div>
        <div class="grid-content" align="center">
          <table class="tylie-table table  add-dest-table" border="0">
            <thead class="tylie-table__head">
              <tr>
                <th>Thumbnail</th>
                <th>Ad-ID</th>
                <th>Title</th>
                <th>Product</th>
                <th>Format</th>
                <th>Status</th>
                <th>Dur.</th>
                <th>Created</th>
                <th class="close-border-dest"></th>
              </tr>
            </thead>
            <tbody class="tylie-table__body">
              <tr *ngFor="let sf of copyUploadSuccessSpotFiles; let i = index">
                <td>
                  <div class="add-media-image">
                    <img *ngIf="sf.isPlayable === true" class="quickLink" [src]="sf.thumbnailUrl" (click)="quickPlay(sf.proxyUrl, sf.title, sf.thumbnailUrl, sf.format)">
                    <img *ngIf="sf.isPlayable === false" [src]="sf.thumbnailUrl" />
                    <div></div>
                    <div></div>
                  </div>
                </td>
                <td style="vertical-align: middle" [title]="sf.adId">{{sf.adId  | truncateByLetters: 12}}</td>
                <td style="vertical-align: middle" [title]="sf.title">{{sf.title | truncateByLetters}}</td>
                <td style="vertical-align: middle">{{sf.product}}</td>
                <td class="format-cell" style="vertical-align: middle">
                  <ng-container *ngFor="let iconSrc of sf.formatClasses">
                    <span [class]="iconSrc"></span>
                  </ng-container>
                </td>
                <td style="vertical-align: middle">{{sf.status}}</td>
                <td style="vertical-align: middle">{{sf.length}}</td>
                <td style="vertical-align: middle">{{sf.created | usertimezone: 'MM/DD/YYYY'}}</td>
                <td align="right" style="vertical-align: middle">
                  <i class="close-btn" (click)="onCopyUploadSuccessSpotFileDelete(sf.spotFileGuid)"></i>
                </td>
              </tr>
              <tr class="danger" *ngFor="let adId of copyUploadErrorSpotFiles; let i = index">
                <td></td>
                <td style="vertical-align: middle">{{adId}}</td>
                <td></td>
                <td></td>
                <td class="format-cell">
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right" style="vertical-align: middle">
                  <i class="close-btn" (click)="onCopyUploadErrorSpotFileDelete(adId)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end" *ngIf="selectionMethod == 'copypasteresult'">
      <button class="tylie-button tylie-button--xs" type="button" [ngClass]="{disabled: copyUploadErrorsExist == true}" (click)="onAddMedia()">Add Assets</button>
    </div>

    <div class="d-flex justify-content-end" *ngIf="selectionMethod == null">
      <button class="tylie-button tylie-button--xs" type="button"
              [ngClass]="{disabled : (pastedAdIds == null || pastedAdIds.trim() == '')}"
              (click)="onSearchCopySubmitted()">
        Add Assets
      </button>
    </div>
  </div>
</div>
