import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, NavigationExtras, CanLoad, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { DBkeys } from '../../configurations/db-Keys';
import { UserProfileService } from "../user/user-profile.service";
import { UserProfile } from "../../models/user/user.profile.model";
import { Constants } from "../../configurations/constants";
import { LocalStoreManager } from "../core/local-store-manager.service";
import { RouteConstants } from "../../configurations/route-constants";
import { JwtHelper } from '../../configurations/jwt-helper';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router,
    private userProfileService: UserProfileService,
    private localStorage: LocalStoreManager
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url;
    return this.redirectIfUnauthenticated(url);
  }


  redirectIfUnauthenticated(url: string) {
    return this.authService.isLoggedIn();
  }
}
