import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';

import { OrderGroupService, DeleteOrderMediaEvent, Vendor, UpdateVendorDateEvent } from "../../../../models/order/vendor-purchaseorder-request.model";
import { FormGroup, FormGroupName, NgForm } from "@angular/forms";

@Component({
  selector: 'orderlvlservicevendorporequest',
  templateUrl: './orderlvlservicevendorporequest.component.html',
  styleUrls: ['./orderlvlservicevendorporequest.component.css'],
})
export class OrderLvlServiceVendorPORequestComponent implements OnInit {
  @Input() orderService: FormGroup;
  @Input() groupName: number;    // form group name not related to order group
  @Input() formGroupCount: number;
  @Input() parentForm: NgForm;
  @Input() nonDigitalVendors: Vendor[];
  @Input() orderGrpFormGroupCount: number;

  @Output() onDeleteOrderLvlService: EventEmitter<number> = new EventEmitter<number>();
  @Output() onVendorChangeDueDateUpdateTriggered: EventEmitter<UpdateVendorDateEvent> = new EventEmitter<UpdateVendorDateEvent>();
  @Output() onDueDateUpdateTriggered: EventEmitter<UpdateVendorDateEvent> = new EventEmitter<UpdateVendorDateEvent>();

  public now: Date = new Date();

  public attributeId: number = -1;

  constructor() {

  }

  ngOnInit() {
    this.attributeId = (-1)*(this.groupName+1);
  }

  public onClickDeleteOrderLvlService() {
    this.onDeleteOrderLvlService.emit(this.groupName);
  }

  public onVendorChange(e: any) {
    if (e.value != "" && e.value != null && e.value != undefined && e.previousValue != e.value) {
      var updatedueDateEvent = new UpdateVendorDateEvent();
      updatedueDateEvent.vendor = e.value;
      this.onVendorChangeDueDateUpdateTriggered.emit(updatedueDateEvent);
    }
  }

  public onDueDateChange(e: any, vendor: any) {
    if (vendor != "" && vendor != null && vendor != undefined && e.previousValue != e.value) {
      var updatedueDateEvent = new UpdateVendorDateEvent();
      updatedueDateEvent.dueDate = e.value;
      updatedueDateEvent.vendor = vendor;
      this.onDueDateUpdateTriggered.emit(updatedueDateEvent);
    }
  }
}
