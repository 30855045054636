import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class GlobalService {
  constructor() { }

  @Output() openEvent: EventEmitter<any> = new EventEmitter();
  @Output() spotEditEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() orderEditEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() draftOrderEditEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() uploadedOrderEditEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() copyOrderEditEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() vaultRequestCompletedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() vaultAssetEditedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() vaultDigitalAssetEditedEvent: EventEmitter<boolean> = new EventEmitter();

  @Output() closePopoverEvent: EventEmitter<boolean> = new EventEmitter();

  public spotEdited() {
    this.spotEditEvent.emit(true);
  }

  public orderEdited() {
    this.orderEditEvent.emit(true);
  }

  public draftOrderEdited() {
    this.draftOrderEditEvent.emit(true);
  }

  public uploadedOrderEdited() {
    this.uploadedOrderEditEvent.emit(true);
  }

  public copyOrderEdited() {
    this.copyOrderEditEvent.emit(true);
  }

  public vaultRequestCompleted() {
    this.vaultRequestCompletedEvent.emit(true);
  }

  public vaultAssetEdited() {
    this.vaultAssetEditedEvent.emit(true);
  }

  public vaultDigitalAssetEdited() {
    this.vaultDigitalAssetEditedEvent.emit(true);
  }

  closePopover() {
    this.closePopoverEvent.emit(true);
  }

  openSpotFile(guid: string) {
    this.orderEditEvent.emit(false);
    this.spotEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: guid, orderGuid: '', orderDraftGuid: '', uploadedOrderGuid: '', copyOrderGuid: '' });
  }

  openVaultAsset(guid: string) {
    this.openEvent.emit({ vaultAssetGuid: guid });
  }

  openUserDetails(guid: string) {
    this.openEvent.emit({ userProfileGuid: guid });
  }

  openClientDetails(guid: string) {
    this.openEvent.emit({ clientGuid: guid });
  }

  openBrandDetails(guid: string) {
    this.openEvent.emit({ brandGuid: guid });
  }

  openDestinationDetails(guid: string) {
    this.openEvent.emit({ destinationGuid: guid });
  }

  openDigitalVaultAsset(itemId: number) {
    console.log(itemId);
    this.openEvent.emit({ vaultDigitalAssetLineItemId: itemId });
  }

  openDraftOrder(orderDraftGuid: string) {
    this.orderEditEvent.emit(false);
    this.spotEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: '', orderGuid: '', orderDraftGuid: orderDraftGuid, uploadedOrderGuid: '', copyOrderGuid: '' });
  }

  openCopyOrder(copyOrderGuid: string, copyOptions: any) {
    this.orderEditEvent.emit(false);
    this.spotEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: '', orderGuid: '', orderDraftGuid: '', uploadedOrderGuid: '', copyOrderGuid: copyOrderGuid, copyOptions: copyOptions });
  }

  openUploadedOrder(uploadedOrderGuid: string) {
    this.orderEditEvent.emit(false);
    this.spotEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: '', orderGuid: '', orderDraftGuid: '', uploadedOrderGuid: uploadedOrderGuid, copyOrderGuid: '' });
  }

  openOrderFile(guid: string) {
    this.spotEditEvent.emit(false);
    this.orderEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: '', orderGuid: guid, orderDraftGuid: '', uploadedOrderGuid: '', copyOrderGuid: '' });
  }

  openVaultRequestFile(guid: string, statusLabel: string) {
    this.spotEditEvent.emit(false);
    this.orderEditEvent.emit(false);
    this.draftOrderEditEvent.emit(false);
    this.uploadedOrderEditEvent.emit(false);
    this.copyOrderEditEvent.emit(false);

    this.openEvent.emit({ spotFileGuid: '', orderGuid: '', orderDraftGuid: '', uploadedOrderGuid: '', copyOrderGuid: '', vaultRequestGuid: guid, statusLabel: statusLabel });
  }
}
