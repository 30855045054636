import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-delete',
  templateUrl: './icon-delete.component.html',
  styleUrls: ['./icon-delete.component.css']
})
export class IconDeleteComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
