import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "icon-checkmark-white",
  templateUrl: "./icon-checkmark-white.component.html",
  styleUrls: ["./icon-checkmark-white.component.css"],
})
export class IconCheckmarkWhiteComponent implements OnInit {
  @Input() height: string = "18px";
  @Input() width: string = "25px";
  @Input() color: string = "ccc";

  constructor() {}

  ngOnInit(): void {}
}
