import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AlertService } from "../../../../services/core/alert.service";
import { Utilities } from "../../../../services/core/utilities";
import { ConfigService } from "../../../../services/core/config.service";
import { OrderService } from "../../../../services/order/order.service";
import { OrderDigitalDeliveryDestinationDetailModel } from "../../../../models/order/order-destinations.model";
import * as moment from 'moment-timezone';
import { DxDateBoxComponent } from 'devextreme-angular';


@Component({
  selector: 'updatepod',
  templateUrl: './updatepod.component.html',
  styleUrls: ['./updatepod.component.css'],
})
export class UpdatePODComponent implements OnInit {

  @Output('saveDeliveryPOD') saveDeliveryPOD: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output('closePopUp') closePopUp: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(DxDateBoxComponent, { static: true }) dateBox: DxDateBoxComponent;

  public updateOrderPODRequest: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private orderService: OrderService,
    private configService: ConfigService) {
  }

  ngOnInit() {
    this.updateOrderPODRequest = new FormGroup({
      deliveryPODDate: new FormControl(null, Validators.compose([Validators.required]))
    });
  }

  public onFormSubmit(updateOrderPODRequest: any) {
    console.log(updateOrderPODRequest);
    if (!this.dateBox.isValid || !this.updateOrderPODRequest.valid) {
      return;
    }
    this.saveDeliveryPOD.emit(updateOrderPODRequest);
    console.log(updateOrderPODRequest);
  }

  public closePopup() {
    this.closePopUp.emit();
  }
}
