<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>
  <div class="row"> 
    <div class="col-md-6">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['timecode'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="timecodes">Timecode(s)<span class="required">*</span></label>
        <div class="d-flex" *ngFor="let t of timecodes; index as i;">
          <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="t.value" (ngModelChange)="onEnterTimecode()" class="tylie-text form-control" [readonly]="isTimecodeDisabled">
          <a class="tylie-button tylie-button--xs tylie-button--link mb-2 ms-3" *ngIf="t.id > 1 && isTimecodeDisabled === false" (click)="onAddTimecode(t.id)"><icon-expire color="#1e90ff"></icon-expire></a>
          <a class="tylie-button tylie-button--xs tylie-button--link mb-2 ms-3" *ngIf="t.id == 1 && isTimecodeDisabled === false" (click)="onAddTimecode(t.id)"><icon-plus-circle color="#1e90ff"></icon-plus-circle></a>
        </div>
        <p class="p-extra-small">Please enter one or more Timecodes</p>
      </div>
    </div>
  </div>
  <div class="row"> 
    <div class="col-md-6">
      <div class="tylie-form-group form-group">
        <label class="tylie-upload" *ngIf="isUploadFileDisabled === false">
          <input type="file" style="display:none" (change)="onAttachFile($event)">
          <div class="tylie-upload__action tylie-upload__action--xs">
          {{isUploading ? 'UPLOADING...' : 'UPLOAD SAMPLE THUMBNAIL'}}
          <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
          </div>
        </label>
        <p *ngFor="let fl of formGroup.controls['thumbnailFile'].value;">
          <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
          <a *ngIf="isUploadFileDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link"><icon-trash-can color="#1e90ff"></icon-trash-can></a></p>
      </div>
    </div>
  </div>
</div>
