import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { UserProfileService } from '../services/user/user-profile.service';

@Pipe({
  name: 'usertimezone'
})

export class UserTimeZone implements PipeTransform {

  constructor(private userProfileService: UserProfileService) { }

  transform(value: Date, format: string): string
  {
    var userTimeZoneCode = this.userProfileService.getUserProfile().ianaCode;
    if (!value)
      return "";

    var date = moment.utc(value);
    if (userTimeZoneCode == null || userTimeZoneCode == undefined) {
      return value.toString();
    }
    else {
      return date.tz(userTimeZoneCode).format(format);
    }
  }
}
