<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}">
    <label class="tylie-upload" *ngIf="isDisabled === false">
      <input type="file" multiple style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-upload__action--xs">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD SCRIPT'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>

    <p *ngFor="let fl of formGroup.controls['scriptFile'].value;"><a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
    <a (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link" *ngIf="isDisabled === false"><icon-trash-can color="#1e90ff"></icon-trash-can></a></p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}">
    <label class="tylie-form-group__lbl" for="script-text">Paste Script Text<span class="required">*</span></label>
    <textarea name="script-text" id="script-text" class="tylie-textarea tylie-textarea--md form-control" formControlName="scriptText"></textarea>
    <p class="p-extra-small">Please upload Script file or paste Script text</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['voice'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl">Voice<span class="required">*</span></label>

    <div class="d-flex">
      <div class="form-check me-3"><input class="form-check-input" type="radio" name="voice" formControlName="voice" value="Male"><span class="form-check-label">Male</span></div>
      <div class="form-check"><input class="form-check-input" type="radio" name="voice" formControlName="voice" value="Female"><span class="form-check-label">Female</span></div>
    </div>
    <!--<div class="form-check">
      <input class="form-check-input" type="radio" formControlName="voice" value="Male"><label class="form-check-input">Male</label>
      <input class="form-check-input" type="radio" formControlName="voice" value="Female"><label class="form-check-input">Female</label>
    </div>-->

    <p class="p-extra-small">Please choose an option</p>
  </div>
</div>
