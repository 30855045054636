<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">ATTACHMENT</h4>
</div>
<div class="modal-body">
  <form [formGroup]="attchmentCategoryRequest" #f="ngForm">
    <p class="tylie-type-body mb-2" *ngFor="let fl of attachedFiles;">{{fl.fileName}}</p>
    <div class="d-flex">
      <label class="tylie-upload">
        <input id="f02" #selectedFile type="file" multiple style="display:none" (change)="onAttachFile($event)" [accept]="acceptFileTypes">
        <div class="tylie-upload__action tylie-upload__action--xs">
          {{isUploading ? 'UPLOADING...' : 'UPLOAD'}}
          <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
        </div>
      </label>
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="closePopup()">Close</button>
    </div>
  </form>
</div>
