import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService } from "../../../../services/core/alert.service";
import { ConfigService } from "../../../../services/core/config.service";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from "../../../../models/config/gridparams.model";
import { DxDataGridComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../configurations/route-constants";
import { UserProfileSearch } from "../../../../models/user/user.profile.model";
import { GlobalService } from '../../../../services/core/global.service';

@Component({
  selector: 'search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.css']
})
export class SearchUsersComponent implements OnInit {

  // get all isactive == true client, clientbrands, clienteditorialhouse, brands, post houses
  @ViewChild('userSearch', { static: true }) userSearchGrid: DxDataGridComponent;

  public searchUserFormGroup: FormGroup;
  public quickSearchValue: string = '';
  public clientDataSource: any[] = [];
  public postHouseDataSource: any[] = [];
  public searchConfig: any = {};
  public searchUserCriteriaObj = <UserProfileSearch>{};
  public resultUserProfileStore: any = {};

  public now: Date = new Date();

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService,
    private gs: GlobalService
    , private fb: FormBuilder) {

  }

  ngOnInit() {
    //search formgroup
    this.searchUserFormGroup = this.fb.group({
      client: [null],
      posthouse: [null],
      includeInactiveUsers: [null]
    });

    this.getSearchConfig();
    this.getUserProfiles();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public getUserProfiles() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchUserCriteriaObj;

    this.resultUserProfileStore.store = new CustomStore({
      key: "userProfileId",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return adminService.searchAllUsers(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var userSearchResponse = response.result;
              var obj: any = {
                data: userSearchResponse.userProfiles,
                totalCount: userSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public clearSearch() {
    //Clears all searches including quick and advanced
    this.searchUserFormGroup.reset();
    this.quickSearchValue = null;
    this.setDefaultFormValues();
    this.userSearchGrid.instance.refresh();
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchUserCriteriaObj.quickSearch = this.quickSearchValue;
    this.userSearchGrid.instance.refresh();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchUserFormGroup.reset();
      this.setDefaultFormValues();
      this.searchUserCriteriaObj.quickSearch = this.quickSearchValue;
      this.userSearchGrid.instance.refresh();
    }
  }

  public onClientValueChanged(e: any) {
    if (this.util.notEmpty(e)) {
      this.getClientPostHouses(e.value);
    }
    else {
      this.postHouseDataSource = [];
    }
  }

  public onFormSubmit({ value, valid }: { value: UserProfileSearch, valid: boolean }) {
    console.log(value);
    if (value.brand === null && value.client === null && value.posthouse === null
      && value.includeInactiveUsers === null) {
      return;
    }

    this.searchUserCriteriaObj.brand = value.brand;
    this.searchUserCriteriaObj.client = value.client;
    this.searchUserCriteriaObj.posthouse = value.posthouse;
    this.searchUserCriteriaObj.includeInactiveUsers = value.includeInactiveUsers;
    this.searchUserCriteriaObj.quickSearch = null;

    console.log(this.searchUserCriteriaObj);
    this.userSearchGrid.instance.refresh();
  }

  private getClientPostHouses(id: number) {
    if (id == null || id == undefined || id == 0) {
      this.postHouseDataSource = this.searchConfig.postHouses;
      return;
    }

    if (this.searchUserFormGroup.value.posthouse != null) {
      this.searchUserFormGroup.controls['posthouse'].setValue(null);
    }

    this.postHouseDataSource = [];
    this.configService.getClientPostHouses(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var postHouses = res.result;
        this.postHouseDataSource = postHouses;
      }
      else {
        this.postHouseDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.postHouseDataSource = [];
      });
  }

  private getSearchConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getUserAdvancedSearchConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.searchConfig = res.result;

        this.clientDataSource = this.searchConfig.clients;
        this.postHouseDataSource = this.searchConfig.postHouses;

        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private setDefaultFormValues() {
    this.searchUserCriteriaObj.brand = null;
    this.searchUserCriteriaObj.client = null;
    this.searchUserCriteriaObj.posthouse = null;
    this.searchUserCriteriaObj.includeInactiveUsers = null;
    this.searchUserCriteriaObj.quickSearch = null;
  }

  public showUserProfileDetail(userProfileGuid: string) {
    console.log(userProfileGuid);
    if (userProfileGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openUserDetails(userProfileGuid);
    //this.router.navigate([RouteConstants.userDetailRoute, userProfileGuid]);
  }

  ngOnDestroy() {

  }
}
