<dx-data-grid class="tylie-grid" id="orderDetailContentDownload"
                    #orderDetailFtp
                    [dataSource]="orderDetailContentDownloadStore"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [hoverStateEnabled]="true"
                    [rowAlternationEnabled]="true"
                    [showBorders]="true"
                    (onRowPrepared)="onRowPrepared($event)"
                    keyExpr="assetFileGuid">

        <dxi-column dataField="adId" width="17%" cssClass="gridStyle" caption="Ad ID" sortOrder="asc"></dxi-column>
        <dxi-column dataField="olvProfile" width="20%" cssClass="gridStyle" caption="OLV Profile"></dxi-column>
        <dxi-column dataField="fileName" width="33%" cssClass="gridStyle" caption="File Name"></dxi-column>
        <dxi-column dataField="fileSize" width="10%" cssClass="gridStyle" caption="File Size"></dxi-column>
        <dxi-column dataField="duration" width="10%" cssClass="gridStyle" caption="Duration"></dxi-column>
        <dxi-column dataField="downloadLink" width="10%" cellTemplate="downloadLinkTemplate" cssClass="gridStyle" caption="Download" ></dxi-column>

        <div *dxTemplate="let t of 'downloadLinkTemplate'">
          <span *ngIf="t.data.canDownload == true" class="blue-text p-extra-small" (click)='onDownload(t.data.assetFileGuid, t.data.fileName, t.data.fileExtension, t.data.filePath)'>Download</span>
        </div>

        <dxo-sorting mode="multiple"></dxo-sorting>
      </dx-data-grid>