
import { finalize } from 'rxjs/operators';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { OrderService } from '../../../../services/order/order.service';
import { Utilities } from '../../../../services/core/utilities';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import { OrderFile } from '../../../../models/order/order-create.model';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { Validators } from '@angular/forms';
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';
import { fileExtExistsInFilePath } from '../../../../configurations/custom-validations';

@Component({
  selector: 'service-webcaptioning',
  templateUrl: './servicewebcaptioning.component.html'
})
export class ServiceWebCaptioningQComponent extends BaseServiceDataCollectionComponent {

  public options: Array<any> = [];

  public selectedFileTypes: Array<string>;

  @ViewChild('tree', { static: false }) tree: DxTreeViewComponent;

  public isUploading: boolean = false;

  public showOtherFileType: boolean = false;

  public isDisabled: boolean = false;

  constructor(private orderService: OrderService,
    public util: Utilities,
    private uploadOrderFileService: UploadOrderFileService,
    private cdr: ChangeDetectorRef) {
    super();
    this.selectedFileTypes = [];
  }

  ngOnInit() {

    super.ngOnInit();

    this.isDisabled = this.formGroup.controls["scriptFile"].disabled;


    this.options.push({ name: '.txt', value: '.txt' });
    this.options.push({ name: '.srt', value: '.srt' });
    this.options.push({ name: '.srt SubRip', value: '.srt SubRip' });
    this.options.push({ name: '.WebVtt', value: '.vtt' });
    this.options.push({ name: '.scc', value: '.scc' });
    this.options.push({ name: '.cap', value: '.cap' });
    this.options.push({ name: 'other', value: 'other' });

    this.formGroup.setValidators([
      this.uploadOrPasteScriptRequired(this.formGroup.get('scriptText'), this.formGroup.get('scriptFile'),
      )]);

    this.formGroup.updateValueAndValidity();

    console.log(this.formGroup.controls);

    if (this.formGroup.controls["scriptFile"].value == null)
      this.formGroup.controls["scriptFile"].setValue([]);

    if (this.formGroup.controls["scriptText"].value == null)
      this.formGroup.controls["scriptText"].setValue('');

    if (this.formGroup.controls["approvalRequired"].value == null) {
      this.formGroup.controls["approvalRequired"].setValue(false);
    }

    if (this.formGroup.controls["approvalRequired"].value == 'false' || this.formGroup.controls["approvalRequired"].value == false) {
      this.formGroup.controls["approvalRequired"].setValue(false);
    }
    else if (this.formGroup.controls["approvalRequired"].value == 'true' || this.formGroup.controls["approvalRequired"].value == true) {
      this.formGroup.controls["approvalRequired"].setValue(true);
    }

    if (this.formGroup.controls["fileType"].value == null) {
      this.formGroup.controls["fileType"].setValue([])
      this.selectedFileTypes = [];
    }
    else {
      this.selectedFileTypes = this.formGroup.controls["fileType"].value;
    }

    this.checkOtherFileType(this.formGroup.controls["fileType"].value);

    this.formGroup.get('fileType').valueChanges.subscribe(
      (fileTypes: Array<string>) => {
        this.checkOtherFileType(fileTypes);
      });
  }

  private checkOtherFileType(fileTypes: Array<string>) {

    if (fileTypes.find(t => t === 'other') != null) {
      this.showOtherFileType = true;
      this.formGroup.get('fileTypeCustom').setValidators([Validators.required, fileExtExistsInFilePath]);
      this.formGroup.get('fileTypeCustom').updateValueAndValidity();
    }
    else {
      this.showOtherFileType = false;
      this.formGroup.get('fileTypeCustom').clearValidators();
      this.formGroup.get('fileTypeCustom').updateValueAndValidity();
      //this.formGroup.controls["fileTypeCustom"].setValue('');
    }
  }

  public onTreeChanged(e) {
    this.selectedFileTypes = e.component.getSelectedNodesKeys();
    this.cdr.detectChanges();
  }

  public onChanged(e = null) {

    var component = (e && e.component) || (this.tree && this.tree.instance);

    if (!component) return;

    if (!this.selectedFileTypes.length) {
      component.unselectAll();
    }

    if (this.selectedFileTypes) {
      this.selectedFileTypes.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
    this.cdr.detectChanges();
  }

  public onAttachFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {

      for (const uploadFile of event.target.files) {
        this.isUploading = false;
        var request: UploadOrderFileRequest = {
          fileType: uploadFile.type,
          fileName: uploadFile.name,
          description: "",
          fileCategory: "serviceMetadata",
          orderGuid: this.orderGuid,
          orderDestinationId: 0
        };

        this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
          finalize(() => {
            this.isUploading = false;
          }))
          .subscribe((newFile: OrderFile) => {
            if (newFile != null && newFile != undefined) {
              this.addFilesToList(newFile);
            }
          });
      }

    }
    else
      this.isUploading = false;
  }

  private addFilesToList(newFile: OrderFile) {
    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles.push(newFile);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
  }

  public onRemoveFile(orderFileGuid: string) {

    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);

    //this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    //    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    //    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }

    //  this.isUploading = false;
    //},
    //  error => {
    //    this.isUploading = false;
    //    this.util.handleError(error);
    //  });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {
    console.log(orderFileGuid);
    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }
}
