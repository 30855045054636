import { Component, ViewChild, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchOrderRequestViewModel } from '../../../../models/order/order-search-request.model';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'

import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";

import { UserRoles } from "../../../../configurations/user-roles";
import { SpotService } from '../../../../services/media/spot.service';

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import * as moment from 'moment';
import { DxDataGridComponent, DxTreeViewComponent, DxPopoverComponent } from "devextreme-angular";
import { ConfigService } from "../../../../services/core/config.service";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { GridParams } from "../../../../models/config/gridparams.model";
import { RouteConstants } from "../../../../configurations/route-constants";
import { AdminService } from '../../../../services/admin/admin.service';
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CopyOrderOptionsPopupComponent } from '../../../popups/copyorderoptionspopup/copyorderoptionspopup.component';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.css']
})

export class OrderSearchComponent implements AfterViewInit, OnInit {

  public resultOrderStore: any = {};
  public resultOrderSpotViewStore: any = {};
  public brandValue: number;
  public quickSearchValue: string = '';
  public statusDataSource: any = [];
  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public formatDataSource: any = [];
  public orderSearchRequest: FormGroup;
  public showClientClear: boolean = true;
  public clientsDataSource: any;
  public destinationDataSource: any = {};
  public userProfileDataSource: any = {};
  public productDataSource: any = [];
  public searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};
  public now: Date = new Date();
  public showTabs: boolean = false;
  public isAllowedToFlagOrder: boolean = false;
  public isAllowedToCreateOrder: boolean = false;
  public activeGrid: string = Constants.allOrdersGridMain;
  public showFlagOrder: boolean = true;

  public ordersGrid = Constants.allOrdersGridMain;
  public deliveryGrid = Constants.allOrdersGridDelivery;

  public modalRef: BsModalRef;


  @Input('orderConfig') orderConfig: any;
  @ViewChild('treeBrand', { static: false }) treeBrand: DxTreeViewComponent;
  @ViewChild('orderSearch', { static: true }) orderViewGrid: DxDataGridComponent;
  @ViewChild('spotView', { static: false }) spotViewGrid: DxDataGridComponent;
  @ViewChild(DxPopoverComponent, { static: false }) orderStatusAwaitingMediaPopOver: DxPopoverComponent;

  constructor(public alertService: AlertService,
    private router: Router,
    public userRoles: UserRoles,
    private orderService: OrderService,
    public util: Utilities,
    public userProfileService: UserProfileService,
    public spotService: SpotService,
    private modalService: BsModalService,
    public adminService: AdminService,
    public configService: ConfigService,
    private sub: SubService,
    private gs: GlobalService) {

  }

  ngAfterViewInit(): void {
    this.orderViewGrid.instance.columnOption("Air Date Start", "visible", false);
    this.orderViewGrid.instance.columnOption("Air Date End", "visible", false);
  }

  ngOnInit() {
    this.orderSearchRequest = new FormGroup({
      jobNumber: new FormControl(),
      clientPurchaseOrder: new FormControl(),
      orderDateFrom: new FormControl(),
      orderDateTo: new FormControl(),
      vendorPurchaseOrder: new FormControl(),
      workOrderNumber: new FormControl(),
      adId: new FormControl(),
      spotTitle: new FormControl(),
      client: new FormControl(),
      brand: new FormControl(),
      destination: new FormControl(),
      orderBuiltBy: new FormControl(),
      status: new FormControl(),
      product: new FormControl(),
      awaitingMediaOrdersOnly: new FormControl(),
      ordersMarkedForFinalReview: new FormControl(),
      flaggedOnly: new FormControl(),
      orderBilledDateFrom: new FormControl(),
      orderBilledDateTo: new FormControl(),
      estimate: new FormControl(),
      referenceKeywords: new FormControl(),
      trafficFileName: new FormControl(),
      costCenter: new FormControl()
    });

    this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};

    this.orderSearchRequest.controls['awaitingMediaOrdersOnly'].setValue(false);
    this.orderSearchRequest.controls['ordersMarkedForFinalReview'].setValue(false);
    this.orderSearchRequest.controls['flaggedOnly'].setValue(false);

    this.clientDataSource = this.orderConfig.clients;
    this.brandDataSource = this.orderConfig.brands;
    this.statusDataSource = this.orderConfig.statuses;
    this.productDataSource = this.orderConfig.products;
    this.showTabs = this.orderConfig.showTabs;
    this.isAllowedToFlagOrder = this.orderConfig.isAllowedToFlagOrder;
    this.isAllowedToCreateOrder = this.orderConfig.isAllowedToCreateOrder;

    this.searchOrders();
    this.searchDestinations();
    this.getUserProfiles();

    if (this.showTabs) {
      this.searchDeliveryHistory();
    }

    if (this.clientDataSource != null && this.clientDataSource.length == 1) {
      this.orderSearchRequest.controls['client'].setValue(this.clientDataSource[0].id);
      this.showClientClear = false;
    }

    this.sub.orderEditedMethodCalled.subscribe(() => {
      this.orderViewGrid.instance.refresh();
      this.orderViewGrid.instance.deselectAll();

      //if (this.showTabs)
      //  this.spotViewGrid.instance.refresh();
    });
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public getSearchConfig() {
    this.orderService.getOrderHomeInitLoad().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.orderConfig = res.result.orderConfig;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.orderConfig = [];
        this.util.handleError(error);
      });
  }

  public searchOrders() {
    var orderService = this.orderService;
    var util = this.util;
    var criteriaObj = this.searchOrderCriteriaObj;
    this.resultOrderStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        console.log("Request:", request);

        return orderService.searchOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var orderSearchResponse = response.result;
              var obj: any = {
                data: orderSearchResponse.searchResults,
                totalCount: orderSearchResponse.totalCount
              };
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public searchDestinations() {
    var adminService = this.adminService;
    var util = this.util;

    this.destinationDataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: 0, take: 0, sort: sortOptions, isExport: false }
        let request: any = {
          criteria: { quickSearch: loadOptions.searchValue }, GridParams: gridParams
        };

        return adminService.searchAllDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var destinationSearchResponse = response.result;
              var obj: any = {
                data: destinationSearchResponse.destinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public getUserProfiles() {
    var adminService = this.adminService;
    var util = this.util;

    this.userProfileDataSource.store = new CustomStore({
      key: "userProfileId",
      load: function (loadOptions: any) {
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: 0, take: 0, sort: sortOptions, isExport: false }
        let request: any = {
          criteria: { quickSearch: loadOptions.searchValue }, GridParams: gridParams
        };

        return adminService.searchAllUsers(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var userSearchResponse = response.result;
              var obj: any = {
                data: userSearchResponse.userProfiles,
                totalCount: userSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public searchDeliveryHistory() {
    var orderService = this.orderService;
    var util = this.util;
    var criteriaObj = this.searchOrderCriteriaObj;

    this.resultOrderSpotViewStore.store = new CustomStore({
      key: "orderLineItemGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return orderService.searchOrdersSpotView(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var orderSearchResponse = response.result;
              var obj: any = {
                data: orderSearchResponse.searchResultsSpotView,
                totalCount: orderSearchResponse.spotViewTotalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public clearSearch() {
    //Clears all searches including quick and advanced
    this.orderSearchRequest.reset();
    this.quickSearchValue = "";

    if (this.clientDataSource.length == 1)
      this.orderSearchRequest.controls['client'].setValue(this.clientDataSource[0].id);
    else
      this.getClientBrands(0);
    this.getBrandProducts(0);

    this.setDefaultFormValues();

    this.orderViewGrid.instance.refresh();

    if (this.showTabs) {
      this.spotViewGrid.instance.refresh();
    }
  }

  public setDefaultFormValues() {
    this.searchOrderCriteriaObj.brand = null;
    this.searchOrderCriteriaObj.adId = null;
    this.searchOrderCriteriaObj.workOrderNumber = null;
    this.searchOrderCriteriaObj.vendorPurchaseOrder = null;
    this.searchOrderCriteriaObj.clientPurchaseOrder = null;
    this.searchOrderCriteriaObj.quickSearch = null;
    this.searchOrderCriteriaObj.status = null;
    this.searchOrderCriteriaObj.client = null;
    this.searchOrderCriteriaObj.orderDateFrom = null;
    this.searchOrderCriteriaObj.orderDateTo = null;
    this.searchOrderCriteriaObj.product = null;
    this.searchOrderCriteriaObj.destination = null;
    this.searchOrderCriteriaObj.awaitingMediaOrdersOnly = false;
    this.searchOrderCriteriaObj.ordersMarkedForFinalReview = false;
    this.searchOrderCriteriaObj.flaggedOnly = false;
    this.searchOrderCriteriaObj.spotTitle = null;
    this.searchOrderCriteriaObj.jobNumber = null;
    this.searchOrderCriteriaObj.orderBilledDateFrom = null;
    this.searchOrderCriteriaObj.orderBilledDateTo = null;
    this.searchOrderCriteriaObj.orderBuiltBy = null;
    this.searchOrderCriteriaObj.trafficFileName = null;
    this.searchOrderCriteriaObj.estimate = null;
    this.searchOrderCriteriaObj.referenceKeywords = null;
    this.searchOrderCriteriaObj.costCenter = null;
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchOrderCriteriaObj.quickSearch = this.quickSearchValue;
    this.orderViewGrid.instance.refresh();
    if (this.showTabs) {
      this.spotViewGrid.instance.refresh();
    }
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      //this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};
      this.searchOrderCriteriaObj.quickSearch = this.quickSearchValue;
      this.setDefaultFormValues();
      this.orderViewGrid.instance.refresh();
      if (this.showTabs) {
        this.spotViewGrid.instance.refresh();
      }
    }
  }

  public onFormSubmit({ value, valid }: { value: SearchOrderRequestViewModel, valid: boolean }) {
    if (value.adId === null && value.brand === null && value.client === null
      && value.clientPurchaseOrder === null && value.status === null && value.orderBuiltBy === null
      && value.vendorPurchaseOrder === null && value.workOrderNumber === null && value.jobNumber === null && (value.orderDateFrom === null || value.orderDateFrom === undefined)
      && (value.orderDateTo === null || value.orderDateTo === undefined)
      && (value.orderBilledDateFrom === null || value.orderBilledDateFrom === undefined) && (value.orderBilledDateTo === null || value.orderBilledDateTo === undefined)
      && value.product === null && value.destination === null
      && value.awaitingMediaOrdersOnly === false && value.ordersMarkedForFinalReview === false && value.flaggedOnly === false && value.spotTitle === null
      && value.estimate === null && value.trafficFileName == null && value.costCenter == null && value.referenceKeywords == null)
    {
      return;
    }
    if (value.orderDateFrom != null || value.orderDateFrom === undefined)
      value.orderDateFrom = moment(value.orderDateFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.orderDateTo != null || value.orderDateTo === undefined)
      value.orderDateTo = moment(value.orderDateTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.orderBilledDateFrom != null || value.orderBilledDateFrom === undefined)
      value.orderBilledDateFrom = moment(value.orderBilledDateFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.orderBilledDateTo != null || value.orderBilledDateTo === undefined)
      value.orderBilledDateTo = moment(value.orderBilledDateTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.searchOrderCriteriaObj.jobNumber = value.jobNumber;
    this.searchOrderCriteriaObj.brand = value.brand;
    this.searchOrderCriteriaObj.adId = value.adId;
    this.searchOrderCriteriaObj.workOrderNumber = value.workOrderNumber;
    this.searchOrderCriteriaObj.vendorPurchaseOrder = value.vendorPurchaseOrder;
    this.searchOrderCriteriaObj.clientPurchaseOrder = value.clientPurchaseOrder;
    this.searchOrderCriteriaObj.quickSearch = null;
    this.searchOrderCriteriaObj.status = value.status;
    this.searchOrderCriteriaObj.spotTitle = value.spotTitle;
    this.searchOrderCriteriaObj.client = value.client;
    this.searchOrderCriteriaObj.orderDateFrom = value.orderDateFrom;
    this.searchOrderCriteriaObj.orderDateTo = value.orderDateTo;
    this.searchOrderCriteriaObj.orderBilledDateFrom = value.orderBilledDateFrom;
    this.searchOrderCriteriaObj.orderBilledDateTo = value.orderBilledDateTo;
    this.searchOrderCriteriaObj.postHouse = value.postHouse;
    this.searchOrderCriteriaObj.product = value.product;
    this.searchOrderCriteriaObj.destination = value.destination;
    this.searchOrderCriteriaObj.awaitingMediaOrdersOnly = value.awaitingMediaOrdersOnly;
    this.searchOrderCriteriaObj.ordersMarkedForFinalReview = value.ordersMarkedForFinalReview;
    this.searchOrderCriteriaObj.flaggedOnly = value.flaggedOnly;
    this.searchOrderCriteriaObj.orderBuiltBy = value.orderBuiltBy;
    this.searchOrderCriteriaObj.trafficFileName = value.trafficFileName;
    this.searchOrderCriteriaObj.estimate = value.estimate;
    this.searchOrderCriteriaObj.costCenter = value.costCenter;
    this.searchOrderCriteriaObj.referenceKeywords = value.referenceKeywords;
    this.orderViewGrid.instance.refresh();

    if (this.showTabs) {
      this.spotViewGrid.instance.refresh();
    }
  }

  public onClientValueChanged(e) {
    if (this.util.notEmpty(e)) {
      this.getClientBrands(e.value);
      this.getClientProducts(e.value);
    }
    else {
      this.brandDataSource = [];
      this.productDataSource = [];
    }
  }

  public onBrandValueChanged(e) {
    if (e == null || e == undefined || e.value == null) {
      this.getBrandProducts(0);
    }
    else {
      this.getBrandProducts(e.value);
    }
  }

  public getBrandProducts(id: number) {
    this.productDataSource = [];

    this.orderSearchRequest.controls['product'].setValue("");

    this.configService.getBrandProducts(id, this.orderSearchRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
        this.productDataSource = this.productDataSource.filter(pds => pds.id != 0);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  public getClientProducts(id: number) {
    this.orderSearchRequest.controls['product'].setValue("");
    this.productDataSource = [];
    this.configService.getClientProducts(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  public getClientBrands(id: number) {
    if (this.orderSearchRequest.value.brand != null) {
      this.orderSearchRequest.controls['brand'].setValue(null);
    }
    this.brandDataSource = [];
    this.configService.getClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;
      }
      else {
        this.brandDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.brandDataSource = [];
      });
  }

  public flagOrder() {
    if (this.isAllowedToFlagOrder) {
      var gridInstance = this.orderViewGrid;

      var flagOrdersSelected = gridInstance.instance.getSelectedRowsData();

      if (flagOrdersSelected.length > 0) {
        var flagOrderIds: any[] = [];
        flagOrdersSelected.forEach(fd => flagOrderIds.unshift(fd.orderId));
        if (flagOrderIds.length > 0) {
          this.orderService.FlagOrders(flagOrderIds).subscribe((res: any) => {
            if (res.isSuccess == true) {

              this.alertService.showMessage("SUCCESS", Constants.ordersFlagged, MessageSeverity.success);
              gridInstance.instance.deselectAll();
              gridInstance.instance.refresh();
            }
            else {
              this.util.handleIsNotSuccess(res.errors);
              gridInstance.instance.refresh();
            }
          },
            error => {
              this.util.handleError(error);
            });
        }
        else { this.alertService.showMessage("ERROR", Constants.ordersFlaggedError, MessageSeverity.success); }
      }
      else {
        this.alertService.showMessage("ERROR", Constants.noOrdersSelected, MessageSeverity.error);
        return;
      }
    }
    else { return; }
  }

  public copySelectedOrder() {
    if (this.isAllowedToCreateOrder) {
      var gridInstance = this.orderViewGrid;

      if (this.activeGrid == Constants.allOrdersGridDelivery) {
        gridInstance = this.spotViewGrid;
      }

      var copyOrderSelected = gridInstance.instance.getSelectedRowsData();
      if (copyOrderSelected.length <= 0) {
        this.alertService.showMessage("ERROR", Constants.noOrdersSelectedForCopy, MessageSeverity.error);
        return;
      }
      else if (copyOrderSelected.length > 1) {
        this.alertService.showMessage("ERROR", Constants.multipleOrdersSelected, MessageSeverity.error);
        return;
      }
      else {
        var flagOrderIds: any[] = [];
        copyOrderSelected.forEach(fd => flagOrderIds.unshift(fd.orderGuid));
        var orderGuid = flagOrderIds[0];
        this.goToCreateOrderForCopy(orderGuid);
      }
    }
    else { return; }
  }

  public goToCreateOrderForCopy(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    var initialState = {
    };

    this.modalRef = this.modalService.show(CopyOrderOptionsPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onComplete.subscribe(result => {
      this.gs.openCopyOrder(orderGuid, result);
    });

    //this.router.navigate([RouteConstants.copyOrderRoute, orderGuid]);
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);
  }

  public exportOrders() {
    var gridInstance = this.orderViewGrid;

    if (this.activeGrid == Constants.allOrdersGridDelivery) {
      gridInstance = this.spotViewGrid;
    }

    gridInstance.instance.exportToExcel(false);
  }

  public onExporting() {
    this.orderViewGrid.instance.columnOption("Air Date Start", "visible", true);
    this.orderViewGrid.instance.columnOption("Air Date End", "visible", true);
  }

  public onExportingDeliveryHistory() {
    this.spotViewGrid.instance.columnOption("Air Date Start", "visible", true);
    this.spotViewGrid.instance.columnOption("Air Date End", "visible", true);
  }

  public onExported() {
    this.orderViewGrid.instance.columnOption("Air Date Start", "visible", false);
    this.orderViewGrid.instance.columnOption("Air Date End", "visible", false);
  }

  public onExportedDeliveryHistory() {
    this.spotViewGrid.instance.columnOption("Air Date Start", "visible", false);
    this.spotViewGrid.instance.columnOption("Air Date End", "visible", false);
  }

  public onclickOrdersGrid() {
    this.activeGrid = Constants.allOrdersGridMain;
    this.showFlagOrder = true;
  }

  public onclickDeliveryHistoryGrid() {
    this.activeGrid = Constants.allOrdersGridDelivery;
    this.showFlagOrder = false;
    this.searchDeliveryHistory();  
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }
}
