import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { SpotUpdateQC } from '../../models/spot/spot-update-qc.model';
import { SpotExpiryRequest } from "../../models/spot/spot-expiry.request.model";
import { SpotRestoreRequest } from "../../models/spot/spot-restore.request.model";
import { ConfirmRequest } from "../../models/generic/confirm-request";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SpotService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  //public getQCSpots(): Observable<Object> {
  //  return this.http.get(this.apiendPoint.getQCSpotsEndpoint());
  //}

  public getApproavalReadySpots(): Observable<Object> {
    return this.http.get(this.apiendPoint.getApprovalReadySpotsEndpoint());
  }

  public getRecentlyViewedSpots(): Observable<Object> {
    return this.http.get(this.apiendPoint.getRecentlyViewedSpotsEndPoint());
  }

  public updateSpotQC(passSpotQC: SpotUpdateQC) {
    return this.http.post(this.apiendPoint.updateqcSpotsEndpoint(), passSpotQC);
  }

  public searchSpots(spotSearchRequest: any) {
    return this.http.post(this.apiendPoint.searchSpotsEndpoint(), spotSearchRequest);
  }

  public getSpotFiles(spotGuid: number) {
    return this.http.get(this.apiendPoint.getSpotFilesEndpoint() + "/" + spotGuid);
  }

  public getCreatePlaceHolderConfig() {
    return this.http.get(this.apiendPoint.getFormatsEndpoint());
  }

  public searchQCReadySpots(searchRequest: any) {
    return this.http.post(this.apiendPoint.searchQCReadySpotsEndpoint(), searchRequest);
  }

  public searchApprovalReadySpots(searchRequest: any) {
    return this.http.post(this.apiendPoint.searchApprovalReadySpotsEndpoint(), searchRequest);
  }

  public expireSpots(expiryRequest: SpotExpiryRequest) {
    return this.http.post(this.apiendPoint.expireSpotEndPoint(), expiryRequest);
  }
  public restoreSpots(restoreRequest: SpotRestoreRequest) {
    return this.http.put(this.apiendPoint.restoreSpotsEndPoint(), restoreRequest);
  }
  public deletePlaceholder(confirmRequest: ConfirmRequest) {
    return this.http.put(this.apiendPoint.deletePlaceholderEndPoint(), confirmRequest);
  }

  public getDownloadSpec(path: string) {
    let request: any = {};
    request.command = "download";
    request.destination = path;

    return this.http.post(this.apiendPoint.getUploadSpecEndpoint(), request);
  }

  public searchSpotsForOrder(request: any) {
    return this.http.post(this.apiendPoint.searchSpotsForOrderEndpoint(), request);
  }

  public searchSpotsForTranscodeOrder(request: any) {
    return this.http.post(this.apiendPoint.searchSpotsForTranscodeOrderEndpoint(), request);
  }

  public getProxyUrl(objectConfig: any) {
    return this.http.post(this.apiendPoint.getProxyUrlEndpoint(), objectConfig);
  }

  public createQuickLinkRequest(request: any) {
    return this.http.post(this.apiendPoint.createQuickLinkRequestEndPoint(), request);
  }

  public getQuickLinks() {
    return this.http.get(this.apiendPoint.getQuickLinksEndPoint());
  }

  public getQuickLinkDetail(quickLinkGuid: string) {
    return this.http.get(this.apiendPoint.getQuickLinkDetailEndPoint() + "/" + quickLinkGuid);
  }

  public validateSpotsFromText(request: any) {
    return this.http.post(this.apiendPoint.validateSpotsTextEndpoint(), request);
  }

  public validateSpotsFromFile(request: any) {
    return this.http.post(this.apiendPoint.validateSpotsFileEndpoint(), request);
  }

  public ValidateTranscodeOrderSpotsFromText(request: any) {
    return this.http.post(this.apiendPoint.validateTranscodeOrderSpotsFromTextEndPoint(), request);
  }

  public ValidateTranscodeOrderSpotsFromFile(request: any) {
    return this.http.post(this.apiendPoint.ValidateTranscodeOrderSpotsFromFileEndPoint(), request);
  }

  public NotifyS3UploadSpotArrival(request: any) {
    return this.http.post(this.apiendPoint.NotifyS3UploadSpotArrivalEndPoint(), request);
  }
}
