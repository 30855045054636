<div id="spot-history">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Order History</h4>
  </div>
  <div class="modal-body">
    <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="!spotOrderHistoryData"></dx-load-indicator>
    <dx-data-grid id="spotOrderHistoryData" *ngIf="spotOrderHistoryData" class="tylie-grid tylie-grid--bordered"
                  [dataSource]="spotOrderHistoryData"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="true"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  keyExpr="workOrder">
      <dxi-column dataField="workOrder" caption="Order Id" cssClass="gridStyle" cellTemplate="workOrderTemplate"></dxi-column>
      <dxi-column dataField="jobNumber" caption="Job #" cssClass="gridStyle"></dxi-column>
      <dxi-column dataField="clientPurchaseOrder" caption="Purchase Order" cssClass="gridStyle"></dxi-column>
      <dxi-column dataField="status" caption="Status" cssClass="gridStyle"></dxi-column>
      <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created On" cssClass="gridStyle" sortOrder="desc"></dxi-column>
      <div *dxTemplate="let data of 'dateTemplate'">
        <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY HH:mm:ss'}}</div>
      </div>
      <div *dxTemplate="let t of 'workOrderTemplate'">
        <span class="blue-text" (click)='showOrderDetail(t.data.orderGuid)' *ngIf="hasOrderViewRole">{{t.data.workOrder}}</span>
        <span *ngIf="!hasOrderViewRole">{{t.data.workOrder}}</span>
      </div>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true"
                  [allowedPageSizes]="[10, 20, 50, 100]"
                  infoText="Page {0} of {1} ({2} items)"
                  [showNavigationButtons]="true"
                  [showInfo]="true">
      </dxo-pager>
    </dx-data-grid>
  </div>
</div>
