import { Component, ViewChild, TemplateRef, Input, OnInit, ViewChildren, QueryList } from '@angular/core';

import { ConfigService } from '../../../../services/core/config.service';
import { Utilities } from '../../../../services/core/utilities';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { OrderUserDestination, TranscodeSpecification } from '../../../../models/order/order-userdestination';
import { SearchUserDestinationRequest } from '../../../../models/order/order-searchuserdestination-request.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranscodeListPopupComponent } from '../../../popups/transcodelistpopup/transcodelistpopup.component';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranscodeOrderConfig } from '../../../../models/order/transcodeorder-config.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../../configurations/constants';

@Component({
  selector: 'transcode-specselection-step',
  templateUrl: './transcode-specselection-step.component.html',
  styleUrls: ['./transcode-specselection-step.component.css']
})

export class TranscodeSpecSelectionComponent implements OnInit {

  public transcodesSavedListSource: Array<OrderUserDestination> = [];
  public transcodeSpecsSource: Array<any> = [];
  public modalRef: BsModalRef;
  public quickSearchValue: string = "";

  public transcodesSelectedString: string;
  private selectedTranscodesSelected: boolean = false;

  @Input() selectedSpotFile: SpotFile;
  @Input() selectedSpotFileTranscodes: Array<string>;
  @Input() selectionType: string;
  @Input() transcodeOrderConfig: TranscodeOrderConfig;
  @Input() isLastOne: boolean;

  @ViewChild('ordertranscodeSpecsGrid', { static: true }) ordertranscodeSpecsGrid: DxDataGridComponent;
  @ViewChild('ordertranscodesSavedListGrid', { static: true }) ordertranscodesSavedListGrid: DxDataGridComponent;
  @ViewChildren('listdeletepop') listdeletepop: QueryList<PopoverDirective>;

  constructor(
    private configService: ConfigService,
    private util: Utilities,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public alertService: AlertService) {
  }

  ngOnInit() {

    this.getTransLists();

    this.transcodeSpecsSource = this.transcodeOrderConfig.transcodePredefinedSpecs;
  }

  private getTransLists() {

    let request: SearchUserDestinationRequest = { category: 'speclist', quickSearch: '' };

    this.configService.searchUserDestinations(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var destinations = res.result as Array<OrderUserDestination>;
        this.transcodesSavedListSource = destinations;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onCreateNewList() {
    var initialState = {
      transcodesSavedListToEdit: null,
      isEdit: false
    };

    this.modalRef = this.modalService.show(TranscodeListPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w70"));

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true)
        this.getTransLists();
    });
  }

  public onItemDelete(id: number) {

    this.configService.deleteUserSavedDestinationList(id, "speclist").subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcodeorder_list_deleted, MessageSeverity.success);
        this.getTransLists();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.onHideDeleteListPop();
    },
      error => {
        this.util.handleError(error);
        this.onHideDeleteListPop();
      });
  }

  public onItemEdit(id: number) {
    var itemToEdit = this.transcodesSavedListSource.find(t => t.id == id);

    var initialState = {
      transcodesSavedListToEdit: itemToEdit,
      isEdit: true
    };

    this.modalRef = this.modalService.show(TranscodeListPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w70"));

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true)
        this.getTransLists();
    });
  }

  private updateTranscodesSelectedString() {
    var selectedTranscodes = [];

    for (var i = 0; i < this.ordertranscodeSpecsGrid.instance.getSelectedRowKeys().length; i++)
      selectedTranscodes.push(this.ordertranscodeSpecsGrid.instance.getSelectedRowsData()[i].name);

    this.transcodesSelectedString = selectedTranscodes.join(",");
  }

  private checkPreviouslySelectedTranscodes(e) {
    this.selectedSpotFileTranscodes.forEach(item => {

      var ind = this.transcodeSpecsSource.findIndex(t => t.name == item);

      if (ind >= 0)
        e.component.selectRows([this.transcodeSpecsSource[ind].id], true);
    });
  }

  public onSpecsGridContentReady(e) {

    if (this.selectedTranscodesSelected == false) {      

      this.checkPreviouslySelectedTranscodes(e);

      this.selectedTranscodesSelected = true;
    }

    this.updateTranscodesSelectedString();
  }

  public onSpecsGridSelectionChanged(e) {

    this.updateTranscodesSelectedString();
  }

  public refreshSelection() {

    this.ordertranscodeSpecsGrid.instance.refresh();
    this.ordertranscodesSavedListGrid.instance.refresh();

    this.ordertranscodeSpecsGrid.instance.deselectAll();
    this.ordertranscodesSavedListGrid.instance.deselectAll();

    this.selectedTranscodesSelected = false;
  }

  public onQuickSearch() {
    this.ordertranscodeSpecsGrid.instance.searchByText(this.quickSearchValue);
  }

  public onClearQuickSearch() {
    this.ordertranscodeSpecsGrid.instance.searchByText("");
  }

  public getAllSelectedTranscodes(): Array<string> {

    var selectedTranscodes = [];

    this.ordertranscodesSavedListGrid.instance.getSelectedRowKeys().forEach(id => {
      var ind = this.transcodesSavedListSource.findIndex(t => t.id == id);

      if (ind >= 0) {

        this.transcodesSavedListSource[ind].specifications.forEach(s => {

          if (selectedTranscodes.findIndex(tran => tran == s.name) < 0)
            selectedTranscodes.push(s.name);
        });
      }
    });

    for (var i = 0; i < this.ordertranscodeSpecsGrid.instance.getSelectedRowKeys().length; i++) {
      if (selectedTranscodes.findIndex(tran => tran == this.ordertranscodeSpecsGrid.instance.getSelectedRowsData()[i].name) < 0)
        selectedTranscodes.push(this.ordertranscodeSpecsGrid.instance.getSelectedRowsData()[i].name);
    }

    return selectedTranscodes;
  }

  public onHideDeleteListPop() {
    this.listdeletepop.forEach(p => {
      p.hide();
    });
  }

  ngOnDestroy() {
  }
}
