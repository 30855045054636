
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent } from "devextreme-angular";

import { BsModalService } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { environment } from '../../../../../environments/environment';
import { SearchOrderRequestViewModel } from '../../../../models/order/order-search-request.model';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'processing-orders',
  templateUrl: './processing-orders.component.html',
  styleUrls: ['./processing-orders.component.css']
})
export class ProcessingOrdersComponent implements OnInit {

  @ViewChild('ordersInProcessing', { static: true }) ordersInProcessing: DxDataGridComponent;

  private refreshId: any;
  public processingOrdersStore: any = {};
  public orderSearchRequest: FormGroup;
  public searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};

  constructor(
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities, private sub: SubService, private gs: GlobalService) {
    this.orderService.onOrderClaimedforProcessing.subscribe(() => {
      this.ordersInProcessing.instance.refresh();
    });

    this.orderSearchRequest = new FormGroup({
      awaitingMediaOrdersOnly: new FormControl(),
      awaitingApprovalOrders: new FormControl(),
      processingOnly: new FormControl(),
      approvalRejectedOrders: new FormControl()
    });
  }

  ngOnInit() {
    if (this.userProfileService.getUserRoles().includes(this.userRoles.getOrderStatusEditorRoleName())) {

      this.sub.orderEditedMethodCalled.subscribe(() => { this.ordersInProcessing.instance.refresh(); });

      this.refreshId = setInterval(() => {
        this.ordersInProcessing.instance.refresh();
      }, environment.ordersProcessingRefreshTimeInMins * 60000);

      //this.orderSearchRequest = new FormGroup({
      //  awaitingMediaOrdersOnly: new FormControl(),
      //  awaitingApprovalOrders: new FormControl(),
      //  processingOnly: new FormControl(),
      //  approvalRejectedOrders: new FormControl()
      //});

      this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};

      this.orderSearchRequest.controls['awaitingMediaOrdersOnly'].setValue(false);
      this.orderSearchRequest.controls['awaitingApprovalOrders'].setValue(false);
      this.orderSearchRequest.controls['processingOnly'].setValue(false);
      this.orderSearchRequest.controls['approvalRejectedOrders'].setValue(false);

      this.getOrdersInProcessing();
    }
  };

  ngAfterViewInit(): void {

  }

  public onRefresh() {
    this.ordersInProcessing.instance.refresh();
  }

  public getOrdersInProcessing() {
    var orderService = this.orderService;
    var util = this.util;
    var criteriaObj = this.searchOrderCriteriaObj;

    this.processingOrdersStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return orderService.getProcessingOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var processsingOrdersResponse = response.result;
              var obj: any = {
                data: processsingOrdersResponse.orderResults,
                totalCount: processsingOrdersResponse.totalCount
              };
              util.ordersInProcessingCount = processsingOrdersResponse.totalPendingActionOrdersCount;
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.isSetToReadyForProcess) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onClickCompleteMultipleOrderProcessing() {
    var completeProcessOrders = this.ordersInProcessing.instance.getSelectedRowKeys();

    if (completeProcessOrders.length == 0) {
      this.alertService.showMessage("ERROR", Constants.noOrdersSelected, MessageSeverity.error);
      return;
    }
    if (completeProcessOrders.length > 5) {
      this.alertService.showMessage("ERROR", Constants.sixOrMoreOrdersSelected, MessageSeverity.error);
      return;
    }

    this.orderService.complteMultipleOrderProcessing(completeProcessOrders).pipe(
      finalize(() => {
        this.ordersInProcessing.instance.refresh();
      }))
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.ordersInProcessing.instance.deselectAll();
          this.alertService.showMessage("SUCCESS", Constants.orderCompleteProcessing, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onClickReleaseMultipleOrdersClaimedForProcessing() {

    var releaseProcessingOrders = this.ordersInProcessing.instance.getSelectedRowKeys();

    if (releaseProcessingOrders.length == 0) {
      this.alertService.showMessage("ERROR", Constants.noOrdersSelected, MessageSeverity.error);
      return;
    }

    if (releaseProcessingOrders.length > 10) {
      this.alertService.showMessage("ERROR", Constants.elevenOrMoreOrdersSelected, MessageSeverity.error);
      return;
    }

    this.orderService.releaseMultipleOrdersClaimedForProcessing(releaseProcessingOrders).pipe(
      finalize(() => {
        this.ordersInProcessing.instance.refresh();
      }))
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.ordersInProcessing.instance.deselectAll();
          this.alertService.showMessage("SUCCESS", Constants.multipleOrderReleaseClaimSuccess, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onClickCheckBox({ value, valid }: { value: SearchOrderRequestViewModel, valid: boolean }) {

    this.searchOrderCriteriaObj.awaitingMediaOrdersOnly = value.awaitingMediaOrdersOnly;
    this.searchOrderCriteriaObj.awaitingApprovalOrders = value.awaitingApprovalOrders;
    this.searchOrderCriteriaObj.processingOnly = value.processingOnly;
    this.searchOrderCriteriaObj.approvalRejectedOrders = value.approvalRejectedOrders;

    console.log(this.searchOrderCriteriaObj);
    this.onRefresh();
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}

