import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaggerBaseCreative, TaggerCard, TaggerCardVoiceOver } from '../../../../models/tagger/tagger.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { AdCreatorStepEnum } from '../../../../configurations/enums/enums';

@Component({
  selector: 'app-tagger-review-step',
  templateUrl: './tagger-review-step.component.html',
  styleUrls: ['./tagger-review-step.component.css']
})
export class TaggerReviewStepComponent implements OnInit {
  @Input() topFormGroup: FormGroup;
  @Input() selectedBaseCreatives: TaggerBaseCreative[];
  @Input() selectedOfferCards: TaggerCard[];
  @Input() selectedEndCards: TaggerCard[];
  @Input() selectedEndCardVoiceOvers: TaggerCardVoiceOver[];
  @Input() requestOrigination: string = null;
  @Input() currentStep: AdCreatorStepEnum = AdCreatorStepEnum.selectCreative;

  @ViewChild('taggernamepop', { static: false }) taggernamepop: PopoverDirective;

  public adCreatorStepEnum = AdCreatorStepEnum;
  constructor() { }

  ngOnInit() {
    console.log(this.selectedBaseCreatives);
    console.log(this.selectedOfferCards);
    console.log(this.selectedEndCards);
    console.log(this.selectedEndCardVoiceOvers);
  }

  get taggerName() {
    return this.topFormGroup.get('name').value || '';
  }

  public stopTogglePropogation(e) {
    e.stopPropagation();
  }

  public onTaggerNamePop() {
    this.taggernamepop.hide();
  }
}
