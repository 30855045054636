import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ServiceBAMetadataObjectOption } from '../../../../models/order/order-servicequestions.model';

@Component({
  selector: 'app-service-baquestions-metadata',
  templateUrl: './service-baquestions-metadata.component.html',
  styleUrls: ['./service-baquestions-metadata.component.css']
})
export class ServiceBaquestionsMetadataComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() f: any;

  public baQuestionOpptions : Array<BAQuestions> = [];
  public isDisabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
    console.log(this.formGroup);

    this.isDisabled = this.formGroup.controls["passthroughdata"].disabled;
    if (this.formGroup.controls["passthroughdata"].value == null) {
      this.baQuestionOpptions.push({ id: 1, invoiceamount: null, itemdescription: null, refno: null });
    }
    else {
      var exisitingValues = this.formGroup.controls["passthroughdata"].value as Array<ServiceBAMetadataObjectOption>;

      exisitingValues.forEach((item, index) => {
        this.baQuestionOpptions.push({ id: index + 1, invoiceamount: item.val1, itemdescription: item.val2, refno: item.val3 });
      });
    }

    // Apply the custom validator
    this.formGroup.controls['passthroughdata'].setValidators([this.baQuestionsOptionsValidator(this.baQuestionOpptions)]);
    this.formGroup.controls['passthroughdata'].updateValueAndValidity();

  }

  public onAddNew(i) {
    if (i == 1)
      this.baQuestionOpptions.push({ id: this.baQuestionOpptions[this.baQuestionOpptions.length - 1].id + 1, invoiceamount: null, itemdescription: null, refno: null });
    else if (i > 1)
      this.baQuestionOpptions = this.baQuestionOpptions.filter(t => t.id != i);

    this.onChange();
  }

  public onChange() {

    let val: Array<ServiceBAMetadataObjectOption> = [];

    this.baQuestionOpptions.forEach((item, index) => {
      if (item.invoiceamount != null && item.itemdescription != null && item.refno != null)
        val.push({ val1: item.invoiceamount, val2: item.itemdescription, val3: item.refno });
    });

    this.formGroup.controls["passthroughdata"].setValue(val);
    this.formGroup.controls['passthroughdata'].updateValueAndValidity();

  }

  baQuestionsOptionsValidator(baQuestionsOptions: any[]): ValidatorFn {
    console.log(baQuestionsOptions);
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasError = baQuestionsOptions.some(option =>
        option.invoiceamount == null || option.invoiceamount === '' ||
        option.itemdescription == null || option.itemdescription === '' || 
        option.refno == null || option.refno == ''
      );

      return hasError ? { 'baQuestionsOptionsError': true } : null;
    };
  }

}

interface BAQuestions {
  id: number,
  invoiceamount: string,
  itemdescription: string,
  refno: string,
}
