<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}">
    <!--<label class="tylie-upload" *ngIf="isDisabled === false">
      {{isUploading ? 'UPLOADING...' : 'UPLOAD SCRIPT'}}
      <div class="tylie-upload__action tylie-upload__action--xs">
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
        <input type="file" multiple style="display:none" (change)="onAttachFile($event)">
      </div>
    </label>-->

    <label class="tylie-upload" *ngIf="isDisabled === false">
      <input type="file" multiple style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-upload__action--xs">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD SCRIPT'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>

    <p *ngFor="let fl of formGroup.controls['scriptFile'].value;">
      <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
      <a *ngIf="isDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
    </p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}">
    <label class="tylie-form-group__lbl" for="script-text">Paste Script Text<span class="required">*</span></label>
    <textarea name="script-text" id="script-text" class="tylie-textarea tylie-textarea--md form-control" formControlName="scriptText"></textarea>
    <p class="p-extra-small">Please upload Script file or paste Script text</p>
  </div>
</div>
