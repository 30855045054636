<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">FILE DELIVERY CONFIG</h4>
  </div>
  <div class="modal-body" *ngIf="fileDelvTypes">
    <form [formGroup]="fileDeliveryConfigForm" (submit)="onAddFileDeliveryConfigGroupFormSubmit(fileDeliveryConfigForm)" novalidate #ngForm="ngForm">
      <ng-container>
        <div formArrayName="fileDeliveryConfigurations" *ngFor="let cfd of getFormFileDeliveryConfigGroupsFormArray.controls; let i = index">
          <filedeliveryconfiggroup [cfdGroup]="cfd"
                                    [groupName]="i"
                                    [parentForm]="ngForm"
                                    [formGroupcount]="fileDeliveryConfigForm.get('fileDeliveryConfigurations').controls?.length"
                                    [fileDelvTypes]="fileDelvTypes"
                                    [isEdit]="isEdit"
                                    (onAccountNameValueChangedEventTriggered)="verifyUniqueAccountNames($event)"
                                    (onClickRemoveFileDeliveryConfigEventTriggered)="onClickRemoveFileDeliveryConfig($event)">

          </filedeliveryconfiggroup>
        </div>
      </ng-container>

     
      <div class="d-flex justify-content-end my-4">
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddAnotherFileDeliveryConfig()">
          <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD CONFIG
        </button>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
