<div class="tylie-panel" id="createclient">
  <div class="tylie-panel__header">
    <h4 class="tylie-panel__title">
      PRODUCTS
    </h4>
  </div>

  <div class="tylie-panel__body" *ngIf="editMode == true && config.canAddProduct == true">
      <button type="button" class="tylie-button tylie-button--icon" (click)="onAddProducts()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">ADD PRODUCTS</span>
      </button>
  </div>

  <div class="tylie-panel__body" id="add-newproduct" *ngIf="addNewProduct == true">
    <form name="createProductForm" id="createProductForm" [formGroup]="createProductForm" #f="ngForm">
        <div class="row">
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createProductForm.controls['productName'].errors && !createProductForm.controls['productName'].pristine)
            || (createProductForm.controls['productName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="productname">Product Name<span class="required">*</span></label>
              <input type="text" name="productName" id="productName" class="tylie-text form-control" formControlName="productName" />
              <p class="p-extra-small">Please Enter Product Name</p>
            </div>
          </div>
          <div class="col-8">
            <div class="form-group" [ngClass]="{error: (createProductForm.controls['shortName'].errors && !createProductForm.controls['shortName'].pristine)
                || (createProductForm.controls['shortName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="shortname">Short Name<span class="required">*</span></label>
              <div class="row">
                <div class="col-6">
                  <input type="text" name="shortName" id="shortName" class="tylie-text form-control" formControlName="shortName" />
                  <p class="p-extra-small">Please Enter a Short Name with a maximum of 15 Characters</p>
                </div>
                <div class="col-6">
                  <div class="d-flex">
                    <button class="tylie-button tylie-button--sm me-3" type="submit" (click)="onFormSubmit(createProductForm, f)">
                      SAVE
                    </button>
                    <button type="button" class="tylie-button tylie-button--sm tylie-button--link" (click)="onCancelProductCreateEdit()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </form>
  </div>

  <div class="tylie-panel__body" id="add-newproduct" *ngIf="editProduct == true">
    <form name="editProductForm" id="editProductForm" [formGroup]="editProductForm" #f="ngForm">
        <div class="row">
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: (editProductForm.controls['productName'].errors && !editProductForm.controls['productName'].pristine)
              || (editProductForm.controls['productName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="productname">Product Name<span class="required">*</span></label>
              <input type="text" name="productName" id="productName" class="tylie-text form-control" formControlName="productName" />
              <p class="p-extra-small">Please Enter Product Name</p>
            </div>
          </div>
          <div class="col-8">
            <div class="tylie-form-group form-group" [ngClass]="{error: (editProductForm.controls['shortName'].errors && !editProductForm.controls['shortName'].pristine)
              || (editProductForm.controls['shortName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="shortname">Short Name<span class="required">*</span></label>
              <div class="row">
                <div class="col-6">
                  <input type="text" name="shortName" id="shortName" class="tylie-text form-control" formControlName="shortName" />
                  <p class="p-extra-small">Please Enter a Short Name with a maximum of 15 Characters</p>
                </div>
                <div class="col-6">
                  <div class="d-flex">
                    <button class="tylie-button tylie-button--sm me-3" type="submit" (click)="onFormSubmit(editProductForm, f)">
                      SAVE
                    </button>
                    <button type="button" class="tylie-button tylie-button--sm tylie-button--link" (click)="onCancelProductCreateEdit()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </form>
  </div>

  <div class="tylie-panel__body" *ngIf="productStore && productStore?.length > 0">
    <dx-data-grid id="productStoreGrid"
                  #productStoreGrid class="tylie-grid"
                  [dataSource]="productStore"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  keyExpr="productId"
                  [rowAlternationEnabled]="true">
      <dxo-export [enabled]="false" fileName="brands" [allowExportSelectedData]="true"></dxo-export>
      <dxi-column dataField="name" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="45%" sortOrder="asc"></dxi-column>
      <dxi-column dataField="shortName" caption="Short Name" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="40%"></dxi-column>
      <dxi-column dataField="productId" caption="Delete" cssClass="gridStyle tyl-nopadding tyl-grid-padding tyl-text-align-left" cellTemplate="actionsTemplate" width="20%" [allowSorting]="false"
                  [visible]="config.canEditProduct == true || config.canDeleteProduct == true"></dxi-column>

      <div *dxTemplate="let t of 'actionsTemplate'">
        <div class="d-flex">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" *ngIf="config.canDeleteProduct == true" (click)="onProductStoreDelete(t.data)">
            <icon-trash-can color="#1e90ff"></icon-trash-can>
          </button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" *ngIf="config.canEditProduct == true" (click)="onEditProduct(t.data)">
            <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
          </button>
        </div>
      </div>
      <dxo-paging [pageSize]="100"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true"
                  [allowedPageSizes]="[100, 200, 500, 1000]"
                  infoText="Page {0} of {1} ({2} items)"
                  [showNavigationButtons]="true"
                  [showInfo]="true">
      </dxo-pager>
    </dx-data-grid>
  </div>
</div>

