import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";

@Injectable()
export class TandemGridService {

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private authService: AuthService) { }

  public getGridDetailsByUserSettings(gridName: string) {
    return this.http.get(this.apiendPoint.getGridDetailsByUserSettingsEndpoint() + "/" + gridName);
  }

  public saveGridDetailsInUserSettings(userSettings: any) {
    return this.http.post(this.apiendPoint.saveGridDetailsInUserSettingsEndpoint(), userSettings);
  }

  public resetGridDetailsForUser(gridName: string) {
    return this.http.get(this.apiendPoint.resetGridDetailsForUserSettingsEndpoint() + "/" + gridName);
  }

}
