import { Component, OnInit } from '@angular/core';

import { PlayList } from '../../../models/playlist/playlist.model'
import { PlayListFileNew } from '../../../models/playlist/playlist-file-new.model';
import { PlayListService } from '../../../services/playlist/playlist.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { SubService } from "../../../services/media/sub.service";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { PlayListAddFiles } from "../../../models/playlist/playlist-add.request.model";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'addtoplaylist',
  templateUrl: './addtoplaylist.component.html',
  styleUrls: ['./addtoplaylist.component.css'],
})
export class AddToPlayListComponent extends BasePopupComponent {

  public selectedPlayListFiles: Array<PlayListFileNew> = [];
  public userPlayLists: PlayList[];
  public title: string = '';
  public closeBtnName: string;
  public list: any[] = [];
  public source: string = '';
  public playListRequest: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private playListService: PlayListService,
    private alertService: AlertService,
    private util: Utilities,
    private subService: SubService) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.playListRequest = new FormGroup({
      newPlayListName: new FormControl(null, Validators.required)
    });

    this.loadPlayLists();
  }

  private loadPlayLists() {
    this.playListService.getPlayLists().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var playLists = res.result;
        this.userPlayLists = playLists;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  private refreshParentPlaylists(source: string) {
    if (source == "search") {
      this.subService.callrefreshPlayListSearchMethod();
    }
    else if (source == "recentlyviewed") {
      this.subService.callrefreshPlayListRecentlyViewedMethod();
    }
    else if (source == "approval") {
      this.subService.callrefreshPlayListMediaApprovalMethod();
    }
  }

  private addSpotsToPlayList(playListFiles: PlayListFileNew[], playListGuid: string) {
    this.playListService.addToPlayList(playListGuid, playListFiles).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.spotsAddedPlaylist, MessageSeverity.success);

        var sourceCalled = this.source;
        this.source = '';
        this.refreshParentPlaylists(sourceCalled);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });

    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSelectPlayList(e) {
    this.addSpotsToPlayList(this.selectedPlayListFiles, e.value);
  }

  public createNewPlayList({ value, valid }: { value: PlayListAddFiles, valid: boolean }) {
    if (this.playListRequest.controls['newPlayListName'].errors)
      return;

    var request = { playListName: value.newPlayListName, playListFiles: this.selectedPlayListFiles };

    this.playListService.createPlayList(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.playlistCreated, MessageSeverity.success);

        var sourceCalled = this.source;
        this.source = '';
        this.refreshParentPlaylists(sourceCalled);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.closePopup();
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }
}
