<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['trafficFile']?.errors && f.submitted)}">
    <label class="tylie-upload" *ngIf="isDisabled === false">
      <input type="file" multiple style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-button tylie-button--sm">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD FILE'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>
    <p class="p-extra-small">Please upload Traffic Instructions</p>

    <p *ngFor="let fl of formGroup.controls['trafficFile'].value;">
      <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
      <a *ngIf="isDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link">
        <icon-trash-can color="#1e90ff"></icon-trash-can>
      </a>
    </p>
  </div>
</div>
