<div class="modal-header">
  <button type="button" class="close delete" (click)="closePopup()"></button>
  <h4 class="modal-title">{{headerMessage}}</h4>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">     
      <div [innerHTML]="mainMessage | safeHtml"></div>
    </div>
    <br />
    <br />
    <div class="form-group media-qc">
      <button class="btn btn-small qc-update" type="button" (click)="closePopup()" (keyup.enter)="closePopup()">Ok</button>
    </div>
  </form>
</div>
