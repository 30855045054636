import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ServiceType, ServiceDependency } from '../../../models/order/servicetype.model';
import { ConfigService } from '../../../services/core/config.service';
import { DxTreeViewModule, DxTreeViewComponent, DxDataGridComponent } from 'devextreme-angular';
import { SpotSelectedServicesRequest, SpotServicesOptionsRequest } from '../../../models/order/order-spotselectedservice-request.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Array } from 'core-js';
import { ServiceOptionsRequest } from '../../../models/order/order-servicequestions.model';
import { ServiceData, ServiceMetadata } from '../../../models/order/order-create.model';
import { metadataCategoryEnum } from '../../../configurations/enums/enums';
import { OrderAdditionalMetadata } from '../../../models/order/order-additionalmetadata';
import * as moment from 'moment-timezone';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderaddservicepopup',
  templateUrl: './orderaddservicepopup.component.html',
  styleUrls: ['./orderaddservicepopup.component.css'],
})
export class OrderAddServicePopupComponent extends BasePopupComponent {

  @Input() spotFileGuid: string;
  @Input() groupSpotId: string;
  @Input() applyAllMode: boolean;
  @Input() editMode: boolean;
  @Input() orderGuid: string;
  @Input() serviceData: Array<ServiceData>;
  @Input() additionalMetadata: OrderAdditionalMetadata;
  @Input() orderEditMode: boolean;
  @Input() showInEditMode: boolean;
  @Input() specialServiceMode: boolean;

  @Input() orderLevel: boolean;
  @Input() groupLevel: boolean;
  @Input() spotLevel: boolean;

  @ViewChild('treeDelivery', { static: false }) treeDelivery: DxTreeViewComponent;
  @ViewChild('treeUserGrps', { static: false }) treeAddl: DxTreeViewComponent;
  @ViewChild('treePp', { static: false }) treePp: DxTreeViewComponent;

  public editModeWithUpdateService: boolean;

  public deliveryServicesValue: Array<number>;
  public addlServicesValue: Array<number>;
  public ppServicesValue: Array<number>;

  public deliveryServicesDataSource: Array<ServiceType> = [];
  public addlServicesDataSource: Array<ServiceType> = [];
  public ppServicesDataSource: Array<ServiceType> = [];

  public servicesDataSource: Array<ServiceType> = [];
  public spotSelectedServicesRequest: FormGroup;
  public onSelectServicesComplete: Subject<SpotSelectedServicesRequest>;
  public onServiceSubmitOptionsComplete: Subject<SpotServicesOptionsRequest>;
  public servicesForAdditionalData: Array<ServiceData>;
  public additionalDataMode: boolean = false;

  public servicesOptional: Array<ServiceData>;

  public serviceDependency: Array<ServiceDependency> = [];

  public title: string = '';

  private readonly DIGITALDELIVERY: string = "Digital Delivery";
  private readonly POSTPRODUCTION: string = "Post Production";
  private readonly ADDITIONAL: string = "Additional";
  private readonly TYLIEARROW: string = './assets/svg/Icon-OrdersTylieMark.svg';

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.alertService.logMessage(this.serviceData);

    this.title = 'SELECT SERVICES';

    this.getServices();

    if (this.editMode == true)
      this.title = "ADD / EDIT SERVICES";

    if (this.orderEditMode == true && this.showInEditMode == false)
      this.title = "VIEW SERVICES";

    this.deliveryServicesValue = this.serviceData.filter(s => s.service.category == this.DIGITALDELIVERY).map(t => t.service.id);
    this.addlServicesValue = this.serviceData.filter(s => s.service.category == this.ADDITIONAL).map(t => t.service.id);
    this.ppServicesValue = this.serviceData.filter(s => s.service.category == this.POSTPRODUCTION).map(t => t.service.id);

    this.onSelectServicesComplete = new Subject();

    this.onServiceSubmitOptionsComplete = new Subject();

    this.spotSelectedServicesRequest = new FormGroup({
      ppServices: new FormControl(),
      deliveryServices: new FormControl(),
      addlServices: new FormControl(),
      applyToAllMedia: new FormControl(),
      spotFileGuid: new FormControl(),
      groupSpotId: new FormControl()
    });

    this.spotSelectedServicesRequest.controls['applyToAllMedia'].setValue(this.applyAllMode);
    this.spotSelectedServicesRequest.controls['spotFileGuid'].setValue(this.spotFileGuid);
    this.spotSelectedServicesRequest.controls['groupSpotId'].setValue(this.groupSpotId);
  }

  private rearrangeOrderServices(services: Array<ServiceData>): Array<ServiceData> {

    console.log(services);

    services.forEach(a => {

      var s = this.addlServicesDataSource.find(t => t.id === a.serviceId);
      if (s != null) {
        //a.service.isSpecialEdit = s.isSpecialEdit;
        a.service.isOrderSpecialEdit = s.isOrderSpecialEdit;
        a.service.isGroupSpecialEdit = s.isGroupSpecialEdit;
        a.service.isSpotSpecialEdit = s.isSpotSpecialEdit;
        a.service.isSpecialOptionEdit = s.isSpecialOptionEdit;
      }
      else {
        var s = this.deliveryServicesDataSource.find(t => t.id === a.serviceId);
        if (s != null) {
          //a.service.isSpecialEdit = s.isSpecialEdit;
          a.service.isOrderSpecialEdit = s.isOrderSpecialEdit;
          a.service.isGroupSpecialEdit = s.isGroupSpecialEdit;
          a.service.isSpotSpecialEdit = s.isSpotSpecialEdit;
          a.service.isSpecialOptionEdit = s.isSpecialOptionEdit;
        }
        else {
          var s = this.ppServicesDataSource.find(t => t.id === a.serviceId);
          if (s != null) {
            //a.service.isSpecialEdit = s.isSpecialEdit;
            a.service.isOrderSpecialEdit = s.isOrderSpecialEdit;
            a.service.isGroupSpecialEdit = s.isGroupSpecialEdit;
            a.service.isSpotSpecialEdit = s.isSpotSpecialEdit;
            a.service.isSpecialOptionEdit = s.isSpecialOptionEdit;
          }
        }
      }

      if (this.orderLevel == true && this.specialServiceMode === true && (a.service.isOrderSpecialEdit === true || a.service.isSpecialOptionEdit === true))
        a.displaySequence = -3;
      else if (this.groupLevel == true && this.specialServiceMode === true && (a.service.isGroupSpecialEdit === true || a.service.isSpecialOptionEdit === true))
        a.displaySequence = -3;
      else if (this.spotLevel == true && this.specialServiceMode === true && (a.service.isSpotSpecialEdit === true || a.service.isSpecialOptionEdit === true))
        a.displaySequence = -3;
      else if (a.recentlyAdded === true && (a.parentServices == null || a.parentServices.length == 0) && a.service.isAdditionalDataNeeded === true)
        a.displaySequence = -2;
      else if (a.recentlyAdded === false && (a.parentServices == null || a.parentServices.length == 0) && a.service.isAdditionalDataNeeded === true)
        a.displaySequence = -1;
      else if (a.recentlyAdded === true && (a.parentServices != null && a.parentServices.length > 0) && a.service.isAdditionalDataNeeded === true)
        a.displaySequence = 0;
      else if (a.recentlyAdded === false && (a.parentServices != null && a.parentServices.length > 0) && a.service.isAdditionalDataNeeded === true)
        a.displaySequence = 1;
      else if (a.recentlyAdded === true && a.service.isAdditionalDataNeeded == true)
        a.displaySequence = 2;
      else if (a.recentlyAdded === false && a.service.isAdditionalDataNeeded == true)
        a.displaySequence = 3;
      else
        a.displaySequence = 4;
    });

    return services.sort((a, b): number => a.displaySequence - b.displaySequence);
  }

  private getServices() {
    this.configService.getServiceCategories().subscribe((res: any) => {
      if (res.isSuccess == true) {

        var allServices = res.result;
        console.log(allServices);

        if (this.orderLevel == true) {
          this.addlServicesDataSource = (allServices.addlServices as Array<ServiceType>).filter(t => t.isOrderLevel == true);
          this.deliveryServicesDataSource = (allServices.deliveryServices as Array<ServiceType>).filter(t => t.isOrderLevel == true);
          this.ppServicesDataSource = (allServices.postProductionServices as Array<ServiceType>).filter(t => t.isOrderLevel == true);

          this.addlServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isOrderLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.deliveryServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isOrderLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.ppServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isOrderLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });
        }
        else if (this.groupLevel == true) {
          this.addlServicesDataSource = (allServices.addlServices as Array<ServiceType>).filter(t => t.isGroupLevel == true);
          this.deliveryServicesDataSource = (allServices.deliveryServices as Array<ServiceType>).filter(t => t.isGroupLevel == true);
          this.ppServicesDataSource = (allServices.postProductionServices as Array<ServiceType>).filter(t => t.isGroupLevel == true);

          this.addlServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isGroupLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.deliveryServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isGroupLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.ppServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isGroupLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });
        }
        else {
          this.addlServicesDataSource = (allServices.addlServices as Array<ServiceType>).filter(t => t.isSpotLevel == true);
          this.deliveryServicesDataSource = (allServices.deliveryServices as Array<ServiceType>).filter(t => t.isSpotLevel == true);
          this.ppServicesDataSource = (allServices.postProductionServices as Array<ServiceType>).filter(t => t.isSpotLevel == true);

          this.addlServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isSpotLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.deliveryServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isSpotLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });

          this.ppServicesDataSource.forEach(serv => {
            serv.isAdditionalDataNeeded = (serv.serviceConfig.options.find(t => t.isSpotLevel == true && (t.isAuthRequired == false || t.isAuthRequired == this.additionalMetadata.allowSpecialOptions)) != null);
          });
        }

        this.deliveryServicesDataSource.forEach(t => {
          t.icon = this.TYLIEARROW;
        });

        this.ppServicesDataSource.forEach(t => {
          t.icon = this.TYLIEARROW;
        });

        this.addlServicesDataSource.forEach(t => {
          t.icon = this.TYLIEARROW;
        });

        this.spotSelectedServicesRequest.controls['ppServices'].setValue(this.ppServicesValue);
        this.spotSelectedServicesRequest.controls['addlServices'].setValue(this.addlServicesValue);
        this.spotSelectedServicesRequest.controls['deliveryServices'].setValue(this.deliveryServicesValue);

        if (this.editMode == true) {
          this.buildServiceDependency();
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onAddNewService() {
    this.editMode = false;
    this.additionalDataMode = false;
    this.editModeWithUpdateService = true;

    //this.buildServiceDependency();
  }

  public syncTreeViewSelection(type, e = null) {

    switch (type) {

      case "delivery": {

        var component = (e && e.component) || (this.treeDelivery && this.treeDelivery.instance);

        if (!component) return;

        if (this.deliveryServicesValue == null || !this.deliveryServicesValue.length) {
          component.unselectAll();
        }

        if (this.deliveryServicesValue != null) {
          this.deliveryServicesValue.forEach((function (value) {
            component.selectItem(value);
          }).bind(this));
        }

        //e.element.find(".myClass").parent().find(".dx-checkbox").dxCheckBox('instance').option("disabled", true);
        //e.element.find(".myClass").find(".dx-checkbox").dxCheckBox('instance').option("readOnly", true);

        break;
      }
      case "addl": {

        var component = (e && e.component) || (this.treeAddl && this.treeAddl.instance);

        if (!component) return;

        if (this.addlServicesValue == null || !this.addlServicesValue.length) {
          component.unselectAll();
        }

        if (this.addlServicesValue != null) {
          this.addlServicesValue.forEach((function (value) {
            component.selectItem(value);
          }).bind(this));
        }

        break;
      }
      case "pp": {

        var component = (e && e.component) || (this.treePp && this.treePp.instance);

        if (!component) return;

        if (this.ppServicesValue == null || !this.ppServicesValue.length) {
          component.unselectAll();
        }

        if (this.ppServicesValue != null) {
          this.ppServicesValue.forEach((function (value) {
            component.selectItem(value);
          }).bind(this));
        }

        break;
      }
    }
  }

  public treeView_itemSelectionChanged(e, type) {

    switch (type) {
      case "delivery":
        {
          this.deliveryServicesValue = e.component.getSelectedNodesKeys();

          break;
        }
      case "addl":
        {
          this.addlServicesValue = e.component.getSelectedNodesKeys();

          break;
        }
      case "pp":
        {
          this.ppServicesValue = e.component.getSelectedNodesKeys();

          var oldServices = e.component.getSelectedNodesKeys() as Array<number>;

          oldServices.forEach(id => {
            var serviceToCheck = this.ppServicesDataSource.find(t => t.id == id);

            if (serviceToCheck != null
              && serviceToCheck.requiredDependentServices != null
              && serviceToCheck.requiredDependentServices.length > 0) {
              serviceToCheck.requiredDependentServices.forEach(childSer => {
                var servDep = this.serviceDependency.find(t => t.serviceId == childSer)
                if (servDep == null) {
                  this.serviceDependency.push({ serviceId: childSer, parentServices: [id] });
                }
                else {
                  if (servDep.parentServices.find(t => t == id) == null)
                    servDep.parentServices.push(id);
                }
              });
            }
          });

          let servicesToRemove: Array<number> = [];

          this.serviceDependency.forEach(ser => {
            if (oldServices.find(t => t == ser.serviceId) == null)
              oldServices.push(ser.serviceId);

            var anyParentExists = false;

            ser.parentServices.forEach(p => {
              if (oldServices.find(t => t == p) != null)
                anyParentExists = true;
            });

            if (anyParentExists == false)
              servicesToRemove.push(ser.serviceId);
          });

          servicesToRemove.forEach(sa => {
            this.serviceDependency = this.serviceDependency.filter(s => s.serviceId != sa);
            oldServices = oldServices.filter(s => s != sa);
          });

          this.ppServicesValue = oldServices;

          this.cdr.detectChanges();

          //var component =  (this.treePp && this.treePp.instance);
          //if (this.ppServicesValue != null && component != null) {
          //  this.ppServicesValue.forEach((function (value) {
          //    component.selectItem(value);
          //  }).bind(this));
          //}

          break;
        }
    }
  }

  public onSaveSelectServices({ value, valid }: { value: SpotSelectedServicesRequest, valid: boolean }) {
    value.serviceData = [];
    value.ppFileSpecs = [];

    let ppFileSuffix: string = '';
    this.servicesOptional = [];

    this.applyAllMode = value.applyToAllMedia;

    if (value.deliveryServices != null && value.deliveryServices != undefined) {
      value.deliveryServices.forEach(sId => {
        var sdata = this.serviceData.find(t => t.service.id == sId);

        if (sdata == null) {
          var serv = this.deliveryServicesDataSource.find(t => t.id === sId);

          if (serv != null)
            value.serviceData.push(this.toServiceData(serv, []));
        }
        else {
          sdata.recentlyAdded = false;
          value.serviceData.push(sdata);
        }
      });
    }

    if (value.addlServices != null && value.addlServices != undefined) {
      value.addlServices.forEach(sId => {
        var sdata = this.serviceData.find(t => t.service.id == sId);

        if (sdata == null) {
          var serv = this.addlServicesDataSource.find(t => t.id === sId);

          if (serv != null)
            value.serviceData.push(this.toServiceData(serv, []));
        }
        else {
          sdata.recentlyAdded = false;
          value.serviceData.push(sdata);
        }
      });
    }

    if (value.ppServices != null && value.ppServices != undefined) {
      value.ppServices.forEach(sId => {
        var sdata = this.serviceData.find(t => t.service.id == sId);

        if (sdata == null) {
          var serv = this.ppServicesDataSource.find(t => t.id === sId);

          if (serv != null) {
            ppFileSuffix = ppFileSuffix.concat(serv.titleSuffix);

            var servDep = this.serviceDependency.find(t => t.serviceId == sId);

            if (servDep == null)
              value.serviceData.push(this.toServiceData(serv, []));
            else
              value.serviceData.push(this.toServiceData(serv, servDep.parentServices));
          }
        }
        else {
          sdata.recentlyAdded = false;
          var servDep = this.serviceDependency.find(t => t.serviceId == sId);

          if (servDep != null && servDep != undefined) {
            sdata.parentServices = servDep.parentServices;
          }

          value.serviceData.push(sdata);
        }
      });


      value.ppServices.forEach(id => {
        var serviceToCheck = this.ppServicesDataSource.find(t => t.id == id);

        if (serviceToCheck != null && serviceToCheck.optionalDependentServices != null && serviceToCheck.optionalDependentServices.length > 0) {
          serviceToCheck.optionalDependentServices.forEach(optChildS => {

            if (value.ppServices.find(t => t == optChildS) == null) {
              var optChildService = this.ppServicesDataSource.find(t => t.id == optChildS);

              if (optChildService != null) {
                var optService = this.servicesOptional.find(t => t.serviceId == optChildService.id);

                if (optService == null) {
                  this.servicesOptional.push(this.toServiceData(optChildService, []));
                }
              }
            }
          });
        }
      });
    }

    var servicesNotCompatible: Array<number> = [];
    value.serviceData.forEach(sdata => {
      var isServiceValid: boolean = false;
      if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0) {
        this.additionalMetadata.ppFileSpecs.forEach(spec => {
          if (sdata.service.isAudio == true && spec.isAudio == true)
            isServiceValid = true;
          else if (sdata.service.isVideo == true && spec.isAudio == false)
            isServiceValid = true;
          else if (sdata.service.isAudio == false && sdata.service.isVideo == false)
            isServiceValid = true;
        });
      }
      else
        isServiceValid = true;

      if (isServiceValid == false)
        servicesNotCompatible.push(sdata.serviceId);
    });

    if (value.applyToAllMedia == true) {
      this.additionalMetadata.ppFileSpecs = [];
      value.ppFileSpecs = [];
    }
    else {
      if (this.additionalMetadata.ppFileSpecs == null)
        this.additionalMetadata.ppFileSpecs = [];

      this.additionalMetadata.ppFileSpecs.forEach(spec => {
        spec.ppFileTitle = spec.ppFileTitle != '' ? spec.ppFileTitle.concat(ppFileSuffix) : '';
      });

      value.ppFileSpecs = this.additionalMetadata.ppFileSpecs;
    }

    var isAdditionalDataNeeded = (value.serviceData.find(t => t.service.isAdditionalDataNeeded === true && t.recentlyAdded === true && servicesNotCompatible.findIndex(s => s == t.serviceId) < 0) != null);

    this.onSelectServicesComplete.next(value);

    console.log("services for options", value.serviceData);

    if (isAdditionalDataNeeded == true) {
      this.servicesForAdditionalData = this.rearrangeOrderServices(value.serviceData.filter(t => servicesNotCompatible.findIndex(s => s == t.serviceId) < 0));

      console.log("services for options sorted", this.servicesForAdditionalData);

      this.additionalDataMode = true;
      this.title = 'Services Next Steps';
    }
    else
      this.closePopup();
  }

  public onServiceSubmitOptions(request: ServiceOptionsRequest) {

    console.log("options form received", request);

    console.log("services for options", this.servicesForAdditionalData);

    let spotServicesOptionsRequest: SpotServicesOptionsRequest = {
      spotFileGuid: this.spotFileGuid,
      groupSpotId: this.groupSpotId,
      applyToAllMedia: this.applyAllMode,
      serviceData: [],
      deletedServices: [],
      addedServices: [],
      //ppFileSpecs: [{ spotFileGuid: this.spotFileGuid, id: this.id, adId: '', title: '', ppFileAdId: '', ppFileTitle: '', isAudio: false }]
      ppFileSpecs: this.additionalMetadata.ppFileSpecs
    };

    this.servicesForAdditionalData.forEach(sdata => {

      let newSdata: ServiceData = {
        service: sdata.service,
        serviceMetadata: [],
        recentlyAdded: false,
        serviceId: sdata.service.id,
        containsServiceMetadataDataErrors: false,
        errorMsg: '',
        optionsSubmitted: false,
        parentServices: [],
        displaySequence: 0
      };

      if (sdata.service.code.toLowerCase() == 'cap' && request.cap != null) {

        if (request.cap.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.cap.scriptFile != null) {
            request.cap.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.cap.scriptText != null && request.cap.scriptText != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.cap.scriptText, display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'cci' && request.cci != null) {

        if (request.cci.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.cci.scriptFile != null) {
            request.cci.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.cci.scriptText != null && request.cci.scriptText != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.cci.scriptText, display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'dvs' && request.dvs != null) {

        if (request.dvs.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.dvs.scriptFile != null) {
            request.dvs.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.dvs.scriptText != null && request.dvs.scriptText.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.dvs.scriptText.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });

          if (request.dvs.voice != null && request.dvs.voice.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'voice', textValue: request.dvs.voice.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'reappdvs' && request.reappdvs != null) {

        if (request.reappdvs.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.reappdvs.notes != null && request.reappdvs.notes != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'notes', textValue: request.reappdvs.notes, display: this.getDisplayOfMetadataKey(sdata.service, 'notes', true) + request.reappdvs.notes });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'reappcap' && request.reappcap != null) {

        if (request.reappcap.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.reappcap.notes != null && request.reappcap.notes != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'notes', textValue: request.reappcap.notes, display: this.getDisplayOfMetadataKey(sdata.service, 'notes', true) + request.reappcap.notes });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ded' && request.ded != null) {

        if (request.ded.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.ded.title != null && request.ded.title.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'title', textValue: request.ded.title.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileTitle = request.ded.title.trim(); });
            //spotServicesOptionsRequest.ppFileTitle = request.ded.title.trim();
          }

          if (request.ded.editNotes != null && request.ded.editNotes != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'editNotes', textValue: request.ded.editNotes, display: this.getDisplayOfMetadataKey(sdata.service, 'editNotes', true) + request.ded.editNotes });

          if (request.ded.totalMins != null && request.ded.totalMins != '' && request.ded.totalMins != '0')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'totalMins', textValue: request.ded.totalMins.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'stc' && request.stc != null) {

        if (request.stc.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.stc.title != null && request.stc.title.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'title', textValue: request.stc.title.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileTitle = request.stc.title.trim(); });
            //spotServicesOptionsRequest.ppFileTitle = request.stc.title.trim();
          }

          if (request.stc.standard != null && request.stc.standard != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'standard', textValue: request.stc.standard, display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'sub' && request.sub != null) {

        if (request.sub.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.sub.scriptFile != null) {
            request.sub.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.sub.title != null && request.sub.title.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'title', textValue: request.sub.title.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileTitle = request.sub.title.trim(); });
            //spotServicesOptionsRequest.ppFileTitle = request.sub.title.trim();
          }

          if (request.sub.scriptText != null && request.sub.scriptText.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.sub.scriptText.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tag' && request.tag != null) {

        if (request.tag.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.tag.phone != null && request.tag.phone.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'phone', textValue: request.tag.phone.trim(), display: '' });

          if (request.tag.url != null && request.tag.url.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'url', textValue: request.tag.url.trim(), display: '' });

          if (request.tag.voiceOver != null && request.tag.voiceOver.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'voiceOver', textValue: request.tag.voiceOver.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'voiceOver', true) + request.tag.voiceOver.trim() });

          if (request.tag.otherInstructions != null && request.tag.otherInstructions.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'otherInstructions', textValue: request.tag.otherInstructions.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'otherInstructions', true) + request.tag.otherInstructions.trim() });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tbi' && request.tbi != null) {

        if (request.tbi.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.tbi.thumbnailFile != null) {
            request.tbi.thumbnailFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'thumbnailFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'thumbnailFile', true) + t.fileName });
            });
          }

          if (request.tbi.timecode != null && request.tbi.timecode.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigMultiple], fileValue: null, objectValue: '', arrayValue: request.tbi.timecode.join(','), metadataKey: 'timecode', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'timecode', true) + request.tbi.timecode.join(',') });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'trs' && request.trs != null) {

        if (request.trs.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.trs.fileType != null && request.trs.fileType.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.trs.fileType.join(','), metadataKey: 'fileType', textValue: '', display: '' });

            if (request.trs.fileType.indexOf('other') >= 0) {
              if (request.trs.fileTypeCustom != null && request.trs.fileTypeCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'fileTypeCustom', textValue: request.trs.fileTypeCustom.trim(), display: '' });
            }
          }

          if (request.trs.language != null && request.trs.language.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.trs.language.join(','), metadataKey: 'language', textValue: '', display: '' });

            if (request.trs.language.indexOf('other') >= 0) {
              if (request.trs.languageCustom != null && request.trs.languageCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'languageCustom', textValue: request.trs.languageCustom.trim(), display: '' });
            }
          }

          if (request.trs.timecodeNeeded != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'timecodeNeeded', textValue: request.trs.timecodeNeeded != null ? request.trs.timecodeNeeded.toString() : '', display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'itrs' && request.itrs != null) {

        if (request.itrs.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.itrs.fileType != null && request.itrs.fileType.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.itrs.fileType.join(','), metadataKey: 'fileType', textValue: '', display: '' });

            if (request.itrs.fileType.indexOf('other') >= 0) {
              if (request.itrs.fileTypeCustom != null && request.itrs.fileTypeCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'fileTypeCustom', textValue: request.itrs.fileTypeCustom.trim(), display: '' });
            }
          }

          if (request.itrs.language != null && request.itrs.language.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.itrs.language.join(','), metadataKey: 'language', textValue: '', display: '' });

            if (request.itrs.language.indexOf('other') >= 0) {
              if (request.itrs.languageCustom != null && request.trs.languageCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'languageCustom', textValue: request.itrs.languageCustom.trim(), display: '' });
            }
          }

          if (request.itrs.timecodeNeeded != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'timecodeNeeded', textValue: request.itrs.timecodeNeeded != null ? request.itrs.timecodeNeeded.toString() : '', display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'wbv' && request.wbv != null) {

        if (request.wbv.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.wbv.bvsForm != null) {
            request.wbv.bvsForm.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'bvsForm', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'bvsForm', true) + t.fileName });
            });
          }

          if (request.wbv.bvsSubmitted != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'bvsSubmitted', textValue: request.wbv.bvsSubmitted != null ? request.wbv.bvsSubmitted.toString() : '', display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'vco' && request.vco != null) {

        if (request.vco.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.vco.voice != null && request.vco.voice.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'voice', textValue: request.vco.voice.trim(), display: '' });

          if (request.vco.title != null && request.vco.title.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'title', textValue: request.vco.title.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileTitle = request.vco.title.trim(); });
            //spotServicesOptionsRequest.ppFileTitle = request.vco.title.trim();
          }

          if (request.vco.scriptFile != null) {
            request.vco.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.vco.totalMins != null && request.vco.totalMins != '' && request.vco.totalMins != '0')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'totalMins', textValue: request.vco.totalMins.toString(), display: '' });

          if (request.vco.scriptText != null && request.vco.scriptText.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.vco.scriptText.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });

          if (request.vco.language != null && request.vco.language.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.vco.language.join(','), metadataKey: 'language', textValue: '', display: '' });

            if (request.vco.language.indexOf('other') >= 0) {
              if (request.vco.languageCustom != null && request.vco.languageCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'languageCustom', textValue: request.vco.languageCustom.trim(), display: '' });
            }
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'wcp' && request.wcp != null) {

        if (request.wcp.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.wcp.scriptFile != null) {
            request.wcp.scriptFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'scriptFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'scriptFile', true) + t.fileName });
            });
          }

          if (request.wcp.scriptText != null && request.wcp.scriptText.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.CopyText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'scriptText', textValue: request.wcp.scriptText.trim(), display: this.getDisplayOfMetadataKey(sdata.service, 'scriptText', false) });

          if (request.wcp.fileType != null && request.wcp.fileType.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.wcp.fileType.join(','), metadataKey: 'fileType', textValue: '', display: '' });

            if (request.wcp.fileType.indexOf('other') >= 0) {
              if (request.wcp.fileTypeCustom != null && request.wcp.fileTypeCustom.trim() != '')
                newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'fileTypeCustom', textValue: request.wcp.fileTypeCustom.trim(), display: '' });
            }

          }

          if (request.wcp.approvalRequired != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'approvalRequired', textValue: request.wcp.approvalRequired != null ? request.wcp.approvalRequired.toString() : '', display: '' });

          console.log(newSdata);

        }
      }

      else if (sdata.service.code.toLowerCase() == 'wst' && request.wst != null) {

        if (request.wst.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.wst.clientId != null && request.wst.clientId.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'clientId', textValue: request.wst.clientId.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'slt' && request.slt != null) {
        if (request.slt.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.slt.isAdded === true) {
            var addedService = this.ppServicesDataSource.find(t => t.id == sdata.service.id);
            if (addedService != null) {
              spotServicesOptionsRequest.addedServices.push(this.toServiceData(addedService, request.slt.parentServices));
            }
          }

          if (request.slt.adid != null && request.slt.adid.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'adid', textValue: request.slt.adid.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileAdId = request.slt.adid.trim(); });
            //spotServicesOptionsRequest.ppFileAdId = request.slt.adid.trim();
          }

          if (request.slt.title != null && request.slt.title.trim() != '') {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'title', textValue: request.slt.title.trim(), display: '' });

            spotServicesOptionsRequest.ppFileSpecs.forEach(spec => { spec.ppFileTitle = request.slt.title.trim(); });
            //spotServicesOptionsRequest.ppFileTitle = request.slt.title.trim();
          }

          if (request.slt.option != null && request.slt.option.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'option', textValue: request.slt.option.trim(), display: '' });

          if (request.slt.removeSlate != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'removeSlate', textValue: request.slt.removeSlate != null ? request.slt.removeSlate.toString() : '', display: '' });

          if (request.slt.reslate != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'reslate', textValue: request.slt.reslate != null ? request.slt.reslate.toString() : '', display: '' });

          if (request.slt.keepClientSlate != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'keepClientSlate', textValue: request.slt.keepClientSlate != null ? request.slt.keepClientSlate.toString() : '', display: '' });

          if (request.slt.advertiser != null && request.slt.advertiser.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'advertiser', textValue: request.slt.advertiser.trim(), display: '' });

          if (request.slt.brand != null && request.slt.brand.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'brand', textValue: request.slt.brand.trim(), display: '' });

          if (request.slt.product != null && request.slt.product.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'product', textValue: request.slt.product.trim(), display: '' });

          if (request.slt.date != null)
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'date', textValue: moment(request.slt.date).format('LL'), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'hrd' && request.hrd != null) {
        if (request.hrd.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.hrd.quantity != null && request.hrd.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.hrd.quantity.toString(), display: '' });

          if (request.hrd.phone != null && request.hrd.phone.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'phone', textValue: request.hrd.phone.trim(), display: '' });

          if (request.hrd.email != null && request.hrd.email.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'email', textValue: request.hrd.email.trim(), display: '' });

          if (request.hrd.trackingNumber != null && request.hrd.trackingNumber.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'trackingNumber', textValue: request.hrd.trackingNumber, display: this.getDisplayOfMetadataKey(sdata.service, 'trackingNumber', true) + request.hrd.trackingNumber });

          if (request.hrd.address != null && request.hrd.address.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'address', textValue: request.hrd.address, display: this.getDisplayOfMetadataKey(sdata.service, 'address', true) + request.hrd.address });

        }
      }

      else if (sdata.service.code.toLowerCase() == 'exphandle' && request.exphandle != null) {
        if (request.exphandle.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.exphandle.quantity != null && request.exphandle.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.exphandle.quantity.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'btd' && request.btd != null) {
        if (request.btd.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.btd.phone != null && request.btd.phone.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'phone', textValue: request.btd.phone.trim(), display: '' });

          if (request.btd.email != null && request.btd.email.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'email', textValue: request.btd.email.trim(), display: '' });

          if (request.btd.trackingNumber != null && request.btd.trackingNumber.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'trackingNumber', textValue: request.btd.trackingNumber, display: this.getDisplayOfMetadataKey(sdata.service, 'trackingNumber', true) + request.btd.trackingNumber });

          if (request.btd.address != null && request.btd.address.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'address', textValue: request.btd.address, display: this.getDisplayOfMetadataKey(sdata.service, 'address', true) + request.btd.address });

        }
      }

      else if (sdata.service.code.toLowerCase() == 'hdf' && request.hdf != null) {
        if (request.hdf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.hdf.quantity != null && request.hdf.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.hdf.quantity.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ovt' && request.ovt != null) {
        if (request.ovt.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.ovt.overtime != null && request.ovt.overtime != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'overtime', textValue: request.ovt.overtime.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'icf' && request.icf != null) {
        if (request.icf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.icf.quantity != null && request.icf.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.icf.quantity.toString(), display: '' });

          if (request.icf.vendor != null && request.icf.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.icf.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.icf.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ioms' && request.ioms != null) {
        if (request.ioms.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.ioms.quantity != null && request.ioms.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.ioms.quantity.toString(), display: '' });

          //if (request.ioms.vendor != null && request.ioms.vendor.trim() != '')
          //  newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.ioms.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.ioms.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tfd' && request.tfd != null) {
        if (request.tfd.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.tfd.thumbflashdrivequantity != null && request.tfd.thumbflashdrivequantity.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.tfd.thumbflashdrivequantity.forEach(item => {
              value.push(item.val1 + '|' + item.val2);
              displayValue.push(item.val1 + ' - ' + item.val2);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultiple], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'thumbflashdrivequantity', textValue: '', display: '' });
          }

          if (request.tfd.phone != null && request.tfd.phone.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'phone', textValue: request.tfd.phone.trim(), display: '' });

          if (request.tfd.email != null && request.tfd.email.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'email', textValue: request.tfd.email.trim(), display: '' });

          if (request.tfd.trackingNumber != null && request.tfd.trackingNumber.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'trackingNumber', textValue: request.tfd.trackingNumber, display: this.getDisplayOfMetadataKey(sdata.service, 'trackingNumber', true) + request.tfd.trackingNumber });

          if (request.tfd.address != null && request.tfd.address.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'address', textValue: request.tfd.address, display: this.getDisplayOfMetadataKey(sdata.service, 'address', true) + request.tfd.address });

        }
      }

      else if (sdata.service.code.toLowerCase() == 'bcc' && request.bcc != null) {
        if (request.bcc.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bcc.comments != null && request.bcc.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.bcc.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.bcc.comments });

          if (request.bcc.vendor != null && request.bcc.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.bcc.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.bcc.vendor });

          if (request.bcc.clearancetype == null) {
            request.bcc.clearancetype = '';
          }

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'clearancetype', textValue: request.bcc.clearancetype, display: this.getDisplayOfMetadataKey(sdata.service, 'clearancetype', true) + request.bcc.clearancetype });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bcd' && request.bcd != null) {
        if (request.bcd.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.bcd.vendor != null && request.bcd.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.bcd.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.bcd.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bci' && request.bci != null) {
        if (request.bci.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bci.vendor != null && request.bci.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.bci.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.bci.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'vit' && request.vit != null) {

        if (request.vit.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.vit.fileType != null && request.vit.fileType.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'fileType', textValue: request.vit.fileType.trim(), display: '' });

          if (request.vit.codec != null && request.vit.codec.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'codec', textValue: request.vit.codec.trim(), display: '' });

          if (request.vit.bitrate != null && request.vit.bitrate != '' && request.vit.bitrate != '0') {
            request.vit.bitrate = (Number(request.vit.bitrate) * request.vit.bitratemultiplier).toString();
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'bitrate', textValue: request.vit.bitrate.toString(), display: '' });
          }

          if (request.vit.audiobitrate != null && request.vit.audiobitrate != '' && request.vit.audiobitrate != '0')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'audiobitrate', textValue: request.vit.audiobitrate.toString(), display: '' });

          if (request.vit.resolution != null && request.vit.resolution.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'resolution', textValue: request.vit.resolution.trim(), display: '' });

          if (request.vit.slate != null && request.vit.slate.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'slate', textValue: request.vit.slate.trim(), display: '' });

          if (request.vit.framerate != null && request.vit.framerate.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'framerate', textValue: request.vit.framerate.trim(), display: '' });

          if (request.vit.audiosamplerate != null && request.vit.audiosamplerate.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'audiosamplerate', textValue: request.vit.audiosamplerate.trim(), display: '' });

          if (request.vit.spec != null && request.vit.spec.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'spec', textValue: request.vit.spec.trim(), display: '' });

          if (request.vit.comments != null && request.vit.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.vit.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.vit.comments });

          if ((request.vit.customfilenamepos1 != null && request.vit.customfilenamepos1.trim() != '')
            || request.vit.customfilenamepos2 != null && request.vit.customfilenamepos2.trim() != ''
            || request.vit.customfilenamepos3 != null && request.vit.customfilenamepos3.trim() != '')
            newSdata.serviceMetadata.push({
              category: metadataCategoryEnum[metadataCategoryEnum.CustomFileName], fileValue: null, objectValue: '', arrayValue: '',
              metadataKey: 'customfilename', textValue: [request.vit.customfilenamepos1, request.vit.customfilenamepos2, request.vit.customfilenamepos3].filter(Boolean).join("|"), display: ''
            });

          if (request.vit.customfilenamepos1 != null && request.vit.customfilenamepos1.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos1', textValue: request.vit.customfilenamepos1.trim(), display: '' });

          if (request.vit.customfilenamepos2 != null && request.vit.customfilenamepos2.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos2', textValue: request.vit.customfilenamepos2.trim(), display: '' });

          if (request.vit.customfilenamepos3 != null && request.vit.customfilenamepos3.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos3', textValue: request.vit.customfilenamepos3.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ins' && request.ins != null) {
        if (request.ins.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.ins.noOfIns != null && request.ins.noOfIns != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'noOfIns', textValue: request.ins.noOfIns.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bcf' && request.bcf != null) {
        if (request.bcf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bcf.totalMins != null && request.bcf.totalMins != '' && request.bcf.totalMins != '0')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'totalMins', textValue: request.bcf.totalMins.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'vla' && request.vla != null) {
        if (request.vla.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.vla.comments != null && request.vla.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.vla.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.vla.comments });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'arp' && request.arp != null) {
        if (request.arp.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.arp.comments != null && request.arp.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.arp.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.arp.comments });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'aea' && request.aea != null) {
        if (request.aea.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.aea.comments != null && request.aea.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.aea.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.aea.comments });

          if (request.aea.adjustamount != null && request.aea.adjustamount != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Dollar], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'adjustamount', textValue: request.aea.adjustamount.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bagp' && request.bagp != null) {
        console.log(request.bagp);
        if (request.bagp.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          console.log(request.bagp);
          if (request.bagp.passthroughdata != null && request.bagp.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.bagp.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3 + '|' + item.val4);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3 + ' - ' + item.val4);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
          console.log(newSdata.serviceMetadata);
          if (request.bagp.comments != null && request.bagp.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.bagp.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.bagp.comments });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bahf' && request.bahf != null) {
        if (request.bahf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bahf.comments != null && request.bahf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.bahf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.bahf.comments });

          if (request.bahf.passthroughdata != null && request.bahf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.bahf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'klhsf' && request.klhsf != null) {
        if (request.klhsf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.klhsf.comments != null && request.klhsf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.klhsf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.klhsf.comments });

          if (request.klhsf.passthroughdata != null && request.klhsf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.klhsf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'trfs' && request.trfs != null) {
        if (request.trfs.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.trfs.comments != null && request.trfs.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.trfs.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.trfs.comments });

          if (request.trfs.passthroughdata != null && request.trfs.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.trfs.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'balf' && request.balf != null) {
        if (request.balf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.balf.comments != null && request.balf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.balf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.balf.comments });

          if (request.balf.passthroughdata != null && request.balf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.balf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'baoth' && request.baoth != null) {
        if (request.baoth.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.baoth.comments != null && request.baoth.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.baoth.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.baoth.comments });

          if (request.baoth.passthroughdata != null && request.baoth.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.baoth.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'pasf' && request.pasf != null) {
        if (request.pasf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.pasf.comments != null && request.pasf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.pasf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.pasf.comments });

          if (request.pasf.passthroughdata != null && request.pasf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.pasf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'batm' && request.batm != null) {
        if (request.batm.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.batm.comments != null && request.batm.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.batm.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.batm.comments });

          if (request.batm.passthroughdata != null && request.batm.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.batm.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bactf' && request.bactf != null) {
        if (request.bactf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bactf.comments != null && request.bactf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.bactf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.bactf.comments });

          if (request.bactf.passthroughdata != null && request.bactf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.bactf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'trfdctf' && request.trfdctf != null) {
        if (request.trfdctf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.trfdctf.comments != null && request.trfdctf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.trfdctf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.trfdctf.comments });

          if (request.trfdctf.passthroughdata != null && request.trfdctf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.trfdctf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'cpp' && request.cpp != null) {
        if (request.cpp.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.cpp.comments != null && request.cpp.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.cpp.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.cpp.comments });

          if (request.cpp.passthroughdata != null && request.cpp.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.cpp.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'sfoth' && request.sfoth != null) {
        if (request.sfoth.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.sfoth.comments != null && request.sfoth.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.sfoth.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.sfoth.comments });

          if (request.sfoth.passthroughdata != null && request.sfoth.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.sfoth.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tpp' && request.tpp != null) {
        if (request.tpp.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.tpp.comments != null && request.tpp.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.tpp.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.tpp.comments });

          if (request.tpp.passthroughdata != null && request.tpp.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.tpp.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'pmf' && request.pmf != null) {
        if (request.pmf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.pmf.comments != null && request.pmf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.pmf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.pmf.comments });

          if (request.pmf.passthroughdata != null && request.pmf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.pmf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'dist' && request.dist != null) {
        if (request.dist.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.dist.comments != null && request.dist.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.dist.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.dist.comments });

          if (request.dist.passthroughdata != null && request.dist.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.dist.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'distsf' && request.distsf != null) {
        if (request.distsf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.distsf.comments != null && request.distsf.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.distsf.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.distsf.comments });

          if (request.distsf.passthroughdata != null && request.distsf.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.distsf.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'distsoth' && request.distsoth != null) {
        if (request.distsoth.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.distsoth.comments != null && request.distsoth.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.distsoth.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.distsoth.comments });

          if (request.distsoth.passthroughdata != null && request.distsoth.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.distsoth.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'mup' && request.mup != null) {
        if (request.mup.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.mup.comments != null && request.mup.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.mup.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.mup.comments });

          if (request.mup.passthroughdata != null && request.mup.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.mup.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'othinc' && request.othinc != null) {
        if (request.othinc.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.othinc.comments != null && request.othinc.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.othinc.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.othinc.comments });

          if (request.othinc.passthroughdata != null && request.othinc.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.othinc.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'csdev' && request.csdev != null) {
        if (request.csdev.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.csdev.comments != null && request.csdev.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.csdev.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.csdev.comments });

          if (request.csdev.passthroughdata != null && request.csdev.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.csdev.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'gac' && request.gac != null) {
        if (request.gac.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.gac.comments != null && request.gac.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.gac.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.gac.comments });

          if (request.gac.passthroughdata != null && request.gac.passthroughdata.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.gac.passthroughdata.forEach(item => {
              value.push(item.val1 + '|' + item.val2 + '|' + item.val3);
              displayValue.push(item.val1 + ' - ' + item.val2 + ' - ' + item.val3);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'passthroughdata', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'dtr' && request.dtr != null) {

        if (request.dtr.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          //if (request.dtr.mins != null && request.dtr.mins != '' && request.dtr.mins != '0')
          //  newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'mins', textValue: request.dtr.mins.toString(), display: '' });

          if (request.dtr.minstransfers != null && request.dtr.minstransfers.length > 0) {

            var value: Array<string> = [];
            var displayValue: Array<string> = [];

            request.dtr.minstransfers.forEach(item => {
              value.push(item.val1 + '|' + item.val2);
              displayValue.push(item.val1 + ' - ' + item.val2);
            });

            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleMinsTransfers], fileValue: null, objectValue: value.join('||'), arrayValue: '', metadataKey: 'minstransfers', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'sen' && request.sen != null) {
        if (request.sen.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.sen.notificationText != null && request.sen.notificationText.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'notificationText', textValue: request.sen.notificationText, display: this.getDisplayOfMetadataKey(sdata.service, 'notificationText', true) + request.sen.notificationText });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'trf' && request.trf != null) {

        if (request.trf.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.trf.trafficFile != null) {
            request.trf.trafficFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'trafficFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'trafficFile', true) + t.fileName });
            });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bms' && request.bms != null) {

        if (request.bms.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bms.billboardFile != null) {
            request.bms.billboardFile.forEach(t => {
              newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.File], fileValue: t, objectValue: '', arrayValue: '', metadataKey: 'billboardFile', textValue: '', display: this.getDisplayOfMetadataKey(sdata.service, 'billboardFile', true) + t.fileName });
            });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tar' && request.tar != null) {

        if (request.tar.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.tar.format != null && request.tar.format.length > 0) {
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Multiple], fileValue: null, objectValue: '', arrayValue: request.tar.format.join(','), metadataKey: 'format', textValue: '', display: '' });
          }
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ait' && request.ait != null) {

        if (request.ait.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.ait.spec != null && request.ait.spec.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'spec', textValue: request.ait.spec.trim(), display: '' });

          if (request.ait.comments != null && request.ait.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.ait.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.ait.comments });

          if ((request.ait.customfilenamepos1 != null && request.ait.customfilenamepos1.trim() != '')
            || request.ait.customfilenamepos2 != null && request.ait.customfilenamepos2.trim() != ''
            || request.ait.customfilenamepos3 != null && request.ait.customfilenamepos3.trim() != '')
            newSdata.serviceMetadata.push({
              category: metadataCategoryEnum[metadataCategoryEnum.CustomFileName], fileValue: null, objectValue: '', arrayValue: '',
              metadataKey: 'customfilename', textValue: [request.ait.customfilenamepos1, request.ait.customfilenamepos2, request.ait.customfilenamepos3].filter(Boolean).join("|"), display: ''
            });

          if (request.ait.customfilenamepos1 != null && request.ait.customfilenamepos1.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos1', textValue: request.ait.customfilenamepos1.trim(), display: '' });

          if (request.ait.customfilenamepos2 != null && request.ait.customfilenamepos2.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos2', textValue: request.ait.customfilenamepos2.trim(), display: '' });

          if (request.ait.customfilenamepos3 != null && request.ait.customfilenamepos3.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.NA], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'customfilenamepos3', textValue: request.ait.customfilenamepos3.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'iti' && request.iti != null) {
        if (request.iti.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.iti.vendor != null && request.iti.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.iti.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.iti.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ddi' && request.ddi != null) {

        if (request.ddi.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.ddi.referenceId != null && request.ddi.referenceId.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'referenceId', textValue: request.ddi.referenceId.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ddai' && request.ddai != null) {

        if (request.ddai.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.ddai.referenceId != null && request.ddai.referenceId.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'referenceId', textValue: request.ddai.referenceId.trim(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'tmgt' && request.tmgt != null) {

        if (request.tmgt.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.tmgt.totalMins != null && request.tmgt.totalMins != '' && request.tmgt.totalMins != '0')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.HoursMins], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'totalMins', textValue: request.tmgt.totalMins.toString(), display: '' });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bca' && request.bca != null) {
        if (request.bca.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bca.quantity != null && request.bca.quantity != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'quantity', textValue: request.bca.quantity.toString(), display: '' });


          if (request.bca.vendor != null && request.bca.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.bca.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.bca.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'ccl' && request.ccl != null) {
        if (request.ccl.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {

          if (request.ccl.vendor != null && request.ccl.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.ccl.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.ccl.vendor });
        }
      }

      else if (sdata.service.code.toLowerCase() == 'bc3p' && request.bc3p != null) {
        if (request.bc3p.isDeleted === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
        else {
          if (request.bc3p.comments != null && request.bc3p.comments.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.BigText], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'comments', textValue: request.bc3p.comments, display: this.getDisplayOfMetadataKey(sdata.service, 'comments', true) + request.bc3p.comments });

          if (request.bc3p.adjustamount != null && request.bc3p.adjustamount != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Dollar], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'adjustamount', textValue: request.bc3p.adjustamount.toString(), display: '' });

          if (request.bc3p.vendor != null && request.bc3p.vendor.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Special], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'vendor', textValue: request.bc3p.vendor, display: this.getDisplayOfMetadataKey(sdata.service, 'vendor', true) + request.bc3p.vendor });

          if (request.bc3p.servicelevel != null && request.bc3p.servicelevel.trim() != '')
            newSdata.serviceMetadata.push({ category: metadataCategoryEnum[metadataCategoryEnum.Text], fileValue: null, objectValue: '', arrayValue: '', metadataKey: 'servicelevel', textValue: request.bc3p.servicelevel.trim(), display: '' });
        }
      }

      else {
        var req = request[sdata.service.code.toLowerCase()];

        if (req != null && req["isDeleted"] != null && req["isDeleted"] === true)
          spotServicesOptionsRequest.deletedServices.push(sdata.service.id);
      }

      spotServicesOptionsRequest.serviceData.push(newSdata);
    });

    console.log("raising", spotServicesOptionsRequest);

    this.onServiceSubmitOptionsComplete.next(spotServicesOptionsRequest);

    this.closePopup();
  }

  public onItemRendered(type, e) {

    if (this.specialServiceMode === true) {
      if ((e.itemData.isOrderSpecialEdit == false && this.orderLevel == true)
        || (e.itemData.isGroupSpecialEdit == false && this.groupLevel == true)
        || (e.itemData.isSpotSpecialEdit == false && this.spotLevel == true))
        e.itemElement.parent().addClass("dx-state-disabled");
      else
        e.itemElement.parent().addClass("dx-checkbox-special");
    }
  }

  private toServiceData(servItem: ServiceType, parentServices: Array<number>): ServiceData {

    let serviceData: ServiceData = {
      service: servItem,
      serviceMetadata: [],
      recentlyAdded: true,
      serviceId: servItem.id,
      containsServiceMetadataDataErrors: false,
      errorMsg: '',
      optionsSubmitted: false,
      parentServices: parentServices,
      displaySequence: 0
    };

    return serviceData;
  }

  private buildServiceDependency(): void {
    this.serviceDependency = [];

    this.servicesForAdditionalData = this.rearrangeOrderServices(this.serviceData);

    this.servicesForAdditionalData.forEach(sd => {

      var id = sd.serviceId;

      var serviceToCheck = this.ppServicesDataSource.find(t => t.id == id);

      if (serviceToCheck != null
        && serviceToCheck.requiredDependentServices != null
        && serviceToCheck.requiredDependentServices.length > 0) {
        serviceToCheck.requiredDependentServices.forEach(childSer => {
          var servDep = this.serviceDependency.find(t => t.serviceId == childSer)
          if (servDep == null) {
            this.serviceDependency.push({ serviceId: childSer, parentServices: [id] });
          }
          else {
            if (servDep.parentServices.find(t => t == id) == null)
              servDep.parentServices.push(id);
          }
        });
      }
    });

    console.log("buildServiceDependency", this.serviceDependency);

    this.servicesForAdditionalData.forEach(sd => {
      var servDep = this.serviceDependency.find(t => t.serviceId == sd.serviceId);

      if (servDep != null)
        sd.parentServices = servDep.parentServices;
    });

    console.log("services for options in edit sorted", this.servicesForAdditionalData);

    this.additionalDataMode = true;

    this.editModeWithUpdateService = false;
  }

  private getDisplayOfMetadataKey(service: ServiceType, mKey: string, colonRequired: boolean): string {
    if (service.isAdditionalDataNeeded && service.serviceConfig != null && service.serviceConfig.options != null) {
      var mSet = service.serviceConfig.options.find(t => t.tag.toLowerCase() == mKey.toLowerCase())
      if (mSet != null)
        return mSet.displayLabel + (colonRequired ? ' : ' : '');
    }

    return '';
  }
}
