<div class="tylie-accordion accordion" id="search-all-media-section">
  <div class="tylie-accordion__panel accordion-item" id="mediaSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#projectSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH ALL PROJECTS
      </button>
    </h2>


    <div id="projectSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Project Name" name="srch-term" id="srch-term"
                    type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="clearQuickSearch()"
                    (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>

        <div class="tylie-section collapse" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong></div>
          <form novalidate (ngSubmit)="onAdvancedSearch()" [formGroup]="searchRequest" role="search">
            <div class="row">
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="adID">Project Code</label>
                  <input name="adID" type="text" class="tylie-text form-control" id="adID" formControlName="projectCode">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="title">Project Name</label>
                  <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="projectName">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="client">Client</label>
                  <dx-select-box [dataSource]="clientDataSource" name="client" id="client" class="tylie-select form-control"
                                formControlName="clientId" [searchEnabled]="true" valueExpr="id" displayExpr="name"
                                [showClearButton]="true" placeholder="Select a value..." [readOnly]="isClientReadOnly">
                  </dx-select-box>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>
      
        <ul class="tylie-tab-nav nav nav-tabs mx-4 mt-3" id="myTab" role="tablist">
          <li class="tylie-tab-nav__itm nav-item" role="presentation">
            <button class="tylie-tab-nav__lnk nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#projectSearch" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="onclickProjectsGrid()">ACTIVE</button>
          </li>
          <li class="tylie-tab-nav__itm nav-item" role="presentation">
            <button class="tylie-tab-nav__lnk nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#archivedProjectSearch" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" (click)="onclickArchiveProjectsGrid()">ARCHIVED</button>
          </li>
        </ul>

        <div class="tylie-tab-content tab-content">
          <div class="tylie-tab-content__pane tab-pane fade show active">
            <dx-data-grid class="tylie-grid" id="projectSearch" #projectSearch [dataSource]="resultProjectStore" [allowColumnReordering]="true"
                          [allowColumnResizing]="true" [columnAutoWidth]="true" [showColumnLines]="true" [showRowLines]="true"
                          [hoverStateEnabled]="true" keyExpr="fileCollectionId" [rowAlternationEnabled]="true"
                          [masterDetail]="{ enabled: true, template: 'filesTemplate', autoExpandAll: false }">
              <dxo-remote-operations [sorting]="true" [paging]="true">
              </dxo-remote-operations>
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

              <dxi-column [allowSorting]="true" dataField="collectionName" caption="Project Name"
                          cellTemplate="projectNameTemplate" width="20%" cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="collectionnId" caption="Project ID" width="20%"
                          cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="clientName" caption="Client" width="20%" cssClass="gridStyle">
              </dxi-column>
              <dxi-column [allowSorting]="true" dataField="status" caption="Status" width="10%"
                          cssClass="gridStyle tyl-text-align-left"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="modifiedDate" caption="Modified" cellTemplate="dateTemplate"
                          width="15%" cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="createdDate" caption="Created" cellTemplate="dateTemplate"
                          width="15%" cssClass="gridStyle" sortOrder="desc"></dxi-column>

              <div *dxTemplate="let t of 'projectNameTemplate'">
                <span class="d-flex align-items-center"><span class="me-2"><icon-project color="#000"></icon-project></span>{{t.data.collectionName}}</span>
              </div>

              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY h:mm A'}}</div>
              </div>

              <div *dxTemplate="let t of 'filesTemplate'" class="detail-grid-margin-left-small">
                <div class="my-2">
                  <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" secured
                          [accessControlListComponent]=[this.userRoles.getProjectAdminRoleName()] #projectEditpop="bs-popover"
                          container="body" outsideClick="true" role="button" placement="right" [popoverContext]="t.data"
                          [popover]="editProjectTemplate" (click)="onProjectClick(t.data)">
                    <span class="tylie-button__icon"><icon-edit-pencil color="#1e90ff"></icon-edit-pencil></span>Edit
                  </button>

                  <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" type="button"
                          (click)="onClickAddFilesToProject(t.data)" *ngIf="showAddFiles(t.data)">
                        <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD Files
                  </button>
                  <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" type="button" *ngIf="t.data.isProjectManager"
                          (click)="onClickCreateNewProjectFolder(t.data)">
                          <span class="tylie-button__icon"><icon-folder-add color="#1e90ff"></icon-folder-add></span> FOLDER
                  </button>
                  <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" type="button" (click)="onClickUsers(t.data)"
                          *ngIf="t.data.isProjectManager">
                          <span class="tylie-button__icon"><icon-user color="#1e90ff"></icon-user></span>USERS
                  </button>
                  <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" type="button" #projectArchivepop="bs-popover"
                          container="body" outsideClick="true" role="button" placement="right" [popoverContext]="t.data"
                          [popover]="archiveProjectTemplate" *ngIf="t.data.isProjectManager">
                          <span class="tylie-button__icon"><icon-archive color="#1e90ff"></icon-archive></span>ARCHIVE
                  </button>
                </div>
                <dx-data-grid *ngIf="t.data.inverseParentfilecollection.length" [showBorders]="false"
                              keyExpr="fileCollectionId" [columnAutoWidth]="true" [showColumnLines]="false" [hoverStateEnabled]="true"
                              [showRowLines]="true" [dataSource]="t.data.inverseParentfilecollection"
                              (onCellPrepared)="onCellPrepared1($event)"
                              [masterDetail]="{ enabled: true, template: 'folderFilesTemplate', autoExpandAll: false }">

                  <div *dxTemplate="let t of 'actionsTemplate'">
                    <div style="text-align:center;" class="tyl-vertical-align" *ngIf="t.data.collectionType == 'Folder'">
                      <span  #projectFolderEditpop="bs-popover" *ngIf="showEditFolder(t.data)"
                              container="body" outsideClick="true" role="button" placement="right" [popoverContext]="t.data"
                              [popover]="editProjectFilesTemplate" (click)="onProjectFolderEdit(t.data)">
                        <i class="icon-ProjectEdit"></i>
                      </span>
                    </div>

                    <div class="d-flex align-items-center justify-content-center" *ngIf="t.data.collectionType != 'Folder'">
                      <a class="tylie-button tylie-button--xs tylie-button--link me-2" *ngIf="showDownloadFile(t.data)"
                        (click)="downloadFile(t.data)">
                        <icon-download color="#1e90ff"></icon-download>
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link me-2" (click)="projectPromote(t.data)" *ngIf="showPromoteFile(t.data)">
                        <icon-orders-tylie-mark color="#1e90ff"></icon-orders-tylie-mark>
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link me-2" outsideClick="true" role="button" placement="left"
                            #deleteFilePop="bs-popover" container="body" [popoverContext]="t.data"
                            [popover]="deleteProjectFileTemplate">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link" *ngIf="showMoveFile(t.data)"
                        (click)="onClickMoveFiles(t.data)"><icon-folder-move color="#1e90ff"></icon-folder-move></a>
                    </div>
                  </div>

                  <div *dxTemplate="let t of 'projectFileNameTemplate'">
                    <span class="d-flex align-items-center">
                      <span class="me-2" *ngIf="t.data.collectionType == 'Folder'">
                        <icon-folder color="#000"></icon-folder>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Video'">
                        <icon-video color="#000"></icon-video>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Audio'">
                        <icon-audio color="#000"></icon-audio>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Other'">
                        <icon-file color="#000"></icon-file>
                      </span>
                      {{t.data.collectionName}}
                    </span>
                  </div>

                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxi-column dataField="collectionName" caption="Name" [allowSorting]="false"
                              cellTemplate="projectFileNameTemplate" width="40%" cssClass="gridStyle1"></dxi-column>
                  <dxi-column dataField="collectionType" caption="Type" [allowSorting]="false" width="20%"
                              cssClass="gridStyle1"></dxi-column>
                  <dxi-column dataField="status" width="20%" caption="Status" [allowSorting]="false" cssClass="gridStyle1">
                  </dxi-column>
                  <dxi-column dataField="fileCollectionId" width="20%" caption="Actions" [allowSorting]="false"
                              cssClass="gridStyle1" alignment="center" cellTemplate="actionsTemplate"></dxi-column>


                  <div *dxTemplate="let t of 'folderFilesTemplate'" class="detail-grid-margin-left-small">
                    <dx-data-grid [showBorders]="false" keyExpr="fileCollectionId" [columnAutoWidth]="true"
                                  [showColumnLines]="false" [hoverStateEnabled]="true" [showRowLines]="true"
                                  (onContentReady)="onSpotFilesGridContentReady($event)"
                                  [dataSource]="t.data.inverseParentfilecollection">

                      <div *dxTemplate="let t of 'actionsTemplate'">
                        <div class="d-flex align-items-center justify-content-center">
                          <a class="tylie-button tylie-button--xs tylie-button--link me-2"  *ngIf="showDownloadFile(t.data)"
                            (click)="downloadFile(t.data)"> <icon-download color="#1e90ff"></icon-download></a>
                          <a class="tylie-button tylie-button--xs tylie-button--link me-2"  (click)="projectPromote(t.data)"
                            *ngIf="showPromoteFile(t.data)">
                            <icon-orders-tylie-mark color="#1e90ff"></icon-orders-tylie-mark>
                          </a>
                          <a class="tylie-button tylie-button--xs tylie-button--link me-2" outsideClick="true" role="button" placement="left"
                                #deleteFilePop="bs-popover" container="body" [popoverContext]="t.data"
                                [popover]="deleteProjectFileTemplate">
                            <icon-trash-can color="#1e90ff"></icon-trash-can>
                          </a>
                          <a class="tylie-button tylie-button--xs tylie-button--link" class="icon-FolderMove tyl-margin-right" *ngIf="showMoveFile(t.data)"
                            (click)="onClickMoveFiles(t.data)">
                            <icon-folder-move color="#1e90ff"></icon-folder-move>
                          </a>
                        </div>
                      </div>

                      <div *dxTemplate="let t of 'projectFolderFileNameTemplate'">
                        <span class="d-flex align-items-center">
                          <span class="me-2" *ngIf="t.data.collectionType == 'Video'">
                            <icon-video color="#000"></icon-video>
                          </span>
                          <span class="me-2" *ngIf="t.data.collectionType == 'Audio'">
                            <icon-audio color="#000"></icon-audio>
                          </span>
                          <span class="me-2" *ngIf="t.data.collectionType == 'Other'">
                            <icon-file color="#000"></icon-file>
                          </span>
                          {{t.data.collectionName}}
                        </span>
                      </div>

                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxi-column dataField="collectionName" caption="Name" [allowSorting]="false"
                                  cellTemplate="projectFolderFileNameTemplate" width="40%" cssClass="gridStyle1"></dxi-column>
                      <dxi-column dataField="collectionType" caption="Type" [allowSorting]="false" width="20%"
                                  cssClass="gridStyle1"></dxi-column>
                      <dxi-column dataField="status" width="20%" caption="Status" [allowSorting]="false"
                                  cssClass="gridStyle1"></dxi-column>
                      <dxi-column dataField="fileCollectionId" width="15%" caption="Actions" cellTemplate="actionsTemplate"
                                  [allowSorting]="false" cssClass="gridStyle1" alignment="center">
                      </dxi-column>
                    </dx-data-grid>
                    <div class="panel-footer panel-footer-master-child-row"></div>
                  </div>


                </dx-data-grid>
                
              </div>

              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50, 100]"
                        infoText="Page {0} of {1} ({2} items)"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>

          <div class="tylie-tab-content__pane tab-pane fade">
            <dx-data-grid  class="tylie-grid" id="archivedProjectSearch" #projectSearch [dataSource]="resultProjectStore"
                          [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" [showColumnLines]="true"
                          [showRowLines]="true" [hoverStateEnabled]="true" keyExpr="fileCollectionId" [rowAlternationEnabled]="true"
                          (onCellPrepared)="onCellPrepared($event)"
                          [masterDetail]="{ enabled: true, template: 'filesTemplate', autoExpandAll: false }">
              <dxo-remote-operations [sorting]="true" [paging]="true">
              </dxo-remote-operations>
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

              <dxi-column [allowSorting]="true" dataField="collectionName" caption="Project Name"
                          cellTemplate="projectNameTemplate" width="20%" cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="collectionnId" caption="Project ID" width="20%"
                          cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="clientName" caption="Client" width="20%" cssClass="gridStyle">
              </dxi-column>
              <dxi-column [allowSorting]="true" dataField="status" caption="Status" width="10%"
                          cssClass="gridStyle tyl-text-align-left"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="modifiedDate" caption="Modified" cellTemplate="dateTemplate"
                          width="15%" cssClass="gridStyle"></dxi-column>
              <dxi-column [allowSorting]="true" dataField="createdDate" caption="Created" cellTemplate="dateTemplate"
                          width="15%" cssClass="gridStyle" sortOrder="desc"></dxi-column>

              <div *dxTemplate="let t of 'projectNameTemplate'">
                <span class="d-flex align-items-center"><span class="me-2"><icon-project color="#000"></icon-project></span>{{t.data.collectionName}}</span>
              </div>

              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY h:mm A'}}</div>
              </div>

              <div *dxTemplate="let t of 'filesTemplate'" class="detail-grid-margin-left-small">
                <dx-data-grid *ngIf="t.data.inverseParentfilecollection.length" [showBorders]="false"
                              keyExpr="fileCollectionId" [columnAutoWidth]="true" [showColumnLines]="false" [hoverStateEnabled]="true"
                              [showRowLines]="true" [dataSource]="t.data.inverseParentfilecollection"
                              (onCellPrepared)="onCellPrepared1($event)"
                              [masterDetail]="{ enabled: true, template: 'folderFilesTemplate', autoExpandAll: false }">

                  <div *dxTemplate="let t of 'projectFileNameTemplate'">
                    <span class="d-flex align-items-center">
                      <span class="me-2" *ngIf="t.data.collectionType == 'Folder'">
                        <icon-folder color="#000"></icon-folder>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Video'">
                        <icon-video color="#000"></icon-video>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Audio'">
                        <icon-audio color="#000"></icon-audio>
                      </span>
                      <span class="me-2" *ngIf="t.data.collectionType == 'Other'">
                        <icon-file color="#000"></icon-file>
                      </span>
                      {{t.data.collectionName}}
                    </span>
                  </div>

                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxi-column dataField="collectionName" caption="Name" [allowSorting]="false"
                              cellTemplate="projectFileNameTemplate" width="40%" cssClass="gridStyle1"></dxi-column>
                  <dxi-column dataField="collectionType" caption="Type" [allowSorting]="false" width="20%"
                              cssClass="gridStyle1"></dxi-column>
                  <dxi-column dataField="status" width="40%" caption="Status" [allowSorting]="false" cssClass="gridStyle1">
                  </dxi-column>


                  <div *dxTemplate="let t of 'folderFilesTemplate'" class="detail-grid-margin-left-small">
                    <dx-data-grid [showBorders]="false" keyExpr="fileCollectionId" [columnAutoWidth]="true"
                                  [showColumnLines]="false" [hoverStateEnabled]="true" [showRowLines]="true"
                                  (onContentReady)="onSpotFilesGridContentReady($event)"
                                  [dataSource]="t.data.inverseParentfilecollection">

                      <div *dxTemplate="let t of 'projectFolderFileNameTemplate'">
                        <span class="d-flex align-items-center">
                          <span class="me-2" *ngIf="t.data.collectionType == 'Video'">
                            <icon-video color="#000"></icon-video>
                          </span>
                          <span class="me-2" *ngIf="t.data.collectionType == 'Audio'">
                            <icon-audio color="#000"></icon-audio>
                          </span>
                          <span class="me-2" *ngIf="t.data.collectionType == 'Other'">
                            <icon-file color="#000"></icon-file>
                          </span>
                          {{t.data.collectionName}}
                        </span>
                      </div>

                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxi-column dataField="collectionName" caption="Name" [allowSorting]="false"
                                  cellTemplate="projectFolderFileNameTemplate" width="40%" cssClass="gridStyle1"></dxi-column>
                      <dxi-column dataField="collectionType" caption="Type" [allowSorting]="false" width="20%"
                                  cssClass="gridStyle1"></dxi-column>
                      <dxi-column dataField="status" width="40%" caption="Status" [allowSorting]="false"
                                  cssClass="gridStyle1"></dxi-column>
                    </dx-data-grid>
                    <div class="panel-footer panel-footer-master-child-row"></div>
                  </div>


                </dx-data-grid>
                <div class="panel-footer panel-footer-master-child-row"></div>
              </div>

              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                        infoText="Page {0} of {1} ({2} items)" [showNavigationButtons]="true" [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #archiveProjectTemplate let-fileCollectionId="fileCollectionId">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to archive this Project?
    </p>
    <i (click)="onHideArchivePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onClickArchive(fileCollectionId)">Yes</button> -->


  <button type="button" class="popover__close" (click)="onHideArchivePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to archive this Project?</header>
  <button class='tylie-button tylie-button--xs' type="button" (click)="onClickArchive(fileCollectionId)">Yes</button>
</ng-template>

<ng-template #deleteProjectFileTemplate let-fileCollectionId="fileCollectionId">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this file?
    </p>
    <i (click)="onHideDeleteFilePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onClickDeleteProjectFile(fileCollectionId)">Yes</button>
 -->

  <button type="button" class="popover__close" (click)="onHideDeleteFilePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this file?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onClickDeleteProjectFile(fileCollectionId)">Yes</button>
</ng-template>

<ng-template #editProjectTemplate let-collectionName="collectionName" let-collectionnId="collectionnId"
             let-fileCollectionId="fileCollectionId">
  
  <button class="popover__close" (click)="onHideEditProjectPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <form [formGroup]="updateProjectForm" (submit)="onClickProjectEdit()" novalidate #f="ngForm">
    <div class="tylie-form-group form-group"
            [ngClass]="{error: (updateProjectForm.controls['collectionName'].errors && f.submitted)
    ||(updateProjectForm.controls['collectionName'].dirty && updateProjectForm.controls['collectionName'].errors)}">
        <label class="tylie-form-group__lbl" for="collectionName">Project Name</label>
        <input formControlName="collectionName" type="text" id="collectionName" name="collectionName"
                class="tylie-text form-control" (change)="onProjectValueChange(collectionName, collectionnId)" autocomplete="off" />
        <p class="p-extra-small" *ngIf="updateProjectForm.controls['collectionName'].errors?.required">
          Project name required
        </p>
    </div>
    <div class="tylie-form-group form-group" [ngClass]="{error: (updateProjectForm.controls['collectionId'].errors && f.submitted)
    ||(updateProjectForm.controls['collectionId'].dirty && updateProjectForm.controls['collectionId'].errors)}">
          <label class="tylie-form-group__lbl" for="collectionId">Project Code</label>
          <input formControlName="collectionId" type="text" id="collectionId" name="collectionId" class="tylie-text form-control"
                  (change)="onProjectValueChange(collectionName, collectionnId)" autocomplete="off" />
          <p class="p-extra-small" *ngIf="updateProjectForm.controls['collectionId'].errors?.required">
            Project Code
            required
          </p>
    </div>
    <span class="text-danger mb-3" *ngIf="duplicate">Project name or code already exist</span>
    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit" [disabled]="isSaveDisabled"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
    </div>
  </form>
</ng-template>


<ng-template #editProjectFilesTemplate let-collectionName="folderName"
             let-fileCollectionId="fileCollectionId">
  <button class="popover__close" (click)="onHideEditProjectFolderPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <form [formGroup]="updateProjectFolderForm" (submit)="onClickProjectFolderEdit()" novalidate #fn="ngForm">
    <div class="tylie-form-group form-group"
          [ngClass]="{error: (updateProjectFolderForm.controls['folderName'].errors && fn.submitted)
  ||(updateProjectFolderForm.controls['folderName'].dirty && updateProjectFolderForm.controls['folderName'].errors)}">
      <label class="tylie-form-group__lbl" for="collectionName">Folder Name</label>
      <input formControlName="folderName" type="text" id="folderName" name="folderName"
              class="tylie-text form-control" autocomplete="off" />
      <p class="p-extra-small" *ngIf="updateProjectFolderForm.controls['folderName'].errors?.required">
        Folder Name required
      </p>
    </div>
    <span class="text-danger mb-3" *ngIf="duplicate">Project Folder already exists with this name.</span>
    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
    </div>
  </form>
</ng-template>
