<div class="tylie-panel" id="createclient">
  <div class="tylie-panel__header">
    <h4 class="tylie-panel__title">
      Roles
    </h4>
  </div>
  <div class="tylie-panel__body" id="createClientBody">
      <form [formGroup]="addUserRolesFormGroup" novalidate #roleForm="ngForm">
        <div class="row" formArrayName="userRoles">
          <div class="col-4" *ngFor="let roleGrp of addUserRolesFormGroupArray.controls; let i = index">
            <div class="tylie-form-group form-check" [formGroup]="roleGrp">
              <input type="checkbox" class="form-check-input" formControlName="isChecked" id="isChecked" (change)="onRoleValueChanged($event, i)" />
              <label class="form-check-label">{{addUserRolesFormGroupArray.controls[i].controls['roleName'].value}}</label>
            </div>
          </div>
        </div>
      </form>
  </div>
</div>