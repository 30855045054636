<div class="d-flex justify-content-end mb-3">
  <button type="button"
    class="tylie-button tylie-button--xs tylie-button--icon"
    (click)="onRefresh()"
  >
    <span class="tylie-button__icon">
      <icon-refresh></icon-refresh>
    </span>
    <span class="tylie-button__text">REFRESH</span>
  </button>
</div>

<div class="row justify-content-between">
  <div class="col-md-6">
    <section class="tylie-input-search">
      <div class="tylie-input-search__inner">
        <div class="tylie-input-search__icon"><icon-search></icon-search></div>
        <input
          class="tylie-input-search__text"
          placeholder="Search by keyword"
          name="srch-term"
          id="srch-term"
          type="search"
          [(ngModel)]="quickSearchValue"
          (keydown.enter)="onEnterQuickSearch($event)"
        />
      </div>
      <div class="tylie-input-search__btn">
        <button
          class="tylie-button tylie-button--sm"
          type="button"
          (click)="quickSearch()"
        >
          Search
        </button>
      </div>
    </section>
  </div>

  <div class="col-md-3">
    <div class="row">
      <div class="col-4">
        <label for="sortBy" class="mt-3">Sort By</label>
      </div>
      <div class="col-8">
        <dx-select-box
          [dataSource]="sortDataSource"
          class="tylie-select form-control"
          [searchEnabled]="true"
          valueExpr="id"
          displayExpr="name"
          [value]="selectedSortOrder"
          (onValueChanged)="onSortValueChanged($event)"
        >
        </dx-select-box>
      </div>
    </div>
  </div>
</div>

<div class="grid-container" *ngFor="let taggerAd of taggerAds">
  <div class="grid-item">
    <div
      *ngIf="
        taggerAd.baseCreativeGuid && taggerAd.baseCreativeDetails?.baseCreative
      "
      class="creative-thumbnail-content"
    >
      <img
        class="quickLink"
        [src]="taggerAd.baseCreativeDetails?.baseCreative?.thumbnailDisplayUrl"
        *ngIf="taggerAd.baseCreativeDetails?.baseCreative?.isPlayable"
        (click)="
          quickPlay(
            taggerAd.baseCreativeDetails?.baseCreative?.proxyurl,
            taggerAd.baseCreativeDetails?.baseCreative?.creativeName,
            taggerAd.baseCreativeDetails?.baseCreative?.thumbnailurl,
            taggerAd.baseCreativeDetails?.baseCreative?.format
          )
        "
      />
      <div
        *ngIf="taggerAd.baseCreativeDetails?.baseCreative?.isPlayable"
        class="video-play-icon"
      ></div>
      <img
        [src]="taggerAd.baseCreativeDetails?.baseCreative?.thumbnailDisplayUrl"
        *ngIf="!taggerAd.baseCreativeDetails?.baseCreative?.isPlayable"
      />
    </div>
    <div class="thumbnail-content">
      <div
        *ngIf="taggerAd.offerCardGuid && taggerAd.offerCardDetails?.offerCard"
      >
        <img
          [src]="taggerAd.offerCardDetails?.offerCard?.cardDisplayUrl"
          class="gridThumbnail"
        />
      </div>
      <div *ngIf="taggerAd.endCardGuid && taggerAd.endCardDetails?.endCard">
        <img [src]="taggerAd.endCardDetails?.endCard?.cardDisplayUrl" />
      </div>
    </div>
  </div>
  <div class="grid-item">
    <div class="row">
      <div class="col-md-12">
        <span class="tagger-title">{{ taggerAd.adName }}</span>
      </div>
      <div class="col-md-6">
        <div *ngIf="taggerAd.workOrder" class="tagger-detail-container">
          <Label class="tylie-form-group__lbl">Work Order: </Label>
          <span>{{ taggerAd.workOrder }}</span>
        </div>
        <div *ngIf="taggerAd.createdDate" class="tagger-detail-container">
          <Label class="tylie-form-group__lbl">Created:</Label>
          <span>{{
            taggerAd.createdDate | usertimezone : "MM/DD/YYYY HH:mm A"
          }}</span>
        </div>
        <div
          *ngIf="
            taggerAd.baseCreativeGuid &&
            taggerAd.baseCreativeDetails?.baseCreative
          "
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">Expiring:</Label>
          <span>{{
            taggerAd.baseCreativeDetails?.baseCreative?.expirydate
              | usertimezone : "MM/DD/YYYY HH:mm A"
          }}</span>
        </div>
        <div
          *ngIf="
            taggerAd.baseCreativeGuid &&
            taggerAd.baseCreativeDetails?.baseCreative
          "
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">Duration:</Label>
          <span>{{
            taggerAd.baseCreativeDetails?.baseCreative?.durationinsecs
          }}</span>
        </div>
        <div
          *ngIf="
            taggerAd.baseCreativeGuid &&
            taggerAd.baseCreativeDetails?.baseCreative
          "
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">Base:</Label>
          <span>{{
            taggerAd.baseCreativeDetails?.baseCreative?.creativeName
          }}</span>
        </div>
        <div
          *ngIf="taggerAd.offerCardGuid && taggerAd.offerCardDetails?.offerCard"
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">Offer:</Label>
          <span>{{ taggerAd.offerCardDetails?.offerCard?.cardName }}</span>
        </div>
        <div
          *ngIf="taggerAd.endCardGuid && taggerAd.endCardDetails?.endCard"
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">End:</Label>
          <span>{{ taggerAd.endCardDetails?.endCard?.cardName }}</span>
        </div>
        <div
          *ngIf="taggerAd.voiceOverGuid && taggerAd.voiceOverDetails?.voiceOver"
          class="tagger-detail-container"
        >
          <Label class="tylie-form-group__lbl">Voice:</Label>
          <span>{{ taggerAd.voiceOverDetails?.voiceOver?.voiceOverName }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <Label class="tylie-form-group__lbl">Notes:</Label>
        <div class="p-normal">{{ taggerAd.note?.payload }}</div>
        <span class="p-tiny">{{ taggerAd.note?.createdBy }}</span
        >&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{
          taggerAd.note?.createdDate | usertimezone : "MM/DD/YYYY HH:mm A"
        }}</span>
        <div>
          <a
            class="tylie-lnk"
            (click)="editNote(taggerAd)"
            *ngIf="!taggerAd.note?.payload"
            >Add Note</a
          >
          <a
            class="tylie-lnk"
            (click)="editNote(taggerAd)"
            *ngIf="taggerAd.note?.payload"
            >View/Edit Note</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="grid-item">
    <div class="row tagger-actions">
      <button *ngIf="taggerAd.status != taggingStatus.Success" class="tylie-badge--warning">
        {{ taggerAd.status }}
      </button>
      <button [ngClass]="statusClass(taggerAd.displayStatus)"
              *ngIf="taggerAd.displayStatus && taggerAd.status == taggingStatus.Success">
        {{ taggerAd.displayStatus }}
      </button>
      <a [ngClass]="{
          'disabled-link': taggerAd.ad.proxyUrl == null || taggerAd.ad.proxyUrl == '',
          'tylie-lnk': taggerAd.ad.proxyUrl != null && taggerAd.ad.proxyUrl != ''
        }"
         (click)="onViewAd(taggerAd)"
        >
        <icon-eye color="#808080"
                  *ngIf="taggerAd.ad.proxyUrl == null || taggerAd.ad.proxyUrl == ''"></icon-eye>
        <icon-eye color="#1e90ff"
                  *ngIf="taggerAd.ad.proxyUrl != null &&
            taggerAd.ad.proxyUrl != ''"></icon-eye>
        &nbsp;VIEW TAGGED AD
      </a>
      <a [ngClass]="{
          'disabled-link': taggerAd.ad.proxyUrl == null || taggerAd.ad.proxyUrl == '',
          'tylie-lnk': taggerAd.ad.proxyUrl != null && taggerAd.ad.proxyUrl != ''
        }"
         (click)="onShare(taggerAd)">
        <icon-send color="#808080"
                    *ngIf="taggerAd.ad.proxyUrl == null || taggerAd.ad.proxyUrl == ''"></icon-send>
        <icon-send color="#1e90ff"
                   *ngIf="taggerAd.ad.proxyUrl != null && taggerAd.ad.proxyUrl != ''"></icon-send>
        &nbsp;SHARE
      </a>
      <a [ngClass]="{
          'disabled-link': taggerAd.ad.s3StoragePath == null || taggerAd.ad.s3StoragePath == '',
          'tylie-lnk': taggerAd.ad.s3StoragePath != null && taggerAd.ad.s3StoragePath != ''
        }"
         (click)="onDownload(taggerAd)">
        <icon-download color="#808080"
                       *ngIf="(taggerAd.ad.s3StoragePath == null ||
            taggerAd.ad.s3StoragePath == '')"></icon-download>
        <icon-download color="#1e90ff"
                       *ngIf="(taggerAd.ad.s3StoragePath != null &&
            taggerAd.ad.s3StoragePath != '')"></icon-download>
        &nbsp;DOWNLOAD
      </a>
      <a [ngClass]="{
          'disabled-link': taggerAd.status != taggingStatus.Success,
          'tylie-lnk': taggerAd.status == taggingStatus.Success
        }"
         (click)="onArchive(taggerAd)">
        <icon-archive color="#808080"
                      *ngIf="taggerAd.status != taggingStatus.Success"></icon-archive>
        <icon-archive color="#1e90ff"
                      *ngIf="taggerAd.status == taggingStatus.Success"></icon-archive>
        &nbsp;ARCHIVE
      </a>
    </div>
  </div>
</div>
<div class="no-records" *ngIf="taggerAds?.length === 0">
  <span>No Records found</span>
</div>
