import { Data } from "ngx-bootstrap/positioning/models";
import { Carton } from "./carton.model";

export class CreateVaultRequest {
  public clientId: number;
  public brandId: number;
  public productId: number;
  public requesteBy: string;
  public jobNumber: string;
  public reference: string;
  public warehouseId: number;
  public requestReceivedFrom: string;
  public requestType: number;
  public confirmationEmails: string;
  public isAuditService: boolean;
  public isDigitalEncoding: boolean;
  public isClimateControl: boolean;
  public notes: string;
  public vaultRequestGuid: string;
  public attachmentGuids: Array<string>;
  public clientCode: string;
  public brandCode: string;
  public productCode: string;
}


export class VaultFile {
  vaultFileGuid: string;
  fileName: string;
  fileType: string;
  fileCategory: string;
  description: string;
  filePath: string;
  vaultFileId: number;
  uploadedOn: Date;
  vaultRequestId: number;
  downloadUrl: string;
}

export class VaultAssetDetail {
  assetbarcode: string;
  assetingestedbyuserprofileid: number;
  assetingestedbyuserprofilename: string;
  assetingesteddate: Date;
  assetlastloggedoutdate: Date;
  createdby: string;
  createddate: Date;
  uploadedOn: Date;
  description: string;
  editexpirydate: Date;
  editstartedbyuserprofileid: number;
  editstartedbyuserprofilename: string;
  expectedreturndate: Date;
  formatid: number;
  isactive: boolean;
  isassetmissinginvault: boolean;
  modifiedby: string;
  status: string;
  statusid: number;
  vaultassetguid: string;
  vaultassetid: number;
  vaultrequestid: number;
  format: string;
  location: any;

  vaultRequestClient: string;
  vaultRequestClientId: number;
  vaultRequestBrand: string;
  vaultRequestBrandId: number;
  vaultRequestProduct: string;
  vaultRequestProductId: number;
  vaultRequestRequestedBy: string;
  vaultRequestClientJobNumber: string;
  vaultRequestReference: string;
  vaultRequestReceivedFrom: string;

  cartonWarehouse: string;
  cartonWarehouseId: number;
  cartonColumn: string;
  cartonPosition: string;
  cartonBarcode: string;

  isLoggedOut: boolean;
  isStagging: boolean;
  isRemoved: boolean;
  isInVault: boolean;
  isDestroyed: boolean;

  vaultAssetLineItems: Array<Vaultassetlineitem>
  vaultFiles: Array<VaultFile>
  vaultServices: Array<Vaultservice>
  vaultAssetNotes: Array<Vaultassetnotes>
}

export class UpdateVaultAssetServicesResponse{
  vaultServices: Array<Vaultservice>
}


export class Vaultassetlineitem {
  vaultAssetLineitemId: number;
  vaultAssetId: number;
  sequence: number;
  adid: string;
  title: string;
  length: string;
  isactive: boolean;
}

export class VaultAssetService {
  public serviceName: string;
  public vaultServiceId: number;
  public isAssigned: boolean;
}

export class VaultAssetServicesResponse {
  public vaultAssetServices: VaultAssetService[];
}

export class VaultAssetServicesRequest {
  public vaultAssetServices: any[];
  public vaultAssetId: number;
  public vaultRequestId: number;
}


export class Vaultservice {
  public vaultServiceId: number;
  public serviceName: string;
  public category: string;
}

export class Vaultassetnotes {
  message: string;
  createdBy: string;
  createdDate: Date;
}

export class VaultAssetEditConfig {
  public formats: Array<any>;
  public warehouses: Array<any>;
}

export class VaultAssetEditRequest {
  public vaultAssetId: number;
  public vaultAssetGuid: string;
  public cartonWarehouseId: number;
  public cartonWarehouse: string;
  public cartonColumn: string;
  public cartonPosition: string;
  public cartonBarcode: string;
  public formatId: number;
  public format: string;
  public assetBarcode: string;
  public assetDescription: string;
  public assetIngestedDate: Date;
  public assetlastloggedoutdate: Date;
  public expectedreturndate: Date;
  public assetIngesteBy: string;
  public assetLineItemData: Array<Vaultassetlineitem>;
  public deletedLineItemIds: Array<number>;
}

export class VaultAssetNote {
  public vaultAssetId: number;
  public vaultRequestId: number;
  public vaultDigitalAssetLineItemId: number;
  public note: string;
}


export class UpdateVaultAssetDestroyRemove {
  public companyName: string;
  public recipientName: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public contactEmail: string;
  public contactPhone: string;
  public shippingcompany: number;
  public manualShippingCompany: string;
  public shippingAccountNumber: string;
  public shippingTrackingNumber: string;
  public deliveryConfirmation: string;
  public notes: string;
  public shippingestimate: number;
  public tylieestimate: number;
  public discount: number;
  public totalestimate: number;
  public expectedreturndate: Date;
}

export class VaultShippingCompanyViewModel {
  public companyName: number;
  public vaultShippingCompanyId: string;
}

export class StateViewModel {
  public abbreviation: string;
  public id: number;
  public country: string;
}

export class VaultAssetRemoveDestroyConfig {
  public shippingCompanies: Array<VaultShippingCompanyViewModel>;
  public states: Array<StateViewModel>;
}

export class VaultAssetRemoveDestroyRequest {
  public vaultAssetIds: Array<number>;
  public recipientInfo: string;
  public shippingInfo: string;
  public notes: string;
  public shippingEsimate: number;
  public tylieEstimate: number;
  public discount: number;
  public totalEstimate: number;
  public expectedReturnDate: Date;
  public storageType: string;

  public isVaultDigitalAsset: boolean;
}


export class VaultAssetRecipientInfoViewModel {
  public companyname: string;
  public recipientname: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public contactemail: string;
  public contactphone: string;
}

export class VaultAssetShippingInfoViewModel {
  public existingshippingcompanyid: number;
  public manuallyentershippingcompany: string;
  public shippingaccountnumber: string;
  public shippingtrackingnumber: string;
  public deliveryconfirmation: string;
}


export class VaultAssetPopupGridModel {
  public vaultAssetId: number;
  public assetBarcode: string;
  public format: string;
  public client: string;
  public brand: string;
  public product: string;

  public vaultDigitalAssetId: number;
  public assetIdentifier: string;
}


export class VaultDigitalAssetDetail {
  public vaultDigitalAssetLineItemId: number;
  public vaultDigitalAssetLineItemGuid: string;
  public vaultRequestId: number;
  public assetIdentifier: string;
  public storageType: string;
  public title: string;
  public length: string;
  public filePath: string;
  public isactive: boolean;
  public legacyMasterId: string;
  public legacyVtmNumber: string;
  public status: string;
  public statusId: number;
  public s3bucket: string;
  public assetDescription: string;
  public createdby: string;
  public createddate: Date;
  public modifiedby: string;
  public modifieddate: Date;

  public vaultRequestClient: string;
  public vaultRequestClientId: number;
  public vaultRequestBrand: string;
  public vaultRequestBrandId: number;
  public vaultRequestProduct: string;
  public vaultRequestProductId: number;

  public isLoggedOut: boolean;
  public isStagging: boolean;
  public isRemoved: boolean;
  public isInVault: boolean;
  public isDestroyed: boolean;

  public isUserVaultAdmin: boolean;

  public vaultFiles: Array <VaultFile>
  public vaultAssetNotes: Array<Vaultassetnotes>
}

export class VaultDigitalAssetEditRequest {
  public vaultDigitalAssetLineItemId: number;
  public vaultDigitalAssetLineItemGuid: string;
  public assetIdentifier: string;
  public title: string;
  public length: string;
  public legacyMasterId: string;
  public legacyVtmNumber: string;
  public assetDescription: string;
}

export class VaultStatusChangeRequest {
  public VaultAssetId: number;
  public IsVaultDigitalAsset: boolean;
}


