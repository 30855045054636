import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { MediaDetailService } from '../../../services/mediadetail/mediadetail.service';
import { UpdateDistroMediaRequest } from '../../../models/distribution/update-distro-media-request.model';
import { SpecDetailsModel } from '../../../models/admin/destination/createdestination.model';


@Component({
  selector: 'add-update-specification',
  templateUrl: './addeditspecdetailspopup.component.html',
  styleUrls: ['./addeditspecdetailspopup.component.css'],
})
export class AddEditSpecDetailsPopUp implements OnInit {

  @Input('recipeName') recipeName: string = null;
  public onClose: Subject<SpecDetailsModel>;

  public modalRef: BsModalRef;
  public updateSpecDetailsForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private mediaDetailService: MediaDetailService) {

    this.onClose = new Subject<SpecDetailsModel>();
  }

  ngOnInit() {
    this.updateSpecDetailsForm = this.fb.group({
      recipeName: new FormControl(this.recipeName),
    });
  }

  public onUpdateSpecDetailsFormSubmit({ value, valid }: { value: SpecDetailsModel, valid: boolean }) {

    if (!valid) {
      return;
    }
    this.onClose.next(value);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
