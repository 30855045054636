import { UUID } from "angular2-uuid";

export class SpotUpdateQC {
  public spotFileGuids: string[];
  public qcStatus: string;
  public spotComments: string;

  constructor(spotFileGuids: string[], qCStatus: string, spotComments: string) {
    this.spotFileGuids = spotFileGuids;
    this.qcStatus = qCStatus;
    this.spotComments = spotComments;
  }
}
