import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { CreateFolderResponse } from '../../models/project/create-project.model';
import { Project, ClientUserModel, ApiResponse, FileCollectionUserAccess, UserPermissionResponse } from '../../models/project/project.model';
@Injectable()

export class AddUserPermissionToProjectService {
    constructor(private http: HttpClient,
        private apiEndPoint: ApiEndPoint) { }

    getUsersByClient(userRequest: Project): Observable<any> {
        return this.http.get<any>(`${this.apiEndPoint.getUsersByClient()}?clientId=${userRequest.clientId}&projectId=${userRequest.fileCollectionId}`);
    }

    assignUserPermission(userPermissions: ClientUserModel[], fileCollectionId: number): Observable<ApiResponse> {
        let headers = new HttpHeaders({
            'fileCollectionId': fileCollectionId.toString()
        });
        let options = { headers: headers };
        return this.http.post<ApiResponse>(this.apiEndPoint.assignUserPermission(), userPermissions, options);
    }

    assignExistingUserPermission(fileCollectionUserAccess: FileCollectionUserAccess[], fileCollectionId: number): Observable<ApiResponse> {
        let headers = new HttpHeaders({
            'fileCollectionId': fileCollectionId.toString()
        });
        let options = { headers: headers };
        return this.http.post<ApiResponse>(this.apiEndPoint.assignExistingUserPermission(), fileCollectionUserAccess, options);
    }

    assignNewUserPermission(fileCollectionUserAccess: FileCollectionUserAccess[], fileCollectionId: number): Observable<UserPermissionResponse> {
        let headers = new HttpHeaders({
            'fileCollectionId': fileCollectionId.toString()
        });
        let options = { headers: headers };
        return this.http.post<UserPermissionResponse>(this.apiEndPoint.assignNewUserPermission(), fileCollectionUserAccess, options);
    }
}