<ng-container id="saved-destinations">
  <div class="modal-header">
    <h4 class="modal-title">ADD BRANDS</h4>
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-md-6">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Brand Name" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                 (ngModelChange)="onClearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchBrands()">Search</button>
          </div>
        </section>
      </div>
    </div>
    <div class="py-3">
        <dx-data-grid id="brandsGrid" class="tylie-grid tylie-grid--bordered"
                      #brandsGrid
                      [dataSource]="dataSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="name" width="100%" cssClass="gridStyle" caption="Brand Name"></dxi-column>

          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
    </div>
    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onaddSelectedBrands()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">Add Brands</span>
      </button>
    </div>
  </div>
</ng-container>

