import { Injectable, Inject } from '@angular/core';
import { Constants } from "../../configurations/constants";
import { SubService } from "./sub.service";

@Injectable()
export class DatGridService {

  public selectAll: string = Constants.selectAll;
  public deSelectAll: string = Constants.deSelectAll;

  constructor(private pagingService: SubService) { }

  public atleastOneRowSelected(datGrid: any) {
    let i: number;
    let flag: boolean = false;

    let count = datGrid.getVisibleRows().length;
    for (i = 0; i < count; i++) {
      flag = datGrid.isRowSelected(datGrid.getKeyByRowIndex(i));
      if (flag == true) { return flag; }
    }
    return flag;
  }

}
