export class CreateUser {
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public client: number;
  public postHouse: number;
  public timeZone: string;
  public notificationPreference: NotificationPreference;
  public userRole: UserRole;
  public brands: UserClientBrand[];
  public destination: string;
  public dealerNumber: string;
  public dealerName: string;

  public enableAdvancedFileNaming: boolean;
  public specifications: any[];
}

export class CreateUserResponse {
  public warnings: string[];
}

export class NotificationPreference {
  public notificationPreferences: UserProfileNotificationPreference[];
}

export class UserProfileNotificationPreference {
  public id: number;
  public roleId: string;
  public description: string;
  public isChecked: boolean;
}

export class UserRole {
  public userRoles: UserProfileRoles[];
}

export class UserProfileRoles {
  public roleId: number;
  public roleName: string;
  public isChecked: boolean;
}

export class CreateUserConfig {
  public rolesNotifications: CreateUserRoleNotification[];
  public clients: any[];
  public postHouses: any[];
  public timeZones: any[];
  public brands: any[];
  public allowSpecialOptions: boolean;
}

export class CreateUserRoleNotification {
  public roleId: number;
  public roleName: string;
  public roleDescription: string;
  public roleNotificationPreferences: RoleNotificationPreference[];
}

export class RoleNotificationPreference {
  public id: number;
  public name: string;
  public description: string;
}

export class UserClientBrand {
  public id: number;
  public brandName: string;
  public brandId: number;
  public products: UserBrandProduct[];
  public productsDisplay: string[];
  public hasBrandProducts: boolean;
  public clientBrandApprovalLevels: number[];
  public userClientBrandApprovalLevel: number;
  public hasApprovalLevels: boolean;
}

export class UserBrandProduct {
  public id: number;
  public productGuid: string;
  public productId: number;
  public name: string;
  public isCurrent: boolean;
}
