<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="request" #f="ngForm" (ngSubmit)="onSubmit(request)">
    
    <div class="tylie-form-group form-group" [ngClass]="{error: (request.controls['comments'].errors && f.submitted) }">
      <label class="tylie-form-group__lbl" for="notes">Comments<span class="required">*</span></label>
      <textarea name="notes" formControlName="comments" class="tylie-textarea tylie-textarea--md form-control" rows="10"></textarea>
      <p class="p-extra-small">Please enter comments</p>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="submit">Submit</button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>
  </form>
</div>
