import { first, mergeMap, map, filter, finalize, takeUntil } from 'rxjs/operators';
import { Component, OnInit, HostListener, ViewChild, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '../app/services/auth/auth.service';
import { AlertService, AlertMessage, MessageSeverity } from '../app/services/core/alert.service';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { Utilities } from "../app/services/core/utilities";
import { RouteConstants } from "./configurations/route-constants";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SessionExpiryWarningPopupComponent } from './components/popups/sessionexpirywarningpopup/sessionexpirywarningpopup.component';
import { Constants } from './configurations/constants';
import { GlobalService } from './services/core/global.service';
import { SubService } from './services/media/sub.service';
import { environment } from '../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { InfoPopupComponent } from './components/popups/infopopup/infopopup.component';
import { Observable, Subject } from 'rxjs';
import { WebStatus } from './models/core/webstatus.model';
import { UnSavedDataWarningComponent } from './components/popups/unsaveddatapopup/unsaveddatapopup.component';
import { OrderDetailContentComponent } from './components/orders/order-detail/order-detail-content/orderdetailcontent.component';
import { CreateOrderContentComponent } from './components/orders/order-create/create-order-content/createordercontent.component';
import { MediaDetailContentComponent } from './components/mediadetail/mediadetailcontent/mediadetailcontent.component';
import { DBkeys } from './configurations/db-Keys';
import { LocalStoreManager } from './services/core/local-store-manager.service';
import { VaultAssetDetailComponent } from './components/vault/vault-asset-detail/vault-asset-detail.component';
import { VaultRequestDetailComponent } from './components/vault/vault-request-detail/vault-request-detail.component';
import { UserDetailComponent } from './components/admin/user/user-detail/user-detail.component';
import { ClientDetailComponent } from './components/admin/client/clientdetail/client-detail.component';
import { ViewEditBrandComponent } from './components/admin/brand/viewedit-brand/viewedit-brand.component';
import { DestinationDetailComponent } from './components/admin/destination/destination-detail/destination-detail.component';
import { VaultDigitalAssetDetailComponent } from './components/vault/vault-digital-asset-detail/vault-digital-asset-detail.component';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  public showLoading: boolean = false;
  public stickyToasties: number[] = [];
  public isUserLoggedIn: boolean;
  public showNavBar: boolean = false;

  private timedOut = false;
  private idleState = "";
  public modalRef: BsModalRef;

  public showPopup: boolean = false;

  public spotFileGuid: string;
  public orderGuid: string;
  public orderDraftGuid: string;
  public uploadedOrderGuid: string;
  public copyOrderGuid: string;
  public vaultRequestGuid: string;
  public vaultDigitalAssetLineItemId: number;
  public vaultAssetGuid: string;
  public userProfileGuid: string;
  public destinationGuid: string;
  public clientGuid: string;
  public brandGuid: string;
  public copyOptions: any;

  public madeSpotEdits: boolean;
  public madeOrderEdits: boolean;
  public madeDraftOrderEdits: boolean;
  public madeUploadedOrderEdits: boolean;
  public madeCopyOrderEdits: boolean;
  public completedVaultRequest: boolean;
  public madeVaultAssetEdits: boolean;
  public madeVaultDigitalAssetEdits: boolean;
  public statusLabel: string;

  public sidebarActive: boolean = false;
  public wHeight: number = (window.innerHeight - 80);
  public versionWarningPopupVisible: boolean = false;

  @ViewChild(OrderDetailContentComponent, { static: false }) odetail: OrderDetailContentComponent;
  @ViewChild(MediaDetailContentComponent, { static: false }) mdetail: MediaDetailContentComponent;
  @ViewChild(CreateOrderContentComponent, { static: false }) ocreate: CreateOrderContentComponent;
  @ViewChild(VaultAssetDetailComponent, { static: false }) vdetail: VaultAssetDetailComponent;
  @ViewChild(VaultDigitalAssetDetailComponent, { static: false }) vaultdigitaldetail: VaultDigitalAssetDetailComponent;
  @ViewChild(VaultRequestDetailComponent, { static: false }) vRdetail: VaultRequestDetailComponent;
  @ViewChild(UserDetailComponent, { static: false }) userDetail: UserDetailComponent;
  @ViewChild(ClientDetailComponent, { static: false }) clientDetail: ClientDetailComponent;
  @ViewChild(ViewEditBrandComponent, { static: false }) brandDetail: ViewEditBrandComponent;
  @ViewChild(DestinationDetailComponent, { static: false }) destinationDetail: DestinationDetailComponent;

  private readonly _destroying$ = new Subject<void>();

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.wHeight = (event.target.innerHeight - 80);
  }

  constructor(
    public authService: AuthService,
    private alertService: AlertService,
    private toastyService: ToastrService,
    public util: Utilities,
    public router: Router,
    private route: ActivatedRoute,
    private idle: Idle,
    private modalService: BsModalService,
    public gs: GlobalService,
    public subService: SubService,
    private http: HttpClient,
    private authMS: MsalService,
    private msalBroadcastService: MsalBroadcastService) {

    idle.setIdle(environment.idleTimeInSecs);
    // sets a timeout period of 10800 seconds. after 21600 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(environment.idleTimeInSecs);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.authService.forceLogOut();
      this.alertService.ShowLoader(false);
      this.ngIdleReset();
    });

    idle.onTimeoutWarning.pipe(
      finalize(() => { this.ngIdleReset(); }))
      .subscribe((countdown) => {
        if (this.showNavBar) {
          if (countdown == environment.idleWarningRemainingTimeInSecs) {
            var initialState = {
              timeOutStartTime: countdown * 1000
            };

            this.modalRef = this.modalService.show(SessionExpiryWarningPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
            this.modalRef.content.onClose.subscribe(result => {
              if (result == true) {
                // do nothing
              }
              else {
                this.authService.forceLogOut();
                this.alertService.ShowLoader(false);
              }
            });
          }
        }
      });

    this.ngIdleReset();
  }

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.isLoggedIn();
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
      setTimeout(() => {
        if (this.isUserLoggedIn == false) {
          this.router.navigate([RouteConstants.loginRoute]);
        }
      }, 5);
    });

    this.alertService.ShowLoading.subscribe((t: boolean) => {
      this.showLoading = t;
    });

    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => {
        var routeVar = event['showNav'];
        if (routeVar == false) {
          this.showNavBar = false;
        }
        else {
          this.showNavBar = true;
        }
        this.closePopover();
        this.closePopup();

        this.toastyService.toasts.forEach(v => this.toastyService.remove(v.toastId));
      });

    this.gs.openEvent.subscribe(t => { this.openPopover(t) });

    this.gs.spotEditEvent.subscribe(t => { this.madeSpotEdits = t; });

    this.gs.orderEditEvent.subscribe(t => { this.madeOrderEdits = t; });

    this.gs.draftOrderEditEvent.subscribe(t => { this.madeDraftOrderEdits = t; });

    this.gs.uploadedOrderEditEvent.subscribe(t => { this.madeUploadedOrderEdits = t; });

    this.gs.copyOrderEditEvent.subscribe(t => { this.madeCopyOrderEdits = t; });

    this.gs.vaultRequestCompletedEvent.subscribe(t => { this.completedVaultRequest = t; });

    this.gs.vaultAssetEditedEvent.subscribe(t => { this.madeVaultAssetEdits = t; });

    this.gs.vaultDigitalAssetEditedEvent.subscribe(t => { this.madeVaultDigitalAssetEdits = t; });

    this.gs.closePopoverEvent.subscribe(t => { this.closePopover(); });

    if (environment.production == true)
      this.initWebStatusCheck(environment.apiBaseUrl + environment.versionCheckUrl, (1000 * 60 * environment.versionCheckFrequencyInMins));
  }

  public ngIdleReset() {
    this.idle.watch();
    this.timedOut = false;
  }

  showToast(message: AlertMessage) {

    console.log(message);
    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastyService.clear(id);
      }
      return;
    }

    if (message.severity == MessageSeverity.error)
      message.isSticky = true;

    var timeOUt = message.isSticky ? 8000 : 4000;

    switch (message.severity) {
      case MessageSeverity.default: this.toastyService.show(message.detail, message.summary, { timeOut: timeOUt }); break
      case MessageSeverity.info: this.toastyService.info(message.detail, message.summary, { timeOut: timeOUt }); break;
      case MessageSeverity.success: this.toastyService.success(message.detail, message.summary, { timeOut: timeOUt }); break;
      case MessageSeverity.error: this.toastyService.error(message.detail, message.summary, { timeOut: timeOUt }); break
      case MessageSeverity.warn: this.toastyService.warning(message.detail, message.summary, { timeOut: timeOUt }); break;
    }
  }

  public closePopup() {
    for (var i = this.modalService.getModalsCount() - 1; i >= 0; i--) {
      this.modalService.hide(i);
    }

    this.versionWarningPopupVisible = false;
  }

  public closePopover(check: boolean = false) {
    if (check == true) {

      if ((this.orderGuid != null && this.odetail != null && this.odetail.hasUnsavedData() == true)
        || (this.spotFileGuid != null && this.mdetail != null && this.mdetail.hasUnsavedData() == true)
        || ((this.orderDraftGuid || this.uploadedOrderGuid || this.copyOrderGuid) && this.ocreate != null && this.ocreate.hasUnsavedData() == true)) {

        var initialState = {
          message: "You have unsaved changes! Do you really want to leave this page?"
        };

        this.modalRef = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

        this.modalRef.content.subject.subscribe(result => {
          if (result == true)
            this.closePopover();
        });

        return;
      }
    }

    if (this.showPopup) {
      if (this.spotFileGuid != '' && this.madeSpotEdits) {
        this.subService.callLoadQCGrid();
        this.subService.callGetApprovalSearchSpotsGrid();
        this.subService.callGetSearchSearchSpotsGrid();
      }

      if (this.orderGuid != '' && this.madeOrderEdits) {
        this.subService.callRefreshOrders();
      }

      if (this.orderDraftGuid != '' && this.madeDraftOrderEdits) {
        this.subService.callRefreshDraftOrders();
        this.subService.callRefreshOrders();
      }

      if (this.uploadedOrderGuid != '' && this.madeUploadedOrderEdits) {
        this.subService.callRefreshUploadedOrders();
        this.subService.callRefreshOrders();
      }

      if (this.copyOrderGuid != '' && this.madeCopyOrderEdits) {
        this.subService.callRefreshDraftOrders();
        this.subService.callRefreshOrders();
      }

      if (this.vaultRequestGuid != '' && this.completedVaultRequest) {
        // refresh open vault request
        this.subService.callRefreshVaultRequest();
        this.subService.callRefreshVaultSearch();
      }

      if (this.vaultAssetGuid != '' && this.madeVaultAssetEdits) {
        // refresh vault search
        this.subService.callRefreshVaultSearch();
      }

      if (this.vaultDigitalAssetLineItemId != 0 && this.madeVaultDigitalAssetEdits) {
        // refresh vault search
        this.subService.callRefreshVaultDigitalAssetSearch();
      }


      this.showPopup = false;

      this.spotFileGuid = '';
      this.orderGuid = '';

      this.orderDraftGuid = '';
      this.uploadedOrderGuid = '';
      this.copyOrderGuid = '';

      this.vaultRequestGuid = '';
      this.vaultAssetGuid = '';
      this.vaultDigitalAssetLineItemId = 0;

      this.userProfileGuid = '';
      this.clientGuid = '';
      this.brandGuid = '';
      this.destinationGuid = '';

      this.madeOrderEdits = false;
      this.madeSpotEdits = false;

      this.madeDraftOrderEdits = false;
      this.madeUploadedOrderEdits = false;

      if (document.body.classList.contains('no-scroll-body'))
        document.body.classList.remove('no-scroll-body');
    }
  }

  public openPopover(t: any) {
    this.spotFileGuid = t.spotFileGuid;
    this.orderGuid = t.orderGuid;
    this.orderDraftGuid = t.orderDraftGuid;
    this.uploadedOrderGuid = t.uploadedOrderGuid;
    this.copyOrderGuid = t.copyOrderGuid;
    this.copyOptions = t.copyOptions;
    this.vaultRequestGuid = t.vaultRequestGuid;
    this.vaultAssetGuid = t.vaultAssetGuid;
    this.userProfileGuid = t.userProfileGuid;
    this.clientGuid = t.clientGuid;
    this.brandGuid = t.brandGuid;
    this.destinationGuid = t.destinationGuid;
    this.vaultDigitalAssetLineItemId = t.vaultDigitalAssetLineItemId;


    this.statusLabel = t.statusLabel;

    this.showPopup = true;

    var sidebarDiv = document.getElementById("sidebar");

    if (sidebarDiv != null && sidebarDiv.classList.contains('active'))
      this.sidebarActive = true;
    else
      this.sidebarActive = false;

    if (!document.body.classList.contains('no-scroll-body'))
      document.body.classList.add('no-scroll-body');
  }

  private initWebStatusCheck(url, frequency) {

    setInterval(() => {
      this.checkWebStatus(url);
    }, frequency);
  }

  private checkWebStatus(url): boolean {
    var header = new HttpHeaders();

    header = header.append("Skip-Token", "skip");

    this.http.get(url + environment.version, { headers: header }).pipe(
      first())
      .subscribe(
        (response: any) => {

          if (response.isSuccess == true) {
            var webStatus = response.result as WebStatus;
            this.notifyUser(webStatus);
          }
          else
            console.warn("WebStatus check call failed");
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );

    return false;
  }

  private notifyUser(webStatus: WebStatus) {

    //if the application is under maintenance then refresh immediately to show the maintenance page.
    if (webStatus.isDown == true) {
      location.reload();
      return;
    }

    //if no new version available then exit
    if (webStatus.newWebVersionAvailable == false)
      return;

    // new version available. refresh immediately if the update is critical or user is logged-out.
    if (this.showNavBar == false || webStatus.isCritical == true) {
      location.reload();
      return;
    }

    // new version available. user is still logged-in and not very critical. show message to the user about the update.
    if (this.versionWarningPopupVisible == true)
      return;

    var initialState = {
      headerMessage: Constants.newVersionHeader,
      mainMessage: Constants.newVersionRedirectionMessage
    };

    this.modalRef = this.modalService.show(InfoPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.versionWarningPopupVisible = true;

    this.modalRef.content.onClose.subscribe(result => {
      location.reload();
    });
  }
}
