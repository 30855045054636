import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { confirmEventType } from '../../../configurations/enums/enums';
import { CreateTaggerUserModel, LocationModel, TaggerGroupRequestModel } from '../../../models/tagger/tagger.model';
import { UserProfileSelectionModel } from '../../../models/user/user.profile.model';
import { AdminService } from '../../../services/admin/admin.service';
import { AuthService } from '../../../services/auth/auth.service';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { TaggerService } from '../../../services/tagger/tagger.service';
import { ConfirmPopupComponent } from '../confirmationpopups/confirmpopup.component';
import { TaggeruserpopupComponent } from '../taggeruserpopup/taggeruserpopup.component';

@Component({
  selector: 'app-add-tagger-user-popup',
  templateUrl: './add-tagger-user-popup.component.html',
  styleUrls: ['./add-tagger-user-popup.component.css']
})
export class AddTaggerUserPopupComponent implements OnInit {
  groupLocationsGrid: DxDataGridComponent;

  public taggerUserRequest: FormGroup;
  public isFormSubmitted: boolean = false;
  public modalRef: BsModalRef;

  public modalTitle: string;
  @Input() locationsByGroup: LocationModel[];

  @Input() locationGuid: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() title: string;
  @Input() phoneNumber: string;
  @Input() userProfileId: string;
  @Input() userProfileGuid: string;
  @Input() isEditUser: boolean = false;

  public onClose: Subject<boolean>;

  public locationDataSource: LocationModel[];

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private taggerService: TaggerService,
    public util: Utilities,
    private authService: AuthService,
    private modalService: BsModalService,
    public adminService: AdminService
  ) {
    
  }

  ngOnInit(): void {
    if (this.isEditUser) {
      this.modalTitle = "EDIT USER";
    } else {
      this.modalTitle = "ADD NEW USER";
    }

    this.taggerUserRequest = new FormGroup({
      location: new FormControl(this.locationGuid, Validators.required),
      userName: new FormControl(this.email, Validators.required),
      firstName: new FormControl(this.firstName, Validators.required),
      lastName: new FormControl(this.lastName, Validators.required),
      title: new FormControl(this.title, Validators.required),
      phoneNumber: new FormControl(this.phoneNumber),
      isNewUser: new FormControl(!this.isEditUser),
      isEditUser: new FormControl(this.isEditUser),
      userProfileId: new FormControl(this.userProfileId),
      userProfileGuid: new FormControl(this.userProfileGuid),
      isExistingTandemUser: new FormControl(false)
    });

    this.onClose = new Subject();
    this.getLocations();
  }

  public getLocations() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getLocations().subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.locationDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }


  public onSubmitUser({ value, valid }: { value: CreateTaggerUserModel, valid: boolean }) {
    this.isFormSubmitted = true;
    console.log(valid);
    console.log(value);
    var locations: string[] = [];
    locations.push(value.location)
    value.locations = locations;

    if (!valid) {
      return;
    }

    if (this.userProfileGuid != null) {
      this.updateUser(value)
    } else {
      this.addNewUser(value);
    }
  }

  public addNewUser(user: CreateTaggerUserModel) {
    this.alertService.ShowLoader(true);
    this.taggerService.addTaggerUser(user).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_add_user, MessageSeverity.success, true);
        this.onClose.next(true);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", res.errors.length > 0 ? res.errors[0] : Constants.failed_add_user, MessageSeverity.error, true);
      }
    });
  }

  public updateUser(user: CreateTaggerUserModel) {
    this.alertService.ShowLoader(true);
    this.taggerService.updateTaggerUser(user, this.userProfileGuid).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.editUserProfileSuccessful, MessageSeverity.success, true);
        this.onClose.next(true);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", res.errors.length > 0 ? res.errors[0] : Constants.failed_add_user, MessageSeverity.error, true);
      }
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public deleteUserClicked() {
    console.log(this.userProfileId);
    console.log(this.userProfileGuid);

    this.taggerService.getUserLocations(this.userProfileGuid).subscribe((res: any) => { //change this to check if user is associated with only 1 location
      var userLocations = res.result as LocationModel[];
      console.log(userLocations);
      if (userLocations == null) {
        return;
      }
      if (userLocations != null && userLocations.length == 1) {
        var initialState = {
          eventType: confirmEventType[confirmEventType.DeleteUser]
        };

        this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        this.modalRef.content.onClose.subscribe(result => {
          if (result == true) {
            this.userStatusUpdate(false);
          }
        });
      }
      else {
        this.userStatusUpdate(true);
      }
    });
  }

  public userStatusUpdate(isActive: boolean) {
    this.taggerService.updateUserProfileStatus(this.userProfileGuid, { isActive: isActive, locationGuid: this.locationGuid }).subscribe((res: any) => {
      var result = res.result;
      console.log(result);
      if (res.isSuccess == true) {
        this.onClose.next(true);
        this.closePopup();
        this.alertService.showMessage("SUCCESS", Constants.userProfileStatusUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

}
