export class LogMessage {
  public message: string;
  public level: string;
}

export enum LogSeverity {
  info,
  warn,
  trace,
  debug,
  fatal,
  error
}