import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Utilities } from "../../../../../../services/core/utilities";
import { UserRoles } from "../../../../../../configurations/user-roles";

@Component({
  selector: 'edit-user-roles',
  templateUrl: './edit-user-roles.component.html',
  styleUrls: ['./edit-user-roles.component.css']
})

export class EditUserRolesComponent implements OnInit {

  @Input() addUserRolesFormGroup: FormGroup;

  @Output() onMediaApproverRoleUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public util: Utilities,
    private userRoles: UserRoles) {

  }

  ngOnInit() {
    console.log(this.addUserRolesFormGroup);
  }

  public get addUserRolesFormGroupArray() {
    return <FormArray>this.addUserRolesFormGroup.get('userRoles');
  }

  public onRoleValueChanged(e: any, i: number) {
    if (this.util.notEmpty(e)) {
      var rolesArray = <FormArray>this.addUserRolesFormGroup.get('userRoles') as FormArray;
      var currentRole = rolesArray.at(i).value;
      if (currentRole.roleName == this.userRoles.getMediaApproverRoleName()) {
        this.onMediaApproverRoleUpdated.emit(currentRole.isChecked);
      }
    }
  }
}
