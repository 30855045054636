
import { finalize } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { OrderFile } from '../../../models/order/order-create.model';
import { UploadOrderFileRequest } from '../../../models/order/order-uploadfile.model';
import { OrderService } from '../../../services/order/order.service';
import { element } from 'protractor';
import { UploadOrderFileService } from '../../../services/order/uploadorderfile.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'selectattachmentcategorypopup',
  templateUrl: './selectattachmentcategorypopup.component.html',
  styleUrls: ['./selectattachmentcategorypopup.component.css'],
})
export class SelectAttachmentCategoryPopupComponent extends BasePopupComponent {

  @Input() attachmentCategories: Array<any>;
  @Input() orderGuid: string;
  @ViewChild('selectedFile', { static: false }) selectedFile: ElementRef;

  public onSelectAttachmentCategory: Subject<OrderFile>;
  public attchmentCategoryRequest: FormGroup;
  public isUploading: boolean = false;
  public attachedFiles: Array<OrderFile> = [];
  public errorMsg: string = '';
  public acceptFileTypes: string = "*";

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private uploadOrderFileService: UploadOrderFileService) {
    super();
    this.onSelectAttachmentCategory = new Subject();
  }

  ngOnInit() {

    super.ngOnInit();

    this.attchmentCategoryRequest = new FormGroup({
      selectedCategory: new FormControl(null, Validators.required)
    });

    if (this.attachmentCategories != null && this.attachmentCategories.length == 1) {
      this.attchmentCategoryRequest.controls['selectedCategory'].setValue(this.attachmentCategories[0].name);
      this.onChangeCategory(null);
    }
  }

  public onChangeCategory(e) {

    var fileTypes: string = (this.attachmentCategories.filter(t => t.name == this.attchmentCategoryRequest.controls['selectedCategory'].value)[0]).fileTypes;
    this.acceptFileTypes = "";

    if (fileTypes == '*') {
      this.acceptFileTypes = "*";
      this.errorMsg = "";
    }
    else {
      this.errorMsg = "Accepted File types:";

      var types: Array<string> = fileTypes.split('|');

      types.forEach(ft => {
        if (ft == 'pdf') {
          this.acceptFileTypes += "application/pdf,";
          this.errorMsg += ' pdf';
        }
        else if (ft == 'jpeg') {
          this.acceptFileTypes += "application/jpeg,";
          this.errorMsg += ' jpeg';
        }
      });
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onAttachFile(event: any) {

    if (event.target.files && event.target.files.length > 0) {

      if (this.attchmentCategoryRequest.controls['selectedCategory'].errors) {
        this.selectedFile.nativeElement.value = "";
        return;
      }

      for (const uploadFile of event.target.files) {
        if (this.acceptFileTypes != '*' && this.acceptFileTypes.indexOf(uploadFile.type) < 0) {
          //this.attchmentCategoryRequest.controls['selectedCategory'].setErrors({ 'invalid': true });
          this.isUploading = false;
          this.selectedFile.nativeElement.value = "";
          this.errorMsg = uploadFile.name + ' is not acceptable file type.'
          return;
        }
      }

      for (const uploadFile of event.target.files) 
      {
        var request: UploadOrderFileRequest = {
          fileType: uploadFile.type,
          fileName: uploadFile.name,
          description: "",
          fileCategory: this.attchmentCategoryRequest.controls['selectedCategory'].value,
          orderGuid: this.orderGuid,
          orderDestinationId: 0
        };

        this.isUploading = true;

        this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
          finalize(() => {
            this.selectedFile.nativeElement.value = "";
            this.isUploading = false;
          }))
          .subscribe((newFile: any) => {
            if (newFile != null && newFile != undefined) {
              this.attachedFiles.push(newFile);
              this.onSelectAttachmentCategory.next(newFile);
            }
          }, error => {
            this.closePopup();
            this.util.handleError(error);
          });
      }
    }
    else
      this.isUploading = false;
  }
}
