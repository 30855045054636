import { Component } from '@angular/core';
//import { Validators } from '@angular/forms';
//import { Utilities } from '../../../../services/core/utilities';
//import { OrderService } from '../../../../services/order/order.service';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-internationalordermgmtsupport',
  templateUrl: './serviceinternationalordermgmtsupport.component.html'
})
export class ServiceInternationalOrderMgmtsupportQComponent extends BaseServiceDataCollectionComponent {

  //public vendors: Array<any> = [];
  constructor() {
  //constructor(private orderService: OrderService, private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    //if (this.allowSpecialOptions) {

    //  this.formGroup.get('vendor').setValidators([Validators.required]);
    //  this.formGroup.get('vendor').updateValueAndValidity();

    //  this.getVendors();
    //}
  }

  //private getVendors() {

  //  var code = this.formGroup.get('serviceCode').value;

  //  this.orderService.getServiceVendors(code).subscribe((res: any) => {
  //    if (res.isSuccess == true) {
  //      var allVendors = res.result as Array<any>;

  //      allVendors.forEach(ven => {
  //        this.vendors.push({ name: ven.name, value: ven.code });
  //      });
  //    }
  //    else {
  //      this.util.handleIsNotSuccess(res.errors);
  //    }
  //  },
  //    error => {
  //      if (this.util.handleError(error)) { }
  //      //this.closePopup();
  //    });
  //}
}
