import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-stdconversion',
  templateUrl: './servicestdconversion.component.html'
})
export class ServiceStdConversionQComponent extends BaseServiceDataCollectionComponent {

  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.isDisabled = this.formGroup.controls["title"].disabled;

    if (this.applyAllMode === true) {
      this.formGroup.controls["title"].clearValidators();
      this.formGroup.controls["title"].updateValueAndValidity();
    }
    else {
      if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0)
        this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileSpecs[0].ppFileTitle);
      else
        this.formGroup.controls["title"].setValue('');
      //this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileTitle);
    }
  }
}
