import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-harddriveformatting',
  templateUrl: './serviceharddriveformatting.component.html'
})
export class ServiceHardDriveFormattingQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();
  }
}
