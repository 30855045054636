import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LocalStoreManager } from '../core/local-store-manager.service';
import { EndpointFactory } from '../../configurations/endpoint-factory.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AlertService } from '../core/alert.service';

@Injectable()
export class ReportService {

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getVendorPurchaseOrderPDF(request: any) {
    return this.http.post(this.apiendPoint.getVendorPurchaseOrderPDFEndPoint(), request);
  }

  public getDeliveryConfirmationReport(request: any) {
    return this.http.post(this.apiendPoint.getDeliveryConfirmationReportEndPoint(), request);
  }

  public getOrderSummaryReportPDF(id: any) {
    return this.http.get(this.apiendPoint.getOrderSummaryReportPDFEndPoint() + "/" + id);
  }

  public getReportConfig() {
    return this.http.get(this.apiendPoint.getReportConfigEndPoint());
  }

  public generateOnDemandReport(request: any) {
    return this.http.post(this.apiendPoint.generateOnDemandReportEndPoint(), request);
  }

  public getScheduledReportFiles(reportGuid: any) {
    return this.http.get(this.apiendPoint.getScheduledReportFilesEndPoint() + "/" + reportGuid);
  }

  public getReportFile(request: any) {
    return this.http.post(this.apiendPoint.getReportFileEndPoint(), request);
  }
}
