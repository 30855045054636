import { Component, OnInit } from "@angular/core";
import { RouteConstants } from "../../../configurations/route-constants";
import {
  AdCreatorStepEnum,
  TaggableDurationEnum,
  TaggerTabs,
} from "../../../configurations/enums/enums";
import {
  FormControl,
  FormGroup,
  RequiredValidator,
  Validators,
} from "@angular/forms";
import { Utilities } from "../../../services/core/utilities";
import { UserRoles } from "../../../configurations/user-roles";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertService,
  MessageSeverity,
} from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import {
  CreateDraftRequestModel,
  CreateOrderRequestModel,
  DraftOrderResponseModel,
  TaggedAdResponseModel,
  UpdateOrderRequestModel,
} from "../../../models/tagger/tagger.model";
import { UUID } from "angular2-uuid";
import { Constants } from "../../../configurations/constants";

enum TaggingStatusEnums {
  Draft = 1,
  Received,
  Processing,
  Success,
  PartialSuccess,
  Failed,
  Canceled,
  ReadyForBilling,
  SentToBilling,
  BillingFailed,
  Billed,
  AdCreated,
  SlateCreated,
}

@Component({
  selector: "app-tagger-form",
  templateUrl: "./tagger-form.component.html",
  styleUrls: ["./tagger-form.component.css"],
})
export class TaggerFormComponent implements OnInit {
  public isLastStep: boolean;
  public isFirstStep: boolean;
  public currentStep: AdCreatorStepEnum = AdCreatorStepEnum.selectCreative;
  public isAdCreatorSubmitted: boolean;
  public adCreatorStepEnum = AdCreatorStepEnum;

  public topFormGroup: FormGroup;
  public draftModel: CreateDraftRequestModel;
  public isEdit: boolean = false;
  public isEditDraft: boolean = false;
  public draftOrderGuid: string = null;
  public adGuid: string = null;
  public orderGuid: string = null;
  public activeTab = TaggerTabs.taggedAds;
  public editOrder: TaggedAdResponseModel = null;
  public requestOrigination: any;
  public requestBaseCreatives: any;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private taggerService: TaggerService
  ) {
    if (
      this.router != null &&
      this.router.getCurrentNavigation() != null &&
      this.router.getCurrentNavigation().extras != null &&
      this.router.getCurrentNavigation().extras.state != null &&
      this.router.getCurrentNavigation().extras.state.creativeTagsOrigination !=
        null
    ) {
      this.requestBaseCreatives =
        this.router.getCurrentNavigation().extras.state.baseCreatives;
      this.requestOrigination =
        this.router.getCurrentNavigation().extras.state.creativeTagsOrigination;
    }
  }

  ngOnInit() {
    this.isLastStep = false;
    this.isFirstStep = true;
    this.currentStep = AdCreatorStepEnum.selectCreative;
    this.isAdCreatorSubmitted = false;

    this.isEdit = this.route.snapshot.queryParams["isEdit"] || false;
    this.isEditDraft = this.route.snapshot.queryParams["isEditDraft"] || false;
    this.draftOrderGuid =
      this.route.snapshot.queryParams["draftOrderGuid"] || null;
    this.adGuid = this.route.snapshot.queryParams["adGuid"] || null;
    this.orderGuid = this.route.snapshot.queryParams["orderGuid"] || null;
    this.activeTab =
      this.route.snapshot.queryParams["activeTab"] || TaggerTabs.taggedAds;

    this.topFormGroup = new FormGroup({
      name: new FormControl("", [Validators.required]),
      baseCreatives: new FormControl([], [Validators.required]),
      offerCards: new FormControl([]),
      endCards: new FormControl([], [Validators.required]),
      endCardVoiceOvers: new FormControl([]),
    });

    this.draftModel = {
      draftOrderGuid: UUID.UUID(),
      status: "Draft",
      isActive: true,
    };

    if (this.requestOrigination == "CreativeTags") {
      this.topFormGroup.patchValue({
        name: "",
        baseCreatives: this.requestBaseCreatives,
        offerCards: [],
        endCards: [],
        endCardVoiceOvers: [],
      });
      this.moveToSelectOfferCardStep();
    }

    if (this.isEditDraft && this.draftOrderGuid) {
      // get the draft
      this.getEditDraftOrder();
    }

    if (this.isEdit && this.adGuid && this.orderGuid) {
      // get the Ad
      this.getEditOrder();
    }
  }

  get taggerName() {
    return this.topFormGroup.get("name").value || "";
  }

  get selectedBaseCreatives() {
    return this.topFormGroup.get("baseCreatives").value || [];
  }

  get selectedOfferCards() {
    return this.topFormGroup.get("offerCards").value || [];
  }

  get selectedEndCards() {
    return this.topFormGroup.get("endCards").value || [];
  }

  get selectedEndCardVoiceOvers() {
    return this.topFormGroup.get("endCardVoiceOvers").value || [];
  }

  private getEditDraftOrder() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getEditDraftOrder(this.draftOrderGuid).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          const draftOrder = res.result as DraftOrderResponseModel;
          this.topFormGroup.patchValue({
            name: draftOrder.adName || "",
            baseCreatives: draftOrder.baseCreativeDetails.baseCreative
              ? [draftOrder.baseCreativeDetails.baseCreative]
              : [],
            offerCards: draftOrder.offerCardDetails.offerCard
              ? [draftOrder.offerCardDetails.offerCard]
              : [],
            endCards: draftOrder.endCardDetails.endCard
              ? [draftOrder.endCardDetails.endCard]
              : [],
            endCardVoiceOvers: draftOrder.voiceOverDetails.voiceOver
              ? [draftOrder.voiceOverDetails.voiceOver]
              : [],
          });
          this.draftModel = draftOrder;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getEditOrder() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getAdOrder(this.orderGuid, this.adGuid).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          const order = res.result;
          this.topFormGroup.patchValue({
            name: order.adName || "",
            baseCreatives: order.baseCreativeDetails.baseCreative
              ? [order.baseCreativeDetails.baseCreative]
              : [],
            offerCards: order.offerCardDetails.offerCard
              ? [order.offerCardDetails.offerCard]
              : [],
            endCards: order.endCardDetails.endCard
              ? [order.endCardDetails.endCard]
              : [],
            endCardVoiceOvers: order.voiceOverDetails.voiceOver
              ? [order.voiceOverDetails.voiceOver]
              : [],
          });
          this.editOrder = order;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public gotoAdCreators(res) {
    this.router.navigate([RouteConstants.taggerRoute], {
      queryParams: {
        activeTab:
          this.activeTab == TaggerTabs.savedDraftAds && res
            ? TaggerTabs.taggedAds
            : this.activeTab,
      },
    });
  }

  public onSaveAsDraft() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const formValue = this.topFormGroup.value;

    this.draftModel = {
      ...this.draftModel,
      adName: formValue.name || "",
      baseCreativeGuid:
        (formValue.baseCreatives &&
          formValue.baseCreatives.length &&
          formValue.baseCreatives[0].baseCreativeGuid) ||
        null,
      offerCardGuid:
        (formValue.offerCards &&
          formValue.offerCards.length &&
          formValue.offerCards[0].cardGuid) ||
        null,
      endCardGuid:
        (formValue.endCards &&
          formValue.endCards.length &&
          formValue.endCards[0].cardGuid) ||
        null,
      voiceOverGuid:
        (formValue.endCardVoiceOvers &&
          formValue.endCardVoiceOvers.length &&
          formValue.endCardVoiceOvers[0].voiceOverGuid) ||
        null,
    };

    if (this.isEditDraft && this.draftOrderGuid) {
      this.taggerService.updateDraft(this.draftModel).subscribe(
        (res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage(
              "SUCCESS",
              Constants.updateTaggerSuccessful,
              MessageSeverity.success
            );
            this.gotoAdCreators(false);
          } else {
            this.util.handleIsNotSuccess(res.errors);
          }

          this.alertService.ShowLoader(false);
        },
        (error) => {
          // this.util.handleError(error);
          this.alertService.ShowLoader(false);
        }
      );
    } else {
      this.taggerService.saveAsDraft(this.draftModel).subscribe(
        (res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage(
              "SUCCESS",
              Constants.draftTaggerSuccessful,
              MessageSeverity.success
            );
            this.gotoAdCreators(false);
          } else {
            this.util.handleIsNotSuccess(res.errors);
          }

          this.alertService.ShowLoader(false);
        },
        (error) => {
          // this.util.handleError(error);
          this.alertService.ShowLoader(false);
        }
      );
    }
  }

  public onNext() {
    if (this.currentStep == AdCreatorStepEnum.selectCreative) {
      if (!this.validateSelectCreativeStep()) return;
      if (this.selectedBaseCreatives.length > 0) {
        if (this.selectedBaseCreatives[0].taggableDurationInSecs  === TaggableDurationEnum.THREE) {
          this.moveToSelectEndCardStep();
          return;
        }
      }
      this.moveToSelectOfferCardStep();
    } else if (this.currentStep == AdCreatorStepEnum.selectOfferCard) {
      if (!this.validateAdTitleName()) {
        this.topFormGroup.get("name").invalid;
        this.topFormGroup.get("name").errors.required;
        this.topFormGroup.get("name").markAsDirty();
        return;
      }
      this.moveToSelectEndCardStep();
    } else if (this.currentStep == AdCreatorStepEnum.selectEndCard) {
      if (!this.validateSelectEndCardStep()) return;

      this.moveToSelectEndCardVoiceOverStep();
    } else if (this.currentStep == AdCreatorStepEnum.selectEndCardVoiceOver) {
      this.moveToReviewAdCreatorStep();
    }
  }

  public onPrevious() {
    if (this.currentStep == AdCreatorStepEnum.selectOfferCard) {
      if (!this.validateAdTitleName()) {
        this.topFormGroup.get("name").invalid;
        this.topFormGroup.get("name").errors.required;
        this.topFormGroup.get("name").markAsDirty();
        return;
      }
      this.moveToSelectCreativeStep();
    } else if (this.currentStep == AdCreatorStepEnum.selectEndCard) {
      if (this.selectedBaseCreatives.length > 0) {
        if (this.selectedBaseCreatives[0].taggableDurationInSecs  === TaggableDurationEnum.THREE) {
          this.moveToSelectCreativeStep();
          return;
        }
      }
      this.moveToSelectOfferCardStep();
    } else if (this.currentStep == AdCreatorStepEnum.selectEndCardVoiceOver) {
      this.moveToSelectEndCardStep();
    } else if (this.currentStep == AdCreatorStepEnum.reviewAdCreator) {
      this.moveToSelectEndCardVoiceOverStep();
    }
  }

  private moveToSelectCreativeStep() {
    this.currentStep = AdCreatorStepEnum.selectCreative;
    this.isFirstStep = true;
    this.isLastStep = false;
  }

  private moveToSelectOfferCardStep() {
    this.currentStep = AdCreatorStepEnum.selectOfferCard;
    this.isFirstStep = false;
    this.isLastStep = false;
  }

  private moveToSelectEndCardStep() {
    this.currentStep = AdCreatorStepEnum.selectEndCard;
    this.isFirstStep = false;
    this.isLastStep = false;
  }

  private moveToSelectEndCardVoiceOverStep() {
    this.currentStep = AdCreatorStepEnum.selectEndCardVoiceOver;
    this.isFirstStep = false;
    this.isLastStep = false;
  }

  private moveToReviewAdCreatorStep() {
    this.currentStep = AdCreatorStepEnum.reviewAdCreator;
    this.isFirstStep = false;
    this.isLastStep = true;
  }

  private validateSelectCreativeStep(): boolean {
    return (
      this.selectedBaseCreatives.length > 0 &&
      this.taggerName !== null &&
      this.taggerName !== ""
    );
  }

  private validateAdTitleName(): boolean {
    return this.taggerName !== null && this.taggerName !== "";
  }

  private validateSelectEndCardStep(): boolean {
    return this.selectedEndCards.length > 0;
  }

  public onAdCreate() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const formValue = this.topFormGroup.value;
    const payload: CreateOrderRequestModel = {
      orderGuid: this.draftOrderGuid,
      adName: formValue.name || "",
      baseCreativeGuid:
        (formValue.baseCreatives &&
          formValue.baseCreatives.length &&
          formValue.baseCreatives[0].baseCreativeGuid) ||
        null,
      offerCardGuid:
        (formValue.offerCards &&
          formValue.offerCards.length &&
          formValue.offerCards[0].cardGuid) ||
        null,
      endCardGuid:
        (formValue.endCards &&
          formValue.endCards.length &&
          formValue.endCards[0].cardGuid) ||
        null,
      voiceOverGuid:
        (formValue.endCardVoiceOvers &&
          formValue.endCardVoiceOvers.length &&
          formValue.endCardVoiceOvers[0].voiceOverGuid) ||
        null,
    };
    console.log(payload);
    this.taggerService.adCreate(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.createTaggerSuccessful,
            MessageSeverity.success
          );
          this.gotoAdCreators(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        // this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onUpdateAd() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const formValue = this.topFormGroup.value;
    const payload: UpdateOrderRequestModel = {
      ...this.editOrder,
      adName: formValue.name || "",
      baseCreativeGuid:
        (formValue.baseCreatives &&
          formValue.baseCreatives.length &&
          formValue.baseCreatives[0].baseCreativeGuid) ||
        null,
      offerCardGuid:
        (formValue.offerCards &&
          formValue.offerCards.length &&
          formValue.offerCards[0].cardGuid) ||
        null,
      endCardGuid:
        (formValue.endCards &&
          formValue.endCards.length &&
          formValue.endCards[0].cardGuid) ||
        null,
      voiceOverGuid:
        (formValue.endCardVoiceOvers &&
          formValue.endCardVoiceOvers.length &&
          formValue.endCardVoiceOvers[0].voiceOverGuid) ||
        null,
    };

    if (this.isEdit && this.adGuid && this.orderGuid) {
      this.taggerService.updateAd(payload).subscribe(
        (res: any) => {
          if (res.isSuccess == true) {
            this.gotoAdCreators(true);
            this.alertService.showMessage(
              "SUCCESS",
              Constants.createTaggerSuccessful,
              MessageSeverity.success
            );
          } else {
            this.util.handleIsNotSuccess(res.errors);
          }

          this.alertService.ShowLoader(false);
        },
        (error) => {
          // this.util.handleError(error);
          this.alertService.ShowLoader(false);
        }
      );
    }
  }

  public gotoTaggerHome() {
    this.router.navigate([RouteConstants.taggerRoute]);
  }
}
