import { Component, OnInit } from "@angular/core";
import { Params, ActivatedRoute, Router } from "@angular/router";
import { Utilities } from "../../services/core/utilities";
import { RouteConstants } from "../../configurations/route-constants";

@Component({
  selector: 'mediadetail',
  templateUrl: './mediadetail.component.html',
  styleUrls: ['./mediadetail.component.css'],
})
export class MediaDetailComponent implements OnInit {

  public spotFileGuid: string;

  constructor(private route: ActivatedRoute, private router: Router, public util: Utilities) {
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.spotFileGuid = params['spotFileGuid'];
    });
  }

  public gotoMedia() {
    this.router.navigate([RouteConstants.mediaRoute]);
  }
}
