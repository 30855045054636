<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header media-text">
      <h3 class="media-text">Quick Link</h3>
    </div>
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li (click)="gotoMedia()">
          <a class="back-to-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.37 108.37">
              <!--<title>Asset 12</title>-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M54.19,0a54.19,54.19,0,1,0,54.18,54.18A54.19,54.19,0,0,0,54.19,0Zm6,82.36L32.51,54.76,58.42,28.84l6.8,6.86-19,19L67,75.62Z" />
                </g>
              </g>
            </svg>
            <span class="back_to_nav">Back to All Assets</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="inner-content" *ngIf="quickLinkDetail">
  <div class="order-intractions">
    <div class="row">
      <div class="order-info-container">
        <div class="col-md-4">
          <h5 class="order-number">Request# {{quickLinkDetail.requestNo}}</h5>
          <div class="p-normal">
            <span><b>Client</b></span>
            <span> {{quickLinkDetail.client}}</span>
          </div>
          <div class="submit-date p-normal">
            <span>
              <b>Submitted</b>
            </span>
            <span>
              on {{quickLinkDetail.createdDate | usertimezone: 'LL' }}
              at {{quickLinkDetail.createdDate | usertimezone: 'HH:mm'}}
            </span>
          </div>
          <div class="submit-date p-normal">
            <span>
              <b>Expires</b>
            </span>
            <span>
              on {{quickLinkDetail.expiryDate | usertimezone: 'LL' }}
              at {{quickLinkDetail.expiryDate | usertimezone: 'HH:mm'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="quicklink-progress" *ngFor="let quickLinkMedia of quickLinkDetail.quickLinkMedia">
    <!--<table class="table table-noborders">
      <tbody>
        <tr>
          <td width="25%">
            <p class="black p-normal"><b>Thumbnail</b></p>
            <div class="spot-search-image">
              <img class="gridThumbnail" [src]="quickLinkMedia.thumbnail" />
            </div>
          </td>
          <td width="25%">
            <p class="black p-normal"><b>Ad-ID</b></p>
            <div class="black p-normal">{{quickLinkMedia.adId}}</div>
          </td>
          <td width="25%">
            <p class="black p-normal"><b>Title</b></p>
            <div class="black p-normal">{{quickLinkMedia.title}}</div>
          </td>
          <td width="25%">
            <div class="comment">
              <p class="black"><b>Transcode Spec</b></p>
              <div class="black p-normal">{{quickLinkMedia.spec}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>-->
    <div class="progress-container">
      <div class="progress progress-left">
        <div class="progress-bar" role="progressbar"
             [attr.aria-valuenow]="50"
             aria-valuemin="0"
             aria-valuemax="100"
             [style.width]="(50 + '%')">
        </div>
      </div>
      <div class=" progress-right">
        <p>{{quickLinkMedia.status}}</p>
      </div>
      <div class="progress-download-right">
        <!--<i class="glyphicon glyphicon-upload" [ngClass]="{submitted: quickLinkMedia.status == 'Submitted', processing: quickLinkMedia.status == 'Processing', '': quickLinkMedia.status == 'Failed'}" *ngIf="quickLinkMedia.status != 'completed'">
        </i>-->
        <i class="" *ngIf="quickLinkMedia.status == 'Completed'">
          <svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <style type="text/css">
            .st0 {
              fill: #59bc25;
            }

            .st1 {
              fill: #fff;
            }
</style><g><circle class="st0" cx="64" cy="64" r="64"></circle></g><g>
          <path class="st1" d="M54.3,97.2L24.8,67.7c-0.4-0.4-0.4-1,0-1.4l8.5-8.5c0.4-0.4,1-0.4,1.4,0L55,78.1l38.2-38.2   c0.4-0.4,1-0.4,1.4,0l8.5,8.5c0.4,0.4,0.4,1,0,1.4L55.7,97.2C55.3,97.6,54.7,97.6,54.3,97.2z"></path></g>
               </svg>
        </i>
      </div>
      <div class="file-name">
        <span>Ad-ID: {{quickLinkMedia.adId}}, Title: {{quickLinkMedia.title}}, Transcode Spec: {{quickLinkMedia.spec}}</span>
        <!--<p class="error" *ngIf="f.error && f.error.length > 0">{{quickLinkMedia.error}}</p>-->
      </div>
    </div>
  </div>
</div>
