<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">VENDOR PURCHASE ORDERS</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="vendorPOList != null && vendorPOList.length > 0" class="tyl-border-solid tyl-padding tyl-margin-bottom-large">
      <vendor-po-confirmation [vendorPOList]="vendorPOList"></vendor-po-confirmation>
    </ng-container>

    <form [formGroup]="vendorPOForm" (ngSubmit)="onSubmitCreateVendorPOForm(vendorPOForm)" novalidate #ngForm="ngForm">
      <ng-container *ngIf="((vendorPOOrdergroupService != null && vendorPOOrdergroupService.length > 0) || (vendorPOOrderLvlServices != null && vendorPOOrderLvlServices.length > 0))" formArrayName="vendorPOOrderGroupServices">
        <ng-container *ngFor="let groupService of vendorPOForm.get('vendorPOOrderGroupServices').controls; let i = index">
          
            <ordergroupservicevendorporequest [groupService]="groupService"
                                              [groupName]="i"
                                              [parentForm]="ngForm"
                                              [formGroupCount]="vendorPOForm.get('vendorPOOrderGroupServices').controls?.length"
                                              [orderLvlFormGroupCount]="vendorPOForm.get('vendorPOOrderLvlServices').controls?.length"
                                              (onDeleteOrderGroupService)="onDeleteVendorPOServiceEmitted('grpServices', $event)"
                                              (onDeleteOrderMedia)="onDeleteOrderMediaEmitted($event)"
                                              (onVendorChangeDueDateUpdateTriggered)="onVendorChangeDueDateUpdateEmitted('grpServices', $event)"
                                              (onDueDateUpdateTriggered)="onDueDateUpdateEmitted('grpServices', $event)">
            </ordergroupservicevendorporequest>
          
        </ng-container>

        <ng-container *ngFor="let orderService of vendorPOForm.get('vendorPOOrderLvlServices').controls; let i = index">
          <orderlvlservicevendorporequest  [orderService]="orderService"
                                            [groupName]="i"
                                            [parentForm]="ngForm"
                                            [formGroupCount]="vendorPOForm.get('vendorPOOrderLvlServices').controls?.length"
                                            [orderGrpFormGroupCount]="vendorPOForm.get('vendorPOOrderGroupServices').controls?.length"
                                            (onDeleteOrderLvlService)="onDeleteVendorPOServiceEmitted('orderLvlServices', $event)"
                                            (onVendorChangeDueDateUpdateTriggered)="onVendorChangeDueDateUpdateEmitted('orderLvlServices', $event)"
                                            (onDueDateUpdateTriggered)="onDueDateUpdateEmitted('orderLvlServices', $event)">
          </orderlvlservicevendorporequest>
        </ng-container>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs me-3" type="submit">Submit PO</button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
        </div>
      </ng-container>
    </form>
    <div class="tylie-type-body" *ngIf="((vendorPOOrdergroupService == null || vendorPOOrdergroupService.length == 0) && (vendorPOOrderLvlServices == null || vendorPOOrderLvlServices.length == 0))">
      This order has no services that require a  PO creation at this time.
    </div>
  </div>
</div>
