import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { Observable } from "rxjs";
import {
  CreateOrderRequestModel,
  CreateDraftRequestModel,
  SearchDraftRequestModel,
  UpdateOrderRequestModel,
  OrderNote,
  TaggerGroupRequestModel,
  CreateTaggerUserModel,
  CreateLocationModel,
  UpdateLocationGroupModel,
  LocationValidationModel,
} from "../../models/tagger/tagger.model";
import { DraftOrderNote } from "../../models/order/draft-order.model";
import { TaggerAssetValidationModel } from "../../models/upload/upload-asset-form.model";

@Injectable({
  providedIn: "root",
})
export class TaggerService {
  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint
  ) {}

  public getTaggedAds(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.getTaggedAdsEndpoint(), payload);
  }

  public getDraftTaggers(payload: SearchDraftRequestModel): Observable<any> {
    return this.http.post(this.apiendPoint.getDraftTaggersEndpoint(), payload);
  }

  public getAdOrder(orderGuid: string, adGuid: string): Observable<any> {
    return this.http.get(this.apiendPoint.getAdOrderEndpoint(), {
      params: { orderGuid, adGuid },
    });
  }

  public getBaseCreatives(): Observable<any> {
    return this.http.get(this.apiendPoint.getBaseCreativesEndpoint());
  }

  public getOfferCards(): Observable<any> {
    return this.http.get(this.apiendPoint.getOfferCardsEndpoint());
  }

  public getEndCards(): Observable<any> {
    return this.http.get(this.apiendPoint.getEndCardsEndpoint());
  }

  public getEndCardVoiceOvers(): Observable<any> {
    return this.http.get(this.apiendPoint.getEndCardVoiceOversEndpoint());
  }

  public saveAsDraft(payload: CreateDraftRequestModel): Observable<any> {
    return this.http.post(this.apiendPoint.saveAsDraftAdEndpoint(), payload);
  }

  public updateDraft(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.updateDraftAdEndpoint(), payload);
  }

  public adCreate(payload: CreateOrderRequestModel): Observable<any> {
    return this.http.post(this.apiendPoint.adCreateEndpoint(), payload);
  }

  public updateAd(payload: UpdateOrderRequestModel): Observable<any> {
    return this.http.post(this.apiendPoint.updateAdEndpoint(), payload);
  }

  public getEditDraftOrder(draftOrderGuid: string): Observable<any> {
    return this.http.get(
      `${this.apiendPoint.getEditDraftOrder()}/${draftOrderGuid}`
    );
  }

  public addDraftOrderNote(note: DraftOrderNote) {
    return this.http.post(this.apiendPoint.addDraftOrderNoteEndpoint(), note);
  }

  public addOrderNote(note: OrderNote) {
    return this.http.post(this.apiendPoint.addOrderNoteEndpoint(), note);
  }

  public archiveOrder(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.archiveOrderEndpoint(), payload);
  }

  public unArchiveOrder(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.unArchiveOrderEndpoint(), payload);
  }

  // Tagger Assets
  public searchTaggerAssets(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.searchTaggerAssetsEndpoint(),
      payload
    );
  }

  // Tagger Assets
  public uploadTaggerAssets(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.uploadTaggerAssetsEndpoint(),
      payload
    );
  }

  public getGroups(): Observable<any> {
    return this.http.get(this.apiendPoint.getGroupsEndpoint());
  }

  public searchGropus(groupSearchRequest: any) {
    return this.http.post(
      this.apiendPoint.searchGroupsEndpoint(),
      groupSearchRequest
    );
  }

  public getLocations(): Observable<any> {
    return this.http.get(this.apiendPoint.getLocationsEndpoint());
  }

  public updateTaggerAsset(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.updateTaggerAssetEndpoint(),
      payload
    );
  }

  public validateAssetTitle(
    payload: TaggerAssetValidationModel
  ): Observable<any> {
    return this.http.post(
      this.apiendPoint.validateAssetTitleEndpoint(),
      payload
    );
  }

  // Tagger Groups
  public createTaggerGroup(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.createTaggerGroupEndpoint(),
      payload
    );
  }

  public searchLocations(request: any): Observable<any> {
    return this.http.post(
      `${this.apiendPoint.searchLocationsEndpoint()}`,
      request
    );
  }

  public getStates(): Observable<any> {
    return this.http.get(this.apiendPoint.getStatesEndpoint());
  }

  public searchGroupLocations(request: any): Observable<any> {
    return this.http.post(
      `${this.apiendPoint.searchGroupLocationsEndpoint()}`,
      request
    );
  }

  public updateTaggerGroup(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.updateTaggerGroupEndpoint(),
      payload
    );
  }

  public validateDuplicateGroupName(
    payload: TaggerGroupRequestModel
  ): Observable<any> {
    return this.http.post(
      this.apiendPoint.validateGroupNameEndpoint(),
      payload
    );
  }

  public updateTaggerGroupLocations(payload: any): Observable<any> {
    return this.http.post(
      this.apiendPoint.updateTaggerGroupLocationEndpoint(),
      payload
    );
  }

  public addTaggerUser(payload: CreateTaggerUserModel): Observable<any> {
    return this.http.post(this.apiendPoint.addTaggerUserEndpoint(), payload);
  }

  public updateTaggerUser(payload: CreateTaggerUserModel, userProfileGuid: string): Observable<any> {
    return this.http.post(this.apiendPoint.updateTaggerUserEndpoint() + "/" + userProfileGuid, payload);
  }

  public addLocation(payload: CreateLocationModel): Observable<any> {
    return this.http.post(this.apiendPoint.addLocationEndpoint(), payload);
  }

  public updateLocation(payload: CreateLocationModel): Observable<any> {
    return this.http.post(this.apiendPoint.updateLocationEndpoint(), payload);
  }

  public updateLocationGroups(payload: UpdateLocationGroupModel): Observable<any> {
    return this.http.post(this.apiendPoint.updateLocationGroupsEndpoint(), payload);
  }

  public validateDuplicateLocation(
    payload: LocationValidationModel
  ): Observable<any> {
    return this.http.post(
      this.apiendPoint.validateLocationEndpoint(),
      payload
    );
  }

  public updateUserProfileStatus(userProfileGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateTaggerUserProfileStatusEndPoint() + "/" + userProfileGuid, request);
  }


  public loadFeaturedAssets(): Observable<any> {
    return this.http.get(this.apiendPoint.loadFeaturedAssetsEndpoint());
  }


  public deleteTaggerAsset(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.deleteTaggerAssetEndpoint(), payload);
  }

  public updateUploadAssetStatus(payload: any): Observable<any> {
    return this.http.post(this.apiendPoint.updateUploadAssetStatusEndpoint(), payload);
  }

  public getUserLocations(userProfileGuid:string): Observable<any> {
    return this.http.get(this.apiendPoint.getUserLocationsEndpoint() + "/" + userProfileGuid);
  }
}
