export class UserChangePassword {
  oldPassword: string;
  newPassword: string;
  userName: string;

  constructor(oldPassword: string, newPassword: string, userName: string) {
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.userName = userName;
  }
}

export class UserChangePasswordResponse {
  isOldPasswordIncorrect: boolean;
  isNewPasswordMatchedRecentPasswords: boolean;
}

