<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <ng-container *ngIf="orderLevel == true">
    <div class="row" *ngFor="let t of thumbflashdrivesizes; index as i;">
      <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['thumbflashdrivequantity'].errors && f.submitted && t.size == null)}">
        <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Size<span class="required">*</span></label>
        <dx-select-box [dataSource]="driveSizes" [disabled]="isDisabled"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="t.size" (ngModelChange)="onChange()"
                       displayExpr="display" valueExpr="val" class="tylie-select form-control">
        </dx-select-box>
        <p class="p-extra-small">Invalid Size & Quantity</p>
      </div>
      <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['thumbflashdrivequantity'].errors && f.submitted && t.quantity == null)}">
        <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Quantity<span class="required">*</span></label>
        <div class="d-flex">
          <dx-number-box class="tylie-select form-control"
                         [min]="1" [disabled]="isDisabled"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="t.quantity" (ngModelChange)="onChange()"
                         [showSpinButtons]="false"></dx-number-box>
          <a class="tylie-button--xs tylie-button--link mb-2" *ngIf="t.id > 1 && isDisabled === false" (click)="onAddNew(t.id)"><icon-expire color="#1e90ff"></icon-expire></a>
          <a class="tylie-button--xs tylie-button--link mb-2" *ngIf="t.id == 1 && isDisabled === false" (click)="onAddNew(t.id)"><icon-plus-circle color="#1e90ff"></icon-plus-circle></a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="spotLevel == true">

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['address']?.errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="address">Address<span class="required">*</span></label>
      <textarea name="address" id="address" class="tylie-textarea tylie-textarea--md form-control" formControlName="address" rows="4"></textarea>
      <p class="p-extra-small">Please enter Address</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['email'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="email">Email<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="email" formControlName="email" />
      <p class="p-extra-small">Please enter Email</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['phone'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="phone">Phone#<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="phone" formControlName="phone" />
      <p class="p-extra-small">Please enter Phone#</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['trackingNumber'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="trackingNumber">Tracking#<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="trackingNumber" formControlName="trackingNumber" />
      <p class="p-extra-small">Please enter Tracking#</p>
    </div>

  </ng-container>
</div>
