import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { VendorPurchaseOrderViewModel } from "../../../models/order/vendor-purchaseorder-request.model";

@Component({
  selector: 'vendorpurchaseorderconfirmationpopup',
  templateUrl: './vendorpurchaseorderconfirmationpopup.component.html',
  styleUrls: ['./vendorpurchaseorderconfirmationpopup.component.css'],
})
export class VendorPurchaseOrderConfirmationPopupComponent implements OnInit {

  @Input() vendorPOList: VendorPurchaseOrderViewModel[];
  @Input() title: string = "VENDOR PURCHASE ORDERS - CONFIRMATION"

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  public closePopup() {
    this.bsModalRef.hide();
  }

}
