<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="brandName" class="row">
      <div class="col-md-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl">Brand</label>
          <div class="tylie-form-group__txt">{{brandName}}</div>
        </div>
      </div>
    </div>

    <form [formGroup]="clientbrandVendorInfoForm" (ngSubmit)="onclientbrandVendorInfoFormSubmit(clientbrandVendorInfoForm)" novalidate #ngForm="ngForm">

      <div class="row">
        <div class="col-md-6"  [ngClass]="{error: (clientbrandVendorInfoForm.controls['preferredNetworkVendor'].errors
             && !clientbrandVendorInfoForm.controls['preferredNetworkVendor'].pristine)
             || (clientbrandVendorInfoForm.controls['preferredNetworkVendor'].errors && ngForm.submitted)}">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="bundleServices">Preferred Vendor</label>
            <dx-drop-down-box [(value)]="preferredVendorValue"
                              formControlName="preferredNetworkVendor"
                              id="preferredNetworkVendor"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select Vendor"
                              [showClearButton]="true"
                              (onValueChanged)="syncTreeViewSelection()"
                              [dataSource]="pvendorDataSource">
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view [dataSource]="pvendorDataSource"
                              dataStructure="plain"
                              keyExpr="id"
                              #treeServices
                              parentIdExpr="parentId"
                              selectionMode="single"
                              showCheckBoxesMode="normal"
                              displayExpr="name"
                              [selectByClick]="true"
                              (onContentReady)="syncTreeViewSelection($event)"
                              (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
        <div class="col-md-6"  [ngClass]="{error: (clientbrandVendorInfoForm.controls['excludeNetworkVendor'].errors
             && !clientbrandVendorInfoForm.controls['excludeNetworkVendor'].pristine)
             || (clientbrandVendorInfoForm.controls['excludeNetworkVendor'].errors && ngForm.submitted)}">
          <div class="tylie-form-group form-group ">
            <label class="tylie-form-group__lbl" for="bundleServices">Excluded Vendor(s)</label>
            <dx-drop-down-box [(value)]="excludedVendorValue"
                              formControlName="excludeNetworkVendor"
                              id="excludeNetworkVendor"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select Vendors"
                              [showClearButton]="true"
                              (onValueChanged)="syncrbTreeViewSelection()"
                              [dataSource]="evendorDataSource">
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view [dataSource]="evendorDataSource"
                              dataStructure="plain"
                              keyExpr="id"
                              #treeServices
                              parentIdExpr="parentId"
                              selectionMode="multiple"
                              showCheckBoxesMode="normal"
                              displayExpr="name"
                              [selectByClick]="true"
                              (onContentReady)="syncrbTreeViewSelection($event)"
                              (onItemSelectionChanged)="rbtreeView_itemSelectionChanged($event)">
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
