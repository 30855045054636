import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { CreateCustomTagConfig, TagValueChangedEvent, CustomTagConfig } from '../../../models/admin/client/customtagconfig.model';
import { ConfigService } from '../../../services/core/config.service';
import { emptySpacesValidator } from '../../../configurations/custom-validations';


@Component({
  selector: 'addcustomtagconfiguration',
  templateUrl: './addcustomtagconfiguration.component.html',
  styleUrls: ['./addcustomtagconfiguration.component.css'],
})
export class AddCustomTagConfigurationPopUp implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('selectedBrandsDataSource') selectedBrandsDataSource: any[] = [];
  @Input('isBrandsNotSelected') isBrandsNotSelected: boolean = false;
  @Input('brandCustomTags') brandCustomTags: CustomTagConfig[] = [];

  public modalRef: BsModalRef;

  public customTagConfigForm: FormGroup;

  public onSaveCustomTagConfiguration: Subject<CreateCustomTagConfig>;
  public brandsValue: Array<number>;
  public customTagDataTypes: any[] = [];
  public noOfCustomTagsAllowed: number = 0;
  public brandsNotSelected: boolean = false;
  public inActiveCustomTagConfig: CustomTagConfig[] = [];

  @ViewChild('treeBrands', { static: false }) treeBrands: DxTreeViewComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService) {

    this.onSaveCustomTagConfiguration = new Subject<CreateCustomTagConfig>();
  }

  ngOnInit() {
    this.getClientCustomTagConfig();

    this.customTagConfigForm = this.fb.group({
      customTagConfigurations: this.addCustomTagConfigGroupsToFormArray(),
      selectedBrandsForCustomTags: []
    });
  }

  public get getFormCustomTagConfigGroupsFormArray() {
    return <FormArray>this.customTagConfigForm.get('customTagConfigurations');
  }

  public onAddAnotherCustomTag() {
    const group = this.fb.group({
      id: [0],
      tag: [null, Validators.compose([Validators.required, emptySpacesValidator])],
      dataType: [1, Validators.compose([Validators.required])],
      config: [null],
      description: [null],
      isActive: [true]
    });

    this.getFormCustomTagConfigGroupsFormArray.push(group);
  }

  public addCustomTagConfigGroupsToFormArray() {
    var customTagConfigurations = this.fb.array([]);
    if (this.brandCustomTags.length > 0) {
      this.brandCustomTags.forEach(bct => {
        if (this.util.notEmpty(bct)) {
          const group = this.fb.group({
            id: [bct.id],
            tag: [bct.tag, Validators.compose([Validators.required, emptySpacesValidator])],
            dataType: [bct.dataType, Validators.compose([Validators.required])],
            config: [bct.config],
            description: [bct.description],
            isActive: [bct.isActive]
          });

          customTagConfigurations.push(group);
        }
      });
    }
    else {
      const group = this.fb.group({
        id: [0],
        tag: [null, Validators.compose([Validators.required, emptySpacesValidator])],
        dataType: [1, Validators.compose([Validators.required])],
        config: [null],
        description: [null],
        isActive: [true]
      });

      customTagConfigurations.push(group);
    }
    return customTagConfigurations;
  }


  public onaddCustomTagConfigFormFormSubmit({ value, valid }: { value: CreateCustomTagConfig, valid: boolean }) {
    this.brandsNotSelected = false;

    if ((this.selectedBrandsDataSource.length > 0) && (value.selectedBrandsForCustomTags == null || value.selectedBrandsForCustomTags == undefined || value.selectedBrandsForCustomTags.length == 0)) {
      this.brandsNotSelected = true;
      return;
    }

    if (!valid) {
      return;
    }

    value.customTagConfigurations.forEach(ct => {
      var displayDataType = this.customTagDataTypes.find(cd => cd.id == ct.dataType);
      if (displayDataType != undefined) {
        ct.displayDataType = displayDataType.name;
      }
      else {
        ct.displayDataType = "";
      }
    });

    value.inActiveCustomTagConfig = this.inActiveCustomTagConfig;
    this.onSaveCustomTagConfiguration.next(value);
    this.closePopup();
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeBrands && this.treeBrands.instance);

    if (!component) return;

    if (this.brandsValue == null || !this.brandsValue.length) {
      component.unselectAll();
    }

    if (this.brandsValue != null) {
      this.brandsValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.brandsValue = newValues;
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public verifyUniqueTags(eve: TagValueChangedEvent) {
    for (var i = 0; i < this.getFormCustomTagConfigGroupsFormArray.controls.length; i++) {
      var iTagVal = this.getFormCustomTagConfigGroupsFormArray.at(i).get('tag').value;
      if (i != eve.formGroupNumber && iTagVal != null && iTagVal != undefined) {
        if (iTagVal.trim().toLowerCase() == eve.tag.trim().toLowerCase()) {
          this.getFormCustomTagConfigGroupsFormArray.at(eve.formGroupNumber).get('tag').setErrors({ "notUnique": true });
          break;
        }
      }
    }
  }

  public onClickRemoveCustomTag(groupNumber: number) {
    if (this.brandCustomTags != null && this.brandCustomTags != undefined && this.brandCustomTags.length > 0) {
      if (this.getFormCustomTagConfigGroupsFormArray.at(groupNumber).get('id').value > 0) {
        var id = this.getFormCustomTagConfigGroupsFormArray.at(groupNumber).get('id').value;
        var customTag = this.brandCustomTags.find(cd => cd.id == id);
        this.inActiveCustomTagConfig.push(customTag);
      }
      this.getFormCustomTagConfigGroupsFormArray.removeAt(groupNumber);
    }
    else {
      this.getFormCustomTagConfigGroupsFormArray.removeAt(groupNumber);
    }
  }

  private getClientCustomTagConfig() {
    this.configService.getClientCustomTagConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var res = res.result;
        this.customTagDataTypes = res.dataTypes;
        this.noOfCustomTagsAllowed = res.noOfCustomTagsAllowed;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
      });
  }
}
