<div id="spot-resend">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 *ngIf="spotResendData" class="modal-title">DESTINATIONS - RESEND CONFIRMATION</h4>
    <h4 *ngIf="spotResendWarnings" class="modal-title">DESTINATIONS - RESEND WARNINGS</h4>
  </div>
  <div class="modal-body">

    <ng-container *ngIf="spotResendData">
      <div class="">
        <p class="tylie-alert tylie-alert--error mb-2">The following spots were delivered to the following destinations within the last {{spotDestinationOnHandDurationInDays}} days.</p>
        <p class="tylie-alert tylie-alert--warning">To resend any or all of these spots as a new delivery, please check the box in the left column. Normal delivery charges apply.</p>
      </div>

      <div class="mt-4">
        <dx-data-grid id="resendGrid" class="tylie-grid tylie-grid--bordered"
                      #resendGrid
                      [dataSource]="spotResendData"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [hoverStateEnabled]="true"
                      [rowAlternationEnabled]="true"
                      (onRowPrepared)="onRowPrepared($event)"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                         selectAllMode="page"
                         allowSelectAll="Select"
                         showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="destinationCode" width="15%" cssClass="gridStyle" caption="Destination ID" cellTemplate="destinationCodeTemplate" sortOrder="asc"></dxi-column>
          <dxi-column dataField="lastSentDestinationCode" width="14%" cssClass="gridStyle" caption="Last Sent Destination ID"></dxi-column>
          <dxi-column dataField="adId" width="10%" cssClass="gridStyle" caption="Ad-ID" cellTemplate="adIDTemplate"></dxi-column>
          <dxi-column dataField="podDate" width="9%" cssClass="gridStyle" caption="POD (EST)" cellTemplate="dateTemplate"></dxi-column>
          <dxi-column dataField="daysFromLastDelivery" width="13%" cssClass="gridStyle tyl-text-align-left" caption="Days Since Last Delivery"></dxi-column>
          <dxi-column dataField="workOrder" width="10%" cssClass="gridStyle" caption="Order ID" cellTemplate="workOrderTemplate"></dxi-column>
          <dxi-column dataField="orderStatus" width="10%" cssClass="gridStyle" caption="Order Status"></dxi-column>
          <dxi-column dataField="spotFileTitle" width="19%" cssClass="gridStyle" caption="Title"></dxi-column>
          <dxi-column dataField="vendorName" width="10%" cssClass="gridStyle" caption="Network"></dxi-column>

          <div *dxTemplate="let t of 'destinationCodeTemplate'">
            <div style="text-align:left;">{{t.data.destinationCode}}
              <span *ngIf="t.data.isBackupDestination"> (Backup - {{t.data.primaryDestinationCode}})</span></div>
          </div>

          <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{data.value | convertFromUTC:'MM/DD/YYYY' }}</div>
          </div>
          <div *dxTemplate="let t of 'adIDTemplate'">
            <span class="blue-text" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
          </div>
          <div *dxTemplate="let t of 'workOrderTemplate'">
            <span class="blue-text" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
          </div>

          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50, 100]"
                     infoText="Page {0} of {1} ({2} items)"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs" type="button" (click)="onConfirm()">
          CONFIRM
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="spotResendWarnings">

      <div class="">
        <p class="tylie-alert tylie-alert--error mb-2">You are attempting to make a change that will impact other pending orders.</p>
        <p class="tylie-alert tylie-alert--warning">Please update the following pending orders before this requested change is completed.</p>
      </div>

      <div class="mt-4">
        <dx-data-grid id="resendWarningsGrid" class="tylie-grid tylie-grid--bordered"
          [dataSource]="spotResendWarnings"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [columnAutoWidth]="true"
          [showColumnLines]="true"
          [showRowLines]="true"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          keyExpr="id">
            <dxi-column dataField="workOrder" width="15%" cssClass="gridStyle" caption="Order ID" cellTemplate="workOrderTemplate"></dxi-column>
            <dxi-column dataField="groupSequence" width="15%" cssClass="gridStyle" caption="Group" text-align="left"></dxi-column>
            <dxi-column dataField="destinationCode" width="15%" cssClass="gridStyle" caption="Destination ID" sortOrder="asc"></dxi-column>
            <dxi-column dataField="adId" width="14%" cssClass="gridStyle" caption="Ad-ID" cellTemplate="adIDTemplate"></dxi-column>
            <dxi-column dataField="orderStatus" width="12%" cssClass="gridStyle" caption="Status"></dxi-column>
            <dxi-column dataField="orderedBy" width="15%" cssClass="gridStyle" caption="Ordered By"></dxi-column>
            <dxi-column dataField="orderBuiltBy" width="15%" cssClass="gridStyle" caption="Created By"></dxi-column>
            <dxi-column dataField="orderSubmittedOn" width="12%" cssClass="gridStyle" caption="Submitted On" cellTemplate="dateTemplate"></dxi-column>
            <div *dxTemplate="let t of 'adIDTemplate'">
            <span class="blue-text" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{data.value | usertimezone: 'MM/DD/YYYY'}}</div>
            </div>
            <div *dxTemplate="let t of 'workOrderTemplate'">
            <span class="blue-text" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
            </div>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                  [allowedPageSizes]="[10, 20, 50, 100]"
                  infoText="Page {0} of {1} ({2} items)"
                  [showNavigationButtons]="true"
                  [showInfo]="true">
            </dxo-pager>
            </dx-data-grid>
      </div>

    </ng-container>
  </div>
</div>
