import { ReportTypeEnum } from "../../configurations/enums/enums";

export class OrderGroupService {
  orderGroupSequenceId: number;
  serviceId: number;
  serviceName: string;
  nonDigitalVendors: Vendor[];
  dueDate: Date;
  notes: string;
  orderMedia: OrderServiceMediaViewModel[];
}

export class OrderLvlService {
  serviceId: number;
  serviceName: string;
  nonDigitalVendors: Vendor[];
  dueDate: Date;
  notes: string;
  orderServiceId: number;
}

export class Vendor {
  vendorGuid: string;
  id: number;
  name: string;
}

export class OrderServiceMediaViewModel {
  spotFileGuid: string;
  spotFileId: number;
  adId: string;
  title: string;
  length: string;
  orderServiceId: number;
}

export class CreatepurchaseorderForm {
  orderGroupSequenceId: number;
  serviceId: number;
  serviceName: string;
  vendor: number;
  dueDate: Date;
  notes: string;
  orderMedia: OrderServiceMediaViewModel[]; //form array
}

export class CreateVendorPORequest {
  vendorPOOrderGroupServices: CreatepurchaseorderForm[];
}

export class DeleteOrderMediaEvent {
  orderMediaIndex: number;
  groupName: number;
}

export class UpdateVendorDateEvent {
  vendor: number;
  dueDate: Date = null;
}

export class VendorPurchaseOrderViewModel {
  vendorId: number;
  vendorName: string;
  vendorPurchaseOrder: Date;
  orderId: number;
}



export class ReportDeliveryConfirmationRequestViewModel {
  orderGuid: string;
  reportType: string;
}
