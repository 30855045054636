export enum commentTypeEnum {
  QC,
  General,
  TimeCode,
  Status,
}

export enum qcStatusEnum {
  Pass,
  Fail
}

export enum approvalStatusEnum {
  Approved,
  Rejected
}

export enum notificationPreference {
  NewMedia,
  MediaPendingApproval,
  MediaQC,
  MediaEdit,
  NewOrder,
  OrderEdit,
  OrderComplete,
  MediaApproval,
  MediaQCPass,
  MediaQCFail
}

export enum userProfileRoles {
  MediaApprover = 3
}

export enum mediaDetailSource {
  Search,
  RecentlyViewed,
  Approval,
  QC,
  PlayList
}

export enum brandDetailSource {
  create
}

export enum confirmEventType {
  DeleteOrderGroup,
  ChangeOrderClient,
  ChangeUserCreationClient,
  ChangeUserCreationPostHouse,
  ChangeOrderBrand,
  ChangeOrderProduct,
  DeleteDraftOrder,
  RetryVendorOrderSubmission,
  RetryVendorOrderStatusCheck,
  DeleteBrandProduct,
  DeleteClientBrand,
  DeleteClientBrandProduct,
  DeletePostHouse,
  DeleteUploadedOrder,
  DeleteUser,
  CopyOrderDraft,
  ChangeOrderAgency
}

export enum deliveryMethodEnum {
  Network,
  Physical,
  TLink,
  FTP,
  NA
}

export enum uploadFileCategoryEnum {
  FTP
}

export enum destinationTypeEnum {
  Network,
  ShareLink,
  FTP
}

export enum metadataCategoryEnum {
  File,
  Text,
  CopyText,
  BigText,
  NA,
  Multiple,
  BigMultiple,
  HoursMins,
  Dollar,
  ObjectMultiple,
  ObjectMultipleMinsTransfers,
  Special,
  CustomFileName,
  ObjectMultipleThreeRequired,
  ObjectMultipleFourRequired,
}

export enum actionRoleEnum {
  Full,
  Header,
  PodUpload,
  UpdateSpecialServices,
  VendorChange,
  FTPUpdate,
  DeleteAwaitingMedia,
  SpecialHeader
}

export enum contactTypeEnum {
  MainAddress = 7,
  TrafficContact = 11
}

export enum ReportTypeEnum {
  PDF = 0,
  Excel = 1
}

export enum brandEditScopeEnum {
  Full,
  Header,
  AddProduct,
  EditProduct,
  DeleteProduct
}

export enum clientEditScopeEnum {
  Full,
  Header,
  AddBrand,
  EditBrand,
  DeleteBrand,
  EditClientId,
  Contact,
  CostCenterandBillTo,
  Others,
  CustomTags,
  EditBrandDetail,
  PODDistributionEmails,
  CutDownServices,
  OrderedBy,
  FileDeliveryConfigs,
  BillingSettings
}

export enum ProjectStatus {
  Active = 1,
  Archived = 2
}

export enum ProjectCollectionType {
  Folder = "Folder",
  Audio = "Audio",
  Video = "Video",
  Text = "Text"
}

export enum TranscodeOrderStepEnum {
  selectMedia,
  selectTranscode,
  chooseFileName,
  reviewTranscode
}

export enum FileNamingTypeEnum {
  basic,
  advanced
}

export enum VaultAssetStages {
  remove = 1,
  destroy = 2,
  logout = 3,
  staging = 4,
  login = 5
}

export enum AdCreatorStepEnum {
  selectCreative,
  selectOfferCard,
  selectEndCard,
  selectEndCardVoiceOver,
  reviewAdCreator
}

export enum ClientTypes {
  Billable = 1,
  Partner = 2
}

export enum TaggerTabs {
  taggedAds = "YOUR TAGGED ADS",
  savedDraftAds = "SAVED DRAFT ADS",
  archivedAds = "ARCHIVED ADS",
}

export enum DraftOrderSort {
  DateCreated = "Date Created",
  Alphabetical = "Alphabetical",
  StatusCurrent = "Status Current",
  StatusExpiringSoon = "Status Expiring Soon",
  //StatusExpired = "Status Expired"
}

export enum Statuses {
  Expired = "EXPIRED",
  ExpireSoon = "EXPIRING SOON",
  Current = "CURRENT",
  AvailableSoon = "AVAILABLE SOON"
}

export enum TaggingStatus {
  Draft = "Draft",
  Received = "Received",
  Processing = "Processing",
  Success = "Success",
  PartialSuccess = "Partial Success",
  Failed = "Failed",
  Canceled = "Canceled",
  ReadyForBilling = "Ready For Billing",
  SentToBilling = "Sent To Billing",
  BillingFailed = "Billing Failed",
  Billed = "Billed",
  AdCreated = "Processing",
  SlateCreated = "Processing",
  OrderArchived = "Archived"
}

export enum AssetType {
  All = "All",
  BaseCreative = "Base Creative",
  OfferTag = "Offer Tag",
  EndTag = "End Tag",
  VoiceOver = "Voice Over"
}

export enum TaggerAssetSort {
  DateUploaded = "Date Uploaded",
  Alphabetical = "Alphabetical",
  StatusCurrent = "Status Current",
  StatusExpiringSoon = "Status Expiring Soon",
  StatusExpired = "Status Expired"
}

export enum mediaFormatTypeEnum {
  PNG = 1,
  MOV = 2,
  WAV = 3,
  MP3 = 4
}

export enum TaggableDurationEnum {
  THREE = 3,
  FIVE = 5,
  SEVEN = 7,
}

export enum SearchAllAssetGridButton {
  AddToPlayList = "ADD TO PLAYLIST",
  Share = "SHARE"
}
