<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.16 130.9" [attr.height]="height" [attr.width]="width">
    
    <g id="Layer_2" data-name="Layer 2" [attr.fill]="color">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x="18.18" y="62.36" width="37.46" height="10.58" />
        <rect x="18.18" y="79.55" width="52" height="10.58" />
        <rect x="18.18" y="96.74" width="52" height="10.58" />
        <path d="M114.48,25.55,88.74,1.41A5.3,5.3,0,0,0,85.11,0h-52a5.29,5.29,0,0,0-5.29,5.29h0V19.83H5.29A5.29,5.29,0,0,0,0,25.12V125.61a5.29,5.29,0,0,0,5.29,5.29H83.1a5.29,5.29,0,0,0,5.29-5.29V111.07h22.48a5.29,5.29,0,0,0,5.29-5.29V29.41h0A5.27,5.27,0,0,0,114.48,25.55ZM90.4,17.48l7.08,6.61H90.4ZM10.58,120.31V30.4H52.06V49.24a5.29,5.29,0,0,0,5.29,5.29H77.81v65.78ZM62.64,44V37.34L69.71,44Zm25.75,56.53v-13H98V76.9H88.39V70.29H98V59.71H88.39V49.24h0a5.26,5.26,0,0,0-1.65-3.86L61,21.25a5.31,5.31,0,0,0-3.61-1.42h-19V10.58H79.82V29.41a5.29,5.29,0,0,0,5.29,5.29h20.47v65.78Z" />
      </g>
    </g>
  </svg>