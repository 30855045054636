import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-nav-tagger',
  templateUrl: './icon-nav-tagger.component.html',
  styleUrls: ['./icon-nav-tagger.component.css']
})
export class IconNavTaggerComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string = '#a1a1a1';

  constructor() { }

  ngOnInit(): void {
  }

}
