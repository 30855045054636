import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-send',
  templateUrl: './icon-send.component.html',
  styleUrls: ['./icon-send.component.css']
})
export class IconSendComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#f5f5f5';
  constructor() { }

  ngOnInit() {
  }

}
