import { Injectable } from '@angular/core';

@Injectable()
export class RouteConstants {

  public static readonly defaultHomeUrl = '/';
  //May come from userprofile - just a default value
  public static readonly homeUrl = '/';
  public static readonly loginRoute = "login";
  public static readonly homeRoute = "home";   //should be home after dashboard is built
  public static readonly defaultRoute = "assets"; //should be fixed after dashboard is built
  public static readonly settingsRoute = 'user/settings';
  public static readonly reportRoute = 'reports';
  public static readonly mediaRoute = 'assets';
  public static readonly notificationRoute = 'user/notifications';
  public static readonly clientsRoute = 'admin/clients';
  public static readonly createClientsRoute = 'admin/clients/create';
  public static readonly clientDetailRoute = 'admin/clients/detail'
  public static readonly brandsRoute = 'admin/brands';
  public static readonly brandDetailRoute = 'admin/brands/detail'
  public static readonly createBrandsRoute = 'admin/brands/create';
  public static readonly mediaDetailRoute = 'assets/detail';
  public static readonly mediaUploadRoute = 'assets/upload';
  public static readonly mediaDownloadRoute = 'assets/download';
  public static readonly taggerDownloadRoute = 'tagger/download';
  public static readonly mediaQuickLinkRoute = 'assets/quicklink';
  public static readonly forgotPasswordRoute = 'login/forgotpassword';
  public static readonly resetPasswordRoute = 'resetpassword';
  public static readonly ordersRoute = 'orders';
  public static readonly createorderRoute = 'orders/create';
  public static readonly createvaultRoute = 'vault/create';
  public static readonly vaultRoute = 'vault';
  public static readonly vaultDeailRoute = 'vault/detail';
  public static readonly draftOrderRoute = 'orders/draft';
  public static readonly orderDetailRoute = 'orders/detail';
  public static readonly orderTraceRoute = 'orders/trace';
  public static readonly notfoundRoute = 'notfound';
  public static readonly ordersUpload = "orders/upload";
  public static readonly usersRoute = 'admin/users';
  public static readonly createUsersRoute = 'admin/users/create'
  public static readonly userDetailRoute = 'admin/users/detail';
  public static readonly ordersUploaded = "orders/uploaded";
  public static readonly userNewProfile = "admin/users/createprofile";
  public static readonly destinationDetailRoute = 'admin/destinations/detail';
  public static readonly createDestinationRoute = 'admin/destinations/create';
  public static readonly destinationsRoute = 'admin/destinations';
  public static readonly updatepassword = 'login/updatepassword';
  public static readonly itOps = 'itOps';
  public static readonly distributionRoute = 'distribution';
  public static readonly distributionDownloadRoute = 'distribution/assets/download';
  public static readonly tmcDownloadRoute = 'distribution/download/tmc';
  public static readonly transcodeFilesRoute = 'assets/transcodefiles';
  public static readonly orderTrancodesRoute = 'assets/transcode';
  public static readonly trancodeOrderListsSaved = 'assets/transcodeorderlists/saved';
  public static readonly trancodeOrderListsOLV = 'assets/transcodeorderlists/olv';
  public static readonly talentRightsRoute = 'assets/talentRights';

  public static readonly taggerRoute = 'tagger';
  public static readonly adCreatorRoute = 'tagger/ad-creator';
  public static readonly taggerOfferCardStepRoute = 'tagger/tagger-form/tagger-offer-card-step';
  public static readonly creativeTagsRoute = 'tagger/creative-tags';
  public static readonly groupsRoute = 'tagger/groups';
  public static readonly locationsAndUsersRoute = 'tagger/locations-users';
}
