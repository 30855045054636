//import { Injectable } from '@angular/core';
//import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
//import { Observable, throwError } from 'rxjs';
//import { catchError } from 'rxjs/operators';
//import { Utilities } from './utilities';
//import { environment } from '../../../environments/environment';
//import { LocalStoreManager } from './local-store-manager.service';
//import { DBkeys } from '../../configurations/db-Keys';

//@Injectable({
//  providedIn: 'root'
//})
//export class RequestInterceptorService implements HttpInterceptor {

//  constructor(public util: Utilities, private localStorage: LocalStoreManager) { }

//  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//    if (req.url === environment.userProfileEndPoint || req.url === environment.userDisplayDataEndPoint || !req.url.startsWith(environment.apiBaseUrl)) {
//      return next.handle(req);
//    }

//    // Retrieve user object from local storage
//    const userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
//    if (userObj != null && userObj != undefined) {
//      this.util.userProfileRoles = userObj.roles;
//      this.util.userProfileId = userObj.userprofileid; // Assuming 'userprofileid' is stored in the user object
//    }

//    // Retrieve access token from local storage
//    const accessToken = this.localStorage.getData(DBkeys.ACCESS_TOKEN);

//    // Check if access token exists
//    if (accessToken) {
//      // Clone the request to include the Authorization and userProfileId headers
//      const tokenReq = req.clone({
//        setHeaders: {
//          Authorization: `Bearer ${accessToken}`,
//          'UserProfileId': this.util.userProfileId
//        }
//      });

//      return next.handle(tokenReq).pipe(
//        catchError(err => throwError(err))
//      );
//    } else {
//      return throwError('No access token found in local storage');
//    }
//  }
//}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DBkeys } from '../../configurations/db-Keys';


@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Retrieve userProfileId from local storage
    const userObj = localStorage.getItem(DBkeys.X_TANDEM_Profile);
    let clonedRequest = req;

    if (userObj != null && userObj !== undefined) {
      const userProfileGuid = userObj;

      // Add UserProfileId header if it exists
      clonedRequest = req.clone({
        setHeaders: {
          [DBkeys.X_TANDEM_Profile]: userProfileGuid
        }
      });
    }

    return next.handle(clonedRequest);
  }
}

