import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { UserRoles } from "../../../configurations/user-roles";
import { RouteConstants } from "../../../configurations/route-constants";
import { ConfigService } from "../../../services/core/config.service";
import { AlertService } from "../../../services/core/alert.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileRecommendation } from "../../../models/user/user.profile.model";
import { UserDataService } from '../../../services/user/user-data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserSelectionPopupComponent } from '../../popups/userselectionpopup/userselectionpopup.component';

@Component({
  selector: 'user-landing',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  //public userProfileEmailsDataSource: any[] = [];
  public users: UserProfileRecommendation[] = [];
  public userTypes: any = [];
  public userHomeConfig: any = {};

  public selectUserFormGroup: FormGroup;
  public modalRef: BsModalRef;

  constructor(public util: Utilities
    , private router: Router
    , private alertService: AlertService
    , private configService: ConfigService
    , private userDataService: UserDataService
    , public userRoles: UserRoles
    , private fb: FormBuilder
    , private modalService: BsModalService)
  {

  }

  ngOnInit() {

    this.selectUserFormGroup = this.fb.group({
      email: [null, [Validators.required]]
    });

    this.getUserHomeConfig();
  }

  private getUserHomeConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getUserHomeConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.userHomeConfig = res.result;
        this.users = this.userHomeConfig.userProfileRecommendations;
        this.userTypes = this.userHomeConfig.userTypes;

        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public gotoCreateNewUser(userType) {
    this.router.navigate([RouteConstants.createUsersRoute, userType]);
  }

  public onClickCreateUser() {
    var initialState = {
      userTypeDataSource: this.userTypes
    };

    this.modalRef = this.modalService.show(UserSelectionPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        this.gotoCreateNewUser(result)
      }
    });
  }

  public gotoCreateNewProfile(userName: string) {
    if (userName == "00000000-0000-0000-0000-000000000000")
      return;

    var initialState = {
      userTypeDataSource: this.userTypes
    };

    this.modalRef = this.modalService.show(UserSelectionPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        this.router.navigate([RouteConstants.userNewProfile, result, userName]);
      }
    });
  }

  public onFormSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.userDataService.checkUserExists(value.email).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.gotoCreateNewProfile(value.email)
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(["Email you have entered is invalid."]);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }
}
