import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AlertService } from "../../../../services/core/alert.service";
import { Utilities } from "../../../../services/core/utilities";
import { ConfigService } from "../../../../services/core/config.service";
import { OrderService } from "../../../../services/order/order.service";
import { OrderDigitalDeliveryDestinationDetailModel } from "../../../../models/order/order-destinations.model";

@Component({
  selector: 'updatevendorordernumber',
  templateUrl: './updatevendorordernumber.component.html',
  styleUrls: ['./updatevendorordernumber.component.css'],
})
export class UpdateVendorOrderNumberComponent implements OnInit {

  @Output('saveVendorOrderNumber') saveVendorOrderNumber: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output('closePopUp') closePopUp: EventEmitter<void> = new EventEmitter<void>();

  public updateVendorOrderNumber: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private orderService: OrderService,
    private configService: ConfigService) {
  }

  ngOnInit() {
    this.updateVendorOrderNumber = new FormGroup({
      vendorOrderNumber: new FormControl('', Validators.required)
    });

  }

  public onFormSubmit(updateVendorOrderNumber: any) {
    this.saveVendorOrderNumber.emit(updateVendorOrderNumber);
    console.log(updateVendorOrderNumber);
  }

  public closePopup() {
    this.closePopUp.emit();
  }
}
