import { Observable, Subject, of, throwError, from } from 'rxjs';
import { catchError, mergeMap, map, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { JwtHelper } from '../../configurations/jwt-helper';
import { User } from '../../models/user/user.model';
import { LocalStoreManager } from '../core/local-store-manager.service';
import { DBkeys } from '../../configurations/db-Keys';
import { Utilities } from "../core/utilities";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { UserProfile, MultiUserProfileModel, AccountExecutiveModel, UserProfileSelectionModel } from '../../models/user/user.profile.model';
import { Constants } from "../../configurations/constants";
import { RouteConstants } from "../../configurations/route-constants";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AlertService } from '../core/alert.service';
import { DOCUMENT } from '@angular/common';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  SilentRequest,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';

@Injectable()
export class AuthService {

  public _loginRedirectUrl?: string;
  private _loginStatus = new Subject<boolean>();
  public errorMessage: any;
  public previousIsLoggedInCheck = false;
  public multipleProfilesExist = false;
  public loginStatusSubscription: any;

  constructor(private http: HttpClient,
    private router: Router,
    private apiendPoint: ApiEndPoint,
    private localStorage: LocalStoreManager,
    private alertService: AlertService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authMS: MsalService,
    private msalBroadcastService: MsalBroadcastService) {

  }

  public clearSessionStorage(): void {
    this.localStorage.deleteData(DBkeys.CURRENT_USER);
    this.alertService.ShowLoader(false);
    this.reevaluateLoginStatus();
  }

  public forceLogOut(): void {
    this.localStorage.deleteData(DBkeys.CURRENT_USER);
    this._loginRedirectUrl = this.router.url;
    //this.redirectLogoutUser();


    const logoutRequest = {
      account: this.activeUser,
      postLogoutRedirectUri: "https://7milesglobal.com/"
    };

    this.authMS.logoutRedirect(logoutRequest);
  }

  public acquireTokenSilent(scopes: string[] = environment.entraApiscopes): Observable<string> {
    const activeAccount = this.activeUser;

    if (!activeAccount) {
      throw new Error('No active account! User may not be logged in.');
    }

    const tokenRequest = {
      account: activeAccount,
      scopes: scopes,
    };

    // Return an observable for the access token
    return from(this.authMS.acquireTokenSilent(tokenRequest)).pipe(
      map((res: AuthenticationResult) => res.accessToken)
    );
  }

  public acquireTokenSilentRequest(scopes: string[] = environment.entraApiscopes): Observable<AuthenticationResult> {
    const activeAccount = this.activeUser;

    if (!activeAccount) {
      throw new Error('No active account! User may not be logged in.');
    }

    const tokenRequest = {
      account: activeAccount,
      scopes: scopes,
    };

    // Return an observable
    return this.authMS.acquireTokenSilent(tokenRequest);
  }

  public processLoginResponse(response: any): Observable<MultiUserProfileModel> {
    let accessTokens = response.accessToken;

    console.log(response);

    if (accessTokens != null) {

      let idToken = response.idToken;
      let accessTokenExpiry = response.expiresOn; //Thu Sep 26 2024 12:42:38 GMT-0700
    
      let refreshToken = response.refresh_token;

      let jwtHelper = new JwtHelper();
      let decodedIdToken = jwtHelper.decodeToken(idToken);

      console.log('decodedIdToken', decodedIdToken);

      return this.getUserProfileSelections(accessTokens).pipe(map((res: any) => {
        console.log(res);

        var userProfiles = res.result as UserProfileSelectionModel[];

        if (userProfiles == null || userProfiles == undefined || userProfiles.length == 0)
          return null;

        if (userProfiles != null && userProfiles != undefined && userProfiles.length > 1)
          this.multipleProfilesExist = true;

        if (this.multipleProfilesExist == false && userProfiles != undefined && userProfiles.length == 1) {

          console.log("single profile");

          let userProfile: UserProfile = new UserProfile();

          userProfile.userProfileGuid = userProfiles[0].userProfileGuid;
          localStorage.setItem(DBkeys.X_TANDEM_Profile, userProfile.userProfileGuid);

          let user = new User(
            userProfile,
            decodedIdToken.sub,
            decodedIdToken.name,
            decodedIdToken.email,
            []);

          user.isEnabled = true;

          this.getUserDisplayData().pipe(map(profile => {

            console.log("DisplayData", profile);

            userProfile.email = profile.email;
            userProfile.authIdentifier = profile.authIdentifier;

            user.userProfile.accountExecutive = profile.accountExecutive;

            user.userProfile.firstName = profile.firstName;
            user.userProfile.lastName = profile.lastName;
            user.userProfile.initials = profile.initials;
            user.userProfile.avatar = profile.avatar;
            user.userProfile.timeZone = profile.timeZone;
            user.userProfile.ianaCode = profile.ianaCode;

            user.userProfile.cellPhone = profile.cellPhone;
            user.userProfile.workPhone = profile.workPhone;
            user.userProfile.birthDay = profile.birthDay;
            user.userProfile.birthMonth = profile.birthMonth;
            user.userProfile.birthYear = profile.birthYear;
            user.userProfile.userProfileType = profile.userProfileType;
            user.roles = profile.roles;
            user.userProfile.defaultRoute = profile.defaultRoute;

            this.saveUserDetails(user);

            return profile;

          })).subscribe(profile => {
            this.reevaluateLoginStatus(user);
          });

 
          return { hasMultipleProfiles: false, userProfileSelectionModelList: null, user: user, token: null };
        }
        else if (this.multipleProfilesExist == true) {

          console.log("multiple profiles");

          return { hasMultipleProfiles: true, userProfileSelectionModelList: userProfiles, user: null, token: accessTokens };
        }

        return null;
      }));
    }
    else { return of(null); }
  }

  public processProfileSelectLoginResponse(userProfileGuid: any): Observable<MultiUserProfileModel> {

    if (userProfileGuid && typeof userProfileGuid === 'string') {  // Simplified undefined, null, and empty check

      localStorage.setItem(DBkeys.X_TANDEM_Profile, userProfileGuid);

      console.log("Single profile selected");

      const jwtHelper = new JwtHelper();
      const idToken = this.activeUser.idToken; // Use optional chaining to avoid errors
      if (!idToken) {
        console.error("ID token is missing");
        return throwError('ID token is missing');
      }

      const decodedIdToken = jwtHelper.decodeToken(idToken);
      console.log('Decoded ID Token', decodedIdToken);

      const userProfile = new UserProfile();
      userProfile.userProfileGuid = userProfileGuid;

      const user = new User(
        userProfile,
        decodedIdToken.sub,
        decodedIdToken.name,
        decodedIdToken.email,
        []
      );

      user.isEnabled = true;

      return this.getUserDisplayData().pipe(
        map(profile => {
          console.log("DisplayData", profile);
          // Assign the profile values to user profile
          userProfile.email = profile.email;
          userProfile.firstName = profile.firstName;
          userProfile.lastName = profile.lastName;
          userProfile.initials = profile.initials;
          userProfile.avatar = profile.avatar;
          userProfile.timeZone = profile.timeZone;
          userProfile.ianaCode = profile.ianaCode;
          userProfile.cellPhone = profile.cellPhone;
          userProfile.workPhone = profile.workPhone;
          userProfile.birthDay = profile.birthDay;
          userProfile.birthMonth = profile.birthMonth;
          userProfile.birthYear = profile.birthYear;
          userProfile.userProfileType = profile.userProfileType;
          userProfile.defaultRoute = profile.defaultRoute;
          user.roles = profile.roles;
          user.userProfile.accountExecutive = profile.accountExecutive;

          this.saveUserDetails(user);

          return { hasMultipleProfiles: false, userProfileSelectionModelList: null, user, token: null };
        }),
        catchError(error => {
          console.error("Error retrieving user display data", error);
          return throwError(error);
        })

      );

    } else {
      // No valid profile GUID, return that multiple profiles are present
      return of({ hasMultipleProfiles: true, userProfileSelectionModelList: null, user: null, token: null });
    }
  }

  public getUserProfileSelections(accessToken: string) {
    const headers = new HttpHeaders({
      'Skip-Token': 'skip',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(this.apiendPoint.getUserSelectionEndpoint(), { headers: headers });
  }

  public getUserDisplayData(accessToken: string = null) {
    return this.http.get(this.apiendPoint.getUserDisplayDataEndpoint()).pipe(map((res: any) => {
      return res.result;
    }));
  }

  public processLoginError(errorRes: HttpErrorResponse): loginErr {
    var loginerrorRes = new loginErr();

    try {
      if (errorRes.status == 403 && errorRes.error.error == 'invalid_grant') {
        if (errorRes.error.error_description == 'user is blocked')
          loginerrorRes.errorDescrption = "Your account has been blocked. Please reach out to Tylie Support for a speedy resolution.";
        else
          loginerrorRes.errorDescrption = "Your username or password doesn't match.";
      }
      else if (errorRes.status == 429 && errorRes.error.error == 'too_many_attempts') {
        loginerrorRes.errorDescrption = "Your account has been blocked after multiple consecutive login attempts. Please reach out to Tylie Support for a speedy resolution.";
      }
      else if (errorRes.status == 400 && errorRes.error.error == 'password_expired') {
        loginerrorRes.errorDescrption = "Your password has expired.";
        loginerrorRes.isPasswordExpired = true;
      }
      else {
        loginerrorRes.errorDescrption = Constants.connectionLoginError;
      }

      return loginerrorRes;
    }
    catch (e) {
      loginerrorRes.errorDescrption = Constants.connectionLoginError;

      return loginerrorRes;
    }
  }

  public getUserProfilesForInactivateUserEndpoint() {
    return this.http.get(this.apiendPoint.getUserProfilesForInactivateUserEndpoint());
  }

  public saveUserDetails(user?: User) {
    if (user != null) {
      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
    }
  }

  public redirectForLogin() {
    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
    this._loginRedirectUrl = this.router.url;
  }

  public redirectLoginUser() {
    var userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    console.log('userObj', userObj);
    if (userObj != null && userObj != undefined) {
      let userProf: UserProfile = userObj.userProfile;
      console.log('userProf', userProf);
      if (userProf != null && userProf != undefined) {
        let redirect = this._loginRedirectUrl && this._loginRedirectUrl != '/' && this._loginRedirectUrl != RouteConstants.defaultHomeUrl ? this._loginRedirectUrl : userProf.defaultRoute;
        console.log(redirect)
        setTimeout(() => {
          this.router.navigate([redirect]);
        }, 100);

      }
      else {
        this.router.navigate([RouteConstants.homeRoute]);
      }
    }
    else {
      this.router.navigate([RouteConstants.homeRoute]);
    }    
  }

  public getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }

  public redirectLogoutUser() {
    this.router.navigate([RouteConstants.loginRoute]);
  }

  get currentUserToken(): boolean {
    let user = this.activeUser;

    if (user != null && user != undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  public isLoggedIn(): boolean {
    if (this.currentUserToken) {
      return true;
    }
    return false;
  }

  private reevaluateLoginStatus(currentUser?: User) {
    let activeUser = this.activeUser;
    let user = currentUser || activeUser;
    let isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck != isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    console.log(isLoggedIn)
    this.previousIsLoggedInCheck = isLoggedIn;
  }
  get activeUser() {
    return this.authMS.instance.getActiveAccount();
  }
}

export class loginErr {
  public isPasswordExpired: boolean;
  public errorDescrption: string;
}
