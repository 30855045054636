<div class="row">
  <div class="col-md-7" id="create-client-section">
    <div class="tylie-panel" id="createclient">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          Roles
        </h4>
      </div>
      <ng-container id="createClientCollapseOne">
        <div class="tylie-panel__body" id="createClientBody">
          <form [formGroup]="addUserRolesFormGroup" novalidate #roleForm="ngForm">
            <div class="row" formArrayName="userRoles">
              <div class="col-4" *ngFor="let roleGrp of addUserRolesFormGroupArray.controls; let i = index">
                <div class="tylie-form-group form-check" [formGroup]="roleGrp">
                  <input class="form-check-input" type="checkbox" formControlName="isChecked" id="isChecked" (change)="onRoleValueChanged($event, i)"/>
                  <label class="form-check-label">{{rolesNotifications[i].roleDescription}}</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-md-5" id="create-client-section">
    <div class="tylie-panel" id="createclient">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          Notifications
        </h4>
      </div>
      <ng-container id="createClientCollapseOne">
        <div class="tylie-panel__body" id="createClientBody">
          <span *ngIf="notificationPreferncesFormGroupArray?.length == 0">Notification options only available after Role selection.</span>
          <form [formGroup]="addUserNotificationsFormGroup" novalidate #NotifcationsForm="ngForm">
            <div class="row" formArrayName="notificationPreferences">
              <div class="col-4" *ngFor="let notifGrp of notificationPreferncesFormGroupArray.controls; let i = index">
                <div class="tylie-form-group form-check" [formGroup]="notifGrp">
                  <input type="checkbox"  class="form-check-input" formControlName="isChecked" id="isChecked" />
                  <label  class="form-check-label">{{notifGrp.controls['description'].value}}</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</div>
