import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AuthService } from '../auth/auth.service';
import { SpotUploadRequest } from '../../models/upload/upload-request.model';
import { SpotUploadMetadataRequest } from "../../models/upload/uploadMetadata-request.model";
import { UploadOrderServiceModel } from '../../models/upload/upload-order-service.model';

@Injectable()
export class UploadService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }
  
  public getUploadSpec(destinationFolder: string) {

    let request: any = {};
    request.command = "upload";
    request.destination = destinationFolder;

    return this.http.post(this.apiendPoint.getUploadSpecEndpoint(), request);
  }

  public createPlaceHolder(uploadRequest: SpotUploadRequest)
  {
    let requests: Array <SpotUploadRequest> = [];
    requests.push(uploadRequest);

    return this.http.post(this.apiendPoint.createPlaceHolderEndpoint(), requests);
  }

  public uploadOrder(uploadRequestServiceModel: UploadOrderServiceModel) {
    return this.http.post(this.apiendPoint.uploadOrderEndpoint(), uploadRequestServiceModel);
  }

  public uploadMetadata(uploadRequest: SpotUploadMetadataRequest) {
    return this.http.post(this.apiendPoint.uploadMetadataEndpoint(), uploadRequest);
  }

  public createSpotFile(spotFileCreateRequests: Array<SpotUploadRequest>) {
    return this.http.post(this.apiendPoint.createSpotFileEndpoint(), spotFileCreateRequests);
  }

  public downloadMetadaTemplate(request: any) {
    return this.http.post(this.apiendPoint.downloadMetadaFileEndpoint(), request);
  }
}
