import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { DxDataGridComponent, DxTreeViewComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../configurations/route-constants";
import { CreateUserConfig, CreateUser, UserClientBrand, UserBrandProduct, CreateUserRoleNotification, UserRole, CreateUserResponse } from "../../../../models/user/create-user";
import { PostHouseAddProductPopUp } from "../../../popups/posthouseaddproduct/posthouseaddproductpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ProductModel } from "../../../../models/admin/brand/createproduct.model";
import { customPhoneValidator, customEmailValidator } from "../../../../configurations/custom-validations";
import { ConfirmPopupComponent } from "../../../popups/confirmationpopups/confirmpopup.component";
import { confirmEventType, userProfileRoles } from "../../../../configurations/enums/enums";
import { Subscription } from "rxjs";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../../models/config/gridparams.model';
import { AdmintranscodepreferencessettingsComponent } from '../../../popups/admintranscodepreferencessettings/admintranscodepreferencessettings.component';

@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  @ViewChild('brandsGrid', { static: false }) brandsGrid: DxDataGridComponent;
  @ViewChild('treeUserGrps', { static: false }) treeUserGrps: DxTreeViewComponent;

  public createUserFormGroup: FormGroup;

  public usersRoute = RouteConstants.usersRoute;
  public createUserConfig: CreateUserConfig = null;
  public clientDataSource: any[] = [];
  public postHouseDataSource: any[] = [];
  public userGrpDataSource: any[] = [];
  public destinationDataSource: any = {};
  public userClientBrandDataSource: UserClientBrand[] = null;
  public modalRef: BsModalRef;
  public phoneMask = Constants.phoneMask;
  public mediaApproverSelected: boolean = false;
  public previousClientValue: number = null;
  public previousPostHouseValue: number = null;
  private subscription: Subscription;
  private userName: string = null;
  public title: string = "Create New User";
  public userType: string = null;
  public networkSpecifications: any[] = [];
  public enableAdvancedFileNaming: boolean = false;
  public userGrpsValue: Array<number>;

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , private fb: FormBuilder
    , private modalService: BsModalService
    , private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe((params: Params) => {
      this.userName = params['userName'];
      this.userType = params['userType'];

      if (this.userName != null) {
        this.title = "Create New User Profile - " + this.userName;
        this.getCreateUserProfileConfig(this.userType, this.userName);
      }
    });

    this.createUserFormGroup = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.compose([customEmailValidator])]],
      phone: [null, Validators.compose([customPhoneValidator])],
      client: [null],
      postHouse: [null],
      destination: [null],
      timeZone: [null, Validators.required],
      userRole: this.fb.group({
        userRoles: this.fb.array([])
      }),
      notificationPreference: this.fb.group({
        notificationPreferences: this.fb.array([])
      }),
      enableAdvancedFileNaming: [false],
      specifications: [null],
      dealerName: [null],
      dealerNumber: [null],
      userGroups: [null]
    });

    if (this.userType != null && this.userType.toLowerCase() == Constants.clientUserType.toLowerCase()) {
      this.createUserFormGroup.controls['client'].setValidators(Validators.required);
      this.createUserFormGroup.controls['client'].updateValueAndValidity();
    }
    else if (this.userType != null && this.userType.toLowerCase() == Constants.destinationUserType.toLowerCase()) {
      this.createUserFormGroup.controls['destination'].setValidators(Validators.required);
      this.createUserFormGroup.controls['destination'].updateValueAndValidity();
    }

    if (this.userName == null && this.userType != null) {
      this.getCreateUserConfig(this.userType);
    }

    if (this.userType != null && this.isUserTypeDestination()) {
      this.searchDestinations();
    }
  }

  public addClientBrandProduct(data: any) {
    if (this.util.notEmpty(data.products)) {
      var productKeys: number[] = data.products.map(product => product.id);
    }
    var initialState = {
      clientId: this.createUserFormGroup.controls['postHouse'].value != null ? this.createUserFormGroup.controls['postHouse'].value : this.createUserFormGroup.controls['client'].value,
      agencyId: this.createUserFormGroup.controls['postHouse'].value != null ? this.createUserFormGroup.controls['client'].value : null,
      brandId: data.brandId,
      productKeys: productKeys
    };

    this.modalRef = this.modalService.show(PostHouseAddProductPopUp, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe((result: ProductModel[]) => {
      var userBrandProducts: UserBrandProduct[] = [];
      result.forEach(res => {
        var product = new UserBrandProduct();
        product.id = 0;
        product.productGuid = res.productGuid;
        product.productId = res.id;
        product.name = res.name;

        userBrandProducts.push(product);
      });

      data.products = userBrandProducts;
    });
  }

  public onClientValueChanged(e: any) {
    if (this.createUserConfig.allowSpecialOptions == false || e.value == this.previousClientValue) {
      return;
    }

    if (this.util.notEmpty(e) && this.util.notEmpty(e.value)) {
      if (this.brandsGrid != null && this.brandsGrid.instance != null && this.brandsGrid.instance.getSelectedRowsData().length > 0) {
        this.showWarningOnClientChangeAfterBrandSelection(e);
      }
      else {
        this.previousClientValue = e.value;
        this.getClientPostHouses(e.value);
        this.getClientUserGroups(e.value);
        this.getBrandsForUserCreation(e.value);
      }
    }
    else {
      if (this.brandsGrid != null && this.brandsGrid.instance != null && this.brandsGrid.instance.getSelectedRowsData().length > 0) {
        this.showWarningOnClientChangeAfterBrandSelection(e);
      }
      else {
        this.previousClientValue = e.value;
        this.postHouseDataSource = [];
        this.userGrpDataSource = [];
        this.userClientBrandDataSource = null;
      }
    }
  }

  public showWarningOnClientChangeAfterBrandSelection(e: any) {
    var initialState = {
      eventType: confirmEventType[confirmEventType.ChangeUserCreationClient]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {

      if (result) {
        this.previousClientValue = e.value;
        if (this.util.notEmpty(e.value)) {
          this.getClientPostHouses(e.value);
          this.getBrandsForUserCreation(e.value);
        }
        else {
          this.postHouseDataSource = [];
          this.userClientBrandDataSource = null;
        }
      }
      else {
        this.previousClientValue = e.previousValue;
        this.createUserFormGroup.controls['client'].setValue(e.previousValue);
      }
    });
  }

  public onPostHouseValueChanged(e: any) {
    if (e.value == this.previousPostHouseValue) {
      return;
    }

    if (this.util.notEmpty(e) && this.util.notEmpty(e.value)) {
      if (this.brandsGrid != null && this.brandsGrid.instance != null && this.brandsGrid.instance.getSelectedRowsData().length > 0) {
        this.showWarningOnPostHouseChangeAfterBrandSelection(e);
      }
      else {
        this.previousPostHouseValue = e.value;
        this.getBrandsForUserCreation(e.value, this.createUserFormGroup.controls['client'].value);
      }
    }
    else {
      if (this.brandsGrid != null && this.brandsGrid.instance != null && this.brandsGrid.instance.getSelectedRowsData().length > 0) {
        this.showWarningOnPostHouseChangeAfterBrandSelection(e);
      }
      else {
        this.previousPostHouseValue = e.value;
        this.userClientBrandDataSource = null;
        if (this.util.notEmpty(this.createUserFormGroup.controls['client'].value)) {
          this.getBrandsForUserCreation(this.createUserFormGroup.controls['client'].value);
        }
      }
    }
  }

  public showWarningOnPostHouseChangeAfterBrandSelection(e: any) {
    var initialState = {
      eventType: confirmEventType[confirmEventType.ChangeUserCreationPostHouse]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.previousPostHouseValue = e.value;
        if (this.util.notEmpty(e.value)) {
          this.getBrandsForUserCreation(e.value, this.createUserFormGroup.controls['client'].value);
        }
        else {
          this.userClientBrandDataSource = null;
          if (this.util.notEmpty(this.createUserFormGroup.controls['client'].value)) {
            this.getBrandsForUserCreation(this.createUserFormGroup.controls['client'].value);
          }
        }
      }
      else {
        this.previousPostHouseValue = e.previousValue;
        this.createUserFormGroup.controls['postHouse'].setValue(e.previousValue);
      }
    });
  }

  public onMediaApproverRoleUpdatedEventTriggered(e: any) {
    this.mediaApproverSelected = e;
    if (this.userClientBrandDataSource != null && this.userClientBrandDataSource != undefined) {
      this.userClientBrandDataSource.forEach(cb => {
        if (cb.hasApprovalLevels == true) {
          cb.userClientBrandApprovalLevel = null;
        }
        else {
          cb.userClientBrandApprovalLevel = 1;
        }
      });
    }
  }

  public onFormSubmit({ value, valid }: { value: CreateUser, valid: boolean }) {
    if (!valid) {
      return;
    }

    if (this.util.notEmpty(this.brandsGrid) && this.util.notEmpty(this.brandsGrid.instance)) {
      value.brands = this.brandsGrid.instance.getSelectedRowsData();
    }

    if (this.userName != null) {
      if (this.validateCreateUserRequest(value.brands, value.userRole)) {
        value.phone = this.util.notEmpty(value.phone) ? value.phone.replace(/\D+/g, '').trim() : null;
        this.createNewUserProfile(value, this.userName);
      }
    }
    else {
      if (this.validateCreateUserRequest(value.brands, value.userRole)) {
        value.phone = this.util.notEmpty(value.phone) ? value.phone.replace(/\D+/g, '').trim() : null;
        value.specifications = this.networkSpecifications;
        this.createNewUser(value);
      }
    }
  }

  private validateCreateUserRequest(brands: UserClientBrand[], userRole: UserRole) {
    var selectionValid = true;

    if ((brands != null || brands != undefined) && brands.length > 0) {
      brands.forEach(bs => {
        if (bs.hasBrandProducts && (bs.products == null || bs.products == undefined || bs.products.length == 0)) {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.productsNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          selectionValid = false;
        }
        else if (this.util.notEmpty(userRole) && (this.util.notEmpty(userRole.userRoles)) && (userRole.userRoles.find(r => r.roleId == userProfileRoles.MediaApprover && r.isChecked == true) != undefined)
          && bs.clientBrandApprovalLevels != null && bs.userClientBrandApprovalLevel == null) {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.approvalLevelsNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          selectionValid = false;
        }
      });
    }
    return selectionValid;
  }

  public onSelectionChangedApprovalLvl(e: any, data: UserClientBrand) {
    if (this.util.notEmpty(e)) {
      data.userClientBrandApprovalLevel = e.selectedItem;
    }
  }

  public isUserTypeClient() {
    if (this.userType.toLowerCase() == Constants.clientUserType.toLowerCase())
      return true
    return false
  }

  public isUserTypeDestination() {
    if (this.userType.toLowerCase() == Constants.destinationUserType.toLowerCase())
      return true
    return false
  }

  public searchDestinations() {
    var adminService = this.adminService;
    var util = this.util;

    this.destinationDataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: 0, take: 0, sort: sortOptions, isExport: false }
        let request: any = {
          criteria: { quickSearch: loadOptions.searchValue }, GridParams: gridParams
        };

        return adminService.searchAllDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var destinationSearchResponse = response.result;
              var obj: any = {
                data: destinationSearchResponse.destinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  private createNewUserProfile(value: CreateUser, userName: string) {
    this.adminService.createNewUserProfile(value, userName).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var results = res.result as CreateUserResponse;

        if (results.warnings && results.warnings.length > 0) {
          var initialState = {
            errors: results.warnings,
            title: 'Warnings'
          };

          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        }

        this.alertService.showMessage("SUCCESS", Constants.createUserProfileSuccessful, MessageSeverity.success);

        this.router.navigate([RouteConstants.usersRoute]);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private createNewUser(value: CreateUser) {
    this.adminService.createNewUser(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var results = res.result as CreateUserResponse;

        if (results.warnings && results.warnings.length > 0) {
          var initialState = {
            errors: results.warnings,
            title: 'Warnings'
          };

          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        }

        this.alertService.showMessage("SUCCESS", Constants.createUserSuccessful, MessageSeverity.success);

        this.router.navigate([RouteConstants.usersRoute]);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private createUserRolesFormArray(rolesNotifications: CreateUserRoleNotification[]) {
    var roleFormGroup = this.createUserFormGroup.get('userRole');
    var rolesArray = <FormArray>roleFormGroup.get('userRoles') as FormArray;
    rolesNotifications.forEach(obj => {
      const group = this.fb.group({
        isChecked: [false],
        roleId: [obj.roleId]
      });
      rolesArray.push(group);
    });
    return rolesArray;
  }

  private getBrandsForUserCreation(clientId: number, agencyId: number = null) {
    this.userClientBrandDataSource = [];
    this.adminService.getBrandsForUserCreation(clientId, agencyId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.userClientBrandDataSource = brands;
        console.log(this.userClientBrandDataSource);
      }
      else {
        this.userClientBrandDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.userClientBrandDataSource = [];
      });
  }

  private getCreateUserConfig(userType: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    console.log(userType);
    this.configService.getCreateUserConfig(userType).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.createUserConfig = res.result as CreateUserConfig;
        console.log(this.createUserConfig);
        this.alertService.ShowLoader(false);
        this.clientDataSource = this.createUserConfig.clients;
        this.createUserRolesFormArray(this.createUserConfig.rolesNotifications);
        if (!this.createUserConfig.allowSpecialOptions) {
          this.userClientBrandDataSource = this.createUserConfig.brands;
        }
        else {
          this.userClientBrandDataSource = [];
        }
        if (this.clientDataSource.length == 1)
          this.createUserFormGroup.controls['client'].setValue(this.clientDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getCreateUserProfileConfig(userType: string, userName: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateUserProfileConfig(userType, userName).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.createUserConfig = res.result as CreateUserConfig;
        this.alertService.ShowLoader(false);
        this.clientDataSource = this.createUserConfig.clients;
        this.createUserRolesFormArray(this.createUserConfig.rolesNotifications);
        if (this.clientDataSource.length == 1)
          this.createUserFormGroup.controls['client'].setValue(this.clientDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getClientPostHouses(id: number) {
    if (this.createUserFormGroup.value.brand != null) {
      this.createUserFormGroup.controls['posthouse'].setValue(null);
    }

    this.postHouseDataSource = [];
    this.configService.getClientPostHouses(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var postHouses = res.result;
        this.postHouseDataSource = postHouses;
      }
      else {
        this.postHouseDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.postHouseDataSource = [];
      });
  }

  private getClientUserGroups(id: number) {
    this.userGrpDataSource = [];
    this.configService.getClientUserGroups(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var userGrps = res.result;
        this.userGrpDataSource = userGrps;
      }
      else {
        this.userGrpDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.userGrpDataSource = [];
      });
  }

  public onAddDistributionSettings() {
    var initialState = {
      enableAdvancedFileNaming: this.createUserFormGroup.controls['enableAdvancedFileNaming'].value,
    };

    this.modalRef = this.modalService.show(AdmintranscodepreferencessettingsComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      console.log(result)
      if (result != null) {
        this.createUserFormGroup.controls['enableAdvancedFileNaming'].setValue(result.enableAdvancedFileNaming);
        this.enableAdvancedFileNaming = result.enableAdvancedFileNaming;

        result.specifications.forEach(spec => {
          console.log(spec.specificationMappingId)
          if (this.networkSpecifications.find(t => t.id == spec.specificationMappingId) == undefined) {
            this.networkSpecifications.push({ id: spec.specificationMappingId, name: spec.name });
          }
        });
      }
    });
  }

  public onRemoveSpecMapping(spec) {
    this.networkSpecifications = this.networkSpecifications.filter(ns => ns.id != spec.id);
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeUserGrps && this.treeUserGrps.instance);

    if (!component) {
      return;
    }

    if (this.userGrpsValue == null || !this.userGrpsValue.length) {
      component.unselectAll();
    }

    if (this.userGrpsValue != null) {
      this.userGrpsValue.forEach((value) => {
        component.selectItem(value);
      });
    }
  }

  public treeView_itemSelectionChanged(e) {
    this.userGrpsValue = e.component.getSelectedNodesKeys();
  }

}
