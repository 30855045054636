import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { CreateOrderComponent } from "../../components/orders/order-create/createorder.component";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from "./utilities";
import { UnSavedDataWarningComponent } from "../../components/popups/unsaveddatapopup/unsaveddatapopup.component";
import { Observable, Subject } from "rxjs";
import { OrderDetailComponent } from "../../components/orders/order-detail/orderdetail.component";
import { OrderTranscodesComponent } from "../../components/transcodeassets/ordertranscodes/ordertranscodes.component";
import { VaultCreateComponent } from "../../components/vault/vault-create/vault-create.component";

@Injectable()
export class CanDeactivateCreateOrder implements CanDeactivate<CreateOrderComponent> {


  constructor(private util: Utilities,
    private modalService: BsModalService) {

  }

  canDeactivate(component: CreateOrderComponent): boolean | Observable<boolean> | Promise<boolean> {
    if (component.hasUnsavedData()) {

      const subject = new Subject<boolean>();

      var initialState = {
        message: "You have unsaved changes! Do you really want to leave this page?"
      };

      const modal = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      modal.content.subject = subject;

      return subject.asObservable();
    }
    else
      return true;
  }
}


@Injectable()
export class CanDeactivateOrderDetail implements CanDeactivate<OrderDetailComponent> {


  constructor(private util: Utilities,
    private modalService: BsModalService) {

  }

  canDeactivate(component: OrderDetailComponent): boolean | Observable<boolean> | Promise<boolean> {
    if (component.hasUnsavedData()) {

      const subject = new Subject<boolean>();

      var initialState = {
        message: "You opened the Order in edit mode! Do you really want to leave this page?"
      };

      const modal = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      modal.content.subject = subject;

      return subject.asObservable();
    }
    else
      return true;
  }
}


@Injectable()
export class CanDeactivateOrderTranscodes implements CanDeactivate<OrderTranscodesComponent> {


  constructor(private util: Utilities,
    private modalService: BsModalService) {

  }

  canDeactivate(component: OrderTranscodesComponent): boolean | Observable<boolean> | Promise<boolean> {
    if (component.hasUnsavedData()) {

      const subject = new Subject<boolean>();

      var initialState = {
        message: "You have unsaved changes! Do you really want to leave this page?"
      };

      const modal = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      modal.content.subject = subject;

      return subject.asObservable();
    }
    else
      return true;
  }
}


@Injectable()
export class CanDeactivateCreateVault implements CanDeactivate<VaultCreateComponent> {


  constructor(private util: Utilities,
    private modalService: BsModalService) {

  }

  canDeactivate(component: VaultCreateComponent): boolean | Observable<boolean> | Promise<boolean> {
    if (component.hasUnsavedData()) {

      const subject = new Subject<boolean>();

      var initialState = {
        message: "You have unsaved changes! Do you really want to leave this page?"
      };

      const modal = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      modal.content.subject = subject;

      return subject.asObservable();
    }
    else
      return true;
  }
}
