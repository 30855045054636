import { OrderDestination } from "./order-destinations.model";
import { OrderFile } from "./order-create.model";
import { FileNameOption } from "./transcodeorder-config.model";

export class OrderUserDestination {
  public name: string;
  public category: string;
  public id: number;
  public destinations: Array<OrderDestination>;
  public specifications: Array<TranscodeSpecification>;
  public fileNamingOptions: FileNamingOption;
  public favDestination: string;
  public ftpServer: string;
  public ftpUserName: string;
  public ftpPassword: string;
  public ftpPort: string;

  public orderDestinationId: number;
  public isMarked: boolean;
  public hasSpecMappings: boolean;

  public containsDestinationError: boolean;
  public errorMsg: string;
  public primaryDestinationCode: string;
}

export class FileNamingOption {
  public options: Array<FileNameOption>;
  public seperator: string;
}

export class TranscodeSpecification {
  public id: number;
  public name: string;
  public description: string;
  //public recipeName: string;
  //public transcodeCustomFileName: string;
}

export class OrderDetailFTPDestinationModel {
  public orderFTPDestinationDetailModelList: OrderFTPDestinationDetailModel[];
  public showActionsColumn: boolean;
}

export class OrderFTPDestinationDetailModel {
  public orderGuid: string;
  public orderDestinationId: number;
  public name: string;
  public ftpServer: string;
  public ftpUserName: string
  public ftpPassword: string;
  public ftpPort: string;
  public status: string;
  public showUploadPod: boolean;
  public showPod: boolean;
  public podDate: Date;
  public ftpPodFileName: string;
  public showEditIcon: boolean;
  public showDeletePod: boolean;
}

export class OrderContentDownloadViewModel {
  public assetFileGuid: string;
  public adId: string;
  public olvProfile: string;
  public fileName: string;
  public fileSize: string;
  public duration: string;
  public canDownload: boolean;
  public filePath: string;
  public fileExtension: string
}
