import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import { Subject } from 'rxjs';
import { AdminService } from "../../../services/admin/admin.service";
import { VendorDestinationSearch } from "../../../models/admin/destination/createdestination.model";
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'adminvendordestinationmappingpopup.component.ts',
  templateUrl: './adminvendordestinationmappingpopup.component.html',
  styleUrls: ['./adminvendordestinationmappingpopup.component.css'],
})
export class AdminVendorDestinationMappingComponent implements OnInit {

  public dataSource: any = {};
  public quickSearchValue: string = '';
  public onClose: Subject<string>;
  public searchDestinationCriteriaObj = <VendorDestinationSearch>{};

  @ViewChild('vendorDestinationsGrid', { static: true }) vendorDestinationsGrid: DxDataGridComponent;

  @Input('vendorId') vendorId: number;
  @Input('vendorName') vendorName: string;
  @Input('mappingId') mappingId: string = null;

  public enterMapDestinationIdFormGroup: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private util: Utilities,
    private adminService: AdminService,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.enterMapDestinationIdFormGroup = this.fb.group({
      mapDestinationId: [null, [Validators.required]]
    });

    this.onClose = new Subject();
    this.searchDestinationCriteriaObj.vendorId = this.vendorId;
    this.searchDestinations();
  }

  public onFormSubmit({ value, valid }: { value: any, valid: boolean }, f: NgForm) {

    if (!valid) {
      return;
    }

    this.adminService.isVendorDestinationCodeValid(value.mapDestinationId, this.vendorId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        if (res.result) {
          this.closePopup();
          this.onClose.next(value.mapDestinationId);
        }
        else {
          this.enterMapDestinationIdFormGroup.controls['mapDestinationId'].setErrors({ invalidDestination: true })
        }
      }
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '') {
      return;
    }

    this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
    this.vendorDestinationsGrid.instance.refresh();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
      this.vendorDestinationsGrid.instance.refresh();
    }
  }

  public onAddDestinationMapping() {
    var data = this.vendorDestinationsGrid.instance.getSelectedRowsData();
    if (this.util.notEmpty(data) && data.length > 0) {
      var dataCode = data.map(d => d.vendorDestinationCode);
      this.onClose.next(dataCode[0]);
    }
    else {
      this.onClose.next(this.mappingId);
    }
    this.closePopup();
  }

  private searchDestinations() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchDestinationCriteriaObj;

    this.dataSource.store = new CustomStore({
      key: "vendorDestinationId",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return adminService.searchVendorDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var destinationSearchResponse = response.result;
              var obj: any = {
                data: destinationSearchResponse.vendorDestinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error)) {
              this.closePopup();
            }
            throw 'Data Loading Error';
          });
      }
    });
  }
}
