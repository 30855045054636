<header class="tylie-header">
  <h3 class="tylie-header__title">Users</h3>
</header>

<div class="tylie-body">
  <div class="d-flex justify-content-start">
    <div class="tylie-form-group form-group d-flex flex-column me-3" secured [accessControlListComponent]="[this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminClientRoleName(), this.userRoles.getAdminSystemsRoleName()]">
      <label class="tylie-form-group__lbl"><b>Create New User</b></label>
      <button type="button" class="tylie-button tylie-button--sm tylie-button--icon"(click)="onClickCreateUser()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">CREATE NEW USER</span>
      </button>
    </div>
    <form [formGroup]="selectUserFormGroup" (ngSubmit)="onFormSubmit(selectUserFormGroup)" novalidate #f="ngForm">
      <div class="d-flex justify-content-start align-items-end">
        <div class="tylie-form-group form-group d-flex flex-column me-3" *ngIf="userHomeConfig.allowSpecialOptions == true" secured [accessControlListComponent]="[this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminSystemsRoleName()]"
            [ngClass]="{error: (selectUserFormGroup.controls['email'].errors && !selectUserFormGroup.controls['email'].pristine)
                || (selectUserFormGroup.controls['email'].errors && f.submitted)}">
          <label class="tylie-form-group__lbl"><b>Add User Profile to Existing User</b></label>
          <dx-autocomplete placeholder="Type username" class="tylie-text"
                          formControlName="email">
          </dx-autocomplete>
        
          <p class="p-extra-small">Please Enter a Username</p>
        </div>
        <div class="tylie-form-group form-group" *ngIf="userHomeConfig.allowSpecialOptions == true" secured [accessControlListComponent]="[this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminSystemsRoleName()]">
          <button class="tylie-button tylie-button--sm tylie-button--icon" type="submit">
            <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
            <span class="tylie-button__text">Add User Profile</span>
          </button>
        </div>
      </div>
    </form>
  </div>

  <search-users secured [accessControlListComponent]="[this.userRoles.getAdminViewRoleName(),this.userRoles.getAdminClientServicesRoleName(), this.userRoles.getAdminClientRoleName(), this.userRoles.getAdminSystemsRoleName()]"></search-users>

  <tmc-registration-requests *isAuthorized="[this.userRoles.getOrderDetailEditorRoleName()]"></tmc-registration-requests>
</div>
