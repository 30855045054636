export class UserEmailDistributionGroups{

  public groupGuid: string;
  public groupName: string;
  public groupEmails: string[];
  public groupEmailsDisplay: string;
  public isPasswordProtected: boolean;
  public hasSpecMappings: boolean;
  public orderDestinationId: number;
  public isMarked: boolean;

  public containsDestinationError: boolean;
  public errorMsg: string;
  public primaryDestinationCode: string;

  constructor(groupGuid: string, groupName: string, groupEmails:string[]) {
    this.groupGuid = groupGuid;
    this.groupName = groupName;
    this.groupEmails = groupEmails;
  }
}
