import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-administrativereporting',
  templateUrl: './serviceadministrativereporting.component.html'
})
export class ServiceAdministrativeReportingQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super(); 
  }

  ngOnInit() {

    super.ngOnInit(); 
  }
}
