<div id="order-conformation">

  <div class="modal-header">
    <button *ngIf="this.askingConfirmation == true" type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{headerText}}</h4>
  </div>

  <div class="modal-body">

    <ng-container *ngIf="isShowCopy == true">
      <div class="tylie-form-group form-group ">
        <div class="d-flex justify-content-between">
          <label class="tylie-form-group__lbl" for="subject">Shareable Link</label>
          <!--<button (click)="generateShareLink(shareMediaForm)" class="tylie-button tylie-button--link tylie-button--xs" type="button" for=" to-email">Generate Shareable Link</button>-->
        </div>

        <div class="tylie-text-group input-group mb-3">
          <input value="{{generatedLink}}" type="text" class="tylie-text form-control" name="sharelink" id="sharelink" type="text">
          <button class="tylie-button tylie-button--sm" type="button" (click)="copyLink()">Copy</button>
        </div>
      </div>
    </ng-container>

    <div>
      <h3 class="black p-normal">{{description}}</h3>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="confirmClicked()"><span class="tylie-button__text">{{successButton}}</span></button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()" *ngIf="this.askingConfirmation == true">Cancel</button>
    </div>

  </div>
</div>
