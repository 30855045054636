import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';

@Component({
  selector: 'posthouse-addproduct',
  templateUrl: './posthouseaddproductpopup.component.html',
  styleUrls: ['./posthouseaddproductpopup.component.css'],
})
export class PostHouseAddProductPopUp implements OnInit {

  @ViewChild('productsGrid', { static: true }) productsGrid: DxDataGridComponent;

  @Input('clientId') clientId: number = 0;
  @Input('agencyId') agencyId: any = null;
  @Input('brandId') brandId: number = 0;
  @Input('productKeys') productKeys: any[];

  public dataSource: any = {};
  public onClose: Subject<number[]>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
  }

  ngOnInit() {
    this.onClose = new Subject();
    if (this.util.notEmpty(this.clientId) && this.clientId > 0 && this.util.notEmpty(this.brandId) && this.brandId > 0) {
      this.getClientBrandProducts();
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onContentReady(e: any) {
    if (this.util.notEmpty(e) && this.util.notEmpty(e.component)) {
      e.component.selectRows(this.productKeys);
    }
  }

  public onaddSelectedProducts() {
    this.publishSelectedRowKeys();
  }

  private getClientBrandProducts() {
    this.configService.getAdminCurrentBrandProducts(this.brandId, this.clientId, this.agencyId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.dataSource = this.util.notEmpty(products) ? products.filter(pds => pds.id != 0) : [];
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
        this.alertService.ShowLoader(false);
      });
  }

  private publishSelectedRowKeys() {
    var selectedRows = this.productsGrid.instance.getSelectedRowsData();
    this.onClose.next(selectedRows);
    this.closePopup();
  }
}
