import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { SubService } from "../../../services/media/sub.service";
import { ShareMediaFileModel } from "../../../models/sharemedia/sharemediafile.model";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShareMediaService } from "../../../services/playlist/sharemedia.service";
import { EmailListValidatorTagBox } from "../../../configurations/custom-validations";
import { UserDataService } from '../../../services/user/user-data.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { HighresshareconfirmpopupComponent } from '../highresshareconfirmpopup/highresshareconfirmpopup.component';

@Component({
  selector: 'sharemedia-popup',
  templateUrl: './sharemediapopup.component.html',
  styleUrls: ['./sharemediapopup.component.css'],
})
export class ShareMediaPopupComponent extends BasePopupComponent {
  public spotFileGuids: string[];
  public playListGuid: string;
  public albumName: string;
  public source: string = '';
  public showShareLink: boolean = false;
  public isGenerateLinkAction: boolean = false;
  public showPasswordError: boolean = false;
  public showLimitViewsError: boolean = false;
  public currentApproverLevel: number = 1;
  public showApproval: boolean = false;
  public emailSuggestions: Array<string> = [];
  public isMasterMediaShare: boolean = false;
  public isMasterShareInternalUser: boolean = false;
  public showMasterMediaShare: boolean = false;

  @Input('showOnlyBasicActions') showOnlyBasicActions: boolean = false;

  public isKubotaMasterShare: boolean = false;
  public isGeneratedShareableLink: boolean = false;

  public modalRef: BsModalRef;

  shareMediaForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private userService: UserDataService,
    public util: Utilities,
    private shareMediaService: ShareMediaService,
    private modalService: BsModalService,
    private subService: SubService) {

    super();

    this.shareMediaForm = new FormGroup({
      toemail: new FormControl('', [EmailListValidatorTagBox]),
      subject: new FormControl('', Validators.required),
      linkexpire: new FormControl('', Validators.required),
      optshareapproval: new FormControl(''),
      totalApprovalLevels: new FormControl(''),
      optdownload: new FormControl(),
      nonbillable: new FormControl(),
      comments: new FormControl(''),
      password: new FormControl(''),
      optcopy: new FormControl(),
      optviews: new FormControl(),
      optpassword: new FormControl(),
      limitviews: new FormControl(),
      sharelink: new FormControl(''),
      optShareMasterMedia: new FormControl(''),
      includeProxy: new FormControl(''),
      optlimitdownloads: new FormControl(''),
      limitdownloads: new FormControl(),
    });

    this.searchUserEmails();
  }

  ngOnInit() {
    super.ngOnInit();

    this.isKubotaMasterShare = this.isMasterMediaShare && this.showOnlyBasicActions

    this.getShareMediaConfig();

    this.shareMediaForm.controls['optdownload'].setValue(false);
    this.shareMediaForm.controls['nonbillable'].setValue(false);
    this.shareMediaForm.controls['optcopy'].setValue(false);
    this.shareMediaForm.controls['optviews'].setValue(false);
    this.shareMediaForm.controls['optpassword'].setValue(false);
    this.shareMediaForm.controls['limitviews'].setValue(1);
    this.shareMediaForm.controls['optshareapproval'].setValue(false);
    this.shareMediaForm.controls['totalApprovalLevels'].setValue(this.currentApproverLevel);
    this.shareMediaForm.controls['optShareMasterMedia'].setValue(false);
    this.shareMediaForm.controls['includeProxy'].setValue(false);
    this.shareMediaForm.controls['optlimitdownloads'].setValue(false);
    this.shareMediaForm.controls['limitdownloads'].setValue(1);

    this.shareMediaForm.get('optshareapproval').valueChanges.subscribe(
      (shareapproval: boolean) => {
        if (shareapproval === true)
          this.shareMediaForm.get('optShareMasterMedia').setValue(false);
      });

    this.shareMediaForm.get('optShareMasterMedia').valueChanges.subscribe(
      (sharemastermedia: boolean) => {
        if (sharemastermedia === true)
          this.shareMediaForm.get('optshareapproval').setValue(false);
      });

    if (this.isMasterMediaShare == true) {
      this.shareMediaForm.controls['optShareMasterMedia'].setValue(true);
      //this.shareMediaForm.controls['linkexpire'].setValue('365');
    }
  }

  public getTotalLevels(): number {
    if (this.currentApproverLevel > 0) {
      return this.currentApproverLevel
    }
    else {
      return 1;
    }
  }

  public autoGeneratePassword() {
    this.shareMediaService.autoGeneratePassword().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.shareMediaForm.controls['password'].setValue(res.result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public getShareMediaConfig() {
    this.alertService.ShowLoader(true);
    this.shareMediaService.getShareMediaConfig().subscribe((res: any) => {
      console.log(res);
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.isMasterShareInternalUser = res.result.isMasterShareInternalUser;
      }
    },
      error => {
        this.alertService.ShowLoader(false);
      });
  }

  public generateShareLink(shareMediaObj: any) {
    this.showShareLink = false;
    this.isGenerateLinkAction = true;
    this.showPasswordError = true;
    this.showLimitViewsError = true;

    if (this.shareMediaForm.controls['linkexpire'].invalid) {
      console.log(this.shareMediaForm.value.linkexpire);
      return;
    }
    else {
      if ((this.shareMediaForm.value.optpassword == true && this.shareMediaForm.value.password == '') ||
        (this.shareMediaForm.value.optviews == true && this.shareMediaForm.value.limitviews == null)
        || (this.shareMediaForm.value.optshareapproval == true && this.shareMediaForm.value.totalApprovalLevels == null)) {
        return;
      }
      else {
        //write logic for confirm popup
        if (this.showOnlyBasicActions) {
          var initialState = {
            askingConfirmation: true,
            isGenerateShareableLink: true
          };

          this.modalRef = this.modalService.show(HighresshareconfirmpopupComponent, this.util.getModalComponentOptions(initialState, false, true, false, 'modal-dialog-centered'));

          this.modalRef.content.onConfirmed.subscribe(result => {
            if (result) {
              this.confirmGenerateShareableLine();
            }
          });
        }
        else {
          this.confirmGenerateShareableLine();
        }
      }
    }
  }

  public confirmGenerateShareableLine() {
    if (this.isMasterMediaShare == true) {
      this.shareMediaForm.controls['optShareMasterMedia'].setValue(true);
    }

    let shareMediaFile = new ShareMediaFileModel(
      this.shareMediaForm.value.optshareapproval,
      this.shareMediaForm.value.optshareapproval == true ? this.shareMediaForm.value.totalApprovalLevels : null,
      this.spotFileGuids,
      this.albumName,
      "",
      "",
      this.shareMediaForm.value.optpassword == true ? this.shareMediaForm.value.password : null,
      this.shareMediaForm.value.optpassword,
      false,
      this.shareMediaForm.value.optdownload,
      this.shareMediaForm.value.linkexpire,
      this.shareMediaForm.value.optviews == true ? this.shareMediaForm.value.limitviews : Constants.maxLimit,
      [],
      this.shareMediaForm.value.optShareMasterMedia,
      this.shareMediaForm.value.optlimitdownloads == true ? this.shareMediaForm.value.limitdownloads : Constants.maxLimit,
      this.shareMediaForm.value.includeProxy,
      this.isMasterMediaShare,
      this.shareMediaForm.value.nonbillable,
      this.isMasterShareInternalUser,
      this.playListGuid
    );

    this.alertService.ShowLoader(true);

    this.shareMediaService.generateTokenEndPoint(shareMediaFile).subscribe((res: any) => {
      if (res.isSuccess == true) {
        if (this.showOnlyBasicActions) {
          var initialState = {
            askingConfirmation: false,
            isGenerateShareableLink: true,
            generatedLink: res.result
          };

          this.modalRef = this.modalService.show(HighresshareconfirmpopupComponent, this.util.getModalComponentOptions(initialState, false, true, false, 'modal-dialog-centered'));

          this.modalRef.content.onConfirmed.subscribe(result => {
            if (result) {
              this.generatedShareableLinkCompleted(res.result);
            }
          });
        } else {
          this.generatedShareableLinkCompleted(res.result);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    }, err => {
        if (this.util.handleError(err)) {
          this.closePopup();
          this.alertService.ShowLoader(false);
        }
    });
  }

  public generatedShareableLinkCompleted(link: string) {
    this.showShareLink = true;
    this.shareMediaForm.controls['sharelink'].setValue(link);
    this.isGeneratedShareableLink = true;
    if (this.isKubotaMasterShare) {
      this.closeModal();
    }
  }

  public shareMedia(shareMediaObj: any) {
    this.isGenerateLinkAction = false;

    if (this.shareMediaForm.invalid || (this.shareMediaForm.value.optpassword == true && this.shareMediaForm.value.password == '') ||
      (this.shareMediaForm.value.optviews == true && this.shareMediaForm.value.limitviews == null)
      || (this.shareMediaForm.value.optshareapproval == true && this.shareMediaForm.value.totalApprovalLevels == null)) {
      return;
    }

    if (this.showOnlyBasicActions) {
      var initialState = {
        askingConfirmation: true,
        isGenerateShareableLink: false
      };

      this.modalRef = this.modalService.show(HighresshareconfirmpopupComponent, this.util.getModalComponentOptions(initialState, false, true, false, 'modal-dialog-centered'));

      this.modalRef.content.onConfirmed.subscribe(result => {
        if (result) {
          this.confirmShareClicked();
        }
      });
    }
    else
    {
      this.confirmShareClicked();
    }
  }

  public confirmShareClicked() {
    if (this.isMasterMediaShare == true) {
      this.shareMediaForm.controls['optShareMasterMedia'].setValue(true);
    }

    let shareMediaFile = new ShareMediaFileModel(
      this.shareMediaForm.value.optshareapproval,
      this.shareMediaForm.value.optshareapproval == true ? this.shareMediaForm.value.totalApprovalLevels : null,
      this.spotFileGuids,
      this.albumName,
      this.shareMediaForm.value.subject,
      this.shareMediaForm.value.comments,
      this.shareMediaForm.value.optpassword == true ? this.shareMediaForm.value.password : null,
      this.shareMediaForm.value.optpassword,
      this.shareMediaForm.value.optcopy,
      this.shareMediaForm.value.optdownload,
      this.shareMediaForm.value.linkexpire,
      this.shareMediaForm.value.optviews == true ? this.shareMediaForm.value.limitviews : Constants.maxLimit,
      this.shareMediaForm.value.toemail as Array<string>,
      this.shareMediaForm.value.optShareMasterMedia,
      this.shareMediaForm.value.optlimitdownloads == true ? this.shareMediaForm.value.limitdownloads : Constants.maxLimit,
      this.shareMediaForm.value.includeProxy,
      this.isMasterMediaShare,
      this.shareMediaForm.value.nonbillable,
      this.isMasterShareInternalUser,
      this.playListGuid
    );

    this.alertService.ShowLoader(true);

    this.shareMediaService.shareMedia(shareMediaFile).subscribe((res: any) => {
      if (res.isSuccess == true) {
        if (this.showOnlyBasicActions) {
          var initialState = {
            askingConfirmation: false,
            isGenerateShareableLink: false
          };

          this.modalRef = this.modalService.show(HighresshareconfirmpopupComponent, this.util.getModalComponentOptions(initialState, false, true, false, 'modal-dialog-centered'));

          this.modalRef.content.onConfirmed.subscribe(result => {
            if (result) {
              this.shareSuccessCompleted();
            }
          });

        } else {
          this.shareSuccessCompleted();
        }
      }
      else
      {
        this.util.handleIsNotSuccess(res.errors);
        this.closeModal();
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
        this.alertService.ShowLoader(false);
      });
  }

  public shareSuccessCompleted() {
    this.alertService.showMessage("SUCCESS", Constants.sharedMediaSuccessful, MessageSeverity.success);
    var sourceCalled = this.source;
    this.source = '';
    this.deselectSubscribe(sourceCalled);

    this.closeModal();
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public deselectSubscribe(source: string) {
    if (source == "search") {
      this.subService.callDeselectSearchGridRowsMethod();
    }
    else if (source == "recentlyviewed") {
      this.subService.callDeselectRecentlyViewedRowsMethod();
    }
  }

  public copyLink() {
    var urlElement = document.getElementById("sharelink");
    if (urlElement != null || urlElement != undefined) {
      (<HTMLInputElement>urlElement).select();
      document.execCommand("copy");
    }
  }

  public onKeyEvent(e) {
    //comma, semi-colon, space
    console.log(e)
    if (e.event.keyCode === 186 || e.event.keyCode === 59 || e.event.keyCode === 32 || e.event.keyCode === 188 || e.event.keyCode === 44) {
      var ev = jQuery.Event("keydown");
      ev.which = 13;
      e.component.field().trigger(ev)
      e.jQueryEvent.preventDefault();
    }
  }

  private addFreeTextValue(e) {
    var values = e.component.option('value');
    var inputValue = e.element.find('input').val();

    if (inputValue && inputValue.length > 0) {

      if (!values)
        values = [];

      values.push(inputValue);

      e.component.option('value', values);
    }
  }

  public onFocusOut(e) {
    this.addFreeTextValue(e);
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  private searchUserEmails() {

    let request: any = { quickSearch: '' };

    this.userService.searchUserFavoriteEmails(request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var emailList = res.result as Array<any>;
        var onlyEmails: Array<string> = [];

        emailList.forEach(ind => {
          onlyEmails.push(ind.email);
        });

        this.emailSuggestions = onlyEmails;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }
}
