import { Component, ViewChild, TemplateRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../configurations/route-constants';
import { SpotService } from '../../../services/media/spot.service';
import { FileNameOption, TranscodeFileNaming, TranscodeMediaOrderRequest, TranscodeMediaOrderResponse, TranscodeOrderConfig } from '../../../models/order/transcodeorder-config.model';
import { TranscodeSearchMediaComponent } from './transcode-searchmedia-step/transcode-searchmedia-step.component';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { TranscodeSpecSelectionComponent } from './transcode-specselection-step/transcode-specselection-step.component';
import { TranscodeFilenameComponent } from './transcode-filename-step/transcode-filename-step.component';
import { OrderService } from '../../../services/order/order.service';
import { Order } from '../../../models/order/order.model';
import { Constants } from '../../../configurations/constants';
import { FileNamingTypeEnum, TranscodeOrderStepEnum } from '../../../configurations/enums/enums';
import { SelectAttachmentCategoryPopupComponent } from '../../popups/selectattachmentcategorypopup/selectattachmentcategorypopup.component';
import { CreateOrderRequest, OrderFile } from '../../../models/order/order-create.model';
import { OrderDetail } from '../../../models/order/order-detail.model';

@Component({
  selector: 'order_transcodes',
  templateUrl: './ordertranscodes.component.html',
  styleUrls: ['./ordertranscodes.component.css']
})

export class OrderTranscodesComponent implements OnInit {

  public modalRef: BsModalRef;

  public isLastStep: boolean;
  public isFirstStep: boolean;
  public currentStep: TranscodeOrderStepEnum;
  public clientId: number = null;
  public brandId: number;
  public isOrderSubmitted: boolean;
  public isOrderSubmitClicked: boolean = false;
  public transcodeOrderStepEnum = TranscodeOrderStepEnum;
  public workOrder: string;
  public isPoRequired: boolean = false;

  public transcodeOrderConfig: TranscodeOrderConfig;
  public orderRequest: TranscodeMediaOrderRequest;

  public selectedSpotFile: SpotFile = null;
  public selectedSpotFileTranscodes: Array<string> = null;
  public lastSelectedSpotFile: boolean = false;
  public orderErrors: Array<string> = [];
  public isTierMaxCapExceeded: false;
  public maxCapExceededMessage: string = '';
  public requestOrigination: any = "Transcode";
  public groupSequenceId: number = 0;
  public orderGuid: string = null;
  public orderGroupSpotFileGuids: string[] = [];
  public orderGrpTranscodeEditSpotFileGuid: string = null;
  public orderDetail: OrderDetail = null;
  public order: CreateOrderRequest = null;
  public onForceHasChangesPopup: boolean = false;

  @ViewChild(TranscodeSearchMediaComponent, { static: false }) searchMediaComp: TranscodeSearchMediaComponent;
  @ViewChild(TranscodeSpecSelectionComponent, { static: false }) specSelectionComp: TranscodeSpecSelectionComponent;
  @ViewChild(TranscodeFilenameComponent, { static: false }) fileNameComp: TranscodeFilenameComponent;

  constructor(
    private configService: ConfigService,
    public util: Utilities,
    private router: Router,
    public bsModalRef: BsModalRef,
    public alertService: AlertService,
    private orderService: OrderService,
    private modalService: BsModalService) {
    if (this.router != null && this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null
      && this.router.getCurrentNavigation().extras.state != null && this.router.getCurrentNavigation().extras.state.transcodeOrigination != null) {
      this.requestOrigination = this.router.getCurrentNavigation().extras.state.transcodeOrigination;
      this.groupSequenceId = this.router.getCurrentNavigation().extras.state.groupSequenceId;
      this.orderGuid = this.router.getCurrentNavigation().extras.state.orderGuid;
      this.orderGrpTranscodeEditSpotFileGuid = this.router.getCurrentNavigation().extras.state.orderGrpTranscodeEditSpotFileGuid;
    }
  }

  ngOnInit() {

    this.isLastStep = false;
    this.isFirstStep = true;
    this.currentStep = TranscodeOrderStepEnum.selectMedia;
    this.isOrderSubmitted = false;
    this.orderErrors = [];

    this.orderRequest = {
      transcodeOrderGuid: '',
      spotFilesData: [],
      isInternalRequest: false,
      spotFileTranscodesData: new Map(),
      customFileName: "",
      fileNameOptions: [],
      fileSeperator: "",
      referenceCode: "",
      selectionType: null,
      fileNamingType: 'basic',
      spotFiles: [],
      poNumber: "",
      jobNumber: "",
      attachments: [],
      spotFileTranscodes: [],
      originalWorkOrders: '',
      spotFileNamingData: new Map(),
      spotFileNaming: [],
      orderedBy: '',
      dealerName: '',
      dealerNumber: '',
      clientId:null,
      customFileNaming: []
    };

    this.getConfig();

    if (this.requestOrigination == 'Order' && this.groupSequenceId > 0) {
      this.getUnSavedOrder();
    }
    else if (this.requestOrigination == 'OrderEdit' && this.groupSequenceId > 0) {
      this.getOrderDetail();
    }
  }

  private getConfig() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getTranscodeOrderConfig().subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {

        this.transcodeOrderConfig = res.result as TranscodeOrderConfig;
        this.orderRequest.transcodeOrderGuid = this.transcodeOrderConfig.transcodeOrderGuid;
        this.setConfig();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getOrderDetail() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.getOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.alertService.logMessage(res);

        this.orderDetail = res.result as OrderDetail;
        if (this.orderDetail != null) {
          this.clientId = this.orderDetail.header.clientId;
          this.brandId = this.orderDetail.header.brandId;

          var grpDetail = this.orderDetail.groups.find(t => t.sequenceId == this.groupSequenceId);

          if (grpDetail != null && grpDetail != undefined) {

            var transcodeData = grpDetail.transcodeRequest;

            if (transcodeData != null && transcodeData != undefined) {
              var transcodeMap = new Map();
              transcodeData.spotFileTranscodes.forEach(t => {
                if (transcodeMap.get(t.spotFileGuid) == null) {
                  transcodeMap.set(t.spotFileGuid, t.transcodes);
                }
              });
              transcodeData.spotFileTranscodesData = transcodeMap;

              var fileNamingMap = new Map();
              transcodeData.spotFileNaming.forEach(t => {
                if (fileNamingMap.get(t.spotFileGuid) == null) {
                  fileNamingMap.set(t.spotFileGuid, t.fileNaming);
                }
              });
              transcodeData.spotFileNamingData = fileNamingMap;
              grpDetail.transcodeRequest = transcodeData;
            }

            if (this.orderGrpTranscodeEditSpotFileGuid != null) {
              var selectedSpot = grpDetail.transcodeRequest.spotFilesData.find(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
              if (selectedSpot != undefined) {
                this.orderGroupSpotFileGuids.push(this.orderGrpTranscodeEditSpotFileGuid);

                this.orderRequest.spotFilesData.push(selectedSpot);
                this.orderRequest.spotFileTranscodesData.set(this.orderGrpTranscodeEditSpotFileGuid, grpDetail.transcodeRequest.spotFileTranscodesData.get(this.orderGrpTranscodeEditSpotFileGuid));

                var spotFileNaming = grpDetail.transcodeRequest.spotFileNamingData.get(this.orderGrpTranscodeEditSpotFileGuid);
               
                this.orderRequest.fileNameOptions = spotFileNaming.fileNameOptions;
                this.orderRequest.fileNamingType = spotFileNaming.fileNamingType;
                this.orderRequest.fileSeperator = spotFileNaming.fileSeperator;
                this.orderRequest.customFileName = spotFileNaming.customFileName;

                var customFileForSpot = grpDetail.transcodeRequest.customFileNaming.filter(cn => cn.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);

                this.orderRequest.customFileNaming = customFileForSpot;

                if (!this.orderRequest.spotFileNamingData.has(this.orderGrpTranscodeEditSpotFileGuid))
                  this.orderRequest.spotFileNamingData.set(this.orderGrpTranscodeEditSpotFileGuid, new TranscodeFileNaming);

                this.isLastStep = false;
                this.isFirstStep = true;
                this.currentStep = TranscodeOrderStepEnum.selectTranscode;

                this.moveToSelectTranscodeStep();
              }
            }
            else {
              grpDetail.spotData.forEach(t => {
                if (t.spotFile.format.toLowerCase() != 'audio') {
                  if (grpDetail.transcodeRequest == null) {
                    this.orderGroupSpotFileGuids.push(t.spotFileGuid);
                  }
                  else {
                    if (grpDetail.transcodeRequest.spotFilesData.find(s => s.spotFileGuid == t.spotFileGuid) == undefined) {
                      this.orderGroupSpotFileGuids.push(t.spotFileGuid);
                    }
                  }
                }
              }
              );
            }
          }
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getUnSavedOrder() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.getUnSavedOrder(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.order = res.result.unSavedOrder as CreateOrderRequest;

        this.clientId = this.order.header.clientId;
        this.brandId = this.order.header.brandId;

        var grp = this.order.groups.find(t => t.sequenceId == this.groupSequenceId);

        if (grp != null && grp != undefined) {

          var transcodeData = grp.transcodeRequest;

          if (transcodeData != null && transcodeData != undefined) {
            var transcodeMap = new Map();
            transcodeData.spotFileTranscodes.forEach(t => { transcodeMap.set(t.spotFileGuid, t.transcodes) });
            transcodeData.spotFileTranscodesData = transcodeMap;

            var fileNamingMap = new Map();
            transcodeData.spotFileNaming.forEach(t => { fileNamingMap.set(t.spotFileGuid, t.fileNaming) });
            transcodeData.spotFileNamingData = fileNamingMap;

            grp.transcodeRequest = transcodeData;
          }

          if (this.orderGrpTranscodeEditSpotFileGuid != null) {
            var selectedSpot = grp.transcodeRequest.spotFilesData.find(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            if (selectedSpot != undefined) {
              this.orderGroupSpotFileGuids.push(this.orderGrpTranscodeEditSpotFileGuid);

              this.orderRequest.spotFilesData.push(selectedSpot);

              console.log(selectedSpot);

              this.orderRequest.spotFileTranscodesData.set(this.orderGrpTranscodeEditSpotFileGuid, grp.transcodeRequest.spotFileTranscodesData.get(this.orderGrpTranscodeEditSpotFileGuid));

              var spotFileNaming = grp.transcodeRequest.spotFileNamingData.get(this.orderGrpTranscodeEditSpotFileGuid);

              this.orderRequest.fileNameOptions = spotFileNaming.fileNameOptions;
              this.orderRequest.fileNamingType = spotFileNaming.fileNamingType;
              this.orderRequest.fileSeperator = spotFileNaming.fileSeperator;
              this.orderRequest.customFileName = spotFileNaming.customFileName;

              var customFileForSpot = grp.transcodeRequest.customFileNaming.filter(cn => cn.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);

              this.orderRequest.customFileNaming = customFileForSpot;

              console.log(this.orderRequest);

              if (!this.orderRequest.spotFileNamingData.has(this.orderGrpTranscodeEditSpotFileGuid))
                this.orderRequest.spotFileNamingData.set(this.orderGrpTranscodeEditSpotFileGuid, new TranscodeFileNaming);

              this.isLastStep = false;
              this.isFirstStep = true;
              this.currentStep = TranscodeOrderStepEnum.selectTranscode;

              this.moveToSelectTranscodeStep();
            }
          }
          else {
            grp.spotData.forEach(t => {
              if (t.spotFile.format.toLowerCase() != 'audio') {
                if (grp.transcodeRequest == null) {
                  this.orderGroupSpotFileGuids.push(t.spotFileGuid);
                }
                else {
                  if (grp.transcodeRequest.spotFilesData.find(s => s.spotFileGuid == t.spotFileGuid) == undefined) {
                    this.orderGroupSpotFileGuids.push(t.spotFileGuid);
                  }
                }
              }
            }
            );
          }

          console.log(grp.transcodeRequest);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private setConfig() {
    if (this.transcodeOrderConfig.fileNameSeperators && this.transcodeOrderConfig.fileNameSeperators.length > 0)
      this.orderRequest.fileSeperator = this.transcodeOrderConfig.fileNameSeperators[0].name;

    if (this.transcodeOrderConfig.fileNameOptions && this.transcodeOrderConfig.fileNameOptions.length >= 3 && this.orderGrpTranscodeEditSpotFileGuid == null) {
      this.transcodeOrderConfig.fileNameOptions.slice(0, 4).forEach(t => {
        this.orderRequest.fileNameOptions.push({ name: t, isCustom: false, selected: true });
      });
    }

    if (this.transcodeOrderConfig.isAdvancedFileNamingEnabled) {
      this.orderRequest.fileNamingType = 'advanced';
    }

    if (this.transcodeOrderConfig.isDealerUser) {
      this.orderRequest.orderedBy = this.transcodeOrderConfig.orderedByEmail;
      this.orderRequest.dealerName = this.transcodeOrderConfig.dealerName;
      this.orderRequest.dealerNumber = this.transcodeOrderConfig.dealerNumber;
    }
  }

  public onAttachmentDelete(orderFileGuid: string) {
    this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var existingAttachments = this.orderRequest.attachments as Array<OrderFile>;

        if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
          var newAttachmentsAfterDelete = existingAttachments.filter(t => t.orderFileGuid.toLowerCase() != orderFileGuid.toLowerCase());

          this.orderRequest.attachments = newAttachmentsAfterDelete;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

    },
      error => {
        this.util.handleError(error);
      });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onGotoAllOrders() {
    this.router.navigate([RouteConstants.transcodeFilesRoute]);
  }

  public onGotoCurrentOrder() {
    this.onForceHasChangesPopup = true;

    if (this.requestOrigination == 'Order') {
      this.router.navigate([RouteConstants.createorderRoute], {
        state: { isBackFromTrancodeRequest: true, orderGuid: this.orderGuid }
      });
    }

    if (this.requestOrigination == 'OrderEdit') {
      this.router.navigate([RouteConstants.orderDetailRoute, this.orderGuid], {
        state: { isBackFromTrancodeRequest: true }
      });
    }
  }

  public onSaveContinueToOrder() {
    // save order edit
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.orderRequest.spotFiles = this.orderRequest.spotFilesData.map(a => a.spotFileGuid);

    this.orderRequest.spotFileTranscodes = [];

    this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
      this.orderRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
    });

    this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
      this.orderRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
    });

    this.orderDetail.groups.forEach(t => {
      if (t.sequenceId == this.groupSequenceId) {
        if (t.transcodeRequest == null) {
          t.transcodeRequest = this.orderRequest;
        }
        else {
          if (this.orderGrpTranscodeEditSpotFileGuid != null) {
            t.transcodeRequest.spotFileNamingData.set(this.orderGrpTranscodeEditSpotFileGuid, this.orderRequest.spotFileNamingData.get(this.orderGrpTranscodeEditSpotFileGuid));
            t.transcodeRequest.spotFileTranscodesData.set(this.orderGrpTranscodeEditSpotFileGuid, this.orderRequest.spotFileTranscodesData.get(this.orderGrpTranscodeEditSpotFileGuid));

            var spotFileIdIndex = t.transcodeRequest.spotFileTranscodes.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.spotFileTranscodes.splice(spotFileIdIndex, 1);
            this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
              t.transcodeRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
            });

            var spotFileIdNamingIndex = t.transcodeRequest.spotFileNaming.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.spotFileNaming.splice(spotFileIdNamingIndex, 1);
            this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
              t.transcodeRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
            });

            console.log(this.orderRequest.customFileNaming);
            var customFileNamingIndex = t.transcodeRequest.customFileNaming.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.customFileNaming.splice(customFileNamingIndex, 1);
            console.log(this.orderRequest.customFileNaming);
            t.transcodeRequest.customFileNaming.push(this.orderRequest.customFileNaming[0]);
          }
          else {
            this.orderRequest.spotFilesData.forEach(s => {
              if (!t.transcodeRequest.spotFiles.includes(s.spotFileGuid)) {
                t.transcodeRequest.spotFilesData.push(s);
                t.transcodeRequest.spotFiles.push(s.spotFileGuid);


                if (t.transcodeRequest.spotFileTranscodesData == null || t.transcodeRequest.spotFileTranscodesData == undefined) {
                  t.transcodeRequest.spotFileTranscodesData = new Map().set(s.spotFileGuid, this.orderRequest.spotFileTranscodesData.get(s.spotFileGuid));
                }
                else {
                  t.transcodeRequest.spotFileTranscodesData.set(s.spotFileGuid, this.orderRequest.spotFileTranscodesData.get(s.spotFileGuid));
                }

                if (t.transcodeRequest.spotFileNamingData == null || t.transcodeRequest.spotFileNamingData == undefined) {
                  t.transcodeRequest.spotFileNamingData = new Map().set(s.spotFileGuid, this.orderRequest.spotFileNamingData.get(s.spotFileGuid));
                }
                else {
                  t.transcodeRequest.spotFileNamingData.set(s.spotFileGuid, this.orderRequest.spotFileNamingData.get(s.spotFileGuid));
                }
              }
            });

            this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
              t.transcodeRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
            });

            this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
              t.transcodeRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
            });

            this.orderRequest.customFileNaming.forEach((fileNaming) => {
              if (t.transcodeRequest.customFileNaming.find(cn => cn.spotFileGuid == fileNaming.spotFileGuid) == null) {
                t.transcodeRequest.customFileNaming.push(fileNaming);
              }
              else {
                var customFileNamingIndex = t.transcodeRequest.customFileNaming.findIndex(s => s.spotFileGuid == fileNaming.spotFileGuid);
                t.transcodeRequest.customFileNaming.splice(customFileNamingIndex, 1);
                t.transcodeRequest.customFileNaming.push(fileNaming);
              }
            });
          }
        }
      }
    });

    var request: any = { orderServices: this.orderDetail.header.serviceData, groups: this.orderDetail.groups, saveSource: 'Transcode' };

    this.orderService.editOrder(request, this.orderGuid).subscribe((res: any) => {
      this.alertService.logMessage(res);

      if (res.isSuccess == true) {

        this.alertService.showMessage("SUCCESS", Constants.orderUpdated, MessageSeverity.success);

        this.orderDetail = res.result.orderDetail as OrderDetail;

        this.router.navigate([RouteConstants.orderDetailRoute, this.orderGuid], {
          state: { isBackFromTrancodeRequest: true }
        });
      }
      else {
        var errors = res.errors as any[];

        if (errors !== null && errors.length > 0) {
          this.util.handleIsNotSuccess(errors);
        }
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onAddToCurrentOrder() {
    this.orderRequest.spotFiles = this.orderRequest.spotFilesData.map(a => a.spotFileGuid);

    this.orderRequest.spotFileTranscodes = [];

    this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
      this.orderRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
    });

    this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
      this.orderRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
    });

    this.order.groups.forEach(t => {
      if (t.sequenceId == this.groupSequenceId) {
        if (t.transcodeRequest == null) {
          t.transcodeRequest = this.orderRequest;
        }
        else {
          if (this.orderGrpTranscodeEditSpotFileGuid != null) {
            t.transcodeRequest.spotFileTranscodesData.set(this.orderGrpTranscodeEditSpotFileGuid, this.orderRequest.spotFileTranscodesData.get(this.orderGrpTranscodeEditSpotFileGuid));
            t.transcodeRequest.spotFileNamingData.set(this.orderGrpTranscodeEditSpotFileGuid, this.orderRequest.spotFileNamingData.get(this.orderGrpTranscodeEditSpotFileGuid));

            var spotFileIdIndex = t.transcodeRequest.spotFileTranscodes.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.spotFileTranscodes.splice(spotFileIdIndex, 1);
            this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
              t.transcodeRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
            });

            var spotFileIdNamingIndex = t.transcodeRequest.spotFileNaming.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.spotFileNaming.splice(spotFileIdNamingIndex, 1);
            this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
              t.transcodeRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
            });

            console.log(this.orderRequest.customFileNaming);
            var customFileNamingIndex = t.transcodeRequest.customFileNaming.findIndex(s => s.spotFileGuid == this.orderGrpTranscodeEditSpotFileGuid);
            t.transcodeRequest.customFileNaming.splice(customFileNamingIndex, 1);
            console.log(this.orderRequest.customFileNaming);
            t.transcodeRequest.customFileNaming.push(this.orderRequest.customFileNaming[0]);
          }
          else {
            this.orderRequest.spotFilesData.forEach(s => {
              if (!t.transcodeRequest.spotFiles.includes(s.spotFileGuid)) {
                t.transcodeRequest.spotFilesData.push(s);
                t.transcodeRequest.spotFiles.push(s.spotFileGuid);
                t.transcodeRequest.spotFileTranscodesData.set(s.spotFileGuid, this.orderRequest.spotFileTranscodesData.get(s.spotFileGuid));
                t.transcodeRequest.spotFileNamingData.set(s.spotFileGuid, this.orderRequest.spotFileNamingData.get(s.spotFileGuid));
              }
            });

            this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
              t.transcodeRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
            });

            this.orderRequest.spotFileNamingData.forEach((fileNaming, spotFileGuid) => {
              t.transcodeRequest.spotFileNaming.push({ spotFileGuid: spotFileGuid, fileNaming: fileNaming });
            });

            this.orderRequest.customFileNaming.forEach((fileNaming) => {
              if (t.transcodeRequest.customFileNaming.find(cn => cn.spotFileGuid == fileNaming.spotFileGuid) == null) {
                t.transcodeRequest.customFileNaming.push(fileNaming);
              }
              else {
                var customFileNamingIndex = t.transcodeRequest.customFileNaming.findIndex(s => s.spotFileGuid == fileNaming.spotFileGuid);
                t.transcodeRequest.customFileNaming.splice(customFileNamingIndex, 1);
                t.transcodeRequest.customFileNaming.push(fileNaming);
              }
            });
          }
        }
      }
    });

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.saveUnSavedOrder(this.order).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.router.navigate([RouteConstants.createorderRoute], {
          state: { isBackFromTrancodeRequest: true, orderGuid: this.orderGuid }
        });
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onSubmitOrder() {
    this.isOrderSubmitClicked = true;

    if (this.transcodeOrderConfig.isDealerUser == false && this.isPoRequired && (this.orderRequest.poNumber == null || this.orderRequest.poNumber == "")) {
      return;
    }

    var errorsExist: boolean = false;

    this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
      if (transcodes == null || transcodes.length == 0) {
        errorsExist = true;
      }
    });

    if (errorsExist) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_no_olvprofiles, MessageSeverity.error);
      return;
    }

    this.orderRequest.spotFiles = this.orderRequest.spotFilesData.map(a => a.spotFileGuid);

    this.orderRequest.spotFileTranscodes = [];

    this.orderRequest.spotFileTranscodesData.forEach((transcodes, spotFileGuid) => {
      this.orderRequest.spotFileTranscodes.push({ spotFileGuid: spotFileGuid, transcodes: transcodes });
    });

    this.orderRequest.clientId = this.clientId;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.createTranscodeOrder(this.orderRequest).subscribe((res: any) => {

      console.log(res);

      if (res.isSuccess == true) {

        this.workOrder = res.result.workOrder;

        this.isOrderSubmitted = true;
      }
      else {
        var allErrors = res.result as TranscodeMediaOrderResponse;
        allErrors.errors.forEach(err => { this.orderErrors.push(err) });

        allErrors.transcodeMediaErrors.forEach(mediaErr => {
          mediaErr.errors.forEach(err => { this.orderErrors.push(`${mediaErr.adId}_${mediaErr.title}: ${err}`) });
        });

        this.alertService.showMessage("ERROR", Constants.transcodeorder_fixerrors, MessageSeverity.error);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public hasUnsavedData(): boolean {

    if ((this.isOrderSubmitted || this.requestOrigination == 'Order' || this.requestOrigination == 'OrderEdit') && this.onForceHasChangesPopup == false)
      return false;
    else
      return true;
  }

  public onContinue() {
    if (this.currentStep == TranscodeOrderStepEnum.selectMedia) {

      if (!this.validateSelectMediaStep())
        return;

      this.moveToSelectTranscodeStep();
    }
    else if (this.currentStep == TranscodeOrderStepEnum.selectTranscode) {

      if (!this.validateSelectTranscodeStep())
        return;

      this.moveToChooseFileNameStep();

    }
    else if (this.currentStep == TranscodeOrderStepEnum.chooseFileName) {

      if (!this.validateChooseFileNameStep())
        return;

      this.moveToFinalReviewStep();
    }
  }

  public onPrevious() {
    if (this.currentStep == TranscodeOrderStepEnum.selectTranscode) {
      this.moveToSelectMediaStep();
    }
    else if (this.currentStep == TranscodeOrderStepEnum.chooseFileName) {
      this.moveToSelectTranscodeStep();
    }
    else if (this.currentStep == TranscodeOrderStepEnum.reviewTranscode) {
      this.moveToChooseFileNameStep();
    }
  }

  public onAddAttachments() {
    var initialState = {
      attachmentCategories: this.transcodeOrderConfig.attachmentCategories,
      orderGuid: this.transcodeOrderConfig.transcodeOrderGuid
    };

    this.modalRef = this.modalService.show(SelectAttachmentCategoryPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onSelectAttachmentCategory.subscribe(result => {
      this.onSelectAttachmentCategorySubscriptionRaised(result);
    });
  }

  private onSelectAttachmentCategorySubscriptionRaised(orderFile: OrderFile) {
    this.addFileToHeader(orderFile);
  }

  private addFileToHeader(newFile: OrderFile) {
    var exisitingFiles = this.orderRequest.attachments as Array<OrderFile>;

    exisitingFiles.push(newFile);

    this.orderRequest.attachments = exisitingFiles;
  }

  private validateSelectMediaStep(): boolean {

    if (this.searchMediaComp.selectedSpotFiles.length == 0) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_choosemedia, MessageSeverity.error);
      return false;
    }

    var clients: Array<string> = [];

    this.searchMediaComp.selectedSpotFiles.forEach(t => {
      if (clients.indexOf(t.client) < 0)
        clients.push(t.client);
    });

    if (clients.length > 1 && this.requestOrigination == 'Transcode') {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_choosemedia_sameclienbt, MessageSeverity.error);
      return false;
    }

    this.orderRequest.spotFilesData = this.searchMediaComp.selectedSpotFiles;

    this.orderRequest.spotFilesData.forEach(spotFile => {

      if (!this.orderRequest.spotFileTranscodesData.has(spotFile.spotFileGuid))
        this.orderRequest.spotFileTranscodesData.set(spotFile.spotFileGuid, []);

      if (!this.orderRequest.spotFileNamingData.has(spotFile.spotFileGuid))
        this.orderRequest.spotFileNamingData.set(spotFile.spotFileGuid, new TranscodeFileNaming);
    });

    this.orderRequest.spotFileTranscodesData.forEach((transodes, spotFileGuid) => {

      var ind = this.orderRequest.spotFilesData.findIndex(t => t.spotFileGuid == spotFileGuid);

      if (ind < 0) {
        this.orderRequest.spotFileTranscodesData.delete(spotFileGuid);
        this.orderRequest.spotFileNamingData.delete(spotFileGuid);
      }
    });


    // non-billable, original workorders

    if (this.requestOrigination == 'Transcode') {
      if (this.transcodeOrderConfig.allowSpecialOptions) {
        this.orderRequest.isInternalRequest = this.searchMediaComp.nonBillableRequest.controls['isInternalRequest'].value;
        this.orderRequest.originalWorkOrders = this.searchMediaComp.nonBillableRequest.controls['originalWorkOrders'].value;
      }
      else {
        this.orderRequest.isInternalRequest = false;
        this.orderRequest.originalWorkOrders = null;
      }
    }

    this.clientId = this.searchMediaComp.clientId;

    return true;
  }

  private validateSelectTranscodeStep(): boolean {

    var selectedTranscodes = this.specSelectionComp.getAllSelectedTranscodes();

    if (selectedTranscodes.length == 0) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_chooseolvprofiles_media, MessageSeverity.error);
      return false;
    }

    this.orderRequest.selectionType = this.specSelectionComp.selectionType;

    //if the selection type is different then set the transcodes only the selectedSpotFile
    if (this.specSelectionComp.selectionType == 'different')
      this.orderRequest.spotFileTranscodesData.set(this.specSelectionComp.selectedSpotFile.spotFileGuid, selectedTranscodes);
    else {
      //if the selection type is not different then set the transcodes to all the spotFiles
      this.orderRequest.spotFileTranscodesData.forEach((transodes, spotFileGuid) => {
        this.orderRequest.spotFileTranscodesData.set(spotFileGuid, selectedTranscodes);
      });
    }

    var currentSpotFileIndex = this.orderRequest.spotFilesData.findIndex(t => t.spotFileGuid == this.specSelectionComp.selectedSpotFile.spotFileGuid);

    if (currentSpotFileIndex < (this.orderRequest.spotFilesData.length - 1) && this.specSelectionComp.selectionType == 'different') {

      this.specSelectionComp.refreshSelection();

      this.selectedSpotFile = this.orderRequest.spotFilesData[currentSpotFileIndex + 1];

      this.selectedSpotFileTranscodes = this.orderRequest.spotFileTranscodesData.get(this.selectedSpotFile.spotFileGuid);

      this.lastSelectedSpotFile = true;

      return false;
    }

    return true;
  }

  private validateChooseFileNameStep(): boolean {

    if (this.fileNameComp.isValid() == false)
      return false;

    this.orderRequest.fileNamingType = this.fileNameComp.fileNameForm.value.fileNamingType;

    if (this.fileNameComp.fileNameForm.value.fileNamingType == 'advanced') {
      this.orderRequest.fileSeperator = this.fileNameComp.fileNameForm.value.fileSeperator;
      this.orderRequest.customFileName = this.fileNameComp.fileNameForm.value.customFileName;
      this.orderRequest.fileNameOptions = this.fileNameComp.getSelectedValidFileNameOptions();
      this.orderRequest.customFileNaming = this.fileNameComp.customFileNameForm != null ? this.fileNameComp.customFileNameForm.value.customFileNaming : null;
    }
    else {
      this.orderRequest.fileSeperator = "";
      this.orderRequest.customFileName = "";
      this.orderRequest.fileNameOptions = [];
    }

    this.orderRequest.spotFileNamingData.forEach((any, spotFileGuid) => {
      this.orderRequest.spotFileNamingData.set(spotFileGuid, {
        selectionType: 'different',
        fileNameOptions: this.orderRequest.fileNameOptions,
        fileNamingType: this.orderRequest.fileNamingType,
        fileSeperator: this.orderRequest.fileSeperator,
        customFileName: this.orderRequest.customFileName
      });
    });

    console.log(this.orderRequest);

    return true;
  }

  private moveToFinalReviewStep() {
    if (this.transcodeOrderConfig.clients.find(c => c.id == this.clientId) != undefined) {
      this.isPoRequired = this.transcodeOrderConfig.clients.find(c => c.id == this.clientId).isPoRequired;
    }
    console.log(this.isPoRequired);
    this.currentStep = TranscodeOrderStepEnum.reviewTranscode;
    this.isFirstStep = false;
    this.isLastStep = true;
    this.orderErrors = [];

    if (this.orderRequest.isInternalRequest == false)
      this.makeMaxCapRequestsCheck();
  }

  public poNumberValidator(value: string): boolean {
    if (this.transcodeOrderConfig.isDealerUser == false && this.isPoRequired && this.isOrderSubmitClicked && (value == null || value.length == 0))
      return true;
    else
      return false;
  }

  private makeMaxCapRequestsCheck() {
    this.orderService.isMaxRequestReachedForTier({ clientId: this.orderRequest.spotFilesData[0].clientId, orderTranscodeCount: (this.getTranscodeAssetFilesCount()) }).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.isTierMaxCapExceeded = res.result.isMaxCapReached;
        this.maxCapExceededMessage = res.result.message;
      }
      else {
        this.isTierMaxCapExceeded = false;
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.isTierMaxCapExceeded = false;
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  getTranscodeAssetFilesCount() {
    var totalTranscodeAsset = 0;
    this.orderRequest.spotFileTranscodesData.forEach((value: any[], key: string) => {
      totalTranscodeAsset = totalTranscodeAsset + value.length;
      console.log(totalTranscodeAsset);
    });
    return totalTranscodeAsset;
  }


  private moveToChooseFileNameStep() {
    if (this.orderRequest.fileNamingType == 'basic') {

      if (this.transcodeOrderConfig.fileNameSeperators && this.transcodeOrderConfig.fileNameSeperators.length > 0)
        this.orderRequest.fileSeperator = this.transcodeOrderConfig.fileNameSeperators[0].name;

      if (this.transcodeOrderConfig.fileNameOptions && this.transcodeOrderConfig.fileNameOptions.length >= 4) {
        this.orderRequest.fileNameOptions = [];

        this.transcodeOrderConfig.fileNameOptions.slice(0, 4).forEach(t => {
          this.orderRequest.fileNameOptions.push({ name: t, isCustom: false, selected: true });
        });
      }
    }

    this.currentStep = TranscodeOrderStepEnum.chooseFileName;
    this.isFirstStep = false;
    this.isLastStep = false;
  }

  private moveToSelectTranscodeStep() {

    this.selectedSpotFile = this.orderRequest.spotFilesData[0];
    this.selectedSpotFileTranscodes = this.orderRequest.spotFileTranscodesData.get(this.selectedSpotFile.spotFileGuid);

    if (this.orderRequest.selectionType == null)
      this.orderRequest.selectionType = "different";

    if (this.orderRequest.spotFilesData.length == 1)
      this.lastSelectedSpotFile = true;
    else
      this.lastSelectedSpotFile = false;

    this.currentStep = TranscodeOrderStepEnum.selectTranscode;
    this.isFirstStep = false;
    this.isLastStep = false;
  }

  private moveToSelectMediaStep() {
    this.currentStep = TranscodeOrderStepEnum.selectMedia;
    this.isFirstStep = true;
    this.isLastStep = false;
  }

  public onDeleteInReview(spotFileGuid: string, transcodeToDelete: string) {
    var transcodes = this.orderRequest.spotFileTranscodesData.get(spotFileGuid);
    var updatedTranscodes: Array<string> = transcodes.filter(tran => tran != transcodeToDelete)

    this.orderRequest.spotFileTranscodesData.set(spotFileGuid, updatedTranscodes);

    this.orderRequest.selectionType = "different";

    if (this.orderRequest.isInternalRequest == false)
      this.makeMaxCapRequestsCheck();
  }

  public onForInternalRequestChange() {
    if (this.orderRequest.isInternalRequest == false)
      this.makeMaxCapRequestsCheck();
    else {
      this.maxCapExceededMessage = '';
      this.isTierMaxCapExceeded = false;
    }
  }

  public getFileName(transcode: string, spotfileGuid: string) {
    var spotFile = this.orderRequest.spotFilesData.find(t => t.spotFileGuid == spotfileGuid);
    if (this.orderRequest.fileNamingType == 'basic')
      return `${spotFile.adId}_${spotFile.title}_${spotFile.durationInSecs}`;
    else if (this.orderRequest.fileNamingType == 'advanced') {
      if (this.orderRequest.customFileNaming != null) {
        //return this.orderRequest.customFileName;
        var customNameObj = this.orderRequest.customFileNaming.find(t => t.spotFileGuid == spotfileGuid) != null ? this.orderRequest.customFileNaming.find(t => t.spotFileGuid == spotfileGuid).specFileNames : null;
        var transcodeFileName = customNameObj != null ? customNameObj.find(t => t.spec == transcode) : null;
        var customFileName =  transcodeFileName != null ? transcodeFileName.customSpecFileName : null;

        if (customFileName == null || customFileName == '') {
          let fileNameData: Array<string> = [];

          this.orderRequest.fileNameOptions.forEach(t => {
            if (t.isCustom == true) {
              fileNameData.push(t.name);
            }
            else {
              if (t.name == 'AdID')
                fileNameData.push(spotFile.adId);
              else if (t.name == 'Title')
                fileNameData.push(spotFile.title);
              else if (t.name == 'Duration')
                fileNameData.push(spotFile.durationInSecs.toString());
              else if (t.name == 'OLV Profile')
                fileNameData.push(transcode);
              else if (t.name == 'Aspect Ratio')
                fileNameData.push(spotFile.aspectRatio);
            }
          });

          return fileNameData.join(this.orderRequest.fileSeperator);
        }
        else {
          return customFileName;
        }
      }
      else {
        let fileNameData: Array<string> = [];

        this.orderRequest.fileNameOptions.forEach(t => {
          if (t.isCustom == true) {
            fileNameData.push(t.name);
          }
          else {
            if (t.name == 'AdID')
              fileNameData.push(spotFile.adId);
            else if (t.name == 'Title')
              fileNameData.push(spotFile.title);
            else if (t.name == 'Duration')
              fileNameData.push(spotFile.durationInSecs.toString());
            else if (t.name == 'OLV Profile')
              fileNameData.push(transcode);
            else if (t.name == 'Aspect Ratio')
              fileNameData.push(spotFile.aspectRatio);
          }
        });

        return fileNameData.join(this.orderRequest.fileSeperator);
      }
    }
  }

  public getCustomFileName(transcode: string, spotfileGuid: string) {
    var spotFile = this.orderRequest.spotFilesData.find(t => t.spotFileGuid == spotfileGuid);
    if (this.orderRequest.customFileNaming != null) {
      var customNameObj = this.orderRequest.customFileNaming.find(t => t.spotFileGuid == spotfileGuid) != null ? this.orderRequest.customFileNaming.find(t => t.spotFileGuid == spotfileGuid).specFileNames : null;
      var transcodeFileName = customNameObj != null ? customNameObj.find(t => t.spec == transcode) : null;
      return transcodeFileName != null ? transcodeFileName.customSpecFileName : null;
    }
  }

  ngOnDestroy() {

  }
}
