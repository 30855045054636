<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Add Files To Project</h4>
  </div>
  <div class="modal-body">
      <form [formGroup]="addFilesToProjectForm" (submit)="addFilesToProject()" novalidate #f="ngForm">
        <div class="row">
          <div class="col-md-4">
            <div class="tylie-form-group form-group"
              [ngClass]="{error: addFilesToProjectForm.controls['folderId'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="client">Folder</label>
              <dx-select-box name="folderNames" id="folderNames" (change)="onFolderChange($event)" [dataSource]="folders"
                [searchEnabled]="true" class="tylie-select form-control" formControlName="folderId" placeholder="Select a folder..."
                valueExpr="fileCollectionId" displayExpr="collectionName">
              </dx-select-box>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 added-files-container">
            <h4 class="media-upload-text" *ngIf="selectedFiles.length"><b>Added Files</b></h4>
            <div class="added-files" *ngFor="let file of selectedFiles">
              <span class="file-text">{{file.fileName}}</span>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link ms-2" (click)="removeFile(file)"><icon-trash-can color="#1e90ff"></icon-trash-can></button>
            </div>
           

            <label class="tylie-upload">
              <input #project_file type="file" multiple style="display:none" (change)="incomingfile($event)" [disabled]="isUploading">
              <div class="tylie-upload__link tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-eye></icon-eye></span>
                <span class="tylie-button__text">
                  {{isUploading ? 'UPLOADING...' : 'ADD PROJECT FILES'}}
                  <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
                </span>
              </div>
            </label>


            <div class="file-name">
              <p class="error" *ngIf="isMaxFilesExceeded" style="padding: 2px 0px; font-size: 15px;">
                {{maxFilesUploadError}}</p>
              <p class="error" *ngIf="isFileBrowsed" style="padding: 2px 0px; font-size: 15px;">Please select a file</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" [disabled]="isUploading" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <button class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()" type="button">Cancel</button>
        </div>
      </form>
  </div>
</div>
