import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import {
  DxDataGridComponent, DxDataGridModule, DxSelectBoxModule,
  DxSelectBoxComponent, DxDropDownBoxModule,
} from "devextreme-angular";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Utilities } from "../../../../../services/core/utilities";
import { AdminService } from "../../../../../services/admin/admin.service";
import { ConfigService } from "../../../../../services/core/config.service";
import { AlertService } from "../../../../../services/core/alert.service";
import { customPhoneValidator, EmailListValidatorIfValueExists } from "../../../../../configurations/custom-validations";
import { Constants } from "../../../../../configurations/constants";
import { DestinationContact } from '../../../../../models/admin/destination/createdestination.model';
import { contactTypeEnum } from '../../../../../configurations/enums/enums';

@Component({
  selector: 'destination-contact',
  templateUrl: './destination-contact.component.html',
  styleUrls: ['./destination-contact.component.css']
})
export class DestinationContactComponent implements OnInit {
  @Input('destinationContact') destinationContact: FormGroup;
  @Input('parentForm') f: NgForm;
  @Input('states') states: any[] = [];
  @Input('contactTypes') contactTypes: any[] = [];

  public modalRef: BsModalRef;
  public phoneMask = Constants.phoneMask;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private modalService: BsModalService
    , private fb: FormBuilder) {

  }

  ngOnInit() {


  }

  public get destinationContactsFormGroupArray() {
    return <FormArray>this.destinationContact.get('destinationContacts');
  }

  public onDestinationContactDelete(i: number) {
    var destinationContacts = this.destinationContactsFormGroupArray;
    destinationContacts.removeAt(i);
  }

  public onAddAnotherDestinationContact() {
    var destinationContactsArray = <FormArray>this.destinationContact.get('destinationContacts') as FormArray;

    const group = this.fb.group({
      name: [null],
      title: [null],
      phone: [null, Validators.compose([customPhoneValidator])],
      fax: [null],
      email: ['', Validators.compose([EmailListValidatorIfValueExists])],
      contactTypeId: [null, Validators.required],
      isMainContact: [false]
    });

    destinationContactsArray.push(group);
    return destinationContactsArray;
  }

  public onContactTypeValueChanged(e: any, contactIndex: number) {
    if (e.value == null)
      return;

    this.updateEmailValidation(contactIndex);
  }

  private updateEmailValidation(i: number) {
    var destContact = <FormGroup>this.destinationContactsFormGroupArray.at(i);

    if (destContact.controls['contactTypeId'].value == contactTypeEnum.TrafficContact)
      destContact.controls['email'].setValidators(Validators.compose([EmailListValidatorIfValueExists, Validators.required]));
    else {
      destContact.controls['email'].clearValidators();
      destContact.controls['email'].setValidators(Validators.compose([EmailListValidatorIfValueExists]));
    }

    destContact.controls['email'].updateValueAndValidity();
  }
}
