import { Injectable } from '@angular/core';

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DistributionService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }


  public searchDistributionMedia(distributionSearchRequest: any) {
    return this.http.post(this.apiendPoint.distributionSearchEndpoint(), distributionSearchRequest);
  }

  public getBxfFileDownloadUrl(getBxfFileDownloadUrlRequest: any) {
    return this.http.post(this.apiendPoint.getBxfFileDownloadUrlEndpoint(), getBxfFileDownloadUrlRequest);
  }

  public updateDistributionMedia(mediaId, updateMediaRequest:any) {
    return this.http.put(this.apiendPoint.getUpdateDistributionMediaEndpoint() + "/" + mediaId, updateMediaRequest);
  }

  public repitchDistributionMedia(repitchRequest: any) {
    return this.http.post(this.apiendPoint.getrepitchDistributionMediaEndpoint(), repitchRequest);
  }

  public getTMCReleasesUrlEndPoint() {
    return this.http.get(this.apiendPoint.getTMCReleasesUrlEndPoint());
  }

  public getTMCReleaseDownloadUrl(getReleaseDownloadUrlRequest: any) {
    return this.http.post(this.apiendPoint.getTMCReleaseDownloadUrlEndpoint(), getReleaseDownloadUrlRequest);
  }
}
