import { Component, Input, OnInit } from '@angular/core';
import { RouteConstants } from '../../../configurations/route-constants';
import { Utilities } from '../../../services/core/utilities';
import { UserRoles } from '../../../configurations/user-roles';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tagger-header',
  templateUrl: './tagger-header.component.html',
  styleUrls: ['./tagger-header.component.css']
})
export class TaggerHeaderComponent implements OnInit {
  @Input('activeLinks') activeLinks: string[];

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }


  // Function to handle link clicks and update the active link
  setActiveLink(link: string[]) {
    this.activeLinks = link;
  }

  public onClickCreativeTags(link: string[]) {
    this.router.navigate([RouteConstants.creativeTagsRoute]);
    this.setActiveLink(link);
  }

  public onClickGroups(link: string[]) {
    this.router.navigate([RouteConstants.groupsRoute]);
    this.setActiveLink(link);
  }

  public onLocationsAndUsers(link: string[]) {
    this.router.navigate([RouteConstants.locationsAndUsersRoute]);
    this.setActiveLink(link);
  }

  public onTaggerAds(link: string[]) {
    this.router.navigate([RouteConstants.taggerRoute]);
    this.util.scrollTo('scrollContainer')
    this.setActiveLink(link);
  }

  public onTaggerAssets(link: string[]) {
    this.router.navigate([RouteConstants.taggerRoute]);
    this.util.scrollTo('scrollContainer')
    this.setActiveLink(link);
  }


}
