<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['bvsSubmitted'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl">Have you submitted your request to BVS for codes?<span class="required">*</span></label>
    <div class="d-flex">
      <div class="form-check me-3">
        <input class="form-check-input" type="radio" name="bvsSubmitted" formControlName="bvsSubmitted" value="Yes"><span class="form-check-label">Yes</span>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="bvsSubmitted" formControlName="bvsSubmitted" value="No"><span class="form-check-label">No</span>
      </div>
    </div>
    <p class="p-extra-small">Please choose an option</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['bvsForm'].errors && f.submitted)}">
    <label class="tylie-upload" *ngIf="isDisabled === false">
      <input type="file" style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-upload__action--xs">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD BVS FORM'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>
    <p *ngFor="let fl of formGroup.controls['bvsForm'].value;">
      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
      <a *ngIf="isDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
    </p>
    <p class="p-extra-small">Please upload your BVS Form</p>
  </div>
</div>
