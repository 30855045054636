import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { OrderDetailHeader, OrderDetailConfig } from '../../../models/order/order-detail.model';
import { OrderService } from '../../../services/order/order.service';
import { EditOrderHeaderRequest } from '../../../models/order/order-edit-header.model';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment-timezone';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { EmailListValidatorIfValueExists, filePathValidator, transcodeFilePathValidator } from '../../../configurations/custom-validations';
import { OrderAddShareLinksPopupComponent } from '../orderaddsharelinkspopup/orderaddsharelinkspopup.component';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { EditTranscodeOrderRequest } from '../../../models/order/transcodeorder.model';

@Component({
  selector: 'orderdetailheadereditpopup',
  templateUrl: './orderdetailheadereditpopup.component.html',
  styleUrls: ['./orderdetailheadereditpopup.component.css'],
})
export class OrderDetailHeaderEditPopupComponent extends BasePopupComponent {

  public orderGuid: string;
  public editCategory: string;
  public editValue: any;
  public orderGroupId: number;
  public header: OrderDetailHeader;
  public config: OrderDetailConfig;

  public canAddCustom: boolean;
  public displayText: string;
  public selectedValue: any;
  public label: string;
  public controlType: string;
  public error: string;
  public maxLengthError: string;
  public maxCharLimit: number = 15;
  public placeholder: string;
  public itemsDataSource: Array<any>;
  public onEditComplete: Subject<EditOrderHeaderRequest>;
  public editRequest: FormGroup;

  public transcodeOrderGuid: string;
  public transcodeOrderWorkOrder: string;
  public transcodeOrderAssetFileGuid: string;
  public currentTranscodeValue: string;
  public isTranscodeOrder: boolean = false;
  public onTranscodeOrderEditComplete: Subject<EditTranscodeOrderRequest>;

  private modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private orderService: OrderService,
    private modalService: BsModalService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isTranscodeOrder)
      this.transcodeOrderEditInit();
    else
      this.orderEditInit();
  }

  private orderEditInit() {
    this.onEditComplete = new Subject();

    var currentValue: any;
    var isRequired: boolean;
    var isMaxLengthValidator: boolean;
    var isSubmitted: boolean = false;
    var airDateStart: Date = null;
    var airDateEnd: Date = null;
    this.canAddCustom = false;
    this.placeholder = '';

    if (this.editCategory === 'PO') {
      currentValue = this.header.clientPurchaseOrder;
      this.error = 'Please enter PO';
      this.maxLengthError = 'PO can have a maximum of 15 characters.';
      isRequired = true;
      //isRequired = (this.header.isConsolidatedOrder == true || this.config.isPoRequiredForClient == true);
      isMaxLengthValidator = true;
      this.controlType = 'text';
      this.label = "Client Purchase Order";
    }
    else if (this.editCategory === 'ESTIMATE') {
      currentValue = this.header.estimate;
      this.error = 'Please enter Estimate#';
      isRequired = false;
      this.controlType = 'text';
      this.label = "Estimate#";
    }
    else if (this.editCategory === 'JOB') {
      currentValue = this.header.jobNumber;
      this.error = 'Please enter Job#';
      this.maxLengthError = 'Job# can have a maximum of 15 characters.';
      isRequired = false;
      isMaxLengthValidator = true;
      this.controlType = 'text';
      this.label = "Job#";
    }
    else if (this.editCategory === 'ORDEREDBY') {
      currentValue = this.header.orderedBy;
      this.error = 'Please select Ordered By or Add New';
      isRequired = true;
      this.controlType = 'dropdown';
      this.itemsDataSource = this.config.orderedBy;
      this.label = "Ordered By";
      this.canAddCustom = true;
    }
    else if (this.editCategory === 'COMMENT') {
      currentValue = this.header.orderComments.length > 0 ? this.header.orderComments[0].comments : '';
      this.error = 'Please enter Comment';
      isRequired = true;
      this.controlType = 'textarea';
      this.label = "Comment";
    }
    else if (this.editCategory === 'CONSOLIDATEDORDER') {
      currentValue = this.header.isConsolidatedOrder;
      this.error = 'Please select Consolidated Order';
      isRequired = false;
      this.controlType = 'checkbox';
      this.label = "Consolidated Order";
    }
    else if (this.editCategory === 'PROBONO') {
      currentValue = this.header.isProBono;
      this.error = 'Please select Pro bono';
      isRequired = false;
      this.controlType = 'checkbox';
      this.label = "Pro Bono";
    }
    else if (this.editCategory === 'AIRDATE') {
      currentValue = '';
      airDateStart = this.header.airDateStart;
      airDateEnd = this.header.airDateEnd;
      this.error = 'Please select valid dates';
      isRequired = false;
      this.controlType = 'airdate';
      this.label = '';
    }
    else if (this.editCategory === 'COSTCENTER') {
      currentValue = this.header.costCenter;
      this.error = 'Please select a Cost Center';
      this.maxLengthError = 'Cost Center can have a maximum of 35 characters.';
      isRequired = false;
      isMaxLengthValidator = true;
      this.maxCharLimit = 35;
      this.controlType = 'dropdown';
      this.itemsDataSource = this.config.costCenter;
      this.label = "Cost Center";
      this.canAddCustom = true;
    }
    else if (this.editCategory === 'INVOICETO') {
      currentValue = this.header.inVoiceTo;
      this.error = 'Please select Bill To';
      isRequired = this.config.inVoiceTo.length > 0;
      this.controlType = 'dropdown';
      this.itemsDataSource = this.config.inVoiceTo;
      this.label = "Bill To";
    }
    else if (this.editCategory === 'REFERENCE/KEYWORDS') {
      currentValue = this.header.refKeywords;
      this.error = 'Please enter Reference/Keywords';
      isRequired = false;
      this.controlType = 'text';
      this.label = "Reference/Keywords";
    }
    else if (this.editCategory === 'HUBDESTINATIONS') {
      currentValue = this.header.isDestinationsHubbed;
      this.error = 'Please select Hub Destinations';
      isRequired = false;
      this.controlType = 'checkbox';
      this.label = "Hub Destinations";
    }
    else if (this.editCategory === 'FINALREVIEWREQUIRED') {
      currentValue = this.header.isFinalReviewRequired;
      this.error = 'Please select Final Review Required';
      isRequired = false;
      this.controlType = 'checkbox';
      this.label = "Final Review Required";
    }
    else if (this.editCategory === 'SERVICELEVEL') {
      currentValue = this.editValue.toString();
      this.error = 'Please select an option';
      isRequired = false;
      this.controlType = 'servicelevel';
      this.label = "Service Level";
    }
    else if (this.editCategory === 'ORIGINALWORKORDER') {
      currentValue = this.header.originalWorkOrder;
      this.error = 'Please enter Original Order#';
      this.placeholder = 'Enter Original Order#';
      isRequired = false;
      this.controlType = 'text';
      this.label = "Non-Billable Order";
    }
    else if (this.editCategory === 'PODEMAILS') {
      currentValue = this.header.podEmails;
      this.error = 'Please enter valid Emails seperated by semi-colon';
      isRequired = false;
      this.controlType = 'podemails';
      this.label = "POD Email Recipients";
    }
    else if (this.editCategory === 'SPECIALVISIBILITYCLIENT') {
      this.itemsDataSource = this.config.specialVisibilityClients;
      currentValue = this.header.specialVisibilityClient;
      this.error = 'Please select a Special Visibility Client';
      isRequired = false;
      this.controlType = 'dropdown';
      this.label = "Special Visibility Client";
      this.displayText = 'name';
      this.selectedValue = 'id';
    }
    else if (this.editCategory === 'BILLINGONLYORDER') {
      currentValue = this.header.isPostDatedOrder;
      this.error = 'Please select Billng Only Order';
      isRequired = false;
      this.controlType = 'checkbox';
      this.label = "Billing Only Order";
    }

    this.editRequest = new FormGroup({
      newValue: (isRequired && this.controlType != 'airdate') ? new FormControl(currentValue, Validators.required) : new FormControl(currentValue),
      airDateStart: new FormControl(airDateStart),
      airDateEnd: new FormControl(airDateEnd),
      editCategory: new FormControl(this.editCategory),
      orderGroupId: new FormControl(this.orderGroupId)
    });

    if (this.editCategory === 'PODEMAILS') {
      this.editRequest.controls["newValue"].setValidators([EmailListValidatorIfValueExists]);

      this.editRequest.updateValueAndValidity();
    }

    if (isMaxLengthValidator) {
      if (isRequired) {
        this.editRequest.controls["newValue"].setValidators([Validators.required, Validators.maxLength(this.maxCharLimit)]);
      }
      else {
        this.editRequest.controls["newValue"].setValidators([Validators.maxLength(this.maxCharLimit)]);
      }
      this.editRequest.updateValueAndValidity();
    }

    if (this.controlType == 'airdate') {
      this.editRequest.setValidators([
        this.airdateValidator(this.editRequest.get('airDateStart'), this.editRequest.get('airDateEnd'))
      ]);

      this.editRequest.updateValueAndValidity();
    }
  }

  private transcodeOrderEditInit() {

    this.onTranscodeOrderEditComplete = new Subject();

    var currentValue: any;
    var isRequired: boolean;
    this.canAddCustom = false;
    this.placeholder = '';

    if (this.editCategory === 'REFERENCECODE') {
      currentValue = this.currentTranscodeValue;
      this.error = 'Please enter Reference Code';
      isRequired = true;
      this.controlType = 'text';
      this.label = "Reference Code";
    }
    else if (this.editCategory === 'FILENAME') {
      currentValue = this.currentTranscodeValue;
      this.error = 'Do not use special characters, including /!?$%#^@* etc. Underscores, hyphens and spaces are acceptable.';
      isRequired = true;
      this.controlType = 'text';
      this.label = "Filename";
    }

    this.editRequest = new FormGroup({
      newValue: (isRequired) ? new FormControl(currentValue, Validators.required) : new FormControl(currentValue),
      editCategory: new FormControl(this.editCategory),
      orderGuid: new FormControl(this.transcodeOrderGuid),
      workOrder: new FormControl(this.transcodeOrderWorkOrder),
      assetFileGuid: new FormControl(this.transcodeOrderAssetFileGuid)
    });

    if (this.editCategory === 'FILENAME') {
      this.editRequest.controls["newValue"].setValidators([transcodeFilePathValidator]);

      this.editRequest.updateValueAndValidity();
    }
  }

  public onAddMorePODEmails() {
    var initialState = {
      podMode: true
    };

    this.modalRef = this.modalService.show(OrderAddShareLinksPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w60"));

    this.modalRef.content.onClose.subscribe(result => {
      this.onAddPODEmailsSubscriptionRaised(result);
    });
  }

  private onAddPODEmailsSubscriptionRaised(allDestinationsSelected: SelectedOrderDestinations) {

    if (allDestinationsSelected === null)
      return;

    var selectedUserDistributionLists = allDestinationsSelected.shareLinks;

    if (selectedUserDistributionLists && selectedUserDistributionLists != null && selectedUserDistributionLists.length > 0) {
      var existingPODEmails: string = this.editRequest.controls['newValue'].value;
      var newEmails: Array<string> = [];

      if (existingPODEmails && existingPODEmails != null && existingPODEmails.trim() != '') {

        existingPODEmails = existingPODEmails.trim();

        if (existingPODEmails.endsWith(';') || existingPODEmails.endsWith(','))
          existingPODEmails = existingPODEmails.slice(0, -1);

        newEmails.push(existingPODEmails);
      }

      selectedUserDistributionLists.forEach(item => {
        item.groupEmails.forEach(em => {
          newEmails.push(em);
        });
      });

      this.editRequest.controls['newValue'].setValue(newEmails.join(';'));

      //if (!existingPODEmails || existingPODEmails == null || existingPODEmails == '')
      //  this.editRequest.controls['newValue'].setValue(newEmails.join(';'));
      //else if (existingPODEmails.endsWith(';') || existingPODEmails.endsWith(','))
      //  this.editRequest.controls['newValue'].setValue(existingPODEmails + newEmails.join(';'));
      //else
      //  this.editRequest.controls['newValue'].setValue(existingPODEmails + ';' + newEmails.join(';'));
    }
  }

  private airdateValidator(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (controls[0].value != null
        && controls[1].value != null
        && Date.parse(controls[0].value) > Date.parse(controls[1].value))
        return { airdatesInvalid: true };

      return null;
    };
  }

  public onSave({ value, valid }: { value: any, valid: boolean }) {
    console.log(value);

    if (this.isTranscodeOrder)
      this.editTranscodeOrder(value);
    else
      this.editOrder(value);
  }

  private editOrder(value: EditOrderHeaderRequest) {
    if (this.editRequest.controls['newValue'].errors || this.editRequest.hasError('airdatesInvalid') || this.editRequest.controls['newValue'].hasError('inValidEmailList'))
      return;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    console.log(this.orderGuid);
    console.log(value);

    this.orderService.editOrderHeader(this.orderGuid, value).subscribe((res: any) => {

      if (res.isSuccess == true) {

        value.orderActions = res.result.orderActions;

        if (this.editCategory === 'SPECIALVISIBILITYCLIENT') {
          var currentValue = value.newValue;
          value.newValue = this.config.specialVisibilityClients.filter(x => x.id == currentValue);
        }

        if (this.controlType == 'servicelevel')
          this.alertService.showMessage("SUCCESS", Constants.orderGpServiceLevelUpdatedSuccessfully, MessageSeverity.success);
        else
          this.alertService.showMessage("SUCCESS", Constants.orderheaderUpdatedSuccessfully, MessageSeverity.success);

        this.onEditComplete.next(value);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.closePopup();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.closePopup();
      });
  }

  private editTranscodeOrder(value: EditTranscodeOrderRequest) {

    if (this.editCategory == 'REFERENCECODE') {
      this.editRequest.controls['newValue'].setValue(this.editRequest.controls['newValue'].value.trim());
    }

    if (this.editRequest.controls['newValue'].errors || this.editRequest.controls['newValue'].hasError('inValidFilePath'))
      return;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.editTranscodeOrder(value).subscribe((res: any) => {

      if (res.isSuccess == true) {

        if (this.editCategory == 'REFERENCECODE')
          this.alertService.showMessage("SUCCESS", Constants.transcodeorder_refCode_Updated, MessageSeverity.success);
        else if (this.editCategory == 'FILENAME')
          this.alertService.showMessage("SUCCESS", Constants.transcodeorder_fileName_Updated, MessageSeverity.success);

        this.onTranscodeOrderEditComplete.next(value);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.closePopup();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
