import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { NewProject, NewProjectResponse, SaveProjectResponse, AddProjFilesResponse, UpdateProject, UpdateProjectFolder } from '../../models/project/create-project.model';
import { ProjectSearchResult } from '../../models/project/project.model';
import { MoveFileBetweenFolders } from '../../models/project/project-actions.model';

@Injectable()

export class ProjectService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  getProjectClients(): Observable<NewProjectResponse> {
    return this.http.get<NewProjectResponse>(this.apiendPoint.getProjectClients());
  }

  saveProject(project: NewProject): Observable<SaveProjectResponse> {
    return this.http.post<SaveProjectResponse>(this.apiendPoint.saveProject(), project);
  }

  loadProjFolders(projectId: number): Observable<AddProjFilesResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId': projectId.toString()
    });
    let options = { headers: headers };
    return this.http.get<AddProjFilesResponse>(`${this.apiendPoint.loadProjFolders()}?projectId=${projectId}`, options);
  }

  loadProjects(request: any): Observable<ProjectSearchResult> {
    return this.http.post<ProjectSearchResult>(`${this.apiendPoint.loadProjects()}`, request);
  }

  deleteFileCollection(fileCollectionId: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('fileCollectionId', fileCollectionId.toString());
    return this.http.put<any>(`${this.apiendPoint.deleteProject()}`, {}, { params });
  }

  updateProject(updateProject: UpdateProject): Observable<any> {
    return this.http.put<any>(this.apiendPoint.updateProject(), updateProject);
  }

  updateProjectFolder(updateProjectFolder: UpdateProjectFolder): Observable<any> {
    let headers = new HttpHeaders({
      'fileCollectionId': [updateProjectFolder.fileCollectionId.toString(), updateProjectFolder.projectId.toString() ]
    });

    let options = { headers: headers };

    return this.http.post<any>(this.apiendPoint.updateProjectFolder(), updateProjectFolder, options);
  }

  moveFileCollection(moveFileCollectionReq: MoveFileBetweenFolders): Observable<any> {
    let headers = new HttpHeaders({
      'fileCollectionId': [moveFileCollectionReq.destinationFileCollectionId.toString(), moveFileCollectionReq.fileCollectionId.toString(), moveFileCollectionReq.projectId.toString()]
    });

    let options = { headers: headers };

    return this.http.post<any>(this.apiendPoint.moveFileCollection(), moveFileCollectionReq, options);
  }

  downloadFile(fileCollectionGuid: string, fileCollectionIds: string[]): Observable<string> {
    let headers = new HttpHeaders({
      'fileCollectionId': fileCollectionIds
    });
    let params = new HttpParams();
    params = params.set('fileCollectionGuid', fileCollectionGuid);

    let options = { headers: headers, params: params };
    return this.http.get<string>(`${this.apiendPoint.downloadProjectFile()}`, options);
  }
}
