<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.25 124.54" [attr.height]="height" [attr.width]="width">
    
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path [attr.fill]="color"
              d="M51.12,124.54a14.19,14.19,0,0,0,13.92-12H37.21A14.17,14.17,0,0,0,51.12,124.54Z" />
        <circle [attr.fill]="color" cx="85.54" cy="41.78" r="22.7" />
        <polygon [attr.fill]="color" points="0 106.96 50.83 106.96 51.41 106.96 102.24 106.96 102.24 93.78 0 93.78 0 106.96" />
        <path [attr.fill]="color" d="M14.65,52.18c0,19.77-3.22,30.17-7,35.74h87c-2.49-3.52-4.69-9.08-5.86-17.73a24.39,24.39,0,0,1-3.23.15A28.54,28.54,0,0,1,68.85,18.63a33.41,33.41,0,0,0-4.69-2.19A5.31,5.31,0,0,1,60.94,11a9.28,9.28,0,0,0-.15-2.79,10.18,10.18,0,0,0-7.91-8,10,10,0,0,0-12,9.81v1a5.14,5.14,0,0,1-3.22,5.42C27.54,20.54,14.65,30.06,14.65,52.18Z" />
      </g>
    </g>
  </svg>