<header class="tylie-header">
  <h3 class="tylie-header__title">Upload</h3>
  <nav  class="tylie-header__nav" id="bs-example-navbar-collapse-1">
    <ul class="tylie-header__nav-lst">
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaProviderRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoUpload()">Upload Assets</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaDownloadRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoDownload()">Download Status</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaApproverRoleName()]"><a class="tylie-header__nav-lst-lnk"  (click)="onGotoMedia('Approval')">Approvals</a></li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaQCRoleName()]"><a (click)="onGotoMedia('QC')">Asset QC</a></li>
      <li class="tylie-header__nav-lst-itm"><a (click)="onGotoMedia('PlayList')"class="tylie-header__nav-lst-lnk" >Playlists</a></li>
      <li class="tylie-header__nav-lst-itm"><a (click)="onGotoMedia('Search')"class="tylie-header__nav-lst-lnk" >View/Search All Spots</a></li>
      <li class="tylie-header__nav-lst-itm"><a (click)="onGotoMedia('RecentlyViewed')" class="tylie-header__nav-lst-lnk" >Recently Viewed</a></li>
      <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getMediaTranscoderRoleName()]" (click)="gotoTranscodeFiles()">Transcode Files</a></li>
      <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk active" >Talent Rights</a></li>
    </ul>
  </nav>
</header>

<div class="tylie-body">
  <div class="tylie-body__top-bar">
    <h4 class="tylie-body__title">Talent Rights Management</h4>
  </div>

  <div class="tylie-accordion accordion" id="rights-management-section">
    <div class="tylie-accordion__panel accordion-item" id="rightsMgmtPanel">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#rightsMgmtCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Assets With Talent Rights
        </button>
      </h2>

      <div id="rightsMgmtCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body">
          
          <div class="tylie-accordion__body-bar" id="advance-search1">
            <!--<div class="advanced-search-caption p-large">Advanced Search</div>-->
            <form novalidate (ngSubmit)="onAdvancedSearch()" [formGroup]="searchRequest" role="search">
              <div class="row">
                <div class="col-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="adId">Ad-ID/BaseSpot</label>
                    <input type="text" name="adId" class="tylie-text form-control" id="adId" autocomplete="off" formControlName="adId">
                  </div>
                </div>
                <div class="col-3">
                  <div class="tylie-form-group form-group" *ngIf="this.talentRightsConfig">
                    <label class="tylie-form-group__lbl" for="elementType">Talent Rights Status</label>
                    <dx-select-box [dataSource]="this.talentRightsConfig.statuses"
                                   name="status"
                                   id="status"
                                   class="tylie-select form-control"
                                   formControlName="status"
                                   [showClearButton]="true"
                                   valueExpr="id"
                                   displayExpr="name"
                                   placeholder="Select a Status...">
                    </dx-select-box>
                  </div>
                 
                </div>
                <div class="col-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="elementName">Element Name</label>
                    <input type="text" name="elementName" id="elementName" class="tylie-text form-control"
                           autocomplete="off" formControlName="elementName">
                  </div>
                </div>
                <div class="col-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="elementType">Element Type</label>
                    <input type="text" name="elementType" class="tylie-text form-control" id="elementType"
                           autocomplete="off" formControlName="elementType">
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-start">
                <div class="tylie-form-group form-check me-5">
                  <input type="checkbox" formControlName="expiredOnly" name="expiredOnly" id="expiredOnly" class="form-check-input">
                  <label for="expiredOnly" class="form-check-label">Expired Only</label>
                </div>

                <div class="tylie-form-group form-check">
                  <input type="checkbox" formControlName="activeOnly" name="activeOnly" id="activeOnly" class="form-check-input">
                  <label for="activeOnly" class="form-check-label">Active Only</label>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                  <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>&nbsp;
                  <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="clearSearch()">Clear Search</button>
              </div>
            </form>
          </div>
        </div>

        <div class="tylie-accordion__body-content">
          <dx-data-grid id="rightsMgmtSearch" #rightsMgmtSearch [dataSource]="resultRightsMgmtStore"
                        [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true"
                        [showColumnLines]="true" [showRowLines]="true" [hoverStateEnabled]="true" keyExpr="projectid"
                        [rowAlternationEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
                        [masterDetail]="{ enabled: true, template: 'filesTemplate', autoExpandAll: false }">
            <dxo-remote-operations [sorting]="true" [paging]="true">
            </dxo-remote-operations>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" caption="Thumbnail" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
            <dxi-column [allowSorting]="false" dataField="projectName" caption="Project Name"
                        width="20%" cssClass="gridStyle"></dxi-column>
            <dxi-column [allowSorting]="false" dataField="adId" cellTemplate="adIDTemplate" caption="AD-ID" width="20%"
                        cssClass="gridStyle"></dxi-column>
            <dxi-column [allowSorting]="false" dataField="client" caption="Client" width="20%"
                        cssClass="gridStyle tyl-text-align-left"></dxi-column>
            <dxi-column [allowSorting]="false" dataField="brand" caption="Brand" cssClass="gridStyle"
                        width="20%"></dxi-column>
            <dxi-column [allowSorting]="false" width="20%" dataField="talentRightsStatus" caption="Talent Rights Status" cellTemplate="statusTemplate" cssClass="gridStyle">
            </dxi-column>

            <div *dxTemplate="let t of 'statusTemplate'">
              <div style="text-align:center;" *ngIf="t.data.isTermsNotSet" class="tyl-talentrights-grey-status"><Span class="tyl-vertical-align tyl-center">{{ t.data.talentRightsStatus}}</Span></div>
              <div style="text-align:center;" *ngIf="t.data.isCurrent" class="tyl-talentrights-green-status"><Span class="tyl-vertical-align tyl-center">{{ t.data.talentRightsStatus}}</Span></div>
              <div style="text-align:center;" *ngIf="t.data.isExpired" class="tyl-talentrights-red-status">{{ t.data.talentRightsStatus}}</div>
              <div style="text-align:center;" *ngIf="t.data.isExpiringSoon" class="tyl-talentrights-orange-status">{{ t.data.talentRightsStatus}}</div>
            </div>

            <div *dxTemplate="let t of 'thumbnailTemplate'" id="search-media">
              <div class="tylie-img-container tylie-img-container--thumbnail">
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
              </div>
            </div>

            <div *dxTemplate="let t of 'adIDTemplate'">
              <span *ngIf="t.data.isBaseSpotActiveInTandem === true" class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
              <span *ngIf="t.data.isBaseSpotActiveInTandem === false">{{t.data.adId}}</span>
            </div>

            <div *dxTemplate="let t of 'filesTemplate'" class="detail-grid-margin-left">
              <dx-data-grid *ngIf="t.data.rmDetails.length" [showBorders]="false" [columnAutoWidth]="true"
                            [showColumnLines]="false" keyExpr="termsofuseid" [hoverStateEnabled]="true"
                            [showRowLines]="true" [dataSource]="t.data.rmDetails">
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxi-column dataField="elementName" caption="Element Name" [allowSorting]="false"
                            width="30%" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="elementTypeDesc" caption="Element Type Desc" [allowSorting]="false"
                            width="30%" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="startDate" width="20%" caption="Start Date" [allowSorting]="false"
                            cellTemplate="dateTemplate" cssClass="gridStyle1">
                </dxi-column>
                <dxi-column dataField="endDate" width="20%" caption="End Date" [allowSorting]="false"
                            cellTemplate="dateTemplate" cssClass="gridStyle1">
                </dxi-column>
                <dxi-column dataField="mediaType" caption="Media Type" [allowSorting]="false" width="20%"
                            cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="territory" caption="Territory" [allowSorting]="false" width="20%"
                            cssClass="gridStyle1"></dxi-column>
                <!--<dxi-column dataField="renewalIndicator" caption="RenewalIndicator" [allowSorting]="false"
                            width="15%" cssClass="gridStyle1"></dxi-column>-->
                <dxi-column dataField="status" caption="Status" [allowSorting]="false" width="15%"
                            cssClass="gridStyle1"></dxi-column>

                <div *dxTemplate="let data of 'dateTemplate'">
                  <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY h:mm A'}}</div>
                </div>

              </dx-data-grid>
              <div class="panel-footer panel-footer-master-child-row"></div>
            </div>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"
                       infoText="Page {0} of {1} ({2} items)" [showNavigationButtons]="true" [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
