import { Component, Input, OnInit } from '@angular/core';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { ShareTaggerAdRequest, TaggerAd } from '../../../models/tagger/tagger.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { UserDataService } from '../../../services/user/user-data.service';
import { Utilities } from '../../../services/core/utilities';
import { ShareMediaService } from '../../../services/playlist/sharemedia.service';
import { SubService } from '../../../services/media/sub.service';
import { EmailListValidatorTagBox } from '../../../configurations/custom-validations';
import { Constants } from '../../../configurations/constants';
import { ShareTranscodeAssetRequest } from '../../../models/sharemedia/sharetranscodeasset.model';

@Component({
  selector: 'app-share-tagger-ad-popup',
  templateUrl: './share-tagger-ad-popup.component.html',
  styleUrls: ['./share-tagger-ad-popup.component.css']
})
export class ShareTaggerAdPopupComponent extends BasePopupComponent {
  @Input() taggerAds: Array<TaggerAd> = [];

  public isGenerateLinkAction: boolean = false;
  public showPasswordError: boolean = false;
  public showLimitViewsError: boolean = false;
  public emailSuggestions: Array<string> = [];
  public adShareGuid: string = null;

  shareMediaForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private userService: UserDataService,
    public util: Utilities,
    private shareMediaService: ShareMediaService,
    private subService: SubService) {

    super();

    this.shareMediaForm = new FormGroup({
      toemail: new FormControl('', [EmailListValidatorTagBox]),
      subject: new FormControl('', Validators.required),
      linkexpire: new FormControl('', Validators.required),
      comments: new FormControl(''),
      password: new FormControl(''),
      optcopy: new FormControl(),
      optviews: new FormControl(),
      optpassword: new FormControl(),
      limitviews: new FormControl(),
      sharelink: new FormControl('')
    });

    this.searchUserEmails();
  }

  ngOnInit() {
    super.ngOnInit();

    this.shareMediaForm.controls['optcopy'].setValue(false);
    this.shareMediaForm.controls['optviews'].setValue(false);
    this.shareMediaForm.controls['optpassword'].setValue(false);
    this.shareMediaForm.controls['limitviews'].setValue(1);
  }

  public onDelete(adGuid: string) {
    this.taggerAds = this.taggerAds.filter(t => t.adGuid != adGuid);
  }

  public autoGeneratePassword() {
    this.shareMediaService.autoGeneratePassword().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.shareMediaForm.controls['password'].setValue(res.result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public copyLink() {
    var urlElement = document.getElementById("sharelink");
    if (urlElement != null || urlElement != undefined) {
      (<HTMLInputElement>urlElement).select();
      document.execCommand("copy");
    }
  }

  public onKeyEvent(e) {
    //comma, semi-colon, space
    if (e.event.keyCode === 186 || e.event.keyCode === 59 || e.event.keyCode === 32 || e.event.keyCode === 188 || e.event.keyCode === 44) {
      var ev = jQuery.Event("keydown");
      ev.which = 13;
      e.component.field().trigger(ev)
      e.jQueryEvent.preventDefault();
    }
  }

  private addFreeTextValue(e) {
    var values = e.component.option('value');
    var inputValue = e.element.find('input').val();

    if (inputValue && inputValue.length > 0) {

      if (!values)
        values = [];

      values.push(inputValue);

      e.component.option('value', values);
    }
  }

  public onFocusOut(e) {
    this.addFreeTextValue(e);
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  private searchUserEmails() {

    let request: any = { quickSearch: '' };

    this.userService.searchUserFavoriteEmails(request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var emailList = res.result as Array<any>;
        var onlyEmails: Array<string> = [];

        emailList.forEach(ind => {
          onlyEmails.push(ind.email);
        });

        this.emailSuggestions = onlyEmails;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public generateShareLink(shareMediaObj: any) {

    if (this.taggerAds.length == 0) {
      this.alertService.showMessage("ERROR", Constants.tagger_ad_share, MessageSeverity.error);
      return;
    }

    this.isGenerateLinkAction = true;
    this.showPasswordError = true;
    this.showLimitViewsError = true;

    if (this.shareMediaForm.controls['linkexpire'].invalid) {
      return;
    }

    if ((this.shareMediaForm.value.optpassword == true && this.shareMediaForm.value.password == '') ||
      (this.shareMediaForm.value.optviews == true && this.shareMediaForm.value.limitviews == null)) {
      return;
    }

    let shareRequest = new ShareTaggerAdRequest();
    shareRequest.adShareGuid = this.adShareGuid;
    shareRequest.taggerAds = this.taggerAds.map(a => a.adGuid);
    shareRequest.subject = "";
    shareRequest.comments = "";
    shareRequest.password = this.shareMediaForm.value.optpassword == true ? this.shareMediaForm.value.password : null;
    shareRequest.isPasswordRequired = this.shareMediaForm.value.optpassword;
    shareRequest.isUserCopied = this.shareMediaForm.value.optcopy;
    shareRequest.expiryInDays = this.shareMediaForm.value.linkexpire;
    shareRequest.limitViews = this.shareMediaForm.value.optviews == true ? this.shareMediaForm.value.limitviews : Constants.maxLimit;
    shareRequest.emailTo = [];

    this.shareMediaService.getTaggerAdsShareLink(shareRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.shareMediaForm.controls['sharelink'].setValue(res.result.link);
        this.adShareGuid = res.result.adShareGuid;
      }
      else { this.util.handleIsNotSuccess(res.errors); }
    }, err => {
      if (this.util.handleError(err))
        this.closePopup();
    });
  }

  public shareMedia(shareMediaObj: any) {
    if (this.taggerAds.length == 0) {
      this.alertService.showMessage("ERROR", Constants.tagger_ad_share, MessageSeverity.error);
      return;
    }
    this.isGenerateLinkAction = false;

    if (this.shareMediaForm.invalid || (this.shareMediaForm.value.optpassword == true && this.shareMediaForm.value.password == '') ||
      (this.shareMediaForm.value.optviews == true && this.shareMediaForm.value.limitviews == null)) {
      return;
    }

    let shareRequest = new ShareTaggerAdRequest();
    shareRequest.adShareGuid = this.adShareGuid;
    shareRequest.taggerAds = this.taggerAds.map(a => a.adGuid);
    shareRequest.subject = this.shareMediaForm.value.subject;
    shareRequest.comments = this.shareMediaForm.value.comments;
    shareRequest.password = this.shareMediaForm.value.optpassword == true ? this.shareMediaForm.value.password : null;
    shareRequest.isPasswordRequired = this.shareMediaForm.value.optpassword;
    shareRequest.isUserCopied = this.shareMediaForm.value.optcopy;
    shareRequest.expiryInDays = this.shareMediaForm.value.linkexpire;
    shareRequest.limitViews = this.shareMediaForm.value.optviews == true ? this.shareMediaForm.value.limitviews : Constants.maxLimit;
    shareRequest.emailTo = this.shareMediaForm.value.toemail as Array<string>;

    this.alertService.ShowLoader(true);

    this.shareMediaService.shareTaggerAds(shareRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.tagger_ad_shared, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
      this.closeModal();
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
        this.alertService.ShowLoader(false);
      });
  }

}
