<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#1e90ff"
    viewBox="0 0 206.31 206.31"
    [attr.height]="height" [attr.width]="width"
>
    <defs>
        <style>
            .cls-1 {
                fill: #fff;
            }
        </style>
    </defs>
    
    <g
        id="Layer_2"
        data-name="Layer 2"
    >
        <g
            id="Layer_1-2"
            data-name="Layer 1"
        >
            <path
                [attr.fill]="color"
                class="cls-1"
                d="M103.15,0A103.16,103.16,0,1,0,206.31,103.15,103.15,103.15,0,0,0,103.15,0Zm39.39,72.21a2.91,2.91,0,0,1-.12.39,2.29,2.29,0,0,1-.1.23l0,0-.07.16-16.81,42-17,42.47a2.25,2.25,0,0,1-1.6,1.35,2.22,2.22,0,0,1-2.64-1.7L94.08,113,49.72,99.38a2.22,2.22,0,0,1,.05-4.28l45-12.74L139.4,69.74l.09-.06h0a1.73,1.73,0,0,1,.31-.07l.1,0a.35.35,0,0,1,.14,0l.06,0h.15l.1,0h.11l.22,0h.11a2.19,2.19,0,0,1,.34.1l.07,0a.57.57,0,0,1,.13.08l.07,0,.13.07a2.24,2.24,0,0,1,1,2.31Z"
            />
        </g>
    </g>
</svg>
