<div id="share-links">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <!--<h4 class="modal-title">{{addMoreEmailsMode == true ? 'ADD NEW SHARE LINK' : 'APPLY TO ORDER - SHARE LINKS'}}</h4>-->

    <h4 class="modal-title" *ngIf="addMoreEmailsMode == true && podMode == false">ADD NEW SHARE LINK</h4>
    <h4 class="modal-title" *ngIf="addMoreEmailsMode == false && podMode == false">APPLY TO ORDER - SHARE LINKS</h4>
    <h4 class="modal-title" *ngIf="addMoreEmailsMode == true && podMode == true">ADD NEW DISTRIBUTION LIST</h4>
    <h4 class="modal-title" *ngIf="addMoreEmailsMode == false && podMode == true">POD RECIPIENTS (VIA EMAIL)</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <section class="tylie-input-search" *ngIf="addMoreEmailsMode == false">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Name" (keyup.enter)="onQuickSearchDestinations()"
                  name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
          </div>
        </section>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end" >
          <button class="tylie-button tylie-button--xs" type="button" (click)="onShowList()" *ngIf="savedListMode == true && addMoreEmailsMode == false && allowAllSharedLinks == true">SHARE LINKS</button>
        
          <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" (click)="onAddMoreEmails()" *ngIf="savedListMode == true && addMoreEmailsMode == false">
            <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
            <span class="tylie-button__icon">ADD NEW</span>
          </button>
          <button class="tylie-button tylie-button--xs ms-3" type="button" (click)="onShowSavedList()"  *ngIf="savedListMode == false">SAVED SHARE LINKS</button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="addMoreEmailsMode == true">
      <form [formGroup]="distributionGroupRequest" #f="ngForm" (ngSubmit)="onFormSubmit(distributionGroupRequest)">
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionGroupRequest.controls['newDistributionListName'].errors && distributionGroupRequest.controls['id'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="dislist">Update to Existing Distribution List</label>
              <dx-select-box [dataSource]="distributionSavedGroupsDataSource"
                              valueExpr="groupGuid"
                              displayExpr="groupName"
                              formControlName="id"
                              name="dislist"
                              class="tylie-select form-control"
                              placeholder="Select a Distribution List">
              </dx-select-box>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionGroupRequest.controls['newDistributionListName'].errors && distributionGroupRequest.controls['id'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="newdislist">Add to New Distribution List</label>
              <input name="newdislist" type="text" placeholder="Name Your List" class="tylie-text form-control" formControlName="newDistributionListName">
              <p class="p-extra-small">Please enter an name for your List or choose an existing one</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionGroupRequest.controls['email'].hasError('inValidEmailList') && f.submitted}">
              <label class="tylie-form-group__lbl" for="email">Emails<span class="gray1 normal"></span><span class="required">*</span>&nbsp;(semi-colon separated)</label>
              <textarea name="email" formControlName="email" class="tylie-textarea tylie-textarea--lg form-control"></textarea>
              <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="tylie-button tylie-button--xs" type="submit">Save Link</button>
            <button class="tylie-button tylie-button--xs tylie-button--link ms-3" type="button" (click)="onCancelAddMore()">Cancel</button>
        </div> 
      </form>
    </ng-container>

    <ng-container *ngIf="savedListMode == true && addMoreEmailsMode == false">
      <div class="tylie-grid-wrapper">
        <dx-data-grid id="distributionSavedGroupsGrid" class="tylie-grid tylie-grid--bordered"
                      #distributionSavedGroupsGrid
                      [dataSource]="distributionSavedGroupsDataSource"
                      [allowColumnReordering]="false"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [showColumnLines]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      keyExpr="groupGuid">
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="groupName" width="20%" cssClass="gridStyle" caption="Distribution List"></dxi-column>
          <dxi-column dataField="groupEmailsDisplay" width="65%" cssClass="gridStyle" caption="Email(s)"></dxi-column>
          <dxi-column dataField="isPasswordProtected" width="15%" cssClass="gridStyle" caption="Pass. Protected" cellTemplate="passwordTemplate" [visible]="!podMode"></dxi-column>
          <dxi-column dataField="groupName" width="15%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate"></dxi-column>
          <div *dxTemplate="let t of 'actionsTemplate'">
            <div class="d-flex justify-content-start">
              <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDistrubtionGroupDelete(t.data.groupGuid)">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </a>
              <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDistrubtionGroupEdit(t.data.groupGuid)">
                <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
              </a>
            </div>
          </div>
          <div *dxTemplate="let t of 'passwordTemplate'">
            <div style="text-align:center;">
              <dx-check-box [(ngModel)]="t.data.isPasswordProtected"></dx-check-box>
            </div>
          </div>
          <dxo-paging [pageSize]="5"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[5, 10, 15]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </ng-container>

    <ng-container *ngIf="savedListMode == false && addMoreEmailsMode == false">
      <div class="tylie-grid-wrapper">
        <dx-data-grid id="distributionGroupsGrid" class="tylie-grid tylie-grid--bordered"
                      #distributionGroupsGrid
                      [dataSource]="distributionGroupsDataSource"
                      [allowColumnReordering]="false"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [showColumnLines]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      keyExpr="groupGuid">
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="groupName" width="25%" cssClass="gridStyle" caption="Distribution List"></dxi-column>
          <dxi-column dataField="groupEmailsDisplay" width="70%" cssClass="gridStyle" caption="Email(s)"></dxi-column>
          <dxi-column dataField="isPasswordProtected" width="20%" cssClass="gridStyle" caption="Pass. Protected" cellTemplate="passwordTemplate"></dxi-column>
          <div *dxTemplate="let t of 'passwordTemplate'">
            <div style="text-align:center;">
              <dx-check-box [(ngModel)]="t.data.isPasswordProtected"></dx-check-box>
            </div>
          </div>
          <dxo-paging [pageSize]="5"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[5, 10, 15]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </ng-container>

    <div class="d-flex justify-content-end align-items-center" *ngIf="addMoreEmailsMode == false && podMode == false">
      <p class="tylie-type-body">* Add Additional Destinations from Order Build</p>
      <div *ngIf="isEdit == false" class="tylie-form-group form-check ms-3 mb-0">
        <input class="form-check-input" type="checkbox" name="apply-to-all-groups" id="apply-to-all-groups" [ngModelOptions]="{standalone: true}" [(ngModel)]="applyToAllGroups">
        <label class="form-check-label" for="apply-to-all-groups">Apply To All Groups</label>
      </div>
      <button class="tylie-button tylie-button--xs ms-3" type="button" (click)="onApplyToOrder()">APPLY TO ORDER</button>
    </div>

    <div class="d-flex justify-content-end" *ngIf="addMoreEmailsMode == false && podMode == true">
        <button class="tylie-button tylie-button--xs" type="button" (click)="onApplyToOrder()">ADD POD RECIPIENTS</button>
    </div>
  </div>
</div>
