<dx-data-grid class="tylie-grid" id="orderDetailFtp"
              #orderDetailFtp
              [dataSource]="orderDetailFtpStore"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [showColumnLines]="true"
              [showRowLines]="true"
              [hoverStateEnabled]="true"
              [rowAlternationEnabled]="true"
              [showBorders]="true"
              (onCellClick)="onFtpPasswordClickshowFtpPassword($event)"
              (onRowPrepared)="onRowPrepared($event)"
              keyExpr="orderDestinationId">

  <dxi-column dataField="name" width="17%" cssClass="gridStyle" caption="Name" sortOrder="asc"></dxi-column>
  <dxi-column dataField="ftpServer" width="15%" cssClass="gridStyle" caption="FTP Server"></dxi-column>
  <dxi-column dataField="ftpUserName" width="16%" cssClass="gridStyle" caption="FTP User"></dxi-column>
  <dxi-column dataField="ftpPassword" width="15%" cellTemplate="passwordTemplate" cssClass="gridStyle" caption="FTP Password"></dxi-column>
  <dxi-column dataField="status" width="15%" cssClass="gridStyle" caption="Status"></dxi-column>
  <dxi-column dataField="podDate" width="17%" cellTemplate="dateTemplate" cssClass="gridStyle" caption="Status Date (EST)"></dxi-column>
  <dxi-column dataField="pod" cellTemplate="podTemplate" cssClass="gridStyle" [visible]="this.orderFTPDestinationModel?.showActionsColumn" caption="" [allowSorting]="false"></dxi-column>

  <div *dxTemplate="let t of 'passwordTemplate'">
    <span>********</span>
    <dx-popover id="statusPopOver"
                position="top">
    </dx-popover>
  </div>

  <div *dxTemplate="let t of 'podTemplate'">
    <span class="new_upload_pod blue-text p-extra-small" style="cursor:pointer;" *ngIf="t.data.showUploadPod == true">
      <input id="f02" type="file" accept="image/*" (change)="onUploadPod($event,t.data.orderDestinationId)" />
      {{(uploadingPodDestinationIds.includes(t.data.orderDestinationId)) ? 'Uploading...' : 'Upload POD'}}
      <i *ngIf="uploadingPodDestinationIds.includes(t.data.orderDestinationId)" class='fa fa-circle-o-notch fa-spin'></i>
    </span>
    <span *ngIf="(t.data.showPod == true)" class="blue-text p-extra-small" (click)="onDownloadPOD(t.data.ftpPodGuid, t.data.ftpPodFileName)">POD</span>
    <span *ngIf="(t.data.showDeletePod == true)"> | </span>
    <span *ngIf="(t.data.showDeletePod == true)" class="blue-text p-extra-small" (click)="deleteFtpPod(t.data.orderDestinationId, t.data.ftpPodGuid)">Delete POD</span>
    <div style="text-align:center;" *ngIf="t.data.showEditIcon == true">
      <a class="icon-link icon-primary icon-small text-primary" (click)="onOrderFtpDestinationEdit(t.data)">
        <i class="glyphicon glyphicon-edit"></i>
      </a>
    </div>
  </div>

  <div *dxTemplate="let data of 'dateTemplate'">
    <div style="text-align:left;">{{  data.value | convertFromUTC:'MM/DD/YYYY hh:mm A' }}</div>
  </div>
  <dxo-sorting mode="multiple"></dxo-sorting>
</dx-data-grid>
    

