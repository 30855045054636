import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Utilities } from "../../../../../services/core/utilities";
import { AdminService } from "../../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { DxDataGridComponent } from "devextreme-angular";
import { UserProfileDetail } from "../../../../../models/user/user-profile-detail";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { Constants } from "../../../../../configurations/constants";
import { ForgotPasswordSettingsService } from "../../../../../services/user/forgotpassword-settings.service";
import { AuthService } from '../../../../../services/auth/auth.service';
import { UserProfileSelectionModel } from '../../../../../models/user/user.profile.model';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfirmPopupComponent } from '../../../../popups/confirmationpopups/confirmpopup.component';
import { confirmEventType } from '../../../../../configurations/enums/enums';

@Component({
  selector: 'user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

  @Input('userProfileDetail') detail: UserProfileDetail;
  @ViewChild('brandsGrid', { static: false }) brandsGrid: DxDataGridComponent;
  @Output() onEditUserEvent: EventEmitter<void> = new EventEmitter<void>();

  public usersRoute = RouteConstants.usersRoute;
  public modalRef: BsModalRef;
  constructor(public util: Utilities
    , public adminService: AdminService
    , private alertService: AlertService
    , private forgotPasswordSettingsService: ForgotPasswordSettingsService
    , private authService: AuthService
    , private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  public onEditUser() {
    this.onEditUserEvent.emit();
  }

  public generateResetPasswordToken() {
    this.forgotPasswordSettingsService.generateResetPasswordToken(this.detail.userName)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.passwordResetEmailSent, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccessLogin(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onStatusUpdateVerifyUserNeedsToBeRemoved(isActive: boolean) {
    this.authService.getUserProfilesForInactivateUserEndpoint().subscribe((res: any) => {
      var userProfiles = res.result as UserProfileSelectionModel[];
      if (userProfiles != null && userProfiles.length == 1) {
        var initialState = {
          eventType: confirmEventType[confirmEventType.DeleteUser]
        };

        this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        this.modalRef.content.onClose.subscribe(result => {
          if (result == true) {
            this.userStatusUpdate(isActive);
          }
        });
      }
      else {
        this.userStatusUpdate(isActive);
      }
    });
  }

  public userStatusUpdate(isActive: boolean) {
    this.adminService.updateUserProfileStatus(this.detail.userProfileGuid, { isActive: isActive }).subscribe((res: any) => {
      var result = res.result;
      if (res.isSuccess == true) {
        this.detail = result;
        this.detail.avatar = this.util.notEmpty(this.detail.avatar) ? Constants.avatarEncoding + this.detail.avatar : null;
        this.alertService.showMessage("SUCCESS", Constants.userProfileStatusUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }
}
