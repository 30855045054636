<div class="notifications-section" id="notifications-section">
  <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        SELECT OLV PROFILES
      </h4>
    </div>

    <div id="mediaApprovalCollapseOne" class="tylie-panel__body">
      
        <div class="form-group qc-update-radio" *ngIf="!isLastOne">
          <label class="radio-inline">
            <input type="radio" name="selectionType" value="same" [(ngModel)]="selectionType"><span>Select the same OLV Profiles for all Creative</span>
          </label>
          <label class="radio-inline">
            <input type="radio" name="selectionType" value="different" [(ngModel)]="selectionType"><span>Select individual OLV Profiles for each Creative</span>
          </label>
        </div>

        <h3 *ngIf="selectedSpotFile && selectionType == 'different'" class="tylie-type-h3">
          Select OLV Profiles for "{{selectedSpotFile.adId + ':  ' + selectedSpotFile.title}}"
        </h3>

        <div *ngIf="selectionType == 'same'" class="tylie-type-h3">
          Select OLV Profiles for all Creative in this Order
        </div>

        <div class="d-flex justify-content-between mb-3">
          <h3 class="tylie-body__title">Saved Transcode Order Lists</h3>
          <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="onCreateNewList()"><span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>NEW TRANSCODE ORDER LIST</button>
        </div>

        <dx-data-grid class="tylie-grid tylie-grid--bordered mb-4" id="ordertranscodesSavedListGrid"
                      #ordertranscodesSavedListGrid
                      [dataSource]="transcodesSavedListSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxi-column dataField="name" width="20%" cssClass="gridStyle" caption="List Name" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="favDestination" width="70%" cssClass="gridStyle" caption="OLV Profiles in List" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="name" width="10%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate" [allowSorting]="false"></dxi-column>
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <div *dxTemplate="let t of 'actionsTemplate'">
            <div class="d-flex justify-content-center">
              <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onItemEdit(t.data.id)">
                <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
              </a>
              <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button"
                      placement="left" #listdeletepop="bs-popover" [popoverContext]="t.data" container="body" [popover]="deleteListTemplate">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </a>
            </div>
          </div>
        </dx-data-grid>

        <div class="row">
          <div class="col-md-8">
            <h3 class="tylie-body__title">Online Video</h3>
            <h3 class="tylie-type-h3">
              OLV Profiles Currently Selected
              <p *ngIf="transcodesSelectedString" class="tylie-type-body tylie-type-body--sm">{{transcodesSelectedString}}</p>
              <p *ngIf="!transcodesSelectedString" class="tylie-type-body tylie-type-body--sm">Nothing selected yet. Please use checkboxes below.</p>
            </h3>
          </div>
          <div class="col-md-4">
            
            <section class="tylie-input-search">
              <div class="tylie-input-search__inner">
                <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                <input class="tylie-input-search__text" placeholder="Search by OLV Profile or Spec" name="srch-term" id="srch-term" type="search"
                       [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()" (keydown.enter)="onQuickSearch()">
               
              </div>
              <div class="tylie-input-search__btn">
                <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearch()">Search</button>
              </div>
            </section>


          </div>
        </div>

        <dx-data-grid class="tylie-grid tylie-grid--bordered" id="ordertranscodeSpecsGrid"
                      #ordertranscodeSpecsGrid
                      [dataSource]="transcodeSpecsSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      (onContentReady)="onSpecsGridContentReady($event)"
                      (onSelectionChanged)="onSpecsGridSelectionChanged($event)"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                          [allowSelectAll]="false"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxi-column width="30%" caption="OLV Profiles" dataField="name" cssClass="gridStyle" sortOrder="asc"></dxi-column>
          <dxi-column width="70%" caption="Transcode Specs" dataField="description" [allowSorting]="false" cssClass="gridStyle"></dxi-column>
        </dx-data-grid>

        <div class="tylie-type-body tylie-type-body--sm mt-2">Don't see the Spec you're looking for? <span><a href="mailto:support@tylie.com">Let Us Know.</a></span></div>
      
    </div>
  </div>
</div>


<ng-template #deleteListTemplate let-id="id">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this List?
    </p>
    <i (click)="onHideDeleteListPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onItemDelete(id)">Yes</button>

   -->

  <button type="button" class="popover__close" (click)="onHideDeleteListPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this List?</header>
  <button class='tylie-button tylie-button--xs' type="button" (click)="onItemDelete(id)">Yes</button>
</ng-template>
