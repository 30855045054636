<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="row" *ngIf="allowSpecialOptions == true">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['vendor'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Vendor<span class="required">*</span></label>
      <dx-select-box [dataSource]="vendors" name="vendor"
                     valueExpr="value"
                     displayExpr="name"
                     class="tylie-select"
                     formControlName="vendor" placeholder="Choose vendor"></dx-select-box>
      <p class="p-extra-small">Please choose a Vendor</p>
    </div>
  </div>
</div>
