<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Expire Spots</h4>
</div>
<div class="modal-body">
  <form [formGroup]="expiryRequest" #f="ngForm" (ngSubmit)="onSubmit(expiryRequest)">
    <div class="tylie-form-group form-group" [ngClass]="{error: (expiryRequest.controls['expiryDate'].errors) && f.submitted}">
      <label class="tylie-form-group__lbl" for="notes">Select Expiry date<span class="required">*</span></label>
      <dx-date-box class="tylie-select" type="date" #db name="expiryDate"
                   displayFormat="MM/dd/yyyy"
                   formControlName="expiryDate" [disabled]="spots.length == 0" [showClearButton]="true" invalidDateMessage="Invalid date"></dx-date-box>
      <p class="p-extra-small">Please select a valid expiry date</p>
    </div>
  
    <div class="tylie-form-group form-group">
      <div class="d-flex justify-content-between">
        <label class="tylie-form-group__lbl" for="Format">
          Expire Spots File
        </label>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadSpotExpiryTemplate()">
          Click Here for Expire Spots File Template
        </button>
      </div>
      <div class="tylie-upload input-group">
        <label class="input-group-btn">
          <span class="tylie-upload__action tylie-button tylie-button--sm">
            Browse<input type="file" [disabled]="spots.length > 0" style="display: none;" #excelFileInput (change)="incomingfile($event)" required accept=".xlsx,.xls" id="spotExpireFile" name="spotExpireFile">
          </span>
        </label>
        <input type="text" class="tylie-text form-control" readonly="" value="{{spotExpireExcelFile != null ? spotExpireExcelFile.name : ''}}">
      </div>
      <span class="help-block hide error">
        Please choose Expire Spots File excel
      </span>
    </div>

    <div id="expiryNotesDiv" class="tylie-form-group form-group">
      <label class="tylie-form-group__lbl" for="expiryNotesDiv">Notes</label>
      <textarea name="expiryNotes" formControlName="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
    </div>
    <div class="d-flex">
      <button class="tylie-button tylie-button--xs me-3" type="submit">Submit</button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>
  </form>
</div>
