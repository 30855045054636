import { Component, OnInit, ViewChild } from "@angular/core";
import { Params, ActivatedRoute } from "@angular/router";
import { Utilities } from "../../../services/core/utilities";
import { RouteConstants } from "../../../configurations/route-constants";
import { OrderDetailContentComponent } from "./order-detail-content/orderdetailcontent.component";

@Component({
  selector: 'order-detail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.css']
})
export class OrderDetailComponent implements OnInit {

  public orderGuid: string;
  public ordersRoute = RouteConstants.ordersRoute;
  @ViewChild(OrderDetailContentComponent, { static: false }) private od: OrderDetailContentComponent;

  constructor(private route: ActivatedRoute, public util: Utilities) {
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.orderGuid = params['orderGuid'];
    });
  }

  public hasUnsavedData(): boolean {
    return this.od.hasUnsavedData();
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }
}
