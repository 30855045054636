<div id="order-conformation">

  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">ORDER CONFIRMATION</h4>
  </div>

  <div class="modal-body">
    <div>
      <h6>Order #{{workOrder}}</h6>
      <p class="black p-normal"><strong>Thank you for your order. Team Tylie is processing it now.</strong></p>
      <br />
      <p class="gray1  p-normal">Progress notifications via email are available in your user preferences.</p>
      <p class="gray1  p-normal">You can also track the order online.</p>
      <p class="gray1  p-normal">Options for downloading a copy of your order are  available by clicking below.</p>
      <div align="right" class="finalize-order-btn-sec">
        <button class="new-default-btn small-btn finalize-order-btn" (click)="onCopyOrderLink()">
          <label>COPY ORDER LINK</label>
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button class="new-default-btn small-btn finalize-order-btn" [disabled]="isDownlaoding" (click)="onDownloadOrderSummaryPdf()">
          <label><i *ngIf="isDownlaoding" class='fa fa-circle-o-notch fa-spin'></i> {{isDownlaoding ? 'DOWNLOADING...' : 'DOWNLOAD PDF'}}</label>
        </button>
      </div>

      <div class="traffic p-normal gray1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 507.55 507.56">
          <defs>
            <style>
              .cls-1 {
                fill: #ed1c24;
              }
            </style>
          </defs>
          <!--<title>Asset 3</title>-->
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M253.78,0C113.62,0,0,113.62,0,253.78S113.62,507.56,253.78,507.56,507.55,393.94,507.55,253.78,393.94,0,253.78,0Zm96.91,177.66a5.21,5.21,0,0,1-.32.94,5.77,5.77,0,0,1-.22.58l-.07.1c-.05.13-.11.26-.17.39L308.56,283.1,266.82,387.57a5.47,5.47,0,0,1-10.43-.84L231.45,278,122.32,244.49a5.47,5.47,0,0,1,.12-10.53l110.7-31.33,109.81-31,.23-.14h0a5.86,5.86,0,0,1,.75-.17l.25-.05.33-.08.17,0H345l.25-.06.27,0a5,5,0,0,1,.54.06l.26,0a6.36,6.36,0,0,1,.85.25l.18.05a3.28,3.28,0,0,1,.31.2l.18,0a3.28,3.28,0,0,1,.31.2,5.46,5.46,0,0,1,2.48,5.66Z" /></g></g>
        </svg>
        To contact us. please call (818) 955 7600 or email traffic@tylie.com
        <br />
      </div>
    </div>
  </div>
</div>
