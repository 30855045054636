<header class="tylie-header">
  <h3 class="tylie-header__title">Client</h3>
</header>

<div class="tylie-body">
  <ng-container *isAuthorized="[this.userRoles.getAdminClientServicesRoleName()]">
    <div class="tylie-body__bar" id="create-order-section">
      <button type="button" class="tylie-button tylie-button--icon" (click)="gotoCreateNewClient()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">CREATE NEW CLIENT</span>
      </button>
    </div>
  </ng-container>

  <search-clients></search-clients>
</div>

