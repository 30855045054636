import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { ProductModel } from "../../../../models/admin/brand/createproduct.model";
import { AlertService } from "../../../../services/core/alert.service";
import { OrderService } from "../../../../services/order/order.service";
import { ConfigService } from "../../../../services/core/config.service";
import { Utilities } from "../../../../services/core/utilities";
import { DeleteOrderMediaEvent } from "../../../../models/order/vendor-purchaseorder-request.model";
import { ClientBrandProductSelectedEvent } from "../../../../models/admin/client/createclient.model";
import { Constants } from "../../../../configurations/constants";
import { EditClientConfig } from '../../../../models/admin/client/editclient.model';

@Component({
  selector: 'clientbrandproductgroup',
  templateUrl: './clientbrandproductgroup.component.html',
  styleUrls: ['./clientbrandproductgroup.component.css'],
})
export class ClientBrandProductGroup implements OnInit {

  @Input() cbpGroup: FormGroup;
  @Input() parentForm: NgForm;
  @Input() groupName: number;
  @Input() formGroupcount: number;
  @Input() brandProductDataSource: ProductModel[] = [];
  @Input('editConfig') editConfig: EditClientConfig = null;
  @Input('allowEdits') allowEdits: boolean = false;
  @Input('isClientEdit') isClientEdit: boolean = false;

  @Output() onProductSelectedEventTriggered: EventEmitter<ClientBrandProductSelectedEvent> = new EventEmitter<ClientBrandProductSelectedEvent>();
  @Output() onDeletecbpGroupEventTriggered: EventEmitter<number> = new EventEmitter<number>();

  public modalRef: BsModalRef;

  public selectedClientBrandProducts: number[] = [];
  public brandProducts: ProductModel[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private util: Utilities,
    private configService: ConfigService) {

  }

  ngOnInit() {

  }

  public get formCostCentersArray() {
    return <FormArray>this.cbpGroup.get('costCenter');
  }

  public get formbillTosArray() {
    return <FormArray>this.cbpGroup.get('inVoiceTo');
  }

  public onCostCenterAddMore() {
    var costCenter = this.cbpGroup.get('costCenter') as FormArray;
    const group = this.fb.group({
      costCenter: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern), Validators.maxLength(35)])],
    });
    costCenter.push(group);
    return costCenter;
  }

  public onBillToAddMore() {
    var inVoiceTo = this.cbpGroup.get('inVoiceTo') as FormArray;
    const group = this.fb.group({
      billTo: [null, Validators.compose([Validators.pattern(Constants.costCenterBillToPattern)])],
    });
    inVoiceTo.push(group);
    return inVoiceTo;
  }

  public onCostCenterRemove(groupIndex: number) {
    var costCenterArray = this.cbpGroup.get('costCenter') as FormArray;
    costCenterArray.removeAt(groupIndex);
  }

  public onBillToRemove(groupIndex: number) {
    var billToArray = this.cbpGroup.get('inVoiceTo') as FormArray;
    billToArray.removeAt(groupIndex);
  }

  public onProductSeleceted(e: any) {
    if (this.util.notEmpty(e)) {
      var cbpSelectedEvent = new ClientBrandProductSelectedEvent();
      cbpSelectedEvent.groupName = this.groupName;
      cbpSelectedEvent.productSelected = e.value;
      this.cbpGroup.patchValue({ name: this.brandProductDataSource.find(bp => bp.id == e.value).name });
      this.onProductSelectedEventTriggered.emit(cbpSelectedEvent);
    }
  }

  public oncbpGroupRemove() {
    this.onDeletecbpGroupEventTriggered.emit(this.groupName);
  }
}
