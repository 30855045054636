import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-star',
  templateUrl: './icon-star.component.html',
  styleUrls: ['./icon-star.component.css']
})
export class IconStarComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string = '#ed8a19';

  constructor() { }

  ngOnInit(): void {
  }

}
