<header class="tylie-header">
    <h3 class="tylie-header__title">Download</h3>
    <nav class="tylie-header__nav">
      <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoSource()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>
        <span class="back_to_nav" *ngIf="source == 'media'">Back to All Assets</span>
        <span class="back_to_nav" *ngIf="source == 'transcode'">BACK TO TRANSCODE FILES MAIN</span>
        <span class="back_to_nav" *ngIf="source == 'tagger'">BACK TO TAGGER MAIN</span>
      </button>
    </nav>
</header>

<div class="tylie-body">
  <div class="tylie-panel" *ngIf="anyActiveDownloads">
    <div class="tylie-panel__body">
      <h4 class="media-upload-text">
        <b>Active Downloads</b>
        <span class="action" (click)="clearCompleted()">Clear Completed</span>
      </h4>
      <div class="group-progress" *ngFor="let aFS of downloadFileStatus">
        <ng-container *ngIf="aFS.show">
          <p class="p-normal client-up-name">Started at {{aFS.header}}</p>
          <div class="progress-container" *ngFor="let f of aFS.dowloadStatus">
            <ng-container *ngIf="f.show">
              <div class="progress progress-left">
                <div class="progress-bar" role="progressbar"
                      [attr.aria-valuenow]="f.percentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="(f.percentage + '%')">
                </div>
              </div>
              <div class="progress-right">
                <p>{{downloadStatusEnum[f.status].toString()}}</p>
                <p>{{f.percentage}}%</p>
              </div>
              <div class=" progress-download-right">
                <i class="glyphicon glyphicon-download" *ngIf="f.status != downloadStatusEnum.completed"
                    [ngClass]="{submitted: f.status == downloadStatusEnum.submitted, processing: f.status == downloadStatusEnum.downloading, '': f.status == downloadStatusEnum.failed}">
                </i>
                <i *ngIf="f.status == downloadStatusEnum.completed">
                  <svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <style type="text/css">
                    .st0 {
                      fill: #59bc25;
                    }

                    .st1 {
                      fill: #fff;
                    }
</style><g><circle class="st0" cx="64" cy="64" r="64"></circle></g><g><path class="st1" d="M54.3,97.2L24.8,67.7c-0.4-0.4-0.4-1,0-1.4l8.5-8.5c0.4-0.4,1-0.4,1.4,0L55,78.1l38.2-38.2   c0.4-0.4,1-0.4,1.4,0l8.5,8.5c0.4,0.4,0.4,1,0,1.4L55.7,97.2C55.3,97.6,54.7,97.6,54.3,97.2z"></path></g>
                  </svg>
                </i>
              </div>
              <div class="file-name">
                <!-- <p class="name" *ngIf="!f.fileNameWithExt">{{f.adId}} - {{f.title}}</p>
                <p class="name" *ngIf="f.fileNameWithExt">{{f.fileNameWithExt}}</p> -->
                <p class="name" *ngIf="f.status != downloadStatusEnum.completed">{{f.fileName}}</p>
                <p class="name" *ngIf="f.status == downloadStatusEnum.completed">{{f.fileNameWithExt}}</p>
                <p class="error" *ngIf="f.error && f.error.length > 0">{{f.error}}</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div> 

  <div class="tylie-panel tylie-panel--clean" *ngIf="!anyActiveDownloads">
    <div class="tylie-panel__body">
      <h4 class="media-upload-text">
        <b>No Active Downloads</b>
      </h4>
    </div>
  </div>
</div>
