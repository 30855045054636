<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['timecodeNeeded'].errors && f.submitted)}">
    <label class="tylie-form-group__lbl">Timecode needed ?<span class="required">*</span></label>
    <div class="d-flex">
      <div class="form-check me-3"><input class="form-check-input" type="radio" name="timecodeNeeded" formControlName="timecodeNeeded" value="Yes"><span class="form-check-label">Yes</span></div>
      <div class="form-check"><input class="form-check-input" type="radio" name="timecodeNeeded" formControlName="timecodeNeeded" value="No"><span class="form-check-label">No</span></div>
    </div>
    <p class="p-extra-small">Please choose an option</p>
  </div>

  <div class="row">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['language'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="language">Language<span class="required">*</span></label>
      <dx-drop-down-box id="language"
                        name="language"
                        class="tylie-select form-control"
                        [(value)]="selectedLanguages"
                        valueExpr="value"
                        displayExpr="name"
                        formControlName="language"
                        (onValueChanged)="onLanguageChanged()"
                        [placeholder]="placeholder"
                        [dataSource]="optionsLanguage">
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [dataSource]="optionsLanguage"
                        dataStructure="plain"
                        keyExpr="value"
                        displayExpr="name"
                        #treeLanguage
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        (onContentReady)="onLanguageChanged($event)"
                        (onItemSelectionChanged)="onLanguageTreeChanged($event)"
                        [selectByClick]="true">
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
      <p class="p-extra-small">Please Select a Language</p>
    </div>
  </div>

  <div class="row">
    <div class="tylie-form-group form-group col-md-6" *ngIf="showOtherLanguage" [ngClass]="{error: (formGroup.controls['languageCustom'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="languageCustom">Other Language<span class="required">*</span></label>
      <input type="text" name="languageCustom" class="tylie-text form-control" formControlName="languageCustom">
      <p class="p-extra-small">Please enter other Language</p>
    </div>
  </div>

  <div class="row">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['fileType'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="fileType">File Type<span class="required">*</span></label>
      <dx-drop-down-box id="fileType"
                        name="fileType"
                        [(value)]="selectedFileTypes"
                        valueExpr="value"
                        displayExpr="name"
                        class="tylie-select form-control"
                        formControlName="fileType"
                        (onValueChanged)="onFileTypeChanged()"
                        [placeholder]="placeholder"
                        [dataSource]="optionsFileType">
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [dataSource]="optionsFileType"
                        dataStructure="plain"
                        keyExpr="value"
                        displayExpr="name"
                        #treeFileType
                        selectionMode="single"
                        showCheckBoxesMode="normal"
                        (onContentReady)="onFileTypeChanged($event)"
                        (onItemSelectionChanged)="onFileTypeTreeChanged($event)"
                        [selectByClick]="true">
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
      <p class="p-extra-small">Please choose a file type</p>
    </div>
  </div>
</div>
