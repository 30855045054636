import { Injectable } from '@angular/core';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AuthService } from "../auth/auth.service";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserDataService {
  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  //public getUserData() {
  //  return this.http.get(this.apiendPoint.getUserDataEndpoint());
  //}

  public getPendingActionCount() {
    return this.http.get(this.apiendPoint.getPendingActionCountEndpoint());
  }

  public getUserDisplayData() {
    return this.http.get(this.apiendPoint.getUserDisplayDataEndpoint());
  }

  public searchUserFavoriteEmails(request: any) {
    return this.http.post(this.apiendPoint.searchUserFavoriteEmailsEndpoint(), request);
  }

  public checkUserExists(userName: string) {
    return this.http.get(this.apiendPoint.checkUserExistsEndpoint() + '/' + userName);
  }
}
