import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { MediaDetailService } from "../../../services/mediadetail/mediadetail.service";
import { OrderService } from '../../../services/order/order.service';

@Component({
  selector: 'edithistorypopup',
  templateUrl: './edithistorypopup.component.html',
  styleUrls: ['./edithistorypopup.component.css'],
})
export class EditHistoryPopupComponent implements OnInit {

  public objectId: string;
  public editHistoryData: any;
  public objectCategory: string;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private mediaDetailService: MediaDetailService,
    private orderService: OrderService,
    private util: Utilities) {
  }

  ngOnInit() {

    if (this.objectCategory == 'spot') {
      this.mediaDetailService.getAuditLogsSpot(this.objectId).subscribe((res: any) => {
        if (res.isSuccess == true) {

          this.editHistoryData = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error)
          this.closePopup();
        });
    }
    else {

      this.orderService.getOrderAuditLogs(this.objectId).subscribe((res: any) => {

        if (res.isSuccess == true) {

          this.editHistoryData = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error)
          this.closePopup();
        });
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
