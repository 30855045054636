import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { Validators } from '@angular/forms';
import { PredefinedSpec } from '../../../../models/order/order-additionalmetadata';

@Component({
  selector: 'service-videotranscoding',
  templateUrl: './servicevideotranscoding.component.html'
})
export class ServiceVideoTranscodingQComponent extends BaseServiceDataCollectionComponent {

  public fileTypes: Array<string> = [];
  public codecs: Array<string> = [];
  public bitratesTypeA: Array<number> = [];
  public audiobitratesTypeA: Array<number> = [];
  public resolutions: Array<string> = [];
  public slateoptions: Array<string> = [];
  public framerates: Array<string> = [];
  public audiosamplerates: Array<string> = [];
  public predefinedSpecs: Array<PredefinedSpec> = [];
  public customSpecMode: boolean = true;
  public customFileNameOptions: Array<string>;

  public specDescription: string = '';
  public isDisabled: boolean = false;

  private isaudio: boolean = false;
  private aspectratio: string = '';
  private format: string = '';
  private bitRateUnit = "Kbps";
  public bitrateunits = ['Kbps', 'Mbps']

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();
    if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0) {
      this.isaudio = this.additionalMetadata.ppFileSpecs[0].isAudio;
      if (this.additionalMetadata.ppFileSpecs[0].aspectRatio != null)
        this.aspectratio = this.additionalMetadata.ppFileSpecs[0].aspectRatio;
      if (this.additionalMetadata.ppFileSpecs[0].format != null)
        this.format = this.additionalMetadata.ppFileSpecs[0].format;
    }
    this.isDisabled = this.formGroup.controls["spec"].disabled;

    this.predefinedSpecs = this.additionalMetadata.transcodePredefinedSpecs;

    this.fileTypes = ['MOV', 'MP4', 'WMV', 'MPG', 'MXF', 'AVI', 'FLV'];
    this.customFileNameOptions = this.additionalMetadata.customFileNameOptions;

    this.performFileTypeChecks();

    this.perfromCodecChecks();

    if (this.formGroup.get('spec').value != null && this.formGroup.get('spec').value.trim() != '') {
      this.specDescription = this.predefinedSpecs.find(p => p.name == this.formGroup.controls["spec"].value).description;
      this.customSpecMode = false;
    }
    else if (this.formGroup.get('fileType').value != null && this.formGroup.get('fileType').value.trim() != '')
      this.customSpecMode = true;
    else
      this.customSpecMode = false;

    this.formGroup.controls["bitratemultiplier"].setValue(1);
    this.formGroup.controls["bitrateunit"].setValue("Kbps");

    this.onBuildCustomSpec();

    this.setValidators();
  }

  public onPredefineSpecChanged() {
    if (this.formGroup.get('spec').value != null && this.formGroup.get('spec').value.trim() != '')
      this.specDescription = this.predefinedSpecs.find(p => p.name == this.formGroup.controls["spec"].value).description;
  }
  private setValidators() {
    if (this.customSpecMode == false) {
      this.formGroup.controls["fileType"].clearValidators();
      this.formGroup.controls["fileType"].setValue('');
      this.formGroup.controls["fileType"].updateValueAndValidity();

      this.formGroup.controls["codec"].clearValidators();
      this.formGroup.controls["codec"].setValue('');
      this.formGroup.controls["codec"].updateValueAndValidity();

      this.formGroup.controls["bitrate"].clearValidators();
      this.formGroup.controls["bitrate"].setValue('');
      this.formGroup.controls["bitrate"].updateValueAndValidity();

      this.formGroup.controls["audiobitrate"].clearValidators();
      this.formGroup.controls["audiobitrate"].setValue('');
      this.formGroup.controls["audiobitrate"].updateValueAndValidity();

      this.formGroup.controls["resolution"].clearValidators();
      this.formGroup.controls["resolution"].setValue('');
      this.formGroup.controls["resolution"].updateValueAndValidity();

      this.formGroup.controls["slate"].clearValidators();
      this.formGroup.controls["slate"].setValue('');
      this.formGroup.controls["slate"].updateValueAndValidity();

      this.formGroup.controls["framerate"].clearValidators();
      this.formGroup.controls["framerate"].setValue('');
      this.formGroup.controls["framerate"].updateValueAndValidity();

      this.formGroup.controls["audiosamplerate"].clearValidators();
      this.formGroup.controls["audiosamplerate"].setValue('');
      this.formGroup.controls["audiosamplerate"].updateValueAndValidity();

      this.formGroup.get('spec').setValidators([Validators.required]);
      this.formGroup.get('spec').updateValueAndValidity();
    }
    else {
      this.formGroup.controls["spec"].clearValidators();
      this.formGroup.controls["spec"].setValue('');
      this.formGroup.controls["spec"].updateValueAndValidity();

      this.formGroup.get('comments').setValidators([this.fileTypeRequiredIfCommentsNotEntered.bind(this)]);
      this.formGroup.get('fileType').setValidators([this.fileTypeRequiredIfCommentsNotEntered.bind(this)]);
    }
  }

  fileTypeRequiredIfCommentsNotEntered(control) {
    const commentsValue = control.parent.get('comments').value;
    const fileTypeValue = control.parent.get('fileType').value;

    console.log("filetype" + fileTypeValue)
    console.log(commentsValue)

    if ((commentsValue == null || commentsValue == "") && (fileTypeValue == null || fileTypeValue == "")) {
      console.log("Setting error");
      control.parent.get('fileType').setErrors({ fileTypeRequired: true });
      control.parent.get('comments').setErrors({ fileTypeRequired: true });
      return { fileTypeRequired: true };
    } else {
      console.log("Clearing error");
      control.parent.get('fileType').setErrors(null);
      control.parent.get('comments').setErrors(null);
      return null;
    }
  }

  public onFileTypeChanged() {

    this.performFileTypeChecks();

    this.formGroup.controls["codec"].setValue(null);
    this.formGroup.controls["bitrate"].setValue(null);
    this.formGroup.controls["audiobitrate"].setValue(null);
    this.formGroup.controls["resolution"].setValue(null);
    this.formGroup.controls["slate"].setValue(null);
    this.formGroup.controls["framerate"].setValue(null);
    this.formGroup.controls["audiosamplerate"].setValue(null);

    this.bitratesTypeA = [];
    this.audiobitratesTypeA = [];
    this.resolutions = [];
    this.slateoptions = [];
    this.framerates = [];
    this.audiosamplerates = [];

    this.formGroup.get('codec').setValidators([Validators.required]);
    this.formGroup.get('codec').updateValueAndValidity();

    this.formGroup.get('resolution').setValidators([Validators.required]);
    this.formGroup.get('resolution').updateValueAndValidity();

    this.formGroup.get('slate').setValidators([Validators.required]);
    this.formGroup.get('slate').updateValueAndValidity();

    this.formGroup.get('framerate').setValidators([Validators.required]);
    this.formGroup.get('framerate').updateValueAndValidity();

    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')
      || (this.formGroup.controls["fileType"].value == 'MPG')
      || (this.formGroup.controls["fileType"].value == 'WMV')
      || (this.formGroup.controls["fileType"].value == 'MXF')
      || (this.formGroup.controls["fileType"].value == 'FLV')) {
      this.formGroup.get('bitrate').setValidators([Validators.required]);
      this.formGroup.get('bitrate').updateValueAndValidity();
    }
    else {
      this.formGroup.controls["bitrate"].clearValidators();
      this.formGroup.controls["bitrate"].setValue('');
      this.formGroup.controls["bitrate"].updateValueAndValidity();
    }

    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')) {
      this.formGroup.get('audiobitrate').setValidators([Validators.required]);
      this.formGroup.get('audiobitrate').updateValueAndValidity();
    }
    else {
      this.formGroup.controls["audiobitrate"].clearValidators();
      this.formGroup.controls["audiobitrate"].setValue('');
      this.formGroup.controls["audiobitrate"].updateValueAndValidity();
    }

    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')
      || (this.formGroup.controls["fileType"].value == 'MPG')
      || (this.formGroup.controls["fileType"].value == 'AVI')) {
      this.formGroup.get('audiosamplerate').setValidators([Validators.required]);
      this.formGroup.get('audiosamplerate').updateValueAndValidity();
    }
    else {
      this.formGroup.controls["audiosamplerate"].clearValidators();
      this.formGroup.controls["audiosamplerate"].setValue('');
      this.formGroup.controls["audiosamplerate"].updateValueAndValidity();
    }

  }

  public onCodecChanged() {
    this.perfromCodecChecks();
    if (this.bitratesTypeA.length > 0)
      this.formGroup.controls["bitrate"].setValue(50000);
    else
      this.formGroup.controls["bitrate"].setValue(null);
    if (this.audiobitratesTypeA.length > 0)
      this.formGroup.controls["audiobitrate"].setValue(384);
    else
      this.formGroup.controls["audiobitrate"].setValue(null);
    this.formGroup.controls["slate"].setValue(null);
    this.formGroup.controls["audiosamplerate"].setValue(null);
    this.formGroup.controls["framerate"].setValue(null);
    this.formGroup.controls["resolution"].setValue(null);
  }

  private performFileTypeChecks() {
    if (this.formGroup.controls["fileType"].value == 'MOV')
      this.codecs = ['H264', 'ProRes'];
    else if (this.formGroup.controls["fileType"].value == 'MP4')
      this.codecs = ['H264'];
    else if (this.formGroup.controls["fileType"].value == 'MPG' || this.formGroup.controls["fileType"].value == 'MXF')
      this.codecs = ['MPEG-2'];
    else if (this.formGroup.controls["fileType"].value == 'WMV')
      this.codecs = ['WMV9'];
    else if (this.formGroup.controls["fileType"].value == 'AVI')
      this.codecs = ['MV1'];
    else if (this.formGroup.controls["fileType"].value == 'FLV')
      this.codecs = ['FLV8'];
  }

  private perfromCodecChecks() {
    this.slateoptions = ['Keep', 'Remove'];
    this.framerates = ['23.98', '29.97'];
    //bitrate
    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')
      || (this.formGroup.controls["fileType"].value == 'MPG')
      || (this.formGroup.controls["fileType"].value == 'WMV')
      || (this.formGroup.controls["fileType"].value == 'MXF')
      || (this.formGroup.controls["fileType"].value == 'FLV')) {
      this.bitratesTypeA = [1000, 50000];
      //this.formGroup.controls["bitrate"].setValue(this.bitratesTypeA[0]);
    }
    else {
      this.bitratesTypeA = [];
      this.formGroup.controls["bitrate"].clearValidators();
      this.formGroup.controls["bitrate"].setValue(null);
      this.formGroup.controls["bitrate"].updateValueAndValidity();
    }
    //audio bitrate
    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')) {
      this.audiobitratesTypeA = [96, 384];
    }
    else {
      this.audiobitratesTypeA = [];
      this.formGroup.controls["audiobitrate"].clearValidators();
      this.formGroup.controls["audiobitrate"].setValue(null);
      this.formGroup.controls["audiobitrate"].updateValueAndValidity();
    }
    //audio 
    if ((this.formGroup.controls["fileType"].value == 'MOV' && (this.formGroup.controls["codec"].value == 'H264' || this.formGroup.controls["codec"].value == '' || this.formGroup.controls["codec"].value == null))
      || (this.formGroup.controls["fileType"].value == 'MP4')
      || (this.formGroup.controls["fileType"].value == 'MPG')
      || (this.formGroup.controls["fileType"].value == 'AVI')) {
      this.audiosamplerates = ['44.1', '48.0']
    }
    else {
      this.audiosamplerates = [];
      this.formGroup.controls["audiosamplerate"].clearValidators();
      this.formGroup.controls["audiosamplerate"].setValue('');
      this.formGroup.controls["audiosamplerate"].updateValueAndValidity();
    }
    //resolution
    if (this.formGroup.controls["fileType"].value == 'MOV' && this.formGroup.controls["codec"].value == 'ProRes') {
      if (this.aspectratio == '16:9')
        this.resolutions = ['1920x1080', '1280x720', 'As Master'];
      else if (this.aspectratio == '4:3' || this.aspectratio == '3:2')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else if (this.aspectratio == '1:1' || this.aspectratio == '1.000')
        this.resolutions = ['1080x1080', '640x640', 'As Master'];
      else if (this.aspectratio == '4:5' || this.aspectratio == '0.800')
        this.resolutions = ['864x1080', '512x640', 'As Master'];
      else if (this.aspectratio == '9:16' || this.aspectratio == '0.563')
        this.resolutions = ['1080x1920', '720x1280', '360x640', 'As Master'];
      else if (this.format == 'hd')
        this.resolutions = ['1920x1080', '1280x720', 'As Master'];
      else if (this.format == 'sd')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else
        this.resolutions = [];
    }
    else if ((this.formGroup.controls["fileType"].value == 'MOV' && this.formGroup.controls["codec"].value == 'H264')
      || (this.formGroup.controls["fileType"].value == 'MP4')
      || (this.formGroup.controls["fileType"].value == 'WMV')
      || (this.formGroup.controls["fileType"].value == 'AVI')
      || (this.formGroup.controls["fileType"].value == 'FLV')) {
      if (this.aspectratio == '16:9')
        this.resolutions = ['1920x1080', '1280x720', '1024x576', '960x540', '640x360', 'As Master'];
      else if (this.aspectratio == '4:3' || this.aspectratio == '3:2')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else if (this.aspectratio == '1:1' || this.aspectratio == '1.000')
        this.resolutions = ['1080x1080', '640x640', 'As Master'];
      else if (this.aspectratio == '4:5' || this.aspectratio == '0.800')
        this.resolutions = ['864x1080', '512x640', 'As Master'];
      else if (this.aspectratio == '9:16' || this.aspectratio == '0.563')
        this.resolutions = ['1080x1920', '720x1280', '360x640', 'As Master'];
      else if (this.format == 'hd')
        this.resolutions = ['1920x1080', '1280x720', '1024x576', '960x540', '640x360', 'As Master'];
      else if (this.format == 'sd')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else
        this.resolutions = [];
    }
    else if ((this.formGroup.controls["fileType"].value == 'MPG')
      || (this.formGroup.controls["fileType"].value == 'MXF')) {
      if (this.aspectratio == '16:9')
        this.resolutions = ['1920x1080', '1280x720', '1024x576', '960x540', '640x360', 'As Master'];
      else if (this.aspectratio == '4:3' || this.aspectratio == '3:2')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else if (this.format == 'hd')
        this.resolutions = ['1920x1080', '1280x720', '1024x576', '960x540', '640x360', 'As Master'];
      else if (this.format == 'sd')
        this.resolutions = ['720x486', '640x480', '320x240', 'As Master'];
      else
        this.resolutions = [];
    }

  }

  public onBuildCustomSpec() {

    this.customSpecMode = true;

    this.setValidators();

    this.formGroup.controls["codec"].setValue(null);
    this.formGroup.controls["bitrate"].setValue(null);
    this.formGroup.controls["audiobitrate"].setValue(null);
    this.formGroup.controls["resolution"].setValue(null);
    this.formGroup.controls["slate"].setValue(null);
    this.formGroup.controls["framerate"].setValue(null);
    this.formGroup.controls["fileType"].setValue(null);
    this.formGroup.controls["audiosamplerate"].setValue(null);
    this.specDescription = '';

    this.bitratesTypeA = [];
    this.audiobitratesTypeA = [];
    this.resolutions = [];
    this.slateoptions = [];
    this.framerates = [];
    this.codecs = [];
    this.audiosamplerates = [];
  }

  public onChoosePredefinedSpec() {

    this.customSpecMode = false;
    this.specDescription = '';
    this.setValidators();
  }

  public onBitRateUintChanged(bitrateunit: string) {
    console.log(bitrateunit);
    if (bitrateunit == 'Kbps' && this.bitRateUnit != 'Kbps') {
      this.formGroup.controls["bitratemultiplier"].setValue(1);
      this.bitRateUnit = 'Kbps';
      this.bitratesTypeA = this.bitratesTypeA.map(val => val * 1000);

      if (this.formGroup.controls["bitrate"].value != null) {
        this.formGroup.controls["bitrate"].setValue(this.formGroup.controls["bitrate"].value * 1000);
      }
    }
    else if (bitrateunit == 'Mbps' && this.bitRateUnit != 'Mbps') {
      this.formGroup.controls["bitratemultiplier"].setValue(1000);
      this.bitRateUnit = 'Mbps'
      this.bitratesTypeA = this.bitratesTypeA.map(val => val / 1000);

      if (this.formGroup.controls["bitrate"].value != null) {
        this.formGroup.controls["bitrate"].setValue(this.formGroup.controls["bitrate"].value / 1000);
      }
    }
  }
}
