import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../services/core/utilities";
import { ReportService } from '../../services/report/report.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OnDemandReportRequestModel, ReportConfigModel, OnDemandReportConfigModel, OnDemandReportParameterConfigModel, ScheduledReportConfigModel, ScheduledReportFileModel } from '../../models/report/ondemand-report.model';
import { read } from 'fs';
import { AlertService } from '../../services/core/alert.service';
import { ProductModel } from '../../models/admin/brand/createproduct.model';
import { ConfigService } from '../../services/core/config.service';

@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public reportConfig: ReportConfigModel = null;

  public onDemandReports: Array<OnDemandReportConfigModel> = [];
  public onDemandReportParameterConfig: Array<OnDemandReportParameterConfigModel> = [];
  public onDemandReportRequest: FormGroup;

  public onDemandReportFormSubmitted: boolean = false;

  public scheduledReports: Array<ScheduledReportConfigModel> = [];
  public scheduledReportFiles: Array<ScheduledReportFileModel> = [];
  private selectedScheduledReport: ScheduledReportConfigModel = null;

  fileUploadExcelFile: File;
  uploadedByteFile: any;
  private client_id = 0;

  constructor(public util: Utilities, private reportService: ReportService, private alertService: AlertService, private configService: ConfigService) {


  }

  ngOnInit() {
    this.configureOnDemandReportLayout();

    this.getReportConfig();
  }

  public get onDemandReportParametersFormGroup() {
    return this.onDemandReportRequest.controls['onDemandReportParameters'];
  }

  public incomingfile(event) {

    this.fileUploadExcelFile = event.target.files[0];

    let reader = new FileReader();

    if (this.fileUploadExcelFile) {
      reader.readAsDataURL(this.fileUploadExcelFile);
      reader.onload = () => {
        this.uploadedByteFile = (reader.result as string).split(',')[1];
      };
    }
  }

  public onDemandReportChange(e) {
    this.onDemandReportFormSubmitted = false;
    this.fileUploadExcelFile = null;

    var reportId = this.onDemandReportRequest.controls['onDemandReportGuid'].value;
    var report = this.onDemandReports.find(t => t.reportGuid == reportId);

    this.onDemandReportParameterConfig = report.parameterConfig;

    let mainGroup: any = {};
    let subGroup: any = {};

    mainGroup["onDemandReportGuid"] = new FormControl(reportId, Validators.required);

    report.parameterConfig.forEach(p => {
      if (p.isRequired == true)
        subGroup[p.name] = new FormControl(null, Validators.required);
      else
        subGroup[p.name] = new FormControl(null);
    });

    mainGroup["onDemandReportParameters"] = new FormGroup(subGroup);

    this.onDemandReportRequest = new FormGroup(mainGroup);
  }

  public onGenerateOnDemandReport({ value, valid }: { value: OnDemandReportRequestModel, valid: boolean }) {
    this.onDemandReportFormSubmitted = true;

    if (valid == false)
      return;

    this.generateOnDemandReport(value);
  }

  public onScheduledReportChange(e) {

    this.reportService.getScheduledReportFiles(e.value).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.scheduledReportFiles = res.result.reportFiles as Array<ScheduledReportFileModel>;

        this.selectedScheduledReport = this.scheduledReports.find(t => t.reportGuid == e.value);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public getReportFile(objectKey: string) {
    this.reportService.getReportFile({ objectKey: objectKey, fileName: this.selectedScheduledReport.fileName }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        window.open(res.result, '_blank');
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onDropDownChange(event, dropdownName) {
    var vaultReport = this.onDemandReports.find(r => r.reportName == 'Vault Inventory Report');

    if (dropdownName == 'clientid_in') {
      this.client_id = event.value;
      var parameterConfig = vaultReport.parameterConfig.find(t => t.name == 'brandid_in');

      if (parameterConfig != null) {
        this.getClientBrands(event.value, parameterConfig);
      }
    }
    else if (dropdownName == 'brandid_in') {
      var parameterConfig = vaultReport.parameterConfig.find(t => t.name == 'productid_in');

      if (parameterConfig != null) {
        this.getBrandProducts(event.value, parameterConfig);
      }
    }
  }

  private getClientBrands(id: number, parameterConfig: OnDemandReportParameterConfigModel) {

    if (id === null || id <= 0) {
      return;
    }

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        parameterConfig.dataSource = brands;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getBrandProducts(id: number, parameterConfig: OnDemandReportParameterConfigModel) {
    if (id === null || id <= 0) {
      return;
    }

    this.configService.getCurrentBrandProducts(id, this.client_id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        parameterConfig.dataSource = (products != null || products != undefined) ? (products.length > 1 ? products.filter(pds => pds.id != 0) : products) : products;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private configureOnDemandReportLayout(): void {
    let mainGroup: any = {};
    let subGroup: any = {};

    mainGroup["onDemandReportGuid"] = new FormControl(null, Validators.required);

    mainGroup["onDemandReportParameters"] = new FormGroup(subGroup);

    this.onDemandReportRequest = new FormGroup(mainGroup);
  }

  private getReportConfig() {
    this.reportService.getReportConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.reportConfig = res.result as ReportConfigModel;
        this.onDemandReports = this.reportConfig.onDemandReports;
        this.scheduledReports = this.reportConfig.scheduledReports;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private generateOnDemandReport(request: OnDemandReportRequestModel) {

    var parameters: Array<any> = [];

    for (var key in request.onDemandReportParameters) {
      if (key == "fileupload" && this.fileUploadExcelFile != null) {
        parameters.push({ name: key, fileObjectValue: { fileValue: this.uploadedByteFile, fileName: this.fileUploadExcelFile.name } });
      }
      else {
        if (typeof request.onDemandReportParameters[key] == 'string') {
          parameters.push({ name: key, value: request.onDemandReportParameters[key] });
        }
        else if (typeof request.onDemandReportParameters[key] == 'number') {
          parameters.push({ name: key, numberValue: request.onDemandReportParameters[key] });
        }
        else {
          parameters.push({ name: key, value: request.onDemandReportParameters[key] });
        }
      }
    }

    var updatedRequest = { onDemandReportGuid: request.onDemandReportGuid, onDemandReportParameters: parameters };

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.reportService.generateOnDemandReport(updatedRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        window.open(res.result.url, '_blank');
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }
}
