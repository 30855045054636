
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import { DxDataGridComponent } from "devextreme-angular";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'vendor-submission-failed-orders',
  templateUrl: './vendor-submission-failed-orders.component.html',
  styleUrls: ['./vendor-submission-failed-orders.component.css']
})
export class VendorSubmissoinFailedOrderComponent implements OnInit {

  public orderFailureStore: any = {};
  @ViewChild('orderFailureGrid', { static: true }) orderFailureGrid: DxDataGridComponent;

  public modalRef: BsModalRef;
  private refreshId: any;

  constructor(
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private sub: SubService,
    private gs: GlobalService) {

  }

  ngOnInit() {

    this.getVendorSubmssionFailedOrders();

    this.sub.orderEditedMethodCalled.subscribe(() => { this.orderFailureGrid.instance.refresh(); });

    this.refreshId = setInterval(() => {
      this.orderFailureGrid.instance.refresh();
    }, environment.ordersVendorSubmissionFailedGridRefreshTimeInMins * 60000);
  };

  public getVendorSubmssionFailedOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.orderFailureStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };
        return orderService.getVendorSubmissionFailedOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var orderFailuresResponse = response.result;
              var obj: any = {
                data: orderFailuresResponse.inLineClaimableOrderResults,
                totalCount: orderFailuresResponse.totalCount
              };

              util.vendorSubmissionFailedOrderCount = orderFailuresResponse.totalPendingActionOrdersCount;
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public claimOrderForReview(orderGuid: string) {
    this.orderService.claimOrder(orderGuid).pipe(
      finalize(() => {
        this.orderFailureGrid.instance.refresh();
      }))
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderReviewClaimSuccess, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);

    //this.router.navigate([RouteConstants.orderDetailRoute, orderGuid]);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.hasReviewComments) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onRefresh() {
    this.orderFailureGrid.instance.refresh();
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}
