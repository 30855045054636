<div class="tylie-accordion accordion" [formGroup]="group">
  <div class="tylie-accordion__panel accordion-item">
    
    <div class="d-flex">
      <div class="group-header col-md-2">
        <span class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (group.controls['containsGroupErrors'].value == true && triedToSubmitOrder === true) }">
            GROUP {{group.controls['sequenceId'].value}}&nbsp;{{group.controls['name'].value}}
            <a class="tylie-button tylie-button--xs tylie-button--link ms-3" outsideClick="true" role="button" placement="top" #gpnamepop="bs-popover" container="body" [popover]="groupNameTemplate" (click)="stopTogglePropogation($event)">EDIT</a>
        </span>
      </div>
      <div class="col-md-10">
        <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (group.controls['containsGroupErrors'].value == true && triedToSubmitOrder === true) }">
          <button type="button" class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" [attr.data-bs-target]="'#group' + group.controls['sequenceId'].value" aria-expanded="true" aria-controls="collapseOne">
            &nbsp;
          </button>
        </h2>
      </div>
    </div>

    <!-- <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (group.controls['containsGroupErrors'].value == true && triedToSubmitOrder === true) }">
      <button type="button" class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" [attr.data-bs-target]="'#group' + group.controls['sequenceId'].value" aria-expanded="true" aria-controls="collapseOne">
        GROUP {{group.controls['sequenceId'].value}}&nbsp;{{group.controls['name'].value}}
        <a class="tylie-button tylie-button--xs tylie-button--link ms-3" outsideClick="true" role="button" placement="top" #gpnamepop="bs-popover" container="body" [popover]="groupNameTemplate" (click)="stopTogglePropogation($event)">EDIT</a>
      </button>
    </h2> -->

    <div [attr.id]="'group' + group.controls['sequenceId'].value" class="tylie-accordion__content accordion-collapse">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content">
          <div class="tylie-form-group" [ngClass]="{error: group.controls['isRush'].errors && (triedToSubmitOrder) }">
            <label class="tylie-form-group__lbl mb-0 me-5">Service Level:</label>
            <div class="form-check">
              <label class="form-check-label me-5">
                <input class="form-check-input" type="radio" formControlName="isRush" value="false" (change)="onRushChanged()"><span>Standard</span>
              </label>
              <label class="form-check-label">
                <input type="radio" formControlName="isRush" value="true" class="form-check-input" (change)="onRushChanged()">
                <span *ngIf="group.controls['isRush'].value === 'true'">
                  <icon-rush color="#1e90ff"></icon-rush>
                  <span class="rush-text group-error-message">Rush</span>
                </span>
                <span *ngIf="group.controls['isRush'].value !== 'true'">Rush</span>
              </label>
            </div>
          </div>
          <p class="p-extra-small">Please select an option</p>
          <!-- One -->
          <div class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['spotData'].value == null || group.controls['spotData']?.value?.length == 0">
              <h4 class="tylie-panel__title">
                Assets
              </h4>
            </div>
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['spotData'].value != null && group.controls['spotData']?.value?.length > 0">
              <h4 class="tylie-panel__title">
                Assets
                <div class="d-flex">
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddMedia()">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                    Add assets
                  </button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddServicesToMedia()">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                    Apply Services To All Assets
                  </button>
                </div>
              </h4> 
            </div>

            <div class="tylie-panel__body" *ngIf="group.controls['spotData'].value == null || group.controls['spotData']?.value?.length == 0">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddMedia()">
                <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                Add assets
              </button>
            </div>
            <div class="tylie-panel__body" *ngIf="group.controls['spotData'].value != null && group.controls['spotData']?.value?.length > 0">
             
                <table class="tylie-table table table-bordered">
                  <thead class="tylie-table__head">
                    <tr>
                      <th width="11.98%">Thumbnail</th>
                      <th width="15.57%" [ngClass]="{'group-error-message': group.controls['containsGroupAdIdErrors'].value == true && (triedToSubmitOrder) }">
                        Ad-ID
                      </th>
                      <th width="19.57%" [ngClass]="{'group-error-message': group.controls['containsGroupTitleErrors'].value == true && (triedToSubmitOrder) }">Title</th>
                      <th width="8%">Status</th>
                      <th width="9%">Format</th>
                      <th width="4.47%">Dur.</th>
                      <th width="45%" [ngClass]="{'group-error-message': group.controls['containsGroupServiceErrors'].value == true && (triedToSubmitOrder) }">
                        Services Selected
                      </th>
                      <th width="4%"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body" *ngFor="let sp of group.controls['spotData'].value; let i = index">
                    <tr>
                      <td class="tylie-table__cell-img thumb-cell" [attr.rowspan]="isNonTextItemsExists(sp.serviceData) ? 2 : null">
                        <div class="tylie-img-container tylie-img-container--thumbnail thumb-play-parent">
                          <span *ngIf="sp.spotFile.isPlayable === true && sp.ppSpotFile == null" class="play" (click)="quickPlay(sp.spotFile.proxyUrl, sp.spotFile.title, sp.spotFile.thumbnailUrl, sp.spotFile.format)"></span>
                          <span *ngIf="sp.ppSpotFile != null" class="creating-new-media-banner"></span>
                          <img class="tylie-img" src="{{sp.spotFile.thumbnailUrl}}" />
                        </div>
                      </td>
                      <td [ngClass]="{'group-error-border': (sp.containsAdIdErrors == true) && (triedToSubmitOrder) }" style="word-break:break-all;">
                        <div class="blue-text" *ngIf="sp.ppSpotFile == null" [ngClass]="{'group-error-message': (sp.containsSpotErrors == true) && (triedToSubmitOrder) }"
                            containerClass="customPopover" placement="top" [popover]="sp.errorMsgSpot" triggers="mouseenter:mouseleave" (click)="showDetail(sp.spotFile.spotFileGuid)">
                          {{sp.spotFile.adId}}
                        </div>

                        <div *ngIf="sp.ppSpotFile != null">
                          <div [ngClass]="{'group-error-message': (sp.containsPpSpotAdIdErrors == true) && (triedToSubmitOrder) }"
                              containerClass="customPopover" placement="top" [popover]="sp.errorMsgPpSpotAdId" triggers="mouseenter:mouseleave">{{sp.ppSpotFile.adId}}</div>
                          <div class="prev_version gray1">
                            <div class="p-small">Selected Asset:</div>
                            <div class="p-small blue-text">
                              <div [ngClass]="{'group-error-message': (sp.containsSpotErrors == true) && (triedToSubmitOrder) }" (click)="showDetail(sp.spotFile.spotFileGuid)"
                                  containerClass="customPopover" placement="top" [popover]="sp.errorMsgSpot" triggers="mouseenter:mouseleave">
                                {{sp.spotFile.adId}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td [ngClass]="{'group-error-border': (sp.containsTitleErrors == true) && (triedToSubmitOrder) }" style="word-break:break-all;">
                        <div *ngIf="sp.ppSpotFile == null">
                          {{sp.spotFile.title}}
                        </div>
                        <div *ngIf="sp.ppSpotFile != null">
                          <div [ngClass]="{'group-error-message': (sp.containsPpSpotTitleErrors == true) && (triedToSubmitOrder) }"
                              containerClass="customPopover" placement="top" [popover]="sp.errorMsgPpSpotTitle" triggers="mouseenter:mouseleave">
                            {{sp.ppSpotFile.title}}
                          </div>
                          <div class="prev_version gray1">
                            <div class="p-small">Selected Asset:</div>
                            <div class="p-small">{{sp.spotFile.title}}</div>
                          </div>
                        </div>
                      </td>
                      <td>{{sp.spotFile.status}}</td>
                      <td class="format-cell">
                        <ng-container *ngFor="let iconSrc of sp.spotFile.formatClasses">
                          <span [class]="iconSrc"></span>
                        </ng-container>
                      </td>
                      <td>{{sp.spotFile.length}}</td>
                      <td class="service-selected" [ngClass]="{'group-error-border': (sp.serviceData.length === 0 || sp.containsSpotServiceDataErrors == true) && (triedToSubmitOrder) }">
                        <div *ngIf="sp.serviceData.length > 0">
                          <div *ngIf="sp.isDeliveryExists === true">
                            <p class="p-small">Digital Delivery</p>
                            <div *ngFor="let serv of sp.serviceData;">
                              <div *ngIf="serv.service.category.toLowerCase() === 'digital delivery'">
                                <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                  *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder)">
                                  {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                                </p>
                                <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder))">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="sp.isPpExists === true">
                            <p class="p-small">Post Production</p>
                            <div *ngFor="let serv of sp.serviceData;">
                              <div *ngIf="serv.service.category.toLowerCase() === 'post production'">
                                <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                  *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder)">
                                  {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                                </p>
                                <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder))">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="sp.isAddlExists === true">
                            <p class="p-small">Additional</p>
                            <div *ngFor="let serv of sp.serviceData;">
                              <div *ngIf="serv.service.category.toLowerCase() === 'additional'">
                                <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                  *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder)">
                                  {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                                </p>
                                <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder))">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                              </div>
                            </div>
                          </div>
                          <a class="tylie-lnk" (click)="onEditServicesOfMedia(sp.spotFile.spotFileGuid, sp.groupSpotId)">Add / Edit Services</a>
                        </div>
                        <div *ngIf="sp.serviceData.length === 0">
                          <button class="new-default-btn add-services x-small-btn add-group-btn" type="button" (click)="onAddServicesToMedia(sp.spotFile.spotFileGuid, sp.groupSpotId)">
                            <label>Add Services</label>
                          </button>
                          <!--<p class="p-small" *ngIf="triedToSubmitOrder == true" style="color: #ec1c23">No Services added</p>-->
                        </div>
                      </td>
                      <td align="center">
                        <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onSpotDelete(sp.spotFile.spotFileGuid, sp.groupSpotId)">
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="isNonTextItemsExists(sp.serviceData)">
                      <td colspan="7" class="thumb-cell">
                        <table class="table services-row">
                          <thead>
                            <tr>
                              <th width="20%">Service</th>
                              <th width="55%">File</th>
                              <th width="15%">Type</th>
                              <th width="10%">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let serv of sp.serviceData">
                              <ng-container *ngFor="let mt of serv.serviceMetadata">
                                <tr *ngIf="isNonTextItem(mt)">
                                  <td>{{serv.service.name}}</td>
                                  <td *ngIf="mt.fileValue == null">{{mt.display}}</td>
                                  <td *ngIf="mt.fileValue != null"><a class="quickLink" (click)="onDownloadFile(mt.fileValue.orderFileGuid, mt.fileValue.fileName);">{{mt.display}}</a></td>
                                  <td>{{mt.fileValue != null ? mt.fileValue.fileType: 'Text'}}</td>
                                  <td>
                                    <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceMetadataDelete(sp.spotFileGuid, serv.serviceId, mt.metadataKey, mt.fileValue)">
                                      <icon-trash-can color="#1e90ff"></icon-trash-can>
                                    </a>
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-container *ngIf="triedToSubmitOrder == true">
                  <p class="p-small" *ngFor="let er of serviceErrors" style="color: #ec1c23">{{er}}</p>
                </ng-container>
            </div>
          </div>

          <!-- two -->

          <div class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['destinations'].value.length > 0 || group.controls['ftpDestinations'].value.length > 0 || group.controls['shareLinks'].value.length > 0">
              <h4 class="tylie-panel__title">
                Destinations
                <div class="d-flex">
                 
                  
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddDestination()" containerClass="customPopover" placement="top" [popover]="'Search from list, paste, or upload destinations.'" triggers="mouseenter:mouseleave">
                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                    Destinations
                  </button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddFtpDestination()">
                    <span class="tylie-button__icon"><icon-upload color="#1e90ff"></icon-upload></span>
                    Ftp Upload
                  </button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddShareLinks()">
                    <span class="tylie-button__icon">
                      <svg height="20px" width="20px" enable-background="new 0 0 300 300" version="1.1" viewBox="0 0 300 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#1e90ff"d="m150 0c-82.839 0-150 67.161-150 150s67.156 150 150 150 150-67.163 150-150-67.164-150-150-150zm-16.682 150c0 3.133-0.571 6.111-1.569 8.901l41.868 20.142c4.819-5.275 11.731-8.595 19.436-8.595 14.553 0 26.353 11.796 26.353 26.348 0 14.555-11.803 26.356-26.359 26.356-14.553 0-26.359-11.801-26.359-26.356 0-1.395 0.145-2.757 0.353-4.09l-44.568-21.436c-4.357 3.188-9.71 5.089-15.52 5.089-14.555 0-26.356-11.796-26.356-26.361 0-14.55 11.801-26.348 26.356-26.348 5.81 0 11.165 1.901 15.523 5.086l44.571-21.431c-0.21-1.336-0.353-2.692-0.353-4.09 0-14.558 11.803-26.356 26.359-26.356 14.553 0 26.356 11.798 26.356 26.356 0 14.555-11.803 26.356-26.356 26.356-7.7 0-14.617-3.328-19.436-8.598l-41.868 20.134c0.999 2.786 1.569 5.771 1.569 8.893z" />
                      </svg>
                      <icon-share ></icon-share>
                    </span>
                    Share Links
                  </button>
                </div>
              </h4>
              
            </div>
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['destinations'].value.length == 0 && group.controls['ftpDestinations'].value.length == 0 && group.controls['shareLinks'].value.length == 0">
              <h4 class="tylie-panel__title">
                Destinations
              </h4>
            </div>
          
          
            <div class="tylie-panel__body" *ngIf="group.controls['destinations'].value.length == 0 && group.controls['ftpDestinations'].value.length == 0 && group.controls['shareLinks'].value.length == 0">
              
             
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddDestination()" containerClass="customPopover" placement="top" [popover]="'Search from list, paste, or upload destinations.'" triggers="mouseenter:mouseleave">
                <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                Destinations
              </button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddFtpDestination()">
                <span class="tylie-button__icon"><icon-upload color="#1e90ff"></icon-upload></span>
                Ftp Upload
              </button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddShareLinks()">
                <span class="tylie-button__icon">
                  <svg height="20px" width="20px" enable-background="new 0 0 300 300" version="1.1" viewBox="0 0 300 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#1e90ff"d="m150 0c-82.839 0-150 67.161-150 150s67.156 150 150 150 150-67.163 150-150-67.164-150-150-150zm-16.682 150c0 3.133-0.571 6.111-1.569 8.901l41.868 20.142c4.819-5.275 11.731-8.595 19.436-8.595 14.553 0 26.353 11.796 26.353 26.348 0 14.555-11.803 26.356-26.359 26.356-14.553 0-26.359-11.801-26.359-26.356 0-1.395 0.145-2.757 0.353-4.09l-44.568-21.436c-4.357 3.188-9.71 5.089-15.52 5.089-14.555 0-26.356-11.796-26.356-26.361 0-14.55 11.801-26.348 26.356-26.348 5.81 0 11.165 1.901 15.523 5.086l44.571-21.431c-0.21-1.336-0.353-2.692-0.353-4.09 0-14.558 11.803-26.356 26.359-26.356 14.553 0 26.356 11.798 26.356 26.356 0 14.555-11.803 26.356-26.356 26.356-7.7 0-14.617-3.328-19.436-8.598l-41.868 20.134c0.999 2.786 1.569 5.771 1.569 8.893z" />
                  </svg>
                  <icon-share ></icon-share>
                </span>
                Share Links
              </button>
              
              <!-- <span class="order-header">
                <span class="order-header-left" (click)="onAddDestination()" containerClass="customPopover" placement="top" [popover]="'Search from list, paste, or upload destinations.'" triggers="mouseenter:mouseleave">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
                  
                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0ZM58,43.49H43.42V58H35.61V43.49H21.08V35.68H35.61V21.15h7.81V35.68H58Z" /></g></g>
                  </svg>
                  <label> Destinations </label>
                </span>
                <span class="order-header-left" (click)="onAddFtpDestination()">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="arrow-circle-up" class="svg-inline--fa fa-arrow-circle-up fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z" /></svg>
                  <label>
                    Ftp Upload
                  </label>
                </span>
                <span class="order-header-left" (click)="onAddShareLinks()">
                  <svg enable-background="new 0 0 300 300" version="1.1" viewBox="0 0 300 300" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                  <path d="m150 0c-82.839 0-150 67.161-150 150s67.156 150 150 150 150-67.163 150-150-67.164-150-150-150zm-16.682 150c0 3.133-0.571 6.111-1.569 8.901l41.868 20.142c4.819-5.275 11.731-8.595 19.436-8.595 14.553 0 26.353 11.796 26.353 26.348 0 14.555-11.803 26.356-26.359 26.356-14.553 0-26.359-11.801-26.359-26.356 0-1.395 0.145-2.757 0.353-4.09l-44.568-21.436c-4.357 3.188-9.71 5.089-15.52 5.089-14.555 0-26.356-11.796-26.356-26.361 0-14.55 11.801-26.348 26.356-26.348 5.81 0 11.165 1.901 15.523 5.086l44.571-21.431c-0.21-1.336-0.353-2.692-0.353-4.09 0-14.558 11.803-26.356 26.359-26.356 14.553 0 26.356 11.798 26.356 26.356 0 14.555-11.803 26.356-26.356 26.356-7.7 0-14.617-3.328-19.436-8.598l-41.868 20.134c0.999 2.786 1.569 5.771 1.569 8.893z" />
                  </svg>
                  <label>
                    Share Links
                  </label>
                </span>
              </span> -->
              <ng-container *ngIf="triedToSubmitOrder == true">
                <p class="p-small" *ngFor="let er of destinationErrors" style="color: #ec1c23">{{er}}</p>
              </ng-container>
            </div>
            <div class="tylie-panel__body" *ngIf="group.controls['destinations'].value.length > 0 || group.controls['ftpDestinations'].value.length > 0 || group.controls['shareLinks'].value.length > 0">
              <table class="tylie-table table table-bordered">
                <thead class="tylie-table__head">
                  <tr>
                    <th style="width:50%;" [ngClass]="{'group-error-message': group.controls['containsGroupDestinationErrors'].value == true && (triedToSubmitOrder) }">Name</th>
                    <th style="width:15%;">Accepts DVS</th>
                    <th style="width:15%;">Type</th>
                    <th style="width:15%;">Category</th>
                    <th style="width:5%;"></th>
                  </tr>
                </thead>
                <tbody class="tylie-table__body">
                  <tr *ngFor="let des of group.controls['destinations'].value; let i = index">
                    <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSubmitOrder) }">
                      <div [ngClass]="{'group-error-message': (des.containsDestinationError == true) && (triedToSubmitOrder) }"
                          placement="top" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">
                        {{des.code}}-{{des.name}}
                        <div class="service-selected" *ngIf="des.isAdditionalInfoRequired == true && anySpotDeliveryExists == true">
                          <a class="tylie-lnk" (click)="onAddEditDestinationInfo(des.code)">Add / Edit Addl Info</a>
                        </div>
                      </div>
                    </td>
                    <td style="width:15%;">{{des.acceptsDescriptiveVideo}}</td>
                    <td style="width:15%;">{{des.destinationType}}</td>
                    <th style="width:15%;">Destination</th>
                    <td align="center">
                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDestinationDelete(des.code)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </td>
                  </tr>
                  <tr *ngFor="let des of group.controls['ftpDestinations'].value; let i = index">
                    <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSubmitOrder) }">
                      <div [ngClass]="{'group-error-message': (des.containsDestinationError == true) && (triedToSubmitOrder) }"
                          placement="top" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">{{des.name}}</div>
                    </td>
                    <td style="width:15%;"></td>
                    <td style="width:15%;"></td>
                    <th style="width:15%;">FTP</th>
                    <td align="center">
                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onFtpDestinationDelete(des.id)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </td>
                  </tr>
                  <tr *ngFor="let des of group.controls['shareLinks'].value; let i = index">
                    <td style="width:50%;" [ngClass]="{'group-error-border': (des.containsDestinationError == true) && (triedToSubmitOrder) }">
                      <div [ngClass]="{'group-error-message': (des.containsDestinationError == true) && (triedToSubmitOrder) }"
                          placement="top" [popover]="des.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover">{{des.groupName}}{{des.isPasswordProtected === true ? ' (Password Protected)' : ''}}</div>
                    </td>
                    <td style="width:15%;"></td>
                    <td style="width:15%;"></td>
                    <th style="width:15%;">Share Links</th>
                    <td align="center">
                      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onShareLinksDelete(des.groupGuid)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-container *ngIf="triedToSubmitOrder == true">
                <p class="p-small" style="color: #ec1c23" *ngFor="let er of destinationErrors">{{er}}</p>
              </ng-container>
            </div>

          </div>
          

          <!-- three -->

          <div class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['serviceData']?.value?.length == 0">
              <h4 class="tylie-panel__title">Traffic Express</h4>
            </div>
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['serviceData']?.value?.length > 0">
              <h4 class="tylie-panel__title">
                Traffic Express
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddEditGroupServices(true)">
                  <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                  Add/Edit Services
                </button>
              </h4>
            </div>
            <div class="tylie-panel__body" *ngIf="group.controls['serviceData']?.value?.length == 0">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddEditGroupServices(false)">
                <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                Add Services
              </button>
            </div>
  
            <div class="tylie-panel__body" *ngIf="group.controls['serviceData'].value != null && group.controls['serviceData']?.value?.length > 0">
                <table class="tylie-table table table-bordered">
                  <thead class="tylie-table__head">
                    <tr>
                      <th width="90%" [ngClass]="{'group-error-message': (group.controls['containsGroupServiceLevelErrors'].value == true && triedToSubmitOrder === true) }">
                        Services Selected
                      </th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <tr *ngFor="let serv of group.controls['serviceData'].value; let i = index">
                      <td class="service-selected" [ngClass]="{'group-error-border': (serv.containsServiceMetadataDataErrors == true && triedToSubmitOrder) }">
                        <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                          *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder)">
                          {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                        </p>
                        <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && triedToSubmitOrder)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                      </td>
                      <td align="center">
                        
                        <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onGroupServiceDelete(serv.service.id)">
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                    </tr>
                    <tr *ngIf="isNonTextItemsExists(group.controls['serviceData'].value)">
                      <td colspan="2" class="thumb-cell">
                        <table class="table services-row">
                          <thead>
                            <tr>
                              <th width="20%">Service</th>
                              <th width="55%">File</th>
                              <th width="15%">Type</th>
                              <th width="10%">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let serv of group.controls['serviceData'].value">
                              <ng-container *ngFor="let mt of serv.serviceMetadata">
                                <tr *ngIf="isNonTextItem(mt)">
                                  <td>{{serv.service.name}}</td>
                                  <td *ngIf="mt.fileValue == null">{{mt.display}}</td>
                                  <td *ngIf="mt.fileValue != null"><a class="quickLink" (click)="onDownloadFile(mt.fileValue.orderFileGuid, mt.fileValue.fileName);">{{mt.display}}</a></td>
                                  <td>{{mt.fileValue != null ? mt.fileValue.fileType: 'Text'}}</td>
                                  <td>
                                    <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onGroupServiceMetadataDelete(serv.serviceId, mt.metadataKey, mt.fileValue)">
                                      <icon-trash-can color="#1e90ff"></icon-trash-can>
                                    </a>
                                    <!--  -->
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
          
          

           <!-- Four -->

          <div class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['transcodeRequest']?.value == null">
              <h4 class="tylie-panel__title">Transcodes</h4>
            </div>
            <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="group.controls['transcodeRequest']?.value != null">
              <h4 class="tylie-panel__title">
                Transcodes
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="gotoTranscodeFiles(true)" *ngIf="(group.controls['transcodeRequest'].value.spotFilesData != null && getGroupVideoSpotsCount() > group.controls['transcodeRequest'].value.spotFilesData.length)">
                  <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                  Add Transcodes
                </button>
              </h4>
            </div>
         

          
            <div class="tylie-panel__body" *ngIf="group.controls['transcodeRequest']?.value == null">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="gotoTranscodeFiles()">
                <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                  Add Transcodes
              </button>
            </div>


            <div class="tylie-panel__body" *ngIf="group.controls['transcodeRequest']?.value != null && group.controls['transcodeRequest']?.value?.spotFilesData != null && group.controls['transcodeRequest']?.value?.spotFilesData?.length > 0">
              <table class="tylie-table table table-bordered">
                <thead class="tylie-table__head">
                  <tr>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody class="tylie-table__body">
                  <tr>
                    <td colspan="2" class="thumb-cell">
                      <table class="tylie-table table services-row table-bordered">
                        <thead class="tylie-table__head">
                          <tr>
                            <th width="20%">Ad ID</th>
                            <th width="40%">Title</th>
                            <th width="35%">OLV Profiles</th>
                            <!--<th width="15%">File Naming</th>-->
                            <th width="5%"></th>
                          </tr>
                        </thead>
                        <tbody class="tylie-table__body">
                          <ng-container *ngFor="let mt of group.controls['transcodeRequest'].value.spotFilesData">
                            <tr>
                              <td>{{mt.adId}}</td>
                              <td>{{mt.title}}</td>
                              <!--<td *ngIf="group.controls['transcodeRequest'].value.spotFileTranscodesData != null">{{getOLVProfilesForSpot(mt.spotFileGuid)}}</td>-->
                              <td>
                                <ng-container *ngFor="let spec of getOLVProfilesForSpot(mt.spotFileGuid)">
                                  <p class="p-extra-small black">
                                    {{spec}}
                                    <ng-container *ngIf="getFileNamingForSpot(mt.spotFileGuid, spec) as fileName">
                                      ({{fileName}})
                                    </ng-container>
                                  </p>
                                </ng-container>
                              </td>
                        <td>
                          <div class="d-flex justify-content-center">
                         
                            <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="editGroupSpotMediaTranscode(mt.spotFileGuid)">
                              <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
                            </a>
                            <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onTranscodeAssetDelete(mt.spotFileGuid)">
                              <icon-trash-can color="#1e90ff"></icon-trash-can>
                            </a>
                          </div>
                        </td>
                  </tr>
                  </ng-container>
                </tbody>
              </table>
              </td>
              </tr>
              </tbody>
              </table>
            </div>
            <div *ngIf="groupErrors != null && groupErrors.length > 0">
              <ng-container *ngFor="let err of groupErrors">
                <p class="p-small" style="color: #ec1c23">
                  {{err}}
                </p>
              </ng-container>
            </div>
            
            <div class="d-flex justify-content-end">
              <button  type="button" class="tylie-button tylie-button--link tylie-button--xs tylie-button--icon" *ngIf="totalGroups > 1 || additionalMetadata.allowSpecialOptions == true"
                    outsideClick="true" role="button" placement="left" #gpdeletepop="bs-popover" container="body" [popover]="deleteGroupTemplate">
                <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>
                Delete Group
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #deleteGroupTemplate>
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this Group?
    </p>
    <i (click)="onHideDeleteGroupPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onGroupDeleteConfirm()">Yes</button> -->


  <button class="popover__close" (click)="onHideDeleteGroupPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this Group?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onGroupDeleteConfirm()">yes</button>
</ng-template>

<ng-template #groupNameTemplate>
  <!-- <div class='p-normal'>
    <div>
      <p class='black'>Group Name</p>
      <input type="text" [(ngModel)]="groupName" />
    </div>
    <i (click)="onHideGroupNamePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onGroupNameEditConfirm()">Rename</button>
 -->

  <button class="popover__close" (click)="onHideGroupNamePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <div class="tylie-form-group form-group">
    <label class='tylie-form-group__lbl'>Group Name</label>
    <input class="form-control tylie-text" type="text" [(ngModel)]="groupName" />
  </div>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onGroupNameEditConfirm()">Rename</button>

</ng-template>
