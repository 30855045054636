<header class="tylie-header">
  <h3 class="tylie-header__title">Reports</h3>
</header>

<div class="tylie-body inner-content">
  <div class="tylie-accordion accordion" id="reports-section">
    <div class="tylie-accordion__panel accordion-item" id="onDemandReportsPanel">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#onDemandReportsCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          ON DEMAND PRE-DEFINED REPORTS
        </button>
      </h2>

      <div id="onDemandReportsCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body m-4">
          <form [formGroup]="onDemandReportRequest" #odf="ngForm" (ngSubmit)="onGenerateOnDemandReport(onDemandReportRequest)">
            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: onDemandReportRequest.controls['onDemandReportGuid'].errors && onDemandReportFormSubmitted == true}">
                  <label class="tylie-form-group__lbl" for="invTo">Select Report<span class="required">*</span></label>
                  <dx-select-box [dataSource]="onDemandReports"
                                 displayExpr="reportName"
                                 valueExpr="reportGuid"
                                 class="tylie-select form-control"
                                 id="onDemandReport"
                                 name="onDemandReport"
                                 formControlName="onDemandReportGuid"
                                 [acceptCustomValue]="false"
                                 (onValueChanged)="onDemandReportChange($event)"
                                 placeholder="">
                  </dx-select-box>
                  <p class="p-extra-small">Please select a Report</p>
                </div>
              </div>
            </div>
            <div class="row" [formGroup]="onDemandReportParametersFormGroup">
              <ng-container *ngFor="let prm of onDemandReportParameterConfig" [ngSwitch]="prm.controlType.toLowerCase()">
                <div class="col-3" *ngSwitchCase="'date'">
                  <div class="tylie-form-group form-group" [ngClass]="{error: onDemandReportParametersFormGroup.controls[prm.name].errors && onDemandReportFormSubmitted == true}">
                    <label class="tylie-form-group__lbl" [for]="prm.name">{{prm.displayName}}<span class="required">*</span></label>
                    <div class="input-group date" style="width:100%">
                      <dx-date-box width="100%" type="date" [id]="prm.name" [formControlName]="prm.name" class="tylie-datepicker form-control" [showClearButton]="true"></dx-date-box>
                    </div>
                    <p class="p-extra-small">Please select a date</p>
                  </div>
                </div>
                <div class="col-3" *ngSwitchCase="'text'">
                  <div class="tylie-form-group form-group" [ngClass]="{error: onDemandReportParametersFormGroup.controls[prm.name].errors && onDemandReportFormSubmitted == true}">
                    <label [for]="prm.name" class="tylie-form-group__lbl">{{prm.displayName}}<span class="required">*</span></label>
                    <div class="input-group date" style="width:100%">
                      <input [formControlName]="prm.name"  type="text" id="prm.name" class="tylie-text form-control " />
                    </div>
                    <p class="p-extra-small">Please enter valid value</p>
                  </div>
                </div>

                <div class="col-3" *ngSwitchCase="'dropdown'">
                  <div class="tylie-form-group form-group" [ngClass]="{error: onDemandReportParametersFormGroup.controls[prm.name].errors && onDemandReportFormSubmitted == true}">
                    <label class="tylie-form-group__lbl" [for]="prm.name">{{prm.displayName}}<span class="required">*</span></label>
                    <dx-select-box [dataSource]="prm.dataSource"
                                   displayExpr="name"
                                   valueExpr="id"
                                   class="tylie-select form-control"
                                   [id]="prm.name"
                                   [formControlName]="prm.name"
                                   [searchEnabled]="true"
                                   [acceptCustomValue]="false"
                                   placeholder="Select a Value"
                                  (onValueChanged)="onDropDownChange($event, prm.name)">
                    </dx-select-box>
                    <p class="p-extra-small">Please select a {{prm.displayName}}</p>
                  </div>
                </div>

                <div class="col-6" *ngSwitchCase="'fileupload'">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" [for]="prm.name">{{prm.displayName}}<span class="required">*</span></label>
                    
                    <div class="tylie-upload input-group">
                      <label class="input-group-btn">
                        <span class="tylie-upload__action tylie-button tylie-button--sm">
                          Browse<input type="file" style="display: none;" #excelFileInput (change)="incomingfile($event)" required accept=".xlsx,.xls" [id]="prm.name" [formControlName]="prm.name">
                        </span>
                      </label>
                      <input type="text" class="tylie-text form-control" readonly="" value="{{fileUploadExcelFile != null ? fileUploadExcelFile.name : ''}}">
                    </div>
                   
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit"><span class="tylie-button__icon"><icon-quick-order></icon-quick-order></span><span class="tylie-button__text">Generate Report</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item" id="scheduledReportsPanel">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#scheduledReportsCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          SCHEDULED PRE-DEFINED REPORTS
        </button>
      </h2>
      <div id="scheduledReportsCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body m-4">
          <div class="row">
            <div class="col-md-6">
              <div class="tyie-form-group form-group">
                <label class="tylie-form-group__lbl" for="invTo">Select Report<span class="required">*</span></label>
                <dx-select-box [dataSource]="scheduledReports"
                               displayExpr="reportName"
                               valueExpr="reportGuid"
                               class="tylie-select form-control"
                               id="scheduledReport"
                               name="scheduledReport"
                               [acceptCustomValue]="false"
                               (onValueChanged)="onScheduledReportChange($event)"
                               placeholder="">
                </dx-select-box>
                <p class="p-extra-small">Please select a Report</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" *ngIf="scheduledReportFiles && scheduledReportFiles.length > 0">
              <div *ngFor="let rep of scheduledReportFiles">
                <i class='fa fa-file-excel-o'></i> &nbsp;
                <span class="blue-text p-extra-small" style="cursor:pointer;margin: 6px 0;" (click)="getReportFile(rep.objectKey)">
                  {{rep.reportDisplay}}
                </span>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
