import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { UserChangePassword } from "../../models/user/user-changepassword.model";
import { NotificationPreferences } from "../../models/user/notification-preferences.model";
import { UserEmailDistributionGroups } from "../../models/user/user-email-distribution-group.model";
import { UserPersonalInformation } from '../../models/user/user-personal-information.model';

@Injectable()
export class AccountSettingsService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint){ }

  public getTimeZone() {
    return this.http.get(this.apiendPoint.getTimeZones());
  }

  public saveUserPersonalInformation(userPersonalInformation: UserPersonalInformation) {
    return this.http.put(this.apiendPoint.saveUserPersonalInformationEndpoint(), userPersonalInformation);
  }

  public updateUserPasswordEndpoint(userChangePassword:UserChangePassword) {
    return this.http.put(this.apiendPoint.updateUserPasswordEndpoint(), userChangePassword);
  }

  public updateUserPasswordAfterExpiry(userChangePassword: UserChangePassword) {
    return this.http.put(this.apiendPoint.updateUserPasswordAfterExpiryEndpoint(), userChangePassword);
  }

  public updateUserAvatarEndpoint(avatar:string) {
    return this.http.put(this.apiendPoint.updateUserAvatarEndpoint(), JSON.stringify(avatar), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public notificationPreferencesEndpoint() {
    return this.http.get(this.apiendPoint.notificationPreferencesEndpoint());
  }

  public currentUserNotificaionPreferencesEndpoint() {
    return this.http.get(this.apiendPoint.currentUserNotificaionPreferencesEndpoint());
  }

  public saveUserNotificaionPreferencesEndpoint(notificationPreference: NotificationPreferences) {
    return this.http.put(this.apiendPoint.saveUserNotificaionPreferencesEndpoint(), notificationPreference, { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public removeNotificationPreferenceEndpoint(notificationPreference: NotificationPreferences) {
    return this.http.put(this.apiendPoint.removeNotificationPreferenceEndpoint(), notificationPreference, { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public canManagePwd(userProfileGuid: string) {
    return this.http.get(this.apiendPoint.canManagePwdEndpoint() + "/" + userProfileGuid);
  }

  // remove passing userDistributionGroupName as path param
  public createUserDistributionGroupEndpoint(distroObj: UserEmailDistributionGroups) {
    return this.http.post(this.apiendPoint.createUserDistributionGroupEndpoint(), distroObj, { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public userEmailDistributionGroupsEndpoint() {
    return this.http.get(this.apiendPoint.userEmailDistributionGroupsEndpoint());
  }

  public searchUserEmailDistributionGroupsEndpoint(request: any) {
    return this.http.post(this.apiendPoint.searchEmailDistributionGroupsEndpoint(), request);
  }

  public userGroupEmailListEndpoint(userDistroGuid: string) {
    return this.http.get(this.apiendPoint.userGroupEmailListEndpoint() + "/" + userDistroGuid);
  }

  public updateUserDistributionEmailsFromGroupEndpoint(groupGuid: string, saveDistroObj: UserEmailDistributionGroups) {
    return this.http.put(this.apiendPoint.updateUserDistributionEmailsFromGroupEndpoint() + "/" + groupGuid, saveDistroObj, { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public removeDistributionGroupEndpoint(userDistroGuid: string) {
    return this.http.delete(this.apiendPoint.removeDistributionGroupEndpoint() + "/" + userDistroGuid);
  }
}


