import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-flag',
  templateUrl: './icon-flag.component.html',
  styleUrls: ['./icon-flag.component.css']
})
export class IconFlagComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
