import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'icon-download',
  templateUrl: './icon-download.component.html',
  styleUrls: ['./icon-download.component.css']
})
export class IconDownloadComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#f5f5f5';
  constructor() { }

  ngOnInit() {
  }

}
