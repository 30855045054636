import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  AssetType,
  mediaFormatTypeEnum,
} from "../../../configurations/enums/enums";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Utilities } from "../../../services/core/utilities";
import {
  AlertService,
  MessageSeverity,
} from "../../../services/core/alert.service";
import { UploadAssetFormModel } from "../../../models/upload/upload-asset-form.model";
import {
  GroupModel,
  LocationModel,
  TaggerAssetModel,
  UploadTaggerAssetRequestModel,
  UpdateTaggerAssetRequestModel,
  CreateTaggerAssetRequestModel,
  TaggerBaseCreative,
  TaggerCard,
  TaggerCardVoiceOver,
} from "../../../models/tagger/tagger.model";
import { TaggerService } from "../../../services/tagger/tagger.service";
import * as moment from "moment";
import { Subject } from "rxjs";
import { Constants } from "../../../configurations/constants";

@Component({
  selector: "app-admin-asset-upload-pop-up",
  templateUrl: "./admin-asset-upload-pop-up.component.html",
  styleUrls: ["./admin-asset-upload-pop-up.component.css"],
})
export class AdminAssetUploadPopUpComponent implements OnInit {
  @Input() uploadedAssetFiles: Array<UploadAssetFormModel> = [];
  @Input() unSupportedFiles: Array<UploadAssetFormModel> = [];
  @Input() isEditAsset: boolean = false;

  public isFormSubmitted: boolean = false;
  public assetRequestFormGroup: FormGroup;
  public assetTypes: any[];
  public groups: GroupModel[];
  public locations: LocationModel[];
  public allLocations: LocationModel[];
  public errorMsgs: string = "";
  public taggerAssets: TaggerAssetModel[];
  uploadConfig: UploadAssetFormModel = null;
  public mediaFormatTypeEnum = mediaFormatTypeEnum;
  public groupNames: string[] = [];

  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    public taggerService: TaggerService,
    public util: Utilities
  ) {
    this.assetRequestFormGroup = new FormGroup({});
  }

  ngOnInit(): void {
    this.assetTypes = Object.keys(AssetType).map((key) => ({
      id: AssetType[key],
      name: AssetType[key],
    }));
    this.onClose = new Subject();

    this.getGroups();
    this.getLocations();

    this.uploadedAssetFiles.forEach((assetFile, index) => {
      console.log(index);
      const group = new FormGroup({
        assetGuid: new FormControl(assetFile.assetGuid),
        fileName: new FormControl(assetFile.fileName),
        assetType: new FormControl(assetFile.assetType, Validators.required),
        assetTitle: new FormControl(
          assetFile.assetTitleName,
          Validators.required
        ),
        startDate: new FormControl(
          assetFile.availableStartDate,
          Validators.required
        ),
        endDate: new FormControl(
          assetFile.availableEndDate,
          Validators.required
        ),
        groups: new FormControl(assetFile.availableGroups),
        locations: new FormControl(assetFile.availableLocations),
        isFeatured: new FormControl(assetFile.isFeatured || false),
        isDuplicate: new FormControl(false),
        taggableDurationInSecs: new FormControl(
          assetFile.taggableDurationInSecs
        ),
      });

      //group.get('groups').setValidators(Validators.required);

      this.assetRequestFormGroup.addControl("assetFormGroup" + index, group);
    });

    this.groupNames = Object.keys(this.assetRequestFormGroup.controls);
  }

  public displayFileName(groupName: string) {
    return this.assetRequestFormGroup.get(groupName).get("fileName").value;
  }

  public getGroups() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getGroups().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.groups = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getLocations() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getLocations().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.locations = res.result;
          this.allLocations = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getUploadAssetRequestForm() {
    var uploadAssets: CreateTaggerAssetRequestModel = {};
    const baseCreativeModels: TaggerBaseCreative[] = [];
    const offerCardModels: TaggerCard[] = [];
    const endCardModels: TaggerCard[] = [];
    const voiceOverModels: TaggerCardVoiceOver[] = [];

    this.groupNames.forEach((groupName, index) => {
      const formGroup = this.assetRequestFormGroup.get(groupName).value;
      if (formGroup.assetType == AssetType.BaseCreative) {
        const model: TaggerBaseCreative = {
          assetGuid: formGroup.assetGuid,
          assetTitle: formGroup.assetTitle,
          startDate: formGroup.startDate,
          endDate: formGroup.endDate,
          assetType: formGroup.assetType,
          groups: formGroup.groups,
          locations: formGroup.locations,
          originalFileName: formGroup.fileName,
          isPlaceHolder: true,
          isFeatured: formGroup.isFeatured,
          taggableDurationInSecs: formGroup.taggableDurationInSecs,
        };
        baseCreativeModels.push(model);
      } else if (formGroup.assetType == AssetType.OfferTag) {
        const model: TaggerCard = {
          assetGuid: formGroup.assetGuid,
          assetTitle: formGroup.assetTitle,
          startDate: formGroup.startDate,
          endDate: formGroup.endDate,
          assetType: formGroup.assetType,
          groups: formGroup.groups,
          locations: formGroup.locations,
          originalFileName: formGroup.fileName,
          isFeatured: formGroup.isFeatured,
        };
        offerCardModels.push(model);
      } else if (formGroup.assetType == AssetType.EndTag) {
        const model: TaggerCard = {
          assetGuid: formGroup.assetGuid,
          assetTitle: formGroup.assetTitle,
          startDate: formGroup.startDate,
          endDate: formGroup.endDate,
          assetType: formGroup.assetType,
          groups: formGroup.groups,
          locations: formGroup.locations,
          originalFileName: formGroup.fileName,
          isFeatured: formGroup.isFeatured,
        };
        endCardModels.push(model);
      } else if (formGroup.assetType == AssetType.VoiceOver) {
        const model: TaggerCardVoiceOver = {
          assetGuid: formGroup.assetGuid,
          assetTitle: formGroup.assetTitle,
          startDate: formGroup.startDate,
          endDate: formGroup.endDate,
          assetType: formGroup.assetType,
          groups: formGroup.groups,
          locations: formGroup.locations,
          originalFileName: formGroup.fileName,
          isFeatured: formGroup.isFeatured,
        };
        voiceOverModels.push(model);
      }
      uploadAssets.baseCreativeRequestViewModels = baseCreativeModels;
      uploadAssets.offerCardRequestViewModels = offerCardModels;
      uploadAssets.endCardRequestViewModels = endCardModels;
      uploadAssets.voiceOverRequestViewModels = voiceOverModels;
    });

    return uploadAssets;
  }

  public uploadTaggerAssets(payload: CreateTaggerAssetRequestModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.taggerService.uploadTaggerAssets(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          const assets = this.getAssetsFromResponse(res.result);
          console.log(assets);
          if (assets.some((element) => element.isDuplicate === true)) {
            assets.forEach((asset) => {
              const index = this.uploadedAssetFiles.findIndex(
                (x) => x.assetGuid === asset.assetGuid
              );
              if (index > -1)
                this.assetRequestFormGroup
                  .get("assetFormGroup" + index)
                  .get("isDuplicate")
                  .setValue(asset.isDuplicate);
            });
            this.alertService.ShowLoader(false);
            return;
          }

          this.alertService.showMessage(
            "SUCCESS",
            Constants.updateTaggerAssetSuccessful,
            MessageSeverity.success
          );
          this.closePopup();
          this.onClose.next(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getAssetsFromResponse(resData) {
    const assets = [];
    resData.baseCreativeResponseViewModels.forEach((element) => {
      assets.push({
        assetGuid: element.baseCreativeGuid,
        isDuplicate: element.isDuplicate,
      });
    });

    resData.offerCardResponseViewModels.forEach((element) => {
      assets.push({
        assetGuid: element.offerCardGuid,
        isDuplicate: element.isDuplicate,
      });
    });

    resData.endCardResponseViewModels.forEach((element) => {
      assets.push({
        assetGuid: element.endCardGuid,
        isDuplicate: element.isDuplicate,
      });
    });

    resData.voiceOverResponseViewModels.forEach((element) => {
      assets.push({
        assetGuid: element.voiceOverGuid,
        isDuplicate: element.isDuplicate,
      });
    });
    return assets;
  }

  public createOrUpdateTaggerAssets(payload: CreateTaggerAssetRequestModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.taggerService.uploadTaggerAssets(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.updateTaggerAsset();
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  onFormSubmit({ value, valid }: { value: any; valid: boolean }) {
    console.log(valid);
    console.log(value);
    this.isFormSubmitted = true;
    if (!valid) {
      return;
    }
    value = this.getUploadAssetRequestForm();
    var uploadAssets: CreateTaggerAssetRequestModel = value;

    uploadAssets.baseCreativeRequestViewModels.forEach(asset => {
      if (this.groups != null && this.groups.length > 0) {
        if (asset.groups == null || asset.groups.length == 0) {
          this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
          valid = false;
          return;
        }

        if (this.locations != null && this.locations.length > 0) {
          if (asset.locations == null || asset.locations.length == 0) {
            this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
            valid = false;
            return;
          }
        }
      }
    });

    uploadAssets.endCardRequestViewModels.forEach(asset => {
      if (this.groups != null && this.groups.length > 0) {
        if (asset.groups == null || asset.groups.length == 0) {
          this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
          valid = false;
          return;
        }

        if (this.locations != null && this.locations.length > 0) {
          if (asset.locations == null || asset.locations.length == 0) {
            this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
            valid = false;
            return;
          }
        }
      }
    });

    uploadAssets.offerCardRequestViewModels.forEach(asset => {
      if (this.groups != null && this.groups.length > 0) {
        if (asset.groups == null || asset.groups.length == 0) {
          this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
          valid = false;
          return;
        }

        if (this.locations != null && this.locations.length > 0) {
          if (asset.locations == null || asset.locations.length == 0) {
            this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
            valid = false;
            return;
          }
        }
      }
    });

    uploadAssets.voiceOverRequestViewModels.forEach(asset => {
      if (this.groups != null && this.groups.length > 0) {
        if (asset.groups == null || asset.groups.length == 0) {
          this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
          valid = false;
          return;
        }

        if (this.locations != null && this.locations.length > 0) {
          if (asset.locations == null || asset.locations.length == 0) {
            this.alertService.showMessage("Error", Constants.group_location_mandatery, MessageSeverity.error);
            valid = false;
            return;
          }
        }
      }
    });

    if (!valid) {
      return;
    }

    if (this.isEditAsset) {
      const assetType = this.assetRequestFormGroup
        .get(this.groupNames[0])
        .get("assetType").value;
      if (assetType !== this.uploadedAssetFiles[0].assetType) {
        this.createOrUpdateTaggerAssets(value);
      } else {
        this.updateTaggerAsset();
      }
    } else {
      this.uploadTaggerAssets(value);
    }
  }

  public updateTaggerAsset() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: UpdateTaggerAssetRequestModel =
      this.getUpdateTaggerAssetRequestModel();
    this.taggerService.updateTaggerAsset(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.updateTaggerAssetSuccessful,
            MessageSeverity.success
          );
          this.closePopup();
          this.onClose.next(true);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getUpdateTaggerAssetRequestModel() {
    const startDate =
      this.assetRequestFormGroup.get(this.groupNames[0]).get("startDate")
        .value || null;
    const endDate =
      this.assetRequestFormGroup.get(this.groupNames[0]).get("endDate").value ||
      null;
    const updateAsset: UpdateTaggerAssetRequestModel = {
      assetGuid:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("assetGuid")
          .value || null,
      assetType:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("assetType")
          .value || AssetType.All,
      assetTitle:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("assetTitle")
          .value || null,
      availableStartDate:
        (startDate && moment(startDate).toISOString()) || null,
      availableEndDate: (endDate && moment(endDate).toISOString()) || null,
      availableGroups:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("groups")
          .value || null,
      availableLocations:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("locations")
          .value || null,
      isFeatured:
        this.assetRequestFormGroup.get(this.groupNames[0]).get("isFeatured")
          .value || false,
      taggableDurationInSecs:
        this.assetRequestFormGroup
          .get(this.groupNames[0])
          .get("taggableDurationInSecs").value || null,
    };

    return updateAsset;
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public removeUnSupportedFile(file) {
    console.log(file);
    const filteredItems = this.unSupportedFiles.filter(
      (item) => item.fileName !== file.fileName
    );
    this.unSupportedFiles = filteredItems;
  }

  public removeFile(event: any) {
    console.log(event);
    var removableFile = this.assetRequestFormGroup.get(event).value;
    this.uploadedAssetFiles = this.uploadedAssetFiles.filter(
      (file) => file.assetGuid != removableFile.assetGuid
    );
    this.assetRequestFormGroup.removeControl(event);
    this.groupNames = Object.keys(this.assetRequestFormGroup.controls);
  }
}
