import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from "../../../../models/config/gridparams.model";
import { DxDataGridComponent } from "devextreme-angular";
import * as moment from 'moment';
import { SearchClientModel } from "../../../../models/admin/adminsearch.model";
import { RouteConstants } from "../../../../configurations/route-constants";
import { GlobalService } from '../../../../services/core/global.service';

@Component({
  selector: 'search-clients',
  templateUrl: './search-clients.component.html',
  styleUrls: ['./search-clients.component.css']
})
export class SearchClientsComponent implements OnInit {
  @ViewChild('clientSearch', { static: true }) clientSearchGrid: DxDataGridComponent;

  public searchClientFormGroup: FormGroup;
  public quickSearchValue: string = '';

  public resultClientStore: any = {};
  public brandDataSource: any = {};
  public statusDataSource: any = {};
  public searchClientCriteriaObj = <SearchClientModel>{};
  public now: Date = new Date();

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService,
      private gs: GlobalService
    , private configService: ConfigService) {

  }

  ngOnInit() {
    this.getClients();
    //get config
    this.getConfig();

    //search formgroup
    this.searchClientFormGroup = new FormGroup({
      clientName: new FormControl(),
      status: new FormControl(),
      brand: new FormControl(),
      createdFrom: new FormControl(),
      createdTo: new FormControl()
    });
  }

  public getClients() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchClientCriteriaObj;

    this.resultClientStore.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return adminService.searchAllClients(request)
          .toPromise()
          .then((response: any) => {
            
            if (response.isSuccess == true) {

              var clientSearchResponse = response.result;
              var obj: any = {
                data: clientSearchResponse.clients,
                totalCount: clientSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getClientSearchConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.brandDataSource = res.result.brands;
        this.statusDataSource = res.result.clientStatuses;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public clearSearch() {
    //Clears all searches including quick and advanced
    this.searchClientFormGroup.reset();
    this.quickSearchValue = "";
    this.setDefaultFormValues();
    this.clientSearchGrid.instance.refresh();
  }

  public setDefaultFormValues() {
    this.searchClientCriteriaObj.clientName = null;
    this.searchClientCriteriaObj.status = null;
    this.searchClientCriteriaObj.brand = null;
    this.searchClientCriteriaObj.createdFrom = null;
    this.searchClientCriteriaObj.createdTo = null;
    this.searchClientCriteriaObj.quickSearch = null;

  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchClientCriteriaObj.quickSearch = this.quickSearchValue;
    this.clientSearchGrid.instance.refresh();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      //this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};
      this.searchClientCriteriaObj.quickSearch = this.quickSearchValue;
      this.setDefaultFormValues();
      this.clientSearchGrid.instance.refresh();
    }
  }

  public onFormSubmit({ value, valid }: { value: SearchClientModel, valid: boolean }) {
    console.log(value);
    if (value.clientName === null && value.status === null && value.brand === null
      && (value.createdTo === null || value.createdTo === undefined)
      && (value.createdFrom === null || value.createdFrom === undefined)) {
      return;
    }

    if (value.createdFrom != null || value.createdFrom === undefined)
      value.createdFrom = moment(value.createdFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.createdTo != null || value.createdTo === undefined)
      value.createdTo = moment(value.createdTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.searchClientCriteriaObj.clientName = value.clientName;
    this.searchClientCriteriaObj.status = value.status;
    this.searchClientCriteriaObj.brand = value.brand;
    this.searchClientCriteriaObj.createdFrom = value.createdFrom;
    this.searchClientCriteriaObj.createdTo = value.createdTo;
    this.searchClientCriteriaObj.quickSearch = null;

    console.log(this.searchClientCriteriaObj);
    this.clientSearchGrid.instance.refresh();
  }

  public showClientViewDetail(clientGuid: string) {
    if (clientGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openClientDetails(clientGuid);

    //this.router.navigate([RouteConstants.clientDetailRoute, clientGuid]);
  }

  public exportSpots() {    
    this.clientSearchGrid.instance.exportToExcel(false);    
  }

  public onExporting() {
    this.clientSearchGrid.instance.beginUpdate();
   
    this.clientSearchGrid.instance.columnOption("phoneNumber", "visible", true);
    this.clientSearchGrid.instance.columnOption("contactName", "visible", true);
    this.clientSearchGrid.instance.columnOption("address", "visible", true);
  }

  public onExported() {
    this.clientSearchGrid.instance.columnOption("phoneNumber", "visible", false);
    this.clientSearchGrid.instance.columnOption("contactName", "visible", false);
    this.clientSearchGrid.instance.columnOption("address", "visible", false);

    this.clientSearchGrid.instance.endUpdate();
  }

  ngOnDestroy() {

  }
}
