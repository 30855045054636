import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'service-internationaltranscribescript',
  templateUrl: './serviceinternationaltranscribescript.component.html'
})
export class ServiceInternationalTranscribeScriptQComponent extends BaseServiceDataCollectionComponent {

  public optionsLanguage: Array<any> = [];

  public optionsFileType: Array<any> = [];

  public selectedFileTypes: Array<string> = [];

  public selectedLanguages: Array<string> = [];

  public showOtherLanguage: boolean = false;

  @ViewChild('treeLanguage', { static: false }) treeLanguage: DxTreeViewComponent;
  @ViewChild('treeFileType', { static: false }) treeFileType: DxTreeViewComponent;

  constructor(private cdr: ChangeDetectorRef) {
    super();

    this.selectedFileTypes = [];
    this.selectedLanguages = [];
  }

  ngOnInit() {

    super.ngOnInit();

    this.optionsLanguage.push({ name: 'English', value: 'English' });
    this.optionsLanguage.push({ name: 'Spanish', value: 'Spanish' });
    this.optionsLanguage.push({ name: 'other', value: 'other' });

    this.optionsFileType.push({ name: '.txt', value: '.txt' });
    this.optionsFileType.push({ name: '.doc', value: '.doc' });
    this.optionsFileType.push({ name: '.docx', value: '.docx' });

    if (this.formGroup.controls["fileType"].value == null) {
      this.formGroup.controls["fileType"].setValue([])
      this.selectedFileTypes = [];
    }
    else {
      this.selectedFileTypes = this.formGroup.controls["fileType"].value;
    }

    if (this.formGroup.controls["language"].value == null) {
      this.formGroup.controls["language"].setValue(["English"])
      this.selectedLanguages = ["English"];
    }
    else {
      this.selectedLanguages = this.formGroup.controls["language"].value;
    }

    this.checkOtherLanguage(this.formGroup.controls["language"].value);

    this.formGroup.get('language').valueChanges.subscribe(
      (languages: Array<string>) => {
        this.checkOtherLanguage(languages);
      });
  }

  private checkOtherLanguage(languages: Array<string>) {

    if (languages.find(t => t === 'other') != null) {
      this.showOtherLanguage = true;
      this.formGroup.get('languageCustom').setValidators([Validators.required]);
      this.formGroup.get('languageCustom').updateValueAndValidity();
    }
    else {
      this.showOtherLanguage = false;
      this.formGroup.get('languageCustom').clearValidators();
      this.formGroup.get('languageCustom').updateValueAndValidity();
      //this.formGroup.controls["languageCustom"].setValue('');
    }
  }


  public onLanguageTreeChanged(e) {
    this.selectedLanguages = e.component.getSelectedNodesKeys();
    this.cdr.detectChanges();
  }

  public onLanguageChanged(e = null) {

    var component = (e && e.component) || (this.treeLanguage && this.treeLanguage.instance);

    if (!component) return;

    if (!this.selectedLanguages.length) {
      component.unselectAll();
    }

    if (this.selectedLanguages) {
      this.selectedLanguages.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }

    this.cdr.detectChanges();
  }

  public onFileTypeTreeChanged(e) {
    this.selectedFileTypes = e.component.getSelectedNodesKeys();
    this.cdr.detectChanges();
  }

  public onFileTypeChanged(e = null) {

    var component = (e && e.component) || (this.treeFileType && this.treeFileType.instance);

    if (!component) return;

    if (!this.selectedFileTypes.length) {
      component.unselectAll();
    }

    if (this.selectedFileTypes) {
      this.selectedFileTypes.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }

    this.cdr.detectChanges();
  }
}
