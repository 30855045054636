import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { MediaDetailService } from '../../../services/mediadetail/mediadetail.service';
import { UpdateDistroMediaRequest } from '../../../models/distribution/update-distro-media-request.model';


@Component({
  selector: 'update-distribution-media',
  templateUrl: './updatedistributionmediapopup.component.html',
  styleUrls: ['./updatedistributionmediapopup.component.css'],
})
export class UpdateDistributionMediaPopUp implements OnInit {

  @Input('houseId') houseId: string = null;
  public onClose: Subject<UpdateDistroMediaRequest>;

  public modalRef: BsModalRef;
  public updateDistroMediaForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private mediaDetailService: MediaDetailService) {

    this.onClose = new Subject<UpdateDistroMediaRequest>();
  }

  ngOnInit() {
    this.updateDistroMediaForm = this.fb.group({
      houseId: new FormControl(this.houseId),
    });
  }

  public onupdateDistroMediaFormSubmit({ value, valid }: { value: UpdateDistroMediaRequest, valid: boolean }) {

    if (!valid) {
      return;
    }
    this.onClose.next(value);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
