<div class="d-flex justify-content-end mb-3">
  <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onRefresh()">
    <span class="tylie-button__icon">
      <icon-refresh></icon-refresh>
    </span>
    <span class="tylie-button__text">REFRESH</span>
  </button>
</div>

<div class="row justify-content-between">
  <div class="col-6">
    <section class="tylie-input-search">
      <div class="tylie-input-search__inner">
        <div class="tylie-input-search__icon">
          <icon-search></icon-search>
        </div>
        <input class="tylie-input-search__text"
                placeholder="Search By Tagger Ads by keyword"
                name="srch-term"
                id="srch-term"
                type="search"
                [(ngModel)]="quickSearchValue"
                (keydown.enter)="onEnterQuickSearch($event)" />
      </div>
      <div class="tylie-input-search__btn">
        <button class="tylie-button tylie-button--sm"
                type="button"
                (click)="quickSearch()">
          Search
        </button>
      </div>
    </section>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-4">
        <label for="sortBy"
                class="mt-3">Sort By</label>
      </div>
      <div class="col-8">
        <dx-select-box [dataSource]="sortDataSource"
                        name="sortBy"
                        id="sortBy"
                        class="tylie-select form-control"
                        valueExpr="id"
                        displayExpr="name"
                        [value]="selectedSortOrder"
                        (onValueChanged)="onSortValueChanged($event)">
        </dx-select-box>
      </div>
    </div>
  </div>
</div>

<div class="grid-container"
      *ngFor="let taggerAd of draftTaggerAds">
  <div class="grid-item">
    <ng-container *ngIf="taggerAd.baseCreativeDetails?.baseCreative; else elseBaseCreativeTemplate">
      <div class="creative-thumbnail-content">
        <img class="quickLink"
              [src]="taggerAd.baseCreativeDetails?.baseCreative?.thumbnailDisplayUrl"
              *ngIf="taggerAd.baseCreativeDetails?.baseCreative?.isPlayable"
              (click)="
                  quickPlay(
                      taggerAd.baseCreativeDetails?.baseCreative?.proxyurl,
                      taggerAd.baseCreativeDetails?.baseCreative?.creativeName,
                      taggerAd.baseCreativeDetails?.baseCreative?.thumbnailurl,
                      taggerAd.baseCreativeDetails?.baseCreative?.format
                  )
              " />
        <img [src]="taggerAd.baseCreativeDetails?.baseCreative?.thumbnailDisplayUrl"
              *ngIf="!taggerAd.baseCreativeDetails?.baseCreative?.isPlayable" />
      </div>
    </ng-container>
    <ng-template #elseBaseCreativeTemplate>
      <div>
        <label class="error"></label>
      </div>
    </ng-template>

    <div class="thumbnail-content">
      <ng-container *ngIf="taggerAd.offerCardDetails?.offerCard; else elseOfferCardTemplate">
        <div>
          <img [src]="taggerAd.offerCardDetails?.offerCard?.cardDisplayUrl" class="gridThumbnail" />
        </div>
      </ng-container>
      <ng-template #elseOfferCardTemplate>
        <div>
          <label class="error"></label>
        </div>
      </ng-template>

      <ng-container *ngIf="taggerAd.endCardDetails?.endCard; else elseEndCardTemplate">
        <div>
          <img [src]="taggerAd.endCardDetails?.endCard.cardDisplayUrl" />
        </div>
      </ng-container>
      <ng-template #elseEndCardTemplate>
        <div>
          <label class="error"></label>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="grid-item">
    <div class="row">
      <div class="col-md-12">
        <span class="tagger-title">{{taggerAd.adName}}</span>
      </div>
      <div class="col-md-6">
        <div class="tagger-detail-container">
          <Label>Created:</Label>
          <span *ngIf="taggerAd.baseCreativeDetails?.baseCreative">{{taggerAd.baseCreativeDetails?.baseCreative?.availableForOrderingFromDate | date:'M/d/yy'}} - {{taggerAd.baseCreativeDetails?.baseCreative?.expirydate | date:'M/d/yy'}}</span>
        </div>
        <div class="tagger-detail-container">
          <Label>Duration:</Label>
          <span *ngIf="taggerAd.baseCreativeDetails?.baseCreative">{{taggerAd.baseCreativeDetails?.baseCreative?.durationinsecs}}</span>
        </div>
        <div class="tagger-detail-container">
          <Label>Base:</Label>
          <span *ngIf="taggerAd.baseCreativeDetails?.baseCreative">{{taggerAd.baseCreativeDetails?.baseCreative?.creativeName}}</span>
        </div>
        <div class="tagger-detail-container">
          <Label>Offer:</Label>
          <span *ngIf="taggerAd.offerCardDetails?.offerCard">{{taggerAd.offerCardDetails?.offerCard?.cardName}}</span>
        </div>
        <div class="tagger-detail-container">
          <Label>End:</Label>
          <span *ngIf="taggerAd.endCardDetails?.endCard">{{taggerAd.endCardDetails?.endCard?.cardName}}</span>
        </div>
        <div class="tagger-detail-container">
          <Label>Voice:</Label>
          <span *ngIf="taggerAd.voiceOverDetails?.voiceOver">{{taggerAd.voiceOverDetails?.voiceOver?.voiceOverName}}</span>
        </div>
      </div>
      <div class="col-md-6">
        <Label>Notes:</Label>
        <div class="p-normal">{{taggerAd.note?.payload}}</div> <span class="p-tiny">{{taggerAd.note?.createdBy}}</span>&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{taggerAd.note?.createdDate | usertimezone: 'MM/DD/YYYY HH:mm A'}}</span>
        <div>
          <a class="btn-link" (click)="editNote(taggerAd)" *ngIf="!taggerAd.note?.payload">Add Note</a>
          <a class="btn-link" (click)="editNote(taggerAd)" *ngIf="taggerAd.note?.payload">View/Edit Note</a>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-item">
    <div class="row tagger-actions">
      <button class="btn tyl-btn tagger-btn-small" *ngIf="taggerAd.status">
        {{taggerAd.status}}
      </button>
      <button class="btn tyl-btn btn-icon-split text tagger-btn-small" (click)="gotoEditDraft(taggerAd)">
        <span class="icon">
          <i class="tylicon tylicon-editpencil-white"></i>
        </span>
        <span class="text">Edit AD</span>
      </button>
    </div>
  </div>
</div>
<div class="no-records" *ngIf="draftTaggerAds?.length === 0">
  <span>No Records found</span>
</div>

