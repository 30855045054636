import { Component, OnInit, NgZone, Inject, Input, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Constants } from "../../../../configurations/constants";
import { OrderService } from '../../../../services/order/order.service';
import { ConfigService } from '../../../../services/core/config.service';
import { OrderDestination } from '../../../../models/order/order-destinations.model';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import * as moment from 'moment-timezone';
import { DxTreeViewComponent, DxDateBoxComponent } from 'devextreme-angular';
import { UserTimeZoneToUtc } from '../../../../pipes/usertimezoneToUtc.pipe';
import { UserTimeZone } from '../../../../pipes/usertimezone.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InfoPopupComponent } from '../../../popups/infopopup/infopopup.component';
import { confirmEventType, deliveryMethodEnum, actionRoleEnum, metadataCategoryEnum, ReportTypeEnum } from '../../../../configurations/enums/enums';
import { ServiceType } from '../../../../models/order/servicetype.model';
import { Subscription } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { SelectAttachmentCategoryPopupComponent } from '../../../popups/selectattachmentcategorypopup/selectattachmentcategorypopup.component';
import { OrderDetail, OrderDetailGroup, OrderDetailSpotServiceData, OrderDetailConfig, OrderDetailAction, OrderDetailServiceData, OrderProgress, RouteToTranscodeEmitData } from '../../../../models/order/order-detail.model';
import { RouteConstants } from "../../../../configurations/route-constants";
import { CurrentOrderState } from "../../../../models/order/current-order-state.model";
import { GroupError, OrderErrors } from '../../../../models/order/ordererror.model';
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { OrderStatusConfirmpopup } from "../../../popups/orderstatusconfirmpopup/orderstatusconfirmpopup.component";
import { OrderFile, ServiceData, ServiceMetadata } from "../../../../models/order/order-create.model";
import { EditHistoryPopupComponent } from '../../../popups/edithistorypopup/edithistorypopup.component';
import { VendorPurchaseOrderPopupComponent } from "../../../popups/vendorpurchaseorder/vendorpurchaseorderpopup.component";
import { OrderGroupService, VendorPurchaseOrderViewModel, ReportDeliveryConfirmationRequestViewModel } from "../../../../models/order/vendor-purchaseorder-request.model";
import { VendorPurchaseOrderConfirmationPopupComponent } from "../../../popups/vendorpurchaseorderconfirmationpopup/vendorpurchaseorderconfirmationpopup.component";
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { OrderAdditionalMetadata } from '../../../../models/order/order-additionalmetadata';
import { OrderAddServicePopupComponent } from '../../../popups/orderaddservicepopup/orderaddservicepopup.component';
import { SpotSelectedServicesRequest, SpotServicesOptionsRequest } from '../../../../models/order/order-spotselectedservice-request.model';

import { ReportService } from '../../../../services/report/report.service';
import { AuthService } from '../../../../services/auth/auth.service';  // move to service
import { GlobalService } from '../../../../services/core/global.service';
import { BillingItemsPopupComponent } from '../../../popups/billingitemspopup/billingitemspopup.component';
import { FormGroup } from '@angular/forms';
import { OrderGroupDetailComponent } from '../order-detail-group/ordergroupdetail.component';
import { SpotDestinationComb } from '../../../../models/order/order-spotdestcomb.model';
import { SpotResendPopupComponent } from '../../../popups/spotresendpopup/spotresendpopup.component';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { catchError, mergeMap, map, finalize } from 'rxjs/operators';
import { UnSavedDataWarningComponent } from '../../../popups/unsaveddatapopup/unsaveddatapopup.component';
import { SendorderemailpopupComponent } from '../../../popups/sendorderemailpopup/sendorderemailpopup.component';
import { DBkeys } from '../../../../configurations/db-Keys';
var stringify = require('json-stringify-safe');

@Component({
  selector: 'order-detail-content',
  templateUrl: './orderdetailcontent.component.html',
  styleUrls: ['./orderdetailcontent.component.css'],
  providers: [CurrencyPipe]
})
export class OrderDetailContentComponent implements OnInit {

  private orderGuid: string;
  public orderDetail: OrderDetail;
  public activeTab: string = 'orders'
  public totalSavedGroups: number = 0;
  public modalRef: BsModalRef;
  public orderDetailShareLinkStore: any = [];
  public isServicesEdited: boolean = false;
  public containsServiceErrors: boolean = false;
  public triedToSave: boolean = false;
  @ViewChildren(OrderGroupDetailComponent) chs: QueryList<OrderGroupDetailComponent>;

  public serviceErrors: Array<string> = [];

  public static readonly DIGITALDELIVERY: string = "digital delivery";
  public static readonly POSTPRODUCTION: string = "post production";
  public static readonly ADDITIONAL: string = "additional";

  @Input() orderIdentifier: string;

  public showSaveChanges: boolean = false;
  public anyChangesDetectedInNonExclusiveEdit: boolean = false;
  public anyChangesDetected: boolean = false;

  @ViewChild('confirmpop', { static: false }) confirmpop: any;
  constructor(public util: Utilities,
    private alertService: AlertService,
    private reportService: ReportService,
    private router: Router,
    private cp: CurrencyPipe,
    private modalService: BsModalService,
    private orderService: OrderService,
    private gs: GlobalService,
    private authService: AuthService,
    ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document) {
    window.onscroll = (e) => {
      ngZone.run(() => {
        if (this.document.documentElement.scrollTop > 50) {
          $('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
          $('.scrolltop').stop(true, true).fadeOut();
        }
      });
    };
  }

  ngOnInit() {

    this.orderGuid = this.orderIdentifier;

    this.getOrderDetail();
  }

  public hasUnsavedData(): boolean {
    return this.anyChangesDetected;
  }

  public onClickStartProcessClaim() {
    this.orderService.orderStartProcessInDetail([this.orderGuid])
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderReviewClaimSuccess, MessageSeverity.success);
          this.orderDetail.config.orderActions.showSendOrderEmailButton = true;
          this.gs.orderEdited();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }

        var order = res.result.orderDetail as OrderDetail;

        if (order != null) {
          this.updateData(order);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onClickAwaitingReviewClaim() {
    this.orderService.claimOrderInDetail(this.orderGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderReviewClaimSuccess, MessageSeverity.success);
          this.orderDetail.config.orderActions.showSendOrderEmailButton = true;
          this.gs.orderEdited();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }

        var order = res.result.orderDetail as OrderDetail;

        if (order != null) {
          this.updateData(order);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }

  public onClickCreatePO() {

    this.orderService.getOrderGroupServicesForVendorPO(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        console.log(res.result);
        var vendorPOOrderGroupServices = res.result.vendorPOOrderGroupService;
        var vendorPurchaseOrders = res.result.vendorPurchaseOrders as VendorPurchaseOrderViewModel[];
        var vendorPOOrderLvlServices = res.result.vendorPOOrderLvlService;

        var initialState = {
          orderGuid: this.orderGuid,
          vendorPOOrdergroupService: vendorPOOrderGroupServices,
          vendorPOList: vendorPurchaseOrders,
          vendorPOOrderLvlServices: vendorPOOrderLvlServices
        };

        this.modalRef = this.modalService.show(VendorPurchaseOrderPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onClickViewPO() {

    this.orderService.viewVendorPO(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var vendorPOList = res.result.vendorPurchaseOrders as VendorPurchaseOrderViewModel[];

        var initialState = {
          vendorPOList: vendorPOList,
          title: "VENDOR PURCHASE ORDERS"
        };

        this.modalRef = this.modalService.show(VendorPurchaseOrderConfirmationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onClickOpsSummary() {
    this.authService.acquireTokenSilent().subscribe(
      (accessToken: string) => {
        const opsSummaryBaseUrl = this.orderService.getOpsSummaryUrl(
          this.orderDetail.config.opsSummaryBaseLink,
          this.orderDetail.orderGuid,
          accessToken,
          localStorage.getItem(DBkeys.X_TANDEM_Profile)
        );

        // Open the URL in a new window
        const win = window.open(opsSummaryBaseUrl, '_blank');
        if (win) {
          win.opener = null;
          win.focus();
        }
      },
      (error) => {
        console.error('Error acquiring token silently:', error);
      }
    );
  }

  public onClickViewPOD() {

    var request = new ReportDeliveryConfirmationRequestViewModel();
    request.orderGuid = this.orderGuid;
    request.reportType = ReportTypeEnum.Excel.toString();

    this.reportService.getDeliveryConfirmationReport(request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var path = res.result.reportFullPath;
        window.open(path, '_blank');

        //this.downloadFile(path, res.result.reportName);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickApproveOrderReview() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.approveOrderReview(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
        this.gs.closePopover();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickCompleteOrderProcessing() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.completeOrderProcessing(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickReleaseClaimedOrder() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.releaseClaimedOrder(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderReleaseClaimSuccess, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickReleaseOrderClaimedForProcessing() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.releaseOrderClaimedForProcessing(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderReleaseClaimSuccess, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickShowBillingItems() {
    var initialState = {
      orderGuid: this.orderGuid
    };

    this.modalRef = this.modalService.show(BillingItemsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));
  }

  public onClickSendOrderToAE() {
    var initialState = {
      title: "Send For Review"
    };

    this.modalRef = this.modalService.show(OrderStatusConfirmpopup, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onConfirm.subscribe(result => {
      this.onSendOrderToAESubscriptionRaised(result);
    });
  }

  public onClickSetOrderBackToReadyForProcess() {
    var initialState = {
      title: "Set To Ready For Process"
    };

    this.modalRef = this.modalService.show(OrderStatusConfirmpopup, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onConfirm.subscribe(result => {
      this.onSetOrderBackToReadyForProcessSubscriptionRaised(result);
    });
  }

  public onSetOrderBackToReadyForProcessSubscriptionRaised(response: any) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.setOrderBackToReadyForProcess(this.orderGuid, { comments: response.comments }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onSendOrderToAESubscriptionRaised(response: any) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.sendOrderBackToAE(this.orderGuid, { comments: response.comments }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getOrderDetail() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.getOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.alertService.logMessage(res);

        var order = res.result as OrderDetail;

        this.updateData(order);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private updateData(orderDetail: OrderDetail) {

    this.totalSavedGroups = orderDetail.groups.filter(t => t.orderGroupId > 0).length;

    for (var i = 0; i <= orderDetail.groups.length - 1; i++) {
      var gp = orderDetail.groups[i];

      this.updateOrderGroupData(gp);
    }

    this.orderDetail = orderDetail;

    this.updateOrderEditConfig();

    this.isServicesEdited = false;

    this.activeTab = 'orders';
  }

  private updateOrderGroupData(gp: OrderDetailGroup) {

    gp.isEdited = false;

    for (let s of gp.spotData)
      this.updateSpotData(s);
  }

  private updateSpotData(s: OrderDetailSpotServiceData) {

    //s.outputMediaId = UUID.UUID();

    s.isPpExists = (s.serviceData.some(t => t.service.category === 'Post Production'));
    s.isAddlExists = (s.serviceData.some(t => t.service.category === 'Additional'));
    s.isDeliveryExists = (s.serviceData.some(t => t.service.category === 'Digital Delivery'));

    if (s.ppSpotFile != null) {
      if (s.ppSpotFile.thumbnailUrl !== null && s.ppSpotFile.thumbnailUrl !== '')
        s.ppSpotFile.thumbnailUrl = this.util.getThumbnailUrl(s.ppSpotFile.thumbnailUrl, (s.ppSpotFile.format.toLowerCase() == 'audio'), (s.ppSpotFile.status.toLowerCase() == 'awaiting media'), (s.ppSpotFile.status.toLowerCase() == 'creating media'));
      else
        s.ppSpotFile.thumbnailUrl = '';

      if (s.ppSpotFile.format.toLowerCase() == 'audio')
        s.ppSpotFile.showCreatingMediaBanner = (s.ppSpotFile.thumbnailUrl == '' && s.ppSpotFile.status.toLowerCase() == 'creating media') ? true : false;
      else
        s.ppSpotFile.showCreatingMediaBanner = (s.ppSpotFile.thumbnailUrl == '') ? true : false;

      s.ppSpotFile.proxyUrl = this.util.getProxyUrl(s.ppSpotFile.proxyUrl);
    }

    s.spotFile.thumbnailUrl = this.util.getThumbnailUrl(s.spotFile.thumbnailUrl, (s.spotFile.format.toLowerCase() == 'audio'), (s.spotFile.status.toLowerCase() == 'awaiting media'), (s.spotFile.status.toLowerCase() == 'creating media'));
    s.spotFile.proxyUrl = this.util.getProxyUrl(s.spotFile.proxyUrl);

    s.spotFile.formatSources = this.util.getAllIconSources(s.spotFile.format, s.spotFile.audioFormat, s.spotFile.spotTrac, s.spotFile.veilEncode,
      s.spotFile.subtitles, s.spotFile.centerCutProtected, s.spotFile.descriptiveVideo,
      s.spotFile.broadcast, s.spotFile.web, s.spotFile.taggable, s.spotFile.generic, s.spotFile.runningFootage, s.spotFile.cc,
      s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);


    s.spotFile.formatClasses = this.util.getAllIconClasses(s.spotFile.format, s.spotFile.audioFormat, s.spotFile.spotTrac,
      s.spotFile.veilEncode, s.spotFile.subtitles, s.spotFile.centerCutProtected, s.spotFile.descriptiveVideo,
      s.spotFile.broadcast, s.spotFile.web, s.spotFile.taggable, s.spotFile.generic, s.spotFile.runningFootage, s.spotFile.cc,
      s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);

    for (let spotfile of s.spotFile.postProdFiles) {
      spotfile.thumbnailUrl = this.util.getThumbnailUrl(spotfile.thumbnailUrl, (s.spotFile.format.toLowerCase() == 'audio'), (s.spotFile.status.toLowerCase() == 'awaiting media'), (s.spotFile.status.toLowerCase() == 'creating media'));
      spotfile.proxyUrl = this.util.getProxyUrl(spotfile.proxyUrl);

      spotfile.formatSources = this.util.getAllIconSources(s.spotFile.format, spotfile.audioFormat, spotfile.spotTrac,
        spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo,
        spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic, spotfile.runningFootage, spotfile.cc,
        s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);

      spotfile.formatClasses = this.util.getAllIconClasses(s.spotFile.format, spotfile.audioFormat, spotfile.spotTrac,
        spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo,
        spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic, spotfile.runningFootage, spotfile.cc,
        s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);
    }

    for (let sd of s.serviceData) {
      sd.containsServiceMetadataDataErrors = false;
      sd.errorMsg = '';
      sd.parentServices = [];
      sd.recentlyAdded = false;
      sd.optionsSubmitted = false;
    }
  }

  private updateOrderEditConfig() {

    var inEditMode = this.orderDetail.config.orderActions.showInEditMode;

    this.orderDetail.config.orderActions.canEditHeader = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase() || t === actionRoleEnum[actionRoleEnum.Header].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canEditSpecialHeader = this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.SpecialHeader].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canEditHubbedDestination = inEditMode && this.orderDetail.config.orderActions.showEditHub === true;

    this.orderDetail.config.orderActions.canEditBillingOnlyOrder = inEditMode && this.orderDetail.config.orderActions.showEditBillingOnlyOrder === true;

    this.orderDetail.config.orderActions.canEditGroupName = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase() || t === actionRoleEnum[actionRoleEnum.Header].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canEditRush = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canFTPUpdate = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.FTPUpdate].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canAddGroup = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;
    this.orderDetail.config.orderActions.canDeleteGroup = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canAddMedia = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;
    this.orderDetail.config.orderActions.canDeleteMedia = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canAddDestination = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;
    this.orderDetail.config.orderActions.canDeleteDestination = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canAddOrderLevelServices = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.Full].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canUpdateSpecialServices = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.UpdateSpecialServices].toLowerCase()) === true;

    this.orderDetail.config.orderActions.canAddCXOpsComments = this.orderDetail.config.orderActions.canEditHeader || this.orderDetail.config.orderActions.canUpdateSpecialServices;

    this.orderDetail.config.orderActions.canDeleteAwaitingMedia = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.DeleteAwaitingMedia].toLowerCase()) === true;
    this.orderDetail.config.orderActions.canDeleteAwaitingMediaGroup = inEditMode && this.orderDetail.config.orderActions.editScope.some(t => t === actionRoleEnum[actionRoleEnum.DeleteAwaitingMedia].toLowerCase()) === true;



    if (this.orderDetail.config.orderActions.showVendorAssignmentFailedError == true) {
      this.alertService.showMessage("WARNING", "Carrier is not assigned for some destinations", MessageSeverity.error, true);
    }

    if (this.orderDetail.config.orderActions.showSpotVendorAssignmentFailedError == true) {
      this.alertService.showMessage("WARNING", "Carrier is not assigned for some spots", MessageSeverity.error, true);
    }

    this.isServicesEdited = false;

    this.orderDetail.groups.forEach(p => {
      p.isEdited = false;
    });

    this.UpdateSaveCancelVisibility();

    console.log(this.orderDetail.config.orderActions);
  }

  private UpdateSaveCancelVisibility() {

    var anyGroupEdited: boolean = null;

    this.orderDetail.groups.forEach(p => {
      if (p.isEdited == true)
        anyGroupEdited = true;
    });

    var canSave = (this.orderDetail.config.orderActions.canAddOrderLevelServices === true
      || this.orderDetail.config.orderActions.canUpdateSpecialServices == true
      || this.orderDetail.config.orderActions.canAddGroup == true);

    this.anyChangesDetected = canSave && (this.isServicesEdited == true || anyGroupEdited == true);

    this.showSaveChanges = canSave && (this.orderDetail.config.orderActions.isExclusiveEdit == true || this.anyChangesDetected == true);

    if (this.showSaveChanges == true && this.orderDetail.config.orderActions.isExclusiveEdit == false)
      this.anyChangesDetectedInNonExclusiveEdit = true;
    else
      this.anyChangesDetectedInNonExclusiveEdit = false;
  }

  public onCancelChanges() {
    this.getOrderDetail();
  }

  public onAddNewGroup() {
    this.orderDetail.groups.push(this.newGroupForm);
    //this.gs.orderEdited();

    this.UpdateSaveCancelVisibility();
  }

  public onAddMediaToAllGroups(selectedSpotFiles: Array<SpotFile>) {
    this.chs.forEach(p => {
      p.onSelectSpotsSubscriptionRaised(selectedSpotFiles);
    });
  }

  public onDeleteGroup(id: number, seq: number) {

    if (id > 0) {

      var groupIndexToDelete = this.orderDetail.groups.findIndex(t => t.orderGroupId == id);

      if (groupIndexToDelete >= 0)
        this.orderDetail.groups[groupIndexToDelete].isDeleted = true;
      //this.orderDetail.groups.splice(groupIndexToDelete, 1);

      //this.alertService.showMessage("SUCCESS", Constants.groupDeleted, MessageSeverity.success);

      //this.gs.orderEdited();
    }
    else {
      var groupIndexToDelete = this.orderDetail.groups.findIndex(t => t.sequenceId == seq);

      if (groupIndexToDelete >= 0)
        this.orderDetail.groups.splice(groupIndexToDelete, 1);
    }

    this.totalSavedGroups = this.orderDetail.groups.filter(t => t.orderGroupId > 0 && t.isDeleted != true).length;

    this.UpdateSaveCancelVisibility();
  }

  private get newGroupForm(): OrderDetailGroup {

    let newGroup: OrderDetailGroup = {
      spotData: [],
      shareLinks: [],
      ftpDestinations: [],
      destinations: [],
      serviceData: [],
      isRush: false,
      name: null,
      sequenceId: ++this.orderDetail.config.lastGroupSequence,
      containsGroupAdIdErrors: false,
      containsGroupDestinationErrors: false,
      containsGroupErrors: false,
      containsGroupServiceErrors: false,
      containsAdIDWarnings : false,
      containsGroupTitleErrors: false,
      containsGroupServiceLevelErrors: false,
      groupError: null,
      orderGroupId: 0,
      resendData: null,
      isEdited: false,
      isAllAwaitingMediaSpotsInGroup: false,
      isAdded: true,
      isDeleted: false,
      transcodeRequest: null
    };

    return newGroup;
  }

  public onGroupUpdated(actions: OrderDetailAction) {
    if (actions != null && actions != undefined) {
      this.orderDetail.config.orderActions = actions;
      this.updateOrderEditConfig();
    }
    else {
      this.UpdateSaveCancelVisibility();
    }
  }

  public onHeaderUpdated(actions: OrderDetailAction) {
    this.orderDetail.config.orderActions = actions;
    this.updateOrderEditConfig();
  }

  public onProgressUpdated(progress: Array<OrderProgress>) {
    this.orderDetail.header.progress = progress;
  }

  public onFlagAndNotify(): void {
    var markedItems = new String('');

    this.orderDetail.groups.forEach(gp => {
      gp.destinations.forEach(des => {
        if (des.isMarked == true)
          markedItems = markedItems.concat("Group " + gp.sequenceId.toString() + ": " + des.code + "\n");
      });
      gp.ftpDestinations.forEach(des => {
        if (des.isMarked == true)
          markedItems = markedItems.concat("Group " + gp.sequenceId.toString() + ": " + des.name + "\n");
      });
      gp.shareLinks.forEach(des => {
        if (des.isMarked == true)
          markedItems = markedItems.concat("Group " + gp.sequenceId.toString() + ": " + des.groupName + "\n")
      });
    });

    var initialState = {
      title: "Flag & Notify",
      comments: markedItems
    };

    this.modalRef = this.modalService.show(OrderStatusConfirmpopup, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onConfirm.subscribe(result => {
      this.onFlagAndNotifySubscriptionRaised(result);
    });
  }

  public onFlagAndNotifySubscriptionRaised(response: any) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.flagAndNotifyOrder(this.orderGuid, { comments: response.comments }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onOrderCancel() {

    var initialState = {
      title: "Cancel Order"
    };

    this.modalRef = this.modalService.show(OrderStatusConfirmpopup, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onConfirm.subscribe(result => {
      this.onCancelOrderSubscriptionRaised(result);
    });
  }

  public onCancelOrderSubscriptionRaised(response: any) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.sendOrderForCancel(this.orderGuid, { comments: response.comments }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onOrderEditStart() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.orderEditStart(this.orderGuid).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderEditStart, MessageSeverity.success);
        this.gs.orderEdited();

        var order = res.result.orderDetail as OrderDetail;

        if (order != null) {
          this.updateData(order);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onOrderEditEnd() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.orderEditEnd(this.orderGuid).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderEditEnd, MessageSeverity.success);
        this.gs.orderEdited();

        var order = res.result.orderDetail as OrderDetail;

        if (order != null) {
          this.updateData(order);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickSendForReview() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.sendOrderForReview(this.orderGuid, { comments: '' }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickSendToBilling() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.sendOrderToBilling(this.orderGuid, { comments: '' }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
        this.gs.orderEdited();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      var order = res.result.orderDetail as OrderDetail;

      if (order != null) {
        this.updateData(order);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

    this.onHideConfirmPop();
  }

  public showEditHistory() {

    var initialState = {
      objectId: this.orderGuid,
      objectCategory: 'order'
    };

    this.modalRef = this.modalService.show(EditHistoryPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onActionTrigger(action) {
    if (action == 'sendToBilling')
      this.onClickSendToBilling();
  }

  public onHideConfirmPop() {
    this.confirmpop.hide();
  }

  public onActionsUpdate(actions: OrderDetailAction) {
    this.orderDetail.config.orderActions = actions;
    this.updateOrderEditConfig();
  }

  public onOrderUpdate(order: OrderDetail) {
    this.updateData(order);
  }

  public isNonTextItemsExists(services: Array<ServiceData>): boolean {
    var exists = false;

    if (services == null)
      return exists;

    services.forEach(sdata => {
      sdata.serviceMetadata.forEach(mt => {
        if (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText])
          exists = true;
      });
    });

    return exists;
  }

  public getTextData(mdata: Array<ServiceMetadata>): string {
    var textData: Array<string> = [];

    mdata.forEach(mt => {
      if (this.isNonTextItem(mt) === false && this.isHiddenItem(mt) === false) {
        if (mt.textValue !== null && mt.textValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.HoursMins]) {
            if (mt.textValue != '0')
              textData.push(this.util.convertStringToHoursMins(mt.textValue));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.Dollar]) {
            textData.push(this.cp.transform(mt.textValue, 'USD', true));
          }
          else
            textData.push(mt.textValue);
        }
        else if (mt.arrayValue !== null && mt.arrayValue != '')
          textData.push(mt.arrayValue);
        else if (mt.objectValue !== null && mt.objectValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleMinsTransfers]) {

            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => this.util.convertStringToHoursMins(item.val1) + ' - ' + item.val2).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired]) {
            var res = this.util.convertStringToThreeRequiredObject(mt.objectValue);
            textData.push(res.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired]) {
            var resu = this.util.convertStringToFourRequiredObject(mt.objectValue);
            textData.push(resu.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3 + ' - ' + item.val4).join(' , '));
          }
          else {
            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => item.val1 + ' - ' + item.val2).join(' , '));
          }
        }
      }
    });

    if (textData.length > 0)
      return "(Options : " + textData.join(", ") + ")";
  }

  public isNonTextItem(mt: ServiceMetadata): boolean {
    return (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText]);
  }

  private isHiddenItem(mt: ServiceMetadata) {
    return mt.category === metadataCategoryEnum[metadataCategoryEnum.NA];
  }

  private copyServiceDataArray(sdataArray: Array<OrderDetailServiceData>): Array<ServiceData> {
    var newSdataArray: Array<ServiceData> = [];

    sdataArray.forEach(sdata => {

      let newSdata: ServiceData = {
        service: sdata.service, serviceMetadata: [], recentlyAdded: false, serviceId: sdata.service.id,
        containsServiceMetadataDataErrors: sdata.containsServiceMetadataDataErrors, errorMsg: sdata.errorMsg,
        optionsSubmitted: sdata.optionsSubmitted,
        parentServices: sdata.parentServices,
        displaySequence: sdata.displaySequence
      };

      sdata.serviceMetadata.forEach(sm => {
        newSdata.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
      });

      newSdataArray.push(newSdata);
    });

    return newSdataArray;
  }

  private copyServiceData(sdata: ServiceData): OrderDetailServiceData {
    let newSdata: OrderDetailServiceData = {
      service: sdata.service, serviceMetadata: [], recentlyAdded: false, serviceId: sdata.service.id,
      containsServiceMetadataDataErrors: sdata.containsServiceMetadataDataErrors, errorMsg: sdata.errorMsg,
      optionsSubmitted: sdata.optionsSubmitted,
      parentServices: sdata.parentServices,
      displaySequence: sdata.displaySequence,
      orderServiceId: 0
    };

    sdata.serviceMetadata.forEach(sm => {
      newSdata.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
    });

    return newSdata;
  }

  public onAddEditAddlServices(isEdit: boolean) {

    var additionalMetadata: OrderAdditionalMetadata = {
      orderGuid: this.orderGuid,
      spotTracCode: '',
      allowDestinationHub: false,
      allowSpecialOptions: this.orderDetail.config.orderActions.showSpecialFlags,
      //ppFileTitle: '',
      //ppFileAdId: '',
      ppFileSpecs: [],
      transcodePredefinedSpecs: this.orderDetail.config.transcodePredefinedSpecs,
      customFileNameOptions: this.orderDetail.config.customFileNameOptions
    };

    var initialState = {
      spotFileGuid: '',
      applyAllMode: false,
      editMode: isEdit,
      orderGuid: this.orderGuid,
      serviceData: this.copyServiceDataArray(this.orderDetail.header.serviceData),
      additionalMetadata: additionalMetadata,
      orderEditMode: true,
      showInEditMode: (this.orderDetail.config.orderActions.showInEditMode && (this.orderDetail.config.orderActions.canAddOrderLevelServices === true || this.orderDetail.config.orderActions.canUpdateSpecialServices == true)),
      specialServiceMode: this.orderDetail.config.orderActions.canUpdateSpecialServices,
      spotLevel: false,
      orderLevel: true,
      groupLevel: false
    };

    console.log(initialState);

    this.modalRef = this.modalService.show(OrderAddServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSelectServicesComplete.subscribe(result => {
      this.onApplyOrderServicesSubscriptionRaised(result);
    });

    this.modalRef.content.onServiceSubmitOptionsComplete.subscribe(result => {
      this.onSubmitOrderServiceOptionsRaised(result);
    });
  }

  private onApplyOrderServicesSubscriptionRaised(selectedServicesRequest: SpotSelectedServicesRequest) {
    var existingServices = this.orderDetail.header.serviceData;

    selectedServicesRequest.serviceData.forEach(sdata => {
      if (existingServices.find(t => t.service.id === sdata.service.id) == null) {
        //if (sdata.service.serviceConfig.options == null || sdata.service.serviceConfig.options.find(t => t.isOrderLevel == true) == null)
        existingServices.push(this.copyServiceData(sdata));
      }
    });

    existingServices = existingServices.filter(t => selectedServicesRequest.serviceData.some(f => f.service.id === t.service.id));

    this.orderDetail.header.serviceData = existingServices;

    //this.isServicesEdited = true;

    this.setError();
  }

  private onSubmitOrderServiceOptionsRaised(spotServicesOptionsRequest: SpotServicesOptionsRequest) {
    var existingServices = this.orderDetail.header.serviceData;

    var newServiceDataAfterDelete = existingServices.filter(t => spotServicesOptionsRequest.deletedServices.find(s => s == t.service.id) == null);

    spotServicesOptionsRequest.addedServices.forEach(aSer => {
      if (newServiceDataAfterDelete.find(t => t.serviceId == aSer.serviceId) == null) {
        if (this.orderDetail.config.orderActions.canUpdateSpecialServices == false
          || (this.orderDetail.config.orderActions.canUpdateSpecialServices == true && (aSer.service.isOrderSpecialEdit || aSer.service.isSpecialOptionEdit))) {
          newServiceDataAfterDelete.push(this.copyServiceData(aSer));
        }
      }
    });

    spotServicesOptionsRequest.serviceData.forEach(servItem => {
      var serviceData = newServiceDataAfterDelete.find(t => t.service.id === servItem.service.id);

      if (serviceData != null) {

        if (this.orderDetail.config.orderActions.canUpdateSpecialServices == false
          || (this.orderDetail.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isOrderSpecialEdit || serviceData.service.isSpecialOptionEdit))) {

          let tagsToRemove: Array<string> = [];

          if (this.orderDetail.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isOrderSpecialEdit || serviceData.service.isSpecialOptionEdit)) {
            if (serviceData.service.isSpecialOptionEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.filter(t => t.isSpecialOption == true).map(a => a.tag);
            else if (serviceData.service.isOrderSpecialEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.map(a => a.tag);

            serviceData.serviceMetadata = serviceData.serviceMetadata.filter(t => !tagsToRemove.includes(t.metadataKey));
          }
          else {
            serviceData.serviceMetadata = [];
          }

          console.log(serviceData.service.serviceConfig.options, tagsToRemove);

          servItem.serviceMetadata.forEach(sm => {
            if (this.orderDetail.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isOrderSpecialEdit || serviceData.service.isSpecialOptionEdit)) {

              if (tagsToRemove.includes(sm.metadataKey))
                serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
            else {
              serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
          });
        }

        serviceData.optionsSubmitted = true;
      }
      else {
        console.log("not exist", servItem.service);
      }
    });

    this.orderDetail.header.serviceData = newServiceDataAfterDelete;

    //this.isServicesEdited = true;

    this.setError();
  }

  public onServiceDelete(id: number) {
    var existingServices = this.orderDetail.header.serviceData;

    if (existingServices && existingServices != null && existingServices.length > 0) {
      var newServicesAfterDelete = existingServices.filter(t => t.serviceId != id);

      this.orderDetail.header.serviceData = newServicesAfterDelete;
    }

    //this.isServicesEdited = true;

    this.setError();
  }

  private setError() {

    this.isServicesEdited = true;

    this.UpdateSaveCancelVisibility();

    this.serviceErrors = [];

    this.containsServiceErrors = false;
    var existingServices = this.orderDetail.header.serviceData;

    existingServices.forEach(s => {

      s.errorMsg = '';

      if (s.service.isAdditionalDataNeeded == true && this.util.isServiceMetadataValid(s.service.code, s.serviceMetadata, true, false, false, false, this.orderDetail.config.orderActions.showSpecialFlags) == false) {
        s.containsServiceMetadataDataErrors = true;
        s.errorMsg = Constants.orderError_OptionsMissing;
      }
      else
        s.containsServiceMetadataDataErrors = false;
    });

    if (existingServices.some(t => t.containsServiceMetadataDataErrors == true)) {
      this.containsServiceErrors = true;
    }
    else {
      this.containsServiceErrors = false;
    }

    this.orderDetail.header.serviceData = existingServices;
  }

  public onCollapse() {
    (<any>$(".orders-section .collapse")).collapse('hide');
  }

  public onCopyOrderGuid() {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', this.orderGuid);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public onSendOrderEmail() {
    var initialState = {
      orderGuid: this.orderGuid,
      isDraftOrder: false
    };

    this.modalRef = this.modalService.show(SendorderemailpopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onOrderEmailSentCallback.subscribe((result: boolean) => {
      this.orderDetail.config.orderActions.isReSendOrderEmail = true;
      console.log(result);
    });
  }

  public onTraceOrder() {
    this.router.navigate([RouteConstants.orderTraceRoute, this.orderGuid]);
  }

  public onSaveChanges(isOrderValid: boolean) {

    this.triedToSave = true;

    this.chs.forEach(p => {
      p.triedToSave = true;
      p.setGroupError();
    });

    this.orderDetail.groups.forEach(p => {
      if (p.containsGroupErrors == true && p.isDeleted != true)
        isOrderValid = false;
    });

    if (this.containsServiceErrors == true)
      isOrderValid = false;

    let isGroupsValid: boolean = this.isGroupValidationPassed();

    if (isOrderValid == false || isGroupsValid == false) {
      this.alertService.showMessage("ERROR", Constants.submitMandatoryFields, MessageSeverity.error);
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var request: any = { orderServices: this.orderDetail.header.serviceData, groups: this.orderDetail.groups };

    this.orderService.editOrder(request, this.orderGuid).subscribe((res: any) => {
      this.alertService.logMessage(res);

      if (res.isSuccess == true) {

        this.alertService.showMessage("SUCCESS", Constants.orderUpdated, MessageSeverity.success);

        this.gs.orderEdited();

        var order = res.result.orderDetail as OrderDetail;

        if (order != null) {
          this.updateData(order);
        }
      }
      else {

        var errors = res.errors as any[];

        if (errors !== null && errors.length > 0) {
          this.util.handleIsNotSuccess(errors);
        }
        else {
          this.alertService.showMessage("ERROR", Constants.orderSubmitErrors, MessageSeverity.error);

          var orderErrors = res.result.orderErrors as OrderErrors;

          if (orderErrors != null) {

            if (orderErrors.spotResendWarnings != null && orderErrors.spotResendWarnings.length > 0) {

              var initialState = {
                spotResendWarnings: orderErrors.spotResendWarnings,
              };

              this.modalRef = this.modalService.show(SpotResendPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));
            }

            if (orderErrors.groupErrors != null && orderErrors.groupErrors.length > 0) {
              this.chs.forEach(p => {

                var gpError = orderErrors.groupErrors.find(t => t.groupIdentifer == p.group.sequenceId);

                if (gpError != null)
                  p.setAddGroupError(gpError);

              });
            }

            if (orderErrors.headerErrors != null && orderErrors.headerErrors.length > 0) {
              this.serviceErrors = this.serviceErrors.concat(orderErrors.headerErrors);
            }
          }
        }
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private isGroupValidationPassed(): boolean {

    let spotDest: Array<SpotDestinationComb> = [];
    let isValid: boolean = true;

    for (var i = 0; i <= this.orderDetail.groups.length - 1; i++) {
      var gp = this.orderDetail.groups[i];
      var spots = gp.spotData;
      var destinations = gp.destinations;
      var groupId = gp.sequenceId;

      if (gp.isDeleted)
        continue;

      spots.forEach(sd => {

        let adid: string = sd.ppSpotFile == null ? sd.spotFile.adId.toLowerCase() : sd.ppSpotFile.adId.toLowerCase();

        sd.serviceData.forEach(ser => {
          if (ser.service.category.toLowerCase() == OrderDetailContentComponent.DIGITALDELIVERY && ser.service.isDigital == true) {
            destinations.forEach(des => {
              /**tylie File Distribution changes**/
              //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() != 'CLEARANCE') {
              if (des.destinationType != null && des.destinationType.toUpperCase() != 'CLEARANCE') {
                let dcode = des.code.toLowerCase();
                let existingEntry = spotDest.find(t => t.adid == adid && t.code == dcode);

                if (existingEntry != null) {
                  des.errorMsg = des.errorMsg + `Already exists for ${adid.toUpperCase()} in Group ${existingEntry.seq}. `;
                  des.containsDestinationError = true;
                  gp.containsGroupErrors = true;
                  isValid = false;
                }
                else {
                  spotDest.push({ adid: adid, code: dcode, seq: groupId });
                }
              }
            });
          }
        });

        sd.serviceData.forEach(ser => {
          if (ser.service.isClearance == true) {
            /**tylie File Distribution changes**/
            destinations.forEach(des => {
            //  if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'CLEARANCE') {
              if (des.destinationType != null && des.destinationType.toUpperCase() == 'CLEARANCE') {
                let dcode = des.code.toLowerCase();
                let existingEntry = spotDest.find(t => t.adid == adid && t.code == dcode);

                if (existingEntry != null) {
                  des.errorMsg = des.errorMsg + `Already exists for ${adid.toUpperCase()} in Group ${existingEntry.seq}. `;
                  des.containsDestinationError = true;
                  gp.containsGroupErrors = true;
                  isValid = false;
                }
                else {
                  spotDest.push({ adid: adid, code: dcode, seq: groupId });
                }
              }
            });
          }
        });
      });
    }

    return isValid;
  }

  private onSaveChangesBeforeLeavingPage(isOrderValid: boolean, groupSeqId: number, e: RouteToTranscodeEmitData) {

    this.triedToSave = true;

    this.chs.forEach(p => {
      p.triedToSave = true;
      p.setGroupError();
    });

    this.orderDetail.groups.forEach(p => {
      if (p.containsGroupErrors == true && p.isDeleted != true)
        isOrderValid = false;
    });

    if (this.containsServiceErrors == true)
      isOrderValid = false;

    let isGroupsValid: boolean = this.isGroupValidationPassed();

    if (isOrderValid == false || isGroupsValid == false) {
      this.alertService.showMessage("ERROR", Constants.submitMandatoryFields, MessageSeverity.error);
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var request: any = { orderServices: this.orderDetail.header.serviceData, groups: this.orderDetail.groups , saveSource: 'Transcode'};

    this.orderService.editOrder(request, this.orderGuid).subscribe((res: any) => {
      this.alertService.logMessage(res);

      if (res.isSuccess == true) {

        this.alertService.showMessage("SUCCESS", Constants.orderUpdated, MessageSeverity.success);

        this.gs.orderEdited();

        var order = res.result.orderDetail as OrderDetail;

        this.anyChangesDetected = false;
        this.anyChangesDetectedInNonExclusiveEdit = false;

        if (order != null) {
          this.anyChangesDetected = false;
          this.router.navigate([RouteConstants.orderTrancodesRoute], {
            state: {
              transcodeOrigination: 'OrderEdit', groupSequenceId: groupSeqId, orderGuid: this.orderGuid, orderGrpTranscodeEditSpotFileGuid: e.spotFileGuid
            }
          });
        }
      }
      else {

        var errors = res.errors as any[];

        if (errors !== null && errors.length > 0) {
          this.util.handleIsNotSuccess(errors);
        }
        else {
          this.alertService.showMessage("ERROR", Constants.orderSubmitErrors, MessageSeverity.error);

          var orderErrors = res.result.orderErrors as OrderErrors;

          if (orderErrors != null) {

            if (orderErrors.spotResendWarnings != null && orderErrors.spotResendWarnings.length > 0) {

              var initialState = {
                spotResendWarnings: orderErrors.spotResendWarnings,
              };
              this.modalRef = this.modalService.show(SpotResendPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));
            }

            if (orderErrors.groupErrors != null && orderErrors.groupErrors.length > 0) {
              this.chs.forEach(p => {

                var gpError = orderErrors.groupErrors.find(t => t.groupIdentifer == p.group.sequenceId);

                if (gpError != null)
                  p.setAddGroupError(gpError);
              });
            }

            if (orderErrors.headerErrors != null && orderErrors.headerErrors.length > 0) {
              this.serviceErrors = this.serviceErrors.concat(orderErrors.headerErrors);
            }
          }
        }
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onRouteToTranscodeRequests(e: RouteToTranscodeEmitData, groupSeqId: number) {
    this.onSaveChangesBeforeLeavingPage(true, groupSeqId, e);
    //if (this.hasUnsavedData) {
    //  var initialState = {
    //    message: "You are leaving this page! unsaved changes will be saved automatically."
    //  };

    //  this.modalRef = this.modalService.show(UnSavedDataWarningComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    //  this.modalRef.content.subject.subscribe(result => {
    //    if (result == true) {
    //      this.onSaveChangesBeforeLeavingPage(true, groupSeqId, e)
    //    }
    //  });
    //}
  }
}
