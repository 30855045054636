import { DestinationContactGroup, VendorDestination } from "./createdestination.model";

export class DestinationDetail {
  public code: string;
  public name: string;
  public destinationGuid: string;
  public destinationTypeId: number;
  public destinationType: string;
  public akas: string;
  public status: string;
  public statusId: number;
  public defaultHub: string;
  public primaryDestination: string;
  public market: string;
  public displayMarket: string;
  public acceptableFormats: number[];
  public acceptableFormatsDisplay: string[];
  public preferredFormat: number;
  public secondaryFormat: number;
  public preferredFormatDisplay: string;
  public secondaryFormatDisplay: string;
  public isCompanionFormatRequired: boolean;
  public isTrafficOnly: boolean;
  public preferredVendor: string;
  public preferredVendorId: number;
  // public fileDeliveryType: string;
  //public fileDeliveryTypeId: number;
  public comments: string;
  public destinationContact: DestinationContactGroup;
  public vendorDestinationMappings: VendorDestination[];
  public config: DestinationDetailConfig;
  public destinationMetaData: DestinationMetaData;
  //public ftpServer: string;
  //public ftpUserName: string;
  // public ftpPassword: string;
  // public ftpPort: string;

  // public linkEmails: string;
  //public isHouseIdRequiredForDownload: boolean;
  //public specifications: any[];
  //public bxfXML: string;
  //public bxfXMLByteArray: any[];
}

export class DestinationDetailConfig {
  public showEdit: boolean;
  public showActivateDestination: boolean;
  public showInActivateDestination: boolean;

  public showFTPConfig: boolean;
  public showLinksConfig: boolean;
  public ShowS3Config: boolean;
}

export class DestinationMetaData {
  public isHouseIdRequiredForDownload: boolean;
  public specifications: any[];
  public bxfXML: string;
  public bxfXMLByteArray: any[];
  public deliveryType: string;
  public deliveryTypeId: number;
  public server: string;
  public userName: string;
  public password: string;
  public port: string;
  public emails: string;
  public notes: string;
  public s3: S3Config;
  public aspera: AsperaConfig;
  public googleDrive: GDriveConfig;
  public dropBox: DropBoxConfig;
  public transcodeCustomFileName: string;
  public transcodeCustomFileNameSeperator: string;
  public acceptsDescriptiveVideo: boolean
}

export class AsperaConfig {
  public endPoint: string;
  public password: string;
  public subFolder: string;
  public user: string;
  public port: string;

}
export class S3Config {
  public bucket: string;
  public path: string;
}

export class GDriveConfig {
  public folderPath: string;
  public folderId: string;
}

export class DropBoxConfig {
  public folderPath: string;
}
