export class ClientFileDeliveryConfig {
  public id: string;
  public configType: string;
  public accountName: string;
  public clientId: number;
  public clientSecret: string;
  public refreshToken: string;
  public googleAdsLoginCustomerId: string;
  public googleAdsCustomerId: boolean;
}

export class CreateClientDeliveryConfig {
  public fileDeliveryConfigurations: ClientFileDeliveryConfig[];
}


export class AccountNameValueChangedEvent {
  public formGroupNumber: number;
  public accountName: string;
}
