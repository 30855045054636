import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { OrderService } from "../../../services/order/order.service";
import { ConfigService } from "../../../services/core/config.service";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'userselectionpopup',
  templateUrl: './userselectionpopup.component.html',
  styleUrls: ['./userselectionpopup.component.css'],
})
export class UserSelectionPopupComponent extends BasePopupComponent {

  public userSelectionRequest: FormGroup;
  public onClose: Subject<string>;

  @Input('userTypeDataSource') userTypeDataSource: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private orderService: OrderService,
    private configService: ConfigService) {

    super();

    this.userSelectionRequest = new FormGroup({
      userType: new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.onClose = new Subject();
  }

  public onFormSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (valid == false)
      return

    this.onClose.next(value.userType);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
