<ng-container>
  <div class="modal-header">
    <h4 class="modal-title">Send Order Email</h4>
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="sendOrderEmailForm" (submit)="onSendOrderEmailFormSubmit(sendOrderEmailForm)" novalidate #ngForm="ngForm">

      <div class="col-md-6">
        <div class="tylie-form-group form-group ">
          <label class="tylie-form-group__lbl">Vendors</label>
          <dx-select-box class="tylie-select form-control"
                         [dataSource]="vendorDataSource"
                         id="vendorId"
                         name="vendorName"
                         [searchEnabled]="true"
                         [showClearButton]="true"
                         valueExpr="vendorId"
                         displayExpr="vendorName"
                         (onValueChanged)="onVendorValueChanged($event)"
                         placeholder="Select a vendor...">
          </dx-select-box>
        </div>
      </div>

      <div class="tylie-form-group form-group" [ngClass]="{error: (sendOrderEmailForm.controls['sendOrderEmails'].errors && !sendOrderEmailForm.controls['sendOrderEmails'].pristine)
                                            || (sendOrderEmailForm.controls['sendOrderEmails'].errors && ngForm.submitted)}">
        <label class="tylie-form-group__lbl" for="sendOrderEmails">
          Email<span class="required">*</span>
        </label>
        <textarea id="sendOrderEmails" name="sendOrderEmails" class="tylie-textarea tylie-textarea--md form-control" formControlName="sendOrderEmails" placeholder="semi-colon seperated"></textarea>
        <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Send</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</ng-container>
