import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from "rxjs";
import { AuthService } from '../auth/auth.service';
import { LocalStoreManager } from '../core/local-store-manager.service';
import { EndpointFactory } from '../../configurations/endpoint-factory.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AlertService } from '../core/alert.service';


@Injectable()
export class MediaService {

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private authService: AuthService) { }

  public getMedia(): Observable<Object> {
    return this.http.get(this.apiendPoint.getMediaEndpointQC());
  }
}
