import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { SendEmailOrderModel, SendOrderRecipients } from '../../../models/admin/client/client';
import { AlertService } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { OrderService } from '../../../services/order/order.service';

@Component({
  selector: 'app-sendorderemailpopup',
  templateUrl: './sendorderemailpopup.component.html',
  styleUrls: ['./sendorderemailpopup.component.css']
})
export class SendorderemailpopupComponent implements OnInit {

  @Input('orderGuid') orderGuid: string;
  @Input('isDraftOrder') isDraftOrder: boolean;

  public modalRef: BsModalRef;
  public sendOrderEmailForm: FormGroup;
  public vendorDataSource: any = [];

  public onOrderEmailSentCallback: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private alertService: AlertService,
    public util: Utilities,
    private orderService: OrderService,
    private configService: ConfigService)
  {
    this.onOrderEmailSentCallback = new Subject<boolean>();
  }

  ngOnInit() {
    console.log(this.orderGuid);
    this.sendOrderEmailForm = this.fb.group({
      sendOrderEmails: [null, Validators.compose([EmailListValidatorIfValueExists, Validators.required])]
    });

    this.getVendors();
  }

  public getVendors() {
    this.alertService.ShowLoader(true);

    this.configService.getVendorsForSendOrerEmail().subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        if (res.result != null && res.result != undefined) {
          this.vendorDataSource = res.result;
        }
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onVendorValueChanged(e) {
    console.log(e);
    var vendor = this.vendorDataSource.find(item => item.vendorId === e.value);
    console.log(vendor);
    if (vendor != null && vendor != undefined) {
      this.sendOrderEmailForm.controls['sendOrderEmails'].setValue(vendor.email);
    } else {
      this.sendOrderEmailForm.controls['sendOrderEmails'].setValue(null);
    }
  }

  public onSendOrderEmailFormSubmit({ value, valid }: { value: SendOrderRecipients, valid: boolean }) {
    if (!valid) {
      return;
    }

    if (value.sendOrderEmails != null && value.sendOrderEmails != undefined) {
      var request = new SendEmailOrderModel();
      request.isDraftOrder = this.isDraftOrder;
      request.orderGuid = this.orderGuid;
      request.toEmails = value.sendOrderEmails;

      console.log(request);

      this.sendOrderEmail(request);
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public sendOrderEmail(request) {
    this.alertService.ShowLoader(true);
    console.log(request);
    this.orderService.sendOrderEmail(request).subscribe((res: any) => {
      console.log(res);
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.onOrderEmailSentCallback.next(true);
        this.closePopup();
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

}
