import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-descriptivevideo-reapplication',
  templateUrl: './servicedescriptivevideoreapplication.component.html'
})
export class ServiceDescriptiveVideoReApplicationQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
