import { Component, OnInit, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { MediaDetailService } from "../../../services/mediadetail/mediadetail.service";
import { OrderService } from '../../../services/order/order.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../services/user/user-profile.service';
import { UserRoles } from '../../../configurations/user-roles';

@Component({
  selector: 'spotorderhistorypopup',
  templateUrl: './spotorderhistorypopup.component.html',
  styleUrls: ['./spotorderhistorypopup.component.css'],
})
export class SpotOrderHistoryPopupComponent extends BasePopupComponent {

  public spotFileGuid: string;
  public spotOrderHistoryData: any;
  public hasOrderViewRole = this.userProfileService.getUserRoles().includes(this.userRoles.getOrderViewerRoleName());

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private mediaDetailService: MediaDetailService,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.mediaDetailService.getOrderHistoryOfSpot(this.spotFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.spotOrderHistoryData = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.closePopup();
      }
    },
      error => {
        this.util.handleError(error);
        this.closePopup();
      });
  }

  public showOrderDetail(orderGuid: string): void {

    this.util.showOrderDetailInNewWindow(orderGuid);    
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
