<div class="tylie-panel" [formGroup]="destinationContact">
  <div class="tylie-panel__header">
    <h4 class="tylie-panel__title">
      CONTACT DETAILS
    </h4>
  </div>
  <div class="tylie-panel__body">
    <div formArrayName="destinationContacts">
      <div *ngFor="let destContact of destinationContactsFormGroupArray.controls; let i = index">
        <div [formGroup]="destContact">
          <hr class="seperator" *ngIf="destinationContactsFormGroupArray.controls[i].controls['isMainContact']?.value == false" />
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: (destinationContactsFormGroupArray.controls[i].controls['name'].errors && !destinationContactsFormGroupArray.controls[i].controls['name'].pristine)
                || (destinationContactsFormGroupArray.controls[i].controls['name'].errors && f.submitted)}">
                <label for="name" class="tylie-form-group__lbl">Name</label>
                <input type="text" name="name" id="name" formControlName="name" class="tylie-text form-control">
                <p class="p-extra-small">Please enter a contact name</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="adId">Title</label>
                <input type="text" name="title" id="title" formControlName="title" class="tylie-text form-control">
                <p class="p-extra-small">Please enter a title</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="destinationContactsFormGroupArray.controls[i].controls['isMainContact']?.value == true">
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label for="Address1" class="tylie-form-group__lbl">Address 1<span class="required">*</span></label>
                <input type="text" id="address1" name="address1" formControlName="address1" class="tylie-text form-control " />
                <p class="p-extra-small">Please enter Address 1</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label for="Address2" class="tylie-form-group__lbl">Address 2</label>
                <input type="text" id="address2" name="address2" formControlName="address2" class="tylie-text form-control " />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="destinationContactsFormGroupArray.controls[i].controls['isMainContact']?.value == true">
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="City">City<span class="required">*</span></label>
                <input type="text" id="city" name="city" formControlName="city" class="tylie-text form-control " />
                <p class="p-extra-small">Please enter City</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group">
                <label for="state" class="tylie-form-group__lbl">State</label>
                <dx-select-box [dataSource]="states"
                                name="state" id="state" class="tylie-select form-control"
                                formControlName="state"
                                [searchEnabled]="true"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select State">
                </dx-select-box>
                <p class="p-extra-small">Please choose a state</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (destinationContactsFormGroupArray.controls[i].controls['zipCode'].errors && !destinationContactsFormGroupArray.controls[i].controls['zipCode'].pristine)
              || (destinationContactsFormGroupArray.controls[i].controls['zipCode'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="ZipCode">Zip Code<span class="required">*</span></label>
                <input type="text" id="zipCode" name="zipCode" formControlName="zipCode" class="tylie-text form-control " />
                <p class="p-extra-small">Please enter a valid Zip Code</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group " [ngClass]="{error: (destinationContactsFormGroupArray.controls[i].controls['phone'].errors && !destinationContactsFormGroupArray.controls[i].controls['phone'].pristine)
              || (destinationContactsFormGroupArray.controls[i].controls['phone'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="phone">Phone<span class="required">*</span></label>
                <input type="text" id="phone" name="phone" formControlName="phone" class="tylie-text form-control " [textMask]="{mask: phoneMask, guide: false, modelClean: true}" />
                <p class="p-extra-small">Please enter a valid Phone</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="fax">Fax</label>
                <input type="text" id="fax" name="fax" formControlName="fax" class="tylie-text form-control " />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="tylie-form-group form-group" [ngClass]="{error: (destinationContactsFormGroupArray.controls[i].controls['email'].errors && !destinationContactsFormGroupArray.controls[i].controls['email'].pristine)
              || (destinationContactsFormGroupArray.controls[i].controls['email'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="TeamEmail">Emails (semi-colon separated)<span class="required">*</span></label>
                <textarea type="text" id="email" name="email" formControlName="email" class="tylie-text form-control"></textarea>
                <p class="p-extra-small">Please enter a valid Email</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="destinationContactsFormGroupArray.controls[i].controls['isMainContact']?.value == false">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: (destinationContactsFormGroupArray.controls[i].controls['contactTypeId'].errors
                    && !destinationContactsFormGroupArray.controls[i].controls['contactTypeId'].pristine) || (destinationContactsFormGroupArray.controls[i].controls['contactTypeId'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="contactTypeId">Contact Type<span class="required">*</span></label>
                <dx-select-box [dataSource]="contactTypes"
                                name="contactTypeId" id="contactTypeId" class="tylie-select form-control"
                                formControlName="contactTypeId"
                                [searchEnabled]="true"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                (onValueChanged)="onContactTypeValueChanged($event, i)"
                                placeholder="Select Contact Type">
                </dx-select-box>
                <p class="p-extra-small">Please choose a Contact type</p>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDestinationContactDelete(i)" *ngIf="destinationContactsFormGroupArray.controls[i].controls['isMainContact']?.value == false">
              <span class="tylie-button__icon"><icon-trash-can></icon-trash-can></span>
              DELETE CONTACT
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="tyl-clickable">
      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddAnotherDestinationContact()">
        <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
        ADD ANOTHER
      </button>
    </div>

  </div>
</div>

