<div id="order-details-section">
  <div class="order-intractions" *ngIf="orderDetail">
    <div class="row">
        <div class="col-md-4">
          <h2 class="tylie-type-h2">ORDER# {{orderDetail.workOrder}}</h2>
          <div class="tylie-data tylie-data--c-block">
            <span class="tylie-data__lbl">
              Submitted on
            </span>
            <span class="tylie-data__txt">
              {{orderDetail.header.createdOn | usertimezone: 'LL' }}
              at {{orderDetail.header.createdOn | usertimezone: 'hh:mm A'}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block">
            <span class="tylie-data__lbl">
              Submitted by
            </span>
            <span class="tylie-data__txt">
              {{orderDetail.header.createdBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block" *ngIf="orderDetail.config.orderActions.isExclusiveEdit == true">
            <span class="tylie-data__lbl">
              Edit Expires on
            </span>
            <span class="tylie-data__txt">
              {{orderDetail.header.editExpiryOn | usertimezone: 'LL' }} at {{orderDetail.header.editExpiryOn | usertimezone: 'hh:mm A'}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block" *ngIf="orderDetail.config.orderActions.showEditStartedBy == true">
            <span class="tylie-data__lbl">
              Open for Edit by
            </span>
            <span class="tylie-data__txt">
              {{orderDetail.header.editStartedBy}}
            </span>
          </div>
          <div class="tylie-data tylie-data--c-block" *ngIf="orderDetail.config.orderActions.showClaimedBy">
            <span class="tylie-data__lbl">
              Claimed By
            </span>
            <span class="tylie-data__txt">
              {{orderDetail.header.claimedBy}}
            </span>
          </div>
        </div>
        <div class="col-md-8">
          <div class="d-flex justify-content-end">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showSendOrderEmailButton == true" (click)="onSendOrderEmail()">
              <span class="tylie-button__icon"><icon-open-envelope></icon-open-envelope></span>
              <span class="tylie-button__text" *ngIf="orderDetail.config.orderActions.isReSendOrderEmail == true">
                Re-Send Order Email
              </span>
              <span class="tylie-button__text" *ngIf="orderDetail.config.orderActions.isReSendOrderEmail == false">
                Send Order Email
              </span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.isAllowedToTraceOrder == true" (click)="onTraceOrder()">
              <span class="tylie-button__icon"><icon-trace></icon-trace></span>
              <span class="tylie-button__text">Trace Order</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showBillingItems == true" (click)="onClickShowBillingItems()">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-list-alt"></i></span>
              <span class="tylie-button__text">Billing Items</span>
            </button>

            <ng-container *ngIf="orderDetail.config.orderActions.showEditHistory == true">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3 approved" (click)="showEditHistory()">
                <span class="tylie-button__icon">
                  <icon-edit-history></icon-edit-history>
                </span>
                <span class="tylie-button__text">Edit History</span>
              </button>
            </ng-container>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showEditButton == true" (click)="onOrderEditStart()">
              <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
              <span class="tylie-button__text">Edit Order</span>
            </button>

            <ng-container *ngIf="orderDetail.config.orderActions.isAllowedToCompleteOrderProcessing == true">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3 approved" (click)="onClickCompleteOrderProcessing()">
                <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
                <span class="tylie-button__text">Complete</span>
              </button>
            </ng-container>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showSendToBilling == true"
                    outsideClick="true" placement="left" #confirmpop="bs-popover"
                    [popoverContext]="{message: 'Are you sure you would like to Send to Billing?', action: 'sendToBilling'}"
                    container="body" [popover]="confirmTemplate">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-list-alt"></i></span>
              <span class="tylie-button__text">Send To Billing</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showViewOPsSummaryButton == true" (click)="onClickOpsSummary()">
              <span class="tylie-button__icon"><icon-browse></icon-browse></span>
              <span class="tylie-button__text">OPs Summary</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showCreatePO == true" (click)="onClickCreatePO()">
              <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
              <span class="tylie-button__text">Create PO</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showViewPO == true" (click)="onClickViewPO()">
              <span class="tylie-button__icon"><icon-browse></icon-browse></span>
              <span class="tylie-button__text">View PO</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showViewPOD == true" (click)="onClickViewPOD()">
              <span class="tylie-button__icon"><icon-browse></icon-browse></span>
              <span class="tylie-button__text">View POD</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showSendForReview == true" (click)="onClickSendForReview()">
              <!--<span class="tylie-button__icon"><i class="glyphicon glyphicon-send"></i></span>-->
              <span class="tylie-button__text">Send For Review</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.isAllowedToSendOrderToAE == true" (click)="onClickSendOrderToAE()">
              <!--<span class="tylie-button__icon"><i class="glyphicon glyphicon-send"></i></span>-->
              <span class="tylie-button__text">Send For Review</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showSetToReadyForProcess == true" (click)="onClickSetOrderBackToReadyForProcess()">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-send"></i></span>
              <span class="tylie-button__text">Set To Ready For Process</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showReviewClaim == true" (click)="onClickAwaitingReviewClaim()">
              <span class="tylie-button__icon"><icon-claim></icon-claim></span>
              <span class="tylie-button__text">Claim</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showStartProcessClaim == true" (click)="onClickStartProcessClaim()">
              <span class="tylie-button__icon"><icon-claim></icon-claim></span>
              <span class="tylie-button__text">Claim</span>
            </button>

            <ng-container *ngIf="orderDetail.config.orderActions.isAllowedToApprove == true">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon approved me-3" (click)="onClickApproveOrderReview()">
                <span class="tylie-button__icon">
                  <icon-checkmark></icon-checkmark>
                </span>
                <span class="tylie-button__text">Approve</span>
              </button>
            </ng-container>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" *ngIf="showSaveChanges == true" (click)="onSaveChanges(true)">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon glyphicon-floppy-disk"></i></span>
              <span class="tylie-button__text">Save Edits</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" *ngIf="anyChangesDetectedInNonExclusiveEdit == true" (click)="onCancelChanges()">
              <span class="tylie-button__icon"><icon-expire color="#1e90ff"></icon-expire></span>
              <span class="tylie-button__text">Close Edits</span>
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.isExclusiveEdit == true" (click)="onOrderEditEnd()">
              <span class="tylie-button__icon"><icon-expire color="#1e90ff"></icon-expire></span>
              Close Edits
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showCancelClaim == true" (click)="onClickReleaseClaimedOrder()">
              <span class="tylie-button__icon"><icon-expire color="#1e90ff"></icon-expire></span>
              Cancel Claim
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.isAllowedToCompleteOrderProcessing == true" (click)="onClickReleaseOrderClaimedForProcessing()">
              <span class="tylie-button__icon"><icon-expire color="#1e90ff"></icon-expire></span>
              Cancel Claim
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" *ngIf="orderDetail.config.orderActions.showFlagAndNotify == true" (click)="onFlagAndNotify()">
              <span class="tylie-button__icon"><i class="glyphicon glyphicon-flag p-normal tyl-vertical-align"></i></span>
              Flag & Notify
            </button>

            <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" *ngIf="orderDetail.config.orderActions.showCancelOrder == true" (click)="onOrderCancel()">
              <span class="tylie-button__icon"><icon-expire color="#1e90ff"></icon-expire></span>
              Cancel Order
            </button>

          </div>
        </div>
    </div>

    <order-header-detail [header]="orderDetail.header"
                         [orderGuid]="orderDetail.orderGuid"
                         (headerUpdated)="onHeaderUpdated($event)"
                         [progressBarDivider]="100/(orderDetail.header.progress.length - 1)"
                         [config]="orderDetail.config">
    </order-header-detail>

    <div class="tylie-accordion accordion" id="orders-section">
      <div class="tylie-accordion__panel accordion-item" id="build-order-section">
          <h2 class="tylie-accordion__heading accordion-header" (click)="onCollapse()">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#buildOrderCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Order Details
            </button>
          </h2>
          <div id="buildOrderCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
            <div class="tylie-accordion__body accordion-body pt-3">
              <ul class="tylie-tab-nav nav nav-tabs mx-4">
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showOrdersTab">
                  <button class="tylie-tab-nav__lnk nav-link active" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" (click)="activeTab = 'orders'" [class.active]="activeTab == 'orders'">ORDERS</button>
                </li>
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showSpotsTab"
                  [class.tyl-error-panel]="orderDetail.config.orderActions.showSpotVendorAssignmentFailedError">
                  <button class="tylie-tab-nav__lnk nav-link" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" (click)="activeTab = 'spots'"  [class.active]="activeTab == 'spots'">SPOTS</button>
                </li>
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showDestinationsTab"
                  [class.tyl-error-panel]="orderDetail.config.orderActions.showVendorAssignmentFailedError">
                  <button class="tylie-tab-nav__lnk nav-link" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" (click)="activeTab = 'destinations'"  [class.active]="activeTab == 'destinations'">DESTINATIONS</button>
                </li>
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showFTPTab">
                  <button class="tylie-tab-nav__lnk nav-link" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" [class.active]="activeTab == 'ftpupload'" (click)="activeTab = 'ftpupload'">FTP UPLOAD</button>
                </li>
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showShareLinksTab">
                  <button class="tylie-tab-nav__lnk nav-link" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" [class.active]="activeTab == 'sharelinks'" (click)="activeTab = 'sharelinks'">SHARE LINKS</button>
                </li>
                <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngIf="orderDetail.config.orderActions.showContentDownloadTab">
                  <button class="tylie-tab-nav__lnk nav-link" data-bs-toggle="tab" data-bs-target="#orderview" type="button" role="tab" aria-selected="true" [class.active]="activeTab == 'contentdownload'" (click)="activeTab = 'contentdownload'">DOWNLOADS</button>
                </li>
              </ul>
                  

              <div class="tylie-tab-content tab-content">
                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'orders'">
                  <div *ngFor="let grp of orderDetail.groups; let i=index">
                    <order-group-detail [group]="grp"
                                        [header]="orderDetail.header"
                                        [config]="orderDetail.config"
                                        [totalSavedGroups]="totalSavedGroups"
                                        [newGroupServiceLevel]="false"
                                        (groupDeleted)="onDeleteGroup(grp.orderGroupId, grp.sequenceId)"
                                        (groupUpdated)="onGroupUpdated($event)"
                                        (progressUpdated)="onProgressUpdated($event)"
                                        (addMediaToAllGroups)="onAddMediaToAllGroups($event)"
                                        (routeToTranscodeRequestsClicked)="onRouteToTranscodeRequests($event, grp.sequenceId)"
                                        [orderGuid]="orderDetail.orderGuid"
                                        *ngIf="grp.isDeleted != true">
                    </order-group-detail>
                  </div>

                  <div class="d-flex justify-content-end mb-3" *ngIf="orderDetail.config.orderActions.canAddGroup === true">
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewGroup()">
                        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                        <span class="tylie-button__text">Add Group</span>
                      </button>
                  </div>

                  <div class="tylie-accordion accordion">
                    <div class="tylie-accordion__panel accordion-item">
                      <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (containsServiceErrors === true && triedToSave === true) }">
                        <button class="tylie-accordion__heading-btn accordion-button"  type="button" data-bs-toggle="collapse" data-bs-target="#addlServicesPanel" aria-expanded="true" aria-controls="collapseOne">
                          ADDITIONAL SERVICES
                        </button>
                      </h2>
                      
                      <div id="addlServicesPanel" class="tylie-accordion__content accordion-collapse collapse show">
                        <div class="tylie-accordion__body accordion-body">
                          <div class="tylie-accordion__body-content">
                            <section class="tylie-panel tylie-panel--secondary">
                              
                              <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="orderDetail.header.serviceData.length == 0">
                                <h4 class="tylie-panel__title">
                                  Services
                                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddEditAddlServices(false)" *ngIf="orderDetail.config.orderActions.canAddOrderLevelServices === true || orderDetail.config.orderActions.canUpdateSpecialServices == true">
                                    <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                                    Add Services
                                  </button>
                                </h4>
                              </div>
                              <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="orderDetail.header.serviceData.length > 0">
                                <h4 class="tylie-panel__title">
                                  Services
                                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3" (click)="onAddEditAddlServices(true)" *ngIf="orderDetail.config.orderActions.canAddOrderLevelServices === true || orderDetail.config.orderActions.canUpdateSpecialServices == true">
                                    <span class="tylie-button__icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
                                        <!--<title>Asset 8</title>-->
                                        <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0ZM58,43.49H43.42V58H35.61V43.49H21.08V35.68H35.61V21.15h7.81V35.68H58Z" /></g></g>
                                      </svg>
                                    </span>
                                    Add/Edit Services
                                  </button>
                                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddEditAddlServices(true)" *ngIf="orderDetail.config.orderActions.canAddOrderLevelServices === false && orderDetail.config.orderActions.canUpdateSpecialServices == false">
                                    View Services
                                  </button>
                                </h4>
                              </div>
                            
                              <div class="tylie-panel__body" *ngIf="orderDetail.header.serviceData.length == 0">There are no Services.</div>

                              <div class="tylie-panel__body" *ngIf="orderDetail.header.serviceData.length > 0">
                                  <table class="tylie-table table table-bordered">
                                    <thead class="tylie-table__head">
                                      <tr>
                                        <th width="90%" [ngClass]="{'group-error-message': (containsServiceErrors === true && triedToSave === true) }">
                                          Services Selected
                                        </th>
                                        <th width="10%" *ngIf="orderDetail.config.orderActions.canAddOrderLevelServices === true || orderDetail.config.orderActions.canUpdateSpecialServices == true"></th>
                                      </tr>
                                    </thead>
                                    <tbody class="tylie-table__body">
                                      <tr *ngFor="let serv of orderDetail.header.serviceData; let i = index">
                                        <td class="service-selected" [ngClass]="{'group-error-border': (serv.containsServiceMetadataDataErrors == true && triedToSave) }">
                                          <p class="p-extra-small group-error-message" placement="top" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                            *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSave)">
                                            {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                                          </p>
                                          <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && triedToSave)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                                        </td>
                                        <td align="center" valign="middle" *ngIf="orderDetail.config.orderActions.canAddOrderLevelServices === true">
                                          <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete(serv.service.id)">
                                            <icon-trash-can color="#1e90ff"></icon-trash-can>
                                          </a>
                                        </td>
                                      </tr>
                                      <tr *ngIf="isNonTextItemsExists(orderDetail.header.serviceData)">
                                        <td colspan="2" class="thumb-cell">
                                          <table class="table services-row">
                                            <thead>
                                              <tr>
                                                <th width="20%">Service</th>
                                                <th width="55%">File</th>
                                                <th width="15%">Type</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <ng-container *ngFor="let serv of orderDetail.header.serviceData">
                                                <ng-container *ngFor="let mt of serv.serviceMetadata">
                                                  <tr *ngIf="isNonTextItem(mt)">
                                                    <td>{{serv.service.name}}</td>
                                                    <td>{{mt.display}}</td>
                                                    <td>{{mt.fileValue != null ? mt.fileValue.fileType: 'Text'}}</td>
                                                  </tr>
                                                </ng-container>
                                              </ng-container>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                              </div>

                              <div *ngIf="serviceErrors != null && serviceErrors.length > 0">
                                <br />
                                <ng-container *ngFor="let err of serviceErrors">
                                  <p class="p-small" style="color: #ec1c23">{{err}}</p>
                                </ng-container>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'spots'">
                  <order-detail-spots [orderGuid]="orderDetail.orderGuid"
                                      (refreshOrder)="onOrderUpdate($event)"
                                      [showActionsColumnInSpotsTab]="orderDetail.config.orderActions.showActionsColumnInSpotsTab"></order-detail-spots>
                </div>

                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'destinations'">
                  <order-detail-destinations [orderGuid]="orderDetail.orderGuid"
                                            [showCarrierErrorInDetailTabs]="orderDetail.config.orderActions.showCarrierErrorInDetailTabs"
                                            [showActionsColumnInDestinationDestinationsTab]="orderDetail.config.orderActions.showActionsColumnInDestinationDestinationsTab"
                                            [showHubbedDeliveryInDetailTabs]="orderDetail.config.orderActions.showHubbedDeliveryInDetailTabs"
                                            [showVendorAssignmentFailedError]="orderDetail.config.orderActions.showVendorAssignmentFailedError"
                                            (refreshOrderActions)="onActionsUpdate($event)">

                  </order-detail-destinations>
                </div>
                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'ftpupload'">
                  <order-detail-ftp [orderGuid]="orderDetail.orderGuid"></order-detail-ftp>
                </div>
                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'sharelinks'">
                  <order-detail-sharelink [orderGuid]="orderDetail.orderGuid" [showTransferLinkColumn]="orderDetail.config.orderActions.showTransferLinkColumnInDetailTabs"></order-detail-sharelink>
                </div>
                <div class="tylie-tab-content__pane tab-pane fade show active" *ngIf="activeTab === 'contentdownload'">
                  <order-detail-download [orderGuid]="orderDetail.orderGuid"></order-detail-download>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      
        <button *ngIf="showSaveChanges == true" class="tylie-button tylie-button--xs tylie-button--icon me-3" type="button" (click)="onSaveChanges(true)">
          <span class="tylie-button__icon"><icon-save></icon-save></span>
          <span class="tylie-button__text">Save Edits</span>
        </button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" *ngIf="anyChangesDetectedInNonExclusiveEdit == true" (click)="onCancelChanges()">
          Cancel Edits
        </button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" *ngIf="orderDetail.config.orderActions.isExclusiveEdit == true" (click)="onOrderEditEnd()">
          Cancel Edits
        </button>
    </div>

  </div>
</div>


<ng-template #confirmTemplate let-message="message" let-action="action">
  <!-- <div class='p-normal'>
    <p class='black'>
      {{message}}
    </p>
    <i (click)="onHideConfirmPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onActionTrigger(action);">Yes</button> -->



  <button type="button" class="popover__close" (click)="onHideConfirmPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <p class="tylie-type-body mb-3">{{message}}</p>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onActionTrigger(action)">Yes</button>
</ng-template>
