import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "icon-checkmark-green",
  templateUrl: "./icon-checkmark-green.component.html",
  styleUrls: ["./icon-checkmark-green.component.css"],
})
export class IconCheckmarkGreenComponent implements OnInit {
  @Input() height: string = "20px";
  @Input() width: string = "17px";
  @Input() color: string = "00ff00";
  constructor() {}

  ngOnInit(): void {}
}
