import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import * as moment from 'moment';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserRoles } from "../../../configurations/user-roles";
import { ProjectFolders } from '../../../models/project/create-project.model';
import { GridParams } from '../../../models/config/gridparams.model';
import { AlertService, MessageSeverity, } from '../../../services/core/alert.service';
import { ConfigService } from "../../../services/core/config.service";
import { Utilities } from "../../../services/core/utilities";
import { ArchiveProjectService } from '../../../services/project/archive-project.service';
import { ProjectService } from '../../../services/project/create-project.service';
import { AddFilesToProjectPopUp } from '../../popups/addfilestoprojectpopup/addfilestoprojectpopup.component';
import { CreateNewProjectFolderPopUp } from '../../popups/createnewprojectfolderpopup/createnewprojectfolderpopup.component';
import { Subscription } from 'rxjs';
import { Project, ProjectSearchRequest } from '../../../models/project/project.model';
import { ProjectCollectionType, ProjectStatus } from '../../../configurations/enums/enums';
import { Clients, UpdateProject } from '../../../models/project/create-project.model';
import { AddUserPermissionToProjectPopUp } from '../../../components/popups/adduserpermissionpopup/adduserpermissionpopup.component'
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ProjectPromoteComponent } from '../../popups/project-promote/project-promote.component';
import { MoveFilesBetweenFoldersProjectPopUp } from '../../popups/movefilesbetweenfoldersprojectpopup/movefilesbetweenfoldersprojectpopup.component';

@Component({
  selector: 'project-search',
  templateUrl: './projectsearch.component.html',
  styleUrls: ['./projectsearch.component.css'],
})

export class ProjectSearchComponent implements OnInit {
  public activeGrid: number = ProjectStatus.Active;
  public subscriptions: Subscription[] = [];
  public projectIds: Number[];
  @ViewChild('projectSearch', { static: false }) gridActiveProjectSearch: DxDataGridComponent;
  @ViewChild('archivedProjectSearch', { static: false }) gridArchiveProjectSearch: DxDataGridComponent;
  @ViewChildren('deleteFilePop') deleteFilePop: QueryList<PopoverDirective>;
  @ViewChildren('projectArchivepop') projectArchivepop: QueryList<PopoverDirective>;
  @ViewChildren('projectEditpop') projectEditpop: QueryList<PopoverDirective>;
  @ViewChildren('projectFolderEditpop') projectFolderEditpop: QueryList<PopoverDirective>;

  searchRequest: FormGroup;
  updateProjectForm: FormGroup;
  updateProjectFolderForm: FormGroup;
  updateProject: UpdateProject;
  isSaveDisabled: boolean;
  duplicate: boolean;
  clientId: number;
  public quickSearchValue: string = '';
  public modalRef: BsModalRef;
  searchCriteriaObj = {} as ProjectSearchRequest
  public scroll: boolean = false;
  public folderNames = [];
  clientDataSource: Clients[];
  detailGridInstances: Array<any>;
  resultProjectStore: any = {};
  isClientReadOnly: boolean;
  fileCollections: Project[];
  constructor(
    public alertService: AlertService,
    public userRoles: UserRoles,
    private modalService: BsModalService,
    public util: Utilities,
    public configService: ConfigService,
    private archiveProjectService: ArchiveProjectService,
    private projectService: ProjectService,
    private fb: FormBuilder,
  ) {

    this.detailGridInstances = [];
  }

  ngOnInit() {
    this.initialiseSearchForm();
    this.getClients();
    this.loadProjects();
    this.initialiseProjectForm();
  }

  initialiseSearchForm() {
    this.searchRequest = new FormGroup({
      projectCode: new FormControl(),
      projectName: new FormControl(),
      clientId: new FormControl(),
    });
  }

  initialiseProjectForm() {
    this.updateProjectForm = this.fb.group({
      collectionName: new FormControl('', Validators.required),
      collectionId: new FormControl('', Validators.required),
      fileCollectionId: new FormControl(''),
      clientId: new FormControl(''),
      projectId: new FormControl('')
    });

    this.updateProjectFolderForm = this.fb.group({
      folderName: new FormControl('', Validators.required),
      fileCollectionId: new FormControl(''),
      clientId: new FormControl(''),
      projectId: new FormControl('')
    });
  }


  getClients() {
    this.subscriptions.push(this.projectService.getProjectClients().subscribe((clients) => {
      this.clientDataSource = clients.result;
      if (clients.result.length === 1) {
        this.searchRequest.patchValue({
          clientId: clients.result[0].id
        });
        this.isClientReadOnly = true;
      }
    },
      error => {
        this.util.handleError(error);
      }));
  }

  showAddFiles(fileCollection): boolean {
    return fileCollection.isProjectContributor || fileCollection.isProjectManager
      || fileCollection.inverseParentfilecollection[0].isProjectContributor || fileCollection.inverseParentfilecollection[0].isProjectManager;
  }

  showMoveFile(fileCollection: Project): boolean {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId); // Check if the parent of the file is project

    if (!project) {
      project = this.fileCollections.filter(project => {
        let folder = project.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId) as any;
        if (folder) {
          return folder.isProjectManager || project.isProjectManager;
        }
      })[0];
      if (project && (fileCollection.collectionType !== 'Folder')) {
        return true;
      }
    }
    else if (fileCollection.collectionType !== 'Folder') {
      return project.isProjectManager;
    }
    return false;
  }


  showEditFolder(fileCollection: Project): boolean {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId); // Check if the parent of the file is project

    if (!project) {
      project = this.fileCollections.filter(project => {
        let folder = project.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId) as any;
        if (folder) {
          return folder.isProjectContributor || folder.isProjectManager || folder.isProjectViewer
            || project.isProjectContributor || project.isProjectManager || project.isProjectViewer;
        }
      })[0];
      if (project) {
        return true;
      }
    }
    else {
      return project.isProjectContributor || project.isProjectManager || project.isProjectViewer;
    }
  }

  showPromoteFile(fileCollection: Project): boolean {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId); // Check if the parent of the file is project

    if (!project) {
      project = this.fileCollections.filter(project => {
        let folder = project.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId) as any;
        if (folder) {
          return folder.isProjectManager || project.isProjectManager;
        }
      })[0];
      if (project && (fileCollection.collectionType === 'Video' || fileCollection.collectionType == 'Audio')) {
        return true;
      }
    }
    else if (fileCollection.collectionType === 'Video' || fileCollection.collectionType == 'Audio') {
      return project.isProjectManager;
    }
    return false;
  }

  showDownloadFile(fileCollection: Project): boolean {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId); // Check if the parent of the file is project

    if (!project) {
      project = this.fileCollections.filter(project => {
        let folder = project.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId) as any;
        if (folder) {
          return folder.isProjectContributor || folder.isProjectManager || folder.isProjectViewer
            || project.isProjectContributor || project.isProjectManager || project.isProjectViewer;
        }
      })[0];
      if (project) {
        return true;
      }
    }
    else {
      return project.isProjectContributor || project.isProjectManager || project.isProjectViewer;
    }
  }

  loadProjects() {
    let thisRef = this;
    thisRef.searchCriteriaObj.projectStatus = this.activeGrid;
    this.resultProjectStore.store = new CustomStore({
      key: "fileCollectionId",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";
        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
        let request: any = { criteria: thisRef.searchCriteriaObj, GridParams: gridParams };

        return thisRef.projectService.loadProjects(request)
          .toPromise()
          .then((response) => {
            if (response.isSuccess) {
              thisRef.fileCollections = response.records;
              var obj: any = {
                data: response.records,
                totalCount: response.totalCount
              };
              return obj;
            }
            else {
              // thisRef.util.handleIsNotSuccess([ "Please try again" ]);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            thisRef.util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  onclickProjectsGrid() {
    this.searchCriteriaObj.projectStatus = this.activeGrid = ProjectStatus.Active;
    this.gridActiveProjectSearch.instance.refresh();
  }

  onclickArchiveProjectsGrid() {
    this.searchCriteriaObj.projectStatus = this.activeGrid = ProjectStatus.Archived;
    this.gridActiveProjectSearch.instance.refresh();
  }

  onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }
    this.searchCriteriaObj = {} as ProjectSearchRequest;
    this.searchCriteriaObj.quickSearch = this.quickSearchValue;

    if (this.clientDataSource.length == 1) {
      this.searchCriteriaObj.clientId = this.clientDataSource[0].id;
    }
    this.refreshSearchResults(this.searchCriteriaObj, false, true);
  }

  clearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.searchCriteriaObj = {} as ProjectSearchRequest;
      if (this.clientDataSource.length == 1) {
        this.searchCriteriaObj.clientId = this.clientDataSource[0].id;
      }
      this.refreshSearchResults(null, false, true);
    }
  }

  onAdvancedSearch() {
    const searchValues = this.searchRequest.value;
    this.searchCriteriaObj = { ...this.searchCriteriaObj, ...searchValues };
    this.searchCriteriaObj.quickSearch = '';
    this.refreshSearchResults(this.searchCriteriaObj, false, true);
  }

  clearSearch() {
    this.searchRequest.reset();
    this.searchCriteriaObj = {} as ProjectSearchRequest;
    this.quickSearchValue = "";

    if (this.clientDataSource.length == 1) {
      this.searchRequest.controls['clientId'].setValue(this.clientDataSource[0].id);
      this.searchCriteriaObj.clientId = this.clientDataSource[0].id;
    }

    this.refreshSearchResults(null, false, true);
  }

  public onClickCreateNewProjectFolder(data: Project) {
    var initialState = {
      selectedProjectId: data.fileCollectionId
    };

    this.modalRef = this.modalService.show(CreateNewProjectFolderPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onClose.subscribe((result: boolean) => {
      if (result) {
        this.alertService.showMessage("SUCCESS", "Folder created successfully.", MessageSeverity.success);
        this.gridActiveProjectSearch.instance.refresh();
      }
    });
  }

  public onClickArchive(fileCollectionId: number) {
    this.onHideArchivePop();
    this.projectIds = [fileCollectionId];
    if (fileCollectionId == undefined)
      return;
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.archiveProjectService.ArchiveProject(this.projectIds).subscribe((res) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess)
        this.alertService.showMessage("SUCCESS", "Project archived successfully.", MessageSeverity.success);
      else
        this.alertService.showMessage("Failure", "Something went Wrong", MessageSeverity.error);

      this.gridActiveProjectSearch.instance.refresh();
    },
      error => {
        this.util.handleError(error);
      }));
  }

  public onClickAddFilesToProject(data: Project) {
    var initialState = {
      projectId: data.fileCollectionId
    };

    this.modalRef = this.modalService.show(AddFilesToProjectPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onClose.subscribe((result: Project) => {
      if (result) {
        this.alertService.showMessage("SUCCESS", "Files added successfully.", MessageSeverity.success);
        this.gridActiveProjectSearch.instance.refresh();
      }
    });
  }

  public onClickUsers(data: Project) {
    var initialState = {
      project: data
    };

    this.modalRef = this.modalService.show(AddUserPermissionToProjectPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onClose.subscribe((result: Boolean) => {
      if (result != null && result != undefined) {
        this.alertService.showMessage("SUCCESS", "Permission updated successfully.", MessageSeverity.success);
        this.gridActiveProjectSearch.instance.refresh();
      }
    });

  }

  onClickDeleteProjectFile(fileCollectionId: number) {
    this.onHideDeleteFilePop();
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectService.deleteFileCollection(fileCollectionId)
      .subscribe(x => {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("SUCCESS", "Deleted file successfully.", MessageSeverity.success);
        this.gridActiveProjectSearch.instance.refresh();
      },
        error => {
          this.util.handleError(error);
        }));
  }

  onProjectClick(project: Project) {
    this.updateProjectForm.patchValue({
      collectionName: project.collectionName,
      collectionId: project.collectionnId,
      fileCollectionId: project.fileCollectionId,
      clientId: project.clientId,
      projectId: project.projectFileCollectionId
    })
    this.isSaveDisabled = true;
    this.duplicate = false;
  }


  onProjectFolderEdit(projectFolder: Project) {
    console.log(projectFolder);
    this.updateProjectFolderForm.patchValue({
      folderName: projectFolder.collectionName,
      fileCollectionId: projectFolder.fileCollectionId,
      clientId: projectFolder.clientId,
      projectId: projectFolder.projectFileCollectionId
    });

    this.duplicate = false;
  }

  onProjectValueChange(collectionName: string, collectionnId: string) {
    this.duplicate = false;
    if (this.updateProjectForm.controls['collectionName'].value != collectionName ||
      this.updateProjectForm.controls['collectionId'].value != collectionnId)
      return this.isSaveDisabled = false;
    else return this.isSaveDisabled = true;
  }

  onClickProjectEdit() {
    if (this.updateProjectForm.valid) {
      this.updateProject = this.updateProjectForm.value;
      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.projectService.updateProject(this.updateProject)
        .subscribe(res => {
          if (res.isSuccess) {
            this.alertService.ShowLoader(false);
            if (res.isProjectCodeExist) {
              this.duplicate = true;
              this.isSaveDisabled = true;
            }
            else {
              this.onHideEditProjectPop();
              this.alertService.showMessage("SUCCESS", "Project updated successfully.", MessageSeverity.success);
              this.gridActiveProjectSearch.instance.refresh();
            }
          }
          else {
            this.onHideEditProjectPop();
            this.alertService.showMessage("FAILURE", "Project update failure.", MessageSeverity.error);
            this.gridActiveProjectSearch.instance.refresh();
          }
        },
          error => {
            this.onHideEditProjectPop();
            this.util.handleError(error);
          }));
    }
  }

  onClickProjectFolderEdit() {
    if (this.updateProjectFolderForm.valid) {
      this.updateProject = this.updateProjectForm.value;
      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.projectService.updateProjectFolder(this.updateProjectFolderForm.value)
        .subscribe(res => {
          if (res.isSuccess) {
            this.alertService.ShowLoader(false);
            if (res.isFolderExist) {
              this.duplicate = true;
            }
            else {
              this.onHideEditProjectFolderPop();
              this.alertService.showMessage("SUCCESS", "Project Folder name updated successfully.", MessageSeverity.success);
              this.gridActiveProjectSearch.instance.refresh();
            }
          }
          else {
            this.onHideEditProjectFolderPop();
            this.alertService.showMessage("FAILURE", "Project Folder name update failure.", MessageSeverity.error);
            this.gridActiveProjectSearch.instance.refresh();
          }
        },
          error => {
            this.onHideEditProjectFolderPop();
            this.util.handleError(error);
          }));
    }
  }

  public onClickMoveFiles(data: Project) {
    var initialState = {
      projectId: data.projectFileCollectionId,
      fileCollectionId: data.fileCollectionId,
      parentFileCollectionId: data.parentFileCollectionId
    };

    this.modalRef = this.modalService.show(MoveFilesBetweenFoldersProjectPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onClose.subscribe((result: Project) => {
      if (result) {
        this.alertService.showMessage("SUCCESS", "Files moved successfully.", MessageSeverity.success);
        this.gridActiveProjectSearch.instance.refresh();
      }
    });
  }

  refreshSearchResults(obj: ProjectSearchRequest, scroll: boolean, isRefresh: boolean): void {
    this.searchCriteriaObj.projectStatus = this.activeGrid;
    this.searchCriteriaObj.clientId = this.searchCriteriaObj.clientId ? this.searchCriteriaObj.clientId : 0;
    this.scroll = scroll;

    if (isRefresh) {
      this.gridActiveProjectSearch.instance.refresh();
    }
  }

  onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.command === "expand") {

      if (e.data != null) {
        if (e.data.inverseParentfilecollection.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  onCellPrepared1(e: any) {
    if (e.rowType === "data" && e.column.command === "expand") {

      if (e.data != null) {
        if (e.data.inverseParentfilecollection.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  public onFolderClick(isFolder: boolean) {
    if (!isFolder) {
      return;
    }
  }

  onSpotFilesGridContentReady(e) {
    this.detailGridInstances.push(e.component);
  }

  public deSelectRows() {
    this.gridActiveProjectSearch.instance.clearSelection();
  }

  onHideDeleteFilePop() {
    this.deleteFilePop.forEach(p => {
      p.hide();
    });
  }

  onHideArchivePop() {
    this.projectArchivepop.forEach(p => {
      p.hide();
    });
  }

  downloadFile(fileCollection: Project) {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId);
    if (!project) {
      project = this.fileCollections.filter(x => x.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId))[0];
    }

    const fileCollectionIds = [
      fileCollection.parentFileCollectionId !== project.fileCollectionId ? project.fileCollectionId.toString() : '',
      fileCollection.parentFileCollectionId.toString()
    ]

    this.subscriptions.push(this.projectService.downloadFile(fileCollection.fileCollectionGuid, fileCollectionIds)
      .subscribe(presignedUrl => {
        if (presignedUrl) {
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              this.alertService.showMessage("SUCCESS", "File downloaded successfully.", MessageSeverity.success);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileCollection.collectionName;
              a.click();
            });
        }
      },
        error => {
          this.util.handleError(error);
        }));
  }

  projectPromote(fileCollection: Project) {
    let project = this.fileCollections.find(x => x.fileCollectionId == fileCollection.parentFileCollectionId);
    if (!project) {
      project = this.fileCollections.filter(x => x.inverseParentfilecollection.find(y => y.fileCollectionId === fileCollection.parentFileCollectionId))[0];
    }
    const initialState = {
      projectFile: fileCollection,
      fileCollectionIds: [
        fileCollection.parentFileCollectionId !== project.fileCollectionId ? project.fileCollectionId.toString() : '',
        fileCollection.parentFileCollectionId.toString()
      ]
    };

    this.modalRef = this.modalService.show(ProjectPromoteComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onClose.subscribe((result: Project) => {
      if (result) {
        this.gridActiveProjectSearch.instance.refresh();
      }
    });
  }

  onHideEditProjectPop() {
    this.projectEditpop.forEach(p => {
      p.hide();
    });
  }

  onHideEditProjectFolderPop() {
    this.projectFolderEditpop.forEach(p => {
      p.hide();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
