import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { ConfigService } from "../../../../services/core/config.service";
import { AlertService } from "../../../../services/core/alert.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {

  @Input('clientGuid') clientGuid: string;
  //public clientGuid: string = null;
  public editMode: boolean = false;
  private subscription: Subscription;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private route: ActivatedRoute) {

  }

  ngOnInit() {
    //this.subscription = this.route.params.subscribe((params: Params) => {
    //  this.clientGuid = params['clientGuid'];
    //});
  }

  public onEditClientEventTriggered(e: any) {
    this.editMode = true;
  }

  public onEditCompleteOrCancelEventTriggered(e: any) {
    this.editMode = false;
  }
}
