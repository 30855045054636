import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { ServiceMetadataObjectOption } from '../../../../models/order/order-servicequestions.model';

@Component({
  selector: 'service-thumbflashdrive',
  templateUrl: './servicethumbflashdrive.component.html'
})
export class ServiceThumbFlashDriveQComponent extends BaseServiceDataCollectionComponent {

  public driveSizes: Array<any> = [{ "val": "16GB", "display": "16GB" }, { "val": "64GB", "display": "64GB" }, { "val": "128GB", "display": "128GB" }];

  public thumbflashdrivesizes: Array<SizeQuantityPair> = [];

  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();    
    console.log(this.formGroup);
    if (this.orderLevel == true) {

      this.isDisabled = this.formGroup.controls["thumbflashdrivequantity"].disabled;

      if (this.formGroup.controls["thumbflashdrivequantity"].value == null) {
        this.thumbflashdrivesizes.push({ id: 1, size: null, quantity: null });
      }
      else {
        var exisitingValues = this.formGroup.controls["thumbflashdrivequantity"].value as Array<ServiceMetadataObjectOption>;

        exisitingValues.forEach((item, index) => {
          this.thumbflashdrivesizes.push({ id: index + 1, size: item.val1, quantity: item.val2 });
        });
      }
    }
  }

  public onAddNew(i) {
    if (i == 1)
      this.thumbflashdrivesizes.push({ id: this.thumbflashdrivesizes[this.thumbflashdrivesizes.length - 1].id + 1, size: null, quantity: null });
    else if (i > 1)
      this.thumbflashdrivesizes = this.thumbflashdrivesizes.filter(t => t.id != i);

    this.onChange();
  }

  public onChange() {

    let val: Array<ServiceMetadataObjectOption> = [];

    this.thumbflashdrivesizes.forEach((item, index) => {
      console.log(index, item.size, item.quantity);
      if (item.size != null && item.quantity != null)
        val.push({ val1: item.size, val2: item.quantity });
    });

    this.formGroup.controls["thumbflashdrivequantity"].setValue(val);
  }
}

interface SizeQuantityPair {
  id: number,
  size: string,
  quantity: string,
}
