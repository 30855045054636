<nav-header headerTitle="{{title}}" link="Back To All Users" [linkRoute]="usersRoute"></nav-header>

<div class="tylie-body" *ngIf="createUserConfig">
  <form [formGroup]="createUserFormGroup" (ngSubmit)="onFormSubmit(createUserFormGroup)" novalidate #f="ngForm">
    <div class="notifications-section" id="personalinformation-section">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
       
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            USER DETAILS
          </h4>
        </div>

        <div class="tylie-panel__body">
          <div class="row">
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['firstName'].errors && !createUserFormGroup.controls['firstName'].pristine)
                || (createUserFormGroup.controls['firstName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="username">First Name<span class="required">*</span></label>
                <input type="text" formControlName="firstName" name="fname" id="fname" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter First name</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['lastName'].errors && !createUserFormGroup.controls['lastName'].pristine)
                || (createUserFormGroup.controls['lastName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="fname">Last Name<span class="required">*</span></label>
                <input type="text" formControlName="lastName" name="lname" id="lname" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Last Name</p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="isUserTypeClient() == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['client'].errors && !createUserFormGroup.controls['client'].pristine)
                || (createUserFormGroup.controls['client'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="brand">Client</label>
                <dx-select-box [dataSource]="clientDataSource"
                               formControlName="client"
                               name="client" id="client" class="tylie-select form-control"
                               [searchEnabled]="true"
                               [readOnly]="createUserConfig.allowSpecialOptions == false"
                               valueExpr="id"
                               (onValueChanged)="onClientValueChanged($event)"
                               displayExpr="name"
                               placeholder="Select a value...">
                </dx-select-box>
                <p class="p-extra-small">Please Select a Client</p>
              </div>
            </div>
            <div class="col-md-3" *ngIf="isUserTypeDestination() == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['destination'].errors && !createUserFormGroup.controls['destination'].pristine)
                || (createUserFormGroup.controls['destination'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="brand">Destination</label>
                <dx-autocomplete name="destination" id="destination" class="tylie-select form-control"
                                 formControlName="destination"
                                 placeholder="Type three letters to search..."
                                 [minSearchLength]="3"
                                 [searchTimeout]="300"
                                 valueExpr="code"
                                 [dataSource]="destinationDataSource"
                                 value="code">
                </dx-autocomplete>
                <p class="p-extra-small">Please Select a Destination</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="isUserTypeClient() == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="userGroups">User Groups<span class="required">*</span></label>
                <dx-drop-down-box [(value)]="userGrpsValue"
                                  formControlName="userGroups"
                                  id="userGroups"
                                  class="tylie-select form-control"
                                  valueExpr="clientUserGroupId"
                                  displayExpr="displayName"
                                  (onValueChanged)="syncTreeViewSelection()"
                                  [dataSource]="userGrpDataSource">
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view [dataSource]="userGrpDataSource"
                                  dataStructure="plain"
                                  keyExpr="clientUserGroupId"
                                  #treeUserGrps
                                  parentIdExpr="parentId"
                                  selectionMode="multiple"
                                  showCheckBoxesMode="normal"
                                  displayExpr="displayName"
                                  [selectByClick]="true"
                                  (onContentReady)="syncTreeViewSelection($event)"
                                  (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
                <p class="p-extra-small">Please Select User Groups</p>
              </div>
            </div>

          </div>

          <div class="search-all-media row">
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['email'].errors && !createUserFormGroup.controls['email'].pristine)
                || (createUserFormGroup.controls['email'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="pnumber">Email<span class="required">*</span></label>
                <input type="text" formControlName="email" name="pnumber" id="pnumber" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter an Email</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['phone'].errors && !createUserFormGroup.controls['phone'].pristine)
                || (createUserFormGroup.controls['phone'].errors && f.submitted) }">
                <label class="tylie-form-group__lbl" for="cnumber">Phone<span class="required">*</span></label>
                <input type="text" formControlName="phone" name="cnumber" id="cnumber" [textMask]="{mask: phoneMask, guide: false, modelClean: true}" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter a Valid Phone Number</p>
              </div>
            </div>
            <div class="tylie-form-group form-group col-md-3" *ngIf="createUserConfig.allowSpecialOptions == true && isUserTypeClient() == true">
              <label class="tylie-form-group__lbl" for="brand">Post House</label>
              <dx-select-box [dataSource]="postHouseDataSource"
                              formControlName="postHouse"
                              name="posthouse" id="posthouse" class="tylie-select form-control"
                              [searchEnabled]="true"
                              valueExpr="id"
                              [showClearButton]="true"
                              (onValueChanged)="onPostHouseValueChanged($event)"
                              displayExpr="name"
                              placeholder="Select a post house...">
              </dx-select-box>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['timeZone'].errors && !createUserFormGroup.controls['timeZone'].pristine)
                || (createUserFormGroup.controls['timeZone'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="timezone">Time Zone<span class="required">*</span></label>
                <dx-select-box [dataSource]="createUserConfig.timeZones"
                                formControlName="timeZone"
                                name="timezone" id="timezone" class="tylie-select form-control"
                                displayExpr="displayName"
                                valueExpr="standardName"
                                placeholder="Select a value...">
                </dx-select-box>
                <p class="p-extra-small">Please Select a Time Zone</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="isUserTypeClient() == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['dealerName'].errors && !createUserFormGroup.controls['dealerName'].pristine)
                || (createUserFormGroup.controls['dealerName'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="dealerName">Dealer Name<span class="required">*</span></label>
                <input type="text" formControlName="dealerName" name="dealerName" id="dealerName" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Dealer Name</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="isUserTypeClient() == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: (createUserFormGroup.controls['dealerNumber'].errors && !createUserFormGroup.controls['dealerNumber'].pristine)
                || (createUserFormGroup.controls['dealerNumber'].errors && f.submitted)}">
                <label class="tylie-form-group__lbl" for="dealerNumber">Dealer Number<span class="required">*</span></label>
                <input type="text" formControlName="dealerNumber" name="dealerNumber" id="dealerNumber" class="tylie-text form-control">
                <p class="p-extra-small">Please Enter Dealer Number</p>
              </div>
            </div>

          </div>
        </div>
       
      </div>
    </div>

    <div class="notifications-section" id="personalinformation-section" *ngIf="isUserTypeClient() == true">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Brands
          </h4>
        </div>
       
        <div class="tylie-panel__body" *ngIf="userClientBrandDataSource != null && userClientBrandDataSource?.length > 0">
          <dx-data-grid id="brandsGrid"
                        #brandsGrid
                        [dataSource]="userClientBrandDataSource"
                        [showColumnLines]="true"
                        [rowAlternationEnabled]="true"
                        [hoverStateEnabled]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        keyExpr="brandId">
            <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
            </dxo-selection>
            <dxi-column dataField="brandName" width="30%" cssClass="gridStyle" caption="Brand Name"></dxi-column>
            <dxi-column caption="Product" width="40%" cellTemplate="productTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Appr.Lvl" width="30%" cellTemplate="approvalLvlTemplate" cssClass="gridStyle tyl-vertical-align-intial" [visible]="mediaApproverSelected && this.previousPostHouseValue == null" [allowSorting]="false"></dxi-column>

            <div *dxTemplate="let t of 'approvalLvlTemplate'">
              <div *ngIf="t.data.clientBrandApprovalLevels?.length > 0">
                <dx-select-box [dataSource]="t.data.clientBrandApprovalLevels"
                                #approvalLevel
                                name="approvalLevel" id="approvalLevel" class="form-control"
                                [value]="t.data.userClientBrandApprovalLevel"
                                (onSelectionChanged)="onSelectionChangedApprovalLvl($event, t.data)">
                </dx-select-box>
              </div>
            </div>

            <div *dxTemplate="let t of 'productTemplate'">
              <div *ngFor="let product of t.data.products">
                <span class="p-small">
                  {{product.name}}
                </span>
              </div>
              <button *ngIf="t.data.products?.length > 0" for="f02" type="button" class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link" (click)="addClientBrandProduct(t.data)">
                ADD/EDIT
              </button>
              <button type="button" *ngIf="(t.data.hasBrandProducts && (t.data.products == null || t.data.products?.length == 0))" for="f02" class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link" (click)="addClientBrandProduct(t.data)">
                <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD PRODUCTS
              </button>
            </div>

            <dxo-paging [pageSize]="25"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[25,50,100]"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
        <div class="tylie-panel__body" *ngIf="userClientBrandDataSource == null">
          <span>Please select a client.</span>
        </div>
        <div class="tylie-panel__body" *ngIf="userClientBrandDataSource?.length == 0">
          <span>No brands are available for your selection.</span>
        </div>
      </div>
    </div>

    <div class="notifications-section" id="personalinformation-section" *ngIf="isUserTypeClient() == true">
      <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Preferences
          </h4>
        </div>
        <div class="tylie-panel__body">
          <div class="mb-2">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddDistributionSettings()">Distribution Settings</button>
          </div>
          <div class="tylie-data">
            <label class="tylie-data__lbl">Advanced File Naming Enabled :</label>
            <div class="tylie-data__txt">{{this.enableAdvancedFileNaming? '&nbsp;Yes' : '&nbsp;No' }} </div>
          </div>
          <ng-container *ngIf="networkSpecifications.length > 0">
            <div class="tylie-form-group">
              <label class="tylie-form-group__lbl" for="specMappings">Specification Mappings</label>
              <ng-container *ngFor="let spec of networkSpecifications; let i = index">
                <p class="tylie-type-body">
                  {{spec.name}}
                  <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onRemoveSpecMapping(spec)">
                    <icon-delete color="#1e90ff"></icon-delete>
                  </button>
                </p>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      </div>

      <addroles-notifications [rolesNotifications]="createUserConfig.rolesNotifications"
                              [addUserRolesFormGroup]="this.createUserFormGroup.get('userRole')"
                              [addUserNotificationsFormGroup]="this.createUserFormGroup.get('notificationPreference')"
                              (onMediaApproverRoleUpdated)="onMediaApproverRoleUpdatedEventTriggered($event)">

      </addroles-notifications>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit">
          <span class="tylie-button__icon"><icon-save></icon-save></span>
          <span class="tylie-button__text">Save User</span>
        </button>
      </div>
  </form>
</div>



