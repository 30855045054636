<div id="saved-destinations">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Distribution Settings</h4>
  </div>
  <div class="modal-body">
    <div class="row search-all-media" *ngIf="this.config != null">
      <form [formGroup]="distributionSettingsFormGroup" novalidate role="search" #f="ngForm">
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="fileDeliveryType">
                File Delivery Type
              </label>
              <dx-select-box [dataSource]="config.fileDeliveryTypes"
                             name="fileDeliveryType" id="fileDeliveryType" class="tylie-select form-control"
                             formControlName="fileDeliveryType"
                             valueExpr="id"
                             [showClearButton]="true"
                             displayExpr="name"
                             placeholder="Select File Delivery Type"
                             (onValueChanged)="onFileDeliveryTypeChange()">
              </dx-select-box>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showDropBoxConfig == true">
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['dropBoxFolderPath'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="dropBoxFolderPath">Folder Path<span class="required">*</span></label>
              <input type="text" id="dropBoxFolderPath" name="dropBoxFolderPath" formControlName="dropBoxFolderPath" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter DropBox Folder Path</p>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showFTPConfig == true">
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['ftpUserName'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpUserName">FTP UserName<span class="required">*</span></label>
              <input type="text" id="ftpUserName" name="ftpUserName" formControlName="ftpUserName" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter FTP Username</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['ftpServer'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpServer">FTP Server<span class="required">*</span></label>
              <input type="text" id="ftpServer" name="ftpServer" formControlName="ftpServer" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter FTP Server</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['ftpPassword'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpPassword">FTP Password<span class="required">*</span></label>
              <input type="password" id="ftpPassword" name="ftpPassword" formControlName="ftpPassword" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter FTP Password</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['ftpPort'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpPort">FTP Port#<span class="required">*</span></label>
              <input type="text" id="ftpPort" name="ftpPort" formControlName="ftpPort" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter FTP Port#</p>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showAsperaConfig == true">
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['asperaServer'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="asperaServer">Server<span class="required">*</span></label>
              <input type="text" id="asperaServer" name="asperaServer" formControlName="asperaServer" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter Server</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['asperaUser'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="asperaUser">User<span class="required">*</span></label>
              <input type="text" id="asperaUser" name="asperaUser" formControlName="asperaUser" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter User</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['asperaPassword'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="asperaPassword">Password<span class="required">*</span></label>
              <input type="password" id="asperaPassword" name="asperaPassword" formControlName="asperaPassword" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter Password</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['asperaPort'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="asperaPort">Port#<span class="required">*</span></label>
              <input type="text" id="asperaPort" name="asperaPort" formControlName="asperaPort" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter Port#</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="asperaSubFolder">Sub Folder</label>
              <input type="text" id="asperaSubFolder" name="asperaSubFolder" formControlName="asperaSubFolder" class="tylie-text form-control" />
            </div>
          </div>
        </div>

        <ng-container *ngIf="showLinksConfig == true">
          <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['linkEmails'].hasError('inValidEmailList') && f.submitted}">
            <label class="tylie-form-group__lbl" for="linkEmails">Link Emails<span class="required">*</span>&nbsp;(semi-colon separated)</label>
            <textarea type="text" id="linkEmails" name="linkEmails" formControlName="linkEmails" class="tylie-textarea form-control"></textarea>
            <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
          </div>
        </ng-container>
        <div class="row" *ngIf="showS3Config == true">
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['s3Bucket'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpUserName">S3 Bucket<span class="required">*</span></label>
              <input type="text" id="s3Bucket" name="s3Bucket" formControlName="s3Bucket" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter S3 Bucket</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['s3Path'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpServer">S3 Path<span class="required">*</span></label>
              <input type="text" id="s3Path" name="s3Path" formControlName="s3Path" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter S3 Path</p>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showGDriveConfig == true">
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['folderPath'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="folderPath">Folder Path<span class="required">*</span></label>
              <input type="text" id="folderPath" name="folderPath" formControlName="folderPath" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter GDrive Folder Path</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: distributionSettingsFormGroup.controls['folderId'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="ftpServer">Folder ID<span class="required">*</span></label>
              <input type="text" id="folderId" name="folderId" formControlName="folderId" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter GDrive Folder ID</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="showManulDistributionConfig == true">
          <div class="tylie-form-group form-group" [ngClass]="{error:  distributionSettingsFormGroup.controls['notes'].errors && f.submitted}">
            <label class="tylie-form-group__lbl" for="notes">Notes<span class="required">*</span></label>
            <textarea type="text" id="notes" name="notes" formControlName="notes" class="tylie-textarea form-control"></textarea>
            <p class="p-extra-small">Please enter Notes (Distibution details)</p>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-check mt-4">
              <input type="checkbox" id="isHouseIdRequired" name="isHouseIdRequired" formControlName="isHouseIdRequired" class="form-check-input " />
              <label for="isHouseIdRequired" class="form-check-label">Is HouseID Required For Download<span class="required">*</span></label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl">Upload BXF File<span class="required">*</span></label>
              <div class="upload-reportdata">
                <div class="input-group">
                  <label class="input-group-btn">
                    <span class="btn btn1 btn-danger">
                      Browse<input type="file" style="display: none;" #excelFileInput (change)="onBxfUploadFileChange($event)" required accept=".xsd">
                    </span>
                  </label>
                  <input type="text" class="form-control" readonly="" value="{{bxfFile != null ? bxfFile.name : ''}}" placeholder="Please upload .xsd">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl">Transcode File Name: Choose from drop down options (options will be separated by "_").  When not specified, system uses default file name "Ad ID_Title_Duration".</label>
          <div class="row">
            <div class="col-md-4">
              <dx-select-box class="tylie-select" [items]="config.customFileDeliveryOptions" name="customfilenamepos1" showClearButton="true" formControlName="customfilenamepos1" placeholder="Select">
              </dx-select-box>
            </div>

            <div class="col-md-4">
              <dx-select-box class="tylie-select" [items]="config.customFileDeliveryOptions" name="customfilenamepos2" showClearButton="true" formControlName="customfilenamepos2" placeholder="Select">
              </dx-select-box>
            </div>

            <div class="col-md-4">
              <dx-select-box class="tylie-select" [items]="config.customFileDeliveryOptions" name="customfilenamepos3" showClearButton="true" formControlName="customfilenamepos3" placeholder="Select">
              </dx-select-box>
            </div>
          </div>
        </div>
      </form>

      <div class="tylie-form-group form-group">
        <label class="tylie-form-group__lbl" for="comments">Specification Mapping (Please don't choose specifications for Backup destinations)</label>
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Specification Name"
                   name="srch-term" id="srch-term" type="search" (keyup.enter)="onQuickSearchDestinations()"
                   [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
          </div>
        </section>
      </div>

      <div class="mb-3">
        <dx-data-grid id="specsGrid" class="tylie-grid tylie-grid--bordered"
                      #specsGrid
                      [dataSource]="dataSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                         selectAllMode="page"
                         allowSelectAll="Select"
                         showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Specification Name" sortOrder="asc"></dxi-column>
          <dxi-column dataField="description" width="70%" cssClass="gridStyle" caption="Description"></dxi-column>
          <dxo-remote-operations [sorting]="true"
                                 [paging]="true">
          </dxo-remote-operations>
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50, 100]"
                     infoText="Page {0} of {1} ({2} items)"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs" type="button" (click)="formSubmit(distributionSettingsFormGroup)">
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>

