<ng-container *ngIf="recentlyViewedSpots.length > 0" id="recently-viewed-section">

  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#recentlyViewedCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          RECENTLY VIEWED
        </button>
      </h2>
      <div id="recentlyViewedCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-bar">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="showChoosePlayListPopup()"><span class="tylie-button__icon"><icon-playlist-add></icon-playlist-add></span><span class="tylie-button__text">Add to playlist</span></button>
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="shareSpots()"><span class="tylie-button__icon"><icon-envelope-share></icon-envelope-share></span><span class="tylie-button__text">Share</span></button>
            <!--<button class="btn tyl-btn btn-extra-small" (click)="exportSpots()"><i class="btn-export"></i><span>Export</span></button>-->
          </div>
          <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
            <dx-data-grid  class="tylie-grid" id="recentlyViewedGrid" *ngIf="recentlyViewedSpots"
                          [dataSource]="recentlyViewedSpots"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true"
                          [showColumnLines]="true"
                          [hoverStateEnabled]="true"
                          [showRowLines]="true"
                          keyExpr="spotFileGuid">
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
              <dxo-selection mode="multiple"
                             selectAllMode="page"
                             allowSelectAll="Select"
                             showCheckBoxesMode="always">
              </dxo-selection>
              <dxi-column caption="Thumbnail" [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
              <dxi-column dataField="adId" caption="Ad-ID" cellTemplate="adIDTemplate" width="10%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="title" width="22%" cssClass="gridStyle"></dxi-column>
              <!--<dxi-column dataField="format" width="8%" cssClass="gridStyle"></dxi-column>-->
              <dxi-column dataField="format" cellTemplate="formatTemplate" cssClass="gridStyle" width="10%"></dxi-column>
              <dxi-column dataField="client" width="19%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="brand" width="13%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="length" width="8%" caption="Duration" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="status" width="10%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="created" cellTemplate="dateTemplate" cssClass="gridStyle" width="10%"></dxi-column>
              <div *dxTemplate="let t of 'adIDTemplate'">
                <span *ngIf="t.data.isViewable === true" class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
                <span *ngIf="t.data.isViewable === false">{{t.data.adId}}</span>
              </div>
              <div class="tylie-img-container tylie-img-container--thumbnail" *dxTemplate="let t of 'thumbnailTemplate'">
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
              </div>
              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{data.value | usertimezone: 'MM/DD/YYYY'}}</div>
              </div>
              <div *dxTemplate="let t of 'formatTemplate'">
                <div class="dx-datagrid-nowrap-remove">
                  <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
                </div>
              </div>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                         [allowedPageSizes]="[10, 20, 50]"
                         [showNavigationButtons]="true"
                         [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>
    
        
      </div>
    </div>
  </div>
</ng-container>
