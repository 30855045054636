import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/internal/Subject';
import { Constants } from '../../../configurations/constants';
import { DraftOrderNote, DraftOrderNoteResponse } from '../../../models/order/draft-order.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { TaggerService } from '../../../services/tagger/tagger.service';

@Component({
  selector: 'app-tagger-draftorder-addnotepopup',
  templateUrl: './tagger-draftorder-addnotepopup.component.html',
  styleUrls: ['./tagger-draftorder-addnotepopup.component.css']
})
export class TaggerDraftorderAddnotepopupComponent implements OnInit {

  @Input('draftorderguid') draftOrderGuid: string;
  @Input('payload') payload: string;

  public vaultAssetNoteRequest: FormGroup;
  public onSaveVaultAssetNote: Subject<DraftOrderNoteResponse>;

  constructor(public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private taggerService: TaggerService,
    private fb: FormBuilder)
  {
    this.onSaveVaultAssetNote = new Subject<DraftOrderNoteResponse>();
  }

  ngOnInit() {
    this.vaultAssetNoteRequest = this.fb.group({
      note: new FormControl(this.payload, Validators.required),
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }


  public onSubmitVaultAssetNote({ value, valid }: { value: DraftOrderNote, valid: boolean }) {
    value.orderGuid = this.draftOrderGuid;
    console.log(value);
    if (!valid) {
      return;
    }
    this.alertService.ShowLoader(true);

    this.taggerService.addDraftOrderNote(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var draftOrderNote = res.result as DraftOrderNoteResponse
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_add_vault_asset_note, MessageSeverity.success, true);
        this.onSaveVaultAssetNote.next(draftOrderNote);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.failed_add_note, MessageSeverity.error, true);
      }
    });
  }

}
