import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Utilities } from "../../../services/core/utilities";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { AlertService } from "../../../services/core/alert.service";
import {
  TaggerBaseCreative,
  TaggerCard,
} from "../../../models/tagger/tagger.model";
import { QuickplayerComponent } from "../../videoplayer/quickplayer/quickplayer.component";
import { RouteConstants } from "../../../configurations/route-constants";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-creative-tags",
  templateUrl: "./creative-tags.component.html",
  styleUrls: ["./creative-tags.component.css"],
})
export class CreativeTagsComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  public baseCreatives: TaggerBaseCreative[] = [];
  public endCards: TaggerCard[] = [];
  public offerCards: TaggerCard[] = [];
  public selectedBaseCreatives: TaggerBaseCreative[] = [];
  public modalRef: BsModalRef;
  public taggerRoute = RouteConstants.taggerRoute;
  public formGroup: FormGroup;
  public providerName: string;
  public providerLocation: string;

  constructor(
    public util: Utilities,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private taggerService: TaggerService,
    public alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.providerName = "Subaru National";
    this.providerLocation = "Subaru San Diego";
    this.getBaseCreatives();
    this.getOfferCards();
    this.getEndCards();
  }

  public getBaseCreatives() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getBaseCreatives().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.baseCreatives = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getEndCards() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getEndCards().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.endCards = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getOfferCards() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getOfferCards().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.offerCards = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onSelect(creativeMedia: any): void {
    this.selectedBaseCreatives = [creativeMedia];
    // navigate route to tagger-form component
    this.router.navigate([RouteConstants.adCreatorRoute], {
      state: {
        creativeTagsOrigination: "CreativeTags",
        baseCreatives: this.selectedBaseCreatives,
      },
    });
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == "") return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(
      QuickplayerComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
  }

  public gotoTaggerHome() {
    this.router.navigate([RouteConstants.taggerRoute]);
  }
}
