import { Component, OnInit } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ServiceBAGuaranteeMetadataObjectOption } from '../../../../models/order/order-servicequestions.model';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'app-service-baguaranteepayments-metadata',
  templateUrl: './service-baguaranteepayments-metadata.component.html',
  styleUrls: ['./service-baguaranteepayments-metadata.component.css']
})
export class ServiceBaguaranteepaymentsMetadataComponent extends BaseServiceDataCollectionComponent {

  public baGuaranteePaymentOptions: Array<BAGuaranteePaymentOptions> = [];
  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    console.log(this.formGroup);
    this.isDisabled = this.formGroup.controls["passthroughdata"].disabled;
    if (this.formGroup.controls["passthroughdata"].value == null) {
      this.baGuaranteePaymentOptions.push({ id: 1, invoiceamount: null, itemdescription: null, refno: null, percentage: null });
    }
    else {
      var exisitingValues = this.formGroup.controls["passthroughdata"].value as Array<ServiceBAGuaranteeMetadataObjectOption>;

      exisitingValues.forEach((item, index) => {
        this.baGuaranteePaymentOptions.push({ id: index + 1, invoiceamount: item.val1, itemdescription: item.val2, refno: item.val3, percentage: item.val4 });
      });
    }

   // Apply the custom validator
    this.formGroup.controls['passthroughdata'].setValidators([this.baGuaranteePaymentOptionsValidator(this.baGuaranteePaymentOptions)]);
    this.formGroup.controls['passthroughdata'].updateValueAndValidity();
  }

  public onAddNew(i) {
    if (i == 1)
      this.baGuaranteePaymentOptions.push({ id: this.baGuaranteePaymentOptions[this.baGuaranteePaymentOptions.length - 1].id + 1, invoiceamount: null, itemdescription: null, refno: null, percentage: null });
    else if (i > 1)
      this.baGuaranteePaymentOptions = this.baGuaranteePaymentOptions.filter(t => t.id != i);

    this.onChange();
  }

  public onChange() {
    let val: Array<ServiceBAGuaranteeMetadataObjectOption> = [];
    this.baGuaranteePaymentOptions.forEach((item, index) => {
      if (item.invoiceamount != null && item.itemdescription != null && item.refno != null && item.percentage != null)
        val.push({ val1: item.invoiceamount, val2: item.itemdescription, val3: item.refno, val4: item.percentage });
    });

    this.formGroup.controls["passthroughdata"].setValue(val);
    this.formGroup.controls['passthroughdata'].updateValueAndValidity();
  }

}

interface BAGuaranteePaymentOptions {
  id: number,
  invoiceamount: string,
  itemdescription: string,
  refno: string,
  percentage: string,
}

