import { GridParams } from "../config/gridparams.model";
import { SpotFile } from "../spot/spot-file.model";
import { PredefinedSpec } from "./order-additionalmetadata";
import { OrderFile } from "./order-create.model";

export class TranscodeOrderConfig {
  public clients: Array<any>;
  public products: Array<any>;
  public brands: Array<any>;
  public allowSpecialOptions: boolean;
  public transcodePredefinedSpecs: Array<PredefinedSpec>;
  public fileNameOptions: Array<string>;
  public fileNameOptionSamples: Array<FileNameOptionSample>;
  public fileNameSeperators: Array<FileNameSeperator>;
  public formats: Array<any>;
  public mediaTypes: Array<any>;
  public attachmentCategories: Array<any>;
  public transcodeOrderGuid: string;
  public isAdvancedFileNamingEnabled: boolean;
  public isDealerUser: boolean;
  public dealerName: string;
  public dealerNumber: string;
  public orderedByEmail: string;
  public perTranscodePrice: Array<FileNameSeperator>;
}

export class FileNameSeperator {
  public name: string;
  public description: string;
}

export class FileNameOptionSample {
  public name: string;
  public sample: string;
}

export class FileNameOption {
  public name: string;
  public isCustom: boolean;
  public selected: boolean;
}

export class TranscodeMediaSearchRequest {
  public client: number;
  public product: number;
  public brand: number;
  public title: string;
  public adID: string;
  public format: number;
  public mediaType: number;
  public quickSearch: string;
  public orderGroupSpotFileGuids: string[];
  public requestOrigination: string;
  public gridParams: GridParams;
}

export class TranscodeMediaOrderRequest {
  public transcodeOrderGuid: string;
  //step#1
  public spotFilesData: Array<SpotFile>;
  public isInternalRequest: boolean;
  public originalWorkOrders: string;

  //step#2
  public selectionType: string;
  public spotFileTranscodesData: Map<string, Array<string>>;

  //step#3
  public fileNameOptions: Array<FileNameOption>;
  public fileNamingType: string;
  public fileSeperator: string;
  public customFileName: string;
  public spotFileNamingData: Map<string, TranscodeFileNaming>;
  public customFileNaming: any[]; //spotfileguid, specname, adid, title, customFileName

  //step#4
  public referenceCode: string;
  public poNumber: string;
  public jobNumber: string;
  public orderedBy: string;
  public dealerNumber: string;
  public dealerName: string;
  public attachments: Array<OrderFile>;

  public clientId: number;
  public spotFiles: Array<string>;
  public spotFileTranscodes: Array<MediaTranscode>;
  public spotFileNaming: Array<MediaTranscodeFileNaming>;
}

export class TranscodeFileNaming {
  public selectionType: string;
  public fileNameOptions: Array<FileNameOption>;
  public fileNamingType: string;
  public fileSeperator: string;
  public customFileName: string;
}

export class MediaTranscodeFileNaming {
  public spotFileGuid: string;
  public fileNaming: TranscodeFileNaming;
}

export class MediaTranscode {

  public spotFileGuid: string;

  public transcodes: Array<string>;
}

export class TranscodeMediaOrderResponse {

  public workOrder: string;

  public errors: Array<string>;

  public transcodeMediaErrors: Array<TranscodeMediaError>;
}

export class TranscodeMediaError {

  public adId: string;

  public title: string;

  public errors: Array<string>;
}

export class TranscodeMaxCapRequestModel {
  public clientId: number;
  public orderTranscodeCount: number;
}

export class OrderDestinationTranscodeModel {
  public spec: string;
  public transcodeFileMetaData: TranscodeFileNaming;
}


export class TranscodePricing {
  public brandName: string;
  public price: string;
}
