import { Component, Inject, ViewChild, Input, OnInit, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { OrderUpdateResult } from "../../../../models/order/order-statusupdate-result.model";
import { Order } from "../../../../models/order/order.model";
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent, DxPopoverComponent } from "devextreme-angular";

import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { MediaDetailComponent } from "../../../mediadetail/mediadetail.component";
import { deliveryMethodEnum } from "../../../../configurations/enums/enums";

@Component({
  selector: 'order-detail-sharelink',
  templateUrl: './order-detail-sharelink.component.html',
  styleUrls: ['./order-detail-sharelink.component.css']
})
export class OrderDetailShareLinkComponent implements OnInit, AfterViewInit {
  public modalRef: BsModalRef;
  public orderDetailShareLinkStore: any = [];
  public showLoader: boolean = true;

  //@ViewChild('orderDetailShareLinks') orderDetailShareLinks: DxDataGridComponent;
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @Input('orderGuid') orderGuid: string;
  @Input('showTransferLinkColumn') showTransferLinkColumn: boolean;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private modalService: BsModalService) {

  }

  ngOnInit() {


  };

  ngAfterViewInit() {
    if (this.orderGuid != null && this.orderGuid != undefined) {
      this.getShareLinksDetailsFromOrder();
    }
  }

  public getShareLinksDetailsFromOrder() {
    this.showLoader = true;
    this.dataGrid.instance.beginCustomLoading("Loading...");

    this.orderService.getShareLinkDetailsForOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var orderShareLinks = res.result;
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.orderDetailShareLinkStore = orderShareLinks;
      }
      else {
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
      });
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public onClickOpenTransferLink(transferLink: string) {
    window.open(transferLink);
  }
}

