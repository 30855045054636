import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-accountmanageradjustment',
  templateUrl: './serviceaccountmanageradjustment.component.html'
})
export class ServiceAccountManagerAdjustmentQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.formGroup.setValidators([
      this.nonZeroNumberRequired(this.formGroup.get('adjustamount'))
    ]);

    this.formGroup.updateValueAndValidity();

    //this.formGroup.valueChanges
    //  .subscribe(val => {
    //    this.searchField.setValue(myPipe.transform(val))
    //  });

    //this.formGroup.get('adjustamount').valueChanges
    //  .debounceTime(400)
    //  .distinctUntilChanged().subscribe(
    //    (val: number) => {
    //      this.formGroup.get('adjustamount').setValue(4);
    //    });
  }
}
