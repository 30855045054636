import { Component, Input, OnInit, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from "devextreme-angular";
import { PlayList } from "../../../models/playlist/playlist.model";
import { Utilities } from "../../../services/core/utilities";
import { PlayListService } from "../../../services/playlist/playlist.service";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { Constants } from "../../../configurations/constants";
import { Subject } from "rxjs";
import { PlayListFile } from "../../../models/playlist/playlist-file.model";
import { IMedia } from "../../../models/videofile/videofile.model";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VideoplayerComponent } from "../../videoplayer/videoplayer.component";
import { SubService } from "../../../services/media/sub.service";
import { ShareMediaPopupComponent } from "../../popups/sharemediapopup/sharemediapopup.component";
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})

export class PlaylistComponent implements OnInit {
  @Input('playList') playList: PlayList[];
  @Output() playListChange = new EventEmitter();

  public playLists: PlayList[] = [];
  public playlistoflist: any[] = [];
  public playListFiles: PlayListFile[] = [];
  public playListName: string = "";
  public detailsPlayListDiv: any;
  //public removeFlag: boolean = false;
  //public playListGuid: string;
  //public deletePlayListFileGuid: string;
  //public deletePlayListInvoke: boolean = false;
  public currentIndex;
  public currentItem: IMedia;
  public modalRef: BsModalRef;
  public videoFilesToPlay: Array<IMedia>;

  //@ViewChild('delPlayListFilepop') delPlayListFilepop: PopoverDirective;
  //@ViewChild('delPlayListpop') delPlayListpop: PopoverDirective;

  @ViewChildren('delPlayListFilepop') delPlayListFilepop: QueryList<PopoverDirective>;
  @ViewChildren('delPlayListpop') delPlayListpop: QueryList<PopoverDirective>;

  constructor(private util: Utilities,
    private playListService: PlayListService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private subService: SubService) {

    this.subService.refreshPlayListSearchMethodCalled.subscribe(
      () => {
        this.refreshPlayLists();
      }
    );

    this.subService.refreshPlayListRecentlyViewedMethodCalled.subscribe(
      () => {
        this.refreshPlayLists();
      }
    );

    this.subService.refreshPlayListMediaApprovalMethodCalled.subscribe(
      () => {
        this.refreshPlayLists();
      }
    );
  }

  ngOnInit() {
    let playListTemp: PlayList[] = this.playList;

    for (let plf of playListTemp) {
      plf.thumbnailUrl = this.util.getThumbnailUrl(plf.thumbnailUrl, plf.isAudioExists, true);
    }

    this.playLists = playListTemp;
    console.log(this.playLists);
    this.setPlayListOfLists();
  }

  //public deletePlayListConfirmation(id: string, deleteMedia: TemplateRef<any>) {

  //  this.modalRef = this.modalService.show(deleteMedia, this.util.getModalComponentOptions({}, false, true, false));

  //  this.playListGuid = id;
  //  this.deletePlayListInvoke = true
  //}

  //public deleteResource() {
  //  if (this.deletePlayListInvoke == true) { this.deletePlayList(); }
  //  else { this.removeFromPlayList(); }
  //}

  public showPlayListFiles(id: string, name: string, ev: any) {
    this.playListFiles = [];
    this.playListName = name;

    this.playListService.getPlayListFiles(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        let playListTemp: PlayListFile[] = res.result;
        for (let plf of playListTemp) {
          plf.thumbnailUrl = this.util.getThumbnailUrl(plf.thumbnailUrl, (plf.format.toLowerCase() == 'audio'), true);
          plf.proxyUrl = this.util.getProxyUrl(plf.proxyUrl);
          console.log(plf.thumbnailUrl);
        }
        this.playListFiles = playListTemp;
        console.log(this.playListFiles);
        this.detailsPlayListDiv = document.getElementById("details-play-list");
        // this.detailsPlayListDiv.classList.add('hide');
        // if (this.playListFiles.length > 0) {
        //   this.detailsPlayListDiv.classList.remove('hide');
        // }
      }
      else { this.util.handleIsNotSuccess(res.errors); }
    }, err => {
      this.util.handleError(err);
    });
    event.stopPropagation();
  }

  public getPlayListFiles(id: string): string[] {
    let spotFileGuids: string[] = [];
    this.playListService.getPlayListFiles(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        let playListTemp: PlayListFile[] = res.result;
        for (let plf of playListTemp) {
          spotFileGuids.push(plf.spotFileGuid);
        }
      }
      else { this.util.handleIsNotSuccess(res.errors); }
    }, err => {
      this.util.handleError(err);
    });
    return spotFileGuids;
  }

  public closePlayList() {
    if (this.playListFiles.length > 0) {
      // this.detailsPlayListDiv.classList.add('hide');
      this.playListFiles = [];
    }
  }

  public setPlayListOfLists() {
    this.playlistoflist = []
    if (this.playLists.length <= Constants.carouselLength) { this.playlistoflist.push(this.playLists); }
    else {
      var stopIndex = this.playLists.length - (this.playLists.length % Constants.carouselLength);
      var loopEnd = stopIndex / Constants.carouselLength;
      for (var i = 0; i < loopEnd; i++) {
        var playList1 = this.playLists.slice(i * Constants.carouselLength, (i * Constants.carouselLength) + Constants.carouselLength);
        this.playlistoflist.push(playList1);
      }

      if (this.playLists.length % Constants.carouselLength != 0) {
        this.playlistoflist.push(this.playLists.slice(stopIndex));
      }
    }

    return this.playlistoflist;
  }

  public playSource(id: string) {

    let videoFilesSource: Array<IMedia> = [];
    for (let file of this.playListFiles) {
      videoFilesSource.push({
        title: file.displayTitle,
        src: file.proxyUrl,
        type: 'video/mp4',
        poster: file.thumbnailUrl,
        playthisone: (file.spotFileGuid === id ? true : false),
        format: file.format,
        proxyUrl: ''
      });
    }

    videoFilesSource.forEach((item, index) => {
      if (item.playthisone == true) {
        this.currentItem = item;
        this.currentIndex = index;
      }
    });

    this.videoFilesToPlay = videoFilesSource;

    const initialState = {
      videoFilesSource: this.videoFilesToPlay,
      playListName: this.playListName,
      isPopup: true,
      currentIndex: this.currentIndex,
      currentItem: this.currentItem 
    };

    this.modalRef = this.modalService.show(VideoplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true,'modal-dialog--w75'));
  }

  //public removeFromPlayListConfirmation(id: string, playListFileGuid: string, deleteMedia: TemplateRef<any>) {

  //  this.modalRef = this.modalService.show(deleteMedia, this.util.getModalComponentOptions({}, false, true, false));

  //  this.playListGuid = id;
  //  this.deletePlayListFileGuid = playListFileGuid;
  //  this.deletePlayListInvoke = false;
  //}

  public deletePlayList(playListGuid: string) {
    let deletedIndex: number = 0;
    this.playListService.deletePlayList(playListGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.playListDeletedSuccessfully, MessageSeverity.success);
        this.refreshPlayLists();
        //this.modalClose();
        this.onHideDeletePlaylistPop();
        this.closePlayList();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        //this.modalClose();
        this.onHideDeletePlaylistPop();
        this.closePlayList();
      }
    }, err => {
      if (this.util.handleError(err)) {
        //this.modalClose();
        this.onHideDeletePlaylistPop();
      }
      this.closePlayList();
    });
  }

  public removeFromPlayList(playListGuid: string, deletePlayListFileGuid: string) {
    let tempList: string[] = [];
    let temp: any[] = [];
    tempList.push(deletePlayListFileGuid);
    temp = this.playLists;
    this.playListService.removeFromPlayList(playListGuid, tempList).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.spotsAddedPlaylist, MessageSeverity.success);
        this.playListFiles = this.playListFiles.filter(pl => pl.playListFileGuid != deletePlayListFileGuid);
        temp.forEach(pl => {
          if (pl.playListId == playListGuid) {
            pl => pl.count = this.playListFiles.length
          }
        });
        this.playLists = this.playLists.filter(pl => pl.playListGuid == playListGuid).filter(pl => pl.count = this.playListFiles.length);
        this.playLists = temp;
        //this.modalClose();
        this.onHideDeletePlaylistFilePop();
        if (this.playListFiles.length <= 0) { this.detailsPlayListDiv.classList.add('hide'); }
      }
      else {
        //this.modalClose();
        this.onHideDeletePlaylistFilePop();
        this.util.handleIsNotSuccess(res.errors);
      }
    }, err => {
      if (this.util.handleError(err)) {
        //this.modalClose();
        this.onHideDeletePlaylistFilePop();
      }
    });
  }

  public closePLayListDetail() {
    if (this.detailsPlayListDiv != null)
      this.detailsPlayListDiv.classList.add('hide');
  }

  //public modalClose() {
  //  this.modalRef.hide();
  //  this.playListGuid = null;
  //  this.deletePlayListFileGuid = null;
  //  this.deletePlayListInvoke = false
  //}

  public refreshPlayLists() {
    this.closePlayList();
    this.playListService.getPlayLists().subscribe((res: any) => {
      if (res.isSuccess == true) {
        let playListTemp: PlayList[] = res.result;
        this.playListChange.emit(playListTemp);
        for (let plf of playListTemp) {
          plf.thumbnailUrl = this.util.getThumbnailUrl(plf.thumbnailUrl, plf.isAudioExists, true);
        }
        this.playLists = playListTemp;
        this.setPlayListOfLists();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    }, err => {
      this.util.handleError(err);
    });
  }

  public openShareMediaPopUP(playListGuid: string, playListName?: string) {
    let albumName: string = "";
    let spotFileGuids: string[] = this.getPlayListFiles(playListGuid);
    if (playListName != null || playListName != "" || playListName != undefined) { albumName = playListName; }

    var initialState = {
      spotFileGuids,
      playListGuid: playListGuid,
      albumName: albumName
    };

    this.modalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onHideDeletePlaylistFilePop() {
    //this.delPlayListFilepop.hide();
    this.delPlayListFilepop.forEach(p => {
      p.hide();
    });
  }

  public onHideDeletePlaylistPop() {
    //this.delPlayListpop.hide();
    this.delPlayListpop.forEach(p => {
      p.hide();
    });
  }

  public closePopup() {
    this.modalService.hide();
  }
}
