<header class="tylie-header">
  <h3 class="tylie-header__title">Order Transcodes</h3>
  
  <nav class="tylie-header__nav" *ngIf="requestOrigination == 'Transcode'">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onGotoAllOrders()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>BACK TO ALL ORDERS</button>
  </nav>
  <nav class="tylie-header__nav" *ngIf="requestOrigination == 'Order' || requestOrigination == 'OrderEdit'">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onGotoCurrentOrder()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>BACK TO CURRENT ORDER</button>
  </nav>

</header>

<div class="tylie-body">
  <div *ngIf="transcodeOrderConfig">

    <div id="searchmedia_step" *ngIf="currentStep == transcodeOrderStepEnum.selectMedia && (((requestOrigination == 'Order' || requestOrigination == 'OrderEdit') && orderGroupSpotFileGuids.length > 0) || requestOrigination == 'Transcode')">
      <transcode-searchmedia-step [transcodeOrderConfig]="transcodeOrderConfig"
                                  [selectedSpotFiles]="orderRequest.spotFilesData"
                                  [isInternalRequest]="orderRequest.isInternalRequest"
                                  [originalWorkOrders]="orderRequest.originalWorkOrders"
                                  [selectedClientId]="clientId"
                                  [selectedBrandId]="brandId"
                                  [requestOrigination]="requestOrigination"
                                  [orderGroupSpotFileGuids]="orderGroupSpotFileGuids">
      </transcode-searchmedia-step>
    </div>

    <div id="transcodedestinations_step" class="notifications-section" *ngIf="currentStep == transcodeOrderStepEnum.selectTranscode">
      <transcode-specselection-step [transcodeOrderConfig]="transcodeOrderConfig"
                                    [selectedSpotFile]="selectedSpotFile"
                                    [selectedSpotFileTranscodes]="selectedSpotFileTranscodes"
                                    [isLastOne]="lastSelectedSpotFile"
                                    [selectionType]="orderRequest.selectionType">
      </transcode-specselection-step>
    </div>

    <div id="filenaming_step" *ngIf="currentStep == transcodeOrderStepEnum.chooseFileName">
      <transcode-filename-step [transcodeOrderConfig]="transcodeOrderConfig"
                               [transcodeData]="orderRequest.spotFileTranscodesData"
                               [spotData]="orderRequest.spotFilesData"
                               [fileNameOptions]="orderRequest.fileNameOptions"
                               [customFileName]="orderRequest.customFileName"
                               [customFileNaming]="orderRequest.customFileNaming"
                               [fileNamingType]="orderRequest.fileNamingType"
                               [fileSeperator]="orderRequest.fileSeperator">
      </transcode-filename-step>
    </div>

    <div id="review_step" class="notifications-section" *ngIf="currentStep == transcodeOrderStepEnum.reviewTranscode && !isOrderSubmitted">
      <div class="tylie-panel">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            ORDER REVIEW
          </h4>
        </div>
        <div id="mediaApprovalCollapseOne" class="tylie-panel__body" *ngIf="requestOrigination == 'Transcode'">
          <h3 class="tylie-body__title">Review and Submit Order</h3>

          <div class="row" *ngIf="transcodeOrderConfig.isDealerUser == false">
            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Reference Code (optional)<span class="ms-2"><icon-question-mark></icon-question-mark></span></label>
                <input name="refcode" class="tylie-text form-control" [(ngModel)]="orderRequest.referenceCode">
              </div>
            </div>

            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">
                  PO Number
                  <!--<span class="ms-2"><icon-question-mark></icon-question-mark></span></label>
                  <i class="tylicon tylicon-quation">
                  </i>-->
                </label>
                <input name="ponum" class="tylie-text form-control" [(ngModel)]="orderRequest.poNumber">
                <div class="p-small group-error-message" *ngIf="poNumberValidator(orderRequest.poNumber)">Please enter Purchase Order.</div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Job Number (optional)<span class="ms-2"><icon-question-mark></icon-question-mark></span></label>
                <input name="jobnum" class="tylie-text form-control" [(ngModel)]="orderRequest.jobNumber">
              </div>
            </div>
          </div>

          <div class="row" *ngIf="transcodeOrderConfig.isDealerUser == true">
            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Ordered By (optional)<span class="ms-2"><icon-question-mark></icon-question-mark></span></label>
                <input name="orderedBy" class="tylie-text form-control" [(ngModel)]="orderRequest.orderedBy">
              </div>
            </div>

            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Dealer Number (optional)<span class="ms-2"><icon-question-mark></icon-question-mark></span></label>
                <input name="dealerNumber" class="tylie-text form-control" [(ngModel)]="orderRequest.dealerNumber">
              </div>
            </div>

            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl">Dealer Name (optional)<span class="ms-2"><icon-question-mark></icon-question-mark></span> </label>
                <input name="dealerName" class="tylie-text form-control" [(ngModel)]="orderRequest.dealerName">
              </div>
            </div>
          </div>

          <ng-container *ngIf="isTierMaxCapExceeded">
            <p class="p-small error-text error-message">{{maxCapExceededMessage}}</p>
          </ng-container>

          <ng-container *ngIf="orderErrors && orderErrors.length > 0">
            <ng-container *ngFor="let err of orderErrors">
              <p class="p-small" style="color: #ec1c23">{{err}}</p>
            </ng-container>
          </ng-container>

          <!-- // pricing-->
          <ng-container *ngIf="transcodeOrderConfig.perTranscodePrice.length > 0">
            
            <div class="tylie-panel tylie-panel--secondary" id="buildOrderPanel">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  Pricing
                </h4>
              </div>
              <div class="tylie-panel__body">
                <div class="tylie-type-body">
                    Per Transcode Pricing for Brands (Does not indicate your order total)
                </div>
                <table class="tylie-table table">
                  <thead class="tylie-table__head">
                    <tr>
                      <th style="width:20%;">Brand Name</th>
                      <th style="width:80%;">Price</th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <tr *ngFor="let att of transcodeOrderConfig.perTranscodePrice; let i = index">
                      <td style="width:20%;">{{att.brandName}}</td>
                      <td style="width:76%;">{{att.price}}$</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>

          <!--// attachments-->
          <div class="tylie-panel tylie-panel--secondary" id="buildOrderPanel">
            <div class="tylie-panel__header tylie-panel__header--br-thick">
              <h4 class="tylie-panel__title">
                Attachments
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddAttachments()">
                  <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                  Add Attachments
                </button>
              </h4>
            </div>
            <div class="tylie-panel__body">
              <ng-container *ngIf="orderRequest.attachments.length == 0">
                  There are no attachments yet
              </ng-container>
              <table class="tylie-table table table-bordered" *ngIf="orderRequest.attachments.length > 0">
                <thead class="tylie-table__head">
                  <tr>
                    <th style="width:20%;">File Type</th>
                    <th style="width:76%;">File Name</th>
                    <th style="width:4%;"></th>
                  </tr>
                </thead>
                <tbody class="tylie-table__body">
                  <tr *ngFor="let att of orderRequest.attachments; let i = index">
                    <td style="width:20%;">{{att.fileCategory}}</td>
                    <td style="width:76%;" class="quickLink"><a (click)="onDownloadFile(att.orderFileGuid, att.fileName);">{{att.fileName}}</a></td>
                    <td align="center">
                      <i class="tylie-button tylie-button--xs tylie-button--link" (click)="onAttachmentDelete(att.orderFileGuid)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


          <div class="tylie-panel tylie-panel--secondary" *ngFor="let spotFile of orderRequest.spotFilesData">
            <div class="tylie-panel__header tylie-panel__header--br-thick">
              <h4 class="tylie-panel__title">
                CREATIVE BEING ORDERED
              </h4>
            </div>
            <div class="tylie-panel__body">
              <h4 class="tylie-type-h4">{{spotFile.title}}</h4>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Ad-ID:</span>
                <span class="tylie-data__txt">{{spotFile.adId}}</span>
              </div>
              <div class="tylie-data">
                <span class="tylie-data__txt">Duration:</span>
                <span class="tylie-data__txt">{{spotFile.length}}</span>
              </div>
              <br>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Client:</span>
                <span class="tylie-data__txt">{{spotFile.client}}</span>
              </div>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Brand:</span>
                <span class="tylie-data__txt">{{spotFile.brand}}</span>
              </div>
              <div class="tylie-data">
                <span class="tylie-data__txt">Product:</span>
                <span class="tylie-data__txt">{{spotFile.product}}</span>
              </div>

              <table class="tylie-table table mt-4">
                <tbody class="tylie-table__body">
                  <tr *ngFor="let transcode of orderRequest.spotFileTranscodesData.get(spotFile.spotFileGuid)">
                    <td style="width:20%;"><strong>{{transcode}}</strong></td>
                    <td style="width:76%;">{{getFileName(transcode, spotFile.spotFileGuid)}}</td>
                    <td>
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDeleteInReview(spotFile.spotFileGuid, transcode)">
                        <icon-delete color="#1e90ff"></icon-delete>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="mediaApprovalCollapseOne" class="tylie-panel__body" *ngIf="requestOrigination == 'Order' || requestOrigination == 'OrderEdit'">

          <h3 class="tylie-body__title">Review and Add to Order</h3>

          <div class="col-md-12 dest-notes" *ngIf="isTierMaxCapExceeded">
            <p class="p-small error-text error-message">{{maxCapExceededMessage}}</p>
          </div>

          <div class="col-md-12" *ngIf="orderErrors && orderErrors.length > 0">
            <br />
            <ng-container *ngFor="let err of orderErrors">
              <p class="p-small" style="color: #ec1c23">{{err}}</p>
            </ng-container>
          </div>

          <div class="tylie-panel tylie-panel--secondary" *ngFor="let spotFile of orderRequest.spotFilesData">
            
            <div class="tylie-panel__header tylie-panel__header--br-thick">
              <h4 class="tylie-panel__title">
                CREATIVE BEING ORDERED
              </h4>
            </div>
            <div class="tylie-panel__body">
              
              <h4 class="tylie-type-h4">{{spotFile.title}}</h4>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Ad-ID:</span>
                <span class="tylie-data__txt">{{spotFile.adId}}</span>
              </div>
              <div class="tylie-data">
                <span class="tylie-data__txt">Duration:</span>
                <span class="tylie-data__txt">{{spotFile.length}}</span>
              </div>
              <br>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Client:</span>
                <span class="tylie-data__txt">{{spotFile.client}}</span>
              </div>
              <div class="tylie-data me-3">
                <span class="tylie-data__txt">Brand:</span>
                <span class="tylie-data__txt">{{spotFile.brand}}</span>
              </div>
              <div class="tylie-data">
                <span class="tylie-data__txt">Product:</span>
                <span class="tylie-data__txt">{{spotFile.product}}</span>
              </div>
              <table class="tylie-table table mt-4">
                <tr *ngFor="let transcode of orderRequest.spotFileTranscodesData.get(spotFile.spotFileGuid)">
                  <td style="width:20%;"><strong>{{transcode}}</strong></td>
                  <td style="width:76%;">{{getFileName(transcode, spotFile.spotFileGuid)}}</td>
                  <td>
                    <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onDeleteInReview(spotFile.spotFileGuid, transcode)">
                      <icon-delete color="#1e90ff"></icon-delete>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
            
        </div>
      </div>
    </div>

    <div class="tylie-panel" id="confirmation_step" *ngIf="isOrderSubmitted && workOrder && requestOrigination == 'Transcode'">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          ORDER CONFIRMATION
        </h4>
      </div>
      <div id="mediaApprovalCollapseOne" class="tylie-panel__body">
        <div class="row">
          <div class="col-md-12">
            <div class="file-name-large">Order# {{workOrder}}</div>
          </div>
          <div class="col-md-12 p-normal-bold">
            Thank you for your order. Team Tylie is processing it now.
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-center" id="nav_back_front" *ngIf="!isOrderSubmitted">
        <button *ngIf="!isFirstStep" type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onPrevious()">
          <span class="tylie-button__icon"><icon-arrow-circle-left class="#1e90ff"></icon-arrow-circle-left></span>
          PREVIOUS STEP
        </button>
        
        <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" *ngIf="!isLastStep" (click)="onContinue()">
          <span class="tylie-button__icon"><icon-arrow-circle-right></icon-arrow-circle-right></span>
          <span class="tylie-button__text">Continue to next step</span>
        </button>

        <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="submit" *ngIf="isLastStep && requestOrigination == 'Transcode'" (click)="onSubmitOrder()">
          <span class="tylie-button__icon"><icon-arrow-circle-right></icon-arrow-circle-right></span>
          <span class="tylie-button__text"> Submit Order</span>
        </button>

        <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="submit" *ngIf="isLastStep && requestOrigination == 'Order'" (click)="onAddToCurrentOrder()">
          <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
          <span class="tylie-button__text">Add to Order</span>
        </button>

        <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="submit" *ngIf="isLastStep && requestOrigination == 'OrderEdit'" (click)="onSaveContinueToOrder()">
          <span class="tylie-button__icon"><icon-save></icon-save></span>
          <span class="tylie-button__text">Save and Continue</span>
        </button>
    </div>
  </div>
</div>
