<div id="saved-destinations">
  <div class="modal-header">
    <button type="button"
            class="modal-close"
            (click)="closePopup()">
      <icon-delete color="#000"></icon-delete>
    </button>
    <h4 class="modal-title">ADD PRODUCTS</h4>
  </div>
  <div class="modal-body">
    <div class="row search-all-media">
      <div class="col-md-12">
        <dx-data-grid id="productsGrid" class="tylie-grid tylie-grid--bordered"
                      #productsGrid
                      [dataSource]="dataSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      (onContentReady)="onContentReady($event)"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="name" width="100%" cssClass="gridStyle" caption="Product Name" sortOrder="asc"></dxi-column>

          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button class="tylie-button tylie-button--xs" type="button" (click)="onaddSelectedProducts()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

