<app-tagger-header [activeLinks]="['locations-users']"></app-tagger-header>

<div class="tylie-body">
    <div class="tylie-body__top-bar">
        <h4 class="tylie-body__title">Locations & Users</h4>
        <div class="d-flex justify-content-end">
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onAddLocation()">
                <span class="tylie-button__icon">
                    <icon-plus-circle></icon-plus-circle>
                </span>
                <span class="tylie-button__text">ADD A LOCATION</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" (click)="onClickAddUser()">
                <span class="tylie-button__icon">
                    <icon-plus-circle></icon-plus-circle>
                </span>
                <span class="tylie-button__text">ADD A USER</span>
            </button>
        </div>
    </div>


    <div class="tylie-panel tylie-panel--br-top">
        <div class="tylie-panel__body">
            <div class="row">
                <div class="col-6">
                    <section class="tylie-input-search">
                        <div class="tylie-input-search__inner">
                            <div  class="tylie-input-search__icon">
                                <icon-search></icon-search>
                            </div>
                            <input class="tylie-input-search__text"
                                placeholder="Search by keyword"
                                name="srch-term"
                                id="srch-term"
                                type="search"
                                [(ngModel)]="quickSearchValue"
                                (ngModelChange)="clearQuickSearch()"
                                (keydown.enter)="
                                    onEnterQuickSearch($event)
                                " />
                        </div>
                        <div class="tylie-input-search__btn">
                            <button
                                class="tylie-button tylie-button--sm"
                                type="button"
                                (click)="quickSearch()"
                            >
                                Search
                            </button>
                        </div>
                    </section>
                </div>
                <div class="col-6">
                    <button type="button"
                        class="tylie-button tylie-button--link tylie-button--xs collapsed mt-3"
                        data-bs-toggle="collapse"
                        href="#advancedSearchContainer"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                    >
                        Advanced Search
                        <span class="tylie-button__icon e">
                            <icon-arrow-simple-down
                                height="12px"
                                width="12px"
                                color="#1e90ff"
                            ></icon-arrow-simple-down>
                        </span>
                    </button>
                </div>
            </div>
            <div class="tylie-section collapse mt-3" id="advancedSearchContainer">
                <form novalidate (ngSubmit)="onFormSubmit()" [formGroup]="searchRequestFormGroup" role="search">
                    <div class="row">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="tylie-form-group form-group col-9">
                                <label for="group" class="tylie-form-group__lbl">Groups</label>
                               
                                <dx-tag-box
                                    [dataSource]="groupDataSource"
                                    name="group"
                                    id="group"
                                    class="tylie-select form-control"
                                    formControlName="groups"
                                    valueExpr="groupGuid"
                                    [showClearButton]="true"
                                    [showSelectionControls]="true"
                                    displayExpr="name"
                                    placeholder="Select a value..."
                                >
                                </dx-tag-box>
                            </div>

                            <div class="tylie-form-group form-check mb-0 d-flex gap-2 align-items-center">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check"
                                    formControlName="unassignedGroup"
                                    name="unassignedGroup"
                                    id="unassignedGroup"
                                />
                                <label
                                    class="form-check-label"
                                    for="unassignedGroup"
                                    >Unassigned to Groups</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between">
                        <div class="row col-4">
                            <div class="tylie-form-group form-group">
                                <label for="state" class="tylie-form-group__lbl">State</label>
                                <dx-tag-box
                                    [dataSource]="stateDataSource"
                                    name="state"
                                    id="state"
                                    class="tylie-select form-control"
                                    formControlName="states"
                                    valueExpr="shortCode"
                                    [showClearButton]="true"
                                    [showSelectionControls]="true"
                                    displayExpr="stateName"
                                    placeholder="Select a value..."
                                >
                                </dx-tag-box>
                            </div>
                        </div>

                        <div
                            class="d-flex align-items-center justify-content-end"
                        >
                            <button type="button"
                                class="tylie-button tylie-button--xs tylie-button--link me-3"
                                (click)="clearSearch()"
                            >
                                Clear Search
                            </button>
                            <button
                                class="tylie-button tylie-button--xs tylie-button--icon"
                                type="submit"
                            >
                                <span class="tylie-button__icon">
                                    <icon-search
                                        color="#fff"
                                    ></icon-search>
                                </span>
                                <span class="tylie-button__text"
                                    >SEARCH</span
                                >
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="tylie-panel tylie-panel--br-top">
        <div class="tylie-panel__body">
            <dx-data-grid
                class="tylie-grid"
                id="locationSearch"
                #locationSearch
                [dataSource]="resultLocationStore"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [hoverStateEnabled]="true"
                keyExpr="locationGuid"
                [rowAlternationEnabled]="true"
                (onCellPrepared)="onCellPrepared($event)"
                [masterDetail]="{
                    enabled: true,
                    template: 'detailTemplate',
                    autoExpandAll: false
                }"
            >
                <dxo-remote-operations
                    [sorting]="true"
                    [paging]="true"
                >
                </dxo-remote-operations>
                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

                <dxi-column
                    [allowSorting]="true"
                    dataField="name"
                    caption="Location"
                    cellTemplate="locationNameTemplate"
                    cssClass="gridStyle"
                    sortOrder="asc"
                ></dxi-column>
                <dxi-column
                    [allowSorting]="true"
                    dataField="address"
                    caption="Address"
                    width="20%"
                    cssClass="gridStyle"
                ></dxi-column>
                <dxi-column
                    [allowSorting]="true"
                    dataField="city"
                    caption="City"
                    width="20%"
                    cssClass="gridStyle"
                >
                </dxi-column>
                <dxi-column
                    [allowSorting]="true"
                    dataField="state"
                    caption="State"
                    width="10%"
                    cssClass="gridStyle tyl-text-align-left"
                ></dxi-column>
                <dxi-column
                    [allowSorting]="true"
                    dataField="zipCode"
                    caption="Zip Code"
                    width="15%"
                    cssClass="gridStyle"
                ></dxi-column>

                <div *dxTemplate="let t of 'locationNameTemplate'" class="d-flex align-items-center gap-2">
                    <span class="tylie-button__icon">
                        <icon-location></icon-location>
                    </span>
                    <span>{{t.data.name}}</span>
                </div>

                <div *dxTemplate="let t of 'detailTemplate'" class="detail-grid-margin-left-small py-3">
                    <div class="mb-3">
                        <h3 class="tylie-type-h3">{{ t.data.name }}</h3>
                        <div class="tylie-data tylie-data--block">
                            <span class="tylie-data__txt">{{ t.data.address && t.data.address + ", "
                            }}{{ t.data.city && t.data.city + ", "
                            }}{{ t.data.state }}
                            {{ t.data.zipCode }}</span>
                        </div>
                        <button type="button" class="tylie-button tylie-button--xs tylie-button--link mt-2" (click)="onEditLocation(t.data)">
                            Edit
                        </button>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <h3 class="tylie-type-h3">Groups</h3>
                            <div class="tylie-data tylie-data--block" *ngFor="
                                    let group of t.data.groups;
                                    let i = index
                                "
                            >
                                <span class="tylie-data__txt">{{ group.name }}</span>
                            </div>
                            <button type="button" class="tylie-button tylie-button--xs tylie-button--link mt-2" (click)="onEditGroup(t.data)">
                                Add / Edit
                            </button>
                        </div>

                        <div class="row col-8">
                            <h3 class="tylie-type-h3">Users</h3>
                            <ng-container *ngIf="t.data.locationUsers?.length > 0">
                                <div class="tylie-data tylie-data--block" *ngFor="
                                        let user of t.data.locationUsers;
                                        let i = index
                                    "
                                >
                                    <div class="tylie-data__txt">{{ user.userDetail?.fullName }}</div>
                                    <div class="tylie-data__txt">{{ user.title }}</div>
                                    <div class="tylie-data__txt">{{ user.userDetail?.email }}</div>
                                    <div class="tylie-data__txt">{{ user.userDetail?.cellPhone }}</div>
                                    
                                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link mt-2" (click)="onClickEditLocationUser(t.data, user)">
                                        Edit
                                    </button>
                                </div>
                            </ng-container>
                            <div>
                                <button type="button"
                                    class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link"
                                (click)="onClickAddLocationUser(t.data)" >
                                    <span class="tylie-button__icon">
                                        <icon-plus-circle
                                            color="#1e90ff"
                                        ></icon-plus-circle>
                                    </span>
                                    <span>ADD A USER</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager
                    [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 20, 50, 100]"
                    infoText="Page {0} of {1} ({2} items)"
                    [showNavigationButtons]="true"
                    [showInfo]="true"
                >
                </dxo-pager>
            </dx-data-grid>
        </div>
    </div>
</div>
