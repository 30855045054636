import { OrderDestination } from "./order-destinations.model";
import { OrderUserDestination } from "./order-userdestination";
import { UserEmailDistributionGroups } from "../user/user-email-distribution-group.model";
import { DestinationAddlInfo, OrderFile, ServiceData, ServiceMetadata } from "./order-create.model";
import { SpotFile } from "../spot/spot-file.model";
import { ServiceType } from "./servicetype.model";
import { GroupError } from "./ordererror.model";
import { SpotResendConfirm } from "./spot-onhand.model";
import { PredefinedSpec } from "./order-additionalmetadata";
import { TranscodeMediaOrderRequest } from "./transcodeorder-config.model";
import { OrderContentDownloadViewModel } from "./order-userdestination";

export class OrderDetail {
  public header: OrderDetailHeader;
  public groups: Array<OrderDetailGroup>;
  public errors: string[];

  public orderGuid: string;
  public orderId: number;
  public workOrder: string;
  public statusId: number;
  public status: string;

  public isDigitalDeliveryOnly: boolean;
  public config: OrderDetailConfig;
}

export class OrderDetailConfig {
  public lastGroupSequence: number;
  public costCenter: Array<string>;
  public inVoiceTo: Array<string>;
  public userName: string;
  public attachmentCategories: Array<any>;
  public spotDestinationOnHandDurationInDays: number;
  public isPoRequiredForClient: boolean;
  public orderActions: OrderDetailAction;
  public transcodePredefinedSpecs: Array<PredefinedSpec>;
  public opsSummaryBaseLink: string;
  public customFileNameOptions: Array<string>;
  public orderedBy: Array<string>;
  public specialVisibilityClients: any[];
}

export class OrderDetailAction {
  public isAllowedToTraceOrder: boolean;
  public isAllowedToApprove: boolean;
  public showReviewCancelClaim: boolean;
  public isAllowedToCompleteOrderProcessing: boolean;
  public showFlagAndNotify: boolean;
  public showSendForReview: boolean;
  public showEditHistory: boolean;
  public showCopyFilenameLink: boolean;
  public showBillingItems: boolean
  public showSpecialFlags: boolean;
  public showEditBillingOnlyOrder: boolean;
  public showDestinationsTab: boolean;
  public showFTPTab: boolean;
  public showTranscodesTab: boolean;
  public showShareLinksTab: boolean;
  public showOrdersTab: boolean;
  public showCarrierInDetailTabs: boolean;
  public showTransferLinkColumnInDetailTabs: boolean;
  public showActionsColumnInDestinationDestinationsTab: boolean;
  public ShowActionsColumnInSpotsTab: boolean;
  public showVendorAssignmentFailedError: boolean;
  public showSpotVendorAssignmentFailedError: boolean;
  public showEditHub: boolean;
  public isAllowedToSendOrderToAE: boolean;
  public showEditButton: boolean;
  public isExclusiveEdit: boolean;
  public showInEditMode: boolean;
  public editScope: Array<string>;
  public showViewPOD: boolean;
  public canEditHeader: boolean;
  public canEditSpecialHeader: boolean;
  public showViewPO: boolean;
  public canEditHubbedDestination: boolean;
  public canEditBillingOnlyOrder: boolean;
  public canAddCXOpsComments: boolean;
  public canEditGroupName: boolean;
  public canEditRush: boolean;
  public canFTPUpdate: boolean;
  public canAddGroup: boolean;
  public canDeleteGroup: boolean;
  public canDeleteAwaitingMediaGroup: boolean;
  public canAddMedia: boolean;
  public canDeleteMedia: boolean;
  public canDeleteAwaitingMedia: boolean;
  public canAddDestination: boolean;
  public canDeleteDestination: boolean;
  public canAddOrderLevelServices: boolean;
  public canUploadPod: boolean;
  public canUpdateSpecialServices: boolean;
  public showSendToBilling: boolean;

  public showAwaitingReviewClaim: boolean;

  public showStartProcessClaim: boolean;
  public showViewOPsSummaryButton: boolean;
  public showSendOrderEmailButton: boolean;
  public isReSendOrderEmail: boolean;
}

export class OrderDetailGroup {
  public isRush: boolean;
  public name: string;
  public sequenceId: number;
  public destinations: Array<OrderDestination>;
  public ftpDestinations: Array<OrderUserDestination>;
  public shareLinks: Array<UserEmailDistributionGroups>;
  public spotData: Array<OrderDetailSpotServiceData>;
  public orderGroupId: number;
  public serviceData: Array<OrderDetailServiceData>;
  public groupError: GroupError;

  // for group panel bar to red
  public containsGroupErrors: boolean;

  // for table header text to red
  public containsGroupServiceErrors: boolean;
  public containsGroupAdIdErrors: boolean;
  public containsGroupTitleErrors: boolean;
  public containsGroupDestinationErrors: boolean;
  public containsGroupServiceLevelErrors: boolean;
  public containsAdIDWarnings: boolean;


  public resendData: Array<SpotResendConfirm>;
  public isEdited: boolean;
  public isAllAwaitingMediaSpotsInGroup: boolean;
  public transcodeRequest: TranscodeMediaOrderRequest;

  public isAdded: boolean;
  public isDeleted: boolean;
}

export class OrderDetailHeader {
  public clientId: number;
  public brandId: number;
  public productId: number;
  public client: string;
  public brand: string;
  public product: string;
  public location: string;
  public clientPurchaseOrder: string;
  public jobNumber: string;
  public inVoiceTo: string;
  public costCenter: string;
  public estimate: string;
  public refKeywords: string;

  public billedDate: Date;
  public dueDate: Date;
  public airDateStart: Date;
  public airDateEnd: Date;

  public originatedFrom: string;
  public orderedBy: string;

  public comments: Array<OrderDetailComment>;
  public orderComments: Array<OrderDetailComment>;
  public systemComments: Array<OrderDetailComment>;
  public reviewComments: Array<OrderDetailComment>;
  public sendToAEComments: Array<OrderDetailComment>;

  public notes: Array<OrderDetailComment>;

  public attachments: Array<OrderFile>;

  public progress: Array<OrderProgress>;

  public consolidatedOrders: Array<ConsolidatedOrder>;

  public serviceData: Array<OrderDetailServiceData>;

  public isConsolidatedOrder: boolean;
  public isProBono: boolean;
  public isDestinationsHubbed: boolean;
  public isFinalReviewRequired: boolean;
  public isOrderFlagged: boolean;
  public isSetToReviewByOps: boolean;

  public originalWorkOrder: string;

  public podEmails: string;

  public reviewCompletedOn: Date;
  public reviewedBy: string;
  public claimedOn: Date;
  public claimedBy: string;
  public processStartedOn: Date;
  public processStartedBy: string;
  public processCompletedOn: Date;
  public processCompletedBy: string;
  public editStartedBy: string;
  public editExpiryOn: Date;
  public createdOn: Date;
  public modifiedOn: Date;
  public createdBy: string;
  public modifiedBy: string;
  public specialVisibilityClient: number;
  public specialVisibilityClientName: string;
  public isPartnerClientUser: boolean;
  public isPostDatedOrder: boolean;
}

export class ConsolidatedOrder {

  public workOrder: string;
  public status: string;
  public createdBy: string;
  public createdOn: Date;
  public isParent: boolean;
  public orderGuid: string;
}

export class OrderProgress {
  public progressText: string;
  public isActive: boolean;
}

export class OrderDetailSpotServiceData {
  public spotFileGuid: string;
  public pPSpotFileGuid: string;

  public groupSpotId: string;

  public spotFile: SpotFile;
  public serviceData: Array<OrderDetailServiceData>;
  public ppSpotFile: SpotFile;

  public destinationAddlInfo: Array<DestinationAddlInfo>;


  public isAddlExists: boolean;
  public isPpExists: boolean;
  public isDeliveryExists: boolean;

  public containsSpotServiceDataErrors: boolean;
  public containsAdIdErrors: boolean;
  public containsTitleErrors: boolean;

  public containsSpotErrors: boolean;

  public containsPpSpotAdIdErrors: boolean;
  public containsPpSpotTitleErrors: boolean;

  public errorMsgSpot: string;

  public errorMsgPpSpotAdId: string;
  public errorMsgPpSpotTitle: string;
}

export class OrderDetailServiceData {
  public serviceId: number;
  public service: ServiceType;
  public serviceMetadata: Array<ServiceMetadata>;

  public orderServiceId: number;

  public recentlyAdded: boolean;
  public containsServiceMetadataDataErrors: boolean;
  public optionsSubmitted: boolean;
  public errorMsg: string;
  public displaySequence: number;
  public parentServices: Array<number>;
}

export class OrderDetailComment {
  public user: string;
  public comments: string;
  public commentType: string;
  public createdBy: string;
  public created: Date;
}

export class RouteToTranscodeEmitData {
  public isSpotEdit: boolean;
  public spotFileGuid: string;
}

export class EmailOrderRequestViewModel {
  public isDraftOrder: boolean;
  public toEmails: string;
  public orderGuid: string;
}
