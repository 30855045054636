import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'icon-plus-circle',
  templateUrl: './icon-plus-circle.component.html',
  styleUrls: ['./icon-plus-circle.component.css']
})
export class IconPlusCircleComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#ffffff';
  constructor() { }

  ngOnInit() {
  }

}
