<div class="search-all-media" id="search-all-media-section">
  <div class="panel panel-default" id="mediaSearchPanel">
    <div class="panel-heading">
      <h4 class="panel-title" data-toggle="collapse" data-target="#distroSearchCollapseOne" aria-expanded="true">
        <a class="p-small">
          SEARCH ALL DISTRIBUTION ASSETS
        </a>
      </h4>
    </div>
    <div id="distroSearchCollapseOne" class="panel-collapse collapse in">
      <div class="panel-body">
        <div class="col-md-12 main-div">
          <div class="input-group add-on col-md-6 col-sm-6  col-xs-6 search-text">
            <input class="form-control" placeholder="Search By Ad-ID, Title" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                   (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
            <div class="input-group-btn">
              <button class="btn btn-default qc-submit" type="button" (click)="quickSearch()">Search</button>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6 advance-search" data-toggle="collapse" data-target="#advance-search" aria-expanded="false">Advanced Search</div>
        </div>

        <div class="advance-search-container col-md-12 collapse" id="advance-search">
          <div class="advanced-search-caption p-large">Advanced Search</div>
          <form novalidate (ngSubmit)="onFormSubmit(searchRequest)" [formGroup]="searchRequest" role="search" #f="ngForm">
            <div class="row col-md-12">

              <div class="form-group col-md-2">
                <label for="client">Advertiser</label>
                <dx-select-box [dataSource]="clientDataSource"
                               name="clientId" id="clientId" class="form-control"
                               formControlName="clientId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               [showClearButton]="true"
                               displayExpr="name"
                               (onValueChanged)="onClientValueChanged($event)"
                               placeholder="Select a value...">
                </dx-select-box>
              </div>

              <div class="form-group col-md-2">
                <label for="product">Brand</label>
                <dx-select-box [dataSource]="brandDataSource"
                               name="brandId" id="brandId" class="form-control"
                               formControlName="brandId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               [showClearButton]="true"
                               (onValueChanged)="clearProductSelection($event);"
                               displayExpr="name"
                               placeholder="Select a value...">
                </dx-select-box>
              </div>

              <div class="form-group col-md-2">
                <label for="product">Product</label>
                <dx-select-box [dataSource]="productDataSource"
                               name="productId" id="productId" class="form-control"
                               formControlName="productId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               [showClearButton]="true"
                               displayExpr="name"
                               placeholder="Select a value....">
                </dx-select-box>
              </div>

              <div class="form-group col-md-2">
                <label for="cf">Delivery Date</label>
                <dx-date-box type="date" formControlName="deliveryDate" id="cf"
                             displayFormat="MM/dd/yyyy"
                             class="form-control" [showClearButton]="true"></dx-date-box>
              </div>

              <div class="form-group col-md-2">
                <label for="ct">Download Date</label>
                <dx-date-box type="date" formControlName="downloadDate" id="ct"
                             displayFormat="MM/dd/yyyy"
                             class="form-control" [showClearButton]="true"></dx-date-box>
              </div>
            </div>
            <div class="row col-md-12">
              <div class="form-group col-md-2">
                <label for="mediatype">Asset Type</label>
                <dx-select-box [dataSource]="mediatypeDataSource"
                               name="mediaTypeId"
                               id="mediaTypeId"
                               class="form-control"
                               formControlName="mediaTypeId"
                               [showClearButton]="true"
                               valueExpr="id"
                               displayExpr="name"
                               placeholder="Select a value...">
                </dx-select-box>
              </div>

              <div class="form-group col-md-2">
                <label for="format">Format</label>
                <dx-select-box [dataSource]="formatDataSource"
                               name="formatTypeId" id="formatTypeId" class="form-control"
                               formControlName="formatTypeId"
                               valueExpr="id"
                               [showClearButton]="true"
                               displayExpr="name"
                               placeholder="Select a value...">
                </dx-select-box>
              </div>

              <div class="form-group col-md-2">
                <label for="format">Station</label>
                <dx-select-box [dataSource]="stationDataSource"
                               name="destination" id="destination" class="form-control"
                               formControlName="destination"
                               valueExpr="code"
                               [showClearButton]="true"
                               displayExpr="code"
                               placeholder="Select a value...">
                </dx-select-box>
              </div>
            </div>
            <div class="form-group col-md-8"></div>
            <div class="col-md-4"></div>

            <div class="form-group col-md-2">
              <button class="btn tyl-btn btn-extra-small adv-search" type="submit">Search</button>
            </div>
            <div class="form-group col-md-2">
              <span class="clear-search" (click)="clearSearch()" style="vertical-align:central">Clear Search</span>
            </div>
          </form>
        </div>

        <div class="search-all-media-actions1">
          <button class="btn tyl-btn btn-extra-small tyl-no-margin-bottom" secured [accessControlListComponent]="[this.userRoles.getMediaDistributionViewer()]" (click)="downloadSpots()">
            <span>
              <i class="glyphicon glyphicon-save"></i>Download
            </span>
          </button>
          <button class="btn tyl-btn btn-extra-small tyl-no-margin-bottom" secured [accessControlListComponent]="[this.userRoles.getMediaDistributionViewer()]" (click)="onRepitchDistroMedia()">
            <i class="glyphicon glyphicon-repeat"></i><span>Repitch</span>
          </button>
        </div>
      </div>
      <div class="panel-footer panel-footer-media-qc"></div>
      <div class="panel-body">
        <dx-data-grid id="distributionSearch"
                      #distributionSearch
                      [dataSource]="resultSpotsStore"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [hoverStateEnabled]="true"
                      keyExpr="mediaId"
                      [rowAlternationEnabled]="true"
                      (onCellPrepared)="onCellPrepared($event)"
                      [masterDetail]="{ enabled: true, template: 'filesTemplate', autoExpandAll: false }">
          <dxo-remote-operations [sorting]="true"
                                 [paging]="true">
          </dxo-remote-operations>
          <dxo-selection mode="multiple"
                         selectAllMode="page"
                         allowSelectAll="Select"
                         showCheckBoxesMode="always">
          </dxo-selection>
          <dxo-sorting mode="multiple"></dxo-sorting>
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" caption="Thumbnail" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="adID" caption="Ad-ID" cellTemplate="adIDTemplate" width="10%" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="title" width="17%" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="durationInSecs" caption="Duration" width="8%" cssClass="gridStyle tyl-text-align-left"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="format" cellTemplate="formatTemplate" cssClass="gridStyle" width="10%"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="client" width="15%" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="brand" width="10%" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" width="10%" dataField="product" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="houseId" caption="House ID" width="8%" cssClass="gridStyle" cellTemplate="houseIdTemplate"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="deliveryDate" cellTemplate="dateTemplate" width="11%" cssClass="gridStyle"></dxi-column>
          <dxi-column [allowSorting]="false" dataField="workOrder" width="11%" cssClass="gridStyle"></dxi-column>

          <div *dxTemplate="let t of 'thumbnailTemplate'" id="search-media">
            <div class="tylie-img-container tylie-img-container--thumbnail">
              <img class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
            </div>
          </div>
          <div *dxTemplate="let t of 'adIDTemplate'">
            <span>{{t.data.adID}}</span>
          </div>
          <div *dxTemplate="let t of 'formatTemplate'">
            <div class="dx-datagrid-nowrap-remove">
              <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
            </div>
          </div>
          <div *dxTemplate="let t of 'filesTemplate'">
            <dx-data-grid [showBorders]="false"
                          keyExpr="mediaFileId"
                          [columnAutoWidth]="true"
                          [showColumnLines]="false"
                          [hoverStateEnabled]="true"
                          [showRowLines]="true"
                          (onSelectionChanged)="onSpotFilesGridSelectionChanged($event)"
                          (onContentReady)="onSpotFilesGridContentReady($event)"
                          [dataSource]="t.data.files">
              <dxo-selection mode="multiple"
                             selectAllMode="page"
                             allowSelectAll="false"
                             showCheckBoxesMode="always">
              </dxo-selection>
              <div *dxTemplate="let data of 'childDateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
              </div>

              <div *dxTemplate="let t of 'bxfUrlTemplate'">
                <div style="text-align:left;">
                  <span *ngIf="t.data.showBXFLink == true" class="quickLink blue-text p-extra-small" (click)="onDownloadBxfFile(t.data.mediaFileId, t.data.bxfFileName,  t.data.destination)">{{'XML LINK'}}</span>
                </div>
              </div>

              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxi-column dataField="spec" caption="Recipe" [allowSorting]="false" width="18%" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="fileSizeInMb" caption="File Size" [allowSorting]="false" width="10%" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="outputFileName" width="23%" caption="File Name" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="checksum" width="15%" caption="CheckSum" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="checksumType" caption="CheckSum Type" width="12%" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="bxfUrl" caption="Bxf Url" width="10%" [allowSorting]="false" cellTemplate="bxfUrlTemplate" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="downloadDate" width="12%" caption="Download Date" [allowSorting]="false" cellTemplate="childDateTemplate" cssClass="gridStyle1"></dxi-column>
            </dx-data-grid>
            <div class="panel-footer panel-footer-master-child-row"></div>
          </div>

          <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
          </div>

          <div *dxTemplate="let t of 'houseIdTemplate'">
            <div *ngIf="t.data.houseId">
              <span>{{t.data.houseId}}</span>
            </div>
            <span for="f02" class="qc-cancel blue-text p-normal tyl-no-margin" (click)="onAddEditDistroMedia(t.data)">
              Add/Edit
            </span>
          </div>

          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50, 100]"
                     infoText="Page {0} of {1} ({2} items)"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
      <div class="panel-footer"></div>
    </div>
  </div>
</div>
