import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { SubService } from "../../../services/media/sub.service";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, AbstractControl } from '@angular/forms';
import { ConfirmResult } from "../../../models/generic/confirm-result";
import { ErrorPopupComponent } from "../errorpopup/errorpopup.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { PurgeMedia } from '../../../models/spot/purgemedia.model';
import { MediaDetailService } from '../../../services/mediadetail/mediadetail.service';
import { SpotMediaDetail } from '../../../models/spot/spotdetail-model';
import { Subject } from 'rxjs';

@Component({
  selector: 'purgemedia-popup',
  templateUrl: './purgemediapopup.component.html',
  styleUrls: ['./purgemediapopup.component.css'],
})
export class PurgeMediaPopupComponent extends BasePopupComponent {
  public spotGuid: string;
  public spotFileGuid: string;

  public confirmRequest: FormGroup;
  public onPurgeConfirm: Subject<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private mediaDetailService: MediaDetailService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private subService: SubService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onPurgeConfirm = new Subject();

    this.confirmRequest = new FormGroup({
      notes: new FormControl(null)
    });
  }

  public closePopup() {
    this.bsModalRef.hide();

    this.subService.callRefreshSearchSpotsGrid();
  }

  public onConfirm({ value, valid }: { value: PurgeMedia, valid: boolean }) {
    if (!valid)
      return;
    var result = value;
    this.onPurgeConfirm.next(result);
    this.closePopup();
  //  value.spotGuid = this.spotGuid;
  //  value.spotFileGuid = this.spotFileGuid;

  //  this.mediaDetailService.purgeMedia(value).subscribe((res: any) => {
  //    if (res.isSuccess == true) {
  //      var result = res.result as SpotMediaDetail;
  //      this.onPurgeConfirm.next(result);
  //    }
  //    else {
  //      this.util.handleIsNotSuccess(res.errors);
  //    }
  //    this.closePopup();
  //  },
  //    error => {
  //      if (this.util.handleError(error))
  //        this.closePopup();
  //    });
  }
}
