import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-webmarkingst',
  templateUrl: './servicewebmarkingst.component.html'
})
export class ServiceWebmarkingSTQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    if (this.formGroup.controls['clientId'].value == null || this.formGroup.controls['clientId'].value == '')
      this.formGroup.controls['clientId'].setValue(this.additionalMetadata.spotTracCode);
  }
}
