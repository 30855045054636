import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Params } from '@angular/router';
import { OrderService } from '../../../services/order/order.service';
import { RouteConstants } from '../../../configurations/route-constants';
import { GlobalService } from '../../../services/core/global.service';
import { Constants } from '../../../configurations/constants';
import { DxDataGridComponent } from 'devextreme-angular';
import { SubService } from '../../../services/media/sub.service';
import { SpotNotificationPopupComponent } from '../../popups/spotnotificationspopup/spotnotificationspopup.component';

@Component({
  selector: 'order-debug',
  templateUrl: './orderdebug.component.html',
  styleUrls: ['./orderdebug.component.css']
})
export class OrderDebugComponent implements OnInit {

  public modalRef: BsModalRef;
  public orderGuid: string;
  public ordersRoute = RouteConstants.ordersRoute;
  public orderTrace: any;
  public orderDetail: any;
  public progressBarDivider: any;
  public queueMessages: any;
  public vendorLineItems: any;
  public orderLineItems: any
  public messageContextMenuItems: any;
  public messageTitle: string;
  public messageContent: string;
  public queueEndpoint: string;
  public isMessageResendMode: boolean = false;
  public isMessageNewMode: boolean = false;
  public resendMessageId: number;
  public selectedWorkflowId: number;
  public workflows: any;
  public workflowOperationTitle: string;
  public workflowOperations: any;
  public workflowKeyValues: any;
  public postProdSpots: any
  public queues: Array<string> = [];

  @ViewChild('workflowsGrid', { static: false }) workflowsGrid: DxDataGridComponent;

  constructor(
    public util: Utilities,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private orderService: OrderService,
    private gs: GlobalService,
    private sub: SubService,
    public bsModalRef: BsModalRef,
    private alertService: AlertService) { }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.orderGuid = params['orderGuid'];
      this.getOrderData();
    });

    this.sub.orderEditedMethodCalled.subscribe(() => {
      this.onRefresh();
    });
  }

  private getOrderData() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.getOrderDebugDetails(this.orderGuid).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.orderTrace = res.result;
        this.orderDetail = this.orderTrace.order;
        this.progressBarDivider = 100 / (this.orderDetail.header.progress.length - 1);
        this.queueMessages = this.orderTrace.queueMessages;
        this.postProdSpots = this.orderTrace.postProdSpots;
        this.vendorLineItems = this.orderTrace.vendorLineItems;
        this.orderLineItems = this.orderTrace.orderLineItems;
        this.workflows = this.orderTrace.spotIngestWorkflows.mediaIngestRecords;
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }

  public onViewQueueMessage(id, queueMsgTemplate: TemplateRef<any>) {
    var qM = this.orderTrace.queueMessages.filter(x => x.messageStatusId == id)[0];
    var json = JSON.parse(qM.message);
    this.messageContent = JSON.stringify(json, undefined, 4);
    this.messageTitle = "Message";

    this.modalRef = this.modalService.show(queueMsgTemplate, this.util.getModalComponentOptions({}, false, false, true));
  }

  public onViewQueuePayload(id, queueMsgTemplate: TemplateRef<any>) {
    var qM = this.orderTrace.queueMessages.filter(x => x.messageStatusId == id)[0];
    this.messageContent = qM.payload;
    this.messageTitle = "Payload";

    this.modalRef = this.modalService.show(queueMsgTemplate, this.util.getModalComponentOptions({}, false, false, true));
  }

  public onQueueMessageResend(id, queueMsgTemplate: TemplateRef<any>) {
    var qM = this.orderTrace.queueMessages.filter(x => x.messageStatusId == id)[0];
    var json = JSON.parse(qM.message);
    this.messageContent = JSON.stringify(json, undefined, 4);
    this.messageTitle = "Resend";
    this.isMessageResendMode = true;
    this.resendMessageId = id;

    this.modalRef = this.modalService.show(queueMsgTemplate, this.util.getModalComponentOptions({}, false, false, true));
  }

  public onQueueMessageResendSubmit() {

    if (this.messageContent.trim() == '')
      return;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var request = {
      messageStatusId: this.resendMessageId,
      message: this.messageContent
    };

    this.orderService.resendOrderMessage(request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.msgPostedSuccessfully, MessageSeverity.success);
        this.alertService.ShowLoader(false);
        this.modalClose();
        this.onQueueMessagesRefresh();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.modalClose();
      });
  }

  public onQueueMessagesRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.status == 'Submitting To Queue' || e.data.status == 'Submitted To Queue' || e.data.status == 'Processing') {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.status == 'Failed') {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onWorkflowsRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.status.toLowerCase() == 'failed') {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.status.toLowerCase() != 'completed') {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onWorkflowOperationRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.status.toLowerCase() == 'failed' || e.data.status.toLowerCase() == 'retry' || e.data.status.toLowerCase() == 'poll') {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.status.toLowerCase() != 'succeeded') {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onWorkflowKeyValuesRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.createdBy.toLowerCase() != 'original') {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public onPostProdSpotsRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.postProdSpotStatus.toLowerCase() == 'rejected' || e.data.postProdSpotStatus.toLowerCase() == 'qc fail') {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.postProdSpotStatus.toLowerCase() != 'approved' && e.data.postProdSpotStatus.toLowerCase() != 'qc pass') {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public modalClose() {
    this.modalRef.hide();

    this.isMessageNewMode = false;
    this.isMessageResendMode = false;
    this.messageContent = "";
    this.queueEndpoint = "";
    this.messageTitle = "";
    this.resendMessageId = 0;
    this.selectedWorkflowId = 0;
    this.workflowOperations = null;
    this.workflowKeyValues = null;
    this.workflowOperationTitle = '';

    this.queues = []
  }

  public onRefresh() {
    this.orderTrace = null;
    this.getOrderData();
  }

  public onSendNewMessageSubmit() {
    if (this.messageContent.trim() == '' || this.queueEndpoint.trim() == '')
      return;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var request = {
      message: this.messageContent,
      queueName: this.queueEndpoint,
      objectId: this.orderDetail.orderId,
    };

    this.orderService.sendNewOrderMessage(request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.msgPostedSuccessfully, MessageSeverity.success);
        this.alertService.ShowLoader(false);
        this.modalClose();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
      this.onQueueMessagesRefresh();
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.modalClose();
      });
  }

  private getAllQueues() {

    this.orderService.getAllQueues().subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.queues = res.result;
        console.log(this.queues);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.queues = [];
      }
    },
      error => {
        this.util.handleError(error);
        this.modalClose();
      });
  }

  public onSendNewMessage(queueMsgTemplate: TemplateRef<any>) {
    this.messageTitle = "New Message"
    this.messageContent = "{   } ";
    this.queueEndpoint = "";
    this.isMessageNewMode = true;

    this.modalRef = this.modalService.show(queueMsgTemplate, this.util.getModalComponentOptions({}, false, false, true));

    this.getAllQueues();
  }

  public onQueueMessagesRefresh() {
    this.orderService.getOrderQueueMessages(this.orderTrace.order.orderId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.queueMessages = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onWorkflowsRefresh() {
    this.orderService.searchOrderWorkflows(this.orderTrace.order.workOrder).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.workflows = res.result.mediaIngestRecords;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onShowOrderDetail() {
    this.gs.openOrderFile(this.orderGuid);
  }

  public onResetWorkflow(requestId) {

    var request = { requestId: requestId, resetWorkflow: true };

    this.orderService.updateWorkflow(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.workflowResetSuccessfully, MessageSeverity.success);
        this.onWorkflowsRefresh();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onUpdateWorkflowRetryPickup(requestId) {
    var request = { requestId: requestId, updateWorkflowRetryPickupDate: true, minutes: 2 };

    this.orderService.updateWorkflow(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.workflowRetryDateUpdatedSuccessfully, MessageSeverity.success);
        this.onWorkflowsRefresh();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onResetWorkflowOperation(requestOperationId) {

    var request = { requestOperationId: requestOperationId, resetWorkflowOperation: true };

    this.orderService.updateWorkflow(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.workflowOpResetSuccessfully, MessageSeverity.success);
        this.getWorkflowOperations(this.selectedWorkflowId);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

  }

  public onUpdateWorkflowOperationRetryPickup(requestOperationId) {
    var request = { requestOperationId: requestOperationId, updateWorkflowOperationRetryPickupDate: true, minutes: 2 };

    this.orderService.updateWorkflow(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.workflowOpRetryDateUpdatedSuccessfully, MessageSeverity.success);
        this.getWorkflowOperations(this.selectedWorkflowId);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onWorkflowSelectionChanged(e, workflowOperationTemplate: TemplateRef<any>) {

    var grid = this.workflowsGrid.instance;

    if (grid.getSelectedRowKeys().length > 0) {
      var row = grid.getSelectedRowsData()[0];

      this.workflowOperationTitle = row.requestId + ' - ' + row.action + ' - ' + row.reference;

      this.modalRef = this.modalService.show(workflowOperationTemplate, this.util.getModalComponentOptions({}, false, false, true, "workFlowPopup"));

      this.getWorkflowOperations(row.requestId);

      grid.deselectAll();
    }
  }

  private getWorkflowOperations(requestId) {

    this.orderService.getWorkflowOperations(requestId).subscribe((res: any) => {

      if (res.isSuccess == true) {
        console.log();
        this.workflowOperations = res.result.workflowOperations;
        this.workflowKeyValues = res.result.workflowKeyValues;
        this.selectedWorkflowId = requestId;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onShowNotifications() {
    var initialState = {
      orderGuid: this.orderGuid
    };

    this.bsModalRef = this.modalService.show(SpotNotificationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }
}
