
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxTreeViewComponent } from 'devextreme-angular';
import { OrderService } from '../../../../services/order/order.service';
import { Utilities } from '../../../../services/core/utilities';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import { OrderFile } from '../../../../models/order/order-create.model';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';

@Component({
  selector: 'service-voiceover',
  templateUrl: './servicevoiceover.component.html'
})
export class ServiceVoiceOverQComponent extends BaseServiceDataCollectionComponent {

  public options: Array<any> = [];

  public selectedLanguages: Array<string>;

  @ViewChild('tree', { static: false }) tree: DxTreeViewComponent;

  public isUploading: boolean = false;

  public showOtherLanguage: boolean = false;

  public minuteOptions: Array<any> = [{ "val": 0, "display": "00" }, { "val": 15, "display": "15" }, { "val": 30, "display": "30" }, { "val": 45, "display": "45" }];

  public isUploadFileDisabled: boolean = false;
  public isTitleDisabled: boolean = false;

  constructor(private orderService: OrderService,
    public util: Utilities, private cdr: ChangeDetectorRef,
    private uploadOrderFileService: UploadOrderFileService) {
    super();

    this.selectedLanguages = [];
  }

  ngOnInit() {

    super.ngOnInit();

    if (this.spotLevel == true) {
      this.isUploadFileDisabled = this.formGroup.controls["scriptFile"].disabled;
      this.isTitleDisabled = this.formGroup.controls["title"].disabled;

      if (this.applyAllMode === true) {
        this.formGroup.controls["title"].clearValidators();
        this.formGroup.controls["title"].updateValueAndValidity();
      }
      else {
        if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0)
          this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileSpecs[0].ppFileTitle);
        else
          this.formGroup.controls["title"].setValue('');
        //this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileTitle);
      }

      this.options.push({ name: 'English', value: 'English' });
      this.options.push({ name: 'Spanish', value: 'Spanish' });
      this.options.push({ name: 'other', value: 'other' });

      if (this.formGroup.controls["scriptFile"].value == null)
        this.formGroup.controls["scriptFile"].setValue([]);

      if (this.formGroup.controls["scriptText"].value == null)
        this.formGroup.controls["scriptText"].setValue('');

      if (this.formGroup.controls["voice"].value == null)
        this.formGroup.controls["voice"].setValue('Male');

      if (this.formGroup.controls["language"].value == null) {
        this.formGroup.controls["language"].setValue(["English"])
        this.selectedLanguages = ["English"];
      }
      else {
        this.selectedLanguages = this.formGroup.controls["language"].value;
      }

      this.checkOtherLanguage(this.formGroup.controls["language"].value);

      this.formGroup.get('language').valueChanges.subscribe(
        (languages: Array<string>) => {
          this.checkOtherLanguage(languages);
        });

      this.formGroup.setValidators([this.uploadOrPasteScriptRequired(this.formGroup.get('scriptText'), this.formGroup.get('scriptFile'))]);

      this.formGroup.updateValueAndValidity();
    }

    if (this.orderLevel == true) {

      this.formGroup.setValidators([this.minimumMinsRequired(15, this.formGroup.get('totalMins'))]);
      this.formGroup.updateValueAndValidity();


      var totalMins = this.formGroup.get('totalMins').value as number;
      if (totalMins != null && totalMins > 0) {
        var hrs = Math.floor(totalMins / 60);
        var mins = totalMins - (hrs * 60);

        this.formGroup.get('hours').setValue(hrs);

        this.formGroup.get('mins').setValue(mins);
      }

      if (this.formGroup.controls["mins"].value == null)
        this.formGroup.controls["mins"].setValue(0);
    }
  }

  private checkOtherLanguage(languages: Array<string>) {

    if (languages.find(t => t === 'other') != null) {
      this.showOtherLanguage = true;
      this.formGroup.get('languageCustom').setValidators([Validators.required]);
      this.formGroup.get('languageCustom').updateValueAndValidity();
    }
    else {
      this.showOtherLanguage = false;
      this.formGroup.get('languageCustom').clearValidators();
      this.formGroup.get('languageCustom').updateValueAndValidity();
      //this.formGroup.controls["languageCustom"].setValue('');
    }
  }

  public onTreeChanged(e) {
    this.selectedLanguages = e.component.getSelectedNodesKeys();
    this.cdr.detectChanges();
  }

  public onChanged(e = null) {

    var component = (e && e.component) || (this.tree && this.tree.instance);

    if (!component) return;

    if (!this.selectedLanguages.length) {
      component.unselectAll();
    }

    if (this.selectedLanguages) {
      this.selectedLanguages.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }

    this.cdr.detectChanges();
  }

  public onAttachFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {

      this.isUploading = true;

      let uploadFile = event.target.files[0];

      var request: UploadOrderFileRequest = {
        fileType: uploadFile.type,
        fileName: uploadFile.name,
        description: "",
        fileCategory: "serviceMetadata",
        orderGuid: this.orderGuid,
        orderDestinationId: 0
      };

      this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
        finalize(() => {
          this.isUploading = false;
        }))
        .subscribe(newFile => {
          if (newFile != null && newFile != undefined) {
            this.addFilesToList(newFile);
          }
        });
    }
    else
      this.isUploading = false;
  }

  private addFilesToList(newFile: OrderFile) {
    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles.push(newFile);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
  }

  public onRemoveFile(orderFileGuid: string) {
    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);

    //this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var exisitingFiles = this.formGroup.controls["scriptFile"].value as Array<OrderFile>;
    //    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    //    this.formGroup.controls["scriptFile"].setValue(exisitingFiles);
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }

    //  this.isUploading = false;
    //},
    //  error => {
    //    this.isUploading = false;
    //    this.util.handleError(error);
    //  });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {
    console.log(orderFileGuid);

    //if (filePath == null || filePath == '')
    //  return;

    //this.util.downloadFile(this.util.getFileUrl(filePath)).subscribe(
    //  fileData => {
    //    var a = document.createElement("a");
    //    document.body.appendChild(a);
    //    a.href = window.URL.createObjectURL(fileData);
    //    a.download = fileName;
    //    a.click();
    //  });
    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onTimeChanged() {

    console.log(this.formGroup.get('mins').value);

    var hrs = this.formGroup.get('hours').value as number;
    var mins = this.formGroup.get('mins').value as number;

    //if (hrs == 0 && mins == 0)
    //{
    //  this.formGroup.get('totalMins').setValue(30);
    //  this.formGroup.get('mins').setValue(30);
    //  this.formGroup.get('hours').setValue(0);
    //  return;
    //}

    var totalMins = (hrs * 60) + mins;

    this.formGroup.get('totalMins').setValue(totalMins.toString());
  }
}
