import { Injectable } from '@angular/core';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { ShareMediaFileModel } from "../../models/sharemedia/sharemediafile.model";
import { HttpClient } from '@angular/common/http';
import { ShareTranscodeAssetRequest } from '../../models/sharemedia/sharetranscodeasset.model';
import { ShareTaggerAdRequest } from '../../models/tagger/tagger.model';

@Injectable()
export class ShareMediaService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public shareMedia(sharedMediaFiles: ShareMediaFileModel) {
    return this.http.post(this.apiendPoint.shareMedia(), sharedMediaFiles)
  }

  public autoGeneratePassword() {
    return this.http.get(this.apiendPoint.autoGeneratePassword());
  }

  public getShareMediaConfig() {
    return this.http.get(this.apiendPoint.getShareMediaConfig());
  }

  public generateTokenEndPoint(sharedMediaFiles: ShareMediaFileModel) {
    return this.http.post(this.apiendPoint.generateTokenEndPoint(), sharedMediaFiles);
  }

  public shareTranscodeAssets(shareRequest: ShareTranscodeAssetRequest) {
    return this.http.post(this.apiendPoint.shareTranscodeAssetsEndpoint(), shareRequest)
  }

  public getTranscodeAssetsShareLink(shareRequest: ShareTranscodeAssetRequest) {
    return this.http.post(this.apiendPoint.getTranscodeAssetsShareLinkEndpoint(), shareRequest)
  }

  public getTaggerAdsShareLink(shareRequest: ShareTaggerAdRequest) {
    return this.http.post(this.apiendPoint.getTaggerAdsShareLinkEndpoint(), shareRequest)
  }

  public shareTaggerAds(shareRequest: ShareTaggerAdRequest) {
    return this.http.post(this.apiendPoint.shareTaggerAdsEndpoint(), shareRequest)
  }

}
