<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['editNotes']?.errors && f.submitted)}" *ngIf="spotLevel === true">
    <label class="tylie-form-group__lbl" for="editNotes">What needs to be edited ?<span class="required">*</span></label>
    <textarea name="editNotes" id="editNotes" class="tylie-textarea tylie-textarea--md form-control" formControlName="editNotes" rows="4"></textarea>
    <p class="p-extra-small">Please enter Edit notes</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['title'].errors && f.submitted)}" *ngIf="spotLevel === true">
    <label *ngIf="applyAllMode === true" class="tylie-form-group__lbl">This below entry is disabled in Apply All Mode. Please edit later.</label>
    <label class="tylie-form-group__lbl" for="title">Title for the new Asset<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="title" formControlName="title" [attr.disabled]="(applyAllMode === true || isDisabled == true) ? '' : null" />
    <p class="p-extra-small">Please enter Title</p>
  </div>

  <div class="row" *ngIf="orderLevel === true">
    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: ((formGroup.controls['hours'].errors || formGroup.controls['mins'].errors || formGroup.hasError('minimumMinsRequired')) && f.submitted)}">
      <label class="tylie-form-group__lbl" for="hours">Time<span class="required">*</span></label>
      <div class="tylie-text-group input-group">
        <span class="input-group-text">hrs</span>
        <dx-number-box formControlName="hours"
                       name="hours"
                       class="tylie-select form-control"
                       [min]="0"
                       (change)="onTimeChanged()"
                       [showSpinButtons]="false"></dx-number-box>
      </div>
      <p class="p-extra-small">Minimun 15 mins required</p>
    </div>

    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: ((formGroup.controls['hours'].errors || formGroup.controls['mins'].errors || formGroup.hasError('minimumMinsRequired')) && f.submitted)}">
      <label for="mins">&nbsp;</label>
      <div class="tylie-text-group input-group">
        <span class="input-group-text">mins</span>
        <dx-select-box [dataSource]="minuteOptions" displayExpr="display" valueExpr="val" formControlName="mins" name="mins" class="tylie-select form-control" (onValueChanged)="onTimeChanged()">
        </dx-select-box>
      </div>
    </div>
  </div>
</div>
