import { Component, ViewChild, TemplateRef, Input, OnInit } from '@angular/core';
import { Spot } from '../../../models/spot/spot.model';
import { SpotUpdateQC } from '../../../models/spot/spot-update-qc.model';
import { SpotService } from '../../../services/media/spot.service';
import { SpotUpdatedQCResult } from '../../../models/spot/spot-updated-qc-result.model';
import { SubService } from "../../../services/media/sub.service";
import { DxDataGridModule, DxDataGridComponent } from 'devextreme-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Constants } from "../../../configurations/constants";

import { Utilities } from "../../../services/core/utilities";
import { FormGroup, FormControl } from '@angular/forms';
import { SpotQCComment } from '../../../models/spot/spotqccomment.model';
import { Router } from '@angular/router';
import { qcStatusEnum, mediaDetailSource } from "../../../configurations/enums/enums";

import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DatGridService } from "../../../services/media/datagrid.service";
import { UserProfileService } from "../../../services/user/user-profile.service";
import { UserRoles } from "../../../configurations/user-roles";
import { ErrorPopupComponent } from "../../popups/errorpopup/errorpopup.component";
import { RouteConstants } from "../../../configurations/route-constants";
import { SpotSearchRequest } from '../../../models/spot/spot-search-request.model';
import { GlobalService } from '../../../services/core/global.service';
import { environment } from '../../../../environments/environment';
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';

@Component({
  selector: 'spot-qc',
  templateUrl: './spotqc.component.html',
  styleUrls: ['./spotqc.component.css']
})

export class SpotqcComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild('spotQCGrid', { static: true }) spotQCGrid: DxDataGridComponent;
  @Input('spotComment') spotComment: string = "";

  SpotComment: FormGroup;
  public modalRef: BsModalRef;
  public spots: Spot[] = [];
  public statusSelected: string;
  public iscommentRequired: boolean;
  public FailureComments: boolean = true;
  public sub;
  public spotFileGuid: string;


  public searchSpotsCriteriaObj: SpotSearchRequest = <SpotSearchRequest>{};

  public resultSpotsStore: any = {};

  private refreshId: any;

  constructor(
    private spotService: SpotService,
    private subService: SubService,
    private router: Router,
    private modalService: BsModalService,
    private alertService: AlertService,
    private datGridService: DatGridService,
    public util: Utilities,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    private gs: GlobalService
  ) {
    this.SpotComment = new FormGroup({
      status: new FormControl('Pass'),
      spotComment: new FormControl('')
    });

    this.searchSpotsCriteriaObj.quickSearch = '';

    this.sub = this.subService.refreshQCSpotsBacktoMediaMethodCalled.subscribe(
      () => {
        if (this.userProfileService.getUserRoles().includes(this.userRoles.getMediaQCRoleName())) {
          this.spotQCGrid.instance.refresh();
        }
      }
    );
  }

  ngOnInit() {
    this.searchQcSpots();

    if (this.userProfileService.getUserRoles().includes(this.userRoles.getMediaQCRoleName())) {
      this.refreshId = setInterval(() => {
        this.spotQCGrid.instance.refresh();
      }, environment.mediaQCGridRefreshTimeInMins * 60000);
    }
  }

  private searchQcSpots() {
    var spotService = this.spotService;
    var util = this.util;
    var criteriaObj = this.searchSpotsCriteriaObj;

    this.resultSpotsStore.store = new CustomStore({
      key: "spotFileGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) }
        let request: any = { SearchCriteria: criteriaObj, GridParams: gridParams, isSpotSearch: false };

        return spotService.searchQCReadySpots(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var spotSearchResponse = response.result;
              var spots = spotSearchResponse.searchResults as Spot[];

              for (let s of spots) {
                s.thumbnailUrl = util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, false);
                s.proxyUrl = util.getProxyUrl(s.proxyUrl);
              }

              var obj: any = {
                data: spots,
                totalCount: spotSearchResponse.totalCount
              };

              util.spotQCCount = spotSearchResponse.totalCount;

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            console.log("err", error);
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public onEnterQuickSearch(e: any) {
    this.spotQCGrid.instance.refresh();
  }

  public onSearchQC() {
    this.spotQCGrid.instance.refresh();
  }

  public onRefresh() {
    this.spotQCGrid.instance.refresh();
  }

  public onClearQuickQCSearch() {
    if (this.searchSpotsCriteriaObj.quickSearch == '')
      this.spotQCGrid.instance.refresh();
  }

  public openModal(updateqc: TemplateRef<any>) {
    this.SpotComment.controls['status'].setValue('Pass');
    this.spotComment = "";
    this.statusSelected = "";
    this.iscommentRequired = false;
    this.FailureComments = true;

    if (this.spotQCGrid.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    if (this.spotQCGrid.instance.getSelectedRowKeys().length > 10) {
      this.alertService.showMessage("ERROR", Constants.tenOrMoreAssetsSelectedForQC, MessageSeverity.error);
      return;
    }

    this.modalRef = this.modalService.show(updateqc, this.util.getModalComponentOptions({}, false, true, false));
  }

  public updateSpotQC(status: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    if ((this.spotComment != null && status == qcStatusEnum[qcStatusEnum.Fail]) || status == qcStatusEnum[qcStatusEnum.Pass]) {

      let spotupdateqc = new SpotUpdateQC(
        this.spotQCGrid.instance.getSelectedRowKeys(),
        status,
        this.spotComment
      );

      this.spotService.updateSpotQC(spotupdateqc).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.qcUpdateSuccess, MessageSeverity.success);
          this.spotQCGrid.instance.refresh();
          this.modalClose();
        }
        else {
          this.modalClose();
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        err => {
          if (this.util.handleError(err)) {
            this.modalClose();
          }
          this.alertService.ShowLoader(false);
        });
    }
    else {
      this.iscommentRequired = true;
    }
  }

  public saveSpotQC(spotcommentObj: SpotQCComment) {
    if (!this.FailureComments) { return; }
    else {
      this.updateSpotQC(this.SpotComment.value.status);
    }
  }

  public emitData() {
    if (this.spotComment.length > 0) {
      this.FailureComments = true;
      this.iscommentRequired = false;
    }
    else {
      if (this.statusSelected == qcStatusEnum[qcStatusEnum.Fail]) {
        this.FailureComments = false;
        this.iscommentRequired = true;
      }
    }
  }

  public commentValidation(status: string) {
    this.statusSelected = status;
    if (status == qcStatusEnum[qcStatusEnum.Pass]) {
      this.FailureComments = true;
      this.iscommentRequired = false;
    }
    else {
      this.emitData();
    }
  }

  public modalClose() {
    this.modalRef.hide();
  }

  public showDetail(id) {

    if (id == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openSpotFile(id);

    //this.router.navigate([RouteConstants.mediaDetailRoute, id], { queryParams: { source: mediaDetailSource[mediaDetailSource.QC] } });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}
