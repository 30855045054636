<header class="tylie-header">
  <h3 class="tylie-header__title">
    Transcoded Files
  </h3>
    <nav class="tylie-header__nav" id="bs-example-navbar-collapse-1">
      <ul class="tylie-header__nav-lst">
        <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaProviderRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoUpload()">Upload Assets</a></li>
        <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaDownloadRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoDownload()">Download Status</a></li>
        <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaApproverRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoMedia('Approval')">Approvals</a></li>
        <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getMediaQCRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="onGotoMedia('QC')">Asset QC</a></li>
        <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="onGotoMedia('PlayList')">Playlists</a></li>
        <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="onGotoMedia('Search')">View/Search All Spots</a></li>
        <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="onGotoMedia('RecentlyViewed')">Recently Viewed</a></li>
        <li class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk active">Transcode Files</a></li>
        <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getAssetRightsManagementRoleName()]"><a class="tylie-header__nav-lst-lnk" (click)="gotoTalentRights()">Talent Rights</a></li>
      </ul>
    </nav>
</header>

<section class="tylie-body" id="scrollContainer">
  <div class="tylie-body__top-bar">
    <h4 class="tylie-body__title">Transcoded Files</h4>
    <div class="tylie-body__actions trns-actions">
        <a class="tylie-lnk" (click)="onGotoTranscodeOLVLists()">View OLV Profile Specs</a>
        <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onGotoOrderTranscode()">
          <span class="tylie-button__icon"><icon-send></icon-send></span>
          <span class="tylie-button__text">transcodes</span>
        </button>
        <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onGotoTranscodeSavedLists()">
          <span class="tylie-button__icon"><icon-orders-tylie-mark></icon-orders-tylie-mark></span>
          <span class="tylie-button__text">Transcodes Order Lists</span>
        </button>
    </div>
  </div>

  <div class="tylie-body__bottom-bar">
    <section class="tylie-input-search">
      <div class="tylie-input-search__inner">
        <div class="tylie-input-search__icon"><icon-search></icon-search></div>
        <input class="tylie-input-search__text" placeholder="Search By Keyword, Ad-ID, Title, OLV Profile, etc." name="srch-term" id="srch-term" type="search"
               [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()" (keydown.enter)="onQuickSearch()">
      </div>
      <div class="tylie-input-search__btn">
        <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearch()">Search</button>
      </div>
    </section>
    <div class="tylie-body__actions">
      <div class="">Batch Actions for Selected</div>
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onBatchDownload()">
        <span class="tylie-button__icon"><icon-download class=""></icon-download></span>
        <span class="tylie-button__text">Download</span>
      </button>
    
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onBatchShare()">
        <span class="tylie-button__icon"><icon-envelope-share></icon-envelope-share></span>
        <span class="tylie-button__text">Share</span>
      </button>
    
    </div>
   
  </div>

  <div *ngIf="ordersSource && ordersSource.length > 0">
    <section class="tylie-panel tylie-panel--br-top" *ngFor="let order of ordersSource; let i=index">
      <div class="tylie-panel__body">
        <div class="d-flex justify-content-between">
          <div class="tylie-data">
            <label class="tylie-data__lbl">ORDER:</label>
            <span class="tylie-data__txt">{{order.workOrder}}</span>
          </div>
          <div class="d-flex justify-content-end">
            <div class="tylie-data me-3">
              <label class="tylie-data__lbl">Submitted By:</label>
              <span class="tylie-data__txt">{{order.createdBy}}</span>
            </div>

            <div class="tylie-data me-3">
              <label class="tylie-data__lbl">Date:</label>
              <span class="tylie-data__txt">{{order.created | usertimezone: 'MM/DD/YY'}}</span>
            </div>

            <div class="tylie-data">
              <label class="tylie-data__lbl">Ref:</label>
              <span class="tylie-data__txt">{{order.referenceCode ? order.referenceCode : NA}}<a (click)="onEditRefCode(order.workOrder, order.orderGuid, order.referenceCode)"><span class="ms-2"><icon-edit-pencil height="15px" width="15px" color="#1e90ff"></icon-edit-pencil></span></a></span>
            </div>
          </div>
        </div>
        <div class="mt-4" *ngFor="let asset of order.transcodeAssets; let j=index">
          <div class="d-flex justify-content-start">
            <div class="tylie-container tylie-container--thumbnail-132-72">
              <img [src]="asset.spotFile.thumbnailUrl" *ngIf="asset.spotFile.isPlayable"
                    (click)="quickPlay(asset.spotFile.proxyUrl, asset.spotFile.title, asset.spotFile.thumbnailUrl, asset.spotFile.format)">
              <img [src]="asset.spotFile.thumbnailUrl" *ngIf="!asset.spotFile.isPlayable">
            </div>
            <div class="ps-2">
              <div class="p-small">{{asset.spotFile.title}}</div>
              <div class="">
                <div class="tylie-data me-3">
                  <label class="tylie-data__lbl">Ad-ID:</label>
                  <span class="tylie-data__txt">{{asset.spotFile.adId}}</span>
                </div>
                <div class="tylie-data">
                  <label class="tylie-data__lbl">Duration:</label>
                  <span class="tylie-data__txt">{{asset.spotFile.length}}</span>
                </div>
              </div>
              <div class="">
                <div class="tylie-data me-3">
                  <label class="tylie-data__lbl">Client:</label>
                  <span class="tylie-data__txt">{{asset.spotFile.client}}</span>
                </div>
                <div class="tylie-data me-3">
                  <label class="tylie-data__lbl">Brand:</label>
                  <span class="tylie-data__txt">{{asset.spotFile.brand}}</span>
                </div>
                <div class="tylie-data">
                  <label class="tylie-data__lbl">Product:</label>
                  <span class="tylie-data__txt">{{asset.spotFile.product}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-1">
            <dx-data-grid [showBorders]="false" class="tylie-grid tylie-grid--primary"
                          keyExpr="assetFileGuid"
                          #assetFileGrid
                          [columnAutoWidth]="true"
                          [showColumnLines]="false"
                          [hoverStateEnabled]="true"
                          [showRowLines]="true"
                          noDataText="No OLV Profiles"
                          (onRowPrepared)="onRowPrepared($event)"
                          (onCellPrepared)="onCellPrepared($event)"
                          [dataSource]="asset.transcodeAssetFiles">
              <dxo-selection mode="multiple"
                              selectAllMode="page"
                              allowSelectAll="false"
                              showCheckBoxesMode="always">
              </dxo-selection>
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxi-column dataField="transcodeSpec" width="17%" caption="OLV Profile" [allowSorting]="true" cssClass="gridStyle1 boldtext"></dxi-column>
              <dxi-column dataField="fileName" width="38%" caption="Filename" [allowSorting]="true" cssClass="gridStyle1" cellTemplate="filenameTemplate"></dxi-column>
              <dxi-column dataField="fileSize" width="9%" caption="Size" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="duration" width="9%" caption="Dur." [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
              <dxi-column dataField="createdDate" width="9%" caption="Created" [allowSorting]="true" cssClass="gridStyle1" cellTemplate="createdTemplate"></dxi-column>
              <dxi-column dataField="sharedDate" width="9%" caption="Shared" [allowSorting]="true" cssClass="gridStyle1" cellTemplate="shareTemplate"></dxi-column>
              <dxi-column dataField="downloadedDate" width="9%" caption="Downloaded" [allowSorting]="true" cssClass="gridStyle1" cellTemplate="downloadTemplate"></dxi-column>

              <div *dxTemplate="let t of 'createdTemplate'">
                <span *ngIf="t.data.createdDate">{{t.data.createdDate | usertimezone: 'MM/DD/YY'}}</span>
                <span *ngIf="!t.data.createdDate && !t.data.isFailed">{{t.data.status}}</span>
                <span *ngIf="!t.data.createdDate && t.data.isFailed"
                      triggers="mouseenter:mouseleave" placement="left" #displErrorpop="bs-popover" [popoverContext]="t.data" container="body" [popover]="errorDisplayTemplate">
                  {{t.data.status}}
                </span>
              </div>

              <div *dxTemplate="let t of 'filenameTemplate'">
                <span [title]="t.data.fileName">
                  {{t.data.fileName | truncateByLetters : 45}}
                  <a class="tylie-button tylie-button--xs tylie-button--link " (click)="onEditFileName(order.workOrder, order.orderGuid, asset.assetGuid, t.data.assetFileGuid, t.data.fileName)" *ngIf="t.data.canRenameFileName">
                    <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
                  </a>
                </span>
              </div>

              <div *dxTemplate="let t of 'shareTemplate'">
                <div class="d-flex align-items-center" *ngIf="!t.data.canDelete">
                  <span *ngIf="t.data.canShare">
                    <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onShare(t.data.assetFileGuid, t.data.fileName, t.data.fileExtension, t.data.filePath, t.data.transcodeSpec)">
                      <icon-envelope-share color="#1e90ff"></icon-envelope-share>
                    </a>
                  </span>
                  <span *ngIf="t.data.sharedDate">{{t.data.sharedDate | usertimezone: 'MM/DD/YY'}}</span>
                  <span *ngIf="!t.data.sharedDate">{{NOT_YET}}</span>
                </div>
              </div>

              <div *dxTemplate="let t of 'downloadTemplate'" class="ord-download">
                <div class="d-flex align-items-center" *ngIf="!t.data.canDelete">
                  <span *ngIf="t.data.canDownload">
                    <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDownload(t.data.assetFileGuid, t.data.fileName, t.data.fileExtension, t.data.filePath)">
                      <icon-download color="#1e90ff"></icon-download>
                    </a>
                  </span>
                  <span *ngIf="t.data.downloadedDate">{{t.data.downloadedDate | usertimezone: 'MM/DD/YY'}}</span>
                  <span *ngIf="!t.data.downloadedDate">{{NOT_YET}}</span>
                </div>
                <div style="text-align: right;" *ngIf="t.data.canDelete">
                  <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button" placement="left" #deletefailedpop="bs-popover" [popoverContext]="onGetDeleteFileData(order.workOrder, order.orderGuid, asset.assetGuid, t.data.assetFileGuid)"
                      container="body" [popover]="deleteFailedTemplate">
                      <icon-expire color="#1e90ff"></icon-expire>
                  </a>
                </div>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="ordersSource == null || ordersSource.length == 0">
    <div class="row">
      <div class="col-md-12">
        <div class="panel-footer panel-footer-media-qc"></div>
        <div class="trs-orders">
          <div class="col-md-4">
            <span class="trns-label-order">No Orders</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container id="transcodeorders-pagination-section">
      <div class="tylie-pagination">
        <div class="tylie-pagination__sets">
          <span class="tylie-pagination__sets-title">Show</span>
          <span class="tylie-pagination__set" [ngClass]="{'active': pSize == pager.currentPageSize}" *ngFor="let pSize of pager.pageSizes" (click)="setPageSize(pSize)">{{pSize}}</span>
        </div>
        <div class="tylie-pagination__page" *ngIf="ordersSource && ordersSource.length > 0">
          <span class="tylie-pagination__page-info">Page {{pager.currentPageNumber}} of {{pager.totalPages}} ({{pager.totalCount}} items)</span>
          <div class="d-flex align-items-center">
            <a class="tylie-lnk me-2" [ngClass]="{'icon-grey-link':pager.currentPageNumber == 1}" (click)="setPageNumber(pager.currentPageNumber - 1)"><icon-arrow-simple-left color="#000000"></icon-arrow-simple-left></a>
            <span class="tylie-pagination__page-no" [ngClass]="{'active': pNo == pager.currentPageNumber}" *ngFor="let pNo of pager.pageNumbers" (click)="setPageNumber(pNo)">{{pNo}}</span>
            <span *ngIf="(pager.totalPages - pager.startPage) > 5" (click)="setPageNumber(pager.startPage + 5)">...</span>
            <a class="tylie-lnk ms-2" [ngClass]="{'icon-grey-link':pager.currentPageNumber == pager.totalPages}" (click)="setPageNumber(pager.currentPageNumber + 1)"><icon-arrow-simple-right color="#000000"></icon-arrow-simple-right></a>
          </div>
        </div>
      </div>
  </ng-container>

  <div class="scrolltop" style="display: none;" (click)="onScrollToTop()"><span></span></div>

</section>

<ng-template #deleteFailedTemplate let-workOrder="workOrder" let-orderGuid="orderGuid" let-assetGuid="assetGuid" let-assetFileGuid="assetFileGuid">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this File?
    </p>
    <i (click)="onHideDeleteFailedPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onDeleteFailed(workOrder, orderGuid, assetGuid, assetFileGuid)">Yes</button>
 -->

  <button type="button" class="popover__close" (click)="onHideDeleteFailedPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this File?</header>
  <button class='tylie-button tylie-button--xs' type="button" (click)="onDeleteFailed(workOrder, orderGuid, assetGuid, assetFileGuid)">Yes</button>
</ng-template>

<ng-template #errorDisplayTemplate let-payload="payload">
  <div class='p-normal'>
    <div>
      <p class='black'>{{payload}}</p>
    </div>
  </div>
</ng-template>
