import { Injectable } from '@angular/core';
import { retry, retryWhen, take, delay } from 'rxjs/operators';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { EditBrandRequest } from "../../models/admin/brand/branddetail.model";
import { CreateUser } from "../../models/user/create-user";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AdminService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getBrandSelection(request: any) {
    return this.http.post(this.apiendPoint.getBrandSelectionEndPoint(), request);
  }

  public getPostHouseClientBrands(clientId: number, postHouseId: any) {
    return this.http.get(this.apiendPoint.getPostHouseClientBrandsEndPoint() + "/" + clientId + "/" + postHouseId);
  }

  public getClientView(clientGuid: string) {
    return this.http.get(this.apiendPoint.getClientViewEndPoint() + "/" + clientGuid);
  }

  public getClientEditDetail(clientGuid: string) {
    return this.http.get(this.apiendPoint.getClientEditDetailEndPoint() + "/" + clientGuid);
  }

  public createClient(request: any) {
    return this.http.post(this.apiendPoint.createClientEndPoint(), request);
  }

  public createPostHouse(request: any) {
    return this.http.post(this.apiendPoint.createPostHouseEndPoint(), request);
  }

  public addPostHouseToClient(request: any) {
    return this.http.post(this.apiendPoint.addPostHouseToClientEndPoint(), request);
  }

  public createBrand(request: any) {
    return this.http.post(this.apiendPoint.createBrandEndPoint(), request);
  }

  public createProduct(request: any) {
    return this.http.post(this.apiendPoint.createProductEndPoint(), request);
  }

  public searchAllClients(request: any) {
    return this.http.post(this.apiendPoint.searchAllClientsEndPoint(), request);
  }

  public searchAllBrands(request: any) {
    return this.http.post(this.apiendPoint.searchAllBrandsEndPoint(), request);
  }

  public deleteClientBrand(clientBrand: number) {
    return this.http.delete(this.apiendPoint.deleteClientBrandEndPoint() + "/" + clientBrand);
  }

  public deleteClientBrandProduct(clientBrandProduct: number) {
    return this.http.delete(this.apiendPoint.deleteClientBrandProductEndPoint() + "/" + clientBrandProduct);
  }

  public updateClientLogo(logo: string, clientGuid: string) {
    return this.http.put(this.apiendPoint.updateClientLogoEndPoint() + "/" + clientGuid, JSON.stringify(logo), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public removeClientLogo(clientGuid: string) {
    return this.http.put(this.apiendPoint.removeClientLogoEndPoint() + "/" + clientGuid, "");
  }

  public editClient(request: any, clientGuid: string) {
    return this.http.post(this.apiendPoint.editClientEndPoint() + "/" + clientGuid, request);
  }

  public getBrandDetail(brandGuid: string) {
    return this.http.get(this.apiendPoint.getBrandDetailEndPoint() + "/" + brandGuid);
  }

  public deleteBrandProduct(brandProductId: number) {
    return this.http.delete(this.apiendPoint.deleteBrandProductEndPoint() + "/" + brandProductId);
  }

  public editBrand(request: EditBrandRequest, brandGuid: string) {
    return this.http.post(this.apiendPoint.editBrandEndPoint() + "/" + brandGuid, request);
  }

  public deletePostHouse(clientEditorialHouseId: number, clientId: number) {
    return this.http.delete(this.apiendPoint.deletePostHouseEndPoint() + "/" + clientId + "/" + clientEditorialHouseId);
  }

  public updateClientStatus(clientGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateClientStatusEndPoint() + "/" + clientGuid, request);
  }

  public deleteDraftClient(clientGuid: string) {
    return this.http.put(this.apiendPoint.deleteDraftClientEndPoint() + "/" + clientGuid, {});
  }

  public startClientEditLock(clientGuid: string) {
    return this.http.put(this.apiendPoint.startClientEditLockEndPoint() + "/" + clientGuid, {});
  }

  public completeClientEditLock(clientGuid: string) {
    return this.http.put(this.apiendPoint.completeClientEditLockEndPoint() + "/" + clientGuid, {});
  }

  public makeClientBillable(clientGuid: string) {
    return this.http.put(this.apiendPoint.makeClientBillableEndPoint() + "/" + clientGuid, {});
  }

  public editProduct(productGuid: string, request: any) {
    return this.http.put(this.apiendPoint.editProductEndPoint() + "/" + productGuid, request);
  }

  public updateBrandStatus(brandGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateBrandStatusEndPoint() + "/" + brandGuid, request);
  }

  public searchAllUsers(request: any) {
    return this.http.post(this.apiendPoint.searchAllUsersEndPoint(), request);
  }

  public getBrandsForUserCreation(clientId: number, agencyId: number) {
    return this.http.get(this.apiendPoint.getBrandsForUserCreationEndPoint() + "/" + clientId + "/" + agencyId);
  }

  public createNewUser(request: CreateUser) {
    return this.http.post(this.apiendPoint.createNewUserEndPoint(), request);
  }

  public createNewUserProfile(request: CreateUser, userId: string) {
    return this.http.post(this.apiendPoint.createNewUserProfileEndPoint() + "/" + userId, request);
  }

  public getUserProfileView(userProfileGuid: string) {
    return this.http.get(this.apiendPoint.getUserProfileViewEndPoint() + "/" + userProfileGuid);
  }

  public getEditUserProfileConfig(userProfileGuid: string) {
    return this.http.get(this.apiendPoint.getEditUserProfileConfigEndPoint() + "/" + userProfileGuid);
  }

  public editUserProfile(request: any, userProfileGuid: string) {
    return this.http.post(this.apiendPoint.editUserProfileEndPoint() + "/" + userProfileGuid, request);
  }

  public updateUserProfileStatus(userProfileGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateUserProfileStatusEndPoint() + "/" + userProfileGuid, request);
  }

  public searchAllDestinations(request: any) {
    return this.http.post(this.apiendPoint.searchAllDestinationsEndPoint(), request);
  }

  public searchVendorDestinations(request: any) {
    return this.http.post(this.apiendPoint.searchVendorDestinationEndPoint(), request);
  }

  public createDestination(request: any) {
    return this.http.post(this.apiendPoint.createDestinationEndPoint(), request);
  }

  public isVendorDestinationCodeValid(vendorDestinationCode: string, vendorId: number) {
    return this.http.post(this.apiendPoint.isVendorDestinationCodeValidEndPoint() + "/" + vendorId, JSON.stringify(vendorDestinationCode), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public isDestinationCodeValid(vendorDestinationCode: string) {
    return this.http.post(this.apiendPoint.isDestinationCodeValidEndPoint(), JSON.stringify(vendorDestinationCode), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public getDestinationView(destinationGuid: string) {
    return this.http.get(this.apiendPoint.getDestinationViewEndPoint() + "/" + destinationGuid);
  }

  public editDestination(request: any, destinationGuid: string) {
    return this.http.post(this.apiendPoint.editDestinationEndPoint() + "/" + destinationGuid, request, { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public updateDestinationStatus(destinationGuid: string, request: any) {
    return this.http.put(this.apiendPoint.updateDestinationStatusEndPoint() + "/" + destinationGuid, request);
  }

  public removeSpecMapping(request:any) {
    return this.http.post(this.apiendPoint.removeSpecMappingEndPoint(), request);
  }

  public validateBXFFile(request:any) {
    return this.http.post(this.apiendPoint.validateBXFFileEndPoint(), request);
  }

  public createAuthOrg(request: any) {
    return this.http.post(this.apiendPoint.createAuthOrgEndPoint(), request);
  }

  public getTranscodeSpecsByDestinationCode(request: any) {
    return this.http.post(this.apiendPoint.getTranscodeSpecsByDestinationCode(), request);
  }
}


