<div id="order-conformation">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">VAULT CONFIRMATION</h4>
  </div>
  <div class="modal-body">
      <h3 class="tylie-type-h3">Vault Request # {{vaultRequestNumber}}</h3>
      <p class="tylie-type-body"><strong>Vault Request is created succesfully, It is available for Edit in Open Vault Requests.</strong></p>
  </div>
</div>
