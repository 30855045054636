import { Component, OnInit, TemplateRef } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { UserMgmtService } from '../../../../services/user/user-mgmt.service';
import { DestinationSearchPopupComponent } from '../../../popups/destinationsearchpopup/destinationsearchpopup.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Constants } from '../../../../configurations/constants';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tmcApproveRegistrationRequest, tmcRegistrationRequest } from '../../../../models/user/tmc-registation-request.model';

@Component({
  selector: 'tmc-registration-requests',
  templateUrl: './tmc-registration-requests.component.html',
  styleUrls: ['./tmc-registration-requests.component.css']
})
export class RegistationRequestsComponent implements OnInit {

  public registrationRequests: Array<tmcRegistrationRequest> = [];

  public modalRef: BsModalRef;

  public approvalRequest: FormGroup;

  constructor(private util: Utilities,
    private userMgmtService: UserMgmtService,
    private alertService: AlertService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.getRequests();
  }

  private getRequests() {

    this.userMgmtService.getTMCRegistrationRequests().subscribe((res: any) => {
      if (res.isSuccess == true) {

        let requests = res.result as Array<tmcRegistrationRequest>;

        this.registrationRequests = requests;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onShowStatusConfirm(id: number, isApproved: boolean, statusConfirm: TemplateRef<any>) {

    var item = this.registrationRequests.find(t => t.registrationrequestid == id);

    if (isApproved == true && item.destinationid == null || item.destinationid == 0) {
      this.alertService.showMessage("ERROR", "Please Map the Destination.", MessageSeverity.error);
      return;
    }

    this.approvalRequest = new FormGroup({
      isApproved: isApproved == true ? new FormControl(true) : new FormControl(false),
      comments: isApproved == true ? new FormControl('') : new FormControl('', Validators.required),
      registrationrequestid: new FormControl(id),
      destination: new FormControl(item.destination),
      destinationid: new FormControl(item.destinationid),
    });

    this.modalRef = this.modalService.show(statusConfirm, this.util.getModalComponentOptions({}, false, true, false));
  }

  public onFormSubmit({ value, valid }: { value: tmcApproveRegistrationRequest, valid: boolean }) {
    console.log(value);

    if (valid == false)
      return;

    this.submitRequest(value);    
  }   

  private submitRequest(request: tmcApproveRegistrationRequest) {

    this.modalClose();

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.userMgmtService.approveTMCRegistrationRequest(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", "You have updated the Approval status successfully.", MessageSeverity.success);

        this.getRequests();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.modalClose();

      this.alertService.ShowLoader(false);
    },
      error => {
        this.modalClose();
        this.alertService.ShowLoader(false);
        this.util.handleError(error);
      });
  }

  public onMapDestination(id: number) {
    var initialState = {
      title: 'MAP DESTINATION',
      multipleSelection: false
    };

    this.modalRef = this.modalService.show(DestinationSearchPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));

    this.modalRef.content.onClose.subscribe(result => {
      this.onSelectDestinationsSubscriptionRaised(result, id);
    });
  }

  private onSelectDestinationsSubscriptionRaised(destinationsSelected: Array<any>, id: number) {
    console.log(destinationsSelected);

    if (destinationsSelected != null && destinationsSelected.length > 0) {
      var item = this.registrationRequests.find(t => t.registrationrequestid == id);
      item.destinationid = destinationsSelected[0].id;
      item.destination = destinationsSelected[0].code;
    }
  }

  public modalClose() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
  }
}
