<form [formGroup]="destinationListRequest" #f="ngForm" (ngSubmit)="createNewDestinationList(destinationListRequest)">
  <h3 class="tylie-type-h3 t-ucase">Save To Distination List</h3>
  <div class="row">
    <div class="col-md-6">
      <div class="tylie-form-group form-group" [ngClass]="{error: destinationListRequest.controls['newDestinationListName'].errors && f.submitted}">
        <label class="tylie-form-group__lbl" for="ext-dest-list">Add To Existing Destination List<span class="required">*</span></label>
        <dx-select-box [dataSource]="userDestinationLists"
                      valueExpr="id"
                      displayExpr="name"
                      class="tylie-select form-control"
                      (onValueChanged)="onSelectDestinationList($event)"
                      placeholder="Select a Destination List">
        </dx-select-box>
      </div>
    </div>
    <div class="col-md-6">
      <div class="tylie-form-group form-group" [ngClass]="{error: destinationListRequest.controls['newDestinationListName'].errors && f.submitted}">
        <label class="tylie-form-group__lbl" for="new-dest-list">Add To New Destination List<span class="required">*</span></label>
        <input type="text" placeholder="Name your List" class="tylie-text form-control" formControlName="newDestinationListName">
        <p class="p-extra-small">Please enter an name for your List or choose an existing one</p>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end">
   
    <button class="tylie-button tylie-button--xs me-3" type="submit">ADD TO DESTINATION LIST</button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="close()">CANCEL</button>
  </div>
</form>
