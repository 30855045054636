import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from "../../../../models/config/gridparams.model";
import { DxDataGridComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../configurations/route-constants";
import { DestinationSearch } from "../../../../models/admin/destination/destinationsearch.model";
import { Subject } from "rxjs";
import * as moment from 'moment';
import { GlobalService } from '../../../../services/core/global.service';

@Component({
  selector: 'search-destinations',
  templateUrl: './search-destinations.component.html',
  styleUrls: ['./search-destinations.component.css']
})
export class SearchDestinationsComponent implements OnInit {

  @ViewChild('destinationSearch', { static: true }) destinationSearchGrid: DxDataGridComponent;

  public searchDestinationFormGroup: FormGroup;
  public quickSearchValue: string = '';

  public searchConfig: any = {};
  public statusDataSource: any = {};
  public destinationTypeDataSource: any = {};
  public searchDestinationCriteriaObj = <DestinationSearch>{};
  public resultDestinationStore: any = {};

  public now: Date = new Date();

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , private gs: GlobalService
    , private fb: FormBuilder) {

  }

  ngOnInit() {
    //search formgroup

    this.searchDestinationFormGroup = this.fb.group({
      code: [null],
      status: [null],
      destinationType: [null],
      createdFrom: [null],
      createdTo: [null],
      email: [null]
    });
    this.searchDestinationCriteriaObj.ignoreSort = false;
    this.getSearchConfig();
    this.searchDestinations();
  }

  public getSearchConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getSearchDestinationConfig().subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.searchConfig = res.result;
        this.destinationTypeDataSource = this.searchConfig.destinationTypes;
        this.statusDataSource = this.searchConfig.destinationStatus;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public searchDestinations() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchDestinationCriteriaObj;

    this.resultDestinationStore.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";;

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };
       
        return adminService.searchAllDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var destinationSearchResponse = response.result;
              var obj: any = {
                data: destinationSearchResponse.destinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public clearSearch() {
    //Clears all searches including quick and advanced
    this.searchDestinationFormGroup.reset();
    this.quickSearchValue = null;
    this.setDefaultFormValues();
    this.destinationSearchGrid.instance.refresh();
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
    this.searchDestinationCriteriaObj.ignoreSort = true;
    this.destinationSearchGrid.instance.clearSorting();
    this.destinationSearchGrid.instance.refresh();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchDestinationFormGroup.reset();
      this.setDefaultFormValues();
      this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
      this.destinationSearchGrid.instance.refresh();
    }
  }


  public onFormSubmit({ value, valid }: { value: DestinationSearch, valid: boolean }) {
    if (value.code === null && value.status === null && value.destinationType === null
      && value.createdFrom === null && value.createdTo === null && value.email == null) {
      return;
    }

    if (value.createdFrom != null || value.createdFrom === undefined)
      value.createdFrom = moment(value.createdFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.createdTo != null || value.createdTo === undefined)
      value.createdTo = moment(value.createdTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.searchDestinationCriteriaObj.code = value.code;
    this.searchDestinationCriteriaObj.status = value.status;
    this.searchDestinationCriteriaObj.destinationType = value.destinationType;
    this.searchDestinationCriteriaObj.createdFrom = value.createdFrom;
    this.searchDestinationCriteriaObj.createdTo = value.createdTo;
    this.searchDestinationCriteriaObj.email = value.email;
    this.searchDestinationCriteriaObj.quickSearch = null;

    this.destinationSearchGrid.instance.refresh();
  }

  private setDefaultFormValues() {
    this.searchDestinationCriteriaObj.code = null;
    this.searchDestinationCriteriaObj.status = null;
    this.searchDestinationCriteriaObj.destinationType = null;
    this.searchDestinationCriteriaObj.createdFrom = null;
    this.searchDestinationCriteriaObj.createdTo = null;
    this.searchDestinationCriteriaObj.quickSearch = null;
    this.searchDestinationCriteriaObj.email = null;
  }

  public showDestinationDetail(destinationGuid: string) {
    if (destinationGuid == "00000000-0000-0000-0000-000000000000")
      return;
    //this.router.navigate([RouteConstants.destinationDetailRoute, destinationGuid]);

    this.gs.openDestinationDetails(destinationGuid);
  }

  ngOnDestroy() {

  }
}
