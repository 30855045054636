import { Component, OnInit, ViewChild, Input, SimpleChanges, SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateProductModel, EditProduct } from "../../../../models/admin/brand/createproduct.model";
import { BrandProduct, BrandDetailConfig } from "../../../../models/admin/brand/branddetail.model";
import { Constants } from "../../../../configurations/constants";
import { ConfirmPopupComponent } from "../../../popups/confirmationpopups/confirmpopup.component";
import { confirmEventType } from "../../../../configurations/enums/enums";
import { Subscription } from 'rxjs';

@Component({
  selector: 'selected-products',
  templateUrl: './selected-products.component.html',
  styleUrls: ['./selected-products.component.css']
})
export class SelectedProductsComponent implements OnInit, OnChanges, OnDestroy {

  @Input('editMode') editMode: boolean = false;
  @Input('brandId') brandId: number;
  @Input('resetProducts') resetProducts: boolean = false;
  @Input('brandProducts') brandProducts: BrandProduct[] = null;
  @Input('config') config: BrandDetailConfig = null;

  @ViewChild('f', { static: false }) addproductform: NgForm;

  editProductForm: FormGroup;
  createProductForm: FormGroup;

  public modalRef: BsModalRef;
  public addNewProduct: boolean = false;
  public editProduct: boolean = false;

  public productValue: string[];
  public productStore: BrandProduct[] = null;
  public deletedProductStore: BrandProduct[] = [];
  private subscription: Subscription;

  constructor(public util: Utilities
    , public adminService: AdminService
    , private alertService: AlertService
    , private modalService: BsModalService) {


  }

  ngOnInit() {
    this.updateProductDataStore();
  }

  ngOnChanges(changes: SimpleChanges) {
    const resetProductsInput: SimpleChange = changes.resetProducts;
    if (this.util.notEmpty(resetProductsInput) && this.util.notEmpty(resetProductsInput.currentValue) && resetProductsInput.currentValue == true) {
      this.productStore = null;
      this.updateProductDataStore();
      this.addNewProduct = false;
      this.editProduct = false;
      if (this.util.notEmpty(this.addproductform)) {
        this.addproductform.resetForm();
      }
      this.deletedProductStore = [];
    }
  }

  public onAddProducts() {
    this.addNewProduct = true;
    this.editProduct = false;

    this.createProductForm = new FormGroup({
      productName: new FormControl('', Validators.required),
      shortName: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      productGuid: new FormControl()
    });

    this.subscription = this.createProductForm.get('productName').valueChanges.subscribe(val => {
      if (val != null) {
        this.createProductForm.patchValue({ 'shortName': val.substring(0, 15) });
      }
    });
  }

  public onFormSubmit({ value, valid }: { value: CreateProductModel, valid: boolean }, f: NgForm) {
    if (this.addNewProduct) {
      this.createProductForm.get('shortName').markAsTouched();
    }
    else {
      this.editProductForm.get('shortName').markAsTouched();
    }
    if (!valid) {
      return;
    }
    else {
      value.brandId = this.brandId;
      if (this.addNewProduct) {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.adminService.createProduct(value).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.editProduct = false;
            this.addNewProduct = false;
            var result = res.result;
            this.brandProducts = result.brandProducts;
            this.updateProductDataStore();
            this.alertService.showMessage("SUCCESS", Constants.createProductSuccessful, MessageSeverity.success);
            f.resetForm();
            this.alertService.ShowLoader(false);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
            this.alertService.ShowLoader(false);
          }
        },
          error => {
            this.util.handleError(error);
            this.alertService.ShowLoader(false);
          });
      }
      else if (this.editProduct) {
        var editProduct = new EditProduct();
        editProduct.productName = value.productName;
        editProduct.shortName = value.shortName;
        editProduct.brandId = this.brandId;
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.adminService.editProduct(value.productGuid, editProduct).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.editProduct = false;
            this.addNewProduct = false;
            var result = res.result;
            this.brandProducts = result.brandProducts;
            this.updateProductDataStore();
            this.alertService.showMessage("SUCCESS", Constants.editProductSuccessful, MessageSeverity.success);
            f.resetForm();
            this.alertService.ShowLoader(false);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
            this.alertService.ShowLoader(false);
          }
        },
          error => {
            this.util.handleError(error);
            this.alertService.ShowLoader(false);
          });
      }
    }
  }

  public onCancelProductCreateEdit() {
    this.editProduct = false;
    this.addNewProduct = false;
  }

  public onProductStoreDelete(data: BrandProduct) {
    console.log(data);
    if (this.util.notEmpty(data) && data.id > 0) {
      var initialState = {
        eventType: confirmEventType[confirmEventType.DeleteBrandProduct]
      };

      this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
      this.modalRef.content.onClose.subscribe(result => {
        if (result == true) {
          this.productStore = this.productStore.filter(p => p.productId != data.productId);
          if (this.productStore.length == 0) {
            this.productStore = null;
          }
          this.deletedProductStore.push(data);
        }
      });
    }
    else {
      this.productStore = this.productStore.filter(p => p.productId != data.productId);
      if (this.productStore.length == 0) {
        this.productStore = null;
      }
    }
  }

  public onEditProduct(data: BrandProduct) {
    this.editProduct = true;
    this.addNewProduct = false;

    this.editProductForm = new FormGroup({
      productName: new FormControl('', Validators.required),
      shortName: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      productGuid: new FormControl()
    });

    this.editProductForm.controls['productName'].setValue(data.name);
    this.editProductForm.controls['shortName'].setValue(data.shortName);
    this.editProductForm.controls['productGuid'].setValue(data.productGuid);
  }

  public getRemovedProducts() {
    return this.deletedProductStore;
  }

  private updateProductDataStore() {
    this.productStore = [];
    if (this.util.notEmpty(this.brandProducts) && this.brandProducts.length > 0) {
      this.brandProducts.forEach(r => {
        if ((this.util.notEmpty(this.productStore))) {
          if (this.productStore.find(p => p.productId == r.productId) === undefined) {
            this.productStore.push(r);
          }
        }
        else {
          this.productStore = [];
          this.productStore.push(r);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
