<form [formGroup]="createOrderRequest" #f="ngForm" (ngSubmit)="onSubmitOrder(createOrderRequest)">
  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item" id="ordersNewPanel">
      <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (header.controls['clientId'].errors || header.controls['brandId'].errors || header.controls['productId'].errors || headerErrors.length > 0 )
             && (triedToSubmitOrder || triedToSaveDraft || triedToAddMedia) }">
        <button type="button" class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" data-bs-target="#ordersNewCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          New Order
        </button>
      </h2>
      <div id="ordersNewCollapseOne" class="tylie-accordion__content accordion-collapse">
        <div class="tylie-accordion__body accordion-body" formGroupName="header">
          <div class="tylie-accordion__body-content">
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['clientId'].errors && (triedToSubmitOrder || triedToSaveDraft || triedToAddMedia) }">
                  <label class="tylie-form-group__lbl" for="client">Client<span class="required">*</span></label>
                  <dx-select-box class="tylie-select form-control" [dataSource]="clientDataSource"
                                 id="client"
                                 name="client"
                                 formControlName="clientId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 (onValueChanged)="onClientValueChanged($event)"
                                 placeholder="Select a client...">
                  </dx-select-box>
                  <p class="p-extra-small">Please select Client</p>
                </div>
                <div class="tylie-form-group form-group  r-brand" [ngClass]="{error: header.controls['brandId'].errors && (triedToSubmitOrder || triedToSaveDraft || triedToAddMedia)}">
                  <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
                  <dx-select-box [dataSource]="brandDataSource"
                                 class="tylie-select form-control"
                                 id="brand"
                                 name="brand"
                                 formControlName="brandId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 noDataText="Please choose a client"
                                 (onValueChanged)="onBrandValueChanged($event)"
                                 placeholder="Select a brand...">
                  </dx-select-box>
                  <p class="p-extra-small">Please select Brand</p>
                </div>
                <div class="tylie-form-group form-group  r-product" [ngClass]="{error: header.controls['productId'].errors && (triedToSubmitOrder || triedToSaveDraft || triedToAddMedia) }">
                  <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
                  <dx-select-box [dataSource]="productDataSource"
                                 class="tylie-select form-control"
                                 id="product"
                                 name="product"
                                 [searchEnabled]="true"
                                 noDataText="Please choose a brand"
                                 formControlName="productId"
                                 valueExpr="id"
                                 displayExpr="name"
                                 placeholder="Select a product..."
                                 (onValueChanged)="onProductValueChanged($event)">
                  </dx-select-box>
                  <p class="p-extra-small">Please select Product</p>
                </div>
                <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['orderedBy'].errors && triedToSubmitOrder }">
                  <label class="tylie-form-group__lbl" for="orderedBy">Ordered By<span class="required">*</span></label>
                  <input *ngIf="this.createOrderConfig?.allowSpecialOptions == false" type="text" id="orderedBy" name="orderedBy" class="tylie-text form-control" formControlName="orderedBy" [readonly]="this.createOrderConfig?.allowSpecialOptions == false">
                  <ng-container *ngIf="this.createOrderConfig?.allowSpecialOptions == true">
                    <dx-select-box [items]="orderedByDataSource"
                                   class="tylie-select form-control"
                                   id="ccCtr"
                                   name="ccCtr"
                                   [acceptCustomValue]="true"
                                   [noDataText]="orderedByNoDataText"
                                   formControlName="orderedBy"
                                   placeholder="Select Ordered By or Add new">
                    </dx-select-box>
                  </ng-container>
                  <p class="p-extra-small" *ngIf="header.controls['orderedBy'].errors?.required">Please enter Ordered By</p>
                </div>

                <div class="tylie-form-group form-group" *ngIf="additionalMetadata.allowSpecialOptions == true">
                  <label class="tylie-form-group__lbl" for="specialVisibilityClient">Agency</label>
                  <dx-select-box [dataSource]="specialVisibilityClientDataSource"
                                 id="specialVisibilityClient"
                                 name="specialVisibilityClient"
                                 class="tylie-select form-control"
                                 formControlName="specialVisibilityClientId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 noDataText="Please choose a Agency"
                                 displayExpr="name"
                                 placeholder="Select a Agency...">
                  </dx-select-box>
                  <p class="p-extra-small">Please select Agency</p>
                </div>

              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group purchase-order" [ngClass]="{error: header.controls['clientPurchaseOrder'].errors && triedToSubmitOrder }">
                  <label class="tylie-form-group__lbl d-flex justify-content-between" for="purchase">
                    Client Purchase Order<span class="required">*</span>
                    <div class="tylie-upload">
                      <input style="display: none;" id="f02" type="file" multiple #selectedFile (change)="onAddPO($event)" accept="application/pdf" />
                      <label class="tylie-upload__link" for="f02">
                        {{isAttaching ? 'Attaching...' : 'Attach PO'}}
                        <i *ngIf="isAttaching" class='fa fa-circle-o-notch fa-spin'></i>
                      </label>
                    </div>
                  </label>
                  <input type="text" id="purchase" name="purchase" class="tylie-text form-control" formControlName="clientPurchaseOrder">
                  <p class="p-extra-small" *ngIf="header.controls['clientPurchaseOrder'].errors?.required">Please enter Client Purchase Order</p>
                  <p class="p-extra-small" *ngIf="header.controls['clientPurchaseOrder'].errors?.maxlength">
                    Client PO can have a maximum of 15 characters.
                  </p>
                </div>
                <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['jobNumber'].errors && triedToSubmitOrder }">
                  <label class="tylie-form-group__lbl" for="job">Job#<span class="required">*</span></label>
                  <input type="text" id="job" name="job" class="tylie-text form-control" formControlName="jobNumber">
                  <p class="p-extra-small" *ngIf="header.controls['jobNumber'].errors?.required">Please enter Job</p>
                  <p class="p-extra-small" *ngIf="header.controls['jobNumber'].errors?.maxlength">
                    Job# can have a maximum of 15 characters.
                  </p>
                </div>
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="refKeywords">Reference/Keyword(s)<span class="required">*</span></label>
                  <input type="text" id="refKeywords" name="refKeywords" class="tylie-text form-control" formControlName="refKeywords">
                  <p class="p-extra-small">Please enter Reference/Keyword(s)</p>
                </div>
                <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['inVoiceTo'].errors && triedToSubmitOrder }">
                  <label class="tylie-form-group__lbl" for="invTo">Bill To<span class="required">*</span></label>
                  <dx-select-box [items]="inVoiceToDataSource"
                                 class="tylie-select form-control"
                                 id="invTo"
                                 name="invTo"
                                 [noDataText]="invoiceAndBillToNoDataText"
                                 formControlName="inVoiceTo"
                                 placeholder="Select Bill To">
                  </dx-select-box>
                  <p class="p-extra-small">Please select any Email</p>
                </div>
                <div class="tylie-form-group form-group" *ngIf="isDraftOrder == true">
                  <label class="tylie-form-group__lbl">Draft Order Number</label>
                  <input type="text" id="DraftOrderNumber" disabled name="DraftOrderNumber" value="{{this.draftOrderNumber}}" class="tylie-text form-control">
                </div>
              </div>
                <div class="col-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="estimate">Estimate#<span class="required">*</span></label>
                    <input type="text" id="estimate" name="estimate" class="tylie-text form-control" formControlName="estimate">
                    <p class="p-extra-small">Please enter estimate(s)</p>
                  </div>
                  <div class="tylie-form-group form-group" [ngClass]="{error: header.hasError('airdatesInvalid') && triedToSubmitOrder}">
                    <label class="tylie-form-group__lbl" for="ads">Air Date Start<span class="required">*</span></label>
                    <div class="input-group date" style="width:100%">
                      <dx-date-box width="100%" #airDtStart type="date" id="ads" name="ads"
                                   displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                                   class="tylie-datepicker form-control" [showClearButton]="true" formControlName="airDateStart"></dx-date-box>
                    </div>
                    <p class="p-extra-small">Invalid Air Dates</p>
                  </div>
                  <div class="tylie-form-group form-group" [ngClass]="{error: header.hasError('airdatesInvalid') && triedToSubmitOrder}">
                    <label class="tylie-form-group__lbl" for="ade">Air Date End<span class="required">*</span></label>
                    <div class="input-group date" style="width:100%">
                      <dx-date-box width="100%" #airDtEnd type="date" id="ade" name="ade"
                                   displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                                   class="tylie-datepicker form-control" [showClearButton]="true" formControlName="airDateEnd"></dx-date-box>
                    </div>
                    <p class="p-extra-small">Invalid Air Dates</p>
                  </div>
                  <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['costCenter'].errors && triedToSubmitOrder }">
                    <label class="tylie-form-group__lbl" for="invTo">Cost Center<span class="required">*</span></label>
                    <dx-select-box [items]="costCenterDataSource"
                                   class="tylie-select form-control"
                                   id="ccCtr"
                                   name="ccCtr"
                                   [acceptCustomValue]="true"
                                   [noDataText]="invoiceAndBillToNoDataText"
                                   formControlName="costCenter"
                                   placeholder="Select a Cost Center or Add new">
                    </dx-select-box>
                    <p class="p-extra-small" *ngIf="header.controls['costCenter'].errors?.required">Please enter a Cost Center</p>
                    <p class="p-extra-small" *ngIf="header.controls['costCenter'].errors?.maxlength">
                      Cost Center can have a maximum of 35 characters.
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: header.controls['podEmails'].hasError('inValidEmailList') && triedToSubmitOrder}">
                    <label class="tylie-form-group__lbl d-flex justify-content-between" for="podEmails">
                      POD Recipients (via Email)<span class="required">*</span>
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddMorePODEmails()">
                        Add from List
                      </button>
                    </label>
                    <textarea id="podEmails" name="podEmails" class="tylie-textarea form-control" formControlName="podEmails" placeholder="semi-colon seperated" style="height: 134px;"></textarea>
                    <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
                  </div>
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="ordernotes">Order Notes<span class="required">*</span></label>
                    <textarea id="ordernotes" name="ordernotes" class="tylie-textarea form-control" formControlName="notes" style="height: 134px;"></textarea>
                    <p class="p-extra-small">Please enter Order Notes</p>
                  </div>
                </div>
              </div>
           
            <div class="tylie-form-group form-group" *ngIf="additionalMetadata.allowSpecialOptions == true">
              <label class="tylie-form-group__lbl" for="ordercomments">CX Comments<span class="required">*</span></label>
              <textarea id="ordercomments" name="ordercomments" class="tylie-textarea form-control" formControlName="comments" style="height: 134px;"></textarea>
              <p class="p-extra-small">Please enter Order Comments</p>
            </div>
             

            <div class="row checkbox-row" *ngIf="this.createOrderConfig?.allowSpecialOptions == true || additionalMetadata.allowDestinationHub == true || additionalMetadata.allowSpecialOptions == true">
              <div class="col-md-3">
                <div class="tylie-form-group form-group" *ngIf="this.createOrderConfig?.allowSpecialOptions == true">
                  <label class="tylie-form-group__lbl" for="originalWorkOrder">Non-Billable Order<span class="required">*</span></label>
                  <input type="text" id="originalWorkOrder" name="originalWorkOrder" class="tylie-text form-control" formControlName="originalWorkOrder" placeholder="Enter Original Order#">
                  <p class="p-extra-small">Please enter Original Order#</p>
                </div>
              </div>
              <div class="col-md-9" *ngIf="additionalMetadata.allowDestinationHub == true || additionalMetadata.allowSpecialOptions == true">
                <div class="d-flex mt-4">
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowDestinationHub == true">
                    <input class="form-check-input" type="checkbox" formControlName="isDestinationsHubbed" name="isDestinationsHubbed">
                    <label class="form-check-label" for="isDestinationsHubbed">Hub Destinations</label>
                  </div>
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowSpecialOptions == true">
                    <input class="form-check-input" type="checkbox" formControlName="isConsolidatedOrder" name="isConsolidatedOrder" (change)="onCosolidatedFlagChanged()">
                    <label class="form-check-label">Consolidated Order</label>
                  </div>
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowSpecialOptions == true">
                    <input class="form-check-input" type="checkbox" formControlName="isFinalReviewRequired" name="finalReviewRequired">
                    <label class="form-check-label">Final Review Required</label>
                  </div>
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowSpecialOptions == true">
                    <input class="form-check-input" type="checkbox" formControlName="isOrderFlagged" name="isOrderFlagged">
                    <label class="form-check-label">Flag Order</label>
                  </div>
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowSpecialOptions == true">
                    <input class="form-check-input" type="checkbox" formControlName="isProBono" name="isProBono">
                    <label class="form-check-label">Pro Bono</label>
                  </div>
                  <div class="tylie-form-group form-check me-3" *ngIf="additionalMetadata.allowSpecialOptions == true">
                    <input class="form-check-input" type="checkbox" formControlName="isPostDatedOrder" name="isPostDatedOrder">
                    <label class="form-check-label" for="isPostDatedOrder">Billing Only Order</label>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="tylie-panel tylie-panel--secondary" id="buildOrderPanel" *ngIf="(additionalMetadata.allowSpecialOptions == true) || (header.controls['attachments']?.value?.length > 0)">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  Attachments
                  <button type="button" *ngIf="additionalMetadata.allowSpecialOptions == true" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddAttachments()"><span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>Add Attachments</button>
                </h4>
              </div>
              <div class="tylie-panel__body time-comment-view">
                <ng-conatainer *ngIf="header.controls['attachments']?.value?.length == 0">
                    There are no attachments yet
                </ng-conatainer>
                <table class="tylie-table table table-bordered" *ngIf="header.controls['attachments']?.value?.length > 0">
                  <thead class="tylie-table__head">
                    <tr>
                      <th style="width:20%;">File Type</th>
                      <th style="width:76%;">File Name</th>
                      <th style="width:4%;"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <tr *ngFor="let att of header.controls['attachments']?.value; let i = index">
                      <td style="width:20%;">{{att.fileCategory}}</td>
                      <td style="width:76%;" class="quickLink"><a (click)="onDownloadFile(att.orderFileGuid, att.fileName);">{{att.fileName}}</a></td>
                      <td align="center">
                        <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onAttachmentDelete(att.orderFileGuid)">
                          <icon-trash-can color="#1e90ff"></icon-trash-can>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="headerErrors != null && headerErrors.length > 0">
              <br />
              <ng-container *ngFor="let err of headerErrors">
                <p class="p-small" style="color: #ec1c23">{{err}}</p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel tylie-accordion__panel--clean accordion-item" id="build-order-section">
      <h2 class="tylie-accordion__heading accordion-header" (click)="onCollapse()">
        <button type="button" class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" data-bs-target="#buildOrderCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Build Order
        </button>
      </h2>
      <div id="buildOrderCollapseOne" class="tylie-accordion__content accordion-collapse">
        <div class="tylie-accordion__body accordion-body">
          
          <ng-container *ngFor="let grp of groups.controls; let i=index">
            <create-ordergroup [group]="grp"
                                [header]="header"
                                [triedToSubmitOrder]="triedToSubmitOrder"
                                [orderGuid]="createOrderRequest.controls['orderGuid'].value"
                                [additionalMetadata]="additionalMetadata"
                                [totalGroups]="groups.controls.length"
                                (tryingToAddMedia)="onTryingToAddMediaInGroup()"
                                (addMediaToAllGroups)="onAddMediaToAllGroups($event)"
                                (addDestinationsToAllGroups)="onAddDestinationsToAllGroups($event)"
                                (groupDeleted)="onDeleteGroup(createOrderRequest.controls.groups.controls[i].controls.sequenceId.value)"
                                (trascodeRequestsAddedOrEdited)="onRouteToTranscodeRequests($event,createOrderRequest.controls.groups.controls[i].controls.sequenceId.value)">
            </create-ordergroup>
          </ng-container>

          <div class="d-flex justify-content-end my-4" *ngIf="isOrderSubmitted == false">
            <button type="button" class="tylie-button tylie-button--icon tylie-button--xs" type="button" (click)="onAddNewGroup()">
              <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
              <span class="tylie-button__text">Add Group</span>
            </button>
          </div>

          <div class="tylie-accordion accordion">
            <div class="tylie-accordion__panel accordion-item">
              <h2 class="tylie-accordion__heading accordion-header" [ngClass]="{'error-panel-groups': (containsServiceErrors === true && triedToSubmitOrder === true) }">
                <button type="button" class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" data-bs-target="#addlServicesPanel" aria-expanded="true" aria-controls="collapseOne">
                  ADDITIONAL SERVICES
                </button>
              </h2>
              <div id="addlServicesPanel" class="tylie-accordion__content accordion-collapse">
                <div class="tylie-accordion__body accordion-body" formGroupName="header">
                  <div class="tylie-accordion__body-content">
                    <div class="tylie-panel tylie-panel--secondary">
                      <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="header.controls['serviceData']?.value?.length == 0">
                        <h4 class="tylie-panel__title">
                          Services
                        </h4>
                      </div>
                      <div class="tylie-panel__header tylie-panel__header--br-thick" *ngIf="header.controls['serviceData']?.value?.length > 0">
                        <h4 class="tylie-panel__title">
                          Services
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddEditAddlServices(true)">
                            <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                            Add/Edit Services
                          </button>
                        </h4>
                      </div>
                      
                      <div class="tylie-panel__body" *ngIf="header.controls['serviceData']?.value?.length == 0">
                        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddEditAddlServices(false)">
                          <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                          Add Services
                        </button>
                      </div>
                      <div class="tylie-panel__body" *ngIf="header.controls['serviceData'].value != null && header.controls['serviceData']?.value?.length > 0">
                      
                          <table class="tylie-table table table-bordered">
                            <thead class="tylie-table__head">
                              <tr>
                                <th width="90%" [ngClass]="{'group-error-message': (containsServiceErrors === true && triedToSubmitOrder === true) }">
                                  Services Selected
                                </th>
                                <th width="10%"></th>
                              </tr>
                            </thead>
                            <tbody class="tylie-table__body">
                              <tr *ngFor="let serv of header.controls['serviceData'].value; let i = index">
                                <td class="service-selected" [ngClass]="{'group-error-border': (serv.containsServiceMetadataDataErrors == true && triedToSubmitOrder) }">
                                  <p class="p-extra-small group-error-message" placement="left" [popover]="serv.errorMsg" triggers="mouseenter:mouseleave" containerClass="customPopover"
                                      *ngIf="serv.containsServiceMetadataDataErrors == true && (triedToSubmitOrder)">
                                    {{serv.service.name}}{{getTextData(serv.serviceMetadata)}}
                                  </p>
                                  <p class="p-extra-small" *ngIf="!(serv.containsServiceMetadataDataErrors == true && triedToSubmitOrder)">{{serv.service.name}}{{getTextData(serv.serviceMetadata)}}</p>
                                </td>
                                <td>
                                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onServiceDelete(serv.service.id)"><span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span></button>
                                  
                                </td>
                              </tr>
                              <tr *ngIf="isNonTextItemsExists(header.controls['serviceData'].value)">
                                <td colspan="2" class="thumb-cell">
                                  <table class="tylie-table table table-bordered services-row">
                                    <thead class="tylie-table__head">
                                      <tr>
                                        <th width="20%">Service</th>
                                        <th width="55%">File</th>
                                        <th width="15%">Type</th>
                                        <th width="10%">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tylie-table__body">
                                      <ng-container *ngFor="let serv of header.controls['serviceData'].value">
                                        <ng-container *ngFor="let mt of serv.serviceMetadata">
                                          <tr *ngIf="isNonTextItem(mt)">
                                            <td>{{serv.service.name}}</td>
                                            <td>{{mt.display}}</td>
                                            <td>{{mt.fileValue != null ? mt.fileValue.fileType : 'Text'}}</td>
                                            <td>
                                              
                                              <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onServiceMetadataDelete(serv.serviceId, mt.metadataKey)"><span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span></button>
                                            </td>
                                          </tr>
                                        </ng-container>
                                      </ng-container>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end" *ngIf="isOrderSubmitted == false">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" outsideClick="true" placement="top" #clearOrderpop="bs-popover" container="body" [popover]="clearOrderTemplate">
                Clear Order
              </button>
              <button class="tylie-button tylie-button--icon tylie-button--xs ms-3" type="button" (click)="onSaveChanges(createOrderRequest)">
                <span class="tylie-button__icon"><icon-save></icon-save></span>
                <span class="tylie-button__text">{{createOrderModuleType =='upload'?  'Save Changes':'Save As Draft'}}</span>
              </button>
              <button class="tylie-button tylie-button--icon tylie-button--xs ms-3" type="submit">
                <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
                <span class="tylie-button__text">Submit Order</span>
              </button>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</form>

<div class="scrolltop" style="display: none;" (click)="onScrollToTop()"><span></span></div>

<ng-template #clearOrderTemplate>
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to clear the Order?
    </p>
    <i (click)="onHideClearOrderPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="clearSavedData()">Yes</button> -->

  <button class="popover__close" (click)="onHideClearOrderPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header"> Are you sure you'd like to clear the Order?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="clearSavedData()">yes</button>

</ng-template>
