import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { AlertService } from '../../../../services/core/alert.service';
import { Utilities } from '../../../../services/core/utilities';
import { AccountNameValueChangedEvent } from '../../../../models/admin/client/deliveryconfig.model';

@Component({
  selector: 'filedeliveryconfiggroup',
  templateUrl: './filedeliveryconfiggroup.component.html',
  styleUrls: ['./filedeliveryconfiggroup.component.css'],
})
export class FileDeliveryConfigGroup implements OnInit {

  @Input() cfdGroup: FormGroup;
  @Input() parentForm: NgForm;
  @Input() groupName: number;
  @Input() formGroupcount: number;
  @Input('fileDelvTypes') fileDelvTypes: string[] = null;
  @Input('isEdit') isEdit: boolean = false;

  @Output() onClickRemoveFileDeliveryConfigEventTriggered: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAccountNameValueChangedEventTriggered: EventEmitter<AccountNameValueChangedEvent> = new EventEmitter<AccountNameValueChangedEvent>();

  public isConfigTypeGoogleAds: boolean = false;

  public clientFileDeliveryConfigTypes;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities) {


  }

  ngOnInit() {
    this.cfdGroup.get('accountName').valueChanges.pipe(debounceTime(300),
      distinctUntilChanged(),)
      .subscribe(val => {
        var valChange = new AccountNameValueChangedEvent();
        valChange.formGroupNumber = this.groupName;
        valChange.accountName = val;

        if (val != null && val != undefined && val.trim().length > 0) {
          this.onAccountNameValueChangedEventTriggered.emit(valChange);
        }
      });

  }

  public onConfigTypeChange(e) {
    if (e == null || e == undefined) {
      return;
    }

    if (e.value.toLocaleLowerCase() === 'googleads') {
      this.isConfigTypeGoogleAds = true;
      this.cfdGroup.get('googleAdsLoginCustomerId').setValidators([Validators.required]);
      this.cfdGroup.get('googleAdsLoginCustomerId').updateValueAndValidity();

      this.cfdGroup.get('googleAdsCustomerId').setValidators(null);
      this.cfdGroup.get('googleAdsCustomerId').updateValueAndValidity();
    }
    else {
      this.isConfigTypeGoogleAds = false;
      this.cfdGroup.get('googleAdsLoginCustomerId').setValidators(null);
      this.cfdGroup.get('googleAdsLoginCustomerId').updateValueAndValidity();

      this.cfdGroup.get('googleAdsCustomerId').setValidators(null);
      this.cfdGroup.get('googleAdsCustomerId').updateValueAndValidity();
    }
  }

  public onClickRemoveFileDeliveryConfig() {
    this.onClickRemoveFileDeliveryConfigEventTriggered.emit(this.groupName);
  }
}
