<header class="tylie-header">
  <h3 class="tylie-header__title">Create New Order</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoOrdersHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Orders</button>
  </nav>
</header>
<div class="tylie-body" id="scrollContainer">
  <createorder-content [orderDraftGuid]="" [uploadedOrderGuid]="" [copyOrderGuid]="" [unSavedOrderGuid]="unSavedOrderGuid" [isPopOver] ="false"></createorder-content>
  <div class="scrolltop" style="display: none;" (click)="onScrollToTop()"><span></span></div>
</div>
