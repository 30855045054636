import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-arrow-simple-up',
  templateUrl: './icon-arrow-simple-up.component.html',
  styleUrls: ['./icon-arrow-simple-up.component.css']
})
export class IconArrowSimpleUpComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
