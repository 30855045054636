import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { request } from 'https';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { VaultAssetStages } from '../../../configurations/enums/enums';
import { VaultAssetStatusUpdateRequest } from '../../../models/vault/vault-asset.model';
import { VaultAssetRemoveDestroyRequest } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { VaultService } from '../../../services/vault/vault.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-vault-asset-popup',
  templateUrl: './vault-asset-popup.component.html',
  styleUrls: ['./vault-asset-popup.component.css']
})
export class VaultAssetPopupComponent extends BasePopupComponent {

  @Input('selectedAssets') selectedAssets: Array<any>;
  @Input('source') source: number;
  @Input('isVaultDigitalAsset') isVaultDigitalAsset: boolean = false;

  public txtNotes: string;
  public isNotesEmpty: boolean;
  public selectedAssetIds: Array<number>;
  public vaultassetRequest: VaultAssetStatusUpdateRequest;
  public vaultassetStageRequest: VaultAssetRemoveDestroyRequest;

  public functionButtonText: string;
  public functionButtonVisible: boolean;
  public title: string;
  public headerText: string;

  public onVaultAssetStatusUpdated: Subject<boolean>;


  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private vaultService: VaultService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.selectedAssets);
    console.log(this.source);
    this.onVaultAssetStatusUpdated = new Subject();

    if (this.source == VaultAssetStages.destroy) {
      this.functionButtonText = "Destroy";
      this.functionButtonVisible = true;
      this.title = "Destroy Vault Asset";
      this.headerText = "Selected Assets to Destroy";
    } else if (this.source == VaultAssetStages.login) {
      this.functionButtonText = "Login";
      this.functionButtonVisible = true;
      this.title = "Transfer Vault Asset";
      this.headerText = "Selected Assets to Log In";
    } else if (this.source == VaultAssetStages.staging) {
      this.functionButtonVisible = false;
      this.title = "Transfer Vault Asset";
      this.headerText = "Selected Assets to Staging";
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onCancelClicked() {
    this.closePopup();
  }

  public createVaultAssetUpdateRequest() {
    this.selectedAssetIds = new Array<number>();
    this.selectedAssets.forEach(r => {
      this.selectedAssetIds.push(r.vaultAssetId);
    });
    this.vaultassetRequest = new VaultAssetStatusUpdateRequest();
    this.vaultassetRequest.VaultAssetIds = this.selectedAssetIds;
    this.vaultassetRequest.Notes = this.txtNotes;
    this.vaultassetRequest.isVaultDigitalAsset = this.isVaultDigitalAsset;
  }

  public createVaultAssetUpdateStageRequest() {
    this.selectedAssetIds = new Array<number>();
    this.selectedAssets.forEach(r => {
      this.selectedAssetIds.push(r.vaultAssetId);
    });
    this.vaultassetStageRequest = new VaultAssetRemoveDestroyRequest();
    this.vaultassetStageRequest.vaultAssetIds = this.selectedAssetIds;
    this.vaultassetStageRequest.notes = this.txtNotes;
    this.vaultassetStageRequest.isVaultDigitalAsset = this.isVaultDigitalAsset;
  }

  public onLoginClicked() {
    if (this.txtNotes == null) {
      this.isNotesEmpty = true;
      return;
    }
    this.createVaultAssetUpdateRequest();
    this.alertService.ShowLoader(true);

    if (this.source == VaultAssetStages.login) {
      this.vaultService.updateVaultAssetsStatusLogin(this.vaultassetRequest).subscribe((res: any) => {
        this.alertService.ShowLoader(false);
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESSS", Constants.assetStatusLoginUpdated, MessageSeverity.success, true);
          this.onVaultAssetStatusUpdated.next(true);
          this.closePopup();
        } else {
          this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
        }
      });
    } else if (this.source == VaultAssetStages.destroy) {
      this.vaultService.updateVaultAssetsStatusDestroy(this.vaultassetRequest).subscribe((res: any) => {
        this.alertService.ShowLoader(false);
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESSS", Constants.assetStatusLoginUpdated, MessageSeverity.success, true);
          this.onVaultAssetStatusUpdated.next(true);
          this.closePopup();
        } else {
          this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
        }
      });
    }

  }

  public onStageClicked() {
    if (this.txtNotes == null) {
      this.isNotesEmpty = true;
      return;
    }
    this.createVaultAssetUpdateStageRequest();
    console.log(this.vaultassetStageRequest);
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetsStatusStaging(this.vaultassetStageRequest).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusStagingUpdated, MessageSeverity.success, true);
        this.onVaultAssetStatusUpdated.next(true);
        this.closePopup();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

}
