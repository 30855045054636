import { NotificationChoice } from "./notification-choice.model";

export class EmailNotificationPreference{

  public email: string;
  public notificationChoices: NotificationChoice[];

  constructor(email: string, notificationChoices: NotificationChoice[]) {
    this.email = email;
    this.notificationChoices = notificationChoices;
  }

}
