<div class="modal-header">
  <button
    type="button"
    class="modal-close"
    (click)="closePopup()"
  >
    <icon-delete color="#000"></icon-delete>
  </button>
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div class="modal-body">
  <form
    [formGroup]="taggerUserRequest"
    #f="ngForm"
    (ngSubmit)="onSubmitUser(taggerUserRequest)"
  >
    <div class="row">
      <div class="col-md-6">
        <div
          class="tylie-form-group form-group"
          [ngClass]="{
            error: taggerUserRequest.controls['location'].errors && f.submitted
          }"
        >
          <label
            class="tylie-form-group__lbl"
            for="location"
            >Select Location<span class="required">*</span></label
          >
          <dx-select-box
            class="tylie-select form-control"
            [dataSource]="locationDataSource"
            id="location"
            name="location"
            selectionMode="multiple"
            formControlName="location"
            [searchEnabled]="true"
            valueExpr="locationGuid"
            displayExpr="name"
            placeholder="Select a location..."
            [disabled]="isEditUser"
          >
          </dx-select-box>
          <p class="p-extra-small">Please select Location</p>
        </div>
      </div>
    </div>

    <app-taggeruserpopup
      [taggerUserRequest]="taggerUserRequest"
      [isFormSubmitted]="isFormSubmitted"
    ></app-taggeruserpopup>

    <div class="row">
      <div class="col-md-3">
        <div *ngIf="isEditUser">
          <button type="button"
                  class="tylie-button tylie-button--xs tylie-button--link me-3"
                  (click)="deleteUserClicked()">
            <span class="tylie-button__icon">
              <icon-trash-can color="#1e90ff" height="15px" width="15px"></icon-trash-can>
            </span>
            DELETE USER
          </button>
        </div>
      </div>

      <div class="col-md-9">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="tylie-button tylie-button--xs tylie-button--link me-3"
            (click)="closePopup()"
          >
            Cancel
          </button>
          <button
            class="tylie-button tylie-button--xs tylie-button--icon"
            type="submit"
          >
            <span class="tylie-button__icon">
              <icon-checkmark></icon-checkmark>
            </span>
            <span class="tylie-button__text">SAVE USER</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
