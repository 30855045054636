import { Component, ViewChild, TemplateRef, Input, OnInit, ViewChildren, QueryList } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Utilities } from '../../../../services/core/utilities';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { SpotService } from '../../../../services/media/spot.service';
import { ConfigService } from '../../../../services/core/config.service';
import { FileNameOption, FileNameSeperator, TranscodeOrderConfig } from '../../../../models/order/transcodeorder-config.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SearchUserDestinationRequest } from '../../../../models/order/order-searchuserdestination-request.model';
import { OrderUserDestination } from '../../../../models/order/order-userdestination';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { fileOptionsValidator, filePathValidator, transcodeFilePathValidator } from '../../../../configurations/custom-validations';
import { Constants } from '../../../../configurations/constants';
import { FileNamingTypeEnum } from '../../../../configurations/enums/enums';

@Component({
  selector: 'transcode-filename-step',
  templateUrl: './transcode-filename-step.component.html',
  styleUrls: ['./transcode-filename-step.component.css']
})

export class TranscodeFilenameComponent implements OnInit {

  public fileNameSeperatorDataSource: Array<FileNameSeperator> = [];
  public savedStylesSource: Array<OrderUserDestination> = [];

  public option1Error: boolean = false;
  public option2Error: boolean = false;

  @Input() transcodeOrderConfig: TranscodeOrderConfig;
  @Input() fileNamingType: string;
  @Input() fileSeperator: string;
  @Input() customFileName: string;
  @Input() customFileNaming: any;
  @Input() fileNameOptions: Array<FileNameOption>;
  @Input() transcodeData: any;
  @Input() spotData: any;

  public fileNameForm: FormGroup;
  public customFileNameForm: FormGroup;
  subscription: Subscription;

  @ViewChildren('styledeletepop') styledeletepop: QueryList<PopoverDirective>;

  constructor(
    public _fb: FormBuilder,
    private util: Utilities,
    private router: Router,
    public bsModalRef: BsModalRef,
    public alertService: AlertService,
    private configService: ConfigService,
    private spotService: SpotService) {
  }

  ngOnInit() {
    console.log(this.transcodeData);
    this.getSavedStyles();

    this.transcodeOrderConfig.fileNameSeperators.forEach(t => { this.fileNameSeperatorDataSource.push(t) });

    let fileNameOptionsDataSource: Array<FileNameOption> = [];
    let options: Array<any> = [];

    //first load all the items in the object
    this.fileNameOptions.forEach(t => {
      fileNameOptionsDataSource.push({ name: t.name, isCustom: t.isCustom, selected: true });
    });

    //add the missing items from the config
    this.transcodeOrderConfig.fileNameOptions.forEach(t => {
      var index = fileNameOptionsDataSource.findIndex(x => x.isCustom == false && x.name == t);
      if (index < 0)
        fileNameOptionsDataSource.push({ name: t, isCustom: false, selected: false });
    });

    fileNameOptionsDataSource.forEach((t, i) => {
      options.push(this._fb.group({
        name: t.name,
        isCustom: t.isCustom,
        selected: t.selected
      }));
    });

    this.fileNameForm = this._fb.group({
      fileNamingType: new FormControl(this.fileNamingType, Validators.required),
      customFileName: new FormControl(this.customFileName, Validators.compose([transcodeFilePathValidator])),
      fileSeperator: new FormControl(this.fileSeperator, Validators.required),
      exampleFileName: new FormControl('', Validators.required),
      styleName: new FormControl(''),
      fileNameOptions: this._fb.array(options, Validators.compose([fileOptionsValidator]))
    });

    this.customFileNameForm = this._fb.group({
      customFileNaming: this.spotSpecCustomFileDataArray
    });


    this.setExampleFileName();

    this.subscription = this.fileNameForm.controls.fileSeperator.valueChanges.subscribe(f => {
      this.setExampleFileName();
    });

    //this.subscription = this.fileNameForm.controls.customFileName.valueChanges.subscribe(f => {
    //  this.applyErrors(false, false);
    //});
  }

  private get spotSpecCustomFileDataArray() {
    var spotSpecCustomFileDataArray: FormArray = this._fb.array([]);
    this.spotData.forEach(val => {
      console.log(val);
      var assetFb = this._fb.group({
        adId: new FormControl(val.adId, Validators.required),
        title: new FormControl(val.title, Validators.required),
        spotFileGuid: new FormControl(val.spotFileGuid, Validators.required),
        specFileNames: this.spotSpecFileNameArray(val.spotFileGuid)
      });

      spotSpecCustomFileDataArray.push(assetFb);
    });

    return spotSpecCustomFileDataArray as FormArray;
  }

  private spotSpecFileNameArray(spotFileGuid: string) {
    var spotSpecFileNameArray: FormArray = this._fb.array([]);
    var transcodes = this.transcodeData.get(spotFileGuid);

    transcodes.forEach(val => {
      var specName = this._fb.group({
        spec: new FormControl(val, Validators.required),
        customSpecFileName: new FormControl(this.getcustomSpecFileName(val, spotFileGuid), Validators.compose([transcodeFilePathValidator])),
      });

      spotSpecFileNameArray.push(specName);
    });

    return spotSpecFileNameArray as FormArray;
  }

  private getcustomSpecFileName(transcode, spotfileGuid) {
    if (this.customFileNaming != null) {
      var customNameObj = this.customFileNaming.find(t => t.spotFileGuid == spotfileGuid) != null ? this.customFileNaming.find(t => t.spotFileGuid == spotfileGuid).specFileNames: null;
      var transcodeFileName = customNameObj != null ? customNameObj.find(t => t.spec == transcode) : null;
      return transcodeFileName != null ? transcodeFileName.customSpecFileName : null;
    }
    else {
      return null;
    }
  }

  private applyErrors(isOption1Error: boolean, isOption2Error: boolean) {
    this.option1Error = isOption1Error;
    this.option2Error = isOption2Error;
  }

  private setExampleFileName() {
    var selectedFileNameOptions = this.fileNameForm.value.fileNameOptions as Array<FileNameOption>;
    var examples = [];

    selectedFileNameOptions.forEach(item => {
      if (item.selected) {
        if (item.isCustom == true)
          examples.push(item.name);
        else
          examples.push(this.transcodeOrderConfig.fileNameOptionSamples.find(t => t.name == item.name).sample);
      }
    });

    if (examples.length == 0)
      examples.push("N/A");

    this.fileNameForm.controls["exampleFileName"].setValue(examples.join(this.fileNameForm.controls["fileSeperator"].value));
  }

  public onFileOptionSelectionChange() {
    this.setExampleFileName();

    this.applyErrors(false, false);
  }

  public onSaveStyle() {

    if (this.fileNameForm.controls['styleName'].value == null || this.fileNameForm.controls['styleName'].value.trim() == '')
      return;

    var selectedFileNameOptions = this.getSelectedValidFileNameOptions();

    if (selectedFileNameOptions.length == 0) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_select_fileoptions, MessageSeverity.error);
      return;
    }

    var styleData = [];

    styleData.push(JSON.stringify(selectedFileNameOptions));

    styleData.push(this.fileNameForm.controls['fileSeperator'].value);

    this.configService.createUserSavedDestinationList(this.fileNameForm.controls['styleName'].value, "specfile", styleData).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcode_saved_style, MessageSeverity.success);
        this.fileNameForm.controls['styleName'].setValue('');
        this.getSavedStyles();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public getSelectedValidFileNameOptions(): Array<FileNameOption> {
    return (this.fileNameForm.value.fileNameOptions as Array<FileNameOption>).filter(t => t.selected == true && (t.isCustom == false || (t.isCustom == true && t.name.trim() != '')));
  }

  public onDeleteStyle(id: number) {

    this.configService.deleteUserSavedDestinationList(id, "specfile").subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcode_deleted_style, MessageSeverity.success);
        this.getSavedStyles();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.onHideDeleteStylePop();
    },
      error => {
        this.util.handleError(error);
        this.onHideDeleteStylePop();
      });
  }

  public onApplyStyle(id) {
    var chosenStyle = this.savedStylesSource.find(t => t.id == id);

    this.fileNameForm.controls['fileSeperator'].setValue(chosenStyle.fileNamingOptions.seperator);

    let optionsInStyle = chosenStyle.fileNamingOptions.options;

    let fileNameOptionsDataSource: Array<FileNameOption> = [];

    //first load all the items in the saved style
    optionsInStyle.forEach(t => {
      fileNameOptionsDataSource.push({ name: t.name, isCustom: t.isCustom, selected: true });
    });

    //add the missing items from the config
    this.transcodeOrderConfig.fileNameOptions.forEach(t => {
      var index = fileNameOptionsDataSource.findIndex(x => x.isCustom == false && x.name == t);
      if (index < 0)
        fileNameOptionsDataSource.push({ name: t, isCustom: false, selected: false });
    });

    let options: Array<any> = [];

    fileNameOptionsDataSource.forEach((t, i) => {
      options.push(this._fb.group({
        name: t.name,
        isCustom: t.isCustom,
        selected: t.selected
      }));
    });

    this.fileNameForm.setControl('fileNameOptions', this._fb.array(options, Validators.compose([fileOptionsValidator])));

    this.setExampleFileName();

    this.applyErrors(false, false);
  }

  private getSavedStyles() {

    let request: SearchUserDestinationRequest = { category: 'specfile', quickSearch: '' };

    this.configService.searchUserDestinations(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var destinations = res.result as Array<OrderUserDestination>;
        this.savedStylesSource = destinations;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onHideDeleteStylePop() {
    this.styledeletepop.forEach(p => {
      p.hide();
    });
  }

  //public isValid(): boolean {
  //  if (this.fileNameForm.controls['fileNamingType'].value == 'advanced') {

  //    //both option 1 & option 2 filled up
  //    if (!this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')
  //      && this.fileNameForm.value.customFileName.trim() != '') {
  //      this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_1_2, MessageSeverity.error);
  //      return false;
  //    }

  //    //both option 1 & option 2 not filled up
  //    if (this.fileNameForm.value.customFileName.trim() == ''
  //      && this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')) {
  //      this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_1_2, MessageSeverity.error);
  //      this.applyErrors(true, true);
  //      return false;
  //    }

  //    //customFilename error exist and FileNameOptions not chosen
  //    if (this.fileNameForm.controls["customFileName"].hasError('inValidFilePath') && this.fileNameForm.value.customFileName.trim() != ''
  //      && this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')) {
  //      this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_2, MessageSeverity.error);
  //      this.applyErrors(false, true);
  //      return false;
  //    }
  //  }

  //  return true;
  //}


  public isValid(): boolean {
    console.log(this.customFileNameForm);
    console.log(this.customFileNameForm.controls["customFileNaming"].value);
    if (this.fileNameForm.controls['fileNamingType'].value == 'advanced') {

      //both option 1 & option 2 filled up
      if (!this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')
        && this.customFileNameForm.controls["customFileNaming"].valid == true) {
        this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_1_2, MessageSeverity.error);
        return false;
      }

      //both option 1 & option 2 not filled up
      if (this.customFileNameForm.controls["customFileNaming"].valid == false
        && this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')) {
        this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_1_2, MessageSeverity.error);
        this.applyErrors(true, true);
        return false;
      }

      if (this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')
        && this.customFileNameForm.controls["customFileNaming"].valid == false) {
        this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_2, MessageSeverity.error);
        this.applyErrors(false, true);
        return false;
      }

      if (!this.fileNameForm.controls["fileNameOptions"].hasError('inValidFileOptions')
        && this.customFileNameForm.controls["customFileNaming"].valid == false
        && (this.isOption2CompletelyEmpty() == false && this.isOption2FilledCompletely() == false)) {
        this.alertService.showMessage("ERROR", Constants.transcodeorder_complete_option_2, MessageSeverity.error);
        this.applyErrors(false, true);
        return false;
      }

    }

    return true;
  }

  public isOption2FilledCompletely() {
    let isFilledCompletely = true;

    this.customFileNameForm.controls["customFileNaming"].value.forEach(t => {
      t.specFileNames.forEach(val => {
        if (val.customSpecFileName == null || val.customSpecFileName == '') {
          isFilledCompletely = false;
          return;
        }
      });

      if (!isFilledCompletely) {
        return;
      }
    });

    return isFilledCompletely;
  }

  public isOption2CompletelyEmpty() {
    let isCompletelyEmpty = true;

    this.customFileNameForm.controls["customFileNaming"].value.forEach(t => {
      t.specFileNames.forEach(val => {
        if (val.customSpecFileName != null && val.customSpecFileName != '') {
          isCompletelyEmpty = false;
          return;
        }
      });

      if (!isCompletelyEmpty) {
        return;
      }
    });

    return isCompletelyEmpty;
  }


  public onAddCustomField() {
    let selectedFileNameOptions = this.fileNameForm.value.fileNameOptions as Array<FileNameOption>;
    let options: Array<any> = [];

    selectedFileNameOptions.forEach((t, i) => {
      options.push(this._fb.group({
        name: t.name,
        isCustom: t.isCustom,
        selected: t.selected
      }));
    });

    options.push(this._fb.group({
      name: "custom_text",
      isCustom: true,
      selected: true
    }));

    this.fileNameForm.setControl('fileNameOptions', this._fb.array(options, Validators.compose([fileOptionsValidator])));

    this.setExampleFileName();

    this.applyErrors(false, false);
  }

  public onEditCustomName(index: number, event) {
    let selectedFileNameOptions = this.fileNameForm.value.fileNameOptions as Array<FileNameOption>;

    selectedFileNameOptions[index].name = event.target.value;

    this.fileNameForm.controls['fileNameOptions'].setValue(selectedFileNameOptions);

    this.setExampleFileName();
  }

  public onUp(index: number) {

    let selectedFileNameOptions = this.fileNameForm.value.fileNameOptions as Array<FileNameOption>;
    let options: Array<any> = [];

    selectedFileNameOptions.forEach((t, i) => {
      options.push(this._fb.group({
        name: t.name,
        isCustom: t.isCustom,
        selected: t.selected
      }));
    });

    options = this.move(options, index, index - 1);

    this.fileNameForm.setControl('fileNameOptions', this._fb.array(options, Validators.compose([fileOptionsValidator])));

    this.setExampleFileName();
  }

  public onDown(index: number) {
    let selectedFileNameOptions = this.fileNameForm.value.fileNameOptions as Array<FileNameOption>;
    let options: Array<any> = [];

    selectedFileNameOptions.forEach((t, i) => {
      options.push(this._fb.group({
        name: t.name,
        isCustom: t.isCustom,
        selected: t.selected
      }));
    });

    options = this.move(options, index, index + 1);

    this.fileNameForm.setControl('fileNameOptions', this._fb.array(options, Validators.compose([fileOptionsValidator])));
  }

  private move(array, from, to) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
  }

  ngOnDestroy() {




  }
}
