import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject ,  Subscription } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { Clients, NewProject } from '../../../models/project/create-project.model';
import { ProjectService } from '../../../services/project/create-project.service';

@Component({
  selector: 'create-new-project',
  templateUrl: './createnewprojectpopup.component.html',
  styleUrls: ['./createnewprojectpopup.component.css'],
})
export class CreateNewProjectPopUp implements OnInit, OnDestroy {

  onClose: Subject<boolean>;
  modalRef: BsModalRef;
  createProjectForm: FormGroup;
  clientDataSource: Clients[];
  project: NewProject;
  isClientReadOnly = false;
  isProjectCodeExist = false;
  subscriptions: Subscription[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private util: Utilities) {

    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.createProjectForm = this.fb.group({
      projectName: new FormControl('', Validators.required),
      projectCode: new FormControl('', Validators.required),
      clientId: new FormControl('', Validators.required),
    });
    this.getClients();
  }

  getClients() {
    this.alertService.ShowLoader(true);
    this.subscriptions.push(this.projectService.getProjectClients().subscribe((clients) => {
      this.clientDataSource = clients.result;
      if (clients.result.length === 1) {
        this.createProjectForm.patchValue({
          clientId: clients.result[0].id
        });
        this.isClientReadOnly = true;
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        if(this.util.handleError(error))
        {
          this.closePopup();
        };
      }));
  }

  onClientsChange(event) {
    if (event.value && event.previousValue && event.value !== event.previousValue) {
      const projectNameErrors = this.createProjectForm.controls['projectName'].errors;
      const projectCodeErrors = this.createProjectForm.controls['projectCode'].errors;
      if (projectNameErrors && projectNameErrors['duplicate']) {
        this.createProjectForm.controls['projectName'].setErrors(null);
      }
      if (projectCodeErrors && projectCodeErrors['duplicate']) {
        this.createProjectForm.controls['projectCode'].setErrors(null);
      }
    }
  }

  onCreateProjectFormSubmit() {
    if (this.createProjectForm.valid) {
      this.project = this.createProjectForm.value;
      this.project.clientName = this.clientDataSource.find(x => x.id === this.createProjectForm.value.clientId).name;

      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.projectService.saveProject(this.project).subscribe((res) => {

        this.alertService.ShowLoader(false);
        if (res.isProjectCodeExist) {
          this.createProjectForm.controls['projectCode'].setErrors({
            'duplicate': true
          })
        }
        else if (res.isProjectCodeExist == false) {
          this.createProjectForm.controls['projectName'].setErrors({
            'duplicate': true
          })
        }
        else {
          this.onClose.next(true);
          this.closePopup();
        }
      },
        error => {
          this.alertService.ShowLoader(false);
          if(this.util.handleError(error)){
            this.closePopup();
          };
        }));
    }
  }

  closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
