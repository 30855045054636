import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { VaultAssetNote, Vaultassetnotes } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { VaultService } from '../../../services/vault/vault.service';

@Component({
  selector: 'app-add-vault-asset-note',
  templateUrl: './add-vault-asset-note.component.html',
  styleUrls: ['./add-vault-asset-note.component.css']
})
export class AddVaultAssetNoteComponent implements OnInit {

  @Input('vaultassetid') vaultAssetId: number = null;
  @Input('vaultRequestId') vaultRequestId: number = null;
  @Input('vaultDigitalAssetLineItemId') vaultDigitalAssetLineItemId: number = null;

  public vaultAssetNoteRequest: FormGroup;
  public onSaveVaultAssetNote: Subject<Vaultassetnotes>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private vaultService: VaultService,
    private fb: FormBuilder) {
    this.onSaveVaultAssetNote = new Subject<Vaultassetnotes>();
  }

  ngOnInit() {
    this.vaultAssetNoteRequest = this.fb.group({
      note: new FormControl(null, Validators.required),
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSubmitVaultAssetNote({ value, valid }: { value: VaultAssetNote, valid: boolean }) {
    value.vaultAssetId = this.vaultAssetId;
    value.vaultRequestId = this.vaultRequestId;
    value.vaultDigitalAssetLineItemId = this.vaultDigitalAssetLineItemId;

    console.log(value);

    if (!valid) {
      return;
    }

    this.alertService.ShowLoader(true);

    this.vaultService.addVaultAssetNote(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var vaultAssetNote = res.result as Vaultassetnotes
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_add_vault_asset_note, MessageSeverity.success, true);
        this.onSaveVaultAssetNote.next(vaultAssetNote);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.failed_add_vault_asset_note, MessageSeverity.error, true);
      }
    });
  }

}
