<form [formGroup]="updateOrderCarrierRequest" #carrierForm="ngForm">
  <p class="tylie-type-body mb-3">Edit Carrier</p>

  <div class="tylie-alert tylie-alert--error my-4">
    The selected carrier will be applied to all spots going to the destination:
    {{destinationCode}}, If that is not intended, please uncheck the below check box.
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="tylie-form-group form-check service-to-all-media">
        <input class="form-check-input" type="checkbox" formControlName="applyToAllDestinations" name="applyToAllDestinations" id="applyToAllDestinations">
        <label class="form-check-label"  for="applyToAllDestinations">Apply Selected Carrier to Spots:  <span class="p-extra-small gray1">({{adids}})</span></label>
      </div>
    </div>
  </div>
  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderCarrierRequest.controls['vendorId'].errors && carrierForm.submitted}">
    <label class="tylie-form-group__lbl" for="name">Carrier<span class="required">*</span></label>
    <dx-select-box [dataSource]="carrierDataSource"
                  name="vendorId" id="vendorId" class="tylie-select form-control"
                  formControlName="vendorId"
                  valueExpr="id"
                  [showClearButton]="true"
                  displayExpr="name"
                  placeholder="Select a carrier...">
    </dx-select-box>
    <p class="p-extra-small">Please select a carrier</p>
  </div>
  <div class="d-flex justify-content-end">
    <button class="tylie-button tylie-button--xs me-3" type="submit" (click)="onFormSubmit(updateOrderCarrierRequest)">Save</button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
  </div>
</form>
