<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header media-text">
      <h3 class="media-text">Download</h3>
    </div>
   
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li (click)="gotoMedia()">
          <a class="back-to-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.37 108.37">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M54.19,0a54.19,54.19,0,1,0,54.18,54.18A54.19,54.19,0,0,0,54.19,0Zm6,82.36L32.51,54.76,58.42,28.84l6.8,6.86-19,19L67,75.62Z" />
                </g>
              </g>
            </svg>
            <span class="back_to_nav">Back to All Distribution Assets</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="inner-content">
  <div class="panel panel-default" id="media-download">
    <div class="panel-collapse">
      <div class="panel-body" *ngIf="anyActiveDownloads">
        <h4 class="media-upload-text">
          <b>Active Downloads</b>
          <span class="action" (click)="clearCompleted()">Clear Completed</span>
        </h4>
        <div class="group-progress" *ngFor="let aFS of downloadFileStatus">
          <ng-container *ngIf="aFS.show">
            <p class="p-normal client-up-name">Started at {{aFS.header}}</p>
            <div class="progress-container" *ngFor="let f of aFS.dowloadStatus">
              <ng-container *ngIf="f.show">
                <div class="progress progress-left">
                  <div class="progress-bar" role="progressbar"
                       [attr.aria-valuenow]="f.percentage"
                       aria-valuemin="0"
                       aria-valuemax="100"
                       [style.width]="(f.percentage + '%')">
                  </div>
                </div>
                <div class="progress-right">
                  <p>{{downloadStatusEnum[f.status].toString()}}</p>
                  <p>{{f.percentage}}%</p>
                </div>
                <div class=" progress-download-right">
                  <i class="glyphicon glyphicon-upload" *ngIf="f.status != downloadStatusEnum.completed"
                     [ngClass]="{submitted: f.status == downloadStatusEnum.submitted, processing: f.status == downloadStatusEnum.downloading, '': f.status == downloadStatusEnum.failed}">
                  </i>
                  <i *ngIf="f.status == downloadStatusEnum.completed">
                    <svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <style type="text/css">
                      .st0 {
                        fill: #59bc25;
                      }
                      .st1 {
                        fill: #fff;
                      }
                      </style><g><circle class="st0" cx="64" cy="64" r="64"></circle></g><g><path class="st1" d="M54.3,97.2L24.8,67.7c-0.4-0.4-0.4-1,0-1.4l8.5-8.5c0.4-0.4,1-0.4,1.4,0L55,78.1l38.2-38.2   c0.4-0.4,1-0.4,1.4,0l8.5,8.5c0.4,0.4,0.4,1,0,1.4L55.7,97.2C55.3,97.6,54.7,97.6,54.3,97.2z"></path></g>
                    </svg>
                  </i>
                </div>
                <div class="file-name">
                  <p class="name">{{f.adId}} - {{f.title}}</p>
                  <p class="error" *ngIf="f.error && f.error.length > 0">{{f.error}}</p>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="panel-body" *ngIf="!anyActiveDownloads">
        <h4 class="media-upload-text">
          <b>No Active Downloads</b>
        </h4>
      </div>
      <div class="panel-footer"></div>
    </div>
  </div>
</div>
