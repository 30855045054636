import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import {
  DxDataGridComponent, DxDataGridModule, DxSelectBoxModule,
  DxSelectBoxComponent, DxDropDownBoxModule, DxTreeViewModule, DxTreeViewComponent,
} from "devextreme-angular";
import { ConfigService } from "../../../../services/core/config.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BrandSearchComponent } from "../../../popups/brandsearch/brandsearch.component";
import { ClientBrandSelectionModel, BrandSelectionResponse, CostCenterAndBillToObjects, CostCenter, BillTo, CreateClientBrandProduct, ClientBrandVendorInfoObjects } from "../../../../models/admin/client/createclient.model";
import { Constants } from "../../../../configurations/constants";
import { AddCostCenterAndBillToPopUp } from "../../../popups/addcostcenterandbilltopopup/addcostcenterandbilltopopup.component";
import { AddClientBrandVendorInfoPopUp } from "../../../popups/addclientbrandvendorinfopopup/addclientbrandvendorinfopopup.component";
import { AddClientBrandProductPopUp } from "../../../popups/addclientbrandproductpopup/addclientbrandproductpopup.component";
import { ProductModel } from "../../../../models/admin/brand/createproduct.model";
import { confirmEventType } from "../../../../configurations/enums/enums";
import { ConfirmPopupComponent } from "../../../popups/confirmationpopups/confirmpopup.component";
import { CustomTagConfig, CreateCustomTagConfig } from '../../../../models/admin/client/customtagconfig.model';
import { AddCustomTagConfigurationPopUp } from '../../../popups/addcustomtagconfiguration/addcustomtagconfiguration.component';
import { EditClientConfig } from '../../../../models/admin/client/editclient.model';
import { AssetShareBillingSettings, ManagementFeeSettings, StorageManagementFeeSettings, TranscodeBillingSettings } from '../../../../models/admin/client/billingsettings.model';
import { AddTranscodeBillingSettingsPopUp } from '../../../popups/addtranscodebillingsettings/addtranscodebillingsettings.component';
import { AddManagementFeeSettingsettingsPopUp } from '../../../popups/addmanagementfeesettings/addmanagementfeesettings.component';
import { AddStorageManagementFeeSettingsettingsPopUp } from '../../../popups/addstoragemanagementfeesettings/addstoragemanagementfeesettings.component';
import { AddAssetShareBillingSettingsPopupComponent } from '../../../popups/add-asset-share-billing-settings-popup/add-asset-share-billing-settings-popup.component';

@Component({
  selector: 'selected-brands',
  templateUrl: './selected-brands.component.html',
  styleUrls: ['./selected-brands.component.css']
})
export class SelectedBrandsComponent implements OnInit {

  @Input('allowEdits') allowEdits: boolean = false;
  @Input('isClientView') isClientView: boolean = false;
  @Input('clientBrands') clientBrands: ClientBrandSelectionModel[] = null;
  @Input('isClientEdit') isClientEdit: boolean = false;
  @Input('editConfig') editConfig: EditClientConfig = null;
  @Input('createConfig') createConfig: any = null;
  @Input('showTranscodeBillingSettings') showTranscodeBillingSettings: boolean = false;

  @ViewChild('brandStoreGrid', { static: false }) private brandStoreGrid: DxDataGridComponent;

  public productValue: string[];
  public destHubValue: string[];
  public TrafficEmailInPODDisplayedValue: string[];
  public selectedBrandsResult: number[] = [];
  public brandStore: ClientBrandSelectionModel[] = null;
  public deletedBrandStore: ClientBrandSelectionModel[] = [];
  public preferredVendorsDataSource: any;
  public selectedBrands: ClientBrandSelectionModel[] = [];
  public approvalLevelsDataSource: any[] = Constants.approvalLvlDataSource;
  public cutDownServicesDataSource: any[] = [];
  public reslateBundleServicesDataSource: any[] = [];
  public noOfCustomTagConfigurationsPerClient: number;

  public modalRef: BsModalRef;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private modalService: BsModalService) {

  }

  ngOnInit() {
    if (this.allowEdits == true || this.isClientEdit == true) {
      this.getConfig();
    }

    if (this.util.notEmpty(this.clientBrands)) {
      this.brandStore = this.clientBrands;
    }
  }

  public getBrandSelection(selectedBrandsResult: number[]) {
    return this.adminService.getBrandSelection(selectedBrandsResult)
      .toPromise()
      .then((response: any) => {
        if (response.isSuccess == true) {
          var brandsRes = response.result as BrandSelectionResponse;
          console.log(brandsRes);
          brandsRes.selectedClientBrands.forEach(b => {
            if (this.brandStore != null && this.brandStore != undefined) {
              this.brandStore.push(b);
            }
            else {
              this.brandStore = [];
              this.brandStore.push(b);
            }
          });
        }
        else {
          this.util.handleIsNotSuccess(response.errors);
          throw 'Data Loading Error';
        }
      })
      .catch(error => {
        this.util.handleError(error);
        throw 'Data Loading Error';
      });
  }

  public onAddBrands() {
    var initialState = {
    };

    this.modalRef = this.modalService.show(BrandSearchComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      var recentlySelectedList = this.onBrandSearchClose(result);
      if (recentlySelectedList != null && recentlySelectedList != undefined && recentlySelectedList.length > 0) {
        this.getBrandSelection(recentlySelectedList);
      }
    });
  }

  public getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getBrandSelectionConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.preferredVendorsDataSource = res.result.vendors;
        this.cutDownServicesDataSource = res.result.specialServices;
        this.reslateBundleServicesDataSource = res.result.reslateBundleServices;
        this.noOfCustomTagConfigurationsPerClient = res.result.noOfCustomTagConfigurationsPerClient;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onSelectionChangedApprovalLvl(e: any, brandId: number) {
    var item = this.updateDataSourceonRowEdit(brandId);
    item[0].approvalLevel = e.selectedItem.id
  }

  public onSelectionChangedPreferredVendor(e: any, brandId: number) {
    var item = this.updateDataSourceonRowEdit(brandId);
    if (e.selectedItem != null) {
      item[0].preferredNetwork = e.selectedItem.id
    }
    else {
      item[0].preferredNetwork = 0;
    }
  }

  public onValueChanged_destHub(e, brandId: number) {
    this.destHubValue = e.value;
    var item = this.updateDataSourceonRowEdit(brandId);
    item[0].isDestinationHubbed = e.value;
  }

  public onValueChanged_TrafficEmailInPODDisplayed(e, brandId: number) {
    this.TrafficEmailInPODDisplayedValue = e.value;
    var item = this.updateDataSourceonRowEdit(brandId);
    item[0].isTrafficEmailInPODDisplayed = e.value;
  }

  public getSelectedBrands(): ClientBrandSelectionModel[] {
    if (this.brandStore == null || this.brandStore == undefined) {
      this.brandStore = [];
    }
    return this.brandStore;
  }

  public getRemovedBrands(): ClientBrandSelectionModel[] {
    if (this.deletedBrandStore == null || this.deletedBrandStore == undefined) {
      this.deletedBrandStore = [];
    }
    return this.deletedBrandStore;
  }

  public onBrandStoreDelete(data: ClientBrandSelectionModel) {
    if (this.util.notEmpty(data) && data.id > 0) {
      var initialState = {
        eventType: confirmEventType[confirmEventType.DeleteClientBrand]
      };

      this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
      this.modalRef.content.onClose.subscribe(result => {
        if (result == true) {

          this.brandStore = this.brandStore.filter(p => p.brandId != data.brandId);
          this.selectedBrandsResult = this.selectedBrandsResult.filter(p => p != data.brandId);
          if (this.brandStore.length == 0) {
            this.brandStore = null;
          }
          this.deletedBrandStore.push(data);
        }
      });
    }
    else {
      this.brandStore = this.brandStore.filter(p => p.brandId != data.brandId);
      this.selectedBrandsResult = this.selectedBrandsResult.filter(p => p != data.brandId);
      if (this.brandStore.length == 0) {
        this.brandStore = null;
      }
    }
  }

  public clearBrandSelection() {
    this.brandStore = null;
  }

  public addBillToAndCostCenter(data: any) {
    var costCenters: string[] = [];
    var billTos: string[] = [];

    var initialState = {
      costCenterList: data.costCenters,
      billToList: data.billTos,
      brandName: data.brandName,
      cutDownServicesDataSource: this.cutDownServicesDataSource,
      cutDownServicesOfferedIds: data.cutDownServicesOfferedIds,
      reslateBundleServicesDataSource: this.reslateBundleServicesDataSource,
      reslateBundleServicesIds: data.reslateBundleServicesOfferedIds,
      podDistributionEmails: data.podDistributionEmails,
      stdStorageDays: data.stdStorageDays,
      infrqStorageDays: data.infrqStorageDays,
      archiveDurationInDays: data.archiveDurationInDays,
      activeTandemStorageInDays: data.activeTandemStorageInDays,
      isWCPFileDeliveryBilled: data.isWCPFileDeliveryBilled,
      opsNotes: data.opsNotes,
      editConfig: this.editConfig,
      allowEdits: this.allowEdits,
      isClientEdit: this.isClientEdit
    };

    this.modalRef = this.modalService.show(AddCostCenterAndBillToPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.oncostCenterAndBillToAddEdit.subscribe((result: CostCenterAndBillToObjects) => {
      console.log(result);
      if (result != null && result != undefined) {
        result.costCenters = this.util.notEmpty(result.costCenters) ? result.costCenters.filter((cc): cc is CostCenter => cc != null) : [];
        result.billTos = this.util.notEmpty(result.billTos) ? result.billTos.filter((bt): bt is BillTo => bt != null) : [];

        result.costCenters.forEach(cc => {
          if (this.util.notEmpty(cc.costCenter)) {
            if (costCenters.indexOf(cc.costCenter) === -1) {
              costCenters.push(cc.costCenter)
            }
          }
        });
        result.billTos.forEach(bt => {
          if (this.util.notEmpty(bt.billTo)) {
            if (billTos.indexOf(bt.billTo) === -1) {
              billTos.push(bt.billTo)
            }
          }
        });

        if (result.cutDownServices != null && result.cutDownServices.length > 0) {
          data.cutDownServicesOfferedIds = result.cutDownServices;
          var specialSrvNames: string[] = [];
          result.cutDownServices.forEach(id => {
            var service = this.cutDownServicesDataSource.find(sd => sd.id == id);
            if (service != null && service != undefined) {
              specialSrvNames.push(service.name);
            }
          });

          data.cutDownServicesOffered = specialSrvNames.join(", ");
        }
        else {
          data.cutDownServicesOfferedIds = [];
          data.cutDownServicesOffered = null;
        }

        var reslateBundleServicesCode: string[] = [];

        if (result.reslatebundleServices != null && result.reslatebundleServices.length > 0) {
          data.reslateBundleServicesOfferedIds = result.reslatebundleServices;
          var specialSrvNames: string[] = [];
          result.reslatebundleServices.forEach(id => {
            var service = this.reslateBundleServicesDataSource.find(sd => sd.id == id);
            if (service != null && service != undefined) {
              specialSrvNames.push(service.name);
              reslateBundleServicesCode.push(service.name);
            }
          });

          data.reslatebundleServicesOffered = specialSrvNames.join(", ");

          if (data.bundleServices.length > 0) {
            data.bundleServices.forEach(bserv => {
              if (bserv.bundleServiceCode == 'SLT') {
                bserv.serviceNames = data.reslatebundleServicesOffered;
              }
            });
          }
          else {
            data.bundleServices.push({
              bundleServiceCode: 'SLT',
              bundleServiceName: 'Slate/Reslate',
              serviceNames: data.reslatebundleServicesOffered,
              serviceCodes: reslateBundleServicesCode
            });
          }
        }
        else {
          data.reslateBundleServicesOfferedIds = [];
          data.reslatebundleServicesOffered = null;

          if (data.bundleServices.length > 0) {
            data.bundleServices = data.bundleServices.filter(bserv => {
              bserv.bundleServiceCode != 'SLT'
              bserv.serviceNames = null;
            });
          }
        }

        if (result.podEmails != null && result.podEmails != undefined) {
          var podEmails = result.podEmails.split(";");
          data.podDistributionEmails = podEmails.join('; ');
        }
        else {
          data.podDistributionEmails = '';
        }

        if (result.stdStorageDays != null && result.stdStorageDays != undefined) {
          data.stdStorageDays = result.stdStorageDays;
        }
        else {
          data.stdStorageDays = '';
        }

        if (result.infrqStorageDays != null && result.infrqStorageDays != undefined) {
          data.infrqStorageDays = result.infrqStorageDays;
        }
        else {
          data.infrqStorageDays = '';
        }

        if (result.archiveDurationInDays != null && result.archiveDurationInDays != undefined) {
          data.archiveDurationInDays = result.archiveDurationInDays;
        }
        else {
          data.archiveDurationInDays = '';
        }
        if (result.activeTandemStorageInDays != null && result.activeTandemStorageInDays != undefined) {
          data.activeTandemStorageInDays = result.activeTandemStorageInDays;
        }
        else {
          data.activeTandemStorageInDays = '';
        }
      }

      data.costCenters = costCenters;
      data.billTos = billTos;
      data.costCenter = costCenters.join('; ');
      data.billTo = billTos.join('; ');
      data.isWCPFileDeliveryBilled = result.isWCPFileDeliveryBilled;
      data.opsNotes = result.opsNotes;
    });
  }

  public editVendorPreference(data: any) {
    var initialState = {
      brandName: data.brandName,
      pvendorDataSource: this.preferredVendorsDataSource,
      evendorDataSource: this.preferredVendorsDataSource,
      preferredVendorId: data.preferredNetwork,
      excludedVendorIds: data.excludedNetworkList,
    };
    this.modalRef = this.modalService.show(AddClientBrandVendorInfoPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onClientBrandVendorInfoAddEdit.subscribe((result: ClientBrandVendorInfoObjects) => {
      if (result != null && result != undefined) {

        if (result.excludeNetworkVendor != null && result.excludeNetworkVendor.length > 0) {
          data.excludedNetworkList = result.excludeNetworkVendor;
          var eVendorNames: string[] = [];
          result.excludeNetworkVendor.forEach(id => {
            var vendor = this.preferredVendorsDataSource.find(sd => sd.id == id);
            if (vendor != null && vendor != undefined) {
              eVendorNames.push(vendor.name);
            }
          });

          data.excludedNetwork = eVendorNames.join("; ");
        }
        else {
          data.excludedNetworkList = [];
          data.excludedNetwork = null;

        }

        if (result.preferredNetworkVendor != null && result.preferredNetworkVendor.length > 0) {
          data.preferredNetwork = result.preferredNetworkVendor[0];

          var vendor = this.preferredVendorsDataSource.find(sd => sd.id == data.preferredNetwork);
          if (vendor != null && vendor != undefined) {
            data.preferredNetworkName = vendor.name;
          }
        }
        else if (result.preferredNetworkVendor != null) {
          data.preferredNetwork = result.preferredNetworkVendor;

          var vendor = this.preferredVendorsDataSource.find(sd => sd.id == data.preferredNetwork);
          if (vendor != null && vendor != undefined) {
            data.preferredNetworkName = vendor.name;
          }
        }
        else {
          data.preferredNetwork = null;
          data.preferredNetworkName = null;

        }
      }
    });
  }

  public addClientBrandProduct(data: any) {
    var selectedClientBrandProducts: ProductModel[] = [];

    var initialState = {
      brandId: data.brandId,
      clientBrandProducts: data.products,
      editConfig: this.editConfig,
      allowEdits: this.allowEdits,
      isClientEdit: this.isClientEdit
    };

    this.modalRef = this.modalService.show(AddClientBrandProductPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onClientBrandProductAddEdit.subscribe((result: CreateClientBrandProduct) => {
      console.log(result);
      if (this.util.notEmpty(result)) {
        var products = result.clientBrandProductGroups;
        products.forEach(p => {
          var costCenters: string[] = [];
          var billTos: string[] = [];
          p.costCenter = this.util.notEmpty(p.costCenter) ? p.costCenter.filter((cc): cc is CostCenter => cc != null) : [];
          p.inVoiceTo = this.util.notEmpty(p.inVoiceTo) ? p.inVoiceTo.filter((bt): bt is BillTo => bt != null) : [];

          p.costCenter.forEach(cc => {
            if (this.util.notEmpty(cc.costCenter)) {
              if (costCenters.indexOf(cc.costCenter) === -1) {
                costCenters.push(cc.costCenter)
              }
            }
          });

          p.inVoiceTo.forEach(bt => {
            if (this.util.notEmpty(bt.billTo)) {
              if (billTos.indexOf(bt.billTo) === -1) {
                billTos.push(bt.billTo)
              }
            }
          });

          var productModel = new ProductModel();
          productModel.id = p.id;
          productModel.name = p.name;
          productModel.clientBrandProductId = p.clientBrandProductId;
          productModel.costCenter = costCenters;
          productModel.inVoiceTo = billTos;
          productModel.costCenterDisplay = costCenters.join('; ');
          productModel.billToDisplay = billTos.join('; ');
          productModel.isTrafficEmailInPODDisplayed = p.isTrafficEmailInPODDisplayed;
          if (p.podEmails != null && p.podEmails != undefined) {
            var podEmails = p.podEmails.split(";");
            productModel.podDistributionEmails = podEmails.join('; ');
          }
          else {
            productModel.podDistributionEmails = '';
          }

          if (p.stdStorageDays != null && p.stdStorageDays != undefined) {
            productModel.stdStorageDays = p.stdStorageDays;
          }
          else {
            productModel.stdStorageDays = '';
          }

          if (p.infrqStorageDays != null && p.infrqStorageDays != undefined) {
            productModel.infrqStorageDays = p.infrqStorageDays;
          }
          else {
            productModel.infrqStorageDays = '';
          }

          if (p.archiveDurationInDays != null && p.archiveDurationInDays != undefined) {
            productModel.archiveDurationInDays = p.archiveDurationInDays;
          }
          else {
            productModel.archiveDurationInDays = '';
          }

          if (p.activeTandemStorageInDays != null && p.activeTandemStorageInDays != undefined) {
            productModel.activeTandemStorageInDays = p.activeTandemStorageInDays;
          }
          else {
            productModel.activeTandemStorageInDays = '';
          }


          selectedClientBrandProducts.push(productModel);
        });

        data.products = selectedClientBrandProducts;
      }
    });

    this.modalRef.content.onClientBrandProductDelete.subscribe((result: number) => {
      if (this.util.notEmpty(result) && result > 0) {
        if (data.deletedProducts == null || data.deletedProducts == undefined) {
          data.deletedProducts = [];
          var deletedResult: any[] = data.products.filter(p => p.clientBrandProductId == result);
          deletedResult.forEach(r => {
            data.deletedProducts.push(r);
          });
        }
        else {
          var deletedResult: any[] = data.products.filter(p => p.clientBrandProductId == result);
          deletedResult.forEach(r => {
            data.deletedProducts.push(r);
          });
        }

        data.products = data.products.filter(p => p.clientBrandProductId != result);
      }
    });
  }

  public applyCustomTagsToAllSelectedBrands(customTagConfig: CustomTagConfig[], selectedBrandsForCustomTags: number[]) {
    var selectedBrands = this.brandStore;
    if (selectedBrands != null && selectedBrands != undefined) {
      selectedBrands = selectedBrands.filter(sb => selectedBrandsForCustomTags.includes(sb.brandId));
      selectedBrands.forEach(brand => {
        if ((brand.customTagConfigurations.length + customTagConfig.length) > this.noOfCustomTagConfigurationsPerClient) {
          // add error with brand name
        }
        customTagConfig.forEach(ct => {
          var item = this.updateDataSourceonRowEdit(brand.brandId);
          brand.customTagConfigurations.push(ct);
          item[0].customTagConfigurations = brand.customTagConfigurations;
          var displayCustomTags = [];
          item[0].customTagConfigurations.forEach(ct => {
            if (ct.isActive) {
              displayCustomTags.push(`${ct.tag} (${ct.displayDataType})`);
            }
          });

          item[0].displayCustomConfigurations = displayCustomTags.join(', ');

          this.brandStoreGrid.instance.refresh();
          // }
        });
      });
    }
  }

  public applyTranscodeBillingSettingsToAllSelectedBrands(settings: TranscodeBillingSettings, selectedBrandsTranscodeBillingSettings: any) {
    var selectedBrands = this.brandStore;
    if (selectedBrands != null && selectedBrands != undefined) {
      selectedBrands = selectedBrands.filter(sb => selectedBrandsTranscodeBillingSettings.includes(sb.brandId));
      selectedBrands.forEach(brand => {
        var item = this.updateDataSourceonRowEdit(brand.brandId);
        item[0].transcodeBillingSettings = settings;
        this.brandStoreGrid.instance.refresh();
      });
    }
  }

  public removeTranscodeBillingSettingsOnAllBrands() {
    var selectedBrands = this.getSelectedBrands();

    selectedBrands.forEach(brand => {
      var item = this.updateDataSourceonRowEdit(brand.brandId);
      item[0].transcodeBillingSettings = null;
      this.brandStoreGrid.instance.refresh();
    });
  }

  public applyAssetShareBillingSettingsToAllSelectedBrands(settings: AssetShareBillingSettings, selectedBrandsAssetShareBillingSettings: any) {
    var selectedBrands = this.brandStore;
    if (selectedBrands != null && selectedBrands != undefined) {
      selectedBrands = selectedBrands.filter(sb => selectedBrandsAssetShareBillingSettings.includes(sb.brandId));
      selectedBrands.forEach(brand => {
        var item = this.updateDataSourceonRowEdit(brand.brandId);
        item[0].assetShareBillingSettings = settings;
        this.brandStoreGrid.instance.refresh();
      });
    }
  }

  public removeAssetShareBillingSettingsOnAllBrands() {
    var selectedBrands = this.getSelectedBrands();

    selectedBrands.forEach(brand => {
      var item = this.updateDataSourceonRowEdit(brand.brandId);
      item[0].assetShareBillingSettings = null;
      this.brandStoreGrid.instance.refresh();
    });
  }

  public onAddEditTranscodeBillingSettings(data: any) {
    var initialState = {
      brandName: data.brandName,
      brandId: data.brandId,
      brandTranscodeSettings: data.transcodeBillingSettings,
      isBrandsNotSelected: false,
      transcodeBillingTypes: this.isClientEdit == true ? this.editConfig.transcodeBillingTypes : this.createConfig.transcodeBillingTypes,
      billingFrequency: this.isClientEdit == true ? this.editConfig.billingFrequency : this.createConfig.billingFrequency,
      flatTranscodeTiers: this.isClientEdit == true ? this.editConfig.flatTranscodeTiers : this.createConfig.flatTranscodeTiers,
      flatTranscodeBillingFrequency: this.isClientEdit == true ? this.editConfig.flatTranscodeBillingFrequency : this.createConfig.flatTranscodeBillingFrequency
    };

    this.modalRef = this.modalService.show(AddTranscodeBillingSettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveTranscodeBillingSettings.subscribe((result: TranscodeBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        data.transcodeBillingSettings = result;
      }
    });
  }

  public onRemoveTranscodeBillingSettings(data: any) {
    data.transcodeBillingSettings = null;
  }

  public onAddEditAssetShareBillingSettings(data: any) {

    var initialState = {
      brandName: data.brandName,
      brandId: data.brandId,
      brandTranscodeSettings: data.assetShareBillingSettings,
      isBrandsNotSelected: false,
      transcodeBillingTypes: this.isClientEdit == true ? this.editConfig.transcodeBillingTypes : this.createConfig.transcodeBillingTypes,
      billingFrequency: this.isClientEdit == true ? this.editConfig.billingFrequency : this.createConfig.billingFrequency,
      flatTranscodeTiers: this.isClientEdit == true ? this.editConfig.flatTranscodeTiers : this.createConfig.flatTranscodeTiers,
      flatTranscodeBillingFrequency: this.isClientEdit == true ? this.editConfig.flatTranscodeBillingFrequency : this.createConfig.flatTranscodeBillingFrequency
    };

    this.modalRef = this.modalService.show(AddAssetShareBillingSettingsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveAssetShareBillingSettings.subscribe((result: TranscodeBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        data.assetShareBillingSettings = result;
      }
    });
  }

  public onRemoveAssetShareBillingSettings(data: any) {
    data.assetShareBillingSettings = null;
  }

  public applyManagementFeeSettingsToAllSelectedBrands(settings: ManagementFeeSettings, selectedBrandsForManagementFee: any) {
    var selectedBrands = this.brandStore;
    if (selectedBrands != null && selectedBrands != undefined) {
      selectedBrands = selectedBrands.filter(sb => selectedBrandsForManagementFee.includes(sb.brandId));
      selectedBrands.forEach(brand => {
        var item = this.updateDataSourceonRowEdit(brand.brandId);
        item[0].managementFeeSettings = settings;
        this.brandStoreGrid.instance.refresh();
      });
    }
  }

  public removeManagementFeeSettingsOnAllBrands() {
    var selectedBrands = this.getSelectedBrands();

    selectedBrands.forEach(brand => {
      var item = this.updateDataSourceonRowEdit(brand.brandId);
      item[0].managementFeeSettings = null;
      this.brandStoreGrid.instance.refresh();
    });
  }

  public onAddEditManagementFeeSettings(data: any) {
    var initialState = {
      brandName: data.brandName,
      brandId: data.brandId,
      brandManagementFeeSettings: data.managementFeeSettings,
      isBrandsNotSelected: false,
      billingFrequency: this.isClientEdit == true ? this.editConfig.managementFeeBillingFrequency : this.createConfig.managementFeeBillingFrequency,
    };

    this.modalRef = this.modalService.show(AddManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveManagementFeeSettings.subscribe((result: ManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        data.managementFeeSettings = result;
      }
    });
  }

  public onRemoveManagementSettings(data: any) {
    data.managementFeeSettings = null;
  }


  public applyStorageManagementFeeSettingsToAllSelectedBrands(settings: StorageManagementFeeSettings, selectedBrandsForStorageManagementFee: any) {
    var selectedBrands = this.brandStore;
    if (selectedBrands != null && selectedBrands != undefined) {
      selectedBrands = selectedBrands.filter(sb => selectedBrandsForStorageManagementFee.includes(sb.brandId));
      selectedBrands.forEach(brand => {
        var item = this.updateDataSourceonRowEdit(brand.brandId);
        item[0].storageManagementFeeSettings = settings;
        this.brandStoreGrid.instance.refresh();
      });
    }
  }

  public removeStorageManagementFeeSettingsOnAllBrands() {
    var selectedBrands = this.getSelectedBrands();

    selectedBrands.forEach(brand => {
      var item = this.updateDataSourceonRowEdit(brand.brandId);
      item[0].storageManagementFeeSettings = null;
      this.brandStoreGrid.instance.refresh();
    });
  }

  public onAddEditStorageManagementFeeSettings(data: any) {
    var initialState = {
      brandName: data.brandName,
      brandId: data.brandId,
      brandStorageManagementFeeSettings: data.storageManagementFeeSettings,
      isBrandsNotSelected: false,
      billingFrequency: this.isClientEdit == true ? this.editConfig.managementFeeBillingFrequency : this.createConfig.managementFeeBillingFrequency,
    };

    this.modalRef = this.modalService.show(AddStorageManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveStorageManagementFeeSettings.subscribe((result: StorageManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        data.storageManagementFeeSettings = result;
      }
    });
  }

  public onRemoveStorageManagementSettings(data: any) {
    data.storageManagementFeeSettings = null;
  }

  public onAddEditCustomTags(data: any) {
    var activeCustomTags = [];

    data.customTagConfigurations.forEach(ct => {
      if (ct.isActive) {
        activeCustomTags.push(ct);
      }
    });

    var initialState = {
      brandName: data.brandName,
      brandCustomTags: activeCustomTags,
      isBrandsNotSelected: false
    };

    this.modalRef = this.modalService.show(AddCustomTagConfigurationPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSaveCustomTagConfiguration.subscribe((result: CreateCustomTagConfig) => {
      if (result != null && result != undefined) {
        if (result.customTagConfigurations != null && result.customTagConfigurations != undefined) {
          console.log(result);
          data.customTagConfigurations = result.customTagConfigurations;

          if (result.inActiveCustomTagConfig != null && result.inActiveCustomTagConfig != undefined && result.inActiveCustomTagConfig.length > 0) {
            result.inActiveCustomTagConfig.forEach(iat => {
              iat.isActive = false;
              data.customTagConfigurations.push(iat);
            });
          }

          var displayCustomTags = [];
          data.customTagConfigurations.forEach(ct => {
            if (ct.isActive) {
              displayCustomTags.push(`${ct.tag} (${ct.displayDataType})`);
            }
          });

          data.displayCustomConfigurations = displayCustomTags.join(', '); // move to common method
        }
      }
    });
  }

  private onBrandSearchClose(result: any) {
    if (result != null && result != undefined) {
      // remove selectedbrandsresult use only brandstore
      console.log(result);
      console.log(this.brandStore);
      var recentlySelectedList: number[] = [];
      result.forEach(r => {
        if (this.selectedBrandsResult.includes(r) == false && (this.brandStore == null || this.brandStore == undefined || this.brandStore.find(b => b.brandId == r) === undefined)) {
          this.selectedBrandsResult.push(r);
          recentlySelectedList.push(r);
        }
      });
    }
    return recentlySelectedList;
  }

  private updateDataSourceonRowEdit(brandId: number) {
    //var datasource = this.brandStoreGrid.instance.getDataSource();
    //var items = datasource.items();
    //var item = items.filter(i => i.brandId == brandId);
    //return item;

    var item = this.brandStore.filter(i => i.brandId == brandId);
    return item;
  }
}
