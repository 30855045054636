import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { Subject ,  Observable } from "rxjs";
import { LocalStoreManager } from "../core/local-store-manager.service";
import { DBkeys } from "../../configurations/db-Keys";
import { UserProfileService } from "../user/user-profile.service";
import { UserDataService } from "../user/user-data.service";
import { Utilities } from "../core/utilities";

@Injectable()
export class SubService {

  constructor(private localStorage: LocalStoreManager,
    private userProfileService: UserProfileService,
    private userData: UserDataService,
    public util: Utilities) { }

  @Output() avatarChange = new EventEmitter();
  @Output() profileChange = new EventEmitter();

  private refreshPlayListSearchMethodCallSource = new Subject<any>();
  public refreshPlayListSearchMethodCalled = this.refreshPlayListSearchMethodCallSource.asObservable();
  public callrefreshPlayListSearchMethod() {
    this.refreshPlayListSearchMethodCallSource.next();
  }

  private refreshPlayListRecentlyViewedMethodCallSource = new Subject<any>();
  public refreshPlayListRecentlyViewedMethodCalled = this.refreshPlayListRecentlyViewedMethodCallSource.asObservable();
  public callrefreshPlayListRecentlyViewedMethod() {
    this.refreshPlayListRecentlyViewedMethodCallSource.next();
  }

  private refreshPlayListMediaApprovalMethodCallSource = new Subject<any>();
  public refreshPlayListMediaApprovalMethodCalled = this.refreshPlayListMediaApprovalMethodCallSource.asObservable();
  public callrefreshPlayListMediaApprovalMethod() {
    this.refreshPlayListMediaApprovalMethodCallSource.next();
  }

  private refreshQCSpotsBacktoMediaCallSource = new Subject<any>();
  public refreshQCSpotsBacktoMediaMethodCalled = this.refreshQCSpotsBacktoMediaCallSource.asObservable();
  public callLoadQCGrid() {
    this.refreshQCSpotsBacktoMediaCallSource.next();
  }

  private refreshApprovalSpotsBacktoMediaCallSource = new Subject<any>();
  public refreshApprovalSpotsBacktoMediaMethodCalled = this.refreshApprovalSpotsBacktoMediaCallSource.asObservable();
  public callGetApprovalSearchSpotsGrid() {
    this.refreshApprovalSpotsBacktoMediaCallSource.next();
  }

  private refreshSearchSpotsBacktoMediaCallSource = new Subject<any>();
  public refreshSearchSpotsBacktoMediaMethodCalled = this.refreshSearchSpotsBacktoMediaCallSource.asObservable();
  public callGetSearchSearchSpotsGrid() {
    this.refreshSearchSpotsBacktoMediaCallSource.next();
  }

  private refreshSearchSpotsGridSource = new Subject<any>();
  public refreshSearchSpotsCalled = this.refreshSearchSpotsGridSource.asObservable();
  public callRefreshSearchSpotsGrid() {
    this.refreshSearchSpotsGridSource.next();
  }

  private refreshRecentlyViewedBacktoMediaCallSource = new Subject<any>();
  public refreshRecentlyViewedBacktoMediaMethodCalled = this.refreshRecentlyViewedBacktoMediaCallSource.asObservable();
  public callRecentlyViewedSpotsGrid() {
    this.refreshRecentlyViewedBacktoMediaCallSource.next();
  }

  private deselectRowsCallSearchGridSource = new Subject<any>();
  public deselectSearchGridRowsCalled = this.deselectRowsCallSearchGridSource.asObservable();
  public callDeselectSearchGridRowsMethod() {
    this.deselectRowsCallSearchGridSource.next();
  }

  private deselectRowsCallRecentlyViewedSource = new Subject<any>();
  public deselectRecentlyViewedRowsCalled = this.deselectRowsCallRecentlyViewedSource.asObservable();
  public callDeselectRecentlyViewedRowsMethod() {
    this.deselectRowsCallRecentlyViewedSource.next();
  }

  private orderEditedCallSource = new Subject<any>();
  public orderEditedMethodCalled = this.orderEditedCallSource.asObservable();
  public callRefreshOrders() {
    this.orderEditedCallSource.next();
  }

  private draftOrderEditedCallSource = new Subject<any>();
  public draftOrderEditedMethodCalled = this.draftOrderEditedCallSource.asObservable();
  public callRefreshDraftOrders() {
    this.draftOrderEditedCallSource.next();
  }

  private uploadedOrderEditedCallSource = new Subject<any>();
  public uploadedOrderEditedMethodCalled = this.uploadedOrderEditedCallSource.asObservable();
  public callRefreshUploadedOrders() {
    this.uploadedOrderEditedCallSource.next();
  }  

  public onQuickLinkSubmit = new Subject<any>();
  public quickLinkSubmit() {
    this.onQuickLinkSubmit.next();
  }

  private vaultRequestCompletedCallSource = new Subject<any>();
  public vaultRequestCompletedMethodCalled = this.vaultRequestCompletedCallSource.asObservable();
  public callRefreshVaultRequest() {
    this.vaultRequestCompletedCallSource.next();
  }

  private vaultAssetEditedCallSource = new Subject<any>();
  public vaultAssetEditedMethodCalled = this.vaultAssetEditedCallSource.asObservable();
  public callRefreshVaultSearch() {
    this.vaultAssetEditedCallSource.next();
  }

  private vaultDigitalAssetEditedCallSource = new Subject<any>();
  public vaultDigitalAssetEditedMethodCalled = this.vaultDigitalAssetEditedCallSource.asObservable();
  public callRefreshVaultDigitalAssetSearch() {
    this.vaultDigitalAssetEditedCallSource.next();
  }


  public getUpdatedAvatar(avatar: string) {
    var userProfile = this.userProfileService.getUserProfile();
    userProfile.avatar = avatar;
    var user = this.userProfileService.getUser();
    user.userProfile = userProfile;
    this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
    this.avatarChange.emit(avatar);
  }

  public getUpdatedAccount() {


    this.userData.getUserDisplayData().subscribe((res: any) => {
      if (res.isSuccess == true)
      {
        var userProfile = this.userProfileService.getUserProfile();
        var user = this.userProfileService.getUser();

        var profile = res.result;

        userProfile.email = profile.email;

        userProfile.firstName = profile.firstName;
        userProfile.lastName = profile.lastName;
        userProfile.initials = profile.initials;
        userProfile.avatar = profile.avatar;
        userProfile.timeZone = profile.timeZone;
        userProfile.ianaCode = profile.ianaCode;

        userProfile.cellPhone = profile.cellPhone;
        userProfile.workPhone = profile.workPhone;
        userProfile.birthDay = profile.birthDay;
        userProfile.birthMonth = profile.birthMonth;
        userProfile.birthYear = profile.birthYear;
        userProfile.userProfileType = profile.userProfileType;

        user.userProfile = userProfile;

        this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);

        this.profileChange.emit(user.userProfile);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }
}
