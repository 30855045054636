import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { CreateFolderResponse } from '../../models/project/create-project.model';
import { Project } from '../../models/project/project.model';
import { forEach } from "core-js/fn/dict";
@Injectable()

export class ArchiveProjectService {

  constructor(private http: HttpClient,
    private apiEndPoint: ApiEndPoint) { }

  ArchiveProject(projectIds: Number[]): Observable<any> {
    var projectHeaders: string[] = [];

    projectIds.forEach(pid => projectHeaders.push(pid.toString()));

    let headers = new HttpHeaders({
      'fileCollectionId': projectHeaders
    });

    let options = { headers: headers };


    return this.http.post<CreateFolderResponse>(this.apiEndPoint.archiveProjects(), projectIds, options);
  }
}
