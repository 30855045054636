<div id="saved-destinations">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
      <div class="tylie-accordion accordion" id="adiderror-section" *ngIf="invalidAdids !=null && invalidAdids.length >0">
        <div class="tylie-accordion__panel accordion-item" id="adidErrorPanel">
          <!-- <div class="panel-heading">
            <h4 class="panel-title" data-toggle="collapse" data-target="#adidErrorCollapseOne">
              <a class="p-small">
                Unmatched Ad-ID
              </a>
            </h4>
          </div> -->
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#adidErrorCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Unmatched Ad-ID
            </button>
          </h2>
          <div id="adidErrorCollapseOne" class="tylie-accordion__content accordion-collapse collapse">
            <div class="tylie-accordion__body accordion-body" id="uploadPanelBody">
              <div class="tylie-accordion__body-content">
                <table class="tylie-table table" border="0">
                  <thead class="tylie-table__head">
                    <tr>
                      <th>Ad-ID</th>
                      <th></th>
                      <th class="close-border-dest"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <ng-container *ngFor="let rec of invalidAdids; let i = index">
                      <tr class="danger" *ngIf="rec != null && rec.status ==null">
                        <td class="error-message">
                          {{rec.adId}}
                          <table>
                            <thead>
                              <tr class="danger">
                                <!--<th>Group Name</th>-->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let grpName of rec.groups; let i = index" class="danger">
                                <td align="left">&nbsp;&nbsp; -Group {{grpName.sequenceId}}&nbsp;{{grpName.groupName}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table>
                            <thead>
                              <tr class="">
                                <!--<th>Group Name</th>-->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let errorInfo of rec.errors; let i = index" class="danger">
                                <td align="left">{{errorInfo}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td align="center"><i class="close-btn" (click)="deleteUserSelectedAdid(rec.adId)"></i></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tylie-accordion accordion" id="destinationError-section" *ngIf="invalidDestinations !=null && invalidDestinations.length >0">
        <div class="tylie-accordion__panel accordion-item" id="destinationErrorPanel">
          <!-- <div class="panel-heading">
            <h4 class="panel-title" data-toggle="collapse" data-target="#destinationErrorCollapseOne">
              <a class="p-small">
                Unmatched Destinations
              </a>
            </h4>
          </div> -->
          <h2 class="tylie-accordion__heading accordion-header">
            <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#destinationErrorCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              Unmatched Destinations
            </button>
          </h2>
          <div id="destinationErrorCollapseOne" class="tylie-accordion__content accordion-collapse collapse">
            <div class="tylie-accordion__body accordion-body" id="uploadPanelBody">
              <div class="tylie-accordion__body-content">
                <table class="tylie-table table" border="0">
                  <thead class="tylie-table__head">
                    <tr>
                      <th>Destination ID</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th class="close-border-dest"></th>
                    </tr>
                  </thead>
                  <tbody class="tylie-table__body">
                    <ng-container *ngFor="let dest of copyUploadErrorDestinations; let i = index">
                      <tr *ngIf="dest.selectedDestination != null ">
                        <td>
                          {{dest.selectedDestination.code}}
                        </td>
                        <td>{{dest.selectedDestination.name}}</td>
                        <td>{{dest.selectedDestination.destinationType}}</td>
                        <td align="center" class=""><i class="close-btn" (click)="deleteUserSelectedDestination(dest.selectedDestination.code)"></i></td>
                      </tr>
                      <tr class="danger" *ngIf="dest.selectedDestination == null ">
                        <td class="error-message">
                          {{dest.code}}
                          <div class="">
                            <table>
                              <thead>
                                <tr class="">
                                  <!--<th>Group Name</th>-->
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="danger" *ngFor="let grpName of dest.groupNames; let i = index">
                                  <td align="left">
                                    &nbsp; - Group {{grpName.sequenceId}}&nbsp;{{grpName.groupName}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                        <td colspan="2" class="zero-dest-margin" *ngIf="dest.destinations.length > 0">
                          <table class="table table-bordered" *ngIf="dest.destinations.length > 0">
                            <thead>
                              <tr class="danger">
                                <th>Select</th>
                                <th>Destination ID</th>
                                <th>Name</th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="danger" *ngFor="let destSuggest of dest.destinations; let i = index">
                                <td>
                                  <input type="radio" name="dest.code" (change)="OnSuggestedDestinationSelection(dest.code, destSuggest.code)">
                                </td>
                                <td>{{destSuggest.code}}</td>
                                <td>{{destSuggest.name}}</td>
                                <td>{{destSuggest.destinationType}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td *ngIf="dest.destinations.length == 0" colspan="2">No suggested match found</td>
                        <td align="center" class="">
                          <i class="close-btn" (click)="deleteDestination(dest.code)"></i>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngFor="let dest of copyUploadSuccessDestinations; let i = index">
                      <td> </td>
                      <td>{{dest.name}}</td>
                      <td>{{dest.destinationType}}</td>
                      <td align="center" class="">
                        <i class="close-btn" (click)="onCopyUploadSuccessDestinationDelete(dest.code)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs" type="button"
                (click)="onApplyToOrder()">
          APPLY TO ORDER
        </button>
      </div>
      <div *ngIf="selectionMethodNext == 'savetolist'">
        <br /><br />
        <ordersavetodestinationlistpopup [destinationsToAdd]="destinationsToAddToSavedList" (saveToListComplete)="onSaveToListCompleted()"></ordersavetodestinationlistpopup>
      </div>
    </div>
</div>
