export class NotificationPreferenceMapping  {

  static MediaQCPending = "Media QC Pending";
  static MediaQCPass = "Media QC Pass";
  static MediaQCFail = "Media QC Fail";
  static MediaArrivalConfirmation = "Media Arrival Confirmation";
  static MediaPendingApproval = "Media Pending Approval";
  static MediaApproveRejectNotification = "Media Approve/Reject Notification";
  static MediaEditNotification = "Media Edit Notification";
  static OrderConfirmation = "Order Confirmation";
  static OrderCompletion = "Order Completion";
  static OrderEditNotification = "Order Edit Notification";
  
}
