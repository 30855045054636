<div *ngIf="userProfileDetail != null">
  <user-view *ngIf="editMode == false"
             [userProfileDetail]="userProfileDetail"
             (onEditUserEvent)="onEditUserEventTriggered($event)">

  </user-view>
  <user-edit *ngIf="editMode == true"
             [userProfileGuid]="this.userProfileGuid"
             (onEditCompleteOrCancel)="onEditCompleteOrCancelEventTriggered($event)">

  </user-edit>
</div>
