export class NewProjectResponse {
  result: Clients[];
}

export class Clients {
  id: number;
  name: string
}

export class NewProject {
  projectName: string;
  projectCode: string;
  clientId: number;
  clientName: string;
}

export class SaveProjectResponse {
  isProjectCodeExist: boolean;
}

export class AddProjFilesResponse {
  result: ProjectFolder[];
}

export class ProjectFolder {
  fileCollectionId: number;
  collectionName: string;
}

export class ProjectFolders {
  folder: NewFolder[] = []
}

export class NewFolder {
  ProjectId: Number;
  FolderName: String;
}

export class CreateFolderResponse {
  isFolderExist: Boolean;
}

export class UpdateProject {
  fileCollectionId: number;
  collectionName: string;
  collectionId: string;
  clientId: number;
}

export class UpdateProjectFolder {
  fileCollectionId: number;
  folderName: string;
    clientId: number;
  projectId: number;
}
