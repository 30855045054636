<div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()" ><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">UPLOAD ASSETS</h4>
</div>

<div class="modal-body">
  <div *ngFor="let f of unSupportedFiles">
      <div class="added-files">
          <span
              class="file-text"
              style="color: red"
              ><b>{{ f.fileName }}</b></span
          >
          <a
              class="tylie-button tylie-button--link"
              (click)="removeUnSupportedFile(f)"
          >
          <icon-delete color="#ff0009"></icon-delete>
        </a>
      </div>
  </div>

    <form novalidate (ngSubmit)="onFormSubmit(assetRequestFormGroup)" [formGroup]="assetRequestFormGroup">
        <ng-container *ngFor="let groupName of groupNames; let i = index">
            <app-admin-asset-upload-form
            [assetRequestFormGroup]="assetRequestFormGroup"
            [groupName]="groupName"
            [groups]="groups"
            [locations]="locations"
            [fileName]="displayFileName(groupName)"
            [isEditAsset]="isEditAsset"
            [isFormSubmitted]="isFormSubmitted"
            (removeFile)="removeFile($event)"
            >
            </app-admin-asset-upload-form>
        </ng-container>
    </form>
    <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="button" (click)="onFormSubmit(assetRequestFormGroup)">
            <span class="tylie-button__icon">
                <icon-upload></icon-upload>
            </span>
            <span class="tylie-button__text">{{
                (isEditAsset && "UPDATE ASSET") || "UPLOAD ASSETS"
            }}</span>
        </button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()" >Cancel</button>
    </div>
</div>
