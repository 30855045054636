import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TaggerCardVoiceOver } from "../../../../models/tagger/tagger.model";
import { Utilities } from "../../../../services/core/utilities";
import { TaggerService } from "../../../../services/tagger/tagger.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { QuickplayerComponent } from "../../../videoplayer/quickplayer/quickplayer.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Constants } from "../../../../configurations/constants";

@Component({
    selector: "app-tagger-end-card-voice-over-step",
    templateUrl: "./tagger-end-card-voice-over-step.component.html",
    styleUrls: ["./tagger-end-card-voice-over-step.component.css"],
})
export class TaggerEndCardVoiceOverStepComponent implements OnInit {
    @Input() topFormGroup: FormGroup;
    @Output() next: EventEmitter<any> = new EventEmitter();
    public endCardVoiceOvers: TaggerCardVoiceOver[] = [];
    public selectedEndCardVoiceOvers: TaggerCardVoiceOver[] = [];
    public modalRef: BsModalRef;

    constructor(
        public util: Utilities,
        private taggerService: TaggerService,
        public alertService: AlertService,
        public bsModalRef: BsModalRef,
        private modalService: BsModalService
    ) { }

    ngOnInit() {
        this.getEndCardVoiceOvers();
        this.topFormGroup
            .get("endCardVoiceOvers")
            .valueChanges.subscribe((data) => {
                this.selectedEndCardVoiceOvers = data;
            });
    }

    public getEndCardVoiceOvers() {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.taggerService.getEndCardVoiceOvers().subscribe(
            (res: any) => {
                if (res.isSuccess == true) {
                    this.endCardVoiceOvers = res.result.searchResults && res.result.searchResults.filter((vo) => vo.durationinsecs <= 5 || !vo.durationinsecs);
                } else {
                    this.util.handleIsNotSuccess(res.errors);
                }

                this.alertService.ShowLoader(false);
            },
            (error) => {
                this.util.handleError(error);
                this.alertService.ShowLoader(false);
            }
        );
    }

    public onSelect(endCardVoiceOver: any): void {
        if (endCardVoiceOver.durationinsecs <= 5) {
            this.selectedEndCardVoiceOvers = [endCardVoiceOver];
            this.topFormGroup
                .get("endCardVoiceOvers")
                .setValue(this.selectedEndCardVoiceOvers);
            this.next.emit();
        } else {
            this.alertService.showMessage(
                "ERROR",
                Constants.voice_over_duration_error,
                MessageSeverity.error
            );
        }
    }

    public quickPlay(proxy, title, thumbnail, format) {
        if (proxy == null || proxy == "") return;

        const initialState = {
            videoSource: proxy,
            thumbnail: thumbnail,
            title: title,
            format: format,
        };

        this.modalRef = this.modalService.show(
            QuickplayerComponent,
            this.util.getModalComponentOptions(initialState, false, false, true)
        );
    }
}
