<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">PROMOTE TO DISTRIBUTION</h4>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="promoteForm" (ngSubmit)="onPromoteProjectSubmit()" novalidate #f="ngForm">
  
        <h3 class="tylie-type-h2">Promote this file to Tylie Distribution.</h3>
        <h3 class="tylie-type-h3">Please fill out the required fields below.</h3>

        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="client">Filename</label><br>
              <span class="tylie-form-group__txt">{{projectFile.collectionName}}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="client">Client</label><br>
              <span class="tylie-form-group__txt">{{projectFile.clientName}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group r-brand" [ngClass]="{error: promoteForm.controls['brand'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
              <dx-select-box name="brand" #brand id="brand" formControlName="brand" [dataSource]="brandDataSource"
                [searchEnabled]="true" class="tylie-select form-control" valueExpr="id" displayExpr="name"
                placeholder="Select a brand...">
              </dx-select-box>
              <p class="p-extra-small">Please select Brand</p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: promoteForm.controls['title'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="title" #titleToolTip="bs-popover" outsideClick="true" container="body" [popover]="tooltipTmpl"
                popoverTitle="Title style requirements" placement="top">Title<span class="required">*(view <span
                    style="color: blue;">requirements</span>
                  )</span></label>
              <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title" />
              <p class="p-extra-small">Please enter Title</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: promoteForm.controls['adID'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="adid">Ad-ID<span class="required">*</span></label>
              <input type="text" name="adid" id="adid" class="tylie-text form-control" formControlName="adID" />
              <p class="p-extra-small">Please enter Ad-ID</p>
            </div>
          </div>
          <div class="col-md-2">
            <div class="tylie-form-group form-group r-brand" [ngClass]="{error: promoteForm.controls['length'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="length">Length<span class="required">*</span></label>
              <input type="text" name="length" id="length" class="tylie-text form-control" formControlName="length" />
              <p class="p-extra-small">Please enter Length</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <span class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</span>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #tooltipTmpl>
  <button class="popover__close" (click)="onHideTooltip()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <p class="tylie-type-body">Place the title here.</p>
</ng-template>