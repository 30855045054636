import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { SubService } from "../../../services/media/sub.service";
import { SpotService } from "../../../services/media/spot.service";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, AbstractControl } from '@angular/forms';
import { ConfirmRequest } from "../../../models/generic/confirm-request";
import { ConfirmResult } from "../../../models/generic/confirm-result";
import { confirmEventType } from "../../../configurations/enums/enums";
import { ErrorPopupComponent } from "../errorpopup/errorpopup.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'deleteplaceholder-popup',
  templateUrl: './deleteplaceholderpopup.component.html',
  styleUrls: ['./deleteplaceholderpopup.component.css'],
})
export class DeletePlaceholderPopupComponent extends BasePopupComponent {
  public spots: Array<string>;
  public confirmRequest: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private spotService: SpotService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private subService: SubService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.confirmRequest = new FormGroup({
      notes: new FormControl(null)
    });
  }

  public closePopup() {
    this.bsModalRef.hide();

    this.subService.callRefreshSearchSpotsGrid();
  }

  public onConfirm({ value, valid }: { value: ConfirmRequest, valid: boolean }) {
    if (!valid)
      return;

    value.spots = this.spots;

    this.spotService.deletePlaceholder(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var results = res.result as Array<ConfirmResult>;
        var inValidResults = results.filter(t => t.isSuccess == false);

        if (inValidResults.length == 0) {
          this.alertService.showMessage("SUCCESS", Constants.placeholdersDeletedSuccessfully, MessageSeverity.success);
        }
        else {
          let updateMessageList: string[] = [];
          for (let res of inValidResults) {
            updateMessageList.push(`${res.spotIdentifier}: ${res.error}`);
          }

          var initialState = {
            errors: updateMessageList
          };

          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        }
        this.subService.callDeselectSearchGridRowsMethod();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.closePopup();
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }
}
