<div class="tylie-accordion accordion" id="search-all-orders-section">
  <div class="tylie-accordion__panel accordion-item" id="orderSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#orderSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH ALL DESTINATIONS
      </button>
    </h2>
    <div id="orderSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">

      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Code or Name or Aka" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue" (ngModelChange)="clearQuickSearch()"
                   (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col-6">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>

        <div class="tylie-section collapse collapse" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
          <form novalidate role="search" (ngSubmit)="onFormSubmit(searchDestinationFormGroup)" [formGroup]="searchDestinationFormGroup">
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="code">Code</label>
                  <input type="text" name="code" id="code" class="tylie-text form-control" formControlName="code">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                name="status" id="status" class="tylie-select form-control"
                                formControlName="status"
                                [searchEnabled]="true"
                                valueExpr="id"
                                [showClearButton]="true"
                                displayExpr="name"
                                placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="email">Email</label>
                  <input type="text" name="email" id="email" class="tylie-text form-control" formControlName="email">
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="destinationType">Type</label>
                  <dx-select-box [dataSource]="destinationTypeDataSource"
                                name="destinationType" id="destinationType" class="tylie-select form-control"
                                formControlName="destinationType"
                                  valueExpr="id"
                                displayExpr="name"
                                [showClearButton]="true"
                                placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>

              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cf">Created From</label>
                  <dx-date-box type="date" id="cf" class="tylie-datepicker form-control" formControlName="createdFrom" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>

              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="ct">Created To </label>
                  <dx-date-box type="date" id="ct" class="tylie-datepicker form-control" formControlName="createdTo" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>

        <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
          <div id="destinationView">
            <dx-data-grid id="destinationSearch" class="tylie-grid"
                          #destinationSearch
                          [dataSource]="resultDestinationStore"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [hoverStateEnabled]="true"
                          keyExpr="id"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true">

              <dxi-column dataField="code" caption="Code" cellTemplate="codeTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="asc" width="10%"></dxi-column>
              <dxi-column dataField="name" caption="Name" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="26%"></dxi-column>
              <dxi-column dataField="aka" caption="Aka" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
              <dxi-column dataField="acceptsDescriptiveVideo" caption="Accepts DVS" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
              <dxi-column dataField="destinationType" caption="Type" cssClass="gridStyle tyl-nopadding tyl-grid-padding dx-datagrid-nowrap-remove" width="12%"></dxi-column>
              <dxi-column dataField="market" caption="Market" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="26%"></dxi-column>
              <div *dxTemplate="let t of 'codeTemplate'">
                <span class="quickLink" (click)='showDestinationDetail(t.data.destinationGuid)'>{{t.data.code}}</span>
              </div>

              <dxo-remote-operations [sorting]="true"
                                      [paging]="true">
              </dxo-remote-operations>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                          [allowedPageSizes]="[10, 20, 50, 100]"
                          infoText="Page {0} of {1} ({2} items)"
                          [showNavigationButtons]="true"
                          [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>

      </div>
     
    </div>
  </div>
</div>
