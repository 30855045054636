<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header media-text">
      <h3 class="media-text">Assets</h3>
    </div>
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li (click)="gotoMedia()">
          <a class="back-to-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.37 108.37">
              <!--<title>Asset 12</title>-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M54.19,0a54.19,54.19,0,1,0,54.18,54.18A54.19,54.19,0,0,0,54.19,0Zm6,82.36L32.51,54.76,58.42,28.84l6.8,6.86-19,19L67,75.62Z" />
                </g>
              </g>
            </svg>
            <span class="back_to_nav">Back to All Assets</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="inner-content">
  <!--<div class="panel panel-default media-details" id="media-details">
    <div class="panel-collapse collapse in">
      <div class="panel-body">-->
        <mediadetailcontent [isQuickView]="false" [isPopOverView]="false" [spotFileIdentifier]="spotFileGuid" *ngIf="spotFileGuid"></mediadetailcontent>
      <!--</div>
      <div class="panel-footer"></div>
    </div>
  </div>-->
</div>
