<div id="saved-destinations" class="search-all-media all-orders">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">MAP DESTINATION {{vendorName}}</h4>
  </div>
  <div class="modal-body">
     
    <p class="mb-3">Please enter or search/click on a row to map the destination for {{vendorName}}.</p>

    <form [formGroup]="enterMapDestinationIdFormGroup" (ngSubmit)="onFormSubmit(enterMapDestinationIdFormGroup, f)" novalidate role="search" #f="ngForm">
      <div class="tylie-form-group form-group " [ngClass]="{error: (enterMapDestinationIdFormGroup.controls['mapDestinationId'].errors && !enterMapDestinationIdFormGroup.controls['mapDestinationId'].pristine)
          || (enterMapDestinationIdFormGroup.controls['mapDestinationId'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="defaultHub">Enter Map Destination ID<span class="required">*</span></label>
        <div class="row">
          <div class="col-4">
            <input type="text" id="mapDestinationId" name="mapDestinationId" formControlName="mapDestinationId" class="tylie-text form-control" />
          </div>

          <div class="col-md-3">
            <button class="tylie-button tylie-button--sm tylie-button--icon" type="submit">
              <span class="tylie-button__icon"><icon-checkmark></icon-checkmark> </span>
              <span class="tylie-button__text">Select</span>
            </button>
          </div>
        </div>
        <p class="p-extra-small" *ngIf="enterMapDestinationIdFormGroup.controls['mapDestinationId'].hasError('required')">Please enter a map destination Id</p>
        <p class="p-extra-small" *ngIf="enterMapDestinationIdFormGroup.controls['mapDestinationId'].hasError('invalidDestination') && f.submitted">Please enter a valid vendor destination code</p>
      </div>
      
    </form>

    <div class="row">
      <div class="col-6">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Code or Name or Aka" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                 (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
          </div>
        </section>
      </div>
    </div>

    <div class="tylie-grid-wrapper">
      <dx-data-grid id="vendorDestinationsGrid" class="tylie-grid tylie-grid--bordered"
                    #vendorDestinationsGrid
                    [dataSource]="dataSource"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [rowAlternationEnabled]="true"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showRowLines]="true"
                    keyExpr="vendorDestinationId">
        <dxo-selection mode="single"
                        selectAllMode="page"
                        allowSelectAll="Select"
                        showCheckBoxesMode="always">
        </dxo-selection>

        <dxi-column dataField="vendorDestinationCode" width="30%" cssClass="gridStyle" caption="Code" sortOrder="asc"></dxi-column>
        <dxi-column dataField="vendorDestinationName" width="70%" cssClass="gridStyle" caption="Name"></dxi-column>

        <dxo-remote-operations [sorting]="true"
                                [paging]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                    [allowedPageSizes]="[5, 10, 20, 50]"
                    infoText="Page {0} of {1} ({2} items)"
                    [showNavigationButtons]="true"
                    [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="button" (click)="onAddDestinationMapping()"><span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span><span class="tylie-button__text">ADD</span></button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>
  </div>
</div>
