import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderfinalizepopup',
  templateUrl: './orderfinalizepopup.component.html',
  styleUrls: ['./orderfinalizepopup.component.css'],
})
export class OrderFinalizePopupComponent extends BasePopupComponent {

  @Input() showWarningMessage: boolean;
  @Input() orderId: string;

  public onFinalizeOrderComplete: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onFinalizeOrderComplete = new Subject();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public OnOrderFinalize() {
    this.onFinalizeOrderComplete.next(true);

    this.closePopup();
  }

  public onBackToOrder() {
    this.closePopup();
  }
}
