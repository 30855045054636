import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-vault-logout',
  templateUrl: './icon-vault-logout.component.html',
  styleUrls: ['./icon-vault-logout.component.css']
})
export class IconVaultLogoutComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
