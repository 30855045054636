import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { OrderGroupService, DeleteOrderMediaEvent, OrderServiceMediaViewModel, CreateVendorPORequest, Vendor, VendorPurchaseOrderViewModel, UpdateVendorDateEvent } from "../../../models/order/vendor-purchaseorder-request.model";
import { OrderService } from "../../../services/order/order.service";
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import {  ClientBrandVendorInfoObjects } from "../../../models/admin/client/createclient.model";
import { digitsOnlyValidator, EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';
import { EditClientConfig } from '../../../models/admin/client/editclient.model';

@Component({
  selector: 'addclientbrandvendorinfopopup',
  templateUrl: './addclientbrandvendorinfopopup.component.html',
  styleUrls: ['./addclientbrandvendorinfopopup.component.css'],
})
export class AddClientBrandVendorInfoPopUp implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('pvendorDataSource') pvendorDataSource: string[] = [];
  @Input('evendorDataSource') evendorDataSource: string[] = [];
  @Input('preferredVendorId') preferredVendorId: number = 0;
  @Input('excludedVendorIds') excludedVendorIds: number[] = [];
  @Input('title') title: string = "EDIT DETAIL";

  @ViewChild('treepvendor', { static: false }) treePVendor: DxTreeViewComponent;
  @ViewChild('treeevendor', { static: false }) treeEVendor: DxTreeViewComponent;
  public clientbrandVendorInfoForm: FormGroup;
  public modalRef: BsModalRef;
  public onClientBrandVendorInfoAddEdit: Subject<ClientBrandVendorInfoObjects>;
  public preferredVendorValue: number;
  public excludedVendorValue: Array<number>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private util: Utilities) {

  }

  ngOnInit() {
    this.onClientBrandVendorInfoAddEdit = new Subject<ClientBrandVendorInfoObjects>();

    this.clientbrandVendorInfoForm = this.fb.group({
      preferredNetworkVendor: [this.preferredVendorId],
      excludeNetworkVendor: [this.excludedVendorIds],
    });

    this.preferredVendorValue = this.preferredVendorId;
    this.excludedVendorValue = this.excludedVendorIds;
  }


  public onclientbrandVendorInfoFormSubmit({ value, valid }: { value: ClientBrandVendorInfoObjects, valid: boolean }) {
    if (!valid) {
      return;
    }
    else {
      this.onClientBrandVendorInfoAddEdit.next(value);
      this.closePopup();
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treePVendor && this.treePVendor.instance);

    if (!component) return;

    if (this.preferredVendorValue == null ) {
      component.unselectAll();
    }

    if (this.preferredVendorValue != null) {
      component.selectItem(this.preferredVendorValue)
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as number;
    this.preferredVendorValue = newValues;
  }

  public syncrbTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeEVendor && this.treeEVendor.instance);

    if (!component) return;

    if (this.excludedVendorValue == null || !this.excludedVendorValue.length) {
      component.unselectAll();
    }

    if (this.excludedVendorValue != null) {
      component.unselectAll();
      this.excludedVendorValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public rbtreeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.excludedVendorValue = newValues;
  }

}
