
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RouteConstants } from "../../../configurations/route-constants";
import { ReportService } from "../../../services/report/report.service";
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderconfirmationpopup',
  templateUrl: './orderconfirmationpopup.component.html',
  styleUrls: ['./orderconfirmationpopup.component.css'],
})
export class OrderConfirmationPopupComponent extends BasePopupComponent {

  @Input() workOrder: string;
  @Input() orderLink: string;
  @Input() orderGuid: string;

  public onClose: Subject<boolean>;

  public isDownlaoding = false;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    private reportService: ReportService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();
  }

  public closePopup() {
    this.onClose.next(true);
    this.modalService.hide();
  }

  public onCopyOrderLink() {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', this.orderLink);
    //dummy.classList.add('tyl-display-none')
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public onDownloadOrderSummaryPdf() {
    this.isDownlaoding = true;

    this.reportService.getOrderSummaryReportPDF(this.orderGuid).pipe(
      finalize(() => {
        this.isDownlaoding = false;
      }))
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var path = res.result.reportFullPath;
          this.downloadFile(path, res.result.reportName);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
      });
  }

  private downloadFile(path: string, reportName: string) {
    if (path == null) {
      return;
    }
    else {
      this.util.downloadFile(this.util.getFileUrl(path)).subscribe(
        fileData => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(fileData);
          }
          else {
            var objectUrl = URL.createObjectURL(fileData);
            window.open(objectUrl);
          }
        });
    }
  }

  //private gotoOrderHome() {
  //  this.router.navigate([RouteConstants.ordersRoute]);    
  //}
}
