import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Utilities } from "../../services/core/utilities";
import { AlertService } from '../../services/core/alert.service';
import { Subscription } from "rxjs";
import { UserRoles } from "../../configurations/user-roles";
import { RouteConstants } from "../../configurations/route-constants";
import { SpotService } from '../../services/media/spot.service';
import { QuickLink } from '../../models/spot/quicklink.model';

@Component({
  selector: 'quicklinkdetail',
  templateUrl: './quicklinkdetail.component.html',
  styleUrls: ['./quicklinkdetail.component.css'],
})
export class QuickLinkDetailComponent implements OnInit {

  private quickLinkGuid: string;
  private source: string;
  public quickLinkDetail: QuickLink;

  private subscription: Subscription;

  constructor(
    private spotService: SpotService,
    public util: Utilities,
    private router: Router,
    public userRoles: UserRoles,
    private route: ActivatedRoute,
    private alertService: AlertService) {

  }

  ngOnInit() {
    this.quickLinkDetail = null;

    this.subscription = this.route.params.subscribe((params: Params) => {
      this.quickLinkGuid = params['quickLinkGuid'];
      this.getDetail();
    });
  }

  public gotoMedia() {
    this.router.navigate([RouteConstants.mediaRoute], { queryParams: { t: this.source } });
  }

  private getDetail() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.spotService.getQuickLinkDetail(this.quickLinkGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.quickLinkDetail = res.result as QuickLink;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.quickLinkDetail = null;
      });
  }
}
