import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators, NgForm, AbstractControl } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import { UserEmailDistributionGroups } from '../../../models/user/user-email-distribution-group.model';
import { AccountSettingsService } from '../../../services/user/account-settings.service';
import { Constants } from '../../../configurations/constants';
import { EmailListValidator } from '../../../configurations/custom-validations';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderaddsharelinkspopup',
  templateUrl: './orderaddsharelinkspopup.component.html',
  styleUrls: ['./orderaddsharelinkspopup.component.css'],
})
export class OrderAddShareLinksPopupComponent extends BasePopupComponent {
  @Input() allowAllSharedLinks: boolean = false;
  @Input() podMode: boolean = false;
  @Input() isEdit: boolean;

  public quickSearchValue: string = '';
  public distributionGroupsDataSource: Array<UserEmailDistributionGroups> = null;
  public distributionSavedGroupsDataSource: Array<UserEmailDistributionGroups> = null;
  public distributionGroupRequest: FormGroup;
  public onClose: Subject<SelectedOrderDestinations>;
  public addMoreEmailsMode: boolean = false;
  public savedListMode: boolean = false;  
  public applyToAllGroups: boolean = false;

  @ViewChild("f", { static: false }) f: NgForm;

  @ViewChild('distributionGroupsGrid', { static: false }) distributionGroupsGrid: DxDataGridComponent;
  @ViewChild('distributionSavedGroupsGrid', { static: false }) distributionSavedGroupsGrid: DxDataGridComponent;

  constructor(public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private accountSettingsService: AccountSettingsService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();

    this.distributionGroupRequest = new FormGroup({
      email: new FormControl('', [EmailListValidator]),
      newDistributionListName: new FormControl('', Validators.required),
      id: new FormControl(null, Validators.required)
    });

    if (this.allowAllSharedLinks == true)
      this.savedListMode = false;
    else
      this.savedListMode = true;

    this.searchDistributionGroups();
  }

  private searchDistributionGroups() {

    let request = { quickSearch: this.quickSearchValue };

    if (this.savedListMode == true) {

      this.accountSettingsService.searchUserEmailDistributionGroupsEndpoint(request).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var userEmailDistroList = res.result as Array<UserEmailDistributionGroups>;

          userEmailDistroList.forEach(t => {
            if (t.groupEmails != null && t.groupEmails.length > 0)
              t.groupEmailsDisplay = t.groupEmails.join("; ");

            t.isPasswordProtected = false;
          });

          this.distributionSavedGroupsDataSource = userEmailDistroList.filter(t => t.groupEmails != null && t.groupEmails.length > 0);
          this.distributionGroupsDataSource = [];
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          if (this.util.handleError(error))
            this.closePopup();
        });

    }
    else {
      this.configService.searchShareLinkDestinations(request).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var userEmailDistroList = res.result as Array<UserEmailDistributionGroups>;

          userEmailDistroList.forEach(t => {
            if (t.groupEmails != null && t.groupEmails.length > 0)
              t.groupEmailsDisplay = t.groupEmails.join("; ");

            t.isPasswordProtected = false;
          });

          this.distributionGroupsDataSource = userEmailDistroList.filter(t => t.groupEmails != null && t.groupEmails.length > 0);
          this.distributionSavedGroupsDataSource = [];
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          if (this.util.handleError(error))
            this.closePopup();
        });
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      //this.clearForm();
      this.searchDistributionGroups();
    }
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue === '') {
      //this.clearForm();
      this.searchDistributionGroups();
    }
  }

  public onApplyToOrder() {
    this.saveShareLinks();
  }

  private saveShareLinks() {
    var grid = this.savedListMode ? this.distributionSavedGroupsGrid.instance : this.distributionGroupsGrid.instance;

    var selectedShareLinks = new Array<UserEmailDistributionGroups>();
    var allSelectedDestiantions = new SelectedOrderDestinations();

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedShareLinks.push(grid.getSelectedRowsData()[i]);
    }

    allSelectedDestiantions.applyToAllGroups = this.applyToAllGroups;

    allSelectedDestiantions.shareLinks = selectedShareLinks;

    this.onClose.next(allSelectedDestiantions);

    this.closePopup();
  }

  public onFormSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (this.distributionGroupRequest.controls['email'].errors || (this.distributionGroupRequest.controls['id'].errors && this.distributionGroupRequest.controls['newDistributionListName'].errors))
      return;

    var emails = (value.email as string).split(';').filter(t => t.trim() != '');

    if (this.distributionGroupRequest.controls['newDistributionListName'].errors) {
      var distroGroupToEdit = this.distributionSavedGroupsDataSource.find(t => t.groupGuid == value.id);

      if (distroGroupToEdit != null) {
        var userEmailDistributionGroup = new UserEmailDistributionGroups(
          value.id,
          distroGroupToEdit.groupName,
          emails
        );

        this.updateDistributionGroup(userEmailDistributionGroup);
      }
    }
    else {
      var userEmailDistributionGroup = new UserEmailDistributionGroups(
        '00000000-0000-0000-0000-000000000000',
        value.newDistributionListName,
        emails
      );

      this.createDistributionGroup(userEmailDistributionGroup);
    }
  }

  private createDistributionGroup(userEmailDistributionGroup: UserEmailDistributionGroups) {

    this.accountSettingsService.createUserDistributionGroupEndpoint(userEmailDistributionGroup).subscribe((res: any) => {
      if (res.isSuccess == true) {
        userEmailDistributionGroup.groupGuid = res.result;
        this.alertService.showMessage("SUCCESS", Constants.userDistroCreated, MessageSeverity.success);
        this.clearForm();
        this.addMoreEmailsMode = false;
        this.searchDistributionGroups();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  private updateDistributionGroup(userEmailDistributionGroup: UserEmailDistributionGroups) {
    this.accountSettingsService.updateUserDistributionEmailsFromGroupEndpoint(userEmailDistributionGroup.groupGuid, userEmailDistributionGroup).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.userDistroEmailUpdated, MessageSeverity.success);
        this.clearForm();
        this.addMoreEmailsMode = false;
        this.searchDistributionGroups();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public onDistrubtionGroupEdit(id: string) {

    var distroGroupToEdit = this.distributionSavedGroupsDataSource.find(t => t.groupGuid == id);

    if (distroGroupToEdit != null) {
      this.distributionGroupRequest.controls['email'].setValue(distroGroupToEdit.groupEmailsDisplay);
      //this.distributionGroupRequest.controls['editDistributionListName'].setValue(distroGroupToEdit.groupName);
      this.distributionGroupRequest.controls['newDistributionListName'].setValue('');
      this.distributionGroupRequest.controls['id'].setValue(distroGroupToEdit.groupGuid);

      this.addMoreEmailsMode = true;
    }
  }

  public onDistrubtionGroupDelete(groupGuid: string) {
    this.accountSettingsService.removeDistributionGroupEndpoint(groupGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.userDistroDeleted, MessageSeverity.success);
        this.searchDistributionGroups();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  private clearForm() {
    //this.f.resetForm();
    //this.addMoreEmailsMode = false;

    this.distributionGroupRequest = new FormGroup({
      //email: new FormControl('', [Validators.required, Validators.pattern(emailRegEx)]),
      //email: new FormControl('', Validators.required),
      //email: new FormControl('', [this.emailListValidator]),
      email: new FormControl('', [EmailListValidator]),
      //editDistributionListName: new FormControl('', Validators.required),
      newDistributionListName: new FormControl('', Validators.required),
      id: new FormControl(null, Validators.required)
    });
  }

  public onAddMoreEmails() {
    this.addMoreEmailsMode = true;
  }

  public onCancelAddMore() {
    this.addMoreEmailsMode = false;
    this.clearForm();
  }

  public onShowList() {
    this.savedListMode = false;
    this.searchDistributionGroups();
  }

  public onShowSavedList() {
    this.savedListMode = true;
    this.searchDistributionGroups();
  }
}

export function ValidateEmailList(control: AbstractControl) {
  if (!Validators.required(control) && control.value.trim() != '') {
    return null;
  }
  return true;
}
