import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { DxDataGridComponent } from 'devextreme-angular';;
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { OrderGroupService, DeleteOrderMediaEvent, OrderServiceMediaViewModel, CreateVendorPORequest, Vendor, VendorPurchaseOrderViewModel, UpdateVendorDateEvent, OrderLvlService } from "../../../models/order/vendor-purchaseorder-request.model";
import { OrderService } from "../../../services/order/order.service";
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { VendorPurchaseOrderConfirmationPopupComponent } from "../vendorpurchaseorderconfirmationpopup/vendorpurchaseorderconfirmationpopup.component";

@Component({
  selector: 'vendorpurchaseorderpopup',
  templateUrl: './vendorpurchaseorderpopup.component.html',
  styleUrls: ['./vendorpurchaseorderpopup.component.css'],
})
export class VendorPurchaseOrderPopupComponent implements OnInit, OnDestroy {

  @Input() vendorPOOrdergroupService: OrderGroupService[];
  @Input() orderGuid: string;
  @Input() vendorPOList: VendorPurchaseOrderViewModel[];
  @Input() vendorPOOrderLvlServices: OrderLvlService[];

  public vendorPOForm: FormGroup;
  public modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private util: Utilities) {

  }

  ngOnInit() {
    this.vendorPOForm = this.fb.group({
      vendorPOOrderGroupServices: this.createVendorPOOrderGroupServicesFormArray(),
      vendorPOOrderLvlServices: this.createVendorPOOrderLvlServicesFormArray()
    });
  }

  public onSubmitCreateVendorPOForm({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      return;
    }
    else {
      console.log(value);
      this.orderService.createNonDigitalVendorPO(this.orderGuid, value).pipe(
        finalize(() => {
          this.closePopup();
        }))
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            var vendorPOConfirmationList = res.result.vendorPurchaseOrders as VendorPurchaseOrderViewModel[];
            var initialState = {
              vendorPOList: vendorPOConfirmationList
            };
            this.modalRef = this.modalService.show(VendorPurchaseOrderConfirmationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
            this.alertService.showMessage("SUCCESS", Constants.vendorPurchaseOrderCreationSuccessful, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            if (this.util.handleError(error)) {
              this.closePopup();
            }
          });
    }
  }

  public onDeleteVendorPOServiceEmitted(poType, e) {
    this.removeElementFromcreateVendorPOFormArray(poType, e);
  }

  public onDeleteOrderMediaEmitted(e: DeleteOrderMediaEvent) {
    this.removeElementFromorderMediaArray(e.groupName, e.orderMediaIndex);
  }

  public onVendorChangeDueDateUpdateEmitted(poType, e: UpdateVendorDateEvent) {
    var dueDate: any = "";
    var vendorPOArray = this.fb.array([]) as FormArray;

    if (poType == 'grpServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderGroupServices') as FormArray;
    }
    else if (poType == 'orderLvlServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderLvlServices') as FormArray;
    }

    var filterForDueDate = vendorPOArray.controls.filter(formg =>
      formg.value['vendor'] == e.vendor && formg.value['dueDate'] != ""
    );

    if (filterForDueDate.length > 0) {
      filterForDueDate.forEach(formg => {
        if (formg.value['dueDate'] > dueDate) {
          dueDate = formg.value['dueDate'];
        }
      });
    }

    vendorPOArray.controls.forEach(formg => {
      if (formg.value['vendor'] == e.vendor) {
        formg.patchValue({ dueDate: dueDate });
      }
    });
  }

  public onDueDateUpdateEmitted(poType, e: UpdateVendorDateEvent) {
    var vendorPOArray = this.fb.array([]) as FormArray;

    if (poType == 'grpServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderGroupServices') as FormArray;
    }
    else if (poType == 'orderLvlServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderLvlServices') as FormArray;
    }

    vendorPOArray.controls.forEach(formg => {
      if (formg.value['vendor'] == e.vendor) {
        formg.patchValue({ dueDate: e.dueDate });
      }
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  private createVendorPOOrderGroupServicesFormArray() {
    var vendorPOOrderGroupServices: FormArray = this.fb.array([]);
    this.vendorPOOrdergroupService.forEach(obj => {
      const group = this.fb.group({
        orderGroupSequenceId: [obj.orderGroupSequenceId, Validators.compose([Validators.required])],
        serviceId: [obj.serviceId, Validators.compose([Validators.required])],
        serviceName: [obj.serviceName, Validators.compose([Validators.required])],
        vendor: ['', Validators.compose([Validators.required])],
        dueDate: [null],
        notes: [''],
        nonDigitalVendors: [obj.nonDigitalVendors],
        orderMedia: this.createOrderMediaFormArray(obj.orderMedia) as FormArray
      });

      vendorPOOrderGroupServices.push(group);
    });
    return vendorPOOrderGroupServices;
  }

  private createVendorPOOrderLvlServicesFormArray() {
    var vendorPOOrderLvlServices: FormArray = this.fb.array([]);
    this.vendorPOOrderLvlServices.forEach(obj => {
      const group = this.fb.group({
        serviceId: [obj.serviceId, Validators.compose([Validators.required])],
        orderServiceId: [obj.orderServiceId],
        serviceName: [obj.serviceName, Validators.compose([Validators.required])],
        vendor: ['', Validators.compose([Validators.required])],
        dueDate: [null],
        notes: [''],
        nonDigitalVendors: [obj.nonDigitalVendors]
      });

      vendorPOOrderLvlServices.push(group);
    });
    return vendorPOOrderLvlServices;
  }

  private createOrderMediaFormArray(orderServiceMedia: OrderServiceMediaViewModel[]) {
    var orderMediaList: FormArray = this.fb.array([]);
    orderServiceMedia.forEach(sp => {
      const media = this.fb.group({
        title: [sp.title],
        adId: [sp.adId],
        length: [sp.length],
        spotFileGuid: [sp.spotFileGuid],
        orderServiceId: [sp.orderServiceId],
        spotFileId: [sp.spotFileId]
      });
      orderMediaList.push(media);
    });
    return orderMediaList;
  }

  private removeElementFromcreateVendorPOFormArray(poType, index: number) {
    var vendorPOArray = this.fb.array([]) as FormArray;

    if (poType == 'grpServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderGroupServices') as FormArray;
    }
    else if (poType == 'orderLvlServices') {
      vendorPOArray = this.vendorPOForm.get('vendorPOOrderLvlServices') as FormArray;
    }

    vendorPOArray.removeAt(index);
  }

  private removeElementFromorderMediaArray(groupIndex: number, ordermediaIndex: number) {
    const vendorPOArray = this.vendorPOForm.get('vendorPOOrderGroupServices') as FormArray;
    const formGroup = vendorPOArray.at(groupIndex);
    var orderMedia = formGroup.get('orderMedia') as FormArray;
    if (orderMedia.length > 1) {
      orderMedia.removeAt(ordermediaIndex);
    }
  }

  ngOnDestroy(): void {

  }
}
