import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LocalStoreManager {
  public static readonly DBKEY_USER_DATA = "user_data";

  public saveSyncedSessionData(data: any, key: string) {
    this.sessionStorageSetItem(key, data);
    this.localStorageSetItem(key, data);
  }

  public sessionStorageSetItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  private localStorageSetItem(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  //get Session storage by key
  public sessionStorageGetItem(key: string) {
    let value = sessionStorage.getItem(key);
    if (value != null && value != "undefined") {
      return JSON.parse(value)
    }
  }

  public localStorageGetItem(key: string) {
    let value = localStorage.getItem(key);
    if (value != null && value != "undefined") {
      return JSON.parse(value)
    }
  }

  public getData(key = LocalStoreManager.DBKEY_USER_DATA) {
   //var data = this.sessionStorageGetItem(key);
   // if (data == "undefined" || data == null || data == "") {
      let data = this.localStorageGetItem(key);
   // }

    //console.log(data);
    return data;
  }

  public deleteData(keyToRemove: string) {
    sessionStorage.removeItem(keyToRemove);
    localStorage.removeItem(keyToRemove);
  }

  //public clearInstanceSessionStorage() {
  //  sessionStorage.clear();
  //  localStorage.clear();
  //}
}
