import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { Utilities } from "../../../../../services/core/utilities";
import { ConfigService } from "../../../../../services/core/config.service";
import { AdminService } from "../../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { Client, PostHouseModel } from "../../../../../models/admin/client/client";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { Constants } from "../../../../../configurations/constants";
import { AddPosthouseToClientResponse, BrandSelectionResponse, AddPosthouseToClientRequest } from "../../../../../models/admin/client/createclient.model";
import { PostHouseAddProductPopUp } from "../../../../popups/posthouseaddproduct/posthouseaddproductpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DxDataGridComponent } from "devextreme-angular";
import { confirmEventType } from "../../../../../configurations/enums/enums";
import { ConfirmPopupComponent } from "../../../../popups/confirmationpopups/confirmpopup.component";

@Component({
  selector: 'client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit {

  @Input('clientGuid') clientGuid: string;
  @Output() onEditClientEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('brandsGrid', { static: false }) brandsGrid: DxDataGridComponent;

  public addClientPostHouseForm: FormGroup;
  public modalRef: BsModalRef;
  public clientsRoute = RouteConstants.clientsRoute;
  public client: Client = null;
  public postHouseAddMode: boolean = false;
  public isPostHouseEdit: boolean = false;
  public selectedBrandKeys: number[] = [];
  public addPostHouseBrandProductsdataSource: any[] = [];
  public postHouseDataSource: PostHouseModel[] = [];
  public isSaving: boolean = false;

  constructor(public util: Utilities
    , public adminService: AdminService
    , private alertService: AlertService
    , private fb: FormBuilder
    , public bsModalRef: BsModalRef
    , private modalService: BsModalService
    , private router: Router) {

  }

  ngOnInit() {
    this.addClientPostHouseForm = this.fb.group({
      postHouseId: [null, Validators.compose([Validators.required])],
      postHouseName: [null, Validators.compose([Validators.required])]
    });

    this.getClientView(this.clientGuid);
  }

  public onEditClient() {
    this.adminService.startClientEditLock(this.clientGuid).subscribe((res: any) => {
      var result = res.result;
      if (res.isSuccess == true) {
        this.onEditClientEvent.next();
      }
      else {
        this.client = result as Client;
        this.client.base64Logo = this.util.notEmpty(this.client.base64Logo) ? Constants.avatarEncoding + this.client.base64Logo : null;

        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onAddPostHouse() {
    this.postHouseAddMode = true;
    this.getPostHouseClientBrands();
  }

  public onEditPostHouse(data: PostHouseModel) {
    this.updateaddClientPostHouseForm(data);
    this.getPostHouseClientBrands(data);
    this.postHouseAddMode = true;
    this.isPostHouseEdit = true;
  }

  public onDeletePostHouse(data: PostHouseModel) {
    var initialState = {
      eventType: confirmEventType[confirmEventType.DeletePostHouse]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.postHouseAddMode = false;
        this.isPostHouseEdit = false;

        this.adminService.deletePostHouse(data.clientEditorialHouseId, this.client.id).subscribe((res: any) => {
          if (res.isSuccess == true) {
            var result = res.result;
            this.client.postHouses = result.postHouses;
            this.alertService.showMessage("SUCCESS", Constants.deletePostHouseSuccessful, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    });
  }

  public onClientStatusUpdate(isCurrent: boolean) {
    this.adminService.updateClientStatus(this.clientGuid, { isCurrent: isCurrent }).subscribe((res: any) => {
      var result = res.result;
      this.client = result as Client;
      this.client.base64Logo = this.util.notEmpty(this.client.base64Logo) ? Constants.avatarEncoding + this.client.base64Logo : null;

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.clientStatusUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public ondeleteDraftClient() {
    this.adminService.deleteDraftClient(this.clientGuid).subscribe((res: any) => {
      var result = res.result;

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.clientDraftDeleted, MessageSeverity.success);
        this.router.navigate([RouteConstants.clientsRoute]);
      }
      else {
        this.client = result as Client;
        this.client.base64Logo = this.util.notEmpty(this.client.base64Logo) ? Constants.avatarEncoding + this.client.base64Logo : null;

        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onMakeClientBillable() {
    this.adminService.makeClientBillable(this.clientGuid).subscribe((res: any) => {
      var result = res.result;
      this.client = result as Client;
      this.client.base64Logo = this.util.notEmpty(this.client.base64Logo) ? Constants.avatarEncoding + this.client.base64Logo : null;

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.makeClientBillableSuccess, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onCancelEditPostHouse() {
    this.postHouseAddMode = false;
    this.isPostHouseEdit = false;
    this.addClientPostHouseForm.reset();
  }

  public onAddNewPostHouseSuccess(e: PostHouseModel) {
    this.postHouseDataSource.push(e);
    this.addClientPostHouseForm.controls['postHouseId'].setValue(e.id);
  }

  public onContentReady(e: any) {
    if (this.util.notEmpty(e) && this.util.notEmpty(e.component)) {
      e.component.selectRows(this.selectedBrandKeys);
    }
  }

  public addClientBrandProduct(data: any) {
    if (this.util.notEmpty(data.products)) {
      var productKeys: number[] = data.products.map(product => product.id);
    }
    var initialState = {
      clientId: this.client.id,
      brandId: data.brandId,
      productKeys: productKeys
    };

    this.modalRef = this.modalService.show(PostHouseAddProductPopUp, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      data.products = result;
    });
  }

  public onFormSubmit({ value, valid }: { value: AddPosthouseToClientRequest, valid: boolean }) {
    if (valid == false) { return; }

    value.agencyId = this.client.id;
    value.postHouseBrandSelectionList = this.brandsGrid.instance.getSelectedRowsData();
    this.addPostHouseToClient(value);
  }

  public onPostHouseSelected(e: any) {
    if (this.util.notEmpty(e) && this.util.notEmpty(e.value) && e.value > 0 && this.util.notEmpty(this.postHouseDataSource)) {
      var postHouse = this.postHouseDataSource.find(pd => pd.id == e.value);
      if (this.util.notEmpty(postHouse)) {
        this.addClientPostHouseForm.controls['postHouseName'].setValue(postHouse.name);
      }
    }
  }

  private updateaddClientPostHouseForm(postHouse: PostHouseModel) {
    if (postHouse != null) {
      this.addClientPostHouseForm.controls['postHouseId'].setValue(postHouse.id);
      this.addClientPostHouseForm.controls['postHouseName'].setValue(postHouse.name);
    }
  }

  private getPostHouseClientBrands(postHouse?: PostHouseModel) {
    var postHouseId = null;
    this.selectedBrandKeys = [];

    if (this.util.notEmpty(postHouse)) {
      postHouseId = postHouse.id;
    }
    this.adminService.getPostHouseClientBrands(this.client.id, postHouseId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result as BrandSelectionResponse;
        this.postHouseDataSource = result.availablePostHouses;
        this.addPostHouseBrandProductsdataSource = result.selectedClientBrands;
        if (this.util.notEmpty(postHouse)) {
          this.selectedBrandKeys = postHouse.postHouseBrandSelectionList.map(brand => brand.brandId);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private addPostHouseToClient(value: AddPosthouseToClientRequest) {
    this.isSaving = true;
    this.adminService.addPostHouseToClient(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result;
        this.onAddPostHouseSuccess(result);
        this.isSaving = false;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.isSaving = false;
      }
    },
      error => {
        this.util.handleError(error);
        this.isSaving = false;
      });
  }

  private getClientView(clientGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getClientView(clientGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.client = res.result as Client;
        console.log(this.client)
        this.client.base64Logo = this.util.notEmpty(this.client.base64Logo) ? Constants.avatarEncoding + this.client.base64Logo : null;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private onAddPostHouseSuccess(result: AddPosthouseToClientResponse) {
    this.postHouseAddMode = false;
    this.addClientPostHouseForm.reset();
    this.isPostHouseEdit = false;
    this.client.postHouses = result.postHouses;
  }
}
