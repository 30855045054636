import { Injectable, Injector } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, NavigationExtras, CanLoad, Route } from '@angular/router';





//Internal Routing Urls
@Injectable()
export class EndpointFactory {

    private _loginRoutepoint: string = "login";

    constructor(private router: Router) {

    }
    public loginUrl() { return this._loginRoutepoint;}

}
