import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { AdminService } from "../../../services/admin/admin.service";
import { DestinationSearch } from "../../../models/admin/destination/destinationsearch.model";

@Component({
  selector: 'admindefaulthubdestinationsearchpopup.component.ts',
  templateUrl: './admindefaulthubdestinationsearchpopup.component.html',
  styleUrls: ['./admindefaulthubdestinationsearchpopup.component.css'],
})
export class AdminDefaultHubDestinationSearchComponent implements OnInit {

  public dataSource: any = {};
  public quickSearchValue: string = '';
  public onClose: Subject<string>;
  public searchDestinationCriteriaObj = <DestinationSearch>{};

  @Input('selectedRowCode') selectedRowCode: string = null;
  @ViewChild('destinationsGrid', { static: true }) destinationsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private util: Utilities,
    private adminService: AdminService) {

  }

  ngOnInit() {
    this.onClose = new Subject();
    this.searchDestinations();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '') {
      return;
    }

    this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
    this.destinationsGrid.instance.refresh();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
      this.destinationsGrid.instance.refresh();
    }
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public onAddAsDefaultHub() {
    var data = this.destinationsGrid.instance.getSelectedRowsData();
    if (this.util.notEmpty(data) && data.length > 0) {
      var dataCode = data.map(d => d.code);
      this.onClose.next(dataCode[0]);
    }
    else {
      this.onClose.next(this.selectedRowCode);
    }
    this.closePopup();
  }

  private searchDestinations() {
    var adminService = this.adminService;
    var util = this.util;
    this.searchDestinationCriteriaObj.ignoreStatus = true;
    var criteriaObj = this.searchDestinationCriteriaObj;

    this.dataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return adminService.searchAllDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var destinationSearchResponse = response.result;
              var obj: any = {
                data: destinationSearchResponse.destinations,
                totalCount: destinationSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }
}
