
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { RouteConstants } from "../../../configurations/route-constants";
import { ReportService } from "../../../services/report/report.service";
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'vaultconfirmationpopup',
  templateUrl: './vaultconfirmationpopup.component.html',
  styleUrls: ['./vaultconfirmationpopup.component.css'],
})
export class VaultConfirmationPopupComponent extends BasePopupComponent {

  @Input() vaultRequestNumber: string;

  public onClose: Subject<boolean>;

  public isDownlaoding = false;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    private reportService: ReportService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onClose = new Subject();
  }

  public closePopup() {
    this.onClose.next(true);
    this.modalService.hide();
  }
}
