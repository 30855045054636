<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="close delete" (click)="closePopup()"></button>
    <h4 class="modal-title">Update Distribution Assets</h4>
  </div>
  <div class="modal-body">

    <div>
      <form [formGroup]="updateDistroMediaForm" (submit)="onupdateDistroMediaFormSubmit(updateDistroMediaForm)" novalidate #ngForm="ngForm">
        <div class="form-group row col-md-6" [ngClass]="{error: (updateDistroMediaForm.controls['houseId'].errors && !updateDistroMediaForm.controls['houseId'].pristine)
                                            || (updateDistroMediaForm.controls['houseId'].errors && ngForm.submitted)}">
          <label for="houseId">
            House Id<span class="required">*</span>
          </label>
          <input id="houseId" name="houseId" class="form-control" formControlName="houseId"/>
        </div>

        <div class="col-md-12">
          <br />
        </div>

        <div class="row">
          <div class="col-md-6 col-md-offset-6">
            <div class="form-group media-qc tyl-right">
              <button class="btn btn-small qc-update" type="submit">Save</button>
              <span class="qc-cancel p-normal" (click)="closePopup()">Cancel</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
