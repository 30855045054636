<header class="tylie-header">
  <h3 class="tylie-header__title">IT Ops</h3>
</header>

<div class="tylie-body">
  <div class="tylie-body__bar" id="create-order-section">
    <div secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <button type="button" class="tylie-button tylie-button--icon" (click)="onClearCache()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">Clear Cache</span>
      </button>
    </div>
  </div>

  <div class="tylie-accordion accordion">
    <div class="tylie-accordion__panel accordion-item" id="media-new-upload">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#createSpotFilesCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Create SpotFiles In Creating Asset status
        </button>
      </h2>

      <div id="createSpotFilesCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="spotFileCreateRequest" #sf="ngForm" (ngSubmit)="onCreateSpotFileRequestSubmit(spotFileCreateRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: spotFileCreateRequest.controls['adID'].errors && sf.submitted}">
                    <label class="tylie-form-group__lbl" for="adid">Ad-ID<span class="required">*</span></label>
                    <input type="text" name="adID" id="adID" class="tylie-text form-control" formControlName="adID" />
                    <p class="p-extra-small">Please enter Ad-ID</p>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="tylie-form-group form-group" [ngClass]="{error: spotFileCreateRequest.controls['title'].errors && sf.submitted}">
                    <label class="tylie-form-group__lbl" for="title">Title<span class="required">*</span></label>
                    <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title" />
                    <p class="p-extra-small">Please enter Title</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 added-files-container">
                  <h4 class="media-upload-text" *ngIf="spotFileCreateRequests.length > 0"><b>Added Requests</b></h4>
                  <div class="added-files" *ngFor="let f of spotFileCreateRequests">
                    <span class="file-text">{{f.adID}} -- {{f.title}}</span>
                    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link ms-2" (click)="removeCreateFileRequests(f.adID, f.title)">
                      <icon-trash-can color="#1e90ff"></icon-trash-can>
                    </button>
                  </div>
                  <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon"><span class="tylie-button__icon"><icon-eye></icon-eye></span><span class="tylie-button__text">Add Request</span></button>
                </div>
              </div>

              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon mt-2" (click)="onCreateSpotFiles()">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">CREATE SPOTFILES</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-cancel" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cancelOrderCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Cancel Order
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="cancelOrderCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="cancelOrderRequest" #oc="ngForm" (ngSubmit)="onCancelOrderFormSubmit(cancelOrderRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: cancelOrderRequest.controls['orderGuid'].errors && ocsubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Cancel Order</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-cancel" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#updateOrderStatusCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Update Order Status
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="updateOrderStatusCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="updateOrderStatusRequest" #os="ngForm" (ngSubmit)="onUpdateOrderStatusFormSubmit(updateOrderStatusRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderStatusRequest.controls['orderGuid'].errors && ossubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderStatusRequest.controls['status'].errors && ossubmitted == true }">
                    <label class="tylie-form-group__lbl" for="client">Status<span class="required">*</span></label>
                    <dx-select-box [dataSource]="statusDataSource"
                                   id="status"
                                   name="status"
                                   class="tylie-select form-control"
                                   formControlName="status"
                                   [searchEnabled]="true"
                                   valueExpr="id"
                                   displayExpr="name"
                                   placeholder="Select a status...">
                    </dx-select-box>
                    <p class="p-extra-small">Please select Status</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Update Order Status</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-update" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#updateServiceLevelCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Update Service Level
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="updateServiceLevelCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="updateServiceLevelRequest" #os="ngForm" (ngSubmit)="onUpdateServiceLevelFormSubmit(updateServiceLevelRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateServiceLevelRequest.controls['orderGuid'].errors && slsubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateServiceLevelRequest.controls['sequenceid'].errors && slsubmitted == true}">
                    <label class="tylie-form-group__lbl" for="sequenceid">Sequence Id<span class="required">*</span></label>
                    <input type="text" id="sequenceid" name="sequenceid" class="tylie-text form-control" formControlName="sequenceid">
                    <p class="p-extra-small">Please enter Sequence Id</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateServiceLevelRequest.controls['serviceLevel'].errors && slsubmitted == true }">
                    <label class="tylie-form-group__lbl" for="client">Service Level<span class="required">*</span></label>
                    <dx-select-box [dataSource]="serviceLevelDataSource"
                                   id="serviceLevel"
                                   name="serviceLevel"
                                   class="tylie-select form-control"
                                   formControlName="serviceLevel"
                                   [searchEnabled]="true"
                                   valueExpr="id"
                                   displayExpr="name"
                                   placeholder="Select a Service Level...">
                    </dx-select-box>
                    <p class="p-extra-small">Please select Service Level</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Update Service Level</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-cancel" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#createVendorPoCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Create Vendor PO
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="createVendorPoCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="createVendorPoRequest" #os="ngForm" (ngSubmit)="onCreateVendorPoFormSubmit(createVendorPoRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: createVendorPoRequest.controls['orderGuid'].errors && ossubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: createVendorPoRequest.controls['network'].errors && ossubmitted == true }">
                    <label class="tylie-form-group__lbl" for="client">Network<span class="required">*</span></label>
                    <dx-select-box [dataSource]="networkDataSource"
                                   name="network"
                                   id="network"
                                   class="tylie-select form-control"
                                   formControlName="network"
                                   [searchEnabled]="true"
                                   valueExpr="id"
                                   [showClearButton]="true"
                                   displayExpr="name"
                                   placeholder="Select a Vendor"
                                   (onValueChanged)="onPreferredNetworkChange()">
                    </dx-select-box>

                    <!--<dx-select-box [dataSource]="networkDataSource"
                                id="network"
                                name="network"
                                class="form-control"
                                formControlName="network"
                                [searchEnabled]="true"
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Select a network...">
                  </dx-select-box>-->
                    <p class="p-extra-small">Please select Network</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Create Vendor PO</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="update-orderlineitems-status-check" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#updatestatuscheckCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Update Order lineItems to Status Check
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="updatestatuscheckCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="updateStatusCheckRequest" #oc="ngForm" (ngSubmit)="onUpdateStatusCheckFormSubmit(updateStatusCheckRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateStatusCheckRequest.controls['orderGuid'].errors && ocsubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Update Order lineItems to Status Check</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="media-new-upload">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#authOrgCreateRequestCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Create Auth Organization
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="authOrgCreateRequestCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="authOrgCreateRequest" #ao="ngForm">
              <div class="row">
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: authOrgCreateRequest.controls['organizationName'].errors && ao.submitted}">
                    <label class="tylie-form-group__lbl" for="adid">Organization Name<span class="required">*</span></label>
                    <input type="text" name="organizationName" id="organizationName" class="tylie-text form-control" formControlName="organizationName" />
                    <p class="p-extra-small">Please enter Organization Name</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: authOrgCreateRequest.controls['organizationDomain'].errors && ao.submitted}">
                    <label class="tylie-form-group__lbl" for="title">Organization Domain<span class="required">*</span></label>
                    <input type="text" name="organizationDomain" id="organizationDomain" class="tylie-text form-control" formControlName="organizationDomain" />
                    <p class="p-extra-small">Please enter Organization Domain</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: authOrgCreateRequest.controls['organizationId'].errors && ao.submitted}">
                    <label class="tylie-form-group__lbl" for="title">Organization ID<span class="required">*</span></label>
                    <input type="text" name="organizationId" id="organizationId" class="tylie-text form-control" formControlName="organizationId" />
                    <p class="p-extra-small">Please enter Organization ID</p>
                  </div>
                </div>
              </div>

              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAuthOrgFormSubmit(authOrgCreateRequest)">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">CREATE Auth Organization</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-cancel" secured [accessControlListComponent]="[this.userRoles.getITDevRoleName()]">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#encryptTextCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Encrypt Text
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="encryptTextCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="encryptTextRequest" #et="ngForm" (ngSubmit)="onEncryptTextFormSubmit(encryptTextRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: encryptTextRequest.controls['plainText'].errors && et.submitted == true}">
                    <label class="tylie-form-group__lbl" for="plainText">Plain Text<span class="required">*</span></label>
                    <input type="text" id="plainText" name="plainText" class="tylie-text form-control" formControlName="plainText">
                    <p class="p-extra-small">Please enter plin text you would like to encrypt</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon mb-4">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Encrypt</span>
              </button>

              <div class="row">
                <div class="col-md-12">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="protectedText">Encrypted Text:</label>
                    <span id="protectedTextCp">{{ encryptTextRequest.controls['protectedText'].value }}</span>
                  </div>
                </div>
              </div>

              <button class="tylie-button tylie-button--xs" type="button" (click)="copyText(encryptTextRequest.controls['protectedText'].value)">Copy Encrypted Text</button>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="tylie-accordion__panel accordion-item" id="order-product">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#updateOrderProductCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          Update Order Product
        </button>
      </h2>
      <div class="tylie-accordion__content accordion-collapse collapse show" id="updateOrderProductCollapseOne">
        <div class="tylie-accordion__body accordion-body">
          <div class="tylie-accordion__body-content py-4">
            <form [formGroup]="updateOrderProductRequest" #os="ngForm" (ngSubmit)="onUpdateOrderProductFormSubmit(updateOrderProductRequest)">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderProductRequest.controls['orderGuid'].errors && opsubmitted == true}">
                    <label class="tylie-form-group__lbl" for="orderGuid">Order Guid<span class="required">*</span></label>
                    <input type="text" id="orderGuid" name="orderGuid" class="tylie-text form-control" formControlName="orderGuid">
                    <p class="p-extra-small">Please enter Order Guid</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderProductRequest.controls['product'].errors && opsubmitted == true }">
                    <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
                    <dx-select-box [dataSource]="orderProductDataSource"
                                   name="product" id="product" class="tylie-select form-control"
                                   formControlName="product"
                                   [searchEnabled]="true"
                                   valueExpr="id"
                                   [showClearButton]="true"
                                   displayExpr="name"
                                   placeholder="Select a product...">
                    </dx-select-box>

  <!--<dx-select-box [dataSource]="networkDataSource"
                                   name="network"
                                   id="network"
                                   class="tylie-select form-control"
                                   formControlName="network"
                                   [searchEnabled]="true"
                                   valueExpr="id"
                                   [showClearButton]="true"
                                   displayExpr="name"
                                   placeholder="Select a Vendor"
                                   (onValueChanged)="onPreferredNetworkChange()">
                    </dx-select-box>-->

                    <p class="p-extra-small">Please select Network</p>
                  </div>
                </div>
              </div>

              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">Update Order Product</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
