import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { UserRoles } from "../../../configurations/user-roles";
import { RouteConstants } from "../../../configurations/route-constants";

@Component({
    selector: 'client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit{    
  constructor(public util: Utilities
    , private router: Router
    ,public userRoles: UserRoles) {
    
  }

  ngOnInit() { }


  public gotoCreateNewClient() {
    this.router.navigate([RouteConstants.createClientsRoute]);
  }

}
