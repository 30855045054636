import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-tab-archive-ad',
  templateUrl: './icon-tab-archive-ad.component.html',
  styleUrls: ['./icon-tab-archive-ad.component.css']
})
export class IconTabArchiveAdComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string = '#fff';

  constructor() { }

  ngOnInit(): void {
  }

}
