import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'icon-question-mark',
  templateUrl: './icon-question-mark.component.html',
  styleUrls: ['./icon-question-mark.component.css']
})
export class IconQuestionMarkComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#ffffff';
  constructor() { }

  ngOnInit() {
  }

}
