<div class="tylie-accordion accordion" id="vendor-orderfailure-section">
  <div class="tylie-accordion__panel accordion-item" id="recentlyviewedPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#orderFailureCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        Vendor Submission Failed
      </button>
    </h2>
    <div id="orderFailureCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">

        <div class="tylie-accordion__body-bar">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
        </div>
        <div class="tylie-accordion__body-content">
        <dx-data-grid id="orderFailureGrid" class="tylie-grid"
                      #orderFailureGrid
                      [dataSource]="orderFailureStore"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [showBorders]="true"
                      [hoverStateEnabled]="true"
                      (onRowPrepared)="onRowPrepared($event)"
                      [rowAlternationEnabled]="true">
          <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
          <dxi-column dataField="workOrder" caption="Order #" cellTemplate="workOrderTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
          <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="17%"></dxi-column>
          <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="17%"></dxi-column>
          <dxi-column dataField="clientPurchaseOrder" caption="Client Purchase Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="14%"></dxi-column>
          <dxi-column dataField="claimedByUserProfileId" caption="Claimed By" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="14%"></dxi-column>
          <dxi-column dataField="orderBuiltBy" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="14%"></dxi-column>
          <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="13%"></dxi-column>
          <dxi-column dataField="orderGuid" cellTemplate="actionTemplate" caption="Actions" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding"></dxi-column>

          <div *dxTemplate="let t of 'workOrderTemplate'">
            <span class="quickLink" (click)='showOrderDetail(t.data.orderGuid)'>{{t.data.workOrder}}</span>
            <span>
              <img *ngIf="t.data.hasReviewComments == true" src="./assets/svg/Icon-OrderReviewFail.svg" class="ordersIcons" alt="review Fail">
              <img *ngIf="t.data.isRush == true" src="./assets/svg/Icon-Rush.svg" class="ordersIcons" alt="rush">
            </span>
          </div>

          <div *dxTemplate="let t of 'actionTemplate'" class="d-flex justify-content-center">
            <div>
              <span><button *ngIf="t.data.isAlreadyClaimed == false" class="tylie-button tylie-button--xxs" (click)="claimOrderForReview(t.data.orderGuid)">CLAIM</button></span>
              <span *ngIf="t.data.isAlreadyClaimed == true" class="tyl-vertical-align">Claimed</span>
            </div>
          </div>

          <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
          </div>
          <dxo-remote-operations [sorting]="true"
                                 [paging]="true">
          </dxo-remote-operations>
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50, 100]"
                     infoText="Page {0} of {1} ({2} items)"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

