import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { Constants } from "../../configurations/constants";
import { NotificationService } from "../../services/notifications/notification.service";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import { NotificationSearchCriteria, NotificationSearchFields } from '../../models/notifications/notification-request.model';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  public dataSource: any = {};
  public notificationMessage: string = "";
  public showArchived: boolean = false;
  public quickSearchValue: string = '';
  public searchCriteriaObj = <NotificationSearchFields>{};

  @ViewChild('notificationGrid', { static: true }) notificationGrid: DxDataGridComponent;

  constructor(public util: Utilities,
    private notificationService: NotificationService,
    private alertService: AlertService) {

  }

  ngOnInit() {
    this.searchCriteriaObj = <NotificationSearchFields>{};

    this.getNotifications();
  }

  private getNotifications() {
    var notifService = this.notificationService;
    var util = this.util;
    var criteriaObj = this.searchCriteriaObj;

    this.dataSource.store = new CustomStore({
      key: "requestNotifyID",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        var showA = $('#showArchivedOnly').is(":checked");
        let request: any = { archivedOnly: showA, quickSearch: criteriaObj.quickSearch, criteria: null, skip: skip, take: take, sort: sortOptions, user: null };

        return notifService.searchNotifications(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var notificationResponse = response.result;
              var notificationResults = notificationResponse.searchResults;

              if (!showA)
                util.notificationCount = notificationResponse.unReadCount;

              var obj: any = {
                data: notificationResponse.searchResults,
                totalCount: notificationResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchCriteriaObj.quickSearch = this.quickSearchValue;
    this.notificationGrid.instance.refresh();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchCriteriaObj.quickSearch = this.quickSearchValue;
      this.setDefaultFormValues();
      this.notificationGrid.instance.refresh();

    }
  }

  public setDefaultFormValues() {
    this.searchCriteriaObj.quickSearch = null;
  }

  public onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      if (e.data.isViewed === false) {
        e.rowElement.addClass('unReadItem');
      }
    }
  }

  public onRowClick(e: any) {

    $(".dx-datagrid-table tr").removeClass("highlightSelected");

    e.rowElement.removeClass('dx-state-hover');
    e.rowElement.addClass('highlightSelected');

    this.notificationService.getMessage(e.data.requestID, e.data.requestNotifyID).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var obj = res.result;
        this.notificationMessage = obj.message;

        e.rowElement.removeClass('unReadItem');

        this.updateNotificationsCount(1);
        e.data.isViewed = true;
      }
      else {
        this.notificationMessage = null;
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.notificationMessage = null;
        this.util.handleError(error);
      });
  }

  public setViewed() {
    if (this.notificationGrid.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.noEmailsSelected, MessageSeverity.error);
      return;
    }

    this.notificationService.setViewed(this.getSelectedItemsAsArray()).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.uncheckSelectedViewedItems();

        this.alertService.showMessage("SUCCESS", Constants.notificationsSetAsViewwedSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public setArchived() {

    if (this.showArchived)
      return;

    if (this.notificationGrid.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.noEmailsSelected, MessageSeverity.error);
      return;
    }

    this.notificationService.setArchived(this.getSelectedItemsAsArray()).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.notificationsArchivedSuccessful, MessageSeverity.success);

        this.notificationGrid.instance.deselectAll();
        this.notificationGrid.instance.refresh();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getSelectedItemsAsArray(): Array<number> {
    var selectedItems: number[] = [];
    var grid = this.notificationGrid.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedItems.push(grid.getSelectedRowsData()[i].requestNotifyID);
    }

    return selectedItems;
  }

  private uncheckSelectedViewedItems(): void {
    var grid = this.notificationGrid.instance;
    var noOfItemsEffected = 0;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isViewed == false) {
        grid.getSelectedRowsData()[i].isViewed = true;
        noOfItemsEffected++;
      }
    }

    this.updateNotificationsCount(noOfItemsEffected);

    grid.repaint();

    grid.deselectAll();
  }

  private updateNotificationsCount(count: number): void {
    var showA = $('#showArchivedOnly').is(":checked");

    if (showA)
      return;

    if ((this.util.notificationCount - count) >= 0)
      this.util.notificationCount = this.util.notificationCount - count;
    else
      this.util.notificationCount = 0;
  }

  public showArchivedChange(): void {
    this.notificationMessage = null;

    this.notificationGrid.instance.refresh();

    var showA = $('#showArchivedOnly').is(":checked");
    this.showArchived = showA;
  }
}
