<p *ngIf="!spots"><em>Loading...</em></p>

<div class="tylie-accordion accordion" id="media-qc-section">
  <div class="tylie-accordion__panel accordion-item" id="mediaQcPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#mediaQcCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        Asset QC
      </button>
    </h2>
    <div id="mediaQcCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Ad-ID, Title or Session Id" name="srch-term" id="srch-term" type="search" [(ngModel)]="searchSpotsCriteriaObj.quickSearch"
                  (ngModelChange)="onClearQuickQCSearch()" (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="onSearchQC()">Search</button>
                </div>
              </section>
            </div>
          </div>

          <div class="d-flex mt-3">
            <button type="button" class="tylie-button tylie-button--xs me-3" (click)="openModal(updateqc)"><span>QC Update</span></button>
            <button type="button" class="tylie-button tylie-button--icon tylie-button--xs" (click)="onRefresh()">
              <span class="tylie-button__icon"><icon-refresh></icon-refresh></span>
              <span class="tylie-button__text">REFRESH</span>
            </button>
          </div>
        </div>
        
        <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
          <dx-data-grid id="spotQCGrid"
                        #spotQCGrid
                        [dataSource]="resultSpotsStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="false"
                        [rowAlternationEnabled]="true"
                        [showColumnLines]="true"
                        [hoverStateEnabled]="true"
                        [showRowLines]="true"
                        [cacheEnabled]="false"
                        keyExpr="spotFileGuid" class="tylie-grid">
            <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
            </dxo-remote-operations>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-selection mode="multiple"
                          selectAllMode="page"
                          allowSelectAll="Select"
                          showCheckBoxesMode="always">
            </dxo-selection>
            <!--<dxi-column width="12%" dataField="sessionId" cssClass="gridStyle" [allowSorting]="false"></dxi-column>-->
            <dxi-column width="15%" caption="Ad-ID" dataField="adId" cellTemplate="adIDTemplate" cssClass="gridStyle"></dxi-column>
            <dxi-column width="20%" dataField="title" cssClass="gridStyle"></dxi-column>
            <dxi-column width="13%" dataField="client" cssClass="gridStyle"></dxi-column>
            <dxi-column width="13%" dataField="brand" cssClass="gridStyle"></dxi-column>
            <dxi-column width="13%" dataField="product" cssClass="gridStyle"></dxi-column>
            <dxi-column width="10%" caption="Duration" dataField="length" cssClass="gridStyle"></dxi-column>
            <dxi-column width="14%" dataField="createdDate" cellTemplate="dateTemplate" cssClass="gridStyle" sortOrder="desc"></dxi-column>
       

            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 15, 25, 50]"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
            </dxo-pager>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <div *dxTemplate="let t of 'adIDTemplate'">
              <span *ngIf="t.data.isViewable === true" class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
              <span *ngIf="t.data.isViewable === false">{{t.data.adId}}</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY hh:mm:ss A z'}}</div>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #updateqc>
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">QC UPDATE</h4>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="SpotComment" (ngSubmit)="saveSpotQC( )">
      <h3 class="tylie-type-h3">Please select status update</h3>
      <p class="p-extra-small black">For "Fail " status, the note field is required</p>
      <div class="tylie-form-group form-check">
        <label class="me-5">
          <input class="form-check-input" formControlName="status" type="radio" [checked]="true" value="Pass" name="status" (click)="commentValidation('Pass')"><label class="form-check-label">PASS</label>
        </label>
        <label>
          <input class="form-check-input" formControlName="status" type="radio" value="Fail" name="status" (click)="commentValidation('Fail')"><label class="form-check-label">FAIL</label>
        </label>
      </div>
      <div id="commentDiv" [ngClass]="{error: iscommentRequired}" class="tylie-form-group form-group">
        <label class="tylie-form-group__lbl" for="notes">Notes<span class="required">*</span></label>
        <textarea id="comment" formControlName="spotComment" [(ngModel)]="spotComment" (ngModelChange)="emitData()" name="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
        <p class="p-extra-small">Please enter notes</p>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs me-3" type="submit">QC Update</button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="modalClose()">Cancel</button>
      </div>
    </form>
  </div>
</ng-template>
