import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinBySeperator'
})

export class JoinBySeperatorPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string[], seperator:string): any {
    let result = value || '';

    if (value != null && value != undefined && value.length > 0) {
      result = value.join(seperator);
    }
    return result;
  }
}
