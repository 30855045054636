import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { OrderDetailHeader, OrderDetailConfig } from '../../../models/order/order-detail.model';
import { OrderService } from '../../../services/order/order.service';
import { EditOrderHeaderRequest } from '../../../models/order/order-edit-header.model';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment-timezone';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { EmailListValidatorIfValueExists, filePathValidator, transcodeFilePathValidator } from '../../../configurations/custom-validations';
import { OrderAddShareLinksPopupComponent } from '../orderaddsharelinkspopup/orderaddsharelinkspopup.component';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { EditTranscodeOrderRequest } from '../../../models/order/transcodeorder.model';
import { VaultService } from '../../../services/vault/vault.service';

@Component({
  selector: 'vaultdetailheadereditpopup',
  templateUrl: './vaultdetailheadereditpopup.component.html',
  styleUrls: ['./vaultdetailheadereditpopup.component.css'],
})
export class VaultDetailHeaderEditPopupComponent extends BasePopupComponent {

  public vaultRequestGuid: string;
  public editCategory: string;
  public editValue: any;
  public header: any;
  public config: any;

  public canAddCustom: boolean;
  public label: string;
  public controlType: string;
  public error: string;
  public maxLengthError: string;
  public maxCharLimit: number = 15;
  public placeholder: string;
  public itemsDataSource: Array<any>;
  public onEditComplete: Subject<any>;
  public editRequest: FormGroup;

  private modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private vaultService: VaultService,
    private modalService: BsModalService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.orderEditInit();
    console.log(this.config)
  }

  private orderEditInit() {
    this.onEditComplete = new Subject();

    var currentValue: any;
    var isRequired: boolean;
    var isMaxLengthValidator: boolean;
    var isSubmitted: boolean = false;
    var airDateStart: Date = null;
    var airDateEnd: Date = null;
    this.canAddCustom = false;
    this.placeholder = '';

    if (this.editCategory === 'VaultJobNumber') {
      currentValue = this.header.clientJobNumber;
      this.error = 'Please enter Client Job Number';
      isRequired = true;
      this.controlType = 'text';
      this.label = "Client Job Number";
    }
    else if (this.editCategory === 'VaultReference') {
      currentValue = this.header.reference;
      this.error = 'Please enter Reference';
      isRequired = false;
      this.controlType = 'text';
      this.label = "Reference";
    }
    else if (this.editCategory === 'VaultReceivedFrom') {
      currentValue = this.header.receivedFrom;
      this.error = 'Please enter Received From';
      isRequired = false;
      isMaxLengthValidator = false;
      this.controlType = 'text';
      this.label = "Received From";
    }
    else if (this.editCategory === 'VaultWareHouse') {
      currentValue = this.header.warehouse;
      this.error = 'Please select WareHouse';
      isRequired = true;
      this.controlType = 'dropdown';
      this.itemsDataSource = this.config.warehouses;
      this.label = "WareHouse";
      this.canAddCustom = false;
    }
    else if (this.editCategory === 'VaultFormat') {
      currentValue = this.header.formatId;
      this.error = 'Please select Format';
      isRequired = true;
      this.controlType = 'dropdown';
      this.itemsDataSource = this.config.formats;
      this.label = "Format";
      this.canAddCustom = false;
    }
    else if (this.editCategory === 'VaultConfirmationEmail') {
      currentValue = this.header.confirmationEmails;
      this.error = 'Please enter valid Emails seperated by semi-colon';
      isRequired = false;
      this.controlType = 'podemails';
      this.label = "Confirmation Emails";
    }

    this.editRequest = new FormGroup({
      newValue: (isRequired) ? new FormControl(currentValue, Validators.required) : new FormControl(currentValue),
      editCategory: new FormControl(this.editCategory)
    });

    if (this.editCategory === 'VaultConfirmationEmail') {
      this.editRequest.controls["newValue"].setValidators([EmailListValidatorIfValueExists]);

      this.editRequest.updateValueAndValidity();
    }

    if (isMaxLengthValidator) {
      if (isRequired) {
        this.editRequest.controls["newValue"].setValidators([Validators.required, Validators.maxLength(this.maxCharLimit)]);
      }
      else {
        this.editRequest.controls["newValue"].setValidators([Validators.maxLength(this.maxCharLimit)]);
      }
      this.editRequest.updateValueAndValidity();
    }

  }


  public onAddMorePODEmails() {
    var initialState = {
      podMode: true
    };

    this.modalRef = this.modalService.show(OrderAddShareLinksPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w60"));

    this.modalRef.content.onClose.subscribe(result => {
      this.onAddPODEmailsSubscriptionRaised(result);
    });
  }

  private onAddPODEmailsSubscriptionRaised(allDestinationsSelected: SelectedOrderDestinations) {

    if (allDestinationsSelected === null)
      return;

    var selectedUserDistributionLists = allDestinationsSelected.shareLinks;

    if (selectedUserDistributionLists && selectedUserDistributionLists != null && selectedUserDistributionLists.length > 0) {
      var existingPODEmails: string = this.editRequest.controls['newValue'].value;
      var newEmails: Array<string> = [];

      if (existingPODEmails && existingPODEmails != null && existingPODEmails.trim() != '') {

        existingPODEmails = existingPODEmails.trim();

        if (existingPODEmails.endsWith(';') || existingPODEmails.endsWith(','))
          existingPODEmails = existingPODEmails.slice(0, -1);

        newEmails.push(existingPODEmails);
      }

      selectedUserDistributionLists.forEach(item => {
        item.groupEmails.forEach(em => {
          newEmails.push(em);
        });
      });

      this.editRequest.controls['newValue'].setValue(newEmails.join(';'));
    }
  }

  public onSave({ value, valid }: { value: any, valid: boolean }) {
    this.editVaultHeader(value);
  }

  private editVaultHeader(value: any) {
    if (this.editRequest.controls['newValue'].errors || this.editRequest.controls['newValue'].hasError('inValidEmailList'))
      return;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var request = null;
    if (value.editCategory == 'VaultJobNumber') {
      this.header.clientJobNumber = value.newValue;
      request = { clientJobNumber: value.newValue, editCategory: this.editCategory}
    }
    else if (value.editCategory == 'VaultReference') {
      this.header.reference = value.newValue;
      request = { reference: value.newValue, editCategory: this.editCategory }
    }
    else if (value.editCategory == 'VaultReceivedFrom') {
      this.header.receivedFrom = value.newValue;
      request = { receivedFrom: value.newValue, editCategory: this.editCategory }
    }
    else if (value.editCategory == 'VaultWareHouse') {
      this.header.warehouse = value.newValue;
      request = { warehouse: value.newValue, editCategory: this.editCategory }
    }
    else if (value.editCategory == 'VaultFormat') {
      this.header.format = value.newValue;
      request = { format: value.newValue, editCategory: this.editCategory }
    }
    else if (value.editCategory == 'VaultConfirmationEmail') {
      this.header.confirmationEmails = value.newValue;
      request = { confirmationEmails: value.newValue, editCategory: this.editCategory }
    }

    console.log(this.header);
    this.vaultService.updateVaultRequestDetailHeader(this.vaultRequestGuid, request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderVaultHeaderUpdatedSuccessfully, MessageSeverity.success);
        console.log(res);
        this.onEditComplete.next(res.result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.closePopup();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
