import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { TaggerService } from '../../../services/tagger/tagger.service';
import { Utilities } from '../../../services/core/utilities';
import { CreateLocationModel, GroupModel, LocationModel, LocationValidationModel, StateModel, UpdateLocationGroupModel } from '../../../models/tagger/tagger.model';
import { Constants } from '../../../configurations/constants';

@Component({
  selector: 'app-add-locations-popup',
  templateUrl: './add-locations-popup.component.html',
  styleUrls: ['./add-locations-popup.component.css']
})
export class AddLocationsPopupComponent implements OnInit {
  @Input() isEditLocation: boolean = false;
  @Input() isAddLocation: boolean = false;
  @Input() location: LocationModel;
  @Input() isEditLocationUsers: boolean = false;
  @Input() isEditLocationGroups: boolean = false;

  public locationFormGroup: FormGroup;
  public modalTitle: string;
  public onClose: Subject<boolean>;
  public stateDataSource: StateModel[];
  public groupDataSource: GroupModel[];
  public selectedGroups: any[] = [];
  public isFormSubmitted: boolean = false;
  public isDuplicateLocationName: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private taggerService: TaggerService,
    public util: Utilities,
    private fb: FormBuilder
  ) {
    this.initForm();
  }


  ngOnInit(): void {
    this.onClose = new Subject();
    this.getStates();
    this.getGroups();

    if (this.isAddLocation) {
      this.modalTitle = "ADD NEW LOCATION";
      this.addUser();
    }

    if (this.isEditLocation) {
      this.modalTitle = "EDIT LOCATION INFO";
      this.locationFormGroup.patchValue({
        name: this.location.name,
        address: this.location.address,
        city: this.location.city,
        state: this.location.state,
        zipCode: this.location.zipCode
      });
    }

    if (this.isEditLocationUsers) {
      this.modalTitle = "EDIT LOCATION USERS";
      this.location.locationUsers.forEach((user) => {
        const userGroup = this.fb.group({
          userName: [user.userName],
          firstName: [user.userDetail.firstName],
          lastName: [user.userDetail.lastName],
          title: [user.title],
          phoneNumber: [user.userDetail.workPhone],
          isNewUser: [false],
          isEditUser: [true],
          userProfileId: [user.userDetail.userProfileId],
          userProfileGuid: [user.userDetail.userProfileGuid],
          isExistingTandemUser: new FormControl(false)
        });

        this.usersFormArray.push(userGroup);
      });
    }

    if (this.isEditLocationGroups) {
      this.modalTitle = "EDIT LOCATION GROUPS";
      this.selectedGroups = this.location.groups.map(group => group.groupGuid);
    }

  }

  private initForm(): void {
    this.locationFormGroup = this.fb.group({
      name: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      zipCode: new FormControl(null, Validators.required),
      users: this.fb.array([]),
      groupGuids: this.fb.array([]),
    });
  }

  get usersFormArray(): FormArray {
    return this.locationFormGroup.get('users') as FormArray;
  }

  addUser(): void {
    this.isFormSubmitted = false;
    const userGroup = this.fb.group({
      userProfileId: [''],
      userProfileGuid: [''],
      userName: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      title: [null, Validators.required],
      phoneNumber: [null],
      isNewUser: [true],
      isEditUser: [false],
      isExistingTandemUser: new FormControl(false)
    });

    this.usersFormArray.push(userGroup);
  }

  removeUser(index: number): void {
    this.usersFormArray.removeAt(index);
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public getStates() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getStates().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.stateDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getGroups() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getGroups().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.groupDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  selectGroup(group) {
    if (this.selectedGroups.includes(group.groupGuid)) {
      this.selectedGroups = this.selectedGroups.filter(groupGuid => groupGuid !== group.groupGuid);
    } else {
      this.selectedGroups.push(group.groupGuid);
    }
  }

  selectAll() {
    this.selectedGroups = this.groupDataSource.map(group => group.groupGuid);
  }

  private validateLocationName(): boolean {
    const name = this.locationFormGroup.get("name").value;
    return name !== null && name !== "";
  }

  onFormSubmit() {
    this.isFormSubmitted = true;
    if (this.isAddLocation) {
      if (!this.validateLocationName()) {
        this.locationFormGroup.get("name").invalid;
        this.locationFormGroup.get("name").errors.required;
        this.locationFormGroup.get("name").markAsDirty();
        return;
      }

      if (!this.locationFormGroup.valid) {
        return;
      } else {
        this.validateDuplicateLocation();
        if (this.isDuplicateLocationName) {
          return;
        }
      }
    } else if (this.isEditLocation) {
      if (!this.locationFormGroup.valid) {
        return;
      }
      const updatePayload = this.locationFormGroup.value as CreateLocationModel;
      updatePayload.groupGuids = this.selectedGroups;
      updatePayload.locationGuid = this.location.locationGuid;
      this.alertService.ShowLoader(true);
      this.taggerService.updateLocation(updatePayload).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Success", Constants.success_update_location, MessageSeverity.success, true);
          this.onClose.next(true);
          this.closePopup();
        } else {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Failed", res.errors.length > 0 ? res.errors[0] : Constants.failed_update_location, MessageSeverity.error, true);
        }
      });
    }
    else if (this.isEditLocationGroups) {
      const updateGroupPayload: UpdateLocationGroupModel = {
        locationGuid: this.location.locationGuid,
        groupGuids: this.selectedGroups
      }

      this.alertService.ShowLoader(true);
      this.taggerService.updateLocationGroups(updateGroupPayload).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Success", Constants.success_update_location_groups, MessageSeverity.success, true);
          this.onClose.next(true);
          this.closePopup();
        } else {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Failed", res.errors.length > 0 ? res.errors[0] : Constants.failed_update_location_groups, MessageSeverity.error, true);
        }
      });
    }
  }

  validateDuplicateLocation() {
    const name = this.locationFormGroup.controls["name"];
    if (!name) {
      this.isDuplicateLocationName = false;
      return;
    }

    const payload: LocationValidationModel = {
      name: name.value,
    }

    this.taggerService.validateDuplicateLocation(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.isDuplicateLocationName = res.result;
          if (!this.isDuplicateLocationName) {
            this.addLocation();
          }
        } else {
          this.isDuplicateLocationName = false;
        }
      },
      () => {
        this.isDuplicateLocationName = false;
      }
    );
  }

  addLocation() {
    const payload = this.locationFormGroup.value as CreateLocationModel;
    payload.groupGuids = this.selectedGroups;
    this.alertService.ShowLoader(true);
    this.taggerService.addLocation(payload).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_add_location, MessageSeverity.success, true);
        this.onClose.next(true);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", res.errors.length > 0 ? res.errors[0] : Constants.failed_add_location, MessageSeverity.error, true);
      }
    });
  }

}
