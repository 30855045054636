<div class="tylie-accordion accordion" id="search-all-media-section">
  <div class="tylie-accordion__panel accordion-item" id="mediaSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#mediaSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH ALL ASSETS
      </button>
    </h2>
    <div id="mediaSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">

        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                         (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>

            </div>
            <div class="col">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed"
                      data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">
                Advanced Search<span class="tylie-button__icon e">
                  <icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down>
                </span>
              </button>
            </div>
          </div>
        </div>


        <div class="tylie-section collapse" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong></div>
          <form novalidate (ngSubmit)="onFormSubmit(searchRequest)" [formGroup]="searchRequest" role="search">
            <div class="row">
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="adID">Ad-ID</label>
                  <input name="adID" type="text" class="tylie-text form-control" id="adID" formControlName="adID">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="title">Title</label>
                  <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title">
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                 formControlName="status"
                                 class="tylie-select form-control" name="status" id="status"
                                 showClearButton="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group">
                  <label class="tylie-form-group__lbl" for="client">Client</label>
                  <dx-select-box [dataSource]="clientDataSource"
                                 name="client" id="client" class="tylie-select form-control"
                                 formControlName="client"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 [showClearButton]="showClientClear"
                                 displayExpr="name"
                                 (onValueChanged)="onClientValueChanged($event)"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="brand">Brand</label>
                  <dx-drop-down-box [(value)]="brandValue"
                                    formControlName="brands"
                                    id="brand" class="tylie-select form-control"
                                    valueExpr="id"
                                    displayExpr="name"
                                    placeholder="Select a value..."
                                    [showClearButton]="true"
                                    (onValueChanged)="syncTreeViewSelection();clearProductSelection($event);"
                                    [dataSource]="brandDataSource">
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view [dataSource]="brandDataSource"
                                    dataStructure="plain"
                                    keyExpr="id"
                                    #treeBrand
                                    [searchEnabled]="true"
                                    selectionMode="multiple"
                                    showCheckBoxesMode="normal"
                                    displayExpr="name"
                                    [selectByClick]="true"
                                    (onContentReady)="syncTreeViewSelection($event)"
                                    (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="product">Product</label>
                  <dx-drop-down-box [(value)]="productValue"
                                    formControlName="products"
                                    id="product" class="tylie-select form-control"
                                    valueExpr="id"
                                    displayExpr="name"
                                    placeholder="Select a value..."
                                    [showClearButton]="true"
                                    (onValueChanged)="syncTreeViewSelectionProduct();"
                                    [dataSource]="productDataSource"
                                    [disabled]="disableProducts">
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view [dataSource]="productDataSource"
                                    dataStructure="plain"
                                    keyExpr="id"
                                    #treeBrandProduct
                                    [searchEnabled]="true"
                                    selectionMode="multiple"
                                    showCheckBoxesMode="normal"
                                    displayExpr="name"
                                    [selectByClick]="true"
                                    (onContentReady)="syncTreeViewSelectionProduct($event)"
                                    (onItemSelectionChanged)="treeView_productSelectionChanged($event)">
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </div>

              <div class="col-2">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cf">Created From</label>
                  <dx-date-box type="date" formControlName="createdFrom" id="cf"
                               displayFormat="MM/dd/yyyy"
                               class="tylie-datepicker form-control" [showClearButton]="true"></dx-date-box>
                </div>
              </div>
              <div class="col-2">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="ct">Created To</label>
                  <dx-date-box type="date" formControlName="createdTo" id="ct"
                               displayFormat="MM/dd/yyyy"
                               class="tylie-datepicker form-control" [showClearButton]="true"></dx-date-box>
                </div>
              </div>
              <div class="col-2">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="mediatype">Asset Type</label>
                  <dx-select-box [dataSource]="mediatypeDataSource"
                                 name="mediatype"
                                 id="mediatype"
                                 class="tylie-select form-control"
                                 formControlName="mediaType"
                                 [showClearButton]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-2">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="format">Format</label>
                  <dx-select-box [dataSource]="formatDataSource"
                                 name="format" id="format" class="tylie-select form-control"
                                 formControlName="format"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a value...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="customTagValue">Custom Tag / Value</label>
                  <input name="customTagValue" type="text" class="tylie-text form-control" id="customTagValue" formControlName="customTagValue">
                </div>
              </div>

              <div class="col-4">
                <div class="tylie-form-group form-group" *ngIf="searchConfig.showQcCompletedBy">
                  <label class="tylie-form-group__lbl" for="qcCompletedBy">QC Completed By</label>
                  <input name="qcCompletedBy" type="text" class="tylie-text form-control" id="qcCompletedBy" formControlName="qcCompletedBy">
                </div>
              </div>

              <div class="col-2">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="expiredOnly" name="expiredOnly" id="expiredOnly">
                  <label class="form-check-label" for="expiredOnly">Expired Only</label>
                </div>
              </div>

              <div class="col-2">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="activeOnly" name="activeOnly" id="activeOnly">
                  <label class="form-check-label" for="activeOnly">Active Only</label>
                </div>
              </div>

              <div class="col-2">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" class="form-check-input" formControlName="archivedOnly" name="archivedOnly" id="archivedOnly">
                  <label class="form-check-label" for="archivedOnly">Archived Only</label>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>

        <div class="px-4 py-3 d-flex">
          <button type="button" *ngIf="searchConfig.showOnlyBasicActions == false" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="showChoosePlayListPopup()"><span class="tylie-button__icon"><icon-playlist-add></icon-playlist-add></span><span class="tylie-button__text">Add to playlist</span></button>
          <button type="button" *ngIf="searchConfig.isMasterShareVisible" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="shareMasterMedia()"><span class="tylie-button__icon"><icon-envelope-share></icon-envelope-share></span>
          <span class="tylie-button__text">
           {{searchConfig.showOnlyBasicActions ? 'Order High Res Master' : 'Share Master'}}
           </span>
          </button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="shareSpots()">
            <span class="tylie-button__icon"><icon-envelope-share></icon-envelope-share></span><span class="tylie-button__text">{{searchConfig.isMasterShareVisible && searchConfig.showOnlyBasicActions ? 'Share Low Res File' : 'Share'}}</span>
          </button>
          <button type="button" *ngIf="searchConfig.showOnlyBasicActions == false" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="exportSpots()"><span class="tylie-button__icon"><icon-export></icon-export></span><span class="tylie-button__text">Export</span></button>
          <button type="button" *ngIf="searchConfig.showOnlyBasicActions == false" class="tylie-button tylie-button--xs tylie-button--icon me-3" secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]" (click)="expireSpots()"><span class="tylie-button__icon"><icon-expire></icon-expire></span><span class="tylie-button__text">Expire</span></button>
          <button type="button" *ngIf="searchConfig.showOnlyBasicActions == false" class="tylie-button tylie-button--xs tylie-button--icon me-3" secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]" (click)="deletePlaceholders()"><span class="tylie-button__icon"><icon-delete></icon-delete></span><span class="tylie-button__text">Delete Placeholder</span></button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" secured [accessControlListComponent]="[this.userRoles.getMediaDownloadRoleName()]" (click)="downloadSpots()"><span class="tylie-button__icon"><icon-download></icon-download></span><span class="tylie-button__text">Download Master</span></button>
          <button type="button" *ngIf="searchConfig.showOnlyBasicActions == false" class="tylie-button tylie-button--xs tylie-button--icon" secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]" (click)="restoreFiles()"><span class="tylie-button__icon"><icon-restore></icon-restore></span><span class="tylie-button__text">Restore</span></button>
          <!--<button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="manageGridActions()">Manage Grid Actions</button>-->
        </div>

        <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
          <dx-data-grid id="spotSearch"
                        #spotSearch
                        [dataSource]="resultSpotsStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="spotFileGuid"
                        [rowAlternationEnabled]="true"
                        (onExporting)="onExporting()"
                        (onExported)="onExported()"
                        (onCellPrepared)="onCellPrepared($event)"
                        class="tylie-grid"
                        [columns]="gridColumns"
                        [masterDetail]="{ enabled: true, template: 'filesTemplate', autoExpandAll: false }">
            <dxo-remote-operations [sorting]="true"
                                   [paging]="true">
            </dxo-remote-operations>
            <dxo-selection *ngIf="allowMultiSelection" mode="multiple"
                           selectAllMode="page"
                           allowSelectAll="Select"
                           showCheckBoxesMode="always">
            </dxo-selection>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxo-export [enabled]="false" fileName="Spots" [allowExportSelectedData]="true"></dxo-export>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <!--<dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" caption="Thumbnail" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>

  <dxi-column dataField="adId" caption="Ad-ID" cellTemplate="adIDTemplate" width="10%" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="title" width="17%" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="format" [allowSorting]="false" cellTemplate="formatTemplate" cssClass="gridStyle" width="10%"></dxi-column>

  <dxi-column dataField="audioFormat" caption="Audio Format" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="spotTrac" caption="Spot Trac" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="veilEncode" caption="Veil Encode" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="subtitles" caption="Subtitles" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="centerCutProtected" caption="Center Cut Protected" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="descriptiveVideo" caption="Descriptive Video" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="broadcast" caption="Broadcast" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="web" caption="Web" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="taggable" caption="Taggable" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="generic" caption="Generic" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="runningFootage" caption="Running Footage" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>

  <dxi-column dataField="fileName" caption="FileName" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="masterFileName" caption="FileName" width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>
  <dxi-column dataField="storageKey" caption=" " width="0%" cssClass="gridStyle" [visible]="false"></dxi-column>

  <dxi-column dataField="client" width="15%" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="brand" width="10%" cssClass="gridStyle"></dxi-column>
  <dxi-column width="10%" dataField="product" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="qcStatus" caption="QC Status" width="9%" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="length" caption="Duration" width="8%" cssClass="gridStyle"></dxi-column>
  <dxi-column dataField="createdDate" cellTemplate="dateTemplate" width="11%" cssClass="gridStyle" sortOrder="desc"></dxi-column>-->

            <div *dxTemplate="let t of 'thumbnailTemplate'" id="search-media">
              <div class="tylie-img-container tylie-img-container--thumbnail" *ngIf="t.data.versionCount > 0">
                <span class="tylie-img-container__pill">{{t.data.versionCount}}</span>
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />

              </div>
              <div class="tylie-img-container tylie-img-container--thumbnail" *ngIf="t.data.versionCount == 0">
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
              </div>
            </div>
            <div *dxTemplate="let t of 'adIDTemplate'">
              <span [style.visibility]="t.data.isDistributed === true ? 'visible' : 'hidden'" class="distributedCircle"></span>
              <span *ngIf="t.data.isViewable === true" class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
              <span *ngIf="t.data.isViewable === false">{{t.data.adId}}</span>
            </div>
            <div *dxTemplate="let t of 'formatTemplate'">
              <div class="dx-datagrid-nowrap-remove">
                <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
              </div>
            </div>
            <div *dxTemplate="let t of 'filesTemplate'" class="detail-grid-margin-left">
              <dx-data-grid [showBorders]="false"
                            keyExpr="spotFileGuid"
                            [columnAutoWidth]="true"
                            [showColumnLines]="false"
                            [hoverStateEnabled]="true"
                            [showRowLines]="true"
                            (onSelectionChanged)="onSpotFilesGridSelectionChanged($event)"
                            (onContentReady)="onSpotFilesGridContentReady($event)"
                            [dataSource]="t.data.spotFiles">
                <dxo-selection mode="multiple"
                               selectAllMode="page"
                               allowSelectAll="false"
                               showCheckBoxesMode="always">
                </dxo-selection>

                <div *dxTemplate="let data of 'childDateTemplate'">
                  <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
                </div>
                <div *dxTemplate="let t of 'titleTemplate'">
                  <span *ngIf="t.data.isViewable === true" class="quickLink" (click)='showDetail(t.data.spotFileGuid)' [title]="t.data.title">{{t.data.title | truncateByLetters : 45}}</span>
                  <span *ngIf="t.data.isDownloadable === true" class="quickLink"
                        outsideClick="true" placement="right" #delDownloadpop="bs-popover" container="body" [popover]="downLoadOther"
                        [popoverContext]="t.data" [title]="t.data.title">{{t.data.title | truncateByLetters : 35}}&nbsp;<i class="glyphicon glyphicon-download"></i></span>

                  <span *ngIf="t.data.isDownloadable === false && t.data.isViewable === false" [title]="t.data.title">{{t.data.title | truncateByLetters : 45}}</span>
                </div>
                <div *dxTemplate="let t of 'fileTemplate'">
                  <span *ngIf="t.data.isPlayable === true" class="quickLink" (click)="quickPlay(t.data.proxyUrl, t.data.title, '')">{{t.data.fileName}}&nbsp;<i class="glyphicon glyphicon-play-circle"></i></span>
                  <span *ngIf="t.data.isPlayable === false">{{t.data.fileName}}</span>
                </div>
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxi-column dataField="title" [allowSorting]="false" width="22%" cellTemplate="titleTemplate" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="fileName" width="50%" caption="File" [allowSorting]="false" cellTemplate="fileTemplate" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="mediaType" width="8%" caption="Type" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="status" width="10%" [allowSorting]="false" cssClass="gridStyle1"></dxi-column>
                <dxi-column dataField="created" width="10%" [allowSorting]="false" cellTemplate="childDateTemplate" cssClass="gridStyle1"></dxi-column>
              </dx-data-grid>
              <div class="panel-footer panel-footer-master-child-row"></div>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>

            <div *dxTemplate="let data of 'dateTemplate1'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                       [allowedPageSizes]="[10, 20, 50, 100]"
                       infoText="Page {0} of {1} ({2} items)"
                       [showNavigationButtons]="true"
                       [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #downLoadOther let-storageKey="storageKey" let-storageType="storageType" let-fileName="fileName">
  <!-- <div class='p-normal'>
    <p class='black'>
      Download this file?
    </p>
    <i (click)="onHideDownloadPop()"></i>
  </div>

  <div>
    <button class='btn tyl-btn btn-extra-small' (click)="downloadOtherFile(storageKey, storageType, fileName)">Yes</button>
  </div> -->

  <button class="popover__close" (click)="onHideDownloadPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header"> Download this file?</header>
  <button class='tylie-button tylie-button--xs' (click)="downloadOtherFile(storageKey, storageType, fileName)">yes</button>

</ng-template>
