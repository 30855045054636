import { Component, ChangeDetectorRef, ViewChildren, Query, QueryList, Directive, ElementRef, HostBinding, AfterViewInit, Input, OnInit } from '@angular/core';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AlertService, MessageSeverity } from '../services/core/alert.service';
import { UserProfileService } from "../services/user/user-profile.service";
import { UserRolesNotification } from "../configurations/user-roles-notification";

@Directive({
  selector: '[notificationPref]'
})

export class notificationPrefDirective implements AfterViewInit {

  @Input() displayPreference: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eltRef: ElementRef,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private userRolesNotification: UserRolesNotification) {
  }

  ngAfterViewInit() {

    let hasAccess: boolean = false;
    let userRoles: string[] = this.userProfileService.getUserRoles();
    if (this.displayPreference != undefined) {
      for (let ur of userRoles) {
        if (this.userRolesNotification.getNotification(ur).indexOf(this.displayPreference) >= 0) {
          hasAccess = true;
        }
        if (hasAccess) { break }
      }
    }

    if (!hasAccess) {
      let el: HTMLElement = this.eltRef.nativeElement;

      if (el != null && el.parentNode != null)
        el.parentNode.removeChild(el);
    }
  }
}
