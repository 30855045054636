import { OrderDetailAction } from "./order-detail.model";

export class OrderDestination {
  public id: number;
  public destinationGuid: string;
  public code: string;
  public name: string;
  public aka: string;
  public destinationType: string;
  public fileDeliveryType: string;
  public isTrafficOnly: boolean;
  public isFTP: boolean;
  public isShareLinks: boolean;
  public isEmailAddressableOnly: boolean;
  public isTied: boolean;
  public isNonVendor: boolean;
  public tiedID: string;
  public keyword: string;
  public isActive: boolean;
 
  public isAdditionalInfoRequired: boolean;

  public applyAddlInfoToAllAssets: boolean;
  public hasSpecMappings: boolean;

  public containsDestinationError: boolean;
  public errorMsg: string;
  public unableToChooseVendor: boolean;
  public noVendorDestinationMappingAvailable: boolean;
  public orderDestinationId: number;
  public isMarked: boolean;
  public destinationFormatInCompatible: boolean;
  public destinationFormatErrors: string;
  public destinationInCompatible: boolean;
  public destinationIncompatableErrors: string;
  public primaryDestinationCode: string;
  public isBackupDestination: boolean;
}

export class OrderDigitalDeliveryDestinationDetailModel {
  public orderGuid: string;
  public destinationId: number;
  public market :string;
  public orderDestinationId: number;
  public orderId: number;
  public destinationGuid: string;
  public destinationName: string;
  public serviceId: number;
  public serviceName: string;
  public destinationCode: string;
  public adId: string;
  public vendorName: string;
  public vendorId: number;
  public vendorLineItemId: number;
  public status: string;
  public statusId: number;
  public podDate: Date;
  public allowReSubmitWithDifferentVendor: boolean;
  public allowStatusUpdate: boolean;
  public retryVendorLineItemCreation: boolean;
  public allowVendorAssignment: boolean;
  public allowServiceVendorAssignment: boolean;
  public showDestinationDetail: boolean;
  public error: string;
  public allowReSendWithVendorAssignment: boolean;
  public allowPODUpdate: boolean;
}

export class VendorAssignmentModel {
  public orderGuid: string;
  public destinationGuid: string;
  public destinationCode: string;
  public adId: string;
  public applyToAllDestinations: boolean;
  public vendorId: number;
  public serviceId: number;
}

export class UpdateDeliveryPODModel {
  public orderGuid: string;
  public destinationGuid: string;
  public destinationCode: string;
  public adId: string;
  public vendorId: number;
  public applyToAllDestinations: boolean;
  public deliveryPODDate: string;
}

export class UpdateVendorOrderNumberModel {
  public orderGuid: string;
  public vendorId: number;
  public vendorOrderNumber: string;
  public vendorLineItemId: number;
}

export class OrderDetailDigitalDeliveryDestinationModel {
  public orderDigitalDeliveryDestinationDetailList: OrderDigitalDeliveryDestinationDetailModel[];
  public orderActions: OrderDetailAction;
}

export class RetryVendorProcessRequestModel {
  public orderGuid: string;
  public destinationGuid: string;
  public adId: string;
  public vendorId: number;
  public vendorLineItemId: number;
}

export class ResendMediaToDestinationModel {
  public orderGuid: string;
  public destinationGuid: string;
  public destinationCode: string;
  public adId: string;
  public vendorId: number;
  public serviceId: number;
}
