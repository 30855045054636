import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { ConfigService } from '../../../services/core/config.service';
import { emptySpacesValidator } from '../../../configurations/custom-validations';
import { AccountNameValueChangedEvent, ClientFileDeliveryConfig, CreateClientDeliveryConfig } from '../../../models/admin/client/deliveryconfig.model';


@Component({
  selector: 'addfiledeliveryconfiguration',
  templateUrl: './addfiledeliveryconfiguration.component.html',
  styleUrls: ['./addfiledeliveryconfiguration.component.css'],
})
export class AddFileDeliveryConfigurationPopUp implements OnInit {

  @Input('fileDelConfigs') fileDelConfigs: ClientFileDeliveryConfig[] = [];
  @Input('fileDelvTypes') fileDelvTypes: string[] = null;
  @Input('isEdit') isEdit: boolean = false;

  public modalRef: BsModalRef;
  public fileDeliveryConfigForm: FormGroup;
  public onSaveFileDeliveryConfiguration: Subject<CreateClientDeliveryConfig>;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService) {

    this.onSaveFileDeliveryConfiguration = new Subject<CreateClientDeliveryConfig>();
  }

  ngOnInit() {
    //this.getClientCustomTagConfig();

    this.fileDeliveryConfigForm = this.fb.group({
      fileDeliveryConfigurations: this.addFileDeliveryConfigGroupsToFormArray()
    });

    console.log(this.fileDelvTypes);
  }

  public get getFormFileDeliveryConfigGroupsFormArray() {
    return this.fileDeliveryConfigForm.get('fileDeliveryConfigurations') as FormArray;
  }

  public onAddAnotherFileDeliveryConfig() {
    const group = this.fb.group({
      id: [0],
      configType: [null, Validators.compose([Validators.required])],
      accountName: [null, Validators.compose([Validators.required, emptySpacesValidator])],
      clientId: [null, Validators.compose([Validators.required])],
      clientSecret: [null, Validators.compose([Validators.required])],
      refreshToken: [null, Validators.compose([Validators.required])],
      googleAdsLoginCustomerId: [null],
      googleAdsCustomerId: [null]
    });

    this.getFormFileDeliveryConfigGroupsFormArray.push(group);
  }

  public addFileDeliveryConfigGroupsToFormArray() {
    var fileDeliveryConfigurations = this.fb.array([]);
    if (this.fileDelConfigs != null && this.fileDelConfigs.length > 0) {
      this.fileDelConfigs.forEach(bct => {
        if (this.util.notEmpty(bct)) {
          const group = this.fb.group({
            id: [bct.id],
            configType: [bct.configType, Validators.compose([Validators.required])],
            accountName: [bct.accountName, Validators.compose([Validators.required, emptySpacesValidator])],
            clientId: [bct.clientId, Validators.compose([Validators.required])],
            clientSecret: [bct.clientSecret, Validators.compose([Validators.required])],
            refreshToken: [bct.refreshToken, Validators.compose([Validators.required])],
            googleAdsLoginCustomerId: [bct.googleAdsLoginCustomerId],
            googleAdsCustomerId: [bct.googleAdsCustomerId]
          });

          fileDeliveryConfigurations.push(group);
        }
      });
    }
    else {
      const group = this.fb.group({
        id: [0],
        configType: [null, Validators.compose([Validators.required])],
        accountName: [null, Validators.compose([Validators.required, emptySpacesValidator])],
        clientId: [null, Validators.compose([Validators.required])],
        clientSecret: [null, Validators.compose([Validators.required])],
        refreshToken: [null, Validators.compose([Validators.required])],
        googleAdsLoginCustomerId: [null],
        googleAdsCustomerId: [null]
      });

      fileDeliveryConfigurations.push(group);
    }
    return fileDeliveryConfigurations;
  }

  public onAddFileDeliveryConfigGroupFormSubmit({ value, valid }: { value: CreateClientDeliveryConfig, valid: boolean }) {

    console.log(value);

    if (!valid) {
      return;
    }

    this.onSaveFileDeliveryConfiguration.next(value);
    this.closePopup();
  }

  public verifyUniqueAccountNames(eve: AccountNameValueChangedEvent) {
    for (var i = 0; i < this.getFormFileDeliveryConfigGroupsFormArray.controls.length; i++) {
      var iVal = this.getFormFileDeliveryConfigGroupsFormArray.at(i).get('accountName').value;
      if (i != eve.formGroupNumber && iVal != null && iVal != undefined) {
        if (iVal.trim().toLowerCase() == eve.accountName.trim().toLowerCase()) {
          this.getFormFileDeliveryConfigGroupsFormArray.at(eve.formGroupNumber).get('accountName').setErrors({ "notUnique": true });
          break;
        }
      }
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClickRemoveFileDeliveryConfig(groupNumber: number) {
    this.getFormFileDeliveryConfigGroupsFormArray.removeAt(groupNumber);
  }
}
