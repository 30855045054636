<div class="tylie-accordion accordion" [formGroup]="groupService">
  <div class="tylie-accordion__panel accordion-item">
    <!-- <div class="panel-heading">
      <h4 class="panel-title" data-toggle="collapse" [attr.data-target]="'#' + attributeId">
        <a class="p-large">GROUP {{groupService.controls['orderGroupSequenceId'].value}}</a>
        &nbsp;
        <span class="p-normal">{{groupService.controls['serviceName'].value}}</span>
        <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onClickDeleteOrderGroupService()" *ngIf="formGroupCount+orderLvlFormGroupCount>1">
          <icon-trash-can color="#1e90ff"></icon-trash-can>
        </a>
      </h4>
    </div> -->

    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + attributeId" aria-expanded="true" aria-controls="collapseOne">
        GROUP {{groupService.controls['orderGroupSequenceId'].value}}
        <span class="p-normal ms-4">{{groupService.controls['serviceName'].value}}</span>
        <a class="tylie-button tylie-button--xs tylie-button--link ms-4" (click)="onClickDeleteOrderGroupService()" *ngIf="formGroupCount+orderLvlFormGroupCount>1">
          <icon-trash-can color="#1e90ff"></icon-trash-can>
        </a>
      </button>
    </h2>
    <div [attr.id]="attributeId" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content">
          <div class="row">
            <div class="col-md-5">
              <div class="tylie-form-group form-group " [ngClass]="{error: (groupService.controls['vendor'].errors && (!groupService.controls['vendor'].pristine || parentForm.submitted))}">
                <label class="tylie-form-group__lbl" for="vendor">Vendor<span class="required">*</span></label>
                <dx-select-box [dataSource]="groupService.controls['nonDigitalVendors'].value"
                              name="vendor" id="vendor" class="tylie-select form-control"
                              [searchEnabled]="true"
                              valueExpr="id"
                              [showClearButton]="true"
                              displayExpr="name"
                              formControlName="vendor"
                              placeholder="Select a vendor..."
                              (onValueChanged)="onVendorChange($event)">
                </dx-select-box>
                <p class="p-extra-small">Please Select a Vendor</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="cf">Due Date<span></span></label>
                <dx-date-box type="date" width="100%" id="cf" class="tylie-select form-control" formControlName="dueDate" [min]="now" [showClearButton]="true"
                            (onValueChanged)="onDueDateChange($event, groupService.controls['vendor']?.value)"></dx-date-box>
              </div>
            </div>
          </div>
          <ng-container *ngIf="groupService.controls['orderMedia']?.value?.length > 0">
            <table class="tylie-table table table-bordered mb-4">
              <thead class="tylie-table__head">
                <tr>
                  <th width="30%">Ad-ID</th>
                  <th width="55%">Title</th>
                  <th width="10%">Length</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody  class="tylie-table__body" *ngFor="let sp of groupService.controls['orderMedia'].value; let j = index; odd as isOdd">
                <tr [class.tyl-custom-grid-tr]="isOdd">
                  <td>
                    <div placement="left">
                      {{sp.adId}}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{sp.title}}
                    </div>
                  </td>
                  <td>{{sp.length}}</td>
                  <td align="center" class="blue-text p-normal">
                    <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onClickDeleteMedia(j, groupService.controls['orderMedia'].value.length)">
                      <icon-trash-can color="#1e90ff"></icon-trash-can>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="ordernotes">Notes</label>
            <textarea id="notes" name="notes" formControlName="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
