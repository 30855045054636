export class SpotPatternCheckFile {
  public file: string;
  public error: string;
}

export class SpotPatternCheck {
  public clientGuid: string;
  public ClientId: number;
  public BrandId: number;
  public ProductId: number;
  public UserGroupIds: Array<number>;
  public EditorialHouseId: number;
  public AgencyId: number;
  public isUploadTypeS3: boolean;
  public spotPatternCheckFiles: Array<SpotPatternCheckFile> = [];
}
