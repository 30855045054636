import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UpdateVaultAssetServicesResponse, VaultAssetDetail, VaultAssetService, VaultAssetServicesRequest, VaultAssetServicesResponse } from '../../../models/vault/vault-create.model';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { VaultService } from '../../../services/vault/vault.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-add-vault-asset-addition-service-popup',
  templateUrl: './add-vault-asset-addition-service-popup.component.html',
  styleUrls: ['./add-vault-asset-addition-service-popup.component.css']
})
export class AddVaultAssetAdditionServicePopupComponent extends BasePopupComponent {

  @Input('vaultAssetId') vaultAssetId: number = null;
  @Input('vaultRequestId') vaultRequestId: number = null;

  public vaultAssetAdditionServiceRequest: FormGroup;
  public onSelectAdditionalServiceVault: Subject<UpdateVaultAssetServicesResponse>;

  public form: FormGroup;
  public vaultAssetServices: VaultAssetServicesResponse;
  public modifiedVaultAssetServices: VaultAssetServicesRequest = new VaultAssetServicesRequest();


  get ordersFormArray() {
    return this.form.controls.orders as FormArray;
  }

  constructor(private vaultService: VaultService,
    public bsModalRef: BsModalRef,
    public util: Utilities,
    public modalService: BsModalService,
    private alertService: AlertService,
    private fb: FormBuilder) {
    super();
    this.onSelectAdditionalServiceVault = new Subject();
    this.form = this.fb.group({
      orders: new FormArray([])
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.getVaultAssetAdditionalServices();
  }

  private addCheckboxesToForm() {
    this.vaultAssetServices.vaultAssetServices.forEach((v) => this.ordersFormArray.push(new FormControl(v.isAssigned)));
  }


  private getVaultAssetAdditionalServices() {
    this.vaultService.getVaultAssetAdditionalServices(this.vaultAssetId, this.vaultRequestId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.vaultAssetServices = res.result as VaultAssetServicesResponse;
        this.addCheckboxesToForm();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public submit() {
    var modifiedServices = this.form.value.orders
      .map((checked, i) => {
        var vaultServ = this.vaultAssetServices.vaultAssetServices[i];
        vaultServ.isAssigned = checked;
        return vaultServ
      });
    this.modifiedVaultAssetServices.vaultAssetServices = [];
    this.modifiedVaultAssetServices.vaultAssetServices = modifiedServices;
    this.modifiedVaultAssetServices.vaultAssetId = this.vaultAssetId;
    this.modifiedVaultAssetServices.vaultRequestId = this.vaultRequestId;
    console.log(this.modifiedVaultAssetServices);

    this.updateAdditionalServices();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public updateAdditionalServices() {
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetAdditionalServices(this.modifiedVaultAssetServices).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.closePopup();
        var services = res.result as UpdateVaultAssetServicesResponse;
        this.onSelectAdditionalServiceVault.next(services);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }


}
