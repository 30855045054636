import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TaggerService } from "../../../../services/tagger/tagger.service";
import { AlertService } from "../../../../services/core/alert.service";
import { Utilities } from "../../../../services/core/utilities";
import { TaggerCard } from "../../../../models/tagger/tagger.model";

@Component({
    selector: "app-tagger-offer-card-step",
    templateUrl: "./tagger-offer-card-step.component.html",
    styleUrls: ["./tagger-offer-card-step.component.css"],
})
export class TaggerOfferCardStepComponent implements OnInit {
    @Input() topFormGroup: FormGroup;
    @Output() next: EventEmitter<any> = new EventEmitter();
    public offerCards: TaggerCard[] = [];
    public selectedOfferCards: TaggerCard[] = [];

    constructor(
        public util: Utilities,
        private taggerService: TaggerService,
        public alertService: AlertService
    ) {}

    ngOnInit() {
        this.getOfferCards();
        this.topFormGroup.get("offerCards").valueChanges.subscribe((data) => {
            this.selectedOfferCards = data;
        });
    }

    public getOfferCards() {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.taggerService.getOfferCards().subscribe(
            (res: any) => {
                if (res.isSuccess == true) {
                    this.offerCards = res.result.searchResults;
                } else {
                    this.util.handleIsNotSuccess(res.errors);
                }

                this.alertService.ShowLoader(false);
            },
            (error) => {
                this.util.handleError(error);
                this.alertService.ShowLoader(false);
            }
        );
    }

    public onSelect(offerCard: any): void {
        this.selectedOfferCards = [offerCard];
        this.topFormGroup.get("offerCards").setValue(this.selectedOfferCards);
        this.next.emit();
    }
}
