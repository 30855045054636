
import {finalize} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Utilities } from '../../../../services/core/utilities';
import { Validators } from '@angular/forms';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import { OrderService } from '../../../../services/order/order.service';
import { OrderFile } from '../../../../models/order/order-create.model';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';

@Component({
  selector: 'service-watermarkingbvs',
  templateUrl: './servicewatermarkingbvs.component.html'
})
export class ServiceWaterMarkingBVSQComponent extends BaseServiceDataCollectionComponent {

  public isUploading: boolean = false;
  public isDisabled: boolean = false;

  constructor(private orderService: OrderService,
    private uploadOrderFileService: UploadOrderFileService,
    public util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.isDisabled = this.formGroup.controls["bvsForm"].disabled;

    if (this.formGroup.get('bvsSubmitted').value === 'Yes')
      this.formGroup.get('bvsForm').setValidators([Validators.required]);
    else
      this.formGroup.get('bvsForm').clearValidators();

    if (this.formGroup.controls["bvsForm"].value == null)
      this.formGroup.controls["bvsForm"].setValue([]);

    this.formGroup.updateValueAndValidity();

    this.formGroup.get('bvsSubmitted').valueChanges.subscribe(
      (bvsSubmitted: string) => {

        if (bvsSubmitted === 'Yes')
          this.formGroup.get('bvsForm').setValidators([Validators.required]);
        else
          this.formGroup.get('bvsForm').clearValidators();

        this.formGroup.get('bvsForm').updateValueAndValidity();
      });
  }

  public onAttachFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {

      this.isUploading = true;

      let uploadFile = event.target.files[0];

      var request: UploadOrderFileRequest = {
        fileType: uploadFile.type,
        fileName: uploadFile.name,
        description: "",
        fileCategory: "serviceMetadata",
        orderGuid: this.orderGuid,
        orderDestinationId: 0
      };

      this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
        finalize(() => {
          this.isUploading = false;
        }))
        .subscribe((newFile: OrderFile) => {
          if (newFile != null && newFile != undefined) {
            this.addFilesToList(newFile);
          }
        });
    }
    else
      this.isUploading = false;
  }

  private addFilesToList(newFile: OrderFile) {
    var exisitingFiles = this.formGroup.controls["bvsForm"].value as Array<OrderFile>;

    if (exisitingFiles == null)
      exisitingFiles = [];

    exisitingFiles.push(newFile);

    this.formGroup.controls["bvsForm"].setValue(exisitingFiles);
  }

  public onRemoveFile(orderFileGuid: string) {

    var exisitingFiles = this.formGroup.controls["bvsForm"].value as Array<OrderFile>;
    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    this.formGroup.controls["bvsForm"].setValue(exisitingFiles);

    //this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => { 
    //  if (res.isSuccess == true) {
    //    var exisitingFiles = this.formGroup.controls["bvsForm"].value as Array<OrderFile>;
    //    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    //    this.formGroup.controls["bvsForm"].setValue(exisitingFiles);
    //  }

    //  this.isUploading = false;
    //},
    //  error => {
    //    this.isUploading = false;
    //    this.util.handleError(error);
    //  });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {
    console.log(orderFileGuid);

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }
}
