import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from "@angular/router";
import {
  DxDataGridComponent, DxDataGridModule, DxSelectBoxModule,
  DxSelectBoxComponent, DxDropDownBoxModule,
} from "devextreme-angular";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Utilities } from "../../../../../services/core/utilities";
import { AdminService } from "../../../../../services/admin/admin.service";
import { ConfigService } from "../../../../../services/core/config.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { AdminVendorDestinationMappingComponent } from "../../../../popups/adminvendordestinationmapping/adminvendordestinationmappingpopup.component";
import { VendorDestination } from "../../../../../models/admin/destination/createdestination.model";

@Component({
  selector: 'select-network',
  templateUrl: './select-network.component.html',
  styleUrls: ['./select-network.component.css']
})
export class SelectNetworkComponent implements OnInit {

  @Input('vendorDestinationMappings') vendorDestinationMappings: VendorDestination[] = null;
  @Input('editMode') editMode: boolean = false;
  @Input('destinationCode') destinationCode: string = null;

  @ViewChild('networkGrid', { static: true }) networkGrid: DxDataGridComponent;
  public networkDataSource: VendorDestination[] = null;
  public selectedRowKeys: number[] = [];

  public modalRef: BsModalRef;

  constructor(public util: Utilities
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private modalService: BsModalService) {

  }

  ngOnInit() {
    this.getDigitalVendor();
  }

  public getDigitalVendor() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getDigitalVendorsForDestinationMapping().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.networkDataSource = res.result as VendorDestination[];
        if (this.vendorDestinationMappings != null && this.vendorDestinationMappings.length > 0) {
          this.selectedRowKeys = this.vendorDestinationMappings.map(vd => vd.vendorId);
          this.networkDataSource.forEach(nd => {
            var existingMapping = this.vendorDestinationMappings.find(d => d.vendorId == nd.vendorId);
            if (existingMapping != undefined) {
              nd.vendorDestinationCode = existingMapping.vendorDestinationCode;
              nd.vendorDestinationId = existingMapping.vendorDestinationId;
            }
          });
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onAddMapping(data: any) {
    if (data.isDestinationMappingAvailable == true) {
      var initialState = {
        vendorId: data.vendorId,
        vendorName: data.vendorCode
      };

      this.modalRef = this.modalService.show(AdminVendorDestinationMappingComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onClose.subscribe(result => {
        data.vendorDestinationCode = result;
      });
    }
    else {
      if (this.destinationCode == null || this.destinationCode == undefined || this.destinationCode == "") {
        data.vendorDestinationCode = "Auto Mapped on Save";
        data.isDestinationMapped = true;
      }
      else {
        data.vendorDestinationCode = this.destinationCode;
        data.isDestinationMapped = true;
      }
    }
  }

  public onEditMapping(data: any) {
    if (data.isDestinationMappingAvailable == true) {
      var initialState = {
        vendorId: data.vendorId,
        vendorName: data.vendorCode,
        mappingId: data.vendorDestinationCode
      };

      this.modalRef = this.modalService.show(AdminVendorDestinationMappingComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onClose.subscribe(result => {
        data.vendorDestinationCode = result;
      });
    }
  }

  public onMappedIdDelete(vendorId: number) {
    this.networkDataSource.forEach(s => {
      if (s.vendorId == vendorId) {
        s.vendorDestinationCode = null;
        s.isDestinationMapped = false;
      }
    });
  }

  public getSelectedVendors(): VendorDestination[] {
    if (this.networkGrid != null && this.networkGrid.instance != null) {
      //return this.networkGrid.instance.getSelectedRowsData();
      //var vendorDestMapping = this.networkGrid.instance.get();
      var vendorDestMapping = this.networkDataSource.filter(vd => vd.isDestinationMapped == true || (vd.vendorDestinationCode != null
        && vd.vendorDestinationCode != undefined && vd.vendorDestinationCode.trim() != ""));
      return vendorDestMapping;
    }
    else {
      return [];
    }
  }
}
