
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { UserLogin } from '../../models/user/user-login.model';
import { LocalStoreManager } from "../../services/core/local-store-manager.service";
import { AlertService } from '../../services/core/alert.service';
import { environment } from '../../../environments/environment';
import { Utilities } from "../../services/core/utilities";
import { Constants } from "../../configurations/constants";
import { RouteConstants } from "../../configurations/route-constants";
import { MultiUserProfileModel, UserProfileSelectionModel } from "../../models/user/user.profile.model";
import { APP_CONFIG, AppConfig } from "../../app.module.config";
import { DBkeys } from '../../configurations/db-Keys';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { JwtHelper } from '../../configurations/jwt-helper';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {

  public loginStatusSubscription: Subscription;
  public isLoading = false;
  public loginErrorMsg: string = '';
  public hasMultipleprofiles: boolean = false;
  public isPasswordExpired: boolean = false;
  public isNewUser: boolean = false;
  public userProfileSelectionsList: UserProfileSelectionModel[] = [];
  public defaultAvatar: string = "";
  public avatarEnconding = Constants.avatarEncoding;
  public appVersion = environment.version;
  public currentYear: number = new Date().getFullYear();

  userLogin = new UserLogin();
  loginDisplay = false;
 
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private localStorage: LocalStoreManager,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    public util: Utilities,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authMS: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.alertService.logMessage(environment.apiBaseUrl);
    this.alertService.logMessage(environment.env);
    this.alertService.logMessage(environment.version);

    if (this.authService.activeUser && this.authService.isLoggedIn()) {
      this.authService.redirectLoginUser();
    }
  }

  ngOnInit() {
    this.defaultAvatar = this.config.defaultAvatar;
    this.userProfileSelectionsList = [];

    this.authMS.instance.enableAccountStorageEvents();

    // Check if an active account is already set before triggering login redirect
    const activeAccount = this.authService.activeUser;
    if (!activeAccount) {
      this.loginWithRedirect();
    } else {
      // If an active account is present, update the display and account status
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
    }
  }

  loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      console.log("Auth Request:", this.msalGuardConfig.authRequest);

      this.authMS.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      console.log("No Auth Request Found. Using Default Redirect.");
      this.authMS.loginRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authMS.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.activeUser;

    if (!activeAccount && this.authMS.instance.getAllAccounts().length > 0) {
      let accounts = this.authMS.instance.getAllAccounts();
      // Handle multiple accounts if necessary
      this.authMS.instance.setActiveAccount(accounts[0]);
      console.log(accounts)
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  public forgotPassword() {
    this.router.navigate([RouteConstants.forgotPasswordRoute]);
  }

  public onClickBackToLogin() {
    this.hasMultipleprofiles = false;
    this.userLogin.password = "";
    this.userLogin.email = "";
    this.userProfileSelectionsList = [];
  }

  public showLoginOnPasswordUpdate() {
    this.isPasswordExpired = false;
    this.isNewUser = false;
    this.userLogin.password = "";
    this.userLogin.email = "";
    this.loginErrorMsg = "";
  }
}
