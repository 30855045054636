<div class="search-all-media notifications-section">
  <div class="tylie-panel" id="mediaSearchPanel" *ngIf="requestOrigination == 'Transcode'">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        SELECT CREATIVE AVAILABLE FOR TRANSCODING
      </h4>
    </div>
    <div id="mediaSearchCollapseOne" class="tylie-panel__body">
      <div class="row" *ngIf="transcodeOrderConfig.allowSpecialOptions == true">
        <div class="col-md-6">
          <div class="tylie-panel tylie-panel--secondary">
            <div class="tylie-panel__header tylie-panel__header--br-thick">
              <h4 class="tylie-panel__title">
                SELECT CLIENT
              </h4>
            </div>
            <div class="tylie-panel__body">
              <div class="row">
                <div class="col-md-6">
                  <dx-select-box [dataSource]="clientDataSource"
                                name="clientId"
                                id="clientId"
                                class="tylie-select form-control"
                                [showClearButton]="true"
                                [searchEnabled]="true"
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Select Client"
                                [(value)]="clientId"
                                (onValueChanged)="onClientValueChanged($event)">
                  </dx-select-box>
                </div>

                <div class="col-md-6">
                  <button class="tylie-button tylie-button--xs" type="button"
                          [disabled]="loadClientCreativeButtonDisabled" (click)="onClickLoadCreative()">
                    Load Client Creative
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <form novalidate [formGroup]="nonBillableRequest">
            <div class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  NON-BILLABLE
                </h4>
              </div>
              <div class="tylie-panel__body">
                <div class="tylie-form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="forInternalUse" formControlName="isInternalRequest">
                    <span>Revision / Non-Billable</span>
                  </label>
                </div>

              </div>
            </div>
            

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="originalWorkOrders">Replace Existing Order(s)</label>
                  <dx-autocomplete name="originalOrderDropDown" id="originalOrderDropDown" class="tylie-select form-control"
                                   placeholder="Type three letters to search..."
                                   [minSearchLength]="3"
                                   [searchTimeout]="300"
                                   valueExpr="workOrder"
                                   [value]="workOrder"
                                   [dataSource]="originalWorkOrdersDataSource"
                                   (onSelectionChanged)="addOriginalWorkOrderToTextField($event)">
                  </dx-autocomplete>
                </div>
              </div>

              <div class="col-md-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="originalWorkOrders">&nbsp;</label>
                  <input name="originalWorkOrders" type="text" class="tylie-text form-control" id="originalWorkOrders" placeholder="List Replacement Order #" formControlName="originalWorkOrders">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="tylie-panel tylie-panel--secondary">
        <div class="tylie-panel__header tylie-panel__header--br-thick">
          <h4 class="tylie-panel__title">
            SELECT CLIENT CREATIVE
          </h4>
        </div>

        <div class="tylie-panel__body" *ngIf="showAssetSearch == false">
          <span>Please select the Client above to see their available Creative.</span>
          <hr>
        </div>
        <div class="tylie-panel__body">
          <div class="tylie-panel__body-bar">
            <div class="row" *ngIf="showAssetSearch == true">
              <div class="col-md-6">
                <section class="tylie-input-search">
                  <div class="tylie-input-search__inner">
                    <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                    <input class="tylie-input-search__text" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                         (ngModelChange)="onClearQuickSearch()" (keydown.enter)="onEnterQuickSearch()">
                  </div>
                  <div class="tylie-input-search__btn">
                    
                    <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearch()">Search</button>
                  </div>
                </section>
              </div>
              
              <div class="col-md-3">
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
              </div>
              <div class="col-md-3">
                <div class="d-flex justify-content-end">
                  <button class="tylie-button tylie-button--xs" type="button" (click)="onCopyPasteOptionSelected();">Copy Or Upload</button>
                </div>
              </div>
            </div>
          </div>
          <div class="tylie-section collapse" id="advancedSearchContainer" *ngIf="showAssetSearch == true">
            <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
            <form novalidate (ngSubmit)="onFormSubmit(searchRequest)" [formGroup]="searchRequest" role="search">
              <div class="row">
                <div class="col-md-4">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="adID">Ad-ID</label>
                    <input name="adID" type="text" class="tylie-text form-control" id="adID" formControlName="adID">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="title">Title</label>
                    <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="tylie-form-group form-group">
                    <label for="mediatype" class="tylie-form-group__lbl">Asset Type</label>
                    <dx-select-box [dataSource]="mediatypeDataSource"
                                  name="mediatype"
                                  id="mediatype"
                                  class="tylie-select form-control"
                                  formControlName="mediaType"
                                  [showClearButton]="true"
                                  valueExpr="id"
                                  displayExpr="name"
                                  placeholder="Select a value...">
                    </dx-select-box>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="format">Format</label>
                    <dx-select-box [dataSource]="formatDataSource"
                                  name="format" id="format" class="tylie-select form-control"
                                  valueExpr="id"
                                  formControlName="format"
                                  [showClearButton]="true"
                                  displayExpr="name"
                                  placeholder="Select a value...">
                    </dx-select-box>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="client">Client</label>
                    <dx-select-box [dataSource]="clientDataSource"
                                  id="client"
                                  name="client"
                                  class="tylie-select form-control"
                                  [searchEnabled]="true"
                                  formControlName="client"
                                  valueExpr="id"
                                  displayExpr="name"
                                  [(value)]="clientId"
                                  placeholder="Select a client..."
                                  disabled="true">
                    </dx-select-box>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="brand">Brand</label>
                    <dx-select-box [dataSource]="brandDataSource"
                                  class="tylie-select form-control"
                                  id="brand"
                                  name="brand"
                                  formControlName="brand"
                                  [searchEnabled]="true"
                                  valueExpr="id"
                                  displayExpr="name"
                                  [(value)]="brandId"
                                  noDataText="Please choose a client"
                                  (onValueChanged)="onBrandValueChanged($event)"
                                  placeholder="Select a brand...">
                    </dx-select-box>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl" for="product">Product</label>
                    <dx-select-box [dataSource]="productDataSource"
                                  class="tylie-select form-control"
                                  id="product"
                                  name="product"
                                  [searchEnabled]="true"
                                  formControlName="product"
                                  noDataText="Please choose a brand"
                                  valueExpr="id"
                                  displayExpr="name"
                                  [(value)]="productId"
                                  placeholder="Select a product..."
                                  (onValueChanged)="onProductValueChanged($event)">
                    </dx-select-box>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                  <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
                  <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onClearSearch()">Clear Search</button>
              </div>
            </form>
          </div>
          <div class="tylie-grid-wrapper" *ngIf="showAssetSearch == true">
            <dx-data-grid class="tylie-grid" id="mediaForTranscodingGrid"
                          #mediaForTranscodingGrid
                          [dataSource]="resultMediaForTranscodingStore"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [showBorders]="false"
                          [hoverStateEnabled]="true"
                          keyExpr="spotFileGuid"
                          [rowAlternationEnabled]="true"
                          (onContentReady)="onGridContentReady($event)"
                          (onSelectionChanged)="onGridSelectionChanged($event)">
              <dxo-remote-operations [sorting]="true"
                                     [paging]="true">
              </dxo-remote-operations>
              <dxo-selection mode="multiple"
                             selectAllMode="page"
                             allowSelectAll="Select"
                             showCheckBoxesMode="always">
              </dxo-selection>
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxo-export [enabled]="false"></dxo-export>
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
              <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" caption="Thumbnail" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
              <dxi-column dataField="adId" caption="Ad-ID" width="10%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="title" width="35%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="format" [allowSorting]="false" cellTemplate="formatTemplate" cssClass="gridStyle" width="10%"></dxi-column>
              <dxi-column dataField="client" width="15%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="brand" width="15%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="length" caption="Dur." width="5%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="created" caption="Created" cellTemplate="dateTemplate" width="10%" cssClass="gridStyle" sortOrder="desc"></dxi-column>
              <div class="tylie-img-container tylie-img-container--thumbnail-s" *dxTemplate="let t of 'thumbnailTemplate'">
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
              </div>
              <div *dxTemplate="let t of 'formatTemplate'">
                <div class="dx-datagrid-nowrap-remove">
                  <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
                </div>
              </div>
              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
              </div>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                         [allowedPageSizes]="[10, 20, 50, 100]"
                         infoText="Page {0} of {1} ({2} items)"
                         [showNavigationButtons]="true"
                         [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="media-qc" id="media-awaiting-approval-section" *ngIf="showAssetSearch == true && (requestOrigination == 'Order' || requestOrigination == 'OrderEdit')">
    <div class="tylie-panel" id="mediaApprovalPanel">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          SELECT CREATIVE IN THIS GROUP FOR TRANSCODING
        </h4>
      </div>
      <div class="tylie-panel__body">
        <dx-data-grid class="tylie-grid" id="mediaForTranscodingGrid"
                      #mediaForTranscodingGrid
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [showBorders]="false"
                      [hoverStateEnabled]="true"
                      keyExpr="spotFileGuid"
                      [rowAlternationEnabled]="true"
                      [dataSource]="resultMediaForTranscodingStore"
                      (onContentReady)="onGridContentReady($event)"
                      (onSelectionChanged)="onGridSelectionChanged($event)">
          <dxo-selection mode="multiple"
                         selectAllMode="page"
                         allowSelectAll="Select"
                         showCheckBoxesMode="always">
          </dxo-selection>
          <dxo-sorting mode="multiple"></dxo-sorting>
          <dxo-export [enabled]="false"></dxo-export>
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxi-column [allowFiltering]="false" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" caption="Thumbnail" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
          <dxi-column dataField="adId" caption="Ad-ID" width="10%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="title" width="35%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="format" [allowSorting]="false" cellTemplate="formatTemplate" cssClass="gridStyle" width="10%"></dxi-column>
          <dxi-column dataField="client" width="15%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="brand" width="15%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="length" caption="Dur." width="5%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="created" caption="Created" cellTemplate="dateTemplate" width="10%" cssClass="gridStyle" sortOrder="desc"></dxi-column>
          <div class="tylie-img-container tylie-img-container--thumbnail-s" *dxTemplate="let t of 'thumbnailTemplate'">
            <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
            <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
          </div>
          <div *dxTemplate="let t of 'formatTemplate'">
            <div class="dx-datagrid-nowrap-remove">
              <img *ngFor="let iconSrc of t.data.formatSources" class="formatIcon" [src]="iconSrc" />
            </div>
          </div>
          <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
          </div>
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50, 100]"
                     infoText="Page {0} of {1} ({2} items)"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
