<div class="tylie-panel tylie-panel--secondary">
  <div class="tylie-panel__header tylie-panel__header--br-thick">
    <h4 class="tylie-panel__title">
     Select Base Creative
    </h4>
  </div>
  <div class="tylie-panel__body">
    <div class="row">
      <div class="col-sm-4" *ngFor="let baseCreative of baseCreatives">
          <div class="tylie-card">
            <div class="tylie-card__img-wrap">
              <!-- Base Creative Thumbnail -->
              <img
                class="tylie-card__img"
                [src]="baseCreative.thumbnailDisplayUrl"
                *ngIf="baseCreative.isPlayable"
                (click)="
                  quickPlay(
                    baseCreative.proxyurl,
                    baseCreative.creativeName,
                    baseCreative.thumbnailurl,
                    baseCreative.format
                  )
                "
                alt="Image"
              />
              <div
                *ngIf="baseCreative.isPlayable"
                class="tylie-card__img-overlay"
                (click)="
                  quickPlay(
                    baseCreative.proxyurl,
                    baseCreative.creativeName,
                    baseCreative.thumbnailurl,
                    baseCreative.format
                  )
                "
              ></div>
              <img class="tylie-card__img"
                [src]="getThumbnail()"
                *ngIf="!baseCreative.thumbnailDisplayUrl"
                alt="Image"
              />
            </div>
            <div class="tylie-card__body">
              <!-- Base Creative Name -->
              <Label class="tylie-card__title">{{ baseCreative.creativeName }}</Label>
              <!-- Availability Date Range -->
              <div class="tylie-data tylie-data--block">
                <ng-container
                  *ngIf="baseCreative.expirydate; else elseAvailableTemplate"
                >
                  <span class="tylie-data__txt">Available:
                    {{
                      baseCreative.availableForOrderingFromDate | date : "M/d/yy"
                    }}
                    - {{ baseCreative.expirydate | date : "M/d/yy" }}</span
                  >
                </ng-container>
                <ng-template #elseAvailableTemplate>
                  <span class="tylie-data__txt">Available: Unlimited</span>
                </ng-template>
              </div>
              <!-- Duration -->
              <div class="tylie-data tylie-data--block">
                <span class="tylie-data__txt">Length: {{ baseCreative.durationinsecs }}</span>
              </div>
              <div class="d-flex mt-2">
                <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="onSelect(baseCreative)">
                  <span class="tylie-button__icon" ><icon-checkmark></icon-checkmark></span>
                  <span class="tylie-button__text">SELECT THIS BASE</span>
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="no-records" *ngIf="baseCreatives?.length === 0">
      <span>No Records found</span>
    </div>
  </div>
</div>

