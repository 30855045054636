import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { OrderDetailHeaderEditPopupComponent } from '../../../popups/orderdetailheadereditpopup/orderdetailheadereditpopup.component';
import { OrderDetailHeader, OrderDetailConfig, OrderDetailAction } from '../../../../models/order/order-detail.model';
import { EditOrderHeaderRequest } from '../../../../models/order/order-edit-header.model';
import { OrderService } from '../../../../services/order/order.service';
import { SelectAttachmentCategoryPopupComponent } from '../../../popups/selectattachmentcategorypopup/selectattachmentcategorypopup.component';
import { OrderFile } from '../../../../models/order/order-create.model';
import { OrderCommentsHistoryPopupComponent } from '../../../popups/ordercommentshistorypopup/ordercommentshistorypopup.component';

@Component({
  selector: 'order-header-detail',
  templateUrl: './orderheaderdetail.component.html',
  styleUrls: ['./orderheaderdetail.component.css']
})
export class OrderHeaderDetailComponent implements OnInit {

  public modalRef: BsModalRef;

  @Input() header: OrderDetailHeader;
  @Input() config: OrderDetailConfig;
  @Input() orderGuid: string;
  @Input() progressBarDivider: number;

  @Output() headerUpdated = new EventEmitter<OrderDetailAction>();

  @ViewChildren('pop') pop: QueryList<PopoverDirective>;

  constructor(public util: Utilities,
    private alertService: AlertService,
    private orderService: OrderService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    console.log(this.header);

  }

  public onAttDelete(fileName: string, orderFileGuid: string) {

    this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var attToDeleteIndex = this.header.attachments.findIndex(t => t.orderFileGuid == orderFileGuid);

        if (attToDeleteIndex >= 0)
          this.header.attachments.splice(attToDeleteIndex, 1);

        this.alertService.showMessage("SUCCESSS", fileName + ' deleted successfully', MessageSeverity.success, true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    this.onHidePop();
  }

  public onHidePop() {
    this.pop.forEach(p => {
      p.hide();
    });
  }

  public onEdit(category: string) {

    var initialState = {
      orderGuid: this.orderGuid,
      header: this.header,
      config: this.config,
      editCategory: category
    };
    console.log(initialState);

    this.modalRef = this.modalService.show(OrderDetailHeaderEditPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onEditComplete.subscribe(result => {
      this.onEditCompleteSubscriptionRaised(result);      
    });
  }

  public onEditCompleteSubscriptionRaised(result: EditOrderHeaderRequest) {
    console.log(result);
    if (result.editCategory === 'PO')
      this.header.clientPurchaseOrder = result.newValue;
    else if (result.editCategory === 'ESTIMATE')
      this.header.estimate = result.newValue;
    else if (result.editCategory === 'JOB')
      this.header.jobNumber = result.newValue;
    else if (result.editCategory === 'ORDEREDBY')
      this.header.orderedBy = result.newValue;
    else if (result.editCategory === 'COMMENT') {
      if (this.header.comments.find(cm => cm.comments == result.newValue) == undefined) {
        this.header.comments.push({ comments: result.newValue, commentType: '', created: new Date(), createdBy: '', user: this.config.userName });
        this.header.orderComments.unshift({ comments: result.newValue, commentType: '', created: new Date(), createdBy: '', user: this.config.userName });
      }
    }
    else if (result.editCategory === 'CONSOLIDATEDORDER')
      this.header.isConsolidatedOrder = result.newValue;
    else if (result.editCategory === 'PROBONO')
      this.header.isProBono = result.newValue;
    else if (result.editCategory === 'AIRDATE') {
      this.header.airDateStart = result.airDateStart;
      this.header.airDateEnd = result.airDateEnd;
    }
    else if (result.editCategory === 'REFERENCE/KEYWORDS')
      this.header.refKeywords = result.newValue;
    else if (result.editCategory === 'INVOICETO')
      this.header.inVoiceTo = result.newValue;
    else if (result.editCategory === 'COSTCENTER')
      this.header.costCenter = result.newValue;
    else if (result.editCategory === 'HUBDESTINATIONS')
      this.header.isDestinationsHubbed = result.newValue;
    else if (result.editCategory === 'FINALREVIEWREQUIRED')
      this.header.isFinalReviewRequired = result.newValue;
    else if (result.editCategory === 'ORIGINALWORKORDER')
      this.header.originalWorkOrder = result.newValue;
    else if (result.editCategory === 'PODEMAILS')
      this.header.podEmails = result.newValue;
    else if (result.editCategory === 'SPECIALVISIBILITYCLIENT') {
      if (result.newValue != null && result.newValue.length > 0) {
        this.header.specialVisibilityClient = result.newValue[0].id;
        this.header.specialVisibilityClientName = result.newValue[0].name;
      }
    }
    else if (result.editCategory === 'BILLINGONLYORDER')
      this.header.isPostDatedOrder = result.newValue;
    

    this.headerUpdated.emit(result.orderActions);
  }

  public onAddAttachments() {
    var initialState = {
      attachmentCategories: this.config.orderActions.canEditSpecialHeader == true ? this.config.attachmentCategories.filter(t => t.name == 'Purchase Order') : this.config.attachmentCategories,
      orderGuid: this.orderGuid
    };

    this.modalRef = this.modalService.show(SelectAttachmentCategoryPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onSelectAttachmentCategory.subscribe(result => {
      this.onSelectAttachmentCategorySubscriptionRaised(result);
    });
  }

  public onViewAllCxComments() {
    var initialState = {
      orderCommentsHistoryData: this.header.orderComments,
    };

    this.modalRef = this.modalService.show(OrderCommentsHistoryPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
  }


  OrderCommentsHistoryPopupComponent

  public onDownloadFile(orderFileGuid: string, fileName: string) {

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  private onSelectAttachmentCategorySubscriptionRaised(newFile: OrderFile) {
    this.header.attachments.push(newFile);
  }

  public showOrderDetail(orderGuid: string): void {

    this.util.showOrderDetailInNewWindow(orderGuid);
  }
}
