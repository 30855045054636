<header class="tylie-header">
  <h3 class="tylie-header__title">Tagger</h3>
  <nav class="tylie-header__nav">
    <ul class="tylie-header__nav-lst">
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerLocalRoleName()]">
        <a class="tylie-header__nav-lst-lnk" (click)="onClickCreativeTags(['creative-tags'])" [class.active]="activeLinks.includes('creative-tags')">CREATIVE & TAGS</a>
      </li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerLocalRoleName()]">
        <a class="tylie-header__nav-lst-lnk" (click)="onTaggerAds(['your-tagged-ads'])" [class.active]="activeLinks.includes('your-tagged-ads')">YOUR TAGGED ADS</a>
      </li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerAdminRoleName()]">
        <a class="tylie-header__nav-lst-lnk" (click)="onTaggerAssets(['your-tagger-assets'])" [class.active]="activeLinks.includes('your-tagger-assets')">YOUR TAGGER ASSETS</a>
      </li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerAdminRoleName()]">
        <a class="tylie-header__nav-lst-lnk" (click)="onLocationsAndUsers(['locations-users'])" [class.active]="activeLinks.includes('locations-users')">LOCATIONS & USERS</a>
      </li>
      <li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerAdminRoleName()]">
        <a class="tylie-header__nav-lst-lnk" (click)="onClickGroups(['groups'])" [class.active]="activeLinks.includes('groups')">GROUPS</a>
      </li>
      <!--<li class="tylie-header__nav-lst-itm" *isAuthorized="[this.userRoles.getTaggerAdminRoleName()]">
    <a class="tylie-header__nav-lst-lnk">NOTIFICATIONS</a>
  </li>-->
    </ul>
  </nav>
</header>
