import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { Utilities } from "../../../services/core/utilities";
import { SubService } from "../../../services/media/sub.service";
import { SpotService } from "../../../services/media/spot.service";
import { SpotExpiryRequest } from "../../../models/spot/spot-expiry.request.model";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { SpotExpiryResult } from "../../../models/spot/spot-expiry.result.model";
import { ErrorPopupComponent } from "../errorpopup/errorpopup.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { DxDateBoxComponent } from 'devextreme-angular';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { UploadService } from '../../../services/upload/upload.service';

@Component({
  selector: 'expirepopup',
  templateUrl: './expirepopup.component.html',
  styleUrls: ['./expirepopup.component.css'],
})
export class ExpirePopupComponent extends BasePopupComponent {

  public spots: Array<string>;
  public expiryRequest: FormGroup;
  public now: Date = new Date();
  @ViewChild(DxDateBoxComponent, { static: true }) db: DxDateBoxComponent;

  spotExpireExcelFile: File;

  constructor(
    public bsModalRef: BsModalRef,
    private spotService: SpotService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private util: Utilities,
    private uploadService: UploadService,
    private subService: SubService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.expiryRequest = new FormGroup({
      expiryDate: new FormControl(null, Validators.required),
      notes: new FormControl(null)
    });

    this.expiryRequest.controls['expiryDate'].setValue(new Date());
  }

  public onSubmit({ value, valid }: { value: SpotExpiryRequest, valid: boolean }) {
    if (this.expiryRequest.controls['expiryDate'].errors)
      return;

    if (!this.db.isValid)
      return;

    var spotExpiryRequest: SpotExpiryRequest;

    let reader = new FileReader();
    if (this.spotExpireExcelFile) {
      reader.readAsDataURL(this.spotExpireExcelFile);

      reader.onload = () => {
        spotExpiryRequest = {
          spots: this.spots,
          expiryDate: moment(value.expiryDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(),
          spotExpireFile: (<string>reader.result).split(',')[1],
          spotExpireFileType: this.spotExpireExcelFile.type,
          spotExpireFileName: this.spotExpireExcelFile.name,
          notes: value.notes
        };

        console.log(spotExpiryRequest);
        this.spotService.expireSpots(spotExpiryRequest).subscribe((res: any) => {
          if (res.isSuccess == true) {
            var results = res.result as Array<SpotExpiryResult>;
            var inValidResults = results.filter(t => t.isSuccess == false);

            if (inValidResults.length == 0) {
              this.alertService.showMessage("SUCCESS", Constants.spotsExpiredSuccessfully, MessageSeverity.success);
            }
            else {
              let updateMessageList: string[] = [];
              for (let res of inValidResults) {
                if (res.spotIdentifier != null) {
                  updateMessageList.push(`${res.spotIdentifier}: ${res.error}`);
                } else {
                  updateMessageList.push(`${res.error}`);
                }
              }

              var initialState = {
                errors: updateMessageList
              };

              this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

            }
            this.subService.callDeselectSearchGridRowsMethod();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
          this.closePopup();
        },
          error => {
            if (this.util.handleError(error))
              this.closePopup();
          });


      };
    }
    else
    {
      spotExpiryRequest = {
        spots: this.spots,
        expiryDate: moment(value.expiryDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(),
        spotExpireFile: null,
        spotExpireFileType: null,
        spotExpireFileName: null,
        notes: value.notes
      };
    
  
        this.spotService.expireSpots(spotExpiryRequest).subscribe((res: any) => {
          if (res.isSuccess == true) {
            var results = res.result as Array<SpotExpiryResult>;
            var inValidResults = results.filter(t => t.isSuccess == false);

            if (inValidResults.length == 0) {
              this.alertService.showMessage("SUCCESS", Constants.spotsExpiredSuccessfully, MessageSeverity.success);
            }
            else {
              let updateMessageList: string[] = [];
              for (let res of inValidResults) {
                updateMessageList.push(`${res.spotIdentifier}: ${res.error}`);
              }

              var initialState = {
                errors: updateMessageList
              };

              this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

            }
            this.subService.callDeselectSearchGridRowsMethod();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
          this.closePopup();
        },
          error => {
            if (this.util.handleError(error))
              this.closePopup();
          });
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  incomingfile(event) {
    this.spotExpireExcelFile = event.target.files[0];
  }

  public onDownloadSpotExpiryTemplate() {

    var request = { isSpotExpiryUpload: true };

    this.uploadService.downloadMetadaTemplate(request)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              //let fileURL = window.URL.createObjectURL(fileData);
              //window.open(fileURL);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = "Asset_Expiry_Template.xlsx";
              a.click();
            });
        }
        else {
          console.log(res.errors);
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          console.log(error);
          this.util.handleError(error);
        });

  }

}
