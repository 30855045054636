<div class="tylie-panel tylie-panel--br-top">
  <div class="tylie-panel__header">
    <h4 class="tylie-panel__title">
      FEATURED CREATIVE & TAGS
    </h4>
  </div>
  <div class="tylie-panel__body">
    <p class="tylie-type-body mb-3">
      <span *ngIf="!featuredCreatives">
        No Creatives & Tags Available
      </span>
    </p>
    <div class="row">
      <div class="col-sm-3 col-md-3" *ngFor="let featured of featuredCreatives">
        <div class="tylie-card">
          <div class="tylie-card__img-wrap" *ngIf="featured.isPlayable">
            <img class="tylie-card__img" [src]="featured.thumbnailDisplayUrl"
                 (click)="quickPlay(featured.proxyUrl, featured.assetName, featured.thumbnailUrl, featured.format)"
                 alt="Image">
            <div class="tylie-card__img-overlay"></div>
          </div>

          <div class="tylie-card__img-wrap" *ngIf="!featured.isPlayable">
            <img class="tylie-card__img" [src]="featured.thumbnailDisplayUrl" *ngIf="!featured.isPlayable" alt="Image">
          </div>

          <div class="tylie-card__body">
            <span class="tylie-data__txt">{{featured.assetType}}</span><span class="tylie-data__lbl-green" *ngIf="featured.isRecentlyUploaded">&nbsp;NEW</span>
            <Label class="tylie-card__title">{{featured.assetName}}</Label>
            <div class="tylie-data tylie-data--block">
              <ng-container *ngIf="featured.availableEndDate; else elseAvailableTemplate">
                <span class="tylie-data__txt">
                  Available: {{featured.availableStartDate | date:'M/d/yy'}} -
                  {{featured.availableEndDate | date:'M/d/yy'}}
                </span>
              </ng-container>
              <ng-template #elseAvailableTemplate>
                <span class="tylie-data__txt">Available: Unlimited</span>
              </ng-template>
            </div>
            <div class="tylie-data tylie-data--block" *ngIf="featured.duration > 0">
              <span class="tylie-data__txt">Length: {{featured.duration}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-auto">
        <a class="tylie-lnk" (click)="goToCreativeAndTags()">
          <icon-eye color="#1e90ff">
          </icon-eye>
          &nbsp;VIEW ALL CREATIVE & TAGS
        </a>
      </div>
    </div>
  </div>
</div>
