import { ClientBrandSelectionModel } from "./createclient.model";

export class Client {
  public id: number;
  public clientGuid: string;
  public name: string;
  public code: string;
  public logo: any[];
  public base64Logo: string;
  public isActive: boolean;
  public isEditorialHouse: boolean;
  public isSubClient: boolean;
  public isViewRestricted: boolean;
  public isOrderFinalReviewRequired: boolean;
  public isReviewRequired: boolean;
  public isBundleServicesOffered: boolean;
  public isPoRequired: boolean;
  public isOnTrail: boolean;
  public isManagementFeeAutoBilled: boolean;
  public accountExecutiveUserProfileName: string;
  public billingFrequency: string;
  public BillToDisplayString: string;
  public costCenterDisplayString: string;
  public isVaultBilling: boolean;
  public inVoiceTo: string[];
  public costCenter: string[];
  public clientContact: ClientContact;
  public brandSelectionList: ClientBrandSelectionModel[];
  public config: ViewClientConfig;
  public postHouses: PostHouseModel[];
  public status: string;
  public bundleServicesOfferedCodes: string;
  public editStartedByUserProfileName: string;
  public archiveDurationInDays: string;
  public activeTandemStorageInDays: string;
  public infrqStorageDays: string;
  public stdStorageDays: string;
  public isFileDeliveryConfigExists: string;
  public isS3AccelerationEnabled: boolean;
  public partnerClientNames: string[];
  public isClientTypePartner: boolean;
}

export class ViewClientConfig {
  public showAddPostHouse: boolean;
  public showClientEdit: boolean;
  public showActivateClient: boolean;
  public showInActivateClient: boolean;
  public showEditStartedByUserProfileName: boolean;
}

export class ClientContact {
  public name: string;
  public address1: string;
  public address2: string;
  public city: string;
  public country: string;
  public state: string;
  public zipCode: string;
  public phone: string;
  public mobile: string;
  public email: string;
  public teamEmail: string;
}

export class PostHouseModel {
  public id: number;
  public clientEditorialHouseId: number;
  public name: string;
  public code: string;
  public type: string;
  public isActive: boolean;
  public isCurrent: boolean;
  public postHouseBrandSelectionList: ClientBrandSelectionModel[];
}

export class PODRecipients {
  public podDistributionEmails: string;
}

export class SendOrderRecipients {
  public sendOrderEmails: string;
}

export class ClientOrderedBy {
  public orderedByList: string;
}

export class SendEmailOrderModel {
  public orderGuid: string;
  public isDraftOrder: boolean;
  public toEmails: string;
}


