import {
  Component, ChangeDetectorRef, ViewChildren, Query, QueryList, Directive, ElementRef, HostBinding,
  AfterViewInit, Input, OnInit
} from '@angular/core';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AlertService, MessageSeverity } from '../services/core/alert.service';
import { UserProfileService } from "../services/user/user-profile.service";

@Directive({
  selector: '[secured]'
})

export class SecuredDirective implements AfterViewInit {
  @Input('accessControlList')
  accessControlList: Array<string>;
  @Input('accessControlListComponent')
  accessControlListComponent: Array<string>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eltRef: ElementRef,
    private authService: AuthService,
    private userProfileService: UserProfileService) {
  }

  ngAfterViewInit() {
    let hasAccess = false;
    const userRoles: string[] = this.userProfileService.getUserRoles();

    if (this.accessControlList != undefined) {
      for (let role of this.accessControlList) {
        if (userRoles.some(r => r.toLowerCase().startsWith(role.toLowerCase()))) {
          hasAccess = true;
        }
      }
    }

    if (this.accessControlListComponent != undefined) {
      for (let role of this.accessControlListComponent) {
        if (userRoles.some(r => r.toLowerCase() == role.toLowerCase())) {
          hasAccess = true;
        }
      }
    }

    if (!hasAccess) {
      let el: HTMLElement = this.eltRef.nativeElement;

      if (el != null && el.parentNode != null) {
        el.parentNode.removeChild(el);
      }
    }
  }
}
