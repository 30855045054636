<div class="tylie-flyout__body-content" *ngIf="this.vaultRequestDetail">
  <div class="tylie-flyout__body-topbar">
      <h5 class="tylie-flyout__body-title">
        VAULT REQUEST: {{vaultRequestDetail.vaultRequestNumber}}
      </h5>
      <div class="tylie-badge tylie-badge--error">{{statusLabel}}</div>
  </div>

  <div class="search-all-media all-orders order-intractions" [formGroup]="createVaultRequest">
    <div class="tylie-panel">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          Vault Request Header
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button" container="body" (click)="editHeader()" *ngIf="vaultRequestDetail.isStagingRequest == false">
            <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
            EDIT HEADER
          </button> 
        </h4>
      </div>
      <div class="tylie-panel__body">
        <div class="row">
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Request Type</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.requestTypeName}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Created</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.createdDate | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Client</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.client}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Brand</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.brand}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Product</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.product}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Requested By</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.requestedByUserProfileName}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Client Job Number</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.clientJobNumber}}</div>

              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true" (click)="onEdit('VaultJobNumber')">Edit</button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Reference</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.reference}}</div>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true" (click)="onEdit('VaultReference')">Edit</button>
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Warehouse</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.warehouse}}</div>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true && this.vaultRequestDetail.config.isWareHouseEditable == true" (click)="onEdit('VaultWareHouse')">Edit</button> <!--if nocarton allow for edit-->
            </div>
          </div>
          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Received From</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.receivedFrom}}</div>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true" (click)="onEdit('VaultReceivedFrom')">Edit</button>
            </div>
          </div>
          <!--<div class="col-md-3">
    <div class="tylie-data tylie-data--block mb-3">
      <label class="tylie-data__lbl">Format</label>
      <div class="tylie-data__txt">{{this.vaultRequestDetail.format}}</div>
      <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true" (click)="onEdit('VaultFormat')">Edit</button>
    </div>
  </div>-->
          <div class="col-md-3" *ngIf="this.vaultRequestDetail.transferTo">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Transfer To</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.transferTo | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>
          </div>

          <div class="col-md-3" *ngIf="this.vaultRequestDetail.transferDate">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Transfer Date</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.transferDate | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>
          </div>


          <div class="col-md-3" *ngIf="this.vaultRequestDetail.returnedDate">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Returned Date</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.returnedDate | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="tylie-data tylie-data--block mb-3">
              <label class="tylie-data__lbl">Confirmation Emails</label>
              <div class="tylie-data__txt">{{this.vaultRequestDetail.confirmationEmails}}</div>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="isEditMode === true" (click)="onEdit('VaultConfirmationEmail')">Edit</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  NOTES & HISTORY
                  <button type="button"class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddNewNote()" *ngIf="vaultRequestDetail.isStagingRequest == false">
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    ADD NOTE
                  </button>
                </h4>
              </div>
              <div class="tylie-panel__body">
                <ng-container *ngIf="this.vaultRequestDetail.vaultAssetNotes.length == 0">There are no notes and history yet.</ng-container>
                <table class="table table-bordered" *ngIf="this.vaultRequestDetail.vaultAssetNotes.length > 0">
                  <tbody>
                    <tr *ngFor="let att of this.vaultRequestDetail.vaultAssetNotes; let i = index">
                      <td><div class="p-normal">{{att.message}}</div> <span class="p-tiny">by {{att.createdBy}}</span>&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{att.createdDate | usertimezone: 'MM/DD/YYYY HH:mm A z'}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  ATTACHMENTS
                  <button  type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddNewAttachment()" *ngIf="vaultRequestDetail.isStagingRequest == false">
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    ADD ATTACHMENTS
                  </button>
                </h4>
              </div>
              <div class="tylie-panel__body">
                <ng-container *ngIf="this.vaultRequestDetail.vaultFiles.length == 0">There are no attachments yet.</ng-container>
                <table class="table table-bordered" *ngIf="this.vaultRequestDetail.vaultFiles.length > 0">
                  <tbody>
                    <tr *ngFor="let att of this.vaultRequestDetail.vaultFiles; let i = index">
                      <td class="blue-text" (click)="onDownloadFile(att.vaultFileGuid, att.fileName)">{{att.fileName}}</td>
                      <td align="center">
                        <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  ADDITIONAL SERVICES
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddNewService()" *ngIf="vaultRequestDetail.isStagingRequest == false">
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    ADD SERVICE
                  </button>
                </h4>
              </div>
              <div class="tylie-panel__body">
                <ng-container *ngIf="this.vaultRequestDetail.vaultServices.length == 0">There are no additional services.</ng-container>
                <table class="table table-bordered" *ngIf="this.vaultRequestDetail.vaultServices.length > 0">
                  <tbody>
                    <tr *ngFor="let att of this.vaultRequestDetail.vaultServices; let i = index">
                      <td>{{att.serviceName}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-panel tylie-panel--secondary">
              <div class="tylie-panel__header tylie-panel__header--br-thick">
                <h4 class="tylie-panel__title">
                  ESTIMATES
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="onAddEstimates()" *ngIf="vaultRequestDetail.isStagingRequest == false">
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    ADD ESTIMATES
                  </button>
                </h4>
              </div>
              <div class="tylie-panel__body">
                <ng-container *ngIf="this.vaultRequestDetail.estimateFiles.length == 0">There are no estimates yet.</ng-container>
                <table class="table table-bordered" *ngIf="this.vaultRequestDetail.estimateFiles.length > 0">
                  <tbody>
                    <tr *ngFor="let att of this.vaultRequestDetail.estimateFiles; let i = index">
                      <td class="blue-text" (click)="onDownloadFile(att.vaultFileGuid, att.fileName)">{{att.fileName}}</td>
                      <td align="center">
                        <a class="tylie-button tylie-button--link tylie-button--xs" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--cartons-->
    <div class="tylie-panel" *ngIf="this.vaultRequestDetail.isRequestTypeIngest == true">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          Vault Request Cartons
        </h4>
      </div>
      <div class="tylie-panel__body">
        <div class="row">
          <div class="col-md-4" *ngIf="vaultRequestDetail.isStagingRequest == false || (this.vaultRequestDetail.ingestStartDate != null && vaultRequestDetail.isStagingRequest == true)">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="ads">Ingest Start Date<span class="required">*</span></label>
              <div class="tylie-form-group__txt" *ngIf="vaultRequestDetail.isStagingRequest == true">{{this.vaultRequestDetail.ingestStartDate | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
              <ng-container style="width:100%" *ngIf="vaultRequestDetail.isStagingRequest == false">
                <dx-date-box width="100%" #ingestStartDate type="date" id="ads" name="ads"
                             displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                             placeholder="Select"
                             class="tylie-datepicker form-control" [showClearButton]="true" formControlName="ingestStartDate"></dx-date-box>
              </ng-container>
              <p class="p-extra-small">Invalid Ingest Start Date</p>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div *ngFor="let carton of cartons.controls; let c=index">
            <vault-request-carton [carton]="carton"
                                  [totalCartons]="cartons.controls.length"
                                  [triedToSubmitRequest]="triedToSubmitRequest"
                                  [cartonSequence]="c"
                                  [formatDataSource]="vaultRequestDetail.config.formats"
                                  (onDeleteCartonEvent)="onDeleteCartonEmitted($event)"
                                  [isStagingRequest]="vaultRequestDetail.isStagingRequest">

            </vault-request-carton>
          </div>
        </div>

        <div *ngIf="vaultRequestDetail.isStagingRequest == false">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="addAnotherCarton()">
            <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
            ADD ANOTHER CARTON
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end" *ngIf="vaultRequestDetail.isStagingRequest == false">
      <button type="button" class="tylie-button tylie-button--link tylie-button--xs" outsideClick="true" (click)="onClose()">
        CANCEL
      </button>
      <button *ngIf="this.vaultRequestDetail.isRequestTypeIngest == true" type="button" class="tylie-button tylie-button--icon tylie-button--xs ms-3" type="button" (click)="onSaveChanges()">
        <span class="tylie-button__icon"><icon-save></icon-save></span>
        <span class="tylie-button__text">SAVE PROGRESS</span>
      </button>
      <button type="button" class="tylie-button tylie-button--icon tylie-button--xs ms-3" type="button" (click)="onCompleteVaultRequest()">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">COMPLETE VAULT REQUEST</span>
      </button>
    </div>
  </div>
</div>


<ng-template #deleteAttTemplate let-fileName="fileName" let-vaultFileGuid="vaultFileGuid">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete {{fileName}}?
    </p>
    <i (click)="onHidePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button> -->


  <button type="button" class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete {{fileName}}?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button>
</ng-template>
