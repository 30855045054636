<app-tagger-header [activeLinks]="userRoles.getTaggerLocalRoleName() ? ['your-tagged-ads'] : ['your-tagger-assets']"></app-tagger-header>

<div class="tylie-body">
  <div *isAuthorized="[this.userRoles.getTaggerLocalRoleName()]">
    <app-tagger-featured></app-tagger-featured>
  </div>

<div *isAuthorized="[this.userRoles.getTaggerLocalRoleName()]" id="your-tagger-ads">
  <div class="tylie-body__top-bar">
    <h4 class="tylie-body__title">Your Tagger Ads</h4>
  </div>

  <div class="d-flex justify-content-between">
    <ul class="tylie-tab-nav nav nav-tabs mx-4" id="myTab" role="tablist">
      <li class="tylie-tab-nav__itm nav-item" role="presentation" *ngFor="let tab of tabConfig">
        <button class="tylie-tab-nav__lnk nav-link" [class.active]="activeTab == tab.name" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="activeTab = tab.name">
          <span class="tylie-tab-nav__lnk-icn">
            <icon-tab-tagged-ad *ngIf="tab.name === taggerTabs?.taggedAds"></icon-tab-tagged-ad>
            <icon-tab-draft-ad *ngIf="
                                      tab.name === taggerTabs?.savedDraftAds
                                  "></icon-tab-draft-ad>
            <icon-tab-archive-ad *ngIf="tab.name === taggerTabs?.archivedAds"></icon-tab-archive-ad>
          </span>
          <span class="tylie-tab-nav__lnk-txt">{{ tab.name }}</span>

        </button>
      </li>
    </ul>

    <div class="d-flex justify-content-end pt-1">
      <button class="tylie-button tylie-button--sm tylie-button--icon" type="button" (click)="gotoAdCreate()">
        <span class="tylie-button__icon"><i class="tylicon tylicon-tylmark"></i></span><span class="tylie-button__text">CREATE NEW TAGGER AD</span>
      </button>
    </div>
  </div>


  <div class="tylie-tab-content tab-content">
    <div class="tylie-tab-content__pane tab-pane fade show active"
         *ngIf="activeTab === taggerTabs.taggedAds">
      <app-tagger-search></app-tagger-search>
    </div>

    <div class="tylie-tab-content__pane tab-pane fade show active"
         *ngIf="activeTab === taggerTabs.savedDraftAds">
      <app-tagger-draft-search></app-tagger-draft-search>
    </div>

    <div class="tylie-tab-content__pane tab-pane fade show active"
         *ngIf="activeTab === taggerTabs.archivedAds">
      <app-tagger-archived-search></app-tagger-archived-search>
    </div>
  </div>
</div>

<app-tagger-asset *isAuthorized="[this.userRoles.getTaggerAdminRoleName()]" id="your-tagger-assets"></app-tagger-asset>
</div>
