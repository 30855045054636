import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-upload',
  templateUrl: './icon-upload.component.html',
  styleUrls: ['./icon-upload.component.css']
})
export class IconUploadComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
