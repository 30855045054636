<div id="addeditmediacustomtagsservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">CUSTOM TAGS</h4>
  </div>
  <div class="modal-body" *ngIf="spotFileCustomTags && (spotFileCustomTags.activeCustomTags.length > 0 || spotFileCustomTags.otherAvailablecustomTags.length > 0); else messageBlockMediaCustomTag">
    <ng-container>
      <div class="form-group">
        <span class="p-normal">This Spot Contains the below Custom Tags. You can edit their values or add more.</span>
      </div>

      <form [formGroup]="mediaCustomTagForm" (submit)="onaddEditCustomTagFormSubmit(mediaCustomTagForm)" novalidate #ngForm="ngForm">
        <div class="tyl-border-top" *ngIf="spotFileCustomTags.activeCustomTags.length > 0">
          <span class="p-normal form-group">ACTIVE CUTSOM TAGS</span>
          <ng-container>
            <div formArrayName="activeCustomTags" *ngFor="let grp of getActiveCustomTagsFormArray.controls; let i = index">
              <div class="row">
                <div class="form-group col-md-8"
                     [ngClass]="{error: (grp.controls['tagValue'].errors && (!grp.controls['tagValue'].pristine || ngForm.submitted))}">
                  <label class="form-group-label">{{grp.controls['tag'].value}}<span class="required">*</span></label>
                  <customtagcontrols controlName="tagValue"
                                     arrayName="tagValueList"
                                     [ctrlGrp]="grp"
                                     [controlType]="grp.controls['dataType'].value"
                                     [itemsDataSource]="grp.controls['config'].value">

                  </customtagcontrols>
                </div>
                <div>
                  <br />
                  <span class="tyl-formcontrol-vertical-align p-normal qc-cancel tyl-no-margin" (click)="onClickClearTagValue(i)">Clear</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="spotFileCustomTags.otherAvailablecustomTags.length > 0" class="form-group tyl-border-top">
          <span class="p-normal form-group">CUSTOM TAGS AVAILABLE FOR THIS SPOT</span>
          <ng-container>
            <div formArrayName="otherAvailablecustomTags" *ngFor="let grp of getOtherAvailablecustomTagsFormArray.controls; let i = index">
              <div class="row">
                <div class="form-group col-md-8"
                     [ngClass]="{error: (grp.controls['tagValue'].errors && (!grp.controls['tagValue'].pristine || ngForm.submitted))}">
                  <label class="form-group-label">{{grp.controls['tag'].value}}<span class="required">*</span></label>
                  <customtagcontrols controlName="tagValue"
                                     arrayName="tagValueList"
                                     [ctrlGrp]="grp"
                                     [controlType]="grp.controls['dataType'].value"
                                     [itemsDataSource]="grp.controls['config'].value">

                  </customtagcontrols>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="col-md-12 tyl-border-top">
          <div class="form-group"></div>
          <div class="media-qc tyl-right">
            <button class="btn btn-small qc-update" type="submit">SAVE CUSTOM TAGS</button>
            <span class="qc-cancel p-normal" (click)="closePopup()">Cancel</span>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <ng-template #messageBlockMediaCustomTag>
    <span class="modal-body">Custom Tags are not available for this asset.</span>
  </ng-template>
</div>
