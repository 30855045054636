<p *ngIf="!approvalSpots"><em>Loading...</em></p>

<div class="tylie-accordion accordion" id="media-awaiting-approval-section">
  <div class="tylie-accordion__panel accordion-item" id="mediaApprovalPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Asset Awaiting Approval
      </button>
    </h2>
    <div id="collapseOne" class="tylie-accordion__content accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickApprovalSearchValue"
                        (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="searchApprovalSpots()">Search</button>
                </div>
              </section>
            </div>
          </div>
          
  
          <div class="mt-3">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="showChoosePlayListPopup()"><span class="tylie-button__icon"><icon-playlist-add></icon-playlist-add></span><span class="tylie-button__text">Add to playlist</span></button>
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
          </div>
        </div>
        
        <div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">
          <dx-data-grid id="approvalGrid" *ngIf="approvalSpots"
                        [dataSource]="approvalSpots"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [rowAlternationEnabled]="true"
                        [showColumnLines]="true"
                        [hoverStateEnabled]="true"
                        [showRowLines]="true"
                        keyExpr="spotFileGuid" class="tylie-grid">
              <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
              </dxo-selection>
              <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
              <dxi-column caption="Thumbnail" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" cssClass="gridStyle tylie-grid__cell-img"></dxi-column>
              <dxi-column caption="Ad-ID" dataField="adId" cellTemplate="adIDTemplate" width="15%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="title" width="42%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="brand" width="23%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="length" caption="Duration" alignment="left" width="8%" cssClass="gridStyle"></dxi-column>
              <dxi-column dataField="createdDate" cellTemplate="dateTemplate" width="12%" cssClass="gridStyle" sortOrder="desc"></dxi-column>
              <div class="tylie-img-container tylie-img-container--thumbnail" *dxTemplate="let t of 'thumbnailTemplate'">
                <img *ngIf="t.data.isPlayable === true" class="tylie-img" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
                <img *ngIf="t.data.isPlayable === false" class="tylie-img" [src]="t.data.thumbnailUrl" />
              </div>
              <div *dxTemplate="let data of 'dateTemplate'">
                <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY'}}</div>
              </div>
              <div *dxTemplate="let t of 'adIDTemplate'">
                <span class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
              </div>
              <dxo-paging [pageSize]="10"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50]"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
              </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>


    <!-- <div class="tylie-accordion__heading panel-heading">
      <h4 class="panel-title" data-toggle="collapse" data-target="#mediaApprovalCollapseOne">
        <a class="p-small">
          Asset Awaiting Approval
        </a>
      </h4>
    </div> -->
    <!-- <div id="mediaApprovalCollapseOne" class="tylie-accordion__body panel-collapse collapse in">
      <div class="panel-body">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Ad-ID or Title" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickApprovalSearchValue"
                  (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="searchApprovalSpots()">Search</button>
          </div>
        </section>

        <div class="search-all-media-actions1">
          <button class="tylie-button tylie-button--xs tylie-button--icon" (click)="showChoosePlayListPopup()"><span class="tylie-button__icon"><icon-playlist-add></icon-playlist-add></span><span class="tylie-button__text">Add to playlist</span></button>
          <button class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-playlist-add></icon-playlist-add></span><span class="tylie-button__text">REFRESH</span></button>
        </div>
      </div>
      <div class="panel-footer panel-footer-media-qc"></div>
      <div class="panel-body">
        <dx-data-grid id="approvalGrid" *ngIf="approvalSpots"
                      [dataSource]="approvalSpots"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [showColumnLines]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      keyExpr="spotFileGuid">
          <dxo-selection mode="multiple"
                         selectAllMode="page"
                         allowSelectAll="Select"
                         showCheckBoxesMode="always">
          </dxo-selection>
          <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
          <dxi-column caption="Thumbnail" [allowSorting]="false" dataField="thumbnailUrl" cellTemplate="thumbnailTemplate" cssClass="gridStyle"></dxi-column>
          <dxi-column caption="Ad-ID" dataField="adId" cellTemplate="adIDTemplate" width="15%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="title" width="42%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="brand" width="23%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="length" caption="Duration" alignment="left" width="8%" cssClass="gridStyle"></dxi-column>
          <dxi-column dataField="createdDate" cellTemplate="dateTemplate" width="12%" cssClass="gridStyle" sortOrder="desc"></dxi-column>
          <div *dxTemplate="let t of 'thumbnailTemplate'">
            <img *ngIf="t.data.isPlayable === true" class="quickLink gridThumbnail" [src]="t.data.thumbnailUrl" (click)="quickPlay(t.data.proxyUrl, t.data.title, t.data.thumbnailUrl, t.data.format)" />
            <img *ngIf="t.data.isPlayable === false" class="gridThumbnail" [src]="t.data.thumbnailUrl" />
          </div>
          <div *dxTemplate="let data of 'dateTemplate'">
            <div style="text-align:left;">{{ data.value | usertimezone: 'MM/DD/YYYY'}}</div>
          </div>
          <div *dxTemplate="let t of 'adIDTemplate'">
            <span class="quickLink" (click)='showDetail(t.data.spotFileGuid)'>{{t.data.adId}}</span>
          </div>
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                     [allowedPageSizes]="[10, 20, 50]"
                     [showNavigationButtons]="true"
                     [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
      <div class="panel-footer"></div>
    </div> -->
  </div>
</div>
