import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { VaultFile } from '../../../models/vault/vault-create.model';
import { UploadVaultFileRequest } from '../../../models/vault/vault-upload.model';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { UploadVaultFileService } from '../../../services/vault/uploadvaultfile.service';
//import { UploadOrderFileService } from '../../../services/order/uploadorderfile.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-selectattachmentvaultpopup',
  templateUrl: './selectattachmentvaultpopup.component.html',
  styleUrls: ['./selectattachmentvaultpopup.component.css']
})
export class SelectattachmentvaultpopupComponent extends BasePopupComponent {

  @Input() attachmentCategory: string;
  @Input() vaultGuid: string;
  @ViewChild('selectedFile', { static: false }) selectedFile: ElementRef;

  public onSelectAttachmentVault: Subject<VaultFile>;
  public attchmentCategoryRequest: FormGroup;
  public isUploading: boolean = false;
  public attachedFiles: Array<VaultFile> = [];
  public errorMsg: string = '';
  public acceptFileTypes: string = "*";

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private uploadVaultFileService: UploadVaultFileService
    ) {
    super();
    this.onSelectAttachmentVault = new Subject();
  }

  ngOnInit() {
    super.ngOnInit();
    this.attchmentCategoryRequest = new FormGroup({
      //selectedCategory: new FormControl(null, Validators.required)
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onAttachFile(event: any) {

    if (event.target.files && event.target.files.length > 0) {

      //if (this.attchmentCategoryRequest.controls['selectedCategory'].errors) {
      //  this.selectedFile.nativeElement.value = "";
      //  return;
      //}

      for (const uploadFile of event.target.files) {
        if (this.acceptFileTypes != '*' && this.acceptFileTypes.indexOf(uploadFile.type) < 0) {
          //this.attchmentCategoryRequest.controls['selectedCategory'].setErrors({ 'invalid': true });
          this.isUploading = false;
          this.selectedFile.nativeElement.value = "";
          this.errorMsg = uploadFile.name + ' is not acceptable file type.'
          return;
        }
      }

      for (const uploadFile of event.target.files) {
        var request: UploadVaultFileRequest = {
          fileType: this.attachmentCategory,
          fileName: uploadFile.name,
          description: "",
          //fileCategory: this.attchmentCategoryRequest.controls['selectedCategory'].value,
          vaultGuid: this.vaultGuid,
          vaultDestinationId: 0
        };

        this.isUploading = true;

        this.uploadVaultFileService.uploadVaultFile(request, uploadFile).pipe(
          finalize(() => {
            this.selectedFile.nativeElement.value = "";
            this.isUploading = false;
          }))
          .subscribe((newFile: any) => {
            if (newFile != null && newFile != undefined) {
              this.attachedFiles.push(newFile);
              this.onSelectAttachmentVault.next(newFile);
            }
          }, error => {
            this.closePopup();
            this.util.handleError(error);
          });
      }
    }
    else
      this.isUploading = false;
  }
}
