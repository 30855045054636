import { Component, Inject, ViewChild, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../services/core/alert.service'
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent } from "devextreme-angular";

import { BsModalService } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'orders-in-progress',
  templateUrl: './orders-in-progress.component.html',
  styleUrls: ['./orders-in-progress.component.css']
})
export class OrdersInProgressComponent implements OnInit {

  public ordersInProgresStore: any = {};
  @ViewChild('ordersInProgress', { static: true }) ordersInProgressGrid: DxDataGridComponent;
  @Input('inProgressOrderConfig') inProgressOrderConfig: any;

  public showAllColumns: boolean = false;

  private refreshId: any;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities, private sub: SubService, private gs: GlobalService) {

  }

  ngOnInit() {
    if (this.inProgressOrderConfig != null) {
      if (this.inProgressOrderConfig.showAllColumns != null || this.inProgressOrderConfig.showAllColumns != undefined) {
        this.showAllColumns = this.inProgressOrderConfig.showAllColumns;
      }
    }

    this.getOrdersInProgress();

    this.sub.orderEditedMethodCalled.subscribe(() => { this.ordersInProgressGrid.instance.refresh(); });

    this.refreshId = setInterval(() => {
      this.ordersInProgressGrid.instance.refresh();
    }, environment.ordersInProgressRefreshTimeInMins * 60000);

  };

  ngAfterViewInit(): void {
    if (!this.showAllColumns) {
      this.ordersInProgressGrid.instance.columnOption("Claimed By", "visible", false);
      this.ordersInProgressGrid.instance.columnOption("Final Review", "visible", false);
    }
  }

  public onRefresh() {
    this.ordersInProgressGrid.instance.refresh();
  }

  public getOrdersInProgress() {
    var orderService = this.orderService;
    var util = this.util;

    this.ordersInProgresStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };

        return orderService.getOrdersInProgress(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var ordersInProgressResponse = response.result;
              var obj: any = {
                data: ordersInProgressResponse.orderResults,
                totalCount: ordersInProgressResponse.totalCount
              };
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);

    //this.router.navigate([RouteConstants.orderDetailRoute, orderGuid]);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }

  //public onCellPrepared(e: any) {
  //  if (e.rowType === "data" && e.column.command === 'select' && (e.data.isAlreadyClaimed || e.data.isStatusInvalidForAction)) {
  //    var instance = e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance");
  //    instance.option("disabled", true);
  //    e.cellElement.off();
  //  }
  //}

  //public onSelectionChanged(e: any) {
  //  var disabledRows = this.ordersInProgressGrid.instance.getSelectedRowsData();

  //  var disabledKeys: any[] = [];
  //  if (disabledRows.length > 0) {
  //    disabledRows.forEach(data => {
  //      if (data.isAlreadyClaimed || data.isStatusInvalidForAction) {
  //        disabledKeys.unshift(data.orderGuid);
  //      }
  //    });
  //    e.component.deselectRows(disabledKeys);
  //  }
  //}
}

