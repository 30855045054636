<div id="quick-link">
  <div class="modal-header">
    <button type="button" class="close delete" (click)="closePopup()"></button>
    <h4 class="modal-title">QUICK LINK</h4>
  </div>

  <div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <span class="p-normal" *ngIf="spotData != null && spotData.length > 0">Selected Spots</span>
        <span class="p-small qc-cancel pull-right clearOrder-label" (click)="closePopup()">
          ADD MORE
        </span>
      </div>
    </div>
    <div class="panel-body" *ngIf="spotData == null || spotData.length <= 0">
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-danger">
            {{nospotsSelected}}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="spotData != null && spotData.length > 0">
      <div class="row time-comment-view">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="30%">AD ID</th>
                <th width="50%">Title</th>
                <th width="20%">Duration</th>
              </tr>
            </thead>
            <tbody *ngFor="let spot of spotData; let j = index; odd as isOdd">
              <tr [class.tyl-custom-grid-tr]="isOdd" [class.group-error-border]="spot.isQuickLinkAllowed == false" [class.group-error-message]="spot.isQuickLinkAllowed == false"
                  containerClass="customPopover" placement="top" [popover]="spot.isQuickLinkAllowed == false? quickLinkNotAvaialableError : ''" triggers="mouseenter:mouseleave">
                <td>
                  <div placement="left">
                    {{spot.adId}}
                  </div>
                </td>
                <td>
                  <div>
                    {{spot.title}}
                  </div>
                </td>
                <td>
                  <div>
                    {{spot.length}}
                  </div>
                </td>
                <td align="center">
                    <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onClickdelete(spotData[j].spotFileGuid)">
                      <icon-trash-can color="#1e90ff"></icon-trash-can>
                    </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body" *ngIf="config">
    <form novalidate [formGroup]="quickLinkFormGroup" #f="ngForm">
      <div class="media-share row new-order-check">
        <div class="form-group col-md-6" [class.error]="f.submitted && quickLinkFormGroup.controls['spec'].errors?.required">
          <label>Predefined Specs<span class="required">*</span></label>
          <dx-drop-down-box [(value)]="selectedSpecs"
                            formControlName="spec"
                            id="spec" class="form-control"
                            valueExpr="name"
                            displayExpr="name"
                            placeholder="Select specs..."
                            [showClearButton]="true"
                            (onValueChanged)="syncTreeViewSelection();"
                            [dataSource]="config.transcodePredefinedSpecs">
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view [dataSource]="config.transcodePredefinedSpecs"
                            #treeSpecs
                            selectionMode="multiple"
                            keyExpr="name"
                            displayExpr="name"
                            showCheckBoxesMode="normal"
                            [selectByClick]="true"
                            (onContentReady)="syncTreeViewSelection($event)"
                            (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
          <p class="p-extra-small">Please choose a Spec</p>
        </div>
        <div class="col-md-6 line-height">
          <div class="form-group">
            <label class="checkbox-inline" *ngIf="config.allowSpecialOptions == true">
              <input type="checkbox" formControlName="isInternal" name="isInternal">
              <span>Internal Use</span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group" [ngClass]="{error: (quickLinkFormGroup.controls['slate'].errors && f.submitted)}">
        <label>Slate<span class="required">*</span></label>
        <div class="service-radio-sec">
          <ng-container *ngFor="let opt of slateoptions">
            <input type="radio" name="slate" formControlName="slate" [value]="opt"><span>{{opt}}</span>
          </ng-container>
        </div>
        <p class="p-extra-small">Please choose an option</p>
      </div>

      <div class="submit-option">
        <div class="col-md-12"></div>
        <div class="tyl-right">
          <button class="btn new-default-btn btn-normal" type="submit" (click)="onClickSubmitForm(quickLinkFormGroup)">SUBMIT</button>
        </div>
      </div>
    </form>
  </div>
</div>

