import { Component, AfterViewInit, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { UserDataService } from "../../services/user/user-data.service";
import { AccountExecutiveModel, UserProfile } from "../../models/user/user.profile.model";
import { Utilities } from "../../services/core/utilities";
import { Constants } from "../../configurations/constants";
import { UserProfileService } from "../../services/user/user-profile.service";
import { environment } from "../../../environments/environment";
import { SubService } from "../../services/media/sub.service";
import { AppConfig, APP_CONFIG } from "../../app.module.config";
import { NotificationService } from "../../services/notifications/notification.service";
import { RouteConstants } from "../../configurations/route-constants";
import { UserRoles } from '../../configurations/user-roles';
import { DOCUMENT } from '@angular/common';
import { LocalStoreManager } from '../../services/core/local-store-manager.service';
import { DBkeys } from '../../configurations/db-Keys';

@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavMenuComponent implements AfterViewInit, OnInit {

  public mediaCount: boolean = false;
  public spotCount: string;
  public email: string = "";
  public menuItemSelected: string;
  public initials: string = "";
  public appVersion: string = "";
  public avatar: string = null;
  public showAdminSubMenu: boolean = false;
  public showAccountExecutive: boolean = false;
  public accountExecutive: AccountExecutiveModel;
  public currentYear: number = new Date().getFullYear();

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private userData: UserDataService,
    public util: Utilities,
    private notificationService: NotificationService,
    private userProfileService: UserProfileService,
    private subService: SubService,
    private userRoles: UserRoles,
    @Inject(DOCUMENT) private doc: Document,
    private localStorage: LocalStoreManager) {

    var userProfile = this.userProfileService.getUserProfile();

    if (userProfile == null || userProfile == undefined) {
      this.logout();
    }

    this.email = userProfile.email;
    this.initials = userProfile.initials;
    this.avatar = userProfile.avatar == null ? this.config.defaultAvatar : Constants.avatarEncoding + userProfile.avatar;

    var userRolesList = this.userProfileService.getUserRoles();

    this.showAccountExecutive = userProfile.accountExecutive != null && userRolesList != null && userRolesList != undefined && userRolesList.length > 0 ? !userRolesList.includes(userRoles.getAdminViewRoleName()) : false;

    this.accountExecutive = userProfile.accountExecutive;

    this.subService.avatarChange.subscribe((avatar: string) => {
      this.avatar = Constants.avatarEncoding + avatar;
    });

    this.subService.profileChange.subscribe((userProfile: any) => {
      this.initials = userProfile.initials;
    });
  }

  ngOnInit() {
    this.menuItemSelected = RouteConstants.homeRoute;
    this.appVersion = environment.version;
  }

  ngAfterViewInit() {
    //this.userData.getPendingActionCount().subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    this.util.spotQCCount = res.result.spotQCCount;
    //    this.util.spotApprovalCount = res.result.spotApprovalCount;
    //    this.util.draftOrdersCount = res.result.draftOrderCount;
    //    this.util.awaitingOrderReviewCount = res.result.awaitingOrderReviewCount;
    //    this.util.ordersReadyToProcessCount = res.result.ordersReadyToProcessCount;
    //    this.util.ordersInProcessingCount = res.result.ordersInProcessingCount;
    //    this.util.vendorSubmissionFailedOrderCount = res.result.vendorSubmissionFailedOrderCount;
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }
    //},
    //  error => {
    //    this.util.handleError(error);
    //  });

    //this.notificationService.getCount().subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    this.util.notificationCount = res.result;
    //  }
    //  else { this.util.handleIsNotSuccess(res.errors); }
    //},
    //  error => {
    //    this.util.handleError(error);
    //  });
  }

  public logout() {
    //this.authService.clearSessionStorage();
    //this.authService.redirectLogoutUser();
    //this.auth.logout();
    this.authService.forceLogOut();
  }

  public onAdminClick() {
    this.showAdminSubMenu = !this.showAdminSubMenu;
  }

  public onNonAdminClick() {
    this.showAdminSubMenu = false;
  }

  public onSettingsClick() {
    this.showAdminSubMenu = false;
    this.util.gotoSettings();
  }

  public onAdminMouseout() {
    this.showAdminSubMenu = false;
  }
}
