import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { TaggerService } from '../../../services/tagger/tagger.service';
import { Constants } from '../../../configurations/constants';
import { OrderNote, OrderNoteResponse } from '../../../models/tagger/tagger.model';

@Component({
  selector: 'app-tagger-order-notepopup',
  templateUrl: './tagger-order-notepopup.component.html',
  styleUrls: ['./tagger-order-notepopup.component.css']
})
export class TaggerOrderNotepopupComponent implements OnInit {
  @Input('orderguid') orderGuid: string;
  @Input('payload') payload: string;

  public vaultAssetNoteRequest: FormGroup;
  public onSaveVaultAssetNote: Subject<OrderNoteResponse>;

  constructor(public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private taggerService: TaggerService,
    private fb: FormBuilder)
  {
    this.onSaveVaultAssetNote = new Subject<OrderNoteResponse>();
  }

  ngOnInit() {
    this.vaultAssetNoteRequest = this.fb.group({
      note: new FormControl(this.payload, Validators.required),
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSubmitVaultAssetNote({ value, valid }: { value: OrderNote, valid: boolean }) {
    value.orderGuid = this.orderGuid;
    if (!valid) {
      return;
    }

    this.alertService.ShowLoader(true);

    this.taggerService.addOrderNote(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var draftOrderNote = res.result as OrderNoteResponse;
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_add_vault_asset_note, MessageSeverity.success, true);
        this.onSaveVaultAssetNote.next(draftOrderNote);
        this.closePopup();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.failed_add_note, MessageSeverity.error, true);
      }
    });
  }

}
