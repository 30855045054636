import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import { Utilities } from '../../../services/core/utilities';
import { SpecificationSearch } from '../../../models/admin/destination/destinationsearch.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Input } from '@angular/core';
import { TranscodePreferenceRequestModel } from '../../../models/user/transcode-preference.model';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-admintranscodepreferencessettings',
  templateUrl: './admintranscodepreferencessettings.component.html',
  styleUrls: ['./admintranscodepreferencessettings.component.css']
})
export class AdmintranscodepreferencessettingsComponent extends BasePopupComponent {

  public dataSource: any = {};
  public quickSearchValue: string = '';
  public onClose: Subject<TranscodePreferenceRequestModel>;
  public searchSpecificationsCriteriaObj = <SpecificationSearch>{};
  public distributionSettingsFormGroup: FormGroup;
  public seperator: string = "_"

  @Input('enableAdvancedFileNaming') enableAdvancedFileNaming: boolean = false;
  @ViewChild('specsGrid', { static: false }) specificationsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private configService: ConfigService,
    private util: Utilities,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.distributionSettingsFormGroup = this.fb.group({
      enableAdvancedFileNaming: [this.enableAdvancedFileNaming],
      specifications: [],
    });

    this.onClose = new Subject();
    this.searchSpecifications();
  }

  public formSubmit({ value, valid }: { value: TranscodePreferenceRequestModel, valid: boolean }) {
    if (!valid)
      return;

    if (this.specificationsGrid != null && this.specificationsGrid.instance != null) {
      value.specifications = this.specificationsGrid.instance.getSelectedRowsData();
    }

    this.closePopup();
    this.onClose.next(value);
  }

  public closePopup() {
    this.bsModalRef.hide();

    if (this.specificationsGrid != null && this.specificationsGrid.instance != null) {
      this.specificationsGrid.instance.deselectAll();
    }
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {

      this.searchSpecificationsCriteriaObj.quickSearch = this.quickSearchValue;
      this.searchSpecifications();
    }
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      this.searchSpecificationsCriteriaObj.quickSearch = this.quickSearchValue;
      this.searchSpecifications();
    }
  }

  private get specificationsDataStore() {
    var configService = this.configService;
    var util = this.util;
    var criteriaObj = this.searchSpecificationsCriteriaObj;
    var mRef = this.bsModalRef;

    return new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'name','desc':false}]";
        var groupConfig = loadOptions.group ? JSON.stringify(loadOptions.group) : "";

        let gridParams: GridParams = { group: groupConfig, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, gridParams: gridParams };

        return configService.searchSpecifications(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var obj: any = {
                data: response.result.specifications,
                totalCount: response.result.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  private searchSpecifications() {
    this.dataSource = new DataSource({
      store: this.specificationsDataStore
    });
  }

}
