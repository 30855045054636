<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Add Additional Services</h4>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row">
        <ng-container formArrayName="orders" *ngFor="let order of ordersFormArray.controls; let i = index">
          <label class="tylie-form-group form-check">
            <input class="form-check-input" type="checkbox" [formControlName]="i">
            <label class="form-check-label">{{this.vaultAssetServices.vaultAssetServices[i].serviceName}}</label>
          </label>
        </ng-container>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
      </div>
</form>
</div>
