<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="vendorPOList != null && vendorPOList.length > 0">
      <vendor-po-confirmation [vendorPOList]="vendorPOList">

      </vendor-po-confirmation>
    </div>
    <div *ngIf="vendorPOList == null || vendorPOList.length == 0">
      <span class="tylie-type-body">No Vendor Purchase Orders have been submitted for this order.</span>
    </div>
  </div>
</div>
