<div class="tylie-accordion accordion" id="uploaded-order-section">
  <div class="tylie-accordion__panel accordion-item" id="uploadedOrders">
    <h2 class="tylie-accordion__heading accordion-header" [class.uploadOrders-heading]="showAllColumns == true">
      <button class="tylie-accordion__heading-btn accordion-button" data-bs-toggle="collapse" data-bs-target="#uploadOrderCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          UPLOADED ORDERS
      </button>
    </h2>
    <div id="uploadOrderCollapseOne" class="tylie-accordion__content accordion-collapse collapse" [class.show]="showAllColumns == false">

      <div class="tylie-accordion__body accordion-body" id="uploadPanelBody">

        <div class="tylie-accordion__body-bar">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
        </div>
        <div class="tylie-accordion__body-content">
          <dx-data-grid id="uploadedOrderStoreGrid" class="tylie-grid"
                        #uploadedOrderStoreGrid
                        [dataSource]="uploadOrderStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="uploadOrderGuid"
                        [rowAlternationEnabled]="true"
                        (onRowPrepared)="onRowPrepared($event)">

            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '12%' :'20%'}}"></dxi-column>
            <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '12%' :'20%'}}"></dxi-column>
            <dxi-column dataField="workOrder" caption="Order #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '12%' :'20%'}}"></dxi-column>
            <dxi-column dataField="fileName" caption="File" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '15%' :'20%'}}"></dxi-column>
            <dxi-column dataField="userName" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '13%' :'0%'}}"></dxi-column>
            <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="asc" width="{{showAllColumns == true ? '13%' :'20%'}}"></dxi-column>
            <dxi-column dataField="status" caption="Status" cellTemplate="statusTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '13%' :'20%'}}"></dxi-column>
            <dxi-column dataField="uploadedOrderGuid" cellTemplate="actionTemplate" caption="Actions" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
            <div *dxTemplate="let t of 'actionTemplate'" class="tyl-center ">
              <div>
                <div>
                  <span><button *ngIf="t.data.enableContinue == true" class="tylie-button tylie-button--xxs" (click)="continueToOrder(t.data.uploadedOrderGuid)">Continue</button></span>
                  <button type="button" class="tylie-button tylie-button--xxs tylie-button--link ms-2" (click)="deleteOrder(t.data.uploadedOrderGuid)">
                    <icon-trash-can color="#1e90ff"></icon-trash-can>
                  </button>
                </div>
              </div>
            </div>
            <div *dxTemplate="let t of 'statusTemplate'">
              <div *ngIf="t.data.errors.length == 0">{{ t.value}}</div>
              <div class="quickLink" *ngIf="t.data.errors.length > 0" (click)="showError(t.data.errors)">{{ t.value}}</div>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>
            <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      infoText="Page {0} of {1} ({2} items)"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

