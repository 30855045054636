
import {finalize} from 'rxjs/operators';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent } from "devextreme-angular";

import { BsModalService } from "ngx-bootstrap/modal";
import { SubService } from '../../../../services/media/sub.service';
import { GlobalService } from '../../../../services/core/global.service';
import { environment } from '../../../../../environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { SearchOrderRequestViewModel } from '../../../../models/order/order-search-request.model';


@Component({
  selector: 'ready-to-process-orders',
  templateUrl: './ready-to-process-orders.component.html',
  styleUrls: ['./ready-to-process-orders.component.css']
})

export class ReadyToProcessOrdersComponent implements OnInit {

  public ordersReadyToProcessStore: any = {};
  @ViewChild('readyToProcessOrdersGrid', { static: true }) readyToProcessOrdersGrid: DxDataGridComponent;

  public selectionMode: string = "multiple";
  private refreshId: any;
  public orderSearchRequest: FormGroup;
  public searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private router: Router,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private modalService: BsModalService,
    private sub: SubService,
    private gs: GlobalService) {

    this.orderSearchRequest = new FormGroup({
      awaitingMediaOrdersOnly: new FormControl(),
      awaitingApprovalOrders: new FormControl(),
      readyToProcessOrdersOnly: new FormControl(),
      approvalRejectedOrders: new FormControl()
    });

  }

  ngOnInit() {
    if (this.userProfileService.getUserRoles().includes(this.userRoles.getOrderStatusEditorRoleName())) {
     
      this.sub.orderEditedMethodCalled.subscribe(() => { this.readyToProcessOrdersGrid.instance.refresh(); });

      this.refreshId = setInterval(() => {
        this.readyToProcessOrdersGrid.instance.refresh();
      }, environment.ordersReadyToProcessGridRefreshTimeInMins * 60000);

      //this.orderSearchRequest = new FormGroup({
      //  awaitingMediaOrdersOnly: new FormControl(),
      //  awaitingApprovalOrders: new FormControl(),
      //  readyToProcessOrdersOnly: new FormControl(),
      //  approvalRejectedOrders: new FormControl()
      //});

      this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};

      this.orderSearchRequest.controls['awaitingMediaOrdersOnly'].setValue(false);
      this.orderSearchRequest.controls['awaitingApprovalOrders'].setValue(false);
      this.orderSearchRequest.controls['readyToProcessOrdersOnly'].setValue(false);
      this.orderSearchRequest.controls['approvalRejectedOrders'].setValue(false);

      this.getReadyToProcessOrders();
    }
  }

  ngAfterViewInit(): void {

  }

  public getReadyToProcessOrders() {
    var orderService = this.orderService;
    var util = this.util;
    var criteriaObj = this.searchOrderCriteriaObj;

    this.ordersReadyToProcessStore.store = new CustomStore({
      key: "orderGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return orderService.getReadyToProcessOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var readyToProcessOrders = response.result;
              var obj: any = {
                data: readyToProcessOrders.orderResults,
                totalCount: readyToProcessOrders.totalCount
              };
              util.ordersReadyToProcessCount = readyToProcessOrders.totalCount;
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public showOrderDetail(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openOrderFile(orderGuid);
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
      if (e.data.isRush) {
        e.rowElement.addClass('tyl-rush-color');
        e.rowElement.removeClass('dx-row-alt');
      }
      else if (e.data.isSetToReadyForProcess) {
        e.rowElement.addClass('tyl-reviewFail-color');
        e.rowElement.removeClass('dx-row-alt');
      }
    }
  }

  public startOrderProcess() {
    var startProcessOrders = this.readyToProcessOrdersGrid.instance.getSelectedRowKeys();

    if (startProcessOrders.length > 0) {
      this.orderService.orderStartProcess(startProcessOrders).pipe(
        finalize(() => {
          this.readyToProcessOrdersGrid.instance.refresh();
        }))
        .subscribe((res: any) => {

          if (res.isSuccess == true) {
            this.readyToProcessOrdersGrid.instance.deselectAll();
            this.orderService.onOrderClaimedforProcessingSuccess();
            this.alertService.showMessage("SUCCESS", Constants.orderStartProcessing, MessageSeverity.success);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
    }
    else {
      this.alertService.showMessage("ERROR", Constants.noOrdersSelected, MessageSeverity.error);
    }
  }

  public onClickCheckBox({ value, valid }: { value: SearchOrderRequestViewModel, valid: boolean }) {

    this.searchOrderCriteriaObj.awaitingMediaOrdersOnly = value.awaitingMediaOrdersOnly;
    this.searchOrderCriteriaObj.awaitingApprovalOrders = value.awaitingApprovalOrders;
    this.searchOrderCriteriaObj.readyToProcessOrdersOnly = value.readyToProcessOrdersOnly;
    this.searchOrderCriteriaObj.approvalRejectedOrders = value.approvalRejectedOrders;

    console.log(this.searchOrderCriteriaObj);
    this.onRefresh();
  }

  public onRefresh() {
    this.readyToProcessOrdersGrid.instance.refresh();
  }

  ngOnDestroy() {
    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}

