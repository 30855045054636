<div id="bundleservices">
  <div class="modal-header">
    <button type="button" class="close delete" (click)="closePopup()"></button>
    <h4 class="modal-title">BUNDLE SERVICES</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="bundleServicesForm" *ngIf="bundleServicesDataSource.length > 0 && servicesDataSource.length > 0"
          (submit)="onaddEditBundleServicesFormSubmit(bundleServicesForm)" novalidate #ngForm="ngForm">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{'error': (bundleServicesForm.controls['bundleServiceCode'].errors
               && (!bundleServicesForm.controls['bundleServiceCode'].pristine || ngForm.submitted))}">
            <label for="bundleServiceSelect" class="form-group-label">Bundle Service<span class="required">*</span></label>
            <dx-select-box *ngIf="isEdit == false"
                           [dataSource]="bundleServicesDataSource"
                           name="bundleServiceCode" id="bundleServiceCode" class="form-control"
                           formControlName="bundleServiceCode"
                           valueExpr="code"
                           displayExpr="name"
                           placeholder="Select..">
            </dx-select-box>
            <p class="p-extra-small">Please select bundle service</p>

            <div *ngIf="isEdit == true">
              <div>{{bundleServicesForm.controls['bundleServiceName'].value}}</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div *ngIf="true" class="form-group" [ngClass]="{'error': ((!bundleServicesForm.controls['serviceCodes'].pristine || ngForm.submitted) && bundleServicesForm.controls['serviceCodes'].errors)}">
            <label for="serviceCodes">Services<span class="required">*</span></label>
              <dx-drop-down-box [(value)]="servicesValue"
                                formControlName="serviceCodes"
                                class="form-control"
                                valueExpr="code"
                                displayExpr="name"
                                placeholder="Select Services"
                                [showClearButton]="true"
                                (onValueChanged)="syncTreeViewSelection()"
                                [dataSource]="servicesDataSource">
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view [dataSource]="servicesDataSource"
                                dataStructure="plain"
                                keyExpr="code"
                                #treeView
                                selectionMode="multiple"
                                showCheckBoxesMode="normal"
                                displayExpr="name"
                                [selectByClick]="true"
                                (onContentReady)="syncTreeViewSelection($event)"
                                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
            <p class="p-extra-small">Please select services</p>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <br />
      </div>

      <div class="row">
        <div class="col-md-4 col-md-offset-8">
          <div class="form-group media-qc tyl-right">
            <button class="btn btn-small qc-update" type="submit">Save</button>
            <span class="qc-cancel p-normal" (click)="closePopup()">Cancel</span>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="bundleServicesDataSource.length == 0 || servicesDataSource.length == 0">
      <span class="p-small">No bundle services available to add.</span>
    </div>
  </div>
</div>
