import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-spotexpirationnotif',
  templateUrl: './servicespotexpirationnotif.component.html'
})
export class ServiceSpotExpirationNotifQComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super(); 
  }

  ngOnInit() {

    super.ngOnInit(); 
  }
}
