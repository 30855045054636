<dx-data-grid class="tylie-grid" id="orderDetailShareLinks"
              #orderDetailShareLinks
              [dataSource]="orderDetailShareLinkStore"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [showColumnLines]="true"
              [showRowLines]="true"
              [hoverStateEnabled]="true"
              [rowAlternationEnabled]="true"
              [showBorders]="true"
              (onRowPrepared)="onRowPrepared($event)"
              keyExpr="orderDestinationId">

  <dxi-column dataField="groupName" width="showTransferLinkColumn?15%:15%" cssClass="gridStyle" caption="Name" sortOrder="asc"></dxi-column>
  <dxi-column dataField="groupEmails" width="showTransferLinkColumn?43%:46%" cssClass="gridStyle" caption="Distribution List"></dxi-column>
  <dxi-column dataField="orderGroup" width="showTransferLinkColumn?10%:12%" cssClass="gridStyle tyl-text-align-left" caption="Group"></dxi-column>
  <dxi-column dataField="status" width="showTransferLinkColumn?10%:12%" cssClass="gridStyle" caption="Status"></dxi-column>
  <dxi-column dataField="podDate" width="showTransferLinkColumn?12%:15%" cellTemplate="dateTemplate" cssClass="gridStyle" caption="Status Date (EST)"></dxi-column>
  <dxi-column dataField="transferLink" width="10%" cellTemplate="transferLinkTemplate" cssClass="gridStyle" caption="Share Link" [visible]="showTransferLinkColumn"></dxi-column>

  <div *dxTemplate="let data of 'dateTemplate'">
    <div style="text-align:left;">{{  data.value | convertFromUTC:'MM/DD/YYYY hh:mm A' }}</div>
  </div>

  <div *dxTemplate="let t of 'transferLinkTemplate'">
    <span *ngIf="t.data.transferLink != null" class="blue-text p-extra-small" (click)='onClickOpenTransferLink(t.data.transferLink)'>Share Link</span>
  </div>

  <dxo-sorting mode="multiple"></dxo-sorting>
</dx-data-grid>
    

