import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from "../../../../services/core/alert.service";
import { Utilities } from "../../../../services/core/utilities";
import { ConfigService } from "../../../../services/core/config.service";
import { OrderService } from "../../../../services/order/order.service";
import { OrderDetail } from '../../../../models/order/order-detail.model';
@Component({
  selector: 'assignvendor',
  templateUrl: './assignvendor.component.html',
  styleUrls: ['./assignvendor.component.css'],
})
export class AssignVendorComponent implements OnInit {
  @Input('orderGuid') orderGuid: string;
  @Input('destinationId') destinationId: number;
  @Input('destinationCode') destinationCode: string;
  @Input('adids') adids: string[] = [];
  @Input('vendorId') excludeVendorId: number;
  @Input('serviceId') serviceId: number;

  @Output('saveVendorAssignment') saveVendorAssignment: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output('closePopUp') closePopUp: EventEmitter<void> = new EventEmitter<void>();

  public updateOrderCarrierRequest: FormGroup;
  public orderDetail: OrderDetail;
  public carrierDataSource: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private util: Utilities,
    private configService: ConfigService,
    private orderService: OrderService) {
  }

  ngOnInit() {
    this.updateOrderCarrierRequest = new FormGroup({
      vendorId: new FormControl('', Validators.required),
      applyToAllDestinations: new FormControl(true, Validators.required),
    });
    this.getOrderDetail();
  }

  private getOrderDetail() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.getOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.alertService.logMessage(res);

        this.orderDetail = res.result as OrderDetail;
        if (this.orderDetail != null && this.orderDetail.header != null) {
          this.getAssignVendorConfig({ clientId: this.orderDetail.header.clientId, brandId: this.orderDetail.header.brandId, destinationId: this.destinationId, serviceId: this.serviceId, excludeVendorId: this.excludeVendorId });
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public getAssignVendorConfig(request: any) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getAssignVendorConfig(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.carrierDataSource = res.result.vendors;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        if (this.util.handleError(error)) {
          this.closePopup();
        }
        this.alertService.ShowLoader(false);
      });
  }

  public onFormSubmit(updateOrderCarrierRequest: any) {
    this.saveVendorAssignment.emit(updateOrderCarrierRequest);
  }

  public closePopup() {
    this.closePopUp.emit();
  }
}
