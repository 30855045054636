<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <ng-container *ngIf="orderLevel == true">
    <div class="row" *ngFor="let t of minstransfersList; index as i;">
      <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['minstransfers'].errors && f.submitted && t.mins == null)}">
        <label class="tylie-form-group__lbl" *ngIf="t.id == 1">Mins<span class="required">*</span></label>
        <dx-select-box [dataSource]="minuteOptions" [disabled]="isDisabled"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="t.mins" (ngModelChange)="onChange()"
                       displayExpr="display" valueExpr="val" class="tylie-select form-control">
        </dx-select-box>
        <p class="p-extra-small">Invalid Mins</p>
      </div>
      <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['minstransfers'].errors && f.submitted && t.nooftransfers == null)}">
        <label class="tylie-form-group__lbl" *ngIf="t.id == 1">No of Transfers<span class="required">*</span></label>
        <div class="timecodes">
          <dx-number-box class="tylie-select form-control"
                         [min]="1" [disabled]="isDisabled"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="t.nooftransfers" (ngModelChange)="onChange()"
                         [showSpinButtons]="false"></dx-number-box>
          <span class="glyphicon glyphicon-minus" *ngIf="t.id > 1 && isDisabled === false" (click)="onAddNew(t.id)"></span>
          <span class="glyphicon glyphicon-plus" *ngIf="t.id == 1 && isDisabled === false" (click)="onAddNew(t.id)"></span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
