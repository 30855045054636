import { Injectable } from '@angular/core';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserMgmtService {
  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getTMCRegistrationRequests() {
    return this.http.get(this.apiendPoint.getTMCRegistrationRequestsEndpoint());
  }

  public approveTMCRegistrationRequest(request: any) {
    return this.http.post(this.apiendPoint.approveTMCRegistrationRequestEndpoint(), request);
  }
}
