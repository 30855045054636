import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators, ValidationErrors } from "@angular/forms";
import * as moment from 'moment-timezone';

export function EmailListValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!Validators.required(control) && control.value.trim() != '') {
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails = (control.value as string).trim().split(';');
    var validEmails = 0;
    var inValidEmails = 0;

    emails.forEach(t => {
      if (t.trim() != '') {
        if (EMAIL_REGEXP.test(t.trim()) == false) inValidEmails++;
        else validEmails++;
      }
    });

    if (validEmails > 0 && inValidEmails == 0)
      return null;
  }
  return { inValidEmailList: true };
}

export function EmailListValidatorIfValueExists(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }
  else if (control.value.trim() == '') {
    return null;
  }
  else if (control.value.trim() != '') {
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails = (control.value as string).trim().split(';');
    var validEmails = 0;
    var inValidEmails = 0;

    emails.forEach(t => {
      if (t.trim() != '') {
        if (EMAIL_REGEXP.test(t.trim()) == false) inValidEmails++;
        else validEmails++;
      }
    });

    if (validEmails > 0 && inValidEmails == 0)
      return null;
  }
  return { inValidEmailList: true };
}

export function EmailListValidatorTagBox(control: AbstractControl): { [key: string]: boolean } | null {
  if (!Validators.required(control) && control.value != null) {
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emails = control.value;
    var validEmails = 0;
    var inValidEmails = 0;

    emails.forEach(t => {
      if (t != '') {
        if (EMAIL_REGEXP.test(t) == false) inValidEmails++;
        else validEmails++;
      }
    });

    if (validEmails > 0 && inValidEmails == 0)
      return null;
  }
  return { inValidEmailList: true };
}

export function customEmailValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  return Validators.email(control);
}

export function customPhoneValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  else {
    if (control.value.replace(/\D+/g, '').trim().length == 0 || control.value.replace(/\D+/g, '').trim().length == 10) {
      return null;
    }
  }
  return { inValidPhone: true };
}

export function digitsOnlyValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  else {
    if (/^\d+$/.test(control.value) && control.value > 0) {
      return null;
    }
  }
  return { inValidAliasId: true };
}

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length < 2;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function emptySpacesValidator(control: AbstractControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function customTimeCodeValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  else {
    let timeCodeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]:[0-9][0-9]$/;
    if (timeCodeRegex.test(control.value)) {
      return null;
    }
  }
  return { inValidAliasId: true };
}

export function customTimeValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  else {
    let timeCodeRegex = /^(?:[0-1]?\d|2[0-3]):[0-5]?\d:[0-5]?\d$|^(?:[0-5]?\d):[0-5]?\d$|^\d{1,3}$/;
    if (timeCodeRegex.test(control.value)) {
      return null;
    }
  }
  return { inValidAliasId: true };
}

export function filePathValidator(control: AbstractControl): ValidationErrors {
  let pathRegex = /^[a-zA-Z0-9_ -]*$/;

  if (!control.value || control.value.trim() == 0 || !pathRegex.test(control.value.trim()))
    return { inValidFilePath: true };
  else
    return null;
}

export function transcodeFilePathValidator(control: AbstractControl): ValidationErrors {
  let pathRegex = /^[a-zA-Z0-9_ -~]*$/;

  if (!control.value || control.value.trim() == 0 || !pathRegex.test(control.value.trim()))
    return { inValidFilePath: true };
  else
    return null;
}

export function fileExtExistsInFilePath(control: AbstractControl): ValidationErrors {
  //if (!control.value) {
  //  return null;
  //}
  //else
  //  return (/[.]/.exec(control.value)) ? null : {invalidFileName:true};

  

  let pathRegex = /[^\s]+(.*?).(srt|txt|vtt|srt subrip|scc|cap)$/;

  if (!control.value || control.value.trim() == 0)
    return null;
  else if (!pathRegex.test(control.value.trim())) {
    return { invalidFileName: true };
  }
  else
    return null;
}

export function fileOptionsValidator(control: AbstractControl): ValidationErrors {

  if (!control.value || control.value.length == 0 || control.value.filter(t => t.selected && (t.isCustom == false || (t.isCustom == true && t.name.trim() != ''))).length == 0)
    return { inValidFileOptions: true };
  else
    return null;
}

//export function CustomValidator(control: AbstractControl): ValidationErrors {
//  if ((control.value as string).indexOf(' ') >= 0)
//    return { cannotContainSpace: true }
//  else
//    return null;
//}

export class CustomValidator {
  static required(control: AbstractControl): ValidationErrors | null {
    if (!control.value || (control.value as string).trim() == '') {
      return { required: true }
    }

    return null;
  }
}
