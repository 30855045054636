
import {finalize} from 'rxjs/operators';
import { Component } from '@angular/core';
import { OrderService } from '../../../../services/order/order.service';
import { Utilities } from '../../../../services/core/utilities';
import { UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import { OrderFile } from '../../../../models/order/order-create.model';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';

@Component({
  selector: 'service-thumbnailimages',
  templateUrl: './servicethumbnailimages.component.html'
})
export class ServiceThumbnailImagesQComponent extends BaseServiceDataCollectionComponent {

  public isUploading: boolean = false;
  public timecodes: Array<any> = [];
  public isTimecodeDisabled: boolean = false;
  public isUploadFileDisabled: boolean = false;

  constructor(private orderService: OrderService,
    public util: Utilities,
    private uploadOrderFileService: UploadOrderFileService) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.isTimecodeDisabled = this.formGroup.controls["timecode"].disabled;

    this.isUploadFileDisabled = this.formGroup.controls["thumbnailFile"].disabled;

    if (this.formGroup.controls["timecode"].value == null) {
      this.timecodes.push({ id: 1, value: '' });
    }
    else {
      var exisitingValues = this.formGroup.controls["timecode"].value as Array<string>;

      exisitingValues.forEach((item, index) => {
        this.timecodes.push({ id: index + 1, value: item });
      });
    }

    if (this.formGroup.controls["thumbnailFile"].value == null)
      this.formGroup.controls["thumbnailFile"].setValue([]);
  }

  public onEnterTimecode(): void {
    this.formGroup.controls["timecode"].setValue(this.timecodes.filter(t => t.value != '').map(x => x.value));
  }

  public onAddTimecode(i) {
    if (i == 1)
      this.timecodes.push({ id: this.timecodes[this.timecodes.length - 1].id + 1, value: '' });
    else if (i > 1)
      this.timecodes = this.timecodes.filter(t => t.id != i);

    this.formGroup.controls["timecode"].setValue(this.timecodes.filter(t => t.value != '').map(x => x.value));
  }

  public onAttachFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {

      this.isUploading = true;

      let uploadFile = event.target.files[0];

      var request: UploadOrderFileRequest = {
        fileType: uploadFile.type,
        fileName: uploadFile.name,
        description: "",
        fileCategory: "serviceMetadata",
        orderGuid: this.orderGuid,
        orderDestinationId: 0
      };

      this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
        finalize(() => {
          this.isUploading = false;
        }))
        .subscribe((newFile: OrderFile) => {
          if (newFile != null && newFile != undefined) {
            this.addFilesToList(newFile);
          }
        });
    }
    else
      this.isUploading = false;
  }

  private addFilesToList(newFile: OrderFile) {
    var exisitingFiles = this.formGroup.controls["thumbnailFile"].value as Array<OrderFile>;
    exisitingFiles.push(newFile);
  }

  public onRemoveFile(orderFileGuid: string) {

    var exisitingFiles = this.formGroup.controls["thumbnailFile"].value as Array<OrderFile>;
    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    this.formGroup.controls["thumbnailFile"].setValue(exisitingFiles);

    //this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var exisitingFiles = this.formGroup.controls["thumbnailFile"].value as Array<OrderFile>;
    //    exisitingFiles = exisitingFiles.filter(t => t.orderFileGuid != orderFileGuid);

    //    this.formGroup.controls["thumbnailFile"].setValue(exisitingFiles);
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }

    //  this.isUploading = false;
    //},
    //  error => {
    //    this.isUploading = false;
    //    this.util.handleError(error);
    //  });
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {
    console.log(orderFileGuid);

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }
}
