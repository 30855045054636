<div class="tylie-accordion accordion" [formGroup]="orderService">
  <div class="tylie-accordion__panel accordion-item">
    <!-- <div class="panel-heading">
      <h4 class="panel-title" data-toggle="collapse" [attr.data-target]="'#' + attributeId">
        <span class="p-normal">{{orderService.controls['serviceName'].value}}</span>
        <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onClickDeleteOrderLvlService()" *ngIf="formGroupCount+orderGrpFormGroupCount > 1">
          <icon-trash-can color="#1e90ff"></icon-trash-can>
        </a>
      </h4>
    </div> -->

    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + attributeId" aria-expanded="true" aria-controls="collapseOne">
        {{orderService.controls['serviceName'].value}}
        <a class="tylie-button tylie-button--xs tylie-button--link ms-4" (click)="onClickDeleteOrderLvlService()" *ngIf="formGroupCount+orderGrpFormGroupCount > 1">
          <icon-trash-can color="#1e90ff"></icon-trash-can>
        </a>
      </button>
    </h2>


    <div [attr.id]="attributeId" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content">
          <div class="row">
            <div class="col-md-5">
              <div class="tylie-form-group form-group" [ngClass]="{error: (orderService.controls['vendor'].errors && (!orderService.controls['vendor'].pristine || parentForm.submitted))}">
                <label class="tylie-form-group__lbl" for="vendor">Vendor<span class="required">*</span></label>
                <dx-select-box [dataSource]="orderService.controls['nonDigitalVendors'].value"
                              name="vendor" id="vendor" class="tylie-select form-control"
                              [searchEnabled]="true"
                              valueExpr="id"
                              [showClearButton]="true"
                              displayExpr="name"
                              formControlName="vendor"
                              placeholder="Select a vendor..."
                              (onValueChanged)="onVendorChange($event)">
                </dx-select-box>
                <p class="p-extra-small">Please Select a Vendor</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group  ">
                <label class="tylie-form-group__lbl" for="cf">Due Date<span></span></label>
                <dx-date-box type="date" width="100%" id="cf" class="tylie-select form-control" formControlName="dueDate" [min]="now" [showClearButton]="true"
                            (onValueChanged)="onDueDateChange($event, orderService.controls['vendor']?.value)"></dx-date-box>
              </div>
            </div>
          </div>
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="ordernotes">Notes</label>
            <textarea id="notes" name="notes" formControlName="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
