<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="row">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['format'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="fileType">Format<span class="required">*</span></label>
      <dx-drop-down-box id="format"
                        name="format"
                        class="tylie-select form-control"
                        [(value)]="selectedFormats"
                        valueExpr="value"
                        displayExpr="name"
                        formControlName="format"
                        (onValueChanged)="onFormatChanged()"
                        [placeholder]="placeholder"
                        [dataSource]="optionsFormat">
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view [dataSource]="optionsFormat"
                        dataStructure="plain"
                        keyExpr="value"
                        displayExpr="name"
                        #treeFormat
                        selectionMode="single"
                        showCheckBoxesMode="normal"
                        (onContentReady)="onFormatChanged($event)"
                        (onItemSelectionChanged)="onFormatTreeChanged($event)"
                        [selectByClick]="true">
          </dx-tree-view>
        </div>
      </dx-drop-down-box>
      <p class="p-extra-small">Please choose a format</p>
    </div>
  </div>
</div>
