import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'copyorderoptionspopup',
  templateUrl: './copyorderoptionspopup.component.html',
  styleUrls: ['./copyorderoptionspopup.component.css'],
})
export class CopyOrderOptionsPopupComponent extends BasePopupComponent {

  public copyOptionsRequest: FormGroup;
  public onComplete: Subject<any>;

  constructor(
    public bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onComplete = new Subject();

    this.copyOptionsRequest = new FormGroup({
      media: new FormControl(true),
      services: new FormControl(true),
      destinations: new FormControl(true)
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSave({ value, valid }: { value: any, valid: boolean }) {
    this.onComplete.next(value);
    this.closePopup();
  }
}
