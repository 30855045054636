<!-- <nav-header headerTitle="Create New Destination" link="Back To All Destinations" [linkRoute]="destinationRoute"></nav-header> -->
<header class="tylie-header">
  <h3 class="tylie-header__title">Create New Destination</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoDestinationHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Destinations</button>
  </nav>
</header>

<div class="tylie-body" *ngIf="createDestinationConfig">
  <form [formGroup]="createDestinationFormGroup" novalidate role="search" #f="ngForm">      
    <div class="tylie-panel tylie-panel--clean" id="createclient">
      <div class="tylie-panel__body" id="createClientBody">
        <div class="row">
          <div class="col-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['code'].errors && !createDestinationFormGroup.controls['code'].pristine)
            || (createDestinationFormGroup.controls['code'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="code">Code<span class="required">*</span></label>
              <input type="text" name="code" id="code" formControlName="code" class="tylie-text form-control">
              <p class="p-extra-small">Please enter a code</p>
            </div>
          </div>

          <div class="col-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['name'].errors && !createDestinationFormGroup.controls['name'].pristine)
            || (createDestinationFormGroup.controls['name'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="code">Name<span class="required">*</span></label>
              <input type="text" name="name" id="name" formControlName="name" class="tylie-text form-control">
              <p class="p-extra-small">Please enter a name</p>
            </div>
          </div>

          <div class="col-3" >
            <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['destinationType'].errors && !createDestinationFormGroup.controls['destinationType'].pristine)
            || (createDestinationFormGroup.controls['destinationType'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="destinationType">Type<span class="required">*</span></label>
              <dx-select-box [dataSource]="createDestinationConfig.destinationTypes"
                              name="destinationType" id="destinationType" class="tylie-select form-control"
                              formControlName="destinationType"
                              valueExpr="id"
                              displayExpr="name"
                              [showClearButton]="true"
                              placeholder="Select a value..."
                              (onValueChanged)="onDestinationTypeChange()">
              </dx-select-box>
              <p class="p-extra-small">Please select a type</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="code">AKAs</label>
              <input type="text" name="akas" id="akas" formControlName="akas" class="tylie-text form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <destination-contact [destinationContact]="createDestinationFormGroup.get('destinationContact')"
                           [states]="createDestinationConfig.states"
                           [contactTypes]="createDestinationConfig.destinationContactTypes"
                           [parentForm]="f"></destination-contact>
      </div>
      <div class="col-6">
        <div class="tylie-panel">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              OTHER
            </h4>
          </div>
          
          <div class="tylie-panel__body" id="createClientBody">

            <div class="row">
              <div class="col-12">
                <label class="tylie-form-group__lbl" [ngClass]="{error: (createDestinationFormGroup.controls['defaultHub'].errors && !createDestinationFormGroup.controls['defaultHub'].pristine)
                || (createDestinationFormGroup.controls['defaultHub'].errors && f.submitted)}" for="defaultHub">Default Hub</label>

              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['defaultHub'].errors && !createDestinationFormGroup.controls['defaultHub'].pristine)
            || (createDestinationFormGroup.controls['defaultHub'].errors && f.submitted)}">
                  <input type="text" id="defaultHub" name="defaultHub" formControlName="defaultHub" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter a valid default hub</p>
                </div>
              </div>
              <div class="col-6">
                <button class="tylie-button tylie-button--sm" type="button" (click)="onDefaultHubSearch()">
                  Search
                </button>
              </div>
            </div>

            <div class="row" *ngIf="isDestinationTypeBackup == true">
              <div class="col-12">
                <label class="tylie-form-group__lbl" [ngClass]="{error: (createDestinationFormGroup.controls['primaryDestination'].errors && !createDestinationFormGroup.controls['primaryDestination'].pristine)
                || (createDestinationFormGroup.controls['primaryDestination'].errors && f.submitted)}" for="primaryDestination">Primary Destination<span class="required">*</span></label>

              </div>
              <div class="col-6">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['primaryDestination'].errors && !createDestinationFormGroup.controls['primaryDestination'].pristine)
            || (createDestinationFormGroup.controls['primaryDestination'].errors && f.submitted)}">
                  <input type="text" id="primaryDestination" name="primaryDestination" formControlName="primaryDestination" class="tylie-text form-control " />
                  <p class="p-extra-small">Please enter a valid primary destination</p>
                </div>
              </div>
              <div class="col-6">
                <button class="tylie-button tylie-button--sm" type="button" (click)="onPrimaryDestinationSearch()">
                  Search
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-6" *ngIf="isDestinationTypeInternational == false">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="market">Market<span class="required">*</span></label>
                  <input type="text" id="market" name="market" formControlName="market" class="tylie-text form-control" />
                  <p class="p-extra-small">Please enter a valid Market</p>
                </div>
              </div>

              <div class="col-6" *ngIf="isDestinationTypeInternational == true">
                <div class="tylie-form-group form-group" [ngClass]="{error: (createDestinationFormGroup.controls['market'].errors
                    && !createDestinationFormGroup.controls['market'].pristine)
                || (createDestinationFormGroup.controls['market'].errors && f.submitted)}">
                  <label class="tylie-form-group__lbl" for="market">Market<span class="required">*</span></label>
                  <dx-select-box [dataSource]="createDestinationConfig.markets"
                                 name="market" id="market" class="tylie-select form-control"
                                 formControlName="market"
                                 valueExpr="code"
                                 displayExpr="displayName"
                                 [showClearButton]="true"
                                 placeholder="Select a Market">
                  </dx-select-box>
                  <p class="p-extra-small">Please select a Market</p>
                </div>
              </div>

              <div class="col-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="acceptableFormats">Acceptable Format<span class="required">*</span></label>
                  <dx-drop-down-box [dataSource]="createDestinationConfig.formatTypes"
                                    [(value)]="treeBoxValue"
                                    name="acceptableFormats" id="acceptableFormats" class="tylie-select form-control"
                                    formControlName="acceptableFormats"
                                    valueExpr="id"
                                    [showClearButton]="true"
                                    displayExpr="name"
                                    placeholder="Select Formats"
                                    (onValueChanged)="syncTreeViewSelection()">

                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view [dataSource]="createDestinationConfig.formatTypes"
                                    dataStructure="plain"
                                    keyExpr="id"
                                    selectionMode="multiple"
                                    showCheckBoxesMode="normal"
                                    [selectNodesRecursive]="false"
                                    displayExpr="name"
                                    [selectByClick]="true"
                                    (onContentReady)="syncTreeViewSelection($event)"
                                    (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                  <p class="p-extra-small">Please select Formats</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-check pt-4">
                  <input type="checkbox" id="isCompanionFormatRequired" name="isCompanionFormatRequired" formControlName="isCompanionFormatRequired" class="form-check-input" />
                  <label class="form-check-label" for="comments">Companion Format Required<span class="required"></span></label>
                </div>
              </div>

              <div class="col-6">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="primaryFormat">Preferred Network</label>
                  <dx-select-box [dataSource]="createDestinationConfig.vendors"
                                 name="secondaryFormat" id="secondaryFormat" class="tylie-select form-control"
                                 formControlName="preferredVendorId"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select Vendor"
                                 (onValueChanged)="onPreferredNetworkChange()">
                  </dx-select-box>
                  <div *ngIf="this.showDistributionSettings == true">
                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddDistributionSettings()">Distribution Settings</button>
                  </div>
                </div>
              </div>
            </div>


            <div class="row" *ngIf="networkSpecifications.length > 0">
              <div class="col-12">
                <div class="tylie- form-group form-group">
                  <label class="tylie-form-group__lbl" for="specMappings">Specification Mappings</label>
                  <ng-container *ngFor="let spec of networkSpecifications; let i = index">
                    <div class="p-extra-small">
                      {{spec.name}}
                      <span class="p-extra-small" *ngIf="spec.recipeName">({{spec.recipeName}})</span>
                      <a class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onAddEditRecipeName(spec, i)">ADD / EDIT RECIPE NAME</a>
                      <a class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onRemoveSpecMapping(spec)">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="this.createDestinationFormGroup.controls['bxfXML'].value != null">
              <div class="col-md-12 form-group">
                <label>BXF File</label>
                <div>
                  <span class="tyl-blue-text p-extra-small tyl-clickable" (click)="onDownloadBxfFile()">Download BXF File</span>
                  <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onRemoveBXfFile()">
                    <icon-trash-can color="#1e90ff"></icon-trash-can>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" id="isTrafficOnly" name="isTrafficOnly" formControlName="isTrafficOnly" class="form-check-input" />
                  <label for="comments" class="form-check-label">Is Traffic Only<span class="required"></span></label>
                </div>
              </div>
              <div class="col-6">
                <div class="tylie-form-group form-check">
                  <input type="checkbox" id="acceptsDescriptiveVideo" name="acceptsDescriptiveVideo" formControlName="acceptsDescriptiveVideo" class="form-check-input" />
                  <label for="comments" class="form-check-label">Accepts DVS<span class="required"></span></label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="comments">Comments<span class="required">*</span></label>
                  <textarea type="text" id="comments" name="comments" formControlName="comments" class="tylie-text form-control"></textarea>
                  <p class="p-extra-small">Please enter Comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <select-network></select-network>

    <div class="d-flex justify-content-end">
      <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" type="submit" (click)="onFormSubmit(createDestinationFormGroup)">
        <span class="tylie-button__icon"><icon-save></icon-save></span>
        <span class="tylie-button__text">Save Destination</span>
      </button>
    </div>

  </form>
</div>
