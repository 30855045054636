import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { OrderService } from "../../../../services/order/order.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { confirmEventType } from "../../../../configurations/enums/enums";
import { Router } from "@angular/router";
import { ConfirmPopupComponent } from "../../../popups/confirmationpopups/confirmpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';
import { DxDataGridComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../configurations/route-constants";
import { GlobalService } from '../../../../services/core/global.service';
import { SubService } from '../../../../services/media/sub.service';

@Component({
  selector: 'app-draft-orders',
  templateUrl: './draft-orders.component.html',
  styleUrls: ['./draft-orders.component.css'],
})

export class DraftOrdersComponent implements OnInit {

  @ViewChild('draftOrderStoreGrid', { static: true }) draftOrderStoreGrid: DxDataGridComponent;
  @Input('draftorderConfig') draftorderConfig: any;

  public draftOrderStore: any = {};
  public modalRef: BsModalRef;
  public showAllColumns: boolean = false;
  public isClientAdmin: boolean = false;

  constructor(public util: Utilities
    , private orderService: OrderService
    , private alertService: AlertService
    , private router: Router
    , private gs: GlobalService
    , private modalService: BsModalService
    , private sub: SubService
    , private userProfileService: UserProfileService
    , private userRoles: UserRoles) { }

  ngOnInit() {

    if (this.draftorderConfig != null) {
      if (this.draftorderConfig.showAllColumns != null || this.draftorderConfig.showAllColumns != undefined) {
        this.showAllColumns = this.draftorderConfig.showAllColumns;
      }
      if (this.draftorderConfig.isClientAdmin != null || this.draftorderConfig.isClientAdmin != undefined) {
        this.isClientAdmin = this.draftorderConfig.isClientAdmin;
      }
    }
    if (this.userProfileService.getUserRoles().includes(this.userRoles.getOrderBuilderRoleName())) {
      this.getDraftOrders();

      this.sub.draftOrderEditedMethodCalled.subscribe(() => { this.draftOrderStoreGrid.instance.refresh(); });
    }
  }

  ngAfterViewInit(): void {
    if (!this.showAllColumns && !this.isClientAdmin) {
      this.draftOrderStoreGrid.instance.columnOption("Created By", "visible", false);
    }
  }

  public onRefresh() {
    this.draftOrderStoreGrid.instance.refresh();
  }

  public getDraftOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.draftOrderStore.store = new CustomStore({
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";
        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: null, GridParams: gridParams };
        return orderService.getDraftOrders(request).toPromise().then((response: any) => {
          if (response.isSuccess == true) {
            var orderResponse = response.result;
            var obj: any = {
              data: orderResponse.orderResults,
              totalCount: orderResponse.totalCount
            };
            util.draftOrdersCount = orderResponse.totalCount;
            return obj;
          }
          else {
            util.handleIsNotSuccess(response.errors);
            throw 'Data Loading Error';
          }
        })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public copyOrder(draftOrderGuid: string) {
    console.log(draftOrderGuid);
    if (draftOrderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    var initialState = {
      eventType: confirmEventType[confirmEventType.CopyOrderDraft]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.orderService.copyDraftOrder(draftOrderGuid).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESSS", Constants.draftOrderCopied, MessageSeverity.success, true);
            this.draftOrderStoreGrid.instance.refresh();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    });
  }

  public continueToOrder(draftOrder: string) {
    if (draftOrder == "00000000-0000-0000-0000-000000000000")
      return;

    //this.router.navigate([RouteConstants.draftOrderRoute, draftOrder]);
    this.gs.openDraftOrder(draftOrder);
  }

  public deleteOrder(deleteDraftOrderGuid: string) {
    var initialState = {
      eventType: confirmEventType[confirmEventType.DeleteDraftOrder]
    };

    this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.orderService.deleteDraftOrder(deleteDraftOrderGuid).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESSS", Constants.draftDeleted, MessageSeverity.success, true);
            this.draftOrderStoreGrid.instance.refresh();
            //this.util.draftOrdersCount = this.draftOrderStoreGrid.instance.totalCount();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    });
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }
}
