import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'mediadetailpopup',
  templateUrl: './mediadetailpopup.component.html',
  styleUrls: ['./mediadetailpopup.component.css'],
})
export class MediaDetailPopupComponent extends BasePopupComponent { 

  @Input() spotFileIdentifier: string;
  
  constructor(
    public bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
