import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { RouteConstants } from "../../../configurations/route-constants";
import { downloadStatusEnum } from '../../../models/download/downloadmedia-status';

@Component({
  selector: 'distribution-media-download',
  templateUrl: './distributionmediadownload.component.html',
  styleUrls: ['./distributionmediadownload.component.css'],
})

export class DistributionMediaDownloadComponent implements OnInit {

  public downloadStatusEnum = downloadStatusEnum;
  public downloadFileStatus = this.util.downloadDistributionFileStatus;

  constructor(
    public util: Utilities,
    private router: Router) {
  }

  ngOnInit() {
  }

  public gotoMedia() {
    this.router.navigate([RouteConstants.distributionRoute]);
  }

  public get anyActiveDownloads() {
    return this.downloadFileStatus.some(e => e.show);
  }

  public clearCompleted(): void {
    for (let ft of this.downloadFileStatus) {
      for (let f of ft.dowloadStatus) {
        if (f.status == downloadStatusEnum.completed)
          f.show = false;
      }

      if (!ft.dowloadStatus.some(e => e.show))
        ft.show = false;
    }
  }
}
