import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { Utilities } from "../../../../../services/core/utilities";
import { AdminService } from "../../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { DxDataGridComponent } from "devextreme-angular";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { Constants } from "../../../../../configurations/constants";
import { DestinationDetail } from "../../../../../models/admin/destination/destinationdetail.model";
import { BsModalRef } from "ngx-bootstrap/modal";

import * as $ from 'jquery';
import 'jqueryui';
import { saveAs } from "file-saver";

@Component({
  selector: 'destination-view',
  templateUrl: './destination-view.component.html',
  styleUrls: ['./destination-view.component.css']
})
export class DestinationViewComponent implements OnInit {

  @Input('destinationDetail') detail: DestinationDetail = null;
  @Input('destinationGuid') destinationGuid: string = null; // only when is popoup == true
  @Input('isPopUp') isPopUp: boolean = false;
  @Output() onEditDestinationEvent: EventEmitter<void> = new EventEmitter<void>();

  public destinationsRoute = RouteConstants.destinationsRoute;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private alertService: AlertService
    , public bsModalRef: BsModalRef) {

  }

  ngOnInit() {
    console.log(this.detail);
    if (this.isPopUp == true) {
      if (this.destinationGuid != null) {
        this.getDestinationView(this.destinationGuid);
      }
    }
  }

  public onEditDestination() {
    this.onEditDestinationEvent.emit();
  }

  public onDestinationStatusUpdate(isCurrent: boolean) {
    this.adminService.updateDestinationStatus(this.detail.destinationGuid, { isCurrent: isCurrent }).subscribe((res: any) => {
      var result = res.result;
      this.detail = result;
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.destinationStatusUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onDownloadBxfFile() {
    const file = new Blob([this.detail.destinationMetaData.bxfXML], { type: 'text/plain' });
    saveAs(file, this.detail.code + "-" + "bxf-file" + ".xml");
  }

  private getDestinationView(destinationGuid: string) {
    //Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.adminService.getDestinationView(destinationGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.detail = res.result as DestinationDetail;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      //this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        //this.alertService.ShowLoader(false);
      });
  }
}
