<div [formGroup]="formGroup" *ngIf="isDeleted === false" class="tylie-section px-0">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="row">
    <div class="col-md-6">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['fileType'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="fileType">File Type<span class="required">*</span></label>
        <dx-drop-down-box id="fileType"
                          name="fileType"
                          [(value)]="selectedFileTypes"
                          valueExpr="value"
                          class="tylie-select"
                          displayExpr="name"
                          formControlName="fileType"
                          (onValueChanged)="onChanged()"
                          [placeholder]="placeholder"
                          [dataSource]="options">
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view [dataSource]="options"
                          dataStructure="plain"
                          keyExpr="value"
                          displayExpr="name"
                          #treeFileType
                          selectionMode="multiple"
                          showCheckBoxesMode="normal"
                          (onContentReady)="onChanged($event)"
                          (onItemSelectionChanged)="onTreeChanged($event)"
                          [selectByClick]="true">
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
        <p class="p-extra-small">Please choose a file type</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="tylie-form-group form-check mt-5">
        <input class="form-check-input" type="checkbox" formControlName="approvalRequired" name="approvalRequired" id="approvalRequired">
        <label class="form-check-label" for="service-to-all-media">Approval Required</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="tylie-form-group form-group" *ngIf="showOtherFileType" [ngClass]="{error: (formGroup.controls['fileTypeCustom'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="fileTypeCustom">Custom File Name<span class="required">*</span></label>
        <input type="text" name="fileTypeCustom" class="tylie-text form-control" formControlName="fileTypeCustom" placeholder="File Name with Ext">
        <p class="p-extra-small">Please enter Custom File Name With a Valid Extension</p>
      </div>
    </div>
  </div>

  <div class="tylie-form-group form-group">
    <label class="tylie-upload" *ngIf="isDisabled === false">
      <input type="file" multiple style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-upload__action--xs">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD SCRIPT'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>
    <p *ngFor="let fl of formGroup.controls['scriptFile'].value;">
      <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>
      <a *ngIf="isDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
    </p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}">
    <label class="tylie-form-group__lbl" for="script-text">Paste Script Text<span class="required">*</span></label>
    <textarea name="script-text" id="script-text" class="tylie-textarea tylie-textarea--md form-control" formControlName="scriptText"></textarea>
    <p class="p-extra-small">Please upload Script file or paste Script text</p>
  </div>
</div>
