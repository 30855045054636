<ng-container *ngIf="controlName" [formGroup]="ctrlGrp">

  <input *ngIf="controlType == 'Text' || controlType == 'text'"
         [formControlName]="controlName"
         type="text"
         class="form-control"
         name="{{controlName}}"
         placeholder="text">

  <dx-number-box *ngIf="controlType == 'Number' || controlType == 'number'"
                 [formControlName]="controlName"
                 class="form-control"
                 [showSpinButtons]="false"
                 name="{{controlName}}"
                 placeholder="0">

  </dx-number-box>

  <textarea *ngIf="controlType == 'TextArea' || controlType == 'textArea' || controlType == 'textarea'"
            [formControlName]="controlName"
            class="form-control"
            name="{{controlName}}">

   </textarea>

  <dx-date-box *ngIf="controlType == 'Date' || controlType == 'date'"
               [formControlName]="controlName"
               type="date"
               class="form-control"
               name="{{controlName}}">

  </dx-date-box>

  <div *ngIf="controlType == 'CheckBoxes' || controlType == 'checkBoxes' || controlType == 'checkboxes'">
    <div [formArrayName]="arrayName" class="customTag-checkbox-sec">
      <ng-container *ngFor="let conf of ctrlGrp.controls.tagValueList.controls; let i = index">
        <input [formControlName]="i"
               type="checkbox"
               value="{{itemsDataSource[i]}}">
        <span>{{itemsDataSource[i]}}</span>
      </ng-container>
    </div>
  </div>

  <dx-select-box *ngIf="controlType == 'DropDown' || controlType == 'dropDown' || controlType == 'dropdown'"
                 [formControlName]="controlName"
                 [items]="itemsDataSource"
                 class="form-control"
                 name="{{controlName}}"
                 [acceptCustomValue]="false"
                 [placeholder]="'Select...'"
                 noDataText="No data available">
  </dx-select-box>

  <div *ngIf="controlType == 'Radio' || controlType == 'radio' || controlType == 'radio'" class="servicelevel">
    <div class="customTag-radio-sec">
      <ng-container *ngFor="let item of itemsDataSource">
        <input type="radio"
               [formControlName]="controlName"
               name="{{controlName}}"
               value="{{item}}">
        <span>{{item}}</span>
      </ng-container>
    </div>
  </div>

</ng-container>
