import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';
import { Validators } from '@angular/forms';
//import * as moment from 'moment-timezone';

@Component({
  selector: 'service-slatereslate',
  templateUrl: './serviceslatereslate.component.html'
})
export class ServiceSlateReslateQComponent extends BaseServiceDataCollectionComponent {

  public addedAsDependency: boolean = false;
  public audioMode: boolean = false;

  private adid: string = '';
  private title: string = '';

  public isDisabled: boolean = false;

  ngOnInit() {

    super.ngOnInit();

    this.isDisabled = this.formGroup.controls["title"].disabled;

    //get the ppFile specs
    if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0) {
      this.audioMode = this.additionalMetadata.ppFileSpecs[0].isAudio;
      this.adid = this.additionalMetadata.ppFileSpecs[0].ppFileAdId;
      this.title = this.additionalMetadata.ppFileSpecs[0].ppFileTitle;
    }

    console.log(this.formGroup.controls);

    //option is not null so check the option & set the reSlate and removeSlate checkboxes.
    if (this.formGroup.controls["option"].value != null && this.formGroup.controls["option"].value != '') {
      this.formGroup.controls["removeSlate"].setValue(this.formGroup.controls["option"].value === 'removeSlate');
      this.formGroup.controls["reslate"].setValue(this.formGroup.controls["option"].value === 'reslate');

      //reSlate then set the title and adid textboxes
      if (this.formGroup.controls["option"].value === 'reslate') {
        this.formGroup.controls["title"].setValue(this.title);
        this.formGroup.controls["adid"].setValue(this.adid);
      }

      if (this.formGroup.controls["keepClientSlate"].value == 'true') {
        this.formGroup.controls["keepClientSlate"].setValue(true);
      }
      else {
        this.formGroup.controls["keepClientSlate"].setValue(false);
      }
    }
    else //option is null so its the first time so set the default values. reSlate: true & removeSlate: false
    {
      this.formGroup.controls["removeSlate"].setValue(false);
      this.formGroup.controls["keepClientSlate"].setValue(false);
      this.formGroup.controls["reslate"].setValue(true);
      this.formGroup.controls["option"].setValue('reslate');

      //set the adid and title textboxes because default mode is reSlate
      this.formGroup.controls["title"].setValue(this.title);
      this.formGroup.controls["adid"].setValue(this.adid);
    }

    this.formGroup.get('reslate').valueChanges.subscribe(
      (reslate: boolean) => {

        if (reslate === true && this.formGroup.get('removeSlate').value != false) {
          this.formGroup.get('removeSlate').setValue(false);

          this.formGroup.controls["title"].setValue(this.title);
          this.formGroup.controls["adid"].setValue(this.adid);
        }

        if (reslate === false && this.formGroup.get('removeSlate').value != true) {
          this.formGroup.get('removeSlate').setValue(true);
          this.formGroup.controls["keepClientSlate"].setValue(false);
        }

        if (reslate === true)
          this.formGroup.controls["option"].setValue('reslate');

        this.setControlValidators();
      });

    this.formGroup.get('removeSlate').valueChanges.subscribe(
      (removeSlate: boolean) => {

        if (removeSlate === true && this.formGroup.get('reslate').value != false) {
          this.formGroup.get('reslate').setValue(false);
        }

        if (removeSlate === false && this.formGroup.get('reslate').value != true) {
          this.formGroup.get('reslate').setValue(true);
         
          this.formGroup.controls["title"].setValue(this.title);
          this.formGroup.controls["adid"].setValue(this.adid);
        }

        if (removeSlate === true) {
          this.formGroup.controls["option"].setValue('removeSlate');
          this.formGroup.controls["keepClientSlate"].setValue(false);
        }

        this.setControlValidators();
      });

    this.formGroup.get('keepClientSlate').valueChanges.subscribe(
      (keepClientSlate: boolean) => {

        if (keepClientSlate === true && this.formGroup.get('removeSlate').value != false) {
          this.formGroup.get('removeSlate').setValue(false);
          this.formGroup.get('reslate').setValue(true);
        }

        this.setControlValidators();
      });

    //added as dependency so set reSlate: true & removeSlate: false
    if (this.formGroup.controls["parentServices"].value.length > 0) {
      this.formGroup.controls["removeSlate"].setValue(false);
      this.formGroup.controls["reslate"].setValue(true);
      this.formGroup.controls["option"].setValue('reslate');

      this.addedAsDependency = true;
    }

    this.setControlValidators();
  }

  private setControlValidators() {

    var isRequired = ((this.formGroup.get('reslate').value == true) && this.applyAllMode == false);

    if (isRequired == true) {

      this.formGroup.get('adid').setValidators([Validators.required]);
      this.formGroup.get('adid').updateValueAndValidity();

      this.formGroup.get('title').setValidators([Validators.required]);
      this.formGroup.get('title').updateValueAndValidity();

      if (this.audioMode == true) {
        this.formGroup.get('date').setValidators([Validators.required]);
        this.formGroup.get('date').updateValueAndValidity();

        this.formGroup.get('brand').setValidators([Validators.required]);
        this.formGroup.get('brand').updateValueAndValidity();

        this.formGroup.get('product').setValidators([Validators.required]);
        this.formGroup.get('product').updateValueAndValidity();

        this.formGroup.get('advertiser').setValidators([Validators.required]);
        this.formGroup.get('advertiser').updateValueAndValidity();
      }
    }
    else {
      this.formGroup.controls["adid"].clearValidators();
      this.formGroup.controls["adid"].setValue('');
      this.formGroup.controls["adid"].updateValueAndValidity();

      this.formGroup.controls["title"].clearValidators();
      this.formGroup.controls["title"].setValue('');
      this.formGroup.controls["title"].updateValueAndValidity();

      // move this with in audio if any issues

      this.formGroup.controls["brand"].clearValidators();
      this.formGroup.controls["brand"].setValue('');
      this.formGroup.controls["brand"].updateValueAndValidity();

      this.formGroup.controls["product"].clearValidators();
      this.formGroup.controls["product"].setValue('');
      this.formGroup.controls["product"].updateValueAndValidity();

      this.formGroup.controls["advertiser"].clearValidators();
      this.formGroup.controls["advertiser"].setValue('');
      this.formGroup.controls["advertiser"].updateValueAndValidity();

      if (this.audioMode == true) {
        this.formGroup.controls["date"].clearValidators();
        this.formGroup.controls["date"].setValue(null);
        this.formGroup.controls["date"].updateValueAndValidity();
      }
    }
  }
}
