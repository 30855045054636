<header class="tylie-header">
  <h3 class="tylie-header__title">Assets Library</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onGotoTranscodeFilesMain()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>BACK TO TRANSCODE FILES MAIN</button>
  </nav>
</header>

<div class="tylie-body">
  <div id="trans-list-section" class="notifications-section" *ngIf="listType == 'saved'">
    <div class="tylie-panel">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          TRANSCODE ORDER LISTS
        </h4>
      </div>
      <div class="tylie-panel__body">
        <h3 class="tylie-type-h3">Saved Transcode Order Lists</h3>
        
        <dx-data-grid id="transcodesSavedListGrid" class="tylie-grid tylie-grid--bordered mb-4"
                      #transcodesSavedListGrid
                      [dataSource]="transcodesSavedListSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      keyExpr="id">
          <dxi-column dataField="name" width="20%" cssClass="gridStyle" caption="List Name" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="favDestination" width="70%" cssClass="gridStyle" caption="OLV Profiles in List" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="name" width="10%" cssClass="gridStyle" caption="Actions" cellTemplate="actionsTemplate" [allowSorting]="false"></dxi-column>
          <div *dxTemplate="let t of 'actionsTemplate'">
            <div class="d-flex justify-content-center">
              <a class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onItemEdit(t.data.id)">
                <icon-edit-pencil color="#1e90ff"></icon-edit-pencil>
              </a>
              <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button"
                      placement="left" #listdeletepop="bs-popover" [popoverContext]="t.data" container="body" [popover]="deleteListTemplate">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </a>
            </div>
          </div>
        </dx-data-grid>
      
        <h3 class="tylie-type-h3">New Transcode Order List</h3>
        <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="onCreateNewList()"><span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>NEW TRANSCODE ORDER LIST</button>
      </div>
    </div>
  </div>


  <div id="trans-list-section" class="notifications-section" *ngIf="listType == 'olv'">
    <div class="tylie-panel">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
          VIEW OLV PROFILES
        </h4>
      </div>

      <div class="tylie-panel__body">
        <div class="row">
          <div class="col-md-8">
            <h3 class="tylie-type-h3 mb-1">These are the current OLV Profiles available in our system for creating Transcode Files.</h3>
            <div class="p-extra-small olv-text">Don't see the Spec you're looking for? <a class="tylie-type-lnk" href="mailto:support@tylie.com">Let Us Know.</a></div>
          </div>
          <div class="col-md-4">
            <section class="tylie-input-search">
              <div class="tylie-input-search__inner">
                <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                <input class="tylie-input-search__text" placeholder="Search by OLV Profile or Spec" name="srch-term" id="srch-term" type="search"
                    [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()" (keydown.enter)="onQuickSearch()">
              </div>
              <div class="tylie-input-search__btn">
                <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearch()">Search</button>
              </div>
            </section>
          </div>
        </div>

        <div class="tylie-grid-wrapper">
          <dx-data-grid id="olvGrid" class="tylie-grid tylie-grid--bordered"
                        #olvGrid
                        [dataSource]="olvSource"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [rowAlternationEnabled]="true"
                        [hoverStateEnabled]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        keyExpr="id">
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxi-column width="30%" caption="OLV Profiles" dataField="name" cssClass="gridStyle" sortOrder="asc"></dxi-column>
            <dxi-column width="70%" caption="Transcode Specs" dataField="description" [allowSorting]="false" cssClass="gridStyle"></dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteListTemplate let-id="id">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this List?
    </p>
    <i (click)="onHideDeleteListPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onItemDelete(id)">Yes</button> -->


  <button type="button" class="popover__close" (click)="onHideDeleteListPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this List?</header>
  <button class='tylie-button tylie-button--xs' type="button" (click)="onItemDelete(id)">Delete</button>
</ng-template>
