<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Asset Share Billing Settings</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedBrandsDataSource.length > 0">
      <span class="p-small">Asset Share Billing Settings would be applied to the brands if selected or would be applied to client.</span>
      <p>If a brand is selected any settings at client level will be removed and vice-versa.</p>
    </div>
    <div *ngIf="brandName" class="row">
      <div class="col-md-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl">Brand</label>
          <div class="tylie-form-group__txt">{{brandName}}</div>
        </div>
      </div>
    </div>

    <form [formGroup]="assetShareBillingSettingsForm" (submit)="onSaveAssetShareBillingSettingsFormSubmit(assetShareBillingSettingsForm)" novalidate #ngForm="ngForm">
      <div class="row" *ngIf="selectedBrandsDataSource.length > 0">
        <div class="tylie-form-group form-group col-md-6">
          <label class="tylie-form-group__lbl" for="selectedBrandsForAssetShareBilling">Brands<span class="required">*</span></label>
          <dx-drop-down-box [(value)]="brandsValue"
                            formControlName="selectedBrandsForAssetShareBilling"
                            id="selectedBrandsForAssetShareBilling"
                            class="tylie-select form-control"
                            valueExpr="brandId"
                            displayExpr="brandName"
                            placeholder="Select Brands"
                            [showClearButton]="true"
                            (onValueChanged)="syncTreeViewSelection()"
                            [dataSource]="selectedBrandsDataSource">
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view [dataSource]="selectedBrandsDataSource"
                            dataStructure="plain"
                            keyExpr="brandId"
                            #treeBrands
                            selectionMode="multiple"
                            showCheckBoxesMode="normal"
                            displayExpr="brandName"
                            [selectByClick]="true"
                            (onContentReady)="syncTreeViewSelection($event)"
                            (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
              </dx-tree-view>
            </div>
          </dx-drop-down-box>
          <p class="p-extra-small">Please select Brands</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3" id="billingType" *ngIf="(this.brandsValue == null || this.brandsValue.length == 0) && brandName == null">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (assetShareBillingSettingsForm.controls['billingType'].errors
               && (!assetShareBillingSettingsForm.controls['billingType'].pristine || ngForm.submitted))}">
            <label for="billingType" class="tylie-form-group__lbl">Billing Type<span class="required">*</span></label>
            <dx-select-box [dataSource]="transcodeBillingTypes"
                           name="billingType" id="billingType" class="tylie-select form-control"
                           formControlName="billingType"
                           [searchEnabled]="true"
                           valueExpr="name"
                           displayExpr="name"
                           placeholder="Billing Type"
                           (onValueChanged)="onbillingTypeChange($event)">
            </dx-select-box>
            <p class="p-extra-small">Please Select Billing Type</p>
          </div>
        </div>

        <div class="col-md-3" id="billingType" *ngIf="(this.brandsValue != null && this.brandsValue.length > 0) || brandName">
          <div class="tylie-form-group form-group">
            <label for="billingType" class="tylie-form-group__lbl">Billing Type<span class="required">*</span></label>
            <p>{{defaultBillingTypeForBrand}}</p>
          </div>
        </div>

        <div class="col-md-3" id="billingCycle">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (assetShareBillingSettingsForm.controls['billingCycle'].errors
               && (!assetShareBillingSettingsForm.controls['billingCycle'].pristine || ngForm.submitted))}">
            <label for="dataType" class="tylie-form-group__lbl">Billing Frequency<span class="required">*</span></label>
            <dx-select-box [dataSource]="billingFreqDataSource"
                           name="billingCycle" id="billingCycle" class="tylie-select form-control"
                           formControlName="billingCycle"
                           [searchEnabled]="true"
                           valueExpr="name"
                           displayExpr="name"
                           placeholder="Billing Frequency">
            </dx-select-box>
            <p class="p-extra-small">Please select Billing Frequency</p>
          </div>
        </div>

        <div class="col-md-3">
          <div class="tylie-form-group">
            <label class="tylie-form-group__lbl form-group" for="ct">Next Billing Cycle </label>
            <dx-date-box type="date" width="100%" formControlName="nextBillingCycle" id="ct"
                         displayFormat="MM/dd/yyyy"
                         class="tylie-datepicker form-control" [showClearButton]="true" [min]="now"></dx-date-box>
          </div>
        </div>
      </div>

      <div class="row" formGroupName="tier" *ngIf="assetShareBillingSettingsForm.controls['billingType']?.value == this.flatBillingType">
        <div class="col-md-6" id="level">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (assetShareBillingSettingsForm.controls['tier'].controls['level'].errors
           && (!assetShareBillingSettingsForm.controls['tier'].controls['level'].pristine || ngForm.submitted))}">
            <label for="billingType" class="tylie-form-group__lbl">Asset Share Tier Level<span class="required">*</span></label>
            <dx-select-box [dataSource]="flatTranscodeTiers"
                           name="level" id="level" class="tylie-select form-control"
                           formControlName="level"
                           [searchEnabled]="true"
                           valueExpr="id"
                           displayExpr="name"
                           placeholder="Asset Share Tier Level">
            </dx-select-box>
            <p class="p-extra-small">Please Asset Share Tier Level</p>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="assetShareBillingSettingsForm.controls['billingType']?.value == this.flatBillingType">
        <div class="col-md-6" id="purchaseOrder">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (assetShareBillingSettingsForm.controls['purchaseOrder'].errors
               && (!assetShareBillingSettingsForm.controls['purchaseOrder'].pristine || ngForm.submitted))}">
            <label for="purchaseOrder" class="tylie-form-group__lbl">Purchase Order Number<span class="required">*</span></label>
            <input type="text" id="purchaseOrder" name="purchaseOrder" class="tylie-text form-control" formControlName="purchaseOrder">
          </div>
        </div>
        <div class="col-md-6" id="jobNumber">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (assetShareBillingSettingsForm.controls['jobNumber'].errors
               && (!assetShareBillingSettingsForm.controls['jobNumber'].pristine || ngForm.submitted))}">
            <label for="jobNumber" class="tylie-form-group__lbl">Job Number<span class="required">*</span></label>
            <input type="text" id="jobNumber" name="jobNumber" class="tylie-text form-control" formControlName="jobNumber">
          </div>
        </div>
      </div>

      <div class="row" *ngIf="false">
        <div class="col-md-6" id="distributionIncluded">
          <div class="tylie-form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="distributionIncluded"
                   name="distributionIncluded" id="distributionIncluded">
            <label class="form-check-label" for="distributionIncluded">Include Distribution (Bundle)</label>
          </div>
        </div>
        <div class="col-md-6" id="isSelfService">
          <div class="tylie-form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isSelfService"
                   name="isSelfService" id="isSelfService">
            <label class="form-check-label" for="isSelfService">Self Service</label>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
