import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupModel, LocationModel, LocationSearchRequest, StateModel } from '../../../models/tagger/tagger.model';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Utilities } from '../../../services/core/utilities';
import { AlertService } from '../../../services/core/alert.service';
import { TaggerService } from '../../../services/tagger/tagger.service';
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { AddLocationsPopupComponent } from '../../popups/add-locations-popup/add-locations-popup.component';
import { AddTaggerUserPopupComponent } from '../../popups/add-tagger-user-popup/add-tagger-user-popup.component';

@Component({
  selector: 'app-locations-and-users',
  templateUrl: './locations-and-users.component.html',
  styleUrls: ['./locations-and-users.component.css']
})
export class LocationsAndUsersComponent implements OnInit {
  @ViewChild('locationSearch', { static: false }) gridLocationSearch: DxDataGridComponent;

  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  public searchRequestFormGroup: FormGroup;
  public groupDataSource: GroupModel[];
  public stateDataSource: StateModel[];
  public modalRef: BsModalRef;
  public selectedGroups: any[] = [];
  public selectedStates: any[] = [];

  public resultLocationStore: any = {};
  public searchCriteriaObj = {} as LocationSearchRequest;
  public locations: LocationModel[];

  constructor(
    public util: Utilities,
    private alertService: AlertService,
    private taggerService: TaggerService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.searchRequestFormGroup = new FormGroup({
      groups: new FormControl(null),
      states: new FormControl(null),
      unassignedGroup: new FormControl(false),
    });

    this.getGroups();
    this.getStates();
    this.searchLocations();

  }

  get selectedGroupLabel() {
    const groups = this.searchRequestFormGroup.get("groups").value || [];
    return groups.map(group => group.name).join(", ");
  }

  get selectedStateLabel() {
    const states = this.searchRequestFormGroup.get("states").value || [];
    return states.map(state => state.name).join(", ");
  }

  searchLocations() {
    let thisRef = this;
    this.resultLocationStore.store = new CustomStore({
      key: "locationGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'name','desc':false}]";
        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
        thisRef.searchCriteriaObj = {
          quickSearch: thisRef.quickSearchValue,
          groups: thisRef.searchRequestFormGroup.get("groups").value,
          states: thisRef.searchRequestFormGroup.get("states").value,
          unassignedGroup: thisRef.searchRequestFormGroup.get("unassignedGroup").value || false,
        }
        let request: any = { searchCriteria: thisRef.searchCriteriaObj, GridParams: gridParams };

        return thisRef.taggerService.searchLocations(request)
          .toPromise()
          .then((response) => {
            console.log(response);
            if (response.isSuccess) {
              thisRef.locations = response.result.searchResults;
              var obj: any = {
                data: response.result.searchResults,
                totalCount: response.result.totalCount
              };
              return obj;
            }
            else {
              // thisRef.util.handleIsNotSuccess([ "Please try again" ]);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            // thisRef.util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.command === "expand") {
      if (e.data != null) {
        if (e.data.groups.length < 0  ||e.data.locationUsers.length < 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.refreshSearchResults(true);
    }
  }

  clearSearch() {
    this.searchRequestFormGroup.reset();
    this.quickSearchValue = "";
    this.refreshSearchResults(true);
  }

  clearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.refreshSearchResults(true);
    }
  }

  onFormSubmit() {
    this.refreshSearchResults(true);
  }

  public getGroups() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getGroups().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.groupDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getStates() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getStates().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.stateDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onSelectedGroups(groups) {
    this.selectedGroups = groups || [];
    this.searchRequestFormGroup.get("groups").setValue(groups || []);
  }

  public onSelectedStates(states) {
    this.selectedStates = states || [];
    this.searchRequestFormGroup.get("states").setValue(states || []);
  }

  public refreshSearchResults(isRefresh: boolean): void {
    if (isRefresh) {
      this.gridLocationSearch.instance.refresh();
    }
  }

  onAddLocation() {
    const initialState = {
      isAddLocation: true,
      isEditLocation: false
    };

    this.modalRef = this.modalService.show(
      AddLocationsPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }

  onEditLocation(location: LocationModel): void {
    const initialState = {
      isEditLocation: true,
      location
    };

    this.modalRef = this.modalService.show(
      AddLocationsPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }

  onEditGroup(location: LocationModel): void {
    const initialState = {
      isEditLocationGroups: true,
      location
    };

    this.modalRef = this.modalService.show(
      AddLocationsPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }

  public onClickEditLocationUser(location, user) {
    console.log(location);
    console.log(user);
    const initialState = {
      locationGuid: location.locationGuid,
      firstName: user.userDetail.firstName,
      lastName: user.userDetail.lastName,
      email: user.userDetail.email,
      title: user.title,
      userProfileId: user.userDetail.userProfileId,
      userProfileGuid: user.userDetail.userProfileGuid,
      phoneNumber: user.userDetail.workPhone,
      isEditUser: true
    };
    this.modalRef = this.modalService.show(
      AddTaggerUserPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }

  public onClickAddLocationUser(value) {
    console.log(value);
    const initialState = {
      locationGuid: value.locationGuid
    };
    this.modalRef = this.modalService.show(
      AddTaggerUserPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }

  public onClickAddUser() {
    const initialState = {
      isEditUser: false,
    };
    this.modalRef = this.modalService.show(
      AddTaggerUserPopupComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        this.refreshSearchResults(true);
      }
    });
  }
}
