import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ProjectFolder } from '../../../models/project/create-project.model';
import { ClientUserModel, FileCollectionUserAccess, Project, UserPermissionLevel } from '../../../models/project/project.model';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { AddUserPermissionToProjectService } from '../../../services/project/add-user-permission.service';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-manage-project-users',
  templateUrl: './manage-project-users.component.html',
  styleUrls: ['./manage-project-users.component.css']
})
export class ManageProjectUsersComponent implements OnInit, OnDestroy {
  @ViewChild('treeFolder', { static: false }) treeFolder: DxTreeViewComponent;
  public onClose: Subject<boolean>;
  @Input() userPermissions: UserPermissionLevel[];
  @Input() folders: ProjectFolder[];
  @Input() users: ClientUserModel[];
  @Input() project: Project;
  public modalRef: BsModalRef;
  brandDataSource: any = [];
  manageUserForm: FormGroup;
  public folderValue: number[] = [];
  public selectedRows: number[];
  folderIds: number[];
  fileCollectionUserAccess: FileCollectionUserAccess[] = [];
  subscriptions: Subscription[] = [];
  constructor(
    public bsModalRef: BsModalRef,
    public util: Utilities,
    private fb: FormBuilder,
    private alertService: AlertService,
    private addUserPermissionService: AddUserPermissionToProjectService,
    private cdRef: ChangeDetectorRef) {
    this.onClose = new Subject<boolean>();
  }

  ngOnInit() {
    this.initializeManageUserForm();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  initializeManageUserForm() {
    this.manageUserForm = this.fb.group({
      userId: new FormControl(null, Validators.required),
      userPermissionId: new FormControl('', Validators.required),
      folderId: new FormControl('', Validators.required),
      userType: new FormControl('0'),
      userEmail: new FormControl('', Validators.email)
    });
  }

  syncTreeViewSelection(e) {
    var component = (e && e.component) || (this.treeFolder && this.treeFolder.instance);

    if (!component) return;

    if (!this.folderIds) {
      component.unselectAll();
    }

    if (this.folderIds) {
      this.folderIds.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  treeView_itemSelectionChanged(e) {
    this.folderIds = e.component.getSelectedNodesKeys() as number[];
    this.manageUserForm.patchValue({
      folderId: this.folderIds
    });
  }

  onAddUserSubmit() {
    const formValues = this.manageUserForm.value;
    if (formValues.userType == 0) {
      this.manageUserForm.get('userEmail').setErrors(null);
    }
    else {
      this.manageUserForm.get('userId').setErrors(null);
    }
    if (!this.manageUserForm.valid) {
      return
    }
    this.fileCollectionUserAccess = [];
    this.folderIds.forEach(x => {
      this.fileCollectionUserAccess.push({
        fileCollectionId: x,
        userprofileId: formValues.userType == 0 ? formValues.userId : null,
        username: formValues.userType == 0 ? this.users.find(x => x.userProfileId === formValues.userId).userName : formValues.userEmail,
        userPermissionLevelId: formValues.userPermissionId,
        client: this.project.clientId,
        userProfileGuid: formValues.userType == 0 ? this.users.find(x => x.userProfileId === formValues.userId).userProfileGuid : '00000000-0000-0000-0000-000000000000'
      });
    });
    if (formValues.userType == 0) {
      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.addUserPermissionService.assignExistingUserPermission(this.fileCollectionUserAccess, this.project.fileCollectionId)
        .subscribe(x => {
          this.alertService.ShowLoader(false);
          if (x.isSuccess) {
            this.onClose.next(true);
            this.closePopup();
          }
        },
          error => {
            this.util.handleError(error);
          }));
    }
    else {
      this.alertService.ShowLoader(true);
      this.subscriptions.push(this.addUserPermissionService.assignNewUserPermission(this.fileCollectionUserAccess, this.project.fileCollectionId)
        .subscribe(x => {
          this.alertService.ShowLoader(false);
          if (x.isSuccess) {
            this.onClose.next(true);
            this.closePopup();
          }
          else {
            this.util.handleIsNotSuccess(x.errors);
          }
        },
          error => {
            this.util.handleError(error);
          }));
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
