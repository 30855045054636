import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AssetShareBillingSettings } from '../../../models/admin/client/billingsettings.model';
import { AlertService } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';

@Component({
  selector: 'app-add-asset-share-billing-settings-popup',
  templateUrl: './add-asset-share-billing-settings-popup.component.html',
  styleUrls: ['./add-asset-share-billing-settings-popup.component.css']
})
export class AddAssetShareBillingSettingsPopupComponent implements OnInit {

  @Input('brandName') brandName: string = null;
  @Input('transcodeBillingTypes') transcodeBillingTypes: any = {};
  @Input('billingFrequency') billingFrequency: any = {};
  @Input('flatTranscodeBillingFrequency') flatTranscodeBillingFrequency: any = {};
  @Input('selectedBrandsDataSource') selectedBrandsDataSource: any[] = [];
  @Input('isBrandsNotSelected') isBrandsNotSelected: boolean = false;
  @Input('flatTranscodeTiers') flatTranscodeTiers: any = {};
  @Input('brandTranscodeSettings') brandTranscodeSettings: AssetShareBillingSettings = null;
  @Input('clientAssetShareBillingSettings') clientAssetShareBillingSettings: AssetShareBillingSettings = null;

  public modalRef: BsModalRef;

  public assetShareBillingSettingsForm: FormGroup;

  public onSaveAssetShareBillingSettings: Subject<AssetShareBillingSettings>;
  public brandsValue: Array<number>;
  public brandsNotSelected: boolean = false;
  public defaultBillingTypeForBrand = "Per Request";
  public flatBillingType = "Flat";
  public assetShareBillingSettings: AssetShareBillingSettings = null;
  public billingFreqDataSource: any[] = [];
  public now: Date = new Date();

  @ViewChild('treeBrands', { static: false }) treeBrands: DxTreeViewComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private configService: ConfigService) {

    this.onSaveAssetShareBillingSettings = new Subject<AssetShareBillingSettings>();
  }

  ngOnInit() {
    console.log(this.billingFrequency);
    this.billingFreqDataSource = this.billingFrequency;
    this.assetShareBillingSettings = (this.brandTranscodeSettings == null || this.brandTranscodeSettings == undefined) ? this.clientAssetShareBillingSettings : this.brandTranscodeSettings;

    console.log(this.assetShareBillingSettings);

    if ((this.assetShareBillingSettings == null || this.assetShareBillingSettings == undefined)) {
      this.assetShareBillingSettingsForm = this.fb.group({
        selectedBrandsForAssetShareBilling: [],
        billingType: [null, Validators.required],
        purchaseOrder: [null],
        jobNumber: [null],
        billingCycle: [null, Validators.required],
        distributionIncluded: [false],
        nextBillingCycle: [null, Validators.required],
        isSelfService: [false],
        tier: this.fb.group({
          level: new FormControl(1),
        })
      });
    }
    else {
      if (this.assetShareBillingSettings.billingType != this.defaultBillingTypeForBrand) {
        this.billingFreqDataSource = this.flatTranscodeBillingFrequency;
      }
      this.assetShareBillingSettingsForm = this.fb.group({
        selectedBrandsForAssetShareBilling: [],
        billingType: [this.assetShareBillingSettings.billingType, Validators.required],
        purchaseOrder: [this.assetShareBillingSettings.purchaseOrder],
        jobNumber: [this.assetShareBillingSettings.jobNumber],
        billingCycle: [this.assetShareBillingSettings.billingCycle, Validators.required],
        distributionIncluded: [this.assetShareBillingSettings.distributionIncluded],
        isSelfService: [this.assetShareBillingSettings.isSelfService],
        nextBillingCycle: [this.assetShareBillingSettings.nextBillingCycle],
        tier: this.fb.group({
          level: this.assetShareBillingSettings.tier == null ? new FormControl(1, [Validators.required]) : new FormControl(this.assetShareBillingSettings.tier.level, [Validators.required]),
        })
      });
    }
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeBrands && this.treeBrands.instance);

    if (!component) return;

    if (this.brandsValue == null || !this.brandsValue.length) {
      component.unselectAll();
    }

    if (this.brandsValue != null) {
      this.brandsValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.brandsValue = newValues;
    this.billingFreqDataSource = this.billingFrequency;
  }

  public onbillingTypeChange(e) {
    console.log(e);
    if (e != null && e.value != null) {
      if (e.value == this.defaultBillingTypeForBrand) {
        this.billingFreqDataSource = this.billingFrequency;
      }
      else {
        this.billingFreqDataSource = this.flatTranscodeBillingFrequency;
      }
    }
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSaveAssetShareBillingSettingsFormSubmit({ value, valid }: { value: AssetShareBillingSettings, valid: boolean }) {
    console.log(value);
    console.log(valid);

    var brands = this.assetShareBillingSettingsForm.controls['selectedBrandsForAssetShareBilling'].value;

    console.log(brands);

    if ((brands != null && brands.length > 0) || this.brandName != null) {
      this.assetShareBillingSettingsForm.controls['billingType'].setValue(this.defaultBillingTypeForBrand);
      this.assetShareBillingSettingsForm.controls['billingType'].updateValueAndValidity();
      value.billingType = this.defaultBillingTypeForBrand;
    }

    if (!this.assetShareBillingSettingsForm.valid) {
      return;
    }

    this.onSaveAssetShareBillingSettings.next(value);
    this.closePopup();
  }

}
