import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router } from "@angular/router";
import { Utilities } from "../../../../../services/core/utilities";
import { CreateUserRoleNotification, RoleNotificationPreference } from "../../../../../models/user/create-user";
import { UserRoles } from "../../../../../configurations/user-roles";

@Component({
  selector: 'addroles-notifications',
  templateUrl: './addroles-notifications.component.html',
  styleUrls: ['./addroles-notifications.component.css']
})
export class AddRolesNotificationsComponent implements OnInit {

  @Input('rolesNotifications') rolesNotifications: CreateUserRoleNotification[];
  @Input() addUserRolesFormGroup: FormGroup;
  @Input() addUserNotificationsFormGroup: FormGroup;

  @Output() onMediaApproverRoleUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public util: Utilities
    , private fb: FormBuilder
    , private userRoles: UserRoles) {

  }

  ngOnInit() {

  }

  public get addUserRolesFormGroupArray() {
    return <FormArray>this.addUserRolesFormGroup.get('userRoles');
  }

  public get notificationPreferncesFormGroupArray() {
    return <FormArray>this.addUserNotificationsFormGroup.get('notificationPreferences');
  }

  public onRoleValueChanged(e: any, i: number) {
    if (this.util.notEmpty(e)) {
      var rolesArray = <FormArray>this.addUserRolesFormGroup.get('userRoles') as FormArray;
      var currentRole = rolesArray.at(i).value;
      if (currentRole.isChecked) {
        var roleNotifications = this.rolesNotifications.filter(rn => rn.roleId == currentRole.roleId)[0];
        this.createNotificationPreferencesFormArray(roleNotifications.roleNotificationPreferences, roleNotifications.roleId);
        if (roleNotifications.roleDescription == this.userRoles.getMediaApproverRoleName()) {
          this.onMediaApproverRoleUpdated.emit(true);
        }
      }
      else {
        this.removeNotificationPreferences(currentRole.roleId);
        var roleNotifications = this.rolesNotifications.filter(rn => rn.roleId == currentRole.roleId)[0];
        if (roleNotifications.roleDescription == this.userRoles.getMediaApproverRoleName()) {
          this.onMediaApproverRoleUpdated.emit(false);
        }
      }
    }
  }

  private createUserRolesFormArray() {
    var rolesArray: FormArray = this.fb.array([]);
    this.rolesNotifications.forEach(obj => {
      const group = this.fb.group({
        isChecked: [false],
        roleId: [obj.roleId]
      });
      rolesArray.push(group);
    });
    return rolesArray;
  }

  private createNotificationPreferencesFormArray(roleNotificationPreferences: RoleNotificationPreference[], roleId: number) {
    var notificationPreferencesArray = <FormArray>this.addUserNotificationsFormGroup.get('notificationPreferences') as FormArray;
    roleNotificationPreferences.forEach(obj => {
      const group = this.fb.group({
        isChecked: [false],
        id: [obj.id],
        roleId: [roleId],
        description: [obj.description]
      });

      notificationPreferencesArray.push(group);
    });
    return notificationPreferencesArray;
  }

  private removeNotificationPreferences(roleId: number) {
    var notificationPreferencesArray = <FormArray>this.addUserNotificationsFormGroup.get('notificationPreferences') as FormArray;
    for (var i = notificationPreferencesArray.length - 1; i >= 0; i--) {
      var notificationPreference = notificationPreferencesArray.at(i);
      if (notificationPreference.value.roleId == roleId) {
        notificationPreferencesArray.removeAt(i);
      }
    }
  }
}
