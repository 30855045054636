import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DxTreeViewComponent } from "devextreme-angular";
import { Observable, Subject } from "rxjs";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { Utilities } from "../../../../../services/core/utilities";
import { ConfigService } from "../../../../../services/core/config.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { AdminService } from "../../../../../services/admin/admin.service";
import { Constants } from "../../../../../configurations/constants";
import { ErrorPopupComponent } from "../../../../popups/errorpopup/errorpopup.component";
import { customPhoneValidator, EmailListValidatorIfValueExists, EmailListValidator } from "../../../../../configurations/custom-validations";
import { CreateDestinationConfig, DestinationContact } from "../../../../../models/admin/destination/createdestination.model";
import { SelectNetworkComponent } from "../../create-destination/select-network/select-network.component";
import { DestinationDetail, S3Config, AsperaConfig, GDriveConfig, DropBoxConfig } from "../../../../../models/admin/destination/destinationdetail.model";
import { EditDestination, RemoveSpecMappingModel } from "../../../../../models/admin/destination/editdestination.model";
import { AdminDefaultHubDestinationSearchComponent } from "../../../../popups/admindefaulthubdestinationsearch/admindefaulthubdestinationsearchpopup.component";
import { contactTypeEnum } from '../../../../../configurations/enums/enums';
import { AddEditSpecDetailsPopUp } from '../../../../popups/addeditspecdetailspopup/addeditspecdetailspopup.component';
import { AdminDestinationSettingsPopupComponent } from '../../../../popups/admindestinationsettingspopup/admindestinationsettingspopup.component';
import { saveAs } from "file-saver";

@Component({
  selector: 'edit-destination',
  templateUrl: './edit-destination.component.html',
  styleUrls: ['./edit-destination.component.css']
})
export class EditDestinationComponent implements OnInit {

  @Output() onEditCompleteOrCancelEvent: EventEmitter<DestinationDetail> = new EventEmitter<DestinationDetail>();
  @Input('destinationGuid') destinationGuid: string = null;

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeBoxValue: number[] = [];

  @ViewChild(SelectNetworkComponent, { static: false }) selectNetworkComponent: SelectNetworkComponent;

  public modalRef: BsModalRef;
  public editDestinationFormGroup: FormGroup;
  public destinationRoute = RouteConstants.destinationsRoute;
  public config: CreateDestinationConfig = null;
  public detail: DestinationDetail = null;
  public networkSpecifications: any[] = [];
  public removedSpecifications: any[] = []
  public showDistributionSettings: boolean = false;

  public showFTPConfig: boolean = false;
  public showLinksConfig: boolean = false;
  public showManulDistributionConfig: boolean = false;
  public s3: any = null;
  public aspera: any = null;
  public bxfXML: any = null;
  public googleDrive: any = null;
  public dropBox: any = null;
  public isDestinationTypeInternational: boolean = false;

  constructor(public util: Utilities
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , private modalService: BsModalService
    , private fb: FormBuilder) {

  }

  ngOnInit() {
    this.editDestinationFormGroup = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      destinationType: [null, Validators.compose([Validators.required])],
      akas: [null],
      defaultHub: [null],
      market: [null],
      acceptableFormats: [null],
      preferredFormat: [null],
      secondaryFormat: [null],
      preferredVendorId: [null],
      fileDeliveryTypeId: [null],
      isCompanionFormatRequired: [null],
      isTrafficOnly: [false],
      acceptsDescriptiveVideo: [false],
      comments: [null],
      ftpServer: [null],
      ftpUserName: [null],
      ftpPassword: [null],
      ftpPort: [null],
      s3: [null],
      aspera: [null],
      googleDrive: [null],
      dropBox: [null],
      linkEmails: [null],
      destinationContact: this.fb.group({
        destinationContacts: this.fb.array([])
      }),
      isHouseIdRequired: [false],
      specifications: [null],
      bxfXML: [null],
      transcodeCustomFileName: [null],
      transcodeCustomFileNameSeperator: ["_"],
      notes: [null],
      primaryDestination: [null]
    });

    if (this.destinationGuid != null) {
      this.getConfig();
    }
  }

  public onFormSubmit({ value, valid }: { value: EditDestination, valid: boolean }) {
    if (valid == false) {
      return;
    }

    this.vaidateEditDestinationRequest(value);
  }

  public onCancelEdits() {
    this.onEditCompleteOrCancelEvent.emit(this.detail);
  }

  public onDefaultHubSearch() {
    var initialState = {
      selectedRowCode: this.editDestinationFormGroup.controls['defaultHub'].value
    };

    this.modalRef = this.modalService.show(AdminDefaultHubDestinationSearchComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      this.editDestinationFormGroup.controls['defaultHub'].setValue(result);
    });
  }

  public onPrimaryDestinationSearch() {
    var initialState = {

    };

    this.modalRef = this.modalService.show(AdminDefaultHubDestinationSearchComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      this.editDestinationFormGroup.controls['primaryDestination'].setValue(result);
    });
  }

  public syncTreeViewSelection(e) {
    var component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
  }

  private getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  public addOtherDestinationContacts(contact: DestinationContact) {
    var destinationContact = this.editDestinationFormGroup.get('destinationContact');
    var destinationContactsArray = <FormArray>destinationContact.get('destinationContacts') as FormArray;

    const group = this.fb.group({
      id: [contact.id],
      name: [contact.name],
      title: [contact.title],
      phone: [contact.phone, Validators.compose([customPhoneValidator])],
      fax: [contact.fax],
      email: [contact.email, Validators.compose([EmailListValidatorIfValueExists])],
      isMainContact: [false],
      contactTypeId: [contact.contactTypeId, Validators.required],
    });

    if (contact.contactTypeId == contactTypeEnum.TrafficContact) {
      group.controls['email'].setValidators(Validators.compose([EmailListValidatorIfValueExists, Validators.required]));
      group.controls['email'].updateValueAndValidity();
    }
    destinationContactsArray.push(group);
    return destinationContactsArray;
  }

  public onAddEditRecipeName(spec) {
    console.log(spec);
    var initialState = {
      recipeName: spec.recipeName
    };

    this.modalRef = this.modalService.show(AddEditSpecDetailsPopUp, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      if (result != null) {
        var editedSpec = this.networkSpecifications.find(t => t.id == spec.id)
        editedSpec.recipeName = result.recipeName
      }
    });
  }

  public onAddDistributionSettings() {
    var customSelections = [];
    if (this.editDestinationFormGroup.controls['transcodeCustomFileName'].value != null && this.editDestinationFormGroup.controls['transcodeCustomFileName'].value != undefined) {
      var sep = this.editDestinationFormGroup.controls['transcodeCustomFileNameSeperator'].value;
      customSelections = this.editDestinationFormGroup.controls['transcodeCustomFileName'].value.split(sep);
    }

    var initialState = {
      isHouseIdRequired: this.editDestinationFormGroup.controls['isHouseIdRequired'].value,
      fileDeliveryType: this.editDestinationFormGroup.controls['fileDeliveryTypeId'].value,
      linkEmails: this.editDestinationFormGroup.controls['linkEmails'].value,
      notes: this.editDestinationFormGroup.controls['notes'].value,
      ftpServer: this.editDestinationFormGroup.controls['ftpServer'].value,
      ftpPassword: this.editDestinationFormGroup.controls['ftpPassword'].value,
      ftpUserName: this.editDestinationFormGroup.controls['ftpUserName'].value,
      ftpPort: this.editDestinationFormGroup.controls['ftpPort'].value,
      s3: this.s3,
      aspera: this.aspera,
      googleDrive: this.googleDrive,
      dropBox: this.dropBox,
      config: this.config,
      customfilenamepos1: customSelections.length >= 1 ? customSelections[0] : null,
      customfilenamepos2: customSelections.length >= 2 ? customSelections[1] : null,
      customfilenamepos3: customSelections.length >= 3 ? customSelections[2] : null
    };

    this.modalRef = this.modalService.show(AdminDestinationSettingsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      console.log(result)
      if (result != null) {
        this.editDestinationFormGroup.controls['isHouseIdRequired'].setValue(result.isHouseIdRequired);
        this.editDestinationFormGroup.controls['fileDeliveryTypeId'].setValue(result.fileDeliveryType);
        if (result.fileDeliveryType == this.config.fileDeliveryTypeLinks) {
          this.editDestinationFormGroup.get('linkEmails').setValue(result.linkEmails);
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeFTP) {
          this.editDestinationFormGroup.get('ftpServer').setValue(result.ftpServer);
          this.editDestinationFormGroup.get('ftpUserName').setValue(result.ftpUserName);
          this.editDestinationFormGroup.get('ftpPassword').setValue(result.ftpPassword);
          this.editDestinationFormGroup.get('ftpPort').setValue(result.ftpPort);
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeS3) {
          this.s3 = result.s3;
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeAspera) {
          this.aspera = result.aspera;
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeGdrive) {
          this.googleDrive = result.googleDrive;
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeDropBox) {
          this.dropBox = result.dropBox;
        }
        else if (result.fileDeliveryType == this.config.fileDeliveryTypeManual) {
          this.editDestinationFormGroup.get('notes').setValue(result.notes);
        }
        if (result.bxfByteArray != null) {
          this.editDestinationFormGroup.controls['bxfXML'].setValue(result.bxfByteArray);
          this.bxfXML = result.bxfXML;
        }
        result.specifications.forEach(spec => {
          console.log(this.networkSpecifications)
          if (this.networkSpecifications.find(t => t.id == spec.id) == undefined) {
            this.networkSpecifications.push(spec);
          }
        });

        this.editDestinationFormGroup.controls['transcodeCustomFileName'].setValue(result.transcodeCustomFileName);
      }
    });
  }

  public onPreferredNetworkChange() {
    if (this.config != null && this.config.networksRequireDestinationSettings != null
      && this.config.networksRequireDestinationSettings.includes(this.editDestinationFormGroup.controls['preferredVendorId'].value)) {
      this.showDistributionSettings = true;
    }
    else {
      this.showDistributionSettings = false;
    }
  }

  public onRemoveSpecMapping(spec) {
    //if (this.detail.specifications != null && this.detail.specifications.find(t => t.id == spec.id) != undefined) {
    //  var removeSpecMappingObj = { destinationCode: this.detail.code, specificationMappingIds: [spec.id] } as RemoveSpecMappingModel;
    //  this.adminService.removeSpecMapping(removeSpecMappingObj).subscribe((res: any) => {
    //    if (res.isSuccess == true) {
    //      if (res.result) {
    //        this.detail.specifications = res.result;
    //        this.networkSpecifications = this.detail.specifications;
    //      }
    //      else {
    //        this.util.handleIsNotSuccess(res.errors);
    //      }
    //    }
    //  },
    //    error => {
    //      this.util.handleError(error);
    //    });

    //}
    this.networkSpecifications = this.networkSpecifications.filter(ns => ns.id != spec.id);
    this.removedSpecifications.push(spec);
  }

  public onDownloadBxfFile() {
    const file = new Blob([this.bxfXML], { type: 'text/plain' });
    saveAs(file, this.detail.code + "-" + "bxf-file" + ".xml");
  }

  public onRemoveBXfFile() {
    this.editDestinationFormGroup.controls['bxfXML'].setValue(null);
  }

  public onDestinationTypeChange() {
    if (this.editDestinationFormGroup.controls['destinationType'].value == this.config.fileDestinationTypeInternational) {
      this.isDestinationTypeInternational = true;
      this.editDestinationFormGroup.controls['market'].setValue(this.detail.market);
      this.editDestinationFormGroup.get('market').setValidators([Validators.required]);
      this.editDestinationFormGroup.controls["market"].updateValueAndValidity();
    }
    else if (this.editDestinationFormGroup.controls['destinationType'].value == this.config.fileDestinationTypeBackup) {
      this.editDestinationFormGroup.get('primaryDestination').setValue(null);
      this.editDestinationFormGroup.get('primaryDestination').setValidators([Validators.required]);
      this.editDestinationFormGroup.controls["primaryDestination"].updateValueAndValidity();
    }
    else {
      this.isDestinationTypeInternational = false;
      this.editDestinationFormGroup.controls['market'].setValue(this.detail.market);
      this.editDestinationFormGroup.get('market').clearValidators();
      this.editDestinationFormGroup.controls["market"].updateValueAndValidity();

      this.editDestinationFormGroup.get('primaryDestination').setValue(null);
      this.editDestinationFormGroup.get('primaryDestination').setValidators([Validators.required]);
      this.editDestinationFormGroup.controls["primaryDestination"].updateValueAndValidity();
    }
  }

  private getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateDestinationConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.config = res.result;
        this.getDestinationView(this.destinationGuid);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private updateEditClientForm(detail: DestinationDetail) {
    this.editDestinationFormGroup.controls['name'].setValue(detail.name);
    this.editDestinationFormGroup.controls['destinationType'].setValue(detail.destinationTypeId);
    this.editDestinationFormGroup.controls['akas'].setValue(detail.akas);
    this.editDestinationFormGroup.controls['defaultHub'].setValue(detail.defaultHub);
    this.editDestinationFormGroup.controls['primaryDestination'].setValue(detail.primaryDestination);
    this.editDestinationFormGroup.controls['market'].setValue(detail.market);
    this.editDestinationFormGroup.controls['preferredFormat'].setValue(detail.preferredFormat);
    this.editDestinationFormGroup.controls['secondaryFormat'].setValue(detail.secondaryFormat);
    this.editDestinationFormGroup.controls['preferredVendorId'].setValue(detail.preferredVendorId);
    this.editDestinationFormGroup.controls['isCompanionFormatRequired'].setValue(detail.isCompanionFormatRequired);
    this.editDestinationFormGroup.controls['isTrafficOnly'].setValue(detail.isTrafficOnly);
    this.editDestinationFormGroup.controls['acceptableFormats'].setValue(detail.acceptableFormats);
    this.editDestinationFormGroup.controls['comments'].setValue(detail.comments);

    if (detail.destinationMetaData != null) {
      this.editDestinationFormGroup.controls['acceptsDescriptiveVideo'].setValue(detail.destinationMetaData.acceptsDescriptiveVideo);
      this.editDestinationFormGroup.controls['fileDeliveryTypeId'].setValue(detail.destinationMetaData.deliveryTypeId);
      this.editDestinationFormGroup.controls['linkEmails'].setValue(detail.destinationMetaData.emails);
      this.editDestinationFormGroup.controls['ftpServer'].setValue(detail.destinationMetaData.server);
      this.editDestinationFormGroup.controls['ftpPassword'].setValue(detail.destinationMetaData.password);
      this.editDestinationFormGroup.controls['ftpUserName'].setValue(detail.destinationMetaData.userName);
      this.editDestinationFormGroup.controls['ftpPort'].setValue(detail.destinationMetaData.port);
      this.editDestinationFormGroup.controls['notes'].setValue(detail.destinationMetaData.notes);

      if (detail.destinationMetaData.s3 != null) {
        this.s3 = new S3Config();
        this.s3 = detail.destinationMetaData.s3;
      }
      if (detail.destinationMetaData.aspera != null) {
        this.aspera = new AsperaConfig();
        this.aspera = detail.destinationMetaData.aspera;
      }
      if (detail.destinationMetaData.googleDrive != null) {
        this.googleDrive = new GDriveConfig();
        this.googleDrive = detail.destinationMetaData.googleDrive;
      }
      if (detail.destinationMetaData.dropBox != null) {
        this.dropBox = new DropBoxConfig();
        this.dropBox = detail.destinationMetaData.dropBox;
      }
      this.editDestinationFormGroup.controls['isHouseIdRequired'].setValue(detail.destinationMetaData.isHouseIdRequiredForDownload);
      this.editDestinationFormGroup.controls['bxfXML'].setValue(detail.destinationMetaData.bxfXMLByteArray);

      this.bxfXML = detail.destinationMetaData.bxfXML;
      this.networkSpecifications = detail.destinationMetaData.specifications == null ? [] : detail.destinationMetaData.specifications;

      this.editDestinationFormGroup.controls['transcodeCustomFileName'].setValue(detail.destinationMetaData.transcodeCustomFileName);
    }

    if (this.config != null && this.config.networksRequireDestinationSettings != null
      && this.config.networksRequireDestinationSettings.includes(this.editDestinationFormGroup.controls['preferredVendorId'].value)) {
      this.showDistributionSettings = true;
    }

    detail.acceptableFormats.forEach(f => {
      this.treeBoxValue.push(f)
    });

    var destinationContact = detail.destinationContact;

    if (destinationContact != null && destinationContact.destinationContacts != null && destinationContact.destinationContacts.length > 0) {
      var mainContact = destinationContact.destinationContacts.find(d => (d.isMainContact == true));
      if (mainContact == undefined) {
        this.addEmptyMainDestinationContactFormToArray();
      }
    }
    else {
      this.addEmptyMainDestinationContactFormToArray();
    }

    if (destinationContact != null && destinationContact.destinationContacts != null && destinationContact.destinationContacts.length > 0) {
      var mainContact = destinationContact.destinationContacts.find(d => (d.isMainContact == true));
      if (mainContact != undefined && mainContact != null) {
        this.addMainDestinationContactFormToArray(mainContact);
      }

      destinationContact.destinationContacts.forEach(dc => {
        if (dc.isMainContact == false) {
          this.addOtherDestinationContacts(dc);
        }
      });
    }
  }

  private addEmptyMainDestinationContactFormToArray() {
    var destinationContact = this.editDestinationFormGroup.get('destinationContact');
    var destinationContactsArray = <FormArray>destinationContact.get('destinationContacts') as FormArray;

    const group = this.fb.group({
      name: [null],
      title: [null],
      address1: [null],
      address2: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      phone: [null, Validators.compose([customPhoneValidator])],
      fax: [null],
      email: ['', Validators.compose([EmailListValidatorIfValueExists])],
      isMainContact: [true]
    });

    destinationContactsArray.push(group);
    return destinationContactsArray;
  }

  private addMainDestinationContactFormToArray(mainContact: DestinationContact) {
    var destinationContact = this.editDestinationFormGroup.get('destinationContact');
    var destinationContactsArray = <FormArray>destinationContact.get('destinationContacts') as FormArray;

    const group = this.fb.group({
      id: [mainContact.id],
      name: [mainContact.name],
      title: [mainContact.title],
      address1: [mainContact.address1],
      address2: [mainContact.address2],
      city: [mainContact.city],
      state: [mainContact.state],
      zipCode: [mainContact.zipCode],
      phone: [mainContact.phone, Validators.compose([customPhoneValidator])],
      fax: [mainContact.fax],
      email: [mainContact.email, Validators.compose([EmailListValidatorIfValueExists])],
      isMainContact: [true]
    });

    destinationContactsArray.push(group);
    return destinationContactsArray;
  }

  private vaidateEditDestinationRequest(value: EditDestination) {
    if (value.fileDeliveryTypeId == this.config.fileDeliveryTypeS3) {
      value.s3 = new S3Config();
      value.s3 = this.s3;
    }
    else if (value.fileDeliveryTypeId == this.config.fileDeliveryTypeAspera) {
      value.aspera = new AsperaConfig();
      value.aspera = this.aspera;
    }
    else if (value.fileDeliveryTypeId == this.config.fileDeliveryTypeGdrive) {
      value.googleDrive = new GDriveConfig();
      value.googleDrive = this.googleDrive;
    }
    else if (value.fileDeliveryTypeId == this.config.fileDeliveryTypeDropBox) {
      value.dropBox = new DropBoxConfig();
      value.dropBox = this.dropBox;
    }

    if (value.defaultHub != null && value.defaultHub != undefined && value.defaultHub != '') {
      this.adminService.isDestinationCodeValid(value.defaultHub).subscribe((res: any) => {
        if (res.isSuccess == true) {
          if (res.result) {
            if (this.validateVendorDestinationMappings(value) == true) {
              value.specifications = this.networkSpecifications;
              value.removedSpecifications = this.removedSpecifications;
              this.editDestination(value);
            }
          }
          else {
            this.editDestinationFormGroup.controls['defaultHub'].setErrors({ invalidDestination: true });
          }
        }
      },
        error => {
          this.util.handleError(error);
        });
    }
    else {
      if (this.validateVendorDestinationMappings(value) == true) {
        value.specifications = this.networkSpecifications;
        value.removedSpecifications = this.removedSpecifications;
        this.editDestination(value);
      }
    }
  }

  private editDestination(value: EditDestination) {
    this.adminService.editDestination(value, this.detail.destinationGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result;
        this.alertService.showMessage("SUCCESS", Constants.editDestinationSuccessful, MessageSeverity.success);
        this.onEditCompleteOrCancelEvent.emit(result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private validateVendorDestinationMappings(value: EditDestination): boolean {
    if (this.selectNetworkComponent != null) {
      var vendorDestinationList = this.selectNetworkComponent.getSelectedVendors();
      if (this.util.notEmpty(vendorDestinationList) && vendorDestinationList.length > 0) {
        if (vendorDestinationList.find(t => t.vendorDestinationCode == null && t.isDestinationMappingAvailable == true) == undefined) {
          value.vendorDestinationMappings = vendorDestinationList;
          return true;
        }
        else {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.destinationMappingIdNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
          return false;
        }
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  private getDestinationView(destinationGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getDestinationView(destinationGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.detail = res.result as DestinationDetail;
        this.updateEditClientForm(this.detail);
        //this.onFileDeliveryTypeChange();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  ngOnDestroy() {

  }
}
