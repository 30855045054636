import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-digitalediting',
  templateUrl: './servicedigitalediting.component.html'
})
export class ServiceDigitalEditingQComponent extends BaseServiceDataCollectionComponent {

  public minuteOptions: Array<any> = [{ "val": 0, "display": "00" }, { "val": 15, "display": "15" }, { "val": 30, "display": "30" }, { "val": 45, "display": "45" }];
  public isDisabled: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    if (this.spotLevel == true) {

      this.isDisabled = this.formGroup.controls["title"].disabled;

      if (this.applyAllMode === true) {
        this.formGroup.controls["title"].clearValidators();
        this.formGroup.controls["title"].updateValueAndValidity();
      }
      else {
        if (this.additionalMetadata.ppFileSpecs != null && this.additionalMetadata.ppFileSpecs.length > 0)
          this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileSpecs[0].ppFileTitle);
        else
          this.formGroup.controls["title"].setValue('');
        //this.formGroup.controls["title"].setValue(this.additionalMetadata.ppFileTitle);
      }
    }

    if (this.orderLevel == true) {
      this.formGroup.setValidators([
        this.minimumMinsRequired(15, this.formGroup.get('totalMins'))
      ]);

      this.formGroup.updateValueAndValidity();

      var totalMins = this.formGroup.get('totalMins').value as number;
      if (totalMins != null && totalMins > 0) {
        var hrs = Math.floor(totalMins / 60);
        var mins = totalMins - (hrs * 60);

        this.formGroup.get('hours').setValue(hrs);

        this.formGroup.get('mins').setValue(mins);
      }

      if (this.formGroup.controls["mins"].value == null)
        this.formGroup.controls["mins"].setValue(0);
    }
  }

  public onTimeChanged() {

    console.log(this.formGroup.get('mins').value);

    var hrs = this.formGroup.get('hours').value as number;
    var mins = this.formGroup.get('mins').value as number;

    var totalMins = (hrs * 60) + mins;

    this.formGroup.get('totalMins').setValue(totalMins.toString());
  }

}
