<div id="spot-history">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{this.title}}</h4>
  </div>
  <div class="modal-body">
    <section class="tylie-panel tylie-panel--secondary mb-0">
      <div class="tylie-panel__header tylie-panel__header--br-thick">
        <h4 class="tylie-panel__title">
          {{this.headerText}}
        </h4>
      </div>
      <div class="tylie-panel__body mb-0">
        <div class="tylie-table-wrapper" *ngIf="this.selectedAssets.length > 0">
          <table class="tylie-table table table-bordered">
            <thead class="tylie-table__head">
              <tr>
                <th width="20%" *ngIf="isVaultDigitalAsset == false">Asset Barcode</th>
                <th width="20%" *ngIf="isVaultDigitalAsset == false">Asset Format</th>
                <th width="20%" *ngIf="isVaultDigitalAsset == true">Asset Identifier</th>
                <th width="20%">Client</th>
                <th width="20%">Brand</th>
                <th width="20%">Product</th>
              </tr>
            </thead>
            <tbody class="tylie-table__body">
              <tr *ngFor="let att of this.selectedAssets; let i = index">
                <td *ngIf="isVaultDigitalAsset == false">{{att.assetBarcode}}</td>
                <td *ngIf="isVaultDigitalAsset == false">{{att.format}}</td>
                <td *ngIf="isVaultDigitalAsset == true">{{att.assetIdentifier}}</td>
                <td>{{att.client}}</td>
                <td>{{att.brand}}</td>
                <td>{{att.product}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <form [formGroup]="editRequest" #f="ngForm" (ngSubmit)="onSubmit(editRequest)">
          <h3 class="tylie-type-h3 mt-3">
            Recipient Info
          </h3>
    
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['companyName'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="companyName">Company Name</label>
                <input type="text" formControlName="companyName" id="companyName" name="companyName" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['companyName'].errors?.required">Please Enter Company Name</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['recipientName'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="recipientName">c/o Recipient Name</label>
                <input type="text" formControlName="recipientName" id="recipientName" name="recipientName" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['recipientName'].errors?.required">Please Enter c/o Recipient Name</p>
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['address'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="address">Address</label>
                <input type="text" formControlName="address" id="address" name="address" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['address'].errors?.required">Please Enter Address</p>
              </div>
            </div>
            <div class="col-2">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['city'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="city">City</label>
                <input type="text" formControlName="city" id="city" name="city" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['city'].errors?.required">Please Enter City</p>
              </div>
            </div>
            <div class="col-2">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['state'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="state">State</label>
                <dx-select-box [dataSource]="stateDataSource"
                               name="state" id="state" class="tylie-select form-control"
                               formControlName="state"
                               [searchEnabled]="true"
                               valueExpr="name"
                               [showClearButton]="true"
                               displayExpr="name"
                               placeholder="Select State">
                </dx-select-box>
                <p class="p-extra-small" *ngIf="editRequest.controls['state'].errors?.required">Please Enter State</p>
              </div>
            </div>
            <div class="col-2">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['zip'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="zip">Zip</label>
                <input type="text" formControlName="zip" id="zip" name="zip" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['zip'].errors?.required">Please Enter Zip</p>
              </div>
            </div>
          </div>
    
    
          <div class="row">
            <div class="col-8">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['contactEmail'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="contactEmail">Contact Email</label>
                <input type="text" formControlName="contactEmail" id="contactEmail" name="contactEmail" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['contactEmail'].errors?.required">Please Enter Contact Email</p>
              </div>
            </div>
            <div class="col-4">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['contactPhone'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="contactPhone">Contact Phone</label>
                <input type="text" formControlName="contactPhone" id="contactPhone" name="contactPhone" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['contactPhone'].errors?.required">Please Enter Contact Phone</p>
              </div>
            </div>
          </div>
    
          <h3 class="tylie-type-h3 mt-3">
            Shipping Info
          </h3>
    
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['shippingcompany'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="shippingcompany">Existing Shipping Companies</label>
                <dx-select-box [dataSource]="shippingcompaniesDataSource"
                               name="shippingcompany"
                               id="shippingcompany"
                               class="tylie-select form-control"
                               formControlName="shippingcompany"
                               [searchEnabled]="true"
                               valueExpr="vaultShippingCompanyId"
                               [showClearButton]="true"
                               displayExpr="companyName"
                               placeholder="Select company">
                </dx-select-box>
                <p class="p-extra-small" *ngIf="editRequest.controls['shippingcompany'].errors?.required">Please Enter Existing Shipping Companies</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['manualShippingCompany'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="manualShippingCompany">Manually Enter Shipping Company</label>
                <input type="text" formControlName="manualShippingCompany" id="manualShippingCompany" name="manualShippingCompany" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['manualShippingCompany'].errors?.required">Please Enter Manually Enter Shipping Company</p>
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['shippingAccountNumber'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="shippingAccountNumber">Client-provided Shipping Account Number</label>
                <input type="text" formControlName="shippingAccountNumber" id="shippingAccountNumber" name="shippingAccountNumber" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['shippingAccountNumber'].errors?.required">Please Enter Client-provided Shipping Account Number</p>
              </div>
            </div>
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['shippingTrackingNumber'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="shippingTrackingNumber">Shipping Tracking Number</label>
                <input type="text" formControlName="shippingTrackingNumber" id="shippingTrackingNumber" name="shippingTrackingNumber" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['shippingTrackingNumber'].errors?.required">Please Enter Shipping Tracking Number</p>
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="col-6">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['deliveryConfirmation'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="deliveryConfirmation">Delivery Confirmation</label>
                <input type="text" formControlName="deliveryConfirmation" id="deliveryConfirmation" name="deliveryConfirmation" class="tylie-text form-control" />
                <p class="p-extra-small" *ngIf="editRequest.controls['deliveryConfirmation'].errors?.required">Please Enter Delivery Confirmation</p>
              </div>
            </div>
          </div>
     
          <h3 class="tylie-type-h3 mt-3" *ngIf="this.IsEstimateVisible">
            Estimates
          </h3>
    
          <div class="row" *ngIf="this.IsEstimateVisible">
            <div class="col-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="shippingestimate">Shipping Estimate</label>
                <div class="tylie-text-group input-group">
                  <span class="input-group-text">$</span>
                  <input type="number" formControlName="shippingestimate" id="shippingestimate" name="shippingestimate" class="tylie-text form-control" />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="tylieestimate">Tylie Work Estimate</label>
                <div class="tylie-text-group input-group">
                  <span class="input-group-text">$</span>
                  <input type="number" formControlName="tylieestimate" id="tylieestimate" name="tylieestimate" class="tylie-text form-control" />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="discount">Discount</label>
                <div class="tylie-text-group input-group">
                  <span class="input-group-text">-$</span>
                  <input type="number" formControlName="discount" id="discount" name="discount" class="tylie-text form-control" />
                </div>
              </div>
            </div>
          </div>
    
          <div class="d-flex justify-content-between my-3" *ngIf="this.IsEstimateVisible">
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit" (click)="onCalculateClicked()"><span class="tylie-button__icon"><icon-estimate-calculate></icon-estimate-calculate></span><span class="tylie-button__text">Calculate</span></button>
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl">Total Estimate</label>
              <div class="tylie-type-body">$ {{this.totalEstimate}}</div>
            </div>
          </div>
    
          <div class="row">
            <div class="col-9">
              <div class="tylie-form-group form-group" [ngClass]="{error: editRequest.controls['notes'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="notes">Notes</label>
                <textarea id="notes" formControlName="notes" name="notes" class="tylie-textarea tylie-textarea--lg form-control"></textarea>
                <p class="p-extra-small" *ngIf="editRequest.controls['notes'].errors?.required">Please Enter Notes</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" *ngIf="this.IsEstimateVisible">
                <label class="tylie-form-group__lbl" for="expectedreturndate">Expected Return Date</label>
                <input type="date" id="expectedreturndate" name="expectedreturndate" class="tylie-text form-control" formControlName="expectedreturndate">
              </div>
            </div>
          </div>
    
          <div class="d-flex justify-content-end">
            <button class="tylie-button tylie-button--xs tylie-button--link me-3" type="button" (click)="closePopup()">Cancel</button>
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit" (click)="onStagingClicked()">
              <span class="tylie-button__icon"><icon-settings color="#fff"></icon-settings></span>
              <span class="tylie-button__text">Staging</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="submit" (click)="onConfirmClicked()" *ngIf="this.source == 3">
              <span class="tylie-button__icon"><icon-export></icon-export></span>
              <span class="tylie-button__text">{{this.functionButtonText}}</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="submit" (click)="onConfirmClicked()" *ngIf="this.source == 1">
              <span class="tylie-button__icon"><icon-delete></icon-delete></span>
              <span class="tylie-button__text">{{this.functionButtonText}}</span>
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</div>
