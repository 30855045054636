<!--<nav-header *ngIf="isPopUp == false" headerTitle="Destination" link="Back To All Destinations" [linkRoute]="destinationsRoute"></nav-header>-->

<div class="modal-header cursor-all-scroll" *ngIf="isPopUp==true && detail">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Destination Detail - {{detail.code}}</h4>
</div>

<ng-container *ngIf="detail">
  <div [class.modal-body]="isPopUp==true">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <h3 class="tylie-type-h3">{{detail.name}}</h3>
        <div class="tylie-data tylie-data--block mb-1">
          <label class="tylie-data__lbl">Type:</label> <span class="tylie-data__txt">{{detail.destinationType}}</span>
        </div>
        <div class="tylie-data tylie-data--block mb-1">
          <label class="tylie-data__lbl">Status: </label><span class="tylie-data__txt">{{detail.status}}</span>
        </div>
        <div class="tylie-data tylie-data--block mb-1">
          <label class="tylie-data__lbl" *ngIf="detail.akas != null">AKAs:</label> <span class="tylie-data__txt">{{detail.akas}}</span>
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="isPopUp == false">
        <button type="button" *ngIf="detail.config.showInActivateDestination == true" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onDestinationStatusUpdate(false)">
          <span class="tylie-button__icon"><icon-expire></icon-expire></span>
          <span class="tylie-button__text">InActivate Destination</span>
        </button>
        <button type="button" *ngIf="detail.config.showActivateDestination == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onDestinationStatusUpdate(true)">
          <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
          <span class="tylie-button__text">Activate Destination</span>
        </button>
        <button type="button" *ngIf="detail.config.showEdit == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onEditDestination()">
          <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
          <span class="tylie-button__text">Edit Destination</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" id="create-detail-section" *ngIf="detail.destinationContact != null">
        <div class="tylie-panel" id="createdetail">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              CONTACT DETAILS
            </h4>
          </div>
          <div class="tylie-panel__body" id="createdetailBody">
            <div *ngFor="let contact of detail.destinationContact.destinationContacts; let i = index">
              <div class="row">
                <div class="col-md-6">
                  <div class="tylie-data tylie-data--block mb-1">
                    <label class="tylie-data__lbl">Contact {{i+1}}</label>
                    <div class="tylie-data__txt">Name: <span>{{contact.name}}</span></div>
                    <div class="tylie-data__txt">Title: <span>{{contact.title}}</span></div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="contact.isMainContact == true">
                  <div class="tylie-data tylie-data--block mb-1">
                    <div class="tylie-data__txt">Address:</div>
                    <div class="tylie-data__txt">{{contact.address1}}</div>
                    <span *ngIf="contact.city" class="tylie-data__txt">{{contact.city}} <span *ngIf="contact.city && (contact.stateStr || contact.zipCode)" class="p-tiny">,</span></span>
                    <span class="tylie-data__txt" *ngIf="contact.stateStr || contact.zipCode">{{contact.stateStr}} {{contact.zipCode}}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="tylie-data tylie-data--block mb-1">
                    <div class="tylie-data__txt">Phone: <span *ngIf="contact.phone"> ({{contact.phone | slice:0:3}})  {{contact.phone | slice:3:6}}-{{contact.phone | slice:6:10}}</span></div>
                    <div class="tylie-data__txt">Fax: {{contact.fax}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="tylie-data tylie-data--block mb-1">
                    <div class="tylie-data__txt">Email: {{contact.email}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="tylie-data tylie-data--block mb-1">
                    <div class="tylie-data__txt">Contact Type: {{contact.destinationContactType}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6" id="create-detail-section">
        <div class="tylie-panel" id="createdetail">
          <div class="tylie-panel__header">
            <h4 class="tylie-panel__title">
              OTHER
            </h4>
          </div>
          <div class="tylie-panel__body" id="createdetailBody">
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Default Hub</label>
                  <div class="tylie-data__txt">{{detail.defaultHub}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Acceptable Formats</label>
                  <div class="tylie-data__txt">{{detail.acceptableFormatsDisplay | joinBySeperator: ', '}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Market</label>
                  <div class="tylie-data__txt">{{detail.displayMarket}}</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Companion Format Required</label>
                  <div class="tylie-data__txt">{{detail.isCompanionFormatRequired == true ? 'Yes': 'No'}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="detail.destinationMetaData != null">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">File Delivery Type</label>
                  <div class="tylie-data__txt">{{detail.destinationMetaData.deliveryType}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Preferred Network</label>
                  <div class="tylie-data__txt">{{detail.preferredVendor}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6" *ngIf="detail.config.fileDestinationTypeBackup == true">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Primary Destination</label>
                  <div class="tylie-data__txt">{{detail.primaryDestination}}</div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="detail.backupDestinationCodes != null && detail.backupDestinationCodes.length > 0">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Backup Destinations</label>
                  <div class="tylie-data__txt">{{detail.backupDestinationCodes  | joinBySeperator: ', '}}</div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="detail.config.showDistributionSettings">
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Is HouseID Required For Download</label>
                  <div class="tylie-data__txt">{{detail.isHouseIdRequiredForDownload == true ? 'Yes': 'No'}}</div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="detail.specifications != null && detail.specifications.length > 0">
                <div class="tylie-data tylie-data--block mb-1">
                  <label for="specMappings" class="tylie-data__lbl">Specification Mappings</label>
                  <ng-container *ngFor="let spec of detail.specifications; let i = index">
                    <div class="tylie-data__txt">
                      {{spec.name}}
                      <ng-container class="p-tiny" *ngIf="spec.recipeName">({{spec.recipeName}})</ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="tylie-data tylie-data--block" *ngIf="detail.bxfXML != null">
              <label class="tylie-data__lbl">BXF File</label>
              <div>
                <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadBxfFile()">Download BXF File</button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Is Traffic Only</label>
                  <div class="tylie-data__txt">{{detail.isTrafficOnly == true ? 'Yes': 'No'}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tylie-data tylie-data--block mb-1">
                  <label class="tylie-data__lbl">Accepts DVS</label>
                  <div class="tylie-data__txt">{{detail.destinationMetaData != null && detail.destinationMetaData.acceptsDescriptiveVideo == true  ? 'Yes': 'No'}}</div>
                </div>
              </div>
            </div>

            <div class="tylie-data tylie-data--block" *ngIf="detail.comments != null && detail.comments != ''">
              <label class="tylie-data__lbl">Comments</label>
              <div class="tylie-data__txt">{{detail.comments}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container id="create-client-section" *ngIf="detail.vendorDestinationMappings != null && detail.vendorDestinationMappings?.length > 0">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            NETWORK
          </h4>
        </div>
        <div class="tylie-panel__body">
          <dx-data-grid id="networkGrid" class="tylie-grid"
                        #networkGrid
                        [dataSource]="detail.vendorDestinationMappings"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="vendorId"
                        [rowAlternationEnabled]="true"
                        [wordWrapEnabled]="true">

            <dxi-column dataField="vendorCode" caption="Name" width="30%" cssClass="gridStyle tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="vendorName" caption="Description" width="40%" cssClass="gridStyle tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="vendorDestinationCode" caption="Mapped ID" width="30%" cssClass="gridStyle tyl-text-align-left tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>

          </dx-data-grid>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
