<header class="tylie-header">
  <h3 class="tylie-header__title">Upload</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoAllOrders()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back to All Orders</button>
  </nav>
</header>

<div class="tylie-body">
  <div class="tylie-panel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        Upload Orders
      </h4>
    </div>
    <div class="tylie-panel__body">
      <form [formGroup]="uploadRequest" #f="ngForm" (ngSubmit)="onUploadOrderSubmit(uploadRequest)" name="uploadForm" id="uploadForm">
        <div class="row">
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['client'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="client">Client<span class="required">*</span></label>
              <dx-select-box name="client"
                              id="client"
                              #client
                              [dataSource]="clientDataSource"
                              [searchEnabled]="true"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              formControlName="client"
                              (onValueChanged)="onClientValueChanged($event)"
                              placeholder="Select a client...">
              </dx-select-box>
              <p class="p-extra-small">Please select Client</p>
            </div>
          </div>
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['brand'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
              <dx-select-box name="brand"
                              #brand
                              id="brand"
                              formControlName="brand"
                              [dataSource]="brandDataSource"
                              [searchEnabled]="true"
                              class="tylie-select form-control"
                              valueExpr="id"
                              noDataText="Please choose a brand"
                              displayExpr="name"
                              (onValueChanged)="onBrandValueChanged($event)"
                              placeholder="Select a brand...">
              </dx-select-box>
              <p class="p-extra-small">Please select Brand</p>
            </div>
          </div>
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['product'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
              <dx-select-box [dataSource]="productDataSource"
                              class="tylie-select form-control"
                              #product
                              id="product"
                              name="product"
                              [searchEnabled]="true"
                              noDataText="Please choose a product"
                              formControlName="product"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select a product...">
              </dx-select-box>
              <p class="p-extra-small">Please select Product</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="client">Service Type</label>
              <dx-select-box name="serviceType"
                              id="serviceType"
                              #client
                              [dataSource]="serviceTypeDataSource"
                              [searchEnabled]="true"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              formControlName="serviceType"
                              placeholder="Select a Service Type...">
              </dx-select-box>
            </div>
          </div>
          <div class="col-4">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="client">Service Level</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input class="form-check-input" id="standard" type="radio" formControlName="isRushService" value="false" (change)="serviceLevelChangeOption(uploadRequest)">
                  <label class="form-check-label" for="standard">Standard</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" id="rush" type="radio" formControlName="isRushService" value="true" (change)="serviceLevelChangeOption(uploadRequest)">
                  <label class="form-check-label" for="rush">
                    
                    <ng-container *ngIf="uploadRequest.controls['isRushService'].value === 'true'">
                      <span class="rush-alert-svg"></span>
                      <span class="rush-text group-error-message">Rush</span>
                    </ng-container>
                    <span *ngIf="uploadRequest.controls['isRushService'].value !== 'true'">Rush</span>
                  </label>
                  <p class="p-extra-small">Please select an option</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="d-flex mb-2">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" name="uploadType" id="uploadType" value="uploadOrder" (change)="changeOption(uploadRequest)" formControlName="uploadType" />
                <label class="form-check-label">Upload Order</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="uploadType" value="uploadtrafficInstructions" (change)="changeOption(uploadRequest)" formControlName="uploadType" />
                <label class="form-check-label">Upload Traffic Instructions</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h3 class="tylie-type-h3" *ngIf="anyScheduledFiles() == true"><b>Added Files</b></h3>
            <div class="added-files" *ngFor="let f of getScheduledFiles()">
              <span class="file-text">{{f.fileName}}</span>
              <a class="tylie-lnk" (click)="removeFromFiles(f)"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
            </div>
            <ng-container *ngIf="uploadRequest.value.uploadType == 'uploadOrder'">
              <label class="tylie-upload">
                <div class="tylie-upload__action tylie-upload__action--xs">
                  <icon-eye></icon-eye><span class="ms-2">Add Order Files</span>
                  <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
                </div>
                <input type="file" style="display:none" (change)="onAttachFile($event)" accept=".xlsx,.xls" id="fileTypeuploadOrder">
              </label>
              <a class="tylie-lnk ms-3" (click)="onDownloadTemplate()">Click Here for Upload Template</a>
            </ng-container>

            <ng-container *ngIf="uploadRequest.value.uploadType == 'uploadtrafficInstructions'">
              <label class="tylie-upload">
                <div class="tylie-upload__action tylie-upload__action--xs">
                  <icon-eye></icon-eye><span class="ms-2">Add Traffic Instruction Files</span>
                  <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
                </div>
                <input type="file" style="display:none" (change)="onAttachFile($event)" accept=".pdf,.xlsx,.xls" id="fileTypeuploadTrafficInstructions">
              </label>
            </ng-container>

            <div class="file-name">
              <p class="error" *ngIf="isMaxFilesExceeded()">{{maxFilesUploadError}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
              <button type="submit" class="tylie-button tylie-button--sm tylie-button--icon mt-4">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">UPLOAD ORDERS</span>
              </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
