<ng-container>
    <div class="modal-header">
        <h4 class="modal-title">MANAGE USERS</h4>
        <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    </div>
    <div class="modal-body">
        <div *ngIf="project!=null && project.collectionName!=null" class="d-flex justify-content-between">
            <h3 class="tylie-type-h3">User for Project "{{project.collectionName}}"</h3>
            <button class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" type="button" (click)="addNewUser()">
                <span class="tylie-button__icon"><icon-user color="#1e90ff"></icon-user></span>ADD A NEWUSER
            </button>
        </div>
        <div *ngIf="assignedUsers!=null && assignedUsers.length>0">
            <form [formGroup]="manageUserForm" (submit)="addUserPermissionFormSubmit()" novalidate #f="ngForm">
                <ng-container>
                    <div formArrayName="manageUsers" class="row user-padding">
                        <div [formGroupName]="i"
                            *ngFor="let user of getFormManageUserGroupsFormArray.controls; let i = index">
                            <div class="tyl-border-top tyl-border-bottom">
                                <div class="row user-padding">
                                    <div class="form-group col-md-6">
                                        <label>User Email</label>
                                        <div>{{getFormManageUserGroupsFormArray.controls[i].get('userName').value}}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 mt-4" style="margin-top: 21px;">
                                        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" style="float: right;"
                                            (click)="onClickRemoveUser(i, getFormManageUserGroupsFormArray.controls[i].value)">
                                            <span class="tylie-button__icon"><icon-trash-can color="#1e90ff"></icon-trash-can></span>
                                            REMOVE
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6" id="roleId">
                                        <div class="tylie-form-group form-group">
                                            <label for="roleId" class="tylie-form-group__lbl">User Permission Level
                                                <span class="required">*</span></label>
                                            <dx-select-box [dataSource]="permissionLevel" name="userPermissionLevelId"
                                                id="userPermissionLevelId" class="tylie-select form-control"
                                                formControlName="userPermissionLevelId" [searchEnabled]="true"
                                                valueExpr="userPermissionLevelId" displayExpr="description">
                                            </dx-select-box>
                                            <p class="p-extra-small">Please select Role</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="tylie-form-group form-group">
                                            <label class="tylie-form-group__lbl" for="client">Folder Access</label>
                                            <dx-drop-down-box [(value)]="folderValues" formControlName="folderValues"
                                                class="tylie-select form-control" valueExpr="fileCollectionId"
                                                displayExpr="collectionName" placeholder="Select a folder..."
                                                [dataSource]="folders">
                                                <div *dxTemplate="let data of 'content'">
                                                    <dx-tree-view [dataSource]="folders" dataStructure="plain"
                                                        keyExpr="fileCollectionId" #treeFolder [searchEnabled]="true"
                                                        selectionMode="multiple" showCheckBoxesMode="normal"
                                                        displayExpr="collectionName" [selectByClick]="true"
                                                        (onContentReady)="syncTreeViewSelection($event, user)"
                                                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event, user)">
                                                    </dx-tree-view>
                                                </div>
                                            </dx-drop-down-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="d-flex justify-content-end">
                    <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
                    <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
                  </div>
            </form>
        </div>
    </div>
</ng-container>