import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timecodeformat'
})

export class TimecodeFormat implements PipeTransform {

  constructor() {
  }

  transform(value: number, fr : string): any {

    //var seconds = parseInt(value);
    //return Math.floor(moment.duration(seconds, 'seconds').asHours()) + ':' + moment.duration(seconds, 'seconds').minutes() + ':' + moment.duration(seconds, 'seconds').seconds();
    
    var timeinseconds = value;
    var framerate = 29.97;
    var partseconds = timeinseconds % 1;
    var wholeseconds = timeinseconds - partseconds;

    if (fr == null || fr == '')
      framerate = Number(fr);

    // frames
    var frames = Math.floor(partseconds * framerate);

    // hours
    var hours = Math.floor(wholeseconds / 3600);
    var minsec = (wholeseconds - (hours * 3600));

    // minutes
    var minutes = Math.floor(minsec / 60);

    // seconds
    var seconds = (minsec - (minutes * 60));

    var hoursStr = hours.toString();
    var minutesStr = minutes.toString();
    var secondsStr = seconds.toString();
    var framesStr = frames.toString();

    if (hours < 10) { hoursStr = "0" + hours; }
    if (minutes < 10) { minutesStr = "0" + minutes; }
    if (seconds < 10) { secondsStr = "0" + seconds; }
    if (frames < 10) { framesStr = "0" + frames; }

    var tc = hoursStr + ":" + minutesStr + ":" + secondsStr + ':' + framesStr;
    //console.log(timeinseconds + " = " + tc)

    return tc;
  }
}
