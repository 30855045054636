import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { UserRoles } from "../../../configurations/user-roles";
import { RouteConstants } from "../../../configurations/route-constants";
import { AdminService } from "../../../services/admin/admin.service";
import { ConfigService } from "../../../services/core/config.service";
import { AlertService } from "../../../services/core/alert.service";

@Component({
  selector: 'destination-landing',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements OnInit {

  public destinationHomeConfig: any = {};

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , public userRoles: UserRoles) {

  }

  ngOnInit() {


  }

  public gotoCreateNewDestination() {
    this.router.navigate([RouteConstants.createDestinationRoute]);
  }
}
