import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { UserResetPassword } from "../../models/user/user-resetpassword.model";

@Injectable()
export class ForgotPasswordSettingsService {
  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint){}

  public generateResetPasswordToken(userName: string) {
    return this.http.put(this.apiendPoint.generateResetPasswordTokenEndPoint(), JSON.stringify(userName), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public validateResetPasswordLink(resetToken: string, userName: string) {
    return this.http.put(this.apiendPoint.validateResetPasswordLinkEndpoint() + "/" + userName, JSON.stringify(resetToken), { headers: new HttpHeaders().set("Content-Type", "application/json") });
  }

  public updatePasswordAfterTokenAuthEndpoint(userResetPassword: UserResetPassword) {
    return this.http.put(this.apiendPoint.updatePasswordAfterTokenAuthEndpoint(), userResetPassword);
  }
}


