import { version } from './version';


export const domain = "login.tyliedev.com";
export const apiUri = "https://tylieapi.tandem.tyliedev.com/api";
export const appUri = "https://tandem.tyliedev.com";
export const clientId = "jb4AzspBGXduA695m0atPIX1LzckxiJn";
export const audience = "https://tylieapi.tandem.tyliedev.com";
export const errorPath = "/home";

export const environment = {
  production: false,
  staging: true,
  env: 'Stage',
  clientId: 'e6B1JhjxNWAHq3SMQqusRmvZsjz1QwQo',
  apiBaseUrl: 'https://tylieapi.tandem.tyliedev.com/api/',
  securityUrl: 'https://login.tyliedev.com/oauth/token',
  audience: 'https://tylieapi.tandem.tyliedev.com',
  versionCheckUrl: 'Share/GetWebStatus/',
  version: version,
  versionCheckFrequencyInMins: 30,
  idleTimeInSecs: 10800,
  idleWarningRemainingTimeInSecs: 300,
  mediaApprovalGridRefreshTimeInMins: 15,
  mediaQCGridRefreshTimeInMins: 15,
  ordersReviewGridRefreshTimeInMins: 15,
  ordersReadyToProcessGridRefreshTimeInMins: 15,
  ordersProcessingRefreshTimeInMins: 15,
  ordersInProgressRefreshTimeInMins: 15,
  ordersVendorSubmissionFailedGridRefreshTimeInMins: 15,
  ordersReadyForBillingGridRefreshTimeInMins: 15,
  asperaConnectPath: 'https://stagetylieinstallations.tyliedev.com/AsperaConnectSdk/',
  userProfileEndPoint: 'https://tylieapi.tandem.tyliedev.com/api/User/GetUserProfiles',
  userDisplayDataEndPoint: 'https://tylieapi.tandem.tyliedev.com/api/User/GetDisplayUserData',
  entraApiscopes: ['api://9a7e98a4-cf2b-44c9-911f-6d4633dc98e3/access_as_user']
};
