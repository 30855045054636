<header class="tylie-header">
  <h3 class="tylie-header__title">Notifications</h3>
  <div class="collapse navbar-collapse">
  </div>
</header>

<div class="tylie-body">
    <div class="tylie-panel">
      <div class="tylie-panel__header">
        <h4 class="tylie-panel__title">
            Inbox
        </h4>
      </div>
      <div class="tylie-panel__body">
        <div class="row">
          <div class="col-8">
            <section class="tylie-input-search">
              <div class="tylie-input-search__inner">
                <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                <input class="tylie-input-search__text" placeholder="Search Current Inbox" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                    (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">
              </div>
              <div class="tylie-input-search__btn">
                <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
              </div>
            </section>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-4">
          <div>
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon me-3" (click)="setViewed()"><span class="tylie-button__icon"><icon-open-envelope></icon-open-envelope></span><span class="tylie-button__text">Mark as read</span></button>
            <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" [ngClass]="{disabled : showArchived}" (click)="setArchived()"><span class="tylie-button__icon"><icon-archive></icon-archive></span><span class="tylie-button__text">Archive</span></button>
          </div>
          <div class="tylie-form-group form-check">
            <input type="checkbox" class="form-check-input" name="showArchivedOnly" id="showArchivedOnly" (click)="showArchivedChange()">
            <label for="showArchivedOnly" class="form-check-label">Show Archived Only</label>
          </div>
        </div>
        
        <div class="tylie-grid-wrapper">
          <dx-data-grid id="notificationGrid" class="tylie-grid"
                        #notificationGrid
                        [dataSource]="dataSource"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [hoverStateEnabled]="true"
                        [showRowLines]="true"
                        (onRowPrepared)="onRowPrepared($event)"
                        (onRowClick)="onRowClick($event)"
                        keyExpr="requestNotifyID">
            <dxo-selection mode="multiple"
                            selectAllMode="page"
                            allowSelectAll="Select"
                            showCheckBoxesMode="always">
            </dxo-selection>
            <dxi-column dataField="eventType" width="20%" cssClass="gridStyle" caption="Type"></dxi-column>
            <dxi-column dataField="subject" width="60%" cssClass="gridStyle" caption="Subject"></dxi-column>
            <dxi-column dataField="createdDate" width="20%" cellTemplate="dateTemplate" cssClass="gridStyle" caption="Received" sortOrder="desc"></dxi-column>
            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{data.value | usertimezone: 'MM/DD/YYYY'}}</div>
            </div>
            <dxo-remote-operations [sorting]="true"
                                    [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50]"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>

      <div id="notificationMessageCollapseOne" class="panel-collapse collapse in" *ngIf="notificationMessage">
        <div class="panel-body">
          <div [innerHTML]="notificationMessage | safeHtml" id="notificationMessage"></div>
        </div>
        <div class="panel-footer"></div>
      </div>

    </div>
</div>
