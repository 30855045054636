import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { MediaDetailService } from "../../../services/mediadetail/mediadetail.service";
import { OrderService } from '../../../services/order/order.service';

@Component({
  selector: 'ordercommentshistorypopup',
  templateUrl: './ordercommentshistorypopup.component.html',
  styleUrls: ['./ordercommentshistorypopup.component.css'],
})
export class OrderCommentsHistoryPopupComponent implements OnInit {
  public orderCommentsHistoryData: any;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private mediaDetailService: MediaDetailService,
    private orderService: OrderService,
    private util: Utilities) {
  }

  ngOnInit() {

  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
