<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Select the destination folder</h4>
  </div>
  <div class="modal-body">
    <p class="tylie-type-body mb-3">If no folder is selected file will be placed under project</p>
    <form [formGroup]="moveFilesBetweenFoldersProjectForm" (submit)="moveFileBetweenFolders()" novalidate #f="ngForm">
      <div class="row">
        <div class="col-md-4">
          <div class="tylie-form-group form-group"
            [ngClass]="{error: moveFilesBetweenFoldersProjectForm.controls['destinationFileCollectionId'].errors && f.submitted}">
            <label class="tylie-form-group__lbl" for="client">Folder</label>
            <dx-select-box name="folderNames" id="folderNames" [dataSource]="folders"
              [searchEnabled]="true" class="tylie-select" formControlName="destinationFileCollectionId" placeholder="Select a folder..."
              valueExpr="fileCollectionId" displayExpr="collectionName">
            </dx-select-box>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>

<!--filecollectionid, destinationsfilecollectionid-->
