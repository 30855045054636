<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Transcode Preferences Settings</h4>
</div>
<div class="modal-body">
    <form [formGroup]="distributionSettingsFormGroup" novalidate role="search" #f="ngForm">
      <div class="tylie-form-group form-check">
        <input type="checkbox" id="enableAdvancedFileNaming" name="enableAdvancedFileNaming" formControlName="enableAdvancedFileNaming" class="form-check-input" />
        <label for="enableAdvancedFileNaming" class="form-check-label">Enable Advanced File Naming <span class="required"></span></label>
      </div>
    </form>
    <div class="tylie-form-group">
      <label class="tylie-form-group__lbl" for="comments">Specification Mapping</label>

      <section class="tylie-input-search">
        <div class="tylie-input-search__inner">
          <div class="tylie-input-search__icon"><icon-search></icon-search></div>
          <input class="tylie-input-search__text" placeholder="Search By Specification Name"
               name="srch-term" id="srch-term" type="search" (keyup.enter)="onQuickSearchDestinations()"
               [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
        </div>
        <div class="tylie-input-search__btn">
          <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
        </div>
      </section>
    </div>
          
    <div class="tylie-grid-wrapper">
      <dx-data-grid id="specsGrid" class="tylie-grid tylie-grid--bordered"
                    #specsGrid
                    [dataSource]="dataSource"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [rowAlternationEnabled]="true"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showRowLines]="true"
                    keyExpr="id">
        <dxo-selection mode="multiple"
                        selectAllMode="page"
                        allowSelectAll="Select"
                        showCheckBoxesMode="always">
        </dxo-selection>
        <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Specification Name" sortOrder="asc"></dxi-column>
        <dxi-column dataField="description" width="70%" cssClass="gridStyle" caption="Description"></dxi-column>
        <dxo-remote-operations [sorting]="true"
                                [paging]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                    [allowedPageSizes]="[10, 20, 50, 100]"
                    infoText="Page {0} of {1} ({2} items)"
                    [showNavigationButtons]="true"
                    [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs" type="button" (click)="formSubmit(distributionSettingsFormGroup)">
        SAVE
      </button>
    </div>
</div>

