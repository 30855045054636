<header class="tylie-header">
  <h3 class="tylie-header__title">Create New Brand</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" type="button" (click)="gotoBrandHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Brands</button>
  </nav>
</header>

<div class="tylie-body">
  <form novalidate (ngSubmit)="onFormSubmit(createBrandFormGroup)" [formGroup]="createBrandFormGroup" role="search" #f="ngForm">

    <div class="tylie-panel tylie-panel--clean">
      <div class="tylie-panel__body">
        <div class="row">
          <div class="col-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createBrandFormGroup.controls['brandName'].errors && !createBrandFormGroup.controls['brandName'].pristine)
              || (createBrandFormGroup.controls['brandName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="brandName">Brand Name<span class="required">*</span></label>
              <input formControlName="brandName" type="text" id="brandName" name="brandName" class="tylie-text form-control" />
              <p class="p-extra-small">Please enter Brand Name</p>
            </div>
          </div>
          <div class="col-3">
            <div class="tylie-form-group form-group" [ngClass]="{error: (createBrandFormGroup.controls['shortName'].errors && !createBrandFormGroup.controls['shortName'].pristine)
              || (createBrandFormGroup.controls['shortName'].errors && f.submitted)}">
              <label class="tylie-form-group__lbl" for="brandName">Short Name<span class="required">*</span></label>
              <input formControlName="shortName" type="text" id="shortName" name="shortName" class="tylie-text form-control" />
              <p class="p-extra-small">Please Enter a Short Name with a maximum of 15 Characters</p>
            </div>
          </div>
          <div class="col-3">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="status">Location</label>
              <dx-select-box [dataSource]="locationDataSource"
                            name="location" id="location" class="tylie-select form-control"
                            formControlName="location"
                            valueExpr="id"
                            displayExpr="name">
              </dx-select-box>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit">
        <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
        <span class="tylie-button__text">Save Brand</span>
      </button>
    </div>
  </form>
</div>

