<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#5cb85c"
  viewBox="0 0 90.85 90.85"
  [attr.height]="height"
  [attr.width]="width"
>
  
  <g id="Layer_2" data-name="Layer 2" [attr.fill]="color">
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="M45.43,0A45.43,45.43,0,1,0,90.85,45.43,45.43,45.43,0,0,0,45.43,0ZM67.1,35.4l-23,28.12a6.42,6.42,0,0,1-4.69,2.33h-.23a6.42,6.42,0,0,1-4.62-2l-10.45-11a6.37,6.37,0,1,1,9.23-8.78l5.48,5.75L57.25,27.32A6.38,6.38,0,0,1,62.16,25h0A6.37,6.37,0,0,1,67.1,35.4Z"
      />
    </g>
  </g>
</svg>
