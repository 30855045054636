import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { ConfigService } from '../../../services/core/config.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { Constants } from '../../../configurations/constants';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { SpotDestinationAddlInfoFormRequest, SpotDestinationAddlInfo, DestinationAddlInfoMetadata, SpotDestinationAddlInfoFormSet, SpotDestinationAddlInfoSet } from '../../../models/order/order-create.model';
import { CustomValidator } from '../../../configurations/custom-validations';

@Component({
  selector: 'orderadestinationinfopopup',
  templateUrl: './orderadestinationinfopopup.component.html',
  styleUrls: ['./orderadestinationinfopopup.component.css'],
})
export class OrderDestinationInfoPopupComponent extends BasePopupComponent {

  @Input() spotDestinationAddlInfo: SpotDestinationAddlInfo;
  @Input() readOnlyMode: boolean;

  public addlInfoRequest: FormGroup;
  public onAddlInfoSubmitted: Subject<SpotDestinationAddlInfo>;
  public applyToAll: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private util: Utilities,
    private _fb: FormBuilder) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onAddlInfoSubmitted = new Subject();

    this.applyToAll = this.spotDestinationAddlInfo.destination.applyAddlInfoToAllAssets == null ? false : this.spotDestinationAddlInfo.destination.applyAddlInfoToAllAssets;

    this.initForm();
  }

  private initForm() {

    switch (this.spotDestinationAddlInfo.destination.fileDeliveryType.toLowerCase()) {
      case 'innovid': {

        let sets: Array<any> = [];

        if (this.applyToAll == true) {

          let item = this.spotDestinationAddlInfo.sets[0];
          let spotFiles: Array<SpotFile> = [];

          this.spotDestinationAddlInfo.sets.forEach(item => {
            spotFiles.push(item.spotFile);
          });

          let set = this._fb.group({
            spotFiles: [spotFiles],
            innovidData: this.getInnovidGroup(item)
          });

          sets.push(set);
        }
        else {

          this.spotDestinationAddlInfo.sets.forEach(item => {

            let set = this._fb.group({
              spotFiles: [[item.spotFile]],
              innovidData: this.getInnovidGroup(item)
            });

            sets.push(set);
          });
        }

        this.addlInfoRequest = this._fb.group({
          sets: this._fb.array(sets),
          destinationCode: [this.spotDestinationAddlInfo.destination.code],
          fileDeliveryType: [this.spotDestinationAddlInfo.destination.fileDeliveryType],
        });

        break;
      }
      default: {
        break;
      }
    }

    console.log("init form", this.addlInfoRequest);
  }

  private getInnovidGroup(item: SpotDestinationAddlInfoSet) {
    let ind = item.addlInfoMetadata.findIndex(t => t.metadataKey == 'campaign');

    return this._fb.group({ campaign: new FormControl({ value: (ind >= 0 ? item.addlInfoMetadata[ind].metadataValue : ''), disabled: this.readOnlyMode }, [Validators.required, CustomValidator.required]) });
  }

  public onApplyAllChange() {
    this.initForm();
  }

  public get sets() {
    return <FormArray>this.addlInfoRequest.get('sets');
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSave({ value, valid }: { value: SpotDestinationAddlInfoFormRequest, valid: boolean }) {
    console.log("submit", value);

    if (!valid) {
      this.alertService.showMessage("ERROR", Constants.addOptionsMandatoryFields, MessageSeverity.error);
      return;
    }

    let spotDestinationAddlInfoSets: Array<SpotDestinationAddlInfoFormSet> = value.sets;
    let spotDestinationAddlInfoUpdated: SpotDestinationAddlInfo = { sets: [], destination: this.spotDestinationAddlInfo.destination };

    this.spotDestinationAddlInfo.destination.applyAddlInfoToAllAssets = this.applyToAll;

    switch (this.spotDestinationAddlInfo.destination.fileDeliveryType.toLowerCase()) {
      case 'innovid':
        {
          spotDestinationAddlInfoSets.forEach(t => {

            t.spotFiles.forEach(x => {

              let addlInfoMetadata: Array<DestinationAddlInfoMetadata> = [];
              addlInfoMetadata.push({ metadataKey: 'campaign', metadataValue: t.innovidData.campaign });

              spotDestinationAddlInfoUpdated.sets.push({ spotFile: x, addlInfoMetadata: addlInfoMetadata });
            });
          });

          break;
        }
      default: {
        break;
      }
    }

    this.onAddlInfoSubmitted.next(spotDestinationAddlInfoUpdated);

    this.closePopup();
  }
}
