export class UserResetPassword {
  public resetToken: string;
  public newPassword: string;
  public userName: string;

  constructor(resetToken: string, newPassword: string, userName: string) {
    this.resetToken = resetToken;
    this.newPassword = newPassword;
    this.userName = userName;
  }

}

