import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css']
})
export class CustomDropdownComponent implements OnInit {
  @Input() displayKey: string;
  @Input() groupNameDisplayKey?: string = '';
  @Input() selectedLabel: any;
  @Input() selectTitle: string = 'SELECT';
  @Input() selectedItems: any[]=[];
  @Input() isLocation: boolean = false;
  @Input() options: any[] = [];
  @Output() submit = new EventEmitter<any>();

  public selectedOptions = [];
  expanded = false;

  constructor() { }

  ngOnInit() {
    this.selectedOptions = this.selectedItems;
  }

  ngOnChanges(changes: any): void {
    this.selectedOptions = changes.selectedItems && changes.selectedItems.currentValue || this.selectedOptions;
  }

  toggleDropdown() {
    this.expanded = !this.expanded;
  }

  selectOption(option) {
    if (this.selectedOptions.includes(option)) {
      this.selectedOptions = this.selectedOptions.filter(i => i !== option);
    } else {
      this.selectedOptions.push(option);
    }
  }

  selectAll() {
    if (this.isLocation) {
      this.selectedOptions = this.options.reduce((acc, cur) => { return acc.concat(cur.locations) },[]);
    } else {
      this.selectedOptions = [...this.options];
    }
  }

  onSubmit() {
    this.submit.emit(this.selectedOptions);
    this.toggleDropdown();
  }

  selectAllInGroup(option) {
    option.locations.forEach(location => {
      if (!this.selectedOptions.includes(location)) {
        this.selectedOptions.push(location);
      }
    });
  }
}
