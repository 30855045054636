export class DownloadMediaFileStatus {
  title: string;
  adId: string;
  fileName: string;
  fileNameWithExt: string;
  storageKey: string;
  percentage: number;
  status: downloadStatusEnum;
  error: string;
  show: boolean;
  spotFileGuid: string;
  downloadMediaFileGuid: string;
  assetFileGuid: string;
}

export class DownloadMediaStatus {
  requestGuid: string;
  status: downloadStatusEnum;
  header: string;
  dowloadStatus: Array<DownloadMediaFileStatus>;
  show: boolean;
}

export enum downloadStatusEnum {
  submitted,
  downloading,
  completed,
  failed
}
