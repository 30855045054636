<div class="media-share" id="transcode-pop-up">
  <div class="modal-header">
    <button type="button" class="modal-close" data-dismiss="modal" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Share Transcode Assets</h4>
  </div>
  <div class="modal-body">
    <div class="transcode-galarey">

      <div class="files-sec">
        <div class="cool-md-12 trans-file-list" *ngFor="let asset of assetFiles; let j=index">
          <div class="transcode-bold">
            {{asset.transcodeSpec}}
          </div>
          <div class="trans-file-name">{{asset.fileName + asset.fileExtension}}</div>
          <div class="trans-file-close" (click)="onDelete(asset.assetFileGuid)"><span class="glyphicon glyphicon-remove-sign"></span></div>
        </div>
      </div>
    </div>

    <form novalidate [formGroup]="shareMediaForm" #f="ngForm">

      <div class="tylie-form-group form-group" [ngClass]="{pextrasmall1: (shareMediaForm.controls['toemail'].hasError('inValidEmailList') && !shareMediaForm.controls['toemail'].pristine && isGenerateLinkAction == false)
              || (shareMediaForm.controls['toemail'].hasError('inValidEmailList') && f.submitted && isGenerateLinkAction == false)}">
        <label class="tylie-form-group__lbl" for="to-email">To Email<i class="pextrasmall1"></i><span class="required">*</span></label>
        <dx-tag-box [ngClass]="{errors: (shareMediaForm.controls['toemail'].hasError('inValidEmailList')  && !shareMediaForm.controls['toemail'].pristine && isGenerateLinkAction == false)
                      || (shareMediaForm.controls['toemail'].hasError('inValidEmailList')  && f.submitted && isGenerateLinkAction == false)}"
                    class="tylie-select form-control"
                    formControlName="toemail"
                    (onKeyDown)=onKeyEvent($event)
                    (onFocusOut)="onFocusOut($event)"
                    [deferRendering]="false"
                    [searchEnabled]="true"
                    searchMode="contains"
                    [noDataText]=""
                    [minSearchLength]="3"
                    [dataSource]="emailSuggestions"
                    [showDataBeforeSearch]="false"
                    [acceptCustomValue]="true">
        </dx-tag-box>
        <p [ngClass]="[((shareMediaForm.controls['toemail'].hasError('inValidEmailList') && !shareMediaForm.controls['toemail'].pristine && isGenerateLinkAction == false)
              || (shareMediaForm.controls['toemail'].hasError('inValidEmailList')  && f.submitted && isGenerateLinkAction == false)) ? 'pextrasmall2' : 'hide']">
          One or more emails is not valid
        </p>
      </div>


      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-group " [ngClass]="{error: (shareMediaForm.controls['subject'].errors && !shareMediaForm.controls['subject'].pristine && isGenerateLinkAction == false)
                 || (shareMediaForm.controls['subject'].errors && f.submitted && isGenerateLinkAction == false)}">
            <label class="tylie-form-group__lbl" for="subject">Subject<span class="required">*</span></label>
            <input formControlName="subject" type="text" id="subject" name="subject" class="tylie-text form-control " />
            <p class="p-extra-small">Please enter subject</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="tylie-form-group form-check mt-4  ">
            <label class="form-check-label">
              <input formControlName="optcopy" type="checkbox" name="optcopy" class="form-check-input"><span class="ms-3">Send Me Copy</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-group " [ngClass]="{error: (shareMediaForm.controls['linkexpire'].errors && !shareMediaForm.controls['linkexpire'].pristine)
                 || (shareMediaForm.controls['linkexpire'].errors && f.submitted) || (isGenerateLinkAction == true && shareMediaForm.controls['linkexpire'].errors)}">
            <label class="tylie-form-group__lbl" for="subject">Link Expiration<span class="required">*</span></label>
            <select formControlName="linkexpire" id="linkexpire" name="linkexpire" class="tylie-select form-control ">
              <option value="">--Select Link Expiry--</option>
              <option value="1">1 Day</option>
              <option value="2">2 Days</option>
              <option value="3">3 Days</option>
              <option value="4">4 Days</option>
              <option value="30">30 Days</option>
              <option value="90">90 Days</option>
            </select>
            <p class="p-extra-small">Please select Link Expiration</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-check ">
            <label class="tylie-form-group__lbl">
              <input formControlName="optviews" type="checkbox" name="optviews" class="form-check-input"><span class="ms-3">Limit Views</span>
            </label>
          </div>
        </div>
        <div class="col-md-6" *ngIf="shareMediaForm.value.optviews == true">
          <div class="tylie-form-group form-group " [ngClass]="{error: (shareMediaForm.value.optviews ==true && shareMediaForm.value.limitviews == null && !shareMediaForm.controls['limitviews'].pristine)
                 || (shareMediaForm.value.optviews ==true && shareMediaForm.value.limitviews == null && f.submitted)
                 || (showLimitViewsError == true && shareMediaForm.value.optviews == true && shareMediaForm.value.limitviews == null)}">
            <label class="tylie-form-group__lbl" for="viewspermitted">Views Permitted<span class="required">*</span></label>
            <input formControlName="limitviews" type="number" min="1" max="100" id="limitviews" name="limitviews" class="tylie-select  form-control " />
            <p class="p-extra-small">Please select views permitted</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-check">
            <label class="tylie-form-group__lbl">
              <input formControlName="optpassword" type="checkbox" name="optpassword" class="form-check-input"><span class="ms-3">Require Password</span>
            </label>
          </div>
        </div>
        <div class="col-md-6" *ngIf="shareMediaForm.value.optpassword == true">
          <div class="tylie-form-group form-group  " [ngClass]="{error: (shareMediaForm.value.optpassword == true && shareMediaForm.value.password == '' && !shareMediaForm.controls['password'].pristine) ||
                 (shareMediaForm.value.optpassword == true && shareMediaForm.value.password == '' && f.submitted)
                 || (showPasswordError == true && shareMediaForm.value.optpassword == true && shareMediaForm.value.password == '')}">
            <label class="tylie-form-group__lbl" for="password">Password<span class="required">*</span></label>
            <input type="text" id="password" name="password" class="tylie-text form-control " formControlName="password" />
            <p class="p-extra-small">Please enter passsword</p>

            <button class="tylie-button tylie-button--xs mt-2" type="button" (click)="autoGeneratePassword()">Generate Password</button>
          </div>
        </div>
      </div>

      <div class="tylie-form-group form-group  ">
        <label class="tylie-form-group__lbl" for="Comments">Comments</label>
        <textarea formControlName="comments" id="Comments" name="Comments" class="tylie-textarea form-control "></textarea>
      </div>

      <div class="tylie-form-group form-group ">
        <div class="d-flex justify-content-between">
          <label class="tylie-form-group__lbl" for="subject">Shareable Link</label>
          <button (click)="generateShareLink(shareMediaForm)" class="tylie-button tylie-button--link tylie-button--xs" type="button" for=" to-email">Generate Shareable Link</button>
        </div>

        <div class="tylie-text-group input-group mb-3">
          <input type="text" class="tylie-text form-control" formControlName="sharelink" name="sharelink" id="sharelink" type="text">
          <button class="tylie-button tylie-button--sm" type="button" (click)="copyLink()">Copy</button>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs" type="submit" (click)="shareMedia(shareMediaForm)">Share Assets</button>
        <button class="tylie-button tylie-button--xs tylie-button--link ms-3" type="button" (click)="bsModalRef.hide()">Cancel</button>
      </div>

    </form>
  </div>
</div>
