import { Component, ViewChild, Input, OnInit, EventEmitter, Output, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { DxDataGridComponent } from "devextreme-angular";
import { OrderDetailDigitalDeliveryDestinationModel, OrderDigitalDeliveryDestinationDetailModel } from "../../../../models/order/order-destinations.model";
import { OrderEditNetworkDestinationPopupComponent } from "../../../popups/ordereditnetworkdestinationpopup/ordereditnetworkdestinationpopup.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {  PopoverDirective } from "ngx-bootstrap/popover";
import { Constants } from "../../../../configurations/constants";
import { OrderDetailAction } from "../../../../models/order/order-detail.model";
import { DestinationViewComponent } from "../../../admin/destination/destination-detail/destination-view/destination-view.component";

@Component({
  selector: 'order-detail-destinations',
  templateUrl: './order-detail-destinations.component.html',
  styleUrls: ['./order-detail-destinations.component.css']
})
export class OrderDetailDestinationsComponent implements OnInit, AfterViewInit {
  public orderDetailDestinationStore: OrderDigitalDeliveryDestinationDetailModel[] = [];
  public orderDigitalDeliveryDestinationModel: OrderDetailDigitalDeliveryDestinationModel;
  public modalRef: BsModalRef;
  public showLoader = false;

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @Input('orderGuid') orderGuid: string;
  @Input('showCarrierErrorInDetailTabs') showCarrierErrorInDetailTabs: boolean;
  @Input('showHubbedDeliveryInDetailTabs') showHubbedDeliveryInDetailTabs: boolean;
  @Input('showActionsColumnInDestinationDestinationsTab') showActionsColumnInDestinationDestinationsTab: boolean;
  @Input('showVendorAssignmentFailedError') showVendorAssignmentFailedError: boolean;
  @ViewChildren('displErrorpop') displErrorpop: QueryList<PopoverDirective>;

  @Output() refreshOrderActions: EventEmitter<OrderDetailAction> = new EventEmitter<OrderDetailAction>();

  constructor(
    public alertService: AlertService,
    public util: Utilities,
    private modalService: BsModalService,
    private orderService: OrderService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    if (this.orderGuid != null && this.orderGuid != undefined) {
      this.getDestinationsFromOrder();
    }
  }

  public getDestinationsFromOrder() {
    this.showLoader = true;
    this.dataGrid.instance.beginCustomLoading("Loading...");

    this.orderService.getDestinationForOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.logMessage(res.result);
        this.orderDigitalDeliveryDestinationModel = res.result as OrderDetailDigitalDeliveryDestinationModel;
        this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
      }
      else {
        this.orderDigitalDeliveryDestinationModel = new OrderDetailDigitalDeliveryDestinationModel();
        this.util.handleIsNotSuccess(res.errors);
      }
      this.showLoader = false;
      this.dataGrid.instance.endCustomLoading();
    },
      error => {
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.orderDigitalDeliveryDestinationModel = new OrderDetailDigitalDeliveryDestinationModel();
        this.util.handleError(error);
      });
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public onAssignVendor(data: any) {
    data.orderGuid = this.orderGuid;
    var adids: string[] = [];
    this.orderDetailDestinationStore.filter(d => d.destinationId == data.destinationId).forEach(res => {
      if (res.allowVendorAssignment && !adids.includes(res.adId)) {
        adids.push(res.adId);
      }
    });
    var initialState = {
      data: data,
      isAssignVendor: true,
      adids: adids,
      title: "Assign Carrier"
    };

    this.modalRef = this.modalService.show(OrderEditNetworkDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'destinationTabActions'));
    this.modalRef.content.onClose.subscribe(result => {
      this.orderDigitalDeliveryDestinationModel = result as OrderDetailDigitalDeliveryDestinationModel;
      this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
      var orderAction = this.orderDigitalDeliveryDestinationModel.orderActions;
      this.refreshOrderActions.emit(orderAction);
    });
  }

  public onUpdateVendorPOD(data: any) {
    data.orderGuid = this.orderGuid;

    var initialState = {
      data: data,
      title: "Update POD"
    };

    this.modalRef = this.modalService.show(OrderEditNetworkDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'destinationTabActions'));
    this.modalRef.content.onClose.subscribe(result => {
      this.orderDigitalDeliveryDestinationModel = result as OrderDetailDigitalDeliveryDestinationModel;
      this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
    });
  }

  public onReSendAssignVendor(data: any) {
    data.orderGuid = this.orderGuid;
    
    var initialState = {
      data: data,
      title: "ReSend"
    };

    this.modalRef = this.modalService.show(OrderEditNetworkDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'destinationTabActions'));
    this.modalRef.content.onClose.subscribe(result => {
      this.orderDigitalDeliveryDestinationModel = result as OrderDetailDigitalDeliveryDestinationModel;
      this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
      var orderAction = this.orderDigitalDeliveryDestinationModel.orderActions;
      this.refreshOrderActions.emit(orderAction);
    });
  }

  public onRetryVendorLineItemCreation(data: any) {
    this.orderService.retryVendorLineItemCreation(data.orderGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.orderDigitalDeliveryDestinationModel = res.result as OrderDetailDigitalDeliveryDestinationModel;
          this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
          this.alertService.showMessage("SUCCESS", Constants.orderRetrySuccessful, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onTakeAction(data: any) {
    data.orderGuid = this.orderGuid;
    var adids: string[] = [];

    if (data.allowReSubmitWithDifferentVendor == true) {
      this.orderDetailDestinationStore.filter(d => d.destinationId == data.destinationId).forEach(res => {
        if (!adids.includes(res.adId)) {
          adids.push(res.adId)
        }
      });
    }

    var initialState = {
      data: data,
      adids: adids
    };

    this.modalRef = this.modalService.show(OrderEditNetworkDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'destinationTabActions'));
    this.modalRef.content.onClose.subscribe(result => {
      this.orderDigitalDeliveryDestinationModel = result as OrderDetailDigitalDeliveryDestinationModel;
      this.orderDetailDestinationStore = this.orderDigitalDeliveryDestinationModel.orderDigitalDeliveryDestinationDetailList as OrderDigitalDeliveryDestinationDetailModel[];
    });
  }

  public showDetail(destinationGuid: string, showDetail: boolean) {
    if (showDetail == false || !destinationGuid || destinationGuid == '') {
      return;
    }
    var initialState = {
      destinationGuid: destinationGuid,
      isPopUp: true
    };

    this.modalRef = this.modalService.show(DestinationViewComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onHideDisplayErrorPop() {
    this.displErrorpop.forEach(p => {
      p.hide();
    });
  }

  public onExport() {
    this.dataGrid.instance.exportToExcel(false);
  }
}
