import { Injectable, Inject } from '@angular/core';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { UserProfile } from '../../models/user/user.profile.model';
import { User } from "../../models/user/user.model";
import { LocalStoreManager } from "../core/local-store-manager.service";
import { DBkeys } from "../../configurations/db-Keys";
import { Utilities } from "../core/utilities";
import { UserDataService } from "./user-data.service";

@Injectable()
export class UserProfileService {
  constructor(public util: Utilities,
    private localStorage: LocalStoreManager) { }

  public getUserId(): string {
    let userObj: User;
    userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    return userObj.userName;
  }

  public getUser(): User {
    let userObj: User;
    userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    return userObj;
  }

  public getUserRoles(): string[] {
    let userObj: User;
    let roles: string[] = [];
    userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    if (userObj != undefined) {
      roles = Array.isArray(userObj.roles) ? userObj.roles : [userObj.roles];
    }
    return roles;
  }

  public getUserProfile(): UserProfile {
    let userObj: User;
    userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    if (userObj != null && userObj != undefined) {
      let userProf: UserProfile = userObj.userProfile;
      return userProf;
    }
    else {
      return null;
    }
  }

  //public refreshUserProfile() {
  //  var userProfile = this.getUserProfile();
  //  this.userData.getUserData().subscribe(res => {
  //    var jsonRes = res.json();
  //    if (jsonRes.isSuccess == true) {
  //      userProfile = jsonRes.result.userProfile;
  //      console.log(userProfile);
  //      var user = this.getUser();
  //      user.userProfile = userProfile;
  //      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
  //    }
  //    else { this.util.handleIsNotSuccess(jsonRes.errors); }
  //  },
  //    error => {
  //      this.util.handleError(error);
  //    });
  //}
}


