<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Notifications</h4>
</div>
<div class="modal-body">
  <dx-data-grid id="notificationGrid" class="tylie-grid tylie-grid--bordered"
                #notificationGrid
                [dataSource]="dataSource"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="onRowClick($event)"
                keyExpr="requestID">
    <dxi-column dataField="eventType" width="20%" cssClass="gridStyle" caption="Type"></dxi-column>
    <dxi-column dataField="subject" width="60%" cssClass="gridStyle" caption="Subject"></dxi-column>
    <dxi-column dataField="createdDate" width="20%" cellTemplate="dateTemplate" cssClass="gridStyle" caption="Received" sortOrder="desc"></dxi-column>
    <div *dxTemplate="let data of 'dateTemplate'">
      <div style="text-align:left;">{{data.value | usertimezone: 'MM/DD/YYYY'}}</div>
    </div>
    <dxo-remote-operations [sorting]="true"
                            [paging]="true">
    </dxo-remote-operations>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20, 50, 100]"
                infoText="Page {0} of {1} ({2} items)"
                [showNavigationButtons]="true"
                [showInfo]="true">
    </dxo-pager>
  </dx-data-grid>
   

  <div id="notificationMessageCollapseOne" class="tylie-panel" *ngIf="notificationMessage">
    <div class="tylie-panel__body">
      <div [innerHTML]="notificationMessage | safeHtml" id="notificationMessage"></div>
    </div>
   
  </div>
</div>
