<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">ATTACHMENT CATEGORY</h4>
</div>
<div class="modal-body">
  <form [formGroup]="attchmentCategoryRequest" #f="ngForm">
    <div class="tylie-form-group form-group qc-notes" [ngClass]="{error: errorMsg != ''}">
      <dx-select-box [dataSource]="attachmentCategories" class="tylie-select"
                     formControlName="selectedCategory"
                     displayExpr="name"
                     valueExpr="name"
                      placeholder="Select a category"
                     (onValueChanged)="onChangeCategory($event)"
                     itemTemplate="field">
        <div *dxTemplate="let data of 'field'">
          <div [title]="data.title">{{data.name}}</div>
        </div>
      </dx-select-box>
      <p class="p-extra-small">{{errorMsg}}</p>
    </div>

    <div class="mb-3">
      <p *ngFor="let fl of attachedFiles;">{{fl.fileName}}</p>
    </div>
    <div class="d-flex justify-content-end">
      <div class="tylie-upload me-3">
        
        <label class="tylie-upload__link">
          <input #selectedFile type="file" multiple style="display:none" (change)="onAttachFile($event)" [accept]="acceptFileTypes" [disabled]="attchmentCategoryRequest.controls['selectedCategory'].errors">
          {{isUploading ? 'UPLOADING...' : 'UPLOAD'}}
          <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
        </label>
      </div>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Close</button>
    </div>
  </form>
</div>
