
import {map} from 'rxjs/operators';
import { Injectable, Inject } from "@angular/core";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AlertService, MessageSeverity } from "../core/alert.service";
import { Utilities } from "../core/utilities";
import * as moment from 'moment-timezone';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { DownloadMediaStatus, DownloadMediaFileStatus, downloadStatusEnum } from "../../models/download/downloadmedia-status";
import { SpotFile } from "../../models/spot/spot-file.model";
import { SpotService } from "./spot.service";
import { AppConfig, APP_CONFIG } from "../../app.module.config";
import { Observable } from "rxjs";
import { MediaFileData } from "../../models/distribution/distribution-search-request..model";
import { TranscodeAssetFile } from "../../models/order/transcodeorder.model";
import { environment } from "../../../environments/environment.stage";
declare var AW4: any;

@Injectable()
export class DownloadMediaService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    private apiendPoint: ApiEndPoint,
    private alertService: AlertService,
    private spotService: SpotService,
    private http: HttpClient,
    public util: Utilities) {
  }

  /* Quick S3 download */
  public initiateQuickS3Download(storageKey: string, fileName: string, isMaster: boolean, isProxy: boolean, isOther: boolean, spotFileGuid: string) {

    var request = { fileName: fileName, storageKey: storageKey, isMaster: isMaster, isProxy: isProxy, isOther: isOther, spotFileGuid: spotFileGuid };

    this.http.post(this.apiendPoint.downloadMediaUrlEndPoint(), request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.quickDownloadFromUrl(res.result, fileName);
      }
      else {
        this.util.handleIsNotSuccess([res.errors.join(',')]);
      }
    },
      error => {
        console.log(error);
        this.util.handleIsNotSuccess(['Error getting the download url']);
      });
  }

  private quickDownloadFromUrl(url: string, fileName: string) {
    this.getFile(url).subscribe((event: any) => {

      if (event.type === HttpEventType.DownloadProgress) {
      }
      else if (event.type === HttpEventType.Response) {

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(event.body);
        a.download = fileName;
        a.click();

        this.alertService.showMessage("SUCCESS", "File Downloaded", MessageSeverity.success);
      }
    },
      error => {
        console.log(error);
        this.util.handleIsNotSuccess(['Error downloading the file']);
      });
  }

  /* Quick Aspera download */
  public initiateQuickAsperaDownload(storageKey: string) {
    this.spotService.getDownloadSpec(storageKey).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var transferSpecArray = JSON.parse(res.result);

        if (transferSpecArray.transfer_specs[0].transfer_spec == null) {

          var error = transferSpecArray.transfer_specs[0].error;

          if (error != null)
            this.alertService.logError(error);
          else
            this.alertService.logError("transfer_spec is null and no error tag found");

          this.util.handleIsNotSuccess(["Unable to initiate the Aspera Connect. Please try again later."]);
        }
        else
          this.initiateAsperaConnect(res.result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private initiateAsperaConnect(spec: any) {
    var id = Math.floor((Math.random() * 10000) + 1).toString();

    var asperaWeb = new AW4.Connect({
      sdkLocation: environment.asperaConnectPath,
      minVersion: this.config.asperaMinVersion,
      id: "aspera_web_transfers-" + id
    });

    var asperaInstaller = new AW4.ConnectInstaller({
      sdkLocation: environment.asperaConnectPath
    });

    var callback = this.handleDownloadCallBack;
    var alertService = this.alertService;
    var severity = MessageSeverity;

    var statusEventListener = function (eventType, data) {
      var status = AW4.Connect.STATUS;
      if (eventType === AW4.Connect.EVENT.STATUS) {
        if (data === status.INITIALIZING) {
          asperaInstaller.showLaunching();
        }
        if (data === status.FAILED) {
          asperaInstaller.showDownload();
        }
        if (data === status.OUTDATED) {
          asperaInstaller.showUpdate();
        }
        if (data === status.RUNNING) {
          asperaInstaller.connected();
          console.log("running");
          callback(spec, asperaWeb, id, alertService, severity);
        }
      }
    };

    asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);

    asperaWeb.initSession(id);
  }

  private handleDownloadCallBack(spec, asperaWeb, random, alertService, severity) {

    var fileControls: any = {};
    var uready = false;

    fileControls.handleTransferEvents = function (event, returnObj) {
      var obj = returnObj.transfers[0];
      if (!obj) {
        obj = {};
      }

      if (obj.status == 'initiating' || obj.previous_status == 'initiating') {
        uready = true;
      }
      if (uready) {

        if (obj.status == 'failed') {
          console.log("failed file");
          alertService.showMessage("DOWNLOAD ERROR", obj.error_desc, severity.error);
          uready = false;
        }

        else if (obj.status == 'completed') {
          uready = false;
          asperaWeb.removeEventListener('transfer');
          alertService.showMessage("SUCCESS", "File Downloaded", severity.success);
        }

        else {
          switch (event) {
            case 'transfer':

              break;
          }
        }
      }
    };
    asperaWeb.addEventListener('transfer', fileControls.handleTransferEvents);

    //Block Connect Dialog from appearing, since we are showing progress on web app
    var connectSettings = {
      "allow_dialogs": "no"
    };

    var transferSpecArray = JSON.parse(spec);
    var transferSpec = transferSpecArray.transfer_specs[0].transfer_spec;
    transferSpec.authentication = "token";
    asperaWeb.startTransfer(transferSpec, connectSettings);
    alertService.showMessage("SUCCESS", "File Download initiated", severity.success);
  }

  /* Media S3 download with Status */
  public initiateS3Download(spotFiles: Array<SpotFile>): boolean {

    this.registerDownloadMedia(spotFiles).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.setRegisteredDownload(res.result, spotFiles);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    return true;
  }

  private registerDownloadMedia(spotFiles: Array<SpotFile>): Observable<Object> {
    let request: any = { spotFiles: spotFiles.map(a => a.spotFileGuid), category: 'Master' };

    return this.http.post(this.apiendPoint.registerDownloadMediaEndPoint(), request);
  }

  private setRegisteredDownload(registry: any, spotFiles: Array<SpotFile>) {

    let newBatch: DownloadMediaStatus =
    {
      status: downloadStatusEnum.submitted,
      header: moment().format("MM/DD/YYYY hh:mm:ss A z").toString(),
      requestGuid: registry.requestGuid,
      dowloadStatus: [],
      show: true
    };

    var mediaFiles = registry.mediaFiles as Array<any>;

    mediaFiles.forEach(mf => {
      var sf = spotFiles.find(t => t.spotFileGuid.toLowerCase() == mf.spotFileGuid.toLowerCase());

      if (sf != null) {
        let batchFile = new DownloadMediaFileStatus();

        batchFile.title = sf.title;
        batchFile.adId = sf.adId;
        batchFile.fileName = sf.fileName;
        batchFile.fileNameWithExt = sf.fileName;
        batchFile.storageKey = sf.storageKey;
        batchFile.spotFileGuid = sf.spotFileGuid;
        batchFile.downloadMediaFileGuid = mf.downloadMediaFileGuid;
        batchFile.percentage = 0;
        batchFile.show = true;
        batchFile.status = downloadStatusEnum.submitted;

        newBatch.dowloadStatus.push(batchFile);
      }
    });

    this.util.downloadFileStatus.push(newBatch);

    this.getDownloadUrlAndDownload(newBatch);
  }

  private getDownloadUrlAndDownload(batch: DownloadMediaStatus) {
    batch.dowloadStatus.forEach(batchFile => {

      this.getDownloadUrl(batchFile).subscribe((res: any) => {
        if (res.isSuccess == true) {

          this.setFileStatus(batchFile, 0, '', downloadStatusEnum.downloading);

          this.downloadFromUrl(res.result, batchFile);
        }
        else {
          this.setFileStatus(batchFile, 0, res.errors.join(','), downloadStatusEnum.failed);
        }
      },
        error => {
          console.log(error);

          this.setFileStatus(batchFile, 0, 'error getting the download url', downloadStatusEnum.failed);
        });
    });
  }

  private getDownloadUrl(batchFile: DownloadMediaFileStatus): Observable<Object> {
    var request = { fileName: batchFile.fileName, storageKey: batchFile.storageKey, isMaster: true, isProxy: false, spotFileGuid: batchFile.spotFileGuid };

    return this.http.post(this.apiendPoint.downloadMediaUrlEndPoint(), request);
  }

  public downloadFromUrl(url: string, batchFile: DownloadMediaFileStatus, updateDownloadStatus: boolean = true) {
    try {
      console.log("Last");
      console.log(url);
      this.getFile(url).subscribe((event: any) => {
        console.log(event);
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.type);
          this.setFileStatus(batchFile, Math.round((100 * event.loaded) / event.total), '', downloadStatusEnum.downloading, updateDownloadStatus);
        }
        else if (event.type === HttpEventType.Response) {

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(event.body);
          a.download = batchFile.fileNameWithExt;
          a.click();
          console.log(event.type);
          this.setFileStatus(batchFile, 100, '', downloadStatusEnum.completed, updateDownloadStatus);
        }
      },
        error => {
          console.log(error);

          this.setFileStatus(batchFile, 0, 'error downloading the file', downloadStatusEnum.failed, updateDownloadStatus);
        });
      console.log("End");
    } catch (e) {
      console.log(e);
    }
    
  }

  private getFile(path: string) {
    return this.http.request(new HttpRequest(
      'GET',
      path,
      {
        reportProgress: true,
        responseType: 'blob'
      })).pipe(map(event => {
        return event;
      }));
  }

  private setFileStatus(mediaFile: DownloadMediaFileStatus, percentage: number, error: string, status: downloadStatusEnum, updateDownloadStatus: boolean = true) {
    console.log(status);
    switch (status) {
      case downloadStatusEnum.downloading:
        {
          mediaFile.status = downloadStatusEnum.downloading;
          mediaFile.percentage = percentage;

          break;
        }
      case downloadStatusEnum.completed:
        {
          mediaFile.status = downloadStatusEnum.completed;
          mediaFile.percentage = percentage;

          if (updateDownloadStatus)
            this.updateMediaDownloadStatus(mediaFile.downloadMediaFileGuid, '', true);

          break;
        }
      case downloadStatusEnum.failed:
        {
          mediaFile.status = downloadStatusEnum.failed;
          mediaFile.error = error;
          mediaFile.percentage = 0;

          if (updateDownloadStatus)
            this.updateMediaDownloadStatus(mediaFile.downloadMediaFileGuid, error, false);

          break;
        }
      default:
    }
  }

  private updateMediaDownloadStatus(downloadMediaFileGuid: string, error: string, isSuccess: boolean) {
    let request = { downloadMediaFileGuid: downloadMediaFileGuid, payload: error, isSuccess: isSuccess };

    this.http.post(this.apiendPoint.completeDownloadMediaFileEndPoint(), request).subscribe((res: any) => {
      if (res.isSuccess == true) {
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }


  // Distribution Download
  public initializeDistributionDownload(files: Array<MediaFileData>) {

    let newBatch: DownloadMediaStatus =
    {
      status: downloadStatusEnum.submitted,
      header: moment().format("MM/DD/YYYY hh:mm:ss A z").toString(),
      requestGuid: "",
      dowloadStatus: [],
      show: true
    };

    files.forEach(mf => {

      if (mf != null) {
        let batchFile = new DownloadMediaFileStatus();

        batchFile.title = mf.title + "_" + mf.spec;
        batchFile.adId = mf.adId;
        batchFile.fileName = mf.spec + "_" + mf.outputFileName;
        batchFile.fileNameWithExt = mf.spec + "_" + mf.outputFileName;
        batchFile.downloadMediaFileGuid = mf.downloadId;
        batchFile.percentage = 0;
        batchFile.show = true;
        batchFile.status = downloadStatusEnum.submitted;

        newBatch.dowloadStatus.push(batchFile);
      }
    });

    this.util.downloadDistributionFileStatus.push(newBatch);

    this.getDistributionDownloadUrlAndDownload(newBatch);

    return true;
  }

  private getDistributionDownloadUrlAndDownload(batch: DownloadMediaStatus) {
    batch.dowloadStatus.forEach(batchFile => {

      this.getDistributionMediaDownloadUrl(batchFile).subscribe((res: any) => {
        if (res.isSuccess == true) {

          this.setFileStatus(batchFile, 0, '', downloadStatusEnum.downloading, false);

          this.downloadFromUrl(res.result, batchFile, false);
        }
        else {
          this.setFileStatus(batchFile, 0, res.errors.join(','), downloadStatusEnum.failed, false);
        }
      },
        error => {
          console.log(error);

          this.setFileStatus(batchFile, 0, 'error getting the download url', downloadStatusEnum.failed, false);
        });
    });
  }

  private getDistributionMediaDownloadUrl(batchFile: DownloadMediaFileStatus): Observable<Object> {
    var request = { downloadMediaFileGuid: batchFile.downloadMediaFileGuid, spotFileGuid: "", title: batchFile.title, adId: batchFile.adId };
    return this.http.post(this.apiendPoint.getDistributionMediaDownloadUrlEndPoint(), request);
  }

  public initiateS3TaggerAdFileDownload(assetFiles: Array<TranscodeAssetFile>, adGuid: string): boolean {

    this.registerTaggerAdFileDownloadMedia(assetFiles, adGuid).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.setAdFileRegisteredDownload(res.result, assetFiles);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    return true;
  }

  private registerTaggerAdFileDownloadMedia(assetFiles: Array<TranscodeAssetFile>, adGuid: string): Observable<Object> {
    let request: any = { adFiles: assetFiles.map(a => a.assetFileGuid), category: 'TaggerAd', adGuid: adGuid };

    return this.http.post(this.apiendPoint.registerAdDownloadMediaEndPoint(), request);
  }

  private setAdFileRegisteredDownload(registry: any, assetFiles: Array<TranscodeAssetFile>) {

    let newBatch: DownloadMediaStatus =
    {
      status: downloadStatusEnum.submitted,
      header: moment().format("MM/DD/YYYY hh:mm:ss A z").toString(),
      requestGuid: registry.requestGuid,
      dowloadStatus: [],
      show: true
    };

    var af = assetFiles.find(t => t.assetFileGuid.toLowerCase() == registry.adfileGuid.toLowerCase());

    let batchFile = new DownloadMediaFileStatus();

    batchFile.title = '';
    batchFile.adId = '';
    batchFile.fileNameWithExt = af.fileName + '.' + af.fileExtension;
    batchFile.fileName = af.fileName;
    batchFile.storageKey = af.filePath + "." + af.fileExtension;
    batchFile.assetFileGuid = af.assetFileGuid;
    batchFile.downloadMediaFileGuid = registry.mediaFile;
    batchFile.percentage = 0;
    batchFile.show = true;
    batchFile.status = downloadStatusEnum.submitted;

    newBatch.dowloadStatus.push(batchFile);
    this.util.downloadFileStatus.push(newBatch);
    console.log(newBatch);
    this.getAdFileDownloadUrlAndDownload(newBatch);
  }

  private getAdFileDownloadUrlAndDownload(batch: DownloadMediaStatus) {
    batch.dowloadStatus.forEach(batchFile => {

      this.getTranscodeFileDownloadUrl(batchFile).subscribe((res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          this.setAdFileStatus(batchFile, 0, '', downloadStatusEnum.downloading);

          this.downloadAdFileFromUrl(res.result, batchFile);
        }
        else {
          this.setAdFileStatus(batchFile, 0, res.errors.join(','), downloadStatusEnum.failed);
        }
      },
        error => {
          console.log(error);
          this.setAdFileStatus(batchFile, 0, 'error getting the download url', downloadStatusEnum.failed);
        });
    });
  }

  private setAdFileStatus(mediaFile: DownloadMediaFileStatus, percentage: number, error: string, status: downloadStatusEnum, updateDownloadStatus: boolean = true) {
    console.log(status);
    switch (status) {
      case downloadStatusEnum.downloading:
        {
          mediaFile.status = downloadStatusEnum.downloading;
          mediaFile.percentage = percentage;

          break;
        }
      case downloadStatusEnum.completed:
        {
          mediaFile.status = downloadStatusEnum.completed;
          mediaFile.percentage = percentage;

          if (updateDownloadStatus)
            this.updateAdMediaDownloadStatus(mediaFile.downloadMediaFileGuid, '', true);

          break;
        }
      case downloadStatusEnum.failed:
        {
          mediaFile.status = downloadStatusEnum.failed;
          mediaFile.error = error;
          mediaFile.percentage = 0;

          if (updateDownloadStatus)
            this.updateAdMediaDownloadStatus(mediaFile.downloadMediaFileGuid, error, false);

          break;
        }
      default:
    }
  }

  public downloadAdFileFromUrl(url: string, batchFile: DownloadMediaFileStatus, updateDownloadStatus: boolean = true) {
    try {
      console.log("Last");
      console.log(url);
      this.getFile(url).subscribe((event: any) => {
        console.log(event);
        if (event.type === HttpEventType.DownloadProgress) {
          console.log(event.type);
          this.setAdFileStatus(batchFile, Math.round((100 * event.loaded) / event.total), '', downloadStatusEnum.downloading, updateDownloadStatus);
        }
        else if (event.type === HttpEventType.Response) {

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(event.body);
          a.download = batchFile.fileNameWithExt;
          a.click();
          console.log(event.type);
          this.setAdFileStatus(batchFile, 100, '', downloadStatusEnum.completed, updateDownloadStatus);
        }
      },
        error => {
          console.log(error);

          this.setAdFileStatus(batchFile, 0, 'error downloading the file', downloadStatusEnum.failed, updateDownloadStatus);
        });
      console.log("End");
    } catch (e) {
      console.log(e);
    }

  }

  private updateAdMediaDownloadStatus(downloadMediaFileGuid: string, error: string, isSuccess: boolean) {
    let request = { downloadMediaFileGuid: downloadMediaFileGuid, payload: error, isSuccess: isSuccess };

    this.http.post(this.apiendPoint.completeDownloadAdMediaFileEndPoint(), request).subscribe((res: any) => {
      if (res.isSuccess == true) {
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }


  /* TranscodeFile S3 download with Status */
  public initiateS3TranscodeFileDownload(assetFiles: Array<TranscodeAssetFile>): boolean {

    this.registerTranscodeFileDownloadMedia(assetFiles).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.setTranscodeFileRegisteredDownload(res.result, assetFiles);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    return true;
  }

  private registerTranscodeFileDownloadMedia(assetFiles: Array<TranscodeAssetFile>): Observable<Object> {
    let request: any = { transcodeAssetFiles: assetFiles.map(a => a.assetFileGuid), category: 'TranscodeAssetFile' };

    return this.http.post(this.apiendPoint.registerDownloadMediaEndPoint(), request);
  }

  private setTranscodeFileRegisteredDownload(registry: any, assetFiles: Array<TranscodeAssetFile>) {

    let newBatch: DownloadMediaStatus =
    {
      status: downloadStatusEnum.submitted,
      header: moment().format("MM/DD/YYYY hh:mm:ss A z").toString(),
      requestGuid: registry.requestGuid,
      dowloadStatus: [],
      show: true
    };

    var mediaFiles = registry.mediaFiles as Array<any>;

    mediaFiles.forEach(mf => {
      var af = assetFiles.find(t => t.assetFileGuid.toLowerCase() == mf.transcodeAssetfileGuid.toLowerCase());

      if (af != null) {
        let batchFile = new DownloadMediaFileStatus();

        batchFile.title = '';
        batchFile.adId = '';
        batchFile.fileNameWithExt = af.fileName + af.fileExtension;
        batchFile.fileName = af.fileName;
        batchFile.storageKey = af.filePath;
        batchFile.assetFileGuid = af.assetFileGuid;
        batchFile.downloadMediaFileGuid = mf.downloadMediaFileGuid;
        batchFile.percentage = 0;
        batchFile.show = true;
        batchFile.status = downloadStatusEnum.submitted;

        newBatch.dowloadStatus.push(batchFile);
      }
    });

    this.util.downloadFileStatus.push(newBatch);

    this.getTranscodeFileDownloadUrlAndDownload(newBatch);
  }

  private getTranscodeFileDownloadUrlAndDownload(batch: DownloadMediaStatus) {
    batch.dowloadStatus.forEach(batchFile => {

      this.getTranscodeFileDownloadUrl(batchFile).subscribe((res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          this.setFileStatus(batchFile, 0, '', downloadStatusEnum.downloading);

          this.downloadFromUrl(res.result, batchFile);
        }
        else {
          this.setFileStatus(batchFile, 0, res.errors.join(','), downloadStatusEnum.failed);
        }
      },
        error => {
          console.log(error);

          this.setFileStatus(batchFile, 0, 'error getting the download url', downloadStatusEnum.failed);
        });
    });
  }

  private getTranscodeFileDownloadUrl(batchFile: DownloadMediaFileStatus): Observable<Object> {
    var request = { fileName: batchFile.fileName, storageKey: batchFile.storageKey, isTranscodeAssetFile: true, isMaster: false, isProxy: false };

    return this.http.post(this.apiendPoint.downloadMediaUrlEndPoint(), request);
  }
}
