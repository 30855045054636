
<section class="tylie-modal">
  <div class="modal-header">
    <button type="button" class="modal-close" data-dismiss="modal" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      {{message}}
    </div>
    <div class="modal-actions">
      <button class="tylie-button tylie-button--xs me-2" type="button" (click)="onYes()">Leave</button>
      <button class="tylie-button tylie-button--xs" type="button" (click)="onNo()">Stay</button>
    </div>
  </div>
</section>

