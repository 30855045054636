import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'unsaveddata-popup',
  templateUrl: './unsaveddatapopup.component.html',
  styleUrls: ['./unsaveddatapopup.component.css'],
})
export class UnSavedDataWarningComponent extends BasePopupComponent {

  public subject: Subject<boolean>;
  public message: string;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.subject = new Subject();
  }

  public onYes() {
    this.subject.next(true);
    this.subject.complete();
    this.bsModalRef.hide();
  }

  public onNo() {
    this.subject.next(false);
    this.subject.complete();
    this.bsModalRef.hide();
  }

  public closePopup() {
    this.subject.next(false);
    this.subject.complete();
    this.bsModalRef.hide();
  }
}
