<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header media-text">
      <h3 class="media-text">Distribution Assets</h3>
    </div>
    
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <li><a (click)="util.scrollTo('search-all-media-section')">Search All Distribution Assets</a></li>
      </ul>
    </div>
  </div>
</nav>

<div class="inner-content">

  <!--<div *isAuthorized="[this.userRoles.getMediaDistributionViewer()]">
    <div class="upload-section" id="upload-section">
      <button type="button" class="btn-bs-file btn btn-lg btn-danger" (click)="gotoTMCDownload()">
        <span class="tylicon tylicon-pluse" aria-hidden="true"></span>
        <span class="upload-media-text">DOWNLOAD TMC</span>
      </button>
    </div>
  </div>-->


  <div *isAuthorized="[this.userRoles.getMediaDistributionViewer()]">
    <distro-media-search></distro-media-search>
  </div>
</div>
