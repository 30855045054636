<div class="tylie-accordion accordion" id="search-all-orders-section">
  <div class="tylie-accordion__panel accordion-item" id="orderSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#regReqSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        TMC Pre-Register Requests
      </button>
    </h2>
    <div id="regReqSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content pb-3">
          <dx-data-grid id="registrationRequests" class="tylie-grid"
                        [dataSource]="registrationRequests"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="registrationrequestid"
                        [rowAlternationEnabled]="true"
                        [showBorders]="true">
            <dxi-column dataField="email" caption="Email" cssClass="gridStyle" width="20%"></dxi-column>
            <dxi-column dataField="partnerdestination" caption="Partner Destination" cssClass="gridStyle" width="20%"></dxi-column>
            <dxi-column dataField="destination" caption="Destination" cssClass="gridStyle" width="20%"></dxi-column>
            <dxi-column dataField="approvalstatus" caption="Status" cssClass="gridStyle" width="20%"></dxi-column>
            <dxi-column dataField="createddate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle" width="15%"></dxi-column>
            <dxi-column dataField="preregistrationid" cellTemplate="actionTemplate" caption="Actions" [allowSorting]="false" cssClass="gridStyle tyl-text-align-left" width="25%"></dxi-column>
            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
            </div>
            <div *dxTemplate="let t of 'actionTemplate'">
              <div style="text-align:left;">
                <a class="tyl-blue-text p-extra-small" (click)="onShowStatusConfirm(t.data.registrationrequestid, true, statusConfirm)">APPROVE</a>&nbsp;&nbsp;
                <a class="tyl-blue-text p-extra-small" (click)="onShowStatusConfirm(t.data.registrationrequestid, false, statusConfirm)">DENY</a>&nbsp;&nbsp;
                <a class="tyl-blue-text p-extra-small" (click)="onMapDestination(t.data.registrationrequestid)">MAP</a>
              </div>
            </div>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 20, 50, 100]"
                        infoText="Page {0} of {1} ({2} items)"
                        [showNavigationButtons]="true"
                        [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
     
    </div>
  </div>
</div>

<ng-template #statusConfirm>
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Are you sure ?</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="approvalRequest" #fs="ngForm" (ngSubmit)="onFormSubmit(approvalRequest)">
      <label class="label label-success" [ngClass]="{'label-success': approvalRequest.controls['isApproved'].value == true, 'label-danger': approvalRequest.controls['isApproved'].value == false}">
        {{approvalRequest.controls['isApproved'].value ? 'APPROVED' : 'DENIED'}}
      </label>
      <div id="commentDiv" class="tylie-form-group form-group" [ngClass]="{error: approvalRequest.controls['comments'].errors && fs.submitted}">
        <label class="tylie-form-group__lbl" for="notes">Notes<span class="required">*</span></label>
        <textarea formControlName="comments" name="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
        <p class="p-extra-small">Please enter notes</p>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs me-3" type="submit">Yes</button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="modalClose()">No</button>
      </div>
    </form>
  </div>
</ng-template>
