import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from '../../../services/core/utilities';
import { SpotService } from '../../../services/media/spot.service';
import { TaggedAdResponseModel } from '../../../models/tagger/tagger.model';

@Component({
  selector: 'app-view-tagger-order-adpopup',
  templateUrl: './view-tagger-order-adpopup.component.html',
  styleUrls: ['./view-tagger-order-adpopup.component.css']
})
export class ViewTaggerOrderAdpopupComponent implements OnInit {
  @Input('videoSource') videoSource: string;
  @Input('thumbnail') thumbnail: string;
  @Input('modalTitle') modalTitle: string;
  @Input('title') title: string;
  @Input('id') id: string;

  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;
  public overyLayHeight: number = 0;
  public proxyUrl: string = null;

  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;

  constructor(
    public bsModalRef: BsModalRef,
    public util: Utilities,
    public spotService: SpotService
  ) { }

  ngOnInit() {
    this.getProxyUrl();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public toggleOverlay(overlayType: string) {
    if (overlayType == '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay
    }
    else {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay
    }

    this.overyLayHeight = this.videoPlayer.nativeElement.offsetHeight;
  }

  public onPlayerMouseOver(e) {
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;
  }

  private getProxyUrl() {

    this.spotService.getProxyUrl({ objectKey: this.videoSource }).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.proxyUrl = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.closePopup();
      }

    },
      error => {
        this.util.handleError(error);
        this.closePopup();
      });
  }

}
