<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Edit Order</h4>
</div>
<div class="modal-body">
  <form [formGroup]="editRequest" #f="ngForm" (ngSubmit)="onSave(editRequest)">

    <div class="tylie-form-group form-group" [ngClass]="{error: (editRequest.controls['newValue'].errors || editRequest.controls['newValue'].hasError('inValidEmailList') || editRequest.hasError('airdatesInvalid')) && f.submitted}">
      <div class="d-flex justify-content-between">
        <label class="tylie-form-group__lbl" for="notes" *ngIf="controlType !== 'airdate' && controlType !== 'checkbox' && controlType !== 'servicelevel'">{{label}}<span class="required">*</span></label>

        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddMorePODEmails()" *ngIf="controlType == 'podemails'">
            Add from List
        </button>
      </div>
      <dx-date-box formControlName="newValue" type="date" class="tylie-datepicker form-control" name="datectrl" *ngIf="controlType == 'date'" [showClearButton]="true"></dx-date-box>

      <input formControlName="newValue" type="text" class="tylie-text form-control" name="textctrl" *ngIf="controlType == 'text'" [placeholder]="placeholder">

      <textarea formControlName="newValue" class="tylie-textarea tylie-textarea--lg form-control" name="textareactrl" *ngIf="controlType == 'textarea'"></textarea>

      <textarea formControlName="newValue" class="tylie-textarea tylie-textarea--lg form-control" name="textareactrl" placeholder="semi-colon seperated" *ngIf="controlType == 'podemails'"></textarea>

      <dx-select-box [items]="itemsDataSource" *ngIf="controlType == 'dropdown'"
                     class="tylie-select form-control"
                     name="selectctrl"
                     [showClearButton]="true"
                     [acceptCustomValue]="canAddCustom"
                     [placeholder]="canAddCustom ? 'Select or Add new' : 'Select...'"
                     formControlName="newValue"
                     [displayExpr]="displayText"
                     [valueExpr]="selectedValue"
                     noDataText="No data available">
      </dx-select-box>

      <div class="reslate new-order-check" *ngIf="controlType == 'checkbox'">
        <label class="checkbox-inline">
          <input formControlName="newValue" type="checkbox" name="checkboxctrl"><span>{{label}}</span>
        </label>
      </div>

      <div class="row" *ngIf="controlType === 'airdate'">
        <div class="col-md-6">
          <label for="notes">Air Date Start<span class="required">*</span></label>
          <dx-date-box formControlName="airDateStart" type="date"
                       displayFormat="MM/dd/yyyy"
                       name="airstartdatectrl" class="form-control" [showClearButton]="true"></dx-date-box>
        </div>
        <div class="col-md-6">
          <label for="notes">Air Date End<span class="required">*</span></label>
          <dx-date-box formControlName="airDateEnd" type="date" name="airenddatectrl"
                       displayFormat="MM/dd/yyyy"
                       class="form-control" [showClearButton]="true"></dx-date-box>
        </div>
      </div>

      <div class="form-group popup-service-level servicelevel" *ngIf="controlType == 'servicelevel'">
        <label class="radio-inline servicelevel-label p-normal">Service Level:</label>
        <label class="radio-inline">
          <input type="radio" formControlName="newValue" value="false"><span>Standard</span>
        </label>
        <label class="radio-inline">
          <input type="radio" formControlName="newValue" value="true" class="rush-radio">
          <span *ngIf="editRequest.controls['newValue'].value === 'true'">
            <span class="rush-alert-svg"></span>
            <span class="rush-text group-error-message">Rush</span>
          </span>
          <span *ngIf="editRequest.controls['newValue'].value !== 'true'">Rush</span>
        </label>
      </div>

      <p class="p-extra-small" *ngIf="editRequest.controls['newValue'].errors?.required || editRequest.controls['newValue'].hasError('inValidEmailList') || editRequest.controls['newValue'].hasError('inValidFilePath')">
        {{error}}
      </p>
      <p class="p-extra-small" *ngIf="editRequest.controls['newValue'].errors?.maxlength">{{maxLengthError}}</p>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>

  </form>
</div>
