import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-notification-bell',
  templateUrl: './icon-notification-bell.component.html',
  styleUrls: ['./icon-notification-bell.component.css']
})
export class IconNotificationBellComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#fff';
  constructor() { }

  ngOnInit() {
  }

}
