import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { SpotUploadRequest } from '../../models/upload/upload-request.model';

@Injectable()

export class PromoteProjectFileService {

    constructor(private http: HttpClient,
        private apiendPoint: ApiEndPoint) { }

    promoteProjectFile(uploadRequest: SpotUploadRequest, fileCollectionIds: string[]) {
        let headers = new HttpHeaders({
            'fileCollectionId': fileCollectionIds
        });
        let options = { headers: headers };
        let requests: Array<SpotUploadRequest> = [];
        requests.push(uploadRequest);

        return this.http.post(this.apiendPoint.promoteProjectFile(), requests, options);
    }
}