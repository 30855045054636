<div id="transcode-pop-up">

  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">NEW TRANSCODE ORDER LIST</h4>
  </div>
  <div class="modal-body">
    <div class="tylie-form-group form-group" [ngClass]="{error:(triedToSubmit && transListName == '')}">
      <label for="listname" class="tylie-form-group__lbl">Transcode Order List Name</label>
      <input type="text" placeholder="Give your List a name" class="tylie-text form-control" [(ngModel)]="transListName" />
      <p class="p-extra-small">Please enter a List name</p>
    </div>

   

    <div class="mb-3">
      <h3 class="tylie-type-h3">
        OLV Profiles Currently in List
      </h3>
      <div class="tylie-type-body" *ngIf="selectedTranscodes.length != 0">
        {{selectedTranscodes.join(", ")}}
      </div>
      <div class="tylie-type-body" *ngIf="selectedTranscodes.length == 0">
        Nothing selected yet. Please use checkboxes below.
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h3 class="tylie-type-h3 mt-2">
          Select OLV Profiles for your Order list
        </h3>
      </div>
      <div class="col-md-6">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By OLV Profile or Spec" name="srch-term" id="srch-term" type="search" (keyup.enter)="onQuickSearch()"
                 [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearch()">Search</button>
          </div>
        </section>
      </div>
    </div>

    <div class="tylie-grid-wrapper">
      <dx-data-grid class="tylie-grid tylie-grid--bordered" id="popuptranscodeSpecsGrid"
                    #popuptranscodeSpecsGrid
                    [dataSource]="transcodeSpecsSource"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [rowAlternationEnabled]="true"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showRowLines]="true"
                    (onContentReady)="onContentReady($event)"
                    (onSelectionChanged)="onSelectionChanged($event)"
                    keyExpr="id">
        <dxo-selection mode="multiple"
                       [allowSelectAll]="false"
                       showCheckBoxesMode="always">
        </dxo-selection>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxi-column width="30%" caption="OLV Profiles" dataField="name" cssClass="gridStyle" sortOrder="asc"></dxi-column>
        <dxi-column width="70%" caption="Transcode Specs" dataField="description" [allowSorting]="false" cssClass="gridStyle"></dxi-column>
      </dx-data-grid>
    </div>

    <div class="d-flex justify-content-between">
      <p class="tylie-type-body tylie-type-body--sm">Don't see the Spec you're looking for? <a href="mailto:support@tylie.com">Let Us Know.</a></p>
      
      <button class="tylie-button tylie-button--xs" type="button" (click)="onSaveList()">Save transcode order list</button>
    </div>
  </div>
</div>
