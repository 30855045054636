<div id="customtagconfiggroup">
  <div [formGroup]="ctcGroup" class="tyl-border-top tyl-border-bottom">
    <div class="row">
      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (ctcGroup.controls['tag'].errors && (!ctcGroup.controls['tag'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">Tag<span class="required">*</span></label>
          <input type="text" id="tag" name="tag" class="tylie-text form-control" formControlName="tag">
          <p class="p-extra-small" *ngIf="ctcGroup.controls['tag'].errors?.required || ctcGroup.controls['tag'].errors?.whitespace">Please enter Tag</p>
          <p class="p-extra-small" *ngIf="ctcGroup.controls['tag'].errors?.notUnique">Tag already exists</p>
        </div>
      </div>

      <div class="col-md-6" id="dataType">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (ctcGroup.controls['dataType'].errors && (!ctcGroup.controls['dataType'].pristine || parentForm.submitted))}">
          <label for="dataType" class="tylie-form-group__lbl">Data Type<span class="required">*</span></label>
          <dx-select-box [dataSource]="customTagDataTypes"
                         name="dataType" id="dataType" class="tylie-select form-control"
                         formControlName="dataType"
                         [searchEnabled]="true"
                         valueExpr="id"
                         displayExpr="name"
                         placeholder="DataType"
                         [disabled]="ctcGroup.controls['id'].value == 0 ? false: true"
                         (onValueChanged)="onCustomTagDataTypeChange($event)">
          </dx-select-box>
          <p class="p-extra-small">Please select Data Type</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" id="description">
        <div class="tylie-form-group form-group">
          <label for="description" class="tylie-form-group__lbl">Description</label>
          <textarea id="description" name="description" class="tylie-textarea tylie-textarea--md form-control" formControlName="description"></textarea>
        </div>
      </div>

      <div class="col-md-6" id="config" *ngIf="isDataTypeConfigRequired == true && ctcGroup.controls['id'].value == 0">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (ctcGroup.controls['config'].errors && (!ctcGroup.controls['config'].pristine || parentForm.submitted))}">
          <label for="config" class="tylie-form-group__lbl">
            Configuration (comma separated)
            <button type="button" class="tylie-button tylie-button--link tylie-button--xs"
                  triggers="mouseenter:mouseleave" #approverspop="bs-popover"
                  [popoverContext]="toolTipDetail" [popover]="approversList">
                  <icon-question-mark color="#1e90ff"></icon-question-mark>
            </button>
            <span class="required">*</span>
          </label>
          <textarea id="config" name="config" class="tylie-textarea tylie-textarea--md form-control" formControlName="config" placeholder="Option1,Option2,..."></textarea>
          <p class="p-extra-small">Please enter Configuration</p>
        </div>
      </div>

      <div class="col-md-6" id="config" *ngIf="isDataTypeConfigRequired == true && ctcGroup.controls['id'].value > 0">
        <div class="tylie-form-group form-group">
          <label for="config" class="tylie-form-group__lbl">Configuration</label>
          <textarea id="config" name="config" class="tylie-textarea tylie-textarea--md form-control" disabled>{{ctcGroup.controls['config'].value}}</textarea>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link" type="button" (click)="onClickRemoveCustomTag()">
        <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>
        REMOVE CUSTOM TAG
      </button>
    </div>
  </div>

  <ng-template #approversList let-approvers="approvers">
    <span class="form-group p-normal">Here is an example!</span>

    <div class='p-normal' *ngIf="toolTipDetail.type != 'Drop Down'">
      <div *ngIf="toolTipDetail.type == 'Check Boxes'">
        <ng-container *ngFor="let option of toolTipDetail.options">
          <input name="{{option}}"
                 type="checkbox"
                 value="{{option}}">
          <span>{{option}}</span>
        </ng-container>
      </div>

      <div *ngIf="toolTipDetail.type == 'Radio'">
        <ng-container *ngFor="let option of toolTipDetail.options">
          <input type="radio"
                 name="{{option}}"
                 value="{{option}}">
          <span>{{option}}</span>
        </ng-container>
      </div>
    </div>
    <div class="p-normal popover-height" *ngIf="toolTipDetail.type == 'Drop Down'">
      <dx-select-box name="dropdown"
                     [items]="toolTipDetail.options"
                     [acceptCustomValue]="false"
                     [deferRendering]="true"
                     (onOpened)="onOpened($event)"
                     noDataText="No data available"
                     [opened]="true">
      </dx-select-box>
    </div>
  </ng-template>
</div>

