import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { ConfigService } from "../../../../services/core/config.service";
import { AlertService } from "../../../../services/core/alert.service";
import { Subscription } from 'rxjs';
import { UserProfileDetail } from "../../../../models/user/user-profile-detail";
import { Constants } from "../../../../configurations/constants";

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  @Input('userProfileGuid') userProfileGuid: string;
  //public userProfileGuid: string = null;
  public editMode: boolean = false;
  private subscription: Subscription;
  public userProfileDetail: UserProfileDetail = null;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe((params: Params) => {
      //this.userProfileGuid = params['userProfileGuid'];
      this.getUserProfileView(this.userProfileGuid);
    });
  }

  public onEditUserEventTriggered(e: any) {
    this.editMode = true;
  }

  public onEditCompleteOrCancelEventTriggered(userProfileDetail: UserProfileDetail) {
    this.editMode = false;
    this.userProfileDetail = userProfileDetail;
    //this.userProfileDetail.avatar = this.util.notEmpty(userProfileDetail.avatar) ? Constants.avatarEncoding + userProfileDetail.avatar : null;
  }

  private getUserProfileView(userProfileGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getUserProfileView(userProfileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.userProfileDetail = res.result as UserProfileDetail;
        this.userProfileDetail.avatar = this.util.notEmpty(this.userProfileDetail.avatar) ? Constants.avatarEncoding + this.userProfileDetail.avatar : null;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }
}
