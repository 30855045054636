import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { OrderDestination } from '../../../models/order/order-destinations.model';
import { Subject } from 'rxjs';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { SearchUserDestinationRequest } from '../../../models/order/order-searchuserdestination-request.model';
import { Constants } from '../../../configurations/constants';
import { OrderUserDestination } from '../../../models/order/order-userdestination';
import { DestinationSuggest } from '../../../models/order/order-destinationsuggest.model';
import { DestinationSearch } from "../../../models/admin/destination/destinationsearch.model";
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderadddestinationlistpopup.component.ts',
  templateUrl: './orderadddestinationlistpopup.component.html',
  styleUrls: ['./orderadddestinationlistpopup.component.css'],
})
export class OrderAddDestinationListPopupComponent extends BasePopupComponent {

  @Input() isEdit: boolean;

  public dataSource: any = {};
  public dataSourceSavedList: Array<OrderUserDestination> = null;
  public quickSearchValue: string = '';
  public selectionMethod: string = "";
  public selectionMethodNext: string = "";
  public pastedDestinations: string = "";
  public metadataExcelFile: File;
  public copyUploadResultMessage: string = '';
  public isUploading: boolean = false;
  public onClose: Subject<SelectedOrderDestinations>;
  public copyUploadSuccessDestinations: Array<OrderDestination> = null;
  public copyUploadErrorDestinations: Array<DestinationSuggest> = null;
  public copyUploadErrorsExist: boolean = false;
  public title: string = '';
  public addMoreInEditSavedDestinationList: boolean = false;
  public savedDestinationListToEdit: OrderUserDestination = null;
  public nameEditInEditSavedDestinationList: boolean = false;
  public destinationsToAddToSavedList: Array<string> = null;
  public searchDestinationCriteriaObj = <DestinationSearch>{};
  public applyToAllGroups: boolean = false;

  @ViewChild('destinationsGrid', { static: false }) destinationsGrid: DxDataGridComponent;
  @ViewChild('destinationsSavedListGrid', { static: false }) destinationsSavedListGrid: DxDataGridComponent;
  @ViewChild('destinationErrorsGrid', { static: false }) destinationErrorsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onClose = new Subject();
    this.searchDestinations();
    this.selectionMethod = "list";
    this.title = "ADD DESTINATIONS - DESTINATIONS LIST"
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {
      if (this.selectionMethod == 'list') {
        this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
        this.searchDestinations();
      }
      else if (this.selectionMethod == 'savedlist')
        this.searchSavedDestinationLists();
    }
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      if (this.selectionMethod == 'list' || this.selectionMethod == 'editsavedlist') {
        this.searchDestinationCriteriaObj.quickSearch = this.quickSearchValue;
        this.searchDestinations();
      }
      else if (this.selectionMethod == 'savedlist')
        this.searchSavedDestinationLists();
    }
  }

  public onApplyToOrder() {

    if (this.selectionMethod === "list") {
      this.saveDestinationsFromList();
    }
    else if (this.selectionMethod === "savedlist") {
      this.saveDestinationsFromSavedList();
    }
    else if (this.selectionMethod === "copyuploadresult") {
      this.saveDestinationsFromCopyUpload();
    }
  }

  public onErrorGridCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.command === "expand") {
      if (e.data != null) {
        if (e.data.destinations == null || e.data.destinations.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }

    if (e.rowType === "data" && e.column.command === 'select') {
      var instance = e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance");
      instance.option("disabled", true);
      e.cellElement.off();
    }
  }

  //#region Copy or Upload

  public onCopyPasteOptionSelected() {
    this.selectionMethod = 'copyupload';
    this.title = "ADD DESTINATIONS - COPY OR UPLOAD"
  }

  public onExcelUploadFileChange(event) {
    this.metadataExcelFile = event.target.files[0];

    this.saveDestinationsFromExcel();
  }

  private saveDestinationsFromExcel() {
    if (this.metadataExcelFile == null)
      return;

    var reader = new FileReader();

    reader.readAsDataURL(this.metadataExcelFile);

    reader.onload = () => {
      var request: any = {
        metadataFileType: this.metadataExcelFile.type,
        metadataFileName: this.metadataExcelFile.name,
        metadataFile: (<string>reader.result).split(',')[1],
      };

      this.alertService.ShowLoader(true)

      this.configService.validateDestinationsFromFile(request).subscribe((res: any) => {

        this.selectionMethod = 'copyuploadresult';

        if (res.isSuccess == true) {

          this.copyUploadSuccessDestinations = res.result.destinations as Array<OrderDestination>;
          this.copyUploadErrorDestinations = res.result.errorDestinations as Array<any>;

          if (this.copyUploadErrorDestinations != null && this.copyUploadErrorDestinations.length > 0) {
            this.copyUploadResultMessage = this.copyUploadErrorDestinations.length.toString() + " out of " + (this.copyUploadSuccessDestinations.length + this.copyUploadErrorDestinations.length).toString() + " destinations not found";
            this.copyUploadErrorsExist = true;
          }
          else {
            this.copyUploadResultMessage = this.copyUploadSuccessDestinations.length.toString() + " destination(s) found successfully";
            this.copyUploadErrorsExist = false;
          }
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.isUploading = false;
        this.alertService.ShowLoader(false);
      },
        error => {
          this.alertService.ShowLoader(false);
          this.isUploading = false;
          this.util.handleError(error);
          this.closePopup();
        });
    }
  }

  private saveDestinationsFromPastedText() {

    if (this.pastedDestinations === null || this.pastedDestinations.trim() === '')
      return;

    var request: any = { destinationsText: this.pastedDestinations };

    this.alertService.ShowLoader(true)

    this.configService.validateDestinationsFromText(request).subscribe((res: any) => {

      this.selectionMethod = 'copyuploadresult';

      if (res.isSuccess == true) {

        this.copyUploadSuccessDestinations = res.result.destinations as Array<OrderDestination>;
        this.copyUploadErrorDestinations = res.result.errorDestinations as Array<any>;

        if (this.copyUploadErrorDestinations != null && this.copyUploadErrorDestinations.length > 0) {
          this.copyUploadResultMessage = "Error parsing " + this.copyUploadErrorDestinations.length.toString() + " out of " + (this.copyUploadSuccessDestinations.length + this.copyUploadErrorDestinations.length).toString() + " destinations";
          this.copyUploadErrorsExist = true;
        }
        else {
          this.copyUploadResultMessage = "Successfully parsed " + this.copyUploadSuccessDestinations.length.toString() + " destinations";
          this.copyUploadErrorsExist = false;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        this.closePopup();
        this.util.handleError(error);
      });
  }

  public onAddDestinationsAfterUploadComplete() {
    if (this.selectionMethod === "copyupload") {
      this.saveDestinationsFromPastedText();
    }
  }

  public onDestinationSuggest(searchWord: string, pickedDestinationCode: string) {
    var suggestDestination = this.copyUploadErrorDestinations.find(t => t.code.toLowerCase() == searchWord.toLowerCase());
    if (suggestDestination != null) {
      var pickedDestination = suggestDestination.destinations.find(t => t.code.toLowerCase() == pickedDestinationCode.toLowerCase());
      if (pickedDestination != null) {
        suggestDestination.selectedDestination = pickedDestination;

        this.applyCopyUploadErrorCount();
      }
    }
  }

  private saveDestinationsFromCopyUpload() {

    if (this.copyUploadErrorDestinations.some(t => t.selectedDestination == null) || this.copyUploadErrorsExist == true)
      return;

    var destinationsToAdd = this.copyUploadSuccessDestinations;
    this.copyUploadErrorDestinations.forEach(t => {
      if (destinationsToAdd.filter(s => t.code.toLowerCase() == t.selectedDestination.code.toLowerCase() == null))
        destinationsToAdd.push(t.selectedDestination);
    });

    var allSelectedDestiantions = new SelectedOrderDestinations();
    allSelectedDestiantions.applyToAllGroups = this.applyToAllGroups;
    allSelectedDestiantions.destinations = destinationsToAdd;

    this.onClose.next(allSelectedDestiantions);

    this.closePopup();
  }

  private applyCopyUploadErrorCount() {
    var errorDestinationsToBeSelected = this.copyUploadErrorDestinations.filter(t => t.selectedDestination == null);
    var errorDestinationsAlreadySelected = this.copyUploadErrorDestinations.filter(t => t.selectedDestination != null);

    if (this.copyUploadErrorDestinations.length == 0 && this.copyUploadSuccessDestinations.length == 0) {
      this.copyUploadResultMessage = "No Destinations";
      this.copyUploadErrorsExist = true;
    }
    else if (errorDestinationsToBeSelected.length > 0) {
      this.copyUploadResultMessage = "Error parsing " + errorDestinationsToBeSelected.length.toString() + " out of " + (this.copyUploadSuccessDestinations.length + errorDestinationsAlreadySelected.length + + errorDestinationsToBeSelected.length).toString() + " destinations";
      this.copyUploadErrorsExist = true;
    }
    else {
      this.copyUploadResultMessage = "Successfully parsed " + (this.copyUploadSuccessDestinations.length + errorDestinationsAlreadySelected.length).toString() + " destinations";
      this.copyUploadErrorsExist = false;
    }
  }

  private onCopyUploadSuccessDestinationDelete(code: string) {
    this.copyUploadSuccessDestinations = this.copyUploadSuccessDestinations.filter(t => t.code.toLowerCase() != code.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  private onCopyUploadErrorSuggestedDestinationDelete(code: string) {
    this.copyUploadErrorDestinations = this.copyUploadErrorDestinations.filter(t => t.selectedDestination != null && t.selectedDestination.code.toLowerCase() != code.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  private onCopyUploadErrorNoSuggestedDestinationDelete(code: string) {
    this.copyUploadErrorDestinations = this.copyUploadErrorDestinations.filter(t => t.code.toLowerCase() != code.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  //#endregion

  //#region Destination Lists

  private get destinationsDataStore() {
    var configService = this.configService;
    var util = this.util;
    //var quickSearchValue = this.quickSearchValue;
    var criteriaObj = this.searchDestinationCriteriaObj;
    var mRef = this.bsModalRef;

    return new CustomStore({
      load: function (loadOptions: any) {

        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'code','desc':false}]";
        var groupConfig = loadOptions.group ? JSON.stringify(loadOptions.group) : "";

        let gridParams: GridParams = { group: groupConfig, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, gridParams: gridParams };

        return configService.searchDestinations(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var obj: any = {
                data: response.result.destinations as Array<OrderDestination>,
                totalCount: response.result.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  private searchDestinations() {
    this.dataSource = new DataSource({
      store: this.destinationsDataStore
    });
  }

  public saveDestinationsFromList() {
    var grid = this.destinationsGrid.instance;
    var selectedDestinations = new Array<OrderDestination>();
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedDestinations.push(grid.getSelectedRowsData()[i]);
    }
    var allSelectedDestiantions = new SelectedOrderDestinations();
    allSelectedDestiantions.applyToAllGroups = this.applyToAllGroups;
    allSelectedDestiantions.destinations = selectedDestinations;
    this.onClose.next(allSelectedDestiantions);
    this.closePopup();
  }

  public onDestinationListsSelected() {
    this.selectionMethod = 'list';
    this.title = "ADD DESTINATIONS - DESTINATIONS LIST"
  }

  //#endregion

  //#region Saved Destination Lists

  public onSaveToListCompleted() {
    this.destinationsToAddToSavedList = null;
    this.selectionMethodNext = '';

    if (this.selectionMethod == 'list')
      this.destinationsGrid.instance.deselectRows(this.destinationsGrid.instance.getSelectedRowKeys());
  }

  public deselectSavedDestinationsGrid() {
    this.destinationsSavedListGrid.instance.deselectRows(this.destinationsSavedListGrid.instance.getSelectedRowKeys());
  }

  public saveDestinationsFromSavedList() {
    var grid = this.destinationsSavedListGrid.instance;

    var selectedDestinations = new Array<OrderDestination>();

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      var data = grid.getSelectedRowsData()[i];
      selectedDestinations = selectedDestinations.concat(data.destinations as Array<OrderDestination>);
    }

    var allSelectedDestiantions = new SelectedOrderDestinations();

    allSelectedDestiantions.applyToAllGroups = this.applyToAllGroups;

    allSelectedDestiantions.destinations = selectedDestinations;

    this.onClose.next(allSelectedDestiantions);

    this.closePopup();
  }

  public onDestinationSavedListsSelected() {
    this.selectionMethod = 'savedlist';
    this.quickSearchValue = '';
    this.searchSavedDestinationLists();
    this.savedDestinationListToEdit = null;
    this.title = "ADD DESTINATIONS - SAVED DESTINATION LISTS"
  }

  public onSaveToDestinationList() {

    if (this.selectionMethod == 'list') {

      var grid = this.destinationsGrid.instance;

      if (grid.getSelectedRowKeys().length == 0) {
        return;
      }

      this.destinationsToAddToSavedList = [];

      for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
        this.destinationsToAddToSavedList.push(grid.getSelectedRowsData()[i].code);
      }

      this.selectionMethodNext = 'savetolist';
    }
    else if (this.selectionMethod == 'copyuploadresult') {
      if (this.copyUploadErrorDestinations.some(t => t.selectedDestination == null) || this.copyUploadErrorsExist == true)
        return;

      this.destinationsToAddToSavedList = this.copyUploadSuccessDestinations.map(t => t.code);

      this.copyUploadErrorDestinations.forEach(t => {
        if (this.destinationsToAddToSavedList.filter(s => t.code.toLowerCase() == t.selectedDestination.code.toLowerCase() == null))
          this.destinationsToAddToSavedList.push(t.selectedDestination.code);
      });

      this.selectionMethodNext = 'savetolist';
    }
  }

  private searchSavedDestinationLists() {

    let request: SearchUserDestinationRequest = { category: 'list', quickSearch: this.quickSearchValue };

    this.configService.searchUserDestinations(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var destinations = res.result as Array<OrderUserDestination>;
        this.dataSourceSavedList = destinations;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onDestinationListDelete(id: number) {

    this.configService.deleteUserSavedDestinationList(id, "list").subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.destinationsListDeleted, MessageSeverity.success);
        this.searchSavedDestinationLists();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

  }

  public onDestinationListEdit(id: number) {
    this.selectionMethod = "editsavedlist";
    this.title = "DESTINATIONS LIST - EDIT"
    this.addMoreInEditSavedDestinationList = false;
    this.nameEditInEditSavedDestinationList = false;
    this.savedDestinationListToEdit = this.dataSourceSavedList.find(t => t.id == id);
  }

  //#endregion

  //#region Edit Destination List

  public onAddMoreToEditDestinationList() {
    this.addMoreInEditSavedDestinationList = true;
  }

  public onSaveInEditDestinationList() {

    if (this.savedDestinationListToEdit == null)
      return;

    this.configService.updateUserSavedDestinationList(this.savedDestinationListToEdit.id, "list", this.savedDestinationListToEdit).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.destinationsAddedToList, MessageSeverity.success);
        this.onDestinationSavedListsSelected();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public onAddDestinationInEditDestinationList() {
    var grid = this.destinationsGrid.instance;
    var selectedDestinations = new Array<OrderDestination>();

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (this.savedDestinationListToEdit.destinations.find(t => t.code.toLowerCase() == grid.getSelectedRowsData()[i].code.toLowerCase()) == null)
        this.savedDestinationListToEdit.destinations.push(grid.getSelectedRowsData()[i]);
    }

    grid.deselectRows(grid.getSelectedRowKeys());
  }

  public onDestinationListDestinationDelete(code: string) {
    this.savedDestinationListToEdit.destinations = this.savedDestinationListToEdit.destinations.filter(t => t.code.toLowerCase() != code.toLowerCase());
  }

  //#endregion
}
