import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { CreateBrandModel } from "../../../../models/admin/brand/createbrand.model";
import { SelectedProductsComponent } from "../selected-products/selected-products.component";
import { RouteConstants } from "../../../../configurations/route-constants";
import { brandDetailSource } from "../../../../configurations/enums/enums";
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../../services/core/global.service';

@Component({
  selector: 'create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.css']
})
export class CreateBrandComponent implements OnInit, OnDestroy {

  public createBrandFormGroup: FormGroup;
  @ViewChild(SelectedProductsComponent, { static: false }) productSelectedComp: SelectedProductsComponent;

  public locationDataSource: any = {};
  public statusDataSource: any = {};
  private subscription: Subscription;
  public brandGuid: string;

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private gs: GlobalService
    , private configService: ConfigService) {

  }

  ngOnInit() {
    this.getConfig();

    this.createBrandFormGroup = new FormGroup({
      brandName: new FormControl('', Validators.required),
      shortName: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      location: new FormControl(1, Validators.required),
      status: new FormControl(1, Validators.required)
    });

    this.subscription = this.createBrandFormGroup.get('brandName').valueChanges.subscribe(val => {
      if (val != null) {
        this.createBrandFormGroup.patchValue({ 'shortName': val.substring(0, 15) });
      }
    });

  }

  public getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateBrandConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.locationDataSource = res.result.locations;
        this.statusDataSource = res.result.brandStatuses;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public gotoBrandHome() {
      this.router.navigate([RouteConstants.brandsRoute]);
  }

  public onFormSubmit({ value, valid }: { value: CreateBrandModel, valid: boolean }) {
    if (!valid) {
      return;
    }
    else {
      this.createBrand(value);
    }
  }

  private createBrand(value: CreateBrandModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.adminService.createBrand(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var result = res.result;
        this.alertService.showMessage("SUCCESS", Constants.createBrandSuccessful, MessageSeverity.success);
        if (result.brandGuid != "00000000-0000-0000-0000-000000000000") {
          this.brandGuid = result.brandGuid;
          console.log(this.brandGuid);
          //this.router.navigate([RouteConstants.brandsRoute], { queryParams: { source: brandDetailSource[brandDetailSource.create], brandguid: this.brandGuid } });

         // this.gs.openBrandDetails(result.brandGuid);
          this.router.navigate([RouteConstants.brandDetailRoute, result.brandGuid], { queryParams: { source: brandDetailSource[brandDetailSource.create], brandguid: result.brandGuid } });
        }
        //console.log(this.brandGuid);
        //this.gotoBrandHome();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
