import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { AccountSettingsService } from "../../../services/user/account-settings.service";
import { UserProfileService } from "../../../services/user/user-profile.service";
import { UserDataService } from "../../../services/user/user-data.service";
import { UserPersonalInformation } from "../../../models/user/user-personal-information.model";
import { MessageSeverity, AlertService } from "../../../services/core/alert.service";
import { Constants } from "../../../configurations/constants";
import { TimeZone } from "../../../models/core/timezone.model";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { UserChangePassword } from "../../../models/user/user-changepassword.model";
import { FormBuilder, FormArray } from '@angular/forms';
import { SubService } from "../../../services/media/sub.service";
import { NotificationChoice } from "../../../models/user/notification-choice.model";
import { EmailNotificationPreference } from "../../../models/user/email-notification-preference.model";
import { NotificationPreferences } from "../../../models/user/notification-preferences.model";
import { TextMaskModule } from 'angular2-text-mask';
import { SmsNotificationPreference } from "../../../models/user/sms-notification-preference.model";
import { UserRolesNotification } from "../../../configurations/user-roles-notification";
import { NotificationPreferenceMapping } from "../../../models/user/notification-preference-mapping";
import { AppConfig, APP_CONFIG } from "../../../app.module.config";
import { customPhoneValidator } from "../../../configurations/custom-validations";
import { ChangePasswordComponent } from '../createpassword/changepassword/changepassword.component';
import { UserProfile } from '../../../models/user/user.profile.model';

@Component({
  selector: 'accountsettings',
  templateUrl: './accountsettings.component.html',
  styleUrls: ['./accountsettings.component.css']
})
export class AccountSettingsComponent implements OnInit {
  PersonalInfo: FormGroup;
  ChangePassword: FormGroup;
  EmailNotifications: FormGroup;
  emailAdd: FormGroup;
  smsAdd: FormGroup;
  public showChangePwd: boolean = false;
  public timezoneDataSource: string[];
  public userProfileObj: UserProfile = null;
  public userName: string = "";
  public timeZonesStore: any[] = [];
  public modalRef: BsModalRef;
  public invalidOldPassword: boolean = false;
  public passwordsNotMatch: boolean = false;
  public imageBinary: string = "";
  public seeAllAvatars: boolean = true;
  public saveSelectedAvatar: boolean = false;
  public notificationPreference: string[] = [];
  public notificationChoices: NotificationChoice[] = [];
  public notificationPreferenceList: EmailNotificationPreference[] = null;
  public smsNotificationPreferenceList: SmsNotificationPreference[] = [];
  public notificationPreferenceListSaving: EmailNotificationPreference[] = [];
  public smsNotificationPreferenceListSaving: SmsNotificationPreference[] = [];
  public phoneDuplicate: boolean = false;
  public emailDuplicate: boolean = false;
  public emptyChoicesEmail: boolean = false;
  public emptyChoicesSMS: boolean = false;
  public phonePattern = Constants.phonePattern;
  public passwordPattern = Constants.passwordPattern;
  public emailNotificationType = Constants.email;
  public smsNotificationType = Constants.SMS;
  public now: Date = new Date();
  public isLoading = false;
  public dayList = [];
  public monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  public phoneMask = Constants.phoneMask;

  constructor(public util: Utilities,
    private accountSettingsService: AccountSettingsService,
    private userProfileService: UserProfileService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private subService: SubService,
    private _fb: FormBuilder,
    private userRolesNotification: UserRolesNotification,
    @Inject(APP_CONFIG) private config: AppConfig) {

    this.PersonalInfo = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      workphone: new FormControl('', Validators.compose([customPhoneValidator])),
      cellphone: new FormControl('', Validators.compose([customPhoneValidator])),
      birthday: new FormControl(0),
      birthmonth: new FormControl(''),
      timezone: new FormControl('')
    });

    this.ChangePassword = new FormGroup({
      oldpassword: new FormControl('', Validators.required),
      newpassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
      verifypassword: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.emailAdd = this._fb.group({
      emails: this._fb.array([

      ])
    });

    this.smsAdd = this._fb.group({
      phones: this._fb.array([

      ])
    });
    this.getPersonalInformation(true);
    this.currentNotificationPreferences();
  }

  public initEmails() {
    var emailFb = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      MediaArrivalConfirmation: [false],
      MediaPendingApproval: [false],
      MediaQCPending: [false],
      MediaQCPass: [false],
      MediaQCFail: [false],
      OrderCompletion: [false],
      OrderEditNotification: [false],
      OrderConfirmation: [false],
      MediaEditNotification: [false],
      MediaApproveRejectNotification: [false]
    })
    return emailFb;
  }

  public initPhones() {
    var smsFb = this._fb.group({
      phone: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      MediaArrivalConfirmation: [false],
      MediaPendingApproval: [false],
      MediaQCPending: [false],
      MediaQCPass: [false],
      MediaQCFail: [false],
      OrderCompletion: [false],
      OrderEditNotification: [false],
      OrderConfirmation: [false],
      MediaEditNotification: [false],
      MediaApproveRejectNotification: [false]
    })
    return smsFb;
  }

  public addPhones() {
    const control = <FormArray>this.smsAdd.controls['phones'];
    control.push(this.initPhones());
  }

  public addEmails() {
    const control = <FormArray>this.emailAdd.controls['emails'];
    control.push(this.initEmails());
  }

  get getEmailsData() {
    return <FormArray>this.emailAdd.get('emails');
  }

  get getSmsData() {
    return <FormArray>this.smsAdd.get('phones');
  }

  public removeSms(i: number) {
    const control = <FormArray>this.smsAdd.controls['phones'];

    if (i < this.smsNotificationPreferenceList.length) {
      this.notificationPreference = [];
      this.notificationPreferenceListSaving = [];
      this.smsNotificationPreferenceListSaving = [];

      //move this to common method used more than once
      let userRoles: string[] = this.userProfileService.getUserRoles();
      for (let role of userRoles) {
        var notificationPrefbyRole = this.userRolesNotification.getNotification(role);
        for (let pref of notificationPrefbyRole) {
          if (this.notificationPreference.indexOf(pref) < 0) {
            this.notificationPreference.push(pref)
          }
        }
      }
      var objectTobeRemoved = this.smsAdd.value.phones[i];

      var notificationChoicesSms: NotificationChoice[] = [];
      var smsEntered = objectTobeRemoved.phone;
      for (let pref of this.notificationPreference) {
        var notificationChoiceEmail = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          objectTobeRemoved[pref]
        );
        notificationChoicesSms.push(notificationChoiceEmail);
      }
      var smsNotificationPreferenceObj = new SmsNotificationPreference(
        smsEntered,
        notificationChoicesSms
      );
      this.smsNotificationPreferenceListSaving.push(smsNotificationPreferenceObj);

      var notificationPreference = new NotificationPreferences(
        this.smsNotificationPreferenceListSaving,
        this.notificationPreferenceListSaving
      )

      //move this too used in two removes
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      this.accountSettingsService.removeNotificationPreferenceEndpoint(notificationPreference).subscribe((res: any) => {
        if (res.isSuccess == true) {
          control.removeAt(i);
          this.alertService.showMessage("SUCCESS", Constants.userNotificationsUpdateSuccessful, MessageSeverity.success);
          this.alertService.ShowLoader(false);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          this.alertService.ShowLoader(false);
        }
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
    else {
      control.removeAt(i);
    }
  }

  public removeEmails(i: number) {
    const control = <FormArray>this.emailAdd.controls['emails'];

    if (i < this.notificationPreferenceList.length) {
      this.notificationPreference = [];
      this.notificationPreferenceListSaving = [];
      this.smsNotificationPreferenceListSaving = [];

      //move this to common method used more than once
      let userRoles: string[] = this.userProfileService.getUserRoles();
      for (let role of userRoles) {
        var notificationPrefbyRole = this.userRolesNotification.getNotification(role);
        for (let pref of notificationPrefbyRole) {
          if (this.notificationPreference.indexOf(pref) < 0) {
            this.notificationPreference.push(pref)
          }
        }
      }
      var objectTobeRemoved = this.emailAdd.value.emails[i];

      var notificationChoicesEmail: NotificationChoice[] = [];
      var emailEntered = objectTobeRemoved.email;
      for (let pref of this.notificationPreference) {
        var notificationChoiceEmail = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          objectTobeRemoved[pref]
        );
        notificationChoicesEmail.push(notificationChoiceEmail);
      }
      var emailNotificationPreferenceObj = new EmailNotificationPreference(
        emailEntered,
        notificationChoicesEmail
      );
      this.notificationPreferenceListSaving.push(emailNotificationPreferenceObj);

      var notificationPreference = new NotificationPreferences(
        this.smsNotificationPreferenceListSaving,
        this.notificationPreferenceListSaving
      )
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      this.accountSettingsService.removeNotificationPreferenceEndpoint(notificationPreference).subscribe((res: any) => {
        if (res.isSuccess == true) {
          control.removeAt(i);
          this.alertService.showMessage("SUCCESS", Constants.userNotificationsUpdateSuccessful, MessageSeverity.success);
          this.alertService.ShowLoader(false);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          this.alertService.ShowLoader(false);
        }
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }

    else {
      control.removeAt(i);
    }
  }

  public getUserNotificationPreferences(n: NgForm) {

    this.notificationPreference = [];
    this.notificationPreferenceListSaving = [];
    this.smsNotificationPreferenceListSaving = [];
    let userRoles: string[] = this.userProfileService.getUserRoles();
    for (let role of userRoles) {
      var notificationPrefbyRole = this.userRolesNotification.getNotification(role);
      for (let pref of notificationPrefbyRole) {
        if (this.notificationPreference.indexOf(pref) < 0) {
          this.notificationPreference.push(pref)
        }
      }
    }

    for (let i of this.emailAdd.value.emails) {
      var notificationChoicesEmail: NotificationChoice[] = [];
      var emailEntered = i.email;
      for (let pref of this.notificationPreference) {
        var notificationChoiceEmail = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          i[pref]
        );
        notificationChoicesEmail.push(notificationChoiceEmail);
      }
      var emailNotificationPreferenceObj = new EmailNotificationPreference(
        emailEntered.toLowerCase(),
        notificationChoicesEmail
      );
      this.notificationPreferenceListSaving.push(emailNotificationPreferenceObj);
    }

    for (let i of this.smsAdd.value.phones) {
      var notificationChoicesSms: NotificationChoice[] = [];
      var smsEntered = i.phone.replace(/\D+/g, '').trim();
      for (let pref of this.notificationPreference) {
        var notificationChoiceSms = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          i[pref]
        );
        notificationChoicesSms.push(notificationChoiceSms);
      }
      var smsNotificationPreferenceObj = new SmsNotificationPreference(
        smsEntered,
        notificationChoicesSms
      );
      this.smsNotificationPreferenceListSaving.push(smsNotificationPreferenceObj);
    }

    this.saveNotificationPreference(this.notificationPreferenceListSaving, this.smsNotificationPreferenceListSaving, n);
  }

  public emailAlreadyExistsInForm(email: string): boolean {
    if (email != null && email != undefined) {
      if (email.trim() != "") {
        if (this.emailAdd.value.emails.filter(n => n.email.toLowerCase() == email.toLowerCase()).length > 1) {
          this.emailDuplicate = true;
          return true;
        }
        else {
          this.emailDuplicate = false;
          return false;
        }
      }
    }
  }

  public phoneAlreadyExistsInForm(phone: string): boolean {
    if (phone != null && phone != undefined) {
      if (phone.trim() != "") {
        if (this.smsAdd.value.phones.filter(n => n.phone == phone).length > 1) {
          this.phoneDuplicate = true;
          return true;
        }
        else {
          this.phoneDuplicate = false;
          return false;
        }
      }
    }
  }

  public displayErrorForNotificationPreferencesNotSelected(i: number, type: string): boolean {

    let userRoles: string[] = this.userProfileService.getUserRoles();
    var objectTobeValidated;
    for (let role of userRoles) {
      var notificationPrefbyRole = this.userRolesNotification.getNotification(role);
      for (let pref of notificationPrefbyRole) {
        if (this.notificationPreference.indexOf(pref) < 0) {
          this.notificationPreference.push(pref)
        }
      }
    }

    if (type == this.emailNotificationType) {
      objectTobeValidated = this.emailAdd.value.emails[i];
    }
    if (type == this.smsNotificationType) {
      objectTobeValidated = this.smsAdd.value.phones[i];
    }
    var notificationChoicesEmail: NotificationChoice[] = [];
    for (let pref of this.notificationPreference) {
      if (objectTobeValidated[pref] == true) {
        //this.emptyChoicesEmail = false;
        return false;
      }
    }
    //this.emptyChoicesEmail = true;
    return true;
  }

  public displayErrorForEmailNotificationPreferencesNotSelected(i: number, type: string) {
    this.emptyChoicesEmail = this.displayErrorForNotificationPreferencesNotSelected(i, type);
    return this.emptyChoicesEmail;
  }

  public displayErrorForsmsNotificationPreferencesNotSelected(i: number, type: string) {
    this.emptyChoicesSMS = this.displayErrorForNotificationPreferencesNotSelected(i, type);
    return this.emptyChoicesSMS;
  }

  public addCurrentEmails(email: string, notificationChoicesObj: NotificationChoice[]) {
    const control = <FormArray>this.emailAdd.controls['emails'];
    control.push(this.initCurrentEmails(email, notificationChoicesObj));
  }

  public addCurrentPhones(phone: string, notificationChoicesObj: NotificationChoice[]) {
    const control = <FormArray>this.smsAdd.controls['phones'];
    control.push(this.initCurrentPhones(phone, notificationChoicesObj));
  }

  public initCurrentPhones(phone: string, notificationChoicesObj: NotificationChoice[]) {
    var smsFb = this._fb.group({
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      MediaArrivalConfirmation: [false],
      MediaPendingApproval: [false],
      MediaQCPending: [false],
      MediaQCPass: [false],
      MediaQCFail: [false],
      OrderCompletion: [false],
      OrderEditNotification: [false],
      OrderConfirmation: [false],
      MediaEditNotification: [false],
      MediaApproveRejectNotification: [false]
    })

    if (smsFb.contains('phone')) {
      smsFb.controls['phone'].setValue(phone);
    }

    for (let i of notificationChoicesObj) {
      if (smsFb.contains(i.name.replace("/", "").split(" ").join(""))) {
        smsFb.controls[i.name.replace("/", "").split(" ").join("")].setValue(i.isChecked);
      }
    }
    return smsFb;
  }

  public initCurrentEmails(email: string, notificationChoicesObj: NotificationChoice[]) {
    var emailFb = this._fb.group({
      email: ['', Validators.required],
      MediaArrivalConfirmation: [false],
      MediaPendingApproval: [false],
      MediaQCPending: [false],
      MediaQCPass: [false],
      MediaQCFail: [false],
      OrderCompletion: [false],
      OrderEditNotification: [false],
      OrderConfirmation: [false],
      MediaEditNotification: [false],
      MediaApproveRejectNotification: [false]
    })

    if (emailFb.contains('email')) {
      emailFb.controls['email'].setValue(email);
    }

    for (let i of notificationChoicesObj) {
      if (emailFb.contains(i.name.replace("/", "").split(" ").join(""))) {
        emailFb.controls[i.name.replace("/", "").split(" ").join("")].setValue(i.isChecked);
      }
    }
    return emailFb;
  }

  public currentNotificationPreferences() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.currentUserNotificaionPreferencesEndpoint().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.notificationPreferenceList = res.result.emailNotificationPreferences;
        if (this.notificationPreferenceList.length > 0) {
          for (let i of this.notificationPreferenceList) {
            if (i.notificationChoices.length > 0) {
              this.addCurrentEmails(i.email, i.notificationChoices);
            }
          }
        }
        this.smsNotificationPreferenceList = res.result.smsNotificationPreferences;
        if (this.smsNotificationPreferenceList.length > 0) {
          for (let i of this.smsNotificationPreferenceList) {
            if (i.notificationChoices.length > 0) {
              this.addCurrentPhones(i.phoneNumber, i.notificationChoices);
            }
          }
        }
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public saveNotificationPreference(notificationPreferenceListSaving: EmailNotificationPreference[], smsNotificationPreferenceListSaving: SmsNotificationPreference[], n: NgForm) {
    if (!this.emailAdd.valid || !this.smsAdd.valid || this.phoneDuplicate == true || this.emailDuplicate == true || prefNotSelectedEmail == false || prefNotSelectedSMS == false) {
      return;
    }
    else {
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      var notificationPreference = new NotificationPreferences(
        smsNotificationPreferenceListSaving,
        notificationPreferenceListSaving
      );
      var prefNotSelectedEmail = true;
      var prefNotSelectedSMS = true;
      for (let i of smsNotificationPreferenceListSaving) {
        prefNotSelectedSMS = false;
        for (let j of i.notificationChoices) {
          if (j.isChecked == true) {
            prefNotSelectedSMS = true;
            break;
          }
        }
        if (prefNotSelectedSMS == false) { break; }
      }

      for (let i of notificationPreferenceListSaving) {
        prefNotSelectedEmail = false;
        for (let j of i.notificationChoices) {
          if (j.isChecked == true) {
            prefNotSelectedEmail = true;
            break;
          }
        }
        if (prefNotSelectedEmail == false) { break; }
      }

      this.accountSettingsService.saveUserNotificaionPreferencesEndpoint(notificationPreference).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.userNotificationsUpdateSuccessful, MessageSeverity.success);
          this.cancelNotificationEdits(n);
          this.alertService.ShowLoader(false);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          this.alertService.ShowLoader(false);
        }
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
  }

  public enablecarousel() {
    this.seeAllAvatars = false;
    this.saveSelectedAvatar = true;
  }

  public saveSelectedAvatarInBytes() {
    this.seeAllAvatars = true;
    this.saveSelectedAvatar = false;
    var detailsPlayListDiv = document.getElementById("myAvatar");
    var co = detailsPlayListDiv.firstElementChild.getElementsByClassName("active");
    this.convertAvatarToByteAndUpdateAvatar(co[0].id);
  }

  public showSeeAllAvatarButton(): boolean {
    return this.seeAllAvatars;
  }

  public showsaveSelectedAvatarButton(): boolean {
    return this.saveSelectedAvatar;
  }

  public convertAvatarToByteAndUpdateAvatar(avatarSelected: string) {
    var canvas = document.createElement("canvas");
    var imageElement = document.createElement("img");

    imageElement.setAttribute('src', avatarSelected);
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;
    var context = canvas.getContext("2d");
    context.drawImage(imageElement, 0, 0);
    var base64Image = canvas.toDataURL(avatarSelected);

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.updateUserAvatarEndpoint(base64Image).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.imageBinary = base64Image;
        this.subService.getUpdatedAvatar(base64Image.replace(Constants.avatarEncoding, ""));
        this.alertService.showMessage("SUCCESS", Constants.userAvatarUpdateSuccessful, MessageSeverity.success);
        this.alertService.ShowLoader(false);
      }

      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public getPersonalInformation(loadAvatar: boolean) {
    this.userProfileObj = this.userProfileService.getUserProfile();
    this.userName = this.userProfileObj.email;
    this.PersonalInfo.controls['firstname'].setValue(this.userProfileObj.firstName);
    this.PersonalInfo.controls['lastname'].setValue(this.userProfileObj.lastName);
    this.PersonalInfo.controls['workphone'].setValue(this.userProfileObj.workPhone != null ? this.userProfileObj.workPhone : '');
    this.PersonalInfo.controls['cellphone'].setValue(this.userProfileObj.cellPhone != null ? this.userProfileObj.cellPhone : '');
    this.PersonalInfo.controls['birthmonth'].setValue(this.userProfileObj.birthMonth != null ? this.userProfileObj.birthMonth : '');
    if (this.userProfileObj.birthMonth != null && this.userProfileObj.birthMonth != undefined && this.userProfileObj.birthMonth != "") {
      this.getDaysForMonth(this.userProfileObj.birthMonth);
      this.PersonalInfo.controls['birthday'].setValue(this.userProfileObj.birthDay);
    }

    if (loadAvatar) {
      this.imageBinary = this.userProfileObj.avatar != null ? Constants.avatarEncoding + this.userProfileObj.avatar : this.config.defaultAvatar;
    }

    this.timeZonesStore = this.getTimeZones();
    this.getCanChangePassword();
  }

  public getCanChangePassword() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.canManagePwd(this.userProfileObj.userProfileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.showChangePwd = res.result;
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public changePassword() {

    var initialState = {
      isModal: true
    };

    this.modalRef = this.modalService.show(ChangePasswordComponent, this.util.getModalComponentOptions(initialState, false, true, false));
  }

  public getStandardNameFromDisplayName(displayName: string): string {
    for (let tz of this.timeZonesStore) {
      if (tz.displayName == displayName) { return tz.standardName; }
    }
  }

  public getTimeZones(): TimeZone[] {
    var timeZonesTemp: TimeZone[] = [];
    var displayTimeZones: string[] = [];
    this.accountSettingsService.getTimeZone().subscribe((res: any) => {
      if (res.isSuccess == true) {
        timeZonesTemp = res.result;
        this.timeZonesStore = timeZonesTemp
        for (let tz of this.timeZonesStore) {
          displayTimeZones.push(tz.displayName);
        }
        this.timezoneDataSource = displayTimeZones;
        this.PersonalInfo.controls['timezone'].setValue(this.timezoneDataSource[0]);
        for (let tz of this.timeZonesStore) {
          if (tz.standardName == this.userProfileObj.timeZone) { this.PersonalInfo.controls['timezone'].setValue(tz.displayName); }
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        return [];
      }
    },
      error => {
        this.util.handleError(error);
        this.timezoneDataSource = [];
        return [];
      });

    return timeZonesTemp;
  }

  public cellPhoneValid(): boolean {
    if (this.PersonalInfo.value.cellphone != null && this.PersonalInfo.value.cellphone != undefined) {
      if (this.PersonalInfo.value.cellphone.replace(/\D+/g, '').trim().length != 0 && this.PersonalInfo.value.cellphone.replace(/\D+/g, '').length != 10) {
        return true;
      }
    }
    return false;
  }

  public workPhoneValid(): boolean {
    if (this.PersonalInfo.value.workphone != null && this.PersonalInfo.value.workphone != undefined) {
      if (this.PersonalInfo.value.workphone.replace(/\D+/g, '').trim().length != 0 && this.PersonalInfo.value.workphone.replace(/\D+/g, '').length != 10) {
        return true;
      }
    }
    return false;
  }

  public onbirthMonthChange(e: any) {
    if (e != null && e != undefined && e != "" && e.value != null && e.value != undefined && e.value != "") {
      var month = e.value;
      this.getDaysForMonth(month);
    }
    else {
      this.dayList = [];
    }
  }

  private getDaysForMonth(month) {
    if (month == "February") {
      this.dayList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
    }
    else if (month == "April" || month == "June" || month == "September" || month == "November") {
      this.dayList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    }
    else {
      this.dayList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    }
  }

  public saveAccountSettings(PersonalInfoObj: any) {
    if (this.PersonalInfo.invalid || this.cellPhoneValid() || this.workPhoneValid()) {
      return;
    }

    let userPersonalInformation = new UserPersonalInformation();
    userPersonalInformation.cellPhone = this.PersonalInfo.value.cellphone != null ? this.PersonalInfo.value.cellphone.replace(/\D+/g, '').trim() : this.PersonalInfo.value.cellphone;
    userPersonalInformation.birthDay = this.PersonalInfo.value.birthday;
    userPersonalInformation.birthMonth = this.PersonalInfo.value.birthmonth;
    userPersonalInformation.timeZone = this.PersonalInfo.value.timezone == null ? this.config.timeZoneDefault : this.getStandardNameFromDisplayName(this.PersonalInfo.value.timezone);
    userPersonalInformation.workPhone = this.PersonalInfo.value.workphone != null ? this.PersonalInfo.value.workphone.replace(/\D+/g, '').trim() : this.PersonalInfo.value.workphone;
    userPersonalInformation.firstName = this.PersonalInfo.value.firstname;
    userPersonalInformation.lastName = this.PersonalInfo.value.lastname;

    if ((this.PersonalInfo.get('birthday').value == 0 || this.PersonalInfo.get('birthday').value == null) || (this.PersonalInfo.get('birthmonth').value == "" || this.PersonalInfo.get('birthmonth').value == null)) {
      this.PersonalInfo.get('birthmonth').setValue("");
      this.onbirthMonthChange("");
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.saveUserPersonalInformation(userPersonalInformation).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.subService.getUpdatedAccount();
        this.alertService.showMessage("SUCCESS", Constants.userPersonalInformationSaveSuccessful, MessageSeverity.success);
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public cancelEdits() {
    this.getPersonalInformation(false);
  }

  public cancelAvatarEdit() {
    this.seeAllAvatars = true;
    this.saveSelectedAvatar = false;
  }

  public cancelNotificationEdits(n: NgForm) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.currentNotificationPreferences();
    n.resetForm();
    const control = <FormArray>this.emailAdd.controls['emails'];
    for (let i in control) {
      control.removeAt(+i);
    }
    const controlsms = <FormArray>this.smsAdd.controls['phones'];
    for (let i in controlsms) {
      controlsms.removeAt(+i);
    }
    this.alertService.ShowLoader(false);
  }

  public refreshNotoficationPreferencesOnSave() {
    this.notificationPreferenceList = [];
    this.smsNotificationPreferenceList = [];
    for (let i of this.emailAdd.value.emails) {
      var notificationChoicesEmail: NotificationChoice[] = [];
      var emailEntered = i.email;
      for (let pref of this.notificationPreference) {
        var notificationChoiceEmail = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          i[pref]
        );
        notificationChoicesEmail.push(notificationChoiceEmail);
      }
      var emailNotificationPreferenceObj = new EmailNotificationPreference(
        emailEntered.toLowerCase(),
        notificationChoicesEmail
      );
      this.notificationPreferenceList.push(emailNotificationPreferenceObj);
    }

    for (let i of this.smsAdd.value.phones) {
      var notificationChoicesSms: NotificationChoice[] = [];
      var smsEntered = i.phone.replace(/\D+/g, '').trim();
      for (let pref of this.notificationPreference) {
        var notificationChoiceSms = new NotificationChoice(
          NotificationPreferenceMapping[pref],
          i[pref]
        );
        notificationChoicesSms.push(notificationChoiceSms);
      }
      var smsNotificationPreferenceObj = new SmsNotificationPreference(
        smsEntered,
        notificationChoicesSms
      );
      this.smsNotificationPreferenceList.push(smsNotificationPreferenceObj);
    }
  }

}
