import { Injectable } from '@angular/core';

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { NotificationRequest } from "../../models/notifications/notification-request.model";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificationService {

  constructor(
  private http: HttpClient,
  private apiendPoint: ApiEndPoint) { }

  public searchNotifications(request: NotificationRequest) {
    return this.http.post(this.apiendPoint.searchNotificationsEndPoint(), request);
  }

  public searchSpotNotifications(id:string, request: NotificationRequest) {
    return this.http.post(this.apiendPoint.searchSpotNotificationsEndPoint() + "/" + id, request);
  }

  public searchOrderNotifications(id: string, request: NotificationRequest) {
    return this.http.post(this.apiendPoint.searchOrderNotificationsEndPoint() + "/" + id, request);
  }

  public getMessage(requestID: number, requestNotifyID: number) {
    return this.http.get(this.apiendPoint.getNotificationMessageEndPoint() + "/" + requestID + "/" + requestNotifyID);
  }

  public setViewed(idList: Array<number>) {
    return this.http.post(this.apiendPoint.setViewedNotificationEndPoint(), idList);
  }

  public setArchived(idList: Array<number>) {
    return this.http.post(this.apiendPoint.setArchivedNotificationEndPoint(), idList);
  }

  public getCount() {
    return this.http.get(this.apiendPoint.getNotificationsCountEndPoint());
  }
}
