<div class="notifications-section" id="notifications-section">
  <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        FILE NAMING
      </h4>
    </div>
    <div id="mediaApprovalCollapseOne" class="tylie-panel__body">
      <div id="filenaming_step_content">
        <form [formGroup]="fileNameForm" novalidate #n="ngForm">
         
          <div class="tylie-body__title"> File Naming Options</div>
          <div class="tylie-type-body mb-3">Please select your file naming preferences for your finished Transcoded Assets.</div>
          
          <div class="d-flex">
            <div class="tylie-form-group form-check  me-4">
              <label class="form-check-label" >
                <input type="radio" name="fileNamingType" class="form-check-input" value="basic" formControlName="fileNamingType"><span>Use Basic File Naming </span>
              </label>
            </div>
  
            <div class="tylie-form-group form-check">
              <label class="form-check-label">
                <input type="radio" name="fileNamingType" class="form-check-input" value="advanced" formControlName="fileNamingType"><span>Use Advanced File Naming</span>
              </label>
            </div>
          </div>
          

          <div *ngIf="fileNameForm.controls['fileNamingType'].value == 'advanced'">
            
            <h3 class="tylie-type-h3">Advanced File Naming Preferences</h3>
            <div class="tylie-form-group form-group" [ngClass]="{error: option1Error}">
              <label class="tylie-form-group__lbl">OPTION 1</label>
              <div class="tylie-form-group__txt">Sort the sections into the desired order. Add optional custom text and specify the separator style.</div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="tylie-form-group form-group" formArrayName="fileNameOptions">
                  <label class="tylie-form-group__lbl">File Name Section Ordering</label>
                  <div class="mb-3" *ngFor="let item of fileNameForm.controls['fileNameOptions'].controls; let i = index">
                    <div formGroupName="{{ i }}" class="d-flex mb-2 align-items-center">
                      <div class="p-normal-bold me-1">{{i+1}}.</div>
                      <div class="form-check">
                        <input type="checkbox" name="order" class="form-check-input" [formControl]="item.get('selected')" (change)="onFileOptionSelectionChange()">
                      </div>
                      <div class="mx-1">
                        <input type="text" name="name-type" class="tylie-text form-control"
                              [value]="item.get('name').value"
                              [readonly]="!item.get('isCustom').value"
                              [ngClass]="!item.get('isCustom').value ? 'filename-option-generic' : 'filename-option-custom'"
                              (input)="onEditCustomName(i, $event)">
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onUp(i)" *ngIf="i > 0"><icon-arrow-simple-up height="14px" width="14px" color="#1e90ff"></icon-arrow-simple-up></button>
                        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="onDown(i)" *ngIf="i < (fileNameForm.controls['fileNameOptions'].controls.length-1)"><icon-arrow-simple-down height="14px" width="14px" color="#1e90ff"></icon-arrow-simple-down></button>
                      </div>
                      
                    </div>
                  </div>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="onAddCustomField()"><span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD CUSTOM FIELD</button>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">File Name Section Seperator</label>
                  <div class="file-name_seperator file-ord-sec">
                    <dx-select-box [dataSource]="fileNameSeperatorDataSource"
                                  class="tylie-select form-control"
                                  id="fileSperator"
                                  name="fileSperator"
                                  valueExpr="name"
                                  displayExpr="description"
                                  formControlName="fileSeperator">
                    </dx-select-box>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Example Current Naming Style</label>
                  <div class="tylie-form-group__txt">{{fileNameForm.controls.exampleFileName.value}}</div>
                </div>
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl">Save Current Style</label>
                  <div class="d-flex">
                    <input type="text" placeholder="Name Current Style" class="tylie-text form-control" formControlName="styleName">
                    <button class="tylie-button tylie-button--xs tylie-button--icon ms-3" type="button" (click)="onSaveStyle()"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
                  </div>
                  <div class="p-normal-bold saved-styles" *ngIf="savedStylesSource && savedStylesSource.length > 0">Saved Styles</div>
                  <p class="p-small  blue-text" *ngFor="let style of savedStylesSource">
                    <a (click)="onApplyStyle(style.id)">{{style.name}}&nbsp;&nbsp;&nbsp;</a>
                    <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" role="button"
                        placement="left" #styledeletepop="bs-popover" [popoverContext]="style" container="body" [popover]="deleteStyleTemplate">
                        <icon-trash-can color="#1e90ff"></icon-trash-can>
                    </a>
                  </p>
                </div>
              </div>
              
            </div>

            <div class="row option2">
              <div class="col-md-9">
                <div class="form-group" [ngClass]="{error: option2Error}">
                  <label class="tylie-form-group__lbl">OPTION 2</label>
                  <div class="tylie-form-group__txt">Paste in fullname as you want it. Use Option 1 instead if you'd like more specific and variablized naming control.</div>
                  <!--<div>
                    <input type="text" class="form-control" formControlName="customFileName">
                    <p class="p-extra-small">Do not use special characters, including /!?$%#^@* etc. Underscores, hyphens, Tilde and spaces are acceptable.</p>
                  </div>-->
                  <ng-container [formGroup]="customFileNameForm" novalidate #c="ngForm">
                    <ng-container *ngFor="let att of customFileNameForm.get('customFileNaming')['controls']; let j = index">
                      <ng-container [formGroup]="att">
                        <h5 class="tylie-type-h3">{{att.controls['adId'].value}} : {{att.controls['title'].value}}</h5>
                        <ng-container *ngFor="let sf of att.controls['specFileNames'].controls; let i = index">
                          <div class="tylie-form-group form-group" [formGroup]="sf">
                            <div class="row">
                              <div class="col-md-2">
                                <label class="tylie-form-group__lbl">{{sf.controls['spec'].value}}</label>
                              </div>
                              <div class="col-md-6">
                                <input type="text" class="tylie-text form-control" formControlName="customSpecFileName">
                                <p class="p-extra-small">Do not use special characters, including /!?$%#^@* etc. Underscores, hyphens, Tilde and spaces are acceptable.</p>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <hr>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #deleteStyleTemplate let-id="id">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete this Style?
    </p>
    <i (click)="onHideDeleteStylePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onDeleteStyle(id)">Yes</button> -->


  <button type="button" class="popover__close" (click)="onHideDeleteStylePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete this Style?</header>
  <button class='tylie-button tylie-button--xs' type="button" (click)="onDeleteStyle(id)">Yes</button>
</ng-template>
