
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { UserChangePassword } from "../../../models/user/user-changepassword.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ForgotPasswordSettingsService } from "../../../services/user/forgotpassword-settings.service";
import { UserResetPassword } from "../../../models/user/user-resetpassword.model";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../../../services/auth/auth.service";
import { RouteConstants } from "../../../configurations/route-constants";

@Component({
  selector: 'createnewpassword',
  templateUrl: './createnewpassword.component.html',
  styleUrls: ['./createnewpassword.component.css']
})

export class CreateNewPasswordComponent implements OnInit, OnDestroy {
  ForgotPassword: FormGroup;

  public isLoading = false;
  public invalidErrorMsg: string = '';
  public showInvalidToken: boolean = false;
  public errorMsg: string = '';
  public resetToken: string;
  public userName: string;
  public userResetTokenValid: boolean = null;
  public passwordPattern = Constants.passwordPattern;

  constructor(private alertService: AlertService,
    public util: Utilities,
    private route: ActivatedRoute,
    private forgotPasswordSettingsService: ForgotPasswordSettingsService,
    private router: Router,
    private authService: AuthService) {


    this.ForgotPassword = new FormGroup({
      newpsw: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
      cnfpsw: new FormControl('', [Validators.required])
    });

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.resetToken = params['resetToken'];
      this.userName = params['userName'];
    });

    if (this.resetToken != null && this.resetToken != '' && this.userName != null && this.userName != '') {
      this.showPasswordResetPage(this.resetToken, this.userName);
    }
  }

  ngOnDestroy() {
  }

  public showPasswordResetPage(resetToken: string, userName: string) {
    this.forgotPasswordSettingsService.validateResetPasswordLink(resetToken, userName).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.userResetTokenValid = true;
      }
      else {
        this.userResetTokenValid = false;
        let errorMessage = this.util.handleIsNotSuccessLogin(res.errors);
        this.invalidErrorMsg = errorMessage;
      }
    },
      error => {
        var err = this.authService.processLoginError(error);
        let errorMessage = err.errorDescrption;
      });
  }

  public updatePassword() {
    this.isLoading = true;
    if (this.ForgotPassword.invalid || this.ForgotPassword.value.cnfpsw != this.ForgotPassword.value.newpsw) {
      this.isLoading = false;
      return;
    }

    let userResetPassword = new UserResetPassword(
      this.resetToken,
      this.ForgotPassword.value.cnfpsw,
      this.userName
    );

    this.forgotPasswordSettingsService.updatePasswordAfterTokenAuthEndpoint(userResetPassword).pipe(finalize(() => {
      this.isLoading = false;
    })).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.userPasswordUpdateSuccessful, MessageSeverity.success);
        this.router.navigate([RouteConstants.loginRoute]);
      }
      else {
        let errorMessage = this.util.handleIsNotSuccessLogin(res.errors);
        this.errorMsg = errorMessage;
      }
    },
      error => {
        var err = this.authService.processLoginError(error);
        this.errorMsg = err.errorDescrption;
      });
  }
}
