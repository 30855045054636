<form [formGroup]="updateVendorOrderNumber" #updateVendorOrderNumberForm="ngForm">
  <h3 class="tylie-type-h3">Update Carrier Order Number</h3>

  <div class="col-md-3">
    <div class="tylie-form-group form-group" [ngClass]="{error: updateVendorOrderNumber.controls['vendorOrderNumber'].errors && updateVendorOrderNumberForm.submitted}">
      <label class="tylie-form-group__lbl" for="vendorOrderNumber">Carrier Order#<span class="required">*</span></label>
      <input type="text" id="vendorOrderNumber" name="vendorOrderNumber" class="tylie-text form-control" formControlName="vendorOrderNumber">
      <p class="p-extra-small">Please enter carrier order#</p>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit" (click)="onFormSubmit(updateVendorOrderNumber)"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" (click)="closePopup()">Cancel</button>
  </div>
</form>
