<div class="tylie-main fixed-header" [ngClass]="{'no-scroll-body': showPopup == true}">
  <ng-container *ngIf="this.authService.isLoggedIn() == true">
    <article class="tylie-sidebar" id="sidebar" *ngIf="this.showNavBar == true">
      <nav-menu></nav-menu>
    </article>
  </ng-container>

  <div class="tylie-content" id="content" [ngClass]="(this.showNavBar == true) ? 'auth' : 'noauth'">
    <router-outlet></router-outlet>
  </div>

  <div>
    <ngx-loading [show]="showLoading" [config]="{fullScreenBackdrop: true}"></ngx-loading>
  </div>
</div>


<div class="tylie-flyout" data-pop="pop-in" id="popup" [ngClass]="{'show': showPopup == true && showNavBar == true}">
  <div class="tylie-flyout__content tyl-general-pop-up-content" [ngClass]="{'left-nav': sidebarActive == true}">
    <div class="tylie-flyout__header" id="popup-details-header">
      <button class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link" type="button"
              (click)="closePopover(true)">
        Close<span class="tylie-button__icon e">
          <icon-arrow-circle-right color="#1e90ff"></icon-arrow-circle-right>
        </span>
      </button>
    </div>
    <div class="tylie-flyout__body" [style.height]="wHeight + 'px'">
      <!-- pending -->
      <mediadetailcontent *ngIf="spotFileGuid" [spotFileIdentifier]="spotFileGuid" [isQuickView]="false"
                          [isPopOverView]="true"></mediadetailcontent>
      <order-detail-content *ngIf="orderGuid" [orderIdentifier]="orderGuid"></order-detail-content>
      <createorder-content *ngIf="orderDraftGuid || uploadedOrderGuid || copyOrderGuid"
                           [orderDraftGuid]="orderDraftGuid" [uploadedOrderGuid]="uploadedOrderGuid" [copyOrderGuid]="copyOrderGuid"
                           [copyOptions]="copyOptions" [isPopOver]="true"></createorder-content>
      <app-vault-asset-detail *ngIf="vaultAssetGuid" [vaultAssetGuid]="vaultAssetGuid"></app-vault-asset-detail>
      <app-vault-digital-asset-detail *ngIf="vaultDigitalAssetLineItemId" [vaultDigitalAssetLineItemId]="vaultDigitalAssetLineItemId"></app-vault-digital-asset-detail>
      <vault-request-detail *ngIf="vaultRequestGuid" [vaultRequestGuid]="vaultRequestGuid"
                            [statusLabel]="statusLabel"></vault-request-detail>
      <user-detail *ngIf="userProfileGuid" [userProfileGuid]="userProfileGuid" [isPopOver]="true"></user-detail>
      <client-detail *ngIf="clientGuid" [clientGuid]="clientGuid" [isPopOver]="true"></client-detail>
      <viewedit-brand *ngIf="brandGuid" [brandGuid]="brandGuid" [isPopOver]="true"></viewedit-brand>
      <destination-detail *ngIf="destinationGuid" [destinationGuid]="destinationGuid"
                          [isPopOver]="true"></destination-detail>
    </div>
  </div>
</div>

