import { Component, OnInit } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'app-service-distributionservicefees-metadata',
  templateUrl: './service-distributionservicefees-metadata.component.html',
  styleUrls: ['./service-distributionservicefees-metadata.component.css']
})
export class ServiceDistributionservicefeesMetadataComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

  }

}
