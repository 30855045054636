import { Injectable } from '@angular/core';

@Injectable()
export class UserRolesNotification {

  private MediaProvider: string[] = ["MediaQCPending", "MediaArrivalConfirmation", "MediaQCPass", "MediaQCFail"];
  private MediaApprover: string[] = ["MediaPendingApproval","MediaApproveRejectNotification"];
  private MediaEditor: string[] = ["MediaEditNotification"];
  private OrderBuilder: string[] = ["OrderConfirmation","OrderCompletion"];
  private OrderDetailEditor: string[] = ["OrderEditNotification"];


  public getNotification(r: string): string[] {
    switch (r) {
      //case "Admin":
      //  return this.Admin;
      case "Asset - Provider":
        return this.MediaProvider;
      case "Asset - Approver":
        return this.MediaApprover;
      case "Asset - Editor":
        return this.MediaEditor;
      case "Order - Builder":
        return this.OrderBuilder;
      case "Order - Detail Editor":
        return this.OrderDetailEditor;
      default:
        return [];
    }
    }
}
