<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()">
    <icon-delete color="#000"></icon-delete>
  </button>
  <h4 class="modal-title">UPLOAD ATTACHMENT</h4>
</div>
<div class="modal-body">
  <form [formGroup]="attchmentCategoryRequest" #f="ngForm">
    <div class="form-group">
      <p *ngFor="let fl of attachedFiles;">{{fl.fileName}}</p>

      <label class="new-default-btn x-small-btn file-upload-btn">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
        <input #selectedFile type="file" multiple style="display:none" (change)="onAttachFile($event)" [accept]="acceptFileTypes">
        <!--[disabled]="attchmentCategoryRequest.controls['selectedCategory'].errors"-->
      </label>
      <span class="qc-cancel p-normal" (click)="closePopup()">Close</span>
    </div>
  </form>
</div>
