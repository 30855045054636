
<header class="tylie-header">
  <h3 class="tylie-header__title">Upload</h3>
  <nav class="tylie-header__nav" id="bs-example-navbar-collapse-1">
    <button type="button" class="tylie-button tylie-button--link  tylie-button--xs" (click)="gotoMedia()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back to All Assets</button>
  </nav>
</header>

<section class="tylie-body">
  <div class="tylie-panel tylie-panel--clean">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
          Upload New Assets
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadTylieGuide()">
            Tylie Upload Guide
          </button>
      </h4>
    </div>
    <div class="tylie-panel__body">
      <form [formGroup]="uploadRequest" #f="ngForm" (ngSubmit)="onUploadFormSubmit(uploadRequest)" name="uploadForm" id="uploadForm">
        <div class="row">
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['client'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="client">Client<span class="required">*</span></label>
              <dx-select-box name="client"
                              id="client"
                              #client
                              [dataSource]="clientDataSource"
                              [searchEnabled]="true"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              formControlName="client"
                              (onValueChanged)="onClientValueChanged($event)"
                              placeholder="Select a client...">
              </dx-select-box>
              <p class="p-extra-small">Please select Client</p>
            </div>
          </div>
          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['brand'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
              <dx-select-box name="brand"
                              #brand
                              id="brand"
                              formControlName="brand"
                              [dataSource]="brandDataSource"
                              [searchEnabled]="true"
                              class="tylie-select form-control"
                              valueExpr="id"
                              noDataText="Please choose a client"
                              displayExpr="name"
                              (onValueChanged)="onBrandValueChanged($event)"
                              placeholder="Select a brand...">
              </dx-select-box>
              <p class="p-extra-small">Please select Brand</p>
            </div>
          </div>

          <div class="col-4">
            <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['productId'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
              <dx-select-box [dataSource]="productDataSource"
                              class="tylie-select form-control"
                              #product
                              id="product"
                              name="product"
                              [searchEnabled]="true"
                              [showClearButton]="true"
                              noDataText="Please choose a brand"
                              formControlName="productId"
                              (onValueChanged)="onProductValueChanged($event)"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select a value...">
              </dx-select-box>
              <p class="p-extra-small">Please select Product</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4" *ngIf="this.isAgencySelectionAvailable">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="client">Agency</label>
              <dx-select-box name="agency"
                             id="agency"
                             #agency
                             [dataSource]="agencyDataSource"
                             [disabled]="agencyDataSource.length == 1"
                             [searchEnabled]="true"
                             [showClearButton]="true"
                             class="tylie-select form-control"
                             valueExpr="id"
                             displayExpr="name"
                             formControlName="agency"
                             placeholder="Please choose a Brand/Product...">
              </dx-select-box>
              <p class="p-extra-small">Please choose a Brand/Product</p>
            </div>
          </div>
          <div class="col-4" *ngIf="isUserGroupsVisible == true">
            <div class="tylie-form-group form-group">
              <label class="tylie-form-group__lbl" for="userGroups">User Groups<span class="required">*</span></label>
              <dx-drop-down-box [(value)]="userGrpsValue"
                                formControlName="userGroups"
                                id="userGroups"
                                class="tylie-select form-control"
                                valueExpr="clientUserGroupId"
                                displayExpr="displayName"
                                (onValueChanged)="syncTreeViewSelection()"
                                [dataSource]="userGrpDataSource">
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view [dataSource]="userGrpDataSource"
                                dataStructure="plain"
                                keyExpr="clientUserGroupId"
                                #treeAddl
                                parentIdExpr="parentId"
                                selectionMode="multiple"
                                showCheckBoxesMode="normal"
                                displayExpr="displayName"
                                [selectByClick]="true"
                                (onContentReady)="syncTreeViewSelection($event)"
                                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
              <p class="p-extra-small">Please Select User Groups</p>
            </div>
          </div>
        </div>
       


          <div class="row">
            <div class="col-12">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="uploadType">Upload Type</label>
                <div class="d-flex">
                  <div class="form-check me-3">
                    <input class="form-check-input" type="radio" name="uploadType" id="assets" value="media" (change)="changeOption(uploadRequest)" formControlName="uploadType" />
                    <label class="form-check-label" for="assets">Upload Assets</label>
                  </div>
                  <div class="form-check me-3">
                    <input class="form-check-input" type="radio" name="uploadType" id="metadata" value="metadata" (change)="changeOption(uploadRequest)" formControlName="uploadType" />
                    <label class="form-check-label" for="metadata">Upload Metadata</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="uploadType" id="placeholder" value="placeholder" (change)="changeOption(uploadRequest)" formControlName="uploadType" />
                    <label class="form-check-label" for="placeholder">Create Placeholder</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" *ngIf="uploadRequest.value.uploadType == 'metadata' && isClientTemplateAvailable === true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="templateType">Template Type</label>
                <div class="d-flex">
                  <div class="form-check me-3">
                    <input class="form-check-input" type="radio" name="templateType" id="tylie" value="tylie" formControlName="templateType" />
                    <label class="form-check-label" for="tylie">Tylie Template</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="templateType" id="client" value="client" formControlName="templateType" />
                    <label class="form-check-label" for="client">Client Template</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="uploadRequest.value.uploadType == 'metadata'">
            <div class="col-lg-8 col-sm-8 col-12">
              <div class="tylie-form-group form-group">
                <div class="d-flex justify-content-between">
                  <label class="tylie-form-group__lbl" for="Format">
                    Metadata File<span class="required">*</span>
                  </label>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadMetadataTemplate()">Click Here for Metadata Template</button>
                </div>
                <div class="tylie-upload input-group">
                  <label class="input-group-btn">
                    <span class="tylie-upload__action tylie-button tylie-button--sm">
                      Browse<input type="file" style="display: none;" #excelFileInput (change)="incomingfile($event)" required accept=".xlsx,.xls" [id]="metadataFilee" name="metadataFile">
                    </span>
                  </label>
                  <input type="text" class="tylie-text form-control" readonly="" value="{{metadataExcelFile != null ? metadataExcelFile.name : ''}}">
                </div>
                <span class="help-block hide error">
                  Please choose Metadata excel
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="uploadRequest.value.uploadType == 'placeholder'">
            <div class="row">
              <div class="col-4">
                <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['adID'].errors && f.submitted}">
                  <label class="tylie-form-group__lbl" for="adid">Ad-ID<span class="required">*</span></label>
                  <input type="text" name="adid" id="adid" class="tylie-text form-control" formControlName="adID" />
                  <p class="p-extra-small">Please Enter Ad-ID</p>
                </div>
              </div>
              <div class="col-4">
                <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['title'].errors && f.submitted}">
                  <label class="tylie-form-group__lbl" for="title">Title<span class="required">*</span></label>
                  <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title" />
                  <p class="p-extra-small">Please select Title</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="tylie-form-group form-group" [ngClass]="{error: uploadRequest.controls['length'].errors && f.submitted}">
                  <label class="tylie-form-group__lbl" for="length">Length<span class="required">*</span></label>
                  <input type="text" name="length" id="length" class="tylie-text form-control" formControlName="length" />
                  <p class="p-extra-small">Please Enter Length</p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="format">Format<span class="required">*</span></label>
                  <dx-select-box name="format"
                                  #format
                                  id="format"
                                  formControlName="format"
                                  [dataSource]="formatDataSource"
                                  class="tylie-select form-control"
                                  valueExpr="id"
                                  displayExpr="name"
                                  placeholder="Select a format...">
                  </dx-select-box>
                  <p class="p-extra-small">Please select Format</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="uploadRequest.value.uploadType == 'media'">
            <div class="col-md-12 added-files-container">
              <h4 class="media-upload-text" *ngIf="anyScheduledFiles() == true"><b>Added Files</b></h4>
              <div class="form-group">
                <div class="added-files" *ngFor="let f of getScheduledFiles()">
                  <span class="file-text">{{f.file}}</span>
                  <span class="error">{{f.error}}</span>
                  <!--<span class="glyphicon glyphicon-remove-sign" (click)="removeFromFiles(f.file)"></span>-->
                  <a class="tylie-button tylie-button--link"
                     (click)="removeFromFiles(f.file)">
                    <icon-delete color="#ff0009"></icon-delete>
                  </a>
                </div>
              </div>
              <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon" *ngIf="uploadType == 'aspera'"><span class="tylie-button__icon"><icon-eye></icon-eye></span><span class="tylie-button__text">Add Files</span></button>
              <div *ngIf="uploadType == 's3'">
                <label class="btn tyl-btn btn-extra-small">
                  <i class="glyphicon glyphicon-eye-open">&nbsp;</i>Add Files           
                  <input #asset_file type="file" multiple style="display:none" (change)="incomingAssetsForS3Upload($event, uploadRequest)">
                </label>
              </div>
              <div class="my-3">
                <p class="tylie-alert tylie-alert--error">Note: Please use Chrome or Firefox browser for your Asset uploads.</p>
                <p class="tylie-alert tylie-alert--error" *ngIf="noFilesChosen == true && anyScheduledFiles() == false && !isMaxSessionsExceeded">Please select one or more files to upload</p>
                <p class="tylie-alert tylie-alert--error" *ngIf="isMaxFilesExceeded()">{{maxFilesUploadError}}</p>
                <p class="tylie-alert tylie-alert--error" *ngIf="isMaxSessionsExceeded && anyScheduledFiles() == true">{{maxSessionsUploadError}}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              
              <button type="submit" class="tylie-button tylie-button--sm tylie-button--icon" *ngIf="uploadRequest.value.uploadType == 'placeholder' || uploadRequest.value.uploadType == 'metadata'">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">{{uploadRequest.value.uploadType == 'placeholder' ? ' CREATE PLACEHOLDER' : '  UPLOAD METADATA'}}</span>
              </button>
              <button type="button" class="tylie-button tylie-button--sm tylie-button--icon" *ngIf="uploadRequest.value.uploadType == 'media'" (click)="checkSpotPattern()">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">UPLOAD ASSETS</span>
              </button>
             
            </div>
          </div>
      </form>
    </div>
  </div>

  <div class="tylie-panel" id="media-uploading" *ngIf="anySubmittedFiles() == true">
      <div class="tylie-panel__body">
        <h4 class="media-upload-text"><b>Upload Status</b><span class="action" (click)="clearCompleted()">Clear Completed</span></h4>
        <div class="group-progress" *ngFor="let aFS of getSubmittedFiles()">
          <p class="p-normal client-up-name">Started at {{aFS.groupHeader}}</p>
          <div class="progress-container" *ngFor="let f of aFS.fileStatus">
            <div class="progress progress-left">
              <div class="progress-bar" role="progressbar"
                   [attr.aria-valuenow]="f.percentage"
                   aria-valuemin="0"
                   aria-valuemax="100"
                   [style.width]="(f.percentage + '%')">
              </div>
            </div>
            <div class=" progress-right">
              <p>{{f.bytesWritten}}MB / {{f.bytesExpected}}MB</p>
              <p>{{f.calculatedRateKbps}}mbps - {{f.status}}</p>
            </div>
            <div class=" progress-download-right">
              <i class="glyphicon glyphicon-upload" [ngClass]="{submitted: f.status == 'submitted', processing: f.status == 'running', '': f.status == 'failed'}" *ngIf="f.status != 'completed'"></i>
              <i class="" *ngIf="f.status == 'completed'">
                <svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <style type="text/css">
                  .st0 {
                    fill: #59bc25;
                  }

                  .st1 {
                    fill: #fff;
                  }
</style><g><circle class="st0" cx="64" cy="64" r="64"></circle></g><g><path class="st1" d="M54.3,97.2L24.8,67.7c-0.4-0.4-0.4-1,0-1.4l8.5-8.5c0.4-0.4,1-0.4,1.4,0L55,78.1l38.2-38.2   c0.4-0.4,1-0.4,1.4,0l8.5,8.5c0.4,0.4,0.4,1,0,1.4L55.7,97.2C55.3,97.6,54.7,97.6,54.3,97.2z"></path></g>
             </svg>
              </i>
            </div>
            <div class="file-name">
              <p class="name">{{f.file}}</p>
              <p class="error" *ngIf="f.error && f.error.length > 0">{{f.error}}</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</section>
