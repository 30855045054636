import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-targaimages',
  templateUrl: './servicetargeimages.component.html'
})
export class ServiceTargaImagesQComponent extends BaseServiceDataCollectionComponent {

  public optionsFormat: Array<any> = [];

  public selectedFormats: Array<string> = [];

  @ViewChild('treeFormat', { static: false }) treeFormat: DxTreeViewComponent;

  constructor(private cdr: ChangeDetectorRef) {
    super();

    this.selectedFormats = [];
  }

  ngOnInit() {

    super.ngOnInit();

    this.optionsFormat.push({ name: 'JPG', value: 'JPG' });
    this.optionsFormat.push({ name: 'TIFF', value: 'TIFF' });
    this.optionsFormat.push({ name: 'PNG', value: 'PNG' });

    if (this.formGroup.controls["format"].value == null) {
      this.formGroup.controls["format"].setValue([])
      this.selectedFormats = [];
    }
    else {
      this.selectedFormats = this.formGroup.controls["format"].value;
    }
  }

  public onFormatTreeChanged(e) {
    this.selectedFormats = e.component.getSelectedNodesKeys();
    this.cdr.detectChanges();
  }

  public onFormatChanged(e = null) {

    var component = (e && e.component) || (this.treeFormat && this.treeFormat.instance);

    if (!component) return;

    if (!this.selectedFormats.length) {
      component.unselectAll();
    }

    if (this.selectedFormats) {
      this.selectedFormats.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }

    this.cdr.detectChanges();
  }
}
