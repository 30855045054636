import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, Subject, Subscription } from "rxjs";
import { catchError, map, mergeMap, retry } from "rxjs/operators";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { Constants } from "../../configurations/constants";
import { LogSeverity } from "../../models/core/logmessage.model";
import { FileUploadModel, InitiateMultipartResponse, MultipartAbortRequest, MultipartAbortResponse, MultipartCompleteUploadRequest, MultipartCompleteUploadResponse, MultipartSignedURLRequest, MultipartSignedURLResponse, PartETag } from "../../models/project/project-file-upload.model";
import { AdFileS3UploadRequest, SpotS3UploadRequest } from "../../models/upload/upload-request.model";
import { MessageSeverity } from "../core/alert.service";
import { Utilities } from "../core/utilities";

export class FilePartProcess {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  projectId: number;
  folderId: number;
  uploadId: string;
  fileGuid: string;
  partNumber: number;
  fileName: string;
  part: Blob;
  signedUrl: string;
  eTag = {} as PartETag;
  isRunning: boolean;
  isComplete: boolean;
  constructor(projectId: number, folderId: number, uploadId: string, fileGuid: string, partNumber: number, fileName: string, part: Blob, http: HttpClient, apiEndPoint: ApiEndPoint) {
    this.projectId = projectId;
    this.folderId = folderId;
    this.uploadId = uploadId;
    this.fileGuid = fileGuid;
    this.partNumber = partNumber;
    this.fileName = fileName;
    this.part = part;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
  }

  uploadPart() {
    this.isRunning = true;
    return new Promise((res, rej) => {
      let multipartSignedURLRequest: MultipartSignedURLRequest =
      {
        uploadId: this.uploadId, partNumber: this.partNumber, fileGuid: this.fileGuid, fileName: this.fileName, uploadDestination: "", isS3AccelerationEnabled: false
      }
      this.getPartUploadPresignedURL(multipartSignedURLRequest).subscribe(response => {
        if (response.isSuccess) {
          this.uploadFileToS3(response.presignedUrl.url, "application/octet-stream", this.part).subscribe(y => {
            if (y.status == 200) {
              this.eTag.eTag = JSON.parse(y.headers.get('ETag'));
              this.eTag.partNumber = response.partNumber;
              this.part = null;
              this.isComplete = true;
              this.isRunning = false;

              res(this.eTag);
            }
            else {
              rej();
            }
          }, error => {
            rej();
          })
        }
        else {
          rej();
        }
      }, error => {
        rej();
      })
    });
  }

  private uploadFileToS3(fileuploadurl: string, contenttype: string, file: Blob) {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");
    header = header.append("Content-Type", contenttype);
    return this.http.put(fileuploadurl, file, { headers: header, observe: 'response' });
  }

  private getPartUploadPresignedURL(request: MultipartSignedURLRequest): Observable<MultipartSignedURLResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId': [this.projectId.toString(),
      this.folderId ? this.folderId.toString() : '']
    });
    let options = { headers: headers };
    return this.http.post<MultipartSignedURLResponse>(this.apiEndPoint.getPartUploadPresignedURL(), request, options);
  }
}

export class FileUploadQueue {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  queueSize = 10;
  totalParts: number;
  file: File;
  initiateMultipartRequest: FileUploadModel;
  chunkSize = Constants.multipart_chunk_size;
  fileParts = [];
  uploadId: string;
  fileGuid: string;
  fileChunk: Blob;
  public status: Subject<boolean> = new Subject<false>();
  public forbiddenStatus: Subject<boolean> = new Subject<false>();
  partETags = {} as MultipartCompleteUploadRequest;
  abortUploadModel = {} as MultipartAbortRequest;
  errorOccured = false;
  subscriptions: Subscription[] = [];
  constructor(initiateMultipartRequest: FileUploadModel, file: File, http: HttpClient, apiEndPoint: ApiEndPoint) {
    this.initiateMultipartRequest = initiateMultipartRequest;
    this.file = file;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
    this.findTotalParts();
    this.initiateMultipartUpload();
  }

  initiateMultipartUpload(): void {
    let headers = new HttpHeaders({
      'fileCollectionId':
        [this.initiateMultipartRequest.projectId.toString(),
        this.initiateMultipartRequest.folderId ? this.initiateMultipartRequest.folderId.toString() : '']
    });
    let options = { headers: headers };
    this.http.post<InitiateMultipartResponse>(this.apiEndPoint.initiateMultipartUpload(), this.initiateMultipartRequest, options)
      .subscribe(res => {
        if (res.isSuccess) {
          this.uploadId = res.uploadId;
          this.fileGuid = res.fileGuid;
          this.createFileChunk(1);
          this.fileParts.push(new FilePartProcess(
            this.initiateMultipartRequest.projectId, this.initiateMultipartRequest.folderId, this.uploadId, this.fileGuid, 1, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint
          ));
          this.run();
        }
        else {
          this.status.next(false);
        }
      }, error => {
        if (error.status === 403) {
          this.forbiddenStatus.next(true);
        }
        else {
          this.status.next(false);
        }
      });
  }

  createFileChunk(partNumber: number) {
    const start = (partNumber - 1) * this.chunkSize;
    const end = (partNumber) * this.chunkSize
    this.fileChunk = this.file.slice(start, end);
  }

  findTotalParts() {
    // find total number of parts possible in the file!
    this.totalParts = Math.ceil(this.file.size / this.chunkSize);
  }

  parseNextPart(partNumber) { // 
    if (partNumber <= this.totalParts) {
      this.createFileChunk(partNumber);
      this.fileParts.push(new FilePartProcess(
        this.initiateMultipartRequest.projectId, this.initiateMultipartRequest.folderId, this.uploadId, this.fileGuid, partNumber, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint
      ));
    }
  }

  get runNext() {
    return this.fileParts.filter(x => x.isRunning).length < this.queueSize && this.fileParts.some(x => !x.isComplete);
  }

  composeAbortUploadModel() {
    this.abortUploadModel = {
      fileGuid: this.fileGuid,
      fileName: this.initiateMultipartRequest.fileName,
      uploadId: this.uploadId,
      uploadDestination: ""
    }
  }

  run() {
    while (this.runNext) {
      const filePart = this.fileParts.find(f => !f.isRunning && !f.isComplete);
      if (filePart) {
        filePart.uploadPart().then(res => {
          this.run();
        }).catch(rej => {
          if (!this.errorOccured) {
            this.errorOccured = true;
            this.composeAbortUploadModel();
            this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
              this.fileParts = [];
              this.status.next(false);
            }, error => {
              this.fileParts = [];
              this.status.next(false);
            });
          }
        });
        this.parseNextPart(filePart.partNumber + 1);
      } else {
        break;
      }
    }
    if (this.fileParts.filter(x => x.isComplete).length === this.totalParts) {
      const eTags = this.fileParts.map(x => ({ partNumber: x.partNumber, eTag: x.eTag.eTag }));
      this.partETags.fileName = this.initiateMultipartRequest.fileName;
      this.partETags.fileGuid = this.fileGuid;
      this.partETags.uploadId = this.uploadId;
      this.partETags.partETag = eTags;
      this.completeMultipartUpload(this.partETags).subscribe(res => {
        if (res.isSuccess) {
          this.status.next(true);
        }
        else {
          this.composeAbortUploadModel();
          this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
          }, error => {
            this.status.next(false);
          });
          this.status.next(false);
        }
      }, error => {
        this.composeAbortUploadModel();
        this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
        }, error => {
          this.status.next(false);
        });
        this.status.next(false);
      });
    }
  }

  private completeMultipartUpload(request: MultipartCompleteUploadRequest): Observable<MultipartCompleteUploadResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId':
        [this.initiateMultipartRequest.projectId.toString(),
        this.initiateMultipartRequest.folderId ? this.initiateMultipartRequest.folderId.toString() : '']
    });
    let options = { headers: headers };
    return this.http.post<MultipartCompleteUploadResponse>(this.apiEndPoint.completeMultipartUpload(), request, options);
  }

  private abortMultipartUpload(request: MultipartAbortRequest): Observable<MultipartAbortResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId':
        [this.initiateMultipartRequest.projectId.toString(),
        this.initiateMultipartRequest.folderId ? this.initiateMultipartRequest.folderId.toString() : '']
    });
    let options = { headers: headers };
    return this.http.post<MultipartAbortResponse>(this.apiEndPoint.abortMultipartUpload(), request, options);
  }
}

export class AssetPartProcess {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  uploadId: string;
  fileGuid: string;
  partNumber: number;
  fileName: string;
  part: Blob;
  signedUrl: string;
  eTag = {} as PartETag;
  isRunning: boolean;
  isComplete: boolean;
  uploadDestination: string;
  isS3AccelerationEnabled: boolean;

  constructor(uploadId: string, fileGuid: string, partNumber: number, fileName: string, part: Blob, http: HttpClient, apiEndPoint: ApiEndPoint, uploadDestination: string, isS3AccelerationEnabled: boolean) {
    this.uploadId = uploadId;
    this.fileGuid = fileGuid;
    this.partNumber = partNumber;
    this.fileName = fileName;
    this.part = part;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
    this.uploadDestination = uploadDestination;
    this.isS3AccelerationEnabled = isS3AccelerationEnabled;
  }


  //uploadPart() {
  //  this.isRunning = true;

  //  let multipartSignedURLRequest: MultipartSignedURLRequest =
  //  {
  //    uploadId: this.uploadId, partNumber: this.partNumber, fileGuid: this.fileGuid, fileName: this.fileName, uploadDestination: this.uploadDestination
  //  }

  //  return this.getPartUploadPresignedURL(multipartSignedURLRequest, this.fileName).pipe(
  //    mergeMap(res => {
  //      console.log(res)
  //      if (res.isSuccess) {
  //        if (res.presignedUrl) {
  //          return this.uploadToS3(res.presignedUrl.url, this.part);
  //        }
  //      }
  //      return of(res);
  //    }),
  //    mergeMap(res3 => {
  //      console.log(res3);
  //      if (res3 != null) {
  //        if (res3.status == 200) {
  //          this.eTag.eTag = JSON.parse(res3.headers.get('ETag'));
  //          this.eTag.partNumber = this.partNumber;
  //          this.part = null;
  //          this.isComplete = true;
  //          this.isRunning = false;
  //        }
  //        return of(res3);//isrunning = false
  //      }
  //      else {
  //        return of(res3);//isrunning = false
  //      }
  //    }))
  //}

  uploadPart() {
    console.log("upload part");
    this.isRunning = true;
    return new Promise((res, rej) => {
      let multipartSignedURLRequest: MultipartSignedURLRequest =
      {
        uploadId: this.uploadId, partNumber: this.partNumber, fileGuid: this.fileGuid, fileName: this.fileName, uploadDestination: this.uploadDestination, isS3AccelerationEnabled: this.isS3AccelerationEnabled
      }
      this.getPartUploadPresignedURL(multipartSignedURLRequest).subscribe(response => {
        if (response.isSuccess) {
          this.uploadFileToS3(response.presignedUrl.url, "application/octet-stream", this.part).subscribe(y => {
            if (y.status == 200) {
              this.eTag.eTag = JSON.parse(y.headers.get('ETag'));
              this.eTag.partNumber = response.partNumber;
              this.part = null;
              this.isComplete = true;
              this.isRunning = false;

              res(this.eTag);
            }
            else {
              rej();
            }
          }, error => {
            rej();
          })
        }
        else {
          rej();
        }
      }, error => {
        rej();
      })
    });
  }


  private uploadToS3(presignedUrl: string, uploadedFile: Blob) {
    return this.uploadFileToS3(presignedUrl, "application/octet-stream", uploadedFile).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((error: any) => {
        return error;
      }))
  }


  private uploadFileToS3(fileuploadurl: string, contenttype: string, file: Blob) {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");
    header = header.append("Content-Type", contenttype);
    return this.http.put(fileuploadurl, file, { headers: header, observe: 'response' });
  }

  //private uploadFileToS3(fileuploadurl: string, contenttype: string, file: Blob) {
  //  var header = new HttpHeaders();
  //  header = header.append("Skip-Token", "skip");
  //  header = header.append("Content-Type", contenttype);
  //  return this.http.put(fileuploadurl, file, { headers: header, observe: 'events', reportProgress: true, responseType: 'blob' }).pipe(retry(3));
  //}

  private getPartUploadPresignedURL(request: MultipartSignedURLRequest): Observable<MultipartSignedURLResponse> {
    console.log(request)
    return this.http.post<MultipartSignedURLResponse>(this.apiEndPoint.getAssetPartUploadPresignedURL(), request);
  }
}

export class AssetUploadQueue {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  util: Utilities;
  queueSize = 10;
  totalParts: number;
  file: File;
  initiateMultipartRequest: SpotS3UploadRequest;
  chunkSize = Constants.multipart_chunk_size;
  fileParts = [];
  uploadId: string;
  fileGuid: string;
  fileChunk: Blob;
  public assetstatus: Subject<any> = new Subject<any>();
  public forbiddenStatus: Subject<boolean> = new Subject<false>();
  partETags = {} as MultipartCompleteUploadRequest;
  abortUploadModel = {} as MultipartAbortRequest;
  errorOccured = false;
  subscriptions: Subscription[] = [];
  constructor(initiateMultipartRequest: SpotS3UploadRequest, file: File, http: HttpClient, apiEndPoint: ApiEndPoint, util: Utilities) {
    this.initiateMultipartRequest = initiateMultipartRequest;
    this.file = file;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
    this.util = util;
    this.initiateMultipartRequest.uploadDestination = initiateMultipartRequest.uploadDestination;

    this.findTotalParts();
    this.initiateMultipartUpload(file.name);
  }

  initiateMultipartUpload(currentFileName): void {
    this.initiateMultipartRequest.fileName = currentFileName;

    this.http.post<InitiateMultipartResponse>(this.apiEndPoint.initiateAssetMultipartUpload(), this.initiateMultipartRequest)
      .subscribe(res => {
        if (res.isSuccess) {
          this.uploadId = res.uploadId;
          this.fileGuid = res.fileGuid;
          this.createFileChunk(1);
          
          this.fileParts.push(new AssetPartProcess(
            this.uploadId, this.fileGuid, 1, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint, this.initiateMultipartRequest.uploadDestination, this.initiateMultipartRequest.isS3AccelerationEnabled
          ));

          this.assetstatus.next({ loaded: Constants.minprogress_chunk_size, total: this.file.size, type: "running" });

          this.run();
        }
        else {
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        }
      }, error => {

        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });

      });
  }

  createFileChunk(partNumber: number) {
    const start = (partNumber - 1) * this.chunkSize;
    const end = (partNumber) * this.chunkSize
    this.fileChunk = this.file.slice(start, end);
  }

  findTotalParts() {
    // find total number of parts possible in the file!
    this.totalParts = Math.ceil(this.file.size / this.chunkSize);
  }

  parseNextPart(partNumber) { // 
    if (partNumber <= this.totalParts) {
      this.createFileChunk(partNumber);
      this.fileParts.push(new AssetPartProcess(
        this.uploadId, this.fileGuid, partNumber, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint, this.initiateMultipartRequest.uploadDestination, this.initiateMultipartRequest.isS3AccelerationEnabled
      ));
    }
  }

  get runNext() {
    return this.fileParts.filter(x => x.isRunning).length < this.queueSize && this.fileParts.some(x => !x.isComplete);
  }

  composeAbortUploadModel() {
    this.abortUploadModel = {
      fileGuid: this.fileGuid,
      fileName: this.initiateMultipartRequest.fileName,
      uploadId: this.uploadId,
      uploadDestination: this.initiateMultipartRequest.uploadDestination
    }
  }


  run() {
    while (this.runNext) {
      const filePart = this.fileParts.find(f => !f.isRunning && !f.isComplete);
      if (filePart) {
        filePart.uploadPart().then(res => {
          if ((this.fileParts.filter(x => x.isComplete).length * this.chunkSize) < this.file.size) {

            this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "running" });
          }
          this.run();
        }).catch(rej => {
          if (!this.errorOccured) {
            this.errorOccured = true;
            this.composeAbortUploadModel();
            this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
              this.fileParts = [];
              this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
            }, error => {
              this.fileParts = [];
              this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
            });
          }
        });
        this.parseNextPart(filePart.partNumber + 1);
      } else {
        break;
      }
    }
    if (this.fileParts.filter(x => x.isComplete).length === this.totalParts) {
      const eTags = this.fileParts.map(x => ({ partNumber: x.partNumber, eTag: x.eTag.eTag }));
      this.partETags.fileName = this.initiateMultipartRequest.fileName;
      this.partETags.fileGuid = this.fileGuid;
      this.partETags.uploadId = this.uploadId;
      this.partETags.partETag = eTags;
      this.partETags.uploadDestination = this.initiateMultipartRequest.uploadDestination;
      this.partETags.userGroups = this.initiateMultipartRequest.userGroups;
      this.completeMultipartUpload(this.partETags).subscribe(res => {
        if (res.isSuccess) {
          this.assetstatus.next({ loaded: this.file.size, total: this.file.size, type: "completed" });
        }
        else {
          this.composeAbortUploadModel();
          this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
          }, error => {
            this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
          });
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        }
      }, error => {
        this.composeAbortUploadModel();
        this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
        }, error => {
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        });
        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
      });
    }
  }


  //run() {
  //  while (this.runNext) {
  //    const filePart = this.fileParts.find(f => !f.isRunning && !f.isComplete);
  //    if (filePart) {
  //      filePart.uploadPart().subscribe(res => {
  //        if ((this.fileParts.filter(x => x.isComplete).length * this.chunkSize) < this.file.size) {

  //          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "running" });
  //        }
  //        this.run();
  //      },
  //        error => {
  //          this.composeAbortUploadModel();
  //          this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
  //          }, error => {
  //            this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //          });
  //          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //        });
  //      this.parseNextPart(filePart.partNumber + 1);
  //    } else {
  //      break;
  //    }
  //  }
  //  if (this.fileParts.filter(x => x.isComplete).length === this.totalParts) {
  //    const eTags = this.fileParts.map(x => ({ partNumber: x.partNumber, eTag: x.eTag.eTag }));
  //    this.partETags.fileName = this.initiateMultipartRequest.fileName;
  //    this.partETags.fileGuid = this.fileGuid;
  //    this.partETags.uploadId = this.uploadId;
  //    this.partETags.partETag = eTags;
  //    this.partETags.uploadDestination = this.initiateMultipartRequest.uploadDestination;

  //    this.completeMultipartUpload(this.partETags).subscribe(res => {
  //      if (res.isSuccess) {
  //        this.assetstatus.next({ loaded: this.file.size, total: this.file.size, type: "completed" });
  //      }
  //      else {
  //        this.composeAbortUploadModel();
  //        this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
  //        }, error => {
  //          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //        });
  //        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //      }
  //    }, error => {
  //      this.composeAbortUploadModel();
  //      this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
  //      }, error => {
  //        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //      });
  //      this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
  //    });
  //  }
  //}

  private completeMultipartUpload(request: MultipartCompleteUploadRequest): Observable<MultipartCompleteUploadResponse> {
    return this.http.post<MultipartCompleteUploadResponse>(this.apiEndPoint.completeAssetMultipartUpload(), request);
  }

  private abortMultipartUpload(request: MultipartAbortRequest): Observable<MultipartAbortResponse> {
    return this.http.post<MultipartAbortResponse>(this.apiEndPoint.abortAssetMultipartUpload(), request);
  }
}


export class AdFileUploadQueue {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  util: Utilities;
  queueSize = 10;
  totalParts: number;
  file: File;
  initiateMultipartRequest: AdFileS3UploadRequest;
  chunkSize = Constants.multipart_chunk_size;
  fileParts = [];
  uploadId: string;
  fileGuid: string;
  fileChunk: Blob;
  public assetstatus: Subject<any> = new Subject<any>();
  public forbiddenStatus: Subject<boolean> = new Subject<false>();
  partETags = {} as MultipartCompleteUploadRequest;
  abortUploadModel = {} as MultipartAbortRequest;
  errorOccured = false;
  subscriptions: Subscription[] = [];
  constructor(initiateMultipartRequest: AdFileS3UploadRequest, file: File, http: HttpClient, apiEndPoint: ApiEndPoint, util: Utilities) {
    this.initiateMultipartRequest = initiateMultipartRequest;
    this.file = file;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
    this.util = util;
    this.initiateMultipartRequest.uploadDestination = initiateMultipartRequest.uploadDestination;

    this.findTotalParts();
    this.initiateMultipartUpload(file.name);
  }

  initiateMultipartUpload(currentFileName): void {
    this.initiateMultipartRequest.fileName = currentFileName;

    this.http.post<InitiateMultipartResponse>(this.apiEndPoint.initiateAdFileMultipartUpload(), this.initiateMultipartRequest)
      .subscribe(res => {
        if (res.isSuccess) {
          this.uploadId = res.uploadId;
          this.fileGuid = res.fileGuid;
          this.createFileChunk(1);

          this.fileParts.push(new AdFilePartProcess(
            this.uploadId, this.fileGuid, 1, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint, this.initiateMultipartRequest.uploadDestination, this.initiateMultipartRequest.isS3AccelerationEnabled
          ));

          this.assetstatus.next({ loaded: Constants.minprogress_chunk_size, total: this.file.size, type: "running" });

          this.run();
        }
        else {
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        }
      }, error => {

        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });

      });
  }

  createFileChunk(partNumber: number) {
    const start = (partNumber - 1) * this.chunkSize;
    const end = (partNumber) * this.chunkSize
    this.fileChunk = this.file.slice(start, end);
  }

  findTotalParts() {
    // find total number of parts possible in the file!
    this.totalParts = Math.ceil(this.file.size / this.chunkSize);
  }

  parseNextPart(partNumber) { // 
    if (partNumber <= this.totalParts) {
      this.createFileChunk(partNumber);
      this.fileParts.push(new AdFilePartProcess(
        this.uploadId, this.fileGuid, partNumber, this.initiateMultipartRequest.fileName, this.fileChunk, this.http, this.apiEndPoint, this.initiateMultipartRequest.uploadDestination, this.initiateMultipartRequest.isS3AccelerationEnabled
      ));
    }
  }

  get runNext() {
    return this.fileParts.filter(x => x.isRunning).length < this.queueSize && this.fileParts.some(x => !x.isComplete);
  }

  composeAbortUploadModel() {
    this.abortUploadModel = {
      fileGuid: this.fileGuid,
      fileName: this.initiateMultipartRequest.fileName,
      uploadId: this.uploadId,
      uploadDestination: this.initiateMultipartRequest.uploadDestination
    }
  }


  run() {
    while (this.runNext) {
      const filePart = this.fileParts.find(f => !f.isRunning && !f.isComplete);
      if (filePart) {
        filePart.uploadPart().then(res => {
          if ((this.fileParts.filter(x => x.isComplete).length * this.chunkSize) < this.file.size) {

            this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "running" });
          }
          console.log(res);
          this.run();
        }).catch(rej => {
          console.log("rej");
          console.log(rej);
          if (!this.errorOccured) {
            this.errorOccured = true;
            this.composeAbortUploadModel();
            this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
              this.fileParts = [];
              this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
            }, error => {
              this.fileParts = [];
              this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
            });
          }
        });
        this.parseNextPart(filePart.partNumber + 1);
      } else {
        break;
      }
    }
    if (this.fileParts.filter(x => x.isComplete).length === this.totalParts) {
      const eTags = this.fileParts.map(x => ({ partNumber: x.partNumber, eTag: x.eTag.eTag }));
      this.partETags.fileName = this.initiateMultipartRequest.fileName;
      this.partETags.fileGuid = this.fileGuid;
      this.partETags.uploadId = this.uploadId;
      this.partETags.partETag = eTags;
      this.partETags.uploadDestination = this.initiateMultipartRequest.uploadDestination;
      this.completeMultipartUpload(this.partETags).subscribe(res => {
        if (res.isSuccess) {
          this.assetstatus.next({ loaded: this.file.size, total: this.file.size, type: "completed" });
        }
        else {
          this.composeAbortUploadModel();
          console.log("res");
          console.log(res);
          this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
          }, error => {
            this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
          });
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        }
      }, error => {
        this.composeAbortUploadModel();
          console.log("Error");
          console.log(error);
        this.abortMultipartUpload(this.abortUploadModel).subscribe(res => {
        }, error => {
          this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
        });
        this.assetstatus.next({ loaded: this.fileParts.filter(x => x.isComplete).length * this.chunkSize, total: this.file.size, type: "failed" });
      });
    }
  }

  private completeMultipartUpload(request: MultipartCompleteUploadRequest): Observable<MultipartCompleteUploadResponse> {
    return this.http.post<MultipartCompleteUploadResponse>(this.apiEndPoint.completeAdFileMultipartUpload(), request);
  }

  private abortMultipartUpload(request: MultipartAbortRequest): Observable<MultipartAbortResponse> {
    return this.http.post<MultipartAbortResponse>(this.apiEndPoint.abortAdFileMultipartUpload(), request);
  }
}

export class AdFilePartProcess {
  http: HttpClient;
  apiEndPoint: ApiEndPoint;
  uploadId: string;
  fileGuid: string;
  partNumber: number;
  fileName: string;
  part: Blob;
  signedUrl: string;
  eTag = {} as PartETag;
  isRunning: boolean;
  isComplete: boolean;
  uploadDestination: string;
  isS3AccelerationEnabled: boolean;

  constructor(uploadId: string, fileGuid: string, partNumber: number, fileName: string, part: Blob, http: HttpClient, apiEndPoint: ApiEndPoint, uploadDestination: string, isS3AccelerationEnabled: boolean) {
    this.uploadId = uploadId;
    this.fileGuid = fileGuid;
    this.partNumber = partNumber;
    this.fileName = fileName;
    this.part = part;
    this.http = http;
    this.apiEndPoint = apiEndPoint;
    this.uploadDestination = uploadDestination;
    this.isS3AccelerationEnabled = isS3AccelerationEnabled;
  }

  uploadPart() {
    console.log("upload part Ad File");
    this.isRunning = true;
    return new Promise((res, rej) => {
      let multipartSignedURLRequest: MultipartSignedURLRequest =
      {
        uploadId: this.uploadId, partNumber: this.partNumber, fileGuid: this.fileGuid, fileName: this.fileName, uploadDestination: this.uploadDestination, isS3AccelerationEnabled: this.isS3AccelerationEnabled
      }
      this.getPartUploadPresignedURL(multipartSignedURLRequest).subscribe(response => {
        if (response.isSuccess) {
          this.uploadFileToS3(response.presignedUrl.url, "application/octet-stream", this.part).subscribe(y => {
            console.log(y);
            if (y.status == 200) {
              this.eTag.eTag = JSON.parse(y.headers.get('ETag'));
              this.eTag.partNumber = response.partNumber;
              this.part = null;
              this.isComplete = true;
              this.isRunning = false;

              res(this.eTag);
            }
            else {
              rej();
            }
          }, error => {
              console.log(error);
            rej();
          })
        }
        else {
          rej();
        }
      }, error => {
          console.log(error);
        rej();
      })
    });
  }


  private uploadToS3(presignedUrl: string, uploadedFile: Blob) {
    return this.uploadFileToS3(presignedUrl, "application/octet-stream", uploadedFile).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((error: any) => {
        return error;
      }))
  }


  private uploadFileToS3(fileuploadurl: string, contenttype: string, file: Blob) {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");
    header = header.append("Content-Type", contenttype);
    return this.http.put(fileuploadurl, file, { headers: header, observe: 'response' });
  }

  private getPartUploadPresignedURL(request: MultipartSignedURLRequest): Observable<MultipartSignedURLResponse> {
    return this.http.post<MultipartSignedURLResponse>(this.apiEndPoint.getAdFilePartUploadPresignedURL(), request);
  }
}
