import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserProfileService } from "../user/user-profile.service";
import { RouteConstants } from "../../configurations/route-constants";
import { tap } from 'rxjs/operators';
import { DBkeys } from '../../configurations/db-Keys';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router,
    private userProfileService: UserProfileService)
   {
      window.addEventListener('storage', this.handleLogoutAcrossTabs.bind(this));
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url;
    return this.redirectIfUnauthenticated(url) && this.routeAuthFilter(route, url);
  }

  redirectIfUnauthenticated(url: string) {
    return this.authService.isLoggedIn();
  }

  routeAuthFilter(route: ActivatedRouteSnapshot, url: string) {
    let routeData = route.data['roles'] as Array<string>;
    let userRoles: string[] = this.userProfileService.getUserRoles();

    if (routeData != undefined) {
      for (let role of routeData) {
        if (userRoles.some(r => r.toLowerCase().startsWith(role.toLowerCase()))) {
          return true;
        }
      }

      if (this.authService.isLoggedIn() == true) {
        this.router.navigate([RouteConstants.homeRoute]);
        return true;
      }
      else {
        this.router.navigate([RouteConstants.loginRoute]);
        return false;
      }
    }
    else { return true; }
  }

  private handleLogoutAcrossTabs(event: StorageEvent): void {
    if (event.key === DBkeys.CURRENT_USER && event.newValue === null) {
      this.authService.forceLogOut(); 
    }
  }
}
