<div [formGroup]="formGroup" *ngIf="formGroup.controls['isDeleted'].value === false" class="tylie-section px-0">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true && addedAsDependency === false" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="tylie-form-group form-group" *ngIf="applyAllMode === true">
    <label>This entry is disabled in Apply All Mode. Please edit later.</label>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['reslate'].errors && f.submitted)}" *ngIf="addedAsDependency === false">
        <label class="form-check">
          <input class="form-check-input" type="checkbox" name="reslate" formControlName="reslate"
                 [attr.disabled]="(applyAllMode === true || isDisabled === true) ? '' : null" />
          <span class="form-check-label">Reslate</span>
        </label>
        <p class="p-extra-small">Please choose an option</p>
      </div>
    </div>
    <div class="col-3">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['keepClientSlate'].errors && f.submitted)}" *ngIf="addedAsDependency === false">
        <label class="form-check">
          <input class="form-check-input" type="checkbox" name="keepClientSlate" formControlName="keepClientSlate"
                 [attr.disabled]="(applyAllMode === true || isDisabled === true) ? '' : null" />
          <span class="form-check-label">Keep Client Slate</span>
        </label>
        <p class="p-extra-small">Please choose an option</p>
      </div>
    </div>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['title'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="title">Title for the Reslated Asset<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="title" formControlName="title" [attr.disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false)) ? '' : null" />
      <p class="p-extra-small">Please enter Title</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['adid'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="adid">Ad-ID for the Reslated Asset<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="adid" formControlName="adid" [attr.disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false)) ? '' : null" />
      <p class="p-extra-small">Please enter Ad-ID</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['advertiser'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="advertiser">Advertiser<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="advertiser" formControlName="advertiser" [attr.disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false)) ? '' : null" />
      <p class="p-extra-small">Please enter Advertiser</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['brand'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="brand" formControlName="brand" [attr.disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false)) ? '' : null" />
      <p class="p-extra-small">Please enter Brand</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['product'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="product" formControlName="product" [attr.disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false)) ? '' : null" />
      <p class="p-extra-small">Please enter Product</p>
    </div>

    <ng-container *ngIf="audioMode === true">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['date'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="date">Date<span class="required">*</span></label>
        <dx-date-box type="date" width="50%" formControlName="date" id="date" class="tylie-select form-control" [disabled]="(applyAllMode === true || isDisabled === true || (formGroup.controls['reslate'].value === false))">
        </dx-date-box>
        <p class="p-extra-small">Please enter Date</p>
      </div>
    </ng-container>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['removeSlate'].errors && f.submitted)}" *ngIf="addedAsDependency === false">
      <label class="form-check">
        <input class="form-check-input" type="checkbox" name="removeSlate" formControlName="removeSlate"
               [attr.disabled]="(applyAllMode === true || isDisabled === true) ? '' : null" />
        <span class="form-check-label">Remove Current Slate</span>
      </label>
      <p class="p-extra-small">Please choose an option</p>
    </div>
  </div>
