<nav class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-header media-text">            
          <h3 class="media-text">Welcome {{userName}}</h3>
        </div>
    </div>
</nav>

<div class="inner-content">

</div>
