import { Component } from '@angular/core';
import { Utilities } from "../../services/core/utilities";
import { UserProfileService } from '../../services/user/user-profile.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  public userName: string = '';

  constructor(public util: Utilities, private userProfileService: UserProfileService) {
    var userProfile = this.userProfileService.getUserProfile();
    if (userProfile! = null && userProfile != undefined)
      this.userName = userProfile.firstName + ' ' + userProfile.lastName;
  }

  ngOnDestroy() {
    //alert("destroyed home");
  }
}
