import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { OrderDestination } from '../../../models/order/order-destinations.model';
import { Subject } from 'rxjs';
import { SubService } from '../../../services/media/sub.service';
import { OrderUserDestination } from '../../../models/order/order-userdestination';
import { DestinationSuggest } from '../../../models/order/order-destinationsuggest.model';
import { OrderInvalidDestination, OrderInvalidAdId, UpdatedResults } from '../../../models/order/order-create.model';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'orderinvaliddatapopup.component.ts',
  templateUrl: './orderinvaliddatapopup.component.html',
  styleUrls: ['./orderinvaliddatapopup.component.css'],
})
export class OrderInvalidDataPopupComponent extends BasePopupComponent {

  @Input() public invalidDestinations: Array<OrderInvalidDestination>;
  @Input() public invalidAdids: Array<OrderInvalidAdId>;

  public copyUploadSuccessDestinations: Array<OrderDestination> = null;
  public copyUploadErrorDestinations: Array<DestinationSuggest> = null;

  public dataSource: any = {};
  public dataSourceSavedList: Array<OrderUserDestination> = null;
  public quickSearchValue: string = '';
  public selectionMethod: string = "";
  public selectionMethodNext: string = "";
  public pastedDestinations: string = "";
  public metadataExcelFile: File;
  public copyUploadResultMessage: string = '';
  public isUploading: boolean = false;
  public onApplyChanges: Subject<UpdatedResults>;
  public updatedResults: UpdatedResults;
  public copyUploadErrorsExist: boolean = false;
  public title: string = '';
  public addMoreInEditSavedDestinationList: boolean = false;
  public savedDestinationListToEdit: OrderUserDestination = null;
  public nameEditInEditSavedDestinationList: boolean = false;
  public destinationsToAddToSavedList: Array<string> = null;
  public DestinationsUpdated: Array<OrderInvalidDestination> = null;
  public AdIdsUpdated: Array<OrderInvalidAdId> = null;

  @ViewChild('destinationsGrid', { static: false }) destinationsGrid: DxDataGridComponent;
  @ViewChild('destinationsSavedListGrid', { static: false }) destinationsSavedListGrid: DxDataGridComponent;
  @ViewChild('destinationErrorsGrid', { static: false }) destinationErrorsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onApplyChanges = new Subject();
    this.title = "Errors"
    this.selectionMethod = 'copyuploadresult';
    this.DestinationsUpdated = null;
    this.DestinationsUpdated = new Array<OrderInvalidDestination>();
    if (this.invalidDestinations == null || this.invalidDestinations.length == 0) {
      this.copyUploadErrorDestinations = [];
      this.copyUploadSuccessDestinations = [];
    }
    this.getSuggestionsForInvalidDestinations();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  private getSuggestionsForInvalidDestinations() {

    var invalidD: string;
    if (this.invalidDestinations == null || this.invalidDestinations.length == 0) {
      this.copyUploadErrorDestinations = [];
      return;
    }
    for (let entry of this.invalidDestinations) {
      if (entry.status == null) {
        if (invalidD == null) {
          invalidD = entry.destination + ",";
        }
        else {
          invalidD = invalidD + entry.destination + ",";
        }
      }
    }

    if (invalidD != null) {

      var request: any = { destinationsText: invalidD };

      this.alertService.ShowLoader(true)

      this.configService.validateDestinationsFromText(request).subscribe((res: any) => {
        this.selectionMethod = 'copyuploadresult';

        if (res.isSuccess == true) {

          this.copyUploadSuccessDestinations = res.result.destinations as Array<OrderDestination>;
          this.copyUploadErrorDestinations = res.result.errorDestinations as Array<any>;

          if (this.copyUploadErrorDestinations != null && this.copyUploadErrorDestinations.length > 0) {
            this.copyUploadResultMessage = "Error parsing " + this.copyUploadErrorDestinations.length.toString() + " out of " + (this.copyUploadSuccessDestinations.length + this.copyUploadErrorDestinations.length).toString() + " destinations";
            this.copyUploadErrorsExist = true;
          }
          else {
            this.copyUploadResultMessage = "Successfully parsed " + this.copyUploadSuccessDestinations.length.toString() + " destinations";
            this.copyUploadErrorsExist = false;
          }
          this.addGroupDetailsToErrorDestinations();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.alertService.ShowLoader(false);
          this.closePopup();
          this.util.handleError(error);
        });
    }
  }

  public OnSuggestedDestinationSelection(searchWord: string, pickedDestinationCode: string) {
    this.OnDestinationContentChange(searchWord, pickedDestinationCode, false, true);
    var suggestDestination = this.copyUploadErrorDestinations.find(t => t.code.toLowerCase() == searchWord.toLowerCase());
    if (suggestDestination != null) {
      var pickedDestination = suggestDestination.destinations.find(t => t.code.toLowerCase() == pickedDestinationCode.toLowerCase());
      if (pickedDestination != null) {
        suggestDestination.selectedDestination = pickedDestination;
        this.updateUserSelectedDetination(searchWord, pickedDestination);
        this.applyCopyUploadErrorCount();
      }
    }
  }

  private addGroupDetailsToErrorDestinations() {
    for (let errDest of this.copyUploadErrorDestinations) {
      if (errDest != null) {
        //errDest.code
        var selectGrp = this.invalidDestinations.filter(i => i.destination.trim().toLowerCase() == errDest.code.trim().toLowerCase());
        if (selectGrp != null && selectGrp.length > 0) {
          if (selectGrp[0].groups != null && selectGrp[0].groups.length > 0) {
            errDest.groupNames = selectGrp[0].groups;
          }
        }


      }
    }
  }

  public onCopyUploadSuccessDestinationDelete(matchNotFoundAdid: string) {

    console.log("this.invalidDestinations")
    console.log(this.invalidDestinations)

    console.log("this.copyUploadSuccessDestinations")
    console.log(this.copyUploadSuccessDestinations)

    this.copyUploadSuccessDestinations = this.copyUploadSuccessDestinations.filter(t => t.code.toLowerCase() != matchNotFoundAdid.toLowerCase());
    //this.invalidDestinations = this.invalidDestinations.filter(t => t.destination.toLowerCase() != matchNotFoundAdid.toLowerCase());
    var objTempArray: Array<OrderInvalidDestination> = null;
    var tempResult = this.invalidDestinations.filter(t => t.destination.toLowerCase().trim() == matchNotFoundAdid.toLowerCase().trim()) as Array<OrderInvalidDestination>;;
    if (tempResult != null && tempResult.length > 0) {
      console.log("this.tempResult")
      console.log(tempResult)
      tempResult[0].isDeletedByUser = true;
      tempResult[0].isModifiedByUser = false;
      tempResult[0].status = "modified";
    }
    console.log("this.invalidDestinations After changes")
    console.log(this.invalidDestinations)
  }

  private updateUserSelectedDetination(code: string, userSelectedDestination: OrderDestination) {
    var dest = this.DestinationsUpdated.filter(i => i.destination.trim().toLowerCase() == code.trim().toLowerCase());
    if (dest != null && dest.length > 0) {
      dest[0].userSelectedDestination = userSelectedDestination;
    }
  }

  public deleteUserSelectedDestination(matchNotFoundDetination: string) {
    var destination = this.copyUploadErrorDestinations.filter(t => t.selectedDestination != null && t.selectedDestination.code.trim().toLowerCase() == matchNotFoundDetination.trim().toLowerCase());
    this.deleteDestination(destination[0].code);
    this.applyCopyUploadErrorCount();
  }

  public deleteDestination(matchNotFoundDetination: string) {
    this.OnDestinationContentChange(matchNotFoundDetination, "DeletedByUser", true, false);
    this.copyUploadErrorDestinations = this.copyUploadErrorDestinations.filter(t => t.code.toLowerCase() != matchNotFoundDetination.trim().toLowerCase());
    this.applyCopyUploadErrorCount();
  }

  private applyCopyUploadErrorCount() {

    var errorDestinationsToBeSelected = this.copyUploadErrorDestinations.filter(t => t.selectedDestination == null);
    var errorDestinationsAlreadySelected = this.copyUploadErrorDestinations.filter(t => t.selectedDestination != null);

    if (this.copyUploadErrorDestinations.length == 0 && this.copyUploadSuccessDestinations.length == 0) {
      this.copyUploadResultMessage = "No Destinations";
      this.copyUploadErrorsExist = true;
    }
    else if (errorDestinationsToBeSelected.length > 0) {
      this.copyUploadResultMessage = "Error parsing " + errorDestinationsToBeSelected.length.toString() + " out of " + (this.copyUploadSuccessDestinations.length + errorDestinationsAlreadySelected.length + + errorDestinationsToBeSelected.length).toString() + " destinations";
      this.copyUploadErrorsExist = true;
    }
    else {
      this.copyUploadResultMessage = "Successfully parsed " + (this.copyUploadSuccessDestinations.length + errorDestinationsAlreadySelected.length).toString() + " destinations";
      this.copyUploadErrorsExist = false;
    }

    if (this.DestinationsUpdated != null && this.DestinationsUpdated.length > 0) {
      var allDeleted = this.DestinationsUpdated.filter(i => i.isDeletedByUser == true && i.correctedDestination == "DeletedByUser");
      if (allDeleted != null && allDeleted.length > 0 && allDeleted.length == this.DestinationsUpdated.length) {
        this.copyUploadErrorsExist = false;
      }

    }

  }

  public deleteUserSelectedAdid(matchNotFoundAdid: string) {
    if (matchNotFoundAdid != null) {
      var selAdid = this.invalidAdids.filter(t => t.adId.toLowerCase() == matchNotFoundAdid.toLowerCase());
      if (selAdid != null) {
        selAdid[0].isDeletedByUser = true;
        selAdid[0].status = "modified";
      }

    }
    // this.applyCopyUploadErrorCount();
  }

  private OnDestinationContentChange(code: string, correctedDestination: string, isDeleted: boolean, isModified: boolean) {

    var objTempArray: Array<OrderInvalidDestination> = null;
    if (this.invalidDestinations != null) {

      var tempResult = this.invalidDestinations.filter(t => t.destination.toLowerCase() == code.toLowerCase());
      if (tempResult != null && tempResult.length > 0) {

        objTempArray = this.DestinationsUpdated.filter(t => t.destination.toLowerCase() == code.toLowerCase()) as Array<OrderInvalidDestination>;

        if (objTempArray != null && objTempArray.length > 0) {
          var invlidDes: OrderInvalidDestination;
          invlidDes = objTempArray[0];
          invlidDes.isDeletedByUser = isDeleted;
          invlidDes.isModifiedByUser = isModified;
          invlidDes.correctedDestination = correctedDestination;
          invlidDes.status = "modified";
        }
        else {
          var invlidDes: OrderInvalidDestination;
          invlidDes = new OrderInvalidDestination();
          invlidDes.isDeletedByUser = isDeleted;
          invlidDes.isModifiedByUser = isModified;
          invlidDes.correctedDestination = correctedDestination;
          invlidDes.destination = tempResult[0].destination;
          invlidDes.status = "modified";
          this.DestinationsUpdated.push(invlidDes);
        }

      }
      else {
        if (isDeleted) {

          objTempArray = this.DestinationsUpdated.filter(t => t.destination.toLowerCase() == code.toLowerCase() || t.correctedDestination.toLowerCase() == code.toLowerCase()) as Array<OrderInvalidDestination>;

          if (objTempArray != null && objTempArray.length > 0) {
            var invlidDes: OrderInvalidDestination;
            invlidDes = objTempArray[0];
            invlidDes.isDeletedByUser = isDeleted;
            invlidDes.isModifiedByUser = isModified;
            invlidDes.correctedDestination = correctedDestination;
            invlidDes.status = "modified";
          }

        }
      }
    }
  }

  public onApplyToOrder() {
    var updatedResults = new UpdatedResults();
    updatedResults.invalidAdids = this.invalidAdids;
    updatedResults.invalidDestinations = this.DestinationsUpdated;
    this.onApplyChanges.next(updatedResults);
    this.closePopup();

  }
}
