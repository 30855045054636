<div class="tylie-panel tylie-panel--secondary">
  <div class="tylie-panel__header tylie-panel__header--br-thick">
    <h4 class="tylie-panel__title">
      <div><span class="me-2"><icon-checkmark-white></icon-checkmark-white></span>SELECT OFFER TAG</div>
    </h4>
  </div>
  <div class="tylie-panel__body">
    <p class="tylie-type-body mb-3">Choose one Offer Tag for your Ad.</p>
    <div class="row">
      <div class="col-sm-4" *ngFor="let offerCard of offerCards">
        <div class="tylie-card">
          <div class="tylie-card__img-wrap">
            <img
              class="tylie-card__img"
              [src]="offerCard.cardDisplayUrl"
              alt="Image"
            />
          </div>
          <div class="tylie-card__body">
            <label class="tylie-card__title">{{ offerCard.cardName }}</label>
            <div class="tylie-data tylie-data--block">
              <ng-container
                *ngIf="offerCard.expirydate; else elseAvailableTemplate"
              >
                <span class="tylie-data__txt">Available:
                  {{
                    offerCard.availableForOrderingFromDate | date : "M/d/yy"
                  }}
                  - {{ offerCard.expirydate | date : "M/d/yy" }}</span>
              </ng-container>
              <ng-template #elseAvailableTemplate>
                <span class="tylie-data__txt">Available: Unlimited</span>
              </ng-template>
            </div>
            <div class="d-flex mt-2">
              <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onSelect(offerCard)">
                <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
                <span class="tylie-button__text">SELECT THIS OFFER TAG</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-records" *ngIf="offerCards?.length === 0">
      <span>No Records found</span>
    </div>
  </div>
</div>
