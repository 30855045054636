
<div class="d-flex justify-content-between" *ngIf="this.vaultAssetDetail">
  <div>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isLoggedOut == true">Logged out</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isStagging == true">Staging</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isRemoved == true">Removed</label>
    <label class="tylie-badge tylie-badge--success" *ngIf="this.vaultAssetDetail.isInVault == true">In Vault</label>
    <label class="tylie-badge tylie-badge--error" *ngIf="this.vaultAssetDetail.isDestroyed == true">Destroyed</label>
  </div>
  <div class="d-flex">
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="stagingClicked()" *ngIf="this.vaultAssetDetail.isInVault == true">
      <span class="tylie-button__icon"><icon-settings color="#fff"></icon-settings></span>
      <span class="tylie-button__text">Staging</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="backtoClicked()" *ngIf="this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"></span>
      <span class="tylie-button__text">Back to Vault</span>
    </button>

    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="logoutClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-export></icon-export></span>
      <span class="tylie-button__text">Log out</span>
    </button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="removeClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-delete></icon-delete></span>
      <span class="tylie-button__text">Remove</span>
    </button>
    <!--<button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="destroyClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true">
      <span class="tylie-button__icon"><icon-delete type="trash"></icon-delete></span>
      <span class="tylie-button__text">Destroy</span>
    </button>-->

    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="missingClicked()" *ngIf="this.vaultAssetDetail.isInVault == true">
      <span class="tylie-button__icon"><icon-question-mark></icon-question-mark></span>
      <span class="tylie-button__text">Missing</span>
    </button>

    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="editClicked()" *ngIf="this.vaultAssetDetail.isInVault == true || this.vaultAssetDetail.isStagging == true || this.vaultAssetDetail.isLoggedOut == true">
      <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
      <span class="tylie-button__text">Edit</span>
    </button>

    <button type="button" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="loginClicked()" *ngIf="this.vaultAssetDetail.isLoggedOut == true">
    <span class="tylie-button__icon"><icon-vault-login></icon-vault-login></span>
    <span class="tylie-button__text">Login</span>
  </button>
  </div>
</div>

<ng-container *ngIf="this.vaultAssetDetail && this.vaultAssetRequest">
  <form [formGroup]="vaultAssetRequest" #f="ngForm" (ngSubmit)="onSubmitVaultAsset(vaultAssetRequest)">
    <div class="d-flex mt-3">
      <div class="tylie-form-group form-group me-3" *ngIf="this.vaultAssetDetail.isUserVaultAdmin == true">
        <label class="tylie-form-group__lbl">S3 Path</label>
        <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.filePath}}</div>
      </div>
    </div>

    <div class="tylie-accordion accordion">
      <div class="tylie-accordion__panel accordion-item">
        <h2 class="tylie-accordion__heading accordion-header">
          <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#assetCollapseOne" aria-expanded="true" aria-controls="collapseOne">
            Digital Asset Details
          </button>
        </h2>
        <div id="assetCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
          <div class="tylie-accordion__body accordion-body">
            <div class="tylie-accordion__body-content my-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Asset Identifier</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.assetIdentifier}}</div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetIdentifier'].errors && f.submitted}">
                      <input type="text" id="assetIdentifier" name="assetIdentifier" class="tylie-text form-control" formControlName="assetIdentifier">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetIdentifier'].errors?.required">Please enter Asset Identifier</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Title</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.title}}</div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['title'].errors && f.submitted}">
                      <input type="text" id="title" name="title" class="tylie-text form-control" formControlName="title">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['title'].errors?.required">Please enter Title</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Length</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.length}}</div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['length'].errors && f.submitted}">
                      <input type="text" id="length" name="length" class="tylie-text form-control" formControlName="length">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['length'].errors?.required">Please enter Length</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Created</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.createdby}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['createdby'].errors && f.submitted}">
                    <input type="text" id="createdby" name="createdby" disabled class="tylie-text form-control" formControlName="createdby">
                    <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['createdby'].errors?.required">Please enter Created By</p>
                  </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Legacy Master Id</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.legacyMasterId}}
                    </div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['legacyMasterId'].errors && f.submitted}">
                      <input type="text" id="legacyMasterId" name="legacyMasterId" class="tylie-text form-control" formControlName="legacyMasterId">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['legacyMasterId'].errors?.required">Please enter Legacy Master Id</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Legacy Vtm Number</label>
                    <div class="tylie-form-group__txt"  *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.legacyVtmNumber}}</div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['legacyVtmNumber'].errors && f.submitted}">
                    <input type="text" id="legacyVtmNumber" name="legacyVtmNumber" class="tylie-text form-control" formControlName="legacyVtmNumber">
                    <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['legacyVtmNumber'].errors?.required">Please enter Legacy Vtm Number</p>
                  </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Asset Description</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.assetDescription}}</div>
                    <div class="form-group" *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['assetDescription'].errors && f.submitted}">
                      <input type="text" id="assetDescription" name="assetDescription" class="tylie-text form-control" formControlName="assetDescription">
                      <p class="p-extra-small" *ngIf="vaultAssetRequest.controls['assetDescription'].errors?.required">Please enter Asset Description</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Client</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">{{this.vaultAssetDetail.vaultRequestClient}}</div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['clientId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="clientDataSource"
                                     id="client"
                                     name="client"
                                     class="tylie-select form-control"
                                     formControlName="clientId"
                                     [searchEnabled]="true"
                                     valueExpr="id"
                                     displayExpr="name"
                                     [disabled]="true"
                                     placeholder="Select a client...">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Client</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Brand</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestBrand}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['brandId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="brandDataSource"
                                     class="tylie-select form-control"
                                     id="brand"
                                     name="brand"
                                     formControlName="brandId"
                                     [searchEnabled]="true"
                                     valueExpr="id"
                                     displayExpr="name"
                                     noDataText="Please choose a client"
                                     [disabled]="true"
                                     placeholder="Select a brand...">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Brand</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="tylie-form-group form-group">
                    <label class="tylie-form-group__lbl">Product</label>
                    <div class="tylie-form-group__txt" *ngIf="this.IsEditable == false">
                      {{this.vaultAssetDetail.vaultRequestProduct}}
                    </div>
                    <div *ngIf="this.IsEditable == true" [ngClass]="{error: vaultAssetRequest.controls['productId'].errors && f.submitted}">
                      <dx-select-box [dataSource]="productDataSource"
                                     class="tylie-select form-control"
                                     id="product"
                                     name="product"
                                     [searchEnabled]="true"
                                     noDataText="Please choose a brand"
                                     formControlName="productId"
                                     valueExpr="id"
                                     displayExpr="name"
                                     placeholder="Select a product..."
                                     [disabled]="true">
                      </dx-select-box>
                      <p class="p-extra-small">Please select Product</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="tylie-panel tylie-panel--secondary">
                    <div class="tylie-panel__header tylie-panel__header--br-thick">
                      <h4 class="tylie-panel__title">
                        NOTES & HISTORY
                        <div *ngIf="this.IsEditable == false">
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewNote()">
                            <span class="tylie-button__icon">
                              <icon-plus-circle></icon-plus-circle>
                            </span>
                            <span class="tylie-button__text">ADD Note</span>
                          </button>
                        </div>
                      </h4>
                    </div>
                    <div class="tylie-panel__body" *ngIf="this.notes.length == 0">There are no notes and history yet.</div>
                    <div class="tylie-panel__body">
                      <div class="tylie-table-wrapper">
                        <table class="tylie-table table table-bordered" *ngIf="this.notes.length > 0">
                          <tbody class="tylie-table__body">
                            <tr *ngFor="let att of this.notes; let i = index">
                              <td><div class="p-normal">{{att.message}}</div> <span class="p-tiny">by {{att.createdBy}}</span>&nbsp;&nbsp;&nbsp;<span class="p-tiny">{{att.createdDate | usertimezone: 'MM/DD/YYYY HH:mm A z'}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="tylie-panel tylie-panel--secondary">
                    <div class="tylie-panel__header tylie-panel__header--br-thick">
                      <h4 class="tylie-panel__title">
                        ATTACHMENTS
                        <div *ngIf="this.IsEditable == false">
                          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddNewAttachment()">
                            <span class="tylie-button__icon">
                              <icon-plus-circle></icon-plus-circle>
                            </span>
                            <span class="tylie-button__text">ADD ATTACHMENTS</span>
                          </button>
                        </div>
                      </h4>
                    </div>

                    <div class="tylie-panel__body" *ngIf="this.attachments.length == 0">
                      There are no attachments yet.
                    </div>
                    <div class="tylie-panel__body">
                      <div class="tylie-table-wrapper">
                        <table class="tylie-table table table-bordered" *ngIf="this.attachments?.length > 0">
                          <tbody class="tylie-table__body">
                            <tr *ngFor="let att of this.attachments; let i = index">
                              <td class="blue-text" (click)="onDownloadFile(att.vaultFileGuid, att.fileName)">{{att.fileName}}</td>
                              <td align="center">
                                <i class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate">
                                  <icon-trash-can color="#1e90ff"></icon-trash-can>
                                </i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex" *ngIf="this.IsEditable == true">
      <button type="submit" class="tylie-button tylie-button--xs tylie-button--icon">
        <span class="tylie-button__icon"><icon-save></icon-save></span>
        <span class="tylie-button__text">Update Vault Digital Asset</span>
      </button>

      <button type="button" class="tylie-button tylie-button--xs tylie-button--link ms-3" outsideClick="true" placement="top" #clearVaultpop="bs-popover" container="body" [popover]="cancelVaultTemplate">
        Cancel
      </button>
    </div>
  </form>
</ng-container>

<ng-template #deleteAttTemplate let-fileName="fileName" let-vaultFileGuid="vaultFileGuid">

  <button type="button" class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete {{fileName}}?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button>
</ng-template>

<ng-template #cancelVaultTemplate>

  <button type="button" class="popover__close" (click)="onHideClearVaultPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to cancel?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="clearSavedData()">Yes</button>
</ng-template>


