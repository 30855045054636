import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Subject } from 'rxjs';
import { SpotService } from '../../../services/media/spot.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UploadService } from "../../../services/upload/upload.service";
import { SpotFile } from '../../../models/spot/spot-file.model';
import { TruncateByLetters } from '../../../pipes/truncateByLetters.pipe';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'transcodeordercopyuploadassetspopup',
  templateUrl: './transcodeordercopyuploadassetspopup.component.html',
  styleUrls: ['./transcodeordercopyuploadassetspopup.component.css'],
})
export class TranscodeOrderCopyUploadAssetsPopupComponent extends BasePopupComponent {

  @Input() clientId: number;

  public spotsDataSource: Array<SpotFile> = null;
  public errorMessage: string = '';
  public onClose: Subject<Array<SpotFile>>;
  public selectedSpotFiles: Array<SpotFile> = [];
  public pastedAdIds: string = "";
  public selectionMethod: string = null
  public copyUploadSuccessSpotFiles: Array<SpotFile> = null;
  public copyUploadErrorSpotFiles: Array<string> = null;
  public copyUploadResultMessage: string = '';
  public copyUploadErrorsExist: boolean = false;
  public metadataExcelFile: File;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private spotService: SpotService,
    private uploadService: UploadService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.onClose = new Subject();
  }

  public onCopyUploadSuccessSpotFileDelete(spotFileGuid: string) {
    this.copyUploadSuccessSpotFiles = this.copyUploadSuccessSpotFiles.filter(t => t.spotFileGuid.toLowerCase() != spotFileGuid.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  public onCopyUploadErrorSpotFileDelete(adId: string) {
    this.copyUploadErrorSpotFiles = this.copyUploadErrorSpotFiles.filter(t => t.toLowerCase() != adId.toLowerCase());

    this.applyCopyUploadErrorCount();
  }

  public onExcelUploadFileChange(event) {
    this.metadataExcelFile = event.target.files[0];

    if (this.metadataExcelFile == null)
      return;

    var reader = new FileReader();

    reader.readAsDataURL(this.metadataExcelFile);

    reader.onload = () => {
      var request: any = {
        metadataFileType: this.metadataExcelFile.type,
        metadataFileName: this.metadataExcelFile.name,
        metadataFile: (<string>reader.result).split(',')[1],
        client: this.clientId,
        brand: null
      };

      this.alertService.ShowLoader(true)

      this.spotService.ValidateTranscodeOrderSpotsFromFile(request).subscribe((res: any) => {

        this.selectionMethod = 'copypasteresult';

        if (res.isSuccess == true) {

          console.log(res.result);

          var spotfiles = res.result.spotFiles as Array<SpotFile>;

          for (let s of spotfiles) {
            s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));
            s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
            s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);
            s.formatClasses = this.util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
              s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
              s.sap, s.vchip, s.letterBox, s.surround, s.mono);
          }

          this.copyUploadSuccessSpotFiles = spotfiles;
          this.copyUploadErrorSpotFiles = res.result.unMappedSpotFiles as Array<string>;

          if (this.copyUploadErrorSpotFiles != null && this.copyUploadErrorSpotFiles.length > 0) {
            this.copyUploadResultMessage = this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets not found";
            this.copyUploadErrorsExist = true;
          }
          else {
            this.copyUploadResultMessage = this.copyUploadSuccessSpotFiles.length.toString() + " assets found successfully";
            this.copyUploadErrorsExist = false;
          }
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
        this.alertService.ShowLoader(false);
      },
        error => {
          this.alertService.ShowLoader(false);
          this.closePopup();
          this.util.handleError(error);
        });
    }
  }

  private applyCopyUploadErrorCount() {

    if (this.copyUploadSuccessSpotFiles.length == 0 && this.copyUploadErrorSpotFiles.length == 0) {
      this.copyUploadResultMessage = "No Assets";
      this.copyUploadErrorsExist = true;
    }
    else if (this.copyUploadErrorSpotFiles.length > 0) {
      this.copyUploadResultMessage = "Error parsing " + this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets";
      this.copyUploadErrorsExist = true;
    }
    else {
      this.copyUploadResultMessage = "Successfully parsed " + (this.copyUploadSuccessSpotFiles.length).toString() + " assets";
      this.copyUploadErrorsExist = false;
    }
  }

  public onSearchCopySubmitted() {
    if (this.pastedAdIds === null || this.pastedAdIds.trim() === '')
      return;

    var request: any = { adIdText: this.pastedAdIds, client: this.clientId, brand: null };

    this.alertService.ShowLoader(true)

    this.spotService.ValidateTranscodeOrderSpotsFromText(request).subscribe((res: any) => {

      this.selectionMethod = 'copypasteresult';

      if (res.isSuccess == true) {

        console.log(res.result);

        var spotfiles = res.result.spotFiles as Array<SpotFile>;

        for (let s of spotfiles) {
          s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));
          s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
          s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
            s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
            s.sap, s.vchip, s.letterBox, s.surround, s.mono);
          s.formatClasses = this.util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
            s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
            s.sap, s.vchip, s.letterBox, s.surround, s.mono);
        }

        this.copyUploadSuccessSpotFiles = spotfiles;
        this.copyUploadErrorSpotFiles = res.result.unMappedSpotFiles as Array<string>;

        if (this.copyUploadErrorSpotFiles != null && this.copyUploadErrorSpotFiles.length > 0) {
          this.copyUploadResultMessage = this.copyUploadErrorSpotFiles.length.toString() + " out of " + (this.copyUploadSuccessSpotFiles.length + this.copyUploadErrorSpotFiles.length).toString() + " assets not found";
          this.copyUploadErrorsExist = true;
        }
        else {
          this.copyUploadResultMessage = this.copyUploadSuccessSpotFiles.length.toString() + " assets found successfully";
          this.copyUploadErrorsExist = false;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        this.closePopup();
        this.util.handleError(error);
      });
  }

  public onAddMedia() {

    if (this.selectionMethod == "copypasteresult" && this.copyUploadErrorSpotFiles.length > 0) {
      this.alertService.showMessage("ERROR", "Please fix the errors", MessageSeverity.error);
      return;
    }

    this.onClose.next(this.copyUploadSuccessSpotFiles);

    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
