<ng-container *ngIf="vendorPOList != null && vendorPOList.length > 0">
  <h3 class="tylie-type-h3">The following Vendor POs have been successfully submitted.</h3>
  <table class="tylie-table table table-bordered">
    <thead class="tylie-table__head">
      <tr>
        <th width="30%">PO</th>
        <th width="50%">Vendor Name</th>
        <th width="20%">Download</th>
      </tr>
    </thead>
    <tbody class="tylie-table__body" *ngFor="let vpo of vendorPOList; let j = index; odd as isOdd">
      <tr [class.tyl-custom-grid-tr]="isOdd">
        <td>
          <div placement="left">
            {{vpo.vendorPurchaseOrder}}
          </div>
        </td>
        <td>
          <div>
            {{vpo.vendorName}}
          </div>
        </td>
        <td align="center">
          <a *ngIf="!downloadingVendorPOs.includes(vpo.vendorPurchaseOrder)" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-2" (click)="onDownloadVendorPOPDF(vpo.orderId, vpo.vendorId, vpo.vendorPurchaseOrder)">
            <span class="tylie-button__icon"><icon-download color="#1e90ff"></icon-download></span>Download PDF
          </a>
          <a *ngIf="downloadingVendorPOs.includes(vpo.vendorPurchaseOrder)" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon">
            <span class="tylie-button__icon"><icon-download color="#1e90ff"></icon-download></span>Downloading ...
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

