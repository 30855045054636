<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">ADD TO PLAYLIST</h4>
</div>
<div class="modal-body">
  <!--if you remove error class here it will display normal -->
  <form [formGroup]="playListRequest" #f="ngForm" (ngSubmit)="createNewPlayList(playListRequest)">
    <div class="tylie-form-group form-group">
      <label class="tylie-form-group__lbl" for="notes">Add to Existing Playlist<span class="required">*</span></label>
      <dx-select-box [dataSource]="userPlayLists" class="tylie-select"
                     valueExpr="playListGuid"
                     displayExpr="playListName"
                     (onValueChanged)="onSelectPlayList($event)"
                     placeholder="Select a playlist...">
      </dx-select-box>
      <p class="p-extra-small">Please select existing playlist</p>
    </div>
    <div class="tylie-form-group form-group" [ngClass]="{error: playListRequest.controls['newPlayListName'].errors && f.submitted}">
      <label class="tylie-form-group__lbl" for="notes">Add to New Playlist<span class="required">*</span></label>
      <input type="text" placeholder="Create a new playlist" class="tylie-text form-control" formControlName="newPlayListName">
      <p class="p-extra-small">Please enter new playlist </p>
    </div>
    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="submit">Add To PlayList</button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>
  </form>
</div>
