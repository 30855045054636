import {
  Directive, OnInit, TemplateRef, ViewContainerRef, Input
} from '@angular/core';
import { UserProfileService } from "../services/user/user-profile.service";

@Directive({
  selector: '[isAuthorized]'
})

export class IsAuthorizedDirective implements OnInit {

  @Input('isAuthorized') public accessControlListComponent: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userProfileService: UserProfileService) {

  }

  ngOnInit() {
    this.updateView();
  }

  private checkPermission() {
    const userRoles: string[] = this.userProfileService.getUserRoles();

    let hasAccess = false;

    if (this.accessControlListComponent != undefined) {
      for (let role of this.accessControlListComponent) {
        if (userRoles.some(r => r.toLowerCase().startsWith(role.toLowerCase()))) {
          hasAccess = true;
        }
      }
    }

    if (this.accessControlListComponent != undefined) {
      for (let role of this.accessControlListComponent) {
        if (userRoles.some(r => r.toLowerCase().startsWith(role.toLowerCase()))) {
          hasAccess = true;
        }
      }
    }

    return hasAccess
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
