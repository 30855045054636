import { Injectable } from '@angular/core';

@Injectable()
export class UserRoles {

  private MediaQC = "Asset - QC";
  private MediaApprover = "Asset - Approver";
  private MediaProvider = "Asset - Provider";
  private MediaDownload = "Asset - Download";
  private MediaViewer = "Asset - Viewer";
  private MediaEditor = "Asset - Editor";
  private OrderBuilder = "Order - Builder";
  private OrderDetailEditor = "Order - Detail Editor";
  private OrderStatusEditor = "Order - Status Editor";
  private OrderViewer = "Order - Viewer";
  private Reporting = "Reporting";
  private AdminAccounting = "Admin - Accounting";
  private AdminClientServices = "Admin - Client Services";
  private AdminClient = "Admin - Client";
  private AdminSystems = "Admin - Systems";
  private AdminView = "Admin - View";
  private MediaDistributionViewer = "Distribution - Media Viewer"

  private ProjectViewer = "Project - Viewer";
  private ProjectAdmin = "Project - Admin";
  private ProjectContributor = "Project - Contributor"

  private ITDev = "IT - Dev";

  private MediaTranscoder = "Asset - Transcoder";
  private AssetRightsManagement = "Asset - RightsManagement"
  private VaultViewer = "Vault - Viewer"
  private VaultEditor = "Vault - Editor"
  private VaultBuilder = "Vault - Builder"

  private TaggerAdmin = "Tagger - Admin"
  private TaggerLocal = "Tagger - Local"

  public getProjectViewerRoleName(): string {
    return this.ProjectViewer;
  }

  public getProjectAdminRoleName(): string {
    return this.ProjectAdmin;
  }

  public getProjectContributorRoleName(): string {
    return this.ProjectContributor;
  }

  public getMediaDistributionViewer(): string {
    return this.MediaDistributionViewer;
  }

  public getITDevRoleName(): string {
    return this.ITDev;
  }

  public getAdminViewRoleName(): string {
    return this.AdminView;
  }

  public getMediaQCRoleName(): string {
    return this.MediaQC;
  }

  public getMediaApproverRoleName(): string {
    return this.MediaApprover;
  }

  public getMediaProviderRoleName(): string {
    return this.MediaProvider;
  }

  public getMediaDownloadRoleName(): string {
    return this.MediaDownload;
  }

  public getMediaViewerRoleName(): string {
    return this.MediaViewer;
  }

  public getMediaEditorRoleName(): string {
    return this.MediaEditor;
  }

  public getOrderBuilderRoleName(): string {
    return this.OrderBuilder;
  }

  public getOrderDetailEditorRoleName(): string {
    return this.OrderDetailEditor;
  }

  public getOrderStatusEditorRoleName(): string {
    return this.OrderStatusEditor;
  }

  public getOrderViewerRoleName(): string {
    return this.OrderViewer;
  }

  public getReportingRoleName(): string {
    return this.Reporting;
  }

  public getAdminAccountingRoleName(): string {
    return this.AdminAccounting;
  }

  public getAdminClientServicesRoleName(): string {
    return this.AdminClientServices;
  }

  public getAdminClientRoleName(): string {
    return this.AdminClient;
  }

  public getAdminSystemsRoleName(): string {
    return this.AdminSystems;
  }

  public getMediaTranscoderRoleName(): string {
    return this.MediaTranscoder;
  }

  public getAssetRightsManagementRoleName(): string {
    return this.AssetRightsManagement;
  }

  public getVaultViewertRoleName(): string {
    return this.VaultViewer;
  }

  public getVaultEditorRoleName(): string {
    return this.VaultEditor;
  }

  public getVaultBuilderRoleName(): string {
    return this.VaultBuilder;
  }

  public getTaggerAdminRoleName(): string {
    return this.TaggerAdmin;
  }

  public getTaggerLocalRoleName(): string {
    return this.TaggerLocal;
  }

}
