import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { UploadService } from '../../../services/upload/upload.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileUploadConfig } from "../../../models/upload/upload-file.model";
import { ErrorPopupComponent } from "../../popups/errorpopup/errorpopup.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ConfigService } from "../../../services/core/config.service";
import { RouteConstants } from "../../../configurations/route-constants";
import { UploadOrderFormModel } from "../../../models/upload/upload-order-form.model";
import { UploadOrderServiceModel } from "../../../models/upload/upload-order-service.model"
import { UploadOrderFileRequest } from '../../../models/order/order-uploadfile.model';
import { UploadOrderResponse } from '../../../models/order/order-uploadorder-response.model'
import { UUID } from 'angular2-uuid';
import { UploadOrderFileService } from '../../../services/order/uploadorderfile.service';
import { OrderFile } from '../../../models/order/order-create.model';
import { ServiceType } from '../../../models/order/servicetype.model';

@Component({
  selector: 'order-upload',
  templateUrl: './orderupload.component.html',
  styleUrls: ['./orderupload.component.css'],
})

export class OrderUploadComponent implements OnInit {

  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public formatDataSource: any = [];
  public productDataSource: any = [];
  public serviceTypeDataSource: any = [];
  //uploadMetadataResults: Array<SpotUploadResult>;
  //uploadPlaceHolderResults: Array<SpotUploadResult>;
  inputSelectedFile: File;
  uploadConfig: FileUploadConfig = null;
  public isUploading: boolean = false;
  public uploadRequest: FormGroup;
  public noFilesChosen: boolean = false;
  public maxFilesUploadError: string;
  public maxSessionsUploadError: string;
  public isMaxSessionsExceeded: boolean = false;
  private orderFiles: Array<OrderFile> = [];
  public deliveryServicesDataSource: Array<ServiceType> = [];

  constructor(public util: Utilities,
    private modalService: BsModalService,
    private uploadService: UploadService,
    private alertService: AlertService,
    private router: Router,
    private configService: ConfigService,
    private uploadOrderFileService: UploadOrderFileService) {

  }

  ngOnInit() {

    this.getUploadConfig();

    this.uploadRequest = new FormGroup({
      client: new FormControl(null, Validators.required),
      brand: new FormControl(null, Validators.required),
      product: new FormControl(null, Validators.required),
      uploadType: new FormControl(),
      isRushService: new FormControl(),
      serviceType: new FormControl(null, Validators.required),
    });

    this.uploadRequest.controls['uploadType'].setValue('uploadOrder');
    this.uploadRequest.controls['isRushService'].setValue('false');
  }

  changeOption({ value, valid }: { value: UploadOrderFormModel, valid: boolean }) {
    if (value.uploadType == 'uploadOrder') {
      this.uploadRequest.value.uploadType = "uploadOrder";

    }
    else if (value.uploadType == 'trafficInstructions') {
      this.uploadRequest.value.uploadType = "trafficInstructions";
    }
    this.orderFiles = [];
  }

  serviceLevelChangeOption({ value, valid }: { value: UploadOrderFormModel, valid: boolean }) {
    this.uploadRequest.value.isRushService = value.isRushService;
  }

  getUploadConfig() {
    this.configService.getUploadOrderConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.uploadConfig = res.result as FileUploadConfig;

        this.clientDataSource = this.uploadConfig.clients;
        this.brandDataSource = this.uploadConfig.brands;
        this.formatDataSource = this.uploadConfig.formats;
        this.serviceTypeDataSource = this.uploadConfig.serviceType;

        this.maxFilesUploadError = "You can only upload a max of " + this.uploadConfig.maxOrderFiles + " order files";

        if (this.clientDataSource.length == 1)
          this.uploadRequest.controls['client'].setValue(this.clientDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  onUploadOrderSubmit({ value, valid }: { value: UploadOrderFormModel, valid: boolean }) {

    console.log("Inside on upload OrderSumit");

    if (this.uploadRequest.controls['brand'].errors || this.uploadRequest.controls['client'].errors || this.uploadRequest.controls['product'].errors)
      return;

    this.noFilesChosen = false;

    if (this.orderFiles.length == 0) {
      this.noFilesChosen = true;
      return;
    }

    if (this.uploadConfig != null && this.orderFiles.length >= this.uploadConfig.maxOrderFiles)
      return;

    this.createPlaceHolder(value);
  }

  public getScheduledFiles(): OrderFile[] {
    if (this.orderFiles == null) {
      this.orderFiles = [] as OrderFile[];
    }
    return this.orderFiles;
  }

  public anyScheduledFiles(): boolean {
    if (this.orderFiles.length > 0)
      return true;
    else
      return false;
  }

  public removeFromFiles(file: OrderFile) {
    const index: number = this.orderFiles.indexOf(file);
    if (index !== -1) {
      console.log("remvoing object from array");
      this.orderFiles.splice(index, 1);
    };
  }

  public isMaxFilesExceeded(): boolean {
    if (this.uploadConfig != null && this.orderFiles.length >= this.uploadConfig.maxOrderFiles)
      return true;
    else
      return false;
  }

  private getBrandProducts(brandId: number) {

    if (brandId === null || brandId <= 0) {
      //this.productDataSource = [];
      return;
    }
    console.log(this.uploadRequest.value.client, brandId);

    this.configService.getCurrentBrandProducts(brandId, this.uploadRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;

        if (this.productDataSource.length == 1)
          this.uploadRequest.controls['product'].setValue(this.productDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.productDataSource = [];
      });
  }

  onClientValueChanged(e) {
    this.uploadRequest.controls['brand'].setValue(null);
    this.uploadRequest.controls['product'].setValue(null);
    this.productDataSource = [];
    this.brandDataSource = [];

    this.getClientBrands(e.value);
  }

  onBrandValueChanged(e) {
    this.uploadRequest.controls['product'].setValue(null);
    this.productDataSource = [];

    this.getBrandProducts(e.value);
  }

  getClientBrands(id: number) {

    if (id == null || id <= 0)
      return;

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;

        if (this.brandDataSource.length == 1)
          this.uploadRequest.controls['brand'].setValue(this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.brandDataSource = [];
      });
  }

  public gotoAllOrders() {
    this.router.navigate([RouteConstants.ordersRoute]);
  }

  public onAttachFile(event: any) {
    this.noFilesChosen = false;
    console.log("on AttachFile..");
    if (event.target.files && event.target.files.length > 0) {
      this.isUploading = true;
      console.log("this uploading =true");
      let uploadFile = event.target.files[0];

      var request: UploadOrderFileRequest = {
        fileType: uploadFile.type,
        fileName: uploadFile.name,
        description: "",
        fileCategory: "",
        orderGuid: UUID.UUID(),
        orderDestinationId: 0
      };
      console.log("uploadFile.name:" + uploadFile.name);
      console.log("ServiceMetadata");
      if (this.uploadRequest.value.uploadType == "uploadtrafficInstructions") {
        request.fileCategory = "Order";
      }
      else if (this.uploadRequest.value.uploadType == "uploadOrder") {
        request.fileCategory = "Order";
      }
      else {
        request.fileCategory = null;
      }

      this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
        finalize(() => {
          this.isUploading = false;
        }))
        .subscribe((newFile: OrderFile) => {
          if (newFile != null && newFile != undefined) {
            this.orderFiles.push(newFile);
          }
        });
    }
    else
      this.isUploading = false;
  }

  public createPlaceHolder(uploadRequest: UploadOrderFormModel): void {
    this.alertService.logMessage(uploadRequest);
    var uploadMetadataRequest: UploadOrderServiceModel = {
      uploadedOrderId: 0,
      clientId: uploadRequest.client,
      brandId: uploadRequest.brand,
      productId: uploadRequest.product,
      fileCategory: uploadRequest.uploadType,
      serviceId: uploadRequest.serviceType,
      isRushService: uploadRequest.isRushService,
      statusId: 0,
      orderFiles: this.orderFiles,
      errors: null
    };

    this.uploadService.uploadOrder(uploadMetadataRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var results = res.result as UploadOrderResponse;
        results.success = true; //to be removed;
        if (results.success == true) {
          this.alertService.showMessage("SUCCESS", Constants.orderUploadedSuccessful, MessageSeverity.success);
          this.orderFiles = [] as OrderFile[];
        }
        else {

          var initialState = {
            errors: null
          };

          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onDownloadTemplate() {

    var request = { isOrderUpload: true };

    this.uploadService.downloadMetadaTemplate(request)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              //let fileURL = window.URL.createObjectURL(fileData);
              //window.open(fileURL);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = "Order Upload Template.xlsx";
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }
}
