<div id="client-podRecipients">
  <div class="modal-header">
    <h4 class="modal-title">MANAGE USERS</h4>
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="manageUserForm" (ngSubmit)="onAddUserSubmit()" novalidate #f="ngForm">
        <h3 class="tylie-type-h3">
          Add a New User to Project "{{project.collectionName}}"
        </h3>
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-check">
              <input class="form-check-input" id="male" type="radio" value="0" formControlName="userType" checked>
              <label class="form-check-label" for="male">Select from Existing Users</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-form-group form-check">
              <input class="form-check-input" id="female" type="radio" value="1" formControlName="userType">
              <label class="form-check-label" for="female">Invite new User</label>
            </div>
          </div>
        </div>
        <div class="tylie-type-body my-3" *ngIf="manageUserForm.controls['userType'].value=='1'">
          The user with the email address below will be invited to join Tylie Tandem and will receive the access
          level you assign
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="tylie-form-group form-group" [ngClass]="{error: manageUserForm.controls['userId'].errors && f.submitted}"
              *ngIf="manageUserForm.controls['userType'].value=='0'">
              <label class="tylie-form-group__lbl">Select User<span class="required">*</span></label>
              <dx-select-box formControlName="userId" [dataSource]="users" valueExpr="userProfileId"
                displayExpr="userName" [searchEnabled]="true" class="tylie-select form-control" placeholder="Select a User...">
              </dx-select-box>
              <p class="p-extra-small">Please select User</p>
            </div>
            <div class="tylie-form-group form-group" *ngIf="manageUserForm.controls['userType'].value=='1'"
              [ngClass]="{error: manageUserForm.controls['userEmail'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="adid">User Email<span class="required">*</span></label>
              <input type="text" name="adid" id="adid" class="tylie-text form-control" formControlName="userEmail" />
              <p class="p-extra-small">Please enter Email</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="tylie-form-group form-group"
              [ngClass]="{error: manageUserForm.controls['userPermissionId'].errors && f.submitted}">
              <label class="tylie-form-group__lbl">User Permission Level<span class="required">*</span></label>
              <dx-select-box formControlName="userPermissionId" [dataSource]="userPermissions"
                valueExpr="userPermissionLevelId" displayExpr="description" [searchEnabled]="true" class="tylie-select form-control"
                placeholder="Select a Permission...">
              </dx-select-box>
              <p class="p-extra-small">Please select Permission</p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="tylie-form-group form-group"
              [ngClass]="{error: manageUserForm.controls['folderId'].errors && f.submitted}">
              <label class="tylie-form-group__lbl" for="length">Folder Access<span class="required">*</span></label>
              <dx-drop-down-box formControlName="folderId" class="tylie-select form-control" placeholder="Select a folder..."
                valueExpr="fileCollectionId" displayExpr="collectionName" [dataSource]="folders">
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view [dataSource]="folders" dataStructure="plain" keyExpr="fileCollectionId" #treeFolder
                    [searchEnabled]="true" selectionMode="multiple" showCheckBoxesMode="normal"
                    displayExpr="collectionName" [selectByClick]="true" (onContentReady)="syncTreeViewSelection($event)"
                    (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
              <p class="p-extra-small">Please select Folder</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs me-3" type="submit">Add User</button>
          <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
        </div>
      </form>
  </div>
</div>