
<div class="notifications-section" id="notifications-section">
  <div class="tylie-panel" id="accountPersonalInfoSectionPanel">
    <div class="tylie-panel__header">
      <h4 class="tylie-panel__title">
        Distribution List
      </h4>
    </div>
    <div class="tylie-panel__body" *ngIf="userEmailDistroList != null">
      <p class="p-normal">
        Create your own distribution groups.
      </p>
      
      <div class="mb-4">
          <button type="button" class="tylie-button tylie-button--xs" (click)="openCreateNewDistroTemp(createNewDistroTemp)">Add New Group </button>
      </div>
      
      
      <div *ngFor="let userEmailDistro of userDistros;let i = index" class="tyl-udg-settings existing-dist-group {{i==activeGroup ? 'active' : ''}}">
        <div class="row">
          <div class="col-md-12 headding-group">
            <div>
              <div class="d-flex justify-content-between">
                <div>{{userEmailDistro.groupName}}</div>
                <div>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="onClickExpandActiveGroup(userEmailDistro.groupGuid, i)">View / Edit</button>
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link"   outsideClick="true" placement="left" #deleteUserDistropop="bs-popover"
                        container="body" [popover]="deleteUserDistro"   [popoverContext]="userEmailDistro">Delete</button>
                </div>
              </div>


              <form [formGroup]="distroEmailAdd" novalidate #u="ngForm" *ngIf="activeGroup == i">
                <div id="detailsDistroList" class="details">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="tylie-form-group form-group" [ngClass]="{error: (distroEmailAdd.controls['distrogroupname'].errors && !distroEmailAdd.controls['distrogroupname'].pristine) || (distroEmailAdd.controls['distrogroupname'].errors && u.submitted)}">
                        <label for="groupname" class="tylie-form-group__lbl">Group Name<span class="required">*</span></label>
                        <input type="hidden" name="groupname" id="groupname" class="tylie-text form-control" formControlName="distrogroupguid">
                        <input type="text" name="groupname" id="groupname" class="tylie-text form-control" formControlName="distrogroupname">
                        <p class="p-extra-small" *ngIf="distroEmailAdd.controls['distrogroupname'].errors?.required">Please Enter a group name</p>
                        <p class="p-extra-small" *ngIf="distroEmailAdd.controls['distrogroupname'].errors?.minlength">Group name must have atleast 2 characters</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-3" *ngFor="let em of getEmailsData.controls; let i=index">
                      <div class="tylie-form-group form-group" formArrayName="emails" [ngClass]="{error: (distroEmailAdd.controls.emails.controls[i].controls.email.errors?.pattern && !distroEmailAdd.controls.emails.controls[i].controls.email.pristine) || (distroEmailAdd.controls.emails.controls[i].controls.email.errors?.pattern && u.submitted)}">
                        <div [formGroupName]="i">
                          <label for="emailaddress" class="tylie-form-group__lbl">Email Address<span class="required">*</span></label>
                          <input type="text" name="emailaddress" id="emailaddress" class="tylie-text form-control" formControlName="email">
                          <p class="p-extra-small">Please Enter a valid Email Address</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="mb-3">
                      <button type="button" class="tylie-button tylie-button--xs" (click)="addEmails()" type="button">Add More Emails</button>
                  </div>
                  <div class="d-flex justify-content-end">
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" (click)="cancelEdits()">Cancel Edits</button>
                      <button type="button" class="tylie-button tylie-button--xs" (click)="addNewEmailToDistro(distroEmailAdd.controls['distrogroupguid'].value,distroEmailAdd.controls['distrogroupname'].value,u)">Save Changes</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      
      <dx-load-indicator id="large-indicator" height="60" width="60" *ngIf="userEmailDistroList == null"></dx-load-indicator>
    </div>
    
  </div>
</div>
   

<ng-template #createNewDistroTemp>
  <section class="tylie-modal">
    <div class="tylie-modal__header modal-header">
      <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
      <h4 class="modal-title">Create New Distribution Group</h4>
    </div>
    <div class="tylie-modal__body modal-body">
      <form novalidate [formGroup]="creategroupname" #d="ngForm">
        <div class="tylie-form-group form-group" [ngClass]="{error: (creategroupname.controls['groupname'].errors && !creategroupname.controls['groupname'].pristine) || (creategroupname.controls['groupname'].errors && d.submitted)}">
          <label for="psswed" class="tylie-form-group__lbl">Email Group Name<span class="required">*</span></label>
          <input type="text" formControlName="groupname" name="groupname" id="groupname" class="tylie-text form-control">
          <p class="p-extra-small" *ngIf="creategroupname.controls['groupname'].errors?.required">Please Enter a group name</p>
          <p class="p-extra-small" *ngIf="creategroupname.controls['groupname'].errors?.minlength || creategroupname.controls['groupname'].errors?.whitespace">Group name must have atleast 2 characters, (no spaces at the start or end of name)</p>
        </div>
        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit" (click)="createNewDistro()"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="modalClose()">Cancel</button>
        </div>
      </form>
    </div>
  </section>
</ng-template>

<ng-template #deleteUserDistro let-groupGuid="groupGuid">
  <button class="popover__close" (click)="onHideDeleteUserDistroPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure want to delete?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="removeUserDistro(groupGuid)">Remove</button>



  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure want to delete?
    </p>
    <i (click)="onHideDeleteUserDistroPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="removeUserDistro(groupGuid)">Delete</button> -->
</ng-template>
