import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Constants } from '../../../configurations/constants';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from '../../../services/core/utilities';
import { ForgotPasswordSettingsService } from '../../../services/user/forgotpassword-settings.service';

@Component({
  selector: 'app-taggeruserpopup',
  templateUrl: './taggeruserpopup.component.html',
  styleUrls: ['./taggeruserpopup.component.css']
})
export class TaggeruserpopupComponent implements OnInit {

  @Input('taggerUserRequest') taggerUserRequest: FormGroup;
  @Input('isFormSubmitted') isFormSubmitted: boolean;
  public isNewUser: boolean = false;
  public isEditUser: boolean = false;
  private userProfileId: string;
  private userProfileGuid: string;
  private userName: string;
  public isExistingUser: boolean = false;
  constructor(private forgotPasswordSettingsService: ForgotPasswordSettingsService,
    private alertService: AlertService,
    public util: Utilities)
  {

  }

  ngOnInit(): void {
    //these are using in html
    if (this.taggerUserRequest != null && this.taggerUserRequest != undefined) {
      this.isNewUser = this.taggerUserRequest.controls['isNewUser'].value
      this.isEditUser = this.taggerUserRequest.controls['isEditUser'].value
      this.userProfileId = this.taggerUserRequest.controls['userProfileId'].value
      this.userProfileGuid = this.taggerUserRequest.controls['userProfileGuid'].value
      this.userName = this.taggerUserRequest.controls['userName'].value

      const isExistingTandemUserControl = this.taggerUserRequest.get('isExistingTandemUser') as FormControl;

      if (isExistingTandemUserControl) {
        isExistingTandemUserControl.valueChanges.subscribe((value) => {
          this.isExistingUser = value;
          if (value == true) {
            this.taggerUserRequest.controls['firstName'].setValidators([]);
            this.taggerUserRequest.controls['lastName'].setValidators([]);

            this.taggerUserRequest.controls['firstName'].updateValueAndValidity();
            this.taggerUserRequest.controls['lastName'].updateValueAndValidity();

            console.log(this.taggerUserRequest);
          }
          else {
            this.taggerUserRequest.controls['firstName'].setValidators([Validators.required]);
            this.taggerUserRequest.controls['lastName'].setValidators([Validators.required]);

            this.taggerUserRequest.controls['firstName'].updateValueAndValidity();
            this.taggerUserRequest.controls['lastName'].updateValueAndValidity();
          }
        });
      }
    }
  }

  public generateResetPasswordToken() {
    if (this.taggerUserRequest == undefined) {
      this.util.handleIsNotSuccessLogin(Constants.userNameInValid);
      return;
    }

    this.forgotPasswordSettingsService.generateResetPasswordToken(this.userName)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", Constants.passwordResetEmailSent, MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccessLogin(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

}
