<header class="tylie-header">
  <h3 class="tylie-header__title">Orders</h3>
  <nav class="tylie-header__nav">
    <ul class="tylie-header__nav-lst">
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderBuilderRoleName()]" (click)="gotoCreateOrder()">Create Order</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *ngIf="uploadedOrderConfig && uploadedOrderConfig.showUpload == true" (click)="gotoUploadOrder()">Upload Orders</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *ngIf="uploadedOrderConfig && uploadedOrderConfig.showUpload == true" (click)="util.scrollTo('uploaded-order-section')">Uploaded Orders</a></li>
      <li  class="tylie-header__nav-lst-itm" *ngIf="draftConfig">
        <a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderBuilderRoleName()]" (click)="util.scrollTo('draft-order-section')">Draft Orders</a>
      </li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderDetailEditorRoleName()]" (click)="util.scrollTo('awaiting-orderreview-section')">Awaiting Order Review</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderStatusEditorRoleName()]" (click)="util.scrollTo('order-ready-process-section')">Ready For Process</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderStatusEditorRoleName()]" (click)="util.scrollTo('order-in-process-section')">Orders In Progress</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *ngIf="carrierSubmissionFailureConfig && carrierSubmissionFailureConfig.showGrid == true" (click)="util.scrollTo('vendor-orderfailure-section')">Vendor Submission Failed</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *ngIf="inProgressOrderConfig && inProgressOrderConfig.showGrid == true" (click)="util.scrollTo('order-in-progress-section')">Orders In Progress</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" *isAuthorized="[this.userRoles.getOrderDetailEditorRoleName()]" (click)="util.scrollTo('ready-for-billing-section')">Ready For Billing</a></li>
      <li  class="tylie-header__nav-lst-itm"><a class="tylie-header__nav-lst-lnk" (click)="util.scrollTo('search-all-orders-section')">All Orders</a></li>
    </ul>
  </nav>
</header>

<div class="tylie-body">
  <div class="tylie-body__bar" id="create-order-section">
    <ng-container *isAuthorized="[this.userRoles.getOrderBuilderRoleName()]">
        <button type="button" class="tylie-button tylie-button--icon me-3" (click)="gotoCreateOrder()">
          <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
          <span class="tylie-button__text">CREATE NEW ORDER</span>
        </button>
    </ng-container>

    <ng-container *ngIf="uploadedOrderConfig && uploadedOrderConfig.showUpload == true">
      <button type="button" class="tylie-button tylie-button--icon" (click)="gotoUploadOrder()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">UPLOAD ORDER</span>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="uploadedOrderConfig && uploadedOrderConfig.showUpload == true">
    <app-uploaded-orders [uploadedOrderConfig]="uploadedOrderConfig"></app-uploaded-orders>
  </ng-container>

  <ng-container *ngIf="draftConfig">
    <app-draft-orders *isAuthorized="[this.userRoles.getOrderBuilderRoleName()]" [draftorderConfig]="draftConfig"></app-draft-orders>
  </ng-container>

  <awaiting-review-orders *isAuthorized="[this.userRoles.getOrderDetailEditorRoleName()]"></awaiting-review-orders>

  <ready-to-process-orders *isAuthorized="[this.userRoles.getOrderStatusEditorRoleName()]"></ready-to-process-orders>

  <processing-orders *isAuthorized="[this.userRoles.getOrderStatusEditorRoleName()]"></processing-orders>

  <ng-container *ngIf="carrierSubmissionFailureConfig && carrierSubmissionFailureConfig.showGrid == true">
    <vendor-submission-failed-orders></vendor-submission-failed-orders>
  </ng-container>

  <ng-container *ngIf="inProgressOrderConfig && inProgressOrderConfig.showGrid == true">
    <orders-in-progress [inProgressOrderConfig]="inProgressOrderConfig"></orders-in-progress>
  </ng-container>

  <ready-for-billing-orders *isAuthorized="[this.userRoles.getOrderDetailEditorRoleName()]"></ready-for-billing-orders>

  <ng-container *ngIf="orderConfig">
    <app-order-search [orderConfig]="orderConfig"></app-order-search>
  </ng-container>

</div>
