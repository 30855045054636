import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { ConfigService } from '../../../services/core/config.service';
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { OrderUserDestination, TranscodeSpecification } from '../../../models/order/order-userdestination';
import { Constants } from '../../../configurations/constants';

@Component({
  selector: 'transcodelistpopup.component.ts',
  templateUrl: './transcodelistpopup.component.html',
  styleUrls: ['./transcodelistpopup.component.css'],
})
export class TranscodeListPopupComponent extends BasePopupComponent {

  @Input() transcodesSavedListToEdit: OrderUserDestination = null;
  @Input() isEdit: boolean = true;

  public onClose: Subject<boolean>;
  public quickSearchValue: string = "";
  public transcodeSpecsSource: Array<any> = [];
  public transListName: string = "";
  public triedToSubmit: boolean = false;
  public selectedTranscodes: Array<string> = [];

  @ViewChild('popuptranscodeSpecsGrid', { static: true }) popuptranscodeSpecsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();

    this.getTranscodeSpecs();
  }

  private getTranscodeSpecs() {
    this.configService.getTranscodeSpecs().subscribe((res: any) => {
      if (res.isSuccess == true) {

        var specs = res.result as Array<any>;

        this.transcodeSpecsSource = specs;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onContentReady(e) {

    if (this.isEdit) {

      this.transListName = this.transcodesSavedListToEdit.name;

      this.transcodesSavedListToEdit.specifications.forEach(item => {
        e.component.selectRows([item.id], true);
      });
    }
  }

  public onSelectionChanged(e) {

    e.selectedRowsData.forEach((item, i) => {
      if (this.selectedTranscodes.filter(tran => tran == item.name).length == 0)
        this.selectedTranscodes.push(item.name);
    });

    e.currentDeselectedRowKeys.forEach((deSelectedItem) => {

      var ind = this.transcodeSpecsSource.findIndex(t => t.id == deSelectedItem);

      this.selectedTranscodes.forEach((tran, j) => {
        if (this.transcodeSpecsSource[ind].name === tran)
          this.selectedTranscodes.splice(j, 1);
      });
    });
  }

  public onQuickSearch() {
    this.popuptranscodeSpecsGrid.instance.searchByText(this.quickSearchValue);
  }

  public onClearQuickSearch() {
    this.popuptranscodeSpecsGrid.instance.searchByText("");
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onSaveList() {
    if (this.isEdit == false)
      this.onCreateNewList();
    else
      this.onUpdateList();
  }

  private onUpdateList() {
    this.triedToSubmit = true;

    if (this.transListName == null || this.transListName.trim() == '')
      return;

    var grid = this.popuptranscodeSpecsGrid.instance;

    if (grid.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_select, MessageSeverity.error);
      return;
    }

    var updatedSpecs: Array<TranscodeSpecification> = [];

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      updatedSpecs.push(grid.getSelectedRowsData()[i]);
    }

    this.transcodesSavedListToEdit.specifications = updatedSpecs;

    this.transcodesSavedListToEdit.name = this.transListName.trim();

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.updateUserSavedDestinationList(this.transcodesSavedListToEdit.id, "speclist", this.transcodesSavedListToEdit).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcodeorder_list_updated, MessageSeverity.success);

        this.onClose.next(true);

        this.closePopup();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();

        this.alertService.ShowLoader(false);
      });
  }

  private onCreateNewList() {

    this.triedToSubmit = true;

    var grid = this.popuptranscodeSpecsGrid.instance;
    var selectedTransDestinations: string[] = [];

    if (grid.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.transcodeorder_select, MessageSeverity.error);
      return;
    }

    if (this.transListName == null || this.transListName.trim() == '')
      return;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedTransDestinations.push(grid.getSelectedRowsData()[i].name);
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.createUserSavedDestinationList(this.transListName, "speclist", selectedTransDestinations).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.transcodeorder_list_created, MessageSeverity.success);
        this.onClose.next(true);
        this.closePopup();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();

        this.alertService.ShowLoader(false);
      });
  }
}
