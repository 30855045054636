<ng-container>
  <div class="modal-header">
    <h4 class="modal-title">Create New Project</h4>
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button> 
  </div>
  <div class="modal-body">
    <form [formGroup]="createProjectForm" (submit)="onCreateProjectFormSubmit()" novalidate #f="ngForm">
      <div class="row">
        <div class="col-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: (createProjectForm.controls['projectName'].errors && f.submitted)
          ||(createProjectForm.controls['projectName'].dirty && createProjectForm.controls['projectName'].errors)}">
            <label class="tylie-form-group__lbl" for="projectName">Project Name<span class="required">*</span></label>
            <input formControlName="projectName" type="text" id="projectName" name="projectName"
              class="tylie-text form-control " autocomplete="off"/>
            <p class="p-extra-small" *ngIf="createProjectForm.controls['projectName'].errors?.required">
              Please enter Project Name</p>
            <p class="p-extra-small"
              *ngIf="createProjectForm.controls['projectName'].errors?.duplicate && f.submitted">Project Name already
              exists</p>
          </div>
        </div>

        <div class="col-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: (createProjectForm.controls['projectCode'].errors && f.submitted)
          ||(createProjectForm.controls['projectCode'].dirty && createProjectForm.controls['projectCode'].errors)}">
            <label class="tylie-form-group__lbl" for="projectCode">Project Code<span class="required">*</span></label>
            <input formControlName="projectCode" type="text" id="projectCode" name="projectCode"
              class="tylie-text form-control " autocomplete="off"/>
            <p class="p-extra-small" *ngIf="createProjectForm.controls['projectCode'].errors?.required">Please enter
              Project
              Code</p>
            <p class="p-extra-small"
              *ngIf="createProjectForm.controls['projectCode'].errors?.duplicate && f.submitted">Project Code already
              exists</p>
          </div>
        </div>

        <div class="col-3">
          <div class="tylie-form-group form-group"
            [ngClass]="{error: (createProjectForm.controls['clientId'].errors && f.submitted)}">
            <label class="tylie-form-group__lbl" for="client">Client<span class="required">*</span></label>
            <dx-select-box [dataSource]="clientDataSource" (onValueChanged)="onClientsChange($event)" id="client" name="client" class="tylie-select form-control"
              formControlName="clientId" [searchEnabled]="true" valueExpr="id" displayExpr="name"
              placeholder="Select a client..." [readOnly]="isClientReadOnly">
            </dx-select-box>
            <p class="p-extra-small">Please select Client</p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</ng-container>