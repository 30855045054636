<div id="copy-order-options">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Copy Options</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="copyOptionsRequest" #f="ngForm" (ngSubmit)="onSave(copyOptionsRequest)">
      <div class="row">
        <div class="col-md-4">
          <div class="tylie-form-group form-check">
            <input class="form-check-input" type="checkbox" formControlName="destinations" name="destinations" id="destinations">
            <label class="form-check-label" for="destinations">Destinations</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="tylie-form-group form-check">
            <input class="form-check-input" type="checkbox" formControlName="media" name="media" id="media">
            <label class="form-check-label"  for="media">Asset</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="tylie-form-group form-check">
            <input class="form-check-input" type="checkbox" formControlName="services" name="services" id="services">
            <label class="form-check-label" for="services">Services</label>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs" type="submit">Copy Order</button>
      </div>
    </form>
  </div>
</div>
