<div class="modal-header" *ngIf="isModal == true">
  <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Change Password</h4>
</div>
<div class="modal-body" *ngIf="isModal == true">
  <form novalidate [formGroup]="ChangePassword" #c="ngForm" (submit)="saveNewPassword(ChangePassword)">
    <div class="tylie-form-group form-group" [ngClass]="{error: (ChangePassword.controls['oldpassword'].errors && !ChangePassword.controls['oldpassword'].pristine) || ((ChangePassword.controls['oldpassword'].errors || errMessage)&& c.submitted)}">
      <label class="tylie-form-group__lbl" for="psswed">Old Password<span class="required">*</span></label>
      <input type="password" formControlName="oldpassword" name="oldpassword" id="oldpassword" class="tylie-text form-control">
      <p class="p-extra-small" *ngIf="ChangePassword.controls['oldpassword'].errors?.required">Please Enter Old Password</p>
      <p class="p-extra-small" *ngIf="errMessage">{{errMessage}}</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (ChangePassword.controls['newpassword'].errors && !ChangePassword.controls['newpassword'].pristine) || ((ChangePassword.controls['newpassword'].errors || errMessage) && c.submitted)}">
      <label class="tylie-form-group__lbl" for="psswed">New Password<span class="required">*</span></label>
      <input type="password" formControlName="newpassword" name="newpassword" id="newpassword" class="tylie-text form-control">
      <p class="p-extra-small" *ngIf="ChangePassword.controls['newpassword'].errors?.required">Please Enter New Password</p>
      <p class="p-extra-small" *ngIf="ChangePassword.controls['newpassword'].errors?.pattern">Password must be 8 to 25 letters with atleast a Capital Letter[A-Z], lowercase letter[a-z], a digit [0-9], and a special character[!,@,#,$,%,^,&,*]</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: ((ChangePassword.controls['verifypassword'].errors || ChangePassword.controls['verifypassword'].value != ChangePassword.controls['newpassword'].value || errMessage) && !ChangePassword.controls['verifypassword'].pristine) || (ChangePassword.controls['verifypassword'].errors && c.submitted)}">
      <label class="tylie-form-group__lbl" for="npwed">Confirm New Password<span class="required">*</span></label>
      <input type="password" formControlName="verifypassword" name="verifypassword" id="verifypassword" class="tylie-text form-control">
      <p class="p-extra-small" *ngIf="ChangePassword.controls['verifypassword'].errors?.required">Please Confirm New Password</p>
      <p class="p-extra-small" *ngIf="ChangePassword.controls['verifypassword'].value != ChangePassword.controls['newpassword'].value ">New password and confirm password donot match</p>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs me-3" type="submit" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>{{isLoading ? 'Saving...' : 'Save'}}</button>
      <button class="tylie-button tylie-button--link tylie-button--xs" type="button" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>

<!-- move text fields(Old password, New Password, Confirm New Password to common component if this needs to be reused-->
<div class="container" *ngIf="isModal == false">
  <div class="imgcontainer">
    <img src="./assets/svg/TylieTandem-Logo.svg" alt="Avatar" class="avatar">
  </div>
  <div class="media-awaiting-approval-section animated zoomIn">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a class="p-small">
            Change Password
          </a>
        </h4>
      </div>
      <div id="mediaApprovalCollapseOne" class="panel-collapse collapse in">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">

              <div class="alert alert-danger" *ngIf="errMessage">
                {{errMessage}}
              </div>

              <p class="p-extra-small">
                Your password has expired
              </p>

              <form novalidate [formGroup]="ChangePassword" #f="ngForm" (submit)="saveNewPassword(ChangePassword)">
                <div class="form-group qc-notes" [ngClass]="{error: (ChangePassword.controls['oldpassword'].errors && !ChangePassword.controls['oldpassword'].pristine) || ((ChangePassword.controls['oldpassword'].errors)  && f.submitted)}">
                  <label for="psswed">Old Password<span class="required">*</span></label>
                  <input type="password" formControlName="oldpassword" name="oldpassword" id="oldpassword" class="form-control">
                  <p class="p-extra-small">Please Enter Old Password</p>
                </div>
                <div class="form-group qc-notes" [ngClass]="{error: (ChangePassword.controls['newpassword'].errors && !ChangePassword.controls['newpassword'].pristine) || (ChangePassword.controls['newpassword'].errors && f.submitted)}">
                  <label for="psswed">New Password<span class="required">*</span></label>
                  <input type="password" formControlName="newpassword" name="newpassword" id="newpassword" class="form-control">
                  <p class="p-extra-small" *ngIf="ChangePassword.controls['newpassword'].errors?.required">Please Enter New Password</p>
                  <p class="p-extra-small" *ngIf="ChangePassword.controls['newpassword'].errors?.pattern">Password must be 8 to 25 letters with atleast a Capital Letter[A-Z], lowercase letter[a-z], a digit [0-9], and a special character[!,@,#,$,%,^,&,*]</p>
                </div>

                <div class="form-group qc-notes" [ngClass]="{error: ((ChangePassword.controls['verifypassword'].errors || ChangePassword.controls['verifypassword'].value != ChangePassword.controls['newpassword'].value ) && !ChangePassword.controls['verifypassword'].pristine) || (ChangePassword.controls['verifypassword'].errors && f.submitted)}">
                  <label for="npwed">Confirm New Password<span class="required">*</span></label>
                  <input type="password" formControlName="verifypassword" name="verifypassword" id="verifypassword" class="form-control">
                  <p class="p-extra-small" *ngIf="ChangePassword.controls['verifypassword'].errors?.required">Please Confirm New Password</p>
                  <p class="p-extra-small" *ngIf="ChangePassword.controls['verifypassword'].value != ChangePassword.controls['newpassword'].value ">New password and confirm password donot match</p>
                </div>

                <!--<div class="form-group media-qc  col-md-5 login-btn">
                  <button type="submit" class="btn btn-normal qc-update" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i>{{isLoading ? 'Saving...' : 'Save'}}</button>
                  <label>
                    <span class="psw p-normal" (click)="forgotPassword()">Forgot password? </span>
                  </label>
                </div>-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

