import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-nav-ops',
  templateUrl: './icon-nav-ops.component.html',
  styleUrls: ['./icon-nav-ops.component.css']
})
export class IconNavOpsComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#a1a1a1';
  constructor() { }

  ngOnInit() {
  }

}
