import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/core/alert.service';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Utilities } from "../../../services/core/utilities";
import { MediaCustomTagAddEditRequest, AddEditMediaCustomTag } from '../../../models/spot/addeditmediacustomtag.model';
import { MediaDetailService } from '../../../services/mediadetail/mediadetail.service';


@Component({
  selector: 'addeditmediacustomtag',
  templateUrl: './addeditmediacustomtagpopup.component.html',
  styleUrls: ['./addeditmediacustomtagpopup.component.css'],
})
export class AddEditMediaCustomTagPopUp implements OnInit {

  @Input('spotFileGuid') spotFileGuid: string = null;
  public onSaveCustomTags: Subject<MediaCustomTagAddEditRequest>;

  public modalRef: BsModalRef;
  public mediaCustomTagForm: FormGroup;
  public spotFileCustomTags: any = null;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private util: Utilities,
    private mediaDetailService: MediaDetailService) {

    this.onSaveCustomTags = new Subject<MediaCustomTagAddEditRequest>();
  }

  ngOnInit() {
    this.mediaCustomTagForm = this.fb.group({
      activeCustomTags: this.fb.array([]),
      otherAvailablecustomTags: this.fb.array([])
    });

    if (this.spotFileGuid != null) {
      this.getMediaCustomTags(this.spotFileGuid)
    }
  }

  private getMediaCustomTags(spotFileGuid: string) {
    this.mediaDetailService.getMediaCustomTags(spotFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.spotFileCustomTags = res.result;

        this.mediaCustomTagForm = this.fb.group({
          activeCustomTags: this.addActiveCustomTagsToFormArray(),
          otherAvailablecustomTags: this.addOtherAvailablecustomTagsToFormArray()
        });
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public get getActiveCustomTagsFormArray() {
    return <FormArray>this.mediaCustomTagForm.get('activeCustomTags');
  }

  public get getOtherAvailablecustomTagsFormArray() {
    return <FormArray>this.mediaCustomTagForm.get('otherAvailablecustomTags');
  }

  public onaddEditCustomTagFormSubmit({ value, valid }: { value: MediaCustomTagAddEditRequest, valid: boolean }) {

    if (!valid) {
      return;
    }

    value.activeCustomTags.forEach(ct => {
      if (ct.dataType == 'CheckBoxes') {
        var checkBoxTagValueList = [];
        for (var i = 0; i < ct.tagValueList.length; i++) {
          if (ct.tagValueList[i]) {
            checkBoxTagValueList.push(ct.config[i]);
          }
        }
        ct.tagValueList = checkBoxTagValueList;
        ct.tagValue = checkBoxTagValueList.join(',');
      }
    });

    value.otherAvailablecustomTags.forEach(ct => {
      if (ct.dataType == 'CheckBoxes') {
        var checkBoxTagValueList = [];
        for (var i = 0; i < ct.tagValueList.length; i++) {
          if (ct.tagValueList[i]) {
            checkBoxTagValueList.push(ct.config[i]);
          }
        }
        ct.tagValueList = checkBoxTagValueList;
        ct.tagValue = checkBoxTagValueList.join(',');
      }
    });

    this.onSaveCustomTags.next(value);
    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onClickClearTagValue(grpIndex: number) {
    var grp = this.getActiveCustomTagsFormArray.at(grpIndex) as FormGroup;
    if (grp.get('dataType').value == 'CheckBoxes') {
      var checkBoxConfigs: FormArray = this.fb.array([]);
      var config = grp.get('config').value;

      config.forEach(item => {
        const control = new FormControl(false);
        checkBoxConfigs.push(control);
      });

      grp.setControl('tagValueList', checkBoxConfigs as FormArray);
    }

    grp.patchValue({ 'tagValue': null });

    console.log(grp);
  }

  private addActiveCustomTagsToFormArray() {
    var activeCustomTags: FormArray = this.fb.array([]);
    var checkBoxConfigs: FormArray = this.fb.array([]);

    if (this.spotFileCustomTags.activeCustomTags.length > 0) {
      this.spotFileCustomTags.activeCustomTags.forEach((bct: AddEditMediaCustomTag) => {
        checkBoxConfigs = this.fb.array([]);

        if (this.util.notEmpty(bct)) {
          if (bct.dataType == 'CheckBoxes') {
            bct.config.forEach(item => {
              const control = new FormControl(bct.tagValueList.includes(item));
              checkBoxConfigs.push(control);
            });
          }

          const group = this.fb.group({
            id: [bct.id],
            tag: [bct.tag],
            dataType: [bct.dataType],
            config: [bct.config],
            isActive: [bct.isActive],
            customTagConfigurationId: [bct.customTagConfigurationId],
            tagValue: [bct.tagValue],
            tagValueList: checkBoxConfigs as FormArray
          });

          activeCustomTags.push(group);
        }
      });
    }
    return activeCustomTags;
  }

  private addOtherAvailablecustomTagsToFormArray() {
    var otherAvailablecustomTags: FormArray = this.fb.array([]);
    var checkBoxConfigs: FormArray = this.fb.array([]);

    if (this.spotFileCustomTags.otherAvailablecustomTags.length > 0) {
      this.spotFileCustomTags.otherAvailablecustomTags.forEach(bct => {
        checkBoxConfigs = this.fb.array([]);

        if (this.util.notEmpty(bct)) {
          if (bct.dataType == 'CheckBoxes') {
            bct.config.forEach(item => {
              const control = new FormControl(false);
              checkBoxConfigs.push(control);
            });
          }

          const group = this.fb.group({
            id: [0],
            tag: [bct.tag],
            dataType: [bct.dataType],
            config: [bct.config],
            isActive: [bct.isActive],
            customTagConfigurationId: [bct.customTagConfigurationId],
            tagValue: [null],
            tagValueList: checkBoxConfigs as FormArray
          });

          otherAvailablecustomTags.push(group);
        }
      });
    }

    return otherAvailablecustomTags;
  }
}
