import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from "../../services/auth/auth.service";
import { Utilities } from "../../services/core/utilities";
import { UserRoles } from "../../configurations/user-roles";
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CreateNewProjectPopUp } from '../popups/createnewprojectpopup/createnewprojectpopup.component';
import { ProjectSearchComponent } from './projectsearch/projectsearch.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'project-content',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  public modalRef: BsModalRef;

  @ViewChild(ProjectSearchComponent, { static: true }) projectSearchComp: ProjectSearchComponent;
  subscriptions: Subscription[] = [];
  constructor(
    private authService: AuthService,
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
  }

  gotoCreateProject() {
    var initialState = {};

    this.modalRef = this.modalService.show(CreateNewProjectPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.subscriptions.push(this.modalRef.content.onClose.subscribe((result: boolean) => {
      if (result) {
        this.alertService.showMessage("SUCCESS", "New Project Created.", MessageSeverity.success);
        this.projectSearchComp.gridActiveProjectSearch.instance.refresh();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
