<div id="client-podRecipients">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Add / Update Specification Details</h4>
  </div>
  <div class="modal-body">
      <form [formGroup]="updateSpecDetailsForm" (submit)="onUpdateSpecDetailsFormSubmit(updateSpecDetailsForm)" novalidate #ngForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: (updateSpecDetailsForm.controls['recipeName'].errors && !updateSpecDetailsForm.controls['recipeName'].pristine)
            || (updateSpecDetailsForm.controls['recipeName'].errors && ngForm.submitted)}">
              <label class="tylie-form-group__lbl" for="houseId">
              Recipe Name<span class="required">*</span>
              </label>
              <input id="recipeName" name="recipeName" class="tylie-text form-control" formControlName="recipeName"/>
              </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <button class="tylie-button tylie-button--xs tylie-button--link me-3" type="button" (click)="closePopup()">Cancel</button>
        </div>
      </form>
  </div>
</div>
