import { NgModule, InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
 // asperaConnectPath: string;
  timeZoneDefault: string;
  defaultAvatar: string;
  asperaMinVersion: string;
}

export const APP_DI_CONFIG: AppConfig = {
  //asperaConnectPath: 'https://stagetylieinstallations.tyliedev.com/AsperaConnectSdk/',
  asperaMinVersion: '3.10.0',
  timeZoneDefault: 'Pacific Standard Time',
  defaultAvatar: './assets/avatars/bear colour FINAL3.jpg'
};

@NgModule({
    providers: [{
        provide: APP_CONFIG,
        useValue: APP_DI_CONFIG
    }]
})

export class AppConfigModule { }
