import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-search',
  templateUrl: './icon-search.component.html',
  styleUrls: ['./icon-search.component.css']
})
export class IconSearchComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#000';
  constructor() { }

  ngOnInit() {
  }

}
