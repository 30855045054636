import { Time } from "ngx-bootstrap/timepicker/timepicker.models";
import { OrderDestination } from "./order-destinations.model";
import { Spot } from "../spot/spot.model";
import { UploadOrderFileRequest } from "./order-uploadfile.model";
import { OrderClientDestination } from "./order-clientdestination.model";
import { ServiceType } from "./servicetype.model";
import { OrderUserDestination } from "./order-userdestination";
import { UserEmailDistributionGroups } from "../user/user-email-distribution-group.model";
import { SpotFile } from "../spot/spot-file.model";
import { GroupError } from "./ordererror.model";
import { SpotResendConfirm } from "./spot-onhand.model";
import { TranscodeMediaOrderRequest } from "./transcodeorder-config.model";

export class CreateOrderRequest {
  public createOrderModule: string;
  public header: OrderHeader;
  public groups: Array<OrderGroup>;
  public orderDraftGuid: string;
  public orderGuid: string;
  public draftOrderNumber: string;
  public invalidAdIds: Array<OrderInvalidAdId>;
  public invalidDestinations: Array<OrderInvalidDestination>;
  public invalidServices: Array<OrderInvalidService>;
  public resendData: Array<SpotResendConfirm>;
}

export class UpdatedResults {
  public invalidDestinations: Array<OrderInvalidDestination>;
  public invalidAdids: Array<OrderInvalidAdId>;
}

export class OrderInvalidAdId {
  public adId: string;
  public groups: Array<OrderInvalidGroup>;
  public isDeletedByUser: boolean;
  public correctedAdId: string;
  public errors: Array<string>;
  public status: string
}

export class OrderInvalidDestination {
  public destination: string;
  public correctedDestination: string;
  public userSelectedDestination: OrderDestination;
  public groups: Array<OrderInvalidGroup>;
  public isDeletedByUser: boolean;
  public isModifiedByUser: boolean
  public status: string
  public errors: Array<string>;
}


export class OrderInvalidService {
  public service: string;
  public groups: Array<OrderInvalidGroup>;
  public isDeletedByUser: boolean;
  public correctedService: string;
  public errors: Array<string>;
}

export class OrderGroup {
  public isRush: boolean;
  public sequenceId: number;
  public name: string;
  public destinations: Array<OrderDestination>;
  public ftpDestinations: Array<OrderUserDestination>;
  public shareLinks: Array<UserEmailDistributionGroups>;
  public spotData: Array<SpotServiceData>;
  public invalidAdids: Array<InvalidAdid>;
  public invalidDestinations: Array<InvalidDestination>;
  public invalidServices: Array<InvalidService>;
  public attachments: Array<OrderFile>;
  public serviceData: Array<ServiceData>;

  public groupError: GroupError;

  // for group panel bar to red
  public containsGroupErrors: boolean;

  // for table header text to red
  public containsGroupServiceErrors: boolean;
  public containsAdIDWarnings: boolean;
  public containsGroupAdIdErrors: boolean;
  public containsGroupTitleErrors: boolean;
  public containsGroupDestinationErrors: boolean;
  public containsGroupServiceLevelErrors: boolean;
  public transcodeRequest: TranscodeMediaOrderRequest;
}

export class OrderInvalidGroup {
  groupName: string;
  sequenceId: string;
}

export class InvalidAdid {
  adid: string;
  errors: Array<string>;
}

export class InvalidDestination {
  destination: string;
  errors: Array<string>;
}

export class InvalidService {
  service: string;
  errors: Array<string>;
}

export class OrderFile {
  orderFileGuid: string;
  fileName: string;
  fileType: string;
  fileCategory: string;
  description: string;
  filePath: string;
  orderFileId: number;
  uploadedOn: Date;
}

export class OrderHeader {
  public clientId: number;
  public brandId: number;
  public productId: number;
  public clientPurchaseOrder: string;
  public jobNumber: string;
  public orderedBy: string;
  public inVoiceTo: string;
  public costCenter: string;
  public comments: string;
  public estimate: string;
  public refKeywords: string;
  public airDateStart: Date;
  public airDateEnd: Date;
  public notes: string;
  public attachments: Array<OrderFile>;
  public isConsolidatedOrder: boolean;
  public isProBono: boolean;
  public isDestinationsHubbed: boolean;
  public isFinalReviewRequired: boolean;
  public isOrderFlagged: boolean;
  public originalWorkOrder: string;
  public podEmails: string;
  public isPostDatedOrder: boolean;

  public serviceData: Array<ServiceData>;
  public isAddlExists: boolean;
  public isPpExists: boolean;
  public isDeliveryExists: boolean;
  public specialVisibilityClientId: number;
}

export class SelectedGroupMedia {
  selectedSpotFiles: Array<SpotFile>;
  applyToAllGroups: boolean;
}

export class SelectedOrderDestinations {
  public applyToAllGroups: boolean;
  public destinations: Array<OrderDestination>;
  public ftpDestinations: Array<OrderUserDestination>;
  public shareLinks: Array<UserEmailDistributionGroups>;
}

export class SpotServiceData {
  public spotFileGuid: string;
  public spotFile: SpotFile;
  public serviceData: Array<ServiceData>;

  public groupSpotId: string;

  public ppSpotFile: SpotFile;

  public isAddlExists: boolean;
  public isPpExists: boolean;
  public isDeliveryExists: boolean;

  public destinationAddlInfo: Array<DestinationAddlInfo>;

  // for cell error to red
  public containsSpotServiceDataErrors: boolean;
  public containsAdIdErrors: boolean;
  public containsTitleErrors: boolean;

  public containsSpotErrors: boolean;

  public containsPpSpotAdIdErrors: boolean; //
  public containsPpSpotTitleErrors: boolean; //

  public errorMsgSpot: string;

  public errorMsgPpSpotAdId: string; //
  public errorMsgPpSpotTitle: string; //
}

export class ServiceData {
  public serviceId: number;
  public service: ServiceType;
  public serviceMetadata: Array<ServiceMetadata>;

  public recentlyAdded: boolean;
  //public addedAsRequiredService: boolean;
  public containsServiceMetadataDataErrors: boolean;
  public optionsSubmitted: boolean;
  public errorMsg: string;
  public displaySequence: number;
  public parentServices: Array<number>;
}

export class ServiceMetadata {
  public metadataKey: string;
  public textValue: string;
  public fileValue: OrderFile;
  public arrayValue: string;
  public objectValue: string;
  public category: string;
  public display: string;
  public hidden?: boolean;
}

export class SpotDestinationData {
  public destinationCode: string;
  public deliveryMethod: string;
  public name: string;
  public email: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public country: string;
  public ftpserver: string;
  public ftpuserName: string;
  public ftppassword: string;
  public ftpport: string;
}

export class DestinationAddlInfo {
  public destinationCode: string;
  public fileDeliveryType: string;
  public addlInfoMetadata: Array<DestinationAddlInfoMetadata>;
}

export class DestinationAddlInfoMetadata {
  public metadataKey: string;
  public metadataValue: string;
}

export class SpotDestinationAddlInfo {
  public sets: Array<SpotDestinationAddlInfoSet>;
  public destination: OrderDestination;
}

export class SpotDestinationAddlInfoSet {
  public spotFile: SpotFile;
  public addlInfoMetadata: Array<DestinationAddlInfoMetadata>;
}

export class SpotDestinationAddlInfoFormRequest {
  public sets: Array<SpotDestinationAddlInfoFormSet>;
  public destinationCode: string;
  public fileDeliveryType: string;
}

export class SpotDestinationAddlInfoFormSet {
  public spotFiles: Array<SpotFile>;
  public innovidData: InnovidData;
}

export class InnovidData {
  public campaign: string;
}

export class TranscodeFileNamingInfo {
  public displayString: string;
  public isBasicFileNaming: boolean;
  public fileNamingPopOverDisplay: boolean;
}
