<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['voice'].errors && f.submitted)}" *ngIf="spotLevel == true">
    <label class="tylie-form-group__lbl">Voice<span class="required">*</span></label>
    <div class="d-flex">
      <div class="form-check me-3"><input class="form-check-input" type="radio" formControlName="voice" value="Male"><span class="form-check-label">Male</span></div>
      <div class="form-check"><input class="form-check-input" type="radio" formControlName="voice" value="Female"><span class="form-check-label">Female</span></div>
    </div>
    <p class="p-extra-small">Please choose an option</p>
  </div>

  <div class="row" *ngIf="spotLevel == true">
    <div class="col-md-6">
      <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['language'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="language">Language<span class="required">*</span></label>
        <dx-drop-down-box id="language"
                          name="language"
                          [(value)]="selectedLanguages"
                          valueExpr="value"
                          displayExpr="name"
                          class="tylie-select form-control"
                          formControlName="language"
                          (onValueChanged)="onChanged()"
                          [placeholder]="placeholder"
                          [dataSource]="options">
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view [dataSource]="options"
                          dataStructure="plain"
                          keyExpr="value"
                          displayExpr="name"
                          #tree
                          selectionMode="multiple"
                          showCheckBoxesMode="normal"
                          (onContentReady)="onChanged($event)"
                          (onItemSelectionChanged)="onTreeChanged($event)"
                          [selectByClick]="true">
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
        <p class="p-extra-small">Please Select a Language</p>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="spotLevel == true">
    <div class="col-md-6">
      <div class="tylie-form-group form-group" *ngIf="showOtherLanguage" [ngClass]="{error: (formGroup.controls['languageCustom'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="languageCustom">Other Language<span class="required">*</span></label>
        <input type="text" name="languageCustom" class="tylie-text form-control" formControlName="languageCustom">
        <p class="p-extra-small">Please enter other Language</p>
      </div>
    </div>
  </div>

  <div class="tylie-form-group form-group" *ngIf="spotLevel == true">
    <label class="tylie-upload" *ngIf="isUploadFileDisabled === false">
      <input type="file" style="display:none" (change)="onAttachFile($event)">
      <div class="tylie-upload__action tylie-upload__action--xs">
        {{isUploading ? 'UPLOADING...' : 'UPLOAD SCRIPT'}}
        <i *ngIf="isUploading" class='fa fa-circle-o-notch fa-spin'></i>
      </div>
    </label>
    <p *ngFor="let fl of formGroup.controls['scriptFile'].value;">
      <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onDownloadFile(fl.orderFileGuid, fl.fileName);">{{fl.fileName}}</a>&nbsp;
      <a *ngIf="isUploadFileDisabled === false" (click)="onRemoveFile(fl.orderFileGuid)" class="tylie-button tylie-button--xs tylie-button--link">
        <icon-trash-can color="#1e90ff"></icon-trash-can>
      </a>
    </p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error:(formGroup.hasError('uploadOrPasteScriptRequired') && f.submitted)}" *ngIf="spotLevel == true">
    <label class="tylie-form-group__lbl" for="script-text">Paste Script Text<span class="required">*</span></label>
    <textarea name="script-text" id="script-text" class="tylie-textarea tylie-textarea--md form-control" formControlName="scriptText"></textarea>
    <p class="p-extra-small">Please upload Script file or paste Script text</p>
  </div>

  <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['title'].errors && f.submitted)}" *ngIf="spotLevel == true">
    <div *ngIf="applyAllMode === true">
      <label class="tylie-form-group__lbl">This below entry is disabled in Apply All Mode. Please edit later.</label>
    </div>

    <label class="tylie-form-group__lbl" for="title">Title for the new Asset<span class="required">*</span></label>
    <input type="text" class="tylie-text form-control" name="title" formControlName="title" [attr.disabled]="(applyAllMode === true || isTitleDisabled === true) ? '' : null" />
    <p class="p-extra-small">Please enter Title</p>
  </div>

  <div class="row" *ngIf="orderLevel == true">
    <div class="col-md-3">
      <div class="tylie-form-group form-group" [ngClass]="{error: ((formGroup.controls['hours'].errors || formGroup.controls['mins'].errors || formGroup.hasError('minimumMinsRequired')) && f.submitted)}">
        <label class="tylie-form-group__lbl" for="hours">Time<span class="required">*</span></label>
        <div class="tylie-text-group input-group">
          <span class="input-group-text">hrs</span>
          <dx-number-box formControlName="hours"
                        name="hours"
                        class="tylie-select form-control"
                        [min]="0"
                        (change)="onTimeChanged()"
                        [showSpinButtons]="false"></dx-number-box>
        </div>
        <p class="p-extra-small">Minimun 15 mins required</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="tylie-form-group form-group" [ngClass]="{error: ((formGroup.controls['hours'].errors || formGroup.controls['mins'].errors || formGroup.hasError('minimumMinsRequired')) && f.submitted)}">
        <label class="tylie-form-group__lbl" for="mins">&nbsp;</label>
        <div class="tylie-text-group input-group">
          <span class="input-group-text">mins</span>
          <dx-select-box [dataSource]="minuteOptions" displayExpr="display" valueExpr="val" formControlName="mins" name="mins" class="tylie-select form-control" (onValueChanged)="onTimeChanged()">
          </dx-select-box>
        </div>
      </div>
    </div>
  </div>
</div>
