import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../services/core/utilities';
import { UserRoles } from '../../../configurations/user-roles';
import { AlertService } from '../../../services/core/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaggerService } from '../../../services/tagger/tagger.service';
import { DraftOrderResponseModel, SearchDraftRequestModel } from '../../../models/tagger/tagger.model';
import { DraftOrderSort, TaggerTabs } from '../../../configurations/enums/enums';
import { RouteConstants } from '../../../configurations/route-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuickplayerComponent } from '../../videoplayer/quickplayer/quickplayer.component';
import { TaggerDraftorderAddnotepopupComponent } from '../../popups/tagger-draftorder-addnotepopup/tagger-draftorder-addnotepopup.component';
import { DraftOrderNoteResponse } from '../../../models/order/draft-order.model';

@Component({
  selector: 'app-tagger-draft-search',
  templateUrl: './tagger-draft-search.component.html',
  styleUrls: ['./tagger-draft-search.component.css']
})
export class TaggerDraftSearchComponent implements OnInit {
  public draftTaggerAds: DraftOrderResponseModel[] = [];
  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  public selectedSortOrder: string = "";
  public modalRef: BsModalRef;
  public sortDataSource: any[];

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private taggerService: TaggerService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.sortDataSource = Object.keys(DraftOrderSort).map(key => ({
      id: key,
      name: DraftOrderSort[key]
    }));
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadDraftTaggerAds();
  }

  public onRefresh() {
    this.quickSearchValue = "";
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadDraftTaggerAds();
  }

  public loadDraftTaggerAds() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: SearchDraftRequestModel = {
      search: this.quickSearchValue,
      sortOrder: this.selectedSortOrder
    }
    console.log(payload);
    this.taggerService.getDraftTaggers(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          this.draftTaggerAds = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onSortValueChanged(e: any) {
    this.selectedSortOrder = e.value;
    this.loadDraftTaggerAds();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    //if (this.quickSearchValue === null || this.quickSearchValue.trim() === "") {
    //  return;
    //}
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.loadDraftTaggerAds();
    }
  }

  public gotoEditDraft(taggerAd: DraftOrderResponseModel) {
    this.router.navigate([RouteConstants.adCreatorRoute], {
      queryParams: {
        isEditDraft: true,
        draftOrderGuid: taggerAd.draftOrderGuid,
        activeTab: TaggerTabs.savedDraftAds
      },
    });
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public editNote(taggerAd: DraftOrderResponseModel) {
    console.log(this.draftTaggerAds);
    console.log(taggerAd);
    const initialState = {
      draftOrderGuid: taggerAd.draftOrderGuid,
      payload: taggerAd.note? taggerAd.note.payload : ""
    };
    this.bsModalRef = this.modalService.show(TaggerDraftorderAddnotepopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSaveVaultAssetNote.subscribe((result: DraftOrderNoteResponse) => {
      console.log(this.draftTaggerAds);
      console.log(result);
      if (result != null && result != undefined) {
        var dOrder = this.draftTaggerAds.find(d => d.draftOrderGuid = taggerAd.draftOrderGuid);
        dOrder.note.payload = result.payload
        dOrder.note.createdBy = result.createdBy
        dOrder.note.createdDate = result.createdDate
      }
    });
  }

  public archiveDraftOrder(taggerAd: DraftOrderResponseModel) {
    console.log(taggerAd);
  }

}
