<div class="modal-header">
  <button
    type="button"
    class="modal-close"
    (click)="closePopup()"
  >
    <icon-delete color="#000"></icon-delete>
  </button>
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div class="modal-body">
  <form
    [formGroup]="locationFormGroup"
    #f="ngForm"
    (ngSubmit)="onFormSubmit()"
  >
    <div
      *ngIf="isAddLocation || isEditLocation"
      class=""
    >
      <h3 class="tylie-type-h3">LOCATION INFO</h3>

      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-group">
            <label
              for="name"
              class="tylie-form-group__lbl"
              >Location Name</label
            >
            <input
              type="text"
              id="name"
              name="name"
              formControlName="name"
              class="tylie-text form-control"
              placeholder="Type a location name..."
              [ngClass]="{
                error:
                  isDuplicateLocationName ||
                  (locationFormGroup.get('name').invalid &&
                    (isFormSubmitted ||
                      locationFormGroup.get('name').dirty ||
                      locationFormGroup.get('name').touched)),
                disabled: isEditLocation
              }"
              [readonly]="isEditLocation"
              [disabled]="isEditLocation"
            />
            <div
              *ngIf="
                locationFormGroup.get('name').invalid &&
                (isFormSubmitted ||
                  locationFormGroup.get('name').dirty ||
                  locationFormGroup.get('name').touched)
              "
            >
              <div
                class="error-text"
                *ngIf="locationFormGroup.get('name').errors.required"
              >
                Field is required.
              </div>
            </div>
            <div
              class="error-text"
              *ngIf="isDuplicateLocationName"
            >
              Same location name already exists.
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="tylie-form-group form-group">
            <label
              for="address"
              class="tylie-form-group__lbl"
              >Address</label
            >
            <input
              type="text"
              id="address"
              name="address"
              formControlName="address"
              class="tylie-text form-control"
              placeholder="Type a address..."
              [ngClass]="{
                error:
                  locationFormGroup.get('address').invalid &&
                  (isFormSubmitted ||
                    locationFormGroup.get('address').dirty ||
                    locationFormGroup.get('address').touched)
              }"
            />
            <div
              *ngIf="
                locationFormGroup.get('address').invalid &&
                (isFormSubmitted ||
                  locationFormGroup.get('address').dirty ||
                  locationFormGroup.get('address').touched)
              "
            >
              <div
                class="error-text"
                *ngIf="locationFormGroup.get('address').errors.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="tylie-form-group form-group">
            <label
              for="city"
              class="tylie-form-group__lbl"
              >City</label
            >
            <input
              type="text"
              id="city"
              name="city"
              formControlName="city"
              class="tylie-text form-control"
              placeholder="Type a city..."
              [ngClass]="{
                error:
                  locationFormGroup.get('city').invalid &&
                  (isFormSubmitted ||
                    locationFormGroup.get('city').dirty ||
                    locationFormGroup.get('city').touched)
              }"
            />
            <div
              *ngIf="
                locationFormGroup.get('city').invalid &&
                (isFormSubmitted ||
                  locationFormGroup.get('city').dirty ||
                  locationFormGroup.get('city').touched)
              "
            >
              <div
                class="error-text"
                *ngIf="locationFormGroup.get('city').errors.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row col">
          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label
                for="state"
                class="tylie-form-group__lbl"
                >State</label
              >
              <dx-select-box
                [dataSource]="stateDataSource"
                name="state"
                id="state"
                class="tylie-select form-control"
                formControlName="state"
                valueExpr="shortCode"
                [showClearButton]="true"
                displayExpr="stateName"
                placeholder="Select a value..."
                [ngClass]="{
                  error:
                    locationFormGroup.get('state').invalid &&
                    (isFormSubmitted ||
                      locationFormGroup.get('state').dirty ||
                      locationFormGroup.get('state').touched)
                }"
              >
              </dx-select-box>
              <div
                *ngIf="
                  locationFormGroup.get('state').invalid &&
                  (isFormSubmitted ||
                    locationFormGroup.get('state').dirty ||
                    locationFormGroup.get('state').touched)
                "
              >
                <div
                  class="error-text"
                  *ngIf="locationFormGroup.get('state').errors.required"
                >
                  Field is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="tylie-form-group form-group">
              <label
                for="zipCode"
                class="tylie-form-group__lbl"
                >Zip / Postal code</label
              >
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                formControlName="zipCode"
                class="tylie-text form-control"
                placeholder="Type a zip code..."
                [ngClass]="{
                  error:
                    locationFormGroup.get('zipCode').invalid &&
                    (isFormSubmitted ||
                      locationFormGroup.get('zipCode').dirty ||
                      locationFormGroup.get('zipCode').touched)
                }"
              />
              <div
                *ngIf="
                  locationFormGroup.get('zipCode').invalid &&
                  (isFormSubmitted ||
                    locationFormGroup.get('zipCode').dirty ||
                    locationFormGroup.get('zipCode').touched)
                "
              >
                <div
                  class="error-text"
                  *ngIf="locationFormGroup.get('zipCode').errors.required"
                >
                  Field is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="isAddLocation || isEditLocationUsers"
      class=""
    >
      <h3 class="tylie-type-h3">USERS</h3>

      <div formArrayName="users">
        <ng-container
          *ngFor="let userGroup of usersFormArray.controls; let i = index"
        >
          <app-taggeruserpopup
            [taggerUserRequest]="userGroup"
            [isFormSubmitted]="isFormSubmitted"
          ></app-taggeruserpopup>
        </ng-container>

        <div class="mb-3">
          <button
            type="button"
            class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link"
            (click)="addUser()"
          >
            <span class="tylie-button__icon">
              <icon-plus-circle color="#1e90ff"></icon-plus-circle>
            </span>
            <span>ADD ANOTHER USER</span>
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        (isAddLocation || isEditLocationGroups) &&
        groupDataSource &&
        groupDataSource.length > 0
      "
      class=""
    >
      <h3 class="tylie-type-h3">GROUPS</h3>

      <div
        lass="group-content"
        *ngIf="groupDataSource && groupDataSource.length > 0"
      >
        <div class="col-md-12">
          <label class="tylie-form-group__lbl">
            Location included in Groups
          </label>
        </div>
        <div
          class="col-md-4"
          *ngFor="let group of groupDataSource; let i = index"
        >
          <div class="tylie-form-group form-check group-item">
            <input
              type="checkbox"
              class="form-check-input form-check"
              id="{{ 'group' + i }}"
              [checked]="selectedGroups.includes(group.groupGuid)"
              (change)="selectGroup(group)"
            />
            <label
              for="{{ 'group' + i }}"
              class="form-check-label"
              >{{ group.name }}</label
            >
          </div>
        </div>

        <div class="col-md-12 group-footer">
          <button type="button"
            class="tylie-button tylie-button--xs tylie-button--link select-all"
            (click)="selectAll()"
          >
            Select All
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="tylie-button tylie-button--xs tylie-button--link me-3"
        (click)="closePopup()"
      >
        Cancel
      </button>
      <button
        class="tylie-button tylie-button--xs tylie-button--icon"
        type="submit"
      >
        <span class="tylie-button__icon">
          <icon-checkmark></icon-checkmark>
        </span>
        <span
          *ngIf="isAddLocation || isEditLocation"
          class="tylie-button__text"
          >SAVE LOCATION</span
        >
        <span
          *ngIf="isEditLocationUsers"
          class="tylie-button__text"
          >SAVE LOCATION USERS</span
        >
        <span
          *ngIf="isEditLocationGroups"
          class="tylie-button__text"
          >SAVE LOCATION GROUPS</span
        >
      </button>
    </div>
  </form>
</div>
