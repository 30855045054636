import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'info-popup',
  templateUrl: './infopopup.component.html',
  styleUrls: ['./infopopup.component.css'],
})
export class InfoPopupComponent extends BasePopupComponent
 {
  public mainMessage: string;  
  public headerMessage: string;
  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onClose = new Subject();
  }

  public closePopup() {
    this.onClose.next(true);
    this.bsModalRef.hide();   
  }
}
