<!--<nav-header headerTitle="User" link="Back To All Users" [linkRoute]="usersRoute"></nav-header>-->

<ng-container *ngIf="detail">
  <div class="d-flex justify-content-between mb-4">
    <div class="d-flex">
      <div class="tylie-img-container me-3">
        <img *ngIf="detail.avatar" class="tylie-img" src="{{detail.avatar}}" alt="Logo">
        <span class="tylie-img__fallback" *ngIf="detail.avatar == null"> 110x110 </span>
      </div>
      <div>
        <h3 class="tylie-type-h3 mb-2">{{detail.firstName}}&nbsp; {{detail.lastName}}</h3>
        <div class="tylie-data tylie-data--block">
          <div class="tylie-data__txt" *ngIf="detail.isUserTypeClient == true">{{detail.client.name}}</div>
          <div class="tylie-data__txt" *ngIf="detail.isUserTypeDestination == true">{{detail.destination}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <!--<button type="button" *ngIf="detail.config?.showResetPassword == true" class="tylie-button tylie-button--xs tylie-button--icon" (click)="generateResetPasswordToken()">
        <span class="tylie-button__icon"><icon-refresh></icon-refresh></span>
        <span class="tylie-button__text">Reset Password</span>
      </button>-->
      <button type="button" *ngIf="detail.config?.showInActivateProfile == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onStatusUpdateVerifyUserNeedsToBeRemoved(false)">
        <span class="tylie-button__icon"><icon-expire></icon-expire></span>
        <span class="tylie-button__text">InActivate User</span>
      </button>
      <button type="button" *ngIf="detail.config?.showEdit == true" class="tylie-button tylie-button--xs tylie-button--icon ms-3" (click)="onEditUser()">
        <span class="tylie-button__icon"><icon-edit-pencil></icon-edit-pencil></span>
        <span class="tylie-button__text">Edit User</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6" id="create-client-section">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            CONTACT DETAILS
          </h4>
        </div>
        <div class="tylie-panel__body" id="createClientBody">
          <div class="tylie-data tylie-data--block mb-2">
            <label class="tylie-data__lbl">Email:</label>
            <span class="tylie-data__txt">{{detail.email}}</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.workPhone">
            <label class="tylie-data__lbl">Phone:</label>
            <span class="tylie-data__txt">{{detail.workPhone}}</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2">
            <label class="tylie-data__lbl">Time Zone:</label>
            <span class="tylie-data__txt">{{detail.timeZone}}</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.isPostHouseUser == true">
            <label class="tylie-data__lbl">Post House User:</label>
            <span class="tylie-data__txt">yes</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.isPostHouseUser == true">
            <label class="tylie-data__lbl">Post House:</label>
            <span class="tylie-data__txt">{{detail.editorialHouse.name}}</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.isDealerUser == true">
            <label class="tylie-data__lbl">Dealer User:</label>
            <span class="tylie-data__txt">yes</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.isDealerUser == true">
            <label class="tylie-data__lbl">Dealer Name:</label>
            <span class="tylie-data__txt">{{detail.dealerName}}</span>
          </div>
          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.isDealerUser == true">
            <label class="tylie-data__lbl">Dealer Number:</label>
            <span class="tylie-data__txt">{{detail.dealerNumber}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6" id="create-client-section" *ngIf="detail.isUserTypeClient == true">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Brands/User Groups
          </h4>
        </div>
        <div class="tylie-panel__body" id="createClientBody">
          <div *ngIf="detail.userClientBrands != null && detail.userClientBrands?.length > 0">
            <dx-data-grid id="brandsGrid" class="tylie-grid"
                          #brandsGrid
                          [dataSource]="detail.userClientBrands"
                          [rowAlternationEnabled]="true"
                          [hoverStateEnabled]="true"
                          [showRowLines]="true"
                          keyExpr="brandId">
              <dxi-column dataField="brandName" width="40%" cssClass="gridStyle" caption="Brand Name"></dxi-column>
              <dxi-column caption="Product" width="60%" cellTemplate="productTemplate" cssClass="gridStyle" [allowSorting]="false"></dxi-column>

              <div *dxTemplate="let t of 'productTemplate'" class="dx-datagrid-nowrap-remove">
                <div class="p-extra-small">
                  <div class="tyl-wordwrap">{{t.data.productsDisplay | joinBySeperator: '; '}}</div>
                </div>
              </div>
              <dxo-paging [pageSize]="25"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true"
                         [allowedPageSizes]="[25,50,100]"
                         [showNavigationButtons]="true"
                         [showInfo]="true">
              </dxo-pager>
            </dx-data-grid>
          </div>
          <div *ngIf="detail.userClientBrands == null || detail.userClientBrands?.length == 0">
            <span class="tylie-type-body">There are no brands assigned to this user.</span>
          </div>

          <div class="tylie-data tylie-data--block mb-2" *ngIf="detail.userGrpsDisplay != null && detail.userGrpsDisplay != ''">
            <h3 class="tylie-type-h3 mb-2">
              User Groups
            </h3>
            <div>
              <span class="tylie-type-body">{{ detail.userGrpsDisplay }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6" id="create-client-section">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            ROLES
          </h4>
        </div>
        <div class="tylie-panel__body" id="createClientBody">
          <div *ngIf="detail.userRole != null && detail.userRole.userRoles?.length > 0">
            <div *ngFor="let role of detail.userRole.userRoles">
              <p class="tylie-type-body" *ngIf="role.isChecked == true">{{role.roleName}}</p>
            </div>
          </div>
          <div *ngIf="detail.userRole == null || detail.userRole.userRoles == null || detail.userRole.userRoles?.length == 0">
            <p class="typlie-type-body">There are no roles assigned to this user.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6" id="create-client-section">
      <div class="tylie-panel" id="createclient">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            NOtifications
          </h4>
        </div>
        <div class="tylie-panel__body" id="createClientBody">
          <div *ngIf="detail.userProfileNotificationPreferences != null && detail.userProfileNotificationPreferences?.length > 0">
            <div *ngFor="let notification of detail.userProfileNotificationPreferences">
              <p class="typlie-type-body">{{notification}}</p>
            </div>
          </div>
          <div *ngIf="detail.userProfileNotificationPreferences == null || detail.userProfileNotificationPreferences?.length == 0">
            <span class="tylie-type-body">Notification preferences are not set for this user.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
