import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-audiotranscoding',
  templateUrl: './serviceaudiotranscoding.component.html'
})
export class ServiceAudioTranscodingQComponent extends BaseServiceDataCollectionComponent {
  
  public predefinedSpecs: Array<string> = [];
  public customFileNameOptions: Array<string>;

  constructor() {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.predefinedSpecs = ['MP3', 'AIFF', 'WAV'];
    this.customFileNameOptions = this.additionalMetadata.customFileNameOptions;
  }
}
