import { Component, OnInit, ViewChild } from "@angular/core";
import { AddGroupsLocationsPopUpComponent } from "../../popups/add-groups-locations-popup/add-groups-locations-pop-up.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Utilities } from "../../../services/core/utilities";
import { RouteConstants } from "../../../configurations/route-constants";
import { Router } from "@angular/router";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { AlertService } from "../../../services/core/alert.service";
import { GroupModel } from "../../../models/tagger/tagger.model";
import { DxDataGridComponent } from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import { GridParams } from "../../../models/config/gridparams.model";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.css"],
})
export class GroupsComponent implements OnInit {
  public modalRef: BsModalRef;
  public groupDataSource: GroupModel[];
  public resultGroupsStore: any = {};

  @ViewChild("groupSearch", { static: true }) groupSearch: DxDataGridComponent;

  constructor(
    public util: Utilities,
    private modalService: BsModalService,
    private taggerService: TaggerService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //this.getGroups();
    this.searchGroups();
  }
  // add group
  onClickAddGroup() {
    const initialState = {
      isEditGroup: false,
      isAddOrEditLocation: false,
      isAddGroup: true,
    };

    this.modalRef = this.modalService.show(
      AddGroupsLocationsPopUpComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        // refresh group grid to show edits
        this.groupSearch.instance.refresh();
      }
    });
  }

  // edit group
  onClickEditGroup(value) {
    const initialState = {
      isEditGroup: true,
      isAddOrEditLocation: false,
      isAddGroup: false,
      groupGuid: value.groupGuid,
      groupDescription: value.groupDescription,
      groupName: value.name,
    };

    this.modalRef = this.modalService.show(
      AddGroupsLocationsPopUpComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        // refresh group grid to show edits
        if (this.groupSearch) {
          this.groupSearch.instance.refresh();
        }
      }
    });
  }

  // add/remove locations to existing group
  onClickAddOrEditGroupLocations(value) {
    const initialState = {
      isEditGroup: false,
      isAddOrEditLocation: true,
      isAddGroup: false,
      locationsByGroup: value.locations,
      groupName: value.name,
      groupGuid: value.groupGuid,
    };

    this.modalRef = this.modalService.show(
      AddGroupsLocationsPopUpComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );

    this.modalRef.content.onClose.subscribe(result => {
      if (result == true) {
        // refresh group grid to show edits
        if (this.groupSearch) {
          this.groupSearch.instance.refresh();
        }
      }
    });
  }

  public gotoTaggerHome() {
    this.router.navigate([RouteConstants.taggerRoute]);
  }

  //public getGroups() {
  //  Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
  //  this.isInitialLoadComplete = false;
  //  this.taggerService.getGroups().subscribe(
  //    (res: any) => {
  //      console.log(res);
  //      if (res.isSuccess == true) {
  //        this.groupDataSource = res.result;
  //      } else {
  //        this.util.handleIsNotSuccess(res.errors);
  //      }
  //      this.isInitialLoadComplete = true;
  //      this.alertService.ShowLoader(false);
  //    },
  //    (error) => {
  //      this.isInitialLoadComplete = true;
  //      this.util.handleError(error);
  //      this.alertService.ShowLoader(false);
  //    }
  //  );
  //}

  searchGroups() {
    var taggerService = this.taggerService;
    var util = this.util;

    this.resultGroupsStore.store = new CustomStore({
      key: "groupGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort
          ? JSON.stringify(loadOptions.sort)
          : "[{'selector':'name','desc':false}]";

        let gridParams: GridParams = {
          group: null,
          skip: skip,
          take: take,
          sort: sortOptions,
          isExport:
            loadOptions.isLoadingAll && loadOptions.isLoadingAll === true,
        };
        let request: any = { GridParams: gridParams, isGroupSearch: false };

        console.log(request);
        return taggerService
          .searchGropus(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var result = response.result;
              var groupSearchResponse = result.searchResults as GroupModel[];
        
              var obj: any = {
                data: groupSearchResponse,
                totalCount: result.totalCount,
              };

              return obj;
            } else {
              util.handleIsNotSuccess(response.errors);
              throw "Data Loading Error";
            }
          })
          .catch((error) => {
            util.handleError(error);
            throw "Data Loading Error";
          });
      },
    });
  }

  //onCellPrepared(e: any) {
  //  if (e.rowType === "data" && e.column.command === "expand") {
  //    if (e.data != null) {
  //      if (e.data.locations.length == 0) {
  //        e.cellElement.removeClass("dx-datagrid-expand");
  //        e.cellElement.empty();
  //      }
  //    }
  //  }
  //}
}
