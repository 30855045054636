import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateByLetters'
})

export class TruncateByLetters implements PipeTransform {

  constructor() {
  }

  transform(value: string, limit = 35, completeWords = true, ellipsis = '…'): any {
    let lastindex = limit;
    if (completeWords) {
      lastindex = value.substr(0, limit).lastIndexOf(' ');
    }

    if (value.length <= limit)
      return `${value.substr(0, limit)}`;
    else
      return `${value.substr(0, limit)}${ellipsis}`;
  }
}
