import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forEach } from 'core-js/fn/dict';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from '../../../configurations/constants';
import { VaultAssetStages } from '../../../configurations/enums/enums';
import { RouteConstants } from '../../../configurations/route-constants';
import { UserRoles } from '../../../configurations/user-roles';
import { GridParams } from '../../../models/config/gridparams.model';
import { SearchVaultRequestViewModel } from '../../../models/vault/vault-serach-request.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { GlobalService } from '../../../services/core/global.service';
import { Utilities } from '../../../services/core/utilities';
import { SubService } from '../../../services/media/sub.service';
import { VaultService } from '../../../services/vault/vault.service';
import { VaultAssetPopupComponent } from '../../popups/vault-asset-popup/vault-asset-popup.component';
import { VaultassetsremovedestroypopupComponent } from '../../popups/vaultassetsremovedestroypopup/vaultassetsremovedestroypopup.component';

@Component({
  selector: 'app-vault-search',
  templateUrl: './vault-search.component.html',
  styleUrls: ['./vault-search.component.css']
})
export class VaultSearchComponent implements AfterViewInit, OnInit {

  public searchVaultCriteriaObj = <SearchVaultRequestViewModel>{};
  public vaultSearchRequest: FormGroup;
  public resultVaultSearchStore: any = {};
  public resultDigitalStorageStore: any = {};
  public quickSearchValue: string = '';
  public modalRef: BsModalRef;
  public now: Date = new Date();
  public isReport: boolean = false;
  public activeGrid: string = Constants.physicalStorageGrid;

  public selectedAssets: Array<any>;
  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];
  public warehouseDataSource: any = [];
  public statusDataSource: any = [];

  @ViewChild('vaultSearchGrid', { static: false }) vaultSearchGrid: DxDataGridComponent;
  @ViewChild('vaultSearchGridAssets', { static: true }) vaultSearchGridAssets: DxDataGridComponent;
  @ViewChild('digitalStorageGrid', { static: true }) digitalStorageGrid: DxDataGridComponent;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private vaultService: VaultService,
    private router: Router,
    private modalService: BsModalService,
    public configService: ConfigService,
    private gs: GlobalService,
    private alertService: AlertService,
    private sub: SubService
  ) { }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.vaultSearchRequest = new FormGroup({
      vaultRequestJobNumber: new FormControl(),
      clientId: new FormControl(),
      productId: new FormControl(),
      brandId: new FormControl(),
      adId: new FormControl(),
      title: new FormControl(),
      clientJobNumber: new FormControl(),
      reference: new FormControl(),
      warehouseId: new FormControl(),
      vaultStatusId: new FormControl(),
      bjvvtm: new FormControl(),
      assetBarCode: new FormControl(),
      assetDescription: new FormControl(),
      createdDateFrom: new FormControl(),
      createdDateTo: new FormControl(),
      missingAssets: new FormControl(),
      cartonBarcode: new FormControl()
    });

    this.searchVaultCriteriaObj = <SearchVaultRequestViewModel>{};
    this.getSearchConfig();
    this.searchVaults();
    this.searchDigitalStorage();

    this.sub.vaultAssetEditedMethodCalled.subscribe(() => {
      this.vaultSearchGrid.instance.refresh();
    });

    this.sub.vaultDigitalAssetEditedMethodCalled.subscribe(() => {
      this.digitalStorageGrid.instance.refresh();
    });

  }


  private getSearchConfig() {
    this.vaultService.getSearchVaultConfig().subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.clientDataSource = res.result.clients;
        this.brandDataSource = res.result.brands;
        this.productDataSource = res.result.products;
        this.warehouseDataSource = res.result.warehouses;
        this.statusDataSource = res.result.vaultStatuses;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onClientValueChanged(e) {
    if (this.util.notEmpty(e)) {
      this.getClientBrands(e.value);
      this.getClientProducts(e.value);
    }
    else {
      this.brandDataSource = [];
      this.productDataSource = [];
    }
  }

  public onBrandValueChanged(e) {
    if (e == null || e == undefined || e.value == null) {
      this.getBrandProducts(0);
    }
    else {
      this.getBrandProducts(e.value);
    }
  }

  public getBrandProducts(id: number) {
    this.productDataSource = [];

    this.vaultSearchRequest.controls['productId'].setValue("");
    this.configService.getBrandProducts(id, this.vaultSearchRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
        this.productDataSource = this.productDataSource.filter(pds => pds.id != 0);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  public getClientProducts(id: number) {
    this.vaultSearchRequest.controls['productId'].setValue("");
    this.productDataSource = [];
    this.configService.getClientProducts(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  public getClientBrands(id: number) {
    if (this.vaultSearchRequest.value.brand != null) {
      this.vaultSearchRequest.controls['brandId'].setValue(null);
    }
    this.brandDataSource = [];
    this.configService.getClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;
      }
      else {
        this.brandDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.brandDataSource = [];
      });
  }

  public onFormSubmit({ value, valid }: { value: SearchVaultRequestViewModel, valid: boolean }) {
    console.log(value);
    if (value.adId === null && value.brandId === null && value.clientId === null && value.vaultStatusId === null && value.productId === null
      && (value.createdDateFrom === null || value.createdDateFrom === undefined) && (value.createdDateTo === null || value.createdDateTo === undefined)
      && value.vaultRequestJobNumber === null && value.title === null && value.clientJobNumber === null && value.reference === null && value.missingAssets === null
      && value.bjvvtm === null && value.assetBarCode === null && value.cartonBarcode === null && (value.assetDescription === null || value.assetDescription === undefined)
      && value.warehouseId == null) {
      return;
    }

    if (value.createdDateFrom != null || value.createdDateFrom === undefined)
      value.createdDateFrom = moment(value.createdDateFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.createdDateTo != null || value.createdDateTo === undefined)
      value.createdDateTo = moment(value.createdDateTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.searchVaultCriteriaObj.adId = value.adId;
    this.searchVaultCriteriaObj.clientId = value.clientId;
    this.searchVaultCriteriaObj.brandId = value.brandId;
    this.searchVaultCriteriaObj.productId = value.productId;
    this.searchVaultCriteriaObj.vaultRequestJobNumber = value.vaultRequestJobNumber;
    this.searchVaultCriteriaObj.title = value.title;
    this.searchVaultCriteriaObj.quickSearch = null;
    this.searchVaultCriteriaObj.clientJobNumber = value.clientJobNumber;
    this.searchVaultCriteriaObj.reference = value.reference;
    this.searchVaultCriteriaObj.warehouseId = value.warehouseId;
    this.searchVaultCriteriaObj.vaultStatusId = value.vaultStatusId;
    this.searchVaultCriteriaObj.bjvvtm = value.bjvvtm;
    this.searchVaultCriteriaObj.assetBarCode = value.assetBarCode;
    this.searchVaultCriteriaObj.assetDescription = value.assetDescription;
    this.searchVaultCriteriaObj.createdDateFrom = value.createdDateFrom;
    this.searchVaultCriteriaObj.createdDateTo = value.createdDateTo;
    this.searchVaultCriteriaObj.missingAssets = value.missingAssets;
    this.searchVaultCriteriaObj.cartonBarcode = value.cartonBarcode;

    console.log(this.searchVaultCriteriaObj);

    if (this.activeGrid == Constants.physicalStorageGrid) {
      if (this.vaultSearchGrid != null)
        this.vaultSearchGrid.instance.refresh();
    } else {
      if (this.digitalStorageGrid != null)
        this.digitalStorageGrid.instance.refresh();
    }
  }

  public searchVaults() {
    try {
      var vaultService = this.vaultService;
      var util = this.util;
      var criteriaObj = this.searchVaultCriteriaObj;

      this.resultVaultSearchStore.store = new CustomStore({
        key: "vaultAssetGuid",
        load: function (loadOptions: any) {
          var skip = loadOptions.skip;
          var take = loadOptions.take;
          var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";
          let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
          let request: any = { criteria: criteriaObj, GridParams: gridParams };

          return vaultService.searchVaultAssets(request)
            .toPromise()
            .then((response: any) => {
              if (response.isSuccess == true) {
                var vaultSearchResponse = response.result;
                this.selectedAssets = response.result;
                var obj: any = {
                  data: vaultSearchResponse.searchResults,
                  totalCount: vaultSearchResponse.totalCount
                };
                return obj;
              }
              else {
                util.handleIsNotSuccess(response.errors);
                throw 'Data Loading Error';
              }
            })
            .catch(error => {
              console.log(error);
              util.handleError(error);
              throw 'Data Loading Error';
            });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public clearQuickSearch() {
    console.log(this.quickSearchValue);
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      this.searchVaultCriteriaObj.quickSearch = this.quickSearchValue;
      this.setDefaultFormValues();
      if (this.activeGrid == Constants.physicalStorageGrid) {
        this.vaultSearchGrid.instance.refresh();
      } else {
        this.digitalStorageGrid.instance.refresh();
      }
    }
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchVaultCriteriaObj.quickSearch = this.quickSearchValue;

    if (this.activeGrid == Constants.physicalStorageGrid) {
      this.vaultSearchGrid.instance.refresh();
    } else {
      this.digitalStorageGrid.instance.refresh();
    }
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public clearSearch() {
    if (this.activeGrid == Constants.physicalStorageGrid) {
      this.resultVaultSearchStore.store = null;
    } else {
      this.resultDigitalStorageStore.store = null;
    }
    
    this.setDefaultFormValues();
    this.vaultSearchRequest.reset();
    this.quickSearchValue = "";

    if (this.activeGrid == Constants.physicalStorageGrid) {
      this.vaultSearchGrid.instance.refresh();
    } else {
      this.digitalStorageGrid.instance.refresh();
    }
  }

  public setDefaultFormValues() {
    this.searchVaultCriteriaObj.adId = null;
    this.searchVaultCriteriaObj.clientId = null;
    this.searchVaultCriteriaObj.brandId = null;
    this.searchVaultCriteriaObj.productId = null;
    this.searchVaultCriteriaObj.vaultRequestJobNumber = null;
    this.searchVaultCriteriaObj.title = null;
    this.searchVaultCriteriaObj.quickSearch = null;
    this.searchVaultCriteriaObj.clientJobNumber = null;
    this.searchVaultCriteriaObj.reference = null;
    this.searchVaultCriteriaObj.warehouseId = null;
    this.searchVaultCriteriaObj.vaultStatusId = null;
    this.searchVaultCriteriaObj.bjvvtm = null;
    this.searchVaultCriteriaObj.assetBarCode = null;
    this.searchVaultCriteriaObj.assetDescription = null;
    this.searchVaultCriteriaObj.createdDateFrom = null;
    this.searchVaultCriteriaObj.createdDateTo = null;
    this.searchVaultCriteriaObj.isStaging = null;
    this.searchVaultCriteriaObj.cartonBarcode = null;
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public showVaultRequestDetail(vaultAssetGuid: string) {
    console.log(vaultAssetGuid);
    if (vaultAssetGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openVaultAsset(vaultAssetGuid);
  }

  public reportClicked() {
    this.isReport = true;
    var gridInstance = this.vaultSearchGridAssets;

    if (this.activeGrid == Constants.digitalStorageGrid) {
      gridInstance = this.digitalStorageGrid;
    }

    console.log(gridInstance)
    if (gridInstance != null) {
       gridInstance.instance.exportToExcel(false);
    }
  }

  public onExporting() {
    this.alertService.showMessage("REPORT", Constants.downloadInitiatedSimpleMessage, MessageSeverity.info);
    this.vaultSearchGridAssets.instance.columnOption("visible", true);
  }

  public onExported() {
    this.vaultSearchGridAssets.instance.columnOption("visible", false);
    this.isReport = false;
    this.alertService.showMessage("REPORT", Constants.downloadCompleted, MessageSeverity.info);
  }

  public onDigitalStorageExporting() {
    this.alertService.showMessage("REPORT", Constants.downloadInitiatedSimpleMessage, MessageSeverity.info);
  }

  public onDigitalStorageExported() {
    this.isReport = false;
    this.alertService.showMessage("REPORT", Constants.downloadCompleted, MessageSeverity.info);
  }

  public stagingClicked() {
    var gridInstance = this.vaultSearchGrid;
    var isVaultDigitalAsset = false;
    if (this.activeGrid == Constants.digitalStorageGrid) {
      gridInstance = this.digitalStorageGrid;
      isVaultDigitalAsset = true;
    }
    var assets = gridInstance.instance.getSelectedRowsData();
    if (assets.length == 0)
      this.alertService.showMessage("ERROR", Constants.noVaultAssetSelected, MessageSeverity.error);
    else if (assets.length > 0 && this.checkAnyVaultAssetsNotEligibleForStaging(assets) === true) {
      this.alertService.showMessage("ERROR", Constants.ineligibleAssetsSelectedForLogoutOrStaging, MessageSeverity.error);
    } else {
      var initialState = {
        selectedAssets: assets,
        source: VaultAssetStages.staging,
        isVaultDigitalAsset: isVaultDigitalAsset
      };
      console.log(initialState);
      this.modalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
        console.log(result);
        if (result == true) {
          if (isVaultDigitalAsset) 
            this.digitalStorageGrid.instance.refresh();
          else
            this.vaultSearchGrid.instance.refresh();
        }
        if (isVaultDigitalAsset)
          this.digitalStorageGrid.instance.deselectAll();
        else
          this.vaultSearchGrid.instance.deselectAll();
      });
    }
  }

  public logoutClicked() {
    var gridInstance = this.vaultSearchGrid;
    var isVaultDigitalAsset = false;
    if (this.activeGrid == Constants.digitalStorageGrid) {
      gridInstance = this.digitalStorageGrid;
      isVaultDigitalAsset = true;
    }
    var assets = gridInstance.instance.getSelectedRowsData();
    if (assets.length == 0)
      this.alertService.showMessage("ERROR", Constants.noVaultAssetSelected, MessageSeverity.error);
    else if (assets.length > 0 && this.checkAnyVaultAssetsNotEligibleForLogout(assets) === true) {
      this.alertService.showMessage("ERROR", Constants.ineligibleAssetsSelectedForLogoutOrStaging, MessageSeverity.error);
    } else {
      var initialState = {
        selectedAssets: assets,
        source: VaultAssetStages.logout,
        isVaultDigitalAsset: isVaultDigitalAsset
      };
      console.log(initialState);
      this.modalRef = this.modalService.show(VaultassetsremovedestroypopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
        console.log(result);
        if (result == true) {
          if (isVaultDigitalAsset)
            this.digitalStorageGrid.instance.refresh();
          else
            this.vaultSearchGrid.instance.refresh();
        }
        if (isVaultDigitalAsset)
          this.digitalStorageGrid.instance.deselectAll();
        else
          this.vaultSearchGrid.instance.deselectAll();
      });
    }
  }

  public loginClicked() {
    var gridInstance = this.vaultSearchGrid;
    var isVaultDigitalAsset = false;
    if (this.activeGrid == Constants.digitalStorageGrid) {
      gridInstance = this.digitalStorageGrid;
      isVaultDigitalAsset = true;
    }
    var assets = gridInstance.instance.getSelectedRowsData();
    if (assets.length == 0)
      this.alertService.showMessage("ERROR", Constants.noVaultAssetSelected, MessageSeverity.error);
    else if (assets.length > 0 && this.checkAnyVaultAssetsNotEligibleForLogin(assets) === true) {
      this.alertService.showMessage("ERROR", Constants.loggedOutAssetsNotSelected, MessageSeverity.error);
    } else {
      var initialState = {
        selectedAssets: assets,
        source: VaultAssetStages.login,
        isVaultDigitalAsset: isVaultDigitalAsset
      };
      console.log(initialState);
      this.modalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
        console.log(result);
        if (result == true) {
          if (isVaultDigitalAsset)
            this.digitalStorageGrid.instance.refresh();
          else
            this.vaultSearchGrid.instance.refresh();
        }
        if (isVaultDigitalAsset)
          this.digitalStorageGrid.instance.deselectAll();
        else
          this.vaultSearchGrid.instance.deselectAll();
      });
    }
  }

  public removeClicked() {
    var gridInstance = this.vaultSearchGrid;
    var isVaultDigitalAsset = false;
    if (this.activeGrid == Constants.digitalStorageGrid) {
      gridInstance = this.digitalStorageGrid;
      isVaultDigitalAsset = true;
    }
    var assets = gridInstance.instance.getSelectedRowsData();
    if (assets.length == 0)
      this.alertService.showMessage("ERROR", Constants.noVaultAssetSelected, MessageSeverity.error);
    else if (assets.length > 0 && this.checkAnyVaultAssetsNotEligibleForRemove(assets) === true) {
      this.alertService.showMessage("ERROR", Constants.ineligibleAssetsSelectedForRemoveOrDestroy, MessageSeverity.error);
    } else {
      var initialState = {
        selectedAssets: assets,
        source: VaultAssetStages.remove,
        isVaultDigitalAsset: isVaultDigitalAsset
      };
      console.log(initialState);
      this.modalRef = this.modalService.show(VaultassetsremovedestroypopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
        console.log(result);
        if (result == true) {
          if (isVaultDigitalAsset)
            this.digitalStorageGrid.instance.refresh();
          else
            this.vaultSearchGrid.instance.refresh();
        }
        if (isVaultDigitalAsset)
          this.digitalStorageGrid.instance.deselectAll();
        else
          this.vaultSearchGrid.instance.deselectAll();
      });
    }
  }

  public destroyClicked() {
    var gridInstance = this.vaultSearchGrid;
    var assets = gridInstance.instance.getSelectedRowsData();
    if (assets.length == 0)
      this.alertService.showMessage("ERROR", Constants.noVaultAssetSelected, MessageSeverity.error);
    else if (assets.length > 0 && this.checkAnyVaultAssetsNotEligibleForDestroy(assets) === true) {
      this.alertService.showMessage("ERROR", Constants.ineligibleAssetsSelectedForRemoveOrDestroy, MessageSeverity.error);
    } else {
      var initialState = {
        selectedAssets: assets,
        source: VaultAssetStages.destroy
      };
      console.log(initialState);
      this.modalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
        console.log(result);
        if (result == true)
          this.vaultSearchGrid.instance.refresh();
        this.vaultSearchGrid.instance.deselectAll();
      });
    }
  }

  public checkAnyVaultAssetsNotEligibleForLogin(assets): boolean {
    if (assets != null && assets.length > 0) {
      var assetsNotAllowedForLogin = assets.filter(m => m.isLoginAllowed == false);
      console.log(assetsNotAllowedForLogin);
      if (assetsNotAllowedForLogin != null && assetsNotAllowedForLogin.length > 0) {
        return true;
      }
    }
    return false;
  }

  public checkAnyVaultAssetsNotEligibleForLogout(assets): boolean {
    if (assets != null && assets.length > 0) {
      var assetsNotAllowedForLogout = assets.filter(m => m.isLogoutAllowed == false);
      console.log(assetsNotAllowedForLogout);
      if (assetsNotAllowedForLogout != null && assetsNotAllowedForLogout.length > 0) {
        return true;
      }
    }
    return false;
  }

  public checkAnyVaultAssetsNotEligibleForStaging(assets): boolean {
    if (assets != null && assets.length > 0) {
      var assetsNotAllowedForStaging = assets.filter(m => m.isStagingAllowed == false);
      console.log(assetsNotAllowedForStaging);
      if (assetsNotAllowedForStaging != null && assetsNotAllowedForStaging.length > 0) {
        return true;
      }
    }
    return false;
  }

  public checkAnyVaultAssetsNotEligibleForRemove(assets): boolean {
    if (assets != null && assets.length > 0) {
      var assetsNotAllowedForRemove = assets.filter(m => m.isRemoveAllowed == false);
      console.log(assetsNotAllowedForRemove);
      if (assetsNotAllowedForRemove != null && assetsNotAllowedForRemove.length > 0) {
        return true;
      }
    }
    return false;
  }

  public checkAnyVaultAssetsNotEligibleForDestroy(assets): boolean {
    if (assets != null && assets.length > 0) {
      var assetsNotAllowedForDestroy = assets.filter(m => m.isDestroyAllowed == false);
      console.log(assetsNotAllowedForDestroy);
      if (assetsNotAllowedForDestroy != null && assetsNotAllowedForDestroy.length > 0) {
        return true;
      }
    }
    return false;
  }

  public searchDigitalStorage() {
    try {
      var vaultService = this.vaultService;
      var util = this.util;
      var criteriaObj = this.searchVaultCriteriaObj;
      console.log(this.resultDigitalStorageStore);
      this.resultDigitalStorageStore.store = new CustomStore({
        key: "vaultDigitalAssetLineItemId",
        load: function (loadOptions: any) {
          var skip = loadOptions.skip;
          var take = loadOptions.take;
          var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

          let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
          let request: any = { criteria: criteriaObj, GridParams: gridParams };

          return vaultService.searchVaultDigitalStorage(request)
            .toPromise()
            .then((response: any) => {
              console.log(response);
              if (response.isSuccess == true) {
                var orderSearchResponse = response.result;
                var obj: any = {
                  data: orderSearchResponse.searchResults,
                  totalCount: orderSearchResponse.totalCount
                };

                return obj;
              }
              else {
                util.handleIsNotSuccess(response.errors);
                throw 'Data Loading Error';
              }
            })
            .catch(error => {
              util.handleError(error);
              throw 'Data Loading Error';
            });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public onClickPhysicalStorage() {
    console.log("Physical Storage");
    if (this.activeGrid == Constants.digitalStorageGrid) {
      this.clearSearch();
      this.activeGrid = Constants.physicalStorageGrid;
    }
  }

  public onClickDigitalStorage() {
    console.log("Digital Storage");
    if (this.activeGrid == Constants.physicalStorageGrid) {
      this.clearSearch();
      this.activeGrid = Constants.digitalStorageGrid;
      this.searchDigitalStorage();
    }
  }

  public showDigitalRequestDetail(vaultDigitalAssetLineItemId: number) {
    console.log(vaultDigitalAssetLineItemId);

    this.gs.openDigitalVaultAsset(vaultDigitalAssetLineItemId);
  }

}
