import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService } from "../../../../services/core/alert.service";
import { ConfigService } from "../../../../services/core/config.service";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from "../../../../models/config/gridparams.model";
import { DxDataGridComponent } from "devextreme-angular";
import { SearchBrandModel } from "../../../../models/admin/adminsearch.model";
import { RouteConstants } from "../../../../configurations/route-constants";
import * as moment from 'moment-timezone';
import { GlobalService } from '../../../../services/core/global.service';
import { brandDetailSource } from '../../../../configurations/enums/enums';

@Component({
  selector: 'search-brands',
  templateUrl: './search-brands.component.html',
  styleUrls: ['./search-brands.component.css']
})
export class SearchBrandsComponent implements OnInit {
  @ViewChild('brandSearch', { static: true }) brandSearchGrid: DxDataGridComponent;

  public searchBrandFormGroup: FormGroup;
  public quickSearchValue: string = '';

  public resultBrandStore: any = {};
  public clientDataSource: any = {};
  public statusDataSource: any = {};
  public searchBrandCriteriaObj = <SearchBrandModel>{};
  public now: Date = new Date();
  public brandGuid: string;
  public source: string;

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService,
      private gs: GlobalService
    , private configService: ConfigService
    , private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getBrands();

    //get config
    this.getConfig();

    //search formgroup
    this.searchBrandFormGroup = new FormGroup({
      brandName: new FormControl(),
      status: new FormControl(),
      client: new FormControl(),
      createdFrom: new FormControl(),
      createdTo: new FormControl()
    });

    this.route.queryParams.subscribe((params: Params) => {
      console.log(params);
      this.brandGuid = params['brandguid'];
      this.source = params['source'];
    });

    //if (this.source == brandDetailSource[brandDetailSource.create]) {
    //  this.showBrandView(this.brandGuid);
    //}

  }

  public getBrands() {
    var adminService = this.adminService;
    var util = this.util;
    var criteriaObj = this.searchBrandCriteriaObj;

    this.resultBrandStore.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, GridParams: gridParams };

        return adminService.searchAllBrands(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var brandSearchResponse = response.result;
              var obj: any = {
                data: brandSearchResponse.brands,
                totalCount: brandSearchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getBrandSearchConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.clientDataSource = res.result.clients;
        this.statusDataSource = res.result.brandStatuses;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public clearSearch() {
    //Clears all searches including quick and advanced
    this.searchBrandFormGroup.reset();
    this.quickSearchValue = "";
    this.setDefaultFormValues();
    this.brandSearchGrid.instance.refresh();
  }

  public setDefaultFormValues() {
    this.searchBrandCriteriaObj.brandName = null;
    this.searchBrandCriteriaObj.status = null;
    this.searchBrandCriteriaObj.client = null;
    this.searchBrandCriteriaObj.createdFrom = null;
    this.searchBrandCriteriaObj.createdTo = null;
    this.searchBrandCriteriaObj.quickSearch = null;
  }

  public quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue === '' || this.quickSearchValue.trim() === '') {
      return;
    }

    this.searchBrandCriteriaObj.quickSearch = this.quickSearchValue;
    this.brandSearchGrid.instance.refresh();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public clearQuickSearch() {
    if (this.quickSearchValue == '' || this.quickSearchValue == null) {
      //this.searchOrderCriteriaObj = <SearchOrderRequestViewModel>{};
      this.searchBrandCriteriaObj.quickSearch = this.quickSearchValue;
      this.setDefaultFormValues();
      this.brandSearchGrid.instance.refresh();
    }
  }

  public onFormSubmit({ value, valid }: { value: SearchBrandModel, valid: boolean }) {
    console.log(value);
    if (value.brandName === null && value.status === null && value.client === null
      && (value.createdTo === null || value.createdTo === undefined)
      && (value.createdFrom === null || value.createdFrom === undefined)) {
      return;
    }

    if (value.createdFrom != null || value.createdFrom === undefined)
      value.createdFrom = moment(value.createdFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.createdTo != null || value.createdTo === undefined)
      value.createdTo = moment(value.createdTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.searchBrandCriteriaObj.brandName = value.brandName;
    this.searchBrandCriteriaObj.status = value.status;
    this.searchBrandCriteriaObj.client = value.client;
    this.searchBrandCriteriaObj.createdFrom = value.createdFrom;
    this.searchBrandCriteriaObj.createdTo = value.createdTo;
    this.searchBrandCriteriaObj.quickSearch = null;

    console.log(this.searchBrandCriteriaObj);
    this.brandSearchGrid.instance.refresh();
  }

  public showBrandView(brandGuid: string) {
    console.log(brandGuid);
    if (brandGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openBrandDetails(brandGuid);

    //this.router.navigate([RouteConstants.brandDetailRoute, brandGuid]);
  }


  ngOnDestroy() {

  }
}
