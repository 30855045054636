import { Component } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'service-broadcastclearanceadministration',
  templateUrl: './servicebroadcastclearanceadministration.component.html'
})
export class ServiceBroadcastClearanceAdministrationQComponent extends BaseServiceDataCollectionComponent {

  public minuteOptions: Array<any> = [{ "val": 0, "display": "00" }, { "val": 15, "display": "15" }, { "val": 30, "display": "30" }, { "val": 45, "display": "45" }];

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.formGroup.setValidators([
      this.minimumMinsRequired(15, this.formGroup.get('totalMins'))
    ]);

    this.formGroup.updateValueAndValidity();

    var totalMins = this.formGroup.get('totalMins').value as number;
    if (totalMins != null && totalMins > 0) {
      var hrs = Math.floor(totalMins / 60);
      var mins = totalMins - (hrs * 60);

      this.formGroup.get('hours').setValue(hrs);

      this.formGroup.get('mins').setValue(mins);
    }

    if (this.formGroup.controls["mins"].value == null)
      this.formGroup.controls["mins"].setValue(0);
  }

  public onTimeChanged() {

    console.log(this.formGroup.get('mins').value);

    var hrs = this.formGroup.get('hours').value as number;
    var mins = this.formGroup.get('mins').value as number;

    //if (hrs == 0 && mins == 0)
    //{
    //  this.formGroup.get('totalMins').setValue(30);
    //  this.formGroup.get('mins').setValue(30);
    //  this.formGroup.get('hours').setValue(0);
    //  return;
    //}

    var totalMins = (hrs * 60) + mins;

    this.formGroup.get('totalMins').setValue(totalMins.toString());
  }
}
