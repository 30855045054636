import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { ProjectFolders, CreateFolderResponse } from '../../models/project/create-project.model';

@Injectable()

export class ProjectFolderService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  createProjectFolder(projectFolders: ProjectFolders): Observable<CreateFolderResponse> {
    let headers = new HttpHeaders({
      'fileCollectionId': projectFolders.folder[0].ProjectId.toString()
    });
    let options = { headers: headers };
    return this.http.post<CreateFolderResponse>(this.apiendPoint.createProjectFolder(), projectFolders.folder, options)
  }

}