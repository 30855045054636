import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-tab-tagged-ad',
  templateUrl: './icon-tab-tagged-ad.component.html',
  styleUrls: ['./icon-tab-tagged-ad.component.css']
})
export class IconTabTaggedAdComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string = '#1e90ff';

  constructor() { }

  ngOnInit(): void {
  }

}
