<div class="tylie-panel tylie-panel--secondary">
  <div class="tylie-panel__header tylie-panel__header--br-thick">
    <h4 class="tylie-panel__title">
      <div><span class="me-2"><icon-checkmark-white></icon-checkmark-white></span>SELECT END TAG VOICE
        OVER</div>
    </h4>
  </div>
  <div class="tylie-panel__body">
    <p class="tylie-type-body mb-3">Add voice over audio to your end tag</p>

    <div class="row">
      <div class="col-sm-4" *ngFor="let endCardVoiceOver of endCardVoiceOvers">
        <div class="tylie-card">
          <div class="tylie-card__img-wrap">
            <img class="tylie-card__img" src="./assets/images/voice-over.png" alt="Image" />
          </div>
          <div class="tylie-card__body">
            <label class="tylie-card__title">{{ endCardVoiceOver.voiceOverName }}</label>
            <div class="tylie-data tylie-data--block">
              <ng-container
                *ngIf="endCardVoiceOver.expirydate; else elseAvailableTemplate"
              >
                <span class="tylie-data__txt">Available:
                  {{
                    endCardVoiceOver.availableForOrderingFromDate
                      | date : "M/d/yy"
                  }}
                  - {{ endCardVoiceOver.expirydate | date : "M/d/yy" }}</span
                >
              </ng-container>
              <ng-template #elseAvailableTemplate>
                <span class="tylie-data__txt">Available: Unlimited</span>
              </ng-template>
            </div>
            <div class="tylie-data tylie-data--block">
              <span class="tylie-data__txt">Length: {{ endCardVoiceOver.durationinsecs }}</span>
            </div>
            <div class="d-flex mt-2">
              <button type="button"
                class="tylie-button tylie-button--xs tylie-button--icon"
                (click)="onSelect(endCardVoiceOver)"
              >
                <span class="tylie-button__icon"
                  ><i class="tylicon tylicon-tylmark"></i
                ></span>
                <span class="tylie-button__text"
                  >SELECT THIS END TAG VOICE OVER</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-records" *ngIf="endCardVoiceOvers?.length === 0">
      <span>No Records found</span>
    </div>
  </div>
</div>
