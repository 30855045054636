import { TranscodeAssetFile } from "../order/transcodeorder.model";

export class ShareTranscodeAssetRequest {
  assetFiles: Array<string>;
  subject: string;
  comments: string;
  password: string;
  isPasswordRequired: boolean;
  isUserCopied: boolean;
  expiryInDays: number;
  limitViews: number;
  emailTo: Array<string>;
}
