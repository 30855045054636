import { Component, OnInit, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from "@angular/router";
import { Utilities } from '../../../services/core/utilities';
import { RouteConstants } from '../../../configurations/route-constants';
import { CreateOrderContentComponent } from './create-order-content/createordercontent.component';

@Component({
  selector: 'createorder',
  templateUrl: './createorder.component.html',
  styleUrls: ['./createorder.component.css']
})
export class CreateOrderComponent implements OnInit {

  public ordersRoute = RouteConstants.ordersRoute;
  public isBackFromTrancodeRequest: boolean = false;
  public unSavedOrderGuid: string;

  @ViewChild(CreateOrderContentComponent, { static: true }) private co: CreateOrderContentComponent;

  constructor(public util: Utilities,
    private route: ActivatedRoute,
    private router: Router)
  {
    if (this.router != null && this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null
      && this.router.getCurrentNavigation().extras.state != null && this.router.getCurrentNavigation().extras.state.isBackFromTrancodeRequest != null) {
      this.isBackFromTrancodeRequest = this.router.getCurrentNavigation().extras.state.isBackFromTrancodeRequest;
      this.unSavedOrderGuid = this.router.getCurrentNavigation().extras.state.orderGuid;
      console.log(this.isBackFromTrancodeRequest);
    }
  }

  ngOnInit() {
    this.co.isBackFromTrancodeRequest = this.isBackFromTrancodeRequest;
  }

  public gotoOrdersHome() {
    this.router.navigate([RouteConstants.ordersRoute]);
  }

  public hasUnsavedData(): boolean {
    return this.co.hasUnsavedData();
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }
}
