import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { IAsperaConnect } from '../../../models/upload/asperaConnect.model';
import { IAsperaStatus, IAsperaTransferFileStatus } from '../../../models/upload/transferFileStatus.model';
import { CreateVaultConfig } from '../../../models/vault/createvault-config.model';
import { CreateVaultRequest, VaultFile } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { Utilities } from '../../../services/core/utilities';
import { environment } from '../../../../environments/environment';
import { AppConfig, APP_CONFIG } from '../../../app.module.config';
import { Inject } from '@angular/core';
import { VaultService } from '../../../services/vault/vault.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SelectattachmentvaultpopupComponent } from '../../popups/selectattachmentvaultpopup/selectattachmentvaultpopup.component';
import { Input } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../configurations/constants';
import { forEach } from 'angular';
import { RouteConstants } from '../../../configurations/route-constants';
import { Router } from '@angular/router';
import { VaultConfirmationPopupComponent } from '../../popups/vaultconfirmationpopup/vaultconfirmationpopup.component';
import { EmailListValidatorIfValueExists } from '../../../configurations/custom-validations';

declare var AW4: any;

@Component({
  selector: 'app-vault-create',
  templateUrl: './vault-create.component.html',
  styleUrls: ['./vault-create.component.css']
})
export class VaultCreateComponent implements OnInit {

  public vaultGuid: UUID;
  public vaultRequestId: number;
  public createVaultRequest: FormGroup;
  public createVaultConfig: CreateVaultConfig;
  currentAsperaConnector: IAsperaConnect = null;
  public createVaultRequestModel: CreateVaultRequest;

  @ViewChildren('pop') pop: QueryList<PopoverDirective>;
  @ViewChild('clearVaultpop', { static: false }) clearVaultpop: PopoverDirective;


  public modalRef: BsModalRef;

  private loadingConfig: boolean = false;
  public noFilesChosen: boolean = false;
  public isMaxSessionsExceeded: boolean = false;
  public isVaultSubmitted: boolean = false;

  public maxFilesUploadError: string;
  public maxSessionsUploadError: string;

  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];
  public warehouseDataSource: any = [];
  public formatDataSource: any = [];
  public requestTypesDataSource: any = [];

  private previousClientId: number = null;
  private previousBrandId: number = null;

  public attachments: Array<VaultFile>;

  public vaultRoute = RouteConstants.vaultRoute;

  public isRequestTypeLogIn: boolean;
  public isRequestTypeLogOut: boolean;
  public isRequestTypePermRemoval: boolean;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private fb: FormBuilder,
    private configService: ConfigService,
    private vaultService: VaultService,
    private alertService: AlertService,
    public util: Utilities,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createVaultRequest = this.createEmptyForm;
    this.vaultGuid = UUID.UUID();
    this.attachments = new Array<VaultFile>();

    this.getConfig();
  }
 
  public gotoVaultHome() {
    this.router.navigate([RouteConstants.vaultRoute]);
  }
  private get createEmptyForm() {
    return this.fb.group({
      clientId: new FormControl(null, Validators.required),
      brandId: new FormControl(null, Validators.required),
      productId: new FormControl(null, Validators.required),
      jobNumber: new FormControl(null),
      reference: new FormControl(null),
      warehouseId: new FormControl(null, Validators.required),
      requestReceivedFrom: new FormControl(null, [Validators.required]),
      requestType: new FormControl(null, Validators.required),
      confirmationEmails: new FormControl(null, [EmailListValidatorIfValueExists]),
      isAuditService: new FormControl(null),
      isDigitalEncoding: new FormControl(null),
      isClimateControl: new FormControl(null),
      notes: new FormControl(null),
      transferTo: new FormControl(null),
      transferDate: new FormControl(null),
      returnedDate: new FormControl(null)
    });
  }

  public onRequestTypeChange() {
    if (this.createVaultRequest.controls['requestType'].value == this.createVaultConfig.requestTypeLogIn) {
      this.isRequestTypeLogOut = false;
      this.isRequestTypeLogIn = true;
      this.isRequestTypePermRemoval = false;

      this.createVaultRequest.get('returnedDate').setValue(null);
      this.createVaultRequest.get('returnedDate').setValidators([Validators.required]);
      this.createVaultRequest.controls["returnedDate"].updateValueAndValidity();
    }
    else if (this.createVaultRequest.controls['requestType'].value == this.createVaultConfig.requestTypeLogOut) {
      this.isRequestTypeLogOut = true;
      this.isRequestTypeLogIn = false;
      this.isRequestTypePermRemoval = false;

      this.createVaultRequest.get('transferTo').setValue(null);
      this.createVaultRequest.get('transferTo').setValidators([Validators.required]);
      this.createVaultRequest.controls["transferTo"].updateValueAndValidity();

      this.createVaultRequest.get('transferDate').setValue(null);
      this.createVaultRequest.get('transferDate').setValidators([Validators.required]);
      this.createVaultRequest.controls["transferDate"].updateValueAndValidity();
    }
    else if (this.createVaultRequest.controls['requestType'].value == this.createVaultConfig.requestTypePermRemoval) {
      this.isRequestTypeLogOut = false;
      this.isRequestTypeLogIn = false;
      this.isRequestTypePermRemoval = true;

      this.createVaultRequest.get('transferTo').setValue(null);
      this.createVaultRequest.get('transferTo').setValidators([Validators.required]);
      this.createVaultRequest.controls["transferTo"].updateValueAndValidity();

      this.createVaultRequest.get('transferDate').setValue(null);
      this.createVaultRequest.get('transferDate').setValidators([Validators.required]);
      this.createVaultRequest.controls["transferDate"].updateValueAndValidity();
    }
    else {
      this.isRequestTypeLogIn = false;
      this.createVaultRequest.get('returnedDate').setValue(null);
      this.createVaultRequest.get('returnedDate').clearValidators();
      this.createVaultRequest.controls["returnedDate"].updateValueAndValidity();

      this.isRequestTypeLogOut = false;
      this.isRequestTypePermRemoval = false;
      this.createVaultRequest.get('transferTo').setValue(null);
      this.createVaultRequest.get('transferTo').clearValidators();
      this.createVaultRequest.controls["transferTo"].updateValueAndValidity();

      this.createVaultRequest.get('transferDate').setValue(null);
      this.createVaultRequest.get('transferDate').clearValidators();
      this.createVaultRequest.controls["transferDate"].updateValueAndValidity();
    }
  }

  private getConfig(selectedClientId: number = 0, selectedBrandId: number = 0, selectedProductId: number = 0) {

    var clientId = selectedClientId == 0 ? this.createVaultRequest.controls['clientId'].value : selectedClientId;
    var brandId = selectedBrandId == 0 ? this.createVaultRequest.controls['brandId'].value : selectedBrandId;
    var productId = selectedProductId == 0 ? this.createVaultRequest.controls['productId'].value : selectedProductId;

    var config = { clientId: clientId, brandId: brandId, productId: productId };

    this.loadingConfig = true;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getCreateVaultConfig(config).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {

        this.createVaultConfig = res.result.result as CreateVaultConfig;

        this.setConfig();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.loadingConfig = false;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingConfig = false;
        this.alertService.ShowLoader(false);
      });
  }

  private setConfig() {

    this.createVaultRequest.valueChanges.subscribe(val => {
      this.util.userCreatVaultForm = this.createVaultRequest;
    });

    this.clientDataSource = this.createVaultConfig.clients;
    this.brandDataSource = this.createVaultConfig.brands;
    this.productDataSource = (this.createVaultConfig.products != null || this.createVaultConfig.products != undefined) ? (this.createVaultConfig.products.length > 1 ? this.createVaultConfig.products.filter(pds => pds.id != 0) : this.createVaultConfig.products) : this.createVaultConfig.products;
    this.formatDataSource = this.createVaultConfig.formats;
    this.warehouseDataSource = this.createVaultConfig.warehouses;
    this.requestTypesDataSource = this.createVaultConfig.requestTypes;

    if (this.clientDataSource.length == 1)
      this.createVaultRequest.controls['clientId'].setValue(this.clientDataSource[0].id);

  }

  public onSubmitVault({ value, valid }: { value: CreateVaultRequest, valid: boolean }) {
    if (!valid) {
      return;
    }
    this.createVaultRequestModel = value;
    this.createVaultRequestModel.vaultRequestGuid = this.vaultGuid + "";
    this.createVaultRequestModel.isAuditService = this.createVaultRequest.value['isAuditService'];
    this.createVaultRequestModel.isClimateControl = this.createVaultRequest.value['isClimateControl'];
    this.createVaultRequestModel.isDigitalEncoding = this.createVaultRequest.value['isDigitalEncoding'];
    this.createVaultRequestModel.attachmentGuids = new Array<string>();
    this.attachments.forEach(a => {
      this.createVaultRequestModel.attachmentGuids.push(a.vaultFileGuid+"");
    });
    this.createVaultRequestModel.clientCode = this.clientDataSource.find(item => item.id == value['clientId']).code;
    this.createVaultRequestModel.brandCode = this.brandDataSource.find(item => item.id == value['brandId']).code;
    this.createVaultRequestModel.productCode = this.productDataSource.find(item => item.id == value['productId']).code;
    console.log(this.createVaultRequestModel);

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.vaultService.createVault(this.createVaultRequestModel).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.isVaultSubmitted = true;
        this.alertService.ShowLoader(false);
        this.showVaultConfirmation(res.result.vaultRequestNumber);
      }
      else {
        this.util.handleIsNotSuccess(["Unable to create your request at this time."]);
        this.alertService.ShowLoader(false);
      }
    });
  }

  public onClientValueChanged(e) {
    if (e.value === this.previousClientId || e.value == null)
      return;

    this.previousClientId = e.previousValue;

    this.onClientChangeConfirmed();
  }

  private onClientChangeConfirmed() {

    this.previousClientId = this.createVaultRequest.controls['clientId'].value;

    this.createVaultRequest.controls['brandId'].setValue(null);
    this.createVaultRequest.controls['productId'].setValue(null);

    this.brandDataSource = [];
    this.productDataSource = [];

    this.getClientBrands(this.createVaultRequest.controls['clientId'].value);
  }

  private getClientBrands(id: number) {

    if (id === null || id <= 0) {
      return;
    }

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;

        if (this.brandDataSource.length == 1)
          this.createVaultRequest.controls['brandId'].setValue(this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onBrandValueChanged(e) {
    if (e.value === this.previousBrandId || e.value == null)
      return;

    this.onBrandChangeConfirmed();
  }

  private onBrandChangeConfirmed() {

    this.previousBrandId = this.createVaultRequest.controls['brandId'].value;

    this.createVaultRequest.controls['productId'].setValue(null);

    this.productDataSource = [];

    this.getBrandProducts(this.createVaultRequest.controls['brandId'].value);
  }

  private getBrandProducts(id: number) {

    if (id === null || id <= 0) {
      return;
    }

    this.configService.getCurrentBrandProducts(id, this.createVaultRequest.value.clientId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        
        this.productDataSource = (products != null || products != undefined) ? (products.length > 1 ? products.filter(pds => pds.id != 0) : products) : products;
        
        if (this.productDataSource.length == 1)
          this.createVaultRequest.controls['productId'].setValue(this.productDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onProductValueChanged(e) {
    if (e.value == null)
      return;
  }


  public onAddAttachments() {
    var initialState = {
      vaultGuid: this.vaultGuid,
      attachmentCategory: "Attachment"
    };
    
    this.modalRef = this.modalService.show(SelectattachmentvaultpopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    
    this.modalRef.content.onSelectAttachmentVault.subscribe(result => {
      this.onSelectAttachmentVaultSubscriptionRaised(result);
    });
  }

  private onSelectAttachmentVaultSubscriptionRaised(newFile: VaultFile) {
    this.attachments.push(newFile);
    console.log(this.attachments);
  }

  public onAttDelete(fileName: string, vaultFileGuid: string) {

    this.vaultService.deleteVaultFile(vaultFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var attToDeleteIndex = this.attachments.findIndex(t => t.vaultFileGuid == vaultFileGuid);

        if (attToDeleteIndex >= 0)
          this.attachments.splice(attToDeleteIndex, 1);

        this.alertService.showMessage("SUCCESSS", fileName + ' deleted successfully', MessageSeverity.success, true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    this.onHidePop();
  }

  public onHideClearVaultPop() {
    this.clearVaultpop.hide();
  }

  public clearSavedData(): void {
    this.createVaultRequest = this.createEmptyForm;
    this.onHideClearVaultPop();
  }


  public onHidePop() {
    this.pop.forEach(p => {
      p.hide();
    });
  }

  public onDownloadFile(vaultFileGuid: string, fileName: string) {

    if (vaultFileGuid == null || vaultFileGuid == '00000000-0000-0000-0000-000000000000' || vaultFileGuid == '')
      return;

    var attachment = this.attachments.find(a => a.vaultFileGuid == vaultFileGuid);
    if (attachment != null) {
      var presignedUrl = attachment.downloadUrl;
      if (presignedUrl != null) {
        this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
          fileData => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = window.URL.createObjectURL(fileData);
            a.download = fileName;
            a.click();
          });
      }
    }
  }

  private showVaultConfirmation(vaultRequestNumber: string) {
    var initialState = {
      vaultRequestNumber: vaultRequestNumber
    };

    this.modalRef = this.modalService.show(VaultConfirmationPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {
      this.clearSavedData();
      this.router.navigate([RouteConstants.vaultRoute]);
    });
  }

  public hasUnsavedData(): boolean {
    console.log(this.util.userCreatVaultForm);
    return this.util.userCreatVaultForm != null && this.isVaultSubmitted == false;
  }

}
