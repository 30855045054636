import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Utilities } from "../../../../services/core/utilities";
import { TaggerService } from "../../../../services/tagger/tagger.service";
import { AlertService } from "../../../../services/core/alert.service";
import { TaggerCard } from "../../../../models/tagger/tagger.model";

@Component({
    selector: "app-tagger-end-card-step",
    templateUrl: "./tagger-end-card-step.component.html",
    styleUrls: ["./tagger-end-card-step.component.css"],
})
export class TaggerEndCardStepComponent implements OnInit {
    @Input() topFormGroup: FormGroup;
    @Output() next: EventEmitter<any> = new EventEmitter();
    public endCards: TaggerCard[] = [];
    public selectedEndCards: TaggerCard[] = [];

    constructor(
        public util: Utilities,
        private taggerService: TaggerService,
        public alertService: AlertService
    ) {}

    ngOnInit() {
        this.getEndCards();
        this.topFormGroup.get("endCards").valueChanges.subscribe((data) => {
            this.selectedEndCards = data;
        });
    }

    public getEndCards() {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.taggerService.getEndCards().subscribe(
            (res: any) => {
                if (res.isSuccess == true) {
                    this.endCards = res.result.searchResults;
                } else {
                    this.util.handleIsNotSuccess(res.errors);
                }

                this.alertService.ShowLoader(false);
            },
            (error) => {
                this.util.handleError(error);
                this.alertService.ShowLoader(false);
            }
        );
    }

    public onSelect(endCard: any): void {
        this.selectedEndCards = [endCard];
        this.topFormGroup.get("endCards").setValue(this.selectedEndCards);
        this.next.emit();
    }
}
