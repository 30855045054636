
import {finalize} from 'rxjs/operators';
import { Component, Inject, ViewChild, Input, OnInit, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { OrderUpdateResult } from "../../../../models/order/order-statusupdate-result.model";
import { OrderEditFTPDestinationPopupComponent } from "../../../popups/ordereditftpdestinationpopup/ordereditftpdestinationpopup.component";
import { Order } from "../../../../models/order/order.model";
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent, DxPopoverComponent } from "devextreme-angular";

import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { MediaDetailComponent } from "../../../mediadetail/mediadetail.component";
import { UploadOrderFileRequest } from "../../../../models/order/order-uploadfile.model";
import { uploadFileCategoryEnum } from "../../../../configurations/enums/enums";
import { OrderDetailFTPDestinationModel, OrderFTPDestinationDetailModel } from "../../../../models/order/order-userdestination";
import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';

@Component({
  selector: 'order-detail-ftp',
  templateUrl: './order-detail-ftp.component.html',
  styleUrls: ['./order-detail-ftp.component.css']
})
export class OrderDetailFTPComponent implements OnInit, AfterViewInit {
  public modalRef: BsModalRef;
  public orderDetailFtpStore: OrderFTPDestinationDetailModel[] = [];
  public orderFTPDestinationModel: OrderDetailFTPDestinationModel;
  public showLoader = false;

  public uploadingPodDestinationIds = [];

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild(DxPopoverComponent, { static: false }) orderShowFtpPasswordPopOver: DxPopoverComponent;
  @Input('orderGuid') orderGuid: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private uploadOrderFileService: UploadOrderFileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.orderGuid != null && this.orderGuid != undefined) {
      this.getFTPDetailsFromOrder();
    }
  }

  public getFTPDetailsFromOrder() {
    this.showLoader = true;
    this.dataGrid.instance.beginCustomLoading("Loading...");

    this.orderService.getFtpUploadDetailsForOrderDetail(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.orderFTPDestinationModel = res.result as OrderDetailFTPDestinationModel;
        this.orderDetailFtpStore = this.orderFTPDestinationModel.orderFTPDestinationDetailModelList as OrderFTPDestinationDetailModel[];
        this.alertService.logMessage(this.orderDetailFtpStore);
      }
      else {
        this.orderFTPDestinationModel = new OrderDetailFTPDestinationModel();
        this.util.handleIsNotSuccess(res.errors);
      }
      this.showLoader = false;
      this.dataGrid.instance.endCustomLoading();
    },
      error => {
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.orderFTPDestinationModel = new OrderDetailFTPDestinationModel();
        this.util.handleError(error);
      });
  }

  public onUploadPod(event: any, orderDestinationId: any) {
    console.log(orderDestinationId);
    if (event.target.files && event.target.files.length > 0) {

      this.uploadingPodDestinationIds.push(orderDestinationId);

      let uploadFile = event.target.files[0];

      var uploadRequest: UploadOrderFileRequest = {
        fileType: uploadFile.type,
        fileName: uploadFile.name,
        description: "",
        fileCategory: uploadFileCategoryEnum[uploadFileCategoryEnum.FTP],
        orderGuid: this.orderGuid,
        orderDestinationId: orderDestinationId
      };

      this.uploadOrderFileService.uploadOrderFTPPOd(uploadRequest, uploadFile).pipe(
        finalize(() => {
          this.resetuploadingPodDestinationIds(orderDestinationId);
        }))
        .subscribe((ftpOrderDetail: OrderDetailFTPDestinationModel) => {
          if (ftpOrderDetail != null && ftpOrderDetail != undefined) {
            this.orderFTPDestinationModel = ftpOrderDetail;
            this.orderDetailFtpStore = this.orderFTPDestinationModel.orderFTPDestinationDetailModelList as OrderFTPDestinationDetailModel[];
          }
        });
    }
    else {
      this.resetuploadingPodDestinationIds(orderDestinationId);
    }
  }

  public onOrderFtpDestinationEdit(data: OrderFTPDestinationDetailModel) {
    data.orderGuid = this.orderGuid;

    var initialState = {
      data: data
    };

    this.modalRef = this.modalService.show(OrderEditFTPDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onClose.subscribe(result => {
      this.orderFTPDestinationModel = result as OrderDetailFTPDestinationModel;
      this.orderDetailFtpStore = this.orderFTPDestinationModel.orderFTPDestinationDetailModelList as OrderFTPDestinationDetailModel[];
    });
  }

  public onFtpPasswordClickshowFtpPassword(e: any) {
    if (this.userProfileService.getUserRoles().includes(this.userRoles.getOrderStatusEditorRoleName())) {
      if (e.rowType == 'data' && e.column.dataField == "ftpPassword") {
        if (this.orderShowFtpPasswordPopOver != null && this.orderShowFtpPasswordPopOver != undefined) {
          this.orderShowFtpPasswordPopOver.instance.option("contentTemplate", e.data.ftpPassword);
          this.orderShowFtpPasswordPopOver.instance.option("target", e.cellElement);
          this.orderShowFtpPasswordPopOver.instance.show();
        }
      }
    }
    else { return; }
  }

  public onDownloadPOD(orderFileGuid: string, fileName: string) {
    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;
    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public deleteFtpPod(orderDestinationId: number, fileGuid: string) {
    this.orderService.deleteOrderFtpPod(fileGuid, this.orderGuid, orderDestinationId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderFtpPodDeleted, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.orderFTPDestinationModel = res.result as OrderDetailFTPDestinationModel;
      this.orderDetailFtpStore = this.orderFTPDestinationModel.orderFTPDestinationDetailModelList as OrderFTPDestinationDetailModel[];
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  private resetuploadingPodDestinationIds(orderDestinationId: number) {
    if (this.uploadingPodDestinationIds.includes(orderDestinationId)) {
      this.uploadingPodDestinationIds = this.uploadingPodDestinationIds.filter(f => f != orderDestinationId);
    }
  }
}

