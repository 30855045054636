import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AdminService } from "../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../services/core/alert.service";
import { Constants } from "../../../../configurations/constants";
import { ConfigService } from "../../../../services/core/config.service";
import { customEmailValidator, customPhoneValidator, digitsOnlyValidator, EmailListValidatorIfValueExists } from "../../../../configurations/custom-validations";
import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BrandSearchComponent } from "../../../popups/brandsearch/brandsearch.component";
import { CreateClientModel, ClientBrandSelectionModel, CostCenterAndBillToObjects, CostCenter, BillTo, BundleService, SelectedBundleServices } from "../../../../models/admin/client/createclient.model";
import { SelectedBrandsComponent } from "../selectedbrands/selected-brands.component";
import { RouteConstants } from "../../../../configurations/route-constants";
import { AddCostCenterAndBillToPopUp } from "../../../popups/addcostcenterandbilltopopup/addcostcenterandbilltopopup.component";
import { DxTreeViewComponent } from 'devextreme-angular';
import { AddCustomTagConfigurationPopUp } from '../../../popups/addcustomtagconfiguration/addcustomtagconfiguration.component';
import { CreateCustomTagConfig, CustomTagConfig } from '../../../../models/admin/client/customtagconfig.model';
import { AddEditBundleServicesPopUp } from '../../../popups/addeditbundleservices/addeditbundleservices.component';
import { AddPODRecipientsPopUp } from '../../../popups/addpodrecipients/addpodrecipients.component';
import { AddOrderedByPopUp } from '../../../popups/addorderedby/addorderedby.component';
import { ClientFileDeliveryConfig, CreateClientDeliveryConfig } from '../../../../models/admin/client/deliveryconfig.model';
import { AddFileDeliveryConfigurationPopUp } from '../../../popups/addfiledeliveryconfiguration/addfiledeliveryconfiguration.component';
import { UserRoles } from '../../../../configurations/user-roles';
import { AddTranscodeBillingSettingsPopUp } from '../../../popups/addtranscodebillingsettings/addtranscodebillingsettings.component';
import { AssetShareBillingSettings, ManagementFeeSettings, StorageManagementFeeSettings, TranscodeBillingSettings } from '../../../../models/admin/client/billingsettings.model';
import { AddManagementFeeSettingsettingsPopUp } from '../../../popups/addmanagementfeesettings/addmanagementfeesettings.component';
import { AddStorageManagementFeeSettingsettingsPopUp } from '../../../popups/addstoragemanagementfeesettings/addstoragemanagementfeesettings.component';
import { ClientTypes } from '../../../../configurations/enums/enums';
import { AddAssetShareBillingSettingsPopupComponent } from '../../../popups/add-asset-share-billing-settings-popup/add-asset-share-billing-settings-popup.component';

@Component({
  selector: 'create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

  public createClientFormGroup: FormGroup;
  @ViewChild(SelectedBrandsComponent, { static: true }) brandSelectedComp: SelectedBrandsComponent;

  @ViewChild('treeServices', { static: false }) treeServices: DxTreeViewComponent;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeBoxValue: number[] = [];

  public modalRef: BsModalRef;

  public accountExecutiveDataSource: any = {};
  public billingFrequencyDataSource: any = {};
  public clientTypeDataSource: any = {};
  public statusDataSource: any = {};
  public costCenters: string[] = [];
  public partnerClientDataSource: any[] = [];
  public countriesDataSource: string[] = [];
  public billTos: string[] = [];
  public costCenterDisplayString: string = null;
  public billToDisplayString: string = null;
  public stateDataSource: any = {};
  public base64logo = null;
  public selectedBrands: ClientBrandSelectionModel[] = [];
  public bundleServices: BundleService[] = [];
  public phoneMask = Constants.phoneMask;
  public podDistributionEmails: string = '';
  public orderedByList: string = '';
  public bundleServicesDisplayStrings: string[] = null;
  public fileDelvTypes: string[] = null;
  public fileDeliveryConfigurations: ClientFileDeliveryConfig[] = null;
  public canAddFileDeliveryConfig = false;
  public transcodeBillingTypes: any = [];
  public transcodeBillingSettings: TranscodeBillingSettings = null;
  public assetShareBillingSettings: AssetShareBillingSettings = null;
  public flatTranscodeTiers: any = [];
  public createConfig: any = null;
  public isFlatBillingSelectedOnClient: boolean = false;
  public managementFeeSettings: ManagementFeeSettings = null;
  public storageManagementFeeSettings: StorageManagementFeeSettings = null;
  public isClientTypePartner: boolean = false;

  constructor(public util: Utilities
    , private router: Router
    , private adminService: AdminService
    , private alertService: AlertService
    , private configService: ConfigService
    , private modalService: BsModalService
    , public userRoles: UserRoles
    , private fb: FormBuilder) {

  }

  ngOnInit() {
    this.getConfig();

    this.createClientFormGroup = this.fb.group({
      clientName: [null, Validators.compose([Validators.required])],
      accountExecutive: [null],
      clientType: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      aliasId: [null],
      address1: [null, Validators.compose([Validators.required])],
      address2: [null],
      city: [null, Validators.compose([Validators.required])],
      state: [null, Validators.compose([Validators.required])],
      country: [null, Validators.compose([Validators.required])],
      zipCode: [null, Validators.compose([Validators.required])],
      phone: new FormControl(null, Validators.compose([customPhoneValidator])),
      email: new FormControl(null, Validators.compose([customEmailValidator])),
      teamEmail: [null, Validators.compose([customEmailValidator])],
      poRequired: [true],
      isViewRestricted: [false],
      isOnTrail: [false],
      orderReviewRequired: [false],
      bundleServices: [null],
      isVaultBilling: [false],
      orderFinalReviewRequired: [false],
      billingFrequency: [null],
      opsNotes: [null],
      archiveDurationInDays: [null, Validators.compose([digitsOnlyValidator])],
      activeTandemStorageInDays: [null, Validators.compose([digitsOnlyValidator])],
      infrqStorageDays: [null, Validators.compose([digitsOnlyValidator])],
      stdStorageDays: [null, Validators.compose([digitsOnlyValidator])],
      transcodeBillingSettings: [null],
      assetShareBillingSettings: [null],
      managementFeeSettings: [null],
      storageManagementFeeSettings: [null],
      partnerClient: [null]
    });
  }

  public syncTreeViewSelection(e) {
    var component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
  }

  private getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  public getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateClientConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        console.log(res.result)
        this.createConfig = res.result;
        this.accountExecutiveDataSource = res.result.accountExecutives;
        this.billingFrequencyDataSource = res.result.billingFrequency;
        this.partnerClientDataSource = res.result.partnerClient;
        this.statusDataSource = res.result.clientStatus;
        this.countriesDataSource = res.result.countries;
        this.clientTypeDataSource = res.result.clientType;
        this.createClientFormGroup.patchValue({ country: res.result.defaultCountryCode });
        this.fileDelvTypes = res.result.fileDeliveryConfigTypes;
        this.canAddFileDeliveryConfig = res.result.canAddFileDeliveryConfig;
        this.transcodeBillingTypes = res.result.transcodeBillingTypes;
        this.flatTranscodeTiers = res.result.flatTranscodeTiers;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClientTypeValueChanged(event: any) {
    console.log(event);
    if (event.value == null || event.value == undefined || event.value == "") {
      this.isClientTypePartner = false;
    } else if (event.value == ClientTypes.Partner) {
      this.isClientTypePartner = true;
    } else {
      this.isClientTypePartner = false;
    }

  }

  public onCountryValueChanged(event: any) {
    this.stateDataSource = [];
    this.createClientFormGroup.controls['state'].setValue(null);

    if (event.value == null || event.value == undefined || event.value == "") {
      return;
    }
    this.configService.getStatesByCountry(event.value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.stateDataSource = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public gotoClientHome() {
    this.router.navigate([RouteConstants.clientsRoute]);
  }

  public onFormSubmit({ value, valid }: { value: CreateClientModel, valid: boolean }) {
    if (valid == false) {
      return;
    }

    var clientBrandSelectionList = this.brandSelectedComp.getSelectedBrands();

    if (this.validateBrandSelection(clientBrandSelectionList)) {
      value.brandSelectionList = clientBrandSelectionList;
      value.phone = this.util.notEmpty(value.phone) ? value.phone.replace(/\D+/g, '').trim() : null;
      value.logo = this.base64logo;
      value.costCenters = this.costCenters;
      value.billTos = this.billTos;
      value.bundleServices = this.bundleServices;
      value.podDistributionEmails = this.podDistributionEmails;
      value.orderedBy = this.orderedByList;
      value.fileDeliveryConfigurations = this.fileDeliveryConfigurations;
      value.transcodeBillingSettings = this.transcodeBillingSettings;
      value.assetShareBillingSettings = this.assetShareBillingSettings;
      value.managementFeeSettings = this.managementFeeSettings;
      value.storageManagementFeeSettings = this.storageManagementFeeSettings;

      console.log(value);

      this.createClient(value);
    }
  }

  public onUploadLogo(e) {
    const file = e.target.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  public _handleReaderLoaded(e) {
    var binaryString = e.target.result;
    this.base64logo = Constants.avatarEncoding + btoa(binaryString);
  }

  public onRemoveLogo() {
    this.base64logo = null;
  }

  public addBillToAndCostCenter() {
    var initialState = {
      costCenterList: this.costCenters,
      billToList: this.billTos,
      allowEdits: true,
      title: 'CC & BT'
    };

    this.modalRef = this.modalService.show(AddCostCenterAndBillToPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.oncostCenterAndBillToAddEdit.subscribe((result: CostCenterAndBillToObjects) => {
      this.costCenters = [];
      this.billTos = [];

      if (result != null && result != undefined) {
        result.costCenters = this.util.notEmpty(result.costCenters) ? result.costCenters.filter((cc): cc is CostCenter => cc != null) : [];
        result.billTos = this.util.notEmpty(result.billTos) ? result.billTos.filter((bt): bt is BillTo => bt != null) : [];

        result.costCenters.forEach(cc => {
          if (this.util.notEmpty(cc.costCenter)) {
            if (this.costCenters.indexOf(cc.costCenter) === -1) {
              this.costCenters.push(cc.costCenter)
            }
          }
        });
        result.billTos.forEach(bt => {
          if (this.util.notEmpty(bt.billTo)) {
            if (this.billTos.indexOf(bt.billTo) === -1) {
              this.billTos.push(bt.billTo)
            }
          }
        });
      }

      this.costCenterDisplayString = this.costCenters.join('; ');
      this.billToDisplayString = this.billTos.join('; ');
    });
  }

  public onAddEditApplyAllCustomTags() {
    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.customTagConfigurations == null || sb.customTagConfigurations == undefined || sb.customTagConfigurations.length == 0);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected
    };

    this.modalRef = this.modalService.show(AddCustomTagConfigurationPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveCustomTagConfiguration.subscribe((result: CreateCustomTagConfig) => {
      if (result != null && result != undefined) {
        if (result.customTagConfigurations != null && result.customTagConfigurations != undefined && result.customTagConfigurations.length > 0) {
          this.brandSelectedComp.applyCustomTagsToAllSelectedBrands(result.customTagConfigurations, result.selectedBrandsForCustomTags);
        }
      }
    });
  }

  public onAddEditApplyTranscodeBillingSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0 && this.isFlatBillingSelectedOnClient == false) {
      selectedBrands = selectedBrands.filter(sb => sb.transcodeBillingSettings == null || sb.transcodeBillingSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      transcodeBillingTypes: this.transcodeBillingTypes,
      billingFrequency: this.billingFrequencyDataSource,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      flatTranscodeTiers: this.flatTranscodeTiers,
      clientTranscodeBillingSettings: this.transcodeBillingSettings,
      flatTranscodeBillingFrequency: this.createConfig.flatTranscodeBillingFrequency
    };

    this.modalRef = this.modalService.show(AddTranscodeBillingSettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveTranscodeBillingSettings.subscribe((result: TranscodeBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForTranscodeBilling == null || result.selectedBrandsForTranscodeBilling.length == 0) {
          this.transcodeBillingSettings = result;
          this.isFlatBillingSelectedOnClient = true;
          this.brandSelectedComp.removeTranscodeBillingSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyTranscodeBillingSettingsToAllSelectedBrands(result, result.selectedBrandsForTranscodeBilling);
          this.isFlatBillingSelectedOnClient = false;
          this.transcodeBillingSettings = null;
        }
      }
    });
  }


  public onAddEditApplyAssetShareBillingSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.assetShareBillingSettings == null || sb.assetShareBillingSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      transcodeBillingTypes: this.transcodeBillingTypes,
      billingFrequency: this.billingFrequencyDataSource,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      flatTranscodeTiers: this.flatTranscodeTiers,
      clientAssetShareBillingSettings: this.assetShareBillingSettings,
      flatTranscodeBillingFrequency: this.createConfig.flatTranscodeBillingFrequency
    };

    console.log(initialState);

    this.modalRef = this.modalService.show(AddAssetShareBillingSettingsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveAssetShareBillingSettings.subscribe((result: AssetShareBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForAssetShareBilling == null || result.selectedBrandsForAssetShareBilling.length == 0) {
          this.assetShareBillingSettings = result;
          this.isFlatBillingSelectedOnClient = true;
          this.brandSelectedComp.removeAssetShareBillingSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyAssetShareBillingSettingsToAllSelectedBrands(result, result.selectedBrandsForAssetShareBilling);
          this.isFlatBillingSelectedOnClient = false;
          this.assetShareBillingSettings = null;
        }
      }
    });
  }

  public onAddEditApplyManagementFeeSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.managementFeeSettings == null || sb.managementFeeSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      billingFrequency: this.createConfig.managementFeeBillingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      clientManagementBillingSettings: this.managementFeeSettings,
    };

    this.modalRef = this.modalService.show(AddManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveManagementFeeSettings.subscribe((result: ManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForManagementFee == null || result.selectedBrandsForManagementFee.length == 0) {
          this.managementFeeSettings = result;
          this.brandSelectedComp.removeManagementFeeSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyManagementFeeSettingsToAllSelectedBrands(result, result.selectedBrandsForManagementFee);
          this.managementFeeSettings = null;
        }
      }
    });
  }

  public onAddEditApplyStorageManagementFeeSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.storageManagementFeeSettings == null || sb.storageManagementFeeSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      billingFrequency: this.createConfig.managementFeeBillingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      clientStorageManagementBillingSettings: this.storageManagementFeeSettings,
    };

    this.modalRef = this.modalService.show(AddStorageManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveStorageManagementFeeSettings.subscribe((result: StorageManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForStorageManagementFee == null || result.selectedBrandsForStorageManagementFee.length == 0) {
          this.storageManagementFeeSettings = result;
          this.brandSelectedComp.removeStorageManagementFeeSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyStorageManagementFeeSettingsToAllSelectedBrands(result, result.selectedBrandsForStorageManagementFee);
          this.storageManagementFeeSettings = null;
        }
      }
    });
  }

  public onRemoveBillingSettings() {
    this.transcodeBillingSettings = null;
  }

  public onRemoveAssetShareBillingSettings() {
    this.assetShareBillingSettings = null;
  }

  public onRemoveManagementFeeSettings() {
    this.managementFeeSettings = null;
  }

  public onRemoveStorageManagementFeeSettings() {
    this.storageManagementFeeSettings = null;
  }

  public onAddEditFileDeliveryConfig() {
    const initialState = {
      fileDelConfigs: this.fileDeliveryConfigurations,
      isEdit: false,
      fileDelvTypes: this.fileDelvTypes
    };

    this.modalRef = this.modalService.show(AddFileDeliveryConfigurationPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveFileDeliveryConfiguration.subscribe((result: CreateClientDeliveryConfig) => {
      if (result !== null && result !== undefined) {
        this.fileDeliveryConfigurations = result.fileDeliveryConfigurations;
      }
    });
  }

  public onAddEditPODRecipients() {
    const initialState = {
      podRecipientsList: this.podDistributionEmails,
    };

    this.modalRef = this.modalService.show(AddPODRecipientsPopUp, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSavePodRecipients.subscribe((result: string) => {
      this.podDistributionEmails = result;
    });
  }

  public onAddEditOrderedBy() {
    var initialState = {
      orderedByList: this.orderedByList,
    };

    this.modalRef = this.modalService.show(AddOrderedByPopUp, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSaveOrderedBy.subscribe((result: string) => {
      this.orderedByList = result;
    });
  }

  public onAddBundleServices() {
    var initialState = {
      isEdit: false,
      bundleServices: this.bundleServices
    };

    this.modalRef = this.modalService.show(AddEditBundleServicesPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveBundleServices.subscribe((result: BundleService) => {
      if (result != null && result != undefined) {
        if (this.bundleServices.findIndex(bs => bs.bundleServiceCode == result.bundleServiceCode) == -1) {
          this.bundleServices.push(result);
        }

        this.bundleServices = this.bundleServices;
      }
    });
  }

  public onEditBundleServices(bundleServCode: string) {

    var serv = this.bundleServices.find(bs => bs.bundleServiceCode.toLowerCase() == bundleServCode.toLowerCase());

    if (serv != undefined) {

      var initialState = {
        isEdit: true,
        bundleServiceSelected: serv
      };

      this.modalRef = this.modalService.show(AddEditBundleServicesPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
      this.modalRef.content.onSaveBundleServices.subscribe((result: BundleService) => {
        if (result != null && result != undefined) {
          this.bundleServices.forEach(serv => {
            if (serv.bundleServiceCode.toLowerCase() == result.bundleServiceCode.toLowerCase()) {
              serv.serviceCodes = result.serviceCodes;
              serv.serviceNames = result.serviceNames;
            }
          });

          this.bundleServices = this.bundleServices;
        }
      });
    }
  }

  public onDeleteBundleServices(bundleServCode: string) {
    if (bundleServCode != null && bundleServCode != undefined && bundleServCode != "") {
      this.bundleServices = this.bundleServices.filter(bs => bs.bundleServiceCode.toLowerCase() != bundleServCode.toLowerCase());
    }
  }

  private createClient(value: CreateClientModel) {
    this.adminService.createClient(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var results = res.result;
        this.alertService.showMessage("SUCCESS", Constants.createClientSuccessful, MessageSeverity.success);
        this.router.navigate([RouteConstants.clientsRoute]);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private validateBrandSelection(clientBrandSelectionList) {
    var selectionValid = true;

    if ((clientBrandSelectionList != null || clientBrandSelectionList != undefined) && clientBrandSelectionList.length > 0) {
      clientBrandSelectionList.forEach(bs => {
        if (bs.HasBrandProducts && (bs.products == null || bs.products == undefined || bs.products.length == 0)) {
          let updateMessageList: string[] = [];
          updateMessageList.push(Constants.productsNotSelected);
          var initialState = {
            errors: updateMessageList
          };
          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          selectionValid = false;
        }
      });
    }
    return selectionValid;
  }

  ngOnDestroy() {

  }
}
