import { Component, Inject, ViewChild, Input, OnInit, AfterViewInit, TemplateRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service'
import { OrderUpdateResult } from "../../../../models/order/order-statusupdate-result.model";
import { Order } from "../../../../models/order/order.model";
import { Constants } from "../../../../configurations/constants";
import { OrderService } from "../../../../services/order/order.service";
import { Utilities } from "../../../../services/core/utilities";
import { GridParams } from "../../../../models/config/gridparams.model";

import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/integration/jquery';

import { AppConfig, APP_CONFIG } from "../../../../app.module.config";
import { UserProfileService } from "../../../../services/user/user-profile.service";
import { UserRoles } from "../../../../configurations/user-roles";
import { DxDataGridComponent } from "devextreme-angular";

import { ErrorPopupComponent } from "../../../popups/errorpopup/errorpopup.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouteConstants } from "../../../../configurations/route-constants";
import { MediaDetailComponent } from "../../../mediadetail/mediadetail.component";
import { MediaDetailPopupComponent } from '../../../popups/mediadetailpopup/mediadetailpopup.component';
import { OrderEditNetworkDestinationPopupComponent } from '../../../popups/ordereditnetworkdestinationpopup/ordereditnetworkdestinationpopup.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrderDetailAction, OrderDetail } from '../../../../models/order/order-detail.model';

@Component({
  selector: 'order-detail-spots',
  templateUrl: './order-detail-spots.component.html',
  styleUrls: ['./order-detail-spots.component.css']
})
export class OrderDetailSpotsComponent implements OnInit, AfterViewInit {
  public modalRef: BsModalRef;
  public orderDetailSpotStore: Array<any> = [];
  public showLoader = false;
  public vendors: Array<any> = [];
  public vendorRequest: FormGroup;

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @Input('orderGuid') orderGuid: string;
  @Input('showActionsColumnInSpotsTab') showActionsColumnInSpotsTab: boolean;

  @Output() refreshOrder: EventEmitter<OrderDetail> = new EventEmitter<OrderDetail>();

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles,
    public util: Utilities,
    private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.orderGuid != null && this.orderGuid != undefined) {
      this.getSpotsFromOrder();
    }
  }

  private getSpotsFromOrder() {
    this.showLoader = true;
    this.dataGrid.instance.beginCustomLoading("Loading...");

    this.orderService.getSpotsFromOrder(this.orderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var orderSpots = res.result;
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        for (let s of orderSpots) {
          s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac, s.veilEncode, s.subtitles,
            s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
            s.sap, s.vchip, s.letterBox, s.surround, s.mono);
        }
        this.orderDetailSpotStore = orderSpots;
      }
      else {
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.showLoader = false;
        this.dataGrid.instance.endCustomLoading();
      });
  }

  public showDetail(spotFileGuid: string) {
    var initialState = {
      spotFileIdentifier: spotFileGuid
    };

    this.modalRef = this.modalService.show(MediaDetailPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public onAssignVendor(data: any, vendorAssignTemp: TemplateRef<any>) {

    this.vendorRequest = new FormGroup({
      vendorId: new FormControl(null, Validators.required),
      serviceId: new FormControl(data.serviceId),
      serviceName: new FormControl(data.serviceName),
    });

    this.getVendors(data.serviceCode);

    this.modalRef = this.modalService.show(vendorAssignTemp, this.util.getModalComponentOptions({}, false, true, false));
  }

  private getVendors(code: string) {

    this.orderService.getServiceVendors(code).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var allVendors = res.result as Array<any>;
        this.vendors = [];
        allVendors.forEach(ven => {
          this.vendors.push({ name: ven.name, value: ven.id });
        });

      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public onAssignVendorSubmit({ value, valid }: { value: any, valid: boolean }) {
    if (this.vendorRequest.controls['vendorId'].errors)
      return;

    var request = { "serviceId": value.serviceId, "vendorId": value.vendorId, "orderGuid": this.orderGuid };

    this.orderService.updateServiceVendor(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", "Assigned Vendor Successfully", MessageSeverity.success);
        //this.orderDetailSpotStore = res.result.spots as Array<any>;
        this.refreshOrder.emit(res.result.order);
        this.closePopup();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        if (this.util.handleError(error))
          this.closePopup();
      });
  }

  public closePopup() {
    this.modalRef.hide();
  }
}
