import { Component, OnInit, ViewChild } from '@angular/core';
import { Utilities } from "../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { UserRoles } from '../../configurations/user-roles';
import { ConfigService } from '../../services/core/config.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ErrorPopupComponent } from '../popups/errorpopup/errorpopup.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SpotUploadRequest } from '../../models/upload/upload-request.model';
import { UploadService } from '../../services/upload/upload.service';
import { Constants } from "../../configurations/constants";
import { OrderService } from '../../services/order/order.service';
import { AdminService } from '../../services/admin/admin.service';
import { CreateDestinationConfig, DestinationContact } from '../../models/admin/destination/createdestination.model';

@Component({
  selector: 'itOps',
  templateUrl: './itOps.component.html'
})
export class ITOpsComponent implements OnInit {

  //Create Placeholder
  public spotFileCreateRequest: FormGroup;
  public authOrgCreateRequest: FormGroup;
  public spotFileCreateRequests: Array<SpotUploadRequest> = [];
  @ViewChild("sf", { static: true }) sf: NgForm;

  // cancel order
  public cancelOrderRequest: FormGroup;
  public updateStatusCheckRequest: FormGroup;
  public updateOrderStatusRequest: FormGroup;
  public updateServiceLevelRequest: FormGroup;
  public encryptTextRequest: FormGroup;
  public createVendorPoRequest: FormGroup;
  public updateOrderProductRequest: FormGroup;
  private cancelOrderGuid: string;
  private updateOrderlineItemsGuid: string;
  public ocsubmitted: boolean = false;
  public ossubmitted: boolean = false;
  public slsubmitted: boolean = false;
  public olisubmitted: boolean = false;
  public opsubmitted: boolean = false;
  public showEn = false;
  public statusDataSource: any = [];
  public serviceLevelDataSource: any = [];
  public networkDataSource: any = [];
  public orderProductDataSource: any = [];
  public updateOrderStatusGuid: string;
  public updateOrderProductGuid: string;
  public updateServiceLevelGuid: string;
  public config: CreateDestinationConfig = null;


  //Common
  public modalRef: BsModalRef;

  constructor(public util: Utilities,
    private alertService: AlertService,
    private modalService: BsModalService,
    public userRoles: UserRoles,
    private uploadService: UploadService,
    private orderService: OrderService,
    private configService: ConfigService,
    private adminService: AdminService) {
  }

  ngOnInit() {

    this.spotFileCreateRequest = new FormGroup({
      title: new FormControl('', Validators.required),
      adID: new FormControl('', Validators.required)
    });

    this.cancelOrderRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required)
    });

    this.updateStatusCheckRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required)
    });

    this.encryptTextRequest = new FormGroup({
      plainText: new FormControl('', Validators.required),
      protectedText: new FormControl('')
    });

    this.authOrgCreateRequest = new FormGroup({
      organizationName: new FormControl('', Validators.required),
      organizationDomain: new FormControl('', Validators.required),
      organizationId: new FormControl('', Validators.required)
    });

    this.encryptTextRequest.get('protectedText').disable();

    this.updateOrderStatusRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required)
    });

    this.createVendorPoRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required),
      network: new FormControl('', Validators.required)
    });

    this.updateOrderProductRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required),
      product: new FormControl('', Validators.required)
    });

    this.updateServiceLevelRequest = new FormGroup({
      orderGuid: new FormControl('', Validators.required),
      sequenceid: new FormControl('', Validators.required),
      serviceLevel: new FormControl('', Validators.required)
    });

    this.GetOrderStatuses();
    this.GetServiceLevels();
    this.getConfig();
    this.getOrderProducts();
  }

  //#region update Order statuses

  public GetOrderStatuses(): void {

    this.configService.getOrderStatusesForITOPs()
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          this.statusDataSource = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onUpdateOrderStatusFormSubmit({ value, valid }: { value: UpdateOrderStatusRequest, valid: boolean }) {
    this.ossubmitted = true;
    if (valid == false)
      return;

    this.updateOrderStatusGuid = this.updateOrderStatusRequest.controls['orderGuid'].value;
    this.UpdateOrderStatus({ UpdateOrderStatusTo: this.updateOrderStatusRequest.controls['status'].value });
  }

  public UpdateOrderStatus(request: any) {
    this.ossubmitted = false;
    this.orderService.forceUpdateOrderStatus(this.updateOrderStatusGuid, request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

  }

  //#endregion

  //#region update Service Level

  public GetServiceLevels(): void {

    this.configService.getServiceLevelsForITOPs()
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          this.serviceLevelDataSource = res.result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onUpdateServiceLevelFormSubmit({ value, valid }: { value: UpdateServiceLevelRequest, valid: boolean }) {
    this.slsubmitted = true;
    if (valid == false)
      return;

    this.updateServiceLevelGuid = this.updateServiceLevelRequest.controls['orderGuid'].value;
    this.UpdateServiceLevel({ IsRush: this.updateServiceLevelRequest.controls['serviceLevel'].value, Sequenceid: this.updateServiceLevelRequest.controls['sequenceid'].value });
  }

  public UpdateServiceLevel(request: any) {
    this.slsubmitted = false;
    this.orderService.forceUpdateServiceLevel(this.updateServiceLevelGuid, request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.groupUpdated, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

  }

  //#endregion

  //#region create vendor po

  public onCreateVendorPoFormSubmit({ value, valid }: { value: CreateVendorPoRequest, valid: boolean }) {
    this.ossubmitted = true;
    if (valid == false)
      return;

    this.updateOrderStatusGuid = this.createVendorPoRequest.controls['orderGuid'].value;
    this.CreateVendorPurchaseOrder({ VendorId: this.createVendorPoRequest.controls['network'].value });
  }

  public CreateVendorPurchaseOrder(request: any) {
    this.ossubmitted = false;
    this.orderService.createVendorPurchaseOrder(this.updateOrderStatusGuid, request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.vendorPurchaseOrderCreated, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onUpdateOrderProductFormSubmit({ value, valid }: { value: UpdateOrderProductRequest, valid: boolean }) {
    this.opsubmitted = true;
    if (valid == false)
      return;

    console.log(value);
    this.updateOrderProductGuid = this.updateOrderProductRequest.controls['orderGuid'].value;
    this.UpdateOrderProduct({ productId: this.updateOrderProductRequest.controls['product'].value });
  }

  public UpdateOrderProduct(request: any) {
    this.ossubmitted = false;
    this.orderService.updateOrderProduct(this.updateOrderProductGuid, request).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.updateOrderProduct, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }


  private getConfig() {
    console.log("Called..");
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getCreateDestinationConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        console.log(res);
        this.config = res.result;
        this.networkDataSource = res.result.vendors
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private getOrderProducts() {
    console.log("Order products Called..");
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.configService.getProducts().subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.orderProductDataSource = res.result
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onPreferredNetworkChange() {
    //if (this.config != null && this.config.networksRequireDestinationSettings != null
    //  && this.config.networksRequireDestinationSettings.includes(this.editDestinationFormGroup.controls['preferredVendorId'].value)) {
    //  this.showDistributionSettings = true;
    //}
    //else {
    //  this.showDistributionSettings = false;
    //}
  }

  //#endregion

  //#region Clear Cache

  public onClearCache(): void {

    this.configService.clearCache()
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.alertService.showMessage("SUCCESS", "Cleared cache successfully", MessageSeverity.success);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  //#endregion

  //#region Create Placeholder

  public onCreateSpotFileRequestSubmit({ value, valid }: { value: SpotUploadRequest, valid: boolean }): void {

    if (this.spotFileCreateRequest.controls['title'].errors || this.spotFileCreateRequest.controls['adID'].errors)
      return;

    var exisitingItemIndex = this.spotFileCreateRequests.findIndex(t => t.adID.toLowerCase().trim() == value.adID.toLowerCase().trim()
      && t.title.toLowerCase().trim() == value.title.toLowerCase().trim());

    if (exisitingItemIndex >= 0)
      return;

    this.spotFileCreateRequests.push(value);

    this.sf.resetForm();
  }

  public removeCreateFileRequests(adID: string, title: string): void {
    var indToDelete = this.spotFileCreateRequests.findIndex(t => t.adID.toLowerCase().trim() == adID.toLowerCase().trim()
      && t.title.toLowerCase().trim() == title.toLowerCase().trim());

    if (indToDelete > -1)
      this.spotFileCreateRequests.splice(indToDelete, 1);
  }

  public onCreateSpotFiles(): void {
    if (this.spotFileCreateRequests.length == 0)
      return;

    this.sf.resetForm();

    this.uploadService.createSpotFile(this.spotFileCreateRequests).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", "Spot Files created successfully.", MessageSeverity.success);
        this.spotFileCreateRequests = [];
      }
      else {
        this.spotFileCreateRequests = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  //#endregion

  //#region Common

  private showErrors(errors: Array<string>): void {
    var initialState = {
      errors: errors
    };

    this.modalRef = this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  //#endregion


  //#region Cancel Order

  public onCancelOrderFormSubmit({ value, valid }: { value: OrderCancelRequest, valid: boolean }) {
    this.ocsubmitted = true;
    if (valid == false)
      return;

    this.cancelOrderGuid = this.cancelOrderRequest.controls['orderGuid'].value;
    this.CancelOrder(value);
  }

  public CancelOrder(request: OrderCancelRequest) {
    this.ocsubmitted = false;
    this.orderService.sendOrderForCancel(this.cancelOrderGuid, { comments: "Order Cancelled by support on request", forceCancel: true }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.orderUpdateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

  }

  //#endregion


  //#region Update Order LineItems Status To Check

  public onUpdateStatusCheckFormSubmit({ value, valid }: { value: UpdateStatucCheckRequest, valid: boolean }) {
    this.olisubmitted = true;
    if (valid == false)
      return;

    this.updateOrderlineItemsGuid = this.updateStatusCheckRequest.controls['orderGuid'].value;
    this.UpdateOrderlineItemsToStatusCheck(value);
  }

  public UpdateOrderlineItemsToStatusCheck(request: UpdateStatucCheckRequest) {
    this.olisubmitted = false;
    this.orderService.updateOrderlineItemsToStatusCheck(this.updateOrderlineItemsGuid, { comments: "Order Cancelled by support on request", forceCancel: true }).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.OrderLineItemsStatusToCheckSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  //#endregion

  public onEncryptTextFormSubmit({ value, valid }: { value: EncryptRequest, valid: boolean }) {
    if (valid === false)
      return;

    const pText = value.plainText;
    this.encryptText(pText);
  }

  public encryptText(pText: string) {
    this.configService.encryptText({ plainText: pText }).subscribe((res: any) => {
      if (res.isSuccess === true) {
        this.alertService.showMessage("SUCCESS", Constants.encryptionCompleted, MessageSeverity.success);
        const result = res.result;
        this.encryptTextRequest.controls["protectedText"].setValue(result);
        this.encryptTextRequest.controls["protectedText"].updateValueAndValidity();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  public onAuthOrgFormSubmit({ value, valid }: { value: CreateAuthOrgRequest, valid: boolean }) {
    if (valid == false)
      return;

    this.CreateAuthOrg(value);
  }

  public CreateAuthOrg(request: CreateAuthOrgRequest) {
    this.adminService.createAuthOrg(request).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.authOrgCreateSuccessful, MessageSeverity.success);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

  }
}

export interface OrderCancelRequest {
  orderGuid: string;
}

export interface UpdateStatucCheckRequest {
  orderGuid: string;
}

export interface UpdateOrderStatusRequest {
  orderGuid: string;
  status: number;
}

export interface CreateVendorPoRequest {
  orderGuid: string;
  network: number;
}

export interface UpdateOrderProductRequest {
  orderGuid: string;
  product: number;
}

export interface UpdateServiceLevelRequest {
  orderGuid: string;
  sequenceid: number;
  serviceLevel: number;
}

export interface EncryptRequest {
  plainText: string;
}

export interface CreateAuthOrgRequest {
  organizationName: string;
  organizationDomain: string;
  organizationId: string;
}
