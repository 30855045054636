import {  Subject ,  Observable, Subscription ,  timer as tm} from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utilities } from "../../../services/core/utilities";

@Component({
  selector: 'sessionexpirywarning-popup',
  templateUrl: './sessionexpirywarningpopup.component.html',
  styleUrls: ['./sessionexpirywarningpopup.component.css'],
})
export class SessionExpiryWarningPopupComponent implements OnInit, OnDestroy {

  @Input() timeOutStartTime: number = 120000;

  private subscription: Subscription;
  public onClose: Subject<boolean>;
  public countDownMins: string;
  public countDownSecs: string;

  constructor(
    public bsModalRef: BsModalRef,
    public util: Utilities) {

  }

  ngOnInit() {
    this.onClose = new Subject();
    let timer = tm(0, 1000);  // countdown start ms, countdown interval

    //return timer.switchMap((countdown) => {
    //  this.countDownMinsAndSecs(this.timeOutStartTime - countdown * 1000);
    //  if (countdown == Math.floor(this.timeOutStartTime / 1000)) {
    //    this.onNo();
    //  }
    //  return Observable.empty();
    //});

    this.subscription = timer.subscribe((countdown) => {
      this.countDownMinsAndSecs(this.timeOutStartTime - countdown * 1000);
      if (countdown == Math.floor(this.timeOutStartTime / 1000)) {
        this.onNo();
      }
    });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onYes() {
    this.closePopup();
    this.onClose.next(true);
  }

  public onNo() {
    this.closePopup();
    this.onClose.next(false);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private countDownMinsAndSecs(remianingTimeinMs: number) {
    if (remianingTimeinMs > 0) {
      var remainingmins = (remianingTimeinMs / 1000) % 60;
      var remainingsecs = Math.floor((remianingTimeinMs / 1000) / 60);

      this.countDownSecs = remainingmins > 9 ? '' + remainingmins : '' + '0' + remainingmins;
      this.countDownMins = remainingsecs > 9 ? '' + remainingsecs : '' + '0' + remainingsecs
    }
  }
}
