<ng-container id="client-orderedBy">
  <div class="modal-header">
    <h4 class="modal-title">Ordered By </h4>
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="orderedByForm" (submit)="onOrderedByFormFormSubmit(orderedByForm)" novalidate #ngForm="ngForm">
        <div class="tylie-form-group form-group" [ngClass]="{error: (orderedByForm.controls['orderedByList'].errors && !orderedByForm.controls['orderedByList'].pristine)
                                            || (orderedByForm.controls['orderedByList'].errors && ngForm.submitted)}">
          <label class="tylie-form-group__lbl" for="podDistributionEmails">
            Ordered By<span class="required">*</span>
          </label>
          <textarea id="orderedByList" name="orderedByList" class="tylie-textarea tylie-textarea--md form-control" formControlName="orderedByList" placeholder="semi-colon seperated"></textarea>
          <p class="p-extra-small">Please enter names seperated by semi-colon</p>
        </div>
        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
        </div>
      </form>
  </div>
</ng-container>
