<div
    class="media-share"
    id="transcode-pop-up"
>
  <div class="modal-header">
    <button type="button"
            class="modal-close"
            (click)="closePopup()">
      <icon-delete color="#000"></icon-delete>
    </button>
    <h4 class="modal-title">Share Tagger Ad</h4>
  </div>
    <div class="modal-body">
        <div class="row transcode-galarey">
            <div class="files-sec">
                <div
                    class="cool-md-12 trans-file-list"
                    *ngFor="let taggerAd of taggerAds; let j = index"
                >
                    <div class="transcode-bold">
                        {{ taggerAd.transcodeSpec }}
                    </div>
                    <div class="trans-file-name">
                        {{ taggerAd.fileName + taggerAd.fileExtension }}
                    </div>
                    <div
                        class="trans-file-close"
                        (click)="onDelete(taggerAd.adGuid)"
                    >
                        <span class="glyphicon glyphicon-remove-sign"></span>
                    </div>
                </div>
            </div>
        </div>

        <form novalidate
              [formGroup]="shareMediaForm"
              #f="ngForm">
          <div class="row">
            <div class="col-12">
              <div class="tylie-form-group form-group"
                   [ngClass]="{
                            pextrasmall1:
                                (shareMediaForm.controls['toemail'].hasError(
                                    'inValidEmailList'
                                ) &&
                                    !shareMediaForm.controls['toemail']
                                        .pristine &&
                                    isGenerateLinkAction == false) ||
                                (shareMediaForm.controls['toemail'].hasError(
                                    'inValidEmailList'
                                ) &&
                                    f.submitted &&
                                    isGenerateLinkAction == false)
                        }">
                <label class="tylie-form-group__lbl"
                       for="to-email">
                  Email To<i class="pextrasmall1"></i><span class="required">*</span>
                </label>
                <dx-tag-box [ngClass]="{
                                errors:
                                    (shareMediaForm.controls[
                                        'toemail'
                                    ].hasError('inValidEmailList') &&
                                        !shareMediaForm.controls['toemail']
                                            .pristine &&
                                        isGenerateLinkAction == false) ||
                                    (shareMediaForm.controls[
                                        'toemail'
                                    ].hasError('inValidEmailList') &&
                                        f.submitted &&
                                        isGenerateLinkAction == false)
                            }"
                            class="tylie-select form-control"
                            formControlName="toemail"
                            (onKeyPress)="onKeyEvent($event)"
                            (onFocusOut)="onFocusOut($event)"
                            [deferRendering]="false"
                            [searchEnabled]="true"
                            searchMode="contains"
                            [noDataText]=""
                            [minSearchLength]="3"
                            [dataSource]="emailSuggestions"
                            [showDataBeforeSearch]="false"
                            [acceptCustomValue]="true">
                </dx-tag-box>
                <p [ngClass]="[
                                (shareMediaForm.controls['toemail'].hasError(
                                    'inValidEmailList'
                                ) &&
                                    !shareMediaForm.controls['toemail']
                                        .pristine &&
                                    isGenerateLinkAction == false) ||
                                (shareMediaForm.controls['toemail'].hasError(
                                    'inValidEmailList'
                                ) &&
                                    f.submitted &&
                                    isGenerateLinkAction == false)
                                    ? 'pextrasmall2'
                                    : 'hide'
                            ]">
                  One or more emails is not valid
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="tylie-form-group form-group"
                   [ngClass]="{
                            error:
                                (shareMediaForm.controls['subject'].errors &&
                                    !shareMediaForm.controls['subject']
                                        .pristine &&
                                    isGenerateLinkAction == false) ||
                                (shareMediaForm.controls['subject'].errors &&
                                    f.submitted &&
                                    isGenerateLinkAction == false)
                        }">
                <label class="tylie-form-group__lbl"
                       for="subject">Email Subject<span class="required">*</span></label>
                <input formControlName="subject"
                       type="text"
                       id="subject"
                       name="subject"
                       class="tylie-text form-control" />
                <p class="p-extra-small">Please enter subject</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="tylie-form-group form-check mt-4">
                <input type="checkbox"
                       class="form-check-input"
                       formControlName="optcopy"
                       name="optcopy" />
                <label class="form-check-label"
                       for="optcopy">
                  Send Me Copy
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="tylie-form-group form-group"
                   [ngClass]="{
                            error:
                                (shareMediaForm.controls['linkexpire'].errors &&
                                    !shareMediaForm.controls['linkexpire']
                                        .pristine) ||
                                (shareMediaForm.controls['linkexpire'].errors &&
                                    f.submitted) ||
                                (isGenerateLinkAction == true &&
                                    shareMediaForm.controls['linkexpire']
                                        .errors)
                        }">
                <label class="tylie-form-group__lbl"
                       for="link-expire">
                  Link Expiration<span class="required">*</span>
                </label>
                <select formControlName="linkexpire"
                        id="linkexpire"
                        name="linkexpire"
                        class="tylie-select form-control">
                  <option value="">--Select Link Expiry--</option>
                  <option value="1">1 Day</option>
                  <option value="2">2 Days</option>
                  <option value="3">3 Days</option>
                  <option value="4">4 Days</option>
                  <option value="30">30 Days</option>
                  <option value="90">90 Days</option>
                </select>
                <p class="p-extra-small">
                  Please select Link Expiration
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 line-height">
              <div class="tylie-form-group form-check">
                <input type="checkbox"
                       class="form-check-input"
                       formControlName="optviews"
                       name="optviews" />
                <label class="form-check-label"
                       for="optviews">
                  Limit Views
                </label>
              </div>
            </div>
            <div class="col-md-6"
                 *ngIf="shareMediaForm.value.optviews == true">
              <div class="tylie-form-group form-group"
                   [ngClass]="{
                            error:
                                (shareMediaForm.value.optviews == true &&
                                    shareMediaForm.value.limitviews == null &&
                                    !shareMediaForm.controls['limitviews']
                                        .pristine) ||
                                (shareMediaForm.value.optviews == true &&
                                    shareMediaForm.value.limitviews == null &&
                                    f.submitted) ||
                                (showLimitViewsError == true &&
                                    shareMediaForm.value.optviews == true &&
                                    shareMediaForm.value.limitviews == null)
                        }">
                <label class="tylie-form-group__lbl"
                       for="viewspermitted">
                  Views Permitted<span class="required">*</span>
                </label>
                <input formControlName="limitviews"
                       type="number"
                       min="1"
                       max="100"
                       id="limitviews"
                       name="limitviews"
                       class="tylie-select  form-control" />
                <p class="p-extra-small">
                  Please select views permitted
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 line-height">
              <div class="tylie-form-group form-check">
                <input type="checkbox"
                       class="form-check-input"
                       formControlName="optpassword"
                       name="optpassword" />
                <label class="form-check-label"
                       for="optpassword">
                  Require Password
                </label>
              </div>
            </div>
            <div class="col-md-6"
                 *ngIf="shareMediaForm.value.optpassword == true">
              <div class="tylie-form-group form-group"
                   [ngClass]="{
                            error:
                                (shareMediaForm.value.optpassword == true &&
                                    shareMediaForm.value.password == '' &&
                                    !shareMediaForm.controls['password']
                                        .pristine) ||
                                (shareMediaForm.value.optpassword == true &&
                                    shareMediaForm.value.password == '' &&
                                    f.submitted) ||
                                (showPasswordError == true &&
                                    shareMediaForm.value.optpassword == true &&
                                    shareMediaForm.value.password == '')
                        }">
                <label class="tylie-form-group__lbl"
                       for="password">Password<span class="required">*</span></label>
                <input type="text"
                       id="password"
                       name="password"
                       class="tylie-text form-control"
                       formControlName="password" />
                <p class="p-extra-small">Please enter passsword</p>

                <button class="btn btn-default tyl-btn gnd-pwd"
                        type="button"
                        (click)="autoGeneratePassword()">
                  Generate Password
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl"
                       for="Comments">Comments</label>
                <textarea formControlName="comments"
                          id="Comments"
                          name="Comments"
                          class="tylie-text form-control"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl"
                       for="subject">Shareable Link</label>
                <label a
                       (click)="generateShareLink(shareMediaForm)"
                       class="btn-copy-url-label"
                       for=" to-email">Generate Shareable Link</label>
                <div class="btn-copy-url">
                  <input class="tylie-text form-control"
                         formControlName="sharelink"
                         name="sharelink"
                         id="sharelink"
                         type="text" />
                  <button class="btn btn-small tyl-btn btn-copy"
                          type="button"
                          (click)="copyLink()">
                    Copy
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button class="tylie-button tylie-button--xs me-3"
                    type="submit"
                    (click)="shareMedia(shareMediaForm)">
              Share
            </button>
            <button type="button"
                    class="tylie-button tylie-button--xs tylie-button--link"
                    (click)="bsModalRef.hide()">
              Cancel
            </button>
          </div>
        </form>
    </div>
</div>
