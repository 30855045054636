<div class="col-md-12 captioning" *ngFor="let sdata of servicesOptional">
  <div class="form-group">
    <label>Do you want to Add {{sdata.service.name}} service?<span class="required">*</span></label>
    <div class="service-radio-sec">
      <input type="radio" name="addOptService" value="true" (click)="onAddOptionalService(sdata.service.id, true)"><span>Yes</span>
      <input type="radio" name="addOptService" value="false" (click)="onAddOptionalService(sdata.service.id, false)"><span>No</span>
    </div>
    <p class="p-extra-small">Please choose an option</p>
  </div>
  <hr class="seperator" />
</div>
<form [formGroup]="serviceMetadataRequest" #f="ngForm" (ngSubmit)="onSave(serviceMetadataRequest)">
  <div class="col-md-12 captioning" *ngFor="let sdata of services">
    <div [ngSwitch]="sdata.service.code.toLowerCase()">
      <service-descriptivevideo *ngSwitchCase="'dvs'"
                                [f]="f"
                                [orderGuid]="orderGuid"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [specialServiceMode]="specialServiceMode"
                                [showInEditMode]="showInEditMode"
                                [applyAllMode]="applyAllMode"
                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                [additionalMetadata]="additionalMetadata">
      </service-descriptivevideo>

      <service-descriptivevideo-reapplication *ngSwitchCase="'reappdvs'"
                                              [f]="f"
                                              [orderGuid]="orderGuid"
                                              [spotLevel]="spotLevel"
                                              [groupLevel]="groupLevel"
                                              [orderLevel]="orderLevel"
                                              [specialServiceMode]="specialServiceMode"
                                              [showInEditMode]="showInEditMode"
                                              [applyAllMode]="applyAllMode"
                                              (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                              [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                              [additionalMetadata]="additionalMetadata">
      </service-descriptivevideo-reapplication>

      <service-captioning *ngSwitchCase="'cap'"
                          [f]="f"
                          [orderGuid]="orderGuid"
                          [spotLevel]="spotLevel"
                          [groupLevel]="groupLevel"
                          [orderLevel]="orderLevel"
                          [specialServiceMode]="specialServiceMode"
                          [applyAllMode]="applyAllMode"
                          [showInEditMode]="showInEditMode"
                          (serviceDeleted)="onServiceDelete(sdata.service.id)"
                          [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                          [additionalMetadata]="additionalMetadata">
      </service-captioning>

      <service-internationalcaptioning *ngSwitchCase="'cci'"
                                       [f]="f"
                                       [orderGuid]="orderGuid"
                                       [spotLevel]="spotLevel"
                                       [groupLevel]="groupLevel"
                                       [orderLevel]="orderLevel"
                                       [specialServiceMode]="specialServiceMode"
                                       [applyAllMode]="applyAllMode"
                                       [showInEditMode]="showInEditMode"
                                       (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                       [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                       [additionalMetadata]="additionalMetadata">
      </service-internationalcaptioning>

      <service-captioning-reapplication *ngSwitchCase="'reappcap'"
                                        [f]="f"
                                        [orderGuid]="orderGuid"
                                        [spotLevel]="spotLevel"
                                        [groupLevel]="groupLevel"
                                        [orderLevel]="orderLevel"
                                        [specialServiceMode]="specialServiceMode"
                                        [applyAllMode]="applyAllMode"
                                        [showInEditMode]="showInEditMode"
                                        (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                        [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                        [additionalMetadata]="additionalMetadata">
      </service-captioning-reapplication>

      <service-webcaptioning *ngSwitchCase="'wcp'"
                             [f]="f"
                             [orderGuid]="orderGuid"
                             [spotLevel]="spotLevel"
                             [groupLevel]="groupLevel"
                             [orderLevel]="orderLevel"
                             [specialServiceMode]="specialServiceMode"
                             [applyAllMode]="applyAllMode"
                             [showInEditMode]="showInEditMode"
                             (serviceDeleted)="onServiceDelete(sdata.service.id)"
                             [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                             [additionalMetadata]="additionalMetadata">
      </service-webcaptioning>

      <service-watermarkingbvs *ngSwitchCase="'wbv'"
                               [f]="f"
                               [orderGuid]="orderGuid"
                               [spotLevel]="spotLevel"
                               [groupLevel]="groupLevel"
                               [orderLevel]="orderLevel"
                               [specialServiceMode]="specialServiceMode"
                               [applyAllMode]="applyAllMode"
                               [showInEditMode]="showInEditMode"
                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                               [additionalMetadata]="additionalMetadata">
      </service-watermarkingbvs>

      <service-subtitlingembedded *ngSwitchCase="'sub'"
                                  [f]="f"
                                  [orderGuid]="orderGuid"
                                  [spotLevel]="spotLevel"
                                  [groupLevel]="groupLevel"
                                  [orderLevel]="orderLevel"
                                  [specialServiceMode]="specialServiceMode"
                                  [applyAllMode]="applyAllMode"
                                  [showInEditMode]="showInEditMode"
                                  (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                  [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                  [additionalMetadata]="additionalMetadata">
      </service-subtitlingembedded>

      <service-digitalediting *ngSwitchCase="'ded'"
                              [f]="f"
                              [orderGuid]="orderGuid"
                              [spotLevel]="spotLevel"
                              [groupLevel]="groupLevel"
                              [orderLevel]="orderLevel"
                              [specialServiceMode]="specialServiceMode"
                              [applyAllMode]="applyAllMode"
                              [showInEditMode]="showInEditMode"
                              (serviceDeleted)="onServiceDelete(sdata.service.id)"
                              [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                              [additionalMetadata]="additionalMetadata">
      </service-digitalediting>

      <service-stdconversion *ngSwitchCase="'stc'"
                             [f]="f"
                             [orderGuid]="orderGuid"
                             [spotLevel]="spotLevel"
                             [groupLevel]="groupLevel"
                             [orderLevel]="orderLevel"
                             [specialServiceMode]="specialServiceMode"
                             [applyAllMode]="applyAllMode"
                             [showInEditMode]="showInEditMode"
                             (serviceDeleted)="onServiceDelete(sdata.service.id)"
                             [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                             [additionalMetadata]="additionalMetadata">
      </service-stdconversion>

      <service-slatereslate *ngSwitchCase="'slt'"
                            [f]="f"
                            [applyAllMode]="applyAllMode"
                            [specialServiceMode]="specialServiceMode"
                            [showInEditMode]="showInEditMode"
                            [orderGuid]="orderGuid"
                            [spotLevel]="spotLevel"
                            [groupLevel]="groupLevel"
                            [orderLevel]="orderLevel"
                            (serviceDeleted)="onServiceDelete(sdata.service.id)"
                            [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                            [additionalMetadata]="additionalMetadata">
      </service-slatereslate>

      <service-webmarkingst *ngSwitchCase="'wst'"
                            [f]="f"
                            [orderGuid]="orderGuid"
                            [spotLevel]="spotLevel"
                            [groupLevel]="groupLevel"
                            [orderLevel]="orderLevel"
                            [specialServiceMode]="specialServiceMode"
                            [applyAllMode]="applyAllMode"
                            [showInEditMode]="showInEditMode"
                            (serviceDeleted)="onServiceDelete(sdata.service.id)"
                            [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                            [additionalMetadata]="additionalMetadata">
      </service-webmarkingst>

      <service-voiceover *ngSwitchCase="'vco'"
                         [f]="f"
                         [orderGuid]="orderGuid"
                         [spotLevel]="spotLevel"
                         [groupLevel]="groupLevel"
                         [orderLevel]="orderLevel"
                         [specialServiceMode]="specialServiceMode"
                         [applyAllMode]="applyAllMode"
                         [showInEditMode]="showInEditMode"
                         (serviceDeleted)="onServiceDelete(sdata.service.id)"
                         [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                         [additionalMetadata]="additionalMetadata">
      </service-voiceover>

      <service-tagging *ngSwitchCase="'tag'"
                       [f]="f"
                       [orderGuid]="orderGuid"
                       [spotLevel]="spotLevel"
                       [groupLevel]="groupLevel"
                       [orderLevel]="orderLevel"
                       [specialServiceMode]="specialServiceMode"
                       [applyAllMode]="applyAllMode"
                       [showInEditMode]="showInEditMode"
                       (serviceDeleted)="onServiceDelete(sdata.service.id)"
                       [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                       [additionalMetadata]="additionalMetadata">
      </service-tagging>

      <service-thumbnailimages *ngSwitchCase="'tbi'"
                               [f]="f"
                               [orderGuid]="orderGuid"
                               [spotLevel]="spotLevel"
                               [groupLevel]="groupLevel"
                               [orderLevel]="orderLevel"
                               [specialServiceMode]="specialServiceMode"
                               [applyAllMode]="applyAllMode"
                               [showInEditMode]="showInEditMode"
                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                               [additionalMetadata]="additionalMetadata">
      </service-thumbnailimages>

      <service-internationaltranscribescript *ngSwitchCase="'itrs'"
                                             [f]="f"
                                             [orderGuid]="orderGuid"
                                             [spotLevel]="spotLevel"
                                             [groupLevel]="groupLevel"
                                             [orderLevel]="orderLevel"
                                             [specialServiceMode]="specialServiceMode"
                                             [applyAllMode]="applyAllMode"
                                             [showInEditMode]="showInEditMode"
                                             (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                             [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                             [additionalMetadata]="additionalMetadata">
      </service-internationaltranscribescript>




      <service-transcribescript *ngSwitchCase="'trs'"
                                [f]="f"
                                [orderGuid]="orderGuid"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [specialServiceMode]="specialServiceMode"
                                [applyAllMode]="applyAllMode"
                                [showInEditMode]="showInEditMode"
                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                [additionalMetadata]="additionalMetadata">
      </service-transcribescript>

      <service-harddrive *ngSwitchCase="'hrd'"
                         [f]="f"
                         [orderGuid]="orderGuid"
                         [spotLevel]="spotLevel"
                         [groupLevel]="groupLevel"
                         [orderLevel]="orderLevel"
                         [specialServiceMode]="specialServiceMode"
                         [applyAllMode]="applyAllMode"
                         [showInEditMode]="showInEditMode"
                         (serviceDeleted)="onServiceDelete(sdata.service.id)"
                         [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                         [additionalMetadata]="additionalMetadata">
      </service-harddrive>

      <!-- Done -->
      <service-overtime *ngSwitchCase="'ovt'"
                        [f]="f"
                        [orderGuid]="orderGuid"
                        [spotLevel]="spotLevel"
                        [groupLevel]="groupLevel"
                        [orderLevel]="orderLevel"
                        [specialServiceMode]="specialServiceMode"
                        [showInEditMode]="showInEditMode"
                        [applyAllMode]="applyAllMode"
                        (serviceDeleted)="onServiceDelete(sdata.service.id)"
                        [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                        [additionalMetadata]="additionalMetadata">
      </service-overtime>

      <service-internationalclearancefee *ngSwitchCase="'icf'"
                                         [f]="f"
                                         [orderGuid]="orderGuid"
                                         [spotLevel]="spotLevel"
                                         [groupLevel]="groupLevel"
                                         [orderLevel]="orderLevel"
                                         [specialServiceMode]="specialServiceMode"
                                         [showInEditMode]="showInEditMode"
                                         [applyAllMode]="applyAllMode"
                                         (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                         [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                         [additionalMetadata]="additionalMetadata">
      </service-internationalclearancefee>

      <service-internationalordermgmtsupport *ngSwitchCase="'ioms'"
                                             [f]="f"
                                             [orderGuid]="orderGuid"
                                             [spotLevel]="spotLevel"
                                             [groupLevel]="groupLevel"
                                             [orderLevel]="orderLevel"
                                             [specialServiceMode]="specialServiceMode"
                                             [showInEditMode]="showInEditMode"
                                             [applyAllMode]="applyAllMode"
                                             (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                             [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                             [additionalMetadata]="additionalMetadata">
      </service-internationalordermgmtsupport>

      <service-thumbflashdrive *ngSwitchCase="'tfd'"
                               [f]="f"
                               [orderGuid]="orderGuid"
                               [spotLevel]="spotLevel"
                               [groupLevel]="groupLevel"
                               [orderLevel]="orderLevel"
                               [specialServiceMode]="specialServiceMode"
                               [applyAllMode]="applyAllMode"
                               [showInEditMode]="showInEditMode"
                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                               [additionalMetadata]="additionalMetadata">
      </service-thumbflashdrive>

      <service-broadcastclearancecan *ngSwitchCase="'bcc'"
                                     [f]="f"
                                     [orderGuid]="orderGuid"
                                     [spotLevel]="spotLevel"
                                     [groupLevel]="groupLevel"
                                     [orderLevel]="orderLevel"
                                     [specialServiceMode]="specialServiceMode"
                                     [applyAllMode]="applyAllMode"
                                     [showInEditMode]="showInEditMode"
                                     (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                     [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                     [additionalMetadata]="additionalMetadata">
      </service-broadcastclearancecan>

      <service-videotranscoding *ngSwitchCase="'vit'"
                                [f]="f"
                                [orderGuid]="orderGuid"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [specialServiceMode]="specialServiceMode"
                                [applyAllMode]="applyAllMode"
                                [showInEditMode]="showInEditMode"
                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                [additionalMetadata]="additionalMetadata">
      </service-videotranscoding>

      <service-instructioncreation *ngSwitchCase="'ins'"
                                   [f]="f"
                                   [orderGuid]="orderGuid"
                                   [spotLevel]="spotLevel"
                                   [groupLevel]="groupLevel"
                                   [orderLevel]="orderLevel"
                                   [specialServiceMode]="specialServiceMode"
                                   [applyAllMode]="applyAllMode"
                                   [showInEditMode]="showInEditMode"
                                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                   [additionalMetadata]="additionalMetadata">
      </service-instructioncreation>

      <service-broadcastclearanceadministration *ngSwitchCase="'bcf'"
                                                [f]="f"
                                                [orderGuid]="orderGuid"
                                                [spotLevel]="spotLevel"
                                                [groupLevel]="groupLevel"
                                                [orderLevel]="orderLevel"
                                                [specialServiceMode]="specialServiceMode"
                                                [applyAllMode]="applyAllMode"
                                                [showInEditMode]="showInEditMode"
                                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                [additionalMetadata]="additionalMetadata">
      </service-broadcastclearanceadministration>

      <service-vaultaccess *ngSwitchCase="'vla'"
                           [f]="f"
                           [orderGuid]="orderGuid"
                           [spotLevel]="spotLevel"
                           [groupLevel]="groupLevel"
                           [orderLevel]="orderLevel"
                           [specialServiceMode]="specialServiceMode"
                           [applyAllMode]="applyAllMode"
                           [showInEditMode]="showInEditMode"
                           (serviceDeleted)="onServiceDelete(sdata.service.id)"
                           [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                           [additionalMetadata]="additionalMetadata">
      </service-vaultaccess>

      <service-administrativereporting *ngSwitchCase="'arp'"
                                       [f]="f"
                                       [orderGuid]="orderGuid"
                                       [spotLevel]="spotLevel"
                                       [groupLevel]="groupLevel"
                                       [orderLevel]="orderLevel"
                                       [specialServiceMode]="specialServiceMode"
                                       [applyAllMode]="applyAllMode"
                                       [showInEditMode]="showInEditMode"
                                       (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                       [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                       [additionalMetadata]="additionalMetadata">
      </service-administrativereporting>

      <service-accountmanageradjustment *ngSwitchCase="'aea'"
                                        [f]="f"
                                        [orderGuid]="orderGuid"
                                        [spotLevel]="spotLevel"
                                        [groupLevel]="groupLevel"
                                        [orderLevel]="orderLevel"
                                        [specialServiceMode]="specialServiceMode"
                                        [applyAllMode]="applyAllMode"
                                        [showInEditMode]="showInEditMode"
                                        (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                        [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                        [additionalMetadata]="additionalMetadata">
      </service-accountmanageradjustment>

      <app-service-baguaranteepayments-metadata *ngSwitchCase="'bagp'"
                                                [f]="f"
                                                [orderGuid]="orderGuid"
                                                [spotLevel]="spotLevel"
                                                [groupLevel]="groupLevel"
                                                [orderLevel]="orderLevel"
                                                [specialServiceMode]="specialServiceMode"
                                                [applyAllMode]="applyAllMode"
                                                [showInEditMode]="showInEditMode"
                                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                [additionalMetadata]="additionalMetadata">
      </app-service-baguaranteepayments-metadata>

      <app-service-batraffic-metadata *ngSwitchCase="'trfs'"
                                                [f]="f"
                                                [orderGuid]="orderGuid"
                                                [spotLevel]="spotLevel"
                                                [groupLevel]="groupLevel"
                                                [orderLevel]="orderLevel"
                                                [specialServiceMode]="specialServiceMode"
                                                [applyAllMode]="applyAllMode"
                                                [showInEditMode]="showInEditMode"
                                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                [additionalMetadata]="additionalMetadata">
      </app-service-batraffic-metadata>

      <app-service-bahourlyfee-metadata *ngSwitchCase="'bahf'"
                                        [f]="f"
                                        [orderGuid]="orderGuid"
                                        [spotLevel]="spotLevel"
                                        [groupLevel]="groupLevel"
                                        [orderLevel]="orderLevel"
                                        [specialServiceMode]="specialServiceMode"
                                        [applyAllMode]="applyAllMode"
                                        [showInEditMode]="showInEditMode"
                                        (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                        [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                        [additionalMetadata]="additionalMetadata">
      </app-service-bahourlyfee-metadata>

      <app-service-baklhsignatoryfees-metadata *ngSwitchCase="'klhsf'"
                                               [f]="f"
                                               [orderGuid]="orderGuid"
                                               [spotLevel]="spotLevel"
                                               [groupLevel]="groupLevel"
                                               [orderLevel]="orderLevel"
                                               [specialServiceMode]="specialServiceMode"
                                               [applyAllMode]="applyAllMode"
                                               [showInEditMode]="showInEditMode"
                                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                               [additionalMetadata]="additionalMetadata">
      </app-service-baklhsignatoryfees-metadata>

      <app-service-balegalotherfees-metadata *ngSwitchCase="'balf'"
                                             [f]="f"
                                             [orderGuid]="orderGuid"
                                             [spotLevel]="spotLevel"
                                             [groupLevel]="groupLevel"
                                             [orderLevel]="orderLevel"
                                             [specialServiceMode]="specialServiceMode"
                                             [applyAllMode]="applyAllMode"
                                             [showInEditMode]="showInEditMode"
                                             (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                             [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                             [additionalMetadata]="additionalMetadata">
      </app-service-balegalotherfees-metadata>

      <app-service-baother-metadata *ngSwitchCase="'baoth'"
                                    [f]="f"
                                    [orderGuid]="orderGuid"
                                    [spotLevel]="spotLevel"
                                    [groupLevel]="groupLevel"
                                    [orderLevel]="orderLevel"
                                    [specialServiceMode]="specialServiceMode"
                                    [applyAllMode]="applyAllMode"
                                    [showInEditMode]="showInEditMode"
                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                    [additionalMetadata]="additionalMetadata">
      </app-service-baother-metadata>

      <app-service-bapasignatoryfees-metadata *ngSwitchCase="'pasf'"
                                              [f]="f"
                                              [orderGuid]="orderGuid"
                                              [spotLevel]="spotLevel"
                                              [groupLevel]="groupLevel"
                                              [orderLevel]="orderLevel"
                                              [specialServiceMode]="specialServiceMode"
                                              [applyAllMode]="applyAllMode"
                                              [showInEditMode]="showInEditMode"
                                              (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                              [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                              [additionalMetadata]="additionalMetadata">
      </app-service-bapasignatoryfees-metadata>

      <app-service-batalentmanagement-metadata *ngSwitchCase="'batm'"
                                               [f]="f"
                                               [orderGuid]="orderGuid"
                                               [spotLevel]="spotLevel"
                                               [groupLevel]="groupLevel"
                                               [orderLevel]="orderLevel"
                                               [specialServiceMode]="specialServiceMode"
                                               [applyAllMode]="applyAllMode"
                                               [showInEditMode]="showInEditMode"
                                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                               [additionalMetadata]="additionalMetadata">
      </app-service-batalentmanagement-metadata>

      <app-service-consultingtalentba-metadata *ngSwitchCase="'bactf'"
                                               [f]="f"
                                               [orderGuid]="orderGuid"
                                               [spotLevel]="spotLevel"
                                               [groupLevel]="groupLevel"
                                               [orderLevel]="orderLevel"
                                               [specialServiceMode]="specialServiceMode"
                                               [applyAllMode]="applyAllMode"
                                               [showInEditMode]="showInEditMode"
                                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                               [additionalMetadata]="additionalMetadata">
      </app-service-consultingtalentba-metadata>

      <app-service-consultingtrafficdistribution-metadata *ngSwitchCase="'trfdctf'"
                                                          [f]="f"
                                                          [orderGuid]="orderGuid"
                                                          [spotLevel]="spotLevel"
                                                          [groupLevel]="groupLevel"
                                                          [orderLevel]="orderLevel"
                                                          [specialServiceMode]="specialServiceMode"
                                                          [applyAllMode]="applyAllMode"
                                                          [showInEditMode]="showInEditMode"
                                                          (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                          [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                          [additionalMetadata]="additionalMetadata">
      </app-service-consultingtrafficdistribution-metadata>

      <app-service-crewpayrollprocessing-metadata *ngSwitchCase="'cpp'"
                                                  [f]="f"
                                                  [orderGuid]="orderGuid"
                                                  [spotLevel]="spotLevel"
                                                  [groupLevel]="groupLevel"
                                                  [orderLevel]="orderLevel"
                                                  [specialServiceMode]="specialServiceMode"
                                                  [applyAllMode]="applyAllMode"
                                                  [showInEditMode]="showInEditMode"
                                                  (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                  [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                  [additionalMetadata]="additionalMetadata">
      </app-service-crewpayrollprocessing-metadata>

      <app-service-signatoryfeeother-metadata *ngSwitchCase="'sfoth'"
                                              [f]="f"
                                              [orderGuid]="orderGuid"
                                              [spotLevel]="spotLevel"
                                              [groupLevel]="groupLevel"
                                              [orderLevel]="orderLevel"
                                              [specialServiceMode]="specialServiceMode"
                                              [applyAllMode]="applyAllMode"
                                              [showInEditMode]="showInEditMode"
                                              (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                              [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                              [additionalMetadata]="additionalMetadata">
      </app-service-signatoryfeeother-metadata>

      <app-service-talentpayrollprocessing-metadata *ngSwitchCase="'tpp'"
                                                    [f]="f"
                                                    [orderGuid]="orderGuid"
                                                    [spotLevel]="spotLevel"
                                                    [groupLevel]="groupLevel"
                                                    [orderLevel]="orderLevel"
                                                    [specialServiceMode]="specialServiceMode"
                                                    [applyAllMode]="applyAllMode"
                                                    [showInEditMode]="showInEditMode"
                                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                    [additionalMetadata]="additionalMetadata">
      </app-service-talentpayrollprocessing-metadata>

      <app-service-payrollmanagementfees-metadata *ngSwitchCase="'pmf'"
                                                  [f]="f"
                                                  [orderGuid]="orderGuid"
                                                  [spotLevel]="spotLevel"
                                                  [groupLevel]="groupLevel"
                                                  [orderLevel]="orderLevel"
                                                  [specialServiceMode]="specialServiceMode"
                                                  [applyAllMode]="applyAllMode"
                                                  [showInEditMode]="showInEditMode"
                                                  (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                  [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                  [additionalMetadata]="additionalMetadata">
      </app-service-payrollmanagementfees-metadata>

      <app-service-distributionservices-metadata *ngSwitchCase="'dist'"
                                                 [f]="f"
                                                 [orderGuid]="orderGuid"
                                                 [spotLevel]="spotLevel"
                                                 [groupLevel]="groupLevel"
                                                 [orderLevel]="orderLevel"
                                                 [specialServiceMode]="specialServiceMode"
                                                 [applyAllMode]="applyAllMode"
                                                 [showInEditMode]="showInEditMode"
                                                 (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                 [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                 [additionalMetadata]="additionalMetadata">
      </app-service-distributionservices-metadata>

      <app-service-distributionservicefees-metadata *ngSwitchCase="'distsf'"
                                                    [f]="f"
                                                    [orderGuid]="orderGuid"
                                                    [spotLevel]="spotLevel"
                                                    [groupLevel]="groupLevel"
                                                    [orderLevel]="orderLevel"
                                                    [specialServiceMode]="specialServiceMode"
                                                    [applyAllMode]="applyAllMode"
                                                    [showInEditMode]="showInEditMode"
                                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                    [additionalMetadata]="additionalMetadata">
      </app-service-distributionservicefees-metadata>

      <app-service-distributionserviceother-metadata *ngSwitchCase="'distsoth'"
                                                     [f]="f"
                                                     [orderGuid]="orderGuid"
                                                     [spotLevel]="spotLevel"
                                                     [groupLevel]="groupLevel"
                                                     [orderLevel]="orderLevel"
                                                     [specialServiceMode]="specialServiceMode"
                                                     [applyAllMode]="applyAllMode"
                                                     [showInEditMode]="showInEditMode"
                                                     (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                     [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                     [additionalMetadata]="additionalMetadata">
      </app-service-distributionserviceother-metadata>

      <app-service-musicpublishing-metadata *ngSwitchCase="'mup'"
                                            [f]="f"
                                            [orderGuid]="orderGuid"
                                            [spotLevel]="spotLevel"
                                            [groupLevel]="groupLevel"
                                            [orderLevel]="orderLevel"
                                            [specialServiceMode]="specialServiceMode"
                                            [applyAllMode]="applyAllMode"
                                            [showInEditMode]="showInEditMode"
                                            (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                            [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                            [additionalMetadata]="additionalMetadata">
      </app-service-musicpublishing-metadata>

      <app-service-otherincome-metadata *ngSwitchCase="'othinc'"
                                        [f]="f"
                                        [orderGuid]="orderGuid"
                                        [spotLevel]="spotLevel"
                                        [groupLevel]="groupLevel"
                                        [orderLevel]="orderLevel"
                                        [specialServiceMode]="specialServiceMode"
                                        [applyAllMode]="applyAllMode"
                                        [showInEditMode]="showInEditMode"
                                        (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                        [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                        [additionalMetadata]="additionalMetadata">
      </app-service-otherincome-metadata>

      <app-service-customsystemdevelopment-metadata *ngSwitchCase="'csdev'"
                                                    [f]="f"
                                                    [orderGuid]="orderGuid"
                                                    [spotLevel]="spotLevel"
                                                    [groupLevel]="groupLevel"
                                                    [orderLevel]="orderLevel"
                                                    [specialServiceMode]="specialServiceMode"
                                                    [applyAllMode]="applyAllMode"
                                                    [showInEditMode]="showInEditMode"
                                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                    [additionalMetadata]="additionalMetadata">
      </app-service-customsystemdevelopment-metadata>

      <app-service-globalacademy7m-metadata *ngSwitchCase="'gac'"
                                            [f]="f"
                                            [orderGuid]="orderGuid"
                                            [spotLevel]="spotLevel"
                                            [groupLevel]="groupLevel"
                                            [orderLevel]="orderLevel"
                                            [specialServiceMode]="specialServiceMode"
                                            [applyAllMode]="applyAllMode"
                                            [showInEditMode]="showInEditMode"
                                            (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                            [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                            [additionalMetadata]="additionalMetadata">
      </app-service-globalacademy7m-metadata>

      <service-digitaltransfer *ngSwitchCase="'dtr'"
                               [f]="f"
                               [orderGuid]="orderGuid"
                               [spotLevel]="spotLevel"
                               [groupLevel]="groupLevel"
                               [orderLevel]="orderLevel"
                               [specialServiceMode]="specialServiceMode"
                               [applyAllMode]="applyAllMode"
                               [showInEditMode]="showInEditMode"
                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                               [additionalMetadata]="additionalMetadata">
      </service-digitaltransfer>

      <service-spotexpirationnotif *ngSwitchCase="'sen'"
                                   [f]="f"
                                   [orderGuid]="orderGuid"
                                   [spotLevel]="spotLevel"
                                   [groupLevel]="groupLevel"
                                   [orderLevel]="orderLevel"
                                   [specialServiceMode]="specialServiceMode"
                                   [applyAllMode]="applyAllMode"
                                   [showInEditMode]="showInEditMode"
                                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                   [additionalMetadata]="additionalMetadata">
      </service-spotexpirationnotif>

      <service-trafficexpress *ngSwitchCase="'trf'"
                              [f]="f"
                              [orderGuid]="orderGuid"
                              [spotLevel]="spotLevel"
                              [groupLevel]="groupLevel"
                              [orderLevel]="orderLevel"
                              [specialServiceMode]="specialServiceMode"
                              [applyAllMode]="applyAllMode"
                              [showInEditMode]="showInEditMode"
                              (serviceDeleted)="onServiceDelete(sdata.service.id)"
                              [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                              [additionalMetadata]="additionalMetadata">
      </service-trafficexpress>

      <service-harddriveformatting *ngSwitchCase="'hdf'"
                                   [f]="f"
                                   [orderGuid]="orderGuid"
                                   [spotLevel]="spotLevel"
                                   [groupLevel]="groupLevel"
                                   [orderLevel]="orderLevel"
                                   [specialServiceMode]="specialServiceMode"
                                   [applyAllMode]="applyAllMode"
                                   [showInEditMode]="showInEditMode"
                                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                   [additionalMetadata]="additionalMetadata">
      </service-harddriveformatting>

      <service-targaimages *ngSwitchCase="'tar'"
                           [f]="f"
                           [orderGuid]="orderGuid"
                           [spotLevel]="spotLevel"
                           [groupLevel]="groupLevel"
                           [orderLevel]="orderLevel"
                           [specialServiceMode]="specialServiceMode"
                           [applyAllMode]="applyAllMode"
                           [showInEditMode]="showInEditMode"
                           (serviceDeleted)="onServiceDelete(sdata.service.id)"
                           [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                           [additionalMetadata]="additionalMetadata">
      </service-targaimages>

      <service-billboard *ngSwitchCase="'bms'"
                         [f]="f"
                         [orderGuid]="orderGuid"
                         [spotLevel]="spotLevel"
                         [groupLevel]="groupLevel"
                         [orderLevel]="orderLevel"
                         [specialServiceMode]="specialServiceMode"
                         [applyAllMode]="applyAllMode"
                         [showInEditMode]="showInEditMode"
                         (serviceDeleted)="onServiceDelete(sdata.service.id)"
                         [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                         [additionalMetadata]="additionalMetadata">
      </service-billboard>

      <service-audiotranscoding *ngSwitchCase="'ait'"
                                [f]="f"
                                [orderGuid]="orderGuid"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [specialServiceMode]="specialServiceMode"
                                [applyAllMode]="applyAllMode"
                                [showInEditMode]="showInEditMode"
                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                [additionalMetadata]="additionalMetadata">
      </service-audiotranscoding>

      <service-broadcastclearanceus *ngSwitchCase="'bcd'"
                                    [f]="f"
                                    [orderGuid]="orderGuid"
                                    [spotLevel]="spotLevel"
                                    [groupLevel]="groupLevel"
                                    [orderLevel]="orderLevel"
                                    [specialServiceMode]="specialServiceMode"
                                    [applyAllMode]="applyAllMode"
                                    [showInEditMode]="showInEditMode"
                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                    [additionalMetadata]="additionalMetadata">
      </service-broadcastclearanceus>

      <service-broadcastclearanceintl *ngSwitchCase="'bci'"
                                      [f]="f"
                                      [orderGuid]="orderGuid"
                                      [spotLevel]="spotLevel"
                                      [groupLevel]="groupLevel"
                                      [orderLevel]="orderLevel"
                                      [specialServiceMode]="specialServiceMode"
                                      [applyAllMode]="applyAllMode"
                                      [showInEditMode]="showInEditMode"
                                      (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                      [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                      [additionalMetadata]="additionalMetadata">
      </service-broadcastclearanceintl>

      <service-dvd *ngSwitchCase="'btd'"
                   [f]="f"
                   [orderGuid]="orderGuid"
                   [spotLevel]="spotLevel"
                   [groupLevel]="groupLevel"
                   [orderLevel]="orderLevel"
                   [specialServiceMode]="specialServiceMode"
                   [applyAllMode]="applyAllMode"
                   [showInEditMode]="showInEditMode"
                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                   [additionalMetadata]="additionalMetadata">
      </service-dvd>

      <service-internationaltrafficinstructions *ngSwitchCase="'iti'"
                                                [f]="f"
                                                [orderGuid]="orderGuid"
                                                [spotLevel]="spotLevel"
                                                [groupLevel]="groupLevel"
                                                [orderLevel]="orderLevel"
                                                [specialServiceMode]="specialServiceMode"
                                                [applyAllMode]="applyAllMode"
                                                [showInEditMode]="showInEditMode"
                                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                                [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                                [additionalMetadata]="additionalMetadata">
      </service-internationaltrafficinstructions>

      <service-ddi *ngSwitchCase="'ddi'"
                   [f]="f"
                   [orderGuid]="orderGuid"
                   [spotLevel]="spotLevel"
                   [groupLevel]="groupLevel"
                   [orderLevel]="orderLevel"
                   [specialServiceMode]="specialServiceMode"
                   [applyAllMode]="applyAllMode"
                   [showInEditMode]="showInEditMode"
                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                   [additionalMetadata]="additionalMetadata">
      </service-ddi>

      <service-ddai *ngSwitchCase="'ddai'"
                    [f]="f"
                    [orderGuid]="orderGuid"
                    [spotLevel]="spotLevel"
                    [groupLevel]="groupLevel"
                    [orderLevel]="orderLevel"
                    [specialServiceMode]="specialServiceMode"
                    [applyAllMode]="applyAllMode"
                    [showInEditMode]="showInEditMode"
                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                    [additionalMetadata]="additionalMetadata">
      </service-ddai>

      <service-exphandling *ngSwitchCase="'exphandle'"
                           [f]="f"
                           [orderGuid]="orderGuid"
                           [spotLevel]="spotLevel"
                           [groupLevel]="groupLevel"
                           [orderLevel]="orderLevel"
                           [specialServiceMode]="specialServiceMode"
                           [applyAllMode]="applyAllMode"
                           [showInEditMode]="showInEditMode"
                           (serviceDeleted)="onServiceDelete(sdata.service.id)"
                           [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                           [additionalMetadata]="additionalMetadata">
      </service-exphandling>

      <service-noadditionaldata *ngSwitchDefault
                                [f]="f"
                                [orderGuid]="orderGuid"
                                [spotLevel]="spotLevel"
                                [groupLevel]="groupLevel"
                                [orderLevel]="orderLevel"
                                [specialServiceMode]="specialServiceMode"
                                [applyAllMode]="applyAllMode"
                                [showInEditMode]="showInEditMode"
                                (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                [formGroup]="serviceMetadataRequest.controls[sdata.service.code.toLowerCase()]"
                                [additionalMetadata]="additionalMetadata">
      </service-noadditionaldata>

      <service-trafficmanagementfee *ngSwitchCase="'tmgt'"
                                    [f]="f"
                                    [orderGuid]="orderGuid"
                                    [spotLevel]="spotLevel"
                                    [groupLevel]="groupLevel"
                                    [orderLevel]="orderLevel"
                                    [specialServiceMode]="specialServiceMode"
                                    [applyAllMode]="applyAllMode"
                                    [showInEditMode]="showInEditMode"
                                    (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                    [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                    [additionalMetadata]="additionalMetadata">

      </service-trafficmanagementfee>

      <service-clearanceadvicesupport *ngSwitchCase="'bca'"
                                      [f]="f"
                                      [orderGuid]="orderGuid"
                                      [spotLevel]="spotLevel"
                                      [groupLevel]="groupLevel"
                                      [orderLevel]="orderLevel"
                                      [specialServiceMode]="specialServiceMode"
                                      [applyAllMode]="applyAllMode"
                                      [showInEditMode]="showInEditMode"
                                      (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                      [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                      [additionalMetadata]="additionalMetadata">


      </service-clearanceadvicesupport>

      <service-cinemaclearance *ngSwitchCase="'ccl'"
                               [f]="f"
                               [orderGuid]="orderGuid"
                               [spotLevel]="spotLevel"
                               [groupLevel]="groupLevel"
                               [orderLevel]="orderLevel"
                               [specialServiceMode]="specialServiceMode"
                               [applyAllMode]="applyAllMode"
                               [showInEditMode]="showInEditMode"
                               (serviceDeleted)="onServiceDelete(sdata.service.id)"
                               [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                               [additionalMetadata]="additionalMetadata">

      </service-cinemaclearance>

      <service-standard3rdpartyfee *ngSwitchCase="'bc3p'"
                                   [f]="f"
                                   [orderGuid]="orderGuid"
                                   [spotLevel]="spotLevel"
                                   [groupLevel]="groupLevel"
                                   [orderLevel]="orderLevel"
                                   [specialServiceMode]="specialServiceMode"
                                   [applyAllMode]="applyAllMode"
                                   [showInEditMode]="showInEditMode"
                                   (serviceDeleted)="onServiceDelete(sdata.service.id)"
                                   [formGroup]="serviceMetadataRequest.controls[sdata.service.serviceConfig.group]"
                                   [additionalMetadata]="additionalMetadata">


      </service-standard3rdpartyfee>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-3" *ngIf="showInEditMode === true">
      <button class="tylie-button tylie-button--xs" type="submit">{{editMode === true ? 'UPDATE SERVICES' : 'SUBMIT OPTIONS'}}</button>
  </div>
</form>
