export class PresignedUrlS3Upload {
  uploadUrl: string;
  downloadUrl: string;
  fileGuid: string;
}

export interface UploadOrderFileRequest {
  fileName: string;
  fileType: string;
  fileCategory: string;
  description: string;
  orderGuid: string;
  orderDestinationId: number;
}

export interface UploadTaggerFileRequest {
  fileName: string;
  fileType: string;
  //fileCategory: string;
  description: string;
  taggerGuid: string;
  taggerDestinationId: number;
}
