<div *ngIf="detail != null">
  <destination-view *ngIf="editMode == false"
                    [destinationDetail]="detail"
                    (onEditDestinationEvent)="onEditDestinationEventTriggered($event)">

  </destination-view>
  <edit-destination *ngIf="editMode == true"
                    [destinationGuid]="this.destinationGuid"
                    (onEditCompleteOrCancelEvent)="onEditCompleteOrCancelEventTriggered($event)">

  </edit-destination>
</div>
