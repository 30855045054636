<div class="tylie-select custom-dropdown">
    <div
        class="custom-dropdown-header"
        (click)="toggleDropdown()"
    >
        <span class="custom-dropdown-text">{{
            (selectedLabel && selectedLabel) || "Select"
        }}</span>
        <span class="custom-dropdown-icon"></span>
    </div>
    <div
        class="custom-dropdown-body"
        *ngIf="expanded"
    >
        <div
            class="custom-dropdown-content"
            *ngIf="options?.length > 0"
        >
            <ng-container *ngIf="isLocation; else groupTemplate">
                <div
                    class="col-md-12"
                    *ngFor="let option of options"
                >
                    <div class="col-md-12">
                        <div class="tylie-form-group form-group custom-dropdown-item">
                            <label class="tylie-form-group__lbl" for="">Locations in "{{
                                option[groupNameDisplayKey]
                            }}" Group</label>
                            <span
                                class="select-all"
                                (click)="selectAllInGroup(option)"
                            >
                                SELECT ALL IN GROUP
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 custom-dropdown-child-item">
                        <div
                            class="col-md-4"
                            *ngFor="
                                let location of option.locations;
                                let i = index
                            "
                        >
                            <div
                                class="tylie-form-group form-check custom-dropdown-item"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check"
                                    id="{{ 'location' + i }}"
                                    [checked]="
                                        selectedOptions.includes(location)
                                    "
                                    (change)="selectOption(location)"
                                />
                                <label
                                    for="{{ 'location' + i }}"
                                    class="form-check-label"
                                >
                                    {{ location[displayKey] }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #groupTemplate>
                <div
                    class="col-md-4"
                    *ngFor="let option of options; let i = index"
                >
                    <div
                        class="tylie-form-group form-check custom-dropdown-item"
                    >
                        <input
                            type="checkbox"
                            class="form-check-input form-check"
                            id="{{ 'option' + i }}"
                            [checked]="selectedOptions.includes(option)"
                            (change)="selectOption(option)"
                        />
                        <label
                            for="{{ 'option' + i }}"
                            class="form-check-label"
                            >{{ option[displayKey] }}</label
                        >
                    </div>
                </div>
            </ng-template>
        </div>

        <div *ngIf="options?.length > 0" class="col-md-12 custom-dropdown-footer">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link select-all" (click)="selectAll()">
                Select All
            </button>
            <button type="button"
                class="tylie-button tylie-button--xs"
                (click)="onSubmit()"
                [disabled]="selectedOptions.length === 0"
                [class.disabled]="selectedOptions.length === 0"
            >
                {{selectTitle}}
            </button>
        </div>

        <div class="custom-dropdown-content" *ngIf="!options || options?.length === 0">
            <span>No Data Available</span>
        </div>
    </div>
</div>
