import { Component, OnInit, Inject, OnDestroy, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Utilities } from "../../../../services/core/utilities";
import { Constants } from "../../../../configurations/constants";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { AccountSettingsService } from "../../../../services/user/account-settings.service";
import { UserEmailDistributionGroups } from "../../../../models/user/user-email-distribution-group.model";
import { EmailListValidator, noWhitespaceValidator } from "../../../../configurations/custom-validations";

@Component({
  selector: 'user-distribution-group',
  templateUrl: './userdistributiongroup.component.html',
  styleUrls: ['./userdistributiongroup.component.css']
})

export class UserDistributionGroupComponent implements OnInit, OnDestroy {
  creategroupname: FormGroup;
  distroEmailAdd: FormGroup;

  public modalRef: BsModalRef;
  public userEmailDistroList: UserEmailDistributionGroups[] = null;
  public userEmailList: UserEmailDistributionGroups;
  public groupEmailsListDiv: any;
  public noEmails = "";
  public activeGroup = -1;

  @ViewChildren('deleteUserDistropop') deleteUserDistropop: QueryList<PopoverDirective>;
  
  constructor(private alertService: AlertService,
    public util: Utilities,
    private modalService: BsModalService,
    private accountSettingsService: AccountSettingsService,
    private router: Router,
    private _fb: FormBuilder) {

    this.creategroupname = new FormGroup({
      groupname: new FormControl(null, [Validators.required, Validators.minLength(2), noWhitespaceValidator])
    });

    this.distroEmailAdd = this._fb.group({
      distrogroupname: [null, [Validators.required, Validators.minLength(2)]],
      distrogroupguid: [''],
      emails: this._fb.array([

      ])
    });
  }

  ngOnInit() {
    this.getUserDistros();
  }

  ngOnDestroy() {
  }

  public initEmails() {
    const emailFb = this._fb.group({
      email: ['', [EmailListValidator]]
    });
    return emailFb;
  }

  public addEmails() {
    // add address to the list
    const control = <FormArray>this.distroEmailAdd.controls['emails'];
    control.push(this.initEmails());
  }

  public initCurrentEmails(email: string) {
    const emailFb = this._fb.group({
      email: ['', [EmailListValidator]]
    });
    if (emailFb.contains('email')) {
      emailFb.controls['email'].setValue(email);
    }
    return emailFb;
  }

  get userDistros() {
    return this.userEmailDistroList;
  }

  get userDistroEmail() {
    return this.userEmailList;
  }

  get getEmailsData() {
    return <FormArray>this.distroEmailAdd.get('emails');
  }

  public getUserDistros() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.userEmailDistributionGroupsEndpoint().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.userEmailDistroList = res.result;
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  
  public onHideDeleteUserDistroPop() {
    this.deleteUserDistropop.forEach(p => {
      p.hide();
    });
  }

  public removeUserDistro(guid) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.removeDistributionGroupEndpoint(guid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.onHideDeleteUserDistroPop();
        let detailsEmailDiv = document.getElementById("detailsDistroList");
        if (detailsEmailDiv != null || detailsEmailDiv != undefined) {
          detailsEmailDiv.classList.add('hide');
        }
        this.activeGroup = -1;
        this.alertService.showMessage("SUCCESS", Constants.userDistroDeleted, MessageSeverity.success);
        this.userEmailDistroList = this.userEmailDistroList.filter(ued => ued.groupGuid != guid);
        this.alertService.ShowLoader(false);
      }
      else {
        this.onHideDeleteUserDistroPop();
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.onHideDeleteUserDistroPop();
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public openCreateNewDistroTemp(newDistroTemp: TemplateRef<any>) {
    this.modalRef = this.modalService.show(newDistroTemp, this.util.getModalComponentOptions({}, false, true, false));
  }

  public addNewEmailToDistro(guid: string, groupName: string, u: NgForm) {
    if (this.distroEmailAdd.invalid) { return; }
    let emailList: string[] = [];

    for (let i of this.distroEmailAdd.value.emails) {
      if (i.email != null && i.email != undefined && i.email != "") {
        if (!emailList.includes(i.email.toLowerCase())) {
          emailList.push(i.email.toLowerCase());
        }
      }
    }

    let emailString = emailList.join(';');
    let saveDistroObj = new UserEmailDistributionGroups(guid, groupName, emailList);
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.updateUserDistributionEmailsFromGroupEndpoint(guid, saveDistroObj).subscribe((res: any) => {
      this.userEmailDistroList = res.result.userEmailDistributionGroups;
      if (res.isSuccess == true) {
        this.cancelEdits();
        this.alertService.showMessage("SUCCESS", Constants.userDistroEmailUpdated, MessageSeverity.success);
        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onClickExpandActiveGroup(emailDistroGuid: string, i?: number) {
    if (i != null && i != undefined) {
      this.activeGroup = i;
    }

    this.userEmailList = new UserEmailDistributionGroups("", "", []);
    if (emailDistroGuid != undefined || emailDistroGuid != null) {
      Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
      this.accountSettingsService.userGroupEmailListEndpoint(emailDistroGuid).subscribe((res: any) => {
        if (res.isSuccess == true) {
          this.userEmailList = res.result;

          this.distroEmailAdd.controls['distrogroupname'].setValue(this.userEmailList.groupName);
          this.distroEmailAdd.controls['distrogroupguid'].setValue(this.userEmailList.groupGuid);

          const control = <FormArray>this.distroEmailAdd.controls['emails'];
          for (let index in control) {
            control.removeAt(+index);
          }

          for (let item of this.userEmailList.groupEmails) {
            control.push(this.initCurrentEmails(item));
          }

          this.alertService.ShowLoader(false);
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          this.alertService.ShowLoader(false);
        }
      },
        error => {
          this.util.handleError(error);
          this.alertService.ShowLoader(false);
        });
    }
    else {
      return;
    }
  }

  public createNewDistro() {
    if (this.creategroupname.invalid) {
      return;
    }

    let userEmailDistributionGroup = new UserEmailDistributionGroups(
      '00000000-0000-0000-0000-000000000000',
      this.creategroupname.value.groupname,
      []
    );

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.accountSettingsService.createUserDistributionGroupEndpoint(userEmailDistributionGroup).subscribe((res: any) => {
        if (res.isSuccess == true) {
          userEmailDistributionGroup.groupGuid = res.result;
          this.userEmailDistroList.push(userEmailDistributionGroup);
          this.modalRef.hide();
          this.creategroupname.reset();
          this.alertService.showMessage("SUCCESS", Constants.userDistroCreated, MessageSeverity.success);
          this.alertService.ShowLoader(false);
        }
        else {
          this.modalRef.hide();
          this.creategroupname.reset();
          this.util.handleIsNotSuccess(res.errors);
          this.alertService.ShowLoader(false);
        }
      },
      error => {
        this.modalRef.hide();
        this.creategroupname.reset();
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public modalClose() {
    this.modalRef.hide();
  }

  public cancelEdits() {
    this.activeGroup = -1;
  }
}
