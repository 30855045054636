import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { Utilities } from "../../../../../services/core/utilities";
import { ConfigService } from "../../../../../services/core/config.service";
import { AdminService } from "../../../../../services/admin/admin.service";
import { AlertService, MessageSeverity } from "../../../../../services/core/alert.service";
import { RouteConstants } from "../../../../../configurations/route-constants";
import { EditClient, EditClientRequest } from "../../../../../models/admin/client/editclient.model";
import { SelectedBrandsComponent } from "../../selectedbrands/selected-brands.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { customEmailValidator, customPhoneValidator, digitsOnlyValidator, EmailListValidatorIfValueExists } from "../../../../../configurations/custom-validations";
import { AddCostCenterAndBillToPopUp } from "../../../../popups/addcostcenterandbilltopopup/addcostcenterandbilltopopup.component";
import { Constants } from '../../../../../configurations/constants';
import { CostCenterAndBillToObjects, CostCenter, BillTo, SelectedBundleServices, BundleService } from '../../../../../models/admin/client/createclient.model';
import { DxTreeViewComponent } from 'devextreme-angular';
import { AddCustomTagConfigurationPopUp } from '../../../../popups/addcustomtagconfiguration/addcustomtagconfiguration.component';
import { CreateCustomTagConfig } from '../../../../../models/admin/client/customtagconfig.model';
import { AddEditBundleServicesPopUp } from '../../../../popups/addeditbundleservices/addeditbundleservices.component';
import { clientEditScopeEnum } from '../../../../../configurations/enums/enums';
import { AddPODRecipientsPopUp } from '../../../../popups/addpodrecipients/addpodrecipients.component';
import { AddOrderedByPopUp } from '../../../../popups/addorderedby/addorderedby.component';
import { AddFileDeliveryConfigurationPopUp } from '../../../../popups/addfiledeliveryconfiguration/addfiledeliveryconfiguration.component';
import { ClientFileDeliveryConfig, CreateClientDeliveryConfig } from '../../../../../models/admin/client/deliveryconfig.model';
import { AddTranscodeBillingSettingsPopUp } from '../../../../popups/addtranscodebillingsettings/addtranscodebillingsettings.component';
import { AssetShareBillingSettings, ManagementFeeSettings, StorageManagementFeeSettings, TranscodeBillingSettings } from '../../../../../models/admin/client/billingsettings.model';
import { AddManagementFeeSettingsettingsPopUp } from '../../../../popups/addmanagementfeesettings/addmanagementfeesettings.component';
import { AddStorageManagementFeeSettingsettingsPopUp } from '../../../../popups/addstoragemanagementfeesettings/addstoragemanagementfeesettings.component';
import { AddAssetShareBillingSettingsPopupComponent } from '../../../../popups/add-asset-share-billing-settings-popup/add-asset-share-billing-settings-popup.component';

@Component({
  selector: 'client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent implements OnInit {

  @Input('clientGuid') clientGuid: string;

  @Output() onEditCompleteOrCancel: EventEmitter<void> = new EventEmitter<void>();

  public clientsRoute = RouteConstants.clientsRoute;
  public client: EditClient = null;
  public phoneMask = Constants.phoneMask;
  public base64logo = null;
  public isFlatBillingSelectedOnClient: boolean = false;
  public partnerClientDataSource: any[] = [];

  public editClientFormGroup: FormGroup;
  @ViewChild(SelectedBrandsComponent, { static: false }) brandSelectedComp: SelectedBrandsComponent;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeBoxValue: number[] = [];

  public modalRef: BsModalRef;

  constructor(public util: Utilities
    , private router: Router
    , public adminService: AdminService
    , private configService: ConfigService
    , private alertService: AlertService
    , private fb: FormBuilder
    , private modalService: BsModalService) {

  }

  ngOnInit() {
    this.editClientFormGroup = this.fb.group({
      clientName: [null, Validators.compose([Validators.required])],
      accountExecutive: [null],
      name: [null, Validators.compose([Validators.required])],
      aliasId: [null],
      address1: [null, Validators.compose([Validators.required])],
      address2: [null],
      city: [null, Validators.compose([Validators.required])],
      country: [null, Validators.compose([Validators.required])],
      state: [null, Validators.compose([Validators.required])],
      zipCode: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([customPhoneValidator])],
      email: new FormControl('', Validators.compose([customEmailValidator])),
      teamEmail: [null, Validators.compose([customEmailValidator])],
      poRequired: [true],
      isOnTrail: [false],
      isViewRestricted: [false],
      orderReviewRequired: [false],
      isVaultBilling: [false],
      orderFinalReviewRequired: [false],
      billingFrequency: [null],
      bundleServices: [null],
      opsNotes: [null],
      archiveDurationInDays: [null, Validators.compose([digitsOnlyValidator])],
      activeTandemStorageInDays: [null, Validators.compose([digitsOnlyValidator])],
      infrqStorageDays: [null, Validators.compose([digitsOnlyValidator])],
      stdStorageDays: [null, Validators.compose([digitsOnlyValidator])],
      podDistributionEmails: [null, Validators.compose([EmailListValidatorIfValueExists])],
      orderedBy: [null],
      transcodeBillingSettings: [null],
      managementFeeSettings: [null],
      storageManagementFeeSettings: [null],
      partnerClient: [null]
    });

    this.getClientEditDetail(this.clientGuid);
  }

  public onCountryValueChanged(event: any) {
    this.client.config.states = [];
    this.editClientFormGroup.controls['state'].setValue(null);

    if (event.value == null || event.value == undefined || event.value == "") {
      return;
    }
    this.configService.getStatesByCountry(event.value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.client.config.states = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onUploadLogo(e) {
    const file = e.target.files[0];

    if (file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  public _handleReaderLoaded(e) {
    var binaryString = e.target.result;
    this.base64logo = Constants.avatarEncoding + btoa(binaryString);

    this.adminService.updateClientLogo(this.base64logo, this.clientGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.client.logo = this.base64logo;
        this.base64logo = null;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onRemoveLogo() {
    this.adminService.removeClientLogo(this.clientGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.client.logo = null;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public addBillToAndCostCenter() {
    var initialState = {
      costCenterList: this.client.costCenters,
      billToList: this.client.billTos,
      isClientEdit: true,
      title: 'CC & BT',
      editConfig: this.client.config
    };

    this.modalRef = this.modalService.show(AddCostCenterAndBillToPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.oncostCenterAndBillToAddEdit.subscribe((result: CostCenterAndBillToObjects) => {

      this.client.costCenters = [];
      this.client.billTos = [];

      if (result != null && result != undefined) {
        result.costCenters = this.util.notEmpty(result.costCenters) ? result.costCenters.filter((cc): cc is CostCenter => cc != null) : [];
        result.billTos = this.util.notEmpty(result.billTos) ? result.billTos.filter((bt): bt is BillTo => bt != null) : [];

        result.costCenters.forEach(cc => {
          if (this.util.notEmpty(cc.costCenter)) {
            if (this.client.costCenters.indexOf(cc.costCenter) === -1) {
              this.client.costCenters.push(cc.costCenter)
            }
          }
        });
        result.billTos.forEach(bt => {
          if (this.util.notEmpty(bt.billTo)) {
            if (this.client.billTos.indexOf(bt.billTo) === -1) {
              this.client.billTos.push(bt.billTo)
            }
          }
        });
      }

      this.client.costCenterDisplayString = this.client.costCenters.join('; ');
      this.client.billToDisplayString = this.client.billTos.join('; ');
    });
  }

  public onFormSubmit({ value, valid }: { value: EditClientRequest, valid: boolean }) {
    if (valid == false) {
      return;
    }

    var clientBrandSelectionList = this.brandSelectedComp.getSelectedBrands();

    value.brandSelectionList = clientBrandSelectionList;
    value.phone = this.util.notEmpty(value.phone) ? value.phone.replace(/\D+/g, '').trim() : null;
    value.costCenters = this.client.costCenters;
    value.billTos = this.client.billTos;
    value.deletedClientBrandsList = this.brandSelectedComp.getRemovedBrands();
    value.bundleServices = this.client.bundleServices;
    value.podDistributionEmails = this.client.podDistributionEmails;
    value.orderedBy = this.client.orderedBy;
    value.fileDeliveryConfigurations = this.client.fileDeliveryConfigurations;
    value.transcodeBillingSettings = this.client.transcodeBillingSettings;
    value.assetShareBillingSettings = this.client.assetShareBillingSettings;
    value.managementFeeSettings = this.client.managementFeeSettings;
    value.storageManagementFeeSettings = this.client.storageManagementFeeSettings;

    this.editClient(value);
  }

  public onCancelEdits() {
    this.adminService.completeClientEditLock(this.clientGuid).subscribe((res: any) => {
      var result = res.result;
      if (res.isSuccess == true) {
        this.onEditCompleteOrCancel.emit();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onAddEditApplyAllCustomTags() {
    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.customTagConfigurations == null || sb.customTagConfigurations == undefined || sb.customTagConfigurations.length == 0);
    }
    else {
      isBrandsNotSelected = true;
    }

    var initialState = {
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected
    };

    this.modalRef = this.modalService.show(AddCustomTagConfigurationPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveCustomTagConfiguration.subscribe((result: CreateCustomTagConfig) => {
      if (result != null && result != undefined) {
        if (result.customTagConfigurations != null && result.customTagConfigurations != undefined && result.customTagConfigurations.length > 0) {
          this.brandSelectedComp.applyCustomTagsToAllSelectedBrands(result.customTagConfigurations, result.selectedBrandsForCustomTags);
        }
      }
    });
  }

  public onAddEditApplyTranscodeBillingSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0 && this.isFlatBillingSelectedOnClient == false) {
      selectedBrands = selectedBrands.filter(sb => sb.transcodeBillingSettings == null || sb.transcodeBillingSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      transcodeBillingTypes: this.client.config.transcodeBillingTypes,
      billingFrequency: this.client.config.billingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      flatTranscodeTiers: this.client.config.flatTranscodeTiers,
      clientTranscodeBillingSettings: this.client.transcodeBillingSettings,
      flatTranscodeBillingFrequency: this.client.config.flatTranscodeBillingFrequency
    };

    this.modalRef = this.modalService.show(AddTranscodeBillingSettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveTranscodeBillingSettings.subscribe((result: TranscodeBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForTranscodeBilling == null || result.selectedBrandsForTranscodeBilling.length == 0) {
          this.client.transcodeBillingSettings = result;
          this.isFlatBillingSelectedOnClient = true;
          this.brandSelectedComp.removeTranscodeBillingSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyTranscodeBillingSettingsToAllSelectedBrands(result, result.selectedBrandsForTranscodeBilling);
          this.isFlatBillingSelectedOnClient = false;
          this.client.transcodeBillingSettings = null;
        }
      }
    });
  }

  public onRemoveBillingSettings() {
    this.client.transcodeBillingSettings = null;
  }

  public onAddEditApplyAssetShareBillingSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.assetShareBillingSettings == null || sb.assetShareBillingSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      transcodeBillingTypes: this.client.config.transcodeBillingTypes,
      billingFrequency: this.client.config.billingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      flatTranscodeTiers: this.client.config.flatTranscodeTiers,
      clientAssetShareBillingSettings: this.client.assetShareBillingSettings,
      flatTranscodeBillingFrequency: this.client.config.flatTranscodeBillingFrequency
    };

    this.modalRef = this.modalService.show(AddAssetShareBillingSettingsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveAssetShareBillingSettings.subscribe((result: AssetShareBillingSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForAssetShareBilling == null || result.selectedBrandsForAssetShareBilling.length == 0) {
          this.client.assetShareBillingSettings = result;
          this.isFlatBillingSelectedOnClient = true;
          this.brandSelectedComp.removeAssetShareBillingSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyAssetShareBillingSettingsToAllSelectedBrands(result, result.selectedBrandsForAssetShareBilling);
          this.isFlatBillingSelectedOnClient = false;
          this.client.assetShareBillingSettings = null;
        }
      }
    });
  }


  public onRemoveAssetShareBillingSettings() {
    this.client.assetShareBillingSettings = null;
  }

  public onAddEditApplyManagementFeeSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.managementFeeSettings == null || sb.managementFeeSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      billingFrequency: this.client.config.managementFeeBillingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      clientManagementBillingSettings: this.client.managementFeeSettings,
    };

    this.modalRef = this.modalService.show(AddManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveManagementFeeSettings.subscribe((result: ManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForManagementFee == null || result.selectedBrandsForManagementFee.length == 0) {
          this.client.managementFeeSettings = result;
          this.brandSelectedComp.removeManagementFeeSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyManagementFeeSettingsToAllSelectedBrands(result, result.selectedBrandsForManagementFee);
          this.client.managementFeeSettings = null;
        }
      }
    });
  }

  public onAddEditApplyStorageManagementFeeSettings() {

    var isBrandsNotSelected = false;

    var selectedBrands = this.brandSelectedComp.getSelectedBrands();

    if (selectedBrands != null && selectedBrands != undefined && selectedBrands.length > 0) {
      selectedBrands = selectedBrands.filter(sb => sb.storageManagementFeeSettings == null || sb.storageManagementFeeSettings == undefined);
    }
    else {
      isBrandsNotSelected = true;
    }

    const initialState = {
      billingFrequency: this.client.config.managementFeeBillingFrequency,
      selectedBrandsDataSource: selectedBrands,
      isBrandsNotSelected: isBrandsNotSelected,
      clientStorageManagementBillingSettings: this.client.storageManagementFeeSettings,
    };

    this.modalRef = this.modalService.show(AddStorageManagementFeeSettingsettingsPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveStorageManagementFeeSettings.subscribe((result: StorageManagementFeeSettings) => {
      if (result != null && result != undefined) {
        console.log(result);
        if (result.selectedBrandsForStorageManagementFee == null || result.selectedBrandsForStorageManagementFee.length == 0) {
          this.client.storageManagementFeeSettings = result;
          this.brandSelectedComp.removeStorageManagementFeeSettingsOnAllBrands();
        }
        else {
          // apply for respective brands
          this.brandSelectedComp.applyStorageManagementFeeSettingsToAllSelectedBrands(result, result.selectedBrandsForStorageManagementFee);
          this.client.storageManagementFeeSettings = null;
        }
      }
    });
  }

  public onRemoveManagementFeeSettings() {
    this.client.managementFeeSettings = null;
  }

  public onRemoveStorageManagementFeeSettings() {
    this.client.storageManagementFeeSettings = null;
  }

  public onAddBundleServices() {
    var initialState = {
      isEdit: false,
      bundleServices: this.client.bundleServices
    };

    this.modalRef = this.modalService.show(AddEditBundleServicesPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveBundleServices.subscribe((result: BundleService) => {
      if (result != null && result != undefined) {
        if (this.client.bundleServices.findIndex(bs => bs.bundleServiceCode == result.bundleServiceCode) == -1) {
          this.client.bundleServices.push(result);
        }

        this.client.bundleServices = this.client.bundleServices;
      }
    });
  }

  public onEditBundleServices(bundleServCode: string) {

    var serv = this.client.bundleServices.find(bs => bs.bundleServiceCode.toLowerCase() == bundleServCode.toLowerCase());

    if (serv != undefined) {

      var initialState = {
        isEdit: true,
        bundleServiceSelected: serv
      };

      this.modalRef = this.modalService.show(AddEditBundleServicesPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
      this.modalRef.content.onSaveBundleServices.subscribe((result: BundleService) => {
        if (result != null && result != undefined) {
          this.client.bundleServices.forEach(serv => {
            if (serv.bundleServiceCode.toLowerCase() == result.bundleServiceCode.toLowerCase()) {
              serv.serviceCodes = result.serviceCodes;
              serv.serviceNames = result.serviceNames;
            }
          });

          this.client.bundleServices = this.client.bundleServices;
        }
      });
    }
  }

  public onDeleteBundleServices(bundleServCode: string) {
    if (bundleServCode != null && bundleServCode != undefined && bundleServCode != "") {
      this.client.bundleServices = this.client.bundleServices.filter(bs => bs.bundleServiceCode.toLowerCase() != bundleServCode.toLowerCase());
    }
  }

  public onAddEditPODRecipients() {
    var initialState = {
      podRecipientsList: this.client.podDistributionEmails
    };

    this.modalRef = this.modalService.show(AddPODRecipientsPopUp, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSavePodRecipients.subscribe((result: string) => {
      this.client.podDistributionEmails = result;
    });
  }

  public onAddEditOrderedBy() {
    var initialState = {
      orderedByList: this.client.orderedBy,
    };

    this.modalRef = this.modalService.show(AddOrderedByPopUp, this.util.getModalComponentOptions(initialState, false, true, false));
    this.modalRef.content.onSaveOrderedBy.subscribe((result: string) => {
      this.client.orderedBy = result;
    });
  }

  public onAddEditFileDeliveryConfig() {
    const initialState = {
      fileDelConfigs: this.client.fileDeliveryConfigurations,
      isEdit: true,
      fileDelvTypes: this.client.config.fileDeliveryConfigTypes
    };

    this.modalRef = this.modalService.show(AddFileDeliveryConfigurationPopUp, this.util.getModalComponentOptions(initialState, false, false, true));
    this.modalRef.content.onSaveFileDeliveryConfiguration.subscribe((result: CreateClientDeliveryConfig) => {
      if (result !== null && result !== undefined) {
        this.client.fileDeliveryConfigurations = result.fileDeliveryConfigurations;
      }
    });
  }

  private editClient(value: EditClientRequest) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    console.log(value);

    this.adminService.editClient(value, this.clientGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var results = res.result;
        this.alertService.showMessage("SUCCESS", Constants.editClientSuccessful, MessageSeverity.success);

        this.onEditCompleteOrCancel.emit();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public syncTreeViewSelection(e) {
    var component = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.treeBoxValue) {
      component.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    const nodes = e.component.getNodes();
    this.treeBoxValue = this.getSelectedItemsKeys(nodes);
  }

  private getSelectedItemsKeys(items) {
    var result = [],
      that = this;

    items.forEach(function (item) {
      if (item.selected) {
        result.push(item.key);
      }
      if (item.items.length) {
        result = result.concat(that.getSelectedItemsKeys(item.items));
      }
    });
    return result;
  }

  private getClientEditDetail(clientGuid: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.adminService.getClientEditDetail(clientGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.client = res.result as EditClient;
        console.log(this.client);
        this.client.logo = this.util.notEmpty(this.client.logo) ? Constants.avatarEncoding + this.client.logo : null;
        this.updateEditClientForm(this.client);
        this.updateClientEditConfig(this.client);
        this.updateAliasIdValidation(this.client.config.canEditClientId);
        this.partnerClientDataSource = this.client.allPartnerClients;

        this.client.partnerClients.forEach(f => {
          this.treeBoxValue.push(f)
        });

      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  private updateEditClientForm(client: EditClient) {
    this.editClientFormGroup.controls['clientName'].setValue(client.clientName);
    this.editClientFormGroup.controls['accountExecutive'].setValue(client.accountExecutive);
    this.editClientFormGroup.controls['name'].setValue(client.name);
    this.editClientFormGroup.controls['aliasId'].setValue(client.aliasId);
    this.editClientFormGroup.controls['address1'].setValue(client.address1);
    this.editClientFormGroup.controls['address2'].setValue(client.address2);
    this.editClientFormGroup.controls['city'].setValue(client.city);
    this.editClientFormGroup.controls['state'].setValue(client.state);
    this.editClientFormGroup.controls['country'].setValue(client.country);
    this.editClientFormGroup.controls['zipCode'].setValue(client.zipCode);
    this.editClientFormGroup.controls['phone'].setValue(client.phone);
    this.editClientFormGroup.controls['email'].setValue(client.email);
    this.editClientFormGroup.controls['teamEmail'].setValue(client.teamEmail);
    this.editClientFormGroup.controls['poRequired'].setValue(client.poRequired);
    this.editClientFormGroup.controls['isViewRestricted'].setValue(client.isViewRestricted);
    this.editClientFormGroup.controls['isOnTrail'].setValue(client.isOnTrail);
    this.editClientFormGroup.controls['orderReviewRequired'].setValue(client.orderReviewRequired);
    this.editClientFormGroup.controls['isVaultBilling'].setValue(client.isVaultBilling);
    this.editClientFormGroup.controls['orderFinalReviewRequired'].setValue(client.orderFinalReviewRequired);
    this.editClientFormGroup.controls['billingFrequency'].setValue(client.billingFrequency);
    this.editClientFormGroup.controls['opsNotes'].setValue(client.opsNotes);
    this.editClientFormGroup.controls['stdStorageDays'].setValue(client.stdStorageDays);
    this.editClientFormGroup.controls['infrqStorageDays'].setValue(client.infrqStorageDays);
    this.editClientFormGroup.controls['archiveDurationInDays'].setValue(client.archiveDurationInDays);
    this.editClientFormGroup.controls['activeTandemStorageInDays'].setValue(client.activeTandemStorageInDays);
    this.editClientFormGroup.controls['podDistributionEmails'].setValue(client.podDistributionEmails);
    this.editClientFormGroup.controls['orderedBy'].setValue(client.orderedBy);
    this.editClientFormGroup.controls['transcodeBillingSettings'].setValue(client.transcodeBillingSettings);
    this.editClientFormGroup.controls['partnerClient'].setValue(client.partnerClients);
  }

  private updateClientEditConfig(client: EditClient) {
    client.config.canEditHeader = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Header].toLowerCase()) === true;

    client.config.canEditClientId = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.EditClientId].toLowerCase()) === true;

    client.config.canAddBrand = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.AddBrand].toLowerCase()) === true;

    client.config.canEditBrand = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.EditBrand].toLowerCase()) === true;

    client.config.canDeleteBrand = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.DeleteBrand].toLowerCase()) === true;

    client.config.canAddEditContact = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Contact].toLowerCase()) === true;


    client.config.canAddEditCCandBT = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.CostCenterandBillTo].toLowerCase()) === true;


    client.config.canEditOthers = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Others].toLowerCase()) === true;

    client.config.canAddEditCustomTags = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.CustomTags].toLowerCase()) === true;


    client.config.canEditBrandDetail = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.EditBrandDetail].toLowerCase()) === true;

    client.config.canEditCutDownServices = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.CutDownServices].toLowerCase()) === true;

    client.config.canEditPODDistributionEmails = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.PODDistributionEmails].toLowerCase()) === true;

    client.config.canEditOrderedBy = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.OrderedBy].toLowerCase()) === true;

    client.config.canEditFileDeliveryConfig = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.FileDeliveryConfigs].toLowerCase()) === true;

    client.config.canAddEditBillingSettings = client.config.editScope.some(t => t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.Full].toLowerCase()
      || t.toLowerCase() === clientEditScopeEnum[clientEditScopeEnum.BillingSettings].toLowerCase()) === true;

  }

  private updateAliasIdValidation(canEditClientId: boolean) {
    if (canEditClientId) {
      this.editClientFormGroup.controls["aliasId"].setValidators([Validators.required, digitsOnlyValidator]);
    }
    else {
      this.editClientFormGroup.controls["aliasId"].setValidators(null);
    }
    this.editClientFormGroup.updateValueAndValidity();
  }
}
