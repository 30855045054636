import { Component, OnInit } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RouteConstants } from "../../../configurations/route-constants";
import { downloadStatusEnum } from '../../../models/download/downloadmedia-status';

@Component({
  selector: 'spot-download',
  templateUrl: './spotdownload.component.html',
  styleUrls: ['./spotdownload.component.css'],
})

export class SpotDownloadComponent implements OnInit {

  public downloadStatusEnum = downloadStatusEnum;
  public downloadFileStatus = this.util.downloadFileStatus;
  public source: string = "";

  constructor(
    public util: Utilities,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      console.log(params);
      let s = params['t'] != null ? params['t'] : '';
      if (s == 'transcode' || s == 'olv' || s == 'saved')
        this.source = "transcode";
      else if (s == 'tagger')
        this.source = 'tagger';
      else
        this.source = "media";
    });
  }

  public gotoSource() {
    if (this.source == 'transcode')
      this.router.navigate([RouteConstants.transcodeFilesRoute]);
    else if (this.source == 'tagger')
      this.router.navigate([RouteConstants.taggerRoute]);
    else
      this.router.navigate([RouteConstants.mediaRoute]);
  }

  public get anyActiveDownloads() {
    return this.downloadFileStatus.some(e => e.show);
  }

  public clearCompleted(): void {
    for (let ft of this.downloadFileStatus) {
      for (let f of ft.dowloadStatus) {
        if (f.status == downloadStatusEnum.completed)
          f.show = false;
      }

      if (!ft.dowloadStatus.some(e => e.show))
        ft.show = false;
    }
  }
}
