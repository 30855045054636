import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';

@Component({
  selector: 'orderstatusconfirmpopup',
  templateUrl: './orderstatusconfirmpopup.component.html',
  styleUrls: ['./orderstatusconfirmpopup.component.css'],
})
export class OrderStatusConfirmpopup implements OnInit {

  @Input() title: string;
  @Input() comments: string = '';  

  public onConfirm: Subject<any>;
  public request: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.onConfirm = new Subject();

    this.request = new FormGroup({
      comments: new FormControl(this.comments, Validators.required)
    });
  }

  public onSubmit({ value, valid }: { value: any, valid: boolean }) {

    if (this.request.controls['comments'].errors)
      return;

    this.onConfirm.next({ comments: value.comments });

    this.closePopup();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }
}
