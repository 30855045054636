import { Component, OnInit } from '@angular/core';
import { BaseServiceDataCollectionComponent } from '../service-basedatacollection-metadata/servicebasedatacollection.component';

@Component({
  selector: 'app-service-batraffic-metadata',
  templateUrl: './service-batraffic-metadata.component.html',
  styleUrls: ['./service-batraffic-metadata.component.css']
})
export class ServiceBatrafficMetadataComponent extends BaseServiceDataCollectionComponent {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
