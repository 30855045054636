<form [formGroup]="updateOrderPODRequest" #deliveryPODForm="ngForm" novalidate>
  <p class="tylie-type-body mb-3">UPDATE POD</p>

  
  <div class="tylie-form-group form-group" [ngClass]="{error: updateOrderPODRequest.controls['deliveryPODDate'].errors && deliveryPODForm.submitted}">
    <label class="tylie-form-group__lbl" for="deliveryPODDate">Delivery POD Date (EST)<span class="required">*</span></label>
    
    <dx-date-box width="100%" type="datetime" id="deliveryPODDate" name="deliveryPODDate" class="tylie-select form-control"
                [showClearButton]="true"
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                displayFormat="MM/dd/yyyy hh:mm aa"
                invalidDateMessage="Please enter a valid POD date"
                formControlName="deliveryPODDate">
    </dx-date-box>
    <p class="p-extra-small" *ngIf="updateOrderPODRequest.controls['deliveryPODDate'].errors?.required">
      Please select a Valid Delivery POD Date
    </p>
  </div>
   

  <div class="d-flex justify-content-end">
    <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit" (click)="onFormSubmit(updateOrderPODRequest)"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
    <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
  </div>
</form>
