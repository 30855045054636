<div id="filedelivConfiggroup">
  <div [formGroup]="cfdGroup">
    <div class="row">
      <div class="col-md-6" id="dataType">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['configType'].errors && (!cfdGroup.controls['configType'].pristine || parentForm.submitted))}">
          <label for="dataType" class="tylie-form-group__lbl">Config Type<span class="required">*</span></label>
          <dx-select-box [dataSource]="fileDelvTypes"
                         name="configType" id="configType" class="tylie-select form-control"
                         formControlName="configType"
                         [searchEnabled]="true"
                         [disabled]="isEdit && cfdGroup.controls['id'].value == true"
                         placeholder="Config Type"
                         (onValueChanged)="onConfigTypeChange($event)">
          </dx-select-box>
          <p class="p-extra-small">Please select File Delivery Config Type</p>
        </div>
      </div>
    </div>

    
    <div class="row">
      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['accountName'].errors && (!cfdGroup.controls['accountName'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">Account Name<span class="required">*</span></label>
          <input type="text" id="accountName" name="accountName" class="tylie-text form-control" formControlName="accountName" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['accountName'].errors?.required || cfdGroup.controls['accountName'].errors?.whitespace">Please enter Account Name</p>
          <p class="p-extra-small" *ngIf="cfdGroup.controls['accountName'].errors?.notUnique">Account Name already exists</p>
        </div>
      </div>

      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['clientId'].errors && (!cfdGroup.controls['clientId'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">Client Id<span class="required">*</span></label>
          <input type="text" id="clientId" name="clientId" class="tylie-text form-control" formControlName="clientId" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['clientId'].errors?.required || cfdGroup.controls['clientId'].errors?.whitespace">Please enter Client Id</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['clientSecret'].errors && (!cfdGroup.controls['clientSecret'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">Client Secret<span class="required">*</span></label>
          <input type="text" id="clientSecret" name="clientSecret" class="tylie-text form-control" formControlName="clientSecret" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['clientSecret'].errors?.required || cfdGroup.controls['clientSecret'].errors?.whitespace">Please enter Client Secret</p>
        </div>
      </div>

      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['refreshToken'].errors && (!cfdGroup.controls['refreshToken'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">Refresh Token<span class="required">*</span></label>
          <input type="text" id="refreshToken" name="refreshToken" class="tylie-text form-control" formControlName="refreshToken" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['refreshToken'].errors?.required || cfdGroup.controls['refreshToken'].errors?.whitespace">Please enter Refresh Token</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="this.isConfigTypeGoogleAds == true">
      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['googleAdsLoginCustomerId'].errors && (!cfdGroup.controls['googleAdsLoginCustomerId'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">GoogleAds LoginCustomerId<span class="required">*</span></label>
          <input type="text" id="googleAdsLoginCustomerId" name="googleAdsLoginCustomerId" class="tylie-text form-control" formControlName="googleAdsLoginCustomerId" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['googleAdsLoginCustomerId'].errors?.required || cfdGroup.controls['googleAdsLoginCustomerId'].errors?.whitespace">Please enter GoogleAds LoginCustomerId</p>
        </div>
      </div>

      <div class="col-md-6" id="tag">
        <div class="tylie-form-group form-group"
             [ngClass]="{error: (cfdGroup.controls['googleAdsCustomerId'].errors && (!cfdGroup.controls['googleAdsCustomerId'].pristine || parentForm.submitted))}">
          <label for="tag" class="tylie-form-group__lbl">GoogleAds CustomerId<span class="required">*</span></label>
          <input type="text" id="googleAdsCustomerId" name="googleAdsCustomerId" class="tylie-text form-control" formControlName="googleAdsCustomerId" [readonly]="isEdit && cfdGroup.controls['id'].value > 0">
          <p class="p-extra-small" *ngIf="cfdGroup.controls['googleAdsCustomerId'].errors?.required || cfdGroup.controls['googleAdsCustomerId'].errors?.whitespace">Please enter GoogleAds CustomerId</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="tylie-button tylie-button--xs" (click)="onClickRemoveFileDeliveryConfig()">
        <span class="tylie-button__icon"><icon-trash-can></icon-trash-can></span>
        <span class="tylie-button__text">REMOVE FILE DELIVERY CONFIG</span>
      </button>
    </div>
  </div>
</div>

