<div class="tylie-sidebar__header sidebar-header">
  <img src="./assets/svg/TylieTandem-Logo-Icon.svg" class="tylie-sidebar__mlogo tyl-temp-logo" />
  <img src="./assets/svg/TylieTandem-Logo-White.svg" alt="tyl-logo" class="tylie-sidebar__logo tyl-logo" />
</div>
<div class="tylie-sidebar__body">
  <div class="tylie-sidebar__profile profile-pic">
    <img src="{{avatar}}" class="tylie-sidebar__profile-pic">
    <div class="tylie-sidebar__profile-content">
      <p class="tylie-sidebar__profile-email">{{email}}</p>
      <div class="tylie-sidebar__profile-actions"><button type="button" class="tylie-sidebar__profile-action" (click)="onSettingsClick()">Settings</button> <span>|</span> <button type="button" class="tylie-sidebar__profile-action" (click)="logout()">Logout</button></div>
    </div>
    <div class="tylie-sidebar__profile-mcontent">
      <div class="tylie-sidebar__profile-email">{{initials}}</div>
      <div class="tylie-sidebar__profile-actions">
        <button type="button" class="tylie-button tylie-button--icon tylie-button--tr" (click)="onSettingsClick()"><icon-settings color="#fff" height="16px" width="16px"></icon-settings></button>
        <button type="button" class="tylie-button tylie-button--icon tylie-button--tr" (click)="logout()"><icon-logout color="#fff" height="16px" width="16px"></icon-logout></button>
      </div>
    </div>
  </div>

  <ul class="tylie-sidebar__nav list-unstyled components">
    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Project']" [routerLink]="['/project']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-projects></icon-nav-projects>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Projects</span>
      </a>
    </li>
    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Asset']" [routerLink]="['/assets']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-assets></icon-nav-assets>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Assets</span>
        <span *ngIf="(this.util.spotQCCount+this.util.spotApprovalCount>0)" class="tylie-sidebar__nav-lnk-badge">{{ this.util.spotQCCount + this.util.spotApprovalCount}}</span>
      </a>
    </li>

  <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
    <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Tagger']" [routerLink]="['/tagger']">
      <i class="tylie-sidebar__nav-icon" aria-hidden="true">
        <icon-nav-tagger></icon-nav-tagger>
      </i>
      <span class="tylie-sidebar__nav-txt p-normal">Tagger</span>
    </a>
  </li>

  <li routerLinkActive="active">
    <a (click)="onNonAdminClick()" secured [accessControlList]="['Distribution']" [routerLink]="['/distribution']">
      <i class="tylicon tylicon-media" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.91 68.54">
          <title>Distribution</title>
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="#fff" d="M87.5,3.15l-1.8-.3a256.76,256.76,0,0,0-76.3,0l-1.8.3A8.92,8.92,0,0,0,0,12v44.6a8.84,8.84,0,0,0,7.6,8.8l3.4.5a254.14,254.14,0,0,0,76.4-.4,8.92,8.92,0,0,0,7.5-8.8V12.05A8.54,8.54,0,0,0,87.5,3.15ZM60.7,36.55,41.2,48.75a2.69,2.69,0,0,1-4.1-2.3V22.05a2.69,2.69,0,0,1,4.1-2.3L60.7,32A2.71,2.71,0,0,1,60.7,36.55Z" /></g></g>
        </svg>
      </i>
      <span class="p-normal">Distribution</span>
    </a>
  </li>
    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Distribution']" [routerLink]="['/distribution']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
         <icon-nav-distribution></icon-nav-distribution>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Distribution</span>
      </a>
    </li>

    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Order']" [routerLink]="['/orders']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-orders></icon-nav-orders>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Orders</span>
        <span *ngIf="((this.util.draftOrdersCount + this.util.awaitingOrderReviewCount  + this.util.ordersReadyToProcessCount + this.util.ordersInProcessingCount + this.util.vendorSubmissionFailedOrderCount)>0)" class="tylie-sidebar__nav-lnk-badge">{{ this.util.draftOrdersCount + this.util.awaitingOrderReviewCount  + this.util.ordersReadyToProcessCount + this.util.ordersInProcessingCount + this.util.vendorSubmissionFailedOrderCount }}</span>
      </a>
    </li>


    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Order', 'Asset', 'Reporting', 'IT - Dev']" [routerLink]="['/user/notifications']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-notifications></icon-nav-notifications>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Notifications</span>
        <span class="tylie-sidebar__nav-lnk-badge" *ngIf="this.util.notificationCount">{{this.util.notificationCount}}</span>
      </a>
    </li>

    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Reporting']" [routerLink]="['/reports']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-reports></icon-nav-reports>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Reports</span>
      </a>
    </li>

    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['Vault - View']" [routerLink]="['/vault']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-vault></icon-nav-vault>
        </i>
        <span class="tylie-sidebar__nav-txt p-normal">Vault</span>
      </a>
    </li>

    <li class="tylie-sidebar__nav-itm" routerLinkActive="active">
      <a class="tylie-sidebar__nav-lnk" (click)="onNonAdminClick()" secured [accessControlList]="['IT - Dev']" [routerLink]="['/itOps']">
        <i class="tylie-sidebar__nav-icon" aria-hidden="true">
          <icon-nav-ops></icon-nav-ops>
        </i>
        <span class="p-normal">IT Ops</span>
      </a>
    </li>

    <li class="tylie-sidebar__nav-itm"
        [ngClass]="{'active': (clientsActive.isActive || brandsActive.isActive || usersActive.isActive || destinationsActive.isActive || showAdminSubMenu == true),
                    'readytogetactive': (!clientsActive.isActive && !brandsActive.isActive && !usersActive.isActive && !destinationsActive.isActive)}">
        <a class="tylie-sidebar__nav-lnk" (click)="onAdminClick()" secured [accessControlList]="['Admin']">
          <i class="tylie-sidebar__nav-icon" aria-hidden="true">
            <icon-nav-admin></icon-nav-admin>
          </i>
          <span class="tylie-sidebar__nav-txt p-normal">Admin</span>
        </a>
        <div class="tylie-sidebar__nav-subitm">
          <a class="tylie-sidebar__nav-sublnk" secured [accessControlListComponent]="['Admin - View', 'Admin - Accounting', 'Admin - Client Services']"
              [ngClass]="{'active':clientsActive.isActive}" [routerLink]="['/admin/clients']" routerLinkActive #clientsActive="routerLinkActive">Clients</a>
          <a class="tylie-sidebar__nav-sublnk" secured [accessControlListComponent]="['Admin - View', 'Admin - Accounting', 'Admin - Client Services']"
              [ngClass]="{'active':brandsActive.isActive}" [routerLink]="['/admin/brands']" routerLinkActive #brandsActive="routerLinkActive">Brands</a>
          <a class="tylie-sidebar__nav-sublnk" secured [accessControlListComponent]="['Admin - Systems', 'Admin - View', 'Admin - Client', 'Admin - Client Services']"
              [ngClass]="{'active':usersActive.isActive}" [routerLink]="['/admin/users']" routerLinkActive #usersActive="routerLinkActive">Users</a>
          <a class="tylie-sidebar__nav-sublnk" secured [accessControlListComponent]="['Admin - Systems', 'Admin - Client Services']"
              [ngClass]="{'active':destinationsActive.isActive}" [routerLink]="['/admin/destinations']" routerLinkActive #destinationsActive="routerLinkActive">Destinations</a>
        </div>
    </li>
  </ul>

  <div class="tylie-sidebar__bottom">
    <div class="tylie-sidebar__account" *ngIf="showAccountExecutive">
      <h3 class="tylie-sidebar__account-title">your account executive</h3>
      <div class="tylie-sidebar__account-body">
          <p>{{this.accountExecutive.name}}</p>
          <p>(818) 955 7600</p>
          <p>{{this.accountExecutive.email}}</p>
      </div>
    </div>
    <div class="tylie-sidebar__help-center">
      <a href="mailto:support@tylie.com">
        <icon-question-mark></icon-question-mark>
        <span class="tylie-sidebar__help-center-txt">Help Center</span>
      </a>
    </div>
    <div class="tylie-sidebar__copyright">
      &copy; {{currentYear}} 7Miles Global. v{{appVersion}}
    </div>
  </div>
</div>

<button class="tylie-sidebar__toggler" (click)="util.toggleSidebar($event)" id="sidebarCollapse">
  <icon-arrow-simple-left height="12px" width="12px"></icon-arrow-simple-left>
</button>
