<header class="tylie-header">
  <h3 class="tylie-header__title">Create Vault Request</h3>
  <nav class="tylie-header__nav">
    <button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="gotoVaultHome()"><span class="tylie-button__icon"><icon-arrow-circle-left color="#1e90ff"></icon-arrow-circle-left></span>Back To All Vault Requests</button>
  </nav>
</header>

<div class="tylie-body inner-content order-intractions">
    <form [formGroup]="createVaultRequest" #f="ngForm" (ngSubmit)="onSubmitVault(createVaultRequest)">
      <div id="vaultNewCollapseOne" class="tylie-panel tylie-panel--clean">
        <div class="tylie-panel__body">
          <div class="row">
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['clientId'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="client">Client<span class="required">*</span></label>
                <dx-select-box [dataSource]="clientDataSource"
                               id="client"
                               name="client"
                               class="tylie-select form-control"
                               formControlName="clientId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               displayExpr="name"
                               (onValueChanged)="onClientValueChanged($event)"
                               placeholder="Select a client...">
                </dx-select-box>
                <p class="p-extra-small">Please select Client</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['brandId'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="brand">Brand<span class="required">*</span></label>
                <dx-select-box [dataSource]="brandDataSource"
                               class="tylie-select form-control"
                               id="brand"
                               name="brand"
                               formControlName="brandId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               displayExpr="name"
                               noDataText="Please choose a client"
                               (onValueChanged)="onBrandValueChanged($event)"
                               placeholder="Select">
                </dx-select-box>
                <p class="p-extra-small">Please select Brand</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['productId'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="product">Product<span class="required">*</span></label>
                <dx-select-box [dataSource]="productDataSource"
                               class="tylie-select form-control"
                               id="product"
                               name="product"
                               [searchEnabled]="true"
                               noDataText="Please choose a brand"
                               formControlName="productId"
                               valueExpr="id"
                               displayExpr="name"
                               placeholder="Select Brand first"
                               (onValueChanged)="onProductValueChanged($event)">
                </dx-select-box>
                <p class="p-extra-small">Please select Product</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['requestReceivedFrom'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="requestReceivedFrom">Received From<span class="required">*</span></label>
                <input type="text" id="requestReceivedFrom" name="requestReceivedFrom" class="tylie-text form-control" formControlName="requestReceivedFrom">
                <p class="p-extra-small" *ngIf="createVaultRequest.controls['requestReceivedFrom'].errors?.required">Please enter Received Form</p>
              </div>
            </div>
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['jobNumber'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="jobNumber">Client Job Number<span class="required">*</span></label>
                <input type="text" id="jobNumber" name="jobNumber" class="tylie-text form-control" formControlName="jobNumber">
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['reference'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="reference">Reference<span class="required">*</span></label>
                <input type="text" id="reference" name="reference" class="tylie-text form-control" formControlName="reference">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['requestType'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="requestType">Request Type<span class="required">*</span></label>
                <dx-select-box [dataSource]="requestTypesDataSource"
                               id="requestType"
                               name="requestType"
                               class="tylie-select form-control"
                               formControlName="requestType"
                               [searchEnabled]="true"
                               valueExpr="id"
                               displayExpr="name"
                               placeholder="Select a request type..."
                               (onValueChanged)="onRequestTypeChange()">
                </dx-select-box>
                <p class="p-extra-small">Please select Request Type</p>
              </div>
            </div>

            <div class="col-3">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['warehouseId'].errors && f.submitted}">
                <label for="warehouse" class="tylie-form-group__lbl">Warehouse<span class="required">*</span></label>
                <dx-select-box [dataSource]="warehouseDataSource"
                               id="warehouse"
                               name="warehouse"
                               class="tylie-select form-control"
                               formControlName="warehouseId"
                               [searchEnabled]="true"
                               valueExpr="id"
                               displayExpr="name"
                               placeholder="Select a warehouse...">
                </dx-select-box>
                <p class="p-extra-small">Please select Warehouse</p>
              </div>
            </div>

            <!--<div class="col-3">
      <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['formatId'].errors && f.submitted}">
        <label class="tylie-form-group__lbl" for="format">Format<span class="required">*</span></label>
        <dx-select-box [dataSource]="formatDataSource"
                        id="format"
                        name="format"
                        class="tylie-select form-control"
                        formControlName="formatId"
                        [searchEnabled]="true"
                        valueExpr="id"
                        displayExpr="name"
                        placeholder="Select a format...">
        </dx-select-box>
        <p class="p-extra-small">Please select Format</p>
      </div>
    </div>-->
          </div>

          <div class="row">
            <div class="col-3" *ngIf="isRequestTypeLogOut == true || isRequestTypePermRemoval == true">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['transferTo'].errors && f.submitted}">
                <label class="tylie-form-group__lbl" for="transferTo">Transfer To<span class="required">*</span></label>
                <input type="text" id="transferTo" name="transferTo" class="tylie-text form-control" formControlName="transferTo">
                <p class="p-extra-small" *ngIf="createVaultRequest.controls['transferTo'].errors?.required">Please enter Transfer To</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="isRequestTypeLogOut == true || isRequestTypePermRemoval == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="ads">Transfer Date<span class="required">*</span></label>
                <ng-container style="width:100%">
                  <dx-date-box width="100%" #transferDate type="date" id="transferDate" name="transferDate"
                               displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                               placeholder="Select"
                               class="tylie-datepicker form-control" [showClearButton]="true" formControlName="transferDate"></dx-date-box>
                </ng-container>
                <p class="p-extra-small">Invalid Transfer Date</p>
              </div>
            </div>

            <div class="col-md-3" *ngIf="isRequestTypeLogIn == true">
              <div class="tylie-form-group form-group">
                <label class="tylie-form-group__lbl" for="ads">Returned Date<span class="required">*</span></label>
                <ng-container style="width:100%">
                  <dx-date-box width="100%" #returnedDate type="date" id="returnedDate" name="returnedDate"
                               displayFormat="MM/dd/yyyy" invalidDateMessage="Invalid date"
                               placeholder="Select"
                               class="tylie-datepicker form-control" [showClearButton]="true" formControlName="returnedDate"></dx-date-box>
                </ng-container>
                <p class="p-extra-small">Invalid Returned Date</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-9">
              <div class="tylie-form-group form-group" [ngClass]="{error: createVaultRequest.controls['confirmationEmails'].errors}">
                <label class="tylie-form-group__lbl" for="confirmationEmails">Confirmation Emails</label> <span> &nbsp;(optional, comma separated)</span>
                <input type="text" id="confirmationEmails" name="confirmationEmails" class="tylie-text form-control" formControlName="confirmationEmails">
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-9">
              <div id="commentDiv" class="tylie-form-group form-group qc-notes">
                <label class="tylie-form-group__lbl" for="notes">Notes</label>
                <textarea id="notes" name="notes" formControlName="notes" class="form-control"></textarea>
                <p class="p-extra-small">Please enter notes</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-9">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl order-edit-view">Attachments</label>
                <div *ngIf="attachments.length === 0">There are no attachments yet.</div>

                <div class="tylie-table-wrapper">
                  <table class="tylie-table table table-bordered" *ngIf="attachments.length > 0">
                    <thead class="tylie-table__head">
                      <tr>
                        <th width="90%">File Name</th>
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody class="tylie-table__body">
                      <tr *ngFor="let att of attachments; let i = index">
                        <td class="blue-text"><a (click)="onDownloadFile(att.vaultFileGuid, att.fileName);">{{att.fileName}}</a></td>
                        <td align="center">
                          <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="mt-2">
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddAttachments()">
                    <span class="tylie-button__icon">
                      <icon-plus-circle color="#1e90ff"></icon-plus-circle>
                    </span>
                    Upload Attachment
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="tylie-form-group">
            <label class="tylie-form-group__lbl">Additional Services</label> <span>&nbsp;(optional)</span>
            <div class="d-flex">
              <div class="tylie-form-group form-check me-4">
                <input class="form-check-input" type="checkbox" formControlName="isAuditService" name="isAuditService">
                <label class="form-check-label">Audit Service</label>
              </div>
              <div class="tylie-form-group form-check me-4">
                <input class="form-check-input" type="checkbox" formControlName="isDigitalEncoding" name="isDigitalEncoding">
                <label class="form-check-label">Digital Encoding</label>
              </div>
              <div class="tylie-form-group form-check">
                <input class="form-check-input" type="checkbox" formControlName="isClimateControl" name="isClimateControl">
                <label class="form-check-label">Climate Control</label>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link me-3" outsideClick="true" placement="top" #clearVaultpop="bs-popover" container="body" [popover]="cancelVaultTemplate">
              Cancel
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="submit">
              <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
              <span class="tylie-button__text">Create Vault Request</span>
            </button>

          </div>

          <!-- <div class="row save-draft-btn-group">
    <div class="col-md-12">
      <button type="submit" class="new-default-btn small-btn add-group-btn">

        Create Vault Request
      </button>
      <span class="p-small qc-cancel pull-right clearOrder-label" outsideClick="true" placement="top" #clearVaultpop="bs-popover" container="body" [popover]="cancelVaultTemplate">
        Cancel
      </span>
    </div>
  </div> -->
        </div>
      </div>
    </form>
</div>

<ng-template #deleteAttTemplate let-fileName="fileName" let-vaultFileGuid="vaultFileGuid">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete {{fileName}}?
    </p>
    <i (click)="onHidePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button> -->


  <button type="button" class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header"> Are you sure you'd like to delete {{fileName}}?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onAttDelete(fileName, vaultFileGuid)">Delete</button>
</ng-template>

<ng-template #cancelVaultTemplate>
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to clear the Vault Request?
    </p>
    <i (click)="onHideClearVaultPop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="clearSavedData()">Yes</button> -->


  <button type="button" class="popover__close" (click)="onHideClearVaultPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to clear the Vault Request ?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="clearSavedData()">Yes</button>
</ng-template>
