<div *ngIf="playLists.length > 0">
  <div class="tylie-accordion accordion" id="playlist-section">
    <div class="tylie-accordion__panel accordion-item" id="playlistPanel">
      <h2 class="tylie-accordion__heading accordion-header">
        <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#playListCollapseOne" aria-expanded="true" aria-controls="collapseOne"  (click)="closePLayListDetail()">
          playLists
        </button>
      </h2>
      <div id="playListCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
        <div class="tylie-accordion__body accordion-body">
          
            <section class="tylie-playlists">
              <div id="myCarousel" class="carousel slide" data-interval="false">
                <div class="tylie-playlists__items carousel-inner play-list-carousel">
                  <div *ngFor="let playlist of playlistoflist;let i = index" class="tylie-playlist carousel-item {{i==0 ? 'active' : ''}}">
                    <div class="row">
                      <div *ngFor="let playlist1 of playlist;" class="tylie-playlist__item col-sm-2">
                        <div class="tylie-playlist__item-body" (click)="showPlayListFiles(playlist1.playListGuid, playlist1.playListName, $event)">
                          <div class="tylie-playlist__item-img" [style.background-image]="'url(' + playlist1.thumbnailUrl + ')'" ></div>
                          <button type="button" class="tylie-playlist__item-action share" (click)="openShareMediaPopUP(playlist1.playListGuid,playlist1.playListName)"><icon-envelope-share height="12px" width="12px"></icon-envelope-share></button>
                          <button type="button" class="tylie-playlist__item-action remove" outsideClick="true" placement="left" #delPlayListpop="bs-popover" container="body" [popover]="deletePlaylist"
                          [popoverContext]="playlist1"><icon-trash-can height="12px" width="12px"></icon-trash-can></button>
                          
                          <!-- <a class="hide"> -->
                            <img class="tylie-playlist__item-fimg" [src]="playlist1.thumbnailUrl" alt="Image">
                          <!-- </a> -->
                        </div>
                        <h2 class="tylie-playlist__item-title" title="{{playlist1.playListName}}">
                            {{(playlist1.playListName | truncateByLetters : 10) + ' (' + (playlist1.count) +')'}}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="tylie-playlists__item-prev carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev" *ngIf="playLists.length > 6">
                  <icon-arrow-simple-left></icon-arrow-simple-left>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="tylie-playlists__item-next carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next" *ngIf="playLists.length > 6">
                  <icon-arrow-simple-right></icon-arrow-simple-right>
                  <span class="visually-hidden">Next</span>
                </button>
                <!-- <a class="action-left" href="#myCarousel" data-slide="prev" *ngIf="playLists.length > 6">
                  <icon-arrow-simple-right></icon-arrow-simple-right>
                </a>
                <a class="action-right" href="#myCarousel" data-slide="next" *ngIf="playLists.length > 6">
                  <icon-arrow-simple-left></icon-arrow-simple-left>
                </a> -->
              </div>
            
              <div class="tylie-playlists__details" id="details-play-list" *ngIf="playListFiles.length > 0">
                <header class="tylie-playlists__details-header">
                  <h2 class="tylie-playlists__details-title">
                    {{playListName | truncateByLetters}}
                    <span class="p-small">{{' ('+playListFiles.length + ' Videos'+')'}}</span>
                  </h2>
                  
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePlayList()">
                    <span class="tylie-button__icon"><icon-delete color="#1e90ff"></icon-delete></span>
                    close playlist
                  </button>
                </header>
                <div class="row">
                  <div class="col-sm-2 tylie-playlist__item" *ngFor="let playlist of playListFiles; let ind = index">
                    <div class="tylie-playlist__item-body">
                      
                      <div class="tylie-playlist__item-img" [style.background-image]="'url(' + playlist.thumbnailUrl + ')'"><img class="tylie-playlist__item-pimg" src="./assets/svg/Button-Play-v2.svg" (click)="playSource(playlist.spotFileGuid)">
                                                                                                                                 </div>
                      <button class="tylie-playlist__item-action remove" outsideClick="true" placement="left" #delPlayListFilepop="bs-popover" container="body" [popover]="deletePlaylistFile"
                            [popoverContext]="playlist" *ngIf="playListFiles?.length > 1"><icon-trash-can height="12px" width="12px"></icon-trash-can></button>
                     
                        <img [src]="playlist.thumbnailUrl" alt="Image" class="tylie-playlist__item-fimg">
                     
                    </div>
                  
                    <h2 class="tylie-playlist__item-title" title="{{playlist.displayTitle}}">
                      {{playlist.displayTitle | truncateByLetters : 15}}
                    </h2>
                  </div>
                </div>
              </div>
            </section>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deletePlaylist let-playListGuid="playListGuid" role="popover">
  <button class="popover__close" (click)="onHideDeletePlaylistPop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure want to remove?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="deletePlayList(playListGuid)">Remove</button>
</ng-template>

<ng-template #deletePlaylistFile let-playListGuid="playListGuid" let-playListFileGuid="playListFileGuid">
  <button class="popover__close" (click)="onHideDeletePlaylistFilePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure want to remove?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="removeFromPlayList(playListGuid, playListFileGuid)">Remove</button>


  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure want to remove?
    </p>
    <i (click)="onHideDeletePlaylistFilePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="removeFromPlayList(playListGuid, playListFileGuid)">Remove</button> -->
</ng-template>

<!--<div class="modal fade" id="playVideo"  role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="modal-close" data-dismiss="modal" (click)="videoFilesToPlay = null;"><icon-delete color="#000"></icon-delete></button>
        <h4 class="modal-title">{{playListName}}</h4>
      </div>
      <div class="modal-body">
        <videoplayer [videoFilesSource]="videoFilesToPlay" [currentItem]="currentItem" [currentIndex]="currentIndex" *ngIf="videoFilesToPlay"></videoplayer>
      </div>
    </div>
  </div>
</div>-->
