import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { AlertService, MessageSeverity } from "../../../../../../../services/core/alert.service";
import { OrderService } from "../../../../../../../services/order/order.service";
import { Utilities } from "../../../../../../../services/core/utilities";
import { AdminService } from "../../../../../../../services/admin/admin.service";
import { ConfigService } from "../../../../../../../services/core/config.service";
import { CreateClientModel } from "../../../../../../../models/admin/client/createclient.model";
import { Constants } from "../../../../../../../configurations/constants";
import { PostHouseModel } from "../../../../../../../models/admin/client/client";

@Component({
  selector: 'add-newposthouse',
  templateUrl: './add-newposthouse.component.html',
  styleUrls: ['./add-newposthouse.component.css'],
})
export class AddNewPostHouseComponent implements OnInit {

  public modalRef: BsModalRef;
  public addNewPostHouseForm: FormGroup;
  public isSaving: boolean = false;

  @Output() onAddNewPostHouseSuccess: EventEmitter<PostHouseModel> = new EventEmitter<PostHouseModel>();

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private util: Utilities,
    private configService: ConfigService,
    private adminService: AdminService) {

  }

  ngOnInit() {
    this.addNewPostHouseForm = this.fb.group({
      clientName: [null, Validators.compose([Validators.required])]
    });
  }

  public onFormSubmit(f: NgForm, { value, valid }: { value: CreateClientModel, valid: boolean }) {
    console.log(value);
    if (valid == false) { return; }

    this.createPostHouse(value, f);
  }

  private createPostHouse(value: CreateClientModel, f: NgForm) {
    this.isSaving = true;
    this.adminService.createPostHouse(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result;
        this.onAddNewPostHouseSuccess.emit(result.postHouse);
        this.alertService.showMessage("SUCCESS", Constants.addNewPostHouseSuccessful, MessageSeverity.success);
        this.isSaving = false;
        f.resetForm();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.isSaving = false;
      }
    },
      error => {
        this.util.handleError(error);
        this.isSaving = false;
      });
  }
}
