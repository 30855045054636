<div [formGroup]="formGroup">

  <div class="row">

    <div id="dest-info-asset" class="col-md-12" *ngFor="let spotFile of spotFiles; let i=index">
      <div class="transcode-galarey">
        <div class="ord-details">
          <div class="order-pic">
            <img [src]="spotFile.thumbnailUrl">
          </div>
          <div class="order-details col-md-12">
            <div class="transcode-bold">{{spotFile.adId}}</div>
            <div class="p-normal">{{spotFile.title}}</div>
            <div class="p-normal">{{spotFile.length}}</div>
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="innovidData">

      <div class="form-group col-md-12">
        <label>INNOVID Info</label>
      </div>

      <div class="form-group col-md-6" [ngClass]="{error: (innovidData.controls['campaign'].errors && f.submitted)}">
        <label for="campaign">Campaign<span class="required">*</span></label>
        <input type="text" class="form-control" name="campaign" formControlName="campaign" />
        <p class="p-extra-small">Please enter Campaign</p>
      </div>
    </div>

  </div>

  <hr class="seperator" />

</div>
