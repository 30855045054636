import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ConfigService } from '../../../services/core/config.service';
import { GridParams } from '../../../models/config/gridparams.model';
import DataSource from 'devextreme/data/data_source';
import { OrderDestination } from '../../../models/order/order-destinations.model';
import { Subject } from 'rxjs';
import { SelectedOrderDestinations } from '../../../models/order/order-create.model';
import { OrderUserDestination } from '../../../models/order/order-userdestination';
import { DestinationSearch, SpecificationSearch } from "../../../models/admin/destination/destinationsearch.model";
import { BasePopupComponent } from '../basepopup/basepopup.component';
import { DistributionSettingsRequestModel } from '../../../models/distribution/distribution-settings.model';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AdminService } from '../../../services/admin/admin.service';
import { EmailListValidator } from '../../../configurations/custom-validations';
import { CreateDestinationConfig } from '../../../models/admin/destination/createdestination.model';
import { S3Config, AsperaConfig, GDriveConfig, DropBoxConfig } from '../../../models/admin/destination/destinationdetail.model';

@Component({
  selector: 'admindestinationsettingspopup.component.ts',
  templateUrl: './admindestinationsettingspopup.component.html',
  styleUrls: ['./admindestinationsettingspopup.component.css'],
})
export class AdminDestinationSettingsPopupComponent extends BasePopupComponent {

  public dataSource: any = {};
  public quickSearchValue: string = '';
  public onClose: Subject<DistributionSettingsRequestModel>;
  public searchSpecificationsCriteriaObj = <SpecificationSearch>{};
  public distributionSettingsFormGroup: FormGroup;
  public bxfFile: File = null;
  public isUploading: boolean = false;
  public bxfXML: string = null;
  public bxfByteArray: any[] = null;
  public bxfFileName: string = null;
  public bxfFileCopy: File = null;
  public showFTPConfig: boolean = false;
  public showLinksConfig: boolean = false;
  public showS3Config: boolean = false;
  public showAsperaConfig: boolean = false;
  public showGDriveConfig: boolean = false;
  public showDropBoxConfig: boolean = false;
  public showManulDistributionConfig: boolean = false;
  public showBackupFTPConfig: boolean = false;
  public showBackupLinksConfig: boolean = false;
  public showBackupS3Config: boolean = false;
  public showBackupAsperaConfig: boolean = false;
  public showBackupGDriveConfig: boolean = false;
  public showBackupDropBoxConfig: boolean = false;
  public showBackupManulDistributionConfig: boolean = false;
  public seperator: string = "_"

  @Input('isHouseIdRequired') isHouseIdRequired: boolean = false;
  @Input('customfilenamepos1') customfilenamepos1: string = null;
  @Input('customfilenamepos2') customfilenamepos2: string = null;
  @Input('customfilenamepos3') customfilenamepos3: string = null;
  @Input('fileDeliveryType') fileDeliveryType: number;
  @Input('linkEmails') linkEmails: string = null;
  @Input('ftpServer') ftpServer: string = null;
  @Input('ftpPassword') ftpPassword: string = null;
  @Input('ftpUserName') ftpUserName: string = null;
  @Input('notes') notes: string = null;
  @Input('s3') s3: S3Config = null;
  @Input('aspera') aspera: AsperaConfig = null;
  @Input('googleDrive') googleDrive: GDriveConfig = null;
  @Input('dropBox') dropBox: DropBoxConfig = null;
  @Input('ftpPort') ftpPort: string = null;

  @Input('config') config: CreateDestinationConfig = null;
  @ViewChild('specsGrid', { static: false }) specificationsGrid: DxDataGridComponent;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private configService: ConfigService,
    private util: Utilities,
    private fb: FormBuilder,
    private adminService: AdminService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.distributionSettingsFormGroup = this.fb.group({
      isHouseIdRequired: [this.isHouseIdRequired],
      specifications: [],
      bxfXML: [null],
      fileDeliveryType: [this.fileDeliveryType],
      ftpServer: [null],
      ftpUserName: [null],
      ftpPassword: [null],
      ftpPort: [null],
      linkEmails: [null],
      s3Bucket: [null],
      s3Path: [null],
      dropBoxFolderPath: [null],
      asperaServer: [null],
      asperaUser: [null],
      asperaPassword: [null],
      asperaPort: [null],
      asperaTransferSpeed: [null],
      asperaSubFolder: [null],
      folderPath: [null],
      folderId: [null],
      customfilenamepos1: [this.customfilenamepos1],
      customfilenamepos2: [this.customfilenamepos2],
      customfilenamepos3: [this.customfilenamepos3],
      notes: [null]
    });

    if (this.config != null) {
      this.onFileDeliveryTypeChange();
    }

    this.onClose = new Subject();
    this.searchSpecifications();
    this.bxfFile = null;
  }

  public closePopup() {
    this.bsModalRef.hide();

    if (this.specificationsGrid != null && this.specificationsGrid.instance != null) {
      this.specificationsGrid.instance.deselectAll();
    }
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {

      this.searchSpecificationsCriteriaObj.quickSearch = this.quickSearchValue;
      this.searchSpecifications();
    }
  }

  public onQuickSearchDestinations() {
    if (this.quickSearchValue != null && this.quickSearchValue.trim() != '') {
      this.searchSpecificationsCriteriaObj.quickSearch = this.quickSearchValue;
      this.searchSpecifications();
    }
  }

  public formSubmit({ value, valid }: { value: DistributionSettingsRequestModel, valid: boolean }) {
    if (!valid)
      return;

    if (this.specificationsGrid != null && this.specificationsGrid.instance != null) {
      value.specifications = this.specificationsGrid.instance.getSelectedRowsData();
    }

    value.bxfXML = this.bxfXML;
    value.bxfByteArray = this.bxfByteArray;

    if ((this.distributionSettingsFormGroup.controls['customfilenamepos1'].value != '' && this.distributionSettingsFormGroup.controls['customfilenamepos1'].value != null) ||
      (this.distributionSettingsFormGroup.controls['customfilenamepos2'].value != '' && this.distributionSettingsFormGroup.controls['customfilenamepos2'].value != null) ||
      (this.distributionSettingsFormGroup.controls['customfilenamepos3'].value != '' && this.distributionSettingsFormGroup.controls['customfilenamepos3'].value != null))
    {
      var custFileNameSelections = [
        this.distributionSettingsFormGroup.controls['customfilenamepos1'].value,
        this.distributionSettingsFormGroup.controls['customfilenamepos2'].value,
        this.distributionSettingsFormGroup.controls['customfilenamepos3'].value
      ];

      value.transcodeCustomFileName = custFileNameSelections.join(this.seperator);
    }
    else {
      value.transcodeCustomFileName = null;
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeS3) {
      value.s3 = new S3Config()
      value.s3.bucket = this.distributionSettingsFormGroup.controls['s3Bucket'].value;
      value.s3.path = this.distributionSettingsFormGroup.controls['s3Path'].value
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeAspera) {
      value.aspera = new AsperaConfig()
      value.aspera.endPoint = this.distributionSettingsFormGroup.controls['asperaServer'].value;
      value.aspera.password = this.distributionSettingsFormGroup.controls['asperaPassword'].value
      value.aspera.subFolder = this.distributionSettingsFormGroup.controls['asperaSubFolder'].value
      value.aspera.user = this.distributionSettingsFormGroup.controls['asperaUser'].value
      value.aspera.port = this.distributionSettingsFormGroup.controls['asperaPort'].value
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeGdrive) {
      value.googleDrive = new GDriveConfig()
      value.googleDrive.folderPath = this.distributionSettingsFormGroup.controls['folderPath'].value;
      value.googleDrive.folderId = this.distributionSettingsFormGroup.controls['folderId'].value
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeDropBox) {
      value.dropBox = new DropBoxConfig()
      value.dropBox.folderPath = this.distributionSettingsFormGroup.controls['dropBoxFolderPath'].value
      //alert("Saved." + JSON.stringify(value.dropBox));
    }

    this.closePopup();
    this.onClose.next(value);
  }

  public onBxfUploadFileChange(event) {
    this.bxfFile = event.target.files[0];

    this.validateBxfFile();
  }

  public onFileDeliveryTypeChange() {

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeLinks) {
      this.distributionSettingsFormGroup.get('linkEmails').setValue(this.linkEmails);
      this.distributionSettingsFormGroup.get('linkEmails').setValidators([Validators.required, EmailListValidator]);
      this.distributionSettingsFormGroup.controls["linkEmails"].updateValueAndValidity();

      this.showLinksConfig = true;
    }
    else {
      this.distributionSettingsFormGroup.controls["linkEmails"].clearValidators();
      this.distributionSettingsFormGroup.controls["linkEmails"].updateValueAndValidity();

      this.showLinksConfig = false;
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeS3 ) {
      this.distributionSettingsFormGroup.controls["s3Bucket"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["s3Bucket"].updateValueAndValidity();
      this.showS3Config = true;
      if (this.s3 != null && this.s3 != undefined) {
        this.distributionSettingsFormGroup.get('s3Bucket').setValue(this.s3.bucket);
        this.distributionSettingsFormGroup.get('s3Path').setValue(this.s3.path);
      }
    }
    else {
      this.distributionSettingsFormGroup.controls["s3Bucket"].clearValidators();
      this.distributionSettingsFormGroup.controls["s3Bucket"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["s3Path"].clearValidators();
      this.distributionSettingsFormGroup.controls["s3Path"].updateValueAndValidity();

      this.showS3Config = false;
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeAspera) {
      this.distributionSettingsFormGroup.controls["asperaServer"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["asperaServer"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["asperaUser"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["asperaUser"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["asperaPassword"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["asperaPassword"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["asperaPort"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["asperaPort"].updateValueAndValidity();

      this.showAsperaConfig = true;
      if (this.aspera != null && this.aspera != undefined) {
        this.distributionSettingsFormGroup.get('asperaServer').setValue(this.aspera.endPoint);
        this.distributionSettingsFormGroup.get('asperaUser').setValue(this.aspera.user);
        this.distributionSettingsFormGroup.get('asperaPassword').setValue(this.aspera.password);
        this.distributionSettingsFormGroup.get('asperaPort').setValue(this.aspera.port);
        this.distributionSettingsFormGroup.get('asperaSubFolder').setValue(this.aspera.subFolder);
      }
    }
    else {
      this.distributionSettingsFormGroup.controls["asperaServer"].clearValidators();
      this.distributionSettingsFormGroup.controls["asperaServer"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["asperaUser"].clearValidators();
      this.distributionSettingsFormGroup.controls["asperaUser"].updateValueAndValidity();
      this.distributionSettingsFormGroup.controls["asperaPassword"].clearValidators();
      this.distributionSettingsFormGroup.controls["asperaPassword"].updateValueAndValidity();
      this.distributionSettingsFormGroup.controls["asperaPort"].clearValidators();
      this.distributionSettingsFormGroup.controls["asperaPort"].updateValueAndValidity();
      this.distributionSettingsFormGroup.controls["asperaSubFolder"].clearValidators();

      this.showAsperaConfig = false;
    }


    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeFTP) {
      this.distributionSettingsFormGroup.get('ftpServer').setValue(this.ftpServer);
      this.distributionSettingsFormGroup.get('ftpServer').setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["ftpServer"].updateValueAndValidity();

      this.distributionSettingsFormGroup.get('ftpUserName').setValue(this.ftpUserName);
      this.distributionSettingsFormGroup.get('ftpUserName').setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["ftpUserName"].updateValueAndValidity();

      this.distributionSettingsFormGroup.get('ftpPassword').setValue(this.ftpPassword);
      this.distributionSettingsFormGroup.get('ftpPassword').setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["ftpPassword"].updateValueAndValidity();

      this.distributionSettingsFormGroup.get('ftpPort').setValue(this.ftpPort);

      this.showFTPConfig = true;
    }
    else {

      this.distributionSettingsFormGroup.controls["ftpServer"].clearValidators();
      this.distributionSettingsFormGroup.controls["ftpServer"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["ftpUserName"].clearValidators();
      this.distributionSettingsFormGroup.controls["ftpUserName"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["ftpPassword"].clearValidators();
      this.distributionSettingsFormGroup.controls["ftpPassword"].updateValueAndValidity();

      this.showFTPConfig = false;
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeDropBox) {
      this.distributionSettingsFormGroup.controls["dropBoxFolderPath"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["dropBoxFolderPath"].updateValueAndValidity();
      this.showDropBoxConfig = true;
    
      if (this.dropBox != null && this.dropBox != undefined) {
        this.distributionSettingsFormGroup.get('dropBoxFolderPath').setValue(this.dropBox.folderPath);
      }
    }
    else {
      this.distributionSettingsFormGroup.controls["dropBoxFolderPath"].clearValidators();
      this.distributionSettingsFormGroup.controls["dropBoxFolderPath"].updateValueAndValidity();

      this.showDropBoxConfig = false;
    }

    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeGdrive) {
      this.distributionSettingsFormGroup.controls["folderPath"].setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["folderPath"].updateValueAndValidity();
      this.showGDriveConfig = true;
      if (this.googleDrive != null && this.googleDrive != undefined) {
        this.distributionSettingsFormGroup.get('folderPath').setValue(this.googleDrive.folderPath);
        this.distributionSettingsFormGroup.get('folderId').setValue(this.googleDrive.folderId);
      }
    }
    else {
      this.distributionSettingsFormGroup.controls["folderPath"].clearValidators();
      this.distributionSettingsFormGroup.controls["folderPath"].updateValueAndValidity();

      this.distributionSettingsFormGroup.controls["folderId"].clearValidators();
      this.distributionSettingsFormGroup.controls["folderId"].updateValueAndValidity();

      this.showGDriveConfig = false;
    }


    if (this.distributionSettingsFormGroup.controls['fileDeliveryType'].value == this.config.fileDeliveryTypeManual) {
      this.distributionSettingsFormGroup.get('notes').setValue(this.notes);
      this.distributionSettingsFormGroup.get('notes').setValidators([Validators.required]);
      this.distributionSettingsFormGroup.controls["notes"].updateValueAndValidity();

      this.showManulDistributionConfig = true;
    }
    else {
      this.distributionSettingsFormGroup.controls["notes"].clearValidators();
      this.distributionSettingsFormGroup.controls["notes"].updateValueAndValidity();

      this.showManulDistributionConfig = false;
    }
  }

  private validateBxfFile() {
    if (this.bxfFile == null)
      return;

    var reader = new FileReader();

    reader.readAsDataURL(this.bxfFile);

    reader.onload = () => {
      var request: any = {
        fileType: this.bxfFile.type,
        fileName: this.bxfFile.name,
        bxfFile: (<string>reader.result).split(',')[1],
      };

      this.alertService.ShowLoader(true)

      this.adminService.validateBXFFile(request).subscribe((res: any) => {
        if (res.isSuccess == true) {
          console.log(res);
          if (res.result.isXMLValid) {
            this.bxfXML = res.result.bxfXML
            this.bxfByteArray = res.result.bxfFile;
            this.bxfFileName = this.bxfFile.name;
            this.bxfFileCopy = this.bxfFile;
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
            this.bxfFile = null;
          }
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          this.bxfFile = null;
        }
        this.isUploading = false;
        this.alertService.ShowLoader(false);
      },
        error => {
          this.alertService.ShowLoader(false);
          this.isUploading = false;
          this.util.handleError(error);
          this.bxfFile = null;
        });
    }
  }

  private get specificationsDataStore() {
    var configService = this.configService;
    var util = this.util;
    var criteriaObj = this.searchSpecificationsCriteriaObj;
    var mRef = this.bsModalRef;

    return new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip ? loadOptions.skip : 0;
        var take = loadOptions.take ? loadOptions.take : 10;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'name','desc':false}]";
        var groupConfig = loadOptions.group ? JSON.stringify(loadOptions.group) : "";

        let gridParams: GridParams = { group: groupConfig, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { criteria: criteriaObj, gridParams: gridParams };

        return configService.searchSpecifications(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var obj: any = {
                data: response.result.specifications,
                totalCount: response.result.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            if (util.handleError(error))
              mRef.hide();

            throw 'Data Loading Error';
          });
      }
    });
  }

  private searchSpecifications() {
    this.dataSource = new DataSource({
      store: this.specificationsDataStore
    });
  }
}
