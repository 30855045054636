import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from "../../services/auth/auth.service";
import { Utilities } from "../../services/core/utilities";
import { UserRoles } from "../../configurations/user-roles";
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { RouteConstants } from '../../configurations/route-constants';

@Component({
  selector: 'distribution-media',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.css']
})
export class DistributionComponent implements OnInit {

  constructor(
    private authService: AuthService,
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService) {
  }

  ngOnInit() {

   

  }

  gotoTMCDownload() {
    this.router.navigate([RouteConstants.tmcDownloadRoute]);
  }

  ngOnDestroy() {

  }
}
