
import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, NavigationExtras, CanLoad, Route, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from "../auth/auth.service";
import { AlertService, MessageSeverity } from "./alert.service";
import { Constants } from "../../configurations/constants";
import { environment } from '../../../environments/environment';
import { LocalStoreManager } from "./local-store-manager.service";
import { mediaDetailSource, metadataCategoryEnum } from "../../configurations/enums/enums";
import { IAsperaTransferFileStatus, IAsperaStatus } from "../../models/upload/transferFileStatus.model";
import { ScrollToService, ScrollToConfigOptions } from "@nicky-lenaers/ngx-scroll-to";

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ErrorPopupComponent } from '../../components/popups/errorpopup/errorpopup.component';
import { RouteConstants } from "../../configurations/route-constants";
import { Observable } from "rxjs";
import { DestinationAddlInfoMetadata, ServiceMetadata } from '../../models/order/order-create.model';
import { ServiceBAGuaranteeMetadataObjectOption, ServiceBAMetadataObjectOption, ServiceMetadataObjectOption } from '../../models/order/order-servicequestions.model';
import { DBkeys } from '../../configurations/db-Keys';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DownloadMediaStatus } from '../../models/download/downloadmedia-status';
import { UserProfileService } from '../user/user-profile.service';
import { User } from '../../models/user/user.model';
import { UserProfile } from '../../models/user/user.profile.model';

@Injectable()
export class Utilities {

  private musicThumbnailUrl: string = './assets/images/music.jpg';
  public assetNotInTandemThumbnailUrl: string = './assets/images/talentrightsthumbnail.png';
  private processingThumbnailUrl: string = './assets/images/processing.jpg';
  private creatingNewMediaBannerThumbnailUrl: string = './assets/images/creatingNewMediaBanner.png';
  private creatingNewMediaThumbnailUrl: string = './assets/images/creatingNewMedia.jpg';
  private approvalRejectedThumbnailUrl: string = './assets/images/rejectedMedia.png';
  private pendingApprovalThumbnailUrl: string = './assets/images/pendingApproval.png';
  public awaitingMediaUrl: string = './assets/images/awaitingMedia.jpg';
  private readonly URL_Replace = /\s/g;
  private readonly URL_Space: string = '%20';
  public asperaFileStatus: Array<IAsperaStatus>;
  public taggingFileStatus: Array<IAsperaStatus>;
  public spotQCCount: number;
  public notificationCount: number;
  public draftOrdersCount: number;
  public awaitingOrderReviewCount: number;
  public vendorSubmissionFailedOrderCount: number;
  public ordersReadyToProcessCount: number;
  public ordersInProcessingCount: number;
  public spotApprovalCount: number;
  public mediaScrollParam: string;
  public userCreatOrderForm: any = null;
  public userCreatVaultForm: any = null;
  public profileSelected: boolean = false;
  public awaitingMediaOrderStatusUrl: string = './assets/images/AwaitingMedia_94x45.png';

  public modalRef: BsModalRef;

  public downloadFileStatus: Array<DownloadMediaStatus> = [];
  public downloadDistributionFileStatus: Array<DownloadMediaStatus> = [];
  public userProfileId: string;
  public userOrg_id: string;
  public userProfileRoles: string[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStoreManager,
    private modalService: BsModalService,
    private alertService: AlertService) {
    this.asperaFileStatus = [];
    this.taggingFileStatus = [];
    this.spotQCCount = 0;
    this.notificationCount = 0;
    this.draftOrdersCount = 0;
    this.awaitingOrderReviewCount = 0;
    this.vendorSubmissionFailedOrderCount = 0;
    this.ordersReadyToProcessCount = 0;
    this.spotApprovalCount = 0;
    this.profileSelected = false;

    let userObj: User;
    userObj = this.localStorage.getData(DBkeys.CURRENT_USER);
    if (userObj != null && userObj != undefined) {
      let userProf: UserProfile = userObj.userProfile;
      this.userProfileId = userProf.userProfileGuid;
      this.userOrg_id = userProf.authIdentifier;
      this.userProfileRoles = userObj.roles;
    }
  }

  public loginEvents(userProfileGuid?: string, org_id?: string, roles?: string[]) {
    console.log(roles);
    console.log(org_id);
    this.asperaFileStatus = [];
    this.taggingFileStatus = [];
    this.spotQCCount = 0;
    this.notificationCount = 0;
    this.draftOrdersCount = 0;
    this.awaitingOrderReviewCount = 0;
    this.vendorSubmissionFailedOrderCount = 0;
    this.ordersReadyToProcessCount = 0;
    this.ordersInProcessingCount = 0;
    this.ordersInProcessingCount = 0;
    this.spotApprovalCount = 0;
    this.userCreatOrderForm = null;
    this.userCreatVaultForm = null;
    this.downloadFileStatus = [];
    this.downloadDistributionFileStatus = [];
    this.profileSelected = false;
    this.userProfileId = userProfileGuid;
    this.userOrg_id = org_id;
    this.userProfileRoles = roles;
  }

  public toggleSidebar(event: any) {
    var sidebarDiv = document.getElementById("sidebar");
    if (sidebarDiv != null)
      sidebarDiv.classList.toggle('active');

    var content = document.getElementById("content");
    var header = document.getElementsByClassName("tylie-header")[0];

    if (content != null)
      content.classList.toggle('active');
      header.classList.toggle('active');
    
    // event.target.classList.toggle('expanded-right-nav');
    // event.target.classList.toggle('expanded-left-nav');
  }

  public highlightMenu(event: any) {
    //console.log(event.target.classList);
    event.target.classList.toggle('active');
    //var sidebarDiv = document.getElementById("sidebar");

    //if (sidebarDiv != null)
    //  sidebarDiv.classList.toggle('active');

    //event.target.classList.toggle('expanded-right-nav');
    //event.target.classList.toggle('expanded-left-nav');
  }

  public static JSonTryParse(value: string) {
    try {
      return JSON.parse(value);
    }
    catch (e) {
      if (value === "undefined")
        return void 0;

      return value;
    }
  }

  public static capitalizeFirstLetter(text: string) {
    if (text)
      return text.charAt(0).toUpperCase() + text.slice(1);
    else
      return text;
  }

  public static toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (subString) => {
      return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
    });
  }

  public static parseDate(date: any) {

    if (date) {

      if (date instanceof Date) {
        return date;
      }

      if (typeof date === 'string' || date instanceof String) {
        if (date.search(/[a-su-z+]/i) == -1)
          date = date + "Z";

        return new Date(date);
      }

      if (typeof date === 'number' || date instanceof Number) {
        return new Date(<any>date);
      }
    }
  }

  public static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {

    let separatorIndex = text.indexOf(separator);
    if (separatorIndex == -1)
      return { firstPart: text, secondPart: "" };

    let part1 = text.substr(0, separatorIndex).trim();
    let part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static getQueryParamsFromString(paramString: string) {

    if (!paramString)
      return null;
    let params: { [key: string]: string } = {};
    for (let param of paramString.split("&")) {
      let keyValue = Utilities.splitInTwo(param, "=");
      params[keyValue.firstPart] = keyValue.secondPart;
    }
    return params;
  }

  public handleError(errorRes: HttpErrorResponse): boolean {

    this.alertService.logInfo("error handler");

    let errorMessage: string = "";
    let reRouted: boolean = false;
    if (errorRes.status == 401) {
      this.localStorage.deleteData(DBkeys.CURRENT_USER);
      reRouted = true;
      errorMessage = Constants.error401;
    }
    else if (errorRes.status == 403) {
      this.alertService.logError("Unauthorized access attempted");
      errorMessage = Constants.error403;
      console.log(errorRes);
    }
    else if (errorRes.status == 400) {
      errorMessage = Constants.error400;
      this.alertService.logError("Bad Request");
      var errObj = errorRes.error;
      if (errObj != null) {
        if (errObj.error_description != null) {
          if (errObj.error == "invalid_grant") {
            errorMessage = Constants.error401;
            reRouted = true;
          }
        }
        else if (errObj.errorDescription != null) {
          errorMessage = errObj.errorDescription;
        }
      }
      reRouted = false;
    }
    else if (errorRes.status == 500) {
      this.alertService.logError("Internal Server Error");
      errorMessage = Constants.error500;
    }
    else {
     // this.localStorage.deleteData(DBkeys.CURRENT_USER);
      //this.router.navigate([RouteConstants.loginRoute]);
      reRouted = false;
      this.alertService.logError("API connection error");
      errorMessage = Constants.connectionError;
    }

    if (errorRes.status == 401)
      this.alertService.showMessage("ERROR", errorMessage, MessageSeverity.error, false);
    else
      this.alertService.showMessage("ERROR", errorMessage, MessageSeverity.error, true);

    return reRouted;
  }

  public handleIsNotSuccess(errors: any[]) {
    if (errors && errors.length > 0) {

      var initialState = {
        errors: errors
      };

      this.modalService.show(ErrorPopupComponent, this.getModalComponentOptions(initialState, false, true, false));
    }
    else {
      this.alertService.showMessage("ERROR", Constants.error400, MessageSeverity.error, true);
    }
  }

  public handleIsNotSuccessLogin(errors: any) {
    if (errors.length > 0) {
      return errors;
    }
    else {
      return Constants.error400;
    }
  }

  public throwErrorandRedirect(message: string, route: string) {
    this.router.navigate([route]);
  }

  public getThumbnailUrl(url: string, isAudio: boolean, isAwaitingMedia: boolean = false, isCreatingNewMedia: boolean = false)//, getBanner: boolean = false)
  {
    if (url == null || url == '') {
      if (isAudio)
        return (this.musicThumbnailUrl);

      if (isAwaitingMedia)
        return (this.awaitingMediaUrl);

      if (isCreatingNewMedia)
        return this.creatingNewMediaThumbnailUrl;

      return (this.processingThumbnailUrl);
    }
    else
      return url.replace(this.URL_Replace, this.URL_Space);

    //return (environment.mediaBaseUrl + url.replace(this.URL_Replace, this.URL_Space));
  }

  public getProxyUrl(url: string) {

    if (url == null || url == '') {
      return '';
    }
    else {
      //return (environment.mediaBaseUrl + url.replace(this.URL_Replace, this.URL_Space));
      //return url.replace(this.URL_Replace, this.URL_Space);
      return url;
    }
  }

  public getFileUrl(url: string) {

    if (url == null || url == '') {
      return '';
    }
    else {
      return url.replace(this.URL_Replace, this.URL_Space);
      //return (environment.mediaBaseUrl + url.replace(this.URL_Replace, this.URL_Space));
    }
  }

  public gotoSettings() {
    this.router.navigate([RouteConstants.settingsRoute]);
  }

  public scrollIfNeededTo(): void {
    this.route.queryParams.subscribe((params: Params) => {
      let t = params['t'] || '';
      var target = "";

      if (t == mediaDetailSource[mediaDetailSource.Approval])
        target = "media-awaiting-approval-section";
      else if (t == mediaDetailSource[mediaDetailSource.QC])
        target = "media-qc-section";
      else if (t == mediaDetailSource[mediaDetailSource.Search])
        target = "search-all-media-section";
      else if (t == mediaDetailSource[mediaDetailSource.RecentlyViewed])
        target = "recently-viewed-section";
      else if (t == mediaDetailSource[mediaDetailSource.PlayList])
        target = "playlist-section";

      if (target != "") {
        //let config: ScrollToConfigOptions = {
        //  target: target
        //};
        //this.scrollToService.scrollTo(config);
        this.scrollTo(target);
      }
    });
  }

  public scrollTo(target) {

    var tgt = $("#" + target);
    var header = $('.tylie-header');

    if (tgt && header && tgt.offset() && header.outerHeight()) {
      var offset = $("#" + target).offset().top;
      var navbarOffset = $('.tylie-header').outerHeight();

      $('html,body').animate({ scrollTop: offset - navbarOffset }, 'slow');
    }
  }

  public getModalComponentOptions(initialState, small: boolean, medium: boolean, large: boolean, customClass: string = '') {
    return {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: customClass != '' ? customClass : (large ? ('modal-lg') : (medium ? 'modal-md' : 'modal-sm')),
      animated: true,
      initialState: initialState
    }
  }

  public roundToNearest15Minutes(someDate) {
    var mins = someDate.minutes();
    var remainder = (mins % 15);

    if (remainder === 0) {
      return someDate.minute(mins).second(0);
    }
    else {
      var roundedMinutes = mins + (15 - remainder);
      return someDate.minute(roundedMinutes).second(0);
    }
  }

  public getFormatSource(format: string): string {

    if (format === null)
      return '';

    if (format.toLowerCase() === "sd")
      return "./assets/svg/Icon-MediaType-SD.svg";
    else if (format.toLowerCase() === "hd")
      return "./assets/svg/Icon-MediaType-HD.svg";
    else if (format.toLowerCase() === "4k")
      return "./assets/svg/Icon-MediaType-4K.svg";
    else if (format.toLowerCase() === "audio")
      return "./assets/svg/Icon-MediaType-Audio.svg";
    else
      return '';
  }

  public getAudioFormatSource(audioFormat: string): string {

    if (audioFormat === null)
      return '';

    if (audioFormat != null && audioFormat.toLowerCase() === "5.1")
      return "./assets/svg/Icon-MediaType-5.1.svg";
    else if (audioFormat != null && audioFormat.toLowerCase() === "stereo")
      return "./assets/svg/Icon-MediaType-Stereo.svg";
    else
      return '';
  }

  public is169OverlayRequired(format: string): boolean {
    if (format === null || format === '' || format == undefined)
      return false;

    if (format.toLowerCase() === "hd")
      return true;

    return false;
  }

  public is430verlayRequired(format: string): boolean {
    if (format === null || format === '' || format == undefined || format.toLocaleLowerCase() == 'audio')
      return false;

    return true;
  }

  public convertStringToHoursMins(val: string): string {

    var totalMins = parseInt(val);
    var hrs = Math.floor(totalMins / 60);
    var mins = totalMins - (hrs * 60);
    if (hrs > 0 && mins > 0)
      return (hrs.toString() + ' ' + (hrs > 1 ? 'hrs' : 'hr') + ' ' + mins.toString() + ' mins');
    else if (hrs > 0)
      return (hrs.toString() + ' ' + (hrs > 1 ? 'hrs' : 'hr'));
    else if (mins > 0)
      return (mins.toString() + ' ' + (mins > 1 ? 'mins' : 'min'));
  }

  public getAllIconSources(format: string, audioFormat: string, spotTrac: boolean,
    veilEncode: boolean, subtitles: boolean, centerCutProtected: boolean, descriptiveVideo: boolean,
    broadcast: boolean, web: boolean, taggable: boolean, generic: boolean, runningFootage: boolean, cc: boolean,
    sap: boolean, vchip: boolean, letterbox: boolean, mono: boolean, surround: boolean): Array<string> {
    var sources = new Array<string>();

    if (format != null) {
      if (format.toLowerCase() === "sd")
        sources.push("./assets/svg/Icon-MediaType-SD.svg");
      else if (format.toLowerCase() === "hd")
        sources.push("./assets/svg/Icon-MediaType-HD.svg");
      else if (format.toLowerCase() === "4k")
        sources.push("./assets/svg/Icon-MediaType-4K.svg");
      else if (format.toLowerCase() === "audio")
        sources.push("./assets/svg/Icon-MediaType-Audio.svg");
    }

    if (audioFormat != null) {
      if (audioFormat.toLowerCase() === "5.1")
        sources.push("./assets/svg/Icon-MediaType-5.1.svg");
      else if (audioFormat.toLowerCase() === "stereo")
        sources.push("./assets/svg/Icon-MediaType-Stereo.svg");
    }

    if (spotTrac)
      sources.push("./assets/svg/Icon-MediaType-ST.svg");

    if (veilEncode)
      sources.push("./assets/svg/Icon-MediaType-VEIL.svg");

    if (subtitles)
      sources.push("./assets/svg/Icon-MediaType-Subtitles.svg");

    if (centerCutProtected)
      sources.push("./assets/svg/Icon-MediaType-CCP.svg");

    if (descriptiveVideo)
      sources.push("./assets/svg/Icon-MediaType-DVS.svg");

    if (broadcast)
      sources.push("./assets/svg/Icon-MediaType-Broadcast.svg");

    if (web)
      sources.push("./assets/svg/Icon-MediaType-Web.svg");

    if (generic)
      sources.push("./assets/svg/Icon-MediaType-Generic.svg");

    if (runningFootage)
      sources.push("./assets/svg/Icon-MediaType-RunningFootage2.svg");

    if (taggable)
      sources.push("./assets/svg/Icon-MediaType-Taggable.svg");

    if (cc)
      sources.push("./assets/svg/Icon-MediaType-ClosedCaption.svg")

    if (sap)
      sources.push("./assets/svg/Icon-MediaType-Sap.svg")

    if (vchip)
      sources.push("./assets/svg/Icon-MediaType-Vchip.svg")

    if (letterbox)
      sources.push("./assets/svg/Icon-MediaType-LetterBox.svg")

    if (mono)
      sources.push("./assets/svg/Icon-MediaType-Mono.svg")

    if (surround)
      sources.push("./assets/svg/Icon-MediaType-Surround.svg")

    return sources;
  }

  public getAllIconClasses(format: string, audioFormat: string, spotTrac: boolean, veilEncode: boolean,
    subtitles: boolean, centerCutProtected: boolean, descriptiveVideo: boolean,
    broadcast: boolean, web: boolean, taggable: boolean, generic: boolean, runningFootage: boolean, cc: boolean,
    sap: boolean, vchip: boolean, letterbox: boolean, mono: boolean, surround: boolean): Array<string> {
    var sources = new Array<string>();

    if (format != null) {
      if (format.toLowerCase() === "sd")
        sources.push("format-sd");
      else if (format.toLowerCase() === "hd")
        sources.push("format-hd");
      else if (format.toLowerCase() === "4k")
        sources.push("format-4k");
      else if (format.toLowerCase() === "audio")
        sources.push("format-audio");
    }

    if (audioFormat != null) {
      if (audioFormat.toLowerCase() === "5.1")
        sources.push("format-5dot1");
      else if (audioFormat.toLowerCase() === "stereo")
        sources.push("format-stereo");
    }

    if (spotTrac)
      sources.push("format-st");

    if (veilEncode)
      sources.push("format-veil");

    if (subtitles)
      sources.push("format-subtitles");

    if (centerCutProtected)
      sources.push("format-ccp");

    if (descriptiveVideo)
      sources.push("format-dvs");

    if (broadcast)
      sources.push("format-brd");

    if (web)
      sources.push("format-web");

    if (generic)
      sources.push("format-gnc");

    if (runningFootage)
      sources.push("format-rft");

    if (taggable)
      sources.push("format-tag");

    if (cc)
      sources.push("format-cc");

    if (sap)
      sources.push("format-sap");

    if (vchip)
      sources.push("format-vchip");

    if (letterbox)
      sources.push("format-ltbx");

    if (mono)
      sources.push("format-mono");

    if (surround)
      sources.push("format-surround");

    return sources;
  }

  public addError(errors: Array<string>, err: string): void {
    var index = errors.findIndex(item => item.toLowerCase() == err.toLowerCase());
    if (index < 0)
      errors.push(err)
  }

  public removeError(errors: Array<string>, err: string): Array<string> {
    return errors.filter(item => item.toLowerCase() != err.toLowerCase());
  }

  public removeErrorStartingWith(errors: Array<string>, err: string): Array<string> {
    return errors.filter(item => !item.toLowerCase().includes(err.toLowerCase()));
  }

  public downloadFile(path: string): Observable<Blob> {
    var header = new HttpHeaders();
    header = header.append("Skip-Token", "skip");

    return this.http.get(path, { headers: header, responseType: 'blob' as 'json' }).pipe(
      map((response: any) => response));
  }

  public convertStringToObjectMultiple(val: string): Array<ServiceMetadataObjectOption> {

    let arr: Array<ServiceMetadataObjectOption> = [];

    if (val == null || val == '')
      return arr;

    var firstSplit: Array<string> = val.split('||');

    if (firstSplit.length > 0) {
      firstSplit.forEach(t => {
        var secondSplit: Array<string> = t.split('|');
        if (secondSplit.length == 2)
          arr.push({ val1: secondSplit[0], val2: secondSplit[1] })
      });
    }
    else {
      var secondSplit: Array<string> = val.split('|');
      if (secondSplit.length == 2)
        arr.push({ val1: secondSplit[0], val2: secondSplit[1] })
    }
    
    return arr;
  }

  public convertStringToThreeRequiredObject(val: string): Array<ServiceBAMetadataObjectOption> {

    let arr: Array<ServiceBAMetadataObjectOption> = [];

    if (val == null || val == '')
      return arr;

    var firstSplit: Array<string> = val.split('||');

    if (firstSplit.length > 0) {
      firstSplit.forEach(t => {
        var secondSplit: Array<string> = t.split('|');
        if (secondSplit.length == 3)
          arr.push({ val1: secondSplit[0], val2: secondSplit[1], val3: secondSplit[2]})
      });
    }
    else {
      var secondSplit: Array<string> = val.split('|');
      if (secondSplit.length == 3)
        arr.push({ val1: secondSplit[0], val2: secondSplit[1], val3: secondSplit[2] })
    }

    return arr;
  }

  public convertStringToFourRequiredObject(val: string): Array<ServiceBAGuaranteeMetadataObjectOption> {

    let arr: Array<ServiceBAGuaranteeMetadataObjectOption> = [];

    if (val == null || val == '')
      return arr;

    var firstSplit: Array<string> = val.split('||');

    if (firstSplit.length > 0) {
      firstSplit.forEach(t => {
        var secondSplit: Array<string> = t.split('|');
        if (secondSplit.length == 4)
          arr.push({ val1: secondSplit[0], val2: secondSplit[1], val3: secondSplit[2], val4: secondSplit[3] })
      });
    }
    else {
      var secondSplit: Array<string> = val.split('|');
      if (secondSplit.length == 4)
        arr.push({ val1: secondSplit[0], val2: secondSplit[1], val3: secondSplit[2], val4: secondSplit[3] })
    }

    return arr;
  }

  public isServiceMetadataValid(serviceCode: string, metadata: Array<ServiceMetadata>, isOrderLevel: boolean, isGroupLevel: boolean, isSpotLevel: boolean, isAudio: boolean, allowSpecialOptions: boolean): boolean {
    switch (serviceCode.toLowerCase()) {
      case "dvs": {
        return (this.isAllRequiredMetadataKeysExists(['voice'], metadata)
          && this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata));
      }
      case "reappdvs": {
        return (this.isAllRequiredMetadataKeysExists(['notes'], metadata));
      }
      case "cap": {
        return (this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata));
      }
      case "cci": {
        return (this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata));
      }
      case "reappcap": {
        return (this.isAllRequiredMetadataKeysExists(['notes'], metadata));
      }
      case "wcp": {
        return (this.isAllRequiredMetadataKeysExists(['fileType'], metadata)
          && this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata)); //fileTypeCustom
      }
      case "wbv": {
        var bvsSubmittedExists = this.isAllRequiredMetadataKeysExists(['bvsSubmitted'], metadata);
        if (bvsSubmittedExists == false)
          return false;
        else if (this.getMetadataKeyValue("bvsSubmitted", metadata) == "Yes")
          return (this.isAllRequiredMetadataKeysExists(['bvsForm'], metadata));
        else
          return true;
      }
      case "sub": {
        return this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata);
      }
      case "ded": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['totalMins'], metadata));
        else if (isSpotLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['editNotes'], metadata));

        return true;
      }
      case "stc": {
        return (this.isAllRequiredMetadataKeysExists(['standard', 'title'], metadata));
      }
      case "slt": {

        if (this.getMetadataKeyValue("reslate", metadata) == "true")
          return isAudio ? (this.isAllRequiredMetadataKeysExists(['title', 'adid', 'advertiser', 'brand', 'product', 'date'], metadata)) : (this.isAllRequiredMetadataKeysExists(['title', 'adid'], metadata));
        else if (this.getMetadataKeyValue("removeSlate", metadata) == "true")
          return true;
        else
          return false;
      }
      case "wst": {
        return (this.isAllRequiredMetadataKeysExists(['clientId'], metadata));
      }
      case "vco": {
        if (isSpotLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['language', 'voice', 'title'], metadata)
            && this.isOneOfMetadataKeysExists('scriptFile', 'scriptText', metadata)); //languageCustom
        else if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['totalMins'], metadata));

        return true;
      }
      case "tbi": {
        return (this.isAllRequiredMetadataKeysExists(['Timecode'], metadata));
      }
      case "tar": {
        return (this.isAllRequiredMetadataKeysExists(['format'], metadata));
      }
      case "trs": {
        return (this.isAllRequiredMetadataKeysExists(['timecodeNeeded', 'language', 'fileType'], metadata)) //languageCustom
      }
      case "itrs": {
        return (this.isAllRequiredMetadataKeysExists(['timecodeNeeded', 'language', 'fileType'], metadata)) //languageCustom
      }
      case "ovt": {
        return (this.isAllRequiredMetadataKeysExists(['overtime'], metadata));
      }
      case "icf": {
        return (this.isAllRequiredMetadataKeysExists(['quantity', 'vendor'], metadata));
      }
      case "ioms": {
        return (this.isAllRequiredMetadataKeysExists(['quantity'], metadata));
      }
      case "tfd": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['thumbflashdrivequantity'], metadata));
        else if (isSpotLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['address'], metadata));

        return true;
      }
      case "hdf": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['quantity'], metadata));

        return true;
      }
      case "hrd": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['quantity'], metadata));
        else if (isSpotLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['address'], metadata));

        return true;
      }
      case "exphandle": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['quantity'], metadata));

        return true;
      }
      case "btd": {
        if (isSpotLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['address'], metadata));

        return true;
      }
      case "vit": {
        var specExists = this.isAllRequiredMetadataKeysExists(['spec'], metadata);

        if (specExists == true)
          return true;
        else {

          var fileType = this.getMetadataKeyValue("fileType", metadata);
          return this.isAllRequiredMetadataKeysExists(['fileType', 'codec', 'resolution', 'slate', 'framerate'], metadata)
            || this.isAllRequiredMetadataKeysExists(['comments'], metadata)
          //if (fileType != null && fileType.trim().toUpperCase() == 'UNCOMPRESSED')
          //  return true;
          //else
          //  return this.isAllRequiredMetadataKeysExists(['fileType', 'codec', 'bitrate', 'resolution', 'slate', 'framerate'], metadata)
        }

        //return (specExists == true || this.isAllRequiredMetadataKeysExists(['fileType', 'codec', 'bitrate', 'resolution', 'slate', 'framerate'], metadata));
      }
      case "ins": {
        return (this.isAllRequiredMetadataKeysExists(['noOfIns'], metadata));
      }
      case "bcc": {
        if (allowSpecialOptions)
          return (this.isAllRequiredMetadataKeysExists(['vendor'], metadata));
        else
          return true;
      }
      case "bcd": {
        if (allowSpecialOptions)
          return (this.isAllRequiredMetadataKeysExists(['vendor'], metadata));
        else
          return true;
      }
      case "bci": {
        if (allowSpecialOptions)
          return (this.isAllRequiredMetadataKeysExists(['vendor'], metadata));
        else
          return true;
      }
      case "bcf": {
        return (this.isAllRequiredMetadataKeysExists(['totalMins'], metadata));
      }
      case "aea": {
        return (this.isAllRequiredMetadataKeysExists(['comments', 'adjustamount'], metadata));
      }
      case "dtr": {
        if (isOrderLevel == true)
          return (this.isAllRequiredMetadataKeysExists(['minstransfers'], metadata));

        return true;
      }
      case "trf": {
        return (this.isAllRequiredMetadataKeysExists(['trafficFile'], metadata));
      }
      case "bms": {
        return (this.isAllRequiredMetadataKeysExists(['billboardFile'], metadata));
      }
      case "ait": {
        return (this.isAllRequiredMetadataKeysExists(['spec'], metadata));
      }
      case "iti": {
        return (this.isAllRequiredMetadataKeysExists(['vendor'], metadata));
      }
      case "ddi":
        {
          return (this.isAllRequiredMetadataKeysExists(['referenceId'], metadata));
        }
      case "bagp": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "bahf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "klhsf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "balf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "baoth": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "pasf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "batm": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "bactf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "trfdctf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "cpp": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "sfoth": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "tpp": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "pmf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "dist": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "distsf": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "distsoth": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "mup": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "othinc": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "csdev": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "gac": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      case "trfs": {
        return (this.isAllRequiredMetadataKeysExists(['passthroughdata'], metadata));
      }
      default: {
        return true;
      }
    }
  }

  public isAllRequiredMetadataKeysExists(metadataKeys: Array<string>, metadata: Array<ServiceMetadata>, onlyOne: boolean = false): boolean {

    if (metadata == null || metadata.length == 0)
      return false;

    var isValid = true;

    metadataKeys.forEach(mKey => {
      if (onlyOne == true) {
        var matchedItems = metadata.filter(t => t.metadataKey.toLowerCase() == mKey.toLowerCase());

        if (matchedItems.length != 1)
          isValid = false;
        else if (this.isMetadataValueValid(matchedItems[0]) == false)
          isValid = false;
      }
      else {
        var ind = metadata.findIndex(t => t.metadataKey.toLowerCase() == mKey.toLowerCase());

        if (ind == -1)
          isValid = false;
        else if (this.isMetadataValueValid(metadata[ind]) == false)
          isValid = false;
      }
    });

    return isValid;
  }

  public isMetadataValueValid(mdata: ServiceMetadata): boolean {
    switch (mdata.category) {
      case metadataCategoryEnum[metadataCategoryEnum.File]: {
        return (mdata.fileValue != null && mdata.fileValue.orderFileGuid != null && mdata.fileValue.orderFileGuid.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.BigText]: {
        return (mdata.textValue != null && mdata.textValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.CopyText]: {
        return (mdata.textValue != null && mdata.textValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.Text]: {
        return (mdata.textValue != null && mdata.textValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.Dollar]: {
        return (mdata.textValue != null && mdata.textValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.HoursMins]: {
        return (mdata.textValue != null && mdata.textValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.Multiple]: {
        return (mdata.arrayValue != null && mdata.arrayValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.BigMultiple]: {
        return (mdata.arrayValue != null && mdata.arrayValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.ObjectMultiple]: {
        return (mdata.objectValue != null && mdata.objectValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired]: {
        return (mdata.objectValue != null && mdata.objectValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired]: {
        return (mdata.objectValue != null && mdata.objectValue.trim() != '')
      }
      case metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleMinsTransfers]: {
        return (mdata.objectValue != null && mdata.objectValue.trim() != '')
      }
      default: {
        return true;
      }
    }
  }

  public isOneOfMetadataKeysExists(metadataKey1: string, metadataKey2: string, metadata: Array<ServiceMetadata>): boolean {

    var mdata1 = metadata.find(t => t.metadataKey.toLowerCase() == metadataKey1.toLowerCase());
    var mdata2 = metadata.find(t => t.metadataKey.toLowerCase() == metadataKey2.toLowerCase());

    if (mdata1 == null && mdata2 == null)
      return false;

    if (mdata1 != null && this.isMetadataValueValid(mdata1) == false)
      return false;

    if (mdata2 != null && this.isMetadataValueValid(mdata2) == false)
      return false;

    return true;
  }

  public getMetadataKeyValue(metadataKey: string, metadata: Array<ServiceMetadata>): string {
    var mdata = metadata.find(t => t.metadataKey.toLowerCase() == metadataKey.toLowerCase());

    return mdata == null ? '' : mdata.textValue;
  }

  public notEmpty<TValue>(value: TValue | null | undefined | string): value is TValue {
    return value !== null && value !== undefined && value.toString().trim() != "";
  }

  //public showOrderDetailInNewWindow(orderGuid: string) {
  //  if (orderGuid == "00000000-0000-0000-0000-000000000000")
  //    return;

  //  let url = this.router.serializeUrl(this.router.createUrlTree([RouteConstants.orderDetailRoute, orderGuid]));
  //  //let url = this.router.createUrlTree(['/orders/detail'], { queryParams: { orderGuid } });

  //  //const url = this.router.serializeUrl(this.router.createUrlTree([`/orders/detail/${orderGuid}`]));

  //  //alert(url);

  //  window.open(url.toString(), "_blank");
  //}

  public showOrderDetailInNewWindow(orderGuid: string) {
    if (orderGuid == "00000000-0000-0000-0000-000000000000")
      return;

    //let url = this.router.serializeUrl(this.router.createUrlTree([RouteConstants.orderDetailRoute, orderGuid]));
    //let url = this.router.createUrlTree(['/orders/detail'], { queryParams: { orderGuid } });

    const url = this.router.serializeUrl(this.router.createUrlTree([`/orders`]));

    //alert(url);
    // this.router.navigate([]).then(result => { window.open(`/orders/detail/${orderGuid}`, '_blank'); });

    let newRelativeUrl = this.router.createUrlTree([RouteConstants.orderDetailRoute, orderGuid]);
    let baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');

    //window.open(url.toString(), "_blank");
  }


  public firstordefault(arr, predicate) {
    var itemIndex = arr.findIndex(predicate);

    return itemIndex !== -1 ? arr[itemIndex] : null;
  }

  public findindex(arr, predicate) {
    return arr.findIndex(predicate);
  }

  public isDestinationAddlInfoValid(fileDeliveryType: string, metadata: Array<DestinationAddlInfoMetadata>): boolean {
    switch (fileDeliveryType.toLowerCase()) {
      case "innovid": {
        return (this.isAllRequiredAddlInfoExists(['campaign'], metadata));
      }
      default: {
        return true;
      }
    }
  }

  public isAllRequiredAddlInfoExists(metadataKeys: Array<string>, metadata: Array<DestinationAddlInfoMetadata>): boolean {

    if (metadata == null || metadata.length == 0)
      return false;

    var isValid = true;

    metadataKeys.forEach(mKey => {
      var ind = metadata.findIndex(t => t.metadataKey.toLowerCase() == mKey.toLowerCase());

      if (ind == -1)
        isValid = false;
      else if (this.isAddlInfoValueValid(metadata[ind]) == false)
        isValid = false;
    });

    return isValid;
  }

  public isAddlInfoValueValid(mdata: DestinationAddlInfoMetadata): boolean {
    return (mdata.metadataValue != null && mdata.metadataValue.trim() != '');
  }
}
