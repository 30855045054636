import { Component } from "@angular/core";
import { BaseServiceDataCollectionComponent } from "../service-basedatacollection-metadata/servicebasedatacollection.component";

@Component({
  selector: 'service-noadditionaldata',
  templateUrl: './servicenoadditionaldata.component.html'
})
export class ServiceNoAdditionalDataComponent extends BaseServiceDataCollectionComponent {

}
