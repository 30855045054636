import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { Observable } from "rxjs";

import { PlayListFile } from '../../models/playlist/playlist-file.model';
import { PlayListFileNew } from "../../models/playlist/playlist-file-new.model";

@Injectable()
export class PlayListService {

  constructor(private http: HttpClient,
    private apiendPoint: ApiEndPoint) { }

  public getPlayLists(): Observable<Object> {
    return this.http.get(this.apiendPoint.getPlayListsEndpoint());
  }

  public deletePlayList(id: string) {
    return this.http.delete(this.apiendPoint.deletePlayListEndpoint() + "/" + id);
  }

  public getPlayListFiles(id: string) {
    return this.http.get(this.apiendPoint.getPlayListFilesEndpoint() + "/" + id);
  }

  public addToPlayList(id: string, files: PlayListFileNew[]) {
    return this.http.put(this.apiendPoint.addToPlayListEndpoint() + "/" + id, files);
  }

  public removeFromPlayList(id: string, spotFiles: string[]) {
    return this.http.put(this.apiendPoint.removeFromPlayListEndpoint() + "/" + id, spotFiles);
  }

  public shufflePlayList(id: string, files: PlayListFile[]) {
    return this.http.put(this.apiendPoint.shufflePlayListEndpoint() + "/" + id, files);
  }

  public createPlayList(request: any) {
    return this.http.put(this.apiendPoint.createPlayListEndpoint(), request);
  }
}
