import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "../../../services/core/utilities";
import { UserRoles } from "../../../configurations/user-roles";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { QuickplayerComponent } from "../../videoplayer/quickplayer/quickplayer.component";
import { Constants } from "../../../configurations/constants";
import { DownloadMediaService } from "../../../services/media/media-download.service";
import { RouteConstants } from "../../../configurations/route-constants";

@Component({
  selector: "app-tagger-featured",
  templateUrl: "./tagger-featured.component.html",
  styleUrls: ["./tagger-featured.component.css"],
})
export class TaggerFeaturedComponent implements OnInit {
  public featuredCreatives = [];
  public modalRef: BsModalRef;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private taggerService: TaggerService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private ds: DownloadMediaService,
  ) { }

  ngOnInit() {
    this.loadFeaturedAssets();
  }

  public loadFeaturedAssets() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.taggerService.loadFeaturedAssets().subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.featuredCreatives = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.alertService.ShowLoader(false);
      }
    );
  }

  public quickPlay(proxy, title, thumbnail, format) {
    alert(proxy);
    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public goToCreativeAndTags() {
    this.router.navigate([RouteConstants.creativeTagsRoute]);
  }
}
