import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { DxTreeViewComponent } from 'devextreme-angular';

@Component({
  selector: 'customtagcontrols',
  templateUrl: './customtagcontrols.component.html',
  styleUrls: ['./customtagcontrols.component.css'],
})
export class CustomTagControlsComponent implements OnInit {

  @Input('controlName') controlName: string = null;
  @Input('controlType') controlType: string = null;
  @Input('ctrlGrp') ctrlGrp: FormGroup;
  @Input('itemsDataSource') itemsDataSource: string[] = [];
  @Input('arrayName') arrayName: string = null;


  @ViewChild('tagValueDropDown', { static: false }) tagValueDropDown: DxTreeViewComponent;

  public tagsValue: Array<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService) {

  }

  ngOnInit() {

  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.tagValueDropDown && this.tagValueDropDown.instance);

    if (!component) return;

    if (this.tagsValue == null || !this.tagsValue.length) {
      component.unselectAll();
    }

    if (this.tagsValue != null) {
      this.tagsValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  public treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<string>;
    this.tagsValue = newValues;
  }
}
