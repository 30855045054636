import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable  } from "rxjs";
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { RightsManagementSearchResult } from "../../models/spot/rightsmanagement.model";

@Injectable()

export class RightsManagementService {
    constructor(private http: HttpClient,
        private apiEndPoint: ApiEndPoint) {
    }

    public SearchRightsManagement(searchRequest: any): Observable<any> {
        return this.http.post<any>(`${this.apiEndPoint.searchRightManagement()}`, searchRequest);
    }

}
