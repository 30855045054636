import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { TruncateByLetters } from "../../../pipes/truncateByLetters.pipe";
import { Utilities } from '../../../services/core/utilities';
import { SpotService } from '../../../services/media/spot.service';
import { BasePopupComponent } from '../../popups/basepopup/basepopup.component';

@Component({
  selector: 'quickplayer',
  templateUrl: './quickplayer.component.html',
  styleUrls: ['./quickplayer.component.css']
})

export class QuickplayerComponent extends BasePopupComponent {

  @Input('videoSource') videoSource: string;
  @Input('thumbnail') thumbnail: string;
  @Input('title') title: string;
  @Input('format') format: string;


  public proxyUrl: string = null;
  public overyLayHeightQ: number = 0;
  public is169OverlayRequired: boolean = false;
  public is430verlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;
  @ViewChild('videoPlayerQ', { static: false }) videoPlayerQ: ElementRef;
  playerWidthClass: string = '';
  videoPlayerEle: HTMLVideoElement;
  playerWidth: 0;

  //private videoPlayerQ: HTMLVideoElement;

  //@ViewChild('videoPlayerQ')
  //set mainVideoEl(el: ElementRef) {
  //  this.videoPlayerQ = el.nativeElement;
  //}

  constructor(ngZone: NgZone, public bsModalRef: BsModalRef, public util: Utilities, public spotService: SpotService) {

    super();

    window.onresize = (e) => {
      ngZone.run(() => {
        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {

    super.ngOnInit();

    this.is169OverlayRequired = this.util.is169OverlayRequired(this.format);
    this.is430verlayRequired = this.util.is430verlayRequired(this.format);

    this.getProxyUrl();
  }

  onVideoPlayerLoaded() {
    this.videoPlayerEle = document.getElementById('main-video') as HTMLVideoElement;
    this.updatePlayerWidthClass();
  }

  updatePlayerWidthClass() {
    // Your logic to update the playerWidthClass based on updatePlayerWidth() value
    const width = this.updatePlayerWidth(); // Replace this with your actual function to get the width
    this.playerWidthClass = this.getPlayerWidthClass(width);
  }

  getPlayerWidthClass(width: number): string {
    if (width >= 1280) {
      return 'col-md-12';
    } else if (width >= 1008 && width < 1280) {
      return 'col-md-8';
    } else {
      return 'col-md-8';
    }
  }

  updatePlayerWidth(): number {
    if (this.videoPlayerQ && this.videoPlayerQ.nativeElement) {
      const player = this.videoPlayerQ.nativeElement;
      this.playerWidth = player.offsetWidth;
      return player.offsetWidth;
    }
    return 0; // Default width if the video player is not available
  }

  private getProxyUrl() {

    this.spotService.getProxyUrl({ objectKey: this.videoSource }).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.proxyUrl = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.closePopup();
      }

    },
      error => {
        this.util.handleError(error);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public toggleOverlayQ(overlayType: string) {

    if (overlayType == '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay
    }
    else {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay
    }

    this.overyLayHeightQ = this.videoPlayerQ.nativeElement.offsetHeight;
  }

  public onPlayerMouseOver(e) {
    //var old169 = this.isOver169Overlay;
    //var old43 = this.isOver43Overlay;
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;

    //const video: HTMLVideoElement = this.videoPlayerQ.nativeElement;
    //video.controls = true;

    //this.videoPlayerQ.nativeElement.controls = true;
    //this.isOver169Overlay = old169;
    //this.isOver43Overlay = old43;
  }
}
