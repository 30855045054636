<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">CUSTOM TAGS</h4>
  </div>
  <div class="modal-body">
    <h3 class="tylie-type-h3" *ngIf="selectedBrandsDataSource.length > 0 || brandName">
      Custom Tags would be applied to the selected brands.
    </h3>
    <div *ngIf="brandName" class="row">
      <div class="col-md-4">
        <div class="tylie-form-group form-group">
          <label class="tylie-form-group__lbl">Brand</label>
          <div class="tylie-form-group__txt">{{brandName}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedBrandsDataSource.length > 0 || brandName">
      <form [formGroup]="customTagConfigForm" (submit)="onaddCustomTagConfigFormFormSubmit(customTagConfigForm)" novalidate #ngForm="ngForm">
        <div class="row" *ngIf="selectedBrandsDataSource.length > 0">
          <div class="col-md-6">
            <div class="tylie-form-group form-group" [ngClass]="{error: (brandsNotSelected || (customTagConfigForm.controls['selectedBrandsForCustomTags'].errors && (!customTagConfigForm.controls['selectedBrandsForCustomTags'].pristine || ngForm.submitted)))}">
              <label class="tylie-form-group__lbl" for="selectedBrandsForCustomTags">Brands<span class="required">*</span></label>
              <dx-drop-down-box [(value)]="brandsValue"
                                formControlName="selectedBrandsForCustomTags"
                                id="selectedBrandsForCustomTags"
                                class="tylie-select form-control"
                                valueExpr="brandId"
                                displayExpr="brandName"
                                placeholder="Select Brands"
                                [showClearButton]="true"
                                (onValueChanged)="syncTreeViewSelection()"
                                [dataSource]="selectedBrandsDataSource">
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view [dataSource]="selectedBrandsDataSource"
                                dataStructure="plain"
                                keyExpr="brandId"
                                #treeBrands
                                selectionMode="multiple"
                                showCheckBoxesMode="normal"
                                displayExpr="brandName"
                                [selectByClick]="true"
                                (onContentReady)="syncTreeViewSelection($event)"
                                (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
              <p class="p-extra-small">Please select Brands</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="customTagDataTypes.length > 0">
          <div formArrayName="customTagConfigurations" *ngFor="let ctc of getFormCustomTagConfigGroupsFormArray.controls; let i = index">
            <customtagconfiggroup [ctcGroup]="ctc"
                                  [groupName]="i"
                                  [parentForm]="ngForm"
                                  [formGroupcount]="customTagConfigForm.get('customTagConfigurations').controls?.length"
                                  [customTagDataTypes]="this.customTagDataTypes"
                                  (onTagValueChangedEventTriggered)="verifyUniqueTags($event)"
                                  (onClickRemoveCustomTagEventTriggered)="onClickRemoveCustomTag($event)">

            </customtagconfiggroup>
          </div>
        </ng-container>

        <div class="d-flex justify-content-end my-3" *ngIf="getFormCustomTagConfigGroupsFormArray.length < noOfCustomTagsAllowed">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon tylie-button--link" (click)="onAddAnotherCustomTag()">
            <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
            ADD CUSTOM TAG
          </button>
        </div>

        <div class="d-flex justify-content-end">
          <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
          <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="closePopup()">Cancel</button>
        </div>
      </form>
    </div>

    <div *ngIf="(isBrandsNotSelected == false && (selectedBrandsDataSource.length == 0 && brandName == null))">
      <span class="p-small">All the brands associated with this client have custom tags, Please Add / Edit custom tags for the respective brand.</span>
    </div>

    <div *ngIf="isBrandsNotSelected == true">
      <span class="p-small">Please select brands in the section below to add custom tags.</span>
    </div>
  </div>
</div>
