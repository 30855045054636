<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Storage Management Fee Settings</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedBrandsDataSource.length > 0">
      <span class="p-small">Storage Management Fee Settings would be applied to the brands if selected or would be applied to client.</span>
      <p>If a brand is selected any settings at client level will be removed and vice-versa.</p>
    </div>
    <div *ngIf="brandName" class="row">
      <div class="col-md-4">
        <label>Brand</label>
        <p>{{brandName}}</p>
      </div>
    </div>
  
    <form [formGroup]="storageManagementFeeSettingsForm" (submit)="onSaveStorageManagementFeeBillingSettingsFormSubmit(storageManagementFeeSettingsForm)" novalidate #ngForm="ngForm">
      <div class="row">
        <div class="col" *ngIf="selectedBrandsDataSource.length > 0">
          <div class="tylie-form-group form-group" >
            <label class="tylie-form-group__lbl" for="selectedBrandsForStorageManagementFee">Brands<span class="required">*</span></label>
            <dx-drop-down-box [(value)]="brandsValue"
                              formControlName="selectedBrandsForStorageManagementFee"
                              id="selectedBrandsForStorageManagementFee"
                              class="tylie-select form-control"
                              valueExpr="brandId"
                              displayExpr="brandName"
                              placeholder="Select Brands"
                              [showClearButton]="true"
                              (onValueChanged)="syncTreeViewSelection()"
                              [dataSource]="selectedBrandsDataSource">
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view [dataSource]="selectedBrandsDataSource"
                              dataStructure="plain"
                              keyExpr="brandId"
                              #treeBrands
                              selectionMode="multiple"
                              showCheckBoxesMode="normal"
                              displayExpr="brandName"
                              [selectByClick]="true"
                              (onContentReady)="syncTreeViewSelection($event)"
                              (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
            <p class="p-extra-small">Please select Brands</p>
          </div>
        </div>
        <div class="col" id="billingCycle">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (storageManagementFeeSettingsForm.controls['billingCycle'].errors
               && (!storageManagementFeeSettingsForm.controls['billingCycle'].pristine || ngForm.submitted))}">
            <label class="tylie-form-group__lbl" for="dataType">Billing Frequency<span class="required">*</span></label>
            <dx-select-box [dataSource]="billingFreqDataSource"
                           name="billingCycle" id="billingCycle" class="tylie-select form-control"
                           formControlName="billingCycle"
                           [searchEnabled]="true"
                           valueExpr="name"
                           displayExpr="name"
                           placeholder="Billing Frequency">
            </dx-select-box>
            <p class="p-extra-small">Please select Billing Frequency</p>
          </div>
        </div>

        <div class="col">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="ct">Next Billing Cycle </label>
            <dx-date-box type="date" width="100%" formControlName="nextBillingCycle" id="ct"
                displayFormat="MM/dd/yyyy" class="tylie-datepicker form-control" [showClearButton]="true" [min]="now"></dx-date-box>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4" id="purchaseOrder">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (storageManagementFeeSettingsForm.controls['purchaseOrder'].errors
               && (!storageManagementFeeSettingsForm.controls['purchaseOrder'].pristine || ngForm.submitted))}">
            <label for="purchaseOrder" class="tylie-form-group__lbl">Purchase Order Number<span class="required">*</span></label>
            <input type="text" id="purchaseOrder" name="purchaseOrder" class="tylie-text form-control" formControlName="purchaseOrder">
          </div>
        </div>
        <div class="col-md-4" id="jobNumber">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (storageManagementFeeSettingsForm.controls['jobNumber'].errors
               && (!storageManagementFeeSettingsForm.controls['jobNumber'].pristine || ngForm.submitted))}">
            <label for="jobNumber" class="tylie-form-group__lbl">Job Number<span class="required">*</span></label>
            <input type="text" id="jobNumber" name="jobNumber" class="tylie-text form-control" formControlName="jobNumber">
          </div>
        </div>
        <div class="col-md-4" id="orderedBy">
          <div class="tylie-form-group form-group"
               [ngClass]="{error: (storageManagementFeeSettingsForm.controls['orderedBy'].errors
               && (!storageManagementFeeSettingsForm.controls['orderedBy'].pristine || ngForm.submitted))}">
            <label for="orderedBy" class="tylie-form-group__lbl">Ordered By<span class="required">*</span></label>
            <input type="text" id="orderedBy" name="orderedBy" class="tylie-text form-control" formControlName="orderedBy">
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
