import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-arrow-simple-right',
  templateUrl: './icon-arrow-simple-right.component.html',
  styleUrls: ['./icon-arrow-simple-right.component.css']
})
export class IconArrowSimpleRightComponent implements OnInit {
  @Input() height: string='20px';
  @Input() width: string='20px';
  @Input() color: string='#f5f5f5';
  constructor() { }

  ngOnInit() {
  }

}
