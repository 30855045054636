import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { customTimeCodeValidator, customTimeValidator } from '../../../../configurations/custom-validations';
import { Vaultassetlineitem } from '../../../../models/vault/vault-create.model';
import { AlertService } from '../../../../services/core/alert.service';
import { ConfigService } from '../../../../services/core/config.service';
import { Utilities } from '../../../../services/core/utilities';
import { DownloadMediaService } from '../../../../services/media/media-download.service';
import { VaultService } from '../../../../services/vault/vault.service';

@Component({
  selector: 'vault-request-carton',
  templateUrl: './vault-request-cartons.component.html',
  styleUrls: ['./vault-request-cartons.component.css']
})
export class VaultRequestCartonComponent implements OnInit {

  @Input('vaultRequestGuid') vaultRequestGuid: string;
  @Input() carton: FormGroup;
  @Input() cartonSequence: number;
  @Input() formatDataSource: any[];
  @Input() triedToSubmitRequest: boolean;
  @Input() totalCartons: number;
  @Input() isStagingRequest: boolean;
  
  @Output() onDeleteCartonEvent: EventEmitter<number> = new EventEmitter<number>();

  public assetlineitems: Array<Vaultassetlineitem>;
  public vaultRequestDetail: any;


  constructor(
    private vaultService: VaultService,
    public util: Utilities,
    public modalService: BsModalService,
    private alertService: AlertService,
    private configService: ConfigService,
    private ds: DownloadMediaService,
    private router: Router,
    private _fb: FormBuilder) { }

  ngOnInit() {
    console.log(this.carton);
    console.log(this.isStagingRequest);
  }

  public get assets() {
    return <FormArray>this.carton.get('assets');
  }

  private get addNewAsset() {
    var assetFb = this._fb.group({
      vaultAssetStatus: [null],
      assetFormatName: [null],
      assetFormat: [null, Validators.required],
      assetBarcode: [null, Validators.required],
      assetDescription: [null],
      assetLineItemData: this.newAssetLineItemArrayForm,
    });

    return assetFb;
  }

  private get newAssetLineItemArrayForm() {
    var assetsLineAssets: FormArray = this._fb.array([]);

    var assetLineFb = this._fb.group({
      adId: [null, Validators.required],
      title: [null, Validators.required],
      length: [null, Validators.compose([customTimeValidator])],
    });

    assetsLineAssets.push(assetLineFb);

    return assetsLineAssets as FormArray;
  }

  public onAddNewAsset() {
    this.assets.push(this.addNewAsset);
  }

  public onAddNewAssetLine(assetIndex) {
    var assetLineItems = this.assets.at(assetIndex).get('assetLineItemData') as FormArray;
    var seqNumbers = assetLineItems.value.map(t => t.assetLineSequenceId) as number[];
    var seq = seqNumbers.sort((a, b) => (a - b)).reverse()[0] + 1;

    var assetLineFb = this._fb.group({
      assetLineSequenceId: [null],
      adId: [null, Validators.required],
      title: [null, Validators.required],
      length: [null, Validators.compose([customTimeValidator])],
    });

    assetLineFb.controls['assetLineSequenceId'].setValue(seq);

    assetLineItems.push(assetLineFb);
  }

  public onDeleteAssetLineItem(assetIndex, assetLineIndex) {
    var assetLineItems = this.assets.at(assetIndex).get('assetLineItemData') as FormArray;
    assetLineItems.removeAt(assetLineIndex);
  }

  public onDeleteAsset(assetIndex) { 
    this.assets.removeAt(assetIndex);
  }

  public onDeleteCarton() {
    this.onDeleteCartonEvent.emit(this.cartonSequence);
  }
}
