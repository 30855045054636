import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'destination-baseinfo',
  templateUrl: './destinationbaseinfo.component.html'
})
export class BaseDestinationInfoComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() f: any;

  constructor() {
  }

  ngOnInit() {
  }
}
