<div id="saved-destinations">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <section class="tylie-input-search">
          <div class="tylie-input-search__inner">
            <div class="tylie-input-search__icon"><icon-search></icon-search></div>
            <input class="tylie-input-search__text" placeholder="Search By Name or Destination ID or Aka"
                 name="srch-term" id="srch-term" type="search" (keyup.enter)="onQuickSearchDestinations()"
                 [(ngModel)]="quickSearchValue" (ngModelChange)="onClearQuickSearch()">
          </div>
          <div class="tylie-input-search__btn">
            <button class="tylie-button tylie-button--sm" type="button" (click)="onQuickSearchDestinations()">Search</button>
          </div>
        </section>
      </div>
    </div>

    <div class="tylie-grid-wrapper">
        <dx-data-grid id="destinationsGrid" class="tylie-grid tylie-grid--bordered"
                      #destinationsGrid
                      [dataSource]="dataSource"
                      [columnAutoWidth]="true"
                      [showColumnLines]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showBorders]="true"
                      [showRowLines]="true"
                      (onSelectionChanged)="onSelectionChanged($event)"
                      keyExpr="id">
          <dxo-selection mode="multiple"
                          allowSelectAll="false"
                          showCheckBoxesMode="always">
          </dxo-selection>
          <dxi-column dataField="code" width="20%" cssClass="gridStyle" caption="Destination ID" sortOrder="asc"></dxi-column>
          <dxi-column dataField="name" width="30%" cssClass="gridStyle" caption="Name"></dxi-column>
          <dxi-column dataField="destinationType" width="15%" cssClass="gridStyle" caption="Type"></dxi-column>
          <dxi-column dataField="aka" width="15%" cssClass="gridStyle" caption="Aka"></dxi-column>
          <dxi-column dataField="market" width="40%" cssClass="gridStyle" caption="Market"></dxi-column>
          <dxo-remote-operations [sorting]="true"
                                  [paging]="true">
          </dxo-remote-operations>
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-pager [showPageSizeSelector]="true"
                      [allowedPageSizes]="[10, 20, 50, 100]"
                      infoText="Page {0} of {1} ({2} items)"
                      [showNavigationButtons]="true"
                      [showInfo]="true">
          </dxo-pager>
        </dx-data-grid>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs" type="button" (click)="onMapDestination()">
        Map Destination
      </button>
    </div>
  </div>
</div>
