<div class="container" *ngIf="hideEmailContainer == false">
  <div class="imgcontainer">
    <img src="./assets/svg/TylieTandem-Logo.svg" alt="Avatar" class="avatar">
  </div>
  <div class="media-awaiting-approval-section animated zoomIn">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a class="p-small">
            Forgot password
          </a>
        </h4>
      </div>
      <div id="mediaApprovalCollapseOne" class="panel-collapse collapse in">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-danger animated shake" *ngIf="errorMsg">
                {{errorMsg}}
              </div>
              <form name="floginForm" #f="ngForm">
                <div class="form-group" [ngClass]="{error: f.submitted && !funame.valid}">
                  <label for="uname"><b>Please enter your registered Email/Username</b><span class="required">*</span></label>
                  <input id="funame" type="text" name="funame" class="form-control" required [(ngModel)]="forgotPasswordEmail" #funame="ngModel">
                  <p class="p-extra-small">Please enter your registered email</p>
                </div>
                <p class="p-extra-small">You will receive an email with a link to reset your password, if the entered email matches our records.</p>
                <div class="form-group media-qc  col-md-5 login-btn">
                  <button type="submit" (click)="verifyUserName(forgotPasswordEmail)" class="btn btn-normal qc-update" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? 'Sending...' : 'Continue'}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="tyl-copy-right p-tiny gray3">
          Any concerns please contact us at (818) 955 7600 or support@tylie.com
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="successMessage">
  <div class="imgcontainer">
    <img src="./assets/svg/Tylie-Logo.svg" alt="Avatar" class="avatar">
  </div>
  <br />
  <div>
    <div class="forgot-password-container">
      <p class="p-small black">{{successMessage}}</p>

      <p class="p-small">Can't find the email?</p>

      <p class="p-extra-small">You may need to check your Junk or Spam folder for a message from tylie.com</p>
      <div class="form-group media-qc  col-md-5 login-btn">
        <button type="button" (click)="backToLogin()" class="btn btn-normal qc-update" [disabled]="isLoading"><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? 'Taking you back...' : 'Back to login'}}</button>
      </div>
    </div>
  </div>
</div>
