import { User } from "./user.model";

export class UserProfile {
  //public userProfileId: number;
  public userProfileGuid: string;
  //public userName: string;
  //public userId: string;
  //public isPostHouseUser: boolean;
  //public client: any;
  public timeZone: string;
  public ianaCode: string;
  //public dob: Date;
  public avatar: string
  public cellPhone: string;
  public workPhone: string;
  public email: string;
  //public defaultView: string;
  //public isInternalUser: boolean;
  public firstName: string;
  public lastName: string;
  public initials: string;
  //public location: string;
  public accountExecutive: AccountExecutiveModel;
  public roles: string[];

  public birthDay: number;
  public birthMonth: string;
  public birthYear: string;
  public userProfileType: string;
  public authIdentifier: string;
  public defaultRoute: string;
}

export class AccountExecutiveModel {
  public name: string;
  public email: string;
  public phone: string;
  public userId: string;
  public avatarId: number;
}

export class UserProfileSelectionModel {
  public userProfileGuid: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public avatar: string;
  public fullName: string;
  public userName: string;
  public clientName: string;
  public editorialHouseName: string;
  public authIdentifier: string;
}

export class MultiUserProfileModel{
  public hasMultipleProfiles: boolean;
  public userProfileSelectionModelList: UserProfileSelectionModel[];
  public user: User;
  public token: string;
}

export class UserProfileSearch {
  public brand: number;
  public posthouse: number;
  public includeInactiveUsers: boolean;
  public client: number;
  public quickSearch: string;
}

export class UserProfileRecommendation {
  public email: string;
  public userId: string;
}
