<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()">
      <icon-trash-can color="#1e90ff"></icon-trash-can>
    </a>
  </h3>

  <div class="row">
    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['quantity'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="overtime">Quantity<span class="required">*</span></label>
      <dx-number-box formControlName="quantity"
                     name="quantity"
                     class="tylie-select form-control"
                     [min]="1"
                     [showSpinButtons]="false"></dx-number-box>
      <p class="p-extra-small">Please enter Quantity</p>
    </div>
  </div>

  <div class="row" *ngIf="allowSpecialOptions == true">
    <div class="tylie-form-group form-group col-md-6" [ngClass]="{error: (formGroup.controls['vendor'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl">Vendor<span class="required">*</span></label>
      <dx-select-box [dataSource]="vendors" name="vendor"
                     valueExpr="value"
                     class="tylie-select"
                     displayExpr="name"
                     formControlName="vendor" placeholder="Choose vendor"></dx-select-box>
      <p class="p-extra-small">Please choose a Vendor</p>
    </div>
  </div>
</div>
