<div type="button" class="tylie-button tylie-button--link tylie-button--xs collapsed" data-bs-toggle="collapse" href="#add-newproduct" role="button" aria-expanded="false" aria-controls="collapseExample">
  Add New Post House<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span>
</div>

<div class="collapse" id="add-newproduct" aria-expanded="false">
  <form [formGroup]="addNewPostHouseForm" (ngSubmit)="onFormSubmit(f,addNewPostHouseForm)" novalidate #f="ngForm">
    <div class="col-md-12">
      <div class="tylie-form-group form-group" [ngClass]="{error: (addNewPostHouseForm.controls['clientName'].errors && f.submitted)}">
        <label class="tylie-form-group__lbl" for="postHousename">Name<span class="required">*</span></label>
        <div class="row">
          <div class="col-md-8" [ngClass]="{error: (addNewPostHouseForm.controls['clientName'].errors && f.submitted)}">
            <input type="text" name="productName" id="productName" formControlName="clientName" class="tylie-text form-control" />
            <p class="p-extra-small">Please Enter Post House Name</p>
          </div>
  
          <div class="col-4">
            <button class="tylie-button tylie-button--sm" type="submit" [disabled]="isSaving">
              Save
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </form>
</div>


