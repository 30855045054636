<div class="tylie-section px-0" [formGroup]="formGroup" *ngIf="isDeleted === false">
  <h3 class="tylie-type-h3">
    {{formGroup.controls['serviceName'].value}}
    <a *ngIf="canDelete === true" class="tylie-button tylie-button--xs tylie-button--link" (click)="onServiceDelete()"><icon-trash-can color="#1e90ff"></icon-trash-can></a>
  </h3>

  <div class="row" *ngIf="orderLevel === true">
    <div class="tylie-form-group form-group col-md-3" [ngClass]="{error: (formGroup.controls['quantity'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="quantity">No of 1TB Hard drives<span class="required">*</span></label>
      <dx-number-box formControlName="quantity"
                     name="quantity"
                     class="tylie-select form-control"
                     [min]="1"
                     [showSpinButtons]="false"></dx-number-box>
      <p class="p-extra-small">Please enter Quantity</p>
    </div>
  </div>

  <ng-container *ngIf="spotLevel == true">

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['address']?.errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="address">Address<span class="required">*</span></label>
      <textarea name="address" id="address" class="tylie-textarea tylie-textarea--md form-control" formControlName="address" rows="4"></textarea>
      <p class="p-extra-small">Please enter Address</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['email'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="email">Email<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="email" formControlName="email" />
      <p class="p-extra-small">Please enter Email</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['phone'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="phone">Phone#<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="phone" formControlName="phone" />
      <p class="p-extra-small">Please enter Phone#</p>
    </div>

    <div class="tylie-form-group form-group" [ngClass]="{error: (formGroup.controls['trackingNumber'].errors && f.submitted)}">
      <label class="tylie-form-group__lbl" for="trackingNumber">Tracking#<span class="required">*</span></label>
      <input type="text" class="tylie-text form-control" name="trackingNumber" formControlName="trackingNumber" />
      <p class="p-extra-small">Please enter Tracking#</p>
    </div>
  </ng-container>
</div>
